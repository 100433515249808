import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import ColaboradorDialogPage from 'pages/erp/painel/colaborador/ColaboradorDialogPage'
import ColaboradorPhotoPicker from 'pages/erp/painel/colaborador/picker/ColaboradorPhotoPicker'
import NegocioSupport from 'pages/erp/painel/negocio/NegocioSupport'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import InputTipoRemuneracao from 'pages/erp/painel/remuneracao/input/InputTipoRemuneracao'
import RecalcularRemuneracoesDialog from 'pages/erp/painel/remuneracao/RecalcularRemuneracoesDialog'
import RemuneracoesDetalhesDialogPage from 'pages/erp/painel/remuneracao/RemuneracoesDetalhesDialogPage'
import RemuneracoesPagamentosDialogPage from 'pages/erp/painel/remuneracao/RemuneracoesPagamentosDialogPage'
import SelecionarRemuneracoesPagamentoDialogPage from 'pages/erp/painel/remuneracao/SelecionarRemuneracoesPagamentoDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import ImageUtil from 'support/util/ImageUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const MAX_WIDTH_CONTENT = 880

const styles = (theme) => ({
  colunaFoto: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaValor: {
    flexGrow: 0.6,
    maxWidth: 122,
    minWidth: 122
  },
  colunaValorPendente: {
    flexGrow: 0.6,
    maxWidth: 256,
    minWidth: 256,
    marginRight: -8
  },
  fotoConsultaDetalhadaSmall: {
    marginRight: 16
  }
})

class RemuneracoesPanelPage extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      title: 'Remunerações',
      ajaxing: false,
      dataPesquisaInicial: this.colaboradorLocalPreferences.getMomentPreference('RemuneracoesPanelPageFiltro_DataPesquisaInicial', moment().startOf('month')),
      dataPesquisaFinal: this.colaboradorLocalPreferences.getMomentPreference('RemuneracoesPanelPageFiltro_DataPesquisaFinal', moment().endOf('month')),
      tipo: this.colaboradorLocalPreferences.getPreference('RemuneracoesPanelPageFiltro_Tipo', null)
    }

    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoPagamentoComissao', 'ManipulacaoComissao', 'ManipulacaoColaborador'], (props) => {
      this.recarregarLista()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  recarregarLista = (force) => {
    this.loaderFunctionsMap.load()
  }

  openMenuPagamento = (item, event) => {
    event.stopPropagation()
    this.setState({
      menuPagamentoAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      },
      itemMenu: item
    })
  }

  closeMenuPagamento = (event) => {
    event.stopPropagation()
    this.setState({ menuPagamentoAnchorPosition: null })
  }

  openMenuConsulta = (item, event) => {
    event.stopPropagation()
    this.setState({
      menuConsultaAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      },
      itemMenu: item
    })
  }

  closeMenuConsulta = (event) => {
    event.stopPropagation()
    this.setState({ menuConsultaAnchorPosition: null })
  }

  getRequestParameters = () => {
    const { tipo, dataPesquisaInicial, dataPesquisaFinal } = this.state

    const idsTiposRemuneracao = []
    if (tipo) {
      idsTiposRemuneracao.push(tipo.id)
    }

    return {
      dataInicial: converterMomentParaDataInt(dataPesquisaInicial),
      dataFinal: converterMomentParaDataInt(dataPesquisaFinal),
      idsTiposRemuneracao: idsTiposRemuneracao.length > 0 ? idsTiposRemuneracao : null
    }
  }

  getLoaderKey = () => {
    const { dataPesquisaInicial, dataPesquisaFinal, tipo } = this.state

    let key = 'loader'

    if (dataPesquisaInicial) {
      key += '-i' + dataPesquisaInicial
    }
    if (dataPesquisaFinal) {
      key += '-f' + dataPesquisaFinal
    }

    if (tipo && tipo.id) {
      key += '-tp' + tipo.id
    }

    return key
  }

  abrirRegistrarPagamentoDialogPage = (params) => {
    if (!verificarAutorizacao([PA.PODE_PAGAR_REMUNERACOES])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(SelecionarRemuneracoesPagamentoDialogPage, {
      colaborador: this.state.itemMenu.colaborador,
      dataPesquisaInicial: this.state.dataPesquisaInicial,
      dataPesquisaFinal: this.state.dataPesquisaFinal,
      tipo: this.state.tipo
    })
  }

  abrirInformacoesDetalhadasDialogPage = () => {
    openBackableDialog(RemuneracoesDetalhesDialogPage, {
      colaborador: this.state.itemMenu.colaborador,
      dataPesquisaInicial: this.state.dataPesquisaInicial,
      dataPesquisaFinal: this.state.dataPesquisaFinal,
      tipo: this.state.tipo
    })
  }

  abrirRecalcularRemuneracoesDialogPage = () => {
    if (getSessaoPainel().usuario.simulado) {
      openBackableDialog(RecalcularRemuneracoesDialog, {
        colaborador: this.state.itemMenu.colaborador,
        dataInicial: this.state.dataPesquisaInicial,
        dataFinal: this.state.dataPesquisaFinal
      })
    } else {
      openBackableDialog(MessageDialog, {
        text: 'Entre em contato conosco através do suporte para recalcular as remunerações de um profissional.'
      })
    }
  }

  abrirRemuneracoesPagamentosDialogPage = () => {
    openBackableDialog(RemuneracoesPagamentosDialogPage, {
      colaborador: this.state.itemMenu.colaborador
    })
  }

  getRenderContentConsultaDetalhada = (size, item) => {
    const content = {}
    const variacoesFoto = ImageUtil.extrairVariacoesImagem(item.colaborador.foto)
    content.foto = <ColaboradorPhotoPicker colaborador={item.colaborador} photo1x={variacoesFoto.get(2)} width={38} readOnly={true} />
    content.nomeColaborador = item.colaborador.apelido
    content.valorTotal = formatarValorMonetario(item.valorComissaoEfetivo ? item.valorComissaoEfetivo : 0, { prefix: false })
    content.valorPago = formatarValorMonetario(item.valorPago ? item.valorPago : 0, { prefix: false })
    content.valorPendentePagamento = formatarValorMonetario(item.valorPendentePagamento ? item.valorPendentePagamento : 0, { prefix: false })
    content.valorValesDisponiveis = formatarValorMonetario(item.valorValesDisponiveis ? item.valorValesDisponiveis : 0, { prefix: false })
    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Relatório de Remunerações - ' + formatarPeriodoNomeArquivo(this.state.dataPesquisaInicial, this.state.dataPesquisaFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'colaborador/remuneracoes.gerarListaInformacoesRemuneracoes',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { menuConsultaAnchorPosition, dataPesquisaInicial, dataPesquisaFinal, tipo } = this.state

    let largeRenderProps = undefined
    let mediumRenderProps = undefined
    let smallRenderProps = undefined

    largeRenderProps = {
      columns: [
        { label: null, className: classes.colunaFoto, isVisible: false },
        { label: 'Profissional', props: { xs: true } },
        { label: 'Valor Total (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
        { label: 'Valor Pago (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
        { label: 'Pendente Pagamento (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
        {
          label: (
            <span>
              Vales <br />
              Pendentes (R$)
            </span>
          ),
          className: classes.colunaValor,
          horizontalPadding: 'small',
          align: 'right'
        }
      ],
      itemRenderer: (item, index) => {
        const contentData = this.getRenderContentConsultaDetalhada('large', item)

        return {
          itemData: [
            contentData.foto,
            contentData.nomeColaborador,
            contentData.valorTotal,
            contentData.valorPago,
            contentData.valorPendentePagamento,
            contentData.valorValesDisponiveis
          ]
        }
      }
    }
    mediumRenderProps = {
      rowHeight: 90,
      columns: [
        { label: null, className: classes.colunaFoto, isVisible: false },
        { label: 'Profissional', props: { xs: true } },
        { label: 'Valor Total (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
        { label: 'Pendente Pagamento (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
      ],
      itemRenderer: (item, index) => {
        const contentData = this.getRenderContentConsultaDetalhada('medium', item)

        return {
          itemData: [
            contentData.foto,
            <React.Fragment>
              <Typography variant="body2" noWrap={true}>
                <b>{contentData.nomeColaborador}</b>
              </Typography>
              <Typography variant="body2">Valor Pago: {contentData.valorPago}</Typography>
              <Typography variant="body2">Vales Pendentes: {contentData.valorValesDisponiveis}</Typography>
            </React.Fragment>,
            contentData.valorTotal,
            contentData.valorPendentePagamento
          ]
        }
      }
    }
    smallRenderProps = {
      rowHeight: 136,
      itemRenderer: (item, index) => {
        const contentData = this.getRenderContentConsultaDetalhada('small', item)
        return {
          itemData: (
            <React.Fragment>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item className={classes.fotoConsultaDetalhadaSmall}>
                  {contentData.foto}
                </Grid>
                <Grid item xs style={{ minWidth: 0 }}>
                  <Typography variant="body1" style={{ fontWeight: 500 }} noWrap={true}>
                    {contentData.nomeColaborador}
                  </Typography>
                  <Typography variant="body2">Valor Total: {contentData.valorTotal}</Typography>
                  <Typography variant="body2">Valor Pago: {contentData.valorPago}</Typography>
                  <Typography variant="body2">Pendente pagamento: {contentData.valorPendentePagamento}</Typography>
                  <Typography variant="body2">Vales Pendentes: {contentData.valorValesDisponiveis}</Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }
      }
    }

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push({
      icon: <RefreshIcon />,
      handleAction: () => {
        this.recarregarLista()
      }
    })

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    let valorTotalRemuneracoes = 0
    let valorTotalPago = 0
    let valorValesPendentes = 0
    let valorPendentePagamentoIntegral = 0
    let valorPendentePagamentoDescontandoVales = 0

    if (this.state.lista) {
      for (let item of this.state.lista) {
        valorTotalRemuneracoes += item.valorComissaoEfetivo ? item.valorComissaoEfetivo : 0
        valorTotalPago += item.valorPago ? item.valorPago : 0
        valorPendentePagamentoIntegral += item.valorPendentePagamento ? item.valorPendentePagamento : 0
        valorValesPendentes += item.valorValesDisponiveis ? item.valorValesDisponiveis : 0
      }
    }

    valorPendentePagamentoDescontandoVales = valorPendentePagamentoIntegral - valorValesPendentes
    if (valorPendentePagamentoDescontandoVales < 0) {
      valorPendentePagamentoDescontandoVales = 0
    }

    return (
      <PanelPage {...others} title={this.state.title} toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent maxWidth={MAX_WIDTH_CONTENT} horizontalMarginSmall={true} type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 20, marginLeft: -4 }} key="filtroTipo">
              <InputTipoRemuneracao
                descricaoTodos="Todos"
                customVariant="naked"
                value={tipo ? tipo.id : null}
                fullWidth={false}
                onChange={(event) => {
                  const tipo = TipoRemuneracaoEnum.getById(parseInt(event.target.value, 10))
                  this.colaboradorLocalPreferences.setPreference('RemuneracoesPanelPageFiltro_Tipo', tipo)
                  this.setState({ tipo: tipo })
                }}
              />
            </Grid>
            <Grid item style={{ marginLeft: -4 }}>
              <DateRangeNavigator
                expanded={false}
                defaultValue={{
                  tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RemuneracoesPanelPageFiltro_PesquisaTipoIntervalo', 'mes'),
                  dataInicial: dataPesquisaInicial,
                  dataFinal: dataPesquisaFinal
                }}
                onChange={(data) => {
                  this.colaboradorLocalPreferences.setPreference('RemuneracoesPanelPageFiltro_PesquisaTipoIntervalo', data.tipoIntervalo.nome)
                  this.colaboradorLocalPreferences.setMomentPreference('RemuneracoesPanelPageFiltro_DataPesquisaInicial', data.dataInicial)
                  this.colaboradorLocalPreferences.setMomentPreference('RemuneracoesPanelPageFiltro_DataPesquisaFinal', data.dataFinal)
                  this.setState({
                    dataPesquisaInicial: data.dataInicial,
                    dataPesquisaFinal: data.dataFinal
                  })
                }}
              />
            </Grid>
          </Grid>
        </FloatContent>

        <br />

        <VirtualizedResponsiveTable
          minTableWidth={460}
          showBackgroundDividers={true}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/colaborador/remuneracoes.buscarInformacoesRemuneracoes"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          onClickRow={(item, event) => {
            this.openMenuConsulta(item, event)
          }}
          largeRenderProps={largeRenderProps}
          mediumRenderProps={mediumRenderProps}
          smallRenderProps={smallRenderProps}
          emptyListProps={{
            text: 'Nenhuma comissão foi encontrada'
          }}
        />
        <br />

        {valorTotalRemuneracoes > 0 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            <Typography variant="body2">Total Remunerações: {formatarValorMonetario(valorTotalRemuneracoes)}</Typography>
            <Typography variant="body2">Total Pago: {formatarValorMonetario(valorTotalPago)}</Typography>
            <Typography variant="body2">Vales Pendentes: {formatarValorMonetario(valorValesPendentes)}</Typography>
            <br />
            <Divider />
            <br />
            {valorValesPendentes > 0 && (
              <React.Fragment>
                <Typography variant="body2">
                  <b>Pendente para Pagamento:</b>
                </Typography>
                <Typography variant="body2">Valor Integral: {formatarValorMonetario(valorPendentePagamentoIntegral)}</Typography>
                <Typography variant="body2">Descontando Vales: {formatarValorMonetario(valorPendentePagamentoDescontandoVales)}</Typography>
              </React.Fragment>
            )}
            {!valorValesPendentes && (
              <Typography variant="body2">
                <b>Pendente Pagamento: {formatarValorMonetario(valorPendentePagamentoIntegral)}</b>
              </Typography>
            )}
          </FloatContent>
        )}

        <Menu
          anchorReference="anchorPosition"
          anchorPosition={menuConsultaAnchorPosition}
          open={Boolean(menuConsultaAnchorPosition)}
          onClose={(event) => this.closeMenuConsulta(event)}
        >
          <MenuItem
            onClick={(event) => {
              this.abrirInformacoesDetalhadasDialogPage()
              this.closeMenuConsulta(event)
            }}
          >
            Exibir Detalhes
          </MenuItem>
          {verificarAutorizacao([PA.PODE_PAGAR_REMUNERACOES]) && (
            <MenuItem
              onClick={(event) => {
                event.stopPropagation()
                NegocioSupport.tentarExibirAvisoImportante({
                  onIgnore: () => {
                    this.abrirRegistrarPagamentoDialogPage({
                      colaborador: this.state.itemMenu.colaborador,
                      valor: this.state.itemMenu.valorPendentePagamento,
                      tipo: this.state.itemMenu.tipo
                    })
                  }
                })
                this.closeMenuConsulta(event)
              }}
            >
              Registrar Pagamento
            </MenuItem>
          )}
          <MenuItem
            onClick={(event) => {
              this.abrirRemuneracoesPagamentosDialogPage()
              this.closeMenuConsulta(event)
            }}
          >
            Histórico de Pagamentos
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              openBackableDialog(ColaboradorDialogPage, {
                idColaborador: this.state.itemMenu.colaborador.id
              })
              this.closeMenuConsulta(event)
            }}
          >
            Abrir Colaborador
          </MenuItem>
          {verificarAutorizacao([PA.PODE_PAGAR_REMUNERACOES]) && (
            <MenuItem
              onClick={(event) => {
                this.abrirRecalcularRemuneracoesDialogPage()
                this.closeMenuConsulta(event)
              }}
            >
              Recalcular Remunerações
            </MenuItem>
          )}
        </Menu>
      </PanelPage>
    )
  }
}

RemuneracoesPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RemuneracoesPanelPage)
