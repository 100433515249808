import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import EventNoteIcon from '@material-ui/icons/EventNoteOutlined'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'

const styles = (theme) => ({
  icon: {
    display: 'block'
  },
  regular: {
    fontSize: 24
  },
  regularThumbUp: {
    fontSize: 22
  },
  small: {
    fontSize: 20
  },
  smallThumbUp: {
    fontSize: 18
  },
  mini: {
    fontSize: 18
  },
  miniThumbUp: {
    fontSize: 16
  }
})

class AtendimentoStatusIcon extends Component {
  render() {
    const { classes, className, hasTooltip, status, size, ...others } = this.props

    let IconComponent = null
    let iconClasses = [className, classes.icon]

    let prefixClassName = size
    if (!prefixClassName) {
      prefixClassName = 'regular'
    }

    if (status.id === AtendimentoStatusEnum.MARCADO.id) {
      IconComponent = EventNoteIcon
      iconClasses.push(classes[prefixClassName])
      status.descricao = AtendimentoStatusEnum.MARCADO.descricao
    } else if (status.id === AtendimentoStatusEnum.CONFIRMADO.id) {
      IconComponent = ThumbUpIcon
      iconClasses.push(classes[prefixClassName + 'ThumbUp'])
      status.descricao = AtendimentoStatusEnum.CONFIRMADO.descricao
    } else if (status.id === AtendimentoStatusEnum.CLIENTE_CHEGOU.id) {
      IconComponent = HowToRegIcon
      iconClasses.push(classes[prefixClassName])
      status.descricao = AtendimentoStatusEnum.CLIENTE_CHEGOU.descricao
    } else if (status.id === AtendimentoStatusEnum.EM_ATENDIMENTO.id) {
      IconComponent = SentimentVerySatisfiedIcon
      iconClasses.push(classes[prefixClassName])
      status.descricao = AtendimentoStatusEnum.EM_ATENDIMENTO.descricao
    } else if (status.id === AtendimentoStatusEnum.CONCLUIDO.id) {
      IconComponent = DoneAllIcon
      iconClasses.push(classes[prefixClassName])
      status.descricao = AtendimentoStatusEnum.CONCLUIDO.descricao
    } else if (status.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id) {
      IconComponent = SentimentVeryDissatisfiedIcon
      iconClasses.push(classes[prefixClassName])
      status.descricao = AtendimentoStatusEnum.CLIENTE_FALTOU.descricao
    } else if (status.id === AtendimentoStatusEnum.CANCELADO.id) {
      IconComponent = CancelIcon
      iconClasses.push(classes[prefixClassName])
      status.descricao = AtendimentoStatusEnum.CANCELADO.descricao
    }

    if (IconComponent) {
      const icon = <IconComponent {...others} className={classNames(iconClasses)} />
      if (hasTooltip && status.descricao) {
        return <Tooltip title={status.descricao}>{icon}</Tooltip>
      } else {
        return icon
      }
    } else {
      return null
    }
  }
}

AtendimentoStatusIcon.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtendimentoStatusIcon)
