import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  COMISSAO: { id: 1, descricao: 'Comissão', descricaoPlural: 'Comissões' },
  GORJETA: { id: 2, descricao: 'Gorjeta', descricaoPlural: 'Gorjetas' },
  SALARIO: { id: 3, descricao: 'Salário', descricaoPlural: 'Salários' },
  BONIFICACAO: { id: 4, descricao: 'Bonificação', descricaoPlural: 'Bonificações' }
})

export default enumObject
