import { createInlineValuesObject } from 'support/components/inlinevalues/InlineValues'

const obj = createInlineValuesObject({
  PUBLICO_FEMININO: { position: 0, descricao: 'Feminino' },
  PUBLICO_MASCULINO: { position: 1, descricao: 'Masculino' },
  PUBLICO_INFANTIL: { position: 2, descricao: 'Infantil' },
  SALAO_BELEZA: { position: 3, descricao: 'Salão de Beleza' },
  BARBEARIA: { position: 4, descricao: 'Barbearia' },
  MANICURE: { position: 5, descricao: 'Manicure' },
  PEDICURE: { position: 6, descricao: 'Pedicure' },
  ESTETICA: { position: 7, descricao: 'Estética' },
  SPA: { position: 8, descricao: 'SPA' },
  MASSAGEM: { position: 9, descricao: 'Massagem' },
  SOBRANCELHA: { position: 10, descricao: 'Sobrancelha' },
  CILIOS: { position: 11, descricao: 'Cílios' },
  PODOLOGIA: { position: 12, descricao: 'Podologia' },
  FISIOTERAPIA: { position: 13, descricao: 'Fisioterapia' }
})

export default obj
