function getStyles({ defaultTheme, tema, constant }) {
  return {
    container: {
      basic: function (props) {
        if (!props) {
          props = {}
        }

        let marginValue = constant.SCREEN_MARGIN
        let marginXsValue = constant.SCREEN_MARGIN_XS
        let margin380Value = constant.SCREEN_MARGIN_380

        if (props.horizontalFixedMargin) {
          marginValue = props.horizontalFixedMargin
          marginXsValue = props.horizontalFixedMargin
          margin380Value = props.horizontalFixedMargin
        }

        let widthProp = {}
        let widthPropXs = {}
        let widthProp380 = {}

        if (props.fullWidth) {
          widthProp = { width: 'calc(100% - ' + marginValue * 2 + 'px)' }
          widthPropXs = { width: 'calc(100% - ' + marginXsValue * 2 + 'px)' }
          widthProp380 = { width: 'calc(100% - ' + margin380Value * 2 + 'px)' }
        }

        const style = {
          paddingLeft: marginValue,
          paddingRight: marginValue,
          ...widthProp,
          [defaultTheme.breakpoints.down('sm')]: {
            paddingLeft: marginValue,
            paddingRight: marginValue,
            ...widthProp
          },
          [defaultTheme.breakpoints.down('xs')]: {
            paddingLeft: marginXsValue,
            paddingRight: marginXsValue,
            ...widthPropXs
          },
          [defaultTheme.breakpoints.down(380)]: {
            paddingLeft: margin380Value,
            paddingRight: margin380Value,
            ...widthProp380
          }
        }

        if (typeof props.paddingTop === 'number') {
          style.paddingTop = props.paddingTop
        }

        if (props.extraPaddingBottom) {
          style.paddingBottom = defaultTheme.spacing(12)
        }

        return style
      },
      basicNegativeMargin: function (props) {
        if (!props) {
          props = {}
        }

        let marginValue = constant.SCREEN_MARGIN
        let marginXsValue = constant.SCREEN_MARGIN_XS
        let margin380Value = constant.SCREEN_MARGIN_380

        if (props.horizontalFixedMargin) {
          marginValue = props.horizontalFixedMargin
          marginXsValue = props.horizontalFixedMargin
          margin380Value = props.horizontalFixedMargin
        }

        let widthProp = {}
        let widthPropXs = {}
        let widthProp380 = {}

        const style = {
          marginLeft: marginValue * -1,
          marginRight: marginValue * -1,
          ...widthProp,
          [defaultTheme.breakpoints.down('sm')]: {
            marginLeft: marginValue * -1,
            marginRight: marginValue * -1,
            ...widthProp
          },
          [defaultTheme.breakpoints.down('xs')]: {
            marginLeft: marginXsValue * -1,
            marginRight: marginXsValue * -1,
            ...widthPropXs
          },
          [defaultTheme.breakpoints.down(380)]: {
            marginLeft: margin380Value * -1,
            marginRight: margin380Value * -1,
            ...widthProp380
          }
        }

        return style
      },
      centered: function (props) {
        if (!props) {
          props = {}
        }

        let maxWidth = constant.CENTERED_CONTAINER_MAX_WIDTH
        if (props.maxWidth) {
          maxWidth = props.maxWidth
        }

        return {
          maxWidth: maxWidth,
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      },
      card: function (props) {
        if (!props) {
          props = {}
        }

        return {
          border: '1px solid ' + tema.dados.divider.background,
          borderRadius: 20,
          padding: '26px 26px 30px',
          height: props.fullHeight ? 'calc(100% - 56px)' : 'auto'
        }
      }
    }
  }
}

const objectFunctions = {
  getStyles: getStyles
}

export default objectFunctions
