export interface ResponseType<DataType> {
  data: DataType
}

export interface ApiParams {
  url: string
  requerAutorizacao?: boolean
  headers?: Record<string, string>
  contextoUsuario?: 'erp' | 'admin' | 'portal'
  data?: unknown
  method?: 'get' | 'post' | 'patch'
  params?: Record<string, unknown>
  isMultiparFormData?: boolean
  sendErroToGenericSnackbar?: boolean
  timeout?: number
}

export interface ApiFnParams {
  onSuccess: (response: unknown) => void
  onError: (error: unknown) => void
}

interface EventoResponseError {
  userMessage: string
}

export const callAPIWithPromise = <ResponseDataType>(callApiFn: (params: ApiFnParams) => void, params: ApiParams): Promise<ResponseDataType> => {
  return new Promise<ResponseDataType>((resolve, reject) => {
    callApiFn({
      onSuccess: (response: unknown) => {
        resolve(response as ResponseDataType)
      },
      onError: (err: unknown) => {
        const { userMessage } = (err as EventoResponseError) || {}
        const errorMessage = userMessage || 'Erro desconhecido'

        reject(errorMessage)
      },
      ...params
    })
  })
}
