import moment from 'moment'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'

type PlanoEnumType = {
  AVALIACAO: { id: number; nome: string; precoMensalPadrao?: number; precoAnualPadrao?: number }
  INDIVIDUAL: { id: number; nome: string; precoMensalPadrao: number; precoAnualPadrao: number }
  EMPRESA: { id: number; nome: string; precoMensalPadrao: number; precoAnualPadrao: number }
}

const TEN_DAYS = 10
const THREE_TIMES = 3

const Plan = PlanoEnum as unknown as PlanoEnumType

const getContributorLocalStorage = (contributorId: number) => {
  return JSON.parse(localStorage.getItem(`LocalPreferences_colaborador${contributorId}`) || '{}')
}

export const setContributorBannerDismiss = (contributorId: number) => {
  const contributorLocalStorage = getContributorLocalStorage(contributorId)
  contributorLocalStorage.bannerDismissed = contributorLocalStorage.bannerDismissed
    ? { ...contributorLocalStorage.bannerDismissed, value: contributorLocalStorage.bannerDismissed.value + 1 }
    : { value: 1, type: 'permanent' }

  sessionStorage.setItem('bannerDismissed', 'true')
  localStorage.setItem(`LocalPreferences_colaborador${contributorId}`, JSON.stringify(contributorLocalStorage))
}

export const shouldShowTrainingBanner = (planId: number, signUpDate: string, contributorId: number) => {
  const contributorLocalStorage = getContributorLocalStorage(contributorId)
  const bannerDismissed = contributorLocalStorage.bannerDismissed ?? { value: 0, type: 'permanent' }
  const bannerDismissedSession = sessionStorage.getItem('bannerDismissed')
  const isTrial = planId === Plan.AVALIACAO.id
  const isSignupDateLessThanTenDays = moment().diff(signUpDate, 'days') <= TEN_DAYS

  return isTrial && isSignupDateLessThanTenDays && bannerDismissed.value <= THREE_TIMES && !bannerDismissedSession
}
