import { converterHorarioIntParaMinutos, converterMinutosParaHorarioInt } from 'support/util/TimeConverter'

function adiciconarMinutosHorarioInt(horario, minutos) {
  let horarioEmMinutos = converterHorarioIntParaMinutos(horario)
  return converterMinutosParaHorarioInt(horarioEmMinutos + minutos)
}

const functionsObject = {
  adiciconarMinutosHorarioInt: adiciconarMinutosHorarioInt
}

export default functionsObject
