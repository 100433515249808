import { createEnum } from 'support/util/EnumUtil'

import { dateAsMoment, setMomentPtBr } from './momentConfig'

const diasSemana = []

if (dateAsMoment.locale() !== 'pt-br') {
  setMomentPtBr()
}

for (let i = 0; i <= 6; i++) {
  dateAsMoment.add(1, 'days')
  diasSemana.push({
    id: parseInt(dateAsMoment.format('d'), 10),
    nome: dateAsMoment.format('dddd'),
    nomeResumido: dateAsMoment.format('ddd')
  })
}

const enumObj = createEnum({
  DOMINGO: diasSemana[0],
  SEGUNDA: diasSemana[1],
  TERCA: diasSemana[2],
  QUARTA: diasSemana[3],
  QUINTA: diasSemana[4],
  SEXTA: diasSemana[5],
  SABADO: diasSemana[6]
})

export default enumObj
