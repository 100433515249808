import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import MomentUtils from '@date-io/moment'
import { withStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { PollingMode } from 'configcat-common'
import { ConfigCatProvider } from 'configcat-react'
import AtualizarSistemaDialog from 'support/components/atualizacao/AtualizarSistemaDialog'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogsManager from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import { clearNoPermanentsLocalPreferences } from 'support/components/localpreferences/LocalPreferences'
import SnackBarError from 'support/components/snackbar/SnackBarError'
import SnackBarSuccess from 'support/components/snackbar/SnackBarSuccess'
import TabSaveSnackBar from 'support/components/tab/TabSaveSnackBar'
import { TemaEnum } from 'support/style/Tema'
import { createS99Theme } from 'support/style/ThemeManager.js'

import AppRoute from './AppRoute'

//import MessageEventListener from 'support/components/messageeventlistener/MessageEventListener';
/*MessageEventListener.listener((props)=>{
});*/

class App extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.updateClienteApplicationDialogOpen = false
    this.state = {
      temaAplicacao: createS99Theme(null, this.props.theme)
    }
  }

  componentDidMount = () => {
    clearNoPermanentsLocalPreferences()
    this.eventsManager.sub('AlterarTemaAplicacao', (props) => {
      if (this.state.temaAplicacao.themeId !== props.tema.id) {
        this.setState({ temaAplicacao: createS99Theme(TemaEnum.getById(props.tema.id), this.props.theme) })
      }
    })
    this.eventsManager.sub('UpdateClienteApplication', (props) => {
      if (this.updateClienteApplicationDialogOpen === false) {
        this.updateClienteApplicationDialogOpen = true
        openBackableDialog(AtualizarSistemaDialog)
      }
    })
    const html = document.getElementsByTagName('html')[0]
    if (isMobile) {
      html.classList.add('isMobile')
      html.classList.add('isTablet')
    } else {
      html.classList.add('isDesktop')
    }
  }

  render() {
    return (
      <ConfigCatProvider pollingMode={PollingMode.ManualPoll} sdkKey={process.env.REACT_APP_CONFIGCAT_KEY || 'invalidKey'}>
        <ThemeProvider theme={this.state.temaAplicacao}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
            <BrowserRouter basename="/">
              <Switch>
                <Route path={'/'} render={(props) => <AppRoute {...props} />} />
              </Switch>
            </BrowserRouter>

            {/* Adicionar snackbars antes do DialogsManager. Se o DialogsManager é adicionado antes, por algum motivo (em algumas situações) os snackbars sofrem o unmount e não aparecem mais na aplicação  */}
            <SnackBarSuccess />
            <TabSaveSnackBar />
            <SnackBarError />

            <DialogsManager />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ConfigCatProvider>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

export default withStyles({}, { withTheme: true })(App)
