import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import DuracaoInputSelect from 'pages/erp/painel/atendimento/select/DuracaoInputSelect'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import CategoriaServicoInputDialogSelect from 'pages/erp/painel/servico/input/CategoriaServicoInputDialogSelect'
import InterfaceDefinicaoComissoes from 'pages/erp/painel/servico/InterfaceDefinicaoComissoes'
import ReplicarAlteracoesServicosFuturosDialog from 'pages/erp/painel/servico/ReplicarAlteracoesServicosFuturosDialog'
import ServicoDialogPage from 'pages/erp/painel/servico/ServicoDialogPage'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputPorcentagemValor from 'support/components/input/InputPorcentagemValor'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoConfiguracaoComissaoEnum from 'support/domain/comissao/TipoConfiguracaoComissaoEnum'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import NivelParaleloEnum from 'support/domain/servico/NivelParaleloEnum'
import TipoExibicaoPrecoEnum from 'support/domain/servico/TipoExibicaoPrecoEnum'
import { createEnum } from 'support/util/EnumUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat, InputNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  indicadorVariacao: {
    backgroundColor: '#d8d8d8',
    width: 5,
    height: '100%',
    position: 'absolute',
    left: 0,
    borderRadius: 1
  }
})

export const TipoVariacaoEnum = createEnum({
  SEM_VARIACAO: { id: 1, descricao: 'Sem variações de preço e duração' },
  COM_VARIACAO: { id: 2, descricao: 'Com variações de preço e duração' }
})

let variacaoKeyGenerator = 0

function generateVariacaoKey() {
  return ++variacaoKeyGenerator
}

class ServicoDadosBasicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    let servicoDefault = {}

    if (!props.idServico && props.categoria) {
      servicoDefault.categoria = props.categoria
    }

    this.state = {
      etapa: 1,
      paginaCarregada: false,
      title: 'Serviço',
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      servico: servicoDefault,
      tipoVariacao: TipoVariacaoEnum.SEM_VARIACAO,
      controleAgendamentoOnline: ControleAgendamentoOnlineEnum.HABILITADO,
      tipoExibicaoPreco: TipoExibicaoPrecoEnum.FIXO,
      nivelParalelo: NivelParaleloEnum.RESTRITO,
      variacoes: this.criarVariacoesPorQuantidade(1),
      tipoConfiguracaoComissao: TipoConfiguracaoComissaoEnum.NAO_GERAR_COMISSAO,
      alterarAtendimentosFuturos: false,
      scrollToIndex: -1,
      lista: []
    }

    this.inputNome = React.createRef()
    this.inputDescricao = React.createRef()
    //this.inputTempoExtra = React.createRef();
    this.inputComissaoSozinho = {}
    this.inputComissaoComAssistente = {}
    this.inputDescontarComissaoComAssistente = {}
    this.inputComissaoComoAssistente = {}
    this.categoriaFunctionsMap = {}

    EventsManager.pub('TabSaveSnackBar', { open: false })
    this.interfaceDefinicaoComissoesFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idServico) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/servicos.buscarPorId',
        params: {
          id: this.props.idServico
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          const servico = response.data

          this.setState((state) => ({
            servico: servico,
            controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(servico.controleAgendamentoOnline.id),
            tipoExibicaoPreco: TipoExibicaoPrecoEnum.getById(servico.tipoExibicaoPreco.id),
            nivelParalelo: NivelParaleloEnum.getById(servico.nivelParalelo.id),
            tipoVariacao: servico.quantidadeVariacoes > 1 ? TipoVariacaoEnum.COM_VARIACAO : TipoVariacaoEnum.SEM_VARIACAO,
            tipoConfiguracaoComissao: servico.tipoConfiguracaoComissao ? servico.tipoConfiguracaoComissao : TipoConfiguracaoComissaoEnum.NAO_GERAR_COMISSAO,
            variacoes: this.criarVariacoesPorServico(servico)
          }))
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: this.salvar
          }
        ]
      }
    }))
  }

  criarVariacoesPorQuantidade = (quantidade) => {
    const variacoes = this.state && this.state.variacoes ? this.state.variacoes.slice() : []

    if (variacoes.length > quantidade) {
      for (let i = variacoes.length; i > quantidade; i--) {
        variacoes.splice(i - 1, 1)
      }
    } else if (quantidade > variacoes.length) {
      for (let i = variacoes.length; i < quantidade; i++) {
        variacoes.push(this.criarItemVariacao())
      }
    }

    return variacoes
  }

  onChangeQuantidadeVariacao = (event) => {
    const tipoVariacao = TipoVariacaoEnum.getById(event.target.value)
    const state = { tipoVariacao: tipoVariacao }
    if (tipoVariacao.id === TipoVariacaoEnum.SEM_VARIACAO.id) {
      state.variacoes = this.criarVariacoesPorQuantidade(1)
    } else if (tipoVariacao.id === TipoVariacaoEnum.COM_VARIACAO.id) {
      state.variacoes = this.criarVariacoesPorQuantidade(2)
    }
    this.setState(state)
  }

  criarVariacoesPorServico = (servico) => {
    const variacoes = []

    servico.variacoes.sort(function (a, b) {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0
    })

    if (servico.variacoes) {
      for (let i = 0; i < servico.variacoes.length; i++) {
        variacoes.push(this.criarItemVariacao(servico.variacoes[i]))
      }
    }
    return variacoes
  }

  adicionarVariacoes = (servico) => {
    const variacoes = this.state.variacoes
    variacoes.push(this.criarItemVariacao())
    this.setState({ variacoes: variacoes })
  }

  removerVariacao = (index) => {
    let variacoes = this.state.variacoes
    variacoes.splice(index, 1)
    this.setState({ variacoes: variacoes })
  }

  criarItemVariacao = (variacao) => {
    return {
      key: generateVariacaoKey(),
      id: variacao ? variacao.id : null,
      nome: variacao ? variacao.nome : null,
      descricao: variacao ? variacao.descricao : null,
      duracao: variacao ? variacao.duracao : null,
      preco: variacao ? variacao.preco : null,
      valorDespesa: variacao ? variacao.valorDespesa : null,
      valorNaoComissionavel: variacao ? variacao.valorNaoComissionavel : null,
      inputNome: React.createRef(),
      inputDescricao: React.createRef(),
      inputDuracao: {},
      inputPreco: React.createRef(),
      inputValorDespesa: React.createRef(),
      inputValorNaoComissionavel: React.createRef()
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    if (this.state.etapa === 1) {
      let variacoesServicoComAlteracaoPreco = []

      this.state.variacoes.forEach((item) => {
        const precoNovo = FormUtil.convertStringToNumber(item.inputPreco.current.value)
        const precoAntigo = FormUtil.convertStringToNumber(item.preco)

        if (item.id && precoNovo !== precoAntigo) {
          variacoesServicoComAlteracaoPreco.push(item.id)
        }
      })

      if (variacoesServicoComAlteracaoPreco.length > 0) {
        this.buscarDadosPrePersistencia(variacoesServicoComAlteracaoPreco)
      } else {
        this.salvarServico()
      }
    } else if (this.state.etapa === 2) {
      this.salvarColaboradores()
    }
  }

  buscarDadosPrePersistencia = (variacoesServicoComAlteracaoPreco) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/servicos.buscarDadosPrePersistencia',
      params: {
        idsVariacoesServico: variacoesServicoComAlteracaoPreco
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({ ajaxing: false })
        const quantidadeAtendimentos = response.data.quantidadeAtendimentos

        if (quantidadeAtendimentos > 0) {
          openBackableDialog(ReplicarAlteracoesServicosFuturosDialog, {
            quantidadeAtendimentos: quantidadeAtendimentos,
            clickAction: this.salvarServico
          })
        } else {
          this.salvarServico()
        }
      }
    })
  }

  salvarServico = (alterarAtendimentosFuturos) => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: FormUtil.createErrorsMap(),
      alterarAtendimentosFuturos: alterarAtendimentosFuturos
    }))

    postAPI({
      url: 'erp/servicos.persistir',
      data: this.getDadosServico(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        const servico = response.data
        EventsManager.pub('ManipulacaoServico', { servico: response.data })
        if (!this.props.idServico && verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])) {
          this.setState({
            etapa: 2,
            ajaxing: false,
            errorsMap: FormUtil.createErrorsMap(),
            servico: servico
          })
        } else {
          this.props.handleCloseDialog()
        }
      },
      onError: (response) => {
        if (response.data.errors && response.data.errors.pacote) {
          openBackableDialog(MessageDialog, {
            text: response.data.errors.pacote
          })
        }

        this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formServico')
      }
    })
  }

  getDadosServico = () => {
    const dados = {}

    if (this.props.idServico !== null) {
      dados.id = this.props.idServico
    }

    dados.nome = this.inputNome.current.value
    dados.descricao = this.inputDescricao.current.value

    const idCategoria = this.categoriaFunctionsMap.getValue()
    if (idCategoria) {
      dados.categoria = {
        id: idCategoria
      }
    }

    dados.controleAgendamentoOnline = { id: this.state.controleAgendamentoOnline.id }
    dados.tipoExibicaoPreco = { id: this.state.tipoExibicaoPreco.id }
    dados.nivelParalelo = { id: this.state.nivelParalelo.id }

    //dados.tempoExtra =  FormUtil.convertStringToNumber(this.inputTempoExtra.current.node.value);
    dados.tipoConfiguracaoComissao = this.state.tipoConfiguracaoComissao
    dados.comissaoSozinho = FormUtil.convertStringToNumber(this.inputComissaoSozinho.getValor())
    dados.comissaoComAssistente = FormUtil.convertStringToNumber(this.inputComissaoComAssistente.getValor())
    dados.descontarComissaoComAssistente = this.inputDescontarComissaoComAssistente.getValue()
    dados.comissaoComoAssistente = FormUtil.convertStringToNumber(this.inputComissaoComoAssistente.getValor())

    dados.tipoCalculoComissaoSozinho = this.inputComissaoSozinho.getTipoCalculo()
    dados.tipoCalculoComissaoComAssistente = this.inputComissaoComAssistente.getTipoCalculo()
    dados.tipoCalculoComissaoComoAssistente = this.inputComissaoComoAssistente.getTipoCalculo()

    dados.alterarAtendimentosFuturos = this.state.alterarAtendimentosFuturos

    dados.variacoes = this.state.variacoes.map((variacoes) => ({
      id: variacoes.id,
      nome: this.state.tipoVariacao.id === TipoVariacaoEnum.SEM_VARIACAO.id ? null : variacoes.inputNome.current.value,
      descricao: this.state.tipoVariacao.id === TipoVariacaoEnum.SEM_VARIACAO.id ? null : variacoes.inputDescricao.current.value,
      duracao: FormUtil.convertStringToNumber(variacoes.inputDuracao.getValue()),
      preco: FormUtil.convertStringToNumber(variacoes.inputPreco.current.value),
      valorDespesa: FormUtil.convertStringToNumber(variacoes.inputValorDespesa.current.value),
      valorNaoComissionavel: FormUtil.convertStringToNumber(variacoes.inputValorNaoComissionavel.current.value)
    }))
    return dados
  }

  salvarColaboradores = () => {
    this.interfaceDefinicaoComissoesFunctionsMap.salvar()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, etapa, tipoConfiguracaoComissao } = this.state

    let {
      nome,
      descricao,
      categoria,
      comissaoSozinho,
      tipoCalculoComissaoSozinho,
      comissaoComAssistente,
      descontarComissaoComAssistente,
      tipoCalculoComissaoComAssistente,
      comissaoComoAssistente,
      tipoCalculoComissaoComoAssistente
    } = this.state.servico ? this.state.servico : {}

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    let comissaoInputContent = null
    let labelComissaoSozinho = 'Comissão Padrão'
    const styleInputsSimples = {}
    const styleInputsAvancados = {}

    if (tipoConfiguracaoComissao.id === TipoConfiguracaoComissaoEnum.NAO_GERAR_COMISSAO.id) {
      styleInputsSimples.display = 'none'
      styleInputsAvancados.display = 'none'
    } else if (tipoConfiguracaoComissao.id === TipoConfiguracaoComissaoEnum.SEM_COMISSAO.id) {
      styleInputsSimples.display = 'none'
      styleInputsAvancados.display = 'none'
    } else if (tipoConfiguracaoComissao.id === TipoConfiguracaoComissaoEnum.SIMPLES.id) {
      styleInputsAvancados.display = 'none'
    } else if (tipoConfiguracaoComissao.id === TipoConfiguracaoComissaoEnum.AVANCADA.id) {
      labelComissaoSozinho = 'Comissão Sozinho'
    }

    comissaoInputContent = (
      <React.Fragment>
        <InputPorcentagemValor
          style={styleInputsSimples}
          marginTop={true}
          customVariant="outlined-small"
          shrink={false}
          functionsMap={this.inputComissaoSozinho}
          idname={'xcomissaoSozinho'}
          defaultValor={comissaoSozinho}
          defaultTipoCalculo={tipoCalculoComissaoSozinho}
          disabled={this.state.ajaxing}
          errorMessage={errorsMap('comissaoSozinho')}
          label={labelComissaoSozinho}
        />
        <InputPorcentagemValor
          style={styleInputsAvancados}
          marginTop={true}
          customVariant="outlined-small"
          shrink={false}
          functionsMap={this.inputComissaoComAssistente}
          idname={'xcomissaocomassistente'}
          defaultValor={comissaoComAssistente}
          defaultTipoCalculo={tipoCalculoComissaoComAssistente}
          disabled={this.state.ajaxing}
          errorMessage={errorsMap('comissaoComAssistente')}
          label="Comissão Com Assistentes"
        />
        <UncontrolledCheckbox
          formControlProps={{
            style: { marginRight: 0, marginTop: 12, ...styleInputsAvancados },
            label: (
              <small style={{ lineHeight: '16px', display: 'inline-block', padding: '8px 0px' }}>
                Em atendimentos com assistentes, descontar as comissões dos assistentes da comissão do profissional
              </small>
            )
          }}
          color="default"
          defaultChecked={descontarComissaoComAssistente}
          functionsMap={this.inputDescontarComissaoComAssistente}
        />
        <InputPorcentagemValor
          style={styleInputsAvancados}
          marginTop={true}
          customVariant="outlined-small"
          shrink={false}
          functionsMap={this.inputComissaoComoAssistente}
          idname={'xcomissaocomoassistente'}
          defaultValor={comissaoComoAssistente}
          defaultTipoCalculo={tipoCalculoComissaoComoAssistente}
          disabled={this.state.ajaxing}
          errorMessage={errorsMap('comissaoComoAssistente')}
          label="Comissão Como Assistente"
        />
      </React.Fragment>
    )

    const iconAjudaValorCustos = (
      <HelpOutlineIcon
        style={{ opacity: 0.5, padding: 8, margin: '-8px 0px -8px -8px', cursor: 'pointer' }}
        onClick={(event) => {
          event.stopPropagation()
          openBackableDialog(MessageDialog, {
            text: 'O Valor de Custos criará uma despesa no sistema para cada lançamento desde serviço. Essa despesa será considerada para calcular o lucro líquido nos relatórios financeiros.'
          })
        }}
      />
    )

    const iconAjudaValorNaoComissionavel = (
      <HelpOutlineIcon
        style={{ opacity: 0.5, padding: 8, margin: '-8px 0px -8px -8px', cursor: 'pointer' }}
        onClick={(event) => {
          event.stopPropagation()
          openBackableDialog(MessageDialog, {
            text: (
              <span>
                O sistema irá descontar este valor do Preço do Serviço antes de calcular a comissão. Exemplo:
                <br />
                <br />
                <span style={{ fontSize: 14 }}>
                  Preço do Serviço: R$ 200,00
                  <br />
                  <b>
                    <u>Valor Não Comissionável: R$ 30,00</u>
                  </b>
                  <br />
                  <br />
                  Neste caso, o sistema irá calcular a comissão do profissional considerando o valor de R$ 170,00 (200,00 - 30,00), resultando em:
                  <br />
                  <br />
                  Comissão do Profissional: 50%
                  <br />
                  Cálculo da Comissão: 50% de R$ 170,00
                  <br />
                  Valor da Comissão: R$ 85,00
                </span>
              </span>
            )
          })
        }}
      />
    )

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            {etapa === 1 && (
              <ContentWithPreload loadFunction={this.carregarMain}>
                {() => (
                  <form id="formServico" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                    <InputText
                      idname="xnome"
                      defaultValue={nomeDefaultValue}
                      autoFocus={true}
                      customVariant="outlined-small"
                      shrink={false}
                      disabled={this.state.ajaxing}
                      errorMessage={errorsMap('nome')}
                      label="Nome do serviço"
                      inputProps={{
                        maxLength: 255
                      }}
                      inputRef={this.inputNome}
                    />

                    <CategoriaServicoInputDialogSelect
                      marginTop={true}
                      customVariant="outlined-small"
                      shrink={false}
                      disabled={this.state.ajaxing}
                      errorMessage={errorsMap('categoria')}
                      defaultValue={this.props.categoria ? this.props.categoria : categoria}
                      functionsMap={this.categoriaFunctionsMap}
                    />

                    <InputText
                      marginTop={true}
                      idname="xdescricao"
                      customVariant="outlined-small"
                      shrink={true}
                      defaultValue={descricao}
                      disabled={this.state.ajaxing}
                      errorMessage={errorsMap('descricao')}
                      label="Descrição do serviço"
                      multiline={true}
                      rowsMax={20}
                      rows={2}
                      maxLength="2000"
                      inputRef={this.inputDescricao}
                    />

                    {getSessaoPainel().negocio.carregado &&
                      ControleAgendamentoOnlineEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleAgendamentoOnline.id && (
                        <React.Fragment>
                          <InputSelect
                            idname="xcontroleAtendimentoOnline"
                            disabled={this.state.ajaxing}
                            style={{ minWidth: 200 }}
                            marginTop={true}
                            label={'Agendamento Online'}
                            helpMessage={this.state.controleAgendamentoOnline.descricaoExplicacaoServico}
                            customVariant="outlined-small"
                            value={this.state.controleAgendamentoOnline.id}
                            onChange={(event) => {
                              const id = event.target.value
                              this.setState({ controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(id) })
                            }}
                          >
                            {ControleAgendamentoOnlineEnum.values().map((controle) => (
                              <MenuItem key={controle.id} value={controle.id}>
                                {controle.descricaoOpcao}
                              </MenuItem>
                            ))}
                          </InputSelect>
                          <InputSelect
                            idname="xtipoExibicaoPreco"
                            disabled={this.state.ajaxing}
                            style={{ minWidth: 200 }}
                            marginTop={true}
                            label={'Exibição do Preço'}
                            helpMessage={this.state.tipoExibicaoPreco.descricaoExplicacao}
                            customVariant="outlined-small"
                            value={this.state.tipoExibicaoPreco.id}
                            onChange={(event) => {
                              const id = event.target.value
                              this.setState({ tipoExibicaoPreco: TipoExibicaoPrecoEnum.getById(id) })
                            }}
                          >
                            {TipoExibicaoPrecoEnum.values().map((tipo) => (
                              <MenuItem key={tipo.id} value={tipo.id}>
                                {tipo.descricao}
                              </MenuItem>
                            ))}
                          </InputSelect>
                          <InputSelect
                            idname="xnivelParalelo"
                            disabled={this.state.ajaxing}
                            style={{ minWidth: 200 }}
                            marginTop={true}
                            label={'Nível Paralelo'}
                            helpMessage={this.state.nivelParalelo.descricaoExplicacao}
                            customVariant="outlined-small"
                            value={this.state.nivelParalelo.id}
                            onChange={(event) => {
                              const id = event.target.value
                              this.setState({ nivelParalelo: NivelParaleloEnum.getById(id) })
                            }}
                          >
                            {NivelParaleloEnum.values().map((nivelParalelo) => (
                              <MenuItem key={nivelParalelo.id} value={nivelParalelo.id}>
                                {nivelParalelo.descricao}
                              </MenuItem>
                            ))}
                          </InputSelect>
                        </React.Fragment>
                      )}

                    <InputSelect
                      value={tipoConfiguracaoComissao.id}
                      customVariant="outlined-small"
                      shrink={false}
                      marginTop={true}
                      label="Tipo de Comissão"
                      disabled={this.state.ajaxing}
                      onChange={(event) => {
                        this.setState({ tipoConfiguracaoComissao: TipoConfiguracaoComissaoEnum.getById(parseInt(event.target.value, 10)) })
                      }}
                      errorMessage={errorsMap('tipoConfiguracaoComissao')}
                    >
                      {TipoConfiguracaoComissaoEnum.values().map((tipoConfiguracao) => (
                        <MenuItem key={tipoConfiguracao.id} value={tipoConfiguracao.id}>
                          {tipoConfiguracao.descricaoParaServico}
                        </MenuItem>
                      ))}
                    </InputSelect>

                    {comissaoInputContent}

                    {/*	<DuracaoInputSelect
													marginTop={true}
													clearableLabel="Nenhum"
													id="xtempoextra"
													name="xtempoextra"
													defaultValue={tempoExtra}
													inputComponent={InputNumberFormat}
													disabled={this.state.ajaxing}
													errorMessage={errorsMap("tempoExtra")}
													label="Tempo Extra (em minutos)"
													helpMessage="Tempo extra reservado após finalizar este serviço"
													inputRef={this.inputTempoExtra} />
											*/}

                    <SectionSeparator icon={<SettingsIcon />} title="Preço e Duração" />

                    <InputSelect
                      fullWidth={false}
                      style={{ marginTop: -4, marginBottom: 6 }}
                      customVariant="naked"
                      nakedLeftPadding={false}
                      value={this.state.tipoVariacao.id}
                      onChange={this.onChangeQuantidadeVariacao}
                    >
                      {TipoVariacaoEnum.values().map((tipo) => (
                        <MenuItem key={tipo.id} value={tipo.id}>
                          {tipo.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>

                    {this.state.tipoVariacao.id === TipoVariacaoEnum.SEM_VARIACAO.id && (
                      <div>
                        <DuracaoInputSelect
                          marginTop={true}
                          customVariant="outlined-small"
                          shrink={false}
                          clearableLabel="Nenhuma"
                          idname="xduracao"
                          defaultValue={this.state.variacoes[0].duracao}
                          inputComponent={InputNumberFormat}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('variacao[0].duracao')}
                          label="Duração"
                          functionsMap={this.state.variacoes[0].inputDuracao}
                        />

                        <InputText
                          marginTop={true}
                          customVariant="outlined-small"
                          shrink={false}
                          idname="xpreco"
                          defaultValue={this.state.variacoes[0].preco}
                          inputComponent={InputMoneyFormat}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('variacao[0].preco')}
                          label="Preço (R$)"
                          inputProps={{
                            minValue: 0,
                            maxValue: 999999
                          }}
                          inputRef={this.state.variacoes[0].inputPreco}
                        />

                        <InputText
                          marginTop={true}
                          customVariant="outlined-small"
                          shrink={false}
                          idname="xvalorDespesa"
                          defaultValue={this.state.variacoes[0].valorDespesa}
                          inputComponent={InputMoneyFormat}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('variacao[0].valorDespesa')}
                          label="Valor de Custos (R$)"
                          endAdornment={iconAjudaValorCustos}
                          inputProps={{
                            minValue: 0,
                            maxValue: 999999
                          }}
                          inputRef={this.state.variacoes[0].inputValorDespesa}
                        />

                        <InputText
                          marginTop={true}
                          customVariant="outlined-small"
                          shrink={false}
                          idname="xvalornaocomissionavel"
                          defaultValue={this.state.variacoes[0].valorNaoComissionavel}
                          inputComponent={InputMoneyFormat}
                          disabled={this.state.ajaxing}
                          endAdornment={iconAjudaValorNaoComissionavel}
                          errorMessage={errorsMap('variacao[0].valorNaoComissionavel')}
                          label="Valor Não Comissionável (R$)"
                          inputProps={{
                            minValue: 0,
                            maxValue: 999999
                          }}
                          inputRef={this.state.variacoes[0].inputValorNaoComissionavel}
                        />
                      </div>
                    )}

                    {this.state.tipoVariacao.id === TipoVariacaoEnum.COM_VARIACAO.id && (
                      <div>
                        {this.state.variacoes.map((variacao, index) => (
                          <div key={variacao.key} style={{ marginTop: index === 0 ? 30 : 48, position: 'relative', paddingLeft: 14 }}>
                            <div className={classes.indicadorVariacao}></div>

                            <Grid container>
                              <Grid item xs>
                                <InputText
                                  idname={'xnome' + index}
                                  customVariant="outlined-small"
                                  shrink={false}
                                  defaultValue={variacao.nome}
                                  disabled={this.state.ajaxing}
                                  errorMessage={errorsMap('variacao[' + index + '].nome')}
                                  label={'Nome da variação ' + (index + 1)}
                                  inputProps={{
                                    maxLength: 300
                                  }}
                                  inputRef={variacao.inputNome}
                                />
                              </Grid>
                              {this.state.variacoes.length > 2 && (
                                <Grid item>
                                  <IconButton style={{ marginTop: 2, padding: 8, marginRight: -4 }} onClick={() => this.removerVariacao(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>

                            <InputText
                              marginTop={true}
                              idname={'xdescricaoVariacao' + index}
                              customVariant="outlined-small"
                              shrink={true}
                              defaultValue={variacao.descricao}
                              disabled={this.state.ajaxing}
                              errorMessage={errorsMap('variacao[' + index + '].descricao')}
                              label={'Descrição da variação ' + (index + 1)}
                              multiline={true}
                              rowsMax={20}
                              rows={2}
                              maxLength="2000"
                              inputRef={variacao.inputDescricao}
                            />

                            <DuracaoInputSelect
                              marginTop={true}
                              clearableLabel="Nenhuma"
                              idname={'xduracao' + index}
                              customVariant="outlined-small"
                              shrink={false}
                              defaultValue={variacao.duracao}
                              inputComponent={InputNumberFormat}
                              disabled={this.state.ajaxing}
                              errorMessage={errorsMap('variacao[' + index + '].duracao')}
                              label="Duração"
                              functionsMap={variacao.inputDuracao}
                            />

                            <InputText
                              marginTop={true}
                              idname={'xpreco' + index}
                              customVariant="outlined-small"
                              shrink={false}
                              defaultValue={variacao.preco}
                              inputComponent={InputMoneyFormat}
                              disabled={this.state.ajaxing}
                              errorMessage={errorsMap('variacao[' + index + '].preco')}
                              label="Preço (R$)"
                              inputProps={{
                                minValue: 0,
                                maxValue: 999999
                              }}
                              inputRef={variacao.inputPreco}
                            />

                            <InputText
                              marginTop={true}
                              customVariant="outlined-small"
                              shrink={false}
                              idname={'xvalorDespesa' + index}
                              defaultValue={variacao.valorDespesa}
                              inputComponent={InputMoneyFormat}
                              disabled={this.state.ajaxing}
                              errorMessage={errorsMap('variacao[' + index + '].valorDespesa')}
                              label="Valor de Custos (R$)"
                              endAdornment={iconAjudaValorCustos}
                              inputProps={{
                                minValue: 0,
                                maxValue: 999999
                              }}
                              inputRef={variacao.inputValorDespesa}
                            />

                            <InputText
                              marginTop={true}
                              idname={'xvalornaocomissionavel' + index}
                              customVariant="outlined-small"
                              shrink={false}
                              defaultValue={variacao.valorNaoComissionavel}
                              inputComponent={InputMoneyFormat}
                              disabled={this.state.ajaxing}
                              endAdornment={iconAjudaValorNaoComissionavel}
                              errorMessage={errorsMap('variacao[' + index + '].valorNaoComissionavel')}
                              label="Valor Não Comissionável (R$)"
                              inputProps={{
                                minValue: 0,
                                maxValue: 999999
                              }}
                              inputRef={variacao.inputValorNaoComissionavel}
                            />
                          </div>
                        ))}
                        <div style={{ marginTop: 12, textAlign: 'right' }}>
                          <Button color="secondary" disabled={this.state.ajaxing} onClick={this.adicionarVariacoes}>
                            Adicionar mais variações
                          </Button>
                        </div>
                      </div>
                    )}

                    <FormUtil.HiddenSubmitButton />
                  </form>
                )}
              </ContentWithPreload>
            )}

            {etapa === 2 && (
              <React.Fragment>
                {this.state.paginaCarregada && this.state.lista.length > 0 && (
                  <div className={classes.paperContent} style={{ paddingBottom: 28 }}>
                    <Typography variant="h6">{this.state.servico.nome}</Typography>

                    <Typography variant="body2">Selecione os colaboradores que realizam este serviço.</Typography>
                  </div>
                )}
                <InterfaceDefinicaoComissoes
                  entidade={'colaborador'}
                  acao="selecionarEntidade"
                  scrollElement={dialogContentProps.scrollContainerRef.current}
                  servico={this.state.servico}
                  saveFunction={this.salvarColaboradores}
                  onChange={(props) => {
                    const newState = {}
                    if (props.errorsMap !== undefined) newState.errorsMap = props.errorsMap
                    if (props.scrollToIndex !== undefined) newState.scrollToIndex = props.scrollToIndex
                    if (props.ajaxing !== undefined) newState.ajaxing = props.ajaxing
                    if (props.lista !== undefined) newState.lista = props.lista
                    if (props.paginaCarregada !== undefined) newState.paginaCarregada = props.paginaCarregada
                    this.setState(newState)
                  }}
                  errorsMap={this.state.errorsMap}
                  scrollToIndex={this.state.scrollToIndex}
                  ajaxing={this.state.ajaxing}
                  lista={this.state.lista}
                  paginaCarregada={this.state.paginaCarregada}
                  onSaved={() => {
                    EventsManager.pub('ManipulacaoServico', { servico: this.state.servico })
                    if (!this.props.idServico && (this.props.abrirServicoDepoisCriacao === undefined || this.props.abrirServicoDepoisCriacao)) {
                      openBackableDialog(ServicoDialogPage, {
                        parent: this,
                        shouldCloseParent: true,
                        idServico: this.state.servico.id
                      })
                    } else {
                      this.props.handleCloseDialog()
                    }
                  }}
                  functionsMap={this.interfaceDefinicaoComissoesFunctionsMap}
                />
              </React.Fragment>
            )}

            {this.state.paginaCarregada && (
              <div className={classes.containerFooterButtons}>
                <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                  Cancelar
                </Button>
                <Button
                  disabled={this.state.ajaxing}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.salvar()
                  }}
                >
                  {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

ServicoDadosBasicosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServicoDadosBasicosDialogPage)
