import { Component } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import IndicadoresChurnsSalao99DialogPage from 'pages/admin/painel/relatorio/IndicadoresChurnsSalao99DialogPage'
import IndicadoresFinanceirosGeralDialogPage from 'pages/admin/painel/relatorio/IndicadoresFinanceirosGeralDialogPage'
import IndicadoresFinanceirosSalao99DialogPage from 'pages/admin/painel/relatorio/IndicadoresFinanceirosSalao99DialogPage'
import IndicadoresPlanosSalao99DialogPage from 'pages/admin/painel/relatorio/IndicadoresPlanosSalao99DialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import PanelPage from 'support/components/panel/PanelPage'

const styles = (theme) => ({})

class RelatoriosPanelPage extends Component {
  handleIndicadoresFinanceirosGeral = () => {
    openBackableDialog(IndicadoresFinanceirosGeralDialogPage, {
      parent: this
    })
  }

  handleIndicadoresFinanceirosSalao99 = () => {
    openBackableDialog(IndicadoresFinanceirosSalao99DialogPage, {
      parent: this
    })
  }

  handleChurns = () => {
    openBackableDialog(IndicadoresChurnsSalao99DialogPage, {
      parent: this
    })
  }

  handlePlanos = () => {
    openBackableDialog(IndicadoresPlanosSalao99DialogPage, {
      parent: this
    })
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} title="Relatórios">
        <List disablePadding component="nav" style={{ marginTop: 0, cursor: 'pointer' }}>
          <ListItem disableGutters onClick={() => this.handleIndicadoresFinanceirosGeral()}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Informações Financeiras</b>} secondary="Informações financeiras gerais." />
          </ListItem>
          <ListItem disableGutters onClick={() => this.handleIndicadoresFinanceirosSalao99()}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Indicadores Enterprise</b>} secondary="Indicadores de desempenho do produto Enterprise." />
          </ListItem>
          {/*
					<ListItem disableGutters onClick={() => this.handleChurns()}>
						<ListItemIcon>
							<TrendingUpIcon />
						</ListItemIcon>
						<ListItemText primary={<b>Churn</b>} secondary="Detalhamento por quantidade meses de churns Salão 99 Enterprise." />
					</ListItem>
					*/}
          <ListItem disableGutters onClick={() => this.handlePlanos()}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Pagamento de Planos</b>} secondary="Detalhamento de pagamento de planos por mês." />
          </ListItem>
        </List>
      </PanelPage>
    )
  }
}

RelatoriosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RelatoriosPanelPage)
