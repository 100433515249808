import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import { StatusPagamentoEnum } from 'support/domain/mercadopago/MercadoPagoEnum'

export enum DevicesTypeEnum {
  PAX = 'PAX',
  GERTEC = 'GERTEC',
  PRO2 = 'PRO2',
  SMART = 'SMART'
}

export enum DevicesOperationModeEnum {
  STANDALONE = 'STANDALONE',
  PDV = 'PDV'
}

type FormaPagamento = {
  tipo: {
    id: number
  }
}

type FormaPagamentoEnumType = {
  MERCADO_PAGO: { id: number; nome: string; precoMensalPadrao: number; precoAnualPadrao: number }
}

const PAGAMENTO_ENUM = FormaPagamentoTipoEnum as unknown as FormaPagamentoEnumType

export const MercadoPagoUtils = {
  isMercadoPago: (formaPagamento: FormaPagamento) => {
    return formaPagamento?.tipo?.id === PAGAMENTO_ENUM.MERCADO_PAGO.id
  }
}

export const isPendingPayment = (status: StatusPagamentoEnum) => {
  const list = [StatusPagamentoEnum.ABERTO, StatusPagamentoEnum.PENDENTE]
  return list.includes(status)
}
