import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import InputData from 'support/components/input/InputData'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class DespesaPeriodoPersonalizadoDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap()
    }

    this.dataInicialFunctionsMap = {}
    this.dataFinalFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.aplicarFiltro()
  }

  aplicarFiltro = () => {
    this.setState({ errorsMap: FormUtil.createErrorsMap() })

    const errorsMap = {}

    const dataInicial = this.dataInicialFunctionsMap.getDataAsDate()
    const dataFinal = this.dataFinalFunctionsMap.getDataAsDate()

    if (dataInicial === null) {
      errorsMap['dataInicial'] = 'A data inicial deve ser preenchida'
    }

    if (dataFinal === null) {
      errorsMap['dataFinal'] = 'A data final deve ser preenchida'
    }

    if (Object.keys(errorsMap).length > 0) {
      this.setState({ errorsMap: FormUtil.createErrorsMap(errorsMap) })
      FormUtil.focusFirstElementWithError('formPesquisaPersonalizada')
    } else {
      this.props.setDatas({
        dataPesquisaInicial: moment(dataInicial),
        dataPesquisaFinal: moment(dataFinal)
      })
      this.props.handleCloseDialog()
    }
  }

  render() {
    const { errorsMap } = this.state

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogTitle>Escolher Período</DialogTitle>
        <DialogContent>
          <form id="formPesquisaPersonalizada" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <Grid container direction="column">
              <Grid item>
                <InputData
                  functionsMap={this.dataInicialFunctionsMap}
                  keyboard={true}
                  fullWidth={false}
                  customVariant="outlined-small"
                  shrink={false}
                  id="xdata"
                  name="xdata"
                  label="De"
                  defaultValue={this.props.dataInicial}
                  errorMessage={errorsMap('dataInicial')}
                />
              </Grid>
              <Grid item>
                <InputData
                  functionsMap={this.dataFinalFunctionsMap}
                  keyboard={true}
                  marginTop={true}
                  fullWidth={false}
                  customVariant="outlined-small"
                  shrink={false}
                  id="xdata"
                  name="xdata"
                  label="Até"
                  defaultValue={this.props.dataFinal}
                  errorMessage={errorsMap('dataFinal')}
                />
              </Grid>
            </Grid>

            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Voltar
          </Button>
          <Button color="secondary" onClick={this.aplicarFiltro}>
            PESQUISAR
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

DespesaPeriodoPersonalizadoDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DespesaPeriodoPersonalizadoDialog)
