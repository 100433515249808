import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import Repeticao from 'support/components/repeticao/Repeticao'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons
})

class BonificacaoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      title: 'Bonificação',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      colaborador: this.props.colaborador,
      paginaCarregada: false
    }

    this.inputValorFunctionsMap = {}
    this.inputValor = React.createRef()
    this.inputObservacao = React.createRef()
    this.repeticaoFunctionsMap = {}
    this.dataFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.repeticaoFunctionsMap.exibirSeNecessarioOpcoesPersistencia((persistOptions) => {
      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      const dados = this.getDadosBonificacao()
      dados.repeticao = { ...dados.repeticao, ...persistOptions }

      postAPI({
        url: 'erp/colaboradores.persistirBonificacao',
        data: dados,
        requerAutorizacao: true,
        onSuccess: (response) => {
          EventsManager.pub('ManipulacaoColaborador')
          this.props.handleCloseDialog()
        },
        onError: (response) => {
          this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
          focusFirstElementWithError('formBonificacao')
        }
      })
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idBonificacao) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })

      getAPI({
        url: 'erp/colaboradores.buscarBonificacaoPorId',
        params: {
          id: this.props.idBonificacao
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState((state) => ({
            bonificacao: response.data,
            data: converterDataIntParaMoment(response.data.data)
          }))
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  getDadosBonificacao = () => {
    const dados = {}
    if (this.props.idBonificacao) {
      dados.id = this.props.idBonificacao
    }

    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.valor = FormUtil.convertStringToNumber(this.inputValor.current.value)
    dados.observacao = this.inputObservacao.current.value
    dados.repeticao = this.repeticaoFunctionsMap.getValues()
    dados.idColaborador = this.props.idColaborador

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, ajaxing, data } = this.state
    const { repeticao, valor, observacao } = this.state.bonificacao ? this.state.bonificacao : {}

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formBonificacao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                disabled={ajaxing}
                idname="xvalor"
                defaultValue={valor}
                errorMessage={errorsMap('valor')}
                label="Valor (R$)"
                customVariant="outlined-small"
                shrink={false}
                inputComponent={InputMoneyFormat}
                inputRef={this.inputValor}
                functionsMap={this.inputValorFunctionsMap}
              />

              <InputData
                disabled={ajaxing}
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                functionsMap={this.dataFunctionsMap}
                keyboard={true}
                id="xdata"
                name="xdata"
                errorMessage={errorsMap('data')}
                onChange={(data) => {
                  let dataAsMoment = null
                  if (data) {
                    dataAsMoment = moment(data)
                  }
                  this.setState({ data: dataAsMoment })
                }}
                defaultValue={data}
                label={'Data para pagamento'}
              />

              <InputText
                id="xobservacao"
                name="xobservacao"
                customVariant="outlined-small"
                shrink={false}
                multiline={true}
                marginTop={true}
                errorMessage={errorsMap('observacao')}
                defaultValue={observacao}
                label="Observação"
                inputProps={{
                  maxLength: 2000
                }}
                inputRef={this.inputObservacao}
              />

              <Repeticao
                showLabelIcons={false}
                title="Repetir Bonificação"
                prefixoEntidade="da"
                nomeEntidade="Bonificação"
                tituloAlteracaoIndividual="Alterar apenas esta bonificação"
                descricaoAlteracaoIndividual="As alterações realizadas nesta bonificação não serão replicadas para as próximas bonificações da repetição."
                tituloAlteracaoLote="Replicar alterações para as próximas bonificações"
                descricaoAlteracaoLote="As alterações desta bonificação serão aplicadas também para todas as próximas bonificações da replicação."
                defaultValue={repeticao}
                customVariant={'outlined-small'}
                dataInicial={data}
                disabled={ajaxing}
                errorsMap={errorsMap}
                functionsMap={this.repeticaoFunctionsMap}
                hasEntityChange={() => {
                  const { bonificacao } = this.state
                  const novaBonificacao = this.getDadosBonificacao()

                  if (
                    this.props.idBonificacao &&
                    bonificacao.data === novaBonificacao.data &&
                    parseFloat(bonificacao.valor) === parseFloat(novaBonificacao.valor) &&
                    bonificacao.observacao === novaBonificacao.observacoes
                  ) {
                    return false
                  } else {
                    return true
                  }
                }}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

BonificacaoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BonificacaoDialogPage)
