import React, { Component } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import NfCanceladaIcon from 'support/components/icon/NfCancelada'
import NfErroIcon from 'support/components/icon/NfErro'
import NfGeradaIcon from 'support/components/icon/NfGerada'
import NfPendenteIcon from 'support/components/icon/NfPendente'
import NfProcessandoIcon from 'support/components/icon/NfProcessando'
import StatusApresentacaoNfEnum from 'support/domain/venda/StatusApresentacaoNfEnum'

class Icon extends Component {
  render() {
    let { nf, hasTooltip, style, ...others } = this.props

    if (!style) {
      style = {}
    }

    let IconComponent = null
    let descricaoStatus = ''

    if (!nf) {
      IconComponent = NfPendenteIcon
      style.opacity = 0.3
      descricaoStatus = 'NF não emitida'
    } else {
      descricaoStatus = StatusApresentacaoNfEnum.getById(nf.statusApresentacao.id).descricao
      if (nf.statusApresentacao.id === StatusApresentacaoNfEnum.EMITINDO.id || nf.statusApresentacao.id === StatusApresentacaoNfEnum.CANCELANDO.id) {
        IconComponent = NfProcessandoIcon
      } else if (nf.statusApresentacao.id === StatusApresentacaoNfEnum.EMITIDA.id) {
        IconComponent = NfGeradaIcon
      } else if (nf.statusApresentacao.id === StatusApresentacaoNfEnum.COM_ERRO.id) {
        IconComponent = NfErroIcon
      } else if (nf.statusApresentacao.id === StatusApresentacaoNfEnum.CANCELADA.id) {
        IconComponent = NfCanceladaIcon
      }
    }

    if (IconComponent) {
      const icon = <IconComponent style={style} {...others} />
      if (hasTooltip && descricaoStatus) {
        return <Tooltip title={descricaoStatus}>{icon}</Tooltip>
      } else {
        return icon
      }
    } else {
      return null
    }
  }
}

export default Icon
