import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import ComissoesPersonalizadasDialogPage from 'pages/erp/painel/servico/ComissoesPersonalizadasDialogPage'
import InterfaceDefinicaoComissoes from 'pages/erp/painel/servico/InterfaceDefinicaoComissoes'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class TabColaboradores extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      scrollToIndex: -1,
      ajaxing: false,
      lista: [],
      paginaCarregada: false
    }
    this.interfaceDefinicaoComissoesFunctionsMap = {}
    this.tabSaveSnackBarFM = {}
    EventsManager.pub('TabSaveSnackBar', { open: false })
  }

  habilitarOpcaoSalvar = () => {
    EventsManager.pub('TabSaveSnackBar', {
      open: true,
      functionsMap: this.tabSaveSnackBarFM,
      saveFunction: this.interfaceDefinicaoComissoesFunctionsMap.salvar,
      cancelFunction: () => {
        this.interfaceDefinicaoComissoesFunctionsMap.load()
      }
    })
  }

  salvar = (props) => {
    if (!props) {
      props = {}
    }
    if (this.tabSaveSnackBarFM.isOpen && this.tabSaveSnackBarFM.isOpen() && this.tabSaveSnackBarFM.save) {
      this.tabSaveSnackBarFM.save({
        onSuccessCallback: props.onSuccessCallback
      })
    } else {
      if (props.onSuccessCallback) {
        props.onSuccessCallback()
      }
    }
  }

  render() {
    const { paginaCarregada, ajaxing } = this.state
    const podePersistirColaborador = verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])

    let texto = (
      <span>
        Colaboradores que realizam este serviço.
        <small
          style={{ opacity: ajaxing ? 0.3 : 0.6, cursor: ajaxing ? 'auto' : 'pointer', display: 'block', padding: 8, margin: -8 }}
          onClick={() => {
            if (ajaxing) {
              return
            }
            if (podePersistirColaborador) {
              this.salvar({
                onSuccessCallback: () => {
                  openBackableDialog(ComissoesPersonalizadasDialogPage, {
                    entidade: 'colaborador',
                    servico: this.props.servico
                  })
                }
              })
            } else {
              openBackableDialog(AccessDeniedDialog)
            }
          }}
        >
          <b>
            <u>Clique aqui</u>
          </b>{' '}
          para personalizar individualmente os valores das comissões.
        </small>
      </span>
    )

    if (!podePersistirColaborador) {
      texto = (
        <span>
          {texto}.<br />
          <span style={{ fontSize: 14, color: '#999' }}>Você não possui permissão para alterar estes dados.</span>
        </span>
      )
    }

    return (
      <TabContainer padding={false}>
        {paginaCarregada && this.state.lista.length > 0 && <TabContainerPersistHeader addTabContainerPadding={true} text={texto} />}

        <InterfaceDefinicaoComissoes
          entidade="colaborador"
          acao="selecionarEntidade"
          scrollElement={this.props.scrollElement}
          servico={this.props.servico}
          saveFunction={this.salvar}
          onChange={(props) => {
            const newState = {}
            if (props.errorsMap !== undefined) newState.errorsMap = props.errorsMap
            if (props.scrollToIndex !== undefined) newState.scrollToIndex = props.scrollToIndex
            if (props.ajaxing !== undefined) newState.ajaxing = props.ajaxing
            if (props.lista !== undefined) newState.lista = props.lista
            if (props.paginaCarregada !== undefined) newState.paginaCarregada = props.paginaCarregada
            this.setState(newState)
          }}
          errorsMap={this.state.errorsMap}
          scrollToIndex={this.state.scrollToIndex}
          ajaxing={this.state.ajaxing}
          lista={this.state.lista}
          paginaCarregada={this.state.paginaCarregada}
          onDataChange={this.habilitarOpcaoSalvar}
          functionsMap={this.interfaceDefinicaoComissoesFunctionsMap}
        />
      </TabContainer>
    )
  }
}

TabColaboradores.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabColaboradores)
