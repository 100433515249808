import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import InputSelect from 'support/components/input/InputSelect'
import CaixaService from 'support/domain/caixa/CaixaService'
import { converterMomentParaDataInt } from 'support/util/DateConverter'

const styles = (theme) => ({
  nenhumCaixaDisponivelContent: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main
  }
})

class InputCaixa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      utilizar: this.props.utilizarDefaultValue || this.props.utilizacaoObrigatoria ? true : false,
      idCaixa: this.props.caixaDefaultValue ? this.props.caixaDefaultValue.id : null,
      caixas: [],
      data: null
    }
  }

  componentDidMount() {
    const { functionsMap } = this.props
    if (functionsMap) {
      functionsMap.setData = (dataAsMoment) => {
        this.setData(dataAsMoment, true)
      }
      functionsMap.getCaixa = () => {
        const dados = {
          caixas: this.state.caixas
        }

        let utilizar = this.state.utilizar
        if (this.isDisponivel() === false) {
          utilizar = false
        }
        dados.utilizar = utilizar

        if (this.state.utilizar && this.isDisponivel()) {
          for (let caixa of this.state.caixas) {
            if (caixa.id === this.state.idCaixa) {
              dados.idCaixa = this.state.idCaixa
            }
          }
        }
        return dados
      }
    }
    this.setData(this.props.data, false)
  }

  setData = (data, alterarCaixa) => {
    this.props.errorsMap('caixa', 'remove')

    const stateDataAsDataInt = converterMomentParaDataInt(this.state.data)
    const newDataAsDataInt = converterMomentParaDataInt(data)

    if (stateDataAsDataInt !== newDataAsDataInt) {
      const caixas = []
      let idCaixaPreSelecionado = this.state.idCaixa
      if (newDataAsDataInt) {
        let caixaGeralSelecionado = false

        for (let caixa of this.props.caixas) {
          if (newDataAsDataInt === caixa.dataInicial) {
            caixas.push(caixa)
            if (!caixaGeralSelecionado && (alterarCaixa || !idCaixaPreSelecionado)) {
              if (!caixa.colaborador || !caixa.colaborador.id) {
                idCaixaPreSelecionado = caixa.id
                caixaGeralSelecionado = true
              } else {
                idCaixaPreSelecionado = caixa.id
              }
            }
          }
        }
      }
      this.setState({ data: data, caixas: caixas, idCaixa: idCaixaPreSelecionado })
    }
  }

  isDisponivel = () => {
    const { disponivel } = this.props
    if (CaixaService.isHabilitado() && (disponivel === undefined || disponivel === null || disponivel === true)) {
      return true
    }
    return false
  }

  render() {
    const { utilizar, idCaixa, caixas } = this.state
    const { classes, customVariant, shrink, marginTop, disabled, errorsMap, utilizacaoObrigatoria, labelUtilizacaoCaixa, descricaoData } = this.props

    let inputMarginTop = marginTop
    if (!utilizacaoObrigatoria) {
      inputMarginTop = true
    }

    let nenhumCaixaDisponivelContent = null
    if (!this.state.data) {
      nenhumCaixaDisponivelContent = 'Selecione a ' + descricaoData + ' para conseguir utilizar o caixa'
    } else if (!caixas || caixas.length === 0) {
      nenhumCaixaDisponivelContent = 'Nenhum caixa aberto foi encontrado para a data ' + this.state.data.format('DD/MM/YYYY')
    }

    if (nenhumCaixaDisponivelContent) {
      nenhumCaixaDisponivelContent = (
        <Typography variant="body2" className={classes.nenhumCaixaDisponivelContent}>
          {nenhumCaixaDisponivelContent}
        </Typography>
      )
    }

    return (
      <div style={{ display: this.isDisponivel() ? 'block' : 'none' }}>
        {!utilizacaoObrigatoria && (
          <FormControlLabel
            style={{ marginTop: marginTop ? 24 : 0 }}
            control={
              <Checkbox
                color="primary"
                disabled={disabled}
                checked={utilizar}
                onChange={(event) => {
                  const checked = event.target.checked
                  this.setState({ utilizar: checked })
                }}
              />
            }
            label={<Typography variant="body2">{labelUtilizacaoCaixa}</Typography>}
          />
        )}

        {utilizar && (
          <React.Fragment>
            {caixas.length > 0 && (
              <InputSelect
                id="xcaixa"
                name="xcaixa"
                defaultValue={caixas?.[0]?.id}
                customVariant={customVariant}
                shrink={shrink}
                disabled={disabled}
                value={idCaixa}
                marginTop={inputMarginTop}
                label="Caixa selecionado"
                errorMessage={errorsMap('caixa')}
                onChange={(event) => {
                  this.setState({ idCaixa: parseInt(event.target.value, 10) })
                }}
              >
                {caixas.map((caixa) => (
                  <MenuItem data-testid='caixa' key={caixa.id} value={caixa.id}>
                    {CaixaService.getNome(caixa)}
                  </MenuItem>
                ))}
              </InputSelect>
            )}
            {nenhumCaixaDisponivelContent}
          </React.Fragment>
        )}
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const errorsMapCaixas = this.props.errorsMap('caixas')
    if (errorsMapCaixas) {
      this.props.errorsMap('caixas', 'remove')
      openBackableDialog(MessageDialog, {
        text: errorsMapCaixas
      })
    }
  }
}

InputCaixa.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputCaixa)
