import React, { Component } from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import FileExportDownloadDialog from 'support/components/dialog/preconstructed/FileExportDownloadDialog'

const styles = (theme) => ({})

class DataExporter extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.mounted = true
  }

  componentDidMount() {
    if (this.props.functionsMap !== undefined) {
      this.props.functionsMap['export'] = this.export
    }
  }

  export = (props) => {
    this.lastProps = props
    this.setState({ exportarMenuAnchorEl: props.targetAnchorEl })
  }

  download = (props) => {
    openBackableDialog(FileExportDownloadDialog, {
      format: props.format,
      accessTokenContext: this.lastProps.accessTokenContext,
      filename: this.lastProps.filename,
      downloadCall: this.lastProps.downloadCall
    })
  }

  openExportarMenu = (target) => {
    this.setState({ exportarMenuAnchorEl: target })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  render() {
    const { exportarMenuAnchorEl } = this.state

    return (
      <React.Fragment>
        <Menu anchorEl={exportarMenuAnchorEl} open={Boolean(exportarMenuAnchorEl)} onClose={() => this.closeMenu('exportarMenuAnchorEl')}>
          {this.props.formats.map((format, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  this.download({ format: format })
                  this.closeMenu('exportarMenuAnchorEl')
                }}
              >
                {format.description}
              </MenuItem>
            )
          })}
        </Menu>
      </React.Fragment>
    )
  }

  componentWillUnmount = () => {
    this.mounted = false
  }
}

DataExporter.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DataExporter)
