import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  DINHEIRO: { id: 1, descricao: 'Dinheiro', descricaoResumida: 'DI' },
  CARTAO_CREDITO: { id: 2, descricao: 'Cartão de Crédito', descricaoResumida: 'CC' },
  CARTAO_DEBITO: { id: 3, descricao: 'Cartão de Débito', descricaoResumida: 'CD' },
  CHEQUE: { id: 4, descricao: 'Cheque', descricaoResumida: 'CH' },
  TRANSFERENCIA_BANCARIA: { id: 5, descricao: 'Transferência Bancária', descricaoResumida: 'TB' },
  DEPOSITO: { id: 6, descricao: 'Depósito', descricaoResumida: 'DE' },
  CUSTOMIZADA: { id: 7, descricao: 'Customizada', descricaoResumida: 'CT' },
  CREDITO: { id: 8, descricao: 'Crédito', descricaoResumida: 'CR' },
  PIX: { id: 9, descricao: 'Pix', descricaoResumida: 'PX' },
  MERCADO_PAGO: { id: 10, descricao: 'Mercado Pago', descricaoResumida: 'MP' },
})

export default enumObject
