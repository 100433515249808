import { isWidthDown } from '@material-ui/core/withWidth'

function isDesktopWidth(width) {
  if (isWidthDown('xs', width)) {
    return false
  } else {
    return true
  }
}

function setBoxShadow(style, value) {
  style.boxShadow = value
  style.WebkitBoxShadow = value
  style.MozBoxShadow = value
  style.msBoxShadow = value
}
function createBoxShadow(value) {
  const style = {}
  setBoxShadow(style, value)
  return style
}

const functionsObject = {
  isDesktopWidth: isDesktopWidth,
  setBoxShadow: setBoxShadow,
  createBoxShadow: createBoxShadow
}

export default functionsObject

/*function dpiChecker(){
	return {
		"&::after":{
			content: "\"LOW\""
		},
		"@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)":{
			"&::after":{
				content: "\"HIGH\""
			},
		}
	}
}*/
