import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import NativeApp from 'support/components/nativeapp/NativeApp'

const POLITICA_PRIVACIDADE_URL = `${process.env.REACT_APP_PRIVACY_POLICE_ADDRESS}`
const TERMOS_USO_URL = `${process.env.REACT_APP_WEB_ADDRESS}/termos_uso.html`
const TERMOS_LICENCIAMENTO_URL = `${process.env.REACT_APP_WEB_ADDRESS}/termos_licenciamento.html`

const TermosEPrivacidade = {
  getLinkProps: function () {
    const politicaPrivacidadeLinkProps = {}
    const termosUsoLinkProps = {}
    const termosLicenciamentoLinkProps = {}

    if (DeviceIdentifier.isNativeApp()) {
      politicaPrivacidadeLinkProps.onClick = () => {
        NativeApp.executeNativeMethod({
          method: 'openLink',
          url: POLITICA_PRIVACIDADE_URL
        })
      }
      termosUsoLinkProps.onClick = () => {
        NativeApp.executeNativeMethod({
          method: 'openLink',
          url: TERMOS_USO_URL
        })
      }
      termosLicenciamentoLinkProps.onClick = () => {
        NativeApp.executeNativeMethod({
          method: 'openLink',
          url: TERMOS_LICENCIAMENTO_URL
        })
      }
    } else {
      politicaPrivacidadeLinkProps.href = POLITICA_PRIVACIDADE_URL
      politicaPrivacidadeLinkProps.target = 'politicaPrivacidade'
      termosUsoLinkProps.href = TERMOS_USO_URL
      termosUsoLinkProps.target = 'termosUso'
      termosLicenciamentoLinkProps.href = TERMOS_LICENCIAMENTO_URL
      termosLicenciamentoLinkProps.target = 'termosLicenciamento'
    }

    return {
      politicaPrivacidade: politicaPrivacidadeLinkProps,
      termosUso: termosUsoLinkProps,
      termosLicenciamento: termosLicenciamentoLinkProps
    }
  }
}

export default TermosEPrivacidade
