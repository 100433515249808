import React, { Component } from 'react'

import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { TagNew } from '../TagNew'

const styles = (theme) => ({
  primary: {
    fontSize: 15,
    fontWeight: 500,
    //color:"#fff",
    color: theme.palette.text.primary,
    '.selected &': {
      color: theme.palette.secondary.main
    }
  },
  secondary: {},
  textWithExtraMarginRight: {
    marginRight: 16
  },
  menuContainerTextRetracted: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
})

class DrawerMenuItemText extends Component {
  render() {
    const { classes, extraMarginRight, isMenuRetracted, isNew, primary } = this.props

    const primaryClasses = [classes.primary]
    const secondaryClasses = [classes.secondary]
    if (extraMarginRight) {
      primaryClasses.push(classes.textWithExtraMarginRight)
      secondaryClasses.push(classes.textWithExtraMarginRight)
    }
    if (isMenuRetracted) {
      primaryClasses.push(classes.menuContainerTextRetracted)
      secondaryClasses.push(classes.menuContainerTextRetracted)
    }

    let primaryContent = isNew ? <TagNew>{primary}</TagNew> : primary

    return (
      <ListItemText
        primary={primaryContent}
        secondary={this.props.secondary}
        classes={{
          primary: classNames(primaryClasses),
          secondary: classNames(secondaryClasses)
        }}
      />
    )
  }
}

DrawerMenuItemText.propTypes = {
  isMenuRetracted: PropTypes.bool,
  isNew: PropTypes.bool,
  extraMarginRight: PropTypes.bool,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.shape({
    textWithExtraMarginRight: PropTypes.string,
    menuContainerTextRetracted: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.string
  })
}

export default withStyles(styles)(DrawerMenuItemText)
