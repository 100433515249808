import React, { Component } from 'react'

import SyncIcon from '@material-ui/icons/Sync'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'

class Icon extends Component {
  render() {
    let { style, ...others } = this.props

    if (!style) {
      style = {}
    } else {
      style = Object.assign({}, style)
    }

    style.position = 'relative'
    style.width = 28
    style.height = 24
    style.display = 'flex'

    return (
      <div style={style} {...others}>
        <ReceiptLongIcon style={{ position: 'absolute' }} />
        <div style={{ width: 18, height: 18, backgroundColor: 'white', position: 'absolute', display: 'flex', right: -2, top: -7, borderRadius: 12 }}>
          <SyncIcon style={{ fontSize: 18, color: '#ffb633' }} />
        </div>
      </div>
    )
  }
}

export default Icon
