import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
//import WifiOffIcon from '@material-ui/icons/WifiOff';
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const DEFAULT_ALIGN = 'center'

const styles = (theme) => ({
  root: {
    textAlign: DEFAULT_ALIGN
  },
  rootPadding: {
    padding: theme.spacing(4)
  },
  rootSmallPadding: {
    padding: theme.spacing(2)
  },
  icon: {
    fontSize: 80,
    color: '#eee',
    marginBottom: 10
  },
  button: {
    marginTop: 8
  }
})

class NotLoadedContent extends Component {
  render() {
    let { classes, padding, show, align } = this.props

    const containerStyle = {}
    if (align) {
      containerStyle.textAlign = align
    } else {
      containerStyle.textAlign = DEFAULT_ALIGN
    }

    const containerClasses = [classes.root]
    if (padding === true) {
      containerClasses.push(classes.rootPadding)
    } else if (padding === 'small') {
      containerClasses.push(classes.rootSmallPadding)
    }

    let text = (
      <span>
        Não foi possível <span style={{ whiteSpace: 'nowrap' }}>carregar os dados</span>
      </span>
    )

    if (this.props.text !== undefined) {
      text = this.props.text
    } else {
      if (this.props.messageErrorCode === 403) {
        text = 'Você não tem permissão para carregar estas informações'
      }
    }

    let textButton = 'TENTAR NOVAMENTE'
    if (this.props.textButton !== undefined) {
      textButton = this.props.textButton
    }

    let content = null

    if (!this.props.template || this.props.template === 'big') {
      content = (
        <div className={classNames(containerClasses)} style={containerStyle}>
          {/*this.props.messageErrorCode !== -1 && <WifiOffIcon  className={classes.icon} />*/}
          <Typography variant="body2">{text}</Typography>
          <Button className={classes.button} variant="outlined" color="default" type="button" onClick={() => this.props.loadFunction()}>
            {textButton}
          </Button>
        </div>
      )
    } else if (this.props.template === 'small') {
      content = (
        <div className={classNames(containerClasses)} style={containerStyle}>
          <Typography variant="body2">{text}</Typography>
          <Button size="small" className={classes.button} variant="outlined" color="default" type="button" onClick={() => this.props.loadFunction()}>
            {textButton}
          </Button>
        </div>
      )
    }

    return show === true && content
  }
}

NotLoadedContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotLoadedContent)
