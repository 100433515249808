import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import CustomScrollbars from 'support/components/scroll/CustomScrollbars'

const CHART_HEIGHT_DEAFULT = 400

const styles = (theme) => ({})

function XAxisTick(props) {
  const { x, y, payload } = props
  return (
    <g transform={'translate(' + x + ',' + y + ')'}>
      <text fontSize="12" x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontWeight="bold">
        {payload.value}
      </text>
    </g>
  )
}

function YAxisTick(props) {
  const { x, y, payload } = props
  return (
    <g transform={'translate(' + x + ',' + y + ')'}>
      <text fontSize="12" x={0} y={0} dy={4} dx={-2} textAnchor="end" fill="#666" fontWeight="bold">
        {payload.value}
      </text>
    </g>
  )
}

let INSTANCE_ID = 1

class ChartLine extends Component {
  constructor(props) {
    super(props)
    this.scrollFunctionsMap = {}
    this.state = {}
    this.instaceId = INSTANCE_ID++
  }

  componentDidMount() {
    window.setTimeout(() => {
      const scrollRef = this.scrollFunctionsMap.getScrollRef()
      if (scrollRef) {
        scrollRef.scrollLeft(99999)
      }
    }, 100)
  }

  getColorId = (id) => {
    return 'colorId-i' + this.instaceId + '-id' + id
  }

  render() {
    const { data } = this.props

    let chartHeight = CHART_HEIGHT_DEAFULT
    if (this.props.height) {
      chartHeight = this.props.height
    }

    return (
      <div>
        <div style={{ textAlign: 'center', paddingBottom: 16, paddingLeft: 8, paddingRight: 9 }}>
          {data.items.map((item, index) => {
            const cor = item.color
            return (
              <div key={index} style={{ display: 'inline-block', marginLeft: 4, marginRight: 4, marginBottom: 8 }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <div style={{ backgroundColor: cor, width: 14, height: 14, borderRadius: 10 }}></div>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <Typography variant="body2">{item.name}</Typography>
                  </Grid>
                </Grid>
              </div>
            )
          })}
        </div>
        <div style={{ width: '100%', height: chartHeight, position: 'relative' }}>
          <CustomScrollbars
            functionsMap={this.scrollFunctionsMap}
            hideTracksWhenNotNeeded={false}
            applyWillChangeOnScrollContainer={true}
            verticalScrollDisabled={true}
            color="rgba(0,0,0,0.19)"
          >
            <div style={{ width: '100%', position: 'relative', minWidth: 640, height: '100%' }}>
              <ResponsiveContainer>
                <AreaChart data={data.data} margin={{ top: 10, right: 6, left: 0, bottom: 0 }}>
                  <defs>
                    {data.items.map((item, index) => {
                      const corFill = lighten(item.color, 0.5)
                      return (
                        <linearGradient key={index} id={this.getColorId(index)} x1="0" y1="0" x2="0" y2="1">
                          <stop offset="30%" stopColor={corFill} stopOpacity={0.3} />
                          <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                        </linearGradient>
                      )
                    })}
                  </defs>
                  <CartesianGrid style={{ opacity: 0.37 }} />
                  <XAxis dataKey={data.xAxis.dataKey} height={data.xAxis.height} tick={<XAxisTick />} interval={0} />
                  <YAxis axisLine={false} tick={<YAxisTick />} />
                  <Tooltip formatter={data.tooltip.formatter} />
                  {data.items.map((item, index) => {
                    const corStroke = item.color
                    const hasBackground = item.hasBackground === undefined || item.hasBackground === null || item.hasBackground ? true : false

                    return (
                      <Area
                        key={index}
                        name={item.shortName}
                        dataKey={item.dataKey}
                        type="monotone"
                        dot={{ stroke: corStroke, fill: '#ffffff', fillOpacity: 1, strokeWidth: 2, r: 4 }}
                        stroke={corStroke}
                        strokeWidth={2}
                        fill={'url(#' + this.getColorId(index) + ')'}
                        fillOpacity={hasBackground ? 1 : 0}
                      />
                    )
                  })}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    )
  }
}

ChartLine.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ChartLine)
