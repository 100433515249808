import React, { Component } from 'react'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'

const styles = (theme) => ({
  paperPageFooterContainer: {
    marginTop: 32,
    [theme.breakpoints.up(381)]: {
      marginBottom: -16
    }
  }
})

let staticPodeExibirDialogInstalado = true
let staticDeferredPrompt = null

class WebAppInstalationButton extends Component {
  constructor(props) {
    super(props)

    this.beforeinstallpromptFunction = (e) => {
      e.preventDefault()
      let deferredPrompt = e

      if (this.isShow()) {
        staticDeferredPrompt = deferredPrompt
        this.setState({
          show: true
        })
      } else {
        this.setState({
          show: false
        })
      }
    }

    this.appinstalledFunction = () => {
      this.setState({
        show: false
      })

      if (staticPodeExibirDialogInstalado) {
        staticPodeExibirDialogInstalado = false
        openBackableDialog(MessageDialog, {
          title: 'Instalação',
          text: <span>O aplicativo Salão99 Enterprise já está sendo instalado em seu dispositivo.</span>
        })
      }
    }

    this.state = {
      show: this.isShow(),
      deferredPrompt: null
    }

    window.addEventListener('beforeinstallprompt', this.beforeinstallpromptFunction)
    window.addEventListener('appinstalled', this.appinstalledFunction)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.beforeinstallpromptFunction)
    window.removeEventListener('appinstalled', this.appinstalledFunction)
    staticPodeExibirDialogInstalado = true
  }

  isInStandaloneMode = () => {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://')
  }

  isShow = () => {
    if (!this.isInStandaloneMode() && isMobile && (isAndroid || isIOS) && !DeviceIdentifier.isNativeApp()) {
      return true
    } else {
      return false
    }
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }

    if (isAndroid) {
      openBackableDialog(MessageDialog, {
        title: 'Instalar Aplicativo',
        text: <span>Acesse agora mesmo a Google Play e instale o aplicativo oficial do Salão99 Enterprise.</span>,
        actionButtons: [
          {
            label: 'Voltar'
          },
          {
            label: 'Acessar Play Store',
            href: 'https://play.google.com/store/apps/details?id=br.com.salao99.enterprise.erp'
          }
        ]
      })
    } else if (isIOS) {
      openBackableDialog(MessageDialog, {
        title: 'Instalar Aplicativo',
        text: <span>Acesse agora mesmo a App Store e instale o aplicativo oficial do Salão99 Enterprise.</span>,
        actionButtons: [
          {
            label: 'Voltar'
          },
          {
            label: 'Acessar App Store',
            href: 'https://apps.apple.com/br/app/sal%C3%A3o99-enterprise/id1518718411'
          }
        ]
      })
    }
  }

  openPrompt = () => {
    if (staticDeferredPrompt) {
      //this.containerRef.current.style.display = 'none';
      staticDeferredPrompt.prompt()
      staticDeferredPrompt.userChoice.then((choiceResult) => {
        /*if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the A2HS prompt');
				} else {
					console.log('User dismissed the A2HS prompt');
				}*/
        staticDeferredPrompt = null
      })
    } else {
      openBackableDialog(MessageDialog, {
        title: 'Instalação',
        text: <span>O aplicativo já está instalado em seu dispositivo.</span>
      })
      this.setState({
        show: false
      })
    }
  }

  render() {
    const { classes, variant } = this.props
    const { show } = this.state

    let content = null
    if (variant === 'paperPageFooter') {
      content = (
        <Grid container alignItems="center" justify="center" className={classes.paperPageFooterContainer}>
          <Grid item>
            <GetAppIcon style={{ display: 'block' }} />
          </Grid>
          <Grid item style={{ marginLeft: 8 }}>
            <Typography variant="body2">Instalar App Salão99 Enterprise</Typography>
          </Grid>
        </Grid>
      )
    } else if (variant === 'custom') {
      content = this.props.customContent
    }

    return (
      <div style={{ display: show ? 'block' : 'none', cursor: 'pointer' }} onClick={this.handleClick}>
        {content}
      </div>
    )
  }
}

WebAppInstalationButton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(WebAppInstalationButton)
