import React, { Component } from 'react'

import { Typography, MenuItem, Menu, Grid, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Modal, ModalBody, ModalContent, Text, Box } from '@grupoboticario/flora-react'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PermissoesUsuarioDialogPage from 'pages/admin/painel/usuario/PermissoesUsuarioDialogPage'
import SubstituicaoEmailDialogPage from 'pages/admin/painel/usuario/SubstituicaoEmailDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import InputText from 'support/components/input/InputText'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PermissoesAcesso from 'support/domain/admin/usuario/PermissoesAcesso'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import { createErrorsMap } from 'support/util/FormUtil'

const styles = (theme) => ({
  colunaVerificado: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122
  },
  colunaID: {
    maxWidth: 60,
    minWidth: 60
  },
  colunaQtColaboradores: {
    maxWidth: 100,
    minWidth: 100
  },
  colunaNomeCliente: {
    ...theme.text.limitedLines({ maxLines: 3 })
  },
  containerFooterButtons: theme.form.containerFooterButtons(),
  textModalConfirm: {
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

class UsuariosPanelPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false,
      lista: [],
      showModal: false,
      showModalConfirm: false,
      inputNome: ""
    }

    this.loaderFunctionsMap = {}
    this.inputFiltro = React.createRef()
    this.eventsManager = EventsManager.new()
  }

  getRequestParameters = () => {
    return {
      filtro: this.inputFiltro.current ? this.inputFiltro.current.value : null
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    if (size === 'small') {
      content.nome = item.nome ? item.nome : 'Nome: -'
      content.email = item.email ? item.email : 'E-mail: -'
    } else {
      content.nome = item.nome ? item.nome : '-'
      content.email = item.email ? item.email : '-'
    }

    content.id = item.id
    content.qtColaboradores = item.colaboradores.length

    if (item.verificado) {
      content.verificado = <span style={{ color: 'green' }}>Sim</span>
    } else {
      content.verificado = <span style={{ color: 'red' }}>Não</span>
    }

    return content
  }

  openMenu = (item, event) => {
    event.stopPropagation()
    this.setState({
      menuAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      },
      itemMenu: item
    })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  handlerSubirChat = (event, item) => {
    event.stopPropagation()
    openBackableDialog(EntityConfirmationDialog, {
      parent: this,
      text: (
        <span>
          Você tem certeza que deseja subir o chat do <b>{item.email}</b>?
        </span>
      ),
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'admin/usuario.subirChat',
        data: {
          idUsuario: item.id
        },
        contextoUsuario: 'admin',
        onSuccess: (response, dialogConfirmationInstance) => { }
      }
    })
  }

  handlerDefinirAdminS99 = (usuario) => {
    openBackableDialog(PermissoesUsuarioDialogPage, {
      usuario: usuario
    })
  }

  handlerAbrirSubstituicaoEmail = (usuario) => {
    openBackableDialog(SubstituicaoEmailDialogPage, {
      usuario: usuario
    })
  }

  handleAbrirModal = () => this.setState((prevState) => ({ ...prevState, showModal: !prevState.showModal }))

  handleAbrirModalConfirmacao = () => this.setState((prevState) => ({ ...prevState, showModalConfirm: !prevState.showModalConfirm }))

  handleAnonimizarUsuario = () => {
    postAPI({
      url: 'admin/usuario.anonimizar',
      data: {
        email: this.state.itemMenu.email,
        novoNome: this.state.inputNome
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.loaderFunctionsMap.load()
        EventsManager.pub('SnackBarSuccess', { open: true, message: 'Usuário anonimizado com sucesso!' })
        this.handleAbrirModalConfirmacao()
      },
      onError: (response) => {
        this.setState(() => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        this.handleAbrirModalConfirmacao()
      }
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoUsuario', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  componentDidUpdate(_, prevState) {
    if (prevState.lista.length < this.state.lista.length) {
      const filteredLista = this.state.lista.filter(item => !item.email.includes('@salao99'))
      this.setState(({ lista: filteredLista }))
    }
  }

  render() {
    const { classes, ...others } = this.props
    const { menuAnchorPosition } = this.state

    return (
      <PanelPage {...others} title="Usuários">
        <Grid container alignItems="center" justify="center">
          <Grid item xs>
            <InputText
              customVariant="naked"
              nakedLeftPadding={false}
              placeholder="E-mail ou Nome ou ID do usuário"
              onChange={(event) => {
                this.loaderFunctionsMap.load()
              }}
              inputRef={this.inputFiltro}
            />
          </Grid>
        </Grid>
        <br />
        <VirtualizedResponsiveTable
          showBackgroundDividers={true}
          itemsPerRequest={30}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="admin"
          endpoint="admin/usuario.buscarUsuarios"
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          onClickRow={(item, event) => {
            this.openMenu(item, event)
          }}
          largeRenderProps={{
            columns: [
              { label: 'ID', className: classes.colunaID },
              { label: 'Nome', props: { xs: true } },
              { label: 'E-mail', props: { xs: true } },
              { label: 'Qt. Negócios', className: classes.colunaQtColaboradores },
              { label: 'Verificado', className: classes.colunaVerificado }
            ],
            itemRenderer: (item, index) => {

              const content = this.getRenderContent('large', item)

              return {
                itemData: [content.id, content.nome, content.email, content.qtColaboradores, content.verificado]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 100,
            showFirstItemBorderTop: false,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Typography variant="body2">ID: {content.id}</Typography>
                    <Typography variant="body2" className={classes.colunaNomeCliente}>
                      {content.nome}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.email}
                    </Typography>
                    <Typography variant="body2">Verificado: {content.verificado}</Typography>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum usuário foi encontrado'
          }}
        />

        <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={() => this.closeMenu('menuAnchorPosition')}>
          <MenuItem
            onClick={(event) => {
              this.handlerSubirChat(event, this.state.itemMenu)
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Subir Chat
          </MenuItem>
          {verificarAutorizacao([PermissoesAcesso.PODE_TROCAR_EMAIL_USUARIO]) && (
            <MenuItem
              disabled={this.state.itemMenu && !this.state.itemMenu.email}
              onClick={(event) => {
                this.handlerAbrirSubstituicaoEmail(this.state.itemMenu)
                this.closeMenu('menuAnchorPosition')
              }}
            >
              Trocar E-mail
            </MenuItem>
          )}
          <MenuItem
            disabled={this.state.itemMenu && !this.state.itemMenu}
            onClick={(event) => {
              this.handlerDefinirAdminS99(this.state.itemMenu)
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Admin S99
          </MenuItem>
          <MenuItem
            disabled={this.state.itemMenu && !this.state.itemMenu}
            onClick={() => {
              this.handleAbrirModal()
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Anonimizar
          </MenuItem>
        </Menu>
        <Modal open={this.state.showModal}>
          <ModalContent size="small">
            <ModalBody>
              <Box>
                <Text>Digite o nome que você deseja anonimar:</Text>
                <InputText
                  idname="nome"
                  customVariant="outlined"
                  marginTop={true}
                  shrink={false}
                  label="Nome"
                  inputRef={this.inputNome}
                  onChange={(event) => {
                    this.setState((prevState) => ({ ...prevState, inputNome: event.target.value }))
                  }}
                />
                <div className={classes.containerFooterButtons}>
                  <Button onClick={this.handleAbrirModal}>Cancelar</Button>
                  <Button onClick={() => {
                    this.handleAbrirModalConfirmacao()
                    this.handleAbrirModal()
                  }
                  } variant="contained" color="secondary">Confirmar</Button>
                </div>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal open={this.state.showModalConfirm}>
          <ModalContent size='small'>
            <ModalBody>
              <Text style={{ textAlign: 'center' }} className={classes.textModalConfirm}>Você tem certeza que deseja anonimizar? <br />Todos os dados serão <b>APAGADOS!</b></Text>
              <div className={classes.containerFooterButtons}>
                <Button onClick={() => {
                  this.handleAbrirModalConfirmacao()
                }}>Cancelar</Button>
                <Button onClick={this.handleAnonimizarUsuario} variant="contained" color="secondary">Confirmar</Button>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </PanelPage>
    )
  }
}

UsuariosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UsuariosPanelPage)
