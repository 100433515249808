import { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EventIcon from '@material-ui/icons/Event'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import { getAPI } from 'support/components/api/PortalApiClient'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import NegocioService from 'support/domain/negocio/NegocioService'
import PeriodoService from 'support/domain/periodo/PeriodoService'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

import styles from './styles'
import { gerarHorariosDisponiveisPorColaborador } from './utils'

const QUANTIDADE_MINIMA_DIAS_PRE_CARREGAMENTO_HORARIOS_LIVRES = 3
const ID_QUALQUER_COLABORADOR = 0

class ListagemHorariosLivres extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.componentMounted = true
    this.state = {
      data: moment().add(-1, 'days'),
      dataTermino: moment().add(this.props.negocio.configuracoesGerais.antecedenciaMaximaAgendamentoOnline.id, 'day'),
      ajaxing: true,
      buscaFinalizada: false,
      diasComHorariosDisponiveis: []
    }
  }

  buscarDisponibilidadeAgendamento = () => {
    this.state.data.add(1, 'days')

    if (converterMomentParaDataInt(this.state.data) > converterMomentParaDataInt(this.state.dataTermino)) {
      this.setState({
        ajaxing: false,
        buscaFinalizada: true
      })
      return
    }

    this.setState({
      ajaxing: true
    })

    getAPI({
      url: 'ptl/atendimentos.buscarDisponibilidadeHorariosParaAgendamento',
      params: {
        idWithHash: NegocioService.gerarNegocioIdWithHash(this.props.negocio),
        data: converterMomentParaDataInt(this.state.data),
        idsServicos: [this.props.servico.id]
      },
      onSuccess: (response) => {
        if (!this.componentMounted) {
          return
        }

        const colaboradores = response.data.colaboradores
        const servicos = response.data.servicos

        const mapColaboradoresPorId = {}
        for (let colaborador of colaboradores) {
          mapColaboradoresPorId[colaborador.id] = colaborador
        }

        const mapServicosPorId = {}
        for (let servico of servicos) {
          mapServicosPorId[servico.id] = servico
        }

        let servico = this.props.servico

        const colaboradoresDesteServico = []
        for (let colaboradorServico of servico.colaboradoresServicos) {
          const colaborador = mapColaboradoresPorId[colaboradorServico.colaborador.id]
          if (colaborador) {
            colaboradoresDesteServico.push(colaborador)
          }
        }

        const periodosHorariosServicosSelecionados = {
          paralelos: [],
          naoParalelos: []
        }

        let periodosHorariosIndisponiveis = periodosHorariosServicosSelecionados.naoParalelos.slice()
        if (servico.periodosHorariosIndisponiveis) {
          periodosHorariosIndisponiveis = PeriodoService.mesclarListaPeriodos(periodosHorariosIndisponiveis.concat(servico.periodosHorariosIndisponiveis))
        }

        let horariosDisponiveis = []
        if (this.props.colaborador.id === ID_QUALQUER_COLABORADOR) {
          for (let colaborador of colaboradoresDesteServico) {
            const horariosDisponiveisPorColaborador = gerarHorariosDisponiveisPorColaborador(
              periodosHorariosServicosSelecionados,
              periodosHorariosIndisponiveis,
              colaborador,
              this.props.negocio,
              this.props.variacao
            )
            for (let horarioDisponivelPorColaborador of horariosDisponiveisPorColaborador) {
              if (horariosDisponiveis.indexOf(horarioDisponivelPorColaborador) < 0) {
                horariosDisponiveis.push(horarioDisponivelPorColaborador)
              }
            }
          }

          horariosDisponiveis.sort((a, b) => {
            if (a < b) {
              return -1
            }
            if (a > b) {
              return 1
            }
            return 0
          })
        } else {
          horariosDisponiveis = gerarHorariosDisponiveisPorColaborador(
            periodosHorariosServicosSelecionados,
            periodosHorariosIndisponiveis,
            mapColaboradoresPorId[this.props.colaborador.id],
            this.props.negocio,
            this.props.variacao
          )
        }

        if (horariosDisponiveis && horariosDisponiveis.length > 0) {
          const diasComHorariosDisponiveis = this.state.diasComHorariosDisponiveis
          diasComHorariosDisponiveis.push({
            data: this.state.data.clone(),
            horarios: horariosDisponiveis
          })
          this.setState({
            diasComHorariosDisponiveis: diasComHorariosDisponiveis,
            ajaxing: false
          })

          if (diasComHorariosDisponiveis.length < QUANTIDADE_MINIMA_DIAS_PRE_CARREGAMENTO_HORARIOS_LIVRES) {
            window.setTimeout(() => {
              this.buscarDisponibilidadeAgendamento()
            }, 50)
          }
        } else {
          window.setTimeout(() => {
            this.buscarDisponibilidadeAgendamento()
          }, 50)
        }
      },
      requerAutorizacao: true,
      sendErroToGenericSnackbar: false,
      repeat: {
        stopFunction: () => {
          return !this.componentMounted
        }
      }
    })
  }

  selecionarHorario = (data, horario, duracao) => {
    EventsManager.pub('AlteracaoDataCalendario', { data: data, horario: horario, idItem: this.props.idItem, duracao: duracao })
  }

  componentDidMount() {
    this.buscarDisponibilidadeAgendamento()
  }

  render() {
    const { classes } = this.props
    const { ajaxing, buscaFinalizada, diasComHorariosDisponiveis } = this.state

    return (
      <div>
        {diasComHorariosDisponiveis &&
          diasComHorariosDisponiveis.map((diaComHorariosDisponiveis, index) => (
            <div style={{ marginBottom: 14 }} key={index}>
              <Typography variant="body2">
                <EventIcon style={{ fontSize: 20, verticalAlign: 'middle', marginRight: 6 }} />
                <b>{diaComHorariosDisponiveis.data.format('ddd, DD [de] MMMM')}</b>
              </Typography>
              <div style={{ marginLeft: -2, marginTop: 8 }}>
                {diaComHorariosDisponiveis.horarios.map((horario, index2) => (
                  <div
                    key={index2}
                    className={classes.containerHorarioDisponivel}
                    onClick={() => {
                      this.selecionarHorario(diaComHorariosDisponiveis.data, horario, this.props.variacao.duracao)
                    }}
                  >
                    {formatarHorarioInt(horario)}
                  </div>
                ))}
              </div>
            </div>
          ))}
        {ajaxing && (
          <Grid container alignItems="center" style={{ marginBottom: 16 }}>
            <Grid item style={{ marginRight: 10 }}>
              <CircularProgress size={18} thickness={5.5} style={{ verticalAlign: 'middle', marginLeft: 2 }} />
            </Grid>
            <Grid xs>
              <Typography variant="body2">
                <b>Procurando em {this.state.data.format('DD/MMM/YY')}</b>
              </Typography>
            </Grid>
          </Grid>
        )}
        {!buscaFinalizada && !ajaxing && (
          <Button
            style={{ marginLeft: -4 }}
            endIcon={<SearchIcon style={{ marginLeft: -3 }} />}
            onClick={() => {
              this.buscarDisponibilidadeAgendamento()
            }}
          >
            Buscar Mais
          </Button>
        )}
        {buscaFinalizada && !ajaxing && diasComHorariosDisponiveis.length === 0 && (
          <div className={classes.containerHorarioIndisponivel}>No momento, todos os horários estão ocupados</div>
        )}
      </div>
    )
  }

  componentWillUnmount() {
    this.componentMounted = false
    this.eventsManager.unsubscribe()
  }
}

export default withStyles(styles)(ListagemHorariosLivres)
