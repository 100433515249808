import React, { Component } from 'react'
import 'react-image-lightbox/style.css'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AlteracaoFotoDialog from 'pages/erp/painel/cliente/AlteracaoFotoDialog'
import UploadFotosDialog from 'pages/erp/painel/cliente/UploadFotosDialog'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog, openBackableLightbox } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LightboxFotoGaleria from 'support/components/lightbox/LightboxFotoGaleria'
import 'support/components/lightbox/style.css'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import ImageUtil from 'support/util/ImageUtil'

const IMAGE_GRID_HEIGHT = 240
const IMAGE_GRID_MARGIN = 12

const styles = (theme) => ({
  containerFoto: {
    height: IMAGE_GRID_HEIGHT,
    width: '100%',
    position: 'relative'
  },
  foto: {
    cursor: 'pointer',
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    '&:hover': {
      opacity: 0.85
    }
  },
  fotoInfo: {
    borderRadius: 6,
    padding: '100px 12px 12px 8px',
    opacity: 1,
    textShadow: '1px 1px 1px rgba(0,0,0,0.4)',
    color: 'white',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    textAlign: 'right',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);'
  },
  fotoInfoData: {
    display: 'inline-block',
    fontSize: '12px'
  },
  lightboxLargeTitle: {
    [theme.breakpoints.down(384)]: {
      display: 'none'
    }
  },
  lightboxSmallTitle: {
    [theme.breakpoints.up(384)]: {
      display: 'none'
    }
  }
})

class TabFotos extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      paginaCarregada: false,
      lista: [],
      listaComoLinhasFotos: []
    }
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoFotoGaleria', (props) => {
      this.loaderFunctionsMap.refresh()
    })
  }

  handlerOpenChooseDialog = () => {
    if (verificarAutorizacao([PA.PODE_PERSISTIR_FOTOS_CLIENTE])) {
      openBackableDialog(UploadFotosDialog, {
        cliente: this.props.cliente
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  openLightboxFotoGaleria = (fotos, index) => {
    const { classes } = this.props
    openBackableLightbox(LightboxFotoGaleria, {
      renderTitle: (foto) => {
        return (
          <React.Fragment>
            <Grid container alignItems="center" style={{ height: 50 }} className={classes.lightboxLargeTitle}>
              <Grid item>
                <DateRangeIcon fontSize="small" style={{ display: 'block' }} />
              </Grid>
              <Grid item style={{ marginLeft: 8 }}>
                <Typography variant="body1">{converterDataIntParaMoment(foto.data).format('DD/MMM/YYYY')}</Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ height: 50 }} className={classes.lightboxSmallTitle}>
              <Grid item>
                <Typography variant="body2">{converterDataIntParaMoment(foto.data).format('DD/MM/YY')}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )
      },
      alterarFotoFunction: (dialogInstance, foto) => {
        if (verificarAutorizacao([PA.PODE_PERSISTIR_FOTOS_CLIENTE])) {
          openBackableDialog(AlteracaoFotoDialog, {
            parent: dialogInstance,
            foto: fotos[index]
          })
        } else {
          openBackableDialog(AccessDeniedDialog)
        }
      },
      removerFotoFunction: (dialogInstance, foto) => {
        if (verificarAutorizacao([PA.PODE_PERSISTIR_FOTOS_CLIENTE])) {
          openBackableDialog(EntityRemovalDialog, {
            parent: dialogInstance,
            title: 'Remover Foto',
            text: <span>Você tem certeza que deseja remover esta foto? Essa operação não poderá ser desfeita.</span>,
            call: {
              method: 'post',
              url: 'erp/clientes.removerFotoGaleria',
              contextoUsuario: 'erp',
              data: {
                id: foto.id
              },
              onSuccess: (response, dialog) => {
                dialog.props.setShouldCloseParent(true)
                EventsManager.pub('ManipulacaoFotoGaleria')
              }
            }
          })
        } else {
          openBackableDialog(AccessDeniedDialog)
        }
      },
      mostrarOpcaoDownload: true,
      fotos: fotos,
      index: index
    })
  }

  createFotoElement = (linhaFotos, linha, coluna, size) => {
    const { listaComoLinhasFotos } = this.state
    const { classes } = this.props
    const fotoCliente = linhaFotos[coluna]

    if (fotoCliente) {
      const variacoesFoto = ImageUtil.extrairVariacoesImagem(fotoCliente.foto)
      const style = {}
      if (size === 'large') {
        if (coluna === 0) {
          style.right = IMAGE_GRID_MARGIN / 2
        } else if (coluna === 1) {
          style.left = IMAGE_GRID_MARGIN / 2
          style.right = style.left
        } else if (coluna === 2) {
          style.left = IMAGE_GRID_MARGIN / 2
        }
        if (linha === 0) {
          style.bottom = IMAGE_GRID_MARGIN / 2
        } else if (linha === listaComoLinhasFotos.length - 1) {
          style.top = IMAGE_GRID_MARGIN / 2
        } else {
          style.top = IMAGE_GRID_MARGIN / 2
          style.bottom = IMAGE_GRID_MARGIN / 2
        }
      } else {
        style.marginBottom = IMAGE_GRID_MARGIN
      }

      style.backgroundImage = 'url(' + variacoesFoto.get(1) + ')'
      return (
        <div className={classes.containerFoto} key={fotoCliente.id}>
          <div
            className={classes.foto}
            style={style}
            onClick={() => {
              const { lista } = this.state
              const index = fotoCliente._index
              this.openLightboxFotoGaleria(lista, index)
            }}
          >
            <div className={classes.fotoInfo}>
              <Typography variant="body2" className={classes.fotoInfoData}>
                {converterDataIntParaMoment(fotoCliente.data).format('DD/MMM/YY')}
              </Typography>
            </div>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  render() {
    const { classes } = this.props
    const { lista, listaComoLinhasFotos } = this.state

    return (
      <TabContainer>
        {this.state.paginaCarregada && (
          <TabContainerPersistHeader
            text={lista && lista.length > 0 ? 'Exibição da galeria de fotos' : 'Nenhuma foto foi adicionada.'}
            buttonsContent={
              <Button color="secondary" variant="text" style={{ marginRight: -8, marginLeft: -8 }} onClick={this.handlerOpenChooseDialog}>
                <AddIcon />
                <span style={{ marginLeft: 8, marginRight: 0 }}>ADICIONAR</span>
              </Button>
            }
          />
        )}

        <VirtualizedResponsiveTable
          scrollElement={this.props.scrollElement}
          showBackgroundDividers={false}
          showHeaderColumns={false}
          showRowBorders={false}
          loaderFunctionsMap={this.loaderFunctionsMap}
          columns={[
            { label: 'Foto1', horizontalPadding: 'none', props: { xs: true } },
            { label: 'Foto2', horizontalPadding: 'none', props: { xs: true } },
            { label: 'Foto3', horizontalPadding: 'none', props: { xs: true } }
          ]}
          contextoUsuario="erp"
          dynamicHeight={true}
          endpoint="erp/clientes.buscarFotos"
          getRequestParametersFunction={() => ({
            idCliente: this.props.cliente.id
          })}
          items={listaComoLinhasFotos}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const listaComoLinhasFotos = []
              let linhaAtual = null

              for (let i = 0; i < data.items.length; i++) {
                const fotoCliente = data.items[i]
                fotoCliente._index = i
                if (i % 3 === 0) {
                  linhaAtual = []
                  listaComoLinhasFotos.push(linhaAtual)
                }
                linhaAtual.push(fotoCliente)
              }

              this.setState({
                paginaCarregada: true,
                listaComoLinhasFotos: listaComoLinhasFotos,
                lista: data.items
              })
            }
          }}
          largeRenderProps={{
            columns: [
              { label: 'Foto1', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false },
              { label: 'Foto2', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false },
              { label: 'Foto3', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false }
            ],
            itemRenderer: (linhaFotos, index) => {
              const createFotoElement = (linha, coluna, size) => {
                const fotoCliente = linhaFotos[coluna]
                if (fotoCliente) {
                  const variacoesFoto = ImageUtil.extrairVariacoesImagem(fotoCliente.foto)
                  const style = {}
                  if (size === 'large') {
                    if (coluna === 0) {
                      style.right = IMAGE_GRID_MARGIN / 2
                    } else if (coluna === 1) {
                      style.left = IMAGE_GRID_MARGIN / 2
                      style.right = style.left
                    } else if (coluna === 2) {
                      style.left = IMAGE_GRID_MARGIN / 2
                    }
                    if (linha === 0) {
                      style.bottom = IMAGE_GRID_MARGIN / 2
                    } else if (linha === listaComoLinhasFotos.length - 1) {
                      style.top = IMAGE_GRID_MARGIN / 2
                    } else {
                      style.top = IMAGE_GRID_MARGIN / 2
                      style.bottom = IMAGE_GRID_MARGIN / 2
                    }
                  } else {
                    style.marginBottom = IMAGE_GRID_MARGIN
                  }

                  style.backgroundImage = 'url(' + variacoesFoto.get(1) + ')'
                  return (
                    <div className={classes.containerFoto}>
                      <div
                        className={classes.foto}
                        style={style}
                        onClick={() => {
                          const { lista } = this.state
                          const index = fotoCliente._index
                          this.openLightboxFotoGaleria(lista, index)
                        }}
                      >
                        <div className={classes.fotoInfo}>
                          <Typography variant="body2" className={classes.fotoInfoData}>
                            {converterDataIntParaMoment(fotoCliente.data).format('DD/MMM/YY')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return ''
                }
              }

              return {
                itemData: [createFotoElement(index, 0, 'large'), createFotoElement(index, 1, 'large'), createFotoElement(index, 2, 'large')]
              }
            }
          }}
          smallRenderProps={{
            horizontalPadding: 'none',
            itemRenderer: (linhaFotos, index) => {
              return {
                itemData: [
                  this.createFotoElement(linhaFotos, index, 0, 'small'),
                  this.createFotoElement(linhaFotos, index, 1, 'small'),
                  this.createFotoElement(linhaFotos, index, 2, 'small')
                ]
              }
            }
          }}
        />
      </TabContainer>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabFotos.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabFotos)
