import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import EventIcon from '@material-ui/icons/Event'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import PersonIcon from '@material-ui/icons/Person'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import ClienteInputDialogSelect from 'pages/erp/painel/cliente/input/ClienteInputDialogSelect'
import RedirecionadorFluxoPosCadastroVendaDialog from 'pages/erp/painel/cliente/RedirecionadorFluxoPosCadastroVendaDialog'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import DescontoInputSelect from 'pages/erp/painel/configuracoes/desconto/input/DescontoInputSelect'
import InputProduto from 'pages/erp/painel/inventario/input/InputProduto'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import ProductsIcon from 'support/components/icon/Products'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import DescontoDisponibilidadeEnum from 'support/domain/desconto/DescontoDisponibilidadeEnum'
import DescontoService from 'support/domain/desconto/DescontoService'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { formatarValorCalculo } from 'support/util/CalculoFormatter'
import { converterTudoParaMoment } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat, InputNumberFormat } from 'support/util/Masks'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import NumberUtil from 'support/util/NumberUtil'

const INPUT_CUSTOM_VARIANT = 'outlined-small'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class VendaProdutoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    let colaborador = props.colaboradorPreSelecionado

    if (colaborador && colaborador.vendeProdutos !== undefined && colaborador.vendeProdutos === false) {
      colaborador = null
    }

    if (!colaborador) {
      const colaboradorSessao = getSessaoPainel().colaborador
      if (colaboradorSessao && colaboradorSessao.vendeProdutos) {
        colaborador = colaboradorSessao
      }
    }

    this.state = {
      paginaCarregada: false,
      editavel: true,
      mensagemNaoEditavel: '',
      data: converterTudoParaMoment(props.data),
      colaborador: colaborador,
      cliente: props.cliente,
      preco: null,
      vendaProduto: null,
      title: 'Venda de Produto',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.clienteFunctionsMap = {}
    this.dataFunctionsMap = {}
    this.colaboradorFunctionsMap = {}
    this.produtoFunctionsMap = {}
    this.inputPreco = React.createRef()
    this.inputPrecoFunctionsMap = {}
    this.inputQuantidade = React.createRef()
    this.inputQuantidadeFunctionsMap = {}
    this.descontoFunctionsMap = {}
    this.descontoSelectFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/inventario.buscarDadosVendaPrePersistencia',
      params: {
        idVendaProduto: this.props.idVendaProduto
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const newState = {
          paginaCarregada: true,
          editavel: true
        }

        const vendaProduto = response.data.vendaProduto

        if (vendaProduto && vendaProduto.id) {
          newState.vendaProduto = vendaProduto
          newState.data = converterTudoParaMoment(vendaProduto.data)
          newState.cliente = vendaProduto.venda.cliente
          newState.colaborador = vendaProduto.venda.colaborador
          this.selecionarProduto(vendaProduto.produto)
          newState.preco = vendaProduto.venda.valorUnitario
          newState.quantidade = vendaProduto.venda.quantidade

          if (vendaProduto.venda.processada === true) {
            if (vendaProduto.venda.cancelado === true) {
              newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Esta venda está cancelada.'
            } else {
              newState.mensagemNaoEditavel =
                'Não é mais possível alterar esta venda porque a fatura #' + vendaProduto.venda.notaVenda.numero + ' já foi gerada. É possível apenas cancelar esta venda.'
            }
          } else if (vendaProduto.venda.excluido === true) {
            newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Este venda foi removida.'
          }

          if (newState.mensagemNaoEditavel) {
            newState.editavel = false
          }
        }

        newState.toolbarActions = {
          actions: [
            {
              label: 'Salvar',
              show: newState.editavel,
              handleAction: () => this.salvar()
            },
            {
              label: 'Voltar',
              show: !newState.editavel,
              handleAction: () => this.props.handleCloseDialog()
            }
          ]
        }

        this.setState(newState)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    let dados = {}

    dados.data = this.dataFunctionsMap.getDataAsInt()

    if (this.props.idVendaProduto) {
      dados.id = this.props.idVendaProduto
    }

    if (this.props.contexto === 'AdicionarItensFaturaExistente') {
      dados.nota = this.props.nota
    }

    dados.cliente = this.state.cliente

    dados.colaborador = null
    const colaboradorObject = this.colaboradorFunctionsMap.getObject()
    if (colaboradorObject && colaboradorObject.colaborador) {
      dados.colaborador = colaboradorObject.colaborador
    }

    const produtoObject = this.produtoFunctionsMap.getValue()

    if (produtoObject) {
      if (produtoObject.produto) {
        dados.produto = {
          id: produtoObject.produto.id
        }
      }
    }

    dados.quantidade = FormUtil.convertStringToNumber(this.inputQuantidade.current.value)
    dados.preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)
    dados = { ...dados, aplicacaoDesconto: this.descontoFunctionsMap.getValues() }
    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    if (!this.state.editavel) {
      openBackableDialog(MessageDialog, {
        title: 'Aviso',
        text: this.state.mensagemNaoEditavel
      })
      return
    }

    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = this.getDados()

    postAPI({
      url: 'erp/inventario.persistirVenda',
      data: dados,
      requerAutorizacao: true,
      onSuccess: (response) => {
        if (this.props.contexto !== 'AdicionarItensFaturaExistente') {
          EventsManager.pub('ManipulacaoVendaProduto', {
            action: this.props.idVendaProduto ? 'update' : 'create',
            vendaProduto: response.data
          })
          EventsManager.pub('ManipulacaoVenda', {
            action: this.props.idVendaProduto ? 'update' : 'create'
          })
        }

        if (this.props.contexto === 'CriacaoPainelCliente') {
          const dataParaNovoAtendimento = dados.data

          EventsManager.pub('AlterarDataHorarioParaNovoAtendimento', {
            data: dataParaNovoAtendimento
          })

          openBackableDialog(RedirecionadorFluxoPosCadastroVendaDialog, {
            parent: this,
            shouldCloseParent: true,
            textoConfirmacaoCadastro: 'Venda cadastrada com sucesso!',
            tipoVenda: TipoVendaEnum.VENDA_PRODUTO,
            venderMaisProps: {
              data: dataParaNovoAtendimento,
              colaboradorPreSelecionado: dados.colaborador,
              cliente: dados.cliente,
              contexto: 'CriacaoPainelCliente'
            },
            setFecharPanelCliente: this.props.setFecharPanelCliente
              ? this.props.setFecharPanelCliente
              : () => {
                  this.props.setShouldCloseParent(true)
                }
          })
        } else {
          if (this.props.parent) {
            this.props.setShouldCloseParent(true)
          }

          if (this.props.contexto === 'AdicionarItensFaturaExistente') {
            EventsManager.pub('RecarregarAtendimentos')
            EventsManager.pub('ManipulacaoVendas')
          }

          this.props.handleCloseDialog()
        }
      },
      onError: (response) => {
        if (response && response.data && response.data.errors) {
          let mensagemErro = null
          if (response.data.errors.erroNotaVenda) {
            mensagemErro = response.data.errors.erroNotaVenda
          }
          if (mensagemErro) {
            openBackableDialog(MessageDialog, {
              maxWidth: 400,
              title: 'Erro de Validação',
              text: mensagemErro
            })
          }
        }

        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formVendaProduto')
      }
    })
  }

  selecionarProduto = (produto) => {
    const newState = {
      produto: null,
      preco: null
    }
    if (produto) {
      newState.produto = produto
      newState.preco = produto.valorVendaExterna
    }
    this.setState(newState)
  }

  render() {
    const podeAlterarPrecoVenda = verificarAutorizacao([PA.PODE_ALTERAR_PRECO_VENDA])

    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { data, cliente, vendaProduto, editavel, ajaxing, errorsMap } = this.state

    const { venda } = vendaProduto ? vendaProduto : {}

    let colaboradorDefaultValue = null

    if (this.state.colaborador) {
      colaboradorDefaultValue = this.state.colaborador
    }

    const descontoDefaultValue = {}
    let produtoDefaultValue = undefined
    let precoDefaultValue = undefined
    const keyPorProduto = this.state.produto ? this.state.produto.id : ''

    if (vendaProduto && vendaProduto.id) {
      descontoDefaultValue.desconto = venda.desconto
      descontoDefaultValue.descricaoDesconto = venda.descricaoDesconto
      descontoDefaultValue.tipoCalculo = venda.tipoCalculoDesconto
      descontoDefaultValue.valor = venda.valorDesconto
    }

    produtoDefaultValue = { produto: this.state.produto }
    precoDefaultValue = this.state.preco

    return (
      <DialogPage {...dialogProps} title={this.state.title} ajaxing={ajaxing} align="center" contentMaxWidth={570} pageType="basicForm" toolbarActions={this.state.toolbarActions}>
        {this.state.mensagemNaoEditavel && (
          <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
            <Typography variant="body2">{this.state.mensagemNaoEditavel}</Typography>
          </FloatContent>
        )}

        <ContentWithPreload
          loadFunction={this.carregarMain}
          onComponentDidUpdate={() => {
            if (this.inputPrecoFunctionsMap.updateSuperHelpMessage && this.descontoSelectFunctionsMap.updateSuperHelpMessage) {
              this.inputPrecoFunctionsMap.updateSuperHelpMessage()
              this.descontoSelectFunctionsMap.updateSuperHelpMessage()
            }
          }}
        >
          {() => (
            <form id="formVendaProduto" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: -10 }}
                icon={<PersonIcon />}
                inputTexts={[
                  {
                    input: (
                      <ClienteInputDialogSelect
                        key={'cliente'}
                        disabled={ajaxing}
                        readOnly={!vendaProduto || !editavel ? true : false}
                        defaultValue={cliente}
                        functionsMap={this.clienteFunctionsMap}
                        label="Cliente"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('cliente')}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ cliente: value.cliente })
                          } else {
                            this.setState({ cliente: null })
                          }
                        }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<EventIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputData
                        disabled={ajaxing}
                        readOnly={!editavel}
                        functionsMap={this.dataFunctionsMap}
                        keyboard={true}
                        id="xdata"
                        name="xdata"
                        errorMessage={errorsMap('data')}
                        defaultValue={data}
                        onChange={(data) => {
                          this.setState({ data: converterTudoParaMoment(data) })
                        }}
                        label={'Data'}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<SupervisedUserCircleIcon />}
                inputTexts={[
                  {
                    input: (
                      <ColaboradorInputDialogSelect
                        context="vendaProduto"
                        disabled={ajaxing}
                        readOnly={!editavel}
                        defaultValue={colaboradorDefaultValue}
                        functionsMap={this.colaboradorFunctionsMap}
                        label="Profissional"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('colaborador')}
                        DialogSelectProps={{
                          preProcessItemFunction: (colaborador) => {
                            if (colaborador.vendeProdutos === true) {
                              return colaborador
                            }
                            return null
                          }
                        }}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ colaborador: value.colaborador })
                          } else {
                            this.setState({ colaborador: null })
                          }
                        }}
                        style={{ marginTop: 0 }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<ProductsIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputProduto
                        key={'produto'}
                        disabled={ajaxing}
                        readOnly={!editavel}
                        defaultValue={produtoDefaultValue}
                        functionsMap={this.produtoFunctionsMap}
                        errorMessage={errorsMap('produto')}
                        vendaExterna={true}
                        arquivado={false}
                        onChange={(value) => {
                          if (value) {
                            this.selecionarProduto(value.produto)
                          } else {
                            this.selecionarProduto(null)
                          }
                        }}
                        label="Produto"
                        idname="produto"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        style={{ marginTop: 0 }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<AttachMoneyIcon />}
                inputTexts={[
                  {
                    containerProps: { xs: 12, sm: 7 },
                    input: (
                      <InputText
                        disabled={!podeAlterarPrecoVenda || ajaxing}
                        readOnly={!editavel}
                        key={'preco:' + keyPorProduto}
                        idname="xpreco"
                        helpMessage={podeAlterarPrecoVenda ? null : 'Você não tem permissão para alterar o preço'}
                        defaultValue={precoDefaultValue}
                        errorMessage={errorsMap('preco')}
                        label="Preço (R$)"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        inputComponent={InputMoneyFormat}
                        inputRef={this.inputPreco}
                        functionsMap={this.inputPrecoFunctionsMap}
                        onChange={(event) => {
                          this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                        }}
                        superHelpMessage={() => {
                          const quantidade = FormUtil.convertStringToNumber(this.inputQuantidade.current.value)
                          let preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)
                          const descontoValues = this.descontoFunctionsMap.getValues()
                          let precoDescontoAplicado = 0
                          let textoLinha1 = null
                          let textoLinha2 = null

                          if (quantidade && preco) {
                            preco = NumberUtil.round(preco * quantidade)
                          }

                          if (!descontoValues.manual && descontoValues.desconto) {
                            precoDescontoAplicado = DescontoService.aplicarDesconto(preco, descontoValues.desconto.valor, descontoValues.desconto.tipoCalculoDesconto)
                          } else {
                            precoDescontoAplicado = DescontoService.aplicarDesconto(
                              preco,
                              descontoValues.valorDescontoPersonalizado,
                              descontoValues.tipoCalculoDescontoPersonalizado
                            )
                          }

                          if (quantidade) {
                            textoLinha1 = 'Quantidade: ' + quantidade + (quantidade <= 1 ? ' item' : ' itens')
                          }

                          if (precoDescontoAplicado !== undefined && precoDescontoAplicado !== null && precoDescontoAplicado >= 0) {
                            textoLinha2 = 'Preço final com desconto: ' + formatarValorMonetario(precoDescontoAplicado)
                          } else if (quantidade && preco) {
                            textoLinha2 = 'Preço final: ' + formatarValorMonetario(preco)
                          }

                          if (textoLinha1 || textoLinha2) {
                            return {
                              value: precoDescontoAplicado,
                              content: (
                                <span>
                                  {textoLinha1}
                                  <br />
                                  {textoLinha2}
                                </span>
                              )
                            }
                          }

                          return {
                            content: null
                          }
                        }}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 12, sm: 5 },
                    input: (
                      <InputText
                        id="xquantidade"
                        name="xquantidade"
                        shrink={false}
                        errorMessage={errorsMap('quantidade')}
                        label=""
                        customVariant={INPUT_CUSTOM_VARIANT}
                        startAdornment={<span style={{ opacity: 0.5, marginRight: 6 }}>Qtd: </span>}
                        inputComponent={InputNumberFormat}
                        inputRef={this.inputQuantidade}
                        functionsMap={this.inputQuantidadeFunctionsMap}
                        defaultValue={venda ? venda.quantidade : '1'}
                        onChange={(event) => {
                          this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                        }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<MoneyOffIcon />}
                inputTexts={[
                  {
                    input: (
                      <DescontoInputSelect
                        disabled={ajaxing}
                        readOnly={!editavel}
                        key={'desconto:' + keyPorProduto}
                        disponibilidades={[DescontoDisponibilidadeEnum.PRODUTO]}
                        onChange={(event) => {
                          this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          this.descontoSelectFunctionsMap.updateSuperHelpMessage()
                        }}
                        defaultValue={descontoDefaultValue}
                        selectProps={{
                          idname: 'xdesconto',
                          label: 'Desconto',
                          functionsMap: this.descontoSelectFunctionsMap,
                          superHelpMessage: () => {
                            let valorDescontoFormatado = null
                            const descontoValues = this.descontoFunctionsMap.getValues()
                            if (!descontoValues.manual && descontoValues.desconto) {
                              valorDescontoFormatado = formatarValorCalculo(descontoValues.desconto.tipoCalculoDesconto, descontoValues.desconto.valor)
                            }

                            if (valorDescontoFormatado) {
                              return {
                                value: valorDescontoFormatado,
                                content: 'Desconto: ' + valorDescontoFormatado
                              }
                            }
                            return {
                              value: null,
                              content: null
                            }
                          }
                        }}
                        manualInputProps={{
                          style: { marginTop: 14 },
                          idname: 'xdescontoValorManual',
                          label: 'Valor do Desconto',
                          disabled: ajaxing,
                          readOnly: !editavel,
                          errorMessage: errorsMap('valorDescontoPersonalizado'),
                          onChange: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          },
                          onKeyUp: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          },
                          onChangeTipoCalculo: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          }
                        }}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        functionsMap={this.descontoFunctionsMap}
                      />
                    )
                  }
                ]}
              />

              <FormUtil.HiddenSubmitButton />

              {this.state.paginaCarregada && editavel && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={ajaxing} onClick={this.props.handleCloseDialog}>
                    Voltar
                  </Button>
                  <Button disabled={ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

VendaProdutoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendaProdutoDialogPage)
