import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import TabMUI from '@material-ui/core/Tab'
import TabsMUI from '@material-ui/core/Tabs'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    position: 'relative',
    borderBottom: '1px solid #e0e0e0'
    /*marginLeft: theme.screen.margin*-1,
		marginRight: theme.screen.margin*-1,
		[theme.breakpoints.down('xs')]:{
			marginLeft: theme.screen.marginXs*-1,
			marginRight: theme.screen.marginXs*-1,
		},
		[theme.breakpoints.down(380)]:{
			marginLeft: theme.screen.margin380*-1,
			marginRight: theme.screen.margin380*-1,
		}*/
  },
  scrollButtons: {
    width: 36,
    /*[theme.breakpoints.down('xs')]:{
			width: theme.screen.marginXs,
		},
		[theme.breakpoints.down(380)]:{
			width: theme.screen.margin380,
		},*/
    position: 'absolute',
    height: '100%',
    zIndex: 1,
    background: 'white',
    opacity: 1,
    '&:first-child': {
      left: -11,
      background: 'linear-gradient(90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)'
    },
    '&:last-child': {
      right: -11,
      background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%)'
    },
    '&:not([aria-disabled=false])': {
      display: 'none'
    }
  },
  tabRoot: {
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 24,
    '&:first-child': {
      marginLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 13
    }
  }
})

class Tab extends Component {
  getTabLabel = (tab) => {
    if (isWidthDown('xs', this.props.width) && tab.labelXs) {
      return tab.labelXs
    } else {
      return tab.label
    }
  }

  render() {
    const { classes, children, onChange, tabs, tabsTextColor, ...others } = this.props

    return (
      <TabsMUI
        {...others}
        classes={{
          root: classes.root,
          scrollButtons: classes.scrollButtons
        }}
        onChange={(event, value) => {
          if (value !== this.props.value) {
            onChange(event, value)
          }
        }}
        indicatorColor="primary"
        textColor={tabsTextColor ? tabsTextColor : 'secondary'}
        variant="scrollable"
        scrollButtons="on"
      >
        {tabs.map((tab, index) => (
          <TabMUI key={tab.label + index} label={this.getTabLabel(tab)} classes={{ root: classes.tabRoot }} />
        ))}
      </TabsMUI>
    )
  }
}

Tab.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(Tab))
