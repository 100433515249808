import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CheckIcon from '@material-ui/icons/Check'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import EventIcon from '@material-ui/icons/Event'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import classNames from 'classnames'
import moment from 'moment'
import { HEADER_HEIGHT, VisaoEnum } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TextDatePicker from 'support/components/picker/TextDatePicker'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { formatarNome } from 'support/util/TextFormatter'

const styles = (theme) => ({
  showDesktop: theme.util.showDesktop(),
  showMobile: theme.util.showMobile(),
  showUp699: {
    [theme.breakpoints.down(700)]: {
      display: 'none'
    }
  },
  showDown700: {
    [theme.breakpoints.up(699)]: {
      display: 'none'
    }
  },
  root: {
    position: 'relative',
    zIndex: 5,
    ...theme.panelPage.containerHeaderFullScreen({ headerHeight: HEADER_HEIGHT })
  },
  containerNavegacao: {
    background: '#ffffff',
    borderRadius: '6px',
    [theme.breakpoints.up('sm')]: {
      ppadding: '5px 12px 4px 2px'
    },
    [theme.breakpoints.down('xs')]: {
      ppaddingTop: 8
    }
  },
  button: {
    minWidth: 0,
    border: '1px solid #e4e4e4',
    textTransform: 'none',

    height: 36,
    paddingLeft: 10,
    paddingRight: 10,

    [theme.breakpoints.up('sm')]: {
      height: 42,
      paddingLeft: 14,
      paddingRight: 14
    },
    '&:hover': {
      border: '1px solid #dcdcdc'
    }
  },
  iconeBotao: {
    fontSize: 22,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  },
  textoDataSelecionada: {
    cursor: 'pointer',
    marginTop: 1,
    fontWeight: 500,
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  gridItemSelecaoSemana: {
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      marginRight: 12
    },
    [theme.breakpoints.down(440)]: {
      marginLeft: 8,
      marginRight: 8
    },
    [theme.breakpoints.down(385)]: {
      marginLeft: 4,
      marginRight: 4
    },
    [theme.breakpoints.down(350)]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  gridItemNavegacaoMobile: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginRight: 10
    },
    [theme.breakpoints.down(440)]: {
      marginLeft: 6,
      marginRight: 6
    },
    [theme.breakpoints.down(385)]: {
      marginLeft: 4,
      marginRight: 4
    },
    [theme.breakpoints.down(350)]: {
      marginLeft: 2,
      marginRight: 2
    },
    [theme.breakpoints.down(330)]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  iconNavegacaoHoje: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  iconNavegacaoSeta: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    },
    [theme.breakpoints.down(440)]: {
      casemarginLeft: 2,
      cmarginRight: 2
    },
    [theme.breakpoints.down(350)]: {
      cmarginLeft: 0,
      cmarginRight: 0
    }
  },
  gridItemNomeColaborador: {
    paddingLeft: 10,
    [theme.breakpoints.down(385)]: {
      paddingLeft: 0
    }
  },
  botaoDireitaDesktop: {
    [theme.breakpoints.between(600, 680)]: {
      '&:not(:last-child)': {
        marginRight: -6
      }
    }
  }
})

class AgendaHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.dataSelecionadaFunctionsMap = {}
  }

  abrirPainelAtendimentoCliente = (props) => {
    if (verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO, PA.PODE_VENDER])) {
      EventsManager.pub('AgendaInterface', {
        action: 'adicionarVenda',
        ...props
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  abrirConfiguracoesDialogPage = () => {
    EventsManager.pub('BarraFerramentasAgendaInterface', {
      action: 'open'
    })
  }

  abrirFilaEsperaDialogPage = () => {
    EventsManager.pub('FilaEsperaAgendaInterface', {
      action: 'open'
    })
  }

  openConfiguracaoVisualizacaoAgenda = (event) => {
    this.setState({
      configuracaoVisualizacaoAgendaAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      }
    })
  }

  closeConfiguracaoVisualizacaoAgenda = () => {
    this.setState({ configuracaoVisualizacaoAgendaAnchorPosition: null })
  }

  openSelecaoColaborador = (event) => {
    this.setState({
      selecaoColaboradorAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      }
    })
  }

  closeSelecaoColaborador = () => {
    this.setState({ selecaoColaboradorAnchorPosition: null })
  }

  closePopoverMenu = (key) => {
    this.setState({ [key]: null })
  }

  getNomeColaboradorMinificado = (nome) => {
    const partesNome = nome.split(' ')
    let novoNome = partesNome[0]

    if (isWidthUp('sm', this.props.width)) {
      if (novoNome.length > 13) {
        novoNome = novoNome.substring(0, 3) + '.'
      }
    } else {
      if (novoNome.length > 3) {
        novoNome = novoNome.substring(0, 3) + '.'
      }
    }

    if (partesNome.length > 1) {
      let ultimoNome = partesNome[partesNome.length - 1]
      if (isWidthUp('sm', this.props.width)) {
        if (ultimoNome.length > 13) {
          ultimoNome = novoNome.substring(0, 3) + '.'
        }
      } else {
        ultimoNome = ultimoNome.substring(0, 1) + '.'
      }

      novoNome += ' ' + ultimoNome
    }
    return novoNome
  }

  render() {
    const { classes } = this.props
    let isMobile = true

    let formatoData = 'ddd, DD/MMM/YY'
    if (isWidthUp('lg', this.props.width)) {
      formatoData = 'ddd, DD/MMMM/YYYY'
    }
    if (isWidthUp('sm', this.props.width)) {
      isMobile = false
    }

    let leftGrid = null
    let rightGrid = null

    if (this.props.perspectiva.id === PerspectivaEnum.DIA.id) {
      leftGrid = (
        <React.Fragment>
          <Grid item>
            <Button
              size="small"
              className={classNames(classes.showDesktop)}
              onClick={() => {
                this.props.changeDataSelecionada(moment())
              }}
            >
              Hoje
            </Button>
          </Grid>
          <Grid item style={{ paddingRight: 2 }} className={classes.gridItemNavegacaoMobile}>
            <IconButton
              style={{ padding: 3 }}
              onClick={() => {
                this.props.changeDataSelecionada(this.props.dataSelecionada.clone().subtract(1, 'days'))
              }}
            >
              <ChevronLeftIcon className={classes.iconNavegacaoSeta} />
            </IconButton>
          </Grid>
          <Grid item style={{ paddingLeft: 2 }} className={classes.gridItemNavegacaoMobile}>
            <IconButton
              style={{ padding: 3 }}
              onClick={() => {
                this.props.changeDataSelecionada(this.props.dataSelecionada.clone().add(1, 'days'))
              }}
            >
              <ChevronRightIcon className={classes.iconNavegacaoSeta} />
            </IconButton>
          </Grid>
          <Grid item style={{ paddingLeft: 8 }} className={classes.gridItemNavegacaoMobile}>
            <TextDatePicker
              functionsMap={this.dataSelecionadaFunctionsMap}
              value={this.props.dataSelecionada.toDate()}
              onChange={(date) => {
                this.props.changeDataSelecionada(moment(date))
              }}
              renderComponent={(props) => {
                return (
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.textoDataSelecionada}
                    onClick={(e) => {
                      this.dataSelecionadaFunctionsMap.openPicker(e)
                    }}
                  >
                    {moment(props.dateObject).format(formatoData)}
                  </Typography>
                )
              }}
              autoOk={true}
            />
          </Grid>

          <Grid item style={{ paddingLeft: 10 }} className={classNames(classes.showDesktop)}>
            <Button size="small" focusRipple={false} onClick={this.openConfiguracaoVisualizacaoAgenda}>
              {this.props.visao.descricaoResumida} <ArrowDropDownIcon className={classes.iconeBotao} style={{ marginRight: -9 }} />
            </Button>
          </Grid>

          <Grid item className={classNames(classes.showMobile, classes.gridItemNavegacaoMobile)} style={{ paddingLeft: 8 }}>
            <IconButton
              style={{ padding: 6 }}
              onClick={(event) => {
                this.setState({
                  opcoesMenuAnchorEl: event.target
                })
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover anchorEl={this.state.opcoesMenuAnchorEl} open={Boolean(this.state.opcoesMenuAnchorEl)} onClose={() => this.closePopoverMenu('opcoesMenuAnchorEl')}>
              <MenuItem
                onClick={() => {
                  if (moment().isSame(this.props.dataSelecionada, 'day')) {
                    openBackableDialog(MessageDialog, {
                      text: 'Utilize este botão sempre que precisar posicionar a agenda no dia de hoje. Neste momento, a agenda já está posicionada.'
                    })
                  } else {
                    this.props.changeDataSelecionada(moment())
                  }
                }}
              >
                Ir para Hoje
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  this.abrirConfiguracoesDialogPage(event)
                  this.closePopoverMenu('opcoesMenuAnchorEl')
                }}
              >
                Configurações
              </MenuItem>
            </Popover>
          </Grid>

          <Grid item className={classNames(classes.showMobile, classes.gridItemNavegacaoMobile)}>
            <IconButton style={{ padding: 6 }} onClick={this.abrirPainelAtendimentoCliente}>
              <AddIcon className={classes.iconNavegacaoHoje} />
            </IconButton>
          </Grid>
          <Popover
            PaperProps={{ style: { paddingBottom: 8 } }}
            anchorReference="anchorPosition"
            anchorPosition={this.state.configuracaoVisualizacaoAgendaAnchorPosition}
            open={Boolean(this.state.configuracaoVisualizacaoAgendaAnchorPosition)}
            onClose={() => this.closeConfiguracaoVisualizacaoAgenda()}
          >
            <div style={{ padding: '16px' }}>
              <span variant="body1" style={{ fontSize: 12, color: '#afafaf' }}>
                Visualização da agenda
              </span>
            </div>
            {VisaoEnum.values().map((visao) => {
              return (
                <ItemMenuVisualizacaoAgenda
                  key={visao.id}
                  label={visao.descricao}
                  selected={visao.id === this.props.visao.id}
                  onClick={() => {
                    this.props.changeVisao(visao)
                    this.closeConfiguracaoVisualizacaoAgenda()
                  }}
                />
              )
            })}
          </Popover>
        </React.Fragment>
      )
      rightGrid = (
        <React.Fragment>
          <Button className={classNames(classes.showDesktop)} color="secondary" variant="text" style={{ marginTop: 1 }} onClick={this.abrirPainelAtendimentoCliente}>
            ADICIONAR
          </Button>
        </React.Fragment>
      )
    } else if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id || this.props.perspectiva.id === PerspectivaEnum.MES.id) {
      let intervaloNavegacaoData = null
      if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
        intervaloNavegacaoData = 'weeks'
      } else if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
        intervaloNavegacaoData = 'months'
      }

      leftGrid = (
        <React.Fragment>
          <Grid item className={classes.gridItemSelecaoSemana}>
            <TextDatePicker
              functionsMap={this.dataSelecionadaFunctionsMap}
              value={this.props.dataSelecionada.toDate()}
              onChange={(date) => {
                this.props.changeDataSelecionada(moment(date))
              }}
              renderComponent={(props) => {
                return (
                  <IconButton
                    style={{ padding: 6, marginLeft: 8 }}
                    onClick={(e) => {
                      this.dataSelecionadaFunctionsMap.openPicker(e)
                    }}
                  >
                    <EventIcon />
                  </IconButton>
                )
              }}
              okLabel={'OK'}
              showTodayButton={true}
              todayLabel={'HOJE'}
            />
          </Grid>
          <Grid item style={{ paddingRight: 2 }} className={classes.gridItemNavegacaoMobile}>
            <IconButton
              style={{ padding: 3 }}
              onClick={() => {
                this.props.changeDataSelecionada(this.props.dataSelecionada.clone().subtract(1, intervaloNavegacaoData))
              }}
            >
              <ChevronLeftIcon className={classes.iconNavegacaoSeta} />
            </IconButton>
          </Grid>
          <Grid item style={{ paddingLeft: 2 }} className={classes.gridItemNavegacaoMobile}>
            <IconButton
              style={{ padding: 3 }}
              onClick={() => {
                this.props.changeDataSelecionada(this.props.dataSelecionada.clone().add(1, intervaloNavegacaoData))
              }}
            >
              <ChevronRightIcon className={classes.iconNavegacaoSeta} />
            </IconButton>
          </Grid>

          <Grid item className={classes.gridItemNomeColaborador}>
            <Button focusRipple={false} style={{ textTransform: 'none' }} onClick={this.openSelecaoColaborador}>
              {this.getNomeColaboradorMinificado(formatarNome(this.props.colaboradorSelecionado.apelido, 2))}{' '}
              <ArrowDropDownIcon className={classes.iconeBotao} style={{ marginRight: -9 }} />
            </Button>
          </Grid>

          <Grid item className={classNames(classes.showMobile, classes.gridItemNavegacaoMobile)} style={{ paddingLeft: 8 }}>
            <IconButton
              style={{ padding: 6 }}
              onClick={(event) => {
                this.setState({
                  opcoesMenuAnchorEl: event.target
                })
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover anchorEl={this.state.opcoesMenuAnchorEl} open={Boolean(this.state.opcoesMenuAnchorEl)} onClose={() => this.closePopoverMenu('opcoesMenuAnchorEl')}>
              <MenuItem
                onClick={() => {
                  if (moment().isSame(this.props.dataSelecionada, 'day')) {
                    openBackableDialog(MessageDialog, {
                      text: 'Utilize este botão sempre que precisar posicionar a agenda no dia de hoje. Neste momento, a agenda já está posicionada.'
                    })
                  } else {
                    this.props.changeDataSelecionada(moment())
                  }
                }}
              >
                Ir para Hoje
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  this.abrirConfiguracoesDialogPage(event)
                  this.closePopoverMenu('opcoesMenuAnchorEl')
                }}
              >
                Configurações
              </MenuItem>
            </Popover>
          </Grid>

          <Grid item className={classNames(classes.showMobile, classes.gridItemNavegacaoMobile)}>
            <IconButton
              style={{ padding: 6 }}
              onClick={() => {
                this.abrirPainelAtendimentoCliente({ data: null })
              }}
            >
              <AddIcon className={classes.iconNavegacaoHoje} />
            </IconButton>
          </Grid>
          <Popover
            PaperProps={{ style: { paddingBottom: 8 } }}
            anchorReference="anchorPosition"
            anchorPosition={this.state.selecaoColaboradorAnchorPosition}
            open={Boolean(this.state.selecaoColaboradorAnchorPosition)}
            onClose={() => this.closeSelecaoColaborador()}
          >
            <div style={{ padding: '16px' }}>
              <span variant="body1" style={{ fontSize: 12, color: '#afafaf' }}>
                Visualização da agenda
              </span>
            </div>
            {this.props.colaboradores.map((colaborador) => {
              return (
                <ItemMenuVisualizacaoAgenda
                  key={colaborador.id}
                  label={formatarNome(colaborador.apelido, 2)}
                  selected={colaborador.id === this.props.colaboradorSelecionado.id}
                  onClick={() => {
                    this.props.changeColaboradorSelecionado(colaborador)
                    this.closeSelecaoColaborador()
                  }}
                />
              )
            })}
          </Popover>
        </React.Fragment>
      )
      rightGrid = (
        <React.Fragment>
          <Button
            className={classNames(classes.showDesktop)}
            color="secondary"
            variant="text"
            style={{ marginTop: 1 }}
            onClick={() => {
              this.abrirPainelAtendimentoCliente({ data: null })
            }}
          >
            ADICIONAR
          </Button>
        </React.Fragment>
      )
    }

    return (
      <div className={classNames(classes.root)}>
        <Grid style={{ height: '100%' }} container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs style={{ marginLeft: isMobile ? 0 : -18 }}>
            <Grid className={classes.containerNavegacao} container direction="row" justifyContent={isMobile ? 'center' : 'flex-start'} alignItems="center">
              {leftGrid}
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item style={{ marginRight: -8 }}>
              {rightGrid}
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

function ItemMenuVisualizacaoAgenda(props) {
  return (
    <MenuItem style={props.style} onClick={props.onClick}>
      <Grid container style={{ width: '100%', height: 25 }} justifyContent="center" alignItems="center">
        <Grid item xs align="left">
          {props.label}
        </Grid>
        {props.selected && (
          <Grid item align="right" style={{ paddingLeft: 20 }}>
            <CheckIcon style={{ display: 'block', marginBottom: 1 }} />
          </Grid>
        )}
      </Grid>
    </MenuItem>
  )
}

AgendaHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(AgendaHeader))
