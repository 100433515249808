import { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { User, withConfigCatClient } from 'configcat-react'
import { ROUTE_PORTAL_PANEL } from 'pages/RouteMap'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import AntecedenciaCancelamentoAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaCancelamentoAgendamentoOnlineEnum'

import styles from './styles'

const StepAgendamentoConcluido = ({ negocio, handleCloseDialog, classes, ...props }) => {
  const [googleAgendaEnabled, setGoogleAgendaEnabled] = useState(false)

  useEffect(() => {
    evaluateGoogleAgenda()
  }, [])

  const evaluateGoogleAgenda = () => {
    const user = new User(SessaoUsuario.getUsuario().email)
    props.getValue('FT_AG_GOOGLE_AGENDA', false, user).then((value) => {
      setGoogleAgendaEnabled(value)
    })
  }
  return (
    <div>
      <Grid container alignItems="center" style={{ marginBottom: 24 }}>
        <Grid item style={{ paddingRight: 12 }}>
          <DoneIcon style={{ fontSize: 28, stroke: '#30ce65', strokeWidth: 3, display: 'block', color: '#30ce65' }} />
        </Grid>
        <Grid item xs style={{ paddingLeft: 0 }}>
          <Typography variant="h6" className={classes.titulo}>
            Agendamento Concluído
          </Typography>
        </Grid>
      </Grid>

      {!googleAgendaEnabled ? (
        <Typography variant="body1">
          O horário foi agendado com sucesso. Você pode acompanhar todos os seus agendamentos através da opção "Meus Agendamentos" na sua conta.
        </Typography>
      ) : (
        <>
          <div className={classes.textWithIconOnTheLeft}>
            <InfoOutlinedIcon />
            <Typography variant="body1">Foi enviado para o seu e-mail cadastrado um convite para adicionar o serviço agendado ao seu calendário pessoal.</Typography>
          </div>
          <div className={classes.textWithIconOnTheLeft}>
            <EmailOutlinedIcon />
            <Typography variant="body1">Você pode acompanhar todos os seus agendamentos através da opção "Meus Agendamentos” na sua conta.</Typography>
          </div>
        </>
      )}

      <div style={{ padding: 16, background: '#f5f5f5', borderRadius: 8, marginTop: 16, marginBottom: 32 }}>
        <Typography variant="body2">
          <b>Política de cancelamento: </b>O agendamento pode ser cancelado até{' '}
          {AntecedenciaCancelamentoAgendamentoOnlineEnum.getById(negocio.configuracoesGerais.antecedenciaCancelamentoAgendamentoOnline.id).descricao} do horário marcado.{' '}
          {negocio.configuracoesGerais.textoPoliticaCancelamentoAgendamentoOnline}
        </Typography>
      </div>

      <div style={{ textAlign: 'right', marginTop: 16, marginRight: 0 }}>
        <Button
          style={{ textTransform: 'none', fontSize: 16, marginRight: 16 }}
          color="primary"
          onClick={() => {
            EventsManager.pub('LimparMarcacaoServicos')
            handleCloseDialog()
          }}
        >
          Fechar
        </Button>
        <Button
          style={{ textTransform: 'none', fontSize: 16, marginRight: -4 }}
          color="primary"
          variant="contained"
          onClick={() => {
            EventsManager.pub('LimparMarcacaoServicos')
            this.props.handleCloseDialog({
              onClosedCallback: () => {
                EventsManager.pub('RoutePush', {
                  path: ROUTE_PORTAL_PANEL + '/p/agendamentos'
                })
              }
            })
          }}
        >
          Meus Atendimentos
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(withConfigCatClient(StepAgendamentoConcluido))
