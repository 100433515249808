import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputDialogSelect from 'support/components/input/InputDialogSelect'

const styles = (theme) => ({})

class EstadoIbgeInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    if (!props.label) props.label = 'Município'
    if (!props.id) props.id = 'xmunicipio'
    if (!props.label) props.label = 'xmunicipio'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome,
        municipio: props.defaultValue
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Município...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'svc/localizacao.buscarMunicipiosIbgePorUf'
    props.DialogSelectProps.getRequestParametersFunction = () => {
      return {
        idUf: this.props.uf ? this.props.uf.id : null
      }
    }
    props.DialogSelectProps.generateItemData = (uf) => {
      return {
        value: uf.id,
        label: uf.nome,
        primary: uf.nome,
        municipio: uf
      }
    }

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum município foi encontrado'
      }
    }

    return <InputDialogSelect {...props} />
  }
}

EstadoIbgeInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EstadoIbgeInputDialogSelect)
