function getProps({ defaultTheme, tema, constant }) {
  return {
    props: {
      MuiPopover: {
        disableRestoreFocus: true,
        className: 'needsWillChange'
      },
      MuiDialog: {
        disableRestoreFocus: true
      },
      MuiMenu: {
        disableRestoreFocus: true,
        className: 'needsWillChange'
      }
    },

    overrides: {
      MuiTypography: {
        body2: {
          //letterSpacing : "0.03em"
        }
      },
      MuiLink: {
        root: {
          cursor: 'pointer'
        }
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: '#ffffff',
          'body.height100 &': {
            backgroundColor: 'transparent !important'
          },
          color: '#111111'
        }
      },
      MuiToolbar: {
        gutters: {
          [defaultTheme.breakpoints.down('xs')]: {
            paddingLeft: 6,
            paddingRight: 8
          },
          [defaultTheme.breakpoints.down(380)]: {
            paddingLeft: 2,
            paddingRight: 4
          }
        }
      },
      MuiMenuItem: {
        root: {
          paddingTop: '10px',
          paddingBottom: '10px'
        }
      },
      MuiFormControl: {
        root: {
          backgroundColor: 'inherit'
        }
      },
      MuiInputLabel: {
        root: {
          '&$focused:not($error)': {
            color: tema.dados.secondary.main
          }
        },
        animated: {
          transition: 'color 80ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 100ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
        }
      },
      MuiInput: {
        underline: {
          '&:before, &:hover:before, &:hover:not($disabled):before': {
            borderBottom: '1px solid ' + constant.INPUT_BORDER_COLOR + ''
          },
          '&:after': {
            borderBottomColor: tema.dados.secondary.main + '',
            transition: 'none'
          }
          /*"&$error:after":{
						borderColor: tema.dados.secondary.main+" !important",
						transition: "transform 80ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
					}*/
        }
      },
      MuiOutlinedInput: {
        root: {
          '& $notchedOutline, &:hover $notchedOutline': {
            borderColor: constant.INPUT_BORDER_COLOR
          },
          '&$disabled $notchedOutline': {
            borderColor: 'rgba(0,0,0,0.09)'
          },
          '&$focused $notchedOutline, &:hover$focused $notchedOutline': {
            borderColor: tema.dados.secondary.main
          },
          '&$error $notchedOutline, &:hover$error $notchedOutline': {
            borderColor: defaultTheme.palette.error.main
          }
        },
        adornedEnd: {
          paddingRight: '0px !important'
        }
      },

      MuiList: {
        root: {
          outline: 'none',
          tapHighlightColor: 'transparent'
        }
      },
      MuiListItem: {
        root: {
          whiteSpace: 'normal !important',
          lineHeight: '1.22em !important'
        }
      },
      MuiPopover: {
        root: {
          wwillChange: 'ttransform'
        }
      },
      MuiDialog: {
        scrollPaper: {
          overflow:
            'hidden' /* NUNCA REMOVER - otimização de problema de renderização em dispositivos: Ex: Samsung S7 - Conteudo do dialog fica quebrado, com blocos cinzas na frente dos campos, sem alinhamentos e etc */
        }
      },
      MuiIconButton: {
        root: {
          color: '#5f6368'
        }
      },
      MuiListItemIcon: {
        root: {
          color: '#5f5f5f',
          minWidth: 48
        }
      },
      MuiListItemText: {
        multiline: {
          marginTop: 4,
          marginBottom: 4
        }
      },
      MuiButton: {
        containedSecondary: {
          backgroundColor: tema.dados.primaryStrong.main,
          '&:hover': {
            backgroundColor: tema.dados.primaryStrong.dark
          }
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          alignSelf: 'center'
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          [defaultTheme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            paddingRight: 16
          }
        }
      },
      MuiPickersClock: {
        pin: {
          opacity: 0.2
        }
      },
      MuiPickersClockPointer: {
        pointer: {
          opacity: 0.13
        }
      },
      MuiPickersClockNumber: {
        clockNumberSelected: {
          color: defaultTheme.palette.text.primary,
          fontWeight: 700
        }
      }
    }
  }
}

const objectFunctions = {
  getProps: getProps
}

export default objectFunctions
