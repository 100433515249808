import React, { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import NotLoadedContent from 'support/components/contentload/NotLoadedContent'

const DEFAULT_ALIGN = 'center'

const styles = (theme) => ({
  loadingRoot: {
    textAlign: DEFAULT_ALIGN
  },
  loadingRootPadding: {
    padding: theme.spacing(4)
  },
  loadingRootSmallPadding: {
    padding: theme.spacing(2)
  },
  loadingProgress: {
    margin: theme.spacing(2)
  },
  loadingProgressBlock: {
    display: 'block'
  }
})

class ContentLoad extends Component {
  render() {
    const { classes, circularProgressVariant, padding, align } = this.props

    let content = null

    if (this.props.loading === true && this.props.loaded === false) {
      const containerStyle = {}
      if (align) {
        containerStyle.textAlign = align
      } else {
        containerStyle.textAlign = DEFAULT_ALIGN
      }

      const containerClasses = [classes.loadingRoot]
      if (padding === true) {
        containerClasses.push(classes.loadingRootPadding)
      } else if (padding === 'small') {
        containerClasses.push(classes.loadingRootSmallPadding)
      }

      let circularProgressProps = {
        thickness: 4.5
      }
      if (circularProgressVariant && circularProgressVariant === 'contained') {
        circularProgressProps.style = {
          margin: 14,
          display: 'block'
        }
        circularProgressProps.size = 26
        circularProgressProps.thickness = 5.3
      }

      content = (
        <div className={classNames(containerClasses)} style={containerStyle}>
          <CircularProgress className={classes.loadingProgress} {...circularProgressProps} />
        </div>
      )
    } else if (this.props.loaded === false && this.props.loading === false) {
      content = (
        <NotLoadedContent
          align={align}
          show={true}
          padding={padding}
          messageErrorCode={this.props.messageErrorCode}
          loadFunction={this.props.loadFunction}
          template={this.props.notLoadedContentTemplate}
        />
      )
    }

    return content
  }
}

ContentLoad.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  circularProgressVariant: PropTypes.string,
  containerClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  containerStyle: PropTypes.object,
  align: PropTypes.string,
  padding: PropTypes.bool,
  loadFunction: PropTypes.func
}

ContentLoad.defaultProps = {
  circularProgressVariant: '',
  containerClasses: '',
  containerStyle: {},
  align: 'left'
}

export default withStyles(styles)(ContentLoad)
