import React, { Component } from 'react'

import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PropTypes from 'prop-types'
import PanelPage from 'support/components/panel/PanelPage'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'

const styles = (theme) => ({
  openInNewIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: 6,
    fontSize: '22px',
    marginBottom: 2
  }
})

class TermosEPrivacidadePanelPage extends Component {
  render() {
    const { classes, ...others } = this.props

    const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
    const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade
    const termosUsoLinkProps = TermosEPrivacidadeLinkProps.termosUso

    return (
      <PanelPage {...others} title="Termos e Privacidade">
        <div className={classes.paperContent} style={{ textAlign: 'left', marginTop: 8, maxWidth: 640 }}>
          <Typography variant="body2" style={{ marginTop: 0 }}>
            EXXOS INFORMÁTICA E PUBLICIDADE
            <br />
            CNPJ: 17.818.983/0001-70
            <br />
            Avenida Rui Barbosa, 4110, São José dos Pinhais, Paraná
            <br />
            contato@salao99.com.br
          </Typography>
          <br />
          <Typography variant="body2">
            <Link underline="always" {...termosUsoLinkProps} rel="noopener noreferrer">
              <b>Termos de Uso</b>
              <OpenInNewIcon className={classes.openInNewIcon} />
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link underline="always" {...politicaPrivacidadeLinkProps} rel="noopener noreferrer">
              <b>Política de Privacidade</b>
              <OpenInNewIcon className={classes.openInNewIcon} />
            </Link>
          </Typography>
        </div>
      </PanelPage>
    )
  }
}

TermosEPrivacidadePanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TermosEPrivacidadePanelPage)
