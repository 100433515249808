import React from 'react'

export function createErrorsMap(errors) {
  if (!errors) {
    errors = {}
  }
  return (key, action) => {
    if (action && action === 'remove') {
      if (key in errors) {
        //errors[key] = undefined;
        delete errors[key]
      }
    } else {
      const errorsObject = errors

      if (!Array.isArray(key)) {
        key = [key]
      }

      let errorMessage = null
      for (let i = 0; i < key.length; i++) {
        errorMessage = errorsObject[key[i]]
        if (errorMessage) {
          break
        }
      }

      if (errorMessage !== undefined && errorMessage !== '') {
        return errorMessage
      } else {
        return null
      }
    }
  }
}

function isHasMessageError(fieldMessageErrorValue) {
  if (fieldMessageErrorValue && fieldMessageErrorValue.trim().length > 0) {
    return true
  } else {
    return false
  }
}

export function errorFlag(fieldMessageErrorValue) {
  return isHasMessageError(fieldMessageErrorValue) ? true : false
}

export function errorClass(fieldMessageErrorValue) {
  return isHasMessageError(fieldMessageErrorValue) ? 'field-with-error' : ''
}

export function errorOrHelpText(fieldMessageErrorValue, helpText) {
  return fieldMessageErrorValue === '' ? helpText : fieldMessageErrorValue
}

export function focusOnFirstInput(formId) {
  var toFocus = document.querySelector('#' + formId + ' input')
  if (toFocus !== undefined && toFocus !== null) {
    toFocus.focus()
  }
}

export function clearMessageErrors(component) {
  clearMessageErrorsAtributes(component.state)
  component.setState((state) => component.state)
}

function clearMessageErrorsAtributes(atributes) {
  for (var key in atributes) {
    if (atributes[key] !== null) {
      if (typeof atributes[key] === 'object') {
        clearMessageErrorsAtributes(atributes[key])
      } else if (Array.isArray(atributes[key])) {
        for (var i in atributes[key]) {
          clearMessageErrorsAtributes(atributes[key][i])
        }
      } else if (key.charAt(0) === '_') {
        atributes[key] = ''
      }
    }
  }
}

export function focusFirstElementWithError(formId) {
  window.setTimeout(() => {
    let toFocus = document.querySelector('#' + formId + ' .field-with-error input, #' + formId + ' .field-with-error textarea')
    if (toFocus && toFocus.type === 'hidden') {
      const newNode = document.createElement('input')
      newNode.setAttribute('style', 'opacity: 0; width: 0px; height: 0px; border: 0px solid; padding: 0px; margin: 0px; display: block')
      toFocus.parentElement.prepend(newNode)
      toFocus = newNode
    }
    if (toFocus !== undefined && toFocus !== null) {
      toFocus.focus()
    }
  }, 20)
}

export function HiddenSubmitButton() {
  return <input type="submit" style={{ position: 'absolute', left: -9999, width: 1, height: 1 }} />
}

export function convertStringToNumber(value) {
  if (typeof value === 'string') {
    value = value.replace(/^0{1,}/g, '0')
  }
  if (!value && value !== 0 && value !== '0') {
    return null
  } else if (typeof value === 'number') {
    return value
  } else {
    value = value.toString().trim()
    if (value.length === 0 || value === '-') {
      return null
    } else {
      return parseFloat(
        value
          .replace(/\./g, '')
          .replace(/,/g, '.')
          .replace(/[^0-9.-]/g, '.')
      )
    }
  }
}

//export const autoCompleteValueOff = "noautocomplete-flag";
export const autoCompleteValueOff = 'off'

const functionsObject = {
  createErrorsMap: createErrorsMap,
  errorFlag: errorFlag,
  errorClass: errorClass,
  errorOrHelpText: errorOrHelpText,
  focusOnFirstInput: focusOnFirstInput,
  focusFirstElementWithError: focusFirstElementWithError,
  HiddenSubmitButton: HiddenSubmitButton,
  convertStringToNumber: convertStringToNumber,
  autoCompleteValueOff: autoCompleteValueOff
}
export default functionsObject
