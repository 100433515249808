import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import CustomDialog from 'support/components/dialog/CustomDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import FormUtil, { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const styles = (theme) => ({})

class PagamentoDespesaDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      despesa: this.props.despesa
    }

    this.inputValor = React.createRef()
    this.dataFunctionsMap = {}
  }

  persistirPagamento = () => {
    postAPI({
      url: 'erp/despesas.persistir',
      data: this.getDadosPagamento(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoDespesa')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formPagamento')
      }
    })
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirPagamento()
  }

  getDadosPagamento = () => {
    const dados = this.props.despesa
    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.paga = true
    dados.valor = FormUtil.convertStringToNumber(this.inputValor.current.value)
    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.aplicarFiltro()
  }

  render() {
    const { errorsMap } = this.state

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogTitle>Pagamento</DialogTitle>
        <DialogContent>
          <form id="formPagamento" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <Grid container direction="column">
              <Grid item>
                <InputText
                  idname="xvalor"
                  label="Valor do Pagamento (R$)"
                  defaultValue={this.props.despesa.valor}
                  errorMessage={errorsMap('valor')}
                  disabled={this.state.ajaxing}
                  inputComponent={InputMoneyFormat}
                  inputProps={{
                    minValue: 1,
                    maxValue: 999999
                  }}
                  inputRef={this.inputValor}
                />
              </Grid>
              <Grid item>
                <InputData
                  functionsMap={this.dataFunctionsMap}
                  keyboard={true}
                  marginTop={true}
                  disabled={this.state.ajaxing}
                  id="xdata"
                  name="xdata"
                  errorMessage={errorsMap('data')}
                  defaultValue={this.props.despesa.data}
                  label={'Data do Pagamento'}
                />
              </Grid>
            </Grid>

            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Voltar
          </Button>
          <Button color="secondary" onClick={this.salvar}>
            Pagar
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

PagamentoDespesaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PagamentoDespesaDialog)
