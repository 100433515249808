import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_CONTA_MAIN, ROUTE_PORTAL_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/ApiClient'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'

const styles = (theme) => ({
  root: theme.page.parentCenteredPaper,
  paper: theme.page.paper({ maxWidth: 600 }),
  textAccent: {
    color: theme.palette.secondary.main,
    fontWeight: 500
  }
})

class ContaPendenteVerificacaoPage extends Component {
  constructor(props) {
    super(props)
    this.email = this.getEmailFromURL()
    this.titulo = this.getTituloPagina()
    this.texto = this.getTextoPagina()
    this.state = {
      tentativaReenvioRealizada: false,
      ajaxing: false,
      emailJaVerificado: false
    }
  }

  componentDidMount() {
    if (window.location.pathname.indexOf('/conta-criada') >= 0) {
      window.gtag_criacaoconta_conversion()
    }
    this.verificarStatusContaInterval = window.setInterval(() => {
      getAPI({
        url: 'cda/usuarios.buscarDadosSessao',
        onSuccess: (response) => {
          if (response.data.usuario.verificado) {
            const urlContinucao = SessaoUsuario.getUrlContinuacaoAposLogin()
            if (urlContinucao) {
              window.location.href = decodeURIComponent(urlContinucao)
            } else {
              this.props.history.push(ROUTE_PORTAL_MAIN)
            }
          }
        },
        contextoUsuario: 'erp',
        requerAutorizacao: true,
        sendErroToGenericSnackbar: false
      })
    }, 5000)
  }

  getEmailFromURL() {
    let partesCaminhosURL = window.location.pathname.split('/')
    let email = decodeURIComponent(partesCaminhosURL[partesCaminhosURL.length - 1])
    if (email) {
      email = email.toLowerCase()
    }
    return email
  }

  getTituloPagina() {
    if (window.location.pathname.indexOf('/conta-criada') >= 0) {
      return 'Parabéns!'
    } else if (window.location.pathname.indexOf('/conta-pendente-verificacao') >= 0) {
      return 'Verificação de E-mail'
    }
  }

  getTextoPagina() {
    if (window.location.pathname.indexOf('/conta-criada') >= 0) {
      return (
        <span>
          A sua conta do Salão99 criada com sucesso! Acabamos de enviar um <span style={{ whiteSpace: 'nowrap' }}>e-mail</span> para <b data-strong="true">{this.email}</b> com um
          link para a verificação.
        </span>
      )
    } else if (window.location.pathname.indexOf('/conta-pendente-verificacao') >= 0) {
      return (
        <span>
          A verificação da sua conta está pendente. Acesse o link que enviamos para o <span style={{ whiteSpace: 'nowrap' }}>e-mail</span> <b data-strong="true">{this.email}</b>{' '}
          para verificar a sua conta.
        </span>
      )
    }
  }

  handleReenviarEmail = () => {
    this.setState((state) => ({ ajaxing: true }))

    postAPI({
      url: 'cda/usuarios.reenviarEmailVerificacao',
      onSuccess: (response) => {
        this.setState((state) => ({ tentativaReenvioRealizada: true }))
      },
      onError: (response) => {
        if (response.code === 409) {
          this.setState((state) => ({ emailJaVerificado: true }))
        }
      },
      onPreFinal: () => {
        this.setState((state) => ({ ajaxing: false }))
      },
      requerAutorizacao: true
    })
  }

  render() {
    if (SessaoUsuario.isUsuarioLogado() === false) {
      return <Redirect to={ROUTE_CONTA_MAIN + '/login'} />
    }

    if (this.email == null || this.email === '') {
      return <Redirect to={ROUTE_CONTA_MAIN + '/login'} />
    }

    if (this.state.emailJaVerificado === true) {
      const urlContinucao = SessaoUsuario.getUrlContinuacaoAposLogin()
      if (urlContinucao) {
        window.location.href = decodeURIComponent(urlContinucao)
        return ''
      } else {
        return <Redirect to={ROUTE_PORTAL_MAIN} />
      }
    }

    const { classes } = this.props

    let opcaoReenviar
    if (this.state.tentativaReenvioRealizada === false) {
      opcaoReenviar = (
        <Button disabled={this.state.ajaxing} variant="contained" color="primary" size="large" style={{ marginTop: 24 }} type="button" onClick={this.handleReenviarEmail}>
          {this.state.ajaxing ? 'AGUARDE' : 'REENVIAR E-MAIL'}
        </Button>
      )
    } else {
      opcaoReenviar = (
        <Typography variant="subtitle1" style={{ marginTop: 24, fontSize: '18px' }}>
          E-mail reenviado com sucesso!
        </Typography>
      )
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <div style={{ textAlign: 'center', marginTop: 4 }}>
            <img src={logo} width="100" alt="Salão99" />
            <Typography variant="h5" style={{ marginTop: 22 }}>
              {this.titulo}
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: 20 }}>
              {this.texto}
            </Typography>
            {opcaoReenviar}
            <Typography style={{ marginTop: 24, color: '#555' }}>
              <span className={classes.textAccent}>Atenção:</span> caso não encontre o e-mail na caixa de entrada, procure nas pastas{' '}
              <span className={classes.textAccent}>Spam, Lixeira, Lixo Eletrônico, Itens Apagados ou Arquivo</span>.
            </Typography>
            <Link to={ROUTE_CONTA_MAIN + '/login'} onClick={() => SessaoUsuario.limparSessao()} style={{ textDecoration: 'none', marginTop: 20, display: 'block' }}>
              <Typography variant="subtitle1" color="primary">
                <ArrowBackIcon style={{ verticalAlign: 'middle', marginRight: 2 }} /> Acessar com outra conta
              </Typography>
            </Link>
          </div>
        </Paper>
      </div>
    )
  }

  componentWillUnmount() {
    window.clearInterval(this.verificarStatusContaInterval)
  }
}

ContaPendenteVerificacaoPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ContaPendenteVerificacaoPage)
