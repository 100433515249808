import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ClienteInputDialogSelect from 'pages/erp/painel/cliente/input/ClienteInputDialogSelect'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import ComoConheceuEnum from 'support/domain/cliente/ComoConheceuEnum'
import SexoEnum from 'support/domain/cliente/SexoEnum'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'
import ImageUtil from 'support/util/ImageUtil'
import { formatarCPF, formatarDataNascimento, formatarEndereco } from 'support/util/TextFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  tituloOpcao: {
    fontWeight: 500,
    marginTop: 24,
    marginBottom: 10,
    fontSize: 16
  },
  mensagemErro: {
    fontSize: 14,
    marginTop: '30px',
    color: 'red'
  },
  fotoCliente: {
    width: 48,
    height: 48,
    marginTop: 10,
    borderRadius: 80
  }
})

const QUANTIDADE_MAXIMA_TELEFONES = 5

class UnificacaoClientesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: 'Unificar Clientes',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }
  }

  componentDidMount() {
    this.setState({
      paginaCarregada: true
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/clientes.buscarDadosClientesParaUnificacao',
      params: {
        idClienteOrigem: this.state.clienteOrigem.id,
        idClienteDestino: this.state.clienteDestino.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const clienteOrigem = response.data.clienteOrigem
        const clienteDestino = response.data.clienteDestino
        const telefones = []

        if (clienteOrigem.telefones && clienteOrigem.telefones.length > 0) {
          telefones.push(...clienteOrigem.telefones)
        }

        if (clienteDestino.telefones && clienteDestino.telefones.length > 0) {
          telefones.push(...clienteDestino.telefones)
        }

        telefones.forEach((telefone, index) => {
          telefone.checked = index < QUANTIDADE_MAXIMA_TELEFONES ? true : false
          telefone.position = index
        })

        const newState = {
          clienteOrigem: response.data.clienteOrigem,
          clienteDestino: response.data.clienteDestino,
          idClienteNome: clienteOrigem.id,
          idClienteApelido: clienteOrigem.id,
          idClienteFoto: clienteOrigem.foto ? clienteOrigem.id : clienteDestino.foto ? clienteDestino.id : null,
          idClienteSexo: clienteOrigem.sexo ? clienteOrigem.id : clienteDestino.sexo ? clienteDestino.id : null,
          idClienteComoConheceu: clienteOrigem.comoConheceu ? clienteOrigem.id : clienteDestino.comoConheceu ? clienteDestino.id : null,
          idClienteInstagram: clienteOrigem.instagram ? clienteOrigem.id : clienteDestino.instagram ? clienteDestino.id : null,
          idClienteAnotacao: clienteOrigem.anotacao ? clienteOrigem.id : clienteDestino.anotacao ? clienteDestino.id : null,
          idClienteRg: clienteOrigem.rg ? clienteOrigem.id : clienteDestino.rg ? clienteDestino.id : null,
          idClienteCpf: clienteOrigem.cpf ? clienteOrigem.id : clienteDestino.cpf ? clienteDestino.id : null,
          idClienteProfissao: clienteOrigem.profissao ? clienteOrigem.id : clienteDestino.profissao ? clienteDestino.id : null,
          idClienteUsuario:
            clienteDestino.usuario && clienteDestino.usuario.criacaoCompleta
              ? clienteDestino.id
              : clienteOrigem.usuario
              ? clienteOrigem.id
              : clienteDestino.usuario
              ? clienteDestino.id
              : null,
          idClienteDataNascimento: formatarDataNascimento(clienteOrigem) ? clienteOrigem.id : formatarDataNascimento(clienteDestino) ? clienteDestino.id : null,
          idEndereco:
            formatarEndereco(clienteOrigem.endereco) !== '' ? clienteOrigem.endereco.id : formatarEndereco(clienteDestino.endereco) !== '' ? clienteDestino.endereco.id : null,
          telefones: telefones,
          quantidadeTelefonesSelecionados: telefones.length <= QUANTIDADE_MAXIMA_TELEFONES ? telefones.length : QUANTIDADE_MAXIMA_TELEFONES,
          paginaCarregada: true
        }

        this.setState(newState)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    openBackableDialog(EntityConfirmationDialog, {
      parent: this,
      title: 'Unificar Clientes',
      text: <span>Você tem certeza que deseja unificar estes clientes? Essa operação não poderá ser desfeita.</span>,
      textToConfirm: 'CONFIRMAR',
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'erp/clientes.unificarClientes',
        contextoUsuario: 'erp',
        data: this.getDados(),
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('ManipulacaoCliente', { cliente: response.data })
        }
      }
    })
  }

  getDados = () => {
    const telefonesSelecionados = []

    this.state.telefones.forEach((telefone, index) => {
      if (telefone.checked) {
        telefonesSelecionados.push({ id: telefone.id })
      }
    })

    const dados = {
      clienteOrigem: { id: this.state.clienteOrigem.id },
      clienteDestino: { id: this.state.clienteDestino.id },
      nome: { id: this.state.idClienteNome },
      apelido: { id: this.state.idClienteApelido },
      foto: { id: this.state.idClienteFoto },
      sexo: { id: this.state.idClienteSexo },
      comoConheceu: { id: this.state.idClienteComoConheceu },
      instagram: { id: this.state.idClienteInstagram },
      anotacao: { id: this.state.idClienteAnotacao },
      rg: { id: this.state.idClienteRg },
      profissao: { id: this.state.idClienteProfissao },
      usuario: { id: this.state.idClienteUsuario },
      dataNascimento: { id: this.state.idClienteDataNascimento },
      cpf: { id: this.state.idClienteCpf },
      endereco: { id: this.state.idEndereco },
      telefones: telefonesSelecionados
    }

    return dados
  }

  alterarDadosClienteSelecionado = (clienteOrigem, clienteDestino) => {
    if (
      !clienteOrigem ||
      !this.state.clienteOrigem ||
      clienteOrigem.id !== this.state.clienteOrigem.id ||
      !clienteDestino ||
      !this.state.clienteDestino ||
      clienteDestino.id !== this.state.clienteDestino.id
    ) {
      const state = this.state
      state.clienteOrigem = clienteOrigem
      state.clienteDestino = clienteDestino
      state.idClienteNome = null
      state.idClienteApelido = null
      state.idClienteFoto = null
      state.idClienteSexo = null
      state.idClienteComoConheceu = null
      state.idClienteInstagram = null
      state.idClienteAnotacao = null
      state.idClienteRg = null
      state.idClienteProfissao = null
      state.idClienteUsuario = null
      state.idClienteDataNascimento = null
      state.idClienteCpf = null
      state.idEndereco = null
      state.telefones = []
      this.setState(state)
    }
  }

  handleChangeTelefones = (position, event) => {
    const telefones = this.state.telefones
    let quantidadeTelefonesSelecionados = this.state.quantidadeTelefonesSelecionados

    if (quantidadeTelefonesSelecionados === 1 && event.target.checked) {
      openBackableDialog(MessageDialog, {
        text: <span>Selecione no máximo {QUANTIDADE_MAXIMA_TELEFONES} números de telefones.</span>
      })
    } else {
      if (!event.target.checked) {
        quantidadeTelefonesSelecionados--
      } else {
        quantidadeTelefonesSelecionados++
      }

      for (let telefone of telefones) {
        if (telefone.position === position) {
          telefone.checked = event.target.checked
          break
        }
      }
      this.setState({
        telefones: telefones,
        quantidadeTelefonesSelecionados: quantidadeTelefonesSelecionados
      })
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const {
      errorsMap,
      clienteOrigem,
      clienteDestino,
      idClienteNome,
      idClienteApelido,
      idClienteFoto,
      idClienteSexo,
      idClienteComoConheceu,
      idClienteInstagram,
      idClienteAnotacao,
      idClienteRg,
      idClienteProfissao,
      idClienteUsuario,
      idClienteDataNascimento,
      idClienteCpf,
      idEndereco,
      telefones
    } = this.state
    let enderecoClienteOrigemFormatado, enderecoClienteDestinoFormatado, variacoesFotoPerfilClienteOrigem, variacoesFotoPerfilClienteDestino
    let clienteOrigemCriacaoCompleta = false
    let clienteDestinoCriacaoCompleta = false

    if (clienteOrigem && clienteDestino) {
      enderecoClienteOrigemFormatado = formatarEndereco(clienteOrigem.endereco)
      enderecoClienteDestinoFormatado = formatarEndereco(clienteDestino.endereco)

      variacoesFotoPerfilClienteOrigem = clienteOrigem.foto ? ImageUtil.extrairVariacoesImagem(clienteOrigem.foto) : null
      variacoesFotoPerfilClienteDestino = clienteDestino.foto ? ImageUtil.extrairVariacoesImagem(clienteDestino.foto) : null

      clienteOrigemCriacaoCompleta = clienteOrigem.usuario && clienteOrigem.usuario.criacaoCompleta
      clienteDestinoCriacaoCompleta = clienteDestino.usuario && clienteDestino.usuario.criacaoCompleta
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ClienteInputDialogSelect
          key={'clienteOriginal'}
          idname="xclienteoriginal"
          disabled={this.state.ajaxing}
          defaultValue={clienteOrigem}
          label="Selecione o Cliente 1"
          errorMessage={errorsMap('clienteOrigem')}
          customVariant="outlined-small"
          shrink={true}
          onChange={(value) => {
            let clienteOrigem = null
            if (value) {
              clienteOrigem = { id: value.cliente.id }
            } else {
              clienteOrigem = null
            }
            this.alterarDadosClienteSelecionado(clienteOrigem, this.state.clienteDestino)
          }}
        />

        <ClienteInputDialogSelect
          key={'clienteDestino'}
          idname="xclientedestino"
          marginTop={true}
          disabled={this.state.ajaxing}
          defaultValue={clienteDestino}
          label="Selecione o Cliente 2"
          errorMessage={errorsMap('clienteDestino')}
          customVariant="outlined-small"
          shrink={true}
          onChange={(value) => {
            let clienteDestino = null
            if (value) {
              clienteDestino = { id: value.cliente.id }
            } else {
              clienteDestino = null
            }
            this.alterarDadosClienteSelecionado(this.state.clienteOrigem, clienteDestino)
          }}
        />

        {clienteOrigem && clienteDestino && (
          <ContentWithPreload loadFunction={this.carregarMain} loadKey={clienteOrigem.id + '-' + clienteDestino.id}>
            {() => {
              if (clienteOrigem.id === clienteDestino.id) {
                return (
                  <Typography align="center" className={classes.mensagemErro}>
                    Atenção: Selecione clientes diferentes para realizar a unificação.
                  </Typography>
                )
              } else if (clienteOrigemCriacaoCompleta && clienteDestinoCriacaoCompleta) {
                return (
                  <Typography align="center" className={classes.mensagemErro}>
                    Atenção: Não é possível unir dois clientes que possuem conta criada no sistema.
                  </Typography>
                )
              } else if (clienteOrigem.tipo && clienteDestino.tipo && clienteOrigem.tipo.id !== clienteDestino.tipo.id) {
                return (
                  <Typography align="center" className={classes.mensagemErro}>
                    Atenção: Não é possível unificar clientes de tipos diferentes.
                  </Typography>
                )
              }
              return (
                <form id="formUnificacaoClientes" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  <React.Fragment>
                    <Typography variant="body1" style={{ marginTop: 32, marginBottom: 28 }}>
                      Selecione abaixo os dados que o sistema deve manter na unificação dos clientes:
                    </Typography>

                    <Typography variant="body2" className={classes.tituloOpcao}>
                      Nome
                    </Typography>

                    <RadioGroup
                      name="xnomeCliente"
                      id="xnomeCliente"
                      value={idClienteNome}
                      onChange={(event) => {
                        this.setState({ idClienteNome: parseInt(event.target.value, 10) })
                      }}
                    >
                      <FormControlLabel
                        key={'nco' + clienteOrigem.nome}
                        disabled={this.state.ajaxing}
                        value={clienteOrigem.id}
                        control={<Radio />}
                        label={<Typography variant="body2">{clienteOrigem.nome}</Typography>}
                      />
                      {clienteOrigem.nome !== clienteDestino.nome && (
                        <FormControlLabel
                          key={'ncd' + clienteDestino.nome}
                          value={clienteDestino.id}
                          control={<Radio />}
                          label={<Typography variant="body2">{clienteDestino.nome}</Typography>}
                        />
                      )}
                    </RadioGroup>

                    <Typography variant="body2" className={classes.tituloOpcao}>
                      Apelido
                    </Typography>

                    <RadioGroup
                      name="xapelidoCliente"
                      id="xapelidoCliente"
                      value={idClienteApelido}
                      onChange={(event) => {
                        this.setState({ idClienteApelido: parseInt(event.target.value, 10) })
                      }}
                    >
                      <FormControlLabel
                        key={'ncoa' + clienteOrigem.apelido}
                        disabled={this.state.ajaxing}
                        value={clienteOrigem.id}
                        control={<Radio />}
                        label={<Typography variant="body2">{clienteOrigem.apelido}</Typography>}
                      />
                      {clienteOrigem.apelido !== clienteDestino.apelido && (
                        <FormControlLabel
                          key={'ncda' + clienteDestino.apelido}
                          value={clienteDestino.id}
                          control={<Radio />}
                          label={<Typography variant="body2">{clienteDestino.apelido}</Typography>}
                        />
                      )}
                    </RadioGroup>

                    {(clienteOrigem.foto || clienteDestino.foto) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Foto de perfil
                        </Typography>

                        <RadioGroup
                          name="xfotoCliente"
                          id="xfotoCliente"
                          value={idClienteFoto}
                          onChange={(event) => {
                            this.setState({ idClienteFoto: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.foto && (
                            <FormControlLabel
                              key={'fotoOrigem'}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<img src={variacoesFotoPerfilClienteOrigem.get(0)} className={classes.fotoCliente} alt="Foto Perfil" />}
                            />
                          )}
                          {clienteDestino.foto && (
                            <FormControlLabel
                              key={'fotoDestino'}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<img src={variacoesFotoPerfilClienteDestino.get(0)} className={classes.fotoCliente} alt="Foto Perfil" />}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}

                    {(clienteOrigem.anotacao || clienteDestino.anotacao) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Anotação
                        </Typography>

                        <RadioGroup
                          name="xanotacaoCliente"
                          id="xanotacaoCliente"
                          value={idClienteAnotacao}
                          onChange={(event) => {
                            this.setState({ idClienteAnotacao: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.anotacao && (
                            <FormControlLabel
                              key={'anot' + clienteOrigem.anotacao}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteOrigem.anotacao}</Typography>}
                            />
                          )}
                          {clienteDestino.anotacao && (!clienteOrigem.anotacao || clienteOrigem.anotacao !== clienteDestino.anotacao) && (
                            <FormControlLabel
                              key={'anot' + clienteDestino.anotacao}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteDestino.anotacao}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}

                    {(clienteOrigem.comoConheceu || clienteDestino.comoConheceu) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Como Conheceu
                        </Typography>

                        <RadioGroup
                          name="xcomoConheceuCliente"
                          id="xcomoConheceuCliente"
                          value={idClienteComoConheceu}
                          onChange={(event) => {
                            this.setState({ idClienteComoConheceu: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.comoConheceu && (
                            <FormControlLabel
                              key={'comoConheceu' + clienteOrigem.comoConheceu.id}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{ComoConheceuEnum.getById(clienteOrigem.comoConheceu.id).descricao}</Typography>}
                            />
                          )}
                          {clienteDestino.comoConheceu && (!clienteOrigem.comoConheceu || clienteOrigem.comoConheceu.id !== clienteDestino.comoConheceu.id) && (
                            <FormControlLabel
                              key={'comoConheceu' + clienteDestino.comoConheceu.id}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{ComoConheceuEnum.getById(clienteDestino.comoConheceu.id).descricao}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}

                    {(clienteOrigem.sexo || clienteDestino.sexo) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Sexo
                        </Typography>

                        <RadioGroup
                          name="xsexoCliente"
                          id="xsexoCliente"
                          value={idClienteSexo}
                          onChange={(event) => {
                            this.setState({ idClienteSexo: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.sexo && (
                            <FormControlLabel
                              key={'sexo' + clienteOrigem.sexo.id}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{SexoEnum.getById(clienteOrigem.sexo.id).descricao}</Typography>}
                            />
                          )}
                          {clienteDestino.sexo && (!clienteOrigem.sexo || clienteOrigem.sexo.id !== clienteDestino.sexo.id) && (
                            <FormControlLabel
                              key={'sexo' + clienteDestino.sexo.id}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{SexoEnum.getById(clienteDestino.sexo.id).descricao}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}

                    {(clienteOrigem.usuario || clienteDestino.usuario) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          E-mail
                        </Typography>

                        <RadioGroup
                          name="xemailCliente"
                          id="xemailCliente"
                          value={idClienteUsuario}
                          onChange={(event) => {
                            this.setState({ idClienteUsuario: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.usuario && !clienteDestinoCriacaoCompleta && (
                            <FormControlLabel
                              key={'anot' + clienteOrigem.usuario.email}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteOrigem.usuario.email}</Typography>}
                            />
                          )}
                          {clienteDestino.usuario && !clienteOrigemCriacaoCompleta && (
                            <FormControlLabel
                              key={'anot' + clienteDestino.usuario.email}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteDestino.usuario.email}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}
                    {(clienteOrigem.instagram || clienteDestino.instagram) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Instagram
                        </Typography>

                        <RadioGroup
                          name="xinstagramCliente"
                          id="xinstagramCliente"
                          value={idClienteInstagram}
                          onChange={(event) => {
                            this.setState({ idClienteInstagram: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.instagram && (
                            <FormControlLabel
                              key={'anot' + clienteOrigem.instagram}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteOrigem.instagram}</Typography>}
                            />
                          )}
                          {clienteDestino.instagram && (!clienteOrigem.instagram || clienteOrigem.instagram !== clienteDestino.instagram) && (
                            <FormControlLabel
                              key={'anot' + clienteDestino.instagram}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteDestino.instagram}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}
                    {telefones.length > 0 && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Telefone{telefones.length !== 1 ? 's' : ''}
                        </Typography>
                        <Grid container direction="column">
                          {telefones.map((telefone, index) => (
                            <Grid item key={index}>
                              <FormControlLabel
                                key={'fcl' + telefone.id}
                                control={
                                  <Checkbox
                                    color="secondary"
                                    key={'cb' + telefone.id}
                                    disabled={this.state.ajaxing}
                                    checked={telefone.checked}
                                    onChange={(event) => this.handleChangeTelefones(telefone.position, event)}
                                  />
                                }
                                label={<Typography variant="body2">{telefone.numero}</Typography>}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </React.Fragment>
                    )}
                    {(clienteOrigem.diaNascimento || clienteDestino.diaNascimento) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Data Nascimento
                        </Typography>

                        <RadioGroup
                          name="xdataNascimentoCliente"
                          id="xdataNascimentoCliente"
                          value={idClienteDataNascimento}
                          onChange={(event) => {
                            this.setState({ idClienteDataNascimento: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.diaNascimento && (
                            <FormControlLabel
                              key={'dataNasc' + formatarDataNascimento(clienteOrigem)}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{formatarDataNascimento(clienteOrigem)}</Typography>}
                            />
                          )}
                          {formatarDataNascimento(clienteDestino) &&
                            (!formatarDataNascimento(clienteOrigem) || formatarDataNascimento(clienteOrigem) !== formatarDataNascimento(clienteDestino)) && (
                              <FormControlLabel
                                key={'dataNasc' + formatarDataNascimento(clienteDestino)}
                                disabled={this.state.ajaxing}
                                value={clienteDestino.id}
                                control={<Radio />}
                                label={<Typography variant="body2">{formatarDataNascimento(clienteDestino)}</Typography>}
                              />
                            )}
                        </RadioGroup>
                      </React.Fragment>
                    )}
                    {(clienteOrigem.cpf || clienteDestino.cpf) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          CPF
                        </Typography>

                        <RadioGroup
                          name="xcpfCliente"
                          id="xcpfCliente"
                          value={idClienteCpf}
                          onChange={(event) => {
                            this.setState({ idClienteCpf: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.cpf && (
                            <FormControlLabel
                              key={'cpf' + clienteOrigem.cpf}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{formatarCPF(clienteOrigem.cpf)}</Typography>}
                            />
                          )}
                          {clienteDestino.cpf && (!clienteOrigem.cpf || clienteOrigem.cpf !== clienteDestino.cpf) && (
                            <FormControlLabel
                              key={'cpf' + clienteDestino.cpf}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{formatarCPF(clienteDestino.cpf)}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}
                    {(clienteOrigem.rg || clienteDestino.rg) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          RG
                        </Typography>

                        <RadioGroup
                          name="xrgCliente"
                          id="xrgCliente"
                          value={idClienteRg}
                          onChange={(event) => {
                            this.setState({ idClienteRg: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.rg && (
                            <FormControlLabel
                              key={'rg' + clienteOrigem.rg}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteOrigem.rg}</Typography>}
                            />
                          )}
                          {clienteDestino.rg && (!clienteOrigem.rg || clienteOrigem.rg !== clienteDestino.rg) && (
                            <FormControlLabel
                              key={'rg' + clienteDestino.rg}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteDestino.rg}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}
                    {(clienteOrigem.profissao || clienteDestino.profissao) && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Profissão
                        </Typography>

                        <RadioGroup
                          name="xprofissaoCliente"
                          id="xprofissaoCliente"
                          value={idClienteProfissao}
                          onChange={(event) => {
                            this.setState({ idClienteProfissao: parseInt(event.target.value, 10) })
                          }}
                        >
                          {clienteOrigem.profissao && (
                            <FormControlLabel
                              key={'profissao' + clienteOrigem.profissao}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteOrigem.profissao}</Typography>}
                            />
                          )}
                          {clienteDestino.profissao && (!clienteOrigem.profissao || clienteOrigem.profissao !== clienteDestino.profissao) && (
                            <FormControlLabel
                              key={'profissao' + clienteDestino.profissao}
                              disabled={this.state.ajaxing}
                              value={clienteDestino.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{clienteDestino.profissao}</Typography>}
                            />
                          )}
                        </RadioGroup>
                      </React.Fragment>
                    )}

                    {(enderecoClienteOrigemFormatado !== '' || enderecoClienteDestinoFormatado !== '') && (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.tituloOpcao}>
                          Endereço
                        </Typography>

                        <RadioGroup
                          name="xenderecoCliente"
                          id="xenderecoCliente"
                          value={idEndereco}
                          onChange={(event) => {
                            this.setState({ idEndereco: parseInt(event.target.value, 10) })
                          }}
                        >
                          {enderecoClienteOrigemFormatado !== '' && (
                            <FormControlLabel
                              key={'endereco' + clienteOrigem.endereco.id}
                              disabled={this.state.ajaxing}
                              value={clienteOrigem.endereco.id}
                              control={<Radio />}
                              label={<Typography variant="body2">{formatarEndereco(clienteOrigem.endereco)}</Typography>}
                            />
                          )}
                          {enderecoClienteDestinoFormatado !== '' &&
                            (enderecoClienteOrigemFormatado === '' || enderecoClienteOrigemFormatado !== enderecoClienteDestinoFormatado) && (
                              <FormControlLabel
                                key={'endereco' + clienteDestino.endereco.id}
                                disabled={this.state.ajaxing}
                                value={clienteDestino.endereco.id}
                                control={<Radio />}
                                label={<Typography variant="body2">{enderecoClienteDestinoFormatado}</Typography>}
                              />
                            )}
                        </RadioGroup>
                      </React.Fragment>
                    )}

                    <div style={{ padding: 16, background: '#f5f5f5', borderRadius: 8, marginTop: 30, marginBottom: 44 }}>
                      <Typography variant="body2">
                        <b>Importante: </b>Todos os outros dados, como atendimentos, vendas, comandas, pacotes, saldo, fichas e outros serão unificados, ou seja, somados e agrupados
                        em apenas um cliente. Após a unificação dos dados, não será mais possível voltar ao estado anterior.
                      </Typography>
                    </div>

                    <HiddenSubmitButton />

                    {this.state.paginaCarregada && (
                      <div className={classes.containerFooterButtons}>
                        <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                          Cancelar
                        </Button>
                        <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                          {this.state.ajaxing ? 'Aguarde' : 'Unificar'}
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                </form>
              )
            }}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

UnificacaoClientesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UnificacaoClientesDialogPage)
