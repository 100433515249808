import { Component } from 'react'

class PanelPageBody extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.desktopDrawerOpen !== undefined && this.props.desktopDrawerOpen !== null) {
      if (
        (this.props.desktopDrawerOpen !== nextProps.desktopDrawerOpen || this.props.mobileDrawerOpen !== nextProps.mobileDrawerOpen) &&
        this.props.toolbarExpanded === nextProps.toolbarExpanded
      ) {
        //console.log("OTIMIZACAO,RENDER","PanelPageBody");
        return false
      }
    }
    return true
  }

  render() {
    //console.log("RENDER","PanelPageBody");
    return this.props.children
  }
}

export default PanelPageBody
