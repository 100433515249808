import { createEnum } from 'support/util/EnumUtil'

export const TemaEnum = createEnum({
  PRETO_AZUL: {
    id: 1,
    descricao: 'Azul',
    dados: criarTemaAzul()
  },
  /*PRETO_PRETO : {
		id:8,
		descricao:"Preto",
		dados : criarTemaPreto()
	},*/
  PRETO_VERDE: {
    id: 2,
    descricao: 'Verde',
    dados: criarTemaVerde()
  },
  PRETO_CIANO: {
    id: 7,
    descricao: 'Ciano',
    dados: criarTemaCiano()
  },
  PRETO_ROXO: {
    id: 3,
    descricao: 'Roxo',
    dados: criarTemaRoxo()
  },
  PRETO_ROSA: {
    id: 4,
    descricao: 'Rosa',
    dados: criarTemaRosa()
  },
  PRETO_VERMELHO: {
    id: 5,
    descricao: 'Vermelho',
    dados: criarTemaVermelho()
  },
  PRETO_LARANJA: {
    id: 6,
    descricao: 'Laranja',
    dados: criarTemaLaranja()
  }
})

function criarDadosTema(props) {
  const { primary, ...others } = props

  const dados = {
    secondary: primary,
    ...others
  }

  dados.chart.negative = props.background.negative

  dados.primary = {
    light: '#777777',
    main: '#2e2e2e',
    dark: '#2e2e2e'
  }

  return dados
}

function criarTemaAzul() {
  return criarDadosTema({
    primary: {
      light: '#3396ec',
      main: '#007ce8',
      dark: '#0071d2'
    },
    background: {
      default: '#f5f6f8',
      negative: '#f36767'
    },
    drawer: {
      background: '#0747a6'
    },
    divider: {
      background: 'rgba(32, 57, 95, 0.18)'
    },
    agendaInterface: {
      horariosIndisponiveis: {
        light: '#8facbf',
        dark: '#303b42'
      }
    },
    chart: {
      list: ['#1e88e5', '#1cdc84'],
      pieList: ['#1e88e5', '#b777e0', '#ff7463', '#ffc557', '#cce06e', '#4dd684', '#00dad1']
    },
    badge: {
      primaryContrast: {
        text: '#fff',
        background: '#ff6969'
      }
    }
  })
}

/*function criarTemaPreto(){
	return criarDadosTema({
		primaryStrong : {
			light: '#5f5f5f',
			main: '#383838',
			dark: '#222222'
		},
		primary : {
			light: '#3396ec',
			main: '#007ce8',
			dark: '#0071d2'
		},
		background:{
			default: "#f7f7f7",
			negative : "#f36767"
		},
		drawer: {
			background : "#272727",
		},
		divider:{
			background : "rgba(0, 53, 132, 0.18)"
		},
		agendaInterface:{
			horariosIndisponiveis:{
				light: "#8facbf",
				dark: "#303b42"
			}
		},
		chart: {
			list : ["#1e88e5","#1cdc84"],
			pieList : ["#1e88e5","#b777e0","#ff7463","#ffc557","#cce06e","#4dd684","#00dad1"],
		},
		badge:{
			primaryContrast : {
				text:"#fff",
				background: "#3c96e4"
			}
		}
	});
}*/

function criarTemaCiano() {
  return criarDadosTema({
    primary: {
      light: '#0cc0d2',
      main: '#00c1d4',
      dark: '#00aabb'
    },
    background: {
      default: '#f5f7f7',
      negative: '#f36767'
    },
    drawer: {
      background: '#00899e' //0088a7
    },
    divider: {
      background: 'rgba(0, 57, 70, 0.18)'
    },
    agendaInterface: {
      horariosIndisponiveis: {
        light: '#8fbfaf',
        dark: '#30423f'
      }
    },
    chart: {
      list: ['#00baaf', '#a4da00'],
      pieList: ['#cce06e', '#4dd684', '#00dad1', '#1e88e5', '#b777e0', '#ff7463', '#ffc557']
    },
    badge: {
      primaryContrast: {
        text: '#fff',
        background: '#f3674b'
      }
    }
  })
}

function criarTemaVerde() {
  return criarDadosTema({
    primary: {
      light: '#33b78a',
      main: '#00a66d',
      dark: '#00905f'
    },
    background: {
      default: '#f5f7f7',
      negative: '#f36767'
    },
    drawer: {
      background: '#006951'
    },
    divider: {
      background: 'rgba(0, 64, 49, 0.18)'
    },
    agendaInterface: {
      horariosIndisponiveis: {
        light: '#8fbfaf',
        dark: '#30423f'
      }
    },
    chart: {
      list: ['#00baaf', '#a4da00'],
      pieList: ['#cce06e', '#4dd684', '#00dad1', '#1e88e5', '#b777e0', '#ff7463', '#ffc557']
    },
    badge: {
      primaryContrast: {
        text: '#fff',
        background: '#f3674b'
      }
    }
  })
}

function criarTemaRoxo() {
  return criarDadosTema({
    primary: {
      light: '#8546ee',
      main: '#772ff1',
      dark: '#6b2ad8'
    },
    background: {
      default: '#f6f5f7',
      negative: '#f36767'
    },
    drawer: {
      background: '#4724a9'
    },
    divider: {
      background: 'rgba(16, 1, 58, 0.18)'
    },
    agendaInterface: {
      horariosIndisponiveis: {
        light: '#928fbf',
        dark: '#353042'
      }
    },
    chart: {
      list: ['#8148e2', '#1b96ff'],
      pieList: ['#1e88e5', '#b777e0', '#ff7463', '#ffc557', '#cce06e', '#4dd684', '#00dad1']
    },
    badge: {
      primaryContrast: {
        text: '#fff',
        background: '#45c742'
      }
    }
  })
}

function criarTemaRosa() {
  return criarDadosTema({
    primary: {
      light: '#eb4f90',
      main: '#e7237d',
      dark: '#cf2071'
    },
    background: {
      default: '#f7f5f6',
      negative: '#1e88e5'
    },
    drawer: {
      background: '#ce1863'
    },
    divider: {
      background: 'rgba(93, 49, 77, 0.18)'
    },
    agendaInterface: {
      horariosIndisponiveis: {
        light: '#bf8fb2',
        dark: '#42303b'
      }
    },
    chart: {
      list: ['#ef3271', '#a749fd'],
      pieList: ['#b777e0', '#ff7463', '#ffc557', '#cce06e', '#4dd684', '#00dad1', '#1e88e5']
    },
    badge: {
      primaryContrast: {
        text: '#fff',
        background: '#7673ff'
      }
    }
  })
}

function criarTemaVermelho() {
  return criarDadosTema({
    primary: {
      light: '#ea525f',
      main: '#e52737',
      dark: '#d32535'
    },
    background: {
      default: '#f7f5f5',
      negative: '#1e88e5'
    },
    drawer: {
      background: '#d82222'
    },
    divider: {
      background: 'rgba(95, 28, 28, 0.18)'
    },
    agendaInterface: {
      horariosIndisponiveis: {
        light: '#bf8f8f',
        dark: '#423030'
      }
    },
    chart: {
      list: ['#e83141', '#ffa73a'],
      pieList: ['#ff7463', '#ffc557', '#cce06e', '#4dd684', '#00dad1', '#1e88e5', '#b777e0']
    },
    badge: {
      primaryContrast: {
        text: '#fff',
        background: '#3997e0'
      }
    }
  })
}

function criarTemaLaranja() {
  return criarDadosTema({
    primary: {
      light: '#f8693f',
      main: '#fd531e',
      dark: '#ea5426'
    },
    background: {
      default: '#f7f6f5',
      negative: '#1e88e5'
    },
    drawer: {
      background: '#e64616'
    },
    divider: {
      background: 'rgba(115, 57, 7, 0.18)'
    },
    agendaInterface: {
      horariosIndisponiveis: {
        light: '#bfa38f',
        dark: '#423830'
      }
    },
    chart: {
      list: ['#f4511e', '#ffb000'],
      pieList: ['#ff7463', '#ffc557', '#cce06e', '#4dd684', '#00dad1', '#1e88e5', '#b777e0']
    },
    badge: {
      primaryContrast: {
        text: '#fff',
        background: '#5572ea'
      }
    }
  })
}
