import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import PersonIcon from '@material-ui/icons/Person'
import PlaceIcon from '@material-ui/icons/Place'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EntityDuplicationDialog from 'support/components/dialog/preconstructed/EntityDuplicationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'
import ComoConheceuEnum from 'support/domain/cliente/ComoConheceuEnum'
import SexoEnum from 'support/domain/cliente/SexoEnum'
import TipoClienteEnum from 'support/domain/cliente/TipoClienteEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import EstadoEnum from 'support/domain/localizacao/EstadoEnum'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { CNPJMask, CPFMask, DataNascimentoMask, getDataNascimentoAnoMaskIdByNumero, InputStringNumberFormat } from 'support/util/Masks'
import { CEPMask } from 'support/util/Masks'
import { InputTelefoneList } from 'support/components/input/InputTelefoneList'


const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

const INPUT_CUSTOM_VARIANT = 'outlined-small'

class ClienteDadosBasicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      cliente: null,
      controleAgendamentoOnline: ControleAgendamentoOnlineEnum.HABILITADO,
      title: this.props.idCliente ? 'Alterar' : 'Criar',
      maskAnoNascimento: false,
      tipo: TipoClienteEnum.PESSOA_FISICA,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
    this.inputApelido = React.createRef()
    this.inputProfissao = React.createRef()
    this.telefonesComponentFunctions = {}
    this.inputEmail = React.createRef()
    this.inputCPF = React.createRef()
    this.inputCNPJ = React.createRef()
    this.inputDataNascimento = React.createRef()
    this.inputRG = React.createRef()
    this.inputAnotacao = React.createRef()
    this.inputInstagram = React.createRef()
    this.inputEnderecoEndereco = React.createRef()
    this.inputEnderecoNumero = React.createRef()
    this.inputEnderecoComplemento = React.createRef()
    this.inputEnderecoBairro = React.createRef()
    this.inputEnderecoCidade = React.createRef()
    this.enderecoEstadoFunctionMap = {}
    this.inputEnderecoCep = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idCliente) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })

      getAPI({
        url: 'erp/clientes.buscarPorId',
        params: {
          id: this.props.idCliente
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState((state) => ({
            cliente: response.data,
            controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(response.data.controleAgendamentoOnline.id),
            sexo: response.data.sexo,
            comoConheceu: response.data.comoConheceu,
            tipo: response.data.tipo
          }))
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  getDados = () => {
    const podeSalvarDadosPessoais = !this.props.idCliente || verificarAutorizacao([PA.PODE_ALTERAR_DADOS_PESSOAIS_CLIENTE])
    const podeSalvarDadosEndereco = !this.props.idCliente || verificarAutorizacao([PA.PODE_ALTERAR_DADOS_ENDERECO_CLIENTE])
    const podeSalvarDadosContato = !this.props.idCliente || verificarAutorizacao([PA.PODE_ALTERAR_DADOS_CONTATO_CLIENTE])

    const dados = {
      id: this.props.idCliente,
      nome: this.inputNome.current.value
    }

    dados.controleAgendamentoOnline = { id: this.state.controleAgendamentoOnline.id }

    if (this.inputApelido.current.value) {
      dados.apelido = this.inputApelido.current.value
    } else {
      dados.apelido = this.inputNome.current.value
    }

    if (podeSalvarDadosPessoais) {
      dados.ignorarDadosPessoais = false

      if (this.state.tipo && this.state.tipo.id === TipoClienteEnum.PESSOA_FISICA.id) {
        if (this.inputProfissao.current.value) {
          dados.profissao = this.inputProfissao.current.value
        } else {
          dados.profissao = null
        }

        let cpf = null
        if (this.inputCPF.current) {
          cpf = this.inputCPF.current.value.replace(/[^0-9]/g, '').trim()

          if (cpf === '') {
            cpf = null
          }
        }
        dados.cpf = cpf

        let rg = null

        if (this.inputRG.current) {
          rg = this.inputRG.current.value.trim()
          if (rg === '') {
            rg = null
          }
        }
        dados.rg = rg
      } else {
        let cnpj = null

        if (this.inputCNPJ.current) {
          cnpj = this.inputCNPJ.current.value.replace(/[^0-9]/g, '').trim()
          if (cnpj === '') {
            cnpj = null
          }
        }
        dados.cnpj = cnpj
      }
    } else {
      dados.ignorarDadosPessoais = true
    }

    let anotacao = this.inputAnotacao.current.value.trim()
    if (anotacao === '') {
      anotacao = null
    }
    dados.anotacao = anotacao

    if (podeSalvarDadosContato) {
      dados.ignorarDadosContato = false

      dados.telefones = this.telefonesComponentFunctions.getValues()
      dados.email = this.inputEmail.current.value

      let instagram = this.inputInstagram.current.value.trim()
      if (instagram === '') {
        instagram = null
      }
      dados.instagram = instagram
    } else {
      dados.ignorarDadosContato = true
    }

    if (this.state.sexo) {
      dados.sexo = {
        id: this.state.sexo.id
      }
    }

    if (this.state.comoConheceu) {
      dados.comoConheceu = {
        id: this.state.comoConheceu.id
      }
    }

    if (this.state.tipo) {
      dados.tipo = {
        id: this.state.tipo.id
      }
    }

    let dataNascimento = null

    if (this.inputDataNascimento.current) {
      dataNascimento = this.inputDataNascimento.current.value
    }

    let diaNascimento, mesNascimento, anoNascimento
    if (dataNascimento) {
      const datas = dataNascimento.replace(/\s+/g, '').split('/')

      diaNascimento = parseInt(datas[0], 10)
      mesNascimento = datas[1] ? parseInt(datas[1], 10) : null
      anoNascimento = datas[2] ? parseInt(datas[2], 10) : null
    }
    dados.diaNascimento = diaNascimento
    dados.mesNascimento = mesNascimento
    dados.anoNascimento = anoNascimento

    if (podeSalvarDadosEndereco) {
      dados.ignorarDadosEndereco = false

      let cep = this.inputEnderecoCep.current.value ? this.inputEnderecoCep.current.value : null
      let estado = {
        id: this.enderecoEstadoFunctionMap.getValue()
      }

      dados.endereco = {
        id: this.state.cliente && this.state.cliente.endereco ? this.state.cliente.endereco.id : null,
        endereco: this.inputEnderecoEndereco.current.value,
        numero: this.inputEnderecoNumero.current.value,
        complemento: this.inputEnderecoComplemento.current.value,
        bairro: this.inputEnderecoBairro.current.value,
        cidade: this.inputEnderecoCidade.current.value,
        estado: estado,
        cep: cep
      }
    } else {
      dados.ignorarDadosEndereco = true
    }

    return dados
  }

  salvar = () => {
    if (this.props.idCliente) {
      this.alterarCliente()
    } else {
      this.criarCliente()
    }
  }

  getClientesDuplicados = (clientesDuplicados) => {
    const clientesDuplicadosFormatado = []

    clientesDuplicados.forEach((clienteDuplicado, index) => {
      let email = ''
      let telefones = ''
      if (clienteDuplicado.usuario && clienteDuplicado.usuario.email) {
        email = clienteDuplicado.usuario.email
      }

      if (clienteDuplicado.telefones && clienteDuplicado.telefones.length > 0) {
        clienteDuplicado.telefones.forEach((telefone, index) => {
          if (telefones) {
            telefones += ', '
          }
          telefones += telefone.numero
        })
      }

      clientesDuplicadosFormatado.push({
        id: clienteDuplicado.id,
        descricao: clienteDuplicado.nome + (email ? ' - ' + email : '') + (telefones ? ' - ' + telefones : ''),
        icone: <PersonIcon />
      })
    })

    return clientesDuplicadosFormatado
  }

  alterarCliente = (ignorarClientesDuplicados) => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = this.getDados()
    dados.ignorarDuplicados = ignorarClientesDuplicados

    postAPI({
      url: 'erp/clientes.alterarCliente',
      data: { dadosCliente: dados },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoCliente', { cliente: response.data })
      },
      onError: (response) => {
        if (response.code === 400 && response.data.errors.clientesDuplicados) {
          openBackableDialog(EntityDuplicationDialog, {
            title: 'Cliente Duplicado',
            descricao: 'O sistema encontrou um ou mais clientes que podem ser duplicados:',
            conteudo: this.getClientesDuplicados(response.data.errors.clientesDuplicados),
            onConfirm: () => {
              this.alterarCliente(true)
            }
          })
          this.setState({ ajaxing: false, errorsMap: createErrorsMap() })
        } else {
          this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
          focusFirstElementWithError('formCliente')
        }
      }
    })
  }

  criarCliente = (ignorarClientesDuplicados) => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = this.getDados()
    dados.ignorarDuplicados = ignorarClientesDuplicados

    postAPI({
      url: 'erp/clientes.criarCliente',
      data: { dadosCliente: dados },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoCliente', { cliente: response.data })
      },
      onError: (response) => {
        if (response.code === 400 && response.data.errors.clientesDuplicados) {
          openBackableDialog(EntityDuplicationDialog, {
            title: 'Cliente Duplicado',
            descricao: 'O sistema encontrou um ou mais clientes que podem ser duplicados:',
            conteudo: this.getClientesDuplicados(response.data.errors.clientesDuplicados),
            onConfirm: () => {
              this.criarCliente(true)
            }
          })
          this.setState({ ajaxing: false, errorsMap: createErrorsMap() })
        } else {
          this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
          focusFirstElementWithError('formCliente')
        }
      }
    })
  }

  handleCheckMask = (value) => {
    const maskAnoNascimento = getDataNascimentoAnoMaskIdByNumero(value)
    this.setState({ maskAnoNascimento: maskAnoNascimento })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, idCliente } = this.props
    const { errorsMap } = this.state
    let { maskAnoNascimento } = this.state
    let objEndereco = {}

    const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
    const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade

    if (this.state.cliente && this.state.cliente.endereco) {
      objEndereco = this.state.cliente.endereco
    }

    const { nome, apelido, telefones, usuario, cpf, cnpj, rg, sexo, comoConheceu, diaNascimento, mesNascimento, anoNascimento, anotacao, instagram, profissao } = this.state.cliente
      ? this.state.cliente
      : {}
    const { endereco, numero, complemento, bairro, cidade, estado, cep } = objEndereco

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    let sexoDefaultValue = sexo
    let comoConheceuDefaultValue = comoConheceu
    const tipoDefaultValue = this.state.tipo
    const tituloDados = tipoDefaultValue.id === TipoClienteEnum.PESSOA_FISICA.id ? 'Informações Pessoais' : 'Informações Corporativas'

    if (this.state.sexo) {
      sexoDefaultValue = this.state.sexo
    }

    if (this.state.comoConheceu) {
      comoConheceuDefaultValue = this.state.comoConheceu
    }

    let dataNascimentoDefaultValue = null

    if (diaNascimento) {
      dataNascimentoDefaultValue = diaNascimento.toString().padStart(2, '0') + mesNascimento.toString().padStart(2, '0')
      if (anoNascimento) {
        dataNascimentoDefaultValue = dataNascimentoDefaultValue + anoNascimento
        maskAnoNascimento = true
      }
    }

    const exibirCamposDadosPessoais = !idCliente || verificarAutorizacao([PA.PODE_ALTERAR_DADOS_PESSOAIS_CLIENTE])
    const exibirCamposDadosEndereco = !idCliente || verificarAutorizacao([PA.PODE_ALTERAR_DADOS_ENDERECO_CLIENTE])
    const exibirCamposDadosContato = !idCliente || verificarAutorizacao([PA.PODE_ALTERAR_DADOS_CONTATO_CLIENTE])

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={580}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formCliente" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={nomeDefaultValue}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome do cliente"
                inputRef={this.inputNome}
                inputProps={{
                  maxLength: 255
                }}
              />

              <InputText
                marginTop={true}
                idname="xapelido"
                defaultValue={apelido}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('apelido')}
                label="Apelido"
                inputRef={this.inputApelido}
                inputProps={{
                  maxLength: 100
                }}
              />

              {getSessaoPainel().negocio.carregado &&
                ControleAgendamentoOnlineEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleAgendamentoOnline.id && (
                  <React.Fragment>
                    <InputSelect
                      idname="xcontroleAtendimentoOnline"
                      style={{ minWidth: 200 }}
                      marginTop={true}
                      label={'Agendamento Online'}
                      disabled={this.state.ajaxing}
                      helpMessage={this.state.controleAgendamentoOnline.descricaoExplicacaoCliente}
                      customVariant="outlined-small"
                      value={this.state.controleAgendamentoOnline.id}
                      onChange={(event) => {
                        const id = event.target.value
                        this.setState({ controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(id) })
                      }}
                    >
                      {ControleAgendamentoOnlineEnum.values().map((controle) => (
                        <MenuItem key={controle.id} value={controle.id}>
                          {controle.descricaoOpcao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </React.Fragment>
                )}

              <InputText
                marginTop={true}
                id="xanotacao"
                name="xanotacao"
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                defaultValue={anotacao}
                errorMessage={errorsMap('anotacao')}
                label="Anotação Importante"
                inputProps={{
                  maxLength: 2000
                }}
                inputRef={this.inputAnotacao}
              />

              <InputSelect
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                value={comoConheceuDefaultValue ? comoConheceuDefaultValue.id : undefined}
                label="Como Conheceu"
                onChange={(event) => {
                  const state = this.state
                  const id = event.target.value
                  state.comoConheceu = ComoConheceuEnum.getById(id)
                  this.setState(state)
                }}
              >
                {ComoConheceuEnum.values().map((comoConheceu) => (
                  <MenuItem key={comoConheceu.id} value={comoConheceu.id}>
                    {comoConheceu.descricao}
                  </MenuItem>
                ))}
              </InputSelect>

              <InputSelect
                customVariant="outlined-small"
                shrink={false}
                marginTop={true}
                value={tipoDefaultValue ? tipoDefaultValue.id : undefined}
                label="Tipo"
                onChange={(event) => {
                  const state = this.state
                  const id = event.target.value
                  state.tipo = TipoClienteEnum.getById(id)
                  this.setState(state)
                }}
              >
                {TipoClienteEnum.values().map((tipoCliente) => (
                  <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                    {tipoCliente.descricao}
                  </MenuItem>
                ))}
              </InputSelect>

              {exibirCamposDadosContato && (
                <React.Fragment>
                  <SectionSeparator icon={<ContactPhoneIcon />} title="Contato" />

                  <InputText
                    idname="xemail"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={usuario ? usuario.email : ''}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('email')}
                    label="E-mail"
                    inputRef={this.inputEmail}
                  />

                  <InputText
                    marginTop={true}
                    idname="xinstagram"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={instagram}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('instagram')}
                    label="Instagram"
                    inputRef={this.inputInstagram}
                  />

                  <InputTelefoneList
                    defaultPhones={telefones}
                    maxNumberOfPhones={5}
                    disabled={this.state.ajaxing}
                    errorsMap={this.state.errorsMap}
                    componentFunctions={this.telefonesComponentFunctions}
                  />

                </React.Fragment>
              )}

              {((this.state.tipo && this.state.tipo.id === TipoClienteEnum.PESSOA_FISICA.id) || exibirCamposDadosPessoais) && (
                <SectionSeparator icon={<PersonIcon />} title={tituloDados} />
              )}

              {this.state.tipo && this.state.tipo.id === TipoClienteEnum.PESSOA_JURIDICA.id && exibirCamposDadosPessoais && (
                <InputText
                  idname="xcnpj"
                  customVariant="outlined-small"
                  shrink={false}
                  defaultValue={cnpj}
                  disabled={this.state.ajaxing}
                  errorMessage={errorsMap('cnpj')}
                  label="CNPJ"
                  inputComponent={CNPJMask}
                  inputRef={this.inputCNPJ}
                />
              )}

              {this.state.tipo && this.state.tipo.id === TipoClienteEnum.PESSOA_FISICA.id && (
                <React.Fragment>
                  <InputText
                    idname="xdatanascimento"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={dataNascimentoDefaultValue}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('dataNascimento')}
                    label="Data de Nascimento"
                    helpMessage="Utilize dia/mês (ex: 01/06) ou dia/mês/ano (ex: 01/06/1988)"
                    inputComponent={DataNascimentoMask}
                    inputRef={this.inputDataNascimento}
                    inputProps={{ maskAnoNascimento: maskAnoNascimento }}
                    onChange={(event) => this.handleCheckMask(event.target.value)}
                  />

                  <InputSelect
                    marginTop={true}
                    customVariant="outlined-small"
                    shrink={false}
                    value={sexoDefaultValue ? sexoDefaultValue.id : undefined}
                    label="Sexo"
                    onChange={(event) => {
                      const state = this.state
                      const id = event.target.value
                      state.sexo = SexoEnum.getById(id)
                      this.setState(state)
                    }}
                  >
                    {SexoEnum.values().map((regra) => (
                      <MenuItem key={regra.id} value={regra.id}>
                        {regra.descricao}
                      </MenuItem>
                    ))}
                  </InputSelect>

                  {exibirCamposDadosPessoais && (
                    <React.Fragment>
                      <InputText
                        marginTop={true}
                        idname="xcpf"
                        customVariant="outlined-small"
                        shrink={false}
                        defaultValue={cpf}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('cpf')}
                        label="CPF"
                        inputComponent={CPFMask}
                        inputRef={this.inputCPF}
                      />

                      <InputText
                        marginTop={true}
                        idname="xrg"
                        customVariant="outlined-small"
                        shrink={false}
                        defaultValue={rg}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('rg')}
                        label="RG"
                        inputRef={this.inputRG}
                      />

                      <InputText
                        marginTop={true}
                        idname="xprofissao"
                        defaultValue={profissao}
                        customVariant="outlined-small"
                        shrink={false}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('profissao')}
                        label="Profissão"
                        inputRef={this.inputProfissao}
                        inputProps={{
                          maxLength: 100
                        }}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {exibirCamposDadosEndereco && (
                <React.Fragment>
                  <SectionSeparator icon={<PlaceIcon />} title="Endereço" />

                  <InputHorizontalContainer
                    customVariant={INPUT_CUSTOM_VARIANT}
                    inputTexts={[
                      {
                        input: (
                          <InputText
                            idname="xendereco"
                            customVariant={INPUT_CUSTOM_VARIANT}
                            shrink={false}
                            defaultValue={endereco}
                            disabled={this.state.ajaxing}
                            errorMessage={errorsMap('endereco.endereco')}
                            label="Endereço"
                            inputProps={{
                              maxLength: 255
                            }}
                            inputRef={this.inputEnderecoEndereco}
                          />
                        )
                      }
                    ]}
                  />

                  <InputHorizontalContainer
                    customVariant={INPUT_CUSTOM_VARIANT}
                    style={{ marginTop: 14 }}
                    inputTexts={[
                      {
                        containerProps: { xs: 5, sm: 4 },
                        input: (
                          <InputText
                            idname="xenderecoNumero"
                            customVariant={INPUT_CUSTOM_VARIANT}
                            shrink={false}
                            defaultValue={numero}
                            inputComponent={InputStringNumberFormat}
                            disabled={this.state.ajaxing}
                            errorMessage={errorsMap('endereco.numero')}
                            label="Número"
                            inputProps={{
                              maxLength: 10
                            }}
                            inputRef={this.inputEnderecoNumero}
                          />
                        )
                      },
                      {
                        containerProps: { xs: 7, sm: 8 },
                        input: (
                          <InputText
                            idname="xenderecoComplemento"
                            customVariant={INPUT_CUSTOM_VARIANT}
                            shrink={false}
                            disabled={this.state.ajaxing}
                            defaultValue={complemento}
                            errorMessage={errorsMap('endereco.complemento')}
                            label="Complemento"
                            inputProps={{
                              maxLength: 255
                            }}
                            inputRef={this.inputEnderecoComplemento}
                          />
                        )
                      }
                    ]}
                  />

                  <InputHorizontalContainer
                    customVariant={INPUT_CUSTOM_VARIANT}
                    style={{ marginTop: 14 }}
                    inputTexts={[
                      {
                        input: (
                          <InputText
                            idname="xenderecoBairro"
                            customVariant={INPUT_CUSTOM_VARIANT}
                            shrink={false}
                            defaultValue={bairro}
                            disabled={this.state.ajaxing}
                            errorMessage={errorsMap('endereco.bairro')}
                            label="Bairro"
                            inputProps={{
                              maxLength: 255
                            }}
                            inputRef={this.inputEnderecoBairro}
                          />
                        )
                      }
                    ]}
                  />

                  <InputHorizontalContainer
                    customVariant={INPUT_CUSTOM_VARIANT}
                    style={{ marginTop: 14 }}
                    inputTexts={[
                      {
                        input: (
                          <InputText
                            idname="xenderecoCidade"
                            customVariant={INPUT_CUSTOM_VARIANT}
                            shrink={false}
                            disabled={this.state.ajaxing}
                            defaultValue={cidade}
                            errorMessage={errorsMap('endereco.cidade')}
                            label="Cidade"
                            inputProps={{
                              maxLength: 255
                            }}
                            inputRef={this.inputEnderecoCidade}
                          />
                        )
                      }
                    ]}
                  />

                  <InputHorizontalContainer
                    customVariant={INPUT_CUSTOM_VARIANT}
                    style={{ marginTop: 14 }}
                    inputTexts={[
                      {
                        containerProps: { xs: 7 },
                        input: (
                          <InputSelect
                            idname="xenderecoEstado"
                            customVariant="outlined-small"
                            shrink={false}
                            label="Estado"
                            defaultValue={estado ? estado.id : null}
                            disabled={this.state.ajaxing}
                            errorMessage={errorsMap('endereco.estado')}
                            helpMessage="Selecione o estado"
                            functionsMap={this.enderecoEstadoFunctionMap}
                          >
                            {EstadoEnum.values().map((estado) => (
                              <MenuItem key={estado.id} value={estado.id}>
                                {estado.nome}
                              </MenuItem>
                            ))}
                          </InputSelect>
                        )
                      },
                      {
                        containerProps: { xs: 5 },
                        input: (
                          <InputText
                            idname="xenderecoCep"
                            customVariant="outlined-small"
                            shrink={false}
                            defaultValue={cep}
                            disabled={this.state.ajaxing}
                            errorMessage={errorsMap('endereco.cep')}
                            label="CEP"
                            inputComponent={CEPMask}
                            inputRef={this.inputEnderecoCep}
                          />
                        )
                      }
                    ]}
                  />
                </React.Fragment>
              )}

              <HiddenSubmitButton />

              <br />

              {this.state.paginaCarregada && (
                <Grid container>
                  <Grid item xs style={{ paddingRight: 16 }}>
                    <Typography variant="body2">
                      Certifique-se de obter a autorização de pais ou responsáveis para cadastro de menores de 13 anos. Os dados serão tratados nos termos de nossa{' '}
                      <Link color="secondary" underline="always" {...politicaPrivacidadeLinkProps} rel="noopener noreferrer">
                        Política de Privacidade
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div className={classes.containerFooterButtons}>
                      <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                        Cancelar
                      </Button>
                      <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                        {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ClienteDadosBasicosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ClienteDadosBasicosDialogPage)
