import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const styles = (theme) => ({
  main: {},
  thumbShowOnHover: {
    opacity: 0,
    '$main:hover &': {
      opacity: 1
    }
  }
})

class CustomScrollbars extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.elementRef = React.createRef()
    this.state = {
      mounted: false
    }
  }

  componentDidMount() {
    if (this.props.applyWillChangeOnScrollContainer && this.elementRef.current) {
      //this.elementRef.current.container.children[0].style.willChange = "transform";
      this.elementRef.current.container.children[0].classList.add('needsWillChange')
    }
    if (this.props.horizontalScrollDisabled && this.elementRef.current) {
      this.elementRef.current.container.children[0].style.overflowX = 'hidden'
      this.elementRef.current.container.children[0].style.marginBottom = '0px'
    }
    if (this.props.verticalScrollDisabled && this.elementRef.current) {
      this.elementRef.current.container.children[0].style.overflowY = 'hidden'
      this.elementRef.current.container.children[0].style.marginRight = '0px'
    }
    this.setState({ mounted: true })
    this.eventsManager.sub('CustomScrollbars', (props) => {
      if (props.action === 'forceUpdate') {
        window.setTimeout(
          () => {
            this.forceUpdate()
          },
          props.delay ? props.delay : 0
        )
      }
    })
    if (this.props.functionsMap) {
      this.props.functionsMap['scrollTop'] = this.scrollTop
      this.props.functionsMap['getScrollRef'] = this.getScrollRef
    }
    if (this.props.setScrollbarRef) {
      this.props.setScrollbarRef(this.elementRef)
    }
  }

  scrollTop = (top, force) => {
    if (this.elementRef.current && this.elementRef.current.scrollTop) {
      const scrollTopFunction = (delay, callback) => {
        window.setTimeout(() => {
          const element = this.elementRef.current
          if (element) {
            element.scrollTop(top)
            if (callback) {
              callback()
            }
          }
        }, delay)
      }

      if (!force) {
        scrollTopFunction(0)
      } else {
        scrollTopFunction(0, () => {
          scrollTopFunction(30, () => {
            scrollTopFunction(30, () => {
              scrollTopFunction(50, () => {
                scrollTopFunction(100)
              })
            })
          })
        })
      }
    }
  }

  getScrollRef = () => {
    if (this.elementRef.current) {
      return this.elementRef.current
    }
    return null
  }

  render() {
    const { classes } = this.props

    let color = '#e0e0e0'
    if (this.props.color) {
      color = this.props.color
    }

    const hideStyle = {}
    if (this.props.show === false) {
      hideStyle.display = 'none'
    }

    let children = null
    if (this.state.mounted) {
      children = this.props.children
      if (typeof children == 'function') {
        /*if(this.elementRef.current){
					console.log(this.elementRef.current.container.children[0]);
				}*/
        children = children({ scrollElement: this.elementRef.current ? this.elementRef.current.container.children[0] : false })
      }
    }

    let renderTrackVertical = this.props.renderTrackVertical
    const verticalStyle = {}
    if (this.props.verticalScrollDisabled) {
      verticalStyle.display = 'none'
    }
    if (this.props.verticalScrollWidth) {
      verticalStyle.width = this.props.verticalScrollWidth
    }

    let thumbClassNames = []
    if (this.props.showOnHover) {
      thumbClassNames.push(classes.thumbShowOnHover)
    }

    if (!renderTrackVertical) {
      renderTrackVertical = ({ style, ...props }) => <div {...props} className={classNames(thumbClassNames)} style={{ ...style, ...verticalStyle, right: 0, height: '100%' }} />
    }

    let renderThumbVertical = this.props.renderThumbVertical
    if (!renderThumbVertical) {
      renderThumbVertical = ({ style, ...props }) => (
        <div {...props} className={classNames(thumbClassNames)} style={{ ...style, ...verticalStyle, ...hideStyle, backgroundColor: color, borderRadius: 3 }} />
      )
    }

    return (
      <Scrollbars
        className={classNames(classes.main, 'needsWillChange')}
        autoHide={this.props.autoHide}
        style={{ WebkitOverflowScrolling: 'touch' }}
        ref={this.elementRef}
        onScroll={this.props.onScroll ? (event) => this.props.onScroll(event, this.elementRef.current) : undefined}
        onScrollStart={this.props.onScrollStart}
        onScrollStop={this.props.onScrollStop}
        onUpdate={this.props.onUpdate}
        hideTracksWhenNotNeeded={this.props.hideTracksWhenNotNeeded}
        renderTrackVertical={renderTrackVertical}
        renderThumbVertical={renderThumbVertical}
        renderThumbHorizontal={({ style, ...props }) => (
          <div {...props} className={classNames(thumbClassNames)} style={{ ...style, ...hideStyle, backgroundColor: color, borderRadius: 3 }} />
        )}
      >
        {children}
      </Scrollbars>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

CustomScrollbars.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CustomScrollbars)
