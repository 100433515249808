import { useEffect, useMemo, useRef, useState } from 'react'

import { StylesTheme } from '.'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { StatusPagamentoEnum, StatusPagamentoType } from 'support/domain/mercadopago/MercadoPagoEnum'

import NotaDialogPage from '../NotaDialogPage'

export type StatusType = 'loading' | 'error' | 'success'

type StatusPagamentoProps = {
  status: StatusPagamentoType
  statusType: StatusType
  classes: StylesTheme
  handleCloseDialog?: () => void
  mercadoPagoPagamentoId: number
  mercadoPayment?: {
    notaVenda: {
      id: number
    }
  }
  mercadoPago?: {
    tipo: {
      id: number
      tipo: {
        id: number
      }
      nome: string
      ativo: boolean
      excluido: boolean
      permiteExclusao: boolean
      _rerender: boolean
      _update: boolean
    }
    formaPagamento: {
      id: number
      tipo: {
        id: number
      }
      nome: string
      ativo: boolean
      excluido: boolean
      permiteExclusao: boolean
      _rerender: boolean
      _update: boolean
    }
    parcelas: number
    deviceId: string
    valor: number
  }
}

export const StatusPagamento = ({ mercadoPagoPagamentoId, status, statusType, classes, mercadoPago, mercadoPayment, handleCloseDialog }: StatusPagamentoProps) => {
  const TIMEOUT = 5000
  const timeout = useRef<NodeJS.Timeout>()
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (statusType === 'success') {
      timeout.current = setTimeout(() => {
        openBackableDialog(NotaDialogPage, {
          idNota: mercadoPayment?.notaVenda.id,
          retornarVendas: false,
          maquininha: mercadoPago
        })
      }, TIMEOUT)

      return () => clearTimeout(timeout.current)
    }
  }, [mercadoPago, statusType, mercadoPayment?.notaVenda.id])

  const handleCancelPayment = () => {
    setIsDisabled(true)
    postAPI({
      method: 'post',
      url: `erp/vendas/integracoes-mp.cancelar/${mercadoPagoPagamentoId}`,
      requerAutorizacao: true,
      onSuccess: () => {
        handleCloseDialog?.()
      },
      onFinally: () => {
        setIsDisabled(false)
      }
    })
  }

  const title = useMemo(() => {
    const condition = {
      loading: 'Finalize o pagamento na sua maquininha',
      error: 'Tivemos um problema com seu pagamento',
      success: 'Tudo certo com o pagamento!'
    }
    return condition[statusType]
  }, [statusType])

  const subTitle = useMemo(() => {
    const condition = {
      loading: '',
      error: 'Tente novamente',
      success: 'O pagamento foi recebido com sucesso'
    }
    return condition[statusType]
  }, [statusType])

  return (
    <Box minHeight={451} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {statusType === 'error' && <CancelOutlined color="secondary" className={classes.icon} fontSize="large" />}
      {statusType === 'loading' && <CircularProgress color="secondary" className={classes.spinner} size="xl" />}
      {statusType === 'success' && <CheckCircleOutline color="secondary" className={classes.icon} fontSize="large" />}
      <Typography className={classes.h1}>{title}</Typography>
      {statusType === 'loading' && (
        <>
          <Typography className={classes.h2}>
            Sua maquininha é <b>SMART</b>? Clique em cobrar.
          </Typography>
          <Typography className={classes.h2}>
            Sua maquininha é <b>POINT PRO2</b>? Aperte o botão verde.
          </Typography>
        </>
      )}
      <Typography className={classes.p}>{subTitle}</Typography>
      {status === StatusPagamentoEnum.ABERTO && (
        <Button disabled={isDisabled} variant="contained" color="secondary" onClick={handleCancelPayment} className={classes.button}>
          Cancelar
        </Button>
      )}
    </Box>
  )
}
