import { styled } from '@grupoboticario/flora'

export const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'unset',
  rowGap: '24px',
  columnGap: '24px',
  '@fullscreen': {
    gridTemplateColumns: 'minmax(460px, 1fr) minmax(460px, 1fr)',
    gridTemplateRows: '1fr 1fr 1fr'
  }
})
