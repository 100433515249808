import React, { Component } from 'react'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  menuIcon: {
    fontSize: 22,
    //color: "#ffffff",
    color: theme.palette.text.primary,
    '.selected &': {
      color: theme.palette.secondary.main
      //color: theme.palette.drawer.selected.icon,
      //color: theme.palette.secondary.light
    }
  },
  menuContainerIcon: {
    minWidth: 46
  },
  menuContainerIconRetracted: {
    minWidth: 100
  }
})

class DrawerMenuItemIcon extends Component {
  render() {
    const { classes, isMenuRetracted, containerProps, icon, tooltipTitle } = this.props

    const containerClasses = [classes.menuContainerIcon]
    let iconContent = (
      <React.Fragment>
        <icon.component className={classes.menuIcon} {...icon.props} />
      </React.Fragment>
    )

    if (isMenuRetracted) {
      containerClasses.push(classes.menuContainerIconRetracted)
    }

    iconContent = (
      <ListItemIcon className={classNames(containerClasses)} {...containerProps}>
        {iconContent}
      </ListItemIcon>
    )

    if (isMenuRetracted) {
      iconContent = <Tooltip title={tooltipTitle ? tooltipTitle : ''}>{iconContent}</Tooltip>
    }

    return iconContent
  }
}

DrawerMenuItemIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  isMenuRetracted: PropTypes.bool,
  icon: PropTypes.shape({
    component: PropTypes.elementType.isRequired,
    props: PropTypes.object
  }).isRequired,
  tooltipTitle: PropTypes.string
}

export default withStyles(styles)(DrawerMenuItemIcon)
