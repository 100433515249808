import { ItemMenu } from './components/ItemMenu'
import * as S from './NewRelatorioFinaceiroPage.styles'

type NewRelatorioFinaceiroPageProps = {
  onOpenFinancialReport: () => void
  onOpenServiceReport: () => void
  onOpenCollaboratorsReport: () => void
  onOpenCustomersReport: () => void
  onOpenCustomersAwaysReport: () => void
  onOpenCustomersActiveReport: () => void
  onOpenStoreReport: () => void
}

export const NewRelatorioFinaceiroPage = ({
  onOpenFinancialReport,
  onOpenServiceReport,
  onOpenCollaboratorsReport,
  onOpenCustomersActiveReport,
  onOpenCustomersAwaysReport,
  onOpenCustomersReport,
  onOpenStoreReport
}: NewRelatorioFinaceiroPageProps) => {
  const dataLayer = window.dataLayer || []

  const handleClick = (name: string, cb: () => void) => {
    dataLayer.push({
      event: 'analytics-event',
      event_name: 'interaction_relatorios',
      cd_interaction_detail: `click:${name}`
    })
    cb()
  }
  const menuItems = [
    {
      onClick: () => handleClick('relatorio-financeiro', onOpenFinancialReport),
      icon: 'tag',
      title: 'Relatório Financeiro',
      description: 'Confira valores recebidos, despesas e dados sobre o seu negócio'
    },
    {
      onClick: () => handleClick('relatorio-atendimento', onOpenServiceReport),
      icon: 'tag',
      title: 'Relatório de Atendimentos',
      description: 'Confira os detalhes dos seus atendimentos, produtos, serviços e pacotes por período'
    },
    {
      onClick: () => handleClick('colaboradores', onOpenCollaboratorsReport),
      icon: 'people',
      title: 'Colaboradores',
      description: 'Confira os colaboradores mais rentáveis para seu negócio.'
    },
    {
      onClick: () => handleClick('clientes', onOpenCustomersReport),
      icon: 'person',
      title: 'Clientes',
      description: 'Confira os dados de seus clientes, como gasto médio e mais.'
    },
    {
      onClick: () => handleClick('clientes-sumidos', onOpenCustomersAwaysReport),
      icon: 'minus',
      title: 'Clientes Sumidos',
      description: 'Confira os clientes sumidos e o tempo de ausência.'
    },
    {
      onClick: () => handleClick('clientes-ativos', onOpenCustomersActiveReport),
      icon: 'smile',
      title: 'Clientes Ativos',
      description: 'Confira os clientes que realizaram compras recentemente.'
    },
    { onClick: () => handleClick('estoque', onOpenStoreReport), icon: 'square', title: 'Estoque', description: 'Confira a movimentação de estoque de seu negócio.' }
  ]
  return (
    <S.Container>
      {menuItems.map((menu) => (
        <ItemMenu key={menu.title} onClick={menu.onClick} icons={menu.icon} title={menu.title} description={menu.description} />
      ))}
    </S.Container>
  )
}
