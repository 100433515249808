import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'

const styles = (theme) => ({})

class VisualizacaoEmailDialogPage extends Component {
  static isDialogPage = true

  render() {
    const dialogProps = extractDialogProps(this.props)

    return (
      <DialogPage {...dialogProps} title="Visualização de E-mail" align="center" contentMaxWidth={800} pageType="basicForm">
        <Typography variant="body2">
          <b>Para:</b> {this.props.destinatario}
        </Typography>
        <Typography variant="body2">
          <b>Assunto:</b> {this.props.assunto}
        </Typography>

        <div dangerouslySetInnerHTML={{ __html: this.props.conteudo }} style={{ marginTop: 24 }}></div>
      </DialogPage>
    )
  }
}

VisualizacaoEmailDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VisualizacaoEmailDialogPage)
