import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import RemuneracaoPagamentoDialogPage from 'pages/erp/painel/remuneracao/RemuneracaoPagamentoDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import FormUtil from 'support/util/FormUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaPagamento: {
    flexGrow: 0.7
  }
})

class RemuneracoesPagamentosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoPagamentoComissao', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  exibirDetalhesDialogPage = () => {
    openBackableDialog(RemuneracaoPagamentoDialogPage, {
      idPagamento: this.state.itemMenu.id
    })
  }

  openMenu = (item, event) => {
    event.stopPropagation()
    this.setState({
      menuAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      },
      itemMenu: item
    })
  }

  closeMenu = (event) => {
    event.stopPropagation()
    this.setState({ menuAnchorPosition: null })
  }

  handlerRemover = () => {
    if (verificarAutorizacao([PA.PODE_PAGAR_REMUNERACOES])) {
      openBackableDialog(EntityRemovalDialog, {
        title: 'Remover',
        text: <span>Você tem certeza que deseja remover o pagamento #{this.state.itemMenu.numero}? Essa operação não poderá ser desfeita.</span>,
        call: {
          method: 'post',
          url: 'erp/colaborador/remuneracoes.removerPagamentoRemuneracao',
          contextoUsuario: 'erp',
          data: {
            idPagamento: this.state.itemMenu.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoPagamentoComissao')
          },
          errorKey: 'caixa'
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.numero = '#' + item.numero
    content.data = converterDataIntParaMoment(item.dataPagamento).format('DD/MMM/YYYY')
    if (item.periodoDataInicial) {
      content.periodo = converterDataIntParaMoment(item.periodoDataInicial).format('DD/MMM/YY') + ' - ' + converterDataIntParaMoment(item.periodoDataFinal).format('DD/MMM/YY')
    }
    content.valor = formatarValorMonetario(item.valorTotalQuitado)
    content.botao = (
      <IconButton
        onClick={(event) => {
          this.openMenu(item, event)
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { menuAnchorPosition } = this.state
    const { classes } = this.props

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.lista) {
      valorTotal += item.valorPagamento
      quantidadeTotal++
    }

    return (
      <DialogPage {...dialogProps} title="Histórico de Pagamentos" ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">{this.props.colaborador.apelido}</Typography>
              <Typography variant="body1">Lista de todos os pagamentos de remunerações registrados</Typography>
            </FloatContent>

            <div>
              <VirtualizedResponsiveTable
                scrollElement={dialogContentProps.scrollContainerRef.current}
                showBackgroundDividers={true}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="erp"
                endpoint="erp/colaborador/remuneracoes.buscarRemuneracoesPagamento"
                getRequestParametersFunction={() => ({
                  idColaborador: this.props.colaborador.id
                })}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    this.setState({ lista: data.items })
                  }
                }}
                onClickRow={(item, event) => {
                  this.openMenu(item, event)
                }}
                largeRenderProps={{
                  columns: [
                    { label: 'Pagamento', props: { xs: true }, className: classes.colunaPagamento },
                    { label: 'Data', props: { xs: true } },
                    { label: 'Período', props: { xs: true } },
                    { label: 'Valor Pago', props: { xs: true } },
                    {
                      label: (
                        <IconButton key="remover">
                          <MoreVertIcon />
                        </IconButton>
                      ),
                      isVisible: false,
                      horizontalPadding: 'small',
                      cellPaddingDiff: 14
                    }
                  ],
                  itemRenderer: (comissao, index) => {
                    const content = this.getRenderContent('large', comissao)
                    return {
                      itemData: [content.numero, content.data, content.periodo, content.valor, content.botao]
                    }
                  }
                }}
                smallRenderProps={{
                  rowHeight: 110,
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('small', item)
                    return {
                      itemData: (
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography variant="body2">
                              Pagamento <b>{content.numero}</b>
                            </Typography>
                            <Typography variant="body2">
                              Data: <b>{content.data}</b>
                            </Typography>
                            {content.periodo && (
                              <Typography variant="body2">
                                Período: <b>{content.periodo}</b>
                              </Typography>
                            )}
                            <Typography variant="body2">
                              Valor: <b>{content.valor}</b>
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginLeft: 8 }}>
                            {content.botao}
                          </Grid>
                        </Grid>
                      )
                    }
                  }
                }}
                emptyListProps={{
                  text: 'Nenhum pagamento de comissão foi encontrado'
                }}
              />

              <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={(event) => this.closeMenu(event)}>
                <MenuItem
                  onClick={(event) => {
                    this.exibirDetalhesDialogPage()
                    this.closeMenu(event)
                  }}
                >
                  Informações Detalhadas
                </MenuItem>
                <MenuItem
                  disabled={!verificarAutorizacao([PA.PODE_PAGAR_REMUNERACOES])}
                  onClick={(event) => {
                    this.handlerRemover()
                    this.closeMenu(event)
                  }}
                >
                  Remover Pagamento
                </MenuItem>
              </Menu>
            </div>

            {quantidadeTotal > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Pagamentos: <b>{quantidadeTotal}</b>
                  <br />
                  Total: <b>{formatarValorMonetario(valorTotal)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RemuneracoesPagamentosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RemuneracoesPagamentosDialogPage)
