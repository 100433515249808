import loadImage from 'blueimp-load-image'

export function readAsDataURL(files, callback) {
  if (FileReader && files) {
    if (Array.isArray(files)) {
      let datas = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        readFileAsDataURL(file, (data) => {
          datas[i] = data
          if (files.length === datas.filter((d) => d !== null).length) {
            callback(datas)
          }
        })
      }
    } else {
      readFileAsDataURL(files, callback)
    }
  } else {
    callback(null)
  }
}

function readFileAsDataURL(file, clientCallback) {
  if (FileReader && file) {
    var fr = new FileReader()
    fr.onload = function () {
      const fileObject = {
        dataURL: fr.result,
        getDataURL: function () {
          return this.dataURL
        },
        getMimeType: function () {
          return this.dataURL.substring(0, this.dataURL.indexOf(',')).replace('data:', '').replace(';base64', '')
        },
        getDataAsBase64: function () {
          return this.dataURL.substring(this.dataURL.indexOf(',') + 1)
        }
      }

      if (fileObject.getMimeType().toLowerCase().indexOf('image') >= 0) {
        readImageFileAsDataURL(file, clientCallback)
      } else {
        clientCallback(fileObject)
      }
    }
    fr.readAsDataURL(file)
  } else {
    clientCallback(null)
  }
}

function readImageFileAsDataURL(file, clientCallback) {
  if (FileReader && file) {
    loadImage(
      file,
      (img) => {
        //console.log(img);

        const width = img.width,
          height = img.height,
          canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d')

        canvas.width = width
        canvas.height = height

        ctx.drawImage(img, 0, 0)

        const base64data = canvas.toDataURL()
        const fileObject = {
          dataURL: base64data,
          getDataURL: function () {
            return this.dataURL
          },
          getMimeType: function () {
            return this.dataURL.substring(0, this.dataURL.indexOf(',')).replace('data:', '').replace(';base64', '')
          },
          getDataAsBase64: function () {
            return this.dataURL.substring(this.dataURL.indexOf(',') + 1)
          }
        }
        clientCallback(fileObject)
      },
      { orientation: true }
    )

    /*
		var callbackArrayBufferRead = function (srcOrientation) {
            var reader2 = new FileReader();
            reader2.onload = function (e) {
                var srcBase64 = e.target.result;
                var img = new Image();

                img.onload = function () {
                    var width = img.width,
                        height = img.height,
                        canvas = document.createElement('canvas'),
                        ctx = canvas.getContext("2d");

                    // set proper canvas dimensions before transform & export
                    if (4 < srcOrientation && srcOrientation < 9) {
                        canvas.width = height;
                        canvas.height = width;
                    } else {
                        canvas.width = width;
                        canvas.height = height;
                    }

                    // transform context before drawing image
                    switch (srcOrientation) {
                        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                        default: break;
                    }

                    // draw image
                    ctx.drawImage(img, 0, 0);

                    // export base64
					const fileObject = {
						dataURL : canvas.toDataURL(),
						getDataURL : function(){
							return this.dataURL;
						},
						getMimeType : function(){
							return this.dataURL.substring(0,this.dataURL.indexOf(",")).replace("data:","").replace(";base64","");
						},
						getDataAsBase64 : function(){
							return this.dataURL.substring(this.dataURL.indexOf(",")+1);
						}
					}
					clientCallback(fileObject);
                };

                img.src = srcBase64;
            }

            reader2.readAsDataURL(file);
        }

		var reader = new FileReader();
        reader.onload = function (e) {

            var view = new DataView(e.target.result);
            if (view.getUint16(0, false) !== 0xFFD8) return callbackArrayBufferRead(-2);
            var length = view.byteLength, offset = 2;
            while (offset < length) {
                var marker = view.getUint16(offset, false);
                offset += 2;
                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) return callbackArrayBufferRead(-1);
                    var little = view.getUint16(offset += 6, false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;
                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) === 0x0112)
                            return callbackArrayBufferRead(view.getUint16(offset + (i * 12) + 8, little));
                }
                else if ((marker & 0xFF00) !== 0xFF00) break;
                else offset += view.getUint16(offset, false);
            }
            return callbackArrayBufferRead(-1);
        };
        reader.readAsArrayBuffer(file);
		*/
  } else {
    clientCallback(null)
  }
}

const functionsObject = {
  readAsDataURL: readAsDataURL
}

export default functionsObject
