import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'
import moment from 'moment'
import AtendimentosListaPesquisaAvancadaDialog from 'pages/erp/painel/atendimento/AtendimentosListaPesquisaAvancadaDialog'
import AtendimentosResponsiveTable from 'pages/erp/painel/atendimento/table/AtendimentosResponsiveTable'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import VendaSupport from 'pages/erp/painel/venda/VendaSupport'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import FloatContent from 'support/components/page/FloatContent'
import AtendimentoStatusServicoEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import { converterDateParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { createEnum } from 'support/util/EnumUtil'
import { converterMomentParaHorarioInt } from 'support/util/TimeConverter'

export const StatusFiltroEnum = createEnum({
  TODOS: { id: 1, descricao: 'Todos', statusId: null },
  CONFIRMADOS: { id: 2, descricao: 'Confirmados', statusId: AtendimentoStatusServicoEnum.CONFIRMADO.id, status: AtendimentoStatusServicoEnum.CONFIRMADO },
  CLIENTE_CHEGOU: { id: 3, descricao: 'Cliente Chegou', statusId: AtendimentoStatusServicoEnum.CLIENTE_CHEGOU.id, status: AtendimentoStatusServicoEnum.CLIENTE_CHEGOU },
  EM_ATENDIMENTO: { id: 4, descricao: 'Em Atendimento', statusId: AtendimentoStatusServicoEnum.EM_ATENDIMENTO.id, status: AtendimentoStatusServicoEnum.EM_ATENDIMENTO },
  CONCLUIDOS: { id: 5, descricao: 'Concluídos', statusId: AtendimentoStatusServicoEnum.CONCLUIDO.id, status: AtendimentoStatusServicoEnum.CONCLUIDO },
  CLIENTE_FALTOU: { id: 6, descricao: 'Cliente Faltou', statusId: AtendimentoStatusServicoEnum.CLIENTE_FALTOU.id, status: AtendimentoStatusServicoEnum.CLIENTE_FALTOU },
  CANCELADOS: { id: 7, descricao: 'Cancelados', statusId: AtendimentoStatusServicoEnum.CANCELADO.id, status: AtendimentoStatusServicoEnum.CANCELADO }
})

export const StatusVendaFiltroEnum = createEnum({
  TODOS: { id: 1, descricao: 'Todos', statusVendaId: null },
  SEM_NOTA: { id: 2, descricao: 'Pendente - Sem Fatura', statusVendaId: StatusVendaEnum.NOTA_PENDENTE.id, statusVenda: StatusVendaEnum.NOTA_PENDENTE },
  NOTA_ABERTA: { id: 3, descricao: 'Com Fatura Aberta', statusVendaId: StatusVendaEnum.NOTA_ABERTA.id, statusVenda: StatusVendaEnum.NOTA_ABERTA },
  NOTA_FECHADA: { id: 4, descricao: 'Com Fatura Fechada', statusVendaId: StatusVendaEnum.NOTA_FECHADA.id, statusVenda: StatusVendaEnum.NOTA_FECHADA },
  CANCELADAS: { id: 5, descricao: 'Canceladas', statusVendaId: null }
})

const styles = (theme) => ({
  botaoAdicionarGrande: {
    [theme.breakpoints.down(400)]: {
      display: 'none'
    }
  },
  botaoAdicionarPequeno: {
    [theme.breakpoints.up(400)]: {
      display: 'none'
    }
  }
})

class AtendimentosListaPanelPageContent extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      status: StatusFiltroEnum.TODOS,
      statusVenda: StatusVendaFiltroEnum.TODOS,
      apenasPossuiConflitoHorario: false,
      lista: []
    }
    this.loaderFunctionsMap = {}
    this.inputPalavraChave = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['RecarregarAtendimentos', 'ManipulacaoVenda', 'ManipulacaoVendas'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  abrirPesquisaAvancadaDialog = () => {
    openBackableDialog(AtendimentosListaPesquisaAvancadaDialog, {
      status: this.state.status,
      statusVenda: this.state.statusVenda,
      colaborador: this.state.colaborador,
      cliente: this.state.cliente,
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal,
      apenasPossuiConflitoHorario: this.state.apenasPossuiConflitoHorario,
      setParentState: (state) => {
        this.setState(state)
      }
    })
  }

  getRequestParameters = () => {
    let cancelado = null
    if (this.state.statusVenda.id === StatusVendaFiltroEnum.CANCELADAS.id) {
      cancelado = true
    } else if (this.state.statusVenda.statusVenda) {
      cancelado = false
    }

    let dataInicial = converterDateParaDataInt(this.state.dataInicial)
    const hojeAsDataInt = converterDateParaDataInt(new Date())

    if (!dataInicial) {
      dataInicial = hojeAsDataInt
    }

    return {
      palavraChave: this.inputPalavraChave.current ? this.inputPalavraChave.current.value : null,
      idStatus: StatusFiltroEnum.getById(this.state.status.id).statusId,
      idStatusVenda: StatusVendaFiltroEnum.getById(this.state.statusVenda.id).statusVendaId,
      idColaborador: this.state.colaborador ? this.state.colaborador.id : null,
      idCliente: this.state.cliente ? this.state.cliente.id : null,
      cancelado: cancelado,
      dataInicial: dataInicial,
      dataFinal: converterDateParaDataInt(this.state.dataFinal),
      apenasPossuiConflitoHorario: this.state.apenasPossuiConflitoHorario
    }
  }

  getChipsFiltrosAvancados = () => {
    const { colaborador, cliente, dataInicial, dataFinal, status, statusVenda, apenasPossuiConflitoHorario } = this.state
    const chips = []

    if (colaborador && colaborador.id) {
      chips.push({
        label: 'Colaborador: ' + colaborador.nome,
        onDelete: () => this.setState({ colaborador: undefined })
      })
    }

    if (cliente && cliente.id) {
      chips.push({
        label: 'Cliente: ' + cliente.nome,
        onDelete: () => this.setState({ cliente: undefined })
      })
    }

    if (dataInicial && dataFinal) {
      chips.push({
        label: moment(dataInicial).format('DD/MMM/YYYY') + ' até ' + moment(dataFinal).format('DD/MMM/YYYY'),
        onDelete: () => this.setState({ dataInicial: undefined, dataFinal: undefined })
      })
    } else {
      if (dataInicial) {
        chips.push({
          label: 'A partir: ' + moment(dataInicial).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataInicial: undefined })
        })
      }

      if (dataFinal) {
        chips.push({
          label: 'Até: ' + moment(dataFinal).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataFinal: undefined })
        })
      }
    }

    if (status && status.id !== StatusFiltroEnum.TODOS.id) {
      chips.push({
        label: 'Andamento: ' + StatusFiltroEnum.getById(status.id).descricao,
        onDelete: () => this.setState({ status: StatusFiltroEnum.TODOS })
      })
    }

    if (statusVenda && statusVenda.id !== StatusVendaFiltroEnum.TODOS.id) {
      chips.push({
        label: 'Situação: ' + StatusVendaFiltroEnum.getById(statusVenda.id).descricao,
        onDelete: () => this.setState({ statusVenda: StatusVendaFiltroEnum.TODOS })
      })
    }

    if (apenasPossuiConflitoHorario) {
      chips.push({
        label: 'Com Conflito de Horário',
        onDelete: () => this.setState({ apenasPossuiConflitoHorario: false })
      })
    }

    return chips
  }

  getLoaderKey = () => {
    const { colaborador, cliente, dataInicial, dataFinal, status, statusVenda, apenasPossuiConflitoHorario } = this.state

    let key = 'loader'

    if (colaborador && colaborador.id) {
      key += '-col' + colaborador.id
    }

    if (cliente && cliente.id) {
      key += '-cli' + cliente.id
    }

    if (dataInicial) {
      key += '-di' + dataInicial
    }

    if (dataFinal) {
      key += '-df' + dataFinal
    }

    if (status) {
      key += '-s' + status.id
    }

    if (statusVenda) {
      key += '-sv' + statusVenda.id
    }

    if (apenasPossuiConflitoHorario) {
      key += '-apch' + apenasPossuiConflitoHorario
    }

    return key
  }

  adicionarAtendimento = () => {
    if (verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
      VendaSupport.adicionarVenda({
        horarioPrePreenchido: converterMomentParaHorarioInt(moment())
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  downloadRelatorio = (event) => {
    const { dataInicial, dataFinal } = this.state

    let filename = 'Lista de Atendimentos'
    if (dataInicial || dataFinal) {
      filename += ' - ' + formatarPeriodoNomeArquivo(dataInicial, dataFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'atendimentos.gerarListaAtendimentos',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const { classes } = this.props
    const { lista } = this.state

    return (
      <div style={{ margin: '4px 0px', position: 'relative' }}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent chips={this.getChipsFiltrosAvancados()} type="filter">
          <Grid container alignItems="center">
            <Grid item xs style={{ marginRight: 16, minWidth: 140 }}>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" style={{ display: 'block' }} />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    defaultValue={this.colaboradorLocalPreferences.getPreference('AtendimentosListaPanelPageContent_PalavraChave', '')}
                    nakedLeftPadding={false}
                    placeholder="Pesquisar..."
                    onChange={(event) => {
                      this.colaboradorLocalPreferences.setPreference('AtendimentosListaPanelPageContent_PalavraChave', event.target.value)
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputPalavraChave}
                  />
                </Grid>
              </Grid>
            </Grid>
            {this.state.lista && this.state.lista.length > 0 && (
              <Grid item>
                <IconButton style={{ padding: 8 }} onClick={this.downloadRelatorio}>
                  <GetAppIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <IconButton style={{ padding: 8 }} onClick={this.abrirPesquisaAvancadaDialog}>
                <TuneIcon />
              </IconButton>
            </Grid>
            <Grid item className={classes.botaoAdicionarGrande}>
              <Button style={{ marginRight: -4 }} color="secondary" onClick={this.adicionarAtendimento}>
                ADICIONAR
              </Button>
            </Grid>
            <Grid item className={classes.botaoAdicionarPequeno}>
              <IconButton style={{ padding: 8, marginRight: -8 }} color="secondary" onClick={this.adicionarAtendimento}>
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </FloatContent>
        <br />

        <AtendimentosResponsiveTable
          contexto="horamarcada"
          itemsPerRequest={20}
          contextoUsuario="erp"
          endpoint="erp/atendimentos.buscarAtendimentosLista"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={lista}
          loaderFunctionsMap={this.loaderFunctionsMap}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          emptyListProps={{
            padding: 'small',
            text: !this.state.dataInicial && !this.state.dataFinal ? 'Nenhum atendimento agendado para hoje ou datas futuras' : 'Nenhum atendimento encontrado'
          }}
        />
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AtendimentosListaPanelPageContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtendimentosListaPanelPageContent)
