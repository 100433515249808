import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import ContaHelmet from 'pages/conta/ContaHelmet'
import ContaPendenteVerificacaoPage from 'pages/conta/ContaPendenteVerificacaoPage'
import CriarContaPage from 'pages/conta/CriarContaPage'
import IniciarRedefinicaoSenhaPage from 'pages/conta/IniciarRedefinicaoSenhaPage'
import LoginPage from 'pages/conta/LoginPage'
import RedefinirSenhaPage from 'pages/conta/RedefinirSenhaPage'
import VerificarEmail from 'pages/conta/VerificarEmail'
import { ROUTE_CONTA_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import RouterContent from 'support/components/router/RouterContent'

import GoogleSSOPage from './GoogleSSO/GoogleSSO'

const styles = () => ({})

class ContaPagesManager extends Component {
  render() {
    return (
      <React.Fragment>
        <ContaHelmet />
        <Switch>
          <Route exact path={ROUTE_CONTA_MAIN + '/'} render={(props) => <RouterContent component={LoginPage} sendPageViewGA={true} {...props} />} />
          <Route exact path={ROUTE_CONTA_MAIN + '/login'} render={(props) => <RouterContent component={LoginPage} sendPageViewGA={true} {...props} />} />
          <Route path={ROUTE_CONTA_MAIN + '/login/sso'} render={(props) => <RouterContent component={GoogleSSOPage} sendPageViewGA={true} {...props} />} />
          <Route path={ROUTE_CONTA_MAIN + '/criar-conta'} render={(props) => <RouterContent component={CriarContaPage} sendPageViewGA={true} {...props} />} />
          <Route path={ROUTE_CONTA_MAIN + '/conta-criada'} render={(props) => <RouterContent component={ContaPendenteVerificacaoPage} sendPageViewGA={true} {...props} />} />
          <Route
            path={ROUTE_CONTA_MAIN + '/conta-pendente-verificacao'}
            render={(props) => <RouterContent component={ContaPendenteVerificacaoPage} sendPageViewGA={true} {...props} />}
          />
          <Route path={ROUTE_CONTA_MAIN + '/verificar-email'} render={(props) => <RouterContent component={VerificarEmail} sendPageViewGA={true} {...props} />} />
          <Route
            path={ROUTE_CONTA_MAIN + '/iniciar-redefinicao-senha'}
            render={(props) => <RouterContent component={IniciarRedefinicaoSenhaPage} sendPageViewGA={true} {...props} />}
          />
          <Route path={ROUTE_CONTA_MAIN + '/redefinir-senha'} render={(props) => <RouterContent component={RedefinirSenhaPage} sendPageViewGA={true} {...props} />} />
        </Switch>
      </React.Fragment>
    )
  }
}

ContaPagesManager.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ContaPagesManager)
