import { Button, Text } from '@grupoboticario/flora-react'
import { Box, IconButton } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PanelPage from 'support/components/panel/PanelPage'
import { CONTRATAR_MAQUININHA } from 'support/domain/mercadopago/constants'

import { MaquininhaIntegrada } from './MaquininhaIntegrada'
import { useStyles } from './MaquininhaIntegrada.styles'

export const MaquininhaIntegradaPanelPage = ({ ...props }) => {
  const classes = useStyles()

  const handleBackMenu = () => {
    EventsManager.pub('NavegarPaginaPainel', { rota: '/configuracoes' })
  }

  const handleOpenLink = (value: string) => {
    window.open(value, '_blank')
  }

  return (
    <PanelPage
      {...props}
      variant="clear"
      clearContent={
        <Box className={classes.wrapperTitleDialog}>
          <Box className={classes.wrapperIcon}>
            <IconButton data-testid="back-button" className={classes.backButton} onClick={handleBackMenu}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <Text className={classes.pageTitle}>Maquininha integrada</Text>
          </Box>
          <Box className={classes.wrapperButtons}>
            <Button onClick={() => handleOpenLink(CONTRATAR_MAQUININHA)}>Contratar ou Integrar</Button>
          </Box>
          <Box className={classes.wrapperButtonsMobile}>
            <Button onClick={() => handleOpenLink(CONTRATAR_MAQUININHA)}>Contratar</Button>
            <Button onClick={() => handleOpenLink(CONTRATAR_MAQUININHA)}>Integrar</Button>
          </Box>
        </Box>
      }
      title="Maquininha Integrada"
    >
      <MaquininhaIntegrada />
    </PanelPage>
  )
}
