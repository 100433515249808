import React, { Component } from 'react'

import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import MemoryIcon from '@material-ui/icons/Memory'
import MessageIcon from '@material-ui/icons/Message'
import PersonIcon from '@material-ui/icons/Person'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import StoreIcon from '@material-ui/icons/Store'
import classNames from 'classnames'
import logo from 'img/logo_salao99_painel.png'
import { verificarAutorizacaoPorRota } from 'pages/admin/painel/PainelAdmin'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'
import Drawer from 'support/components/panel/Drawer'
import DrawerMenuDivider from 'support/components/panel/DrawerMenuDivider'
import DrawerMenuItem from 'support/components/panel/DrawerMenuItem'
import DrawerMenuItemIcon from 'support/components/panel/DrawerMenuItemIcon'
import DrawerMenuItemText from 'support/components/panel/DrawerMenuItemText'
import SimpleDrawerMenuItem from 'support/components/panel/SimpleDrawerMenuItem'

const styles = (theme) => ({
  singleLine: theme.text.singleLine(),
  drawerUserColor: {
    color: '#333'
  },
  drawerUserName: {
    fontSize: '16px',
    fontWeight: 500
  }
})

class DrawerAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  isMenuItemDisabled = (rotaMenu) => {
    if (!this.props.usuarioSessao || !this.props.usuarioAdminSessao) {
      return true
    } else if (this.props.usuarioAdminSessao) {
      if (this.props.usuarioAdminSessao.permissoes !== undefined) {
        return !verificarAutorizacaoPorRota(rotaMenu)
      }
    }
    return true
  }

  handleContaMenuClick = (event) => {
    this.setState({ anchorContaMenu: event.currentTarget })
  }

  handleContaMenuClose = () => {
    this.setState({ anchorContaMenu: null })
  }

  render() {
    const { classes, ...others } = this.props
    const { isMenuRetracted } = this.props

    let menuItems = []
    let menuGroupItems = []

    menuItems.push(menuGroupItems)

    if (!this.isMenuItemDisabled('/cupons')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem key="cupons" rota={'/cupons'} regexUrl="cupons" isMenuRetracted={isMenuRetracted} icon={{ component: ReceiptIcon }} isNew={true} primary="Cupons" />
      )
    }

    if (!this.isMenuItemDisabled('/suporte/chat')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="suporte/chat"
          rota={'/suporte/chat'}
          regexUrl="suporte/chat"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: MessageIcon }}
          primary="Chat de Suporte"
        />
      )
    }

    if (!this.isMenuItemDisabled('/usuarios')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem key="usuarios" rota={'/usuarios'} regexUrl="usuarios" isMenuRetracted={isMenuRetracted} icon={{ component: PersonIcon }} primary="Usuários" />
      )
    }

    if (!this.isMenuItemDisabled('/negocios')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem key="negocios" rota={'/negocios'} regexUrl="negocios" isMenuRetracted={isMenuRetracted} icon={{ component: StoreIcon }} primary="Negócios" />
      )
    }

    if (!this.isMenuItemDisabled('/email/log/envios')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="email/log/envios"
          rota={'/email/log/envios'}
          regexUrl="email/log/envios"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: MailOutlineIcon }}
          primary="Logs de E-mails"
        />
      )
    }

    /*
		if(!this.isMenuItemDisabled('/suporte/solicitacoes-treinamento')){
			menuGroupItems.push(
				<SimpleDrawerMenuItem
					key="suporte/solicitacoes-treinamento"
					rota={'/suporte/solicitacoes-treinamento'}
					regexUrl="suporte/solicitacoes-treinamento"
					isMenuRetracted={isMenuRetracted}
					icon={{component : FlagIcon }}
					primary="Solicitações Treinamento"
				/>
			);
		}*/

    menuGroupItems = []
    menuItems.push(menuGroupItems)

    if (!this.isMenuItemDisabled('/processamentos')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="processamentos"
          rota={'/processamentos'}
          regexUrl="processamentos"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: MemoryIcon }}
          primary="Processamentos"
        />
      )
    }

    if (!this.isMenuItemDisabled('/nfs')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem key="nfs" rota={'/nfs'} regexUrl="nfs" isMenuRetracted={isMenuRetracted} icon={{ component: ReceiptLongIcon }} primary="Notas Fiscais" />
      )
    }

    if (!this.isMenuItemDisabled('/servicosnf')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="servicosnf"
          rota={'/servicosnf'}
          regexUrl="servicosnf"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: ListAltIcon }}
          primary="Cadastros Serviços NFS"
        />
      )
    }

    if (!this.isMenuItemDisabled('/relatorios')) {
      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="relatorios"
          rota={'/relatorios'}
          regexUrl="relatorios"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: ShowChartIcon }}
          primary="Relatórios"
        />
      )
    }

    menuItems = menuItems.filter((menuGroupItems) => menuGroupItems.length > 0)

    return (
      <Drawer
        {...others}
        headerImage={<img src={logo} width="84" alt="Salão99" style={{ marginLeft: 0, marginTop: 3 }} />}
        fixedTopContent={
          <List>
            <DrawerMenuItem alwaysClickable={true} onClick={this.handleContaMenuClick}>
              <DrawerMenuItemIcon
                isMenuRetracted={isMenuRetracted}
                icon={{
                  component: AccountCircleIcon
                }}
              />
              <DrawerMenuItemText
                isMenuRetracted={isMenuRetracted}
                extraMarginRight={true}
                primary={this.props.usuarioSessao.nome}
                classes={{
                  primary: classNames(classes.singleLine),
                  secondary: classNames(classes.singleLine)
                }}
              />
            </DrawerMenuItem>

            <Menu id="conta-menu" anchorEl={this.state.anchorContaMenu} open={Boolean(this.state.anchorContaMenu)} onClose={this.handleContaMenuClose}>
              <MenuItem onClick={this.handleContaMenuClose} disabled={true}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Minha conta" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handleContaMenuClose()
                  this.props.handleLogout()
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </MenuItem>
            </Menu>
          </List>
        }
      >
        {menuItems.length === 0 && <DrawerMenuDivider />}

        {menuItems.length > 0 &&
          menuItems.map((menuGroupItems, index) => (
            <React.Fragment key={index}>
              <List>{menuGroupItems}</List>
              <DrawerMenuDivider />
            </React.Fragment>
          ))}
      </Drawer>
    )
  }
}

export default withStyles(styles)(DrawerAdmin)
