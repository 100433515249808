function getWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
}

function getHeight() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
}

const functionsObject = {
  getWidth: getWidth,
  getHeight: getHeight
}

export default functionsObject
