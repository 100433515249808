import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import ReceiptIcon from '@material-ui/icons/Receipt'
import moment from 'moment'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import VendasNotaResponsiveTable from 'pages/erp/painel/venda/table/VendasNotaResponsiveTable'
import VendaDialogPage from 'pages/erp/painel/venda/VendaDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import PassiveDynamicContent from 'support/components/passivedynamiccontent/PassiveDynamicContent'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import PacoteTipoValidadeEnum from 'support/domain/pacote/PacoteTipoValidadeEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  containerHeader: {
    marginTop: 8,
    marginBottom: theme.spacing(3)
  },
  gridHeaderIcon: {
    [theme.breakpoints.down(480)]: {
      display: 'none'
    }
  },
  containerHeaderIcon: {
    border: '1px solid ' + theme.palette.secondary.light,
    borderRadius: 36,
    padding: '10px 10px 10px 11px',
    marginRight: theme.spacing(2)
  },
  headerIcon: {
    display: 'block',
    fontSize: '30px',
    color: theme.palette.secondary.main
  },
  containerNomeCliente: {
    minWidth: 300,
    [theme.breakpoints.down(430)]: {
      minWidth: 200
    }
  },
  containerExibirVendasFuturas: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
      marginBottom: -16
    }
  },
  containerFooter: {
    float: 'right',
    display: 'inline-block',
    maxWidth: 400,
    width: '100%',
    paddingBottom: 0,
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    }
  }
})

class FechamentoContaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.state = {
      lista: [],
      mostrarOpcaoVendasFuturas: false,
      exibirVendasFuturas: false,
      selecionarTodos: false,
      title: 'Comanda',
      tabValue: 0,
      ajaxing: false
    }

    this.resumoTotalFunctionMap = {}
    this.loaderFunctionsMap = {}

    this.isContextoPadrao = false
    this.isContextoAdicionarItensFaturaExistente = false
    if (this.props.contexto === 'AdicionarItensFaturaExistente') {
      this.isContextoAdicionarItensFaturaExistente = true
    } else {
      this.isContextoPadrao = true
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoAtendimento', (props) => {
      if (props.atendimento) {
        const vendaParaAtualizacao = props.atendimento.venda
        vendaParaAtualizacao.atendimento = props.atendimento
        delete vendaParaAtualizacao.atendimento.venda
        this.atualizarVenda(vendaParaAtualizacao)
      }
    })

    this.eventsManager.sub('ManipulacaoVendaPacote', (props) => {
      if (props.vendaPacote) {
        const vendaParaAtualizacao = props.vendaPacote.venda
        vendaParaAtualizacao.vendaPacote = props.vendaPacote
        delete vendaParaAtualizacao.vendaPacote.venda
        this.atualizarVenda(vendaParaAtualizacao)
      }
    })

    this.eventsManager.sub('ManipulacaoVendaProduto', (props) => {
      if (props.vendaProduto) {
        const vendaParaAtualizacao = props.vendaProduto.venda
        vendaParaAtualizacao.vendaProduto = props.vendaProduto
        delete vendaParaAtualizacao.vendaProduto.venda
        this.atualizarVenda(vendaParaAtualizacao)
      }
    })
  }

  atualizarVenda = (vendaParaAtualizacao) => {
    const state = this.state

    for (let i = 0; i < state.lista.length; i++) {
      const venda = state.lista[i]
      if (venda.id === vendaParaAtualizacao.id) {
        vendaParaAtualizacao._checked = venda._checked
        vendaParaAtualizacao._show = venda._show
        vendaParaAtualizacao._update = true
        state.lista[i] = vendaParaAtualizacao
        this.processarUtilizacaoPacote()
        this.setState(state)
        break
      }
    }

    this.resumoTotalFunctionMap.update()
  }

  selecionarTodos = (event) => {
    const lista = this.state.lista

    if (event.target.checked) {
      for (let item of lista) {
        if (item._show) {
          item._checked = true
        } else {
          item._checked = false
        }
        item._update = true
      }
    } else {
      for (let item of lista) {
        item._checked = false
        item._update = true
      }
    }
    this.processarUtilizacaoPacote()
    this.setState({ lista: lista, selecionarTodos: event.target.checked })
    this.resumoTotalFunctionMap.update()
  }

  getStatusSelecionarTodos = () => {
    const lista = this.state.lista
    for (let i = 0; i < lista.length; i++) {
      const itemLista = lista[i]
      if (itemLista._show) {
        if (!itemLista._checked) {
          return false
        }
      }
    }
    return true
  }

  selecionarItem = (item) => {
    const lista = this.state.lista
    item._checked = !item._checked
    item._update = true
    this.processarUtilizacaoPacote()
    this.setState({ selecionarTodos: this.getStatusSelecionarTodos(), lista: lista })
    this.resumoTotalFunctionMap.update()
  }

  processarUtilizacaoPacote = () => {
    const { lista } = this.state

    for (let i = 0; i < lista.length; i++) {
      const venda = lista[i]
      if (venda.clientePacoteItem) {
        venda.utilizacaoPacote = true
      }
    }
  }

  getDadosFecharConta = () => {
    let dados = {}
    dados.cliente = this.props.cliente
    dados.vendas = []
    for (let venda of this.state.lista) {
      if (venda._checked === true) {
        dados.vendas.push({ id: venda.id })
      }
    }
    dados.retornarVendas = this.props.retornarVendas ? this.props.retornarVendas : null
    return dados
  }

  getDadosAdicionarItensFatura = () => {
    let dados = {}
    dados.cliente = this.props.cliente
    dados.nota = this.props.nota
    dados.vendas = []
    for (let venda of this.state.lista) {
      if (venda._checked === true) {
        dados.vendas.push({ id: venda.id })
      }
    }
    return dados
  }

  fecharContaConfirmacaoDialog = () => {
    if (!verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    let valorTotal = 0
    let totalItens = 0

    for (let venda of this.state.lista) {
      if (venda._checked === true) {
        totalItens++

        if (!venda.utilizacaoPacote) {
          valorTotal += venda.valorTotal
        }
      }
    }

    const expressaoItem = totalItens === 1 ? 'item selecionado' : 'itens selecionados'

    if (totalItens > 0) {
      let call = {
        method: 'post',
        contextoUsuario: 'erp',
        onError: (response) => {
          if (response && response.data && response.data.errors) {
            let mensagemErro = null
            if (response.data.errors.erroNotaVenda) {
              mensagemErro = response.data.errors.erroNotaVenda
            } else if (response.data.errors.vendas) {
              mensagemErro = response.data.errors.vendas
            }
            if (mensagemErro) {
              openBackableDialog(MessageDialog, {
                maxWidth: 400,
                title: 'Erro de Validação',
                text: mensagemErro
              })
            }
          }
          this.setState({ ajaxing: false })
        }
      }

      if (this.isContextoPadrao) {
        call.url = 'erp/vendas.fecharConta'
        call.data = this.getDadosFecharConta()
        call.onSuccess = (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)

          dialogConfirmationInstance.props.handleCloseDialog({
            onClosedCallback: () => {
              const notaVenda = response.data.notaVenda
              const vendas = response.data.vendas

              EventsManager.pub('FechamentoConta')

              openBackableDialog(NotaDialogPage, {
                //parent : dialogConfirmationInstance,
                //shouldCloseParent : true,
                idNota: notaVenda.id,
                retornarVendas: this.props.retornarVendas
              })
              if (this.props.retornarVendas) {
                EventsManager.pub('ManipulacaoVendas', {
                  action: 'alteracaoStatus',
                  notaVenda: notaVenda,
                  vendas: vendas
                })
              }
            }
          })
        }
      } else if (this.isContextoAdicionarItensFaturaExistente) {
        call.url = 'erp/vendas.adicionarItensNota'
        call.data = this.getDadosAdicionarItensFatura()
        call.onSuccess = (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          dialogConfirmationInstance.props.handleCloseDialog()
          EventsManager.pub('RecarregarAtendimentos')
          EventsManager.pub('ManipulacaoVendas')
        }
      }

      openBackableDialog(EntityConfirmationDialog, {
        parent: this,
        closeOnSuccess: false,
        title: 'Confirmação',
        text: (
          <React.Fragment>
            {this.isContextoPadrao && (
              <span>
                Você tem certeza que deseja gerar a comanda de{' '}
                <b>
                  {totalItens} {expressaoItem}
                </b>
                {valorTotal === 0 ? (
                  '?'
                ) : (
                  <span>
                    {' '}
                    no valor total de <b>{formatarValorMonetario(valorTotal)}</b>?
                  </span>
                )}
              </span>
            )}
            {this.isContextoAdicionarItensFaturaExistente && (
              <span>
                Você tem certeza que deseja incluir{' '}
                <b>
                  {totalItens} {expressaoItem}
                </b>
                {valorTotal === 0 ? (
                  '?'
                ) : (
                  <span>
                    {' '}
                    no valor total de <b>{formatarValorMonetario(valorTotal)}</b> na fatura <b>#{this.props.nota.numero}</b>?
                  </span>
                )}
              </span>
            )}
          </React.Fragment>
        ),
        cancelButtonLabel: 'VOLTAR',
        confirmButtonLabel: 'Confirmar',
        call: call
      })
    } else {
      openBackableDialog(MessageDialog, {
        title: 'Selecione os itens desejados',
        text: 'É necessário selecionar pelo menos 1 item.'
      })
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    const listaExibicao = this.state.lista.filter((venda) => venda._show)

    let descricao = ''
    if (this.isContextoPadrao) {
      descricao = 'Selecione os itens para gerar a fatura'
    } else if (this.isContextoAdicionarItensFaturaExistente) {
      descricao = 'Selecione os itens para incluir na fatura #' + this.props.nota.numero
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        pageType="basicEntity"
        contentMaxWidth={1000}
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
        onPreClose={() => {
          EventsManager.pub('TabSaveSnackBar', { open: false })
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <Grid container alignItems="center" className={classes.containerHeader} justify="flex-end" alignContent="flex-end">
              <Grid item className={classes.gridHeaderIcon}>
                <div className={classes.containerHeaderIcon}>
                  <ReceiptIcon className={classes.headerIcon} />
                </div>
              </Grid>
              <Grid item xs className={classes.containerNomeCliente}>
                <Typography variant="body1" color="secondary">
                  <b>{this.props.cliente.apelido}</b>
                </Typography>
                {this.state.lista.length > 0 && (
                  <Typography variant="body2" color="secondary">
                    {descricao}
                  </Typography>
                )}
              </Grid>
              {this.state.mostrarOpcaoVendasFuturas && (
                <Grid item className={classes.containerExibirVendasFuturas}>
                  <UncontrolledCheckbox
                    formControlProps={{
                      style: { marginleft: 0, marginRight: 0 },
                      label: 'Exibir vendas futuras'
                    }}
                    onChange={(value) => {
                      const hojeAsDataInt = converterMomentParaDataInt(moment())
                      const { lista } = this.state
                      for (let venda of lista) {
                        if (venda.dataVenda > hojeAsDataInt) {
                          venda._show = value
                          if (!value) {
                            venda._checked = false
                          }
                        }
                        venda._update = true
                      }
                      this.setState({ selecionarTodos: this.getStatusSelecionarTodos(), lista: lista })
                      this.resumoTotalFunctionMap.update()
                    }}
                  />
                </Grid>
              )}
            </Grid>

            <div>
              <VendasNotaResponsiveTable
                scrollElement={dialogContentProps.scrollContainerRef.current}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="erp"
                endpoint="erp/vendas.buscarVendasParaFechamentoConta"
                getRequestParametersFunction={() => {
                  return {
                    idCliente: this.props.cliente.id
                  }
                }}
                items={listaExibicao}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    const state = { lista: data.items }

                    const hojeAsDataInt = converterMomentParaDataInt(moment())
                    let possuiVendasAteHoje = false
                    let possuiVendasFuturas = false

                    for (let i = 0; i < state.lista.length; i++) {
                      const venda = state.lista[i]

                      if (venda.dataVenda <= hojeAsDataInt) {
                        possuiVendasAteHoje = true
                      } else {
                        possuiVendasFuturas = true
                      }

                      if (VendaService.isVendaPacote(venda)) {
                        if (venda.vendaPacote.pacote.itens) {
                          for (let item of venda.vendaPacote.pacote.itens) {
                            let novaDataVencimento = null
                            switch (venda.vendaPacote.pacote.tipoValidade.id) {
                              case PacoteTipoValidadeEnum.ILIMITADA.id:
                                novaDataVencimento = null
                                break
                              case PacoteTipoValidadeEnum.POR_DIAS.id:
                                novaDataVencimento = converterDataIntParaMoment(venda.vendaPacote.data).add(venda.vendaPacote.pacote.prazoValidade, 'days')
                                break
                              case PacoteTipoValidadeEnum.POR_SEMANAS.id:
                                novaDataVencimento = converterDataIntParaMoment(venda.vendaPacote.data).add(venda.vendaPacote.pacote.prazoValidade, 'weeks')
                                break
                              case PacoteTipoValidadeEnum.POR_MESES.id:
                                novaDataVencimento = converterDataIntParaMoment(venda.vendaPacote.data).add(venda.vendaPacote.pacote.prazoValidade, 'months')
                                break
                              case PacoteTipoValidadeEnum.POR_ANOS.id:
                                novaDataVencimento = converterDataIntParaMoment(venda.vendaPacote.data).add(venda.vendaPacote.pacote.prazoValidade, 'years')
                                break
                              default:
                                break
                            }

                            item.dataCriacao = venda.vendaPacote.data
                            const quantidade = item.quantidade
                            delete item.quantidade
                            item.quantidadeDisponivel = quantidade
                            item.quantidadeInicial = quantidade

                            if (novaDataVencimento) {
                              item.dataVencimento = converterMomentParaDataInt(novaDataVencimento)
                            }
                          }
                        }
                      }
                    }

                    if (possuiVendasAteHoje && possuiVendasFuturas) {
                      state.mostrarOpcaoVendasFuturas = true
                    }

                    for (let i = 0; i < state.lista.length; i++) {
                      const venda = state.lista[i]
                      if (venda.dataVenda <= hojeAsDataInt) {
                        venda._show = true
                      } else {
                        if (state.mostrarOpcaoVendasFuturas) {
                          venda._show = false
                        } else {
                          venda._show = true
                        }
                      }
                    }

                    state.paginaCarregada = true

                    if (state.lista.length > 0) {
                      state.toolbarActions = {
                        actions: [
                          {
                            label: 'Continuar',
                            handleAction: this.fecharContaConfirmacaoDialog
                          }
                        ]
                      }
                    } else {
                      state.title = 'Faturamento'
                    }

                    this.setState(state)
                  }
                }}
                onClickRow={(item, event, index) => {
                  this.selecionarItem(item)
                }}
                selecaoProps={{
                  selecionarTodosFunction: this.selecionarTodos,
                  selecionarTodosValue: this.state.selecionarTodos
                }}
                acao={{
                  icon: EditIcon,
                  onClick: (event, item) => {
                    event.stopPropagation()
                    openBackableDialog(VendaDialogPage, {
                      venda: item
                    })
                  }
                }}
                emptyListProps={{
                  padding: false,
                  text: 'Nenhum item pendente para faturamento.'
                }}
              />
            </div>

            {this.state.paginaCarregada && this.state.lista.length > 0 && (
              <div>
                <PassiveDynamicContent
                  fistUpdateOnMount={true}
                  updateFunction={() => {
                    const lista = this.state.lista
                    let valorSubtotal = 0
                    let valorDescontos = 0
                    let valorValorTotal = 0
                    for (let i = 0; i < lista.length; i++) {
                      const venda = lista[i]
                      if (venda._checked && !venda.utilizacaoPacote) {
                        valorSubtotal += venda.valorSubtotal
                        valorDescontos += venda.valorDescontoCalculado
                        valorValorTotal += venda.valorTotal
                      }
                    }

                    return {
                      content: (
                        <div className={classes.containerFooter}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <Typography align="left" variant="body2">
                                Subtotal
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography align="right" variant="body2">
                                {formatarValorMonetario(valorSubtotal)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <Typography align="left" variant="body2">
                                Descontos
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography align="right" variant="body2">
                                {formatarValorMonetario(valorDescontos)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <Typography align="left" variant="body1">
                                Total
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography align="right" variant="body1">
                                {formatarValorMonetario(valorValorTotal)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    }
                  }}
                  functionsMap={this.resumoTotalFunctionMap}
                />
                <div style={{ clear: 'both' }}></div>
              </div>
            )}

            {this.state.paginaCarregada && this.state.lista.length > 0 && (
              <div className={classes.containerFooterButtons}>
                <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                  Cancelar
                </Button>
                <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.fecharContaConfirmacaoDialog}>
                  {this.state.ajaxing ? 'Aguarde' : 'Continuar'}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

FechamentoContaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FechamentoContaDialogPage)
