import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloudUpload from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import PropTypes from 'prop-types'
import ContentBypass from 'support/components/contentbypass/ContentBypass'
import FileUtil from 'support/util/FileUtil'

const BASE_SIZE_PREVIEW_CONTAINER = 140

const styles = (theme) => ({
  dropFilesContainer: {
    border: '3px dashed #ddd',
    textAlign: 'center',
    backgroundColor: '#f6f6f6'
  },
  dropFilesArea: {
    cursor: 'pointer',
    padding: theme.spacing(3)
  },
  containerCrop: {
    backgroundImage:
      'linear-gradient(45deg, #d6d6d6 25%, transparent 25%), linear-gradient(-45deg, #d6d6d6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #d6d6d6 75%), linear-gradient(-45deg, transparent 75%, #d6d6d6 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    textAlign: 'center',
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  containerFiles: {
    textAlign: 'left',
    borderTop: '1px solid #eaeaea',
    padding: theme.spacing(2)
  },
  iconFile: {
    display: 'block'
  }
})

class FileUploader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingCrop: false,
      readImageToCrop: null,
      crop: {}
    }
  }

  onCropChange = (crop) => {
    this.setState({ crop })
    this.executeCroppedListenerFunction(crop)
  }

  idProcessoCrop = 0
  executeCroppedListenerFunction = (crop, delay) => {
    const idProcessoCropAtual = ++this.idProcessoCrop

    this.cropping = true
    this.setState({ cropping: true })

    window.setTimeout(
      () => {
        if (idProcessoCropAtual === this.idProcessoCrop) {
          this.cropping = false
          this.setState({
            cropping: false,
            croppedImageSrc: this.getCroppedImg(this.imageRef, crop)
          })
        }
      },
      delay ? delay : 250
    )
  }

  getCroppedImg = (image, crop) => {
    const { cropsProps } = this.props

    const scale = image.naturalWidth / image.width

    let exportSizeWidth = crop.width * scale
    let exportSizeHeight = crop.height * scale
    if (cropsProps.exportSize) {
      if (cropsProps.exportSize.width) {
        exportSizeWidth = cropsProps.exportSize.width
      }
      if (cropsProps.exportSize.height) {
        exportSizeHeight = cropsProps.exportSize.height
      }
    }

    const canvas = document.createElement('canvas')

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = exportSizeWidth
    canvas.height = exportSizeHeight
    const ctx = canvas.getContext('2d')

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, exportSizeWidth, exportSizeHeight)

    return canvas.toDataURL('image/jpeg')
  }

  trocarImagem = () => {
    this.setState({
      readImageToCrop: null,
      loadingCrop: false
    })
  }

  saveCroppedImage = () => {
    if (this.props.cropsProps.saveCroppedImageFunction) {
      this.props.cropsProps.saveCroppedImageFunction(
        this.state.croppedImageSrc.split('base64,')[1],
        () => {
          if (this.props.eventTrackingFunction) {
            this.props.eventTrackingFunction({ status: 'uploading' })
          }
        },
        () => {
          if (this.props.eventTrackingFunction) {
            this.props.eventTrackingFunction({ status: 'uploaded' })
          }
        },
        () => {
          if (this.props.eventTrackingFunction) {
            this.props.eventTrackingFunction({ status: 'notuploaded' })
          }
        }
      )
    }
  }

  render() {
    const { classes, multiple, disabled, cropsProps, uploading, acceptOnlyBasicImageFormats } = this.props
    let { previewBorderRadius } = this.props
    const { readImageToCrop, loadingCrop, cropping, croppedImageSrc } = this.state
    let { accept } = this.props

    if (cropsProps || acceptOnlyBasicImageFormats) {
      accept = 'image/jpeg, image/png, image/jpg'
    }

    let dropzoneText = (
      <span>
        Solte o arquivo de foto aqui ou <span style={{ fontWeight: 500 }}>clique</span> para selecionar um arquivo.
      </span>
    )
    if (this.props.dropzoneText) {
      dropzoneText = this.props.dropzoneText
    }

    if (!previewBorderRadius && previewBorderRadius !== 0) {
      previewBorderRadius = BASE_SIZE_PREVIEW_CONTAINER
    }

    let previewContainerWidth = BASE_SIZE_PREVIEW_CONTAINER
    let previewContainerHeight = BASE_SIZE_PREVIEW_CONTAINER

    if (cropsProps) {
      if (cropsProps.aspect !== 1 && cropsProps.exportSize) {
        if (cropsProps.aspect > 1) {
          previewContainerHeight = previewContainerHeight / cropsProps.aspect
        } else if (cropsProps.aspect < 1) {
          previewContainerWidth = previewContainerWidth * cropsProps.aspect
        }
      }
    }

    return (
      <Grid container>
        {!readImageToCrop && (
          <Grid item xs style={{ padding: this.props.dropzonePadding ? 10 : 0 }}>
            <Dropzone
              accept={accept}
              multiple={!cropsProps && multiple}
              disabled={loadingCrop || disabled}
              onDrop={(acceptedFiles) => {
                if (cropsProps) {
                  if (acceptedFiles && acceptedFiles.length) {
                    this.setState({
                      loadingCrop: true
                    })
                    FileUtil.readAsDataURL(acceptedFiles[0], (readFile) => {
                      this.setState({
                        readImageToCrop: readFile,
                        crop: { aspect: this.props.cropsProps.aspect, width: 10, height: 10, x: 10, y: 10 }
                      })
                    })
                  }
                } else {
                  let files = this.props.files ? this.props.files.slice(0) : []
                  files = files.concat(acceptedFiles)
                  if (files && files.length > 0) {
                    if (this.props.onDropChange) {
                      this.props.onDropChange(files)
                    }
                  }
                }
              }}
              noClick={true}
            >
              {({ getRootProps, getInputProps, open }) => (
                <section className={classes.dropFilesContainer}>
                  <div {...getRootProps()} className={classes.dropFilesArea} style={{ outline: 0 }} onClick={open}>
                    <input {...getInputProps()} name="file" />
                    <div style={{ color: '#7b7b7b', maxWidth: 300, display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                      {loadingCrop ? (
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item>
                            <CircularProgress thickness={4.5} size={24} style={{ display: 'block' }} />
                          </Grid>
                          <Grid item style={{ marginLeft: 16 }}>
                            Carregando...
                          </Grid>
                        </Grid>
                      ) : (
                        <div>
                          <CloudUpload style={{ color: '#d6d6d6', fontSize: 34 }} />
                          <div style={{ marginBottom: 12 }}>{dropzoneText}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {!cropsProps && this.props.files && this.props.files.length > 0 && (
                      <div className={classes.containerFiles}>
                        {this.props.files.map((file, index) => {
                          let iconContent = <InsertDriveFileIcon fontSize="small" style={{ color: '#bbb' }} className={classes.iconFile} />
                          return (
                            <Grid key={index} container alignItems="center" spacing={1}>
                              <Grid item>{iconContent}</Grid>
                              <Grid item xs>
                                <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                                  {file.name ? file.name : 'Arquivo Selecionado'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  style={{ marginTop: -7, marginBottom: -7 }}
                                  disabled={disabled}
                                  onClick={() => {
                                    this.props.onRemoveFile(index)
                                  }}
                                >
                                  <DeleteIcon fontSize="small" style={{ color: '#888' }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
        )}

        {readImageToCrop && !uploading && (
          <ContentBypass>
            <Grid item xs={6} sm={7} className={classes.containerCrop} style={{}}>
              <div>
                <ReactCrop
                  crop={this.state.crop}
                  style={{ verticalAlign: 'bottom' }}
                  keepSelection={true}
                  src={readImageToCrop.getDataURL()}
                  imageStyle={this.props.cropsProps.imageStyle}
                  onImageLoaded={(image) => {
                    const width = image.width
                    const height = image.height
                    this.imageRef = image
                    const crop = { aspect: this.props.cropsProps.aspect }
                    if (width >= height) {
                      const size = height - 12
                      crop.height = size
                      crop.width = size
                      crop.y = 6
                      crop.x = width / 2 - size / 2
                    } else {
                      const size = width - 12
                      crop.width = size
                      crop.height = size
                      crop.y = height / 2 - size / 2
                      crop.x = 6
                    }

                    if (crop.aspect > 1) {
                      const newHeight = crop.height / crop.aspect
                      crop.y = crop.y + (crop.height - newHeight) / 2
                      crop.height = newHeight
                    } else if (crop.aspect < 1) {
                      const newWidth = crop.width * crop.aspect
                      crop.x = crop.x + (crop.width - newWidth) / 2
                      crop.width = newWidth
                    }

                    window.setTimeout(() => {
                      this.setState({ crop: crop })
                      this.executeCroppedListenerFunction(crop, 50)
                    }, 100)
                  }}
                  onChange={this.onCropChange}
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={5} style={{ textAlign: 'center' }}>
              <Typography variant="body1" style={{ marginTop: 16, marginBottom: 8 }}>
                Pré-visualização
              </Typography>
              <div style={{ padding: '10px 16px' }}>
                <div>
                  <div
                    style={{
                      position: 'relative',
                      maxWidth: previewContainerWidth,
                      maxHeight: previewContainerHeight,
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#f5f5f5',
                      border: '3px dashed rgb(220, 220, 220)',
                      display: 'inline-block',
                      borderRadius: previewBorderRadius
                    }}
                  >
                    <Grid container style={{ position: 'absolute', width: '100%', height: '100%' }} alignItems="center" justify="center">
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                    <div style={{ padding: 5 }}>
                      <img
                        src={croppedImageSrc}
                        style={{ position: 'relative', display: 'block', width: '100%', borderRadius: previewBorderRadius, visibility: cropping ? 'hidden' : 'visible' }}
                        alt="Imagem"
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: 14, marginBottom: 24 }}>
                    <Button disabled={cropping} variant="contained" onClick={this.saveCroppedImage} color="secondary" style={{ margin: 3 }}>
                      Salvar
                    </Button>
                    <br />
                    <Button disabled={cropping} size="small" onClick={this.trocarImagem} style={{ marginTop: 6 }}>
                      Trocar Imagem
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          </ContentBypass>
        )}

        {readImageToCrop && uploading && (
          <Grid item xs style={{ margin: 24 }}>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <CircularProgress thickness={4.5} size={24} style={{ display: 'block' }} />
              </Grid>
              <Grid item style={{ marginLeft: 16 }}>
                Salvando...
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

FileUploader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FileUploader)
