import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import SettingsIcon from '@material-ui/icons/Settings'
import TuneIcon from '@material-ui/icons/Tune'
import moment from 'moment'
import CategoriasProdutosDialogPage from 'pages/erp/painel/inventario/CategoriasProdutosDialogPage'
import CompraProdutoDialogPage from 'pages/erp/painel/inventario/CompraProdutoDialogPage'
import FornecedoresDialogPage from 'pages/erp/painel/inventario/FornecedoresDialogPage'
import MarcasDialogPage from 'pages/erp/painel/inventario/MarcasDialogPage'
import MovimentacaoEstoqueDialogPage from 'pages/erp/painel/inventario/MovimentacaoEstoqueDialogPage'
import ProdutoPhotoPicker from 'pages/erp/painel/inventario/picker/ProdutoPhotoPicker'
import ProdutoDadosBasicosDialogPage from 'pages/erp/painel/inventario/ProdutoDadosBasicosDialogPage'
import ProdutoDialogPage from 'pages/erp/painel/inventario/ProdutoDialogPage'
import ProdutosPesquisaAvancadaDialog from 'pages/erp/painel/inventario/ProdutosPesquisaAvancadaDialog'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import MotivoMovimentacaoEstoqueEnum from 'support/domain/inventario/MotivoMovimentacaoEstoqueEnum'
import TipoMovimentacaoEstoqueEnum from 'support/domain/inventario/TipoMovimentacaoEstoqueEnum'
import { createEnum } from 'support/util/EnumUtil'
import ImageUtil from 'support/util/ImageUtil'

const styles = (theme) => ({
  colunaFoto: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaQuantAtual: {
    maxWidth: 120,
    minWidth: 120
  },
  colunaQuantAtualMedium: {
    maxWidth: 70,
    minWidth: 70
  },
  nomeProdutoContainerSmall: {
    marginTop: 4,
    fontSize: 14,
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

export const VisaoEnum = createEnum({
  ATIVOS: { id: 1, descricao: 'Ativos' },
  EXCLUIDOS: { id: 2, descricao: 'Excluídos' }
})

class ProdutosPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      title: 'Produtos',
      visao: VisaoEnum.ATIVOS,
      ajaxing: false,
      codigoBarras: null,
      sku: null,
      alertaEstoqueBaixo: false,
      arquivado: false
    }

    this.inputNomeFiltro = React.createRef()
    this.existeFiltroPesquisa = false
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoProduto', 'ManutencaoCategoriaProduto'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  openMenuConfiguracoes = (event) => {
    this.setState({ criarItemMenuConfigAnchorEl: event.currentTarget })
  }

  abrirFornecedoresDialog = () => {
    openBackableDialog(FornecedoresDialogPage)
  }

  abrirMarcasDialog = () => {
    openBackableDialog(MarcasDialogPage)
  }

  abrirCategoriasDialog = () => {
    openBackableDialog(CategoriasProdutosDialogPage)
  }

  abrirProdutoDadosBasicosDialog = () => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])) {
      openBackableDialog(ProdutoDadosBasicosDialogPage)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  abrirDetalhesProdutoDialog = (produto) => {
    openBackableDialog(ProdutoDialogPage, {
      idProduto: produto.id
    })
  }

  abrirPesquisaAvancadaDialog = () => {
    openBackableDialog(ProdutosPesquisaAvancadaDialog, {
      categoria: this.state.categoria,
      fornecedor: this.state.fornecedor,
      marca: this.state.marca,
      alertaEstoqueBaixo: this.state.alertaEstoqueBaixo,
      arquivado: this.state.arquivado,
      setParentState: (state) => {
        this.setState(state)
      }
    })
  }

  abrirCompraProduto = (produto) => {
    if (verificarAutorizacao([PA.PODE_MOVIMENTAR_ESTOQUE])) {
      openBackableDialog(CompraProdutoDialogPage, {
        produto: produto
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  abrirAlteracoesEstoque = (produto) => {
    if (verificarAutorizacao([PA.PODE_MOVIMENTAR_ESTOQUE])) {
      openBackableDialog(MovimentacaoEstoqueDialogPage, {
        produto: produto,
        tipoMovimentacao: TipoMovimentacaoEstoqueEnum.SAIDA,
        motivoMovimentacao: MotivoMovimentacaoEstoqueEnum.UTILIZACAO
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerRemover = (produto) => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Produto',
      text: (
        <span>
          Você tem certeza que deseja remover o produto <b>{produto.nome}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/inventario.removerProduto',
        contextoUsuario: 'erp',
        data: {
          id: produto.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoProduto')
        }
      }
    })
  }

  getRequestParameters = () => {
    const ativo = this.state.visao.id === VisaoEnum.ATIVOS.id ? true : false
    const excluido = this.state.visao.id === VisaoEnum.EXCLUIDOS.id ? true : false

    return {
      idCategoria: this.state.categoria ? this.state.categoria.id : null,
      idFornecedor: this.state.fornecedor ? this.state.fornecedor.id : null,
      idMarca: this.state.marca ? this.state.marca.id : null,
      nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null,
      codigoBarras: this.state.codigoBarras,
      sku: this.state.sku,
      alertaEstoqueBaixo: this.state.alertaEstoqueBaixo,
      arquivado: this.state.arquivado,
      ativo: ativo,
      excluido: excluido
    }
  }

  handlerReativar = () => {
    if (!verificarAutorizacao([PA.PODE_REMOVER_COLABORADOR])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityConfirmationDialog, {
      title: 'Reativar Produto',
      text: (
        <span>
          Você tem certeza que deseja reativar o produto <b>{this.state.itemMenu.nome}</b>?
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/inventario.reativarProduto',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoProduto')
          this.setState({ visao: VisaoEnum.ATIVOS })
        }
      }
    })
  }

  getChipsFiltrosAvancados = () => {
    const { categoria, marca, fornecedor, alertaEstoqueBaixo, arquivado } = this.state
    const chips = []
    this.existeFiltroPesquisa = false

    if (categoria && categoria.id) {
      this.existeFiltroPesquisa = true
      chips.push({
        label: 'Categoria: ' + categoria.nome,
        onDelete: () => this.setState({ categoria: undefined })
      })
    }

    if (fornecedor && fornecedor.id) {
      this.existeFiltroPesquisa = true
      chips.push({
        label: 'Fornecedor: ' + fornecedor.nome,
        onDelete: () => this.setState({ fornecedor: undefined })
      })
    }

    if (marca && marca.id) {
      this.existeFiltroPesquisa = true
      chips.push({
        label: 'Marca: ' + marca.nome,
        onDelete: () => this.setState({ marca: undefined })
      })
    }

    if (alertaEstoqueBaixo) {
      this.existeFiltroPesquisa = true
      chips.push({
        label: 'Estoque baixo',
        onDelete: () => this.setState({ alertaEstoqueBaixo: false })
      })
    }

    if (arquivado) {
      this.existeFiltroPesquisa = true
      chips.push({
        label: 'Arquivados',
        onDelete: () => this.setState({ arquivado: false })
      })
    }

    return chips
  }

  getLoaderKey = () => {
    const { categoria, marca, fornecedor, alertaEstoqueBaixo, arquivado } = this.state

    let key = 'loader'

    if (categoria && categoria.id) {
      key += '-c' + this.state.categoria.id
    }

    if (fornecedor && fornecedor.id) {
      key += '-f' + this.state.fornecedor.id
    }

    if (marca && marca.id) {
      key += '-m' + this.state.marca.id
    }

    if (alertaEstoqueBaixo !== null) {
      key += '-a' + alertaEstoqueBaixo
    }

    if (arquivado !== null) {
      key += '-ar' + arquivado
    }

    return key
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.nome = item.nome
    content.categoria = item.categoria.nome

    content.estoqueAtual = !item.controleEstoque ? (
      '-'
    ) : (
      <span>
        {item.unidadeMedidaEmbalagem && (
          <React.Fragment>
            <span>{item.estoqueAtualUnidadePersonalizada + ' ' + item.unidadeMedidaEmbalagem}</span>
            <br />
          </React.Fragment>
        )}
        <span>
          {(item.unidadeMedidaEmbalagem ? ' (' : '') + item.estoqueAtualQuantidade} unid.{item.unidadeMedidaEmbalagem ? ')' : ''}
        </span>
      </span>
    )

    const variacoesFoto = ImageUtil.extrairVariacoesImagem(item.foto)
    content.foto = <ProdutoPhotoPicker produto={item} photo1x={variacoesFoto.get(2)} width={38} preCheckWritePermission={true} />

    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Lista de Produtos - ' + moment().format('YYYYDDMM'),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'inventario.gerarListaProdutos',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { criarItemMenuConfigAnchorEl, menuAnchorPosition, visao } = this.state

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push(
      {
        icon: <AddIcon />,
        handleAction: this.abrirProdutoDadosBasicosDialog
      },
      {
        icon: <SettingsIcon />,
        handleAction: this.openMenuConfiguracoes
      },
      {
        icon: <TuneIcon />,
        label: 'Filtrar',
        handleAction: this.abrirPesquisaAvancadaDialog
      }
    )

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS]) && visao.id === VisaoEnum.ATIVOS.id) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <PanelPage {...others} title={this.state.title} toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent chips={this.getChipsFiltrosAvancados()} type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 16 }} disabled={!verificarAutorizacao([PA.PODE_REMOVER_PRODUTO])}>
              {verificarAutorizacao([PA.PODE_REMOVER_PRODUTO]) && (
                <InputSelect
                  customVariant="naked"
                  nakedLeftPadding={false}
                  value={this.state.visao.id}
                  fullWidth={false}
                  onChange={(event) => {
                    const visao = VisaoEnum.getById(parseInt(event.target.value, 10))
                    this.setState({ visao: visao })
                  }}
                >
                  {VisaoEnum.values().map((visao) => (
                    <MenuItem key={visao.id} value={visao.id}>
                      {visao.descricao}
                    </MenuItem>
                  ))}
                </InputSelect>
              )}
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    nakedLeftPadding={false}
                    placeholder="Procurar por produto..."
                    onChange={(event) => {
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>

        <br />

        <VirtualizedResponsiveTable
          key={visao.id}
          minTableWidth={650}
          windowScroller={true}
          showBackgroundDividers={true}
          itemsPerRequest={30}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/inventario.buscarProdutos"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.setState({
              menuAnchorPosition: {
                left: event.clientX,
                top: event.clientY
              },
              itemMenu: item
            })
          }}
          largeRenderProps={{
            columns: [
              { className: classes.colunaFoto },
              { label: 'Nome', props: { xs: true } },
              { label: 'Categoria', horizontalPadding: 'small', props: { xs: true } },
              { className: classes.colunaQuantAtual, align: 'right', label: 'Estoque' }
            ],
            itemRenderer: (inventario, index) => {
              const content = this.getRenderContent('large', inventario)
              return {
                itemData: [content.foto, content.nome, content.categoria, content.estoqueAtual]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 80,
            columns: [
              { label: 'Categoria', props: { xs: true } },
              { label: 'Produto', horizontalPadding: 'small', props: { xs: true } },
              { className: classes.colunaQuantAtualMedium, align: 'right', label: 'Estoque' }
            ],
            itemRenderer: (inventario, index) => {
              const content = this.getRenderContent('small', inventario)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center">
                      <Grid item style={{ marginRight: 16 }}>
                        {content.foto}
                      </Grid>
                      <Grid item xs style={{ marginRight: 4, minWidth: 0 }}>
                        <Typography variant="body2" className={classes.nomeProdutoContainerSmall}>
                          {content.nome}
                        </Typography>
                        <Typography variant="body2" noWrap={true} style={{ fontSize: 13, fontWeight: 500 }}>
                          {content.categoria}
                        </Typography>
                      </Grid>
                      <Grid item style={{ minWidth: 0 }}>
                        <Typography variant="body2" style={{ fontSize: 13, textAlign: 'right' }}>
                          {content.estoqueAtual}
                        </Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum produto foi encontrado',
            textButton: 'Cadastrar Produto',
            creationFunction: this.abrirProdutoDadosBasicosDialog
          }}
        />

        <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={() => this.closeMenu('menuAnchorPosition')}>
          {this.state.itemMenu && this.state.visao.id === VisaoEnum.ATIVOS.id && (
            <MenuItem
              onClick={() => {
                this.abrirDetalhesProdutoDialog(this.state.itemMenu)
                this.closeMenu('menuAnchorPosition')
              }}
            >
              Abrir
            </MenuItem>
          )}
          {this.state.itemMenu && this.state.visao.id === VisaoEnum.ATIVOS.id && (
            <MenuItem
              onClick={() => {
                this.abrirCompraProduto(this.state.itemMenu)
                this.closeMenu('menuAnchorPosition')
              }}
            >
              Registrar compra
            </MenuItem>
          )}
          {this.state.itemMenu && this.state.itemMenu.controleEstoque && this.state.visao.id === VisaoEnum.ATIVOS.id && (
            <MenuItem
              onClick={() => {
                this.abrirAlteracoesEstoque(this.state.itemMenu)
                this.closeMenu('menuAnchorPosition')
              }}
            >
              Alterar estoque
            </MenuItem>
          )}
          {this.state.itemMenu && this.state.visao.id === VisaoEnum.ATIVOS.id && (
            <MenuItem
              onClick={() => {
                this.handlerRemover(this.state.itemMenu)
                this.closeMenu('menuAnchorPosition')
              }}
            >
              Remover produto
            </MenuItem>
          )}
          {this.state.itemMenu && this.state.visao.id === VisaoEnum.EXCLUIDOS.id && (
            <MenuItem
              onClick={() => {
                this.handlerReativar(this.state.itemMenu)
                this.closeMenu('menuAnchorPosition')
              }}
            >
              Reativar produto
            </MenuItem>
          )}
        </Menu>

        <Menu anchorEl={criarItemMenuConfigAnchorEl} open={Boolean(criarItemMenuConfigAnchorEl)} onClose={() => this.closeMenu('criarItemMenuConfigAnchorEl')}>
          <MenuItem
            onClick={() => {
              this.abrirCategoriasDialog()
              this.closeMenu('criarItemMenuConfigAnchorEl')
            }}
          >
            Categorias
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.abrirMarcasDialog()
              this.closeMenu('criarItemMenuConfigAnchorEl')
            }}
          >
            Marcas
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.abrirFornecedoresDialog()
              this.closeMenu('criarItemMenuConfigAnchorEl')
            }}
          >
            Fornecedores
          </MenuItem>
        </Menu>

        {this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            <Typography variant="body2">
              Produtos cadastrados: <b>{this.state.lista.length}</b>
              <br />
            </Typography>
          </FloatContent>
        )}
      </PanelPage>
    )
  }
}

ProdutosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProdutosPanelPage)
