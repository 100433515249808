import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ValorPlanoDialogPage from 'pages/admin/painel/negocio/ValorPlanoDialogPage'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PermissoesAcesso from 'support/domain/admin/usuario/PermissoesAcesso'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { createErrorsMap } from 'support/util/FormUtil'
import { formatarValorMonetario, formatarValorPercentual } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ValoresPlanosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.loaderFunctionsMap = {}

    this.state = {
      title: 'Valores',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      plano: PlanoEnum.INDIVIDUAL,
      lista: []
    }

    this.eventsManager = EventsManager.new()
  }

  getRequestParameters = () => {
    return {
      idNegocio: this.props.negocio.id,
      idPlano: this.state.plano.id
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.precoMensal = formatarValorMonetario(item.precoMensal)
    content.precoTrimestral =
      formatarValorMonetario(item.precoTrimestral) + (item.percentualDescontoTrimestral ? ' (' + formatarValorPercentual(item.percentualDescontoTrimestral) + ')' : '')
    content.precoSemestral =
      formatarValorMonetario(item.precoSemestral) + (item.percentualDescontoSemestral ? ' (' + formatarValorPercentual(item.percentualDescontoSemestral) + ')' : '')
    content.precoAnual = formatarValorMonetario(item.precoAnual) + (item.percentualDescontoAnual ? ' (' + formatarValorPercentual(item.percentualDescontoAnual) + ')' : '')
    content.dataVencimento = item.dataVencimento ? converterDataIntParaMoment(item.dataVencimento).format('DD/MMM/YY') : '-'

    if (verificarAutorizacao([PermissoesAcesso.PODE_ALTERAR_VALORES_PLANO])) {
      content.botaoOpcoes = (
        <IconButton
          style={{ marginRight: size === 'small' ? -4 : 0 }}
          onClick={(event) => {
            event.stopPropagation()
            this.setState({ precoPlanoMenuAnchorEl: event.currentTarget, itemMenu: item })
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )
    }

    return content
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'admin/negocio.buscarNegocio',
      params: {
        id: this.props.negocio.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          plano: response.data.plano,
          paginaCarregada: true
        })

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoValorPreco', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  handlerRemover = () => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Preço do Negócio',
      text: <span>Você tem certeza que deseja remover este preço? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'admin/negocios.removerPrecoPlanoNegocio',
        contextoUsuario: 'admin',
        data: {
          idPrecoPlanoNegocio: this.state.itemMenu.id,
          idNegocio: this.props.negocio.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoValorPreco')
        }
      }
    })
  }

  handlerOpenCreateDialog = () => {
    openBackableDialog(ValorPlanoDialogPage, {
      plano: this.state.plano,
      negocio: this.props.negocio
    })
  }

  handlerEditar = (item) => {
    openBackableDialog(ValorPlanoDialogPage, {
      idPlanoPreco: item.id,
      plano: this.state.plano,
      negocio: this.props.negocio
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { plano } = this.state

    const toolbarActions = {
      actions: []
    }

    if (plano.id !== PlanoEnum.AVALIACAO.id && verificarAutorizacao([PermissoesAcesso.PODE_ALTERAR_VALORES_PLANO])) {
      toolbarActions.actions.push({
        icon: <AddIcon />,
        handleAction: this.handlerOpenCreateDialog
      })
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={880} toolbarActions={toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <React.Fragment>
              <FloatContent type="filter">
                <Grid container alignItems="center">
                  <Grid item style={{ marginRight: 16 }}>
                    <InputSelect
                      customVariant="naked"
                      nakedLeftPadding={false}
                      value={plano.id}
                      fullWidth={false}
                      onChange={(event) => {
                        const plano = PlanoEnum.getById(parseInt(event.target.value, 10))
                        this.setState({ plano: plano })
                      }}
                    >
                      {PlanoEnum.values().map((plano) => (
                        <MenuItem key={plano.id} value={plano.id}>
                          {plano.nome}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </Grid>
                </Grid>
              </FloatContent>

              <VirtualizedResponsiveTable
                loaderFunctionsMap={this.loaderFunctionsMap}
                key={plano.id}
                contextoUsuario="admin"
                endpoint="admin/negocios.buscarValoresPlanos"
                getRequestParametersFunction={this.getRequestParameters}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    const state = { lista: data.items }
                    this.setState(state)
                  }
                }}
                largeRenderProps={{
                  columns: [
                    { label: 'Mensal', horizontalPadding: 'small', props: { xs: true } },
                    { label: 'Trimestral', horizontalPadding: 'small', props: { xs: true } },
                    { label: 'Semestral', horizontalPadding: 'small', props: { xs: true } },
                    { label: 'Anual', horizontalPadding: 'small', props: { xs: true } },
                    { label: 'Data Vencimento', horizontalPadding: 'small', props: { xs: true } },
                    { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                  ],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('large', item)
                    return {
                      itemData: [content.precoMensal, content.precoTrimestral, content.precoSemestral, content.precoAnual, content.dataVencimento, content.botaoOpcoes]
                    }
                  }
                }}
                smallRenderProps={{
                  rowHeight: 120,
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('small', item)
                    return {
                      itemData: (
                        <Grid container alignItems="center" style={{ minWidth: 0 }}>
                          <Grid item style={{ marginRight: 6 }}>
                            <React.Fragment>
                              <Typography variant="body2">Mensal: {content.precoMensal}</Typography>
                              <Typography variant="body2">Trimestral: {content.precoTrimestral}</Typography>
                              <Typography variant="body2">Semestral: {content.precoSemestral}</Typography>
                              <Typography variant="body2">Anual: {content.precoAnual}</Typography>
                              <Typography variant="body2">Dt. Vencimento: {content.dataVencimento}</Typography>
                            </React.Fragment>
                            <Grid item>{content.botaoOpcoes}</Grid>
                          </Grid>
                        </Grid>
                      )
                    }
                  }
                }}
                emptyListProps={{
                  text: 'Nenhum registro encontrado'
                }}
              />

              <Menu anchorEl={this.state.precoPlanoMenuAnchorEl} open={Boolean(this.state.precoPlanoMenuAnchorEl)} onClose={() => this.closeMenu('precoPlanoMenuAnchorEl')}>
                <MenuItem
                  disabled={this.state.itemMenu && !this.state.itemMenu.negocio}
                  onClick={() => {
                    this.handlerEditar(this.state.itemMenu)
                    this.closeMenu('precoPlanoMenuAnchorEl')
                  }}
                >
                  Abrir/Editar
                </MenuItem>
                <MenuItem
                  disabled={this.state.itemMenu && !this.state.itemMenu.negocio}
                  onClick={() => {
                    this.handlerRemover()
                    this.closeMenu('precoPlanoMenuAnchorEl')
                  }}
                >
                  Remover
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ValoresPlanosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ValoresPlanosDialogPage)
