import { AxiosResponse } from 'axios'
import { getAPI } from 'support/components/api/PainelErpApiClient'

type BuscarDadosCarregamentoPlanoResponse = {
  negocio: {
    descricao: string
    site: string
    facebook: string
    instagram: string
    id: number
    nome: string
    notaMediaAvaliacoes: number
    quantidadeAvaliacoes: number
    publicoAlvo: string
    segmentosAtuacao: string
    dataVencimentoPlano: number
    dataSistemaAtivo: number
    diasDisponibilizadosPospago: number
    plano: {
      id: number
    }
    ativo: boolean
    agendamentoOnlineAtivo: boolean
    paginaPortalAtiva: boolean
    excluido: boolean
    endereco: {
      endereco: string
      numero: string
      complemento: string
      bairro: string
      cidade: string
      estado: {
        id: number
        sigla: string | null
        nome: string | null
      }
      cep: string
      latitude: number
      longitude: number
      descricaoFormatada: string
    }
    cpfOuCnpj: string
    usaPagamentoRecorrente: boolean
  }
  planosPrecos: {
    id: number
    plano: {
      id: number
    }
    precoMensal: number
    precoTrimestral: number
    percentualDescontoTrimestral: number
    precoSemestral: number
    percentualDescontoSemestral: number
    precoAnual: number
    percentualDescontoAnual: number
  }[]
  possuiPendenciasCadastrais: boolean
}

function buscarDadosCarregamentoPlano({ sendErroToGenericSnackbar = true }: { sendErroToGenericSnackbar?: boolean } = {}) {
  return new Promise<AxiosResponse<BuscarDadosCarregamentoPlanoResponse>>((resolve, reject) => {
    getAPI({
      url: 'erp/negocios.buscarDadosCarregamentoPlano',
      requerAutorizacao: true,
      onSuccess: (response: AxiosResponse<BuscarDadosCarregamentoPlanoResponse>) => {
        resolve(response)
      },
      onError: (error: unknown) => {
        reject(error)
      },
      sendErroToGenericSnackbar
    })
  })
}

export default buscarDadosCarregamentoPlano
