import { Button, Text } from '@grupoboticario/flora-react'
import { Box } from '@material-ui/core'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import { CONTRATEI_URL, iconColor, JA_TINHA_URL, LOGO_SALAO_MERCADO_PAGO } from 'support/domain/mercadopago/constants'

import { useStyles } from './MaquininhaIntegrada.styles'

type MaquininhaIntegradaCardTaxsProps = {
  onOpenLink: (url: string) => void
}

export const MaquininhaIntegradaCardTaxs = ({ onOpenLink }: MaquininhaIntegradaCardTaxsProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.card}>
      <Box className={classes.cardContent}>
        <Box className={classes.wrapperTitleContent}>
          <Box className={classes.wrapperIconContent}>
            <Box className={classes.wrapperIconText}>
              <MonetizationOnOutlinedIcon htmlColor={iconColor} />
              <Text className={classes.iconText}>Solicite a revisão de suas taxas</Text>
            </Box>
          </Box>
        </Box>
        <Box className={classes.wrapperImg}>
          <img src={LOGO_SALAO_MERCADO_PAGO} />
          <Box className={classes.column}>
            <Text className={classes.cardText}>Clientes Salão 99 que possuem maquininha Mercado Pago contam com taxas especiais!</Text>
            <Box className={classes.wrapperButtonsTaxs}>
              <Button onClick={() => onOpenLink(CONTRATEI_URL)}>Novo cliente Mercado Pago</Button>
              <Button onClick={() => onOpenLink(JA_TINHA_URL)}>Já tinha conta no Mercado Pago</Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.wrapperButtonsTaxsMobile}>
          <Button onClick={() => onOpenLink(CONTRATEI_URL)}>Novo cliente Mercado Pago</Button>
          <Button onClick={() => onOpenLink(JA_TINHA_URL)}>Já tinha conta no Mercado Pago</Button>
        </Box>
      </Box>
    </Box>
  )
}
