import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import PermissoesAcesso from 'support/domain/admin/usuario/PermissoesAcesso'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class PermissoesUsuarioDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Permissões Usuário',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputEmailOrigem = React.createRef()
    this.inputEmailDestino = React.createRef()
    this.contentWithPreloadFunctions = {}
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  atualizarUsuarioAdmin = (usuario) => {
    this.setState({
      ajaxing: false,
      usuario: usuario,
      permissoes: PermissoesAcesso.generateAllItems(usuario.permissoes)
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/usuario.buscarUsuarioAdminPorId',
      params: {
        idUsuario: this.props.usuario.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.atualizarUsuarioAdmin(response.data)
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    const dados = {
      idUsuario: this.state.usuario.id,
      emailDestino: this.inputEmailDestino.current.value
    }

    return dados
  }

  removerPermissaoAdmin = () => {
    if (!verificarAutorizacao([PermissoesAcesso.PODE_ALTERAR_PERMISSAO_ADMIN])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    postAPI({
      url: 'admin/usuario.removerPermissaoAdmin',
      data: {
        idUsuarioAdmin: this.state.usuario.id
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.contentWithPreloadFunctions.reload()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  habilitarPermissaoAdmin = () => {
    if (!verificarAutorizacao([PermissoesAcesso.PODE_ALTERAR_PERMISSAO_ADMIN])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    postAPI({
      url: 'admin/usuario.habilitarPermissaoAdmin',
      data: {
        idUsuario: this.props.usuario.id
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.contentWithPreloadFunctions.reload()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'admin/usuario.alterarPermissoesAcesso',
      data: {
        idUsuarioAdmin: this.state.usuario.id,
        idUsuario: this.props.usuario.id,
        permissoes: PermissoesAcesso.getString(this.state.permissoes)
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.atualizarUsuarioAdmin(response.data)
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoUsuario')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formUsuarioAdmin')
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  handleChangePermissao = (permissaoAlterada, event) => {
    const checked = event.target.checked

    const permissoes = this.state.permissoes
    const positionsToIgnore = [permissaoAlterada.position]

    for (let permissao of permissoes) {
      if (permissao.exists === checked) {
        positionsToIgnore.push(permissao.position)
      }
    }

    this.changePermissoes(permissaoAlterada, checked)
  }

  changePermissoes = (permissoesParaAlteracao, checked) => {
    const changePermissaoFunction = (permissoes, permissaoParaAlterar) => {
      for (let permissao of permissoes) {
        if (permissao.position === permissaoParaAlterar.position) {
          permissao.exists = checked
          break
        }
      }
    }

    const permissoes = this.state.permissoes
    if (Array.isArray(permissoesParaAlteracao)) {
      for (let permissaoParaAlteracao of permissoesParaAlteracao) {
        changePermissaoFunction(permissoes, permissaoParaAlteracao)
      }
    } else {
      changePermissaoFunction(permissoes, permissoesParaAlteracao)
    }
    this.setState({ permissoes: permissoes })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { usuario, permissoes } = this.state

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.contentWithPreloadFunctions}>
          {() => (
            <React.Fragment>
              <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={usuario ? this.removerPermissaoAdmin : this.habilitarPermissaoAdmin}>
                {usuario ? 'Remover Permissão Admin' : 'Habilitar Permissão Admin'}
              </Button>
              {usuario && (
                <form id="formUsuarioAdmin" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  <Grid style={{ marginTop: 15 }} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    {permissoes.map((permissao) => (
                      <Grid item>
                        <FormControlLabel
                          key={permissao.position}
                          control={
                            <Checkbox
                              style={{ padding: 5, marginRight: 8 }}
                              color="primary"
                              disabled={this.state.ajaxing}
                              checked={permissao.exists}
                              onChange={(event) => this.handleChangePermissao(permissao, event)}
                            />
                          }
                          label={
                            <Typography variant="body2" className={classes.textCheckbox}>
                              {permissao.descricao}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <HiddenSubmitButton />

                  {this.state.paginaCarregada && (
                    <div className={classes.containerFooterButtons}>
                      <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                        Cancelar
                      </Button>
                      <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                        {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                      </Button>
                    </div>
                  )}
                </form>
              )}
            </React.Fragment>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

PermissoesUsuarioDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PermissoesUsuarioDialogPage)
