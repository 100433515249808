import TipoMovimentacaoEstoqueEnum from 'support/domain/inventario/TipoMovimentacaoEstoqueEnum'
import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  COMPRA: { id: 1, tipo: TipoMovimentacaoEstoqueEnum.ENTRADA.id, descricao: 'Compra', habilitarMovimentacaoEstoque: false },
  FABRICACAO: { id: 15, tipo: TipoMovimentacaoEstoqueEnum.ENTRADA.id, descricao: 'Fabricação Própria', habilitarMovimentacaoEstoque: true },
  RETORNO: { id: 2, tipo: TipoMovimentacaoEstoqueEnum.ENTRADA.id, descricao: 'Retorno', habilitarMovimentacaoEstoque: true },
  ESTOQUE_INICIAL: { id: 3, tipo: TipoMovimentacaoEstoqueEnum.ENTRADA.id, descricao: 'Estoque Inicial', habilitarMovimentacaoEstoque: false },
  VENDA: { id: 4, tipo: TipoMovimentacaoEstoqueEnum.SAIDA.id, descricao: 'Venda', habilitarMovimentacaoEstoque: false },
  UTILIZACAO: { id: 12, tipo: TipoMovimentacaoEstoqueEnum.SAIDA.id, descricao: 'Utilização', habilitarMovimentacaoEstoque: true },
  AVARIADO: { id: 5, tipo: TipoMovimentacaoEstoqueEnum.SAIDA.id, descricao: 'Avariado', habilitarMovimentacaoEstoque: true },
  PERDIDO: { id: 6, tipo: TipoMovimentacaoEstoqueEnum.SAIDA.id, descricao: 'Perdido', habilitarMovimentacaoEstoque: true },
  VENCIDO: { id: 7, tipo: TipoMovimentacaoEstoqueEnum.SAIDA.id, descricao: 'Vencido', habilitarMovimentacaoEstoque: true },
  AJUSTE: { id: 8, descricao: 'Ajuste', habilitarMovimentacaoEstoque: true },
  OUTROS: { id: 9, descricao: 'Outros', habilitarMovimentacaoEstoque: true },
  CANCELAMENTO_VENDA: { id: 10, tipo: TipoMovimentacaoEstoqueEnum.ENTRADA.id, descricao: 'Cancelamento de Venda', habilitarMovimentacaoEstoque: false },
  CANCELAMENTO_COMPRA: { id: 11, tipo: TipoMovimentacaoEstoqueEnum.SAIDA.id, descricao: 'Cancelamento de Compra', habilitarMovimentacaoEstoque: false },
  ATENDIMENTO: { id: 13, tipo: TipoMovimentacaoEstoqueEnum.SAIDA.id, descricao: 'Atendimento', habilitarMovimentacaoEstoque: false },
  CANCELAMENTO_ATENDIMENTO: { id: 14, tipo: TipoMovimentacaoEstoqueEnum.ENTRADA.id, descricao: 'Cancelamento de Atendimento', habilitarMovimentacaoEstoque: false }
})

enumObject.getByTipo = (tipo) => {
  const items = []
  enumObject.values().forEach(function (motivo) {
    if (motivo.tipo === tipo || motivo.tipo === undefined) {
      items.push(motivo)
    }
  })

  return items
}

export default enumObject
