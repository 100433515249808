import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { postAPI as postAPIErp } from 'support/components/api/ApiClient'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import { SuccessResponseType } from 'support/components/api/types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import { StatusPagamentoEnum, StatusPagamentoType } from 'support/domain/mercadopago/MercadoPagoEnum'

import { StatusPagamento } from './StatusPagamento'

export interface StylesTheme extends Theme {
  spinner: string
  h1: string
  h2: string
  p: string
  icon: string
  button: string
}

type MercadoPagoDialogPageProps = {
  mercadoPagoPagamentoId: number
  handleCloseDialog: () => void
  thisNotaDialogPage: {
    setPagamentosMercadoCancelado: () => void
  }
  idNota?: number
  isTotal?: boolean
}

type MercadoPagoResponse = {
  status: StatusPagamentoType
}

type PagamentoStatus = 'loading' | 'error' | 'success'

const useStyles = makeStyles<StylesTheme>((theme) =>
  createStyles({
    spinner: {
      marginBottom: 40,
      borderWidth: 6,
      width: '60px',
      height: '60px'
    },
    h1: {
      fontSize: '20px',
      fontWeight: 500,
      textAlign: 'center'
    },
    h2: {
      fontSize: '18px',
      fontWeight: 400,
      textAlign: 'center',
      '& > b': {
        color: theme.palette.secondary.main
      }
    },
    p: {
      fontSize: '16px',
      color: '#666',
      fontWeight: 400
    },
    icon: {
      width: '60px',
      height: '60px',
      marginBottom: '40px'
    },
    button: {
      marginTop: 40,
      fontWeight: 500,
      textTransform: 'capitalize',
      padding: '10px 24px'
    }
  })
)

const STEPS_ENUM = {
  FIRST: 1,
  SECOND: 2,
  LAST: 3
}

const INTERVAL_CALL = 2000

export const MercadoPagoDialogPage = ({ mercadoPagoPagamentoId, isTotal, idNota, handleCloseDialog, thisNotaDialogPage, ...rest }: MercadoPagoDialogPageProps) => {
  const [step, setStep] = useState(STEPS_ENUM.FIRST)
  const [status, setStatus] = useState<StatusPagamentoType | void>(StatusPagamentoEnum.ABERTO)
  const intervaloRef = useRef(INTERVAL_CALL)
  const [contador] = useState(0)
  const classes = useStyles()

  const checkStatus = useCallback(() => {
    postAPI({
      url: `erp/vendas/integracoes-mp/${mercadoPagoPagamentoId}`,
      requerAutorizacao: true,
      onSuccess: (response: SuccessResponseType<MercadoPagoResponse>) => {
        setStatus(response.data.status)
        if (response.data.status === StatusPagamentoEnum.PENDENTE) {
          setStatus(undefined)
          return
        } else if (response.data.status === StatusPagamentoEnum.CONCLUIDO) {
          if (isTotal) {
            postAPIErp({
              method: 'post',
              url: 'erp/vendas.fecharNota',
              contextoUsuario: 'erp',
              requerAutorizacao: true,
              data: {
                id: idNota,
                concluirAtendimentos: false,
                emitirNfs: false,
                retornarVendas: true
              }
            })
          }
          setStep(STEPS_ENUM.SECOND)
        } else if (response.data.status === StatusPagamentoEnum.CANCELADO || response.data.status === StatusPagamentoEnum.ESTORNADO) {
          thisNotaDialogPage.setPagamentosMercadoCancelado()
          setStatus(response.data.status)
          setStep(STEPS_ENUM.LAST)
        }
      },
      onError: () => {
        setStep(STEPS_ENUM.LAST)
      }
    })
  }, [thisNotaDialogPage, mercadoPagoPagamentoId, idNota, isTotal])

  const acaoDoTemporizador = useCallback(() => {
    checkStatus()
  }, [checkStatus])

  useEffect(() => {
    checkStatus()
  }, [checkStatus])

  useEffect(() => {
    let temporizador: NodeJS.Timer

    if (step === 1) {
      temporizador = setInterval(acaoDoTemporizador, intervaloRef.current)
    }

    return () => clearInterval(temporizador)
  }, [step, contador, acaoDoTemporizador])

  const renderSteps = useMemo(() => {
    const condition = {
      [STEPS_ENUM.FIRST]: 'loading',
      [STEPS_ENUM.SECOND]: 'success',
      [STEPS_ENUM.LAST]: 'error'
    }
    return condition[step]
  }, [step])

  const dialogProps = extractDialogProps(rest)

  return (
    <DialogPage {...dialogProps} {...rest} align="center" handleCloseDialog={handleCloseDialog} contentMaxWidth={845} hidebackButton hasCloseButton={step === STEPS_ENUM.LAST}>
      <StatusPagamento
        mercadoPagoPagamentoId={mercadoPagoPagamentoId}
        status={status as StatusPagamentoType}
        handleCloseDialog={handleCloseDialog}
        statusType={renderSteps as PagamentoStatus}
        {...rest}
        classes={classes as ClassNameMap<string> & StylesTheme}
      />
    </DialogPage>
  )
}
export default MercadoPagoDialogPage
