import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'
import PainelClienteContent from 'pages/erp/painel/cliente/PainelClienteContent'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import FechamentoContaDialogPage from 'pages/erp/painel/venda/FechamentoContaDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CustomDialog from 'support/components/dialog/CustomDialog'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const MAX_WIDTH_PAPER_DEFAULT = 760
const MAX_WIDTH_PAPER_TAB_VENDAS = 1080

const styles = (theme) => ({
  container: {
    alignItems: 'flex-start'
  },
  paper: {
    minWidth: 300,
    width: '100%',
    maxWidth: MAX_WIDTH_PAPER_DEFAULT,
    wwillChange: 'ttransform'
  },
  dialogBar: {
    paddingTop: 12,
    paddingLeft: theme.screen.margin - 14,
    paddingRight: theme.screen.margin,
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.screen.marginXs - 14,
      paddingRight: theme.screen.marginXs
    },
    [theme.breakpoints.down(380)]: {
      paddingLeft: theme.screen.margin380 - 14,
      paddingRight: theme.screen.margin380
    }
  },
  gridBotaoFecharContaExpandido: {
    marginLeft: 8
  },
  content: {
    ...theme.container.basic({ paddingTop: 42 })
  }
})

class PainelAtendimentoCliente extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      clienteInfoTabValue: 0,
      lista: []
    }
    this.paperRef = React.createRef()
    this.contentWithPreloadFunctions = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCliente', (props) => {
      if (props !== undefined && props.cliente !== undefined) {
        this.setState({ cliente: props.cliente })
      } else {
        this.contentWithPreloadFunctions.reload()
      }
    })

    this.eventsManager.sub('AlteracaoSaldoCliente', (props) => {
      this.contentWithPreloadFunctions.reloadInBackground()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/clientes.buscarPorId',
      params: {
        id: this.props.cliente.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          cliente: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  fecharConta = () => {
    if (verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(FechamentoContaDialogPage, {
        cliente: this.state.cliente,
        retornarVendas: true
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const { classes } = this.props
    const { clienteInfoTabValue, cliente } = this.state

    if (this.paperRef.current) {
      if (clienteInfoTabValue === 1) {
        this.paperRef.current.style.maxWidth = MAX_WIDTH_PAPER_TAB_VENDAS + 'px'
      } else {
        this.paperRef.current.style.maxWidth = MAX_WIDTH_PAPER_DEFAULT + 'px'
      }
    }

    return (
      <CustomDialog classes={{ container: classes.container, paper: classNames(classes.paper, 'needsWillChange') }} dialogProps={this.props} paperRef={this.paperRef}>
        <ContentWithPreload loadContentPadding={true} loadFunction={this.carregarMain} functionsMap={this.contentWithPreloadFunctions}>
          {() => {
            let nomeUsuario = cliente.apelido
            if (isWidthDown('xs', this.props.width)) {
              nomeUsuario = nomeUsuario.split(' ')[0]
            }

            return (
              <div>
                <Grid container alignItems="center" className={classes.dialogBar} wrap="nowrap" style={{ minWidth: 0 }}>
                  <Grid item>
                    <IconButton onClick={this.props.handleCloseDialog} style={{ marginRight: 8 }}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs style={{ minWidth: 0 }}>
                    <Typography variant="h6" className={classes.nomeCliente} noWrap={true}>
                      {nomeUsuario}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.gridBotaoFecharContaExpandido}>
                    <Button color="secondary" style={{ marginRight: -6, whiteSpace: 'nowrap' }} onClick={this.fecharConta}>
                      Faturar
                    </Button>
                  </Grid>
                </Grid>

                <div className={classes.content}>
                  <PainelClienteContent
                    parentDialog={this}
                    cliente={cliente}
                    containerScrollElement={this.paperRef.current}
                    infoTabValue={clienteInfoTabValue}
                    onChangeTab={(value) => {
                      this.setState({ clienteInfoTabValue: value })
                    }}
                    vendaDialogProps={{
                      data: this.props.data,
                      tipoAtendimentoPreSelecionado: this.props.tipoAtendimentoPreSelecionado,
                      colaboradorPreSelecionado: this.props.colaboradorPreSelecionado,
                      horarioPrePreenchido: this.props.horarioPrePreenchido
                    }}
                  />
                </div>
              </div>
            )
          }}
        </ContentWithPreload>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

PainelAtendimentoCliente.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(PainelAtendimentoCliente))
