import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import GroupIcon from '@material-ui/icons/Group'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PersonIcon from '@material-ui/icons/Person'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import moment from 'moment'
import RegisterPhoneDialogPage from 'pages/conta/RegisterPhoneDialogPage'
import { prepararListaItensParaRecuperacaoEstado } from 'pages/portal/negocio/NegocioPage'
import { ROUTE_CONTA_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PortalApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CalendarioSimples from 'support/components/calendar/CalendarioSimples'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CustomDialog from 'support/components/dialog/CustomDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LoginIcon from 'support/components/icon/Login'
import InputText from 'support/components/input/InputText'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import NegocioService from 'support/domain/negocio/NegocioService'
import ServicoService from 'support/domain/servico/ServicoService'
import TipoExibicaoPrecoEnum from 'support/domain/servico/TipoExibicaoPrecoEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { createErrorsMap } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import ImageUtil from 'support/util/ImageUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { formatarNome } from 'support/util/TextFormatter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'
import { formatarDuracao } from 'support/util/TimeFormatter'
import TimeUtil from 'support/util/TimeUtil'

import StepAgendamentoConcluido from './StepAgendamentoConcluido'
import StepConfirmacao from './StepConfirmacao'
import StepListagemHorariosLivres from './StepListagemHorariosLivres'
import styles from './styles'
import { gerarHorariosDisponiveis } from './utils'

const ID_QUALQUER_COLABORADOR = 0

class AgendarAtendimentosDialog extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.periodosHorariosIndisponiveisGerais = []
    this.ordemSelecaoContador = 0

    for (let item of props.itens) {
      item.inputObservacoes = React.createRef()
    }

    let dataSelecionada = this.props.data

    if (!dataSelecionada) {
      dataSelecionada = moment()
    }

    if (converterMomentParaDataInt(this.props.data) < converterMomentParaDataInt(moment())) {
      dataSelecionada = moment()
    }

    if (dataSelecionada.hour() >= 17) {
      dataSelecionada.add(1, 'days')
    }

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      step: 1,
      dialog: null,
      itens: this.props.itens,
      itensSubmetidos: [],
      dataSelecionada: dataSelecionada,
      colaboradores: []
    }

    this.carregarDisponibilidadeAgendamentoFunctionMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('LimparMarcacaoServicos', (props) => {
      this.setState({ itens: [] })
    })
    this.eventsManager.sub('AlteracaoDataCalendario', (props) => {
      this.setState({ dataSelecionada: props.data })
      if (this.state.dialog) {
        this.fecharDialogBuscarProximosHorarios()
      }
      if (props.idItem && props.horario && props.duracao) {
        this.callbackCarregamentoDataCalendario = () => {
          this.desmarcarHorarios()
          this.selecionarHorario(props.idItem, props.horario, props.duracao)
        }
      }
    })
    this.eventsManager.sub('DesmarcarServico', (props) => {
      const { itens } = this.state
      let index = null
      for (let i = 0; i < itens.length; i++) {
        if (props.id === itens[i].id) {
          index = i
          break
        }
      }
      if (index !== null) {
        itens.splice(index, 1)
      }
      this.setState({ itens: itens })
    })
    EventsManager.pub('EnviarPageViewGA', {
      pageTitle: 'Perfil Salão99 - Agendamento Iniciado',
      pagePath: '/agendamento-iniciado'
    })
  }

  carregarDisponibilidadeAgendamento = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'ptl/atendimentos.buscarDisponibilidadeHorariosParaAgendamento',
      params: {
        idWithHash: NegocioService.gerarNegocioIdWithHash(this.props.negocio),
        data: converterMomentParaDataInt(this.state.dataSelecionada),
        idsServicos: this.state.itens.map((item) => item.servico.id)
      },
      onSuccess: (response) => {
        const { itens } = this.state
        const colaboradores = response.data.colaboradores
        const servicos = response.data.servicos

        const mapColaboradoresPorId = {}
        for (let colaborador of colaboradores) {
          mapColaboradoresPorId[colaborador.id] = colaborador
        }

        const mapServicosPorId = {}
        for (let servico of servicos) {
          mapServicosPorId[servico.id] = servico
        }

        for (let item of itens) {
          item.colaboradores = []
          item.mapColaboradoresPorId = {}
          for (let colaboradorServico of item.servico.colaboradoresServicos) {
            const colaborador = mapColaboradoresPorId[colaboradorServico.colaborador.id]
            if (colaborador) {
              item.colaboradores.push(colaborador)
              item.mapColaboradoresPorId[colaborador.id] = colaborador
            }
          }
          item.colaboradores.sort((a, b) => {
            if (a.nome < b.nome) {
              return -1
            }
            if (a.nome > b.nome) {
              return 1
            }
            if (a.id < b.id) {
              return -1
            }
            if (a.id > b.id) {
              return 1
            }
            return 0
          })
          if (item.colaboradores.length > 1) {
            if (this.props.negocio.configuracoesGerais.exibicaoQualquerProfissional) {
              const colaborador = {
                id: ID_QUALQUER_COLABORADOR,
                nome: 'Qualquer Profissional'
              }
              item.colaboradores.unshift(colaborador)
              item.mapColaboradoresPorId[colaborador.id] = colaborador
            }
          }

          if (item.colaborador && !item.mapColaboradoresPorId[item.colaborador.id]) {
            item.colaborador = null
          }

          if (item.colaboradores.length === 1) {
            item.colaborador = {
              id: item.colaboradores[0].id
            }
          }

          item.servico.periodosHorariosIndisponiveis = null
          const servico = mapServicosPorId[item.servico.id]
          if (servico.periodosHorariosIndisponiveis && servico.periodosHorariosIndisponiveis.length) {
            item.servico.periodosHorariosIndisponiveis = servico.periodosHorariosIndisponiveis
          }

          item.periodoHorarioSelecionado = null
        }

        this.atualizarControlePeriodosHorariosSelecionados()

        this.setState({
          itens: itens,
          colaboradores: colaboradores,
          servicos: servicos
        })

        if (this.callbackCarregamentoDataCalendario) {
          this.callbackCarregamentoDataCalendario()
          this.callbackCarregamentoDataCalendario = null
        }

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      },
      requerAutorizacao: true
    })
  }

  closeSelecaoColaboradorMenu = () => {
    this.setState({ selecaoColaboradorAnchorPosition: null })
  }

  atualizarControlePeriodosHorariosSelecionados = () => {
    const { itens } = this.state
    this.periodosHorariosIndisponiveisGerais = []
    for (let item of itens) {
      if (item.periodoHorarioSelecionado) {
        this.periodosHorariosIndisponiveisGerais.push({
          de: item.periodoHorarioSelecionado.de,
          ate: item.periodoHorarioSelecionado.ate
        })
      }
    }
  }

  selecionarHorario = (idItem, horarioDisponivel, duracao) => {
    const { itens } = this.state
    for (let item of itens) {
      if (idItem === item.id) {
        item.periodoHorarioSelecionado = {
          de: horarioDisponivel,
          ate: TimeUtil.adiciconarMinutosHorarioInt(horarioDisponivel, duracao)
        }
        item.ordemSelecao = ++this.ordemSelecaoContador
        this.atualizarControlePeriodosHorariosSelecionados()
        this.setState({ itens: itens })
        break
      }
    }
  }

  desmarcarHorario = (idItem) => {
    const { itens } = this.state
    for (let item of itens) {
      if (idItem === item.id) {
        item.periodoHorarioSelecionado = null
        this.atualizarControlePeriodosHorariosSelecionados()
        this.setState({ itens: itens })
        break
      }
    }
  }

  desmarcarHorarios = () => {
    const { itens } = this.state
    for (let item of itens) {
      item.periodoHorarioSelecionado = null
      this.atualizarControlePeriodosHorariosSelecionados()
    }
    this.setState({ itens: itens })
  }

  abrirDialogBuscarProximosHorarios = (idItem, colaborador, servico, variacao) => {
    this.setState({
      dialog: 1,
      idItemBuscaHorariosLivres: idItem,
      colaboradorBuscaHorariosLivres: colaborador,
      servicoBuscaHorariosLivres: servico,
      variacaoBuscaHorariosLivres: variacao
    })
  }

  fecharDialogBuscarProximosHorarios = () => {
    this.setState({
      dialog: null,
      idItemBuscaHorariosLivres: null,
      colaboradorBuscaHorariosLivres: null,
      servicoBuscaHorariosLivres: null,
      variacaoBuscaHorariosLivres: null
    })
  }

  submeterItens = () => {
    if (!this.props.usuarioSessao || !this.props.usuarioSessao.carregado) {
      const irParaIdentificacaoUsuario = (path) => {
        prepararListaItensParaRecuperacaoEstado(this.props.negocio.id, this.state.dataSelecionada, this.state.itens)
        this.props.setCustomProps({ precisaFazerLogin: true })
        this.props.setOnClosed(() => {
          EventsManager.pub('RoutePush', {
            path: ROUTE_CONTA_MAIN + path + '?continuacao=' + encodeURIComponent(window.location.href)
          })
        })
      }

      openBackableDialog(MessageDialog, {
        parent: this,
        title: (
          <span>
            <LoginIcon style={{ verticalAlign: 'middle', marginTop: -1, marginRight: 6 }} /> Continuar...
          </span>
        ),
        text: 'É necessário fazer login para confirmar o agendamento. Caso ainda não tenha uma conta no Salão99, crie uma agora mesmo! Leva menos de 1 minutinho =)',
        actionButtons: [
          {
            label: 'Fazer Login',
            onClickFunction: (dialogInstance) => {
              irParaIdentificacaoUsuario('/login')
              dialogInstance.props.setShouldCloseParent(true)
              dialogInstance.props.handleCloseDialog()
            }
          },
          {
            label: 'Criar Conta',
            onClickFunction: (dialogInstance) => {
              irParaIdentificacaoUsuario('/criar-conta')
              dialogInstance.props.setShouldCloseParent(true)
              dialogInstance.props.handleCloseDialog()
            }
          }
        ]
      })

      return
    }

    const itensSubmetidos = this.state.itens.slice()
    itensSubmetidos.sort((a, b) => {
      if (a.periodoHorarioSelecionado.de < b.periodoHorarioSelecionado.de) {
        return -1
      }
      if (a.periodoHorarioSelecionado.de > b.periodoHorarioSelecionado.de) {
        return 1
      }
      if (a.periodoHorarioSelecionado.ate < b.periodoHorarioSelecionado.ate) {
        return -1
      }
      if (a.periodoHorarioSelecionado.ate > b.periodoHorarioSelecionado.ate) {
        return 1
      }
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })
    for (let item of itensSubmetidos) {
      if (item.inputObservacoes && item.inputObservacoes.current && item.inputObservacoes.current.value) {
        item.textoObservacoes = item.inputObservacoes.current.value
      }
    }
    this.setState({
      step: 2,
      itensSubmetidos: itensSubmetidos
    })
  }

  agendarAtendimentos = () => {
    this.setState({
      ajaxing: true
    })

    const itensParaAgendar = this.state.itensSubmetidos.slice()
    itensParaAgendar.sort((a, b) => {
      if (a.ordemSelecao < b.ordemSelecao) {
        return -1
      }
      if (a.ordemSelecao > b.ordemSelecao) {
        return 1
      }
      return 0
    })

    postAPI({
      url: 'ptl/atendimentos.agendarAtendimentos',
      data: {
        idNegocio: this.props.negocio.id,
        hashNegocio: this.props.negocio.hash,
        data: converterMomentParaDataInt(this.state.dataSelecionada),
        itens: itensParaAgendar.map((item) => {
          let colaboradores
          if (item.colaborador.id === ID_QUALQUER_COLABORADOR) {
            colaboradores = item.colaboradores.filter((colaborador) => colaborador.id !== ID_QUALQUER_COLABORADOR).map((colaborador) => ({ id: colaborador.id }))
          } else {
            colaboradores = [{ id: item.colaborador.id }]
          }

          return {
            colaboradores: colaboradores,
            servico: { id: item.servico.id },
            variacaoServico: { id: item.variacao.id },
            horarioInicio: item.periodoHorarioSelecionado.de,
            observacoes: item.textoObservacoes
          }
        })
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('EnviarPageViewGA', {
          pageTitle: 'Perfil Salão99 - Agendamento Concluído',
          pagePath: '/agendamento-concluido'
        })
        this.setState({
          step: 3,
          ajaxing: true
        })
      },
      onError: (response) => {
        this.setState({ ajaxing: false })
        const errorsMap = createErrorsMap(response.data.errors)
        if (errorsMap('telefone')) {
          openBackableDialog(RegisterPhoneDialogPage, { name: SessaoUsuario.getUsuario().nome, onSuccess: () => this.agendarAtendimentos() })
        }
        if (errorsMap('conflitoHorario')) {
          openBackableDialog(MessageDialog, {
            title: (
              <span style={{ color: '#f36d6d' }}>
                <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginTop: -1 }} /> Horário Indisponível
              </span>
            ),
            text: errorsMap('conflitoHorario'),
            buttonLabel: 'Escolher Outro',
            onClosed: () => {
              this.setState({
                step: 1
              })
              this.carregarDisponibilidadeAgendamentoFunctionMap.reload()
            }
          })
          return
        }
        if (errorsMap('dadosDesatualizados')) {
          openBackableDialog(MessageDialog, {
            title: (
              <span style={{ color: '#f36d6d' }}>
                <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginTop: -1 }} /> Dados Desatualizados
              </span>
            ),
            text: errorsMap('dadosDesatualizados'),
            actionButtons: [
              {
                label: 'Recarregar',
                onClickFunction: () => {
                  document.location.reload(true)
                }
              }
            ]
          })
          return
        }
        if (errorsMap('acessoBloqueado')) {
          openBackableDialog(MessageDialog, {
            title: (
              <span style={{ color: '#f36d6d' }}>
                <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginTop: -1 }} /> Acesso Bloqueado
              </span>
            ),
            text: (
              <span>
                Este estabelecimento bloqueou temporariamente o seu acesso à Agenda Online. Em caso de dúvidas, entre em contato diretamente com <b>{this.props.negocio.nome}</b>.
              </span>
            ),
            actionButtons: [
              {
                label: 'Sair',
                onClickFunction: () => {
                  document.location.reload(true)
                }
              }
            ]
          })
          return
        }
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.itens || this.state.itens.length === 0) {
      this.props.handleCloseDialog()
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, negocio } = this.props
    const {
      ajaxing,
      step,
      dialog,
      itens,
      itensSubmetidos,
      dataSelecionada,
      selecaoColaboradorItem,
      idItemBuscaHorariosLivres,
      colaboradorBuscaHorariosLivres,
      servicoBuscaHorariosLivres,
      variacaoBuscaHorariosLivres
    } = this.state

    return (
      <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={dialogProps}>
        <div className={classes.contentContainer}>
          <div style={{ display: step === 1 && !dialog ? 'block' : 'none' }}>
            <Grid container alignItems="center" style={{ marginBottom: 16 }}>
              <Grid item xs style={{ paddingLeft: 8 }}>
                <Typography variant="h6" className={classes.titulo}>
                  Agendar
                </Typography>
              </Grid>
              <Grid style={{ marginRight: 0 }}>
                <IconButton
                  onClick={() => {
                    this.props.handleCloseDialog()
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <CalendarioSimples
              data={this.state.dataSelecionada}
              onChange={(props) => {
                if (converterMomentParaDataInt(this.state.dataSelecionada) !== converterMomentParaDataInt(props.data)) {
                  EventsManager.pub('AlteracaoDataCalendario', { data: props.data })
                }
              }}
              dataInicial={moment()}
              dataFinal={moment().add(this.props.negocio.configuracoesGerais.antecedenciaMaximaAgendamentoOnline.id, 'day')}
            />

            <div className={classes.diaSelecionadoContainer}>
              <Typography variant="h6" style={{ fontSize: 16, fontWeight: 400 }}>
                Para <b style={{ marginLeft: 1 }}>{dataSelecionada.format('ddd, DD/MMM/YYYY')}</b>
              </Typography>
            </div>

            <ContentWithPreload
              loadContentPadding={true}
              functionsMap={this.carregarDisponibilidadeAgendamentoFunctionMap}
              loadFunction={this.carregarDisponibilidadeAgendamento}
              loadKey={dataSelecionada.format('x')}
            >
              {() => {
                let botaoContinuarEnabled = true

                for (let item of itens) {
                  if (!item.periodoHorarioSelecionado) {
                    botaoContinuarEnabled = false
                    break
                  }
                }

                return (
                  <React.Fragment>
                    <div style={{ padding: '0px 8px' }}>
                      {itens.map((item, index) => {
                        let imageContent = null
                        let labelContent = null

                        let horariosSelecaoContent = null
                        const containerItemClasses = [classes.containerItem]

                        if (item.colaborador) {
                          let colaborador = item.mapColaboradoresPorId[item.colaborador.id]

                          if (colaborador.id !== ID_QUALQUER_COLABORADOR) {
                            labelContent = formatarNome(colaborador.nome, 2)
                            if (colaborador.foto) {
                              const variacoesFotoColaborador = ImageUtil.extrairVariacoesImagem(colaborador.foto)
                              imageContent = <img src={variacoesFotoColaborador.get(0)} className={classes.fotoColaborador} alt="Foto" />
                            } else {
                              imageContent = (
                                <div className={classes.semFotoColaboradorContainer}>
                                  <PersonIcon className={classes.semFotoColaboradorIcone} />
                                </div>
                              )
                            }
                          } else {
                            labelContent = colaborador.nome
                            imageContent = (
                              <div className={classes.semFotoColaboradorContainer}>
                                <GroupIcon className={classes.semFotoColaboradorIcone} />
                              </div>
                            )
                          }

                          if (!item.periodoHorarioSelecionado) {
                            const horariosDisponiveis = gerarHorariosDisponiveis(colaborador, negocio, item, this.state.itens)

                            if (horariosDisponiveis && horariosDisponiveis.length > 0) {
                              horariosSelecaoContent = (
                                <div>
                                  {horariosDisponiveis.map((horarioDisponivel, index) => (
                                    <div
                                      key={index}
                                      className={classes.containerHorarioDisponivel}
                                      onClick={() => {
                                        this.selecionarHorario(item.id, horarioDisponivel, item.variacao.duracao)
                                      }}
                                    >
                                      {formatarHorarioInt(horarioDisponivel)}
                                    </div>
                                  ))}
                                  <br />
                                  <Button
                                    size="small"
                                    endIcon={<SearchIcon />}
                                    style={{ marginLeft: 2, marginTop: 4 }}
                                    onClick={() => {
                                      this.abrirDialogBuscarProximosHorarios(item.id, colaborador, item.servico, item.variacao)
                                    }}
                                  >
                                    Mais Horários Livres
                                  </Button>
                                </div>
                              )
                            } else {
                              horariosSelecaoContent = (
                                <div>
                                  <div key={index} className={classes.containerHorarioIndisponivel}>
                                    Nenhum horário disponível
                                  </div>
                                  <br />
                                  <Button
                                    size="small"
                                    endIcon={<SearchIcon />}
                                    style={{ marginLeft: 2, marginTop: 4 }}
                                    onClick={() => {
                                      this.abrirDialogBuscarProximosHorarios(item.id, colaborador, item.servico, item.variacao)
                                    }}
                                  >
                                    Buscar Horários Livres
                                  </Button>
                                </div>
                              )
                            }
                          } else {
                            horariosSelecaoContent = (
                              <div
                                className={classes.containerHorarioEscolhido}
                                onClick={() => {
                                  this.desmarcarHorario(item.id)
                                }}
                              >
                                Horário escolhido{' '}
                                <b>
                                  {formatarHorarioInt(item.periodoHorarioSelecionado.de)} - {formatarHorarioInt(item.periodoHorarioSelecionado.ate)}
                                </b>{' '}
                                <EditIcon className={classes.containerHorarioEscolhidoIcone} />
                              </div>
                            )
                          }
                        } else {
                          containerItemClasses.push(classes.colaboradorNaoSelecionado)

                          labelContent = 'Escolha o Profissional'
                          imageContent = (
                            <div className={classes.fotoSelecioneColaboradorContainer}>
                              <PersonOutlineIcon className={classes.fotoSelecioneColaboradorIcone} />
                            </div>
                          )
                        }

                        let precoDuracaoContent = null
                        let duracaoContent = (
                          <React.Fragment>
                            <span className={classes.precoDuracaoServicoLabel}>Duração</span> {formatarDuracao(item.variacao.duracao, { descricao: 'minificada' })}
                          </React.Fragment>
                        )
                        if (TipoExibicaoPrecoEnum.OCULTO.id !== item.servico.tipoExibicaoPreco.id) {
                          const precoFormatado = formatarValorMonetario(item.variacao.preco)
                          const complementoPreco = TipoExibicaoPrecoEnum.A_PARTIR.id === item.servico.tipoExibicaoPreco.id ? '+' : ''
                          precoDuracaoContent = (
                            <React.Fragment>
                              {duracaoContent} {' \u2022 '} <span className={classes.precoDuracaoServicoLabel}>Preço</span> {precoFormatado + complementoPreco}
                            </React.Fragment>
                          )
                        } else {
                          precoDuracaoContent = duracaoContent
                        }

                        return (
                          <div key={item.id} className={classNames(containerItemClasses)} style={{ marginTop: index === 0 ? 32 : 0 }}>
                            <div className={classes.containerItemNavegacao}>
                              <Grid container alignItems="center">
                                {index > 0 && (
                                  <Grid item>
                                    <IconButton
                                      className={classes.containerItemNavegacaoHeaderIconButton}
                                      onClick={() => {
                                        let item1 = itens[index]
                                        let item2 = itens[index - 1]
                                        itens[index] = item2
                                        itens[index - 1] = item1
                                        this.forceUpdate()
                                      }}
                                    >
                                      <KeyboardArrowUpIcon />
                                    </IconButton>
                                  </Grid>
                                )}
                                {itens.length > 1 && index !== itens.length - 1 && (
                                  <Grid item>
                                    <IconButton
                                      className={classes.containerItemNavegacaoHeaderIconButton}
                                      onClick={() => {
                                        let item1 = itens[index]
                                        let item2 = itens[index + 1]
                                        itens[index] = item2
                                        itens[index + 1] = item1
                                        this.forceUpdate()
                                      }}
                                    >
                                      <KeyboardArrowDownIcon />
                                    </IconButton>
                                  </Grid>
                                )}

                                <Grid item>
                                  <IconButton
                                    className={classes.containerItemNavegacaoHeaderIconButton}
                                    onClick={() => {
                                      EventsManager.pub('DesmarcarServico', {
                                        id: item.id
                                      })
                                    }}
                                  >
                                    <CloseIcon style={{ fontSize: 18, margin: 2 }} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </div>
                            <Grid container alignItems="center">
                              <Grid item xs style={{ paddingLeft: 2 }}>
                                <Typography variant="h6" className={classes.nomeServico}>
                                  {ServicoService.gerarNomeCompletoServico(item.servico, item.variacao)}
                                </Typography>
                                <Typography variant="body2" className={classes.precoDuracaoServico}>
                                  {precoDuracaoContent}
                                </Typography>
                                {!item.colaborador && (
                                  <Typography variant="body2" className={classes.textoInformativoSelecaoProfissional}>
                                    Selecione um profissional...
                                  </Typography>
                                )}
                                {item.adicionarObservacao && (
                                  <div style={{ position: 'relative' }}>
                                    <InputText
                                      idname={'xobservacoes' + item.id}
                                      marginTop={8}
                                      key={item.id}
                                      InputProps={{
                                        inputProps: {
                                          style: {
                                            fontSize: 14,
                                            color: '#999'
                                          }
                                        }
                                      }}
                                      focusEffectDisabled={true}
                                      shrink={true}
                                      customVariant="outlined-small"
                                      placeholder="Escrever..."
                                      inputRef={item.inputObservacoes}
                                      multiline={true}
                                      rowsMax={8}
                                      rows={1}
                                      maxLength="2000"
                                    />

                                    <IconButton
                                      className={classes.campoObservacoesBotaoFechar}
                                      onClick={() => {
                                        item.adicionarObservacao = false
                                        item.inputObservacoes = React.createRef()
                                        this.forceUpdate()
                                      }}
                                    >
                                      <CloseIcon className={classes.campoObservacoesBotaoFecharIcone} />
                                    </IconButton>
                                  </div>
                                )}
                                {!item.adicionarObservacao && item.colaborador && (
                                  <Typography
                                    variant="body2"
                                    className={classes.adicionarObservacoes}
                                    onClick={() => {
                                      item.adicionarObservacao = true
                                      item.inputObservacoes = React.createRef()
                                      this.forceUpdate()
                                      window.setTimeout(() => {
                                        if (item.inputObservacoes.current) {
                                          item.inputObservacoes.current.focus()
                                        }
                                      }, 300)
                                    }}
                                  >
                                    Adicionar Observações
                                  </Typography>
                                )}
                              </Grid>

                              <Grid
                                item
                                className={classes.colaboradorContainer}
                                onClick={(event) => {
                                  this.setState({
                                    selecaoColaboradorAnchorPosition: {
                                      left: event.clientX,
                                      top: event.clientY
                                    },
                                    selecaoColaboradorItem: item
                                  })
                                }}
                              >
                                <div style={{ textAlign: 'center' }}>
                                  {imageContent}
                                  <Grid container alignItems="center" className={classes.colaboradorNomeContainer} justify="center" wrap={'nowrap'}>
                                    <Grid item>
                                      <Typography variant="body2" className={classes.colaboradorNome}>
                                        <b>{labelContent}</b>
                                      </Typography>
                                    </Grid>
                                    <Grid item className={classes.colaboradorNomeIconeContainer}>
                                      <ArrowDropDownIcon />
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>

                            <div style={{ marginTop: 12, marginLeft: -2 }}>{horariosSelecaoContent}</div>
                          </div>
                        )
                      })}
                      <Menu
                        PaperProps={{ style: { ppaddingTop: 9, ppaddingBottom: 8 } }}
                        anchorReference="anchorPosition"
                        anchorPosition={this.state.selecaoColaboradorAnchorPosition}
                        open={Boolean(this.state.selecaoColaboradorAnchorPosition)}
                        onClose={() => this.closeSelecaoColaboradorMenu()}
                      >
                        {selecaoColaboradorItem &&
                          selecaoColaboradorItem.colaboradores.map((colaborador) => {
                            let imageContent
                            if (colaborador.id === ID_QUALQUER_COLABORADOR) {
                              imageContent = (
                                <div className={classes.semFotoColaboradorContainerMenu}>
                                  <GroupIcon className={classes.semFotoColaboradorIconeMenu} />
                                </div>
                              )
                            } else if (colaborador.foto) {
                              const variacoesFotoColaborador = ImageUtil.extrairVariacoesImagem(colaborador.foto)
                              imageContent = <img src={variacoesFotoColaborador.get(0)} className={classes.fotoColaboradorMenu} alt="Foto" />
                            } else {
                              imageContent = (
                                <div className={classes.semFotoColaboradorContainerMenu}>
                                  <PersonIcon className={classes.semFotoColaboradorIconeMenu} />
                                </div>
                              )
                            }

                            return (
                              <MenuItem
                                key={colaborador.id}
                                onClick={() => {
                                  if (!selecaoColaboradorItem.colaborador || colaborador.id !== selecaoColaboradorItem.colaborador.id) {
                                    selecaoColaboradorItem.colaborador = colaborador
                                    const periodoHorarioSelecionado = selecaoColaboradorItem.periodoHorarioSelecionado
                                    selecaoColaboradorItem.periodoHorarioSelecionado = null
                                    this.atualizarControlePeriodosHorariosSelecionados()

                                    if (periodoHorarioSelecionado) {
                                      const horariosDisponiveis = gerarHorariosDisponiveis(colaborador, negocio, selecaoColaboradorItem, this.state.itens)
                                      if (horariosDisponiveis.indexOf(periodoHorarioSelecionado.de) >= 0) {
                                        selecaoColaboradorItem.periodoHorarioSelecionado = periodoHorarioSelecionado
                                        this.atualizarControlePeriodosHorariosSelecionados()
                                      }
                                    }
                                  }
                                  this.closeSelecaoColaboradorMenu()
                                }}
                              >
                                {imageContent}
                                {formatarNome(colaborador.nome, 2)}
                              </MenuItem>
                            )
                          })}
                      </Menu>
                    </div>

                    <div style={{ textAlign: 'right', marginTop: 8, marginRight: 0, marginBottom: 8 }}>
                      <Button
                        style={{ textTransform: 'none', fontSize: 16, marginRight: 8 }}
                        color="primary"
                        onClick={() => {
                          this.props.handleCloseDialog()
                        }}
                      >
                        Fechar
                      </Button>
                      <Button
                        disabled={!botaoContinuarEnabled}
                        style={{ textTransform: 'none', fontSize: 16, marginRight: -4 }}
                        color="primary"
                        variant="contained"
                        onClick={this.submeterItens}
                      >
                        Continuar
                      </Button>
                    </div>
                  </React.Fragment>
                )
              }}
            </ContentWithPreload>
          </div>

          {step === 2 && !dialog && (
            <StepConfirmacao
              ajaxing={ajaxing}
              itensSubmetidos={itensSubmetidos}
              dataSelecionada={dataSelecionada}
              agendarAtendimentos={this.agendarAtendimentos}
              onBack={() => {
                this.setState({
                  step: 1
                })
              }}
            />
          )}

          {step === 3 && !dialog && <StepAgendamentoConcluido negocio={this.props.negocio} handleCloseDialog={this.props.handleCloseDialog} />}

          {dialog === 1 && (
            <StepListagemHorariosLivres
              classes={this.props.classes}
              negocio={this.props.negocio}
              idItemBuscaHorariosLivres={idItemBuscaHorariosLivres}
              colaboradorBuscaHorariosLivres={colaboradorBuscaHorariosLivres}
              servicoBuscaHorariosLivres={servicoBuscaHorariosLivres}
              variacaoBuscaHorariosLivres={variacaoBuscaHorariosLivres}
              fecharDialogBuscarProximosHorarios={this.fecharDialogBuscarProximosHorarios}
            />
          )}
        </div>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AgendarAtendimentosDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AgendarAtendimentosDialog)
