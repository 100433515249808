import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import NativeApp from 'support/components/nativeapp/NativeApp'
import SessaoPainelErp from 'support/components/sessao/SessaoPainelErp'
import UrlUtil from 'support/util/UrlUtil'

const SessaoUsuario = {
  KEY_SESSAO_USUARIO: 'sessaoUsuario',
  KEY_ULTIMA_REQUISICAO: 'ultimaRequisicao',
  KEY_URL_CONTINUACAO: 'urlContinuacaoAposLogin',
  TEMPO_SESSAO: 86400000 * 365,

  getUsuario: function () {
    SessaoUsuario.utilizarSessao()
    return SessaoUsuario.getUsuarioSemUtilizacaoSessao()
  },

  getUsuarioSemUtilizacaoSessao: function () {
    const stringUsuario = localStorage.getItem(SessaoUsuario.KEY_SESSAO_USUARIO)
    if (stringUsuario !== null && stringUsuario !== '') {
      return JSON.parse(stringUsuario)
    } else {
      return null
    }
  },

  getTokenUsuario: function () {
    SessaoUsuario.utilizarSessao()
    const usuario = SessaoUsuario.getUsuario()
    if (usuario !== null && usuario !== '') {
      return usuario.token
    }
    return null
  },

  isUsuarioLogado: function () {
    const usuario = SessaoUsuario.getUsuario()
    if (usuario === null || usuario === '') {
      return false
    } else {
      return true
    }
  },

  utilizarSessao: function () {
    if (localStorage.getItem(SessaoUsuario.KEY_SESSAO_USUARIO) === null || SessaoUsuario.getTempoParaExpiracao() <= 0) {
      SessaoUsuario.limparSessao()
    } else {
      SessaoUsuario.renovarTempoSessao()
    }
  },

  getTempoParaExpiracao: function () {
    if (localStorage.getItem(SessaoUsuario.KEY_ULTIMA_REQUISICAO) === null) {
      return 0
    } else {
      return SessaoUsuario.TEMPO_SESSAO - (new Date().getTime() - localStorage.getItem(SessaoUsuario.KEY_ULTIMA_REQUISICAO))
    }
  },

  limparSessao: function () {
    this.usuarioLogado = null
    localStorage.removeItem(SessaoUsuario.KEY_SESSAO_USUARIO, null)
    localStorage.removeItem(SessaoUsuario.KEY_ULTIMA_REQUISICAO, null)
    SessaoUsuario.registrarTokenUsuarioAppNativo()
  },

  registrarUsuario: function (usuario) {
    localStorage.setItem(SessaoUsuario.KEY_SESSAO_USUARIO, JSON.stringify(usuario))
    SessaoUsuario.renovarTempoSessao()
    SessaoUsuario.registrarTokenUsuarioAppNativo()
  },

  registrarTokenUsuarioAppNativo: function () {
    if (DeviceIdentifier.isNativeApp()) {
      let tokenUsuario = ''
      const usuario = SessaoUsuario.getUsuarioSemUtilizacaoSessao()
      if (usuario && usuario.token) {
        tokenUsuario = usuario.token
      }

      let idNegocio = null
      const dadosSessaoUrl = SessaoPainelErp.getDadosNegocioSessaoFromUrl()
      if (dadosSessaoUrl !== null && dadosSessaoUrl.idNegocio) {
        idNegocio = dadosSessaoUrl.idNegocio
      }

      NativeApp.executeNativeMethod({
        method: 'registrarTokenUsuario',
        tokenUsuario: tokenUsuario,
        idNegocio: idNegocio
      })
    }
  },

  renovarTempoSessao: function () {
    localStorage.setItem(SessaoUsuario.KEY_ULTIMA_REQUISICAO, new Date().getTime())
  },

  registrarUrlContinuacaoAposLogin: function (param) {
    const url = UrlUtil.getUrlQueryStringParsed()[param ? param : 'continuacao']
    if (url) {
      localStorage.setItem(SessaoUsuario.KEY_URL_CONTINUACAO, url)
    }
  },

  getUrlContinuacaoAposLogin: function () {
    const url = localStorage.getItem(SessaoUsuario.KEY_URL_CONTINUACAO)
    if (url) {
      return url
    } else {
      return null
    }
  }
}

export default SessaoUsuario
