import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { calcularVariacao, IndicadorVariacao } from 'support/domain/admin/relatorio/RelatorioService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

const styles = (theme) => ({
  colunaPeriodo: {
    maxWidth: 100,
    minWidth: 100
  }
})

class IndicadoresPlanosSalao99DialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.periodo = converterDataIntParaMoment(item.periodo).format('MMM/YYYY')

    content.quantidadeMensalIndividual = (
      <span>
        <b>{item.quantidadeMensalIndividual}</b>
      </span>
    )
    if (item.quantidadeMensalIndividualDifQtd) {
      let sinal = ''
      if (item.quantidadeMensalIndividualDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeMensalIndividual = (
        <span>
          {content.quantidadeMensalIndividual}{' '}
          <small>
            ({sinal + item.quantidadeMensalIndividualDifQtd}) {item.quantidadeMensalIndividualDifPer}
            <IndicadorVariacao valor={item.quantidadeMensalIndividualDifQtd} />
          </small>
        </span>
      )
    }

    content.quantidadeMensalEmpresa = (
      <span>
        <b>{item.quantidadeMensalEmpresa}</b>
      </span>
    )
    if (item.quantidadeMensalEmpresaDifQtd) {
      let sinal = ''
      if (item.quantidadeMensalEmpresaDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeMensalEmpresa = (
        <span>
          {content.quantidadeMensalEmpresa}{' '}
          <small>
            ({sinal + item.quantidadeMensalEmpresaDifQtd}) {item.quantidadeMensalEmpresaDifPer}
            <IndicadorVariacao valor={item.quantidadeMensalEmpresaDifQtd} />
          </small>
        </span>
      )
    }

    if (size !== 'small') {
      content.descricaoQuantidadeMensal = (
        <Typography variant="body2">
          Individual: {content.quantidadeMensalIndividual}
          <br />
          Empresa: {content.quantidadeMensalEmpresa}
        </Typography>
      )
    }

    content.quantidadeMensalTotal = (
      <span>
        <b>{item.quantidadeMensalIndividual + item.quantidadeMensalEmpresa}</b>
      </span>
    )
    if (item.quantidadeMensalTotalDifQtd) {
      let sinal = ''
      if (item.quantidadeMensalTotalDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeMensalTotal = (
        <span>
          {content.quantidadeMensalTotal}{' '}
          <small>
            ({sinal + item.quantidadeMensalTotalDifQtd}) {item.quantidadeMensalTotalDifPer}
            <IndicadorVariacao valor={item.quantidadeMensalTotalDifQtd} />
          </small>
        </span>
      )
    }

    content.quantidadeTrimestralIndividual = (
      <span>
        <b>{item.quantidadeTrimestralIndividual}</b>
      </span>
    )
    if (item.quantidadeTrimestralIndividualDifQtd) {
      let sinal = ''
      if (item.quantidadeTrimestralIndividualDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeTrimestralIndividual = (
        <span>
          {content.quantidadeTrimestralIndividual}{' '}
          <small>
            ({sinal + item.quantidadeTrimestralIndividualDifQtd}) {item.quantidadeTrimestralIndividualDifPer}
            <IndicadorVariacao valor={item.quantidadeTrimestralIndividualDifQtd} />
          </small>
        </span>
      )
    }

    content.quantidadeTrimestralEmpresa = (
      <span>
        <b>{item.quantidadeTrimestralEmpresa}</b>
      </span>
    )
    if (item.quantidadeTrimestralEmpresaDifQtd) {
      let sinal = ''
      if (item.quantidadeTrimestralEmpresaDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeTrimestralEmpresa = (
        <span>
          {content.quantidadeTrimestralEmpresa}{' '}
          <small>
            ({sinal + item.quantidadeTrimestralEmpresaDifQtd}) {item.quantidadeTrimestralEmpresaDifPer}
            <IndicadorVariacao valor={item.quantidadeTrimestralEmpresaDifQtd} />
          </small>
        </span>
      )
    }

    if (size !== 'small') {
      content.descricaoQuantidadeTrimestral = (
        <Typography variant="body2">
          Individual: {content.quantidadeTrimestralIndividual}
          <br />
          Empresa: {content.quantidadeTrimestralEmpresa}
        </Typography>
      )
    }

    content.quantidadeTrimestralTotal = (
      <span>
        <b>{item.quantidadeTrimestralIndividual + item.quantidadeTrimestralEmpresa}</b>
      </span>
    )
    if (item.quantidadeTrimestralTotalDifQtd) {
      let sinal = ''
      if (item.quantidadeTrimestralTotalDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeTrimestralTotal = (
        <span>
          {content.quantidadeTrimestralTotal}{' '}
          <small>
            ({sinal + item.quantidadeTrimestralTotalDifQtd}) {item.quantidadeTrimestralTotalDifPer}
            <IndicadorVariacao valor={item.quantidadeTrimestralTotalDifQtd} />
          </small>
        </span>
      )
    }

    content.quantidadeSemestralIndividual = (
      <span>
        <b>{item.quantidadeSemestralIndividual}</b>
      </span>
    )
    if (item.quantidadeSemestralIndividualDifQtd) {
      let sinal = ''
      if (item.quantidadeSemestralIndividualDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeSemestralIndividual = (
        <span>
          {content.quantidadeSemestralIndividual}{' '}
          <small>
            ({sinal + item.quantidadeSemestralIndividualDifQtd}) {item.quantidadeSemestralIndividualDifPer}
            <IndicadorVariacao valor={item.quantidadeSemestralIndividualDifQtd} />
          </small>
        </span>
      )
    }

    content.quantidadeSemestralEmpresa = (
      <span>
        <b>{item.quantidadeSemestralEmpresa}</b>
      </span>
    )
    if (item.quantidadeSemestralEmpresaDifQtd) {
      let sinal = ''
      if (item.quantidadeSemestralEmpresaDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeSemestralEmpresa = (
        <span>
          {content.quantidadeSemestralEmpresa}{' '}
          <small>
            ({sinal + item.quantidadeSemestralEmpresaDifQtd}) {item.quantidadeSemestralEmpresaDifPer}
            <IndicadorVariacao valor={item.quantidadeSemestralEmpresaDifQtd} />
          </small>
        </span>
      )
    }

    if (size !== 'small') {
      content.descricaoQuantidadeSemestral = (
        <Typography variant="body2">
          Individual: {content.quantidadeSemestralIndividual}
          <br />
          Empresa: {content.quantidadeSemestralEmpresa}
        </Typography>
      )
    }

    content.quantidadeSemestralTotal = (
      <span>
        <b>{item.quantidadeSemestralIndividual + item.quantidadeSemestralEmpresa}</b>
      </span>
    )
    if (item.quantidadeSemestralTotalDifQtd) {
      let sinal = ''
      if (item.quantidadeSemestralTotalDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeSemestralTotal = (
        <span>
          {content.quantidadeSemestralTotal}{' '}
          <small>
            ({sinal + item.quantidadeSemestralTotalDifQtd}) {item.quantidadeSemestralTotalDifPer}
            <IndicadorVariacao valor={item.quantidadeSemestralTotalDifQtd} />
          </small>
        </span>
      )
    }

    content.quantidadeAnualIndividual = (
      <span>
        <b>{item.quantidadeAnualIndividual}</b>
      </span>
    )
    if (item.quantidadeAnualIndividualDifQtd) {
      let sinal = ''
      if (item.quantidadeAnualIndividualDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeAnualIndividual = (
        <span>
          {content.quantidadeAnualIndividual}{' '}
          <small>
            ({sinal + item.quantidadeAnualIndividualDifQtd}) {item.quantidadeAnualIndividualDifPer}
            <IndicadorVariacao valor={item.quantidadeAnualIndividualDifQtd} />
          </small>
        </span>
      )
    }

    content.quantidadeAnualEmpresa = (
      <span>
        <b>{item.quantidadeAnualEmpresa}</b>
      </span>
    )

    if (item.quantidadeAnualEmpresaDifQtd) {
      let sinal = ''
      if (item.quantidadeAnualEmpresaDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeAnualEmpresa = (
        <span>
          {content.quantidadeAnualEmpresa}{' '}
          <small>
            ({sinal + item.quantidadeAnualEmpresaDifQtd}) {item.quantidadeAnualEmpresaDifPer}
            <IndicadorVariacao valor={item.quantidadeAnualEmpresaDifQtd} />
          </small>
        </span>
      )
    }

    if (size !== 'small') {
      content.descricaoQuantidadeAnual = (
        <Typography variant="body2">
          Individual: {content.quantidadeAnualIndividual}
          <br />
          Empresa: {content.quantidadeAnualEmpresa}
        </Typography>
      )
    }

    content.quantidadeAnualTotal = (
      <span>
        <b>{item.quantidadeAnualIndividual + item.quantidadeAnualEmpresa}</b>
      </span>
    )
    if (item.quantidadeAnualTotalDifQtd) {
      let sinal = ''
      if (item.quantidadeAnualTotalDifQtd > 0) {
        sinal = '+'
      }
      content.quantidadeAnualTotal = (
        <span>
          {content.quantidadeAnualTotal}{' '}
          <small>
            ({sinal + item.quantidadeAnualTotalDifQtd}) {item.quantidadeAnualTotalDifPer}
            <IndicadorVariacao valor={item.quantidadeAnualTotalDifQtd} />
          </small>
        </span>
      )
    }

    return content
  }

  getLoaderKey = () => {
    const { dataInicial, dataFinal } = this.state

    let key = 'loader'

    if (dataInicial) {
      key += '-i' + dataInicial
    }
    if (dataFinal) {
      key += '-f' + dataFinal
    }

    return key
  }

  prepararListas = (items) => {
    for (let i = 0; i < items.length - 1; i++) {
      const item = items[i]
      const itemAnterior = items[i + 1]

      item.quantidadeMensalIndividualDifQtd = item.quantidadeMensalIndividual - itemAnterior.quantidadeMensalIndividual
      item.quantidadeMensalIndividualDifPer = calcularVariacao(item.quantidadeMensalIndividual, itemAnterior.quantidadeMensalIndividual)
      item.quantidadeMensalEmpresaDifQtd = item.quantidadeMensalEmpresa - itemAnterior.quantidadeMensalEmpresa
      item.quantidadeMensalEmpresaDifPer = calcularVariacao(item.quantidadeMensalEmpresa, itemAnterior.quantidadeMensalEmpresa)
      item.quantidadeMensalTotalDifQtd =
        item.quantidadeMensalEmpresa + item.quantidadeMensalIndividual - (itemAnterior.quantidadeMensalEmpresa + itemAnterior.quantidadeMensalIndividual)
      item.quantidadeMensalTotalDifPer = calcularVariacao(
        item.quantidadeMensalEmpresa + item.quantidadeMensalIndividual,
        itemAnterior.quantidadeMensalEmpresa + itemAnterior.quantidadeMensalIndividual
      )

      item.quantidadeTrimestralIndividualDifQtd = item.quantidadeTrimestralIndividual - itemAnterior.quantidadeTrimestralIndividual
      item.quantidadeTrimestralIndividualDifPer = calcularVariacao(item.quantidadeTrimestralIndividual, itemAnterior.quantidadeTrimestralIndividual)
      item.quantidadeTrimestralEmpresaDifQtd = item.quantidadeTrimestralEmpresa - itemAnterior.quantidadeTrimestralEmpresa
      item.quantidadeTrimestralEmpresaDifPer = calcularVariacao(item.quantidadeTrimestralEmpresa, itemAnterior.quantidadeTrimestralEmpresa)
      item.quantidadeTrimestralTotalDifQtd =
        item.quantidadeTrimestralEmpresa + item.quantidadeTrimestralIndividual - (itemAnterior.quantidadeTrimestralEmpresa + itemAnterior.quantidadeTrimestralIndividual)
      item.quantidadeTrimestralTotalDifPer = calcularVariacao(
        item.quantidadeTrimestralEmpresa + item.quantidadeTrimestralIndividual,
        itemAnterior.quantidadeTrimestralEmpresa + itemAnterior.quantidadeTrimestralIndividual
      )

      item.quantidadeSemestralIndividualDifQtd = item.quantidadeSemestralIndividual - itemAnterior.quantidadeSemestralIndividual
      item.quantidadeSemestralIndividualDifPer = calcularVariacao(item.quantidadeSemestralIndividual, itemAnterior.quantidadeSemestralIndividual)
      item.quantidadeSemestralEmpresaDifQtd = item.quantidadeSemestralEmpresa - itemAnterior.quantidadeSemestralEmpresa
      item.quantidadeSemestralEmpresaDifPer = calcularVariacao(item.quantidadeSemestralEmpresa, itemAnterior.quantidadeSemestralEmpresa)
      item.quantidadeSemestralTotalDifQtd =
        item.quantidadeSemestralEmpresa + item.quantidadeSemestralIndividual - (itemAnterior.quantidadeSemestralEmpresa + itemAnterior.quantidadeSemestralIndividual)
      item.quantidadeSemestralTotalDifPer = calcularVariacao(
        item.quantidadeSemestralEmpresa + item.quantidadeSemestralIndividual,
        itemAnterior.quantidadeSemestralEmpresa + itemAnterior.quantidadeSemestralIndividual
      )

      item.quantidadeAnualIndividualDifQtd = item.quantidadeAnualIndividual - itemAnterior.quantidadeAnualIndividual
      item.quantidadeAnualIndividualDifPer = calcularVariacao(item.quantidadeAnualIndividual, itemAnterior.quantidadeAnualIndividual)
      item.quantidadeAnualEmpresaDifQtd = item.quantidadeAnualEmpresa - itemAnterior.quantidadeAnualEmpresa
      item.quantidadeAnualEmpresaDifPer = calcularVariacao(item.quantidadeAnualEmpresa, itemAnterior.quantidadeAnualEmpresa)
      item.quantidadeAnualTotalDifQtd =
        item.quantidadeAnualEmpresa + item.quantidadeAnualIndividual - (itemAnterior.quantidadeAnualEmpresa + itemAnterior.quantidadeAnualIndividual)
      item.quantidadeAnualTotalDifPer = calcularVariacao(
        item.quantidadeAnualEmpresa + item.quantidadeAnualIndividual,
        itemAnterior.quantidadeAnualEmpresa + itemAnterior.quantidadeAnualIndividual
      )
    }

    return items
  }

  render() {
    const dialogProps = extractDialogProps(this.props)

    return (
      <DialogPage
        {...dialogProps}
        title="Planos Pagos"
        align="center"
        toolbarActions={{
          actions: [
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.loaderFunctionsMap.load()
              }
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="admin"
              endpoint="admin/relatorios.buscarIndicadoresPlanosSalao99"
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const state = { lista: this.prepararListas(data.items) }
                  this.setState(state)
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Período', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Mensal', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Total Mensal', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Trimestral', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Total Trimestral', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Semestral', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Total Semestral', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Anual', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Total Anual', horizontalPadding: 'small', props: { xs: true } }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [
                      content.periodo,
                      content.descricaoQuantidadeMensal,
                      content.quantidadeMensalTotal,
                      content.descricaoQuantidadeTrimestral,
                      content.quantidadeTrimestralTotal,
                      content.descricaoQuantidadeSemestral,
                      content.quantidadeSemestralTotal,
                      content.descricaoQuantidadeAnual,
                      content.quantidadeAnualTotal
                    ]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 280,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body2" noWrap={true}>
                          {content.periodo}
                        </Typography>
                        <Typography variant="body2">Mensal (I): {content.quantidadeMensalIndividual}</Typography>
                        <Typography variant="body2">Mensal (E): {content.quantidadeMensalEmpresa}</Typography>
                        <Typography variant="body2">Mensal Total: {content.quantidadeMensalTotal}</Typography>
                        <Typography variant="body2">Trimestral (I): {content.quantidadeTrimestralIndividual}</Typography>
                        <Typography variant="body2">Trimestral (E): {content.quantidadeTrimestralEmpresa}</Typography>
                        <Typography variant="body2">Trimestral Total: {content.quantidadeTrimestralTotal}</Typography>
                        <Typography variant="body2">Semestral (I): {content.quantidadeSemestralIndividual}</Typography>
                        <Typography variant="body2">Semestral (E): {content.quantidadeSemestralEmpresa}</Typography>
                        <Typography variant="body2">Semestral Total: {content.quantidadeSemestralTotal}</Typography>
                        <Typography variant="body2">Anual (I): {content.quantidadeAnualIndividual}</Typography>
                        <Typography variant="body2">Anual (E): {content.quantidadeAnualEmpresa}</Typography>
                        <Typography variant="body2">Anual Total: {content.quantidadeAnualTotal}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum registro encontrado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

IndicadoresPlanosSalao99DialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IndicadoresPlanosSalao99DialogPage)
