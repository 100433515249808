import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import InputCaixa from 'pages/erp/painel/caixa/input/InputCaixa'
import CategoriaInputDialogSelect from 'pages/erp/painel/despesas/input/CategoriaInputDialogSelect'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import Repeticao from 'support/components/repeticao/Repeticao'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoDespesaEnum from 'support/domain/despesa/TipoDespesaEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class DespesaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idDespesa ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      data: moment(),
      despesa: null,
      paga: false
    }

    this.inputNome = React.createRef()
    this.inputValor = React.createRef()
    this.inputObservacoes = React.createRef()
    this.dataFunctionsMap = {}
    this.inputPagaFunctionsMap = {}
    this.inputCaixaFunctionsMap = {}
    this.repeticaoFunctionsMap = {}
    this.autocompleteCategoria = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/despesas.buscarDadosParaPersistencia',
      params: {
        idDespesa: this.props.idDespesa
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const newState = {}
        if (response.data.despesa) {
          const despesa = response.data.despesa
          newState.despesa = despesa
          newState.data = converterDataIntParaMoment(despesa.data)
          newState.paga = despesa.paga
        }
        newState.caixas = response.data.caixas
        this.setState(newState)
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    if (!verificarAutorizacao([PA.PODE_ALTERAR_DESPESA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    this.repeticaoFunctionsMap.exibirSeNecessarioOpcoesPersistencia((persistOptions) => {
      const dados = this.getDadosDespesa()
      dados.repeticao = { ...dados.repeticao, ...persistOptions }

      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      postAPI({
        url: 'erp/despesas.persistir',
        data: dados,
        requerAutorizacao: true,
        onSuccess: (response) => {
          EventsManager.pub('ManutencaoDespesa')
          this.props.handleCloseDialog()
        },
        onError: (response) => {
          this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
          focusFirstElementWithError('formDespesa')
        }
      })
    })
  }

  getDadosDespesa = () => {
    const dados = {}
    if (this.props.idDespesa) {
      dados.id = this.props.idDespesa
    }

    dados.nome = this.inputNome.current.value
    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.valor = FormUtil.convertStringToNumber(this.inputValor.current.value)
    dados.tipo = { id: TipoDespesaEnum.GERAL.id }
    dados.paga = this.state.paga
    dados.caixa = this.inputCaixaFunctionsMap.getCaixa()
    dados.observacoes = this.inputObservacoes.current.value
    dados.repeticao = this.repeticaoFunctionsMap.getValues()
    dados.categoria = {
      id: this.autocompleteCategoria.getValue()
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, ajaxing, caixas, data, paga } = this.state
    const { nome, categoria, valor, observacoes, repeticao, caixa } = this.state.despesa ? this.state.despesa : {}

    let utilizarCaixaDefaultValue = true
    if (this.state.despesa && !caixa && paga) {
      utilizarCaixaDefaultValue = false
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formDespesa" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                id="xnome"
                defaultValue={nome}
                customVariant="outlined-small"
                shrink={false}
                autoFocus={true}
                disabled={ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome"
                inputProps={{
                  maxLength: 255
                }}
                inputRef={this.inputNome}
              />

              <CategoriaInputDialogSelect
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('categoria')}
                placeholder="Selecione a Categoria"
                defaultValue={categoria}
                functionsMap={this.autocompleteCategoria}
              />

              <UncontrolledCheckbox
                formControlProps={{
                  style: { marginTop: 20 },
                  label: <Typography variant="body2">Despesa paga</Typography>
                }}
                color="primary"
                disabled={ajaxing}
                defaultChecked={paga}
                onChange={(checked) => {
                  this.setState({ paga: checked })
                }}
              />

              <InputHorizontalContainer
                style={{ marginTop: 20 }}
                inputTexts={[
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <InputText
                        idname="xvalor"
                        customVariant="outlined-small"
                        shrink={false}
                        disabled={ajaxing}
                        label="Valor para pagamento (R$)"
                        defaultValue={valor}
                        errorMessage={errorsMap('valor')}
                        inputComponent={InputMoneyFormat}
                        inputProps={{
                          minValue: 0.01,
                          maxValue: 999999
                        }}
                        inputRef={this.inputValor}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <InputData
                        disabled={ajaxing}
                        customVariant="outlined-small"
                        shrink={false}
                        functionsMap={this.dataFunctionsMap}
                        keyboard={true}
                        id="xdata"
                        name="xdata"
                        errorMessage={errorsMap('data')}
                        onChange={(data) => {
                          let dataAsMoment = null
                          if (data) {
                            dataAsMoment = moment(data)
                          }
                          this.setState({ data: dataAsMoment })
                          this.inputCaixaFunctionsMap.setData(dataAsMoment)
                        }}
                        style={{ marginTop: 0 }}
                        defaultValue={data}
                        label={'Data para pagamento'}
                      />
                    )
                  }
                ]}
              />

              <InputText
                id="xobservacoes"
                name="xobservacoes"
                customVariant="outlined-small"
                shrink={false}
                disabled={ajaxing}
                multiline={true}
                marginTop={true}
                errorMessage={errorsMap('observacoes')}
                defaultValue={observacoes}
                label="Observações"
                inputProps={{
                  maxLength: 2000
                }}
                inputRef={this.inputObservacoes}
              />

              <InputCaixa
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                disponivel={paga}
                data={data}
                disabled={ajaxing}
                colaboradorPreferencial={getSessaoPainel().colaborador}
                utilizarDefaultValue={utilizarCaixaDefaultValue}
                caixaDefaultValue={caixa}
                caixas={caixas}
                errorsMap={errorsMap}
                descricaoData="data da despesa"
                labelUtilizacaoCaixa="Pago com dinheiro do caixa. Esta opção faz com que essa despesa entre no fluxo de caixa como uma retirada de dinheiro do caixa."
                functionsMap={this.inputCaixaFunctionsMap}
              />

              <Repeticao
                showLabelIcons={false}
                title="Repetir Despesa"
                prefixoEntidade="da"
                nomeEntidade="Despesa"
                tituloAlteracaoIndividual="Alterar apenas esta despesa"
                descricaoAlteracaoIndividual="As alterações realizadas nesta despesa não serão replicadas para as próximas despesas da repetição."
                tituloAlteracaoLote="Replicar alterações para as próximas despesas"
                descricaoAlteracaoLote="As alterações desta despesa serão aplicadas também para todas as próximas despesas da replicação."
                defaultValue={repeticao}
                customVariant={'outlined-small'}
                dataInicial={data}
                disabled={ajaxing}
                errorsMap={errorsMap}
                functionsMap={this.repeticaoFunctionsMap}
                hasEntityChange={() => {
                  const { despesa } = this.state
                  const novaDespesa = this.getDadosDespesa()

                  if (
                    this.props.idDespesa &&
                    despesa.nome === novaDespesa.nome &&
                    despesa.data === novaDespesa.data &&
                    parseFloat(despesa.valor) === parseFloat(novaDespesa.valor) &&
                    despesa.observacoes === novaDespesa.observacoes
                  ) {
                    return false
                  } else {
                    return true
                  }
                }}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

DespesaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DespesaDialogPage)
