function createFromRange(start, end) {
  var a = []
  for (var i = start; i <= end; i++) {
    a.push(i)
  }
  return a
}

function agruparPorPropriedade(formaPagamento, propriedade) {
  var resultado = formaPagamento.reduce(function (arrayFinal, object) {
    const key = object[propriedade]
    if (key && !arrayFinal[key]) {
      arrayFinal[key] = []
    }
    if (key) {
      arrayFinal[key].push(object)
    }
    return arrayFinal
  }, {})
  return resultado
}

function removerElementosDuplicados(arr, propriedade) {
  const unique = arr
    .map((e) => e[propriedade])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e])
  return unique
}

function cloneWithoutReferences(array) {
  if (array) {
    return JSON.parse(JSON.stringify(array))
  }
  return array
}

const functionsObject = {
  createFromRange: createFromRange,
  agruparPorPropriedade: agruparPorPropriedade,
  removerElementosDuplicados: removerElementosDuplicados,
  cloneWithoutReferences: cloneWithoutReferences
}

export default functionsObject
