import { ROUTE_ENTERPRISE_PANEL } from 'pages/RouteMap'

const SessaoPainelErp = {
  gerarDadosNegocioSessaoUrl: function (colaborador, negocio) {
    return 'c' + colaborador.id + 'n' + negocio.id
  },

  getDadosNegocioSessaoFromUrl: function () {
    let pathnameParts = window.location.pathname.split(ROUTE_ENTERPRISE_PANEL + '/')
    if (pathnameParts.length > 1) {
      pathnameParts = pathnameParts[1].split('/')
      if (pathnameParts.length > 1) {
        var sessaoNegocio = pathnameParts[0].match(/c[0-9]{0,15}n[0-9]{0,15}/g)
        if (sessaoNegocio && sessaoNegocio.length === 1) {
          sessaoNegocio = sessaoNegocio[0].replace('c', '')
          sessaoNegocio = sessaoNegocio.split('n')
          return {
            idColaborador: sessaoNegocio[0],
            idNegocio: sessaoNegocio[1]
          }
        }
      }
    }
    return null
  }
}

export default SessaoPainelErp
