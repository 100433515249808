import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import FloatContent from 'support/components/page/FloatContent'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class RemuneracoesTableFooter extends Component {
  render() {
    const { lista } = this.props

    if (!lista || lista.length === 0) {
      return []
    }

    let quantidade = 0
    let valorTotalServicosSemDesconto = 0
    let valorTotalServicosComDesconto = 0
    let valorTotalProdutosSemDesconto = 0
    let valorTotalProdutosComDesconto = 0
    let valorTotalRemuneracoes = 0
    let valorTotalPendentePagamento = 0
    let valorTotalPago = 0
    let valorPendentePagamentoDescontandoVales = 0
    let valorTotalComissoes = 0
    let valorTotalGorjetas = 0
    let valorTotalSalario = 0
    let valorTotalBonificacoes = 0

    let mapFormasPagamentos = new Map()

    if (lista) {
      for (let item of lista) {
        if (!item.cancelada && !item.excluido) {
          if (item.comissaoVenda && item.comissaoVenda) {
            if (item.comissaoVenda.venda.tipo.id === TipoVendaEnum.ATENDIMENTO.id) {
              valorTotalServicosSemDesconto += item.comissaoVenda.valorVenda
              valorTotalServicosComDesconto += item.comissaoVenda.venda.valorTotal
            } else if (item.comissaoVenda.venda.tipo.id === TipoVendaEnum.VENDA_PRODUTO.id) {
              valorTotalProdutosSemDesconto += item.comissaoVenda.valorVenda
              valorTotalProdutosComDesconto += item.comissaoVenda.venda.valorTotal
            }
          }
          valorTotalRemuneracoes += item.valorRemuneracaoCalculadoEfetivo
          valorTotalPendentePagamento += item.valorPendentePagamento

          if (item.formasPagamento && item.formasPagamento.length > 0) {
            for (let formaPagamento of item.formasPagamento) {
              let valor = mapFormasPagamentos.get(formaPagamento.formaPagamento.tipo.id)
              if (!valor) {
                valor = 0
              }
              mapFormasPagamentos.set(formaPagamento.formaPagamento.tipo.id, valor + formaPagamento.valor)
            }
          }

          if (item.tipo.id === TipoRemuneracaoEnum.COMISSAO.id) {
            valorTotalComissoes += item.valorRemuneracaoCalculadoEfetivo
          } else if (item.tipo.id === TipoRemuneracaoEnum.GORJETA.id) {
            valorTotalGorjetas += item.valorRemuneracaoCalculadoEfetivo
          } else if (item.tipo.id === TipoRemuneracaoEnum.SALARIO.id) {
            valorTotalSalario += item.valorRemuneracaoCalculadoEfetivo
          } else if (item.tipo.id === TipoRemuneracaoEnum.BONIFICACAO.id) {
            valorTotalBonificacoes += item.valorRemuneracaoCalculadoEfetivo
          }
        }

        quantidade++
        valorTotalPago += item.valorPago
      }
    }

    mapFormasPagamentos = new Map([...mapFormasPagamentos.entries()].sort((a, b) => b[1] - a[1]))

    const formasPagamentoContent = []
    for (var [idTipo, valor] of mapFormasPagamentos) {
      if (valor > 0) {
        formasPagamentoContent.push(
          <Typography variant="body2" key={idTipo}>
            {FormaPagamentoTipoEnum.getById(idTipo).descricao}: {formatarValorMonetario(valor)}
          </Typography>
        )
      }
    }

    valorPendentePagamentoDescontandoVales = valorTotalPendentePagamento - this.props.valorValesDisponiveis
    if (valorPendentePagamentoDescontandoVales < 0) {
      valorPendentePagamentoDescontandoVales = 0
    }

    return (
      <React.Fragment>
        <br />
        <FloatContent container="paper" context="DialogPage" type="tableSummary">
          <Typography variant="body2">
            <b>Quantidade: {quantidade}</b>
          </Typography>
          {verificarAutorizacao([PA.PODE_ACESSAR_PRECO_VENDA_REMUNERACAO]) && (
            <React.Fragment>
              <Typography variant="body2">Serviços Sem Desconto: {formatarValorMonetario(valorTotalServicosSemDesconto)}</Typography>
              <Typography variant="body2">Serviços Com Desconto: {formatarValorMonetario(valorTotalServicosComDesconto)}</Typography>
              <Typography variant="body2">Produtos Sem Desconto: {formatarValorMonetario(valorTotalProdutosSemDesconto)}</Typography>
              <Typography variant="body2">Produtos Com Desconto: {formatarValorMonetario(valorTotalProdutosComDesconto)}</Typography>
            </React.Fragment>
          )}
          <br />
          <Divider />
          <br />
          {valorTotalComissoes > 0 && <Typography variant="body2">Total Comissões: {formatarValorMonetario(valorTotalComissoes)}</Typography>}
          {valorTotalGorjetas > 0 && <Typography variant="body2">Total Gorjetas: {formatarValorMonetario(valorTotalGorjetas)}</Typography>}
          {valorTotalSalario > 0 && <Typography variant="body2">Total Salário: {formatarValorMonetario(valorTotalSalario)}</Typography>}
          {valorTotalBonificacoes > 0 && <Typography variant="body2">Total Bonificações: {formatarValorMonetario(valorTotalBonificacoes)}</Typography>}
          <br />
          <Divider />
          <br />
          <Typography variant="body2">
            <b>Total Remunerações: {formatarValorMonetario(valorTotalRemuneracoes)}</b>
          </Typography>
          {formasPagamentoContent.length > 0 && (
            <React.Fragment>
              <Typography variant="body2">Por Forma de Pagamento:</Typography>
              {formasPagamentoContent}
            </React.Fragment>
          )}
          <br />
          <Divider />
          <br />
          <Typography variant="body2">Total Já Pago: {formatarValorMonetario(valorTotalPago)}</Typography>
          <br />
          <Divider />
          {this.props.valorValesDisponiveis > 0 && (
            <React.Fragment>
              <br />
              <Typography variant="body2">Vales Pendentes: {formatarValorMonetario(this.props.valorValesDisponiveis)}</Typography>
              <br />
              <Divider />
            </React.Fragment>
          )}
          <br />
          {this.props.valorValesDisponiveis > 0 && (
            <React.Fragment>
              <Typography variant="body2">
                <b>Pendente para Pagamento:</b>
              </Typography>
              <Typography variant="body2">Valor Integral: {formatarValorMonetario(valorTotalPendentePagamento)}</Typography>
              <Typography variant="body2">Descontando Vales: {formatarValorMonetario(valorPendentePagamentoDescontandoVales)}</Typography>
            </React.Fragment>
          )}
          {!this.props.valorValesDisponiveis && (
            <Typography variant="body2">
              <b>Pendente Pagamento: {formatarValorMonetario(valorTotalPendentePagamento)}</b>
            </Typography>
          )}
        </FloatContent>
      </React.Fragment>
    )
  }
}

RemuneracoesTableFooter.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RemuneracoesTableFooter)
