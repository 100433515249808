import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class EntradaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: 'Entrada',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputValor = React.createRef()
    this.inputObservacoes = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idEntrada) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      getAPI({
        url: 'erp/caixas.buscarEntradaPorId',
        params: {
          idEntrada: this.props.idEntrada
        },
        requerAutorizacao: true,
        onSuccess: (response) => {
          this.setState({ entrada: response.data })
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/caixas.persistirEntrada',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoEntrada')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formEntrada')
      }
    })
  }

  getDados = () => {
    const dados = {}
    if (this.props.idEntrada) {
      dados.id = this.props.idEntrada
    }
    dados.valor = FormUtil.convertStringToNumber(this.inputValor.current.value)
    dados.observacao = this.inputObservacoes.current.value
    dados.caixa = { idCaixa: this.props.caixa.id }
    dados.data = this.props.caixa.dataInicial

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, entrada } = this.state
    const { observacao, valor } = entrada ? entrada : {}

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          <form id="formEntrada" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <InputText
              id="xvalor"
              autoFocus={true}
              disabled={this.state.ajaxing}
              errorMessage={errorsMap(['valor', 'caixa'])}
              label="Valor da Entrada (R$)"
              customVariant={'outlined-small'}
              shrink={false}
              inputComponent={InputMoneyFormat}
              defaultValue={valor}
              inputProps={{
                minValue: 1,
                maxValue: 999999
              }}
              inputRef={this.inputValor}
            />

            <InputText
              id="xobservacoes"
              name="xobservacoes"
              customVariant={'outlined-small'}
              shrink={false}
              marginTop={true}
              defaultValue={observacao}
              errorMessage={errorsMap('observacoes')}
              label="Observações"
              inputProps={{
                maxLength: 2000
              }}
              inputRef={this.inputObservacoes}
            />

            <HiddenSubmitButton />

            {this.state.paginaCarregada && (
              <div className={classes.containerFooterButtons}>
                <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                  Cancelar
                </Button>
                <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                  {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                </Button>
              </div>
            )}
          </form>
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

EntradaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EntradaDialogPage)
