import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DetalhesComissoesPorProdutoDialogPage from 'pages/erp/painel/colaborador/DetalhesComissoesPorProdutoDialogPage'
import DetalhesComissoesPorServicoDialogPage from 'pages/erp/painel/colaborador/DetalhesComissoesPorServicoDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import ComissoesPersonalizadasDialogPage from 'pages/erp/painel/servico/ComissoesPersonalizadasDialogPage'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputPorcentagemValor from 'support/components/input/InputPorcentagemValor'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoElegibilidadeGorjetaEnum from 'support/domain/colaborador/TipoElegibilidadeGorjetaEnum'
import RegraComissaoDescontoEnum from 'support/domain/comissao/RegraComissaoDescontoEnum'
import RegraComissaoTaxaRecebimentoEnum from 'support/domain/comissao/RegraComissaoTaxaRecebimentoEnum'
import TipoConfiguracaoComissaoEnum from 'support/domain/comissao/TipoConfiguracaoComissaoEnum'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'

const INPUT_COMISSOES_WIDTH = 160
const INPUT_TIPO_CONFIGURACOES_COMISSOES_WIDTH = 220

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons
})

class ConfiguracoesComissaoGorjetaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Comissões e Gorjetas',
      tipoElegibilidadeGorjeta: TipoElegibilidadeGorjetaEnum.DEPENDENTE_SERVICO,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputComissaoSozinhoServico = {}
    this.inputComissaoComAssistenteServico = {}
    this.inputDescontarComissaoComAssistenteServico = {}
    this.inputComissaoComoAssistenteServico = {}
    this.inputComissaoProduto = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/colaboradores.buscarConfiguracoesComissoesGorjetas',
      params: {
        id: this.props.colaborador.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.atualizarColaborador(response.data)

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar({ fecharDialog: true })
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar({ fecharDialog: true })
  }

  salvar = (props) => {
    this.setState((state) => ({
      ajaxing: true
    }))

    postAPI({
      url: 'erp/colaboradores.persistirConfiguracoesComissaoGorjeta',
      data: {
        idColaborador: this.props.colaborador.id,
        tipoConfiguracaoComissaoServico: this.state.tipoConfiguracaoComissaoServico,
        comissaoSozinhoServico: FormUtil.convertStringToNumber(this.inputComissaoSozinhoServico.getValor()),
        comissaoComAssistenteServico: FormUtil.convertStringToNumber(this.inputComissaoComAssistenteServico.getValor()),
        descontarComissaoComAssistenteServico: this.inputDescontarComissaoComAssistenteServico.getValue(),
        comissaoComoAssistenteServico: FormUtil.convertStringToNumber(this.inputComissaoComoAssistenteServico.getValor()),
        comissaoProduto: FormUtil.convertStringToNumber(this.inputComissaoProduto.getValor()),
        tipoCalculoComissaoSozinhoServico: this.inputComissaoSozinhoServico.getTipoCalculo(),
        tipoCalculoComissaoComAssistenteServico: this.inputComissaoComAssistenteServico.getTipoCalculo(),
        tipoCalculoComissaoComoAssistenteServico: this.inputComissaoComoAssistenteServico.getTipoCalculo(),
        tipoCalculoComissaoProduto: this.inputComissaoProduto.getTipoCalculo(),
        tipoElegibilidadeGorjeta: this.state.tipoElegibilidadeGorjeta,
        regraComissaoTaxaRecebimentoProfissional: this.state.regraComissaoTaxaRecebimentoProfissional,
        regraComissaoTaxaRecebimentoAssistente: this.state.regraComissaoTaxaRecebimentoAssistente,
        regraComissaoDescontoProfissional: this.state.regraComissaoDescontoProfissional,
        regraComissaoDescontoAssistente: this.state.regraComissaoDescontoAssistente
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const colaborador = response.data
        this.atualizarColaborador(colaborador)
        if (props.fecharDialog) {
          this.props.handleCloseDialog()
        }
        EventsManager.pub('ManipulacaoColaborador', { cliente: response.data })
        if (props.onSuccessCallback) {
          props.onSuccessCallback()
        }
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  atualizarColaborador = (colaborador) => {
    this.setState({
      ajaxing: false,
      tipoConfiguracaoComissaoServico:
        colaborador && colaborador.tipoConfiguracaoComissaoServico ? colaborador.tipoConfiguracaoComissaoServico : TipoConfiguracaoComissaoEnum.NAO_GERAR_COMISSAO,
      comissaoSozinhoServico: colaborador.comissaoSozinhoServico,
      comissaoComAssistenteServico: colaborador.comissaoComAssistenteServico,
      descontarComissaoComAssistenteServico: colaborador.descontarComissaoComAssistenteServico,
      comissaoComoAssistenteServico: colaborador.comissaoComoAssistenteServico,
      comissaoProduto: colaborador.comissaoProduto,
      tipoCalculoComissaoSozinhoServico: colaborador.tipoCalculoComissaoSozinhoServico,
      tipoCalculoComissaoComAssistenteServico: colaborador.tipoCalculoComissaoComAssistenteServico,
      tipoCalculoComissaoComoAssistenteServico: colaborador.tipoCalculoComissaoComoAssistenteServico,
      tipoCalculoComissaoProduto: colaborador.tipoCalculoComissaoProduto,
      realizaAtendimentos: colaborador.realizaAtendimentos,
      vendeProdutos: colaborador.vendeProdutos,
      vendePacotes: colaborador.vendePacotes,
      tipoElegibilidadeGorjeta: colaborador.tipoElegibilidadeGorjeta,
      regraComissaoTaxaRecebimentoProfissional: RegraComissaoTaxaRecebimentoEnum.getById(colaborador.regraComissaoTaxaRecebimentoProfissional.id),
      regraComissaoTaxaRecebimentoAssistente: RegraComissaoTaxaRecebimentoEnum.getById(colaborador.regraComissaoTaxaRecebimentoAssistente.id),
      regraComissaoDescontoProfissional: RegraComissaoDescontoEnum.getById(colaborador.regraComissaoDescontoProfissional.id),
      regraComissaoDescontoAssistente: RegraComissaoDescontoEnum.getById(colaborador.regraComissaoDescontoAssistente.id)
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const {
      errorsMap,
      realizaAtendimentos,
      tipoConfiguracaoComissaoServico,
      vendeProdutos,
      comissaoSozinhoServico,
      comissaoComAssistenteServico,
      descontarComissaoComAssistenteServico,
      comissaoComoAssistenteServico,
      tipoCalculoComissaoSozinhoServico,
      tipoCalculoComissaoComAssistenteServico,
      tipoCalculoComissaoComoAssistenteServico,
      comissaoProduto,
      tipoCalculoComissaoProduto,
      tipoElegibilidadeGorjeta,
      regraComissaoTaxaRecebimentoProfissional,
      regraComissaoTaxaRecebimentoAssistente,
      regraComissaoDescontoProfissional,
      regraComissaoDescontoAssistente,
      ajaxing
    } = this.state

    let comissaoInputContent = null
    let labelComissaoSozinho = 'Comissão Padrão'
    const styleInputsSimples = {}
    const styleInputsAvancados = {}

    if (tipoConfiguracaoComissaoServico) {
      if (tipoConfiguracaoComissaoServico.id === TipoConfiguracaoComissaoEnum.NAO_GERAR_COMISSAO.id) {
        styleInputsSimples.display = 'none'
        styleInputsAvancados.display = 'none'
      } else if (tipoConfiguracaoComissaoServico.id === TipoConfiguracaoComissaoEnum.SEM_COMISSAO.id) {
        styleInputsSimples.display = 'none'
        styleInputsAvancados.display = 'none'
      } else if (tipoConfiguracaoComissaoServico.id === TipoConfiguracaoComissaoEnum.SIMPLES.id) {
        styleInputsAvancados.display = 'none'
        styleInputsSimples.maxWidth = INPUT_TIPO_CONFIGURACOES_COMISSOES_WIDTH
      } else if (tipoConfiguracaoComissaoServico.id === TipoConfiguracaoComissaoEnum.AVANCADA.id) {
        labelComissaoSozinho = 'Comissão Sozinho'
      }
    }

    let textoDescricaoRegraPadraoTaxaRecebimentoProfissional = ''
    let textoDescricaoRegraPadraoTaxaRecebimentoAssistente = ''
    let textoDescricaoRegraPadraoDescontoProfissional = ''
    let textoDescricaoRegraPadraoDescontoAssistente = ''

    if (regraComissaoTaxaRecebimentoProfissional && regraComissaoTaxaRecebimentoProfissional.id === RegraComissaoTaxaRecebimentoEnum.REGRA_PADRAO_SISTEMA.id) {
      textoDescricaoRegraPadraoTaxaRecebimentoProfissional =
        ' Regra atual: ' + RegraComissaoTaxaRecebimentoEnum.getById(getSessaoPainel().negocio.configuracoesGerais.regraComissaoTaxaRecebimentoProfissional.id).descricaoOpcao
    }

    if (regraComissaoTaxaRecebimentoAssistente && regraComissaoTaxaRecebimentoAssistente.id === RegraComissaoTaxaRecebimentoEnum.REGRA_PADRAO_SISTEMA.id) {
      textoDescricaoRegraPadraoTaxaRecebimentoAssistente =
        ' Regra atual: ' + RegraComissaoTaxaRecebimentoEnum.getById(getSessaoPainel().negocio.configuracoesGerais.regraComissaoTaxaRecebimentoAssistente.id).descricaoOpcao
    }

    if (regraComissaoDescontoProfissional && regraComissaoDescontoProfissional.id === RegraComissaoDescontoEnum.REGRA_PADRAO_SISTEMA.id) {
      textoDescricaoRegraPadraoDescontoProfissional =
        ' Regra atual: ' + RegraComissaoDescontoEnum.getById(getSessaoPainel().negocio.configuracoesGerais.regraComissaoDescontoProfissional.id).descricaoOpcao
    }

    if (regraComissaoDescontoAssistente && regraComissaoDescontoAssistente.id === RegraComissaoDescontoEnum.REGRA_PADRAO_SISTEMA.id) {
      textoDescricaoRegraPadraoDescontoAssistente =
        ' Regra atual: ' + RegraComissaoDescontoEnum.getById(getSessaoPainel().negocio.configuracoesGerais.regraComissaoDescontoAssistente.id).descricaoOpcao
    }

    comissaoInputContent = (
      <React.Fragment>
        <InputPorcentagemValor
          key={'comissaosozinhoservico'}
          tipoCalculoVariant="small"
          marginTop={true}
          style={{ marginLeft: -1, maxWidth: INPUT_COMISSOES_WIDTH, display: realizaAtendimentos ? undefined : 'none', ...styleInputsSimples }}
          idname="xcomissaosozinhoservico"
          label={labelComissaoSozinho}
          customVariant="outlined-small"
          functionsMap={this.inputComissaoSozinhoServico}
          defaultValor={comissaoSozinhoServico}
          defaultTipoCalculo={tipoCalculoComissaoSozinhoServico}
          disabled={this.state.ajaxing || !realizaAtendimentos}
          errorMessage={errorsMap('comissaoSozinhoServico')}
        />

        <InputPorcentagemValor
          key={'comissaocomassistenteservico'}
          tipoCalculoVariant="small"
          marginTop={true}
          style={{ marginLeft: -1, maxWidth: INPUT_COMISSOES_WIDTH, display: realizaAtendimentos ? undefined : 'none', ...styleInputsAvancados }}
          idname="xcomissaocomassistenteservico"
          label="Com Assistentes"
          customVariant="outlined-small"
          functionsMap={this.inputComissaoComAssistenteServico}
          defaultValor={comissaoComAssistenteServico}
          defaultTipoCalculo={tipoCalculoComissaoComAssistenteServico}
          disabled={this.state.ajaxing || !realizaAtendimentos}
          errorMessage={errorsMap('comissaoComAssistenteServico')}
        />

        <InputPorcentagemValor
          key={'comissaocomoassistenteservico'}
          tipoCalculoVariant="small"
          marginTop={true}
          style={{ marginLeft: -1, maxWidth: INPUT_COMISSOES_WIDTH, display: realizaAtendimentos ? undefined : 'none', ...styleInputsAvancados }}
          idname="xcomissaocomoassistenteservico"
          label="Como Assistente"
          customVariant="outlined-small"
          functionsMap={this.inputComissaoComoAssistenteServico}
          defaultValor={comissaoComoAssistenteServico}
          defaultTipoCalculo={tipoCalculoComissaoComoAssistenteServico}
          disabled={this.state.ajaxing || !realizaAtendimentos}
          errorMessage={errorsMap('comissaoComoAssistenteServico')}
        />

        <UncontrolledCheckbox
          formControlProps={{
            style: { marginRight: 0, marginTop: 12, ...styleInputsAvancados },
            label: (
              <small style={{ lineHeight: '16px', display: 'inline-block', padding: '8px 0px' }}>
                Em atendimentos com assistentes, descontar as comissões dos assistentes da comissão deste profissional
              </small>
            )
          }}
          color="default"
          style={{ display: realizaAtendimentos ? undefined : 'none' }}
          disabled={this.state.ajaxing || !realizaAtendimentos}
          defaultChecked={descontarComissaoComAssistenteServico}
          functionsMap={this.inputDescontarComissaoComAssistenteServico}
        />
      </React.Fragment>
    )

    const podePersistirColaborador = verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])

    let textoDetalhesComissoesPorServico = (
      <Button
        startIcon={<VisibilityIcon />}
        disabled={ajaxing}
        style={{ fontSize: 14, marginTop: 8, textTransform: 'none', opacity: 0.7 }}
        sstyle={{ opacity: ajaxing ? 0.3 : 0.6, cursor: ajaxing ? 'auto' : 'pointer', display: 'block', padding: 8, margin: -8 }}
        onClick={() => {
          if (ajaxing) {
            return
          }
          if (podePersistirColaborador) {
            this.salvar({
              fecharDialog: false,
              onSuccessCallback: () => {
                openBackableDialog(DetalhesComissoesPorServicoDialogPage, {
                  entidade: 'servico',
                  colaborador: this.props.colaborador
                })
              }
            })
          } else {
            openBackableDialog(AccessDeniedDialog)
          }
        }}
      >
        Conferir Comissões
      </Button>
    )

    let textoDetalhesComissoesPorProduto = (
      <Button
        startIcon={<VisibilityIcon />}
        style={{ fontSize: 14, marginTop: 8, textTransform: 'none', opacity: 0.7 }}
        sstyle={{ opacity: ajaxing ? 0.3 : 0.6, cursor: ajaxing ? 'auto' : 'pointer', display: 'block', padding: 8, margin: -8 }}
        onClick={() => {
          if (ajaxing) {
            return
          }
          if (podePersistirColaborador) {
            this.salvar({
              fecharDialog: false,
              onSuccessCallback: () => {
                openBackableDialog(DetalhesComissoesPorProdutoDialogPage, {
                  entidade: 'servico',
                  colaborador: this.props.colaborador
                })
              }
            })
          } else {
            openBackableDialog(AccessDeniedDialog)
          }
        }}
      >
        Conferir Comissões
      </Button>
    )

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={505}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formComissoesGorjeta" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Comissões de Serviços
              </Typography>
              {realizaAtendimentos && (
                <Typography variant="body1">
                  <small
                    style={{ opacity: ajaxing ? 0.3 : 0.6, cursor: ajaxing ? 'auto' : 'pointer', display: 'block', padding: 8, margin: '-8px -8px -0px -8px' }}
                    onClick={() => {
                      if (ajaxing) {
                        return
                      }
                      if (podePersistirColaborador) {
                        this.salvar({
                          fecharDialog: false,
                          onSuccessCallback: () => {
                            openBackableDialog(ComissoesPersonalizadasDialogPage, {
                              entidade: 'servico',
                              colaborador: this.props.colaborador
                            })
                          }
                        })
                      } else {
                        openBackableDialog(AccessDeniedDialog)
                      }
                    }}
                  >
                    <b>
                      <u>Clique aqui</u>
                    </b>{' '}
                    para personalizar individualmente os valores das comissões.
                  </small>
                </Typography>
              )}

              <React.Fragment>
                <InputSelect
                  value={tipoConfiguracaoComissaoServico ? tipoConfiguracaoComissaoServico.id : null}
                  style={{ maxWidth: INPUT_TIPO_CONFIGURACOES_COMISSOES_WIDTH, display: realizaAtendimentos ? undefined : 'none' }}
                  customVariant="outlined-small"
                  disabled={this.state.ajaxing}
                  shrink={false}
                  marginTop={true}
                  label="Tipo de Comissão"
                  onChange={(event) => {
                    this.setState({ tipoConfiguracaoComissaoServico: TipoConfiguracaoComissaoEnum.getById(parseInt(event.target.value, 10)) })
                  }}
                  errorMessage={errorsMap('tipoConfiguracaoComissaoServico')}
                >
                  {TipoConfiguracaoComissaoEnum.values().map((tipoConfiguracao) => (
                    <MenuItem key={tipoConfiguracao.id} value={tipoConfiguracao.id}>
                      {tipoConfiguracao.descricaoParaColaborador}
                    </MenuItem>
                  ))}
                </InputSelect>

                <div style={{ marginLeft: 1 }}>{comissaoInputContent}</div>

                {realizaAtendimentos && textoDetalhesComissoesPorServico}
              </React.Fragment>

              {!realizaAtendimentos && (
                <Typography style={{ marginTop: 10, marginLeft: 10 }} variant="body2">
                  Não é possível configurar as comissões porque este profissional não pode executar atendimentos.
                </Typography>
              )}

              <Divider style={{ marginTop: 28, marginBottom: 26 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Comissões de Produtos
              </Typography>

              <InputPorcentagemValor
                key={'xcomissaoproduto'}
                marginTop={true}
                style={{ marginLeft: 2, maxWidth: INPUT_TIPO_CONFIGURACOES_COMISSOES_WIDTH, display: vendeProdutos ? undefined : 'none' }}
                idname="xcomissaoproduto"
                label="Comissão Padrão"
                customVariant="outlined-small"
                functionsMap={this.inputComissaoProduto}
                defaultValor={comissaoProduto}
                defaultTipoCalculo={tipoCalculoComissaoProduto}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('comissaoProduto')}
              />

              {vendeProdutos && <div>{textoDetalhesComissoesPorProduto}</div>}

              <Typography style={{ marginTop: 10, marginLeft: 10, display: !vendeProdutos ? undefined : 'none' }} variant="body2">
                Não é possível configurar as comissões porque este profissional não pode vender produtos.
              </Typography>

              <Divider style={{ marginTop: 28, marginBottom: 26 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo da taxa de forma de pagamento como profissional principal
              </Typography>

              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={regraComissaoTaxaRecebimentoProfissional.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoTaxaRecebimentoProfissional: RegraComissaoTaxaRecebimentoEnum.getById(id) })
                }}
              >
                {RegraComissaoTaxaRecebimentoEnum.values().map((regra) => (
                  <MenuItem key={regra.id} value={regra.id}>
                    {regra.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {regraComissaoTaxaRecebimentoProfissional.descricaoExplicacao} {textoDescricaoRegraPadraoTaxaRecebimentoProfissional}
              </Typography>

              <Divider style={{ marginTop: 28, marginBottom: 26 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo da taxa de forma de pagamento como assistente
              </Typography>

              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={regraComissaoTaxaRecebimentoAssistente.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoTaxaRecebimentoAssistente: RegraComissaoTaxaRecebimentoEnum.getById(id) })
                }}
              >
                {RegraComissaoTaxaRecebimentoEnum.values().map((regra) => (
                  <MenuItem key={regra.id} value={regra.id}>
                    {regra.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {regraComissaoTaxaRecebimentoAssistente.descricaoExplicacao} {textoDescricaoRegraPadraoTaxaRecebimentoAssistente}
              </Typography>

              <Divider style={{ marginTop: 28, marginBottom: 26 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo de desconto como profissional principal
              </Typography>

              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={regraComissaoDescontoProfissional.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoDescontoProfissional: RegraComissaoDescontoEnum.getById(id) })
                }}
              >
                {RegraComissaoDescontoEnum.values().map((regra) => (
                  <MenuItem key={regra.id} value={regra.id}>
                    {regra.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {regraComissaoDescontoProfissional.descricaoExplicacao} {textoDescricaoRegraPadraoDescontoProfissional}
              </Typography>

              <Divider style={{ marginTop: 28, marginBottom: 26 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo de desconto como assistente
              </Typography>

              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={regraComissaoDescontoAssistente.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoDescontoAssistente: RegraComissaoDescontoEnum.getById(id) })
                }}
              >
                {RegraComissaoDescontoEnum.values().map((regra) => (
                  <MenuItem key={regra.id} value={regra.id}>
                    {regra.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {regraComissaoDescontoAssistente.descricaoExplicacao} {textoDescricaoRegraPadraoDescontoAssistente}
              </Typography>

              <Divider style={{ marginTop: 28, marginBottom: 26 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Gorjetas
              </Typography>

              <RadioGroup
                style={{ marginTop: 16 }}
                value={tipoElegibilidadeGorjeta.id.toString()}
                onChange={(event) => {
                  this.setState({
                    tipoElegibilidadeGorjeta: TipoElegibilidadeGorjetaEnum.getById(parseInt(event.target.value, 10))
                  })
                }}
              >
                {TipoElegibilidadeGorjetaEnum.values().map((tipo, index) => (
                  <FormControlLabel
                    key={tipo.id}
                    value={tipo.id.toString()}
                    control={<Radio />}
                    label={
                      <Typography variant="body2" style={{ padding: '8px 0px' }}>
                        {tipo.descricao}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button
                    disabled={this.state.ajaxing}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.salvar({ fecharDialog: true })
                    }}
                  >
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesComissaoGorjetaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesComissaoGorjetaDialogPage)
