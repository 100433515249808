import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import UltimasAtualizacoesContent from 'support/components/atualizacao/UltimasAtualizacoesContent'
import PanelPage from 'support/components/panel/PanelPage'

const styles = (theme) => ({})

class UltimasAtualizacoesPanelPage extends Component {
  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} contentMaxWidth={800} title="Últimas Atualizações">
        <UltimasAtualizacoesContent />
      </PanelPage>
    )
  }
}

UltimasAtualizacoesPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UltimasAtualizacoesPanelPage)
