export function roundNumber(num, scale) {
  if (!('' + num).includes('e')) {
    return +(Math.round(num + 'e+' + scale) + 'e-' + scale)
  } else {
    var arr = ('' + num).split('e')
    var sig = ''
    if (+arr[1] + scale > 0) {
      sig = '+'
    }
    return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale)
  }
}

export function round(value) {
  return roundNumber(value, 2)
}

export function isInt(str) {
  return !isNaN(str) && Number.isInteger(parseFloat(str))
}

const functionsObject = {
  round: round
}

export default functionsObject
