import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  NAO_EXIBIR: {
    id: 1,
    descricaoOpcao: 'Não Exibir'
  },
  EXIBIR_SELECIONADO: {
    id: 2,
    descricaoOpcao: 'Exibir Selecionado'
  },
  EXIBIR_NAO_SELECIONADO: {
    id: 3,
    descricaoOpcao: 'Exibir Não Selecionado'
  }
})

export default enumObject
