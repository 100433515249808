import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import ServicosPacotesAtivosDialogPage from 'pages/erp/painel/relatorio/ServicosPacotesAtivosDialogPage'
import ServicosPacotesRealizadosDialogPage from 'pages/erp/painel/relatorio/ServicosPacotesRealizadosDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ChartLine from 'support/components/chart/ChartLine'
import ChartPie from 'support/components/chart/ChartPie'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  titulo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  containerInformacoes: {
    marginTop: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paperInformacoes: {
    border: '1px solid #eee',
    borderRadius: 14,
    height: 'calc(100% - 64px)',
    padding: '18px 30px 34px',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 48px)',
      padding: theme.spacing(2) + 'px ' + theme.spacing(3) + 'px ' + theme.spacing(3) + 'px '
    }
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 })
  },
  colunaPacote: {
    flexGrow: 1.0
  },
  colunaValor: {
    maxWidth: 120,
    minWidth: 120
  },
  colunaQuantidade: {
    maxWidth: 120,
    minWidth: 120
  }
})

class RelatorioPacotesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxingDadosPacote: false,
      ajaxingDadosPacoteUltimosMeses: false,
      dadosPacotesDataInicial: this.colaboradorLocalPreferences.getMomentPreference('RelatorioPacotesDialogPageFiltro_MaisLucrativosDataInicial', moment().startOf('month')),
      dadosPacotesDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioPacotesDialogPageFiltro_MaisLucrativosDataFinal', moment().endOf('month')),
      pacotesMaisLucrativos: []
    }
    this.dadosPacotesLoaderFunctionsMap = {}
    this.dadosValoresCobradosUltimosMesesLoaderFunctionsMap = {}
  }

  carregarDadosPacotesUltimosMeses = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingDadosPacoteUltimosMeses: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosPacotes',
      params: {
        dadosPacotesUltimosMeses: true
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxingDadosPacoteUltimosMeses: false,
          dadosValoresCobradosUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(
            response.data.dadosPacotesUltimosMeses,
            'Valor Cobrado',
            'valor',
            this.props.theme.palette.chart.list[0],
            (value, name, props) => [
              <span style={{ fontSize: 12 }}>
                {name}: {formatarValorMonetario(value)}
              </span>,
              null
            ]
          ),
          dadosQuantidadeUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(
            response.data.dadosPacotesUltimosMeses,
            'Quantidade',
            'quantidade',
            this.props.theme.palette.chart.list[1],
            (value, name, props) => [
              <span style={{ fontSize: 12 }}>
                {name}: {value}
              </span>,
              null
            ]
          )
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingDadosPacoteUltimosMeses: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarDadosPacotes = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingDadosPacote: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosPacotes',
      params: {
        dadosGeraisPacotes: true,
        dadosGeraisPacotesDataInicial: converterMomentParaDataInt(this.state.dadosPacotesDataInicial),
        dadosGeraisPacotesDataFinal: converterMomentParaDataInt(this.state.dadosPacotesDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxingDadosPacote: false,
          pacotesMaisLucrativos: response.data.dadosPacotesMaisLucrativos,
          dadosTopMaisLucrativos: this.prepararDadosTopMaisLucrativos(response.data.dadosPacotesMaisLucrativos),
          dadosGeraisPacotes: response.data.dadosGeraisPacotes
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingDadosPacote: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosTopMaisLucrativos = (items) => {
    const dados = []
    for (let item of items) {
      if (dados.length < 6) {
        dados.push({
          name: (
            <span>
              <b>{item.descricao}</b> - Qtd: {item.quantidade}, {formatarValorMonetario(item.valor)}
            </span>
          ),
          value: item.valor
        })
      } else if (dados.length === 6) {
        dados.push({
          name: (
            <span>
              <b>Outros</b> - Qtd: {item.quantidade}, {formatarValorMonetario(item.valor)}
            </span>
          ),
          value: item.valor,
          quantidade: item.quantidade
        })
      } else {
        const ultimoItem = dados[dados.length - 1]
        ultimoItem.value = ultimoItem.value + item.valor
        ultimoItem.quantidade = ultimoItem.quantidade + item.quantidade
        ultimoItem.name = (
          <span>
            <b>Outros</b> - Qtd: {ultimoItem.quantidade}, {formatarValorMonetario(ultimoItem.value)}
          </span>
        )
      }
    }
    return dados
  }

  prepararDadosUltimosMesesParaGrafico = (dadosUltimosMeses, descricao, valueProp, color, tooltipFormatterFunction) => {
    const dadosUltimosMesesPorMes = {}
    if (dadosUltimosMeses && dadosUltimosMeses.length) {
      for (let dadosReceitaUltimosMeses of dadosUltimosMeses) {
        dadosUltimosMesesPorMes[dadosReceitaUltimosMeses.mes.toString()] = dadosReceitaUltimosMeses
      }
    }

    const agora = moment()
    const dataNavegacao = agora.clone().startOf('month').subtract(11, 'month')
    const criarItem = (mes, valor) => {
      const data = moment(mes.toString(), 'YYYYMM')
      return {
        mes: data.format('MMM/YY'),
        vl: valor === null || valor === undefined ? 0 : valor
      }
    }

    const data = []
    while (true) {
      const mesAtual = dataNavegacao.format('YYYYMM')
      const dadosReceitaUltimosMeses = dadosUltimosMesesPorMes[mesAtual]
      if (dadosReceitaUltimosMeses) {
        data.push(criarItem(mesAtual, dadosReceitaUltimosMeses[valueProp]))
      } else {
        data.push(criarItem(mesAtual, 0))
      }
      if (dataNavegacao.isSame(agora, 'month')) {
        break
      } else {
        dataNavegacao.add(1, 'month')
      }
    }
    return {
      xAxis: {
        dataKey: 'mes',
        height: 80
      },
      tooltip: {
        formatter: tooltipFormatterFunction
      },
      items: [
        {
          name: descricao,
          color: color,
          shortName: descricao,
          dataKey: 'vl'
        }
      ],
      data: data
    }
  }

  abrirDetalhesServicosPacotesRealizados = () => {
    openBackableDialog(ServicosPacotesRealizadosDialogPage, {
      dataInicial: this.state.dadosPacotesDataInicial,
      dataFinal: this.state.dadosPacotesDataFinal
    })
  }

  abrirDetalhesServicosPacotesAtivos = () => {
    openBackableDialog(ServicosPacotesAtivosDialogPage, {
      dataInicial: this.state.dadosPacotesDataInicial,
      dataFinal: this.state.dadosPacotesDataFinal
    })
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.descricao = item.descricao
    content.quantidade = item.quantidade.toString()
    content.valor = formatarValorMonetario(item.valor, { prefix: false })
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const {
      ajaxingDadosPacote,
      ajaxingDadosPacoteUltimosMeses,
      dadosValoresCobradosUltimosMeses,
      dadosQuantidadeUltimosMeses,
      dadosPacotesDataInicial,
      dadosPacotesDataFinal,
      dadosGeraisPacotes
    } = this.state

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.pacotesMaisLucrativos) {
      valorTotal += item.valor
      quantidadeTotal += item.quantidade
    }

    let toolbarActions = null
    if (!ajaxingDadosPacote && !ajaxingDadosPacoteUltimosMeses) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.dadosPacotesLoaderFunctionsMap.reload()
              this.dadosValoresCobradosUltimosMesesLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let dadosPacotesLoadKey =
      converterMomentParaDataInt(this.state.dadosPacotesDataInicial).toString() + '-' + converterMomentParaDataInt(this.state.dadosPacotesDataFinal).toString()
    return (
      <DialogPage
        {...dialogProps}
        title="Pacotes"
        align="center"
        contentMaxWidth={1200}
        pageType="basicEntity"
        ajaxing={ajaxingDadosPacote || ajaxingDadosPacoteUltimosMeses}
        toolbarActions={toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Gráfico de Pacotes Vendidos
              </Typography>
            </FloatContent>

            <ContentWithPreload functionsMap={this.dadosValoresCobradosUltimosMesesLoaderFunctionsMap} loadFunction={this.carregarDadosPacotesUltimosMeses}>
              {() => (
                <div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 24 }}>
                    <ChartLine data={dadosValoresCobradosUltimosMeses} height={300} />
                  </div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 24 }}>
                    <ChartLine data={dadosQuantidadeUltimosMeses} height={300} />
                  </div>
                </div>
              )}
            </ContentWithPreload>

            <div style={{ padding: '34px 24px 16px 24px', textAlign: 'center' }}>
              <Typography variant="h5" align="center" className={classes.titulo}>
                Informações Gerais
              </Typography>
              <div style={{ display: 'inline-block' }}>
                <DateRangeNavigator
                  expanded={false}
                  defaultValue={{
                    tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioPacotesDialogPageFiltro_DadosFinanceirosTipoIntervalo', 'mes'),
                    dataInicial: dadosPacotesDataInicial,
                    dataFinal: dadosPacotesDataFinal
                  }}
                  onChange={(data) => {
                    this.colaboradorLocalPreferences.setPreference('RelatorioPacotesDialogPageFiltro_DadosFinanceirosTipoIntervalo', data.tipoIntervalo.nome)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioPacotesDialogPageFiltro_MaisLucrativosDataInicial', data.dataInicial)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioPacotesDialogPageFiltro_MaisLucrativosDataFinal', data.dataFinal)
                    this.setState({
                      dadosPacotesDataInicial: data.dataInicial,
                      dadosPacotesDataFinal: data.dataFinal
                    })
                  }}
                />
              </div>
            </div>

            <ContentWithPreload functionsMap={this.dadosPacotesLoaderFunctionsMap} loadFunction={this.carregarDadosPacotes} loadKey={dadosPacotesLoadKey}>
              {() => (
                <React.Fragment>
                  <div className={classes.containerInformacoes}>
                    <Grid container spacing={isWidthDown('xs', this.props.width) ? 1 : 3}>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paperInformacoes} elevation={1}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                                Serviços Realizados
                              </Typography>
                            </Grid>
                            <Grid item>
                              {
                                <Tooltip title="Abrir Detalhes">
                                  <IconButton
                                    style={{ marginRight: -8 }}
                                    color="secondary"
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      this.abrirDetalhesServicosPacotesRealizados()
                                    }}
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </Grid>
                          </Grid>
                          <Typography variant="body2" style={{ color: '#797878' }} gutterBottom>
                            Quantidade total de serviços de pacotes que já foram realizados.
                          </Typography>
                          <Typography variant="h6">{dadosGeraisPacotes.quantidadeServicosRealizados}</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paperInformacoes} elevation={1}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                                Serviços não Realizados
                              </Typography>
                            </Grid>
                            <Grid item>
                              {
                                <Tooltip title="Abrir Detalhes">
                                  <IconButton
                                    style={{ marginRight: -8 }}
                                    color="secondary"
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      this.abrirDetalhesServicosPacotesAtivos()
                                    }}
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </Grid>
                          </Grid>
                          <Typography variant="body2" style={{ color: '#797878' }} gutterBottom>
                            Quantidade total de serviços que já foram comprados em pacotes mas ainda não foram realizados.
                          </Typography>
                          <Typography variant="h6">{dadosGeraisPacotes.quantidadeServicosAtivos}</Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>

                  <Typography variant="h6" style={{ padding: '34px 24px 16px 24px', textAlign: 'center' }} className={classes.titulo}>
                    Pacotes Mais Lucrativos
                  </Typography>
                  <div style={{ width: '100%', paddingBottom: 32, paddingTop: 32 }}>
                    <ChartPie
                      data={{
                        items: this.state.dadosTopMaisLucrativos
                      }}
                    />
                  </div>

                  {this.state.pacotesMaisLucrativos && this.state.pacotesMaisLucrativos.length > 0 && (
                    <React.Fragment>
                      <div>
                        <VirtualizedResponsiveTable
                          showBackgroundDividers={true}
                          scrollElement={dialogContentProps.scrollContainerRef.current}
                          items={this.state.pacotesMaisLucrativos}
                          largeRenderProps={{
                            columns: [
                              { label: 'Pacote', className: classes.colunaPacote, props: { xs: true } },
                              { label: 'Quantidade', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                              { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item)

                              return {
                                itemData: [content.descricao, content.quantidade, content.valor]
                              }
                            }
                          }}
                          smallRenderProps={{
                            rowHeight: 112,
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('small', item)

                              return {
                                itemData: (
                                  <React.Fragment>
                                    <Typography variant="body2" className={classes.descricaoContainerSmall}>
                                      <b>{content.descricao}</b>
                                    </Typography>
                                    <Grid container style={{ marginTop: 4 }}>
                                      <Grid item xs>
                                        <Typography variant="body2">{content.valor}</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="body2">Qtd.: {content.quantidade}</Typography>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                )
                              }
                            }
                          }}
                          emptyListProps={{
                            text: 'Não existem vendas de pacotes para o período informado'
                          }}
                        />
                      </div>

                      {quantidadeTotal > 1 && (
                        <FloatContent container="paper" context="DialogPage" type="tableSummary">
                          <Typography variant="body2">
                            Pacotes: <b>{quantidadeTotal}</b>
                            <br />
                            Total: <b>{formatarValorMonetario(valorTotal)}</b>
                            <br />
                          </Typography>
                        </FloatContent>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioPacotesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(RelatorioPacotesDialogPage))
