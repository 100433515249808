import { Grid, Typography } from '@material-ui/core'
import { extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import { TipoPagamentoEnum } from 'support/domain/mercadopago/MercadoPagoEnum'

import { useStyles } from './FormaPagamentoMercadoPago.styles'
import PagamentoMercadoPago from './PagamentoMercadoPago'

type FormaPagamentoMercadoPagoDialogPageProps = {
  formaPagamento: {
    nome: string
  }
}

const FormaPagamentoMercadoPagoDialogPage = (props: FormaPagamentoMercadoPagoDialogPageProps) => {
  const classes = useStyles()

  const handleSelectOption = (type: string) => {
    openBackableDialog(PagamentoMercadoPago, {
      typePayment: type,
      formaPagamento: props.formaPagamento
    })
  }

  const listPayments = [{ name: TipoPagamentoEnum.CARTAO_CREDITO }, { name: TipoPagamentoEnum.CARTAO_DEBITO }]
  const dialogProps = extractDialogProps(props)

  return (
    <DialogPage {...dialogProps} title="Maquininha Mercado Pago" align="center" contentMaxWidth={600}>
      <Typography className={classes.span}>Configurações</Typography>
      {listPayments.map((item) => (
        <Grid data-testid={`button-${item.name}`} key={item.name} onClick={() => handleSelectOption(item.name)} container alignItems="center" wrap="nowrap" className={classes.row}>
          <Typography className={classes.textButton}>{item.name}</Typography>
        </Grid>
      ))}
    </DialogPage>
  )
}

export default FormaPagamentoMercadoPagoDialogPage
