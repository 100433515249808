import { useMemo } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'

import { PocketStatus } from './PocketDialog'
import { useStyles } from './PocketDialot.styles'

type StatusPocketProps = {
  status: PocketStatus
}

export const StatusPocket = ({ status }: StatusPocketProps) => {
  const classes = useStyles()

  const title = useMemo(() => {
    const condition = {
      loading: 'Estamos processando sua solicitação',
      success: 'Sua solicitação foi realizada!'
    }
    return condition[status as keyof typeof condition]
  }, [status])

  const subTitle = useMemo(() => {
    const condition = {
      loading: 'Aguarde um pouco',
      success: 'O reprocessamento pode levar até 10 minutos.'
    }
    return condition[status as keyof typeof condition]
  }, [status])

  const icon = useMemo(() => {
    const condition = {
      loading: <CircularProgress color="secondary" size={60} thickness={4} className={classes.loading} />,
      success: <CheckCircleOutline color="secondary" className={classes.loading} fontSize="large" />
    }
    return condition[status as keyof typeof condition]
  }, [status, classes.loading])
  return (
    <Box className={classes.wrapperLoading}>
      {icon}
      <Typography className={classes.waitingTitle}>{title}</Typography>
      <Typography className={classes.waitingSubtitle}>{subTitle}</Typography>
    </Box>
  )
}
