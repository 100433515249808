import React, { Component } from 'react'

class TextMultiline extends Component {
  render() {
    const { children } = this.props

    return children.split('\n').map((item, key) => {
      return (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      )
    })
  }
}

export default TextMultiline
