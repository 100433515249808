import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputSelect from 'support/components/input/InputSelect'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'

const styles = (theme) => ({})

class InputTipoRemuneracao extends Component {
  render() {
    const { classes, descricaoTodos, ...others } = this.props

    return (
      <InputSelect
        {...others}
        displayEmpty={true}
        renderValue={(value) => {
          if (value) {
            return TipoRemuneracaoEnum.getById(parseInt(value, 10)).descricaoPlural
          } else {
            return descricaoTodos ? descricaoTodos : 'Todos'
          }
        }}
      >
        <MenuItem key="todas" value="">
          Exibir Todos
        </MenuItem>
        {TipoRemuneracaoEnum.values().map((tipo) => (
          <MenuItem key={tipo.id} value={tipo.id}>
            {tipo.descricaoPlural}
          </MenuItem>
        ))}
      </InputSelect>
    )
  }
}

InputTipoRemuneracao.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputTipoRemuneracao)
