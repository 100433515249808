import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    textAlign: 'center'
  },
  icon: {
    fontSize: 122,
    color: '#eee',
    marginBottom: 10
  },
  button: {
    marginTop: 10
  },
  progress: {
    margin: theme.spacing(2)
  }
})

class VirtualizedListLoadMoreFooter extends Component {
  render() {
    const { classes, loaded, loading, loadMoreFunction, lastRequestWithError } = this.props

    let content = null
    if (loaded === true && loading === true) {
      content = <CircularProgress className={classes.progress} />
    } else if (loaded === true && loading === false && lastRequestWithError === true) {
      content = (
        <div style={{ display: 'inline-block', textAlign: 'center', padding: '16px 8px' }}>
          <Typography variant="body2">Erro no carregamento dos dados</Typography>
          <Button size="small" variant="outlined" style={{ marginTop: 6 }} color="default" type="button" onClick={loadMoreFunction}>
            Carregar Mais
          </Button>
        </div>
      )
    }

    if (content !== null) {
      return <div className={classes.root}>{content}</div>
    } else {
      return []
    }
  }
}

VirtualizedListLoadMoreFooter.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VirtualizedListLoadMoreFooter)
