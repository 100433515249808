import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'

import { isWidthDown, withWidth } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'
import EventIcon from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GetAppIcon from '@material-ui/icons/GetApp'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PersonIcon from '@material-ui/icons/Person'
import PolicyIcon from '@material-ui/icons/PolicyOutlined'
import PublicIcon from '@material-ui/icons/Public'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SettingsIcon from '@material-ui/icons/Settings'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import StarRateIcon from '@material-ui/icons/StarRate'
import StoreIcon from '@material-ui/icons/Store'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import WorkIcon from '@material-ui/icons/Work'
import classNames from 'classnames'
import { User, withConfigCatClient } from 'configcat-react'
import logo from 'img/logo_salao99_enterprise_preto.png'
import moment from 'moment'
import { verificarAutorizacao, verificarAutorizacaoPorRota } from 'pages/erp/painel/PainelErp'
import SolicitacaoTreinamentoDialog from 'pages/erp/SolicitacaoTreinamentoDialog'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PointOfSaleIcon from 'support/components/icon/PointOfSale'
import PointOfSaleIcon2 from 'support/components/icon/PointOfSale2'
import ProductsIcon from 'support/components/icon/Products'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import NativeApp from 'support/components/nativeapp/NativeApp'
import Drawer from 'support/components/panel/Drawer'
import DrawerMenuDivider from 'support/components/panel/DrawerMenuDivider'
import DrawerMenuItem from 'support/components/panel/DrawerMenuItem'
import DrawerMenuItemIcon from 'support/components/panel/DrawerMenuItemIcon'
import DrawerMenuItemText from 'support/components/panel/DrawerMenuItemText'
import SimpleDrawerMenuItem from 'support/components/panel/SimpleDrawerMenuItem'
import PlanoMenuItem from 'support/components/planomenuitem'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import WebAppInstalationButton from 'support/components/webapp/WebAppInstalationButton'
import Wizard from 'support/components/wizard/Wizard'
import AtivacaoFluxoCaixaEnum from 'support/domain/caixa/AtivacaoFluxoCaixaEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'
import EmissaoNfEnum from 'support/domain/venda/EmissaoNfEnum'
import { converteHexStrToRgbStr } from 'support/util/ColorUtil'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { logAGE, logCRM } from 'support/util/Logger/Logger'

const MAXIMO_DIAS_MOSTRAR_CARD_SOLICITACAO_TREINAMENTO = 30
const INTERVALO_HORAS_MOSTRAR_CARD_SOLICITACAO_TREINAMENTO = 24 * 2
const MINIMO_DIAS_MOSTRAR_CARD_AVALIACAO_APP = 45
const INTERVALO_HORAS_MOSTRAR_CARD_AVALIACAO_APP = 24 * 5

const styles = (theme) => ({
  singleLine: theme.text.singleLine(),
  containerMenuUsuario: {
    ...theme.mixins.toolbar,
    marginTop: -1,
    paddingTop: 9,
    paddingBottom: 8
  },
  notificationBadge: {
    color: theme.palette.badge.primaryContrast.text,
    backgroundColor: theme.palette.badge.primaryContrast.background,
    animation: '$pulse 1.5s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      ...theme.util.createBoxShadow('0 0 0 0 ' + lighten(converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 1), 0.4))
    },
    '85%': {
      ...theme.util.createBoxShadow('0 0 0 20px ' + lighten(converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 0), 0.4))
    },
    '100%': {
      ...theme.util.createBoxShadow('0 0 0 0 ' + lighten(converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 0), 0.4))
    }
  },
  dataVencimentoPlano7DiasVencimento: {
    backgroundColor: '#ffc480'
  },
  dataVencimentoPlano1DiaVencimento: {
    backgroundColor: '#f35656'
  },
  dataVencimentoPlanoProximoVencimento: {
    color: '#fff',
    marginLeft: 4,
    padding: 4,
    borderRadius: 2,
    animation: '$piscar 1s linear infinite'
  },
  '@keyframes piscar': {
    '0%': {
      opacity: 1
    },
    '40%': {
      opacity: 1
    },
    '70%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 0
    }
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.33)',
    boxShadow: '0px 1px 1px 0px #444'
  },
  nomeNegocio: {
    ...theme.text.limitedLines()
  },
  containerSolicitacaoTreinamento: {
    margin: '6px 25px 16px',
    padding: 16,
    background: 'white',
    borderRadius: 5,
    whiteSpace: 'normal'
  },
  containerSolicitacaoTreinamentoDescricao: {
    marginTop: 6,
    display: 'block',
    lineHeight: '1.15rem'
  },
  containerAvaliarAplicativo: {
    border: '1px solid #e0e0e0',
    margin: '6px 25px 16px',
    padding: 16,
    background: 'white',
    borderRadius: 5,
    whiteSpace: 'normal'
  },
  containerAvaliarAplicativoStar: {
    color: '#ffd658'
  },
  containerAvaliarAplicativoDescricao: {
    display: 'block',
    lineHeight: '1.15rem'
  }
})

class DrawerErp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isWizardWhatsappEnabled: false,
      featureFlagsRechecked: false,
      currentWizard: ''
    }
    this.createLocalPreferences(this.props.usuarioSessao, this.props.colaboradorSessao)
    this.scrollbarRef = React.createRef()
  }

  componentDidMount() {
    this.evaluateWizardWhatsappFeatureFlag()
  }

  componentDidUpdate() {
    const { usuarioSessao, negocioSessao } = this.props

    if (usuarioSessao.carregado && negocioSessao.carregado && !this.state.featureFlagsRechecked) {
      this.evaluateWizardWhatsappFeatureFlag()
      this.setState({ featureFlagsRechecked: true })
    }

    this.handleWizardView()
  }

  evaluateWizardWhatsappFeatureFlag() {
    const { usuarioSessao, getValue } = this.props
    let user = undefined
    try {
      user = new User(String(usuarioSessao?.id), SessaoUsuario.getUsuario()?.email)
    } catch (error) {
      logCRM(error, { component: 'DrawerErp', context: 'evaluateWizardWhatsappFeatureFlag' })
    }

    getValue('FT_CRM_WIZARD_MENSAGERIA', false, user).then((value) => {
      this.setState({ isWizardWhatsappEnabled: value })
    })
  }

  shouldComponentUpdate(nextProps) {
    this.createLocalPreferences(nextProps.usuarioSessao, nextProps.colaboradorSessao)
    return true
  }

  createLocalPreferences = (usuarioSessao, colaboradorSessao) => {
    this.usuarioLocalPreferences = LocalPreferences.new('usuario', usuarioSessao.id)
    if (colaboradorSessao) {
      this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', colaboradorSessao.id)
    }
  }

  isMenuItemDisabled = (rotaMenu, props) => {
    if (!props) {
      props = {}
    }

    if (
      !props.validarApenasPermissoes &&
      (this.props.negocioSessao === null || this.props.negocioSessao.ativo === undefined || this.props.negocioSessao.ativo !== true || this.props.negocioSessao.excluido === true)
    ) {
      return true
    } else if (this.props.colaboradorSessao) {
      if (this.props.colaboradorSessao.excluido === true) {
        return true
      } else if (this.props.colaboradorSessao.permissoes !== undefined) {
        return !verificarAutorizacaoPorRota(rotaMenu)
      }
    }
    return true
  }

  changePanelContainerTheme(name) {
    this.props.changePanelContainerTheme(name)
  }

  handleContaMenuClick = (event) => {
    this.setState({ anchorContaMenu: event.currentTarget })
  }

  handleContaMenuClose = (closeMobileMenu) => {
    this.setState({ anchorContaMenu: null })
    if (closeMobileMenu !== undefined && closeMobileMenu === true) {
      this.props.changeDrawerMobileOpen(false)
    }
  }

  getContentDataVencimento = (formatoVencimento, formatoProximoVencimento) => {
    const { classes } = this.props
    const dataVencimentoPlanoAsMoment = converterDataIntParaMoment(this.props.negocioSessao.dataVencimentoPlano).startOf('day')
    const diasParaVencimento = dataVencimentoPlanoAsMoment.diff(moment().startOf('day'), 'days')
    if (diasParaVencimento <= 1) {
      return (
        <span className={classNames(classes.dataVencimentoPlanoProximoVencimento, classes.dataVencimentoPlano1DiaVencimento)}>
          {dataVencimentoPlanoAsMoment.format(formatoProximoVencimento)}
        </span>
      )
    } else if (diasParaVencimento <= 7) {
      return (
        <span className={classNames(classes.dataVencimentoPlanoProximoVencimento, classes.dataVencimentoPlano7DiasVencimento)}>
          {dataVencimentoPlanoAsMoment.format(formatoProximoVencimento)}
        </span>
      )
    } else {
      return dataVencimentoPlanoAsMoment.format(formatoVencimento)
    }
  }

  setScrollbarRef = (ref) => {
    this.scrollbarRef = ref
  }

  isMenuVisible = () => {
    const { negocioSessao, colaboradorSessao, paginaCarregada, mobileDrawerOpen, desktopDrawerOpen } = this.props
    const isSmallDesktopWidth = isWidthDown('sm', this.props.width)

    if (negocioSessao.carregado && colaboradorSessao.carregado && paginaCarregada) {
      // Mobile Menu Closed
      // desktopDrawerOpen is always set to true, regardless if it is on mobile or not
      if (desktopDrawerOpen && isMobile && !mobileDrawerOpen) {
        return false
      }

      // Desktop Small Resolution with Menu Closed should not show Wizard
      if (isSmallDesktopWidth && !mobileDrawerOpen && !isMobile) {
        return false
      }

      // Mobile Menu Opened
      if (mobileDrawerOpen) {
        return true
      }

      // Desktop Menu Opened
      if (desktopDrawerOpen && !isMobile) {
        return true
      }
    }
  }

  handleWizardSession = (wizardKey, viewedTimes) => {
    this.colaboradorLocalPreferences.setPreference(wizardKey, viewedTimes + 1, { type: 'permanent' })
    sessionStorage.setItem(
      'viewedWizardOnCurrentSession',
      JSON.stringify({
        isVisible: true,
        viewed: true
      })
    )
  }

  isTrial = () => {
    if (!this.props.negocioSessao?.plano?.id) {
      return true
    }

    return this.props.negocioSessao.plano.id === PlanoEnum.AVALIACAO.id
  }

  handleWizardView = () => {
    if (!this.isMenuVisible()) {
      if (this.state.currentWizard !== '') {
        this.setState({ currentWizard: '' })
      }

      return
    }

    const hasABackableOpened = window.location.search
    if (hasABackableOpened) {
      return
    }

    const viewOnCurrentSession = JSON.parse(sessionStorage.getItem('viewedWizardOnCurrentSession'))

    if (Boolean(viewOnCurrentSession?.isVisible) || Boolean(viewOnCurrentSession?.viewed)) {
      return
    }

    const { isWizardWhatsappEnabled } = this.state
    const isActive = this.props.negocioSessao.ativo

    const mensageriaWizardViews = this.colaboradorLocalPreferences.getPreference('mensageriaWizardViews')
    if (isWizardWhatsappEnabled && !this.isTrial() && isActive && mensageriaWizardViews < 2 && !this.isMenuItemDisabled('/whatsapp')) {
      this.handleWizardSession('mensageriaWizardViews', mensageriaWizardViews)
      this.setState({ currentWizard: 'whatsapp' })
      return
    }

    const pagamentoRecorrenteWizardViews = this.colaboradorLocalPreferences.getPreference('pagamentoRecorrenteWizardViews')
    if (this.isTrial() && isActive && !DeviceIdentifier.isNativeIOS()) {
      this.handleWizardSession('pagamentoRecorrenteWizardViews', pagamentoRecorrenteWizardViews)
      this.setState({ currentWizard: 'pagamentoRecorrente' })
      return
    }
  }

  render() {
    const { classes, ...others } = this.props
    const { isMenuRetracted } = this.props
    const { currentWizard } = this.state

    const IconeSuporte = (props) => {
      if (isMenuRetracted && this.props.usuarioEstadoAtualizado && this.props.usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas > 0) {
        return (
          <Badge
            classes={{ badge: classes.notificationBadge }}
            badgeContent={this.props.usuarioEstadoAtualizado ? this.props.usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas : 0}
            max={9}
            overlap="rectangular"
          >
            <AccessibilityIcon {...props} />
          </Badge>
        )
      } else {
        return <AccessibilityIcon {...props} />
      }
    }

    let contentNotificacoesUsuario = null
    if (this.props.desktopDrawerOpen && this.props.usuarioEstadoAtualizado && this.props.usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas > 0) {
      contentNotificacoesUsuario = (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            EventsManager.pub('NavegarPaginaPainel', { rota: '/suporte/chat' })
          }}
        >
          <Badge
            classes={{ badge: classes.notificationBadge }}
            badgeContent={this.props.usuarioEstadoAtualizado ? this.props.usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas : 0}
            max={9}
          >
            <NotificationsIcon style={{ fontSize: 22 }} />
          </Badge>
        </IconButton>
      )
    }

    let menuItems = []

    if (this.props.negocioSessao && this.props.negocioSessao.carregado) {
      let menuGroupItems = []
      menuItems.push(menuGroupItems)

      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="negocios"
          rota={'/negocios'}
          regexUrl="negocios"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: StoreIcon }}
          primary={this.props.negocioSessao ? this.props.negocioSessao.nome : 'Empresas / Negócios'}
          classes={{
            primary: classes.nomeNegocio
          }}
        />
      )

      menuGroupItems = []
      menuItems.push(menuGroupItems)

      const atendimentoPath = '/atendimentos'
      if (!this.isMenuItemDisabled(atendimentoPath)) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="atendimentos"
            rota={atendimentoPath}
            regexUrl="atendimentos"
            isMenuRetracted={isMenuRetracted}
            icon={{ component: EventIcon }}
            primary="Atendimentos"
          />
        )
      }

      if (!this.isTrial() && !this.isMenuItemDisabled('/whatsapp')) {
        menuGroupItems.push(
          <DrawerMenuItem
            key="whatsapp"
            className="whatsapp"
            alwaysClickable={true}
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_MFE_ADDRESS}/whatsapp`
            }}
          >
            <DrawerMenuItemIcon
              isMenuRetracted={isMenuRetracted}
              icon={{
                component: WhatsAppIcon
              }}
              tooltipTitle="WhatsApp"
            />
            <DrawerMenuItemText
              isMenuRetracted={isMenuRetracted}
              extraMarginRight={true}
              isNew={true}
              primary="WhatsApp"
              classes={{
                primary: classNames(classes.singleLine),
                secondary: classNames(classes.singleLine)
              }}
            />
          </DrawerMenuItem>
        )
      }

      if (!this.isMenuItemDisabled('/vendas')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="vendas"
            rota={'/vendas'}
            regexUrl="vendas"
            isMenuRetracted={isMenuRetracted}
            icon={{
              component: PointOfSaleIcon,
              props: {
                style: {
                  fontSize: '27px',
                  marginLeft: -2
                }
              }
            }}
            primary="Vendas"
          />
        )
      }

      if (!this.isMenuItemDisabled('/notas')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="notas"
            rota={'/notas'}
            regexUrl="notas"
            isMenuRetracted={isMenuRetracted}
            icon={{
              component: ReceiptIcon,
              pprops: {
                style: {
                  fontSize: '27px',
                  marginLeft: -2
                }
              }
            }}
            primary="Comandas"
          />
        )
      }

      if (!this.isMenuItemDisabled('/fluxocaixa')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="fluxocaixa"
            rota={'/fluxocaixa'}
            regexUrl="fluxocaixa"
            isMenuRetracted={isMenuRetracted}
            icon={{ component: AccountBalanceIcon }}
            primary="Fluxo de Caixa"
          />
        )
      }

      if (AtivacaoFluxoCaixaEnum.HABILITADO.id === this.props.negocioSessao.configuracoesGerais.controleFluxoCaixa.id) {
        if (!this.isMenuItemDisabled('/caixas')) {
          menuGroupItems.push(
            <SimpleDrawerMenuItem
              key="caixas"
              rota={'/caixas'}
              regexUrl="caixas"
              isMenuRetracted={isMenuRetracted}
              icon={{ component: PointOfSaleIcon2 }}
              primary="Controle de Caixa"
            />
          )
        }
      }

      if (!this.isMenuItemDisabled('/remuneracoes')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="remuneracoes"
            rota={'/remuneracoes'}
            regexUrl="remuneracoes"
            isMenuRetracted={isMenuRetracted}
            icon={{ component: WorkIcon }}
            primary="Remunerações"
          />
        )
      }

      if (!this.isMenuItemDisabled('/despesas')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem key="despesas" rota={'/despesas'} regexUrl="despesas" isMenuRetracted={isMenuRetracted} icon={{ component: MoneyOffIcon }} primary="Despesas" />
        )
      }

      if (EmissaoNfEnum.DESABILITADO.id === this.props.negocioSessao.configuracoesGerais.emissaoNf.id) {
        if (!this.isMenuItemDisabled('/apresentacaonf')) {
          menuGroupItems.push(
            <SimpleDrawerMenuItem
              key="apresentacaonf"
              isNew={true}
              rota={'/apresentacaonf'}
              regexUrl="apresentacaonf"
              isMenuRetracted={isMenuRetracted}
              icon={{ component: ReceiptLongIcon }}
              primary="Notas Fiscais"
            />
          )
        }
      }

      if (
        EmissaoNfEnum.HABILITADO.id === this.props.negocioSessao.configuracoesGerais.emissaoNf.id &&
        this.props.negocioSessao.configuracaoNf &&
        (this.props.negocioSessao.configuracaoNf.emissaoNfs === true || this.props.negocioSessao.configuracaoNf.emissaoNfc === true)
      ) {
        if (!this.isMenuItemDisabled('/nfs')) {
          menuGroupItems.push(
            <SimpleDrawerMenuItem key="nfs" rota={'/nfs'} regexUrl="nfs" isMenuRetracted={isMenuRetracted} icon={{ component: ReceiptLongIcon }} primary="Notas Fiscais" />
          )
        }
      }

      menuGroupItems = []
      menuItems.push(menuGroupItems)

      if (!this.isMenuItemDisabled('/servicos')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem key="servicos" rota={'/servicos'} regexUrl="servicos" isMenuRetracted={isMenuRetracted} icon={{ component: ListAltIcon }} primary="Serviços" />
        )
      }

      if (!this.isMenuItemDisabled('/inventario')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="inventario"
            rota={'/inventario'}
            regexUrl="inventario"
            isMenuRetracted={isMenuRetracted}
            icon={{
              component: ProductsIcon,
              props: {
                style: {
                  fontSize: '24px',
                  marginLeft: -1,
                  marginTop: -2
                }
              }
            }}
            primary="Produtos"
          />
        )
      }

      if (!this.isMenuItemDisabled('/pacotes')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem key="pacotes" rota={'/pacotes'} regexUrl="pacotes" isMenuRetracted={isMenuRetracted} icon={{ component: ShoppingBasketIcon }} primary="Pacotes" />
        )
      }

      if (!this.isMenuItemDisabled('/clientes')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem key="clientes" rota={'/clientes'} regexUrl="clientes" isMenuRetracted={isMenuRetracted} icon={{ component: PersonIcon }} primary="Clientes" />
        )
      }

      if (!this.isMenuItemDisabled('/colaboradores', { validarApenasPermissoes: true })) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="colaboradores"
            rota={'/colaboradores'}
            regexUrl="colaboradores"
            isMenuRetracted={isMenuRetracted}
            icon={{ component: SupervisedUserCircleIcon }}
            primary="Colaboradores"
          />
        )
      }

      menuGroupItems = []
      menuItems.push(menuGroupItems)

      if (!this.isMenuItemDisabled('/relatorios')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="relatorios"
            rota={'/relatorios'}
            regexUrl="relatorio"
            isMenuRetracted={isMenuRetracted}
            icon={{ component: ShowChartIcon }}
            primary="Relatórios"
          />
        )
      }

      if (!this.isMenuItemDisabled('/agendamentoonline')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="agendamentoonline"
            rota={'/agendamentoonline'}
            regexUrl="agendamentoonline"
            isMenuRetracted={isMenuRetracted}
            icon={{ component: PublicIcon }}
            primary="Agenda Online"
          />
        )
      }

      if (AtivacaoFluxoCaixaEnum.DESABILITADO.id === this.props.negocioSessao.configuracoesGerais.controleFluxoCaixa.id) {
        if (!this.isMenuItemDisabled('/caixas')) {
          menuGroupItems.push(
            <SimpleDrawerMenuItem
              key="caixas"
              rota={'/caixas'}
              regexUrl="caixas"
              isMenuRetracted={isMenuRetracted}
              icon={{ component: PointOfSaleIcon2 }}
              primary="Caixa Avançado"
            />
          )
        }
      }

      if (!this.isMenuItemDisabled('/configuracoes')) {
        menuGroupItems.push(
          <SimpleDrawerMenuItem
            key="configuracoes"
            rota={'/configuracoes'}
            regexUrl="configuracoes"
            isMenuRetracted={isMenuRetracted}
            icon={{ component: SettingsIcon }}
            isNew
            primary="Configurações"
          />
        )
      }

      if (verificarAutorizacao([PA.PODE_VISUALIZAR_E_REALIZAR_PAGAMENTO_PLANO])) {
        if (this.props.negocioSessao !== null && this.props.negocioSessao.excluido === false) {
          menuGroupItems.push(
            <PlanoMenuItem
              isMenuRetracted={isMenuRetracted}
              session={{
                negocio: this.props.negocioSessao,
                usuario: this.props.usuarioSessao
              }}
              classes={{
                primary: classNames(classes.singleLine),
                secondary: classNames(classes.singleLine)
              }}
            />
          )
        }
      }

      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="termoseprivacidade"
          rota={'/termoseprivacidade'}
          regexUrl="termoseprivacidade"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: PolicyIcon }}
          primary={'Termos e Privacidade'}
        />
      )

      menuGroupItems.push(
        <SimpleDrawerMenuItem
          key="centraldeajuda"
          rota={'/centraldeajuda'}
          regexUrl="centraldeajuda"
          isMenuRetracted={isMenuRetracted}
          icon={{ component: HelpOutlineIcon }}
          primary={'Central de ajuda'}
        />
      )

      menuGroupItems = []
      menuItems.push(menuGroupItems)

      menuGroupItems.push(
        <WebAppInstalationButton
          key="configuracoes"
          variant="custom"
          customContent={
            <DrawerMenuItem alwaysClickable={true}>
              <DrawerMenuItemIcon
                isMenuRetracted={isMenuRetracted}
                icon={{
                  component: GetAppIcon
                }}
                tooltipTitle="Instalar Aplicativo"
              />
              <DrawerMenuItemText isMenuRetracted={isMenuRetracted} primary="Instalar Aplicativo" />
            </DrawerMenuItem>
          }
          onClick={() => {
            this.props.changeDrawerMobileOpen(false)
          }}
        />
      )
      menuItems = menuItems.filter((menuGroupItems) => menuGroupItems.length > 0)
    }

    const dataVerificacaoAsMoment = moment(this.props.usuarioSessao.dataVerificacao)

    let cardSolicitacaoTreinamento = null
    if (
      this.props.usuarioSessao.id >= 7032 &&
      (this.props.usuarioSessao.solicitouTreinamento === undefined || this.props.usuarioSessao.solicitouTreinamento === null) &&
      moment().diff(dataVerificacaoAsMoment, 'days') <= MAXIMO_DIAS_MOSTRAR_CARD_SOLICITACAO_TREINAMENTO
    ) {
      const dataUltimoPedidoLembrarDepois = this.usuarioLocalPreferences.getMomentPreference('ExibicaoCardSolicitarTreinamento_DataUltimoPedidoLembrarDepois', null)

      if (!dataUltimoPedidoLembrarDepois || moment().diff(dataUltimoPedidoLembrarDepois, 'hours') >= INTERVALO_HORAS_MOSTRAR_CARD_SOLICITACAO_TREINAMENTO) {
        const onCloseCardTreinamentoMenu = () => {
          this.setState({
            fecharCardTreinamentoAnchorEl: null
          })
        }

        cardSolicitacaoTreinamento = (
          <div className={classes.containerSolicitacaoTreinamento}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body2">Especialista Salão99</Typography>
              </Grid>
              <Grid item style={{ margin: -4 }}>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    this.setState({
                      fecharCardTreinamentoAnchorEl: event.currentTarget
                    })
                  }}
                >
                  <CloseIcon fontSize="small" style={{ display: 'block', fontSize: '1.1rem', color: '#777' }} />
                </IconButton>
              </Grid>
            </Grid>
            <Typography variant="caption" className={classes.containerSolicitacaoTreinamentoDescricao}>
              Solicite gratuitamente um treinamento pelo WhatsApp e domine o sistema em minutos.
            </Typography>
            <div style={{ textAlign: 'left', marginTop: 4, marginLeft: -4, marginBottom: -4 }}>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  window.gtag('event', 'solicitar_treinamento_menu')
                  openBackableDialog(SolicitacaoTreinamentoDialog, {
                    idUsuario: this.props.usuarioSessao.id,
                    onFinish: () => {
                      EventsManager.pub('AtualizarSessao')
                    }
                  })
                }}
              >
                SOLICITAR CONTATO
              </Button>
            </div>
            <Menu anchorEl={this.state.fecharCardTreinamentoAnchorEl} open={Boolean(this.state.fecharCardTreinamentoAnchorEl)} onClose={onCloseCardTreinamentoMenu}>
              <MenuItem
                onClick={() => {
                  window.gtag('event', 'solicitar_treinamento_depois')
                  this.usuarioLocalPreferences.setMomentPreference('ExibicaoCardSolicitarTreinamento_DataUltimoPedidoLembrarDepois', moment(), { type: 'permanent' })
                  onCloseCardTreinamentoMenu()
                }}
              >
                Lembrar depois
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.gtag('event', 'recusar_treinamento')
                  postAPI({
                    url: 'erp/treinamento.recusarTreinamento',
                    requerAutorizacao: true,
                    onSuccess: (response) => {
                      EventsManager.pub('AtualizarSessao')
                    },
                    repeat: true
                  })
                  this.usuarioLocalPreferences.setMomentPreference('ExibicaoCardSolicitarTreinamento_DataUltimoPedidoLembrarDepois', moment(), { type: 'permanent' })
                  onCloseCardTreinamentoMenu()
                }}
              >
                Não exibir mais
              </MenuItem>
            </Menu>
          </div>
        )
      }
    }

    let cardAvaliacaoContent = null

    if (DeviceIdentifier.isNativeApp() && moment().diff(dataVerificacaoAsMoment, 'days') >= MINIMO_DIAS_MOSTRAR_CARD_AVALIACAO_APP) {
      const dataUltimoPedidoLembrarDepois = this.usuarioLocalPreferences.getMomentPreference('ExibicaoCardAvaliacaoApp_DataUltimoPedidoLembrarDepois', null)
      const dataAvaliacaoFeita = this.usuarioLocalPreferences.getMomentPreference('ExibicaoCardAvaliacaoApp_DataAvalicaoFeita', null)

      if (!dataAvaliacaoFeita && (!dataUltimoPedidoLembrarDepois || moment().diff(dataUltimoPedidoLembrarDepois, 'hours') >= INTERVALO_HORAS_MOSTRAR_CARD_AVALIACAO_APP)) {
        let nomeLoja = null
        if (DeviceIdentifier.isNativeAndroid()) {
          nomeLoja = 'Google Play'
        } else if (DeviceIdentifier.isNativeIOS()) {
          nomeLoja = 'App Store'
        }

        const onCloseCardAvaliacaoMenu = () => {
          this.setState({
            fecharCardAvaliacaoAnchorEl: null
          })
        }

        cardAvaliacaoContent = (
          <div className={classes.containerAvaliarAplicativo}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body2">Avalie na {nomeLoja}</Typography>
              </Grid>
              <Grid item style={{ margin: -4 }}>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    this.setState({
                      fecharCardAvaliacaoAnchorEl: event.currentTarget
                    })
                  }}
                >
                  <CloseIcon fontSize="small" style={{ display: 'block', fontSize: '1.1rem', color: '#777' }} />
                </IconButton>
              </Grid>
            </Grid>
            <div>
              <StarRateIcon className={classes.containerAvaliarAplicativoStar} />
              <StarRateIcon className={classes.containerAvaliarAplicativoStar} />
              <StarRateIcon className={classes.containerAvaliarAplicativoStar} />
              <StarRateIcon className={classes.containerAvaliarAplicativoStar} />
              <StarRateIcon className={classes.containerAvaliarAplicativoStar} />
            </div>
            <Typography variant="caption" className={classes.containerAvaliarAplicativoDescricao}>
              Se você está gostando, deixe uma avaliação na {nomeLoja} e nos ajude a alcançar novos usuários.
            </Typography>
            <div style={{ textAlign: 'left', marginTop: 4, marginLeft: -4, marginBottom: -4 }}>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  window.gtag('event', 'avaliar_app')
                  NativeApp.executeNativeMethod({
                    method: 'openStore'
                  })
                }}
              >
                Avaliar Agora
              </Button>
            </div>
            <Menu anchorEl={this.state.fecharCardAvaliacaoAnchorEl} open={Boolean(this.state.fecharCardAvaliacaoAnchorEl)} onClose={onCloseCardAvaliacaoMenu}>
              <MenuItem
                onClick={() => {
                  window.gtag('event', 'avaliar_app_depois')
                  this.usuarioLocalPreferences.setMomentPreference('ExibicaoCardAvaliacaoApp_DataUltimoPedidoLembrarDepois', moment(), { type: 'permanent' })
                  onCloseCardAvaliacaoMenu()
                }}
              >
                Lembrar Depois
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.gtag('event', 'nao_avaliar_app')
                  this.usuarioLocalPreferences.setMomentPreference('ExibicaoCardAvaliacaoApp_DataAvalicaoFeita', moment(), { type: 'permanent' })
                  onCloseCardAvaliacaoMenu()
                }}
              >
                Já Avaliei
              </MenuItem>
            </Menu>
          </div>
        )
      }
    }

    return (
      <React.Fragment>
        <Drawer
          {...others}
          setScrollbarRef={this.setScrollbarRef}
          headerImage={<img src={logo} alt="Salão99 Enterprise" style={{ height: 23, marginLeft: 0, marginTop: 3 }} />}
          fixedTopContent={
            <React.Fragment>
              {cardSolicitacaoTreinamento}
              {cardAvaliacaoContent}

              <List style={{ padding: '8px 0px' }}>
                <DrawerMenuItem alwaysClickable={true} onClick={this.handleContaMenuClick} className="pagamento-recorrente">
                  <DrawerMenuItemIcon
                    isMenuRetracted={isMenuRetracted}
                    icon={{
                      component: AccountCircleIcon
                    }}
                    tooltipTitle={this.props.usuarioSessao.nome}
                  />
                  <DrawerMenuItemText
                    isMenuRetracted={isMenuRetracted}
                    extraMarginRight={true}
                    primary={this.props.usuarioSessao.nome}
                    classes={{
                      primary: classNames(classes.singleLine),
                      secondary: classNames(classes.singleLine)
                    }}
                  />
                </DrawerMenuItem>

                <Menu id="conta-menu" anchorEl={this.state.anchorContaMenu} open={Boolean(this.state.anchorContaMenu)} onClose={this.handleContaMenuClose}>
                  <MenuItem
                    onClick={() => {
                      this.handleContaMenuClose(true)
                      EventsManager.pub('NavegarPaginaPainel', { rota: '/minhaconta' })
                    }}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Minha conta" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.handleContaMenuClose()
                      this.props.handleLogout()
                    }}
                  >
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                  </MenuItem>
                </Menu>

                <DrawerMenuItem rota={'/suporte/chat'} regexUrl="suporte/chat" rightContent={contentNotificacoesUsuario}>
                  <DrawerMenuItemIcon
                    isMenuRetracted={isMenuRetracted}
                    icon={{
                      component: IconeSuporte
                    }}
                    tooltipTitle="Suporte"
                  />
                  <DrawerMenuItemText isMenuRetracted={isMenuRetracted} extraMarginRight={true} primary="Suporte" />
                </DrawerMenuItem>
              </List>
            </React.Fragment>
          }
        >
          {menuItems.length === 0 && <DrawerMenuDivider />}

          {menuItems.length > 0 &&
            menuItems.map((menuGroupItems, index) => (
              <React.Fragment key={index}>
                <List>{menuGroupItems}</List>
                <DrawerMenuDivider />
              </React.Fragment>
            ))}
        </Drawer>
        <Wizard
          anchorElementClass="whatsapp"
          shouldBeVisible={currentWizard === 'whatsapp'}
          tagText="NOVO!"
          heading="Envie mensagens automáticas para seus clientes via WhatsApp"
          description="A funcionalidade te ajuda a se organizar e economizar tempo."
          actionButtonText="Acessar"
          onContinue={() => (window.location.href = `${process.env.REACT_APP_MFE_ADDRESS}/whatsapp`)}
          scrollbar={this.scrollbarRef}
          tooltipDirection="up"
        />

        <Wizard
          anchorElementClass="pagamento-recorrente"
          shouldBeVisible={currentWizard === 'pagamentoRecorrente'}
          tagText="NOVO!"
          heading="Novas opções de pagamento com renovação automática"
          description="Com o pagamento de renovação automática o débito é feito de forma recorrente, facilitando a sua rotina."
          actionButtonText="Acessar"
          onContinue={() => {
            EventsManager.pub('NavegarPaginaPainel', { rota: '/minhaconta' })
            this.setState({ currentWizard: '' })
          }}
          scrollbar={this.scrollbarRef}
          tooltipDirection="up"
        />
      </React.Fragment>
    )
  }
}

DrawerErp.propTypes = {
  classes: PropTypes.object.isRequired,
  negocioSessao: PropTypes.object,
  usuarioSessao: PropTypes.object,
  getValue: PropTypes.func
}

export default withStyles(styles)(withWidth()(withConfigCatClient(DrawerErp)))
