import { converterHorarioIntParaMinutos } from 'support/util/TimeConverter'

export function formatarDuracao(duracaoEmMinutos, opts) {
  if (opts === undefined) {
    opts = {}
  }

  const horas = parseInt(duracaoEmMinutos / 60, 10)
  const minutos = parseInt(duracaoEmMinutos % 60, 10)

  let stringDuracao = ''

  if (!opts.descricao || opts.descricao === 'completa') {
    if (horas > 0) {
      stringDuracao += horas + ' hora' + (horas > 1 ? 's' : '') + (minutos > 0 ? ' e ' : '')
    }
    if (minutos > 0) {
      stringDuracao += minutos + ' minuto' + (minutos > 1 ? 's' : '')
    }
  } else if (opts.descricao === 'resumida') {
    if (horas > 0) {
      stringDuracao += horas + 'h'
    }
    if (minutos > 0) {
      stringDuracao += minutos + 'min'
    }
  } else if (opts.descricao === 'minificada') {
    if (horas > 0) {
      stringDuracao += horas + 'h'
    }
    if (minutos > 0) {
      stringDuracao += minutos + 'm'
    }
  }

  return stringDuracao
}

export function formatarHorarioInt(horarioInt) {
  if (horarioInt > 2400) {
    return formatarHorarioMinutos(converterHorarioIntParaMinutos(horarioInt) - converterHorarioIntParaMinutos(2400))
  } else {
    return (
      parseInt(horarioInt / 100, 10)
        .toString()
        .padStart(2, '0') +
      ':' +
      parseInt(horarioInt % 100, 10)
        .toString()
        .padStart(2, '0')
    )
  }
}

export function formatarHorarioMinutos(horarioEmMinutos) {
  return (
    parseInt(horarioEmMinutos / 60, 10)
      .toString()
      .padStart(2, '0') +
    ':' +
    parseInt(horarioEmMinutos % 60, 10)
      .toString()
      .padStart(2, '0')
  )
}
