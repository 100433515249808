import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons
})

class AlteracaoValorReferenciaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Alterar Valor Unitário',
      clientePacoteItem: this.props.clientePacoteItem,
      errorsMap: createErrorsMap(),
      ajaxing: false,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }

    this.inputValorReferencia = React.createRef()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/clientes.alterarValorReferenciaPacoteItem',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.setShouldCloseParent(true)
        this.props.handleCloseDialog()
        EventsManager.pub('ManutencaoPacote', { cliente: response.data })
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formValorReferencia')
      }
    })
  }

  getDados = () => {
    const dados = {
      id: this.state.clientePacoteItem.id,
      valorReferencia: FormUtil.convertStringToNumber(this.inputValorReferencia.current.value)
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { clientePacoteItem, classes } = this.props
    const { errorsMap } = this.state
    let nomeServico = null
    let valorReferenciaDescricao = null

    if (clientePacoteItem) {
      nomeServico = clientePacoteItem.servico.nome
      if (clientePacoteItem.variacaoServico) {
        nomeServico += ' - ' + clientePacoteItem.variacaoServico.nome
      }

      if (clientePacoteItem.valorReferencia) {
        valorReferenciaDescricao = formatarValorMonetario(clientePacoteItem.valorReferencia)
      } else {
        valorReferenciaDescricao = 'Não informado'
      }
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <form id="formValorReferencia" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
          <Typography variant="body2" style={{ marginBottom: 1 }}>
            <b>Serviço:</b> {nomeServico}
          </Typography>
          {valorReferenciaDescricao && (
            <Typography variant="body2" style={{ marginTop: 2, marginBottom: 1 }}>
              <b>Valor Unitário:</b> {valorReferenciaDescricao}
            </Typography>
          )}

          <InputText
            marginTop={true}
            shrink={false}
            customVariant="outlined-small"
            idname="xvalorReferencia"
            disabled={this.state.ajaxing}
            label="Novo Valor Unitário (R$)"
            inputComponent={InputMoneyFormat}
            inputRef={this.inputValorReferencia}
            errorMessage={errorsMap('valorReferencia')}
            endAdornment={
              <HelpOutlineIcon
                style={{ opacity: 0.5, padding: 8, margin: '-8px 0px -8px -8px', cursor: 'pointer' }}
                onClick={(event) => {
                  event.stopPropagation()
                  openBackableDialog(MessageDialog, {
                    text: (
                      <span className={classes.textPrimary}>
                        O Valor Unitário é utilizado para modificar o preço padrão unitário do serviço dentro de um pacote. Exemplo:
                        <br />
                        <br />
                        <span className={classes.textSecondary} style={{ fontSize: 14 }}>
                          - Serviço de manicure custa por padrão R$ 30,00;
                          <br />- O pacote com 10 serviços de manicure custa R$ 250,00;
                        </span>
                        <br />
                        <br />
                        Neste caso, no pacote com 10 serviços de manicure, cada serviço custa R$ 25,00. Então, para configurar esse pacote, preencha:
                        <br />
                        <br />
                        <span className={classes.textSecondary} style={{ fontSize: 14 }}>
                          <b>Preço do pacote:</b> R$ 250,00
                          <br />
                          <Grid container style={{ marginTop: 4 }}>
                            <Grid item>
                              <div style={{ height: '100%', width: 4, backgroundColor: '#aaa', marginRight: 6 }}></div>
                            </Grid>
                            <Grid item xs>
                              <b>Serviço:</b> Manicure
                              <br />
                              <b>Preço Unitário:</b> R$ 25,00 <b>Qtd:</b> 10
                            </Grid>
                          </Grid>
                        </span>
                      </span>
                    )
                  })
                }}
              />
            }
            staticHelpMessage={
              <span>
                Para o sistema considerar o valor padrão deste serviço, deixe o <b>Novo Valor Unitário</b> em branco
              </span>
            }
          />

          <HiddenSubmitButton />

          {
            <div className={classes.containerFooterButtons}>
              <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                Cancelar
              </Button>
              <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
              </Button>
            </div>
          }
        </form>
      </DialogPage>
    )
  }
}

AlteracaoValorReferenciaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AlteracaoValorReferenciaDialogPage)
