import { roundNumber } from 'support/util/NumberUtil'

export function formatarMetrosEmDistanciaAproximada(metros) {
  if (metros < 1000) {
    let metrosArredondados = roundNumber(metros / 50, 0) * 50
    if (metrosArredondados <= 0) {
      metrosArredondados = 50
    }
    return metrosArredondados + 'm'
  } else {
    return roundNumber(metros / 1000, 1) + 'km'
  }
}
