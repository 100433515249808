import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import NegocioService from 'support/domain/negocio/NegocioService'
import FormUtil, { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  urlPageView: {
    color: theme.palette.secondary.main
  }
})

const INPUT_MAX_WIDTH = 380

class RastreamentoDialogPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Configurações',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputIdAcompanhamentoGa = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/negocios.buscarConfiguracoesAgendamentoOnline',
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const { negocio, configuracoesGerais } = response.data
        this.setState({
          negocio: negocio,
          configuracoesGerais: configuracoesGerais
        })
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = {
      idAcompanhamentoGa: this.inputIdAcompanhamentoGa.current.value
    }

    postAPI({
      url: 'erp/negocios.alterarConfiguracoesGerais',
      data: dados,
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState({
          ajaxing: false,
          errorsMap: createErrorsMap(response.data.errors)
        })
        focusFirstElementWithError('formConfiguracoesRastreamento')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { negocio, configuracoesGerais, errorsMap, ajaxing } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={640}
        pageType="basicEntity"
        ajaxing={ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => {
            let basePageView = NegocioService.gerarUrlAgendamentoOnline(negocio, configuracoesGerais.sufixoUrlPortal)

            return (
              <form id="formConfiguracoesRastreamento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                <InputText
                  marginTop={true}
                  idname="xidAcompanhamentoGa"
                  style={{ mmaxWidth: INPUT_MAX_WIDTH }}
                  fullWidth={true}
                  label="Id de Acompanhamento ou Métrica"
                  customVariant="outlined-small"
                  inputRef={this.inputIdAcompanhamentoGa}
                  defaultValue={configuracoesGerais.idAcompanhamentoGa}
                  errorMessage={errorsMap('idAcompanhamentoGa')}
                  staticHelpMessage={
                    <span>
                      Preencha o campo com o Id de Acompanhamento (exemplo: UA-99962762-1) da sua conta do Google Analytics ou o Id da Métrica (exemplo: G-XH9BC2EYNB) do Google
                      Analytics V4.
                    </span>
                  }
                  disabled={ajaxing}
                  maxLength="45"
                />

                <Typography variant="body1" style={{ fontWeight: 500, marginTop: 24 }}>
                  Fluxo de pageviews monitoradas:
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  1. Entrar na página do perfil gera 1 pageview para <b className={classes.urlPageView}>{basePageView}</b>
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  2. Abrir galeria de fotos gera 1 pageview para <b className={classes.urlPageView}>{basePageView}/galeria-fotos</b>
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  3. Abrir informações gera 1 pageview para <b className={classes.urlPageView}>{basePageView}/informacoes-detalhadas</b>
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  4. Abrir avaliações gera 1 pageview para <b className={classes.urlPageView}>{basePageView}/avaliacoes</b>
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  5. Favoritar gera 1 pageview para <b className={classes.urlPageView}>{basePageView}/favoritado</b>
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  6. Iniciar agendamento de serviços gera 1 pageview para <b className={classes.urlPageView}>{basePageView}/agendamento-iniciado</b>
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  7. Concluir agendamento de serviços gera 1 pageview para <b className={classes.urlPageView}>{basePageView}/agendamento-concluido</b>
                </Typography>
                <br />
                <br />

                {this.state.paginaCarregada && (
                  <div className={classes.containerFooterButtons}>
                    <Button disabled={ajaxing} onClick={this.props.handleCloseDialog}>
                      Cancelar
                    </Button>
                    <Button disabled={ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                      {ajaxing ? 'Aguarde' : 'Salvar'}
                    </Button>
                  </div>
                )}

                <FormUtil.HiddenSubmitButton />
              </form>
            )
          }}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

RastreamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RastreamentoDialogPage)
