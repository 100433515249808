import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import BlockIcon from '@material-ui/icons/Block'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import BloqueioHorarioDialogPage from 'pages/erp/painel/colaborador/BloqueioHorarioDialogPage'
import PropTypes from 'prop-types'
import { extractPopupProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import BasePopover from 'support/components/popover/BasePopover'
import FacilitadorRemocaoRepeticao from 'support/components/repeticao/FacilitadorRemocaoRepeticao'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  popoverPaper: {
    ...theme.util.createBoxShadow('0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)'),
    borderRadius: 32
  },
  iconSection: {
    display: 'block',
    fontSize: 24,
    color: '#656565'
  }
})

class BloqueioHorarioPopup extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      bloqueioHorario: this.props.bloqueioHorario
    }

    this.facilitadorRemocaoRepeticaoFunctionMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoBloqueioHorario', (props) => {
      if (props.action === 'update' || props.action === 'create') {
        this.setState({ bloqueioHorario: props.bloqueioHorario })
      }
    })

    /*this.eventsManager.sub("RemocaoBloqueioHorario",(props) => {
			const {bloqueioHorario} = this.state;
			if(bloqueioHorario && bloqueioHorario.id === props.bloqueioHorario.id){
				this.props.handleCloseDialog();
			}
		});*/
  }

  render() {
    const popupProps = extractPopupProps(this.props)
    const { classes } = this.props
    const { bloqueioHorario } = this.state

    let dataBloqueioHorarioAsMoment = converterDataIntParaMoment(bloqueioHorario.data)
    let descricaoDataHorario

    let formatoExibicaoData = 'ddd, DD/MMM'
    if (isWidthUp('sm', this.props.width)) {
      formatoExibicaoData = 'dddd, DD [de ] MMMM'
    }
    descricaoDataHorario = (
      <span>
        {dataBloqueioHorarioAsMoment.format(formatoExibicaoData)} &#8226; {formatarHorarioInt(bloqueioHorario.horarioInicio)} até{' '}
        {formatarHorarioInt(bloqueioHorario.horarioTermino)}
      </span>
    )

    let IconComponent = BlockIcon
    if (bloqueioHorario.grupo && bloqueioHorario.grupo.id) {
      IconComponent = EventBusyIcon
    }

    return (
      <BasePopover
        {...popupProps}
        classes={{
          popoverPaper: classes.popoverPaper
        }}
        topIconButtons={[
          {
            tooltip: 'Editar',
            icon: EditIcon,
            onClick: () => {
              if (bloqueioHorario.grupo && bloqueioHorario.grupo.id) {
                openBackableDialog(MessageDialog, {
                  parent: this,
                  shouldCloseParent: true,
                  text: (
                    <span>
                      Esse bloqueio de horário foi cadastrado pelo menu <b>Configurações</b>, na opção <b>Indisponibilidades de Horários</b>. A alteração deste bloqueio deve ser
                      realizada através da mesma opção, ou seja, acessando o menu <b>Configurações</b> e depois <b>Indisponibilidades de Horários</b>.
                    </span>
                  )
                })
              } else {
                openBackableDialog(BloqueioHorarioDialogPage, {
                  parent: this,
                  idBloqueioHorario: bloqueioHorario.id
                })
              }
            }
          },
          {
            tooltip: 'Remover Bloqueio',
            icon: DeleteIcon,
            onClick: (event) => {
              if (bloqueioHorario.grupo && bloqueioHorario.grupo.id) {
                openBackableDialog(MessageDialog, {
                  parent: this,
                  shouldCloseParent: true,
                  text: (
                    <span>
                      Esse bloqueio de horário foi cadastrado pelo menu <b>Configurações</b>, na opção <b>Indisponibilidades de Horários</b>. A remoção deste bloqueio deve ser
                      realizada através da mesma opção, ou seja, acessando o menu <b>Configurações</b> e depois <b>Indisponibilidades de Horários</b>.
                    </span>
                  )
                })
              } else {
                this.facilitadorRemocaoRepeticaoFunctionMap.remover({
                  abrirMenu: bloqueioHorario.repeticao && bloqueioHorario.repeticao.id ? true : false,
                  event: event,
                  call: {
                    endpoint: 'erp/colaboradores.removerBloqueioHorario',
                    data: {
                      id: bloqueioHorario.id
                    },
                    onSuccess: (response, dialogConfirmationInstance) => {
                      dialogConfirmationInstance.props.setShouldCloseParent(true)
                      EventsManager.pub('RemocaoBloqueioHorario', { bloqueioHorario: { id: bloqueioHorario.id } })
                    }
                  },
                  dialog: {
                    parent: this,
                    title: 'Remover Bloqueio',
                    itemDescription: <span>este bloqueio</span>
                  }
                })
              }
            }
          }
        ]}
        body={
          <Grid container alignItems="center" style={{ marginBottom: 24 }}>
            <Grid item style={{ marginRight: 16 }}>
              <IconComponent className={classes.iconSection} />
            </Grid>
            <Grid item xs>
              <Typography variant="body1" style={{ fontSize: 20 }}>
                {bloqueioHorario.colaborador.apelido}
              </Typography>
              <Typography variant="body2" style={{ marginTop: 1 }}>
                {descricaoDataHorario}
              </Typography>
              <Typography variant="body2" style={{ marginTop: 2 }}>
                {bloqueioHorario.descricao}
              </Typography>
            </Grid>
            <FacilitadorRemocaoRepeticao key="FacilitadorRemocaoRepeticao" functionsMap={this.facilitadorRemocaoRepeticaoFunctionMap} />
          </Grid>
        }
      />
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

BloqueioHorarioPopup.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(BloqueioHorarioPopup))
