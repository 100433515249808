import React from 'react'

import moment from 'moment'

export function IndicadorVariacao(props) {
  if (props.valor > 0) {
    return <span style={{ color: '#22c56b' }}>{'\u25B2'}</span>
  } else if (props.valor < 0) {
    return <span style={{ color: '#ff6a6a' }}>{'\u25BC'}</span>
  }
  return null
}

export function ValorComVariacao(props) {
  if (props.valorReferenciaVariacao > 0) {
    return <span style={{ color: '#22c56b' }}>{props.children}</span>
  } else if (props.valorReferenciaVariacao < 0) {
    return <span style={{ color: '#ff6a6a' }}>{props.children}</span>
  }
  return props.children
}

export function getPercentualRestanteMes() {
  const agora = moment()
  return 100 - (agora.date() / agora.daysInMonth()) * 100
}

export function calcularVariacao(novo, antigo) {
  if (novo === 0) {
    if (antigo > 0) {
      return -100 + '%'
    } else {
      return ''
    }
  } else if (antigo === 0) {
    return 'new'
  } else {
    return ((novo / antigo - 1) * 100).toFixed(0) + '%'
  }
}
