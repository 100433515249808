import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import EventIcon from '@material-ui/icons/Event'
import LabelIcon from '@material-ui/icons/Label'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import PersonIcon from '@material-ui/icons/Person'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import InputCaixa from 'pages/erp/painel/caixa/input/InputCaixa'
import ClienteInputDialogSelect from 'pages/erp/painel/cliente/input/ClienteInputDialogSelect'
import RedirecionadorFluxoPosCadastroVendaDialog from 'pages/erp/painel/cliente/RedirecionadorFluxoPosCadastroVendaDialog'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import InputFormaPagamento from 'pages/erp/painel/formapagamento/input/InputFormaPagamento'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import CaixaService from 'support/domain/caixa/CaixaService'
import OrigemCreditoEnum from 'support/domain/cliente/OrigemCreditoEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterTudoParaMoment } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const INPUT_CUSTOM_VARIANT = 'outlined-small'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class VendaCreditoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    let colaborador = props.colaboradorPreSelecionado

    if (
      colaborador &&
      colaborador.realizaAtendimentos !== undefined &&
      colaborador.realizaAtendimentos === false &&
      colaborador.vendeProdutos !== undefined &&
      colaborador.vendeProdutos === false
    ) {
      colaborador = null
    }

    if (!colaborador) {
      const colaboradorSessao = getSessaoPainel().colaborador
      if (colaboradorSessao && (colaboradorSessao.realizaAtendimentos || colaboradorSessao.vendeProdutos)) {
        colaborador = colaboradorSessao
      }
    }

    this.state = {
      paginaCarregada: false,
      editavel: true,
      mensagemNaoEditavel: '',
      data: converterTudoParaMoment(props.data),
      cliente: props.cliente,
      colaborador: colaborador,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputValor = React.createRef()
    this.inputValorFunctionsMap = {}
    this.clienteFunctionsMap = {}
    this.dataFunctionsMap = {}
    this.colaboradorFunctionsMap = {}
    this.formaPagamentoFunctionMap = {}
    this.inputCaixaFunctionsMap = {}
    this.inputObservacoes = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    const params = {}
    if (this.props.origem && this.props.origem.id === OrigemCreditoEnum.VENDA.id) {
      params.idVendaCredito = this.props.idVendaCredito
    } else if (this.props.origem && this.props.origem.id === OrigemCreditoEnum.OUTROS.id) {
      params.idManipulacaoDeliberadaSaldo = this.props.idManipulacaoDeliberadaSaldo
    }

    getAPI({
      url: 'erp/clientes.buscarDadosCreditoPrePersistencia',
      params: params,
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const newState = {
          paginaCarregada: true,
          editavel: true,
          origem: this.props.origem ? this.props.origem : OrigemCreditoEnum.VENDA,
          caixas: response.data.caixas
        }

        const vendaCredito = response.data.vendaCredito

        if (vendaCredito && vendaCredito.id) {
          newState.vendaCredito = vendaCredito
          newState.data = converterTudoParaMoment(vendaCredito.data)
          newState.cliente = vendaCredito.venda.cliente
          newState.colaborador = vendaCredito.venda.colaborador
          newState.preco = vendaCredito.venda.valorUnitario
          newState.formaPagamento = vendaCredito.venda.notaVenda.pagamentos[0]

          if (vendaCredito.venda.processada === true) {
            if (vendaCredito.venda.cancelado === true) {
              newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Esta venda está cancelada.'
            } else {
              newState.mensagemNaoEditavel =
                'Não é mais possível alterar esta venda porque a fatura #' + vendaCredito.venda.notaVenda.numero + ' já foi gerada. É possível apenas cancelar esta venda.'
            }
          } else if (vendaCredito.venda.excluido === true) {
            newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Este venda foi removida.'
          }

          if (newState.mensagemNaoEditavel) {
            newState.editavel = false
          }
        }

        newState.toolbarActions = {
          actions: [
            {
              label: 'Salvar',
              show: newState.editavel,
              handleAction: () => this.salvar()
            },
            {
              label: 'Voltar',
              show: !newState.editavel,
              handleAction: () => this.props.handleCloseDialog()
            }
          ]
        }

        this.setState(newState)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            showOnlyMobile: true,
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  getDados = () => {
    let dados = {}
    const formaPagamentoDados = this.formaPagamentoFunctionMap.getValues()

    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.cliente = this.state.cliente
    dados.colaborador = null
    const colaboradorObject = this.colaboradorFunctionsMap.getObject()

    if (colaboradorObject && colaboradorObject.colaborador) {
      dados.colaborador = colaboradorObject.colaborador
    }

    dados.valor = FormUtil.convertStringToNumber(this.inputValor.current.value)

    if (this.state.origem.id === OrigemCreditoEnum.VENDA.id) {
      dados.formaPagamento = formaPagamentoDados.formaPagamento
      dados.adquirente = formaPagamentoDados.adquirente
      dados.bandeira = formaPagamentoDados.bandeira
      dados.numeroParcelas = formaPagamentoDados.numeroParcelas
      if (this.inputCaixaFunctionsMap.getCaixa) {
        dados.caixa = this.inputCaixaFunctionsMap.getCaixa()
      }
    } else {
      dados.observacoes = this.inputObservacoes.current.value
    }

    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    if (!this.state.editavel) {
      openBackableDialog(MessageDialog, {
        title: 'Aviso',
        text: this.state.mensagemNaoEditavel
      })
      return
    }

    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    let endpoint = ''

    if (this.state.origem.id === OrigemCreditoEnum.VENDA.id) {
      endpoint = 'erp/clientes.criarVendaCredito'
    } else {
      endpoint = 'erp/clientes.criarManipulacaoDeliberadaSaldo'
    }

    postAPI({
      url: endpoint,
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoVenda', {
          action: 'create'
        })
        EventsManager.pub('AlteracaoSaldoCliente')

        if (this.props.contexto === 'CriacaoPainelCliente') {
          openBackableDialog(RedirecionadorFluxoPosCadastroVendaDialog, {
            parent: this,
            shouldCloseParent: true,
            textoConfirmacaoCadastro: 'Venda cadastrada com sucesso!',
            tipoVenda: TipoVendaEnum.VENDA_CREDITO,
            setFecharPanelCliente: this.props.setFecharPanelCliente
              ? this.props.setFecharPanelCliente
              : () => {
                  this.props.setShouldCloseParent(true)
                }
          })
        } else {
          if (this.props.parent) {
            this.props.setShouldCloseParent(true)
          }
          this.props.handleCloseDialog()
        }
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formVendaCredito')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { data, cliente, editavel, ajaxing, errorsMap, origem, caixas, observacoes } = this.state

    const { formaPagamento, adquirente, bandeira, numeroParcelas, caixa } = this.state.formaPagamento ? this.state.formaPagamento : {}

    let colaboradorDefaultValue = null

    if (this.state.colaborador) {
      colaboradorDefaultValue = this.state.colaborador
    }

    let precoDefaultValue = this.state.preco
    let dataPagamentoDefaultAsMoment = data

    let formaPagamentoDefaultValue
    if (formaPagamento && formaPagamento.tipo) {
      formaPagamentoDefaultValue = {
        formaPagamento: formaPagamento,
        adquirente: adquirente,
        bandeira: bandeira,
        numeroParcelas: numeroParcelas
      }
    }

    return (
      <DialogPage {...dialogProps} title={'Crédito'} align="center" contentMaxWidth={570} pageType="basicForm" ajaxing={ajaxing} toolbarActions={this.state.toolbarActions}>
        {this.state.mensagemNaoEditavel && (
          <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
            <Typography variant="body2">{this.state.mensagemNaoEditavel}</Typography>
          </FloatContent>
        )}

        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formVendaCredito" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: -10 }}
                icon={<PersonIcon />}
                inputTexts={[
                  {
                    input: (
                      <ClienteInputDialogSelect
                        key={'cliente'}
                        disabled={!editavel || ajaxing}
                        defaultValue={cliente}
                        functionsMap={this.clienteFunctionsMap}
                        label="Cliente"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('cliente')}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ cliente: value.cliente })
                          } else {
                            this.setState({ cliente: null })
                          }
                        }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<EventIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputData
                        disabled={!editavel || ajaxing}
                        functionsMap={this.dataFunctionsMap}
                        keyboard={true}
                        id="xdata"
                        name="xdata"
                        errorMessage={errorsMap('data')}
                        defaultValue={data}
                        onChange={(data) => {
                          const dataAsMoment = converterTudoParaMoment(data)
                          this.setState({ data: dataAsMoment })
                          if (this.inputCaixaFunctionsMap.setData) {
                            this.inputCaixaFunctionsMap.setData(dataAsMoment)
                          }
                        }}
                        label={'Data'}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<SupervisedUserCircleIcon />}
                inputTexts={[
                  {
                    input: (
                      <ColaboradorInputDialogSelect
                        context="vendaCredito"
                        disabled={!editavel || ajaxing}
                        defaultValue={colaboradorDefaultValue}
                        functionsMap={this.colaboradorFunctionsMap}
                        label="Profissional"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('colaborador')}
                        DialogSelectProps={{
                          preProcessItemFunction: (colaborador) => {
                            if (colaborador.realizaAtendimentos === true || colaborador.vendeProdutos === true) {
                              return colaborador
                            }
                            return null
                          }
                        }}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ colaborador: value.colaborador })
                          } else {
                            this.setState({ colaborador: null })
                          }
                        }}
                        style={{ marginTop: 0 }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<AttachMoneyIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        disabled={!editavel || ajaxing}
                        idname="xvalor"
                        defaultValue={precoDefaultValue}
                        errorMessage={errorsMap('valor')}
                        label="Valor Crédito (R$)"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        inputComponent={InputMoneyFormat}
                        inputRef={this.inputValor}
                        functionsMap={this.inputValorFunctionsMap}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<LabelOutlinedIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputSelect
                        disabled={!editavel || ajaxing}
                        idname="xorigem"
                        value={origem.id}
                        errorMessage={errorsMap('origem')}
                        label="Origem"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        onChange={(event) => {
                          const origem = OrigemCreditoEnum.getById(parseInt(event.target.value, 10))
                          this.setState({ origem: origem })
                        }}
                      >
                        {OrigemCreditoEnum.values().map((origem) => (
                          <MenuItem key={origem.id} value={origem.id}>
                            {origem.descricao}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    )
                  }
                ]}
              />

              {origem.id === OrigemCreditoEnum.VENDA.id && (
                <React.Fragment>
                  <InputHorizontalContainer
                    customVariant={INPUT_CUSTOM_VARIANT}
                    style={{ marginTop: 6 }}
                    icon={<CreditCardIcon />}
                    inputTexts={[
                      {
                        input: (
                          <InputFormaPagamento
                            disabled={!editavel || ajaxing}
                            idname="xformaPagamento"
                            customVariant={INPUT_CUSTOM_VARIANT}
                            defaultValue={formaPagamentoDefaultValue}
                            shrink={false}
                            errorMessage={errorsMap('formaPagamento')}
                            label="Forma de Pagamento"
                            functionsMap={this.formaPagamentoFunctionMap}
                            onChange={(pagamento) => {
                              const newState = {
                                formaPagamento: null
                              }
                              if (pagamento) {
                                if (pagamento.formaPagamento && pagamento.formaPagamento.tipo && pagamento.formaPagamento.tipo.id) {
                                  newState.formaPagamento = pagamento.formaPagamento
                                }
                              }
                              this.setState(newState)
                            }}
                          />
                        )
                      }
                    ]}
                  />

                  {CaixaService.isHabilitado() && (
                    <InputHorizontalContainer
                      customVariant={INPUT_CUSTOM_VARIANT}
                      style={{ marginTop: 6 }}
                      icon={<AccountBalanceIcon />}
                      inputTexts={[
                        {
                          input: (
                            <InputCaixa
                              utilizacaoObrigatoria={true}
                              disabled={!editavel || ajaxing}
                              customVariant={INPUT_CUSTOM_VARIANT}
                              shrink={false}
                              data={dataPagamentoDefaultAsMoment}
                              colaboradorPreferencial={getSessaoPainel().colaborador}
                              caixaDefaultValue={caixa}
                              caixas={caixas}
                              errorsMap={errorsMap}
                              descricaoData="Data do pagamento"
                              functionsMap={this.inputCaixaFunctionsMap}
                            />
                          )
                        }
                      ]}
                    />
                  )}
                </React.Fragment>
              )}
              {origem.id === OrigemCreditoEnum.OUTROS.id && (
                <React.Fragment>
                  <InputHorizontalContainer
                    customVariant={INPUT_CUSTOM_VARIANT}
                    style={{ marginTop: 6 }}
                    icon={<LabelIcon />}
                    inputTexts={[
                      {
                        input: (
                          <InputText
                            disabled={ajaxing || !editavel}
                            id="xobservacoes"
                            name="xobservacoes"
                            multiline={true}
                            defaultValue={observacoes}
                            customVariant={INPUT_CUSTOM_VARIANT}
                            shrink={false}
                            label="Observações"
                            inputRef={this.inputObservacoes}
                          />
                        )
                      }
                    ]}
                  />
                </React.Fragment>
              )}

              <FormUtil.HiddenSubmitButton />

              {this.state.paginaCarregada && editavel && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={ajaxing} onClick={this.props.handleCloseDialog}>
                    Voltar
                  </Button>
                  <Button disabled={ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

VendaCreditoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendaCreditoDialogPage)
