import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PublicIcon from '@material-ui/icons/Public'
import ColaboradorDadosBasicosDialogPage from 'pages/erp/painel/colaborador/ColaboradorDadosBasicosDialogPage'
import ColaboradorPhotoPicker from 'pages/erp/painel/colaborador/picker/ColaboradorPhotoPicker'
import TabAtividades from 'pages/erp/painel/colaborador/TabAtividades'
import TabConfiguracoes from 'pages/erp/painel/colaborador/TabConfiguracoes'
import TabPerfil from 'pages/erp/painel/colaborador/TabPerfil'
import TabSalarios from 'pages/erp/painel/colaborador/TabSalarios'
import TabVales from 'pages/erp/painel/colaborador/TabVales'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import Subject from 'support/components/page/subject/Subject'
import Tab from 'support/components/tab/Tab'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import ImageUtil from 'support/util/ImageUtil'

const MAX_WIDTH_PAPER_DEFAULT = 680
const MAX_WIDTH_PAPER_TAB_VENDAS = 1240

const styles = (theme) => ({})

class ColaboradorDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
    this.cropperRef = React.createRef()

    this.state = {
      colaborador: null,
      title: 'Colaborador',
      tabValue: 0,
      ajaxing: false
    }

    this.functionMapUpload = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoColaborador', (props) => {
      if (props !== undefined && props.colaborador !== undefined) {
        this.setState({ colaborador: props.colaborador })
      } else {
        this.contentWithPreloadFunctions.reload()
      }
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/colaboradores.buscarPorId',
      params: {
        id: this.props.idColaborador
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const toolbarActions = {
          desktopItemsShow: 1,
          mobileItemsShow: 1,
          actions: [
            {
              label: 'Editar',
              handleAction: this.handlerEditar
            }
          ]
        }

        this.setState({
          colaborador: response.data,
          toolbarActions: toolbarActions
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handlerEditar = () => {
    if (!verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(ColaboradorDadosBasicosDialogPage, { idColaborador: this.state.colaborador.id })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { colaborador, tabValue } = this.state

    let contentMaxWidth = MAX_WIDTH_PAPER_DEFAULT
    if (tabValue === 2) {
      contentMaxWidth = MAX_WIDTH_PAPER_TAB_VENDAS
    }

    let emailUsuario = null

    if (colaborador !== null && colaborador.usuario !== undefined && colaborador.usuario.email.length > 0) {
      emailUsuario = {
        type: 'text',
        wordBreakAll: true,
        text: colaborador.usuario.email
      }
    } else {
      emailUsuario = <span style={{ color: '#999' }}>E-mail não cadastrado</span>
    }

    let variacoesFoto = null
    if (colaborador) {
      variacoesFoto = ImageUtil.extrairVariacoesImagem(colaborador.foto)
    }

    let statusAgendamentoOnline = null
    if (
      colaborador != null &&
      getSessaoPainel().negocio.carregado &&
      ControleAgendamentoOnlineEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleAgendamentoOnline.id
    ) {
      if (colaborador.controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.HABILITADO.id) {
        statusAgendamentoOnline = (
          <Grid container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 6 }}>
              <PublicIcon style={{ display: 'block' }} fontSize="small" color="secondary" />
            </Grid>
            <Grid item>
              <Typography variant="body1" color="secondary">
                Agendamento Online Habilitado
              </Typography>
            </Grid>
          </Grid>
        )
      } else {
        statusAgendamentoOnline = (
          <Grid container alignItems="center" style={{ marginTop: 4, opacity: 0.5 }}>
            <Grid item style={{ marginRight: 6 }}>
              <PublicIcon style={{ display: 'block', opacity: 0.8 }} fontSize="small" />
            </Grid>
            <Grid item>
              <Typography variant="body1">Agendamento Online Desabilitado</Typography>
            </Grid>
          </Grid>
        )
      }
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={contentMaxWidth}
        pageType="basicEntity"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => {
          const tabs = [{ label: 'Perfil' }, { label: 'Configurações' }, { label: 'Atividades' }]
          const content = [
            <TabPerfil colaborador={this.state.colaborador} scrollElement={dialogContentProps.scrollContainerRef.current} />,
            <TabConfiguracoes colaborador={this.state.colaborador} scrollElement={dialogContentProps.scrollContainerRef.current} />,
            <TabAtividades colaborador={this.state.colaborador} scrollElement={dialogContentProps.scrollContainerRef.current} />
          ]

          if (verificarAutorizacao([PA.PODE_GERENCIAR_SALARIOS])) {
            tabs.splice(3, 0, { label: 'Salários' })
            content.splice(3, 0, <TabSalarios scrollElement={dialogContentProps.scrollContainerRef.current} colaborador={this.state.colaborador} />)
          }

          if (verificarAutorizacao([PA.PODE_ACESSAR_PROPRIO_VALE])) {
            tabs.splice(4, 0, { label: 'Vales' })
            content.splice(4, 0, <TabVales scrollElement={dialogContentProps.scrollContainerRef.current} colaborador={this.state.colaborador} />)
          }

          return (
            <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadContentPadding={true} loadFunction={this.carregarMain}>
              {() => (
                <div>
                  <Subject
                    photo={<ColaboradorPhotoPicker colaborador={colaborador} photo1x={variacoesFoto.get(0)} photo2x={variacoesFoto.get(0)} framedImage={true} width={148} />}
                    title={colaborador.apelido}
                    infos={[emailUsuario, statusAgendamentoOnline]}
                  />

                  <Tab
                    value={tabValue}
                    onChange={(event, value) => {
                      this.setState({ tabValue: value })
                    }}
                    tabs={tabs}
                  />
                  <div>{content[tabValue]}</div>
                </div>
              )}
            </ContentWithPreload>
          )
        }}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ColaboradorDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ColaboradorDialogPage)
