import { getAPI as _getAPI, patchAPI as _patchAPI, postAPI as _postAPI } from 'support/components/api/ApiClient'

export function postAPI(opts) {
  opts.contextoUsuario = 'erp'
  _postAPI(opts)
}

export function getAPI(opts) {
  opts.contextoUsuario = 'erp'
  _getAPI(opts)
}

export function patchAPI(opts) {
  opts.contextoUsuario = 'erp'
  _patchAPI(opts)
}

export function generateColaboradorAccessToken(opts) {
  if (!opts) {
    opts = {}
  }
  if (!opts.callProps) {
    opts.callProps = {}
  }
  opts.contextoUsuario = 'erp'
  _getAPI({
    method: 'get',
    url: 'cda/accesstokens.generateColaboradorAccessToken',
    contextoUsuario: opts.contextoUsuario,
    requerAutorizacao: true,
    onSuccess: (response) => {
      opts.callback(response.data)
    },
    ...opts.callProps
  })
}
