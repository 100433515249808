import React, { Component } from 'react'

import { User, withConfigCatClient } from 'configcat-react'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import { TemaEnum } from 'support/style/Tema'
import { logCORE } from 'support/util/Logger/Logger'

class RouterContent extends Component {
  constructor(props) {
    super(props)
    if (this.props.denifirTemaPadrao) {
      EventsManager.pub('AlterarTemaAplicacao', {
        tema: TemaEnum.PRETO_AZUL
      })
    }
    this.state = {
      isIntercom: false
    }
  }

  componentDidMount() {
    this.url = window.document.location.pathname
  }

  componentDidUpdate() {
    this.evaluateIntercomFeatureFlag()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.url === window.document.location.pathname) {
      const propsClone = Object.assign({}, this.props)
      const nextPropsClone = Object.assign({}, nextProps)
      delete propsClone.history
      delete nextPropsClone.history
      delete propsClone.location
      delete nextPropsClone.location
      delete propsClone.mobileDrawerOpen
      delete nextPropsClone.mobileDrawerOpen

      const filter = function (key, value) {
        if (key.charAt(0) === '_' || key === 'current') {
          return
        }
        return value
      }

      const propsCloneString = JSON.stringify(propsClone, filter)
      const nextPropsCloneString = JSON.stringify(nextPropsClone, filter)

      if (propsCloneString === nextPropsCloneString) {
        //console.log("OTIMIZACAO,RENDER","RouterContent", this.url, propsCloneString, nextPropsCloneString);
        return false
      }
    }

    if (this.props.sendPageViewGA && window.gtag) {
      window.setTimeout(() => {
        const page = window.document.location.pathname.replace(/\/c[0-9]{1,}n[0-9]{1,}/gi, '')
        window.gtag('config', 'UA-113212457-1', { page_path: page })
      }, 200)
    }

    //console.log("SLOW","RouterContent", this.url);
    this.url = window.document.location.pathname
    return true
  }

  render() {
    const { component: ComponentToRender, ...others } = this.props
    const { setIntercom, isIntercom } = this.state

    if (isIntercom) {
      if (SessaoUsuario.getUsuario()) {
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          name: SessaoUsuario.getUsuario().nome,
          email: SessaoUsuario.getUsuario().email,
          created_at: Date.now()
        })
      } else {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID
        })
      }
    }

    return <ComponentToRender {...others} />
  }

  evaluateIntercomFeatureFlag() {
    const { negocioSessao, getValue } = this.props

    try {
      const user = new User(String(negocioSessao?.id), SessaoUsuario.getUsuario()?.email)
      this.props.getValue('FT_PGS_TAG_INTERCOM', false, user).then((value) => {
        this.setState({ isIntercom: value })
      })
    } catch (error) {
      console.log(error)
      logCORE(error, { component: 'RouterContent', context: 'evaluateIntercomFeatureFlag' })
    }
  }
}

export default withConfigCatClient(RouterContent)
