import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import MotivoMovimentacaoEstoqueEnum from 'support/domain/inventario/MotivoMovimentacaoEstoqueEnum'
import TipoMovimentacaoEstoqueEnum from 'support/domain/inventario/TipoMovimentacaoEstoqueEnum'
import TipoUnidadeMedida from 'support/domain/servico/TipoUnidadeMedida'
import { converterDateParaDataInt } from 'support/util/DateConverter'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputFloatNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class CategoriaProdutoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      title: 'Movimentação',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      produto: this.props.produto,
      tipoMovimentacao: this.props.tipoMovimentacao ? this.props.tipoMovimentacao : TipoMovimentacaoEstoqueEnum.ENTRADA,
      motivoMovimentacao: this.props.motivoMovimentacao,
      dataMovimentacao: converterDateParaDataInt(new Date()),
      tipoUnidadeMedida: TipoUnidadeMedida.QUANTIDADE,
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }

    this.inputMotivo = React.createRef()
    this.inputObservacoes = React.createRef()
    this.dataFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirMovimentacao()
  }

  persistirMovimentacao = () => {
    postAPI({
      url: 'erp/inventario.persistirMovimentacaoEstoque',
      data: this.getDadosMovimentacao(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoProduto')
        this.props.handleCloseDialog()
        EventsManager.pub('SnackBarSuccess', { open: true, message: 'Estoque atualizado com sucesso.' })
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formMovimentacao')
      }
    })
  }

  getDadosMovimentacao = () => {
    const dados = {}
    dados.produto = { id: this.state.produto.id }
    dados.tipoMovimentacao = { id: this.state.tipoMovimentacao.id }
    dados.quantidade = FormUtil.convertStringToNumber(this.state.quantidadeMovimentada)
    dados.tipoUnidadeMedida = {
      id: FormUtil.convertStringToNumber(this.state.tipoUnidadeMedida.id)
    }
    if (this.state.motivoMovimentacao) {
      dados.motivo = { id: this.state.motivoMovimentacao.id }
    }

    if (this.inputObservacoes.current.value) {
      dados.observacao = this.inputObservacoes.current.value
    }

    dados.dataMovimentacao = this.dataFunctionsMap.getDataAsInt()

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, tipoMovimentacao, motivoMovimentacao, dataMovimentacao } = this.state
    const { nome, estoqueAtualQuantidade, estoqueAtualUnidadePersonalizada, tipoUnidadeMedida, unidadeMedidaEmbalagem, quantidadeEmbalagem } = this.state.produto
      ? this.state.produto
      : {}
    const opcoesTipoUnidadeMedida = [TipoUnidadeMedida.QUANTIDADE]
    let quantidadeUsoHelpMessage = null
    let textoEstoque = ''
    const textoUnidade = estoqueAtualQuantidade === 1 || estoqueAtualQuantidade === -1 ? ' unidade' : ' unidades'
    let endAdornment

    if (tipoUnidadeMedida && tipoUnidadeMedida.id === TipoUnidadeMedida.PERSONALIZADA.id) {
      opcoesTipoUnidadeMedida.push({ id: TipoUnidadeMedida.PERSONALIZADA.id, descricao: 'Em ' + unidadeMedidaEmbalagem })

      if (this.state.quantidadeMovimentada) {
        if (this.state.tipoUnidadeMedida.id === TipoUnidadeMedida.PERSONALIZADA.id) {
          quantidadeUsoHelpMessage = this.state.quantidadeMovimentada + ' ' + unidadeMedidaEmbalagem
        } else if (this.state.tipoUnidadeMedida.id === TipoUnidadeMedida.QUANTIDADE.id && this.state.quantidadeMovimentada > 0) {
          quantidadeUsoHelpMessage = quantidadeEmbalagem * this.state.quantidadeMovimentada + ' ' + unidadeMedidaEmbalagem
        }
        if (quantidadeUsoHelpMessage) {
          quantidadeUsoHelpMessage = (
            <span>
              Utiliza <b>{quantidadeUsoHelpMessage}</b> deste produto
            </span>
          )
        }
      }

      endAdornment = (
        <InputSelect
          customVariant="naked"
          style={{ width: 'auto', minWidth: 'auto', maxWidth: 'none' }}
          InputProps={{ style: { marginRight: 6 } }}
          value={this.state.tipoUnidadeMedida.id.toString()}
          fullWidth={false}
          onChange={(event) => {
            const tipoUnidadeMedida = TipoUnidadeMedida.getById(parseInt(event.target.value, 10))
            this.setState({ tipoUnidadeMedida: tipoUnidadeMedida })
          }}
        >
          {opcoesTipoUnidadeMedida.map((tipo) => (
            <MenuItem key={tipo.id} value={tipo.id}>
              {tipo.descricao.toLowerCase()}
            </MenuItem>
          ))}
        </InputSelect>
      )

      textoEstoque += (estoqueAtualUnidadePersonalizada < 0 ? '0' : estoqueAtualUnidadePersonalizada) + ' ' + unidadeMedidaEmbalagem + ' ('
    }

    textoEstoque += estoqueAtualQuantidade < 0 ? '0' : estoqueAtualQuantidade + textoUnidade

    if (tipoUnidadeMedida && tipoUnidadeMedida.id === TipoUnidadeMedida.PERSONALIZADA.id) {
      textoEstoque += ')'
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <form id="formMovimentacao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
          <Typography variant="h6" align="left">
            {nome}
          </Typography>
          <Typography variant="body2" align="left">
            Estoque atual: {textoEstoque}
          </Typography>

          {!this.props.origemCompra && (
            <FormControl component="fieldset" style={{ marginTop: 15 }}>
              <RadioGroup
                row
                name="xtipomovimentacao"
                value={tipoMovimentacao.id.toString()}
                onChange={(event) => {
                  this.setState({
                    tipoMovimentacao: TipoMovimentacaoEstoqueEnum.getById(parseInt(event.target.value, 10)),
                    motivoMovimentacao: null
                  })
                }}
              >
                {TipoMovimentacaoEstoqueEnum.values().map((tipo) => (
                  <FormControlLabel key={tipo.id} value={tipo.id.toString()} control={<Radio />} label={tipo.descricao} />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          <InputHorizontalContainer
            style={{ marginTop: 20 }}
            inputTexts={[
              {
                containerProps: { xs: 12, sm: 6 },
                input: (
                  <InputSelect
                    id="xmotivo"
                    name="xmotivo"
                    key={this.state.tipoMovimentacao.id}
                    autoFocus={true}
                    customVariant="outlined-small"
                    shrink={false}
                    errorMessage={errorsMap('motivo')}
                    label="Motivo da movimentação"
                    value={motivoMovimentacao ? motivoMovimentacao.id.toString() : null}
                    onChange={(event) => {
                      const motivoMovimentacao = MotivoMovimentacaoEstoqueEnum.getById(parseInt(event.target.value, 10))
                      this.setState({ motivoMovimentacao: motivoMovimentacao })
                    }}
                    inputRef={this.inputMotivo}
                  >
                    {MotivoMovimentacaoEstoqueEnum.getByTipo(this.state.tipoMovimentacao.id).map(
                      (motivo) =>
                        motivo.habilitarMovimentacaoEstoque && (
                          <MenuItem key={motivo.id} value={motivo.id}>
                            {motivo.descricao}
                          </MenuItem>
                        )
                    )}
                  </InputSelect>
                )
              },
              {
                containerProps: { xs: 12, sm: 6 },
                input: (
                  <InputData
                    functionsMap={this.dataFunctionsMap}
                    keyboard={true}
                    customVariant="outlined-small"
                    shrink={false}
                    id="xdata"
                    name="xdata"
                    errorMessage={errorsMap('dataMovimentacao')}
                    style={{ marginTop: 0 }}
                    defaultValue={dataMovimentacao}
                    label={'Data da Movimentação'}
                  />
                )
              }
            ]}
          />

          <InputHorizontalContainer
            style={{ marginTop: 10 }}
            inputTexts={[
              {
                containerProps: { xs: 12, sm: 12 },
                input: (
                  <InputText
                    id="xquantidade"
                    customVariant="outlined-small"
                    shrink={false}
                    disabled={this.state.ajaxing}
                    defaultValue={this.props.quantidade}
                    errorMessage={errorsMap('quantidade')}
                    label="Quantidade de itens"
                    inputComponent={InputFloatNumberFormat}
                    inputProps={{
                      minValue: 0,
                      maxValue: 999999
                    }}
                    onChange={(event) => {
                      this.setState({ quantidadeMovimentada: event.target.value })
                    }}
                    helpMessage={quantidadeUsoHelpMessage}
                    endAdornment={endAdornment}
                  />
                )
              }
            ]}
          />

          <InputText
            style={{ display: 'none' }}
            id="xobservacoes"
            name="xobservacoes"
            customVariant="outlined-small"
            shrink={false}
            multiline={true}
            marginTop={true}
            errorMessage={errorsMap('observacoes')}
            label="Observações"
            inputProps={{
              maxLength: 2000
            }}
            inputRef={this.inputObservacoes}
          />

          <HiddenSubmitButton />

          {this.state.paginaCarregada && (
            <div className={classes.containerFooterButtons}>
              <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                Cancelar
              </Button>
              <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
              </Button>
            </div>
          )}
        </form>
      </DialogPage>
    )
  }
}

CategoriaProdutoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CategoriaProdutoDialogPage)
