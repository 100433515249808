import { makeStyles } from '@material-ui/core'

const blueColor = '#264FEC'

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px'
  },
  modalBody: {
    justifyContent: 'flex-start',
    padding: '0px'
  },
  wrapperButtonsModal: {
    marginTop: '20px',
    width: '100%',
    gap: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button:nth-of-type(1)': {
      backgroundColor: 'transparent',
      color: blueColor,
      border: '1px solid',
      borderColor: blueColor
    }
  }
}))

export const modalStyles = {
  modalContent: {
    padding: '24px',
    borderRadius: '8px',
    top: '40%',
    '@media (max-width: 765px)': {
      margin: '0px 24px',
      top: '30%',
      right: '0px'
    }
  }
}
