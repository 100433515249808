import { browserVersion, isChrome, isEdge, isFirefox, isIE, isOpera, isSafari } from 'react-device-detect'

import { setMomentPtBr } from 'support/domain/datetime/momentConfig'

const maxChromeVersion = 40
const maxSafariVersion = 10
const maxEdgeVersion = 17
const maxFirefoxVersion = 68
const maxOperaVersion = 46

function isCompatibleBrowser() {
  let browserVersionAsFloat = parseFloat(browserVersion)
  if (isIE) {
    return false
  } else if (browserVersionAsFloat && browserVersionAsFloat > 0) {
    if (isChrome && browserVersionAsFloat < maxChromeVersion) {
      return false
    } else if (isSafari && browserVersionAsFloat < maxSafariVersion) {
      return false
    } else if (isEdge && browserVersionAsFloat < maxEdgeVersion) {
      return false
    } else if (isFirefox && browserVersionAsFloat < maxFirefoxVersion) {
      return false
    } else if (isOpera && browserVersionAsFloat < maxOperaVersion) {
      return false
    }
  }
  return true
}

export default () => {
  if (!isCompatibleBrowser()) {
    let isNativeApp = false
    if (
      window.location.search.indexOf('os=android') >= 0 ||
      window.location.search.indexOf('os=ios') >= 0 ||
      localStorage.getItem('s99DeviceOS') === 'android' ||
      localStorage.getItem('s99DeviceOS') === 'ios'
    ) {
      isNativeApp = true
    }

    if (isNativeApp) {
      window.location.href = `${process.env.REACT_APP_WEB_ADDRESS}/dispositivo-compatibilidade.html`
    } else {
      window.location.href = `${process.env.REACT_APP_WEB_ADDRESS}/navegador-compatibilidade.html`
    }

    throw new Error('Navegador incompatível')
  }

  setMomentPtBr()
}
