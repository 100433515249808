import React, { MouseEvent, useMemo } from 'react'

import { Menu, MenuItem, PopoverPosition } from '@material-ui/core'
import { MercadoPagoUtils } from 'support/util/MercadoPagoUtil'

type NotaPagamento = {
  id: number
  formaPagamento: {
    id: number
    tipo: {
      id: number
    }
    nome: string
    ativo: boolean
    excluido: boolean
    permiteExclusao: boolean
  }
  numeroParcelas: number
  valorPago: number
  valorPagoComEstorno: number
  valorPagoFinal: number
  valorEntrada: number
  valorAdicional: number
  valorEstornoVenda: number
  valorCalculado: number
  valorCalculadoEfetivo: number
  dataEstorno: number
  formaPagamentoEstorno: {
    tipo: {
      id: number
    }
  }
  valorTotalEstornos: number
  dataPagamento: number
  cancelado: boolean
  excluido: boolean
}

type PagamentoMenuButtonProps = {
  isOpen: boolean
  anchorPosition: PopoverPosition
  onClose: (event: MouseEvent<HTMLLIElement>) => void
  onRemove: () => void
  onEdit: () => void
  onReverse: () => void
  nota: NotaPagamento
}

export const PagamentoMenuButton = ({ nota, onEdit, onRemove, onReverse, onClose, isOpen, anchorPosition }: PagamentoMenuButtonProps) => {
  const isEnabled = useMemo(() => nota.valorPagoFinal > nota.valorEstornoVenda, [nota])
  const isMercadoPago = MercadoPagoUtils.isMercadoPago(nota.formaPagamento)

  return (
    <Menu anchorReference="anchorPosition" anchorPosition={anchorPosition} open={isOpen} onClose={onClose}>
      <React.Fragment>
        {!isMercadoPago && (
          <>
            <MenuItem
              onClick={(event) => {
                onClose(event)
                onRemove()
              }}
            >
              Remover Pagamento
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                onClose(event)
                onEdit()
              }}
            >
              Editar Pagamento
            </MenuItem>
          </>
        )}
      </React.Fragment>
      {isEnabled && (
        <MenuItem
          onClick={(event) => {
            onClose(event)
            onReverse()
          }}
        >
          Estornar Pagamento
        </MenuItem>
      )}
    </Menu>
  )
}
