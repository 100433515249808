import TipoCalculoEnum from 'support/domain/calculo/TipoCalculoEnum'
import NumberUtil from 'support/util/NumberUtil'

function calcularDesconto(valorOrigem, valorDesconto, tipoCalculo) {
  if (!tipoCalculo || valorOrigem === null || valorOrigem === undefined || valorDesconto === null || valorDesconto === undefined) {
    return null
  } else if (tipoCalculo.id === TipoCalculoEnum.PORCENTAGEM.id) {
    return NumberUtil.round((valorOrigem * valorDesconto) / 100)
  } else if (tipoCalculo.id === TipoCalculoEnum.REAL.id) {
    return NumberUtil.round(valorDesconto)
  } else {
    return null
  }
}

function aplicarDesconto(valorOrigem, valorDesconto, tipoCalculo) {
  const valorCalculadoDesconto = calcularDesconto(valorOrigem, valorDesconto, tipoCalculo)
  if (valorCalculadoDesconto === null) {
    return null
  }
  const valorDescontoAplicado = NumberUtil.round(valorOrigem - valorCalculadoDesconto)
  if (valorDescontoAplicado >= 0) {
    return valorDescontoAplicado
  } else {
    return null
  }
}

const functionsObject = {
  calcularDesconto: calcularDesconto,
  aplicarDesconto: aplicarDesconto
}

export default functionsObject
