import { useCallback } from 'react'

type Props = {
  position: DOMRect
  elementSelector: string
}

const ONE = 1

function useAnchorElementHighlight({ position, elementSelector }: Props) {
  const higlightAnchorElement = (selector: string, clientRect: DOMRect): Element => {
    let highlightElement = document.querySelector(`#${selector}`) as HTMLDivElement

    if (!highlightElement) {
      highlightElement = document.createElement('div')
      highlightElement?.setAttribute('id', selector)
    }

    highlightElement.style.position = 'absolute'
    highlightElement.style.zIndex = 'var(--flora--zIndices-modal)'
    highlightElement.style.top = `${clientRect?.top}px`
    highlightElement.style.left = `${clientRect?.left}px`
    highlightElement.style.width = `${clientRect?.width}px`
    highlightElement.style.height = `${clientRect?.height}px`
    highlightElement.style.borderRadius = '0 var(--flora--radii-small) var(--flora--radii-small) 0'
    highlightElement.style.backgroundColor = 'gray'

    return highlightElement
  }

  const removeElement = (selector: string): void => {
    if (typeof document === 'undefined') {
      return
    }

    const highlightElement = document.getElementById(selector)
    if (highlightElement) {
      highlightElement.remove()
    }
  }

  const toggleHiglightElement = useCallback(
    (modalContent: HTMLElement | null) => {
      if (position && modalContent) {
        const highlightElement = higlightAnchorElement(elementSelector, position)

        requestAnimationFrame(() => {
          const overlay = document.querySelector('div[data-flora="overlay"]') as HTMLDivElement
          if (overlay?.children?.length < ONE) {
            overlay.appendChild(highlightElement)
          }
        })
      } else {
        removeElement(elementSelector)
      }
    },
    [elementSelector, position]
  )

  return { toggleHiglightElement }
}

export default useAnchorElementHighlight
