import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  INDICACAO: { id: 1, descricao: 'Indicação' },
  SITES_BUSCA: { id: 2, descricao: 'Sites de Busca' },
  AGENDAMENTO_ONLINE: { id: 3, descricao: 'Agendamento Online' },
  PASSANDO_EM_FRENTE: { id: 4, descricao: 'Passando em Frente' },
  INSTAGRAM: { id: 5, descricao: 'Instagram' },
  FACEBOOK: { id: 6, descricao: 'Facebook' },
  OUTRAS_REDES_SOCIAIS: { id: 7, descricao: 'Outras Redes Sociais' },
  OUTROS: { id: 8, descricao: 'Outros' }
})

export default enumObject
