import { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import EmailsLogsPanelPage from 'pages/admin/painel/email/EmailsLogsPanelPage'
import NegociosPanelPage from 'pages/admin/painel/negocio/NegociosPanelPage'
import NFsPanelPage from 'pages/admin/painel/notafiscal/NFsPanelPage'
import ServicosNFPanelPage from 'pages/admin/painel/notafiscal/ServicosNFPanelPage'
import ProcessamentosPanelPage from 'pages/admin/painel/processamento/ProcessamentosPanelPage'
import RelatoriosPanelPage from 'pages/admin/painel/relatorio/RelatoriosPanelPage'
import SolicitacoesTreinamentoPanelPage from 'pages/admin/painel/suporte/SolicitacoesTreinamentoPanelPage'
import SuporteChatPanelPage from 'pages/admin/painel/suporte/SuporteChatPanelPage'
import UsuariosPanelPage from 'pages/admin/painel/usuario/UsuariosPanelPage'
import { ROUTE_ADMIN_PANEL } from 'pages/RouteMap'
import RouterContent from 'support/components/router/RouterContent'

import { CuponsPanelPage } from './cupons/CuponsPanelPage'

class RoutesAdmin extends Component {
  /*shouldComponentUpdate(nextProps, nextState) {

		if (this.props.desktopDrawerOpen !== undefined && this.props.desktopDrawerOpen !== null) {
			if((this.props.desktopDrawerOpen !== nextProps.desktopDrawerOpen || this.props.mobileDrawerOpen !== nextProps.mobileDrawerOpen) && this.props.panelPagesProps.toolbarExpanded === nextProps.panelPagesProps.toolbarExpanded){
				console.log("[OTIMIZACAO]: render RoutesAdmin");
				return false;
			}

		}
		return true;

	}*/

  render() {
    const panelPagesProps = this.props

    return (
      <Switch>
        <Route path={ROUTE_ADMIN_PANEL + '/p/cupons'} render={(props) => <RouterContent {...panelPagesProps} component={CuponsPanelPage} />} />
        <Route path={ROUTE_ADMIN_PANEL + '/p/suporte/chat'} render={(props) => <RouterContent {...panelPagesProps} component={SuporteChatPanelPage} />} />
        <Route path={ROUTE_ADMIN_PANEL + '/p/email/log/envios'} render={(props) => <RouterContent {...panelPagesProps} component={EmailsLogsPanelPage} />} />
        <Route path={ROUTE_ADMIN_PANEL + '/p/relatorios'} render={(props) => <RouterContent {...panelPagesProps} component={RelatoriosPanelPage} />} />
        <Route path={ROUTE_ADMIN_PANEL + '/p/processamentos'} render={(props) => <RouterContent {...panelPagesProps} component={ProcessamentosPanelPage} />} />
        <Route
          path={ROUTE_ADMIN_PANEL + '/p/suporte/solicitacoes-treinamento'}
          render={(props) => <RouterContent {...panelPagesProps} component={SolicitacoesTreinamentoPanelPage} />}
        />
        <Route path={ROUTE_ADMIN_PANEL + '/p/usuarios'} render={(props) => <RouterContent {...panelPagesProps} component={UsuariosPanelPage} />} />
        <Route path={ROUTE_ADMIN_PANEL + '/p/negocios'} render={(props) => <RouterContent {...panelPagesProps} component={NegociosPanelPage} />} />
        <Route path={ROUTE_ADMIN_PANEL + '/p/nfs'} render={(props) => <RouterContent {...panelPagesProps} component={NFsPanelPage} />} />
        <Route path={ROUTE_ADMIN_PANEL + '/p/servicosnf'} render={(props) => <RouterContent {...panelPagesProps} component={ServicosNFPanelPage} />} />
      </Switch>
    )
  }
}

export default RoutesAdmin
