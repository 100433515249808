import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import NativeApp from 'support/components/nativeapp/NativeApp'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({})

class ImportacaoContatosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      title: 'Importar Contatos',
      paginaCarregada: false,
      dddPadrao: null,
      ajaxing: false,
      selecionarTodos: true,
      errorsMap: createErrorsMap(),
      toolbarActions: {
        actions: [
          {
            label: 'Carregar',
            handleAction: this.salvarDddPadrao
          }
        ]
      },
      nomesClientes: [],
      apelidosClientes: [],
      telefonesClientes: [],
      contatos: []
    }

    this.inputDddPadrao = React.createRef()
  }

  componentDidMount() {
    this.eventsManager.sub('setAllContacts', (props) => {
      const contatos = this.processarContatos(props.contacts)
      let toolbarActions = null

      if (contatos && contatos.length > 0) {
        toolbarActions = {
          actions: [
            {
              label: 'Salvar',
              handleAction: () => this.salvar()
            }
          ]
        }
      }

      this.setState({
        ajaxing: false,
        contatos: contatos,
        toolbarActions: toolbarActions
      })
      this.notifyContentLoaded()
    })

    this.eventsManager.sub('cancelImport', (props) => {
      this.props.handleCloseDialog()
    })
  }

  salvarDddPadrao = () => {
    const dddPadraoString = this.inputDddPadrao.current.value.trim()
    let dddPadrao = null
    let errorsMap = createErrorsMap()
    if (dddPadraoString.length === 0) {
      errorsMap = createErrorsMap({ dddPadrao: 'O DDD Padrão é obrigatório' })
    } else if (!dddPadraoString.match(/^[0-9]{2,2}$/g)) {
      errorsMap = createErrorsMap({ dddPadrao: 'O DDD Padrão é inválido' })
    } else {
      dddPadrao = dddPadraoString
    }
    this.setState({ dddPadrao: dddPadrao, errorsMap: errorsMap })
    focusFirstElementWithError('formDddPadrao')
  }

  handleSubmitDddPadrao = (event) => {
    event.preventDefault()
    this.salvarDddPadrao()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/clientes.buscarDadosPreImportacao',
      params: {},
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState((state) => ({
          ajaxing: false,
          paginaCarregada: true,
          possivelDddPadrao: response.data.possivelDddPadrao,
          nomesClientes: response.data.nomes.map((nome) => nome.toLowerCase()),
          apelidosClientes: response.data.apelidos.map((nome) => nome.toLowerCase()),
          telefonesClientes: response.data.telefones
        }))
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({ ajaxing: false })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarContatos = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.notifyContentLoaded = notifyContentLoaded
    this.notifyContentNotLoaded = notifyContentNotLoaded
    this.setState({ ajaxing: true })
    window.setTimeout(() => {
      NativeApp.executeNativeMethod({
        method: 'loadAllContacts'
      })
    }, 50)
  }

  processarContatos = (contatos) => {
    const { nomesClientes, apelidosClientes, telefonesClientes } = this.state
    const contatosProcessados = []

    if (contatos && contatos.length > 0) {
      loopContatos: for (let contato of contatos) {
        if (nomesClientes.indexOf(contato.nome.toLowerCase()) >= 0) {
          continue
        }
        if (apelidosClientes.indexOf(contato.nome.toLowerCase()) >= 0) {
          continue
        }
        contato._checked = true
        if (contato.telefones && contato.telefones.length > 0) {
          const telefones = []
          for (let telefone of contato.telefones) {
            const telefoneProcessado = this.processarTelefone(telefone)
            if (telefoneProcessado) {
              if (telefonesClientes.indexOf(telefoneProcessado) >= 0) {
                continue loopContatos
              }
              if (telefones.indexOf(telefoneProcessado) <= -1) {
                telefones.push(telefoneProcessado)
              }
            }
          }
          if (telefones.length > 0) {
            contato.telefones = telefones
          } else {
            contato.telefones = null
          }
        }
        contatosProcessados.push(contato)
      }
    }
    return contatosProcessados
  }

  processarTelefone = (telefoneBruto) => {
    const { dddPadrao } = this.state

    if (telefoneBruto) {
      telefoneBruto = telefoneBruto.trim()
      if (telefoneBruto.length > 0) {
        telefoneBruto = telefoneBruto.replace(/[^0-9+]/g, '')

        // desconsiderar numeros que não são do brasil
        if (telefoneBruto.match(/^\+((?!55).){2,2}/g)) {
          return null
        }

        telefoneBruto = telefoneBruto.replace('+55', '').replace(/[^0-9]/g, '')

        // No caso de existir um formato: 55 11 22365588 (possui 12 caracteres ou mais)
        // Não irá remover quando o 55 for DDD, exemplo: 55 979725588 (esse caso possui 11 caracteres)
        if (telefoneBruto.substring(0, 2) === '55' && telefoneBruto.length >= 12) {
          telefoneBruto.replace(/^55/g, '')
        }

        telefoneBruto = telefoneBruto.replace(/^0/g, '')

        if (telefoneBruto.match(/^(55){0,1}0{0,1}800{1,1}/g)) {
          return null
        }

        //Formato: 2236-5588 -> 22365588
        if (telefoneBruto.length === 8) {
          return '(' + dddPadrao + ') ' + telefoneBruto.substring(0, 4) + '-' + telefoneBruto.substring(4, 8)
        }

        //Formato: 97973-2255 -> 979732255
        if (telefoneBruto.length === 9) {
          return '(' + dddPadrao + ') ' + telefoneBruto.substring(0, 5) + '-' + telefoneBruto.substring(5, 9)
        }

        //Formato: 11 2236-5588 ou 011 2236-5588 -> 1122365588
        if (telefoneBruto.length === 10) {
          return '(' + telefoneBruto.substring(0, 2) + ') ' + telefoneBruto.substring(2, 6) + '-' + telefoneBruto.substring(6, 10)
        }

        //Formato: 11 97973-2255 ou 011 97973-2255 -> 11979732255
        if (telefoneBruto.length === 11) {
          return '(' + telefoneBruto.substring(0, 2) + ') ' + telefoneBruto.substring(2, 7) + '-' + telefoneBruto.substring(7, 11)
        }

        //Formato: 31 11 2236-5588 ou 031 11 2236-5588 -> 311122365588
        if (telefoneBruto.length === 12) {
          return '(' + telefoneBruto.substring(2, 4) + ') ' + telefoneBruto.substring(4, 8) + '-' + telefoneBruto.substring(8, 12)
        }

        //Formato: 31 11 97973-2255 ou 031 11 97973-2255 -> 3111979732255
        if (telefoneBruto.length === 13) {
          return '(' + telefoneBruto.substring(2, 4) + ') ' + telefoneBruto.substring(4, 9) + '-' + telefoneBruto.substring(9, 13)
        }

        return null
      }
    }
  }

  getRenderContent = (size, item, index) => {
    const { ajaxing } = this.state
    const content = {}

    content.checkbox = <Checkbox style={{ padding: 7 }} color="default" checked={item._checked === undefined ? false : item._checked} disabled={ajaxing} />
    content.info = (
      <div style={{ padding: '8px 0px' }}>
        <b>{item.nome}</b>
        <br />
        <small style={{ display: 'block', marginTop: 4 }}>{item.telefones && item.telefones.map((telefone) => <div>{telefone}</div>)}</small>
      </div>
    )

    return content
  }

  onChangeSelecionarTodos = () => {
    const checked = !this.state.selecionarTodos
    const contatos = this.state.contatos
    for (let contato of contatos) {
      contato._checked = checked
      contato._update = true
    }
    this.setState({ selecionarTodos: checked, contatos: contatos })
  }

  changeCheckbox = (item, event, index) => {
    const checked = !item._checked
    const contatos = this.state.contatos
    const contato = contatos[index]
    contato._checked = checked
    contato._update = true

    let selecionarTodos = this.state.selecionarTodos
    if (!checked) {
      selecionarTodos = false
    } else {
      selecionarTodos = true
      for (let contato of contatos) {
        if (!contato._checked) {
          selecionarTodos = false
          break
        }
      }
    }

    this.setState({ selecionarTodos: selecionarTodos, contatos: contatos })
  }

  salvar = () => {
    const contatosSelecionados = []
    const { contatos } = this.state
    if (contatos && contatos.length > 0) {
      for (let contato of contatos) {
        if (contato._checked) {
          let telefones = []
          if (contato.telefones && contato.telefones.length > 0) {
            telefones = contato.telefones.map((telefone) => ({ numero: telefone }))
          }
          contatosSelecionados.push({
            nome: contato.nome,
            telefones: telefones
          })
        }
      }
    }

    this.setState({
      ajaxing: true
    })

    postAPI({
      url: 'erp/clientes.importarContatos',
      data: {
        contatos: contatosSelecionados
      },
      requerAutorizacao: true,
      timeout: 0,
      onSuccess: (response) => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoCliente')
        EventsManager.pub('SnackBarSuccess', { open: true, message: 'Contatos importados com sucesso!' })
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { paginaCarregada, errorsMap } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        titleWrap={true}
        align="center"
        contentMaxWidth="default"
        pageType="basicEntity"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => (
          <ContentWithPreload loadFunction={this.carregarMain} loadContentPadding={true}>
            {paginaCarregada && !this.state.dddPadrao && (
              <form id="formDddPadrao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmitDddPadrao(event)}>
                <InputText
                  marginTop={true}
                  idnome="xdddpadrao"
                  customVariant="outlined-small"
                  defaultValue={this.state.possivelDddPadrao}
                  shrink={false}
                  autoFocus={true}
                  disabled={this.state.ajaxing}
                  errorMessage={errorsMap('dddPadrao')}
                  inputComponent={InputNumberFormat}
                  label="DDD Padrão"
                  helpMessage="Informe o DDD padrão que o sistema deve utilizar para carregar os contatos da agenda que não possuem DDD cadastrado."
                  inputRef={this.inputDddPadrao}
                />
                <HiddenSubmitButton />
              </form>
            )}

            {paginaCarregada && this.state.dddPadrao && (
              <ContentWithPreload loadFunction={this.carregarContatos} loadContentPadding={true}>
                {() => (
                  <React.Fragment>
                    {this.state.contatos && this.state.contatos.length === 0 && <Typography variant="body1">Não existe nenhum contato novo para ser importado</Typography>}

                    {this.state.contatos && this.state.contatos.length > 0 && (
                      <React.Fragment>
                        <Grid container alignItems="center" onClick={this.onChangeSelecionarTodos} style={{ marginBottom: 8 }}>
                          <Grid item>
                            <Checkbox style={{ padding: 7 }} color="secondary" checked={this.state.selecionarTodos} />
                          </Grid>
                          <Grid item xs style={{ minWidth: 0, backgroundColor: 'inherit' }}>
                            <Typography variant="body1" color="secondary">
                              <b>Selecionar Todos</b>
                            </Typography>
                          </Grid>
                        </Grid>

                        <VirtualizedResponsiveTable
                          showHeaderColumns={false}
                          showRowBorders={false}
                          scrollElement={dialogContentProps.scrollContainerRef.current}
                          showBackgroundDividers={false}
                          dynamicHeight={true}
                          items={this.state.contatos}
                          onClickRow={this.changeCheckbox}
                          largeRenderProps={{
                            columns: [{ label: 'resposta', props: { xs: true }, horizontalPadding: 'none' }],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item, index)
                              return {
                                itemData: [
                                  <Grid key={index} container alignItems="center" style={{ minWidth: 0, backgroundColor: 'inherit' }}>
                                    <Grid item>{content.checkbox}</Grid>
                                    <Grid item xs style={{ minWidth: 0, backgroundColor: 'inherit' }}>
                                      {content.info}
                                    </Grid>
                                  </Grid>
                                ]
                              }
                            }
                          }}
                          smallRenderProps={{
                            horizontalPadding: 'none',
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('small', item, index)
                              return {
                                itemData: (
                                  <Grid key={index} container alignItems="center" style={{ minWidth: 0, backgroundColor: 'inherit' }}>
                                    <Grid item>{content.checkbox}</Grid>
                                    <Grid item xs style={{ minWidth: 0, backgroundColor: 'inherit' }}>
                                      {content.info}
                                    </Grid>
                                  </Grid>
                                )
                              }
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </ContentWithPreload>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ImportacaoContatosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ImportacaoContatosDialogPage)
