import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Redirect } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PlaceIcon from '@material-ui/icons/PlaceOutlined'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import logo from 'img/logo_salao99_painel.png'
import BuscaFiltrosDialog from 'pages/portal/busca/BuscaFiltrosDialog'
import ListagemNegociosModoGrid from 'pages/portal/negocio/ListagemNegociosModoGrid'
import PortalPageFooter from 'pages/portal/PortalPageFooter'
import { ROUTE_CONTA_MAIN, ROUTE_PORTAL_MAIN, ROUTE_PORTAL_MAIN_AS_PREFIX, ROUTE_PORTAL_PANEL } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CookieConsentManager from 'support/components/cookieconsent/CookieConsentManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import LoginIcon from 'support/components/icon/Login'
import InputText from 'support/components/input/InputText'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import LoginAvatar from 'support/components/loginavatar/LoginAvatar'
import PlacePickerDialog from 'support/components/placepicker/PlacePickerDialog'
import NegocioSegmentoAtuacaoEnum from 'support/domain/negocio/NegocioSegmentoAtuacaoEnum'
import LocalizacaoFormatter from 'support/util/LocalizacaoFormatter'
import TextUtil from 'support/util/TextUtil'

const WIDTH_MODELO2 = 940

const styles = (theme) => ({
  mainContent: {
    ...theme.container.basic(),
    ...theme.container.centered({ maxWidth: 1320 }),
    paddingBottom: 70,
    flexWrap: 'nowrap'
  },
  modelo1: {
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      display: 'none'
    }
  },
  modelo2: {
    [theme.breakpoints.up(WIDTH_MODELO2)]: {
      display: 'none'
    }
  },
  header: {
    paddingTop: 16,
    paddingBottom: 16,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 16
    }
  },
  headerIcon: {
    fontSize: '28px',
    display: 'block',
    color: '#414141'
  },
  logo: {
    width: 110,
    display: 'block',
    cursor: 'pointer',
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      marginBottom: 4,
      marginRight: 12,
      marginLeft: 4,
      width: 106
    },
    [theme.breakpoints.down(780)]: {
      width: 100
    },
    [theme.breakpoints.down(450)]: {
      display: 'none'
    }
  },
  botaoVoltar: {
    marginLeft: -6,
    marginTop: -6,
    marginBottom: -6,
    [theme.breakpoints.up(450)]: {
      display: 'none'
    }
  },
  inputContainerCampoBusca: {
    borderRadius: 100
  },
  /*miniLogo: {
		width: 20,
		marginLeft: 8,
		marginTop: 4,
		display: "block",
		cursor: "pointer",
		[theme.breakpoints.up(450)]:{
			display:"none"
		}
	},*/
  containerChips: {
    marginLeft: 0,
    textAlign: 'left',
    marginBottom: 4
  },
  rootChip: {
    marginTop: 8,
    marginRight: 6,
    background: darken(theme.palette.secondary.light, 0.05),
    '&:focus': {
      background: darken(theme.palette.secondary.light, 0.05)
    },
    height: 30
  },
  labelChip: {
    color: 'white',
    paddingLeft: 10
  },
  deleteIconChip: {
    color: 'white',
    fontSize: 20
  },
  progressCentral: {
    margin: 16,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  progressCentralSVG: {
    color: '#333'
  },
  containerNegocios: {
    //width: "calc(100% + 24px)",
    //margin: -12,
    width: 'calc(100% + 12px)',
    margin: -6
    //[theme.breakpoints.down(9999)]:{
    //	width: "calc(100% + 12px)",
    //	margin: -6,
    //}
  },
  containerNegocio: {
    //padding: 12,
    padding: 6,
    display: 'inline-flex',
    cursor: 'pointer',
    maxWidth: '33.333333%',
    flexBasis: '33.333333%',
    //[theme.breakpoints.down(9999)]:{
    //	padding: 6,
    //},
    [theme.breakpoints.down(1180)]: {
      maxWidth: '50%',
      flexBasis: '50%'
    },
    [theme.breakpoints.down(770)]: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  innerContainerNegocio: {
    border: '1px solid #eee',
    padding: 22,
    borderRadius: 8,
    '$containerNegocio:hover &': {
      backgroundColor: '#fafafa'
    },
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      padding: 16
    },
    [theme.breakpoints.down(350)]: {
      padding: 10
    }
  },
  negocioFoto: {
    width: 84,
    height: 84,
    display: 'block',
    borderRadius: 110,
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      width: 74,
      height: 74
    }
  },
  negocioInfos: {
    marginLeft: 22,
    borderLeft: '1px solid  #f2f2f2',
    paddingLeft: 22,
    minWidth: 0,
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      marginLeft: 14,
      paddingLeft: 14
    },
    [theme.breakpoints.down(350)]: {
      marginLeft: 10,
      paddingLeft: 10
    }
  },
  negocioNome: {
    fontSize: 16
  },
  negocioEndereco: {
    fontSize: 13,
    marginTop: 5,
    marginBottom: 4,
    color: '#353535',
    ...theme.text.limitedLines({ maxLines: 2, withEllipsis: true })
  },
  negocioDistanciaRelativa: {
    fontSize: 12,
    color: '#b5b5b5',
    fontWeight: 500
  },
  negocioAvalicaoIcone: {
    fontSize: 17,
    verticalAlign: 'middle',
    marginLeft: 8,
    color: '#fdc050'
  },
  negocioAvalicaoNota: {
    color: '#ffb939',
    fontSize: 13,
    display: 'inline-block',
    marginLeft: 2,
    fontWeight: 500,
    verticalAlign: 'top',
    marginTop: 2
  }
})

const QUANTIDADE_GRUPO_EXIBICAO = 50

class BuscaPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      negociosPreparadosBusca: [],
      posicaoLimiteExibicao: 0,
      buscando: true,
      mensagemErroBusca: null
    }

    this.globalLocalPreferences = LocalPreferences.new('global', null)
    this.inputPalavrasChave = React.createRef()
  }

  componentDidMount = () => {
    document.body.classList.add('minheight100')

    CookieConsentManager.executeScriptAndShowPopup()

    const filtros = this.getFiltroAtual()
    if (filtros.place) {
      this.buscar()
    }
  }

  getFiltroAtual = () => {
    let filtros = {}
    if (this.props.match.params.filtros) {
      try {
        filtros = JSON.parse(decodeURIComponent(this.props.match.params.filtros))
      } catch (e) {}
      if (!filtros) {
        filtros = {}
      }
    }
    filtros.place = this.globalLocalPreferences.getPreference('Portal_EnderecoBusca', null)
    return filtros
  }

  compararFiltrosParaRota = (f1, f2) => {
    if (!f1) {
      f1 = {}
    }
    if (!f2) {
      f2 = {}
    }

    if (f1.q !== f2.q && (f1.q || f2.q)) {
      return false
    }
    if (f1.segmentosAtuacao !== f2.segmentosAtuacao && (f1.segmentosAtuacao || f2.segmentosAtuacao)) {
      return false
    }

    return true
  }

  alterarFiltros = (params) => {
    if (!params) {
      params = {}
    }

    let filtros = this.getFiltroAtual()

    if (params.q !== undefined) {
      filtros.q = params.q
    }

    if (params.segmentosAtuacao !== undefined) {
      filtros.segmentosAtuacao = params.segmentosAtuacao
    }

    if (params.removerSegmentosAtuacaoPorPosicoes !== undefined) {
      const segmentosAtuacao = NegocioSegmentoAtuacaoEnum.generateAllItems(filtros.segmentosAtuacao)
      for (let position of params.removerSegmentosAtuacaoPorPosicoes) {
        segmentosAtuacao[position].exists = false
      }
      filtros.segmentosAtuacao = NegocioSegmentoAtuacaoEnum.getString(segmentosAtuacao)
    }

    if (params.place) {
      this.globalLocalPreferences.setPreference('Portal_EnderecoBusca', params.place, { type: 'permanent' })
    }

    delete filtros.place

    if (this.compararFiltrosParaRota(filtros, this.getFiltroAtual()) === false) {
      const filtrosAsJSON = JSON.stringify(filtros)
      let route = ROUTE_PORTAL_MAIN_AS_PREFIX + '/busca/' + encodeURIComponent(filtrosAsJSON) + window.location.search
      this.props.history.push(route)
    } else {
      this.buscar()
    }
  }

  buscar = () => {
    const filtros = this.getFiltroAtual()

    if (filtros.place) {
      this.setState({
        buscando: true
      })

      getAPI({
        url: 'ptl/negocios.buscar',
        params: {
          palavrasChave: filtros.q,
          publicoAlvoString: filtros.segmentosAtuacao ? filtros.segmentosAtuacao.substring(0, 3) : null,
          segmentosAtuacaoString: filtros.segmentosAtuacao ? filtros.segmentosAtuacao.substring(3) : null,
          descricaoEndereco: filtros.place.description,
          latitude: filtros.place.coordinates.lat,
          longitude: filtros.place.coordinates.lng
        },
        requerAutorizacao: true,
        onSuccess: (response) => {
          this.setState({
            negociosPreparadosBusca: response.data,
            posicaoLimiteExibicao: QUANTIDADE_GRUPO_EXIBICAO,
            buscando: false,
            mensagemErroBusca: null
          })
        },
        onError: (response) => {
          let mensagemErroBusca = null
          if (response.code === -1) {
            mensagemErroBusca = 'Falha de conexão com a internet'
          } else {
            mensagemErroBusca = 'Ops! Ocorreu um erro inesperado.'
          }

          this.setState({
            buscando: false,
            mensagemErroBusca: mensagemErroBusca
          })
        },
        sendErroToGenericSnackbar: false
      })
    } else {
      this.abrirSelecaoEndereco()
    }
  }

  abrirSelecaoEndereco = () => {
    openBackableDialog(PlacePickerDialog, {
      onSelect: (place) => {
        this.globalLocalPreferences.setPreference('Portal_EnderecoBusca', place, { type: 'permanent' })
        this.buscar()
      },
      dialogTitle: 'Escolha um endereço para procurar opções próximas',
      showGetCurrentLocationButton: true,
      loadUserHistoryPlaces: this.props.usuarioSessao && this.props.usuarioSessao.carregado ? true : false
    })
  }

  abrirPesquisaAvancadaDialog = () => {
    const filtros = this.getFiltroAtual()

    openBackableDialog(BuscaFiltrosDialog, {
      segmentosAtuacaoString: filtros.segmentosAtuacao,
      onSelect: (props) => {
        this.alterarFiltros({
          segmentosAtuacao: props.segmentosAtuacaoString ? props.segmentosAtuacaoString : null
        })
      }
    })
  }

  componentDidUpdate(previousProps, previousState) {
    let prevFiltros = {}
    if (previousProps.match.params.filtros) {
      try {
        prevFiltros = JSON.parse(decodeURIComponent(previousProps.match.params.filtros))
      } catch (e) {}
      if (!prevFiltros) {
        prevFiltros = {}
      }
    }

    let nextFiltros = {}
    if (this.props.match.params.filtros) {
      try {
        nextFiltros = JSON.parse(decodeURIComponent(this.props.match.params.filtros))
      } catch (e) {}
      if (!nextFiltros) {
        nextFiltros = {}
      }
    }

    if (this.compararFiltrosParaRota(prevFiltros, nextFiltros) === false) {
      this.buscar()
    }
  }

  render() {
    const { classes } = this.props
    const { negociosPreparadosBusca, buscando, mensagemErroBusca } = this.state

    const filtros = this.getFiltroAtual()
    const segmentosAtuacao = NegocioSegmentoAtuacaoEnum.generateAllItems(filtros.segmentosAtuacao).filter((segmentosAtuacao) => segmentosAtuacao.exists)

    if (!filtros.place) {
      return <Redirect to={ROUTE_PORTAL_MAIN} push={false} />
    }

    let logoContent = (
      <img
        src={logo}
        alt="Salão99"
        className={classes.logo}
        onClick={() => {
          if (DeviceIdentifier.isNativeApp()) {
            this.props.history.replace(ROUTE_PORTAL_MAIN_AS_PREFIX + '/index-app')
          } else {
            this.props.history.replace(ROUTE_PORTAL_MAIN)
          }
        }}
      />
    )
    let botaoVoltar = (
      <IconButton
        className={classes.botaoVoltar}
        onClick={() => {
          if (DeviceIdentifier.isNativeApp()) {
            this.props.history.replace(ROUTE_PORTAL_MAIN_AS_PREFIX + '/index-app')
          } else {
            this.props.history.replace(ROUTE_PORTAL_MAIN)
          }
        }}
      >
        <ArrowBackIcon className={classes.headerIcon} />
      </IconButton>
    )

    let contaButton = null
    if (this.props.usuarioSessao && this.props.usuarioSessao.carregado) {
      contaButton = (
        <LoginAvatar
          name={this.props.usuarioSessao.nome}
          onClick={() => {
            this.props.history.push(ROUTE_PORTAL_PANEL)
          }}
        />
      )
    } else {
      contaButton = (
        <IconButton
          onClick={() => {
            this.props.history.push(ROUTE_CONTA_MAIN + '/login?continuacao=' + encodeURIComponent(window.location.href))
          }}
        >
          <LoginIcon className={classes.headerIcon} style={{ fontSize: '30px' }} />
        </IconButton>
      )
    }

    let placeContent = (
      <Link
        color="secondary"
        underline="none"
        onClick={() => {
          this.abrirSelecaoEndereco()
        }}
      >
        <Grid container alignItems="center" style={{ minWidth: 0 }} wrap="nowrap" justify="flex-end">
          <Grid item style={{ marginRight: 4 }}>
            <PlaceIcon color="secondary" style={{ display: 'block' }} />
          </Grid>
          <Grid item style={{ minWidth: 0 }}>
            <Typography variant="body1" noWrap={true}>
              <b>{filtros.place.description}</b>
            </Typography>
          </Grid>
        </Grid>
      </Link>
    )

    let placeContainerMeasures = {
      modelo1: {
        minWidth: 150,
        maxWidth: 350
      },
      modelo2: {
        minWidth: 150,
        maxWidth: 490
      }
    }

    let aproxPlaceWidth = TextUtil.getTextWidth(filtros.place.description, 'bold 16px Roboto') + 30

    if (placeContainerMeasures.modelo1.maxWidth > aproxPlaceWidth) {
      placeContainerMeasures.modelo1.maxWidth = aproxPlaceWidth
    }
    if (placeContainerMeasures.modelo1.minWidth > aproxPlaceWidth) {
      placeContainerMeasures.modelo1.minWidth = aproxPlaceWidth
    }

    let resultsContent = null
    if (buscando) {
      resultsContent = (
        <CircularProgress
          size={36}
          thickness={5.1}
          classes={{
            root: classes.progressCentral,
            svg: classes.progressCentralSVG
          }}
        />
      )
    } else if (mensagemErroBusca === null) {
      if (negociosPreparadosBusca && negociosPreparadosBusca.length > 0) {
        resultsContent = <ListagemNegociosModoGrid negociosPreparadosBusca={negociosPreparadosBusca} />
      } else {
        let prefixoTexto = 'No momento, nenhum estabelecimento/profissional foi encontrado'
        if (filtros.q || filtros.segmentosAtuacao) {
          prefixoTexto += ' com o filtro informado e'
        }
        prefixoTexto += ' próximo da localização:'
        resultsContent = (
          <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', paddingTop: 16 }}>
            <Typography variant="h6" align="center" style={{ fontWeight: 400, fontSize: 18 }}>
              {prefixoTexto} <b>{LocalizacaoFormatter.formatarEnderecoSimples(filtros.place.description)}</b>
            </Typography>
          </div>
        )
      }
    } else {
      resultsContent = (
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', paddingTop: 16, textAlign: 'center' }}>
          <Typography variant="body1">{mensagemErroBusca}</Typography>
          <Button style={{ textTransform: 'none', fontSize: 16 }} onClick={this.buscar}>
            Tentar Novamente
          </Button>
        </div>
      )
    }

    return (
      <Grid container alignItems="center" direction="column" className="minheight100">
        <Grid item xs container direction="column" className={classNames(classes.mainContent)}>
          <Helmet>
            <title>Salão99 - Busca de estabelecimentos e profissionais</title>
            <meta name="description" content="O jeito mais fácil de agendar horários em salões de beleza, barbearias, clínicas de estética e Spas" />
          </Helmet>
          <div className={classes.header}>
            <form
              id="formUsuario"
              autoComplete="off"
              noValidate
              onSubmit={(event) => {
                event.preventDefault()
                this.alterarFiltros({
                  q: this.inputPalavrasChave.current ? this.inputPalavrasChave.current.value : ''
                })
              }}
            >
              <Grid container alignItems="center" className={classes.modelo2} style={{ marginBottom: 2 }} wrap="nowrap">
                <Grid item>
                  {logoContent}
                  {botaoVoltar}
                </Grid>
                <Grid item xs style={{ minWidth: 0 }}>
                  <div style={{ minWidth: placeContainerMeasures.modelo2.minWidth, maxWidth: placeContainerMeasures.modelo2.maxWidth, marginLeft: 'auto' }}>{placeContent}</div>
                </Grid>
                <Grid item style={{ marginLeft: 14 }}>
                  {contaButton}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item style={{ marginRight: 20, paddingLeft: 4 }} className={classes.modelo1}>
                  {logoContent}
                </Grid>
                <Grid item xs>
                  <InputText
                    idname="xstringbuscatexto"
                    key={filtros.q ? filtros.q : ''}
                    InputProps={{
                      className: classes.inputContainerCampoBusca,
                      inputProps: {
                        style: {
                          fontSize: 16,
                          paddingTop: 8
                        }
                      }
                    }}
                    shrink={false}
                    customVariant="filled"
                    placeholder="Pesquisar..."
                    defaultValue={filtros.q ? filtros.q : ''}
                    startAdornment={<SearchIcon style={{ color: '#777', marginRight: 8 }} />}
                    endAdornment={
                      <IconButton
                        onClick={() => {
                          this.abrirPesquisaAvancadaDialog()
                        }}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    }
                    inputRef={this.inputPalavrasChave}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  lg={5}
                  style={{ marginLeft: 16, minWidth: placeContainerMeasures.modelo1.minWidth, maxWidth: placeContainerMeasures.modelo1.maxWidth }}
                  className={classes.modelo1}
                >
                  {placeContent}
                </Grid>
                <Grid item style={{ marginLeft: 16 }} className={classes.modelo1}>
                  {contaButton}
                </Grid>
              </Grid>
              <div style={{ marginTop: 8 }}>
                {segmentosAtuacao && segmentosAtuacao.length > 0 && (
                  <div className={classes.containerChips}>
                    {segmentosAtuacao.map((segmento, index) => {
                      return (
                        <Chip
                          key={index}
                          label={segmento.descricao}
                          onDelete={() => {
                            this.alterarFiltros({
                              removerSegmentosAtuacaoPorPosicoes: [segmento.position]
                            })
                          }}
                          classes={{
                            root: classes.rootChip,
                            label: classes.labelChip,
                            deleteIcon: classes.deleteIconChip
                          }}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </form>
          </div>

          {resultsContent}
        </Grid>

        <Grid item xs style={{ flexGrow: 0, width: '100%' }}>
          <PortalPageFooter />
        </Grid>
      </Grid>
    )
  }

  componentWillUnmount() {
    document.body.classList.remove('minheight100')
  }
}

BuscaPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BuscaPage)
