import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ContentBypass from 'support/components/contentbypass/ContentBypass'

const styles = (theme) => ({
  root: {
    marginBottom: 14,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1)
    },
    [theme.breakpoints.down(380)]: {
      paddingBottom: theme.spacing(1)
    }
  },
  photoContainer: {
    textAlign: 'center',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 4
    }
  },
  title: {
    wordBreak: 'break-word'
  },
  iconWithTextIcon: {
    verticalAlign: 'sub',
    fontSize: 22
    //marginRight: 5
  },
  iconWithTextText: {
    fontSize: 15
  }
})

class Subject extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  closeItemMenu = (event) => {
    event.stopPropagation()
    this.setState({ menuAnchorPosition: null })
  }

  render() {
    const { classes, className, style, title, infos, rightActionButton, editIconFunction, moreOptions, photo } = this.props
    const { menuAnchorPosition } = this.state

    return (
      <div className={classNames(classes.root, className)} style={style}>
        <Grid container alignItems="center" spacing={2}>
          {photo && (
            <Grid item className={classes.photoContainer} xs={12} sm="auto">
              {photo}
            </Grid>
          )}
          <Grid item xs>
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item xs>
                <Typography variant="h6" className={classes.title}>
                  {title}
                </Typography>
                {infos &&
                  infos.length > 0 &&
                  infos.map((info, index) => {
                    if (info) {
                      const rootInfoStyle = {}
                      if (info.marginTop === true || info.marginTop === 'big') {
                        rootInfoStyle.marginTop = 8
                      } else if (info.marginTop === 'small') {
                        rootInfoStyle.marginTop = 0
                      }

                      const infoTextStyle = {}

                      if (info.wordBreakAll) {
                        infoTextStyle.wordBreak = 'break-all'
                      }

                      if (!info.type) {
                        return (
                          <Typography key={index} variant="body1" style={infoTextStyle}>
                            {info}
                          </Typography>
                        )
                      } else if (info.type === 'text') {
                        return (
                          <Typography key={index} variant="body1" style={{ ...rootInfoStyle, ...infoTextStyle }}>
                            {info.text}
                          </Typography>
                        )
                      } else if (info.type === 'textWithIcon') {
                        return (
                          <Grid key={index} container alignItems="center" spacing={2} style={rootInfoStyle}>
                            <Grid item>
                              <info.icon className={classes.iconWithTextIcon} />
                            </Grid>
                            <Grid item xs>
                              <Typography variant="body2" className={classes.iconWithTextText} style={infoTextStyle}>
                                {info.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      } else if (info.type === 'component') {
                        return <ContentBypass key={index}>{info.component}</ContentBypass>
                      } else {
                        return <ContentBypass key={index}>{info}</ContentBypass>
                      }
                    }
                    return null
                  })}
              </Grid>
              {editIconFunction && (
                <Grid item>
                  <IconButton onClick={editIconFunction} style={{ marginRight: -6 }}>
                    <EditIcon style={{ display: 'block' }} />
                  </IconButton>
                </Grid>
              )}
              {moreOptions && (
                <React.Fragment>
                  <Grid item>
                    <IconButton
                      style={{ marginRight: -6 }}
                      onClick={(event) => {
                        event.stopPropagation()
                        this.setState({
                          menuAnchorPosition: {
                            left: event.clientX,
                            top: event.clientY
                          }
                        })
                      }}
                    >
                      <MoreVertIcon style={{ display: 'block' }} />
                    </IconButton>
                  </Grid>
                  <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={this.closeItemMenu}>
                    {moreOptions.options.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => {
                          event.stopPropagation()
                          option.onClick()
                          this.closeItemMenu(event)
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </React.Fragment>
              )}
              {rightActionButton && <Grid item>{rightActionButton}</Grid>}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Subject.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Subject)
