import { logAGE } from 'support/util/Logger'

function removeQueryParamsFromUrl(params) {
  const urlParts = window.location.href.split('?')
  if (urlParts[1] !== undefined && params !== undefined && params.length > 0) {
    let newQueryString = ''
    urlParts[1].split('&').forEach((queryStringParameter) => {
      const queryStringParameterName = queryStringParameter.split('=')[0].toLowerCase()
      if (params.indexOf(queryStringParameterName) < 0) {
        if (newQueryString.length > 0) {
          newQueryString += '&'
        }
        newQueryString += queryStringParameter
      }
    })
    let newUrl = urlParts[0]
    if (newQueryString.length > 0) {
      newUrl += '?' + newQueryString
    }
    return newUrl
  } else {
    return urlParts[0]
  }
}

function parseQueryString(query) {
  const vars = query.split('&')
  let query_string = {}
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    const key = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1])
    if (typeof query_string[key] === 'undefined') {
      query_string[key] = decodeURIComponent(value)
    } else if (typeof query_string[key] === 'string') {
      const arr = [query_string[key], decodeURIComponent(value)]
      query_string[key] = arr
    } else {
      query_string[key].push(decodeURIComponent(value))
    }
  }
  return query_string
}

function getUrlQueryStringParsed() {
  const queryString = window.location.search
  if (queryString.length > 0) {
    return parseQueryString(window.location.search.substring(1))
  } else {
    return {}
  }
}

function getUrlQueryStringParameter(param) {
  const queryStringParsed = getUrlQueryStringParsed()
  const value = queryStringParsed[param]
  if (value === undefined || value === null || value.trim().length === 0) {
    return ''
  } else {
    return value
  }
}

function getFullUrlFromRoute(route) {
  return process.env.REACT_APP_WEB_ADDRESS + route
}

const getBaseUrlWithoutQueryParamsOrFragments = (url) => {
  return url.split(/[?#]/)[0].replace(/\/$/, '')
}

const getGoogleSsoURL = (redirectState) => {
  try {
    const REACT_APP_GOOGLE_SSO = process.env.REACT_APP_GOOGLE_SSO
    const REACT_APP_GOOGLE_SSO_CLIENT_ID = process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID
    const REACT_APP_GOOGLE_SSO_CODE_CHALLENGE = process.env.REACT_APP_GOOGLE_SSO_CODE_CHALLENGE

    return (
      REACT_APP_GOOGLE_SSO +
      '&client_id=' +
      REACT_APP_GOOGLE_SSO_CLIENT_ID +
      '&code_challenge=' +
      REACT_APP_GOOGLE_SSO_CODE_CHALLENGE +
      '&redirect_uri=' +
      getFullUrlFromRoute('/conta/login/sso') +
      '&state=' +
      redirectState
    )
  } catch (error) {
    logAGE('Unable retrieve google link from enviroment variables', { component: 'UrlUtil' })
  }
}

export default {
  removeQueryParamsFromUrl,
  getUrlQueryStringParameter,
  getUrlQueryStringParsed,
  getFullUrlFromRoute,
  getBaseUrlWithoutQueryParamsOrFragments,
  getGoogleSsoURL
}
