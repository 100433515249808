export enum CuponsPeriodEnum {
  MENSAL = 'MENSAL',
  TRIMESTRAL = 'TRIMESTRAL',
  SEMESTRAL = 'SEMESTRAL',
  ANUAL = 'ANUAL'
}

export type CuponsPeriodType = keyof typeof CuponsPeriodEnum

export enum CuponsPlansEnum {
  AVALIACAO = 'AVALIACAO',
  INDIVIDUAL = 'INDIVIDUAL',
  EMPRESA = 'EMPRESA'
}

export type CuponsPlansType = keyof typeof CuponsPlansEnum
