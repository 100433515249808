import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import { User, withConfigCatClient } from 'configcat-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import BaseDialog from 'support/components/dialog/BaseDialog'
import TipoTelefoneEnum from 'support/domain/telefone/TipoTelefoneEnum'
import WhatsAppService from 'support/domain/whatsapp/WhatsAppService'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({
  container: {
    alignItems: 'flex-start'
  },
  root: {
    minWidth: 300,
    maxWidth: 480,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  rootTitle: {
    borderBottom: '1px solid ' + theme.palette.divider,
    margin: 0,
    padding: '16px 16px 14px 16px',
    paddingLeft: theme.spacing(3)
  },
  titleMainContent: {
    marginRight: theme.spacing(4)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down(360)]: {
      padding: theme.spacing(2)
    }
  },
  contentHeaderContainer: {
    marginBottom: theme.spacing(2)
  },
  textPrimary: {
    color: theme.palette.text.primary
  }
})

class SolicitacaoTreinamentoDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      telefones: [],
      step: 1,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }
    this.telefonesComponentFunctions = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState({
      ajaxing: true,
      errorsMap: createErrorsMap()
    })

    const telefones = this.telefonesComponentFunctions.getValues()

    postAPI({
      url: 'erp/treinamento.validarTelefoneParaSolicitacaoTreinamento',
      data: {
        telefones: telefones
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxing: false,
          telefones: telefones,
          step: 2
        })
      },
      onError: (response) => {
        this.setState({
          ajaxing: false,
          errorsMap: createErrorsMap(response.data.errors)
        })
        focusFirstElementWithError('formSolicitarTreinamento')
      }
    })
  }

  criarSolicitacaoTreinamento = () => {
    this.setState({
      ajaxing: true
    })
  }

  enviarMensagemWhatsApp = () => {
    postAPI({
      url: 'erp/treinamento.criarSolicitacaoTreinamento',
      data: {
        telefones: [
          {
            tipo: TipoTelefoneEnum.WHATSAPP,
            numero: '(11) 99999-9999'
          }
        ]
      },
      requerAutorizacao: true,
      sendErroToGenericSnackbar: false
    })

    this.setState({
      step: 3
    })
  }

  render() {
    const { classes, origem, ...others } = this.props
    const { telefones, step, ajaxing /*errorsMap*/ } = this.state

    let content = null
    let actions = null

    const linkProps = WhatsAppService.generateClickToChatLinkProps({
      phone: '5511946789049',
      message: 'Olá! Gostaria de receber um treinamento para utilizar o Salão99 Enterprise.',
      onClick: () => {
        this.enviarMensagemWhatsApp()
      }
    })

    if (step === 1) {
      content = (
        <React.Fragment>
          <DialogContentText>Clique no botão abaixo e envie uma mensagem pelo WhatsApp para um especialista Salão99.</DialogContentText>
        </React.Fragment>
      )
      actions = (
        <React.Fragment>
          {/*
						<Button
							onClick={this.props.handleCloseDialog}
							color="primary"
							disabled={this.state.ajaxing}
						>
							Voltar
						</Button>
						*/}
          <Button component="a" disabled={ajaxing} color="primary" {...linkProps}>
            ENVIAR MENSAGEM PELO WHATSAPP
          </Button>
        </React.Fragment>
      )
    } else if (step === 2) {
      content = (
        <React.Fragment>
          <DialogContentText>Verifique se o número do seu WhatsApp foi preenchido corretamente:</DialogContentText>

          <div style={{ fontSize: 28, fontWeight: 500, marginTop: 20, marginBottom: 8 }}>{telefones[0].numero}</div>
        </React.Fragment>
      )
      actions = (
        <React.Fragment>
          <Button
            color="primary"
            disabled={this.state.ajaxing}
            onClick={() => {
              this.setState({ step: 1 })
            }}
          >
            Corrigir
          </Button>
          <Button
            type="submit"
            disabled={ajaxing}
            color="primary"
            onClick={() => {
              this.criarSolicitacaoTreinamento()
            }}
          >
            {ajaxing ? (
              <span>
                <CircularProgress size={18} thickness={5.5} color="inherit" style={{ verticalAlign: 'middle', marginRight: 8 }} /> AGUARDE...
              </span>
            ) : (
              'CONFIRMAR'
            )}
          </Button>
        </React.Fragment>
      )
    } else if (step === 3) {
      const horarioInicial = moment('2020-05-07 08:00:00.000-03:00')
      const horarioFinal = moment('2020-05-07 22:00:00.000-03:00')

      content = (
        <React.Fragment>
          <DialogContentText>
            Obrigado! Um especialista do Salão99 responderá a sua mensagem no WhatsApp assim que possível.
            <br />
            <br />
            <b data-strong="true">Atendimento:</b>
            <br />
            Segunda a sexta, das {horarioInicial.format('HH:mm')} às {horarioFinal.format('HH:mm')}
            {origem === 'cadastro' && (
              <React.Fragment>
                <br />
                <br />
                Enquanto isso, você pode concluir o cadastro da sua conta e navegar pelo sistema. Até breve!
              </React.Fragment>
            )}
          </DialogContentText>
        </React.Fragment>
      )
      actions = (
        <React.Fragment>
          <Button
            color="primary"
            disabled={this.state.ajaxing}
            onClick={() => {
              this.setState({
                step: 1
              })
            }}
          >
            ENVIAR NOVAMENTE
          </Button>
          <Button
            color="primary"
            disabled={this.state.ajaxing}
            onClick={() => {
              this.props.handleCloseDialog({
                onClosedCallback: () => {
                  this.props.onFinish()
                }
              })
            }}
          >
            {origem === 'cadastro' ? 'CONTINUAR' : 'OK'}
          </Button>
        </React.Fragment>
      )
    }

    return (
      <BaseDialog {...others} maxWidth={440} disabledOnClose={this.state.ajaxing} actions={actions}>
        {content}
      </BaseDialog>
    )
  }
}

SolicitacaoTreinamentoDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SolicitacaoTreinamentoDialog)
