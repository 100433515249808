import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import SangriaDialogPage from 'pages/erp/painel/caixa/SangriaDialogPage'
import SangriaEntradaResponsiveTable from 'pages/erp/painel/caixa/table/SangriaEntradaResponsiveTable'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  containerBotao: {
    marginTop: theme.spacing(2),
    ...theme.screen.horizontalMarginStyles(),
    textAlign: 'right'
  }
})

class TabSangrias extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      lista: [],
      paginaCarregada: false
    }
    this.loaderFunctionsMap = {}
  }

  loadTrackingFunction = (data) => {
    if (data.status === 'loading') {
      if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
        this.setState({ errorsMap: FormUtil.createErrorsMap(), lista: [], paginaCarregada: false })
      }
    } else if (data.status === 'loaded') {
      this.setState({ lista: data.items, paginaCarregada: true })
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoSangria', (props) => {
      this.loaderFunctionsMap.refresh()
    })
  }

  handlerOpenCreateDialogPage = () => {
    if (verificarAutorizacao([PA.PODE_REGISTRAR_PROPRIA_SANGRIA])) {
      openBackableDialog(SangriaDialogPage, { caixa: this.props.caixa })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = (item) => {
    if (verificarAutorizacao([PA.PODE_REGISTRAR_PROPRIA_SANGRIA])) {
      openBackableDialog(SangriaDialogPage, { idSangria: item.id, caixa: this.props.caixa })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerRemover = (item) => {
    if (verificarAutorizacao([PA.PODE_REGISTRAR_PROPRIA_SANGRIA])) {
      openBackableDialog(EntityRemovalDialog, {
        title: 'Remover Sangria',
        text: <span>Você tem certeza que deseja remover esta sangria? Essa operação não poderá ser desfeita.</span>,
        call: {
          method: 'post',
          url: 'erp/caixas.removerSangria',
          contextoUsuario: 'erp',
          data: {
            id: item.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoSangria')
          },
          errorKey: 'caixa'
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const { ajaxing, paginaCarregada, lista } = this.state

    let onClickRow = (item) => {
      this.handlerEditar(item)
    }

    const informativo =
      'Esse registro de sangria NÃO representa despesas ou qualquer outro tipo de prejuízo nas contas. Utilize a sangria para registrar uma saída de dinheiro do caixa que não foi um prejuízo, mas sim para deixar o caixa com menos dinheiro no local.'

    return (
      <TabContainer padding={false}>
        {paginaCarregada && (
          <TabContainerPersistHeader
            addTabContainerPadding={true}
            text={
              lista && lista.length > 0 ? (
                <span>
                  Lista de sangrias registradas
                  <br />
                  <small style={{ opacity: 0.5 }}>{informativo}</small>
                </span>
              ) : (
                <span>
                  Nenhuma sangria foi registrada
                  <br />
                  <small style={{ opacity: 0.5 }}>{informativo}</small>
                </span>
              )
            }
            buttonsContent={
              <Button color="secondary" variant="text" style={{ marginRight: -8, marginLeft: -8 }} onClick={this.handlerOpenCreateDialogPage}>
                <AddIcon />
                <span style={{ marginLeft: 8, marginRight: 0 }}>ADICIONAR</span>
              </Button>
            }
          />
        )}

        <SangriaEntradaResponsiveTable
          disabled={ajaxing}
          scrollElement={this.props.scrollContainerRef}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/caixas.buscarSangrias"
          getRequestParametersFunction={() => {
            return {
              idCaixa: this.props.caixa.id
            }
          }}
          items={this.state.lista}
          loadTrackingFunction={this.loadTrackingFunction}
          onClickRow={onClickRow}
          removeFunction={this.handlerRemover}
          emptyListProps={{}}
        />
      </TabContainer>
    )
  }
}

TabSangrias.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabSangrias)
