import { createTheme } from '@material-ui/core/styles'
import PanelPageStyle from 'support/components/panel/PanelPageStyle'
import ContainerStyle from 'support/style/ContainerStyle'
import FormStyle from 'support/style/FormStyle'
import MaterialUIOverride from 'support/style/MaterialUIOverride'
import PageStyle from 'support/style/PageStyle'
import { TemaEnum } from 'support/style/Tema'
import TextStyle from 'support/style/TextStyle'
import UtilStyle from 'support/style/UtilStyle'

export const PLACEHOLDER_COLOR = 'rgba(0, 0, 0, 0.38)'

export function createDefaultTheme() {
  return createTheme({})
}

export function createS99Theme(tema, defaultTheme) {
  if (tema === undefined || tema === null) {
    tema = TemaEnum.PRETO_AZUL
  }
  return generateTheme(defaultTheme, tema)
}

let instanceIdGenerator = 1

function generateTheme(defaultTheme, tema) {
  const dados = tema.dados

  if (!dados.primaryStrong) {
    dados.primaryStrong = {
      light: dados.secondary.light,
      main: dados.secondary.main,
      dark: dados.secondary.dark
    }
  }

  const constants = {
    TOOLBAR_MIN_HEIGHT_EXPANDED: 64,
    TOOLBAR_MIN_HEIGHT_CONTRACTED: 64,

    DRAWER_EXPANDED_WIDTH: 280,
    DRAWER_RETRACTED_WIDTH: defaultTheme.spacing(9),

    BANNER_HEIGHT: 50,

    SCREEN_MARGIN: 40,
    SCREEN_MARGIN_XS: 20,

    CENTERED_CONTAINER_MAX_WIDTH: 1200,

    SCREEN_MARGIN_380: defaultTheme.spacing(2),

    INPUT_FILLED_MARGIN_LEFT: 12,
    INPUT_FILLED_BACKGROUND_COLOR: '#f1f3f4',

    INPUT_BORDER_COLOR: 'rgba(0,0,0,0.13)',

    PLACEHOLDER_COLOR: PLACEHOLDER_COLOR
  }

  return createTheme({
    instanceId: instanceIdGenerator++,
    themeId: tema.id,

    palette: {
      text: {
        primary: 'rgba(0,0,0,0.94)',
        secondary: 'rgba(0,0,0,0.6)',
        negative: '#f36767'
      },
      primaryStrong: {
        light: dados.primaryStrong.light,
        main: dados.primaryStrong.main,
        dark: dados.primaryStrong.dark
      },
      primary: {
        light: dados.primary.light,
        main: dados.primary.main,
        dark: dados.primary.dark,
        contrastText: dados.primary.contrastText ? dados.primary.contrastText : '#fff'
      },
      secondary: {
        light: dados.secondary.light,
        main: dados.secondary.main,
        dark: dados.secondary.dark,
        contrastText: '#fff'
      },
      background: {
        default: dados.background.default,
        negative: dados.background.negative
      },
      chart: {
        ...dados.chart,
        positiveList: ['#4dd684', '#1e88e5', '#b777e0'],
        negativeList: ['#e83141', '#ffa73a']
      },
      snackbar: {
        error: {
          text: '#ffffff',
          background: dados.background.negative
        }
      },
      badge: {
        primaryContrast: {
          text: dados.badge.primaryContrast.text,
          background: dados.badge.primaryContrast.background
        },
        purple: '#722DE8'
      },
      drawer: {
        background: dados.drawer.background
      },
      divider: dados.divider.background,
      placeholder: PLACEHOLDER_COLOR
    },

    agendaInterface: dados.agendaInterface,

    mixins: {
      toolbar: {
        minHeight: constants.TOOLBAR_MIN_HEIGHT_CONTRACTED,
        '@media (min-width: 0px) and (orientation: landscape)': {
          minHeight: constants.TOOLBAR_MIN_HEIGHT_CONTRACTED
        },
        '@media (min-width: 600px)': {
          minHeight: constants.TOOLBAR_MIN_HEIGHT_EXPANDED
        }
      }
    },

    drawer: {
      expandedWidth: constants.DRAWER_EXPANDED_WIDTH,
      retractedWidth: constants.DRAWER_RETRACTED_WIDTH
    },

    banner: {
      height: constants.BANNER_HEIGHT
    },

    inputs: {
      filledMarginLeft: constants.INPUT_FILLED_MARGIN_LEFT,
      filledBackgroundColor: constants.INPUT_FILLED_BACKGROUND_COLOR,
      borderColor: constants.INPUT_BORDER_COLOR
    },

    ...MaterialUIOverride.getProps({ defaultTheme: defaultTheme, tema: tema, constant: constants }),
    ...UtilStyle.getStyles({ defaultTheme: defaultTheme, tema: tema, constant: constants }),
    ...PageStyle.getStyles({ defaultTheme: defaultTheme, tema: tema, constant: constants }),
    ...ContainerStyle.getStyles({ defaultTheme: defaultTheme, tema: tema, constant: constants }),
    ...PanelPageStyle.getStyles({ defaultTheme: defaultTheme, tema: tema, constant: constants }),
    ...FormStyle.getStyles({ defaultTheme: defaultTheme, tema: tema, constant: constants }),
    ...TextStyle.getStyles({ defaultTheme: defaultTheme, tema: tema, constant: constants }),

    screen: {
      margin: constants.SCREEN_MARGIN,
      marginXs: constants.SCREEN_MARGIN_XS,
      margin380: constants.SCREEN_MARGIN_380,
      marginStyles: function () {
        return {
          padding: this.margin,
          [defaultTheme.breakpoints.down('xs')]: {
            padding: this.marginXs
          },
          [defaultTheme.breakpoints.down(380)]: {
            padding: this.margin380
          }
        }
      },
      horizontalMarginStyles: function () {
        return {
          paddingLeft: this.margin,
          paddingRight: this.margin,
          [defaultTheme.breakpoints.down('xs')]: {
            paddingLeft: this.marginXs,
            paddingRight: this.marginXs
          },
          [defaultTheme.breakpoints.down(380)]: {
            paddingLeft: this.margin380,
            paddingRight: this.margin380
          }
        }
      },
      leftMarginStyles: function () {
        return {
          paddingLeft: this.margin,
          [defaultTheme.breakpoints.down('xs')]: {
            paddingLeft: this.marginXs
          },
          [defaultTheme.breakpoints.down(380)]: {
            paddingLeft: this.margin380
          }
        }
      }
    },

    containers: {
      fullHeightMinusToolbar: {
        minHeight: 'calc(100% - ' + constants.TOOLBAR_MIN_HEIGHT_CONTRACTED + 'px)',
        '@media (min-width: 0px) and (orientation: landscape)': {
          minHeight: 'calc(100% - ' + constants.TOOLBAR_MIN_HEIGHT_CONTRACTED + 'px)'
        },
        '@media (min-width: 600px)': {
          minHeight: 'calc(100% - ' + constants.TOOLBAR_MIN_HEIGHT_EXPANDED + 'px)'
        }
      }
    }
  })
}
