import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { ID_DESCONTO_MANUAL } from 'pages/erp/painel/configuracoes/desconto/input/DescontoInputSelect'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class DescontoInputDialogSelect extends Component {
  render() {
    const { classes, disponibilidades, ...others } = this.props
    const props = Object.assign({}, others)

    const podeAplicarDescontoPersonalizado = verificarAutorizacao([PA.PODE_APLICAR_DESCONTO_NCADASTRADO])
    const podeAplicarDescontoCadastrado = verificarAutorizacao([PA.PODE_APLICAR_DESCONTO_CADASTRADO])

    let inputDisabled = false
    if (this.props.disabled || (!podeAplicarDescontoPersonalizado && !podeAplicarDescontoCadastrado)) {
      inputDisabled = true
    }

    if (!props.label) props.label = 'Desconto'
    if (!props.id) props.id = 'xdesconto'
    if (!props.label) props.label = 'xdesconto'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar desconto...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'erp/descontos.buscar'
    props.DialogSelectProps.searchParamName = 'nome'

    props.DialogSelectProps.generateItemData = (desconto) => {
      let disabled = false
      if (desconto.id !== ID_DESCONTO_MANUAL) {
        if (!podeAplicarDescontoCadastrado) {
          disabled = true
        }
      } else if (!podeAplicarDescontoPersonalizado) {
        disabled = true
      }

      return {
        value: desconto.id,
        label: desconto.nome,
        primary: desconto.nome,
        disabled: disabled,
        desconto: desconto
      }
    }

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum desconto foi encontrado.'
      }
    }

    return <InputDialogSelect {...props} disabled={inputDisabled} />
  }
}

DescontoInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DescontoInputDialogSelect)
