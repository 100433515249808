import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import CobrancaEmNotasDetalhesDialogPage from 'pages/erp/painel/relatorio/CobrancaEmNotasDetalhesDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ChartLine from 'support/components/chart/ChartLine'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  tituloInformacoesFinanceiras: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  containerInformacoes: {
    marginTop: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '4px'
    }
  },
  paperInformacoes: {
    ...theme.container.card({ fullHeight: true })
  },
  tituloCard: {
    fontSize: 20,
    paddingTop: 8,
    paddingBottom: 8
  }
})

class RelatorioVendasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxingValoresUltimosMeses: false,
      ajaxingDados: false,
      dadosDataInicial: this.colaboradorLocalPreferences.getMomentPreference('RelatorioVendasDialogPageFiltro_DataInicial', moment().startOf('month')),
      dadosDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioVendasDialogPageFiltro_DataFinal', moment().endOf('month')),
      dadosVendas: {},
      filtroPendentesParaFechamento: true,
      filtroComNotasAbertas: true,
      filtroComNotasFechadas: true,
      filtroAtendimentosFuturos: false
    }
    this.dadosUltimosMesesLoaderFunctionsMap = {}
    this.dadosLoaderFunctionsMap = {}
  }

  carregardadosUltimosMeses = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingValoresUltimosMeses: true })

    getAPI({
      url: 'erp/relatorios.buscarVendasUltimosMeses',
      params: {
        filtroComNotasFechadas: this.state.filtroComNotasFechadas,
        filtroComNotasAbertas: this.state.filtroComNotasAbertas,
        filtroPendentesParaFechamento: this.state.filtroPendentesParaFechamento,
        filtroAtendimentosFuturos: this.state.filtroAtendimentosFuturos
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingValoresUltimosMeses: false,
          dadosUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(response.data, this.props.theme.palette.chart.pieList, (value, name, props) => [
            <span style={{ fontSize: 12 }}>
              {name}: {formatarValorMonetario(value)}
            </span>,
            null
          ])
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingValoresUltimosMeses: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosUltimosMesesParaGrafico = (dadosUltimosMeses, colors, tooltipFormatterFunction) => {
    const dadosUltimosMesesPorMes = {}
    if (dadosUltimosMeses && dadosUltimosMeses.length) {
      for (let dadosReceitaUltimosMeses of dadosUltimosMeses) {
        dadosUltimosMesesPorMes[dadosReceitaUltimosMeses.mes.toString()] = dadosReceitaUltimosMeses
      }
    }

    const agora = moment()
    const dataNavegacao = agora.clone().startOf('month').subtract(11, 'month')
    const criarItem = (mes, vatend, vprod, vpac) => {
      const data = moment(mes.toString(), 'YYYYMM')
      vatend = vatend === null || vatend === undefined ? 0 : vatend
      vprod = vprod === null || vprod === undefined ? 0 : vprod
      vpac = vpac === null || vpac === undefined ? 0 : vpac
      return {
        mes: data.format('MMM/YY'),
        vatend: vatend,
        vprod: vprod,
        vpac: vpac,
        vtotal: vatend + vprod + vpac
      }
    }

    const data = []
    while (true) {
      const mesAtual = dataNavegacao.format('YYYYMM')
      const dadosReceitaUltimosMeses = dadosUltimosMesesPorMes[mesAtual]
      if (dadosReceitaUltimosMeses) {
        data.push(criarItem(mesAtual, dadosReceitaUltimosMeses.valorAtendimentos, dadosReceitaUltimosMeses.valorVendasProduto, dadosReceitaUltimosMeses.valorVendasPacote))
      } else {
        data.push(criarItem(mesAtual, 0, 0, 0))
      }
      if (dataNavegacao.isSame(agora, 'month')) {
        break
      } else {
        dataNavegacao.add(1, 'month')
      }
    }
    return {
      xAxis: {
        dataKey: 'mes',
        height: 80
      },
      tooltip: {
        formatter: tooltipFormatterFunction
      },
      items: [
        {
          name: 'Todos',
          color: colors[0],
          shortName: 'Todos',
          dataKey: 'vtotal'
        },
        {
          name: 'Atendimentos',
          color: colors[1],
          shortName: 'Atendimentos',
          dataKey: 'vatend'
        },
        {
          name: 'Venda de Produtos',
          color: colors[2],
          shortName: 'Produtos',
          dataKey: 'vprod'
        },
        {
          name: 'Venda de Pacotes',
          color: colors[3],
          shortName: 'Pacotes',
          dataKey: 'vpac'
        }
      ],
      data: data
    }
  }

  carregarDados = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingDados: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosVendas',
      params: {
        filtroComNotasFechadas: this.state.filtroComNotasFechadas,
        filtroComNotasAbertas: this.state.filtroComNotasAbertas,
        filtroPendentesParaFechamento: this.state.filtroPendentesParaFechamento,
        filtroAtendimentosFuturos: this.state.filtroAtendimentosFuturos,
        dataInicial: converterMomentParaDataInt(this.state.dadosDataInicial),
        dataFinal: converterMomentParaDataInt(this.state.dadosDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxingDados: false,
          dadosVendas: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingDados: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  abrirDetalhesVendas = (tipo) => {
    openBackableDialog(CobrancaEmNotasDetalhesDialogPage, {
      tipo: tipo,
      dataInicial: this.state.dadosDataInicial,
      dataFinal: this.state.dadosDataFinal,
      filtroComNotasFechadas: this.state.filtroComNotasFechadas,
      filtroComNotasAbertas: this.state.filtroComNotasAbertas,
      filtroPendentesParaFechamento: this.state.filtroPendentesParaFechamento,
      filtroAtendimentosFuturos: this.state.filtroAtendimentosFuturos
    })
  }

  exibirMensagemErro = () => {
    openBackableDialog(MessageDialog, {
      text: <span>É necessário selecionar pelo menos uma opção.</span>
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const {
      ajaxingValoresUltimosMeses,
      ajaxingDados,
      dadosUltimosMeses,
      dadosVendas,
      filtroPendentesParaFechamento,
      filtroComNotasAbertas,
      filtroComNotasFechadas,
      filtroAtendimentosFuturos
    } = this.state

    let toolbarActions = null
    if (!ajaxingDados && !ajaxingValoresUltimosMeses) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.dadosUltimosMesesLoaderFunctionsMap.reload()
              this.dadosLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let dadosUltimosMesesLoadKey =
      filtroPendentesParaFechamento.toString() + '-' + filtroComNotasAbertas.toString() + '-' + filtroComNotasFechadas.toString() + '-' + filtroAtendimentosFuturos.toString()
    let dadosVendasPorPeriodoLoadKey =
      converterMomentParaDataInt(this.state.dadosDataInicial).toString() +
      '-' +
      converterMomentParaDataInt(this.state.dadosDataFinal).toString() +
      '-' +
      filtroPendentesParaFechamento.toString() +
      '-' +
      filtroComNotasAbertas.toString() +
      '-' +
      filtroComNotasFechadas.toString() +
      '-' +
      filtroAtendimentosFuturos.toString()

    let valorAtendimentos = dadosVendas.valorAtendimentos ? dadosVendas.valorAtendimentos : 0
    let valorVendasProduto = dadosVendas.valorVendasProduto ? dadosVendas.valorVendasProduto : 0
    let valorVendasPacote = dadosVendas.valorVendasPacote ? dadosVendas.valorVendasPacote : 0
    let valorTotal = valorAtendimentos + valorVendasProduto + valorVendasPacote

    return (
      <DialogPage
        {...dialogProps}
        title="Atendimentos e Vendas"
        align="center"
        contentMaxWidth={1200}
        pageType="basicEntity"
        ajaxing={ajaxingValoresUltimosMeses || ajaxingDados}
        toolbarActions={toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <Typography variant="h5" align="center" className={classes.tituloInformacoesFinanceiras}>
              Gráfico Mensal
            </Typography>

            <Grid container direction="row" justify="center" style={{ marginTop: '8px', marginBottom: '25px' }}>
              <Grid item>
                <FormControlLabel
                  key="filtroPendentesParaFechamento"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroPendentesParaFechamento}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroComNotasAbertas && !filtroComNotasFechadas && !filtroAtendimentosFuturos) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroPendentesParaFechamento: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Pendente</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  key="filtroComNotasAbertas"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroComNotasAbertas}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroPendentesParaFechamento && !filtroComNotasFechadas && !filtroAtendimentosFuturos) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroComNotasAbertas: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Com Comandas Abertas</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  key="filtroComNotasFechadas"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroComNotasFechadas}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroPendentesParaFechamento && !filtroComNotasAbertas && !filtroAtendimentosFuturos) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroComNotasFechadas: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Com Comandas Fechadas</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  key="filtroAtendimentosFuturos"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroAtendimentosFuturos}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroPendentesParaFechamento && !filtroComNotasAbertas && !filtroComNotasFechadas) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroAtendimentosFuturos: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Atendimentos Futuros</Typography>}
                />
              </Grid>
            </Grid>

            <ContentWithPreload functionsMap={this.dadosUltimosMesesLoaderFunctionsMap} loadFunction={this.carregardadosUltimosMeses} loadKey={dadosUltimosMesesLoadKey}>
              {() => (
                <div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 0 }}>
                    <ChartLine data={dadosUltimosMeses} height={300} />
                  </div>
                </div>
              )}
            </ContentWithPreload>

            <div style={{ padding: '34px 24px 0px 24px', textAlign: 'center' }}>
              <Typography variant="h5" align="center" className={classes.tituloInformacoesFinanceiras}>
                Vendas por Período
              </Typography>
              <div style={{ display: 'inline-block' }}>
                <DateRangeNavigator
                  expanded={false}
                  defaultValue={{
                    tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioVendasDialogPageFiltro_TipoIntervalo', 'mes'),
                    dataInicial: this.state.dadosDataInicial,
                    dataFinal: this.state.dadosDataFinal
                  }}
                  onChange={(data) => {
                    this.colaboradorLocalPreferences.setPreference('RelatorioVendasDialogPageFiltro_TipoIntervalo', data.tipoIntervalo.nome)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioVendasDialogPageFiltro_DataInicial', data.dataInicial)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioVendasDialogPageFiltro_DataFinal', data.dataFinal)
                    this.setState({
                      dadosDataInicial: data.dataInicial,
                      dadosDataFinal: data.dataFinal
                    })
                  }}
                />
              </div>
            </div>

            <Grid container direction="row" justify="center" style={{ marginTop: '0px', marginBottom: '25px' }}>
              <Grid item>
                <FormControlLabel
                  key="filtroPendentesParaFechamento"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroPendentesParaFechamento}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroComNotasAbertas && !filtroComNotasFechadas && !filtroAtendimentosFuturos) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroPendentesParaFechamento: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Pendente</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  key="filtroComNotasAbertas"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroComNotasAbertas}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroPendentesParaFechamento && !filtroComNotasFechadas && !filtroAtendimentosFuturos) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroComNotasAbertas: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Com Comandas Abertas</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  key="filtroComNotasFechadas"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroComNotasFechadas}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroPendentesParaFechamento && !filtroComNotasAbertas && !filtroAtendimentosFuturos) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroComNotasFechadas: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Com Comandas Fechadas</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  key="filtroAtendimentosFuturos"
                  control={
                    <Checkbox
                      color="secondary"
                      disabled={this.state.ajaxing}
                      checked={filtroAtendimentosFuturos}
                      onChange={(event) => {
                        if (!event.target.checked && !filtroPendentesParaFechamento && !filtroComNotasAbertas && !filtroComNotasFechadas) {
                          this.exibirMensagemErro()
                        } else {
                          this.setState({
                            filtroAtendimentosFuturos: event.target.checked
                          })
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">Atendimentos Futuros</Typography>}
                />
              </Grid>
            </Grid>

            <ContentWithPreload functionsMap={this.dadosLoaderFunctionsMap} loadFunction={this.carregarDados} loadKey={dadosVendasPorPeriodoLoadKey}>
              {() => (
                <div className={classes.containerInformacoes}>
                  <Grid container spacing={isWidthDown('xs', this.props.width) ? 1 : 3}>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.paperInformacoes} elevation={1}>
                        <Grid container alignItems="center" style={{ marginTop: -10 }}>
                          <Grid item xs>
                            <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                              Valor Total
                            </Typography>
                          </Grid>
                          <Grid item>
                            {
                              <Tooltip title="Abrir Detalhes">
                                <IconButton
                                  color="secondary"
                                  style={{ marginRight: -8 }}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    this.abrirDetalhesVendas(null)
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Grid>
                        </Grid>
                        <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                          Soma dos valores de todos os atendimentos, vendas de produtos e pacotes.
                        </Typography>
                        <Typography variant="h6" style={{ marginBottom: -6 }}>
                          {formatarValorMonetario(valorTotal)}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div className={classes.paperInformacoes} elevation={1}>
                        <Grid container alignItems="center" style={{ marginTop: -10 }}>
                          <Grid item xs>
                            <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                              Atendimentos
                            </Typography>
                          </Grid>
                          <Grid item>
                            {
                              <Tooltip title="Abrir Detalhes">
                                <IconButton
                                  color="secondary"
                                  style={{ marginRight: -8 }}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    this.abrirDetalhesVendas(TipoVendaEnum.ATENDIMENTO.id)
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Grid>
                        </Grid>
                        <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                          Soma dos valores de todos os atendimentos.
                        </Typography>
                        <Typography variant="h6" style={{ marginBottom: -6 }}>
                          {formatarValorMonetario(valorAtendimentos)}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div className={classes.paperInformacoes} elevation={1}>
                        <Grid container alignItems="center" style={{ marginTop: -10 }}>
                          <Grid item xs>
                            <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                              Venda de Produtos
                            </Typography>
                          </Grid>
                          <Grid item>
                            {
                              <Tooltip title="Abrir Detalhes">
                                <IconButton
                                  color="secondary"
                                  style={{ marginRight: -8 }}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    this.abrirDetalhesVendas(TipoVendaEnum.VENDA_PRODUTO.id)
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Grid>
                        </Grid>
                        <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                          Soma dos valores de todas as vendas de produtos.
                        </Typography>
                        <Typography variant="h6" style={{ marginBottom: -6 }}>
                          {formatarValorMonetario(valorVendasProduto)}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div className={classes.paperInformacoes} elevation={1}>
                        <Grid container alignItems="center" style={{ marginTop: -10 }}>
                          <Grid item xs>
                            <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                              Venda de Pacotes
                            </Typography>
                          </Grid>
                          <Grid item>
                            {
                              <Tooltip title="Abrir Detalhes">
                                <IconButton
                                  color="secondary"
                                  style={{ marginRight: -8 }}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    this.abrirDetalhesVendas(TipoVendaEnum.VENDA_PACOTE.id)
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Grid>
                        </Grid>
                        <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                          Soma dos valores de todas as vendas de pacotes.
                        </Typography>
                        <Typography variant="h6" style={{ marginBottom: -6 }}>
                          {formatarValorMonetario(valorVendasPacote)}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioVendasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(RelatorioVendasDialogPage))
