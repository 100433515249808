import { createStyles, makeStyles, SimplePaletteColorOptions, Theme } from '@material-ui/core'

interface CustomTheme extends Theme {
  palette: Theme['palette'] & {
    primaryStrong: SimplePaletteColorOptions
  }
}

export const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    row: {
      minHeight: '61px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderTop: '1px solid ' + theme.palette.divider,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f7f7f7'
      }
    },
    span: {
      fontWeight: 700,
      fontSize: '0.75rem',
      padding: '10px 0px',
      color: 'rgb(68, 68, 68)'
    },
    textButton: {
      fontWeight: 400,
      fontSize: '0.875rem'
    },
    form: {
      maxWidth: '578px',
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column'
    },
    subTitle: {
      color: theme.palette?.primaryStrong?.main,
      marginTop: '28px',
      marginBottom: '24px'
    },
    grid: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '50%'
    },
    wrapperButtons: {
      marginTop: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '12px',
      '& > button': {
        textTransform: 'capitalize'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    loadingContent: {
      minWidth: '480px',
      maxHeight: '356px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }
  })
)
