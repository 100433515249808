import { useState } from 'react'

import { Button, Flex, Text, useIsMobile } from '@grupoboticario/flora-react'
import { SimplePaletteColorOptions, Theme, useTheme } from '@material-ui/core'
import TrainingModal from 'support/components/trainingModal/TrainingModal'
import { setContributorBannerDismiss } from 'support/util/TrainingBannerEligibility'

import { BannerContainer, MobileBanner } from './Banner.styles'

interface CustomTheme extends Theme {
  palette: Theme['palette'] & {
    primaryStrong: SimplePaletteColorOptions
  }
}

type Props = {
  title: string
  buttonText: string
  contributorId: number
  href: string
  onHide: () => void
}

const Banner = ({ title, buttonText, contributorId, href, onHide }: Props) => {
  const theme = useTheme<CustomTheme>()
  const isMobile = useIsMobile()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDismiss = () => {
    setContributorBannerDismiss(contributorId)

    onHide()

    if (isModalOpen) {
      setIsModalOpen(!isModalOpen)
    }
  }

  const trainingModal = <TrainingModal title="Formulário" href={href} isOpen={isModalOpen} onClose={handleDismiss} />

  if (isMobile) {
    return (
      <>
        <MobileBanner>
          <Flex direction="column" gap="$2">
            <Text color="$light-light" size="auxiliarSmall">
              {title}
            </Text>

            <Flex justify="end" gap="$4">
              <Button variant="unstyled" size="small" onClick={() => handleDismiss()} css={{ color: theme.palette.primaryStrong.light, filter: 'brightness(1.5)' }}>
                Não quero
              </Button>
              <Button
                variant="unstyled"
                size="small"
                onClick={() => setIsModalOpen(true)}
                css={{ color: theme.palette.primaryStrong.light, filter: 'brightness(1.5)', textDecoration: 'none' }}
              >
                Me inscrever
              </Button>
            </Flex>
          </Flex>
        </MobileBanner>

        {trainingModal}
      </>
    )
  }

  return (
    <>
      <BannerContainer
        css={{
          backgroundColor: theme.palette.primaryStrong.main,
          color: theme.palette.primary.contrastText
        }}
      >
        <span>{title}</span>
        <Button variant="alt" onClick={() => setIsModalOpen(true)} css={{ height: 32, padding: '8px 12px', color: theme.palette.primaryStrong.dark, textDecoration: 'none' }}>
          {buttonText}
        </Button>
      </BannerContainer>

      {trainingModal}
    </>
  )
}

export default Banner
