import React from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import VirtualizedList, { ROW_HEIGHT_DEFAULT } from 'support/components/list/virtualizedlist/VirtualizedList'

const DEFAULT_MAX_SMALL_CONTENT_BREAKPOINT = 'xs'
const DEFAULT_MAX_MEDIUM_CONTENT_BREAKPOINT = 'md'

const styles = (theme) => ({
  headerContainer: {
    //paddingLeft: theme.spacing(4),
    //paddingRight: theme.spacing(4),
    //paddingTop: theme.spacing(4),
    //paddingBottom: theme.spacing(2),
    //[theme.breakpoints.down(460)]: {
    //	paddingLeft: theme.spacing(4),
    //	paddingRight: theme.spacing(4),
    //}
  },
  tableWrapper: {
    overflow: 'hidden'
    //overflowX: 'auto',
    //overflowY: 'hidden',
    //paddingTop: 1,
  },
  tableWrapperGrid: {
    backgroundImage: 'linear-gradient(0deg, #e8e8e8 0.89%, #ffffff 0.89%, #ffffff 50%, #e8e8e8 50%, #e8e8e8 50.89%, #ffffff 50.89%, #ffffff 100%)',
    backgroundSize: '112.00px 112.00px',
    backgroundPosition: '0px 0px'
  },
  tableWrapperEmpty: {
    //overflowY: "visible",
    //overflowX: "visible"
    //overflowX: 'hidden'
  },
  row: {
    backgroundColor: '#fff',
    borderTop: '1px solid ' + theme.palette.divider,
    borderBottom: '1px solid ' + theme.palette.divider,
    '&:nth-child(1)': {
      bborderTop: '1px solid transparent'
    }
  },
  rowNotShowFirstBorderTopSmall: {
    '&:first-child': {
      borderTopColor: 'transparent !important'
    }
  },
  rowHeader: {
    borderTop: '1px solid transparent',
    borderBottom: '0px solid transparent',
    '& $cell': {
      wordBreak: 'break-word'
    },
    padding: '10px 0px'
  },
  rowSelectable: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f7f7f7',
      borderTop: '1px solid #ddd',
      borderBottom: '1px solid #ddd',
      zIndex: 2,
      '&:nth-child(1)': {
        borderTop: '1px solid #ddd !important'
      }
    },
    '&$rowSelected:hover': {
      cursor: 'pointer',
      borderTop: '1px solid ' + lighten(theme.palette.secondary.light, 0.3),
      borderBottom: '1px solid ' + lighten(theme.palette.secondary.light, 0.3),
      backgroundColor: lighten(theme.palette.secondary.light, 0.88),
      zIndex: 2,
      '&:nth-child(1)': {
        borderTop: '1px solid ' + lighten(theme.palette.secondary.light, 0.3) + ' !important'
      }
    }
  },
  rowSelected: {
    borderTop: '1px solid ' + lighten(theme.palette.secondary.light, 0.35),
    borderBottom: '1px solid ' + lighten(theme.palette.secondary.light, 0.35),
    backgroundColor: lighten(theme.palette.secondary.light, 0.92),
    zIndex: 2,
    '&:nth-child(1)': {
      borderTop: '1px solid ' + lighten(theme.palette.secondary.light, 0.35) + ' !important'
    }
  },
  rowSoftSelected: {
    borderTop: '1px solid ' + lighten(theme.palette.secondary.light, 0.35),
    borderBottom: '1px solid ' + lighten(theme.palette.secondary.light, 0.35),
    backgroundColor: '#f6f6f6',
    zIndex: 2,
    '&:nth-child(1)': {
      borderTop: '1px solid ' + lighten(theme.palette.secondary.light, 0.35) + ' !important'
    }
  },
  rowAccent: {},
  rowNoBorder: {
    borderTop: '1px solid transparent !important',
    borderBottom: '1px solid transparent !important',
    '&:nth-child(1)': {
      borderTop: '1px solid transparent !important',
      borderBottom: '1px solid transparent !important'
    },
    '&:first-child': {
      borderTop: '1px solid transparent !important',
      borderBottom: '1px solid transparent !important'
    },
    '&:hover': {
      borderTop: '1px solid transparent !important',
      borderBottom: '1px solid transparent !important',
      '&:nth-child(1)': {
        borderTop: '1px solid transparent !important',
        borderBottom: '1px solid transparent !important'
      }
    },
    '&$rowSelected:hover': {
      borderTop: '1px solid transparent !important',
      borderBottom: '1px solid transparent !important',
      '&:nth-child(1)': {
        borderTop: '1px solid transparent !important',
        borderBottom: '1px solid transparent !important'
      }
    }
  },
  cell: {
    minWidth: 0 /*minWidth - para ativar as reticências */,
    backgroundColor: 'inherit',
    maxHeight: '100%',
    marginLeft: theme.spacing(3),
    wordBreak: 'break-word',
    //"&:first-child" : { marginLeft: theme.screen.margin+"px !important" },
    //"&:last-child" : { marginRight: theme.screen.margin+"px !important" },
    '&:first-child': { marginLeft: '0px !important' },
    '&:last-child': { marginRight: '0px !important' },
    [theme.breakpoints.down('xs')]: {
      //"&:first-child" : { marginLeft: theme.screen.marginXs+"px !important" },
      //"&:last-child" : { marginRight: theme.screen.marginXs+"px !important" },
      '&:first-child': { marginLeft: '0px !important' },
      '&:last-child': { marginRight: '0px !important' }
    },
    [theme.breakpoints.down(380)]: {
      //"&:first-child" : { marginLeft: theme.screen.margin380+"px !important" },
      //"&:last-child" : { marginRight: theme.screen.margin380+"px !important" },
      '&:first-child': { marginLeft: '0px !important' },
      '&:last-child': { marginRight: '0px !important' }
    }
  },
  cellPaddingDiff14: {
    //"&:first-child" : { marginLeft: (theme.screen.margin-14)+"px !important" },
    //"&:last-child" : { marginRight: (theme.screen.margin-14)+"px !important" },
    '&:first-child': { marginLeft: '0px !important' },
    '&:last-child': { marginRight: '0px !important' },
    [theme.breakpoints.down('xs')]: {
      //"&:first-child" : { marginLeft: (theme.screen.marginXs-14)+"px !important" },
      //"&:last-child" : { marginRight: (theme.screen.marginXs-14)+"px !important" },
      '&:first-child': { marginLeft: '0px !important' },
      '&:last-child': { marginRight: '0px !important' }
    },
    [theme.breakpoints.down(380)]: {
      //"&:first-child" : { marginLeft: (theme.screen.margin380-14)+"px !important" },
      //"&:last-child" : { marginRight: (theme.screen.margin380-14)+"px !important" },
      '&:first-child': { marginLeft: '0px !important' },
      '&:last-child': { marginRight: '0px !important' }
    }
  },
  cellPaddingDiff6: {
    //"&:first-child" : { marginLeft: (theme.screen.margin-6)+"px !important" },
    //"&:last-child" : { marginRight: (theme.screen.margin-6)+"px !important" },
    '&:first-child': { marginLeft: '0px !important' },
    '&:last-child': { marginRight: '0px !important' },
    [theme.breakpoints.down('xs')]: {
      //"&:first-child" : { marginLeft: (theme.screen.marginXs-6)+"px !important" },
      //"&:last-child" : { marginRight: (theme.screen.marginXs-6)+"px !important" },
      '&:first-child': { marginLeft: '0px !important' },
      '&:last-child': { marginRight: '0px !important' }
    },
    [theme.breakpoints.down(380)]: {
      //"&:first-child" : { marginLeft: (theme.screen.margin380-6)+"px !important" },
      //"&:last-child" : { marginRight: (theme.screen.margin380-6)+"px !important" },
      '&:first-child': { marginLeft: '0px !important' },
      '&:last-child': { marginRight: '0px !important' }
    }
  },
  headerCellTypography: {
    display: 'block',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    lineHeight: '15px'
  },
  cellHorizontalPaddingSmall: {
    //marginLeft: theme.spacing(0)+"px !important",
    marginLeft: '0px !important',
    marginRight: '0px !important',
    '&:first-child': {
      //marginLeft: theme.spacing(2)+"px !important",
      marginLeft: '0px !important'
    },
    '&:last-child': {
      //marginRight: theme.spacing(2)+"px !important",
      marginRight: '0px !important'
    }
  },
  cellNoPadding: {
    marginLeft: '0px !important',
    marginRight: '0px !important',
    padding: '0px !important',
    '&:first-child': {
      padding: '0px !important',
      marginLeft: '0px !important',
      marginRight: '0px !important'
    },
    '&:last-child': {
      padding: '0px !important',
      marginLeft: '0px !important',
      marginRight: '0px !important'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      marginLeft: '0px !important',
      marginRight: '0px !important'
    },
    [theme.breakpoints.down(380)]: {
      padding: '0px !important',
      marginLeft: '0px !important',
      marginRight: '0px !important'
    }
  },
  cellHidden: {
    visibility: 'hidden'
  },
  cellDisplayNone: {
    display: 'none'
  },
  cellText: {
    backgroundColor: 'inherit'
  },
  cellTextWrap: {
    ...theme.text.limitedLines({ maxLines: 3 }),
    lineHeight: '1.1rem',
    //overflow: "hidden",
    position: 'relative',
    //lineHeight: "1.22em",
    //maxHeight: "3.66em" ,
    /*paddingRight: "1em",
		"&:before" : {
			content: "'...'",
			position: "absolute",
			right: 0,
			bottom: -3,
			backgroundColor:"inherit",
			paddingTop: 4,
			paddingLeft: 1,
		},
		"&:after" : {
			content: "''",
			position: "absolute",
			right: 0,
			width: "1em",
			height: "1.22em",
			marginTop: "0.2em",
			backgroundColor:"inherit",
		},*/
    '$rowAccent &': {
      color: theme.palette.secondary.light,
      fontWeight: 500
    },
    '&:last-child': {}
  },
  cellShowUp400: {
    [theme.breakpoints.down(399)]: {
      display: 'none'
    }
  },
  cellShowUpSm: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  cellShowUp800: {
    [theme.breakpoints.down(799)]: {
      display: 'none'
    }
  },
  cellShowUp900: {
    [theme.breakpoints.down(899)]: {
      display: 'none'
    }
  },
  cellShowUpMd: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  cellShowUpLg: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  cellShowUp1500: {
    [theme.breakpoints.down(1499)]: {
      display: 'none'
    }
  },
  cellShowUpXl: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  }
})

class VirtualizedResponsiveTable extends React.Component {
  constructor(props) {
    super(props)
    this.setSizeRenderDefinitions(props.width)
    this.listFunctionsMap = this.props.listFunctionsMap ? this.props.listFunctionsMap : {}
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.width && this.props.width !== nextProps.width) {
      for (let item of nextProps.items) {
        item._update = true
      }
      this.setSizeRenderDefinitions(nextProps.width)
    }
    return true
  }

  setSizeRenderDefinitions = (width) => {
    let maxSmallContentBreakpoint = DEFAULT_MAX_SMALL_CONTENT_BREAKPOINT
    if (this.props.maxSmallContentBreakpoint) {
      maxSmallContentBreakpoint = this.props.maxSmallContentBreakpoint
    }
    if (isWidthDown(maxSmallContentBreakpoint, width)) {
      this.isSmallContent = true
      this.isMediumContent = false
      this.isLargeContent = false
    } else {
      let maxMediumContentBreakpoint = DEFAULT_MAX_MEDIUM_CONTENT_BREAKPOINT
      if (this.props.maxMediumContentBreakpoint) {
        maxMediumContentBreakpoint = this.props.maxMediumContentBreakpoint
      }
      if (isWidthDown(maxMediumContentBreakpoint, width)) {
        this.isSmallContent = false
        this.isMediumContent = true
        this.isLargeContent = true
      } else {
        this.isSmallContent = false
        this.isMediumContent = false
        this.isLargeContent = true
      }
    }
  }

  getRowHeight = () => {
    if (this.renderSmallContent()) {
      if (this.props.smallRenderProps.rowHeight) {
        return this.props.smallRenderProps.rowHeight
      }
    } else if (this.renderMediumContent()) {
      if (this.props.mediumRenderProps.rowHeight) {
        return this.props.mediumRenderProps.rowHeight
      }
    } else {
      if (this.props.largeRenderProps.rowHeight) {
        return this.props.largeRenderProps.rowHeight
      }
    }
    return ROW_HEIGHT_DEFAULT
  }

  getMinTableWidth = () => {
    if (this.renderSmallContent()) {
      if (this.props.smallRenderProps.minTableWidth) {
        return this.props.smallRenderProps.minTableWidth
      }
    } else if (this.renderMediumContent()) {
      if (this.props.mediumRenderProps.minTableWidth) {
        return this.props.mediumRenderProps.minTableWidth
      }
    } else {
      if (this.props.largeRenderProps.minTableWidth) {
        return this.props.largeRenderProps.minTableWidth
      }
    }
    return 0
  }

  getShowHeaderColumns = () => {
    if (this.props.showHeaderColumns !== undefined) {
      return this.props.showHeaderColumns
    } else if (this.renderSmallContent()) {
      if (this.props.smallRenderProps.showHeaderColumns !== undefined) {
        return this.props.smallRenderProps.showHeaderColumns
      }
    } else if (this.renderMediumContent()) {
      if (this.props.mediumRenderProps.showHeaderColumns !== undefined) {
        return this.props.mediumRenderProps.showHeaderColumns
      }
    } else {
      if (this.props.largeRenderProps.showHeaderColumns !== undefined) {
        return this.props.largeRenderProps.showHeaderColumns
      }
    }
    return true
  }

  createColumnsElement = () => {
    const { classes, mediumRenderProps, largeRenderProps, showRowBorders, backgroundColor } = this.props
    const { columns } = this.renderMediumContent() ? mediumRenderProps : largeRenderProps
    //const rowHeight = this.getRowHeight();

    const rowClasses = [classes.row, classes.rowHeader]
    if (showRowBorders === false) {
      rowClasses.push(classes.rowNoBorder)
    }

    /*let columnHeight = null;
		if(this.renderLargeContent() && largeRenderProps.headerColumnHeight){
			columnHeight = largeRenderProps.headerColumnHeight;
		}else if(this.renderMediumContent() && mediumRenderProps.headerColumnHeight){
			columnHeight = mediumRenderProps.headerColumnHeight;
		}else{
			columnHeight = rowHeight+1;
		}*/

    const rowStyle = {
      //height: columnHeight,
      position: 'relative'
    }

    if (backgroundColor) {
      rowStyle.backgroundColor = backgroundColor
    }

    return (
      <Grid container alignItems="center" wrap="nowrap" className={classNames(rowClasses)} style={rowStyle}>
        {columns.map((column, index) => {
          const cellClasses = [classes.cell]

          if (column.horizontalPadding === 'none') {
            cellClasses.push(classes.cellNoPadding)
          } else {
            if (column.cellPaddingDiff === 14) {
              cellClasses.push(classes.cellPaddingDiff14)
            }

            if (column.cellPaddingDiff === 6) {
              cellClasses.push(classes.cellPaddingDiff6)
            }
            if (column.horizontalPadding === 'small') {
              cellClasses.push(classes.cellHorizontalPaddingSmall)
            }
          }

          if (column.className) {
            cellClasses.push(column.className)
          }

          if (column.showOnlyUp) {
            if (column.showOnlyUp === '400' || column.showOnlyUp === 400) {
              cellClasses.push(classes.cellShowUp400)
            } else if (column.showOnlyUp === 'sm') {
              cellClasses.push(classes.cellShowUpSm)
            } else if (column.showOnlyUp === '800' || column.showOnlyUp === 800) {
              cellClasses.push(classes.cellShowUp800)
            } else if (column.showOnlyUp === '900' || column.showOnlyUp === 900) {
              cellClasses.push(classes.cellShowUp900)
            } else if (column.showOnlyUp === 'md') {
              cellClasses.push(classes.cellShowUpMd)
            } else if (column.showOnlyUp === 'lg') {
              cellClasses.push(classes.cellShowUpLg)
            } else if (column.showOnlyUp === '1500' || column.showOnlyUp === 1500) {
              cellClasses.push(classes.cellShowUp1500)
            } else if (column.showOnlyUp === 'xl') {
              cellClasses.push(classes.cellShowUpXl)
            }
          }

          let labelContent = null
          if (typeof column.label === 'string' || (column.label && column.label.type === 'span')) {
            const textClasses = [classes.headerCellTypography, classes.cellText]
            if (column.align !== 'right' || column.wrap) {
              textClasses.push(classes.cellTextWrap)
            }
            labelContent = (
              <Typography variant="caption" style={{ fontWeight: 700, color: '#444' }} className={classNames(textClasses)}>
                {column.label}
              </Typography>
            )
          } else {
            labelContent = column.label
          }

          if (column.isVisible === false) {
            labelContent = <div className={classes.cellHidden}>{labelContent}</div>
          }
          if (column.display === false) {
            cellClasses.push(classes.cellDisplayNone)
          }

          return (
            <Grid key={index} item align={column.align ? column.align : 'left'} {...column.props} className={classNames(cellClasses)}>
              {labelContent}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  rowRenderer = (item, { index, key, style }) => {
    const { classes, onClickRow, showRowBorders, smallRenderProps, mediumRenderProps, largeRenderProps, items, dynamicHeight, backgroundColor, lastLineBorder } = this.props
    const rowHeight = this.getRowHeight()
    //console.log("VirtualizedResponsiveTable - rowRenderer",index);
    return (
      <Row
        key={key}
        index={index}
        style={style}
        classesObject={classes}
        mainOnClickRow={onClickRow}
        showRowBorders={showRowBorders}
        itemsSize={items ? items.length : 0}
        rowHeight={rowHeight}
        smallRenderProps={smallRenderProps}
        mediumRenderProps={mediumRenderProps}
        largeRenderProps={largeRenderProps}
        item={item}
        isSmallContent={this.renderSmallContent()}
        isMediumContent={this.renderMediumContent()}
        isLargeContent={this.renderLargeContent()}
        dynamicHeight={dynamicHeight}
        backgroundColor={backgroundColor}
        lastLineBorder={lastLineBorder}
      />
    )
  }

  renderSmallContent = () => {
    if (this.isSmallContent && this.props.smallRenderProps) {
      return true
    }
    return false
  }

  renderMediumContent = () => {
    if (this.isMediumContent && this.props.mediumRenderProps) {
      return true
    }
    return false
  }

  renderLargeContent = () => {
    if ((this.isMediumContent && !this.props.mediumRenderProps) || this.isLargeContent) {
      return true
    }
    return false
  }

  render() {
    const { classes, functionsMap, ...others } = this.props
    const props = Object.assign({}, others)

    delete props.itemsRenderer
    delete props.columns
    delete props.smallRenderProps
    delete props.mediumRenderProps
    delete props.largeRenderProps
    delete props.listFunctionsMap

    props.rowHeight = this.getRowHeight()
    props.rowRenderer = this.rowRenderer

    const showHeaderColumns = this.getShowHeaderColumns()

    let hasItems = false
    if (this.props.items && this.props.items.length > 0) {
      hasItems = true
    }

    props.functionsMap = this.listFunctionsMap

    let renderPropsRootStyle = null
    if (this.renderSmallContent()) {
      renderPropsRootStyle = this.props.smallRenderProps.rootStyle
    } else if (this.renderMediumContent()) {
      renderPropsRootStyle = this.props.mediumRenderProps.rootStyle
    } else if (this.renderLargeContent()) {
      renderPropsRootStyle = this.props.largeRenderProps.rootStyle
    }

    if (!renderPropsRootStyle) {
      renderPropsRootStyle = {}
    }

    if (this.renderSmallContent() && this.props.smallRenderProps.showFirstItemBorderTop === false && hasItems) {
      if (renderPropsRootStyle.marginTop === undefined || renderPropsRootStyle.marginTop === null) {
        renderPropsRootStyle.marginTop = 0
      }
      renderPropsRootStyle.marginTop = renderPropsRootStyle.marginTop - 16
    }

    const tableWrapperStyle = renderPropsRootStyle

    return (
      <div>
        {this.props.header && <div className={classes.headerContainer}>{this.props.header}</div>}
        <div className={classNames(classes.tableWrapper, hasItems ? null : classes.tableWrapperEmpty)} style={tableWrapperStyle}>
          <div style={{ position: 'relative', minWidth: hasItems ? this.getMinTableWidth() : 0 }}>
            {(showHeaderColumns === undefined || showHeaderColumns === true) && hasItems && !this.renderSmallContent() && this.createColumnsElement()}
            <VirtualizedList {...props} />
          </div>
        </div>
      </div>
    )
  }
}

VirtualizedResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

class Row extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.dynamicHeight) {
      return true
    } else {
      if (nextProps.item._rerender === true) {
        return true
      } else {
        //console.log("OTIMIZACAO,RENDER","VirtualizedResponsiveTable.Row");
        return false
      }
    }
  }

  render() {
    //console.log("RENDER","VirtualizedResponsiveTable.Row - index:"+index);

    const {
      classesObject,
      mainOnClickRow,
      showRowBorders,
      isSmallContent,
      isMediumContent,
      isLargeContent,
      smallRenderProps,
      mediumRenderProps,
      largeRenderProps,
      rowHeight,
      style,
      item,
      index,
      dynamicHeight,
      backgroundColor,
      lastLineBorder
    } = this.props

    let renderProps = {}
    if (isSmallContent) {
      renderProps = smallRenderProps
    } else if (isMediumContent) {
      renderProps = mediumRenderProps
    } else {
      renderProps = largeRenderProps
    }

    const { columns, onClickRow, itemRenderer } = renderProps

    let rowClasses = [classesObject.row]

    if (showRowBorders === false) {
      rowClasses.push(classesObject.rowNoBorder)
    }

    const itemToRender = itemRenderer(item, index)

    let onClickRowToApply = mainOnClickRow

    if (itemToRender.onClickRow) {
      onClickRowToApply = itemToRender.onClickRow
    } else if (onClickRow) {
      onClickRowToApply = onClickRow
    }

    let onClickRowFunction = undefined
    if (onClickRowToApply) {
      onClickRowFunction = (event) => onClickRowToApply(item, event, index)
      rowClasses.push(classesObject.rowSelectable)
    }

    if (itemToRender.selected) {
      rowClasses.push(classesObject.rowSelected)
    }
    if (itemToRender.softSelected) {
      rowClasses.push(classesObject.rowSoftSelected)
    }
    if (itemToRender.rowStyle === 'accent') {
      rowClasses.push(classesObject.rowAccent)
    }

    let gridRowHeight = 'auto'
    if (dynamicHeight) {
      gridRowHeight = style.height
      if (!isNaN(gridRowHeight) /* && itemsSize-1 !== index*/) {
        gridRowHeight++
      }
    } else {
      gridRowHeight = rowHeight
      gridRowHeight++
      if (lastLineBorder && this.props.itemsSize - 1 === index) {
        gridRowHeight--
      }
    }

    let rowContent = null
    if (isLargeContent || isMediumContent) {
      if (renderProps.showFirstItemBorderTop === false) {
        rowClasses.push(classesObject.rowNotShowFirstBorderTopSmall)
      }

      rowContent = itemToRender.itemData.map((itemContent, index) => {
        const column = columns[index]
        const cellClasses = [classesObject.cell]

        if (column.horizontalPadding === 'none') {
          cellClasses.push(classesObject.cellNoPadding)
        } else {
          if (column.cellPaddingDiff === 14) {
            cellClasses.push(classesObject.cellPaddingDiff14)
          }

          if (column.cellPaddingDiff === 6) {
            cellClasses.push(classesObject.cellPaddingDiff6)
          }
          if (column.horizontalPadding === 'small') {
            cellClasses.push(classesObject.cellHorizontalPaddingSmall)
          }
        }

        if (column.className) {
          cellClasses.push(column.className)
        }

        if (column.showOnlyUp) {
          if (column.showOnlyUp === '400' || column.showOnlyUp === 400) {
            cellClasses.push(classesObject.cellShowUp400)
          } else if (column.showOnlyUp === 'sm') {
            cellClasses.push(classesObject.cellShowUpSm)
          } else if (column.showOnlyUp === '800' || column.showOnlyUp === 800) {
            cellClasses.push(classesObject.cellShowUp800)
          } else if (column.showOnlyUp === '900' || column.showOnlyUp === 900) {
            cellClasses.push(classesObject.cellShowUp900)
          } else if (column.showOnlyUp === 'md') {
            cellClasses.push(classesObject.cellShowUpMd)
          } else if (column.showOnlyUp === 'lg') {
            cellClasses.push(classesObject.cellShowUpLg)
          } else if (column.showOnlyUp === '1500' || column.showOnlyUp === 1500) {
            cellClasses.push(classesObject.cellShowUp1500)
          } else if (column.showOnlyUp === 'xl') {
            cellClasses.push(classesObject.cellShowUpXl)
          }
        }

        let content = null
        const contentTypeof = typeof itemContent
        if (contentTypeof === 'string' || contentTypeof === 'number' || (itemContent && itemContent.type && itemContent.type === 'span')) {
          const textClasses = [classesObject.cellText]
          if (column.align !== 'right' && (column.wrap === undefined || column.wrap)) {
            textClasses.push(classesObject.cellTextWrap)
          }
          content = (
            <Typography variant="body2" className={classNames(textClasses)}>
              {itemContent}
            </Typography>
          )
        } else {
          let paddingRight = 0
          if (column.simulateEllipsisPaddingRight !== false && column.align !== 'right' && (column.wrap === undefined || column.wrap)) {
            //paddingRight = "1em";
          }
          content = <div style={{ backgroundColor: 'inherit', paddingRight: paddingRight }}>{itemContent}</div>
        }

        if (column.display === false) {
          cellClasses.push(classesObject.cellDisplayNone)
        }

        return (
          <Grid key={index} item align={column.align ? column.align : 'left'} {...column.props} className={classNames(cellClasses)}>
            {content}
          </Grid>
        )
      })
    } else {
      if (renderProps.showFirstItemBorderTop === false) {
        rowClasses.push(classesObject.rowNotShowFirstBorderTopSmall)
      }

      const cellClasses = [classesObject.cell]
      if (renderProps.horizontalPadding === 'none') {
        cellClasses.push(classesObject.cellNoPadding)
      }

      rowContent = (
        <Grid key={index} item xs className={classNames(cellClasses)}>
          {itemToRender.itemData}
        </Grid>
      )
    }

    if (backgroundColor) {
      style.backgroundColor = backgroundColor
    }
    return (
      <Grid container alignItems="center" wrap="nowrap" style={{ ...style, ...{ height: gridRowHeight } }} className={classNames(rowClasses)} onClick={onClickRowFunction}>
        {rowContent}
      </Grid>
    )
  }
}

export default withStyles(styles)(withWidth()(VirtualizedResponsiveTable))
