import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { generetaURLWithAccessToken } from 'support/components/api/ApiClient'
import { generateColaboradorAccessToken } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import BaseDialog from 'support/components/dialog/BaseDialog'
import NativeApp from 'support/components/nativeapp/NativeApp'

//const MIN_LOADING_TIME = 800;

const styles = (theme) => ({
  paper: {
    width: 'auto'
  },
  errorText: {
    color: theme.palette.error.main
  }
})

class FileExportDownloadDialog extends Component {
  constructor(props) {
    super(props)
    this.closed = false
    this.state = {
      ajaxing: true,
      errorMessage: null,
      fileName: null,
      downloadUrl: null
    }
  }

  componentDidMount = () => {
    this.startLoadingTime = new Date().getTime()
    this.download()
  }

  download = () => {
    this.setState({
      ajaxing: true,
      errorMessage: null
    })

    const callback = (accessToken) => {
      if (this.closed) {
        return
      }

      let urlPrefix = 'erp/export/'

      const fileName = this.props.filename + '.' + this.props.format.extension

      const downloadUrl = generetaURLWithAccessToken({
        accessToken: accessToken,
        url: urlPrefix + this.props.downloadCall.url,
        params: {
          ...this.props.downloadCall.params,
          filename: fileName,
          fileformat: this.props.format.name
        }
      })

      this.setState({
        ajaxing: false,
        downloadUrl: downloadUrl,
        fileName: fileName
      })

      /*
			let delay = MIN_LOADING_TIME - (new Date().getTime() - this.startLoadingTime);
			if(delay < 0){
				delay = 0;
			}

			window.setTimeout(() => {
				this.handleCloseDialog({onClosedCallback : () => {
					window.location.href = downloadUrl;
				}});
			},delay)*/
    }

    if (this.props.accessTokenContext === 'colaborador') {
      generateColaboradorAccessToken({
        callback: callback,
        callProps: {
          sendErroToGenericSnackbar: false,
          repeat: {
            stopFunction: () => {
              return this.closed
            }
          }
        }
      })
    }
  }

  handleCloseDialog = (props) => {
    this.closed = true
    this.props.handleCloseDialog(props)
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { downloadUrl, fileName } = this.state

    dialogProps.handleCloseDialog = this.handleCloseDialog

    const linkProps = {}

    if (DeviceIdentifier.isNativeApp()) {
      linkProps.onClick = () => {
        NativeApp.executeNativeMethod({
          method: 'downloadFile',
          fileName: fileName,
          url: downloadUrl
        })
        this.handleCloseDialog()
      }
    } else {
      linkProps.href = downloadUrl
      linkProps.target = 'fileDownload'
      linkProps.onClick = () => {
        this.handleCloseDialog()
      }
    }

    return (
      <BaseDialog
        {...dialogProps}
        classes={{
          paper: classes.paper
        }}
        maxWidth={410}
        actions={
          <React.Fragment>
            {this.state.errorMessage && (
              <Button onClick={this.download} color="primary" disabled={this.state.ajaxing}>
                TENTAR NOVAMENTE
              </Button>
            )}
          </React.Fragment>
        }
      >
        <React.Fragment>
          {this.state.errorMessage && <DialogContentText>{this.state.errorMessage}</DialogContentText>}
          {this.state.ajaxing && (
            <Grid container alignItems="center" justify="center">
              <Grid item style={{ marginRight: 14 }}>
                <CircularProgress size={22} thickness={5.5} style={{ display: 'block' }} />
              </Grid>
              <Grid item>
                <b>Aguarde...</b>
              </Grid>
            </Grid>
          )}
          {downloadUrl !== null && fileName !== null && (
            <div style={{ padding: '10px 8px 0px 8px', textAlign: 'center' }}>
              <Typography variant="body2">{fileName}</Typography>
              <Button component="a" color="secondary" {...linkProps}>
                Fazer Download
              </Button>
            </div>
          )}
        </React.Fragment>
      </BaseDialog>
    )
  }
}

FileExportDownloadDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FileExportDownloadDialog)
