import React, { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import VariacaoServicoProdutosDialogPage from 'pages/erp/painel/servico/VariacaoServicoProdutosDialogPage'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoUnidadeMedida from 'support/domain/servico/TipoUnidadeMedida'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import NumberUtil from 'support/util/NumberUtil'

const styles = (theme) => ({
  containerVariacao: {
    border: '1px solid #ddd',
    padding: '16px 20px',
    ...theme.util.createBoxShadow('1px 2px 4px 1px rgba(0,0,0,0.075)'),
    borderRadius: 4,
    marginBottom: 16
  }
})

class TabUsoProdutos extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      variacoesServicoAjaxing: []
    }

    this.containerFunctionMap = {}
    this.mounted = true
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoVariacaoServicoProdutos', 'ManipulacaoServico'], (props) => {
      this.containerFunctionMap.reload()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/servicos.buscarVariacoesServicosProdutos',
      params: {
        idServico: this.props.servico.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        response.data.sort(function (a, b) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })
        this.setState({
          variacoesServico: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  abrirVariacaoServicoProdutosDialogPage = (variacaoServico) => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])) {
      openBackableDialog(VariacaoServicoProdutosDialogPage, {
        servico: this.props.servico,
        variacaoServico: variacaoServico
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  onChangeCalcularComissaoCustoProduto = (idVariacaoServico, checked) => {
    const variacoesServicoAjaxing = this.state.variacoesServicoAjaxing.slice()
    variacoesServicoAjaxing[idVariacaoServico] = true
    this.setState({ variacoesServicoAjaxing: variacoesServicoAjaxing })

    postAPI({
      url: 'erp/servicos.alterarVariacaoServicoCalcularComissaoCustoProduto',
      data: {
        id: idVariacaoServico,
        calcularComissaoCustoProduto: checked
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const variacoesServicoAjaxing = this.state.variacoesServicoAjaxing.slice()
        variacoesServicoAjaxing[idVariacaoServico] = false
        this.setState({ variacoesServicoAjaxing: variacoesServicoAjaxing })
      },
      sendErroToGenericSnackbar: false,
      repeat: {
        stopFunction: () => {
          return !this.mounted
        }
      }
    })
  }

  render() {
    const { variacoesServico } = this.state
    const { classes } = this.props

    return (
      <TabContainer>
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.containerFunctionMap}>
          {() => (
            <React.Fragment>
              {variacoesServico.map((variacaoServico) => {
                let produtosContent = null
                let valorTotalCusto = 0

                if (variacaoServico.variacaoServicoProdutos && variacaoServico.variacaoServicoProdutos.length > 0) {
                  variacaoServico.variacaoServicoProdutos.sort(function (a, b) {
                    if (a.id < b.id) return -1
                    if (a.id > b.id) return 1
                    return 0
                  })

                  produtosContent = variacaoServico.variacaoServicoProdutos.map((variacaoServicoProduto) => {
                    let descricao = variacaoServicoProduto.produto.nome
                    let quantidade = null
                    if (TipoUnidadeMedida.QUANTIDADE.id === variacaoServicoProduto.tipoUso.id) {
                      if (variacaoServicoProduto.produto.quantidadeEmbalagem && variacaoServicoProduto.produto.quantidadeEmbalagem > 0) {
                        quantidade =
                          variacaoServicoProduto.quantidadeUso * variacaoServicoProduto.produto.quantidadeEmbalagem + ' ' + variacaoServicoProduto.produto.unidadeMedidaEmbalagem
                      } else {
                        quantidade = variacaoServicoProduto.quantidadeUso + ' unidade' + (variacaoServicoProduto.quantidadeUso !== 1 ? 's' : '')
                      }

                      if (variacaoServicoProduto.produto.precoCompraMaisRecente) {
                        const valorCusto = NumberUtil.round(variacaoServicoProduto.produto.precoCompraMaisRecente * variacaoServicoProduto.quantidadeUso)
                        valorTotalCusto += valorCusto
                        quantidade += ', Custo: ' + formatarValorMonetario(valorCusto)
                      }
                    } else {
                      if (variacaoServicoProduto.produto.quantidadeEmbalagem && variacaoServicoProduto.produto.quantidadeEmbalagem > 0) {
                        quantidade = variacaoServicoProduto.quantidadeUso + ' ' + variacaoServicoProduto.produto.unidadeMedidaEmbalagem
                        if (variacaoServicoProduto.produto.precoCompraMaisRecente) {
                          const valorCusto = NumberUtil.round(
                            ((variacaoServicoProduto.quantidadeUso / variacaoServicoProduto.produto.quantidadeEmbalagem) *
                              100 *
                              variacaoServicoProduto.produto.precoCompraMaisRecente) /
                              100
                          )
                          valorTotalCusto += valorCusto
                          quantidade += ', Custo: ' + formatarValorMonetario(valorCusto)
                        }
                      } else {
                        quantidade = 'Redefinir configuração'
                      }
                    }
                    return (
                      <Typography variant="body2" key={variacaoServicoProduto.id} style={{ marginTop: 8 }}>
                        {descricao}
                        <br />
                        <span style={{ opacity: 0.6, fontSize: 12 }}>
                          <b>Uso: {quantidade}</b>
                        </span>
                      </Typography>
                    )
                  })
                } else {
                  produtosContent = <Typography variant="body2">Nenhum produto configurado</Typography>
                }

                let nomeVariacao = null
                if (variacoesServico.length === 1) {
                  nomeVariacao = this.props.servico.nome
                } else {
                  nomeVariacao = 'Variação: ' + variacaoServico.nome
                }

                return (
                  <div className={classes.containerVariacao} key={variacaoServico.id}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography variant="body1">
                          <b>{nomeVariacao}</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="small"
                          onClick={() => {
                            this.abrirVariacaoServicoProdutosDialogPage(variacaoServico)
                          }}
                        >
                          <SettingsIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {produtosContent}
                    {valorTotalCusto > 0 && (
                      <React.Fragment>
                        {/*<Typography variant="body2" style={{marginTop: 8}}>
															Total de custos: {formatarValorMonetario(valorTotalCusto)}
														</Typography>
														*/}
                        <UncontrolledCheckbox
                          formControlProps={{
                            style: { marginTop: 10 },
                            label: (
                              <span style={{ fontSize: 14, lineHeight: '18px', display: 'inline-block' }}>
                                Calcular comissões apenas sobre o valor líquido do serviço (não gerar comissões sobre os custos de {formatarValorMonetario(valorTotalCusto)})
                                {this.state.variacoesServicoAjaxing[variacaoServico.id] && (
                                  <CircularProgress size={18} thickness={5.5} style={{ verticalAlign: 'text-bottom', marginLeft: 4 }} />
                                )}
                              </span>
                            )
                          }}
                          defaultChecked={!variacaoServico.calcularComissaoCustoProduto}
                          disabled={this.state.variacoesServicoAjaxing[variacaoServico.id]}
                          style={{ marginTop: -8, marginBottom: -8 }}
                          color="default"
                          onChange={(value) => {
                            this.onChangeCalcularComissaoCustoProduto(variacaoServico.id, !value)
                          }}
                        />
                      </React.Fragment>
                    )}
                  </div>
                )
              })}
            </React.Fragment>
          )}
        </ContentWithPreload>
      </TabContainer>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    this.mounted = false
  }
}

TabUsoProdutos.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabUsoProdutos)
