import axios from 'axios'
import qs from 'qs'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SessaoPainelErp from 'support/components/sessao/SessaoPainelErp'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import NegocioPagamentoService from 'support/domain/negociopagamento/NegocioPagamentoService'
import ContextoUsuarioEnum from 'support/domain/usuario/ContextoUsuarioEnum'
import PlatformUtil from 'support/util/PlatformUtil'
import uuidv4 from 'uuid/v4'

import { rehydrateCookieFromLocalStorage } from './RehydrateCookieFromLocalStorage'

const API_VERSION = parseInt(process.env.REACT_APP_API_VERSION, 10)

const SUPER_FORBIDDEN_KEY = 'superForbidden'
const SUPER_FORBIDDEN_CAUSA_NEGOCIO_EXCLUIDO = 'NEGOCIO_EXCLUIDO'

const DEFAULT_TIMEOUT = 1000 * 60

export const HEADER_NAME_NEGOCIO = 'Negocio'

export function postAPI(opts) {
  opts.method = 'post'
  callAPI(opts)
}

export function getAPI(opts) {
  opts.method = 'get'
  callAPI(opts)
}

export function patchAPI(opts) {
  opts.method = 'patch'
  callAPI(opts)
}

export function callAPI(opts) {
  if (opts.data === undefined || opts.data === null) {
    opts.data = {}
  }
  if (opts.params === undefined || opts.params === null) {
    opts.params = {}
  }
  if (opts.headers === undefined || opts.headers === null) {
    opts.headers = {}
  }

  if (opts.contextoUsuario) {
    if (opts.contextoUsuario === 'erp') {
      opts.contextoUsuario = ContextoUsuarioEnum.ERP
    } else if (opts.contextoUsuario === 'admin') {
      opts.contextoUsuario = ContextoUsuarioEnum.ADMIN
    } else if (opts.contextoUsuario === 'portal') {
      opts.contextoUsuario = ContextoUsuarioEnum.PORTAL
    }
  }
  const clientPlatform = PlatformUtil.getPlatformString()
  opts.headers['X-Correlation-Id'] = uuidv4()
  opts.headers['API-Version'] = API_VERSION.toString()
  opts.headers['Client-Platform'] = clientPlatform

  if (opts.requerAutorizacao === true) {
    const isBrowser = clientPlatform === 'browser'
    if (!isBrowser) {
      rehydrateCookieFromLocalStorage('accessToken', 's99-token')
    }
    opts.headers['Autorizacao-Usuario'] = SessaoUsuario.getTokenUsuario()
    if (opts.contextoUsuario) {
      opts.headers['Contexto-Usuario'] = opts.contextoUsuario.id
      if (opts.contextoUsuario.id === ContextoUsuarioEnum.ERP.id) {
        const dadosSessaoUrl = SessaoPainelErp.getDadosNegocioSessaoFromUrl()
        if (dadosSessaoUrl !== null) {
          opts.headers['Colaborador'] = dadosSessaoUrl.idColaborador
          opts.headers[HEADER_NAME_NEGOCIO] = dadosSessaoUrl.idNegocio
        }
      }
    }
  }

  if (opts.isMultiparFormData) {
    opts.headers['Content-Type'] = 'multipart/form-data'
  }

  if (opts.sendErroToGenericSnackbar === undefined) {
    opts.sendErroToGenericSnackbar = true
  }

  if (opts.headers) {
    for (let key in opts.headers) {
      if (opts.headers[key] === null) {
        opts.headers[key] = ''
      }
    }
  }

  if (opts.timeout === undefined || opts.timeout === null) {
    opts.timeout = DEFAULT_TIMEOUT
  }
  axios({
    method: opts.method,
    url: process.env.REACT_APP_API_ENDPOINT + '/' + opts.url,
    data: opts.data,
    params: opts.params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
    headers: opts.headers,
    responseType: opts.responseType,
    timeout: opts.timeout,
    withCredentials: true
  })
    .then((response) => {
      var successResponseObject = {}
      successResponseObject.code = response.status
      successResponseObject.data = response.data
      successResponseObject.headers = response.headers
      if (successResponseObject.headers['s99-token']) {
        localStorage.setItem('s99-token', successResponseObject.headers['s99-token'])
      }

      if (opts.onPreFinal !== undefined) {
        try {
          opts.onPreFinal(successResponseObject)
        } catch (err) {
          EventsManager.pub('MensagemSnackBar', 'Erro inesperado (cod:1). Tente novamente.')
          console.log(err)
        }
      }
      if (opts.onSuccess !== undefined) {
        try {
          opts.onSuccess(successResponseObject)
        } catch (err) {
          EventsManager.pub('MensagemSnackBar', 'Erro inesperado (cod:2). Tente novamente.')
          console.log(err)
        }
      }
      if (opts.onPostFinal !== undefined) {
        try {
          opts.onPostFinal(successResponseObject)
        } catch (err) {
          EventsManager.pub('MensagemSnackBar', 'Erro inesperado (cod:3). Tente novamente.')
          console.log(err)
        }
      }
    })
    .catch((error) => {
      var errorResponseObject = {}
      if (error.response) {
        errorResponseObject.code = error.response.status
        errorResponseObject.data = error.response.data
      } else {
        errorResponseObject.code = -1
        errorResponseObject.data = error.message
      }

      let userMessage = 'Erro inesperado. Tente novamente.'
      const mainMessage = getErrorValueByKey(errorResponseObject, 'main')
      if (mainMessage !== undefined && mainMessage !== null) {
        userMessage = mainMessage
      } else {
        if (errorResponseObject.code === -1) {
          userMessage = 'Falha de conexão com a internet'
        } else if (errorResponseObject.code >= 500 && errorResponseObject.code < 600) {
          userMessage = 'Erro inesperado. Tente novamente em alguns instantes.'
        } else if (errorResponseObject.code === 403) {
          const superForbidden = getErrorValueByKey(errorResponseObject, SUPER_FORBIDDEN_KEY)
          if (superForbidden !== SUPER_FORBIDDEN_CAUSA_NEGOCIO_EXCLUIDO) {
            userMessage = 'Acesso negado'
          }
        }
      }

      errorResponseObject.userMessage = userMessage

      if (opts.onPreFinal !== undefined) {
        opts.onPreFinal(errorResponseObject)
      }

      if (errorResponseObject.code === 404) {
        const updateClientApiVersionError = getErrorValueByKey(errorResponseObject, 'updateClientApiVersion')
        if (updateClientApiVersionError) {
          EventsManager.pub('UpdateClienteApplication')
          opts.sendErroToGenericSnackbar = false
        }
      }

      if (errorResponseObject.code === 401) {
        EventsManager.pub('FalhaAutenticacao')
      }

      if (errorResponseObject.code === 403) {
        const superForbidden = getErrorValueByKey(errorResponseObject, SUPER_FORBIDDEN_KEY)
        if (superForbidden) {
          if (opts.contextoUsuario && opts.contextoUsuario.id === ContextoUsuarioEnum.ERP.id) {
            //EventsManager.pub("LimparSessao",{colaborador:true,negocio:true});
            opts.sendErroToGenericSnackbar = false

            if (opts.subscriptionEnabled || opts.useSubscription) {
              window.location.href = NegocioPagamentoService.gerarUrlDeRedirecionamentoParaPlanos(opts.useSubscription)
              return
            }

            return EventsManager.pub('NavegarPaginaPainel', { rota: '/plano', adicionarDadosNegocioSessao: true, replace: true })
          }
        }
      }

      if (errorResponseObject.code >= 400 && errorResponseObject.code < 500) {
        if (opts.onClientError !== undefined) {
          opts.onClientError(errorResponseObject)
        }
      } else if (errorResponseObject.code >= 500) {
        if (opts.onServerError !== undefined) {
          opts.onServerError(errorResponseObject)
        }
      } else if (errorResponseObject.code === -1) {
        if (opts.onClientConnectionError !== undefined) {
          opts.onClientConnectionError(errorResponseObject)
        }
      }

      if (opts.onError !== undefined) {
        opts.onError(errorResponseObject)
      }

      if (opts.onPostFinal !== undefined) {
        opts.onPostFinal(errorResponseObject)
      }

      let shownGenericSnackbar = false

      if (mainMessage !== undefined && mainMessage !== null) {
        EventsManager.pub('MensagemSnackBar', mainMessage)
        shownGenericSnackbar = true
      }

      if (shownGenericSnackbar === false && opts.sendErroToGenericSnackbar === true) {
        if (errorResponseObject.code === -1) {
          EventsManager.pub('MensagemSnackBar', userMessage)
        } else if (errorResponseObject.code >= 500 && errorResponseObject.code < 600) {
          EventsManager.pub('MensagemSnackBar', userMessage)
        } else if (errorResponseObject.code === 403) {
          const superForbidden = getErrorValueByKey(errorResponseObject, SUPER_FORBIDDEN_KEY)
          if (!superForbidden) {
            EventsManager.pub('AtualizarSessao', { numeroMaximoTentativas: 2 })
          }
          if (superForbidden !== SUPER_FORBIDDEN_CAUSA_NEGOCIO_EXCLUIDO) {
            EventsManager.pub('MensagemSnackBar', userMessage)
          }
        } else if (errorResponseObject.code === 409) {
          EventsManager.pub('MensagemSnackBar', userMessage)
        }
      }

      if (opts.repeat) {
        const { times, stopFunction } = opts.repeat
        if (!times || times >= (opts.currentRepeat ? opts.currentRepeat : 0) + 1) {
          if (!opts.currentRepeat) {
            opts.currentRepeat = 1
          } else {
            opts.currentRepeat = opts.currentRepeat + 1
          }
          window.setTimeout(() => {
            if (!stopFunction || stopFunction() === false) {
              callAPI(opts)
            }
          }, getDelayToRepeat(opts.currentRepeat))
        }
      }
    })
}

function getErrorValueByKey(errorResponseObject, key) {
  if (errorResponseObject.data && errorResponseObject.data.errors) {
    return errorResponseObject.data.errors[key]
  }
  return null
}

function getDelayToRepeat(currentRepeat) {
  if (currentRepeat <= 1) {
    return 0
  } else if (currentRepeat <= 2) {
    return 150
  } else if (currentRepeat <= 4) {
    return 300
  } else if (currentRepeat <= 6) {
    return 1000
  } else if (currentRepeat <= 8) {
    return 3000
  } else if (currentRepeat <= 10) {
    return 5000
  } else if (currentRepeat <= 12) {
    return 15000
  } else {
    return 30000
  }
}

export function generetaURLWithAccessToken(opts) {
  if (!opts.params) {
    opts.params = {}
  }

  let url = process.env.REACT_APP_API_ENDPOINT + '/' + opts.url + '?'
  const params = {
    apiVersion: API_VERSION,
    accessToken: opts.accessToken.id + '-' + opts.accessToken.value,
    ...opts.params
  }

  for (let prop in params) {
    const value = params[prop]
    url += encodeURIComponent(prop) + '=' + (value !== undefined && value !== null ? encodeURIComponent(value) : '') + '&'
  }
  url = url.substring(0, url.length - 1)
  return url
}
