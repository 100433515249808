import RegexUtil from 'support/util/RegexUtil'

function processTemplate(messageTemplate, values) {
  Object.keys(values).forEach(function (key) {
    messageTemplate = messageTemplate.replace(new RegExp(RegexUtil.escape(key), 'g'), values[key])
  })
  return messageTemplate
}

const functionsObject = {
  processTemplate: processTemplate
}

export default functionsObject
