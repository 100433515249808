import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import CakeIcon from '@material-ui/icons/Cake'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import InstagramIcon from '@material-ui/icons/Instagram'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PlaceIcon from '@material-ui/icons/Place'
import PublicIcon from '@material-ui/icons/Public'
import SubtitlesIcon from '@material-ui/icons/Subtitles'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import WorkIcon from '@material-ui/icons/Work'
import ClienteDadosBasicosDialogPage from 'pages/erp/painel/cliente/ClienteDadosBasicosDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import NativeApp from 'support/components/nativeapp/NativeApp'
import TabContainer from 'support/components/tab/TabContainer'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import TipoTelefoneEnum from 'support/domain/telefone/TipoTelefoneEnum'
import WhatsAppService from 'support/domain/whatsapp/WhatsAppService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarCNPJ, formatarCPF, formatarDataNascimento, formatarEndereco } from 'support/util/TextFormatter'

const styles = (theme) => ({
  tituloDadosCadastrais: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 0,
    [theme.breakpoints.down(600)]: {
      fontSize: 16
    }
  },
  tituloDataCadastro: {
    fontSize: 12,
    marginBottom: 12
  },
  iconeLinkExterno: {
    display: 'block',
    marginTop: 2,
    width: 19,
    height: 19
  }
})

class TabGeral extends Component {
  constructor(props) {
    super(props)
  }

  handlerEditar = () => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_CLIENTE])) {
      openBackableDialog(ClienteDadosBasicosDialogPage, { idCliente: this.props.cliente.id })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const { classes, cliente } = this.props

    const dadosCadastraisContent = []

    if (cliente) {
      if (getSessaoPainel().negocio.carregado && ControleAgendamentoOnlineEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleAgendamentoOnline.id) {
        let iconContent = null
        let textContent = null

        if (cliente.controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.HABILITADO.id) {
          iconContent = <PublicIcon style={{ display: 'block' }} color="secondary" />
          textContent = (
            <Typography variant="body2" color="secondary">
              Agendamento Online Habilitado
            </Typography>
          )
        } else {
          iconContent = <PublicIcon style={{ display: 'block', opacity: 0.4 }} />
          textContent = (
            <Typography style={{ display: 'block', opacity: 0.6 }} variant="body2">
              Agendamento Online Desabilitado
            </Typography>
          )
        }
        dadosCadastraisContent.push(
          <Grid key="controleAgendamentoOnlineContent" container alignItems="center" style={{ marginTop: 4, marginBottom: cliente.anotacao ? 4 : 18 }}>
            <Grid item style={{ marginRight: 16 }}>
              {iconContent}
            </Grid>
            <Grid item>{textContent}</Grid>
          </Grid>
        )
      }

      if (cliente.anotacao) {
        dadosCadastraisContent.push(
          <Grid key="anotacao" container alignItems="center" style={{ marginTop: 4, marginBottom: 18 }}>
            <Grid item style={{ marginRight: 16 }}>
              <InfoIcon style={{ display: 'block' }} color="secondary" />
            </Grid>
            <Grid item>
              <Typography variant="body2" color="secondary">
                {cliente.anotacao}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      dadosCadastraisContent.push(
        <Grid key={'nome'} container alignItems="center" style={{ marginTop: 4 }}>
          <Grid item style={{ marginRight: 16 }}>
            <AccountBoxIcon style={{ display: 'block' }} />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
              {cliente.nome}
            </Typography>
          </Grid>
        </Grid>
      )

      if (cliente.telefones && cliente.telefones.length) {
        cliente.telefones.sort(function (a, b) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })

        for (let i = 0; i < cliente.telefones.length; i++) {
          const telefone = cliente.telefones[i]

          let buttonPropsWhatsapp = null

          if (telefone.tipo.id === TipoTelefoneEnum.WHATSAPP.id) {
            buttonPropsWhatsapp = {}

            buttonPropsWhatsapp = WhatsAppService.generateClickToChatLinkProps({
              phone: telefone.codigoDiscagemPais + telefone.numero.replace(/[() -]/g, ''),
              message: ''
            })
            buttonPropsWhatsapp.component = Link
          }

          dadosCadastraisContent.push(
            <Grid key={'telefone' + telefone.id} container alignItems="center" style={{ marginTop: 4 }}>
              <Grid item style={{ marginRight: 16 }}>
                <ContactPhoneIcon style={{ display: 'block' }} />
              </Grid>
              <Grid item>
                <Typography variant="body2">{telefone.numero}</Typography>
              </Grid>
              {buttonPropsWhatsapp && (
                <Grid item>
                  <IconButton size="small" {...buttonPropsWhatsapp} style={{ marginBottom: 3, marginLeft: 5 }}>
                    <WhatsAppIcon className={classes.iconeLinkExterno} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )
        }
      }
      if (cliente.usuario && cliente.usuario.email) {
        dadosCadastraisContent.push(
          <Grid key={'email'} container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 16 }}>
              <ContactMailIcon style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                {cliente.usuario.email}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      if (cliente.instagram) {
        const urlInstagram = 'http://www.instagram.com.br/' + cliente.instagram
        const buttonPropsInstagram = {}

        if (DeviceIdentifier.isNativeApp()) {
          buttonPropsInstagram.onClick = () => {
            NativeApp.executeNativeMethod({
              method: 'openLink',
              url: urlInstagram
            })
          }
        } else {
          buttonPropsInstagram.component = 'a'
          buttonPropsInstagram.href = urlInstagram
          buttonPropsInstagram.target = '_blank'
        }

        dadosCadastraisContent.push(
          <Grid key={'instagram'} container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 16 }}>
              <InstagramIcon style={{ display: 'block' }} />
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                {cliente.instagram}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                {...buttonPropsInstagram}
                style={{ marginBottom: 3, marginLeft: 5, visibility: verificarAutorizacao([PA.PODE_VISUALIZAR_DADOS_CONTATO_CLIENTE]) ? 'visible' : 'hidden' }}
              >
                <OpenInNewIcon className={classes.iconeLinkExterno} />
              </IconButton>
            </Grid>
          </Grid>
        )
      }

      if (cliente.diaNascimento && cliente.mesNascimento) {
        dadosCadastraisContent.push(
          <Grid key={'dataNascimento'} container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 16 }}>
              <CakeIcon style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                {formatarDataNascimento(cliente)}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      if (cliente.cnpj) {
        dadosCadastraisContent.push(
          <Grid key={'cnpj'} container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 16 }}>
              <SubtitlesIcon style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                <b>CNPJ:</b> {formatarCNPJ(cliente.cnpj)}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      if (cliente.cpf) {
        dadosCadastraisContent.push(
          <Grid key={'cpf'} container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 16 }}>
              <SubtitlesIcon style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                <b>CPF:</b> {formatarCPF(cliente.cpf)}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      if (cliente.rg) {
        dadosCadastraisContent.push(
          <Grid key={'rg'} container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 16 }}>
              <SubtitlesIcon style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                <b>RG:</b> {cliente.rg}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      if (cliente.profissao) {
        dadosCadastraisContent.push(
          <Grid key={'profissao'} container alignItems="center" style={{ marginTop: 4 }}>
            <Grid item style={{ marginRight: 16 }}>
              <WorkIcon style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                {cliente.profissao}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      let endereco = ''

      if (cliente.endereco) {
        endereco = formatarEndereco(cliente.endereco)

        if (endereco.length > 0) {
          dadosCadastraisContent.push(
            <Grid key={'endereco'} container alignItems="center" style={{ marginTop: 4 }}>
              <Grid item style={{ marginRight: 16 }}>
                <PlaceIcon style={{ display: 'block' }} />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                  {endereco}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      }
    }

    if (dadosCadastraisContent.length === 0) {
      dadosCadastraisContent.push(
        <Typography key={'informacoesnaopreenchidas'} variant="body2" style={{ marginTop: 4 }}>
          Informações não preenchidos
        </Typography>
      )
    }

    return (
      <TabContainer>
        {cliente && (
          <React.Fragment>
            <Grid container alignItems="center" style={{ marginTop: -8 }}>
              <Grid item xs>
                <Typography variant="h6" className={classes.tituloDadosCadastrais}>
                  Dados Cadastrais
                </Typography>
                <Typography variant="body2" className={classes.tituloDataCadastro}>
                  Data de Cadastro: {converterDataIntParaMoment(cliente.dataCadastro).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: 8 }}>
                <IconButton onClick={this.handlerEditar}>
                  <EditIcon style={{ display: 'block' }} />
                </IconButton>
              </Grid>
            </Grid>
            {dadosCadastraisContent}
          </React.Fragment>
        )}
      </TabContainer>
    )
  }
}

TabGeral.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabGeral)
