import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 90,
    minWidth: 90
  },
  colunaNota: {
    maxWidth: 130,
    minWidth: 130
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaCliente: {
    flexGrow: 1
  },
  colunaValores: {
    maxWidth: 200,
    minWidth: 200,
    [theme.breakpoints.down(900)]: {
      paddingLeft: 16
    }
  },
  notaContentSmall: {
    [theme.breakpoints.up(900)]: {
      display: 'none'
    }
  }
})

class PagamentosResponsiveTable extends Component {
  getRenderContent = (size, item) => {
    const content = {}

    content.dataPagamento = converterDataIntParaMoment(item.dataPagamento).format('DD/MMM/YY')
    content.nomeCliente = item.cliente.apelido
    content.notaContent = null

    if (size === 'small') {
      content.notaContent = (
        <Grid key="notaContent" container alignItems="flex-start" justify="flex-start" style={{ marginTop: 2 }}>
          <Grid item>
            <Typography variant="body2">Fatura: #{item.notaVenda.numero}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              style={{ marginTop: -7, padding: 6 }}
              onClick={() => {
                openBackableDialog(NotaDialogPage, {
                  parent: this,
                  shouldCloseParent: false,
                  idNota: item.notaVenda.id
                })
              }}
            >
              <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      )
    } else {
      const notaContentInner = (
        <React.Fragment>
          <Grid item style={{ marginTop: 1 }}>
            <Typography variant="body2">#{item.notaVenda.numero}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              style={{ marginTop: -1, marginRight: -4, padding: 6 }}
              onClick={() => {
                openBackableDialog(NotaDialogPage, {
                  parent: this,
                  shouldCloseParent: false,
                  idNota: item.notaVenda.id
                })
              }}
            >
              <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
            </IconButton>
          </Grid>
        </React.Fragment>
      )

      content.notaContent = (
        <Grid key="notaContent" container alignItems="center" justify="flex-start">
          {notaContentInner}
        </Grid>
      )

      content.notaContentMediumDynamic = (
        <Grid key="notaContent" container alignItems="center" justify="flex-start" className={this.props.classes.notaContentSmall}>
          {notaContentInner}
        </Grid>
      )
    }

    content.nomeFormaPagamento = item.formaPagamento.nome
    if (item.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id || item.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CHEQUE.id) {
      let complementoFormaPagamento
      if (item.numeroParcelas === 1) {
        complementoFormaPagamento = 'À vista'
      } else {
        complementoFormaPagamento = item.numeroParcelas + 'x'
      }
      content.nomeFormaPagamento = content.nomeFormaPagamento + ' (' + complementoFormaPagamento + ')'
    }

    let taxas = item.valorPago - item.valorCalculado

    if (
      item.valorTotalEstornos > 0 &&
      (item.formaPagamentoEstorno.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id ||
        item.formaPagamentoEstorno.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id ||
        item.formaPagamentoEstorno.tipo.id === FormaPagamentoTipoEnum.PIX.id)
    ) {
      taxas = 0
    }

    let valorPago = item.valorPagoFinal

    if (item.formaPagamentoEstorno && item.formaPagamentoEstorno.tipo.id === FormaPagamentoTipoEnum.CREDITO.id) {
      valorPago = item.valorPago
    }

    if (size === 'small') {
      content.valorTaxas = <React.Fragment>Taxas: {formatarValorMonetario(taxas)}</React.Fragment>
      content.valorPago = <React.Fragment>Valor Pago: {formatarValorMonetario(valorPago)}</React.Fragment>
    } else {
      content.valorTaxas = formatarValorMonetario(taxas)
      content.valorPago = formatarValorMonetario(valorPago)
    }
    return content
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <VirtualizedResponsiveTable
        showBackgroundDividers={true}
        largeRenderProps={{
          columns: [
            { label: 'Pagamento', className: classes.colunaData },
            { label: 'Cliente', className: classes.colunaCliente, props: { xs: true }, horizontalPadding: 'small' },
            { label: 'Forma Pagamento', props: { xs: true }, align: 'left' },
            { label: 'Fatura', className: classes.colunaNota, horizontalPadding: 'small', align: 'left' },
            { label: 'Valor Pago', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
            { label: 'Taxa', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
          ],
          itemRenderer: (comissao, index) => {
            const content = this.getRenderContent('large', comissao)

            return {
              itemData: [content.dataPagamento, content.nomeCliente, content.nomeFormaPagamento, content.notaContent, content.valorPago, content.valorTaxas]
            }
          }
        }}
        mediumRenderProps={{
          headerColumnHeight: 68,
          rowHeight: 106,
          columns: [
            { label: 'Pagamento', className: classes.colunaData },
            { label: 'Descrição', className: classes.colunaCliente, props: { xs: true }, horizontalPadding: 'small' },
            { label: 'Fatura', className: classes.colunaNota, props: { xs: true }, showOnlyUp: '900', align: 'left' },
            { label: 'Valores', className: classes.colunaValores, horizontalPadding: 'small', align: 'left' }
          ],
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('medium', item)

            return {
              itemData: [
                content.dataPagamento,
                <React.Fragment>
                  <Typography variant="body2" noWrap={true}>
                    <b>{content.nomeCliente}</b>
                  </Typography>
                  <Typography variant="body2" noWrap={true} style={{ marginTop: 6 }}>
                    {content.nomeFormaPagamento}
                  </Typography>
                  {content.notaContentMediumDynamic}
                </React.Fragment>,
                content.notaContent,
                <React.Fragment>
                  <Typography variant="body2" noWrap={true}>
                    Pago: {content.valorPago}
                  </Typography>
                  <Typography variant="body2" noWrap={true}>
                    Taxa: {content.valorTaxas}
                  </Typography>
                </React.Fragment>
              ]
            }
          }
        }}
        smallRenderProps={{
          rowHeight: 166,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('small', item)

            return {
              itemData: (
                <React.Fragment>
                  <Grid container alignItems="center" style={{ minWidth: 0 }}>
                    <Grid item xs style={{ minWidth: 0 }}>
                      <Typography variant="body2">{content.dataPagamento}</Typography>
                    </Grid>
                    <Grid item align="right" style={{ minWidth: 0 }}>
                      {content.notaContent}
                    </Grid>
                  </Grid>
                  <Typography variant="body2" noWrap={true}>
                    <b>{content.nomeCliente}</b>
                  </Typography>
                  <Typography variant="body2" noWrap={true}>
                    {content.nomeFormaPagamento}
                  </Typography>
                  <Typography variant="body2" noWrap={true}>
                    {content.valorPago}
                  </Typography>
                  <Typography variant="body2" noWrap={true}>
                    {content.valorTaxas}
                  </Typography>
                </React.Fragment>
              )
            }
          }
        }}
        emptyListProps={{
          text: 'Não existem pagamentos para o período informado'
        }}
        {...others}
      />
    )
  }
}

PagamentosResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(PagamentosResponsiveTable)
