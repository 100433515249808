import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import ClientePacoteItemManipulacaoService from 'support/domain/cliente/ClientePacoteItemManipulacaoService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

const styles = (theme) => ({
  colunaQuantidade: {
    flexGrow: 0.5
  },
  colunaData: {
    flexGrow: 0.8,
    maxWidth: 120,
    minWidth: 100
  }
})

class HistoricoPacoteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Histórico do Pacote',
      lista: []
    }
  }

  getRenderContent = (size, item) => {
    const content = {}
    const style = {}

    if (ClientePacoteItemManipulacaoService.isManipulacaoUso(item)) {
      const venda = item.manipulacaoUso.venda

      if (venda.atendimento.cancelado) {
        style.textDecoration = 'line-through'
        style.color = '#aaa'
      }
    }

    content.data = <span style={style}>{converterDataIntParaMoment(item.dataManipulacao).format('DD/MMM/YYYY')}</span>
    content.descricao = <span style={style}>{ClientePacoteItemManipulacaoService.getDescricao(item)}</span>
    content.profissionalText = <span style={style}>Profissional: </span>

    if (size === 'small') {
      content.quantidade = <span style={style}>Qtd.: {item.quantidade}</span>
    } else {
      content.quantidade = <span style={style}>{item.quantidade}</span>
    }

    return content
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/clientes.buscarClientePacoteItemPorId',
      params: { idClientePacoteItem: this.props.idClientePacoteItem },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({ clientePacoteItem: response.data })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { clientePacoteItem } = this.state

    let descricaoCreditos = null

    if (clientePacoteItem) {
      descricaoCreditos = (
        <span>
          <b>Qtd. Disponível: </b>
          {clientePacoteItem.quantidadeDisponivel.toString()}
        </span>
      )

      if (clientePacoteItem.quantidadeDisponivel > 0) {
        let status = null
        if (clientePacoteItem.cancelado) {
          status = 'Cancelado'
        } else if (clientePacoteItem.dataVencimento) {
          if (converterDataIntParaMoment(clientePacoteItem.dataVencimento).isBefore(moment(), 'day')) {
            status = 'Vencido'
          }
        }
        if (status) {
          descricaoCreditos = (
            <React.Fragment>
              <strike>
                <b>Qtd. Disponível: </b>
                {clientePacoteItem.quantidadeDisponivel.toString()}
              </strike>
              , {status}
            </React.Fragment>
          )
        }
      }
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={650} toolbarActions={this.state.toolbarActions}>
        {(dialogContentProps) => (
          <ContentWithPreload loadFunction={this.carregarMain}>
            {() => (
              <React.Fragment>
                <Typography variant="h6" style={{ marginBottom: 16, marginTop: 16 }}>
                  {clientePacoteItem.servico.nome}
                </Typography>
                <Grid container style={{ marginBottom: 18 }}>
                  <Grid item xs={10} sm={6}>
                    <Typography variant="body2" style={{ marginBottom: 8 }}>
                      <b>Aquisição:</b> {converterDataIntParaMoment(clientePacoteItem.dataCriacao).format('DD/MMM/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={6}>
                    <Typography variant="body2" style={{ marginBottom: 8 }}>
                      {clientePacoteItem.dataVencimento && (
                        <React.Fragment>
                          <b>Vencimento:</b> {converterDataIntParaMoment(clientePacoteItem.dataVencimento).format('DD/MMM/YYYY')}
                        </React.Fragment>
                      )}
                      {!clientePacoteItem.dataVencimento && (
                        <React.Fragment>
                          <b>Vencimento: </b> Não informado
                        </React.Fragment>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={6}>
                    <Typography variant="body2" style={{ marginBottom: 8 }}>
                      <b>Qtd. Inicial:</b> {clientePacoteItem.quantidadeInicial}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={6}>
                    <Typography variant="body2" style={{ marginBottom: 8 }}>
                      {descricaoCreditos}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={6}>
                    <Typography variant="body2" style={{ marginBottom: 8 }}>
                      <b>Qtd. Utilizada:</b> {clientePacoteItem.quantidadeUtilizada}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={6}>
                    <Typography variant="body2" style={{ marginBottom: 8 }}>
                      <b>Qtd. Cancelada:</b> {clientePacoteItem.quantidadeCancelados}
                    </Typography>
                  </Grid>
                </Grid>

                <VirtualizedResponsiveTable
                  scrollElement={dialogContentProps.scrollContainerRef.current}
                  showBackgroundDividers={true}
                  itemsPerRequest={30}
                  loaderFunctionsMap={this.loaderFunctionsMap}
                  contextoUsuario="erp"
                  endpoint="erp/clientes.buscarClientePacoteItemManipulacao"
                  getRequestParametersFunction={() => ({
                    idClientePacoteItem: clientePacoteItem.id
                  })}
                  items={this.state.lista}
                  loadTrackingFunction={(data) => {
                    if (data.status === 'loading') {
                      if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                        this.setState({ lista: [] })
                      }
                    } else if (data.status === 'loaded') {
                      this.setState({ lista: data.items })
                    }
                  }}
                  largeRenderProps={{
                    columns: [
                      { label: 'Data', className: classes.colunaData },
                      { label: 'Quantidade', props: { xs: true }, className: classes.colunaQuantidade },
                      { label: 'Descrição', props: { xs: true }, horizontalPadding: 'none' }
                    ],
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('large', item)
                      return {
                        itemData: [content.data, content.quantidade, content.descricao]
                      }
                    }
                  }}
                  smallRenderProps={{
                    rowHeight: 74,
                    showFirstItemBorderTop: true,
                    horizontalPadding: 'none',
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('small', item)
                      return {
                        itemData: (
                          <React.Fragment>
                            <Typography variant="body2">
                              <b>{content.data}</b>
                            </Typography>
                            <Typography variant="body2">{content.quantidade}</Typography>
                            <Typography variant="body2" noWrap={true}>
                              {content.descricao}
                            </Typography>
                          </React.Fragment>
                        )
                      }
                    }
                  }}
                  emptyListProps={{
                    text: 'Nenhum histórico do pacote foi encontrado'
                  }}
                />
              </React.Fragment>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

HistoricoPacoteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(HistoricoPacoteDialogPage)
