import ClientePacoteItemTipoManipulacaoEnum from 'support/domain/cliente/ClientePacoteItemTipoManipulacaoEnum'
import OpcoesClienteCancelamentoPacoteItemEnum from 'support/domain/cliente/OpcoesClienteCancelamentoPacoteItemEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

function isManipulacaoUso(pacoteItemManipulacao) {
  if (ClientePacoteItemTipoManipulacaoEnum.USO.id === pacoteItemManipulacao.tipo.id) {
    return true
  }
  return false
}

function isManipulacaoCancelamento(pacoteItemManipulacao) {
  if (ClientePacoteItemTipoManipulacaoEnum.CANCELAMENTO.id === pacoteItemManipulacao.tipo.id) {
    return true
  }
  return false
}

function isManipulacaoTransferenciaServicoOrigem(pacoteItemManipulacao) {
  if (ClientePacoteItemTipoManipulacaoEnum.TRANSFERENCIA_SERVICO_ORIGEM.id === pacoteItemManipulacao.tipo.id) {
    return true
  }
  return false
}

function isManipulacaoTransferenciaServicoDestino(pacoteItemManipulacao) {
  if (ClientePacoteItemTipoManipulacaoEnum.TRANSFERENCIA_SERVICO_DESTINO.id === pacoteItemManipulacao.tipo.id) {
    return true
  }
  return false
}

function isManipulacaoCompra(pacoteItemManipulacao) {
  if (ClientePacoteItemTipoManipulacaoEnum.COMPRA.id === pacoteItemManipulacao.tipo.id) {
    return true
  }
  return false
}

function getDescricao(pacoteItemManipulacao) {
  if (isManipulacaoUso(pacoteItemManipulacao)) {
    return (
      'Realização de serviço com ' +
      pacoteItemManipulacao.manipulacaoUso.venda.colaborador.apelido +
      ' às ' +
      formatarHorarioInt(pacoteItemManipulacao.manipulacaoUso.venda.atendimento.horarioInicio)
    )
  } else if (isManipulacaoCancelamento(pacoteItemManipulacao)) {
    const manipulacaoCancelamento = pacoteItemManipulacao.manipulacaoCancelamento

    if (manipulacaoCancelamento.destinoValorEstorno.id === OpcoesClienteCancelamentoPacoteItemEnum.SEM_ESTORNO.id) {
      return 'Cancelado. Fatura #' + pacoteItemManipulacao.clientePacoteItem.clientePacote.vendaPacote.venda.notaVenda.numero
    } else if (manipulacaoCancelamento.destinoValorEstorno.id === OpcoesClienteCancelamentoPacoteItemEnum.ESTORNO_CREDITO.id) {
      return (
        'Cancelado com estorno em crédito (' +
        formatarValorMonetario(manipulacaoCancelamento.valorEstorno) +
        ') na fatura #' +
        pacoteItemManipulacao.clientePacoteItem.clientePacote.vendaPacote.venda.notaVenda.numero
      )
    } else if (manipulacaoCancelamento.destinoValorEstorno.id === OpcoesClienteCancelamentoPacoteItemEnum.ESTORNO_PAGAMENTO.id) {
      return (
        'Cancelado com estorno em pagamento (' +
        formatarValorMonetario(manipulacaoCancelamento.valorEstorno) +
        ') na fatura #' +
        pacoteItemManipulacao.clientePacoteItem.clientePacote.vendaPacote.venda.notaVenda.numero
      )
    }
  } else if (isManipulacaoTransferenciaServicoOrigem(pacoteItemManipulacao)) {
    let nomeServico = pacoteItemManipulacao.manipulacaoTransferenciaServico.servico.nome
    if (pacoteItemManipulacao.manipulacaoTransferenciaServico.variacaoServico) {
      nomeServico += ' - ' + pacoteItemManipulacao.manipulacaoTransferenciaServico.variacaoServico.nome
    }

    return 'Transferência para ' + nomeServico
  } else if (isManipulacaoTransferenciaServicoDestino(pacoteItemManipulacao)) {
    return 'Créditos transferidos de outro serviço'
  } else if (isManipulacaoCompra(pacoteItemManipulacao)) {
    return 'Compra de pacote'
  }
}

const functionsObject = {
  isManipulacaoUso: isManipulacaoUso,
  isManipulacaoCancelamento: isManipulacaoCancelamento,
  isManipulacaoTransferenciaServicoOrigem: isManipulacaoTransferenciaServicoOrigem,
  isManipulacaoTransferenciaServicoDestino: isManipulacaoTransferenciaServicoDestino,
  isManipulacaoCompra: isManipulacaoCompra,
  getDescricao: getDescricao
}

export default functionsObject
