import DeviceIdentifier from 'support/components/device/DeviceIdentifier'

function sendPlataform() {
  if (DeviceIdentifier.isNativeAndroid()) {
    window.gtag('event', 'identificacao_plataforma', { plataforma: 'webview_android' })
  } else if (DeviceIdentifier.isNativeIOS()) {
    window.gtag('event', 'identificacao_plataforma', { plataforma: 'webview_ios' })
  } else {
    window.gtag('event', 'identificacao_plataforma', { plataforma: 'browser' })
  }
}

const functionsObject = {
  sendPlataform: sendPlataform
}

export default functionsObject
