import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth from '@material-ui/core/withWidth'
import classNames from 'classnames'
import Configuracoes from 'pages/erp/painel/atendimento/agendainterface/Configuracoes'
import ConfiguracoesDialogPage from 'pages/erp/painel/atendimento/agendainterface/ConfiguracoesDialogPage'
import FilaEsperaDialogPage from 'pages/erp/painel/atendimento/agendainterface/FilaEsperaDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import CustomScrollbars from 'support/components/scroll/CustomScrollbars'
import StyleUtil from 'support/util/StyleUtil'

export const LEFT_TOOLS_WIDTH_EXPANDED = 280
export const LEFT_TOOLS_WIDTH_RETRACTED = 30

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    salignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: 'none',
    WebkitBoxShadow: 'none',
    MozBoxShadow: 'none',
    msBoxShadow: 'none',
    paddingTop: 4,
    //borderRight: "1px solid #ececec",
    flexBasis: LEFT_TOOLS_WIDTH_RETRACTED
    /*transition: theme.transitions.create('flex-basis', {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.leavingScreen,
		})*/
  },
  rootExpanded: {
    paddingTop: 0,
    //borderRight: "1px solid #ececec",
    flexBasis: LEFT_TOOLS_WIDTH_EXPANDED
    /*transition: theme.transitions.create('flex-basis', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		})*/
  },
  main: {
    alignSelf: 'flex-start',
    flexBasis: '100%',
    height: '100%',
    alignItems: 'flex-start'
  },
  mainPaddingLeft: {
    ...theme.screen.leftMarginStyles()
  },
  header: {
    height: 50,
    borderRadius: 4
  },
  itemMenu: {
    textAlign: 'center'
  },
  itemMenuButton: {
    marginLeft: -6,
    marginRight: -6
  },
  itemMenuSelected: {
    color: theme.palette.secondary.main,
    '& svg': {
      display: 'block',
      marginRight: 12
    }
  },
  body: {
    marginTop: 10,
    height: 'calc(100% - 50px - 10px)'
  },
  content: {
    paddingRight: 20,
    borderRadius: 4,
    minHeight: 'calc(100% - 0px - 16px - 10px)'
  },
  containerExpandRetractLeftTools: {
    display: 'none',
    zIndex: 1,
    cursor: 'pointer',
    position: 'absolute',
    width: 15,
    height: 66,
    background: '#fff',
    borderRadius: '5px 0px 0px 5px',
    '&:hover': {
      background: '#f2f2f2'
    }
  },
  containerExpandRetracttoolsExpanded: {
    boxShadow: '-4px 0px 8px 0px rgba(0, 0, 0, 0.1)',
    WebkitBoxShadow: '-4px 0px 8px 0px rgba(0, 0, 0, 0.1)',
    MozBoxShadow: '-4px 0px 8px 0px rgba(0, 0, 0, 0.1)',
    msBoxShadow: '-4px 0px 8px 0px rgba(0, 0, 0, 0.1)'
  },
  iconExpandRetractLeftTools: {
    position: 'absolute',
    marginLeft: -5,
    marginTop: 20,
    color: theme.palette.primary.main
  }
})

class BarraFerramentasEsquerda extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      secaoMenuEsquedoAnchorPosition: null
    }
  }

  componentDidMount() {
    this.eventsManager.sub('BarraFerramentasAgendaInterface', (props) => {
      if (props.action === 'open') {
        this.abrirConfiguracoesDialogPage()
      }
    })
    this.eventsManager.sub('FilaEsperaAgendaInterface', (props) => {
      if (props.action === 'open') {
        this.abrirFilaEsperaDialogPage()
      }
    })
  }

  abrirConfiguracoesDialogPage = () => {
    const { classes, ...othersProps } = this.props
    openBackableDialog(ConfiguracoesDialogPage, othersProps)
  }

  abrirFilaEsperaDialogPage = () => {
    const { classes, ...othersProps } = this.props
    openBackableDialog(FilaEsperaDialogPage, othersProps)
  }

  closeSecaoMenuEsquedo = () => {
    this.setState({ secaoMenuEsquedoAnchorPosition: null })
  }

  render() {
    const { classes, ...othersProps } = this.props
    delete othersProps.children
    delete othersProps.style

    let content = null

    if (StyleUtil.isDesktopWidth(this.props.width)) {
      content = (
        <div className={classNames(classes.root, this.props.toolsExpanded ? classes.rootExpanded : null)}>
          <div className={classNames(classes.main, classes.mainPaddingLeft)} style={{ visibility: this.props.toolsExpanded ? 'visible' : 'hidden' }}>
            <div
              style={{
                bbackgroundColor: 'rgb(255, 255, 255)',
                borderRadius: 4,
                height: '100%',
                bboxShadow: '0px 0px 6px 2px rgba(0,0,0,0.1)',
                position: 'absolute',
                width: LEFT_TOOLS_WIDTH_EXPANDED - 50,
                zIndex: 5,
                marginTop: 8,
                bbackground: '#f4f9fbd4'
              }}
            >
              <div className={classes.body}>
                <Typography variant="body1">
                  <b>Configurações</b>
                </Typography>

                <CustomScrollbars hideTracksWhenNotNeeded={false} show={this.props.toolsExpanded} applyWillChangeOnScrollContainer={true} color="rgba(0,0,0,0.1)">
                  {(scrollProps) => (
                    <div className={classes.content}>
                      <div style={{ padding: '16px 0px' }}>
                        <Configuracoes {...othersProps} />
                      </div>
                    </div>
                  )}
                </CustomScrollbars>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return content
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

BarraFerramentasEsquerda.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(BarraFerramentasEsquerda))
