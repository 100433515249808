import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import classNames from 'classnames'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import VendaSupport from 'pages/erp/painel/venda/VendaSupport'
import PropTypes from 'prop-types'
import AtendimentoStatusIcon from 'support/components/icon/AtendimentoStatus'
import NfStatusIcon from 'support/components/icon/NfStatus'
import VendaStatusIcon from 'support/components/icon/VendaStatus'
import FacilitadorRemocaoRepeticao from 'support/components/repeticao/FacilitadorRemocaoRepeticao'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import EmissaoNfEnum from 'support/domain/venda/EmissaoNfEnum'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaCliente: {
    flexGrow: 1.1
  },
  colunaDescricao: {
    flexGrow: 1.5
  },
  colunaDescricaoMedium: {
    flexGrow: 1.5,
    overflow: 'hidden'
  },
  colunaIconeTipoVenda: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaIconeStatusNota: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaIconeStatusNotaComNf: {
    maxWidth: 56,
    minWidth: 56
  },
  iconeTipoVenda: {
    display: 'block',
    fontSize: '24px'
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  colunaNota: {
    flexGrow: 1,
    maxWidth: 120,
    minWidth: 120,
    [theme.breakpoints.down(686)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  iconeStatusNota: {
    display: 'block',
    fontSize: 24
  },
  iconeStatusNf: {
    display: 'block',
    marginLeft: 4
  },
  hide: {
    display: 'none'
  },
  singleLine: theme.text.singleLine()
})

class VendasResponsiveTable extends Component {
  constructor(props) {
    super(props)
    this.facilitadorRemocaoRepeticaoFunctionMap = {}
    this.podeVisualizarValorVenda = verificarAutorizacao([PA.PODE_VISUALIZAR_VALOR_VENDA]) ? true : false
  }

  removerVenda = (venda, event) => {
    VendaSupport.removerVenda({ venda: venda, event: event, facilitadorRemocaoRepeticaoFunctionMap: this.facilitadorRemocaoRepeticaoFunctionMap })
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const content = {}

    if (this.podeVisualizarValorVenda) {
      content.descricaoValor = VendaService.getDescricaoPreco(item, {
        format: size === 'medium' || size === 'small' ? 'minificado' : null,
        quebraLinhaUnidades: true
      })
    }

    if (item.cancelado) {
      if (size === 'small') {
        content.nota = (
          <React.Fragment>
            <b style={{ fontSize: 14 }}>Cancelado</b>
            <Link
              style={{ fontSize: 13, marginTop: -8, padding: 8, display: 'inline-block' }}
              onClick={(event) => {
                event.stopPropagation()
                this.removerVenda(item, event)
              }}
              color="secondary"
            >
              Remover
            </Link>
          </React.Fragment>
        )
      } else {
        content.nota = (
          <React.Fragment>
            <span style={{ fontSize: 14 }}>Cancelado</span>
            <br />
            <Link
              style={{ fontSize: 13, display: 'block' }}
              onClick={(event) => {
                event.stopPropagation()
                this.removerVenda(item, event)
              }}
              color="secondary"
            >
              Remover
            </Link>
          </React.Fragment>
        )
      }
      content.iconeStatusNota = <VendaStatusIcon hasTooltip={true} vendaCancelada={true} className={classes.iconeStatusNota} style={{ color: '#444' }} />
    } else if (item.notaVenda) {
      let labelNota = ''
      if (item.status && item.status.id === StatusVendaEnum.NOTA_ABERTA.id) {
        content.iconeStatusNota = (
          <VendaStatusIcon hasTooltip={true} status={item.status} vendaCancelada={item.cancelado} className={classes.iconeStatusNota} style={{ color: '#444' }} />
        )
        labelNota = 'Aberta'
      } else if (item.status && item.status.id === StatusVendaEnum.NOTA_FECHADA.id) {
        content.iconeStatusNota = <VendaStatusIcon hasTooltip={true} status={item.status} vendaCancelada={item.cancelado} color="secondary" className={classes.iconeStatusNota} />
        labelNota = 'Fechada'
      }
      if (size === 'small') {
        content.nota = '#' + item.notaVenda.numero + ' - Fatura ' + labelNota
      } else {
        content.nota = (
          <span>
            #{item.notaVenda.numero}
            <br />
            <small style={{ color: '#555' }}>Fatura {labelNota}</small>
          </span>
        )
      }
    } else if (item.atendimento && item.atendimento.status.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id) {
      content.nota = <span>Cliente Faltou</span>
      content.iconeStatusNota = <AtendimentoStatusIcon status={item.atendimento.status} className={classes.iconeStatusNota} hasTooltip={false} />
    } else {
      if (size === 'small') {
        content.nota = <small style={{ color: '#999999' }}>* Faturamento pendente</small>
      } else {
        content.nota = (
          <span>
            Sem Fatura
            <br />
            <small style={{ color: '#aaa' }}>Pendente*</small>
          </span>
        )
      }
      content.iconeStatusNota = (
        <VendaStatusIcon hasTooltip={true} status={item.status} vendaCancelada={item.cancelado} className={classes.iconeStatusNota} style={{ color: '#444' }} />
      )
    }

    if (getSessaoPainel().negocio.carregado && EmissaoNfEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.emissaoNf.id) {
      content.iconeStatusNota = (
        <Grid container alignItems="center" wrap="nowrap" justify="flex-end">
          <Grid item>{content.iconeStatusNota}</Grid>
          <Grid item>
            <NfStatusIcon nf={item.nf} hasTooltip={true} className={classes.iconeStatusNf} />
          </Grid>
        </Grid>
      )
    }

    content.colaborador = item.colaborador

    const iconeEDescricao = VendaService.getIconeEDescricao(item)
    content.iconeTipoVenda = <iconeEDescricao.icon className={classes.iconeTipoVenda} />
    content.descricaoVenda = iconeEDescricao.descricao

    if (VendaService.isAtendimento(item)) {
      if (size === 'small') {
        content.data = (
          <span>
            {converterDataIntParaMoment(item.dataVenda).format('DD/MMM/YY')} - {formatarHorarioInt(item.atendimento.horarioInicio)}
          </span>
        )
      } else {
        content.data = (
          <span>
            {converterDataIntParaMoment(item.dataVenda).format('DD/MMM/YY')}
            <br />
            <small style={{ color: '#aaa', marginTop: 3 }}>
              <AccessTimeIcon style={{ fontSize: 16, verticalAlign: 'top', marginRight: 3, marginTop: 1 }} />
              {formatarHorarioInt(item.atendimento.horarioInicio)}
            </small>
          </span>
        )
      }
    } else {
      content.data = converterDataIntParaMoment(item.dataVenda).format('DD/MMM/YY')
    }
    content.nomeCliente = item.cliente.apelido
    return content
  }

  render() {
    const { classes, origem, ...others } = this.props

    const largeColunaClienteClasses = [classes.colunaCliente]

    const isOrigemCliente = origem === 'cliente' ? true : false
    if (isOrigemCliente) {
      largeColunaClienteClasses.push(classes.hide)
    }

    let classColunaIconeStatusNota = classes.colunaIconeStatusNota
    if (getSessaoPainel().negocio.carregado && EmissaoNfEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.emissaoNf.id) {
      classColunaIconeStatusNota = classes.colunaIconeStatusNotaComNf
    }

    return (
      <React.Fragment>
        <VirtualizedResponsiveTable
          showBackgroundDividers={true}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Cliente', className: classNames(largeColunaClienteClasses), horizontalPadding: 'small', props: { xs: true } },
              { label: '', className: classes.colunaIconeTipoVenda, isVisible: false, horizontalPadding: 'small' },
              { label: 'Descrição', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } },
              { label: 'Profissional', horizontalPadding: 'small', showOnlyUp: isOrigemCliente ? null : '1500', props: { xs: true } },
              { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right', display: this.podeVisualizarValorVenda },
              { label: 'Situação', className: classes.colunaNota },
              { label: '', className: classColunaIconeStatusNota, align: 'right', horizontalPadding: 'small', isVisible: false }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [
                  content.data,
                  content.nomeCliente,
                  content.iconeTipoVenda,
                  content.descricaoVenda,
                  content.colaborador.apelido,
                  content.descricaoValor,
                  content.nota,
                  content.iconeStatusNota
                ]
              }
            }
          }}
          mediumRenderProps={{
            headerColumnHeight: 52,
            rowHeight: 88,
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: '', className: classes.colunaIconeTipoVenda, isVisible: false, horizontalPadding: 'small' },
              { label: 'Descrição', className: classes.colunaDescricaoMedium, horizontalPadding: 'small', props: { xs: true } },
              { label: 'Profissional', horizontalPadding: 'small', showOnlyUp: 'lg', props: { xs: true } },
              { label: 'Situação', className: classes.colunaNota },
              { label: '', className: classColunaIconeStatusNota, align: 'right', horizontalPadding: 'small', isVisible: false }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('medium', item)

              let descricaoContent = null
              if (origem === 'cliente') {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body2" style={{ fontWeight: 500 }} noWrap={true}>
                      {content.descricaoVenda}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.colaborador.apelido}
                    </Typography>
                    {this.podeVisualizarValorVenda && (
                      <Typography variant="body2" className={classes.singleLine}>
                        {content.descricaoValor}
                      </Typography>
                    )}
                  </React.Fragment>
                )
              } else {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body2" style={{ fontWeight: 500 }} noWrap={true}>
                      {content.nomeCliente}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.descricaoVenda}
                    </Typography>
                    {this.podeVisualizarValorVenda && (
                      <Typography variant="body2" className={classes.singleLine}>
                        {content.descricaoValor}
                      </Typography>
                    )}
                  </React.Fragment>
                )
              }

              return {
                itemData: [content.data, content.iconeTipoVenda, descricaoContent, content.colaborador.apelido, content.nota, content.iconeStatusNota]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: origem === 'cliente' ? 108 : 130,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              let descricaoContent = null
              if (origem === 'cliente') {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body1" noWrap={true}>
                      {content.descricaoVenda}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      <b>{content.colaborador.apelido}</b>
                    </Typography>
                  </React.Fragment>
                )
              } else {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body1" noWrap={true}>
                      <b>{content.nomeCliente}</b>
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.colaborador.apelido}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.descricaoVenda}
                    </Typography>
                  </React.Fragment>
                )
              }

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs style={{ minWidth: 0 /*minWidth - para ativar as reticências */ }}>
                        <Typography variant="body2">{content.data}</Typography>
                        {descricaoContent}
                        <Typography variant="body2" noWrap={true}>
                          {content.nota}
                        </Typography>
                      </Grid>
                      <Grid item align="right">
                        {this.podeVisualizarValorVenda && (
                          <Typography variant="body2" style={{ fontWeight: 500 }}>
                            {content.descricaoValor}
                          </Typography>
                        )}
                        <div style={{ marginTop: 6 }}>{content.iconeStatusNota}</div>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          {...others}
        />
        <FacilitadorRemocaoRepeticao functionsMap={this.facilitadorRemocaoRepeticaoFunctionMap} />
      </React.Fragment>
    )
  }
}

VendasResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendasResponsiveTable)
