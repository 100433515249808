import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import RelatorioDetalhesColaboradoresDialogPage from 'pages/erp/painel/relatorio/RelatorioDetalhesColaboradoresDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DataExporter from 'support/components/dataexporter/DataExporter'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaColaborador: {
    flexGrow: 1
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaValores: {
    maxWidth: 220,
    minWidth: 220
  },
  colunaQuantidade: {
    maxWidth: 100,
    minWidth: 100
  },
  tituloInformacoesFinanceiras: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  botaoVerDadosCompletos: {
    fontSize: 12,
    marginTop: 2,
    color: theme.palette.secondary.main
  }
})

class RelatorioColaboradoresDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxing: false,
      colaboradoresDataInicial: this.colaboradorLocalPreferences.getMomentPreference('RelatorioColaboradoresDialogPageFiltro_MaisLucrativosDataInicial', moment().startOf('month')),
      colaboradoresDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioColaboradoresDialogPageFiltro_MaisLucrativosDataFinal', moment().endOf('month')),
      colaboradores: []
    }
    this.colaboradoresLoaderFunctionsMap = {}
    this.dataExporterFunctionsMap = {}
  }

  getRequestParameters = () => {
    return {
      dataInicial: converterMomentParaDataInt(this.state.colaboradoresDataInicial),
      dataFinal: converterMomentParaDataInt(this.state.colaboradoresDataFinal)
    }
  }

  carregarMaisUtilizados = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/relatorios.buscarColaboradoresVendasPorData',
      params: this.getRequestParameters(),
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxing: false,
          colaboradores: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxing: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Colaboradores Resumo Vendas - ' + formatarPeriodoNomeArquivo(this.state.colaboradoresDataInicial, this.state.colaboradoresDataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarColaboradoresResumoVendas',
        params: this.getRequestParameters()
      }
    })
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const content = {}
    content.nomeColaborador = [
      <Typography key="nome" variant="body2" noWrap={true}>
        {item.colaborador.apelido}
      </Typography>,
      <Typography key="dadosCompletos" className={classes.botaoVerDadosCompletos} variant="body2">
        <b>Ver Dados Completos</b>
      </Typography>
    ]
    content.quantidadeTotalVendas = item.quantidadeTotalVendas.toString()
    content.quantidadeDiasAtendimentos = item.quantidadeDiasAtendimentos.toString()
    content.valorTotalVendas = formatarValorMonetario(item.valorTotalVendas ? item.valorTotalVendas : 0)
    content.valorTotalRemuneracao = formatarValorMonetario(item.valorTotalRemuneracao ? item.valorTotalRemuneracao : 0)
    return content
  }

  abrirDetalhes = (item) => {
    openBackableDialog(RelatorioDetalhesColaboradoresDialogPage, {
      colaborador: item.colaborador,
      dataInicial: this.state.colaboradoresDataInicial,
      dataFinal: this.state.colaboradoresDataFinal,
      tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioColaboradoresDialogPageFiltro_DadosResumoColaboradorTipoIntervalo', 'mes')
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, colaboradoresDataInicial, colaboradoresDataFinal } = this.state

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.colaboradores) {
      valorTotal += item.valor
      quantidadeTotal += item.quantidade
    }

    let toolbarActions = null
    if (!ajaxing) {
      toolbarActions = {
        actions: [
          {
            label: 'Exportar',
            handleAction: this.downloadRelatorio
          }
        ]
      }
    }

    let colaboradoresLoadKey = converterMomentParaDataInt(colaboradoresDataInicial).toString() + '-' + converterMomentParaDataInt(colaboradoresDataFinal).toString()

    return (
      <DialogPage {...dialogProps} title="Colaboradores" align="center" contentMaxWidth={1000} pageType="basicEntity" ajaxing={ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Informações Gerais
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', marginRight: 2 }}>
                  <DateRangeNavigator
                    expanded={false}
                    defaultValue={{
                      tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioColaboradoresDialogPageFiltro_DadosResumoColaboradorTipoIntervalo', 'mes'),
                      dataInicial: colaboradoresDataInicial,
                      dataFinal: colaboradoresDataFinal
                    }}
                    onChange={(data) => {
                      this.colaboradorLocalPreferences.setPreference('RelatorioColaboradoresDialogPageFiltro_DadosResumoColaboradorTipoIntervalo', data.tipoIntervalo.nome)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioColaboradoresDialogPageFiltro_MaisLucrativosDataInicial', data.dataInicial)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioColaboradoresDialogPageFiltro_MaisLucrativosDataFinal', data.dataFinal)
                      this.setState({
                        colaboradoresDataInicial: data.dataInicial,
                        colaboradoresDataFinal: data.dataFinal
                      })
                    }}
                  />
                </div>
              </div>
            </FloatContent>

            <ContentWithPreload functionsMap={this.colaboradoresLoaderFunctionsMap} loadFunction={this.carregarMaisUtilizados} loadKey={colaboradoresLoadKey}>
              {() => (
                <React.Fragment>
                  {
                    <React.Fragment>
                      <div>
                        <VirtualizedResponsiveTable
                          showBackgroundDividers={true}
                          scrollElement={dialogContentProps.scrollContainerRef.current}
                          items={this.state.colaboradores}
                          onClickRow={(item, event) => {
                            this.abrirDetalhes(item)
                          }}
                          largeRenderProps={{
                            columns: [
                              { label: 'Colaborador', className: classes.colunaColaborador, props: { xs: true } },
                              { label: 'Dias com Atendimentos', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                              { label: 'Qtd. Vendas', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                              { label: 'Total Vendas', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                              { label: 'Remuneração', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item)

                              return {
                                itemData: [
                                  content.nomeColaborador,
                                  content.quantidadeDiasAtendimentos,
                                  content.quantidadeTotalVendas,
                                  content.valorTotalVendas,
                                  content.valorTotalRemuneracao
                                ]
                              }
                            }
                          }}
                          mediumRenderProps={{
                            rowHeight: 90,
                            columns: [
                              { label: 'Colaborador', className: classes.colunaColaborador, props: { xs: true } },
                              { label: 'Dias com Atendimentos', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                              { label: 'Qtd. Vendas', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                              { label: 'Valores', className: classes.colunaValores, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item)

                              return {
                                itemData: [
                                  <span>
                                    <b>{content.nomeColaborador}</b>
                                  </span>,
                                  content.quantidadeDiasAtendimentos,
                                  content.quantidadeTotalVendas,
                                  <React.Fragment>
                                    <Typography variant="body2" style={{ fontSize: 13 }}>
                                      Total Vendas: {content.valorTotalVendas}
                                    </Typography>
                                    <Typography variant="body2" style={{ fontSize: 13 }}>
                                      Remunerações: {content.valorTotalRemuneracao}
                                    </Typography>
                                  </React.Fragment>
                                ]
                              }
                            }
                          }}
                          smallRenderProps={{
                            rowHeight: 160,
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('small', item)

                              return {
                                itemData: (
                                  <React.Fragment>
                                    <Typography variant="body2" noWrap={true}>
                                      <b>{content.nomeColaborador}</b>
                                    </Typography>
                                    <Typography variant="body2">Dias com Atendimentos: {content.quantidadeDiasAtendimentos}</Typography>
                                    <Typography variant="body2">Qtd. Vendas: {content.quantidadeTotalVendas}</Typography>
                                    <Typography variant="body2">Total Vendas: {content.valorTotalVendas}</Typography>
                                    <Typography variant="body2">Remunerações: {content.valorTotalRemuneracao}</Typography>
                                  </React.Fragment>
                                )
                              }
                            }
                          }}
                          emptyListProps={{
                            text: 'Não existem dados para o período informado'
                          }}
                        />
                      </div>

                      {quantidadeTotal > 1 && (
                        <FloatContent container="paper" context="DialogPage" type="tableSummary">
                          <Typography variant="body2">
                            Total: <b>{formatarValorMonetario(valorTotal)}</b>
                            <br />
                          </Typography>
                        </FloatContent>
                      )}
                    </React.Fragment>
                  }
                </React.Fragment>
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioColaboradoresDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioColaboradoresDialogPage)
