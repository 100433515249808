import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'
import { extractLightboxProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import Lightbox from 'support/components/lightbox/Lightbox'
import NativeApp from 'support/components/nativeapp/NativeApp'
import ImageUtil from 'support/util/ImageUtil'

const styles = (theme) => ({
  largeTitle: {
    [theme.breakpoints.down(384)]: {
      display: 'none'
    }
  },
  smallTitle: {
    [theme.breakpoints.up(384)]: {
      display: 'none'
    }
  }
})

class LightboxFotoGaleria extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fotos: this.props.fotos,
      index: this.props.index
    }
  }

  render() {
    const lightboxProps = extractLightboxProps(this.props)
    const { fotos, index } = this.state
    const { viewGroupClassName } = lightboxProps

    delete lightboxProps.viewGroupClassName

    let imageTitle = null
    if (this.props.renderTitle) {
      imageTitle = this.props.renderTitle(fotos[index])
    }

    const toolbarButtons = []

    if (this.props.alterarFotoFunction) {
      toolbarButtons.push(
        <IconButton
          style={{ color: 'white', padding: 10 }}
          onClick={() => {
            this.props.alterarFotoFunction(this, fotos[index])
          }}
        >
          <EditIcon />
        </IconButton>
      )
    }

    if (this.props.removerFotoFunction) {
      toolbarButtons.push(
        <IconButton
          style={{ color: 'white', padding: 10 }}
          onClick={() => {
            this.props.removerFotoFunction(this, fotos[index])
          }}
        >
          <DeleteIcon />
        </IconButton>
      )
    }

    if (this.props.mostrarOpcaoDownload) {
      const fileUrl = ImageUtil.extrairVariacoesImagem(fotos[index].foto).get(0)
      const fileName = fileUrl.split(/\//g).pop()
      const linkProps = {}

      if (DeviceIdentifier.isNativeApp()) {
        linkProps.onClick = () => {
          NativeApp.executeNativeMethod({
            method: 'downloadFile',
            fileName: fileName,
            url: fileUrl
          })
        }
      } else {
        linkProps.href = fileUrl
        linkProps.target = 'fileDownload'
        linkProps.download = fileName
      }

      toolbarButtons.push(
        <Link {...linkProps}>
          <IconButton style={{ color: 'white', padding: 10 }}>
            <GetAppIcon />
          </IconButton>
        </Link>
      )
    }

    return (
      <Lightbox
        {...lightboxProps}
        parentNode={document.querySelector('.' + viewGroupClassName)}
        imageTitle={imageTitle}
        toolbarButtons={toolbarButtons}
        mainSrc={ImageUtil.extrairVariacoesImagem(fotos[index].foto).get(0)}
        nextSrc={ImageUtil.extrairVariacoesImagem(fotos[(index + 1) % fotos.length].foto).get(0)}
        prevSrc={ImageUtil.extrairVariacoesImagem(fotos[(index + fotos.length - 1) % fotos.length].foto).get(0)}
        onMovePrevRequest={() =>
          this.setState({
            index: (index + fotos.length - 1) % fotos.length
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            index: (index + 1) % fotos.length
          })
        }
      />
    )
  }
}

LightboxFotoGaleria.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LightboxFotoGaleria)
