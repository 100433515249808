import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_CONTA_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/ApiClient'
import InputText from 'support/components/input/InputText'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  root: theme.page.parentCenteredPaper,
  paper: theme.page.paper({ maxWidth: 380 })
})

class IniciarRedefinicaoSenhaPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      acaoConcluida: false
    }
    this.inputEmail = React.createRef()
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState((state) => ({ ajaxing: true }))

    postAPI({
      url: 'cda/usuarios.enviarEmailRedefinicaoSenha',
      data: {
        email: this.inputEmail.current.value.toLowerCase()
      },
      onSuccess: (response) => {
        this.setState((state) => ({ acaoConcluida: true }))
      },
      onPreFinal: () => {
        this.setState((state) => ({ ajaxing: false }))
      },
      onClientError: (response) => {
        this.setState((state) => ({ errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formIniciarRedefinicaoSenha')
      }
    })
  }

  render() {
    const { classes } = this.props
    const { errorsMap } = this.state

    let containerEnviarEmail
    if (!this.state.acaoConcluida) {
      containerEnviarEmail = (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" style={{ marginTop: 22 }}>
            Redefinir Senha
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 20 }}>
            Informe abaixo o seu e-mail para iniciar o processo de redefinição da sua senha.
          </Typography>
          <form style={{ textAlign: 'center' }} id="formIniciarRedefinicaoSenha" noValidate autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
            <InputText
              style={{ marginTop: 18 }}
              autoFocus={true}
              id="xemail"
              name="xemail"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              errorMessage={errorsMap('email')}
              helpMessage="Iremos enviar um e-mail com um link para redefinir a sua senha"
              label="E-mail"
              inputRef={this.inputEmail}
            />

            <Button disabled={this.state.ajaxing} style={{ marginTop: 30 }} variant="contained" color="primary" size="large" type="submit">
              {this.state.ajaxing ? 'AGUARDE...' : 'REDEFINIR SENHA'}
            </Button>
            <Link to={ROUTE_CONTA_MAIN + '/login'} style={{ textDecoration: 'none', marginTop: 20, display: 'block' }}>
              <Typography variant="subtitle1" color="primary">
                Voltar para o login
              </Typography>
            </Link>
          </form>
        </div>
      )
    } else {
      containerEnviarEmail = (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" style={{ marginTop: 22 }}>
            E-mail enviado com sucesso!
          </Typography>
          <Typography style={{ marginTop: 24, color: '#aaa' }}>
            Atenção: caso não encontre o e-mail na Caixa de Entrada, procure nas pastas <b>Spam, Lixeira, Lixo Eletrônico, Itens Apagados ou Arquivo.</b>
          </Typography>
          <Link to={ROUTE_CONTA_MAIN + '/login'} style={{ textDecoration: 'none' }}>
            <Button style={{ marginTop: 26 }} variant="contained" color="primary" size="large">
              VOLTAR PARA O LOGIN
            </Button>
          </Link>
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Redefina a sua senha no Salão99</title>
          <meta name="description" content="Redefina a sua senha no Salão99." />
        </Helmet>
        <Paper className={classes.paper} elevation={1}>
          <div style={{ textAlign: 'center', marginTop: 4 }}>
            <img src={logo} width="100" alt="Salão99" />
          </div>
          {containerEnviarEmail}
        </Paper>
      </div>
    )
  }
}

IniciarRedefinicaoSenhaPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IniciarRedefinicaoSenhaPage)
