import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import RepeatIcon from '@material-ui/icons/Repeat'
import ReplayIcon from '@material-ui/icons/Replay'
import moment from 'moment'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import OpcoesPersistenciaRepeticaoDialog from 'support/components/repeticao/OpcoesPersistenciaRepeticaoDialog'
import RepeticaoService from 'support/domain/repeticao/RepeticaoService'
import UnidadeFrequencia from 'support/domain/repeticao/UnidadeFrequencia'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'

const valoresFrequenciaRepeticao = (function () {
  const valores = []
  for (let i = 1; i <= 60; i++) {
    if (i <= 30) {
      valores.push(i)
    } else if (i % 5 === 0) {
      valores.push(i)
    }
  }
  return valores
})()

const styles = (theme) => ({
  containerBotaoVerDetalhes: {
    paddingTop: 10,
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    ffontWeight: 500
  },
  inputSelect: {
    paddingRight: '18px !important',
    fontSize: 14
  }
})

class Repeticao extends Component {
  constructor(props) {
    super(props)

    this.initialValues = {}
    this.state = {}

    this.setDefaultValue(this.initialValues)
    this.setDefaultValue(this.state)

    this.valorFrequenciaRepeticaoFunctionMap = {}
    this.unidadeFrequenciaRepeticaoFunctionMap = {}
    this.dataFinalRepeticaoFunctionMap = {}
  }

  setDefaultValue = (obj) => {
    const { defaultValue } = this.props
    if (defaultValue && (defaultValue.id || defaultValue.valorFrequencia)) {
      obj.id = defaultValue.id
      obj.repeticaoHabilitada = defaultValue.repeticaoHabilitada !== undefined ? defaultValue.repeticaoHabilitada : true
      obj.valorFrequencia = defaultValue.valorFrequencia
      obj.unidadeFrequencia = UnidadeFrequencia.getById(defaultValue.unidadeFrequencia.id)
      obj.dataFinal = converterDataIntParaMoment(defaultValue.dataFinal)
    } else {
      const dataFinal = this.props.dataInicial ? this.props.dataInicial.clone() : moment()
      dataFinal.add(7, 'days')
      obj.id = null
      obj.repeticaoHabilitada = false
      obj.valorFrequencia = 7
      obj.unidadeFrequencia = UnidadeFrequencia.DIAS
      obj.dataFinal = dataFinal
    }
  }

  componentDidMount = () => {
    const { functionsMap } = this.props
    if (functionsMap) {
      functionsMap['getValues'] = () => {
        if (this.state.repeticaoHabilitada) {
          const dados = {}
          dados.habilitada = true
          dados.id = this.state.id
          dados.valorFrequencia = this.state.valorFrequencia
          dados.unidadeFrequencia = this.state.unidadeFrequencia
          dados.dataFinal = this.state.dataFinal ? converterMomentParaDataInt(this.state.dataFinal) : null
          return dados
        }
        return {}
      }
      functionsMap['exibirSeNecessarioOpcoesPersistencia'] = this.exibirSeNecessarioOpcoesPersistencia
    }
  }

  exibirSeNecessarioOpcoesPersistencia = (persistFunction, persistOptions) => {
    if (persistOptions) {
      persistFunction(persistOptions)
      return
    }

    if (
      this.initialValues.repeticaoHabilitada === false ||
      this.state.repeticaoHabilitada === false ||
      this.initialValues.valorFrequencia !== this.state.valorFrequencia ||
      this.initialValues.unidadeFrequencia.id !== this.state.unidadeFrequencia.id ||
      !this.initialValues.dataFinal.isSame(this.state.dataFinal, 'day')
    ) {
      persistFunction({})
    } else {
      if (this.props.hasEntityChange === undefined || this.props.hasEntityChange === null || this.props.hasEntityChange() === true) {
        openBackableDialog(OpcoesPersistenciaRepeticaoDialog, {
          tituloAlteracaoIndividual: this.props.tituloAlteracaoIndividual,
          descricaoAlteracaoIndividual: this.props.descricaoAlteracaoIndividual,
          tituloAlteracaoLote: this.props.tituloAlteracaoLote,
          descricaoAlteracaoLote: this.props.descricaoAlteracaoLote,
          persistFunction: persistFunction
        })
      } else {
        persistFunction({})
      }
    }
  }

  removerErroValidacaoDataLimiteRepeticao = (unidadeFrequencia, valorFrequencia) => {
    const { errorsMap } = this.props
    const errorsMapRepeticaoDataFinal = errorsMap('dataFinalRepeticao')
    if (errorsMapRepeticaoDataFinal) {
      errorsMap('dataFinalRepeticao', 'remove')
      this.forceUpdate()
    }
  }

  render() {
    const { showFunction, disabled, readOnly, classes } = this.props
    const { id, repeticaoHabilitada, valorFrequencia, unidadeFrequencia, dataFinal } = this.state

    const inputDataFinalProps = {}
    let frequenciaMessageProps = {}

    if (this.props.dataInicial) {
      if (!valorFrequencia) {
        frequenciaMessageProps.errorMessage = 'O valor da frenquência é obrigatório'
      } else if (!unidadeFrequencia) {
        frequenciaMessageProps.errorMessage = 'O tipo da frenquência é obrigatório'
      }
      if (!dataFinal) {
        inputDataFinalProps.errorMessage = 'A data final é obrigatória'
      }

      if (valorFrequencia && unidadeFrequencia && dataFinal) {
        const datasRepeticoes = RepeticaoService.gerarDatasRepeticoes(unidadeFrequencia, valorFrequencia, this.props.dataInicial, null)
        let dataFinalLimite = null
        if (datasRepeticoes && datasRepeticoes.length) {
          dataFinalLimite = datasRepeticoes[datasRepeticoes.length - 1]
        }
        if (unidadeFrequencia.id === UnidadeFrequencia.MESES.id && valorFrequencia > 35) {
          frequenciaMessageProps.errorMessage = 'A frenquência máxima permitida é de 35 meses'
        } else if (dataFinal.isBefore(!id ? datasRepeticoes[0] : this.props.dataInicial, 'day')) {
          inputDataFinalProps.errorMessage = 'A data mínima para o termino dessa repetição é ' + (!id ? datasRepeticoes[0] : this.props.dataInicial).format('DD/MM/YYYY')
        } else if (dataFinal.isAfter(dataFinalLimite, 'day')) {
          inputDataFinalProps.errorMessage = 'A data máxima para o termino dessa repetição é ' + dataFinalLimite.format('DD/MM/YYYY')
        } else if (dataFinal.isBefore(this.props.dataInicial, 'day')) {
          inputDataFinalProps.errorMessage = 'A data não pode ser menor que a data ' + this.props.prefixoEntidade + ' ' + this.props.nomeEntidade
        } else if (this.props.dataInicial && !datasRepeticoes[0].isAfter(dataFinal, 'day')) {
          frequenciaMessageProps.helpMessage = 'Próxima repetição: ' + datasRepeticoes[0].format('ddd, DD/MM/YYYY')
        }
      }
    }

    /*inputDataFinalProps.errorMessage = errorsMap("repeticao.dataFinal");
		frequenciaMessageProps.errorMessage = errorsMap(["repeticao.valorFrequencia", "repeticao.unidadeFrequencia"]);

		if(this.props.dataInicial && dataFinal){
			const datasRepeticoes = RepeticaoService.gerarDatasRepeticoes(unidadeFrequencia,valorFrequencia, this.props.dataInicial, null);
			let dataFinalLimite = null;
			if(datasRepeticoes && datasRepeticoes.length){
				dataFinalLimite = datasRepeticoes[datasRepeticoes.length-1];
			}
			if(unidadeFrequencia.id === UnidadeFrequencia.MESES.id && valorFrequencia > 35) {
				inputDataFinalProps[errorsMap("repeticao.dataFinal") || dataFinal?"errorMessage":"helpMessage"]
				frequenciaMessageProps = "A frenquência máxima permitida é de 35 meses";
			}else if(!dataFinal || dataFinal.isBefore(!id?datasRepeticoes[0]:this.props.dataInicial, 'day')){
				inputDataFinalProps[errorsMap("repeticao.dataFinal") || dataFinal?"errorMessage":"helpMessage"] = "A data mínima para o termino dessa repetição é "+(!id?datasRepeticoes[0]:this.props.dataInicial).format("DD/MM/YYYY");
			}else if(dataFinal && dataFinal.isAfter(dataFinalLimite, 'day')){
				inputDataFinalProps[errorsMap("repeticao.dataFinal") || dataFinal?"errorMessage":"helpMessage"] = "A data máxima para o termino dessa repetição é "+dataFinalLimite.format("DD/MM/YYYY");
			}else if(dataFinal && dataFinal.isBefore(this.props.dataInicial, 'day')){
				inputDataFinalProps[errorsMap("repeticao.dataFinal") || dataFinal?"errorMessage":"helpMessage"] = "A data não pode ser menor que a data "+this.props.prefixoEntidade+" "+this.props.nomeEntidade;
			}else if(dataFinal && this.props.dataInicial && !datasRepeticoes[0].isAfter(dataFinal, 'day')){
				frequenciaMessageProps = "Próxima repetição: "+datasRepeticoes[0].format("ddd, DD/MM/YYYY");
			}
		}*/

    const show = showFunction ? showFunction() : true

    return (
      <div>
        {show && (
          <div>
            <Grid container alignItems="center">
              <Grid item xs>
                <SectionSeparator icon={<RepeatIcon />} title={this.props.title} />
              </Grid>
              <Grid item>
                <Switch
                  disabled={disabled || readOnly}
                  checked={repeticaoHabilitada}
                  onChange={(event) => {
                    this.setState({ repeticaoHabilitada: event.target.checked })
                  }}
                  color="secondary"
                />
              </Grid>
            </Grid>

            {repeticaoHabilitada && (
              <div style={{ marginBottom: 28 }}>
                <InputHorizontalContainer
                  customVariant={this.props.customVariant}
                  icon={this.props.showLabelIcons && <ReplayIcon />}
                  inputTexts={[
                    {
                      input: (
                        <InputText
                          customVariant={this.props.customVariant}
                          label="Repetir a cada:"
                          {...frequenciaMessageProps}
                          inputProps={{
                            style: {
                              maxWidth: 0
                            }
                          }}
                          startAdornment={
                            <Grid container alignItems="center" style={{ marginTop: 4 }}>
                              <Grid item style={{ paddingLeft: 0, marginRight: -8, marginLeft: -8 }}>
                                <InputSelect
                                  fullWidth={false}
                                  idname="xvalorFrequenciaRepeticao"
                                  customVariant="naked"
                                  classes={{
                                    inputSelect: classes.inputSelect
                                  }}
                                  value={valorFrequencia}
                                  disabled={disabled}
                                  readOnly={readOnly}
                                  onChange={(event) => {
                                    this.setState({ valorFrequencia: parseInt(event.target.value, 10) })
                                  }}
                                >
                                  {valoresFrequenciaRepeticao.map((quantidade) => (
                                    <MenuItem key={quantidade} value={quantidade}>
                                      {quantidade}
                                    </MenuItem>
                                  ))}
                                </InputSelect>
                              </Grid>
                              <Grid item style={{ paddingLeft: 12, marginRight: -8 }}>
                                <InputSelect
                                  fullWidth={false}
                                  nakedLeftPadding={false}
                                  idname="xperiodoFrequenciaRepeticao"
                                  customVariant="naked"
                                  defaultValue={unidadeFrequencia.id}
                                  disabled={disabled}
                                  readOnly={readOnly}
                                  classes={{
                                    inputSelect: classes.inputSelect
                                  }}
                                  onChange={(event) => {
                                    this.setState({ unidadeFrequencia: UnidadeFrequencia.getById(parseInt(event.target.value, 10)) })
                                  }}
                                >
                                  {UnidadeFrequencia.values().map((frequencia) => (
                                    <MenuItem key={frequencia.id} value={frequencia.id}>
                                      {frequencia.descricao}
                                    </MenuItem>
                                  ))}
                                </InputSelect>
                              </Grid>
                            </Grid>
                          }
                        />
                      )
                    }
                  ]}
                />

                <InputHorizontalContainer
                  customVariant={this.props.customVariant}
                  style={{ marginTop: 6 }}
                  icon={this.props.showLabelIcons && <LowPriorityIcon />}
                  inputTexts={[
                    {
                      input: (
                        <InputData
                          keyboard={true}
                          disabled={disabled}
                          readOnly={readOnly}
                          functionsMap={this.dataFinalRepeticaoFunctionMap}
                          {...inputDataFinalProps}
                          idname="xdataFinalRepeticao"
                          label={'Repetir até a data:'}
                          defaultValue={dataFinal ? dataFinal.toDate() : null}
                          changeOnKeyPress={true}
                          onChange={(date) => {
                            this.setState({ dataFinal: date ? moment(date) : null })
                          }}
                          customVariant={this.props.customVariant}
                          shrink={this.props.shrink}
                        />
                      )
                    }
                  ]}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

Repeticao.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Repeticao)
