import React, { Component } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import StyleUtil from 'support/util/StyleUtil'

const IMAGE_BOX_SHADOW = StyleUtil.createBoxShadow('1px 1px 3px 1px rgba(0,0,0,0.10)')

const styles = (theme) => ({
  containerClickable: {
    cursor: 'pointer'
  },
  imageFrame: {
    border: '1px solid #d6d6d6',
    padding: 5,
    display: 'inline-block',
    borderRadius: 10000,
    verticalAlign: 'middle'
  },
  image: {
    display: 'block',
    ...IMAGE_BOX_SHADOW,
    borderRadius: 10000
  },
  rootAvatar: {
    cursor: 'pointer',
    display: 'inline-flex',
    backgroundColor: '#e0e0e0',
    width: 32,
    height: 32,
    fontSize: '1.2rem',
    color: '#555',
    flexDirection: 'column'
  },
  rootAvatarClickable: {
    '&:hover': {
      backgroundColor: '#d0d0d0'
    }
  },
  rootIcon: {
    color: 'white',
    fontSize: 24
  },
  photoContextLabel: {
    marginTop: -12,
    fontWeight: 500,
    color: 'white'
  }
})

const DEFAULT_WIDTH = 32

class PhotoPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  closeItemMenu = (event) => {
    event.stopPropagation()
    this.setState({ menuAnchorPosition: null })
  }

  render() {
    const { menuAnchorPosition } = this.state

    const { classes, photo1x, photo2x, title, hasWritePermission, preCheckWritePermission } = this.props

    let { readOnly } = this.props
    if (!hasWritePermission && preCheckWritePermission) {
      readOnly = true
    }

    let { width } = this.props
    if (!width) {
      width = DEFAULT_WIDTH
    }

    let content = null
    if (photo1x) {
      let srcSetString = undefined
      if (photo2x) {
        srcSetString = photo1x + ' 1x, ' + photo2x + ' 2x'
      }

      let onClickFunction
      const containerClasses = []
      if (!readOnly) {
        onClickFunction = (event) => {
          event.stopPropagation()
          this.setState({
            menuAnchorPosition: {
              left: event.clientX,
              top: event.clientY
            }
          })
        }
        containerClasses.push(classes.containerClickable)
      }

      if (this.props.framedImage) {
        containerClasses.push(classes.imageFrame)
      }

      content = (
        <div className={classNames(containerClasses)}>
          <img style={{ borderRadius: width }} onClick={onClickFunction} src={photo1x} srcSet={srcSetString} width={width} alt={title} className={classes.image} />
          <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={this.closeItemMenu}>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation()
                this.props.editFunction()
                this.closeItemMenu(event)
              }}
            >
              Alterar Foto
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation()
                this.props.removeFunction()
                this.closeItemMenu(event)
              }}
            >
              Remover Foto
            </MenuItem>
          </Menu>
        </div>
      )
    } else {
      let onClickFunction
      const containerClasses = [classes.rootAvatar]
      if (!readOnly) {
        onClickFunction = (event) => {
          event.stopPropagation()
          this.props.editFunction()
        }
        containerClasses.push(classes.containerClickable)
        containerClasses.push(classes.rootAvatarClickable)
      }
      content = (
        <Avatar className={classNames(containerClasses)} onClick={onClickFunction} style={{ width: width, height: width }}>
          <PhotoCameraIcon className={classes.rootIcon} style={{ fontSize: width / 2 + 4, display: 'block', marginTop: 1 }} />
          {this.props.photoContextLabel && (
            <React.Fragment>
              <Typography variant="body1" className={classes.photoContextLabel}>
                {this.props.photoContextLabel}
              </Typography>
            </React.Fragment>
          )}
        </Avatar>
      )
    }

    return content
  }
}

PhotoPicker.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PhotoPicker)
