import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import CategoriaProdutoDialogPage from 'pages/erp/painel/inventario/CategoriaProdutoDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class CategoriaInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    const creationFunction = (props) => {
      openBackableDialog(CategoriaProdutoDialogPage, {
        nome: props.text
      })
    }

    if (!props.label) props.label = 'Categoria'
    if (!props.id) props.id = 'xcategoria'
    if (!props.name) props.name = 'xcategoria'

    if (props.defaultValue !== undefined) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.creationFunction = creationFunction
    props.DialogSelectProps.creationPermission = verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])
    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Categoria...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'erp/inventario.buscarCategorias'
    props.DialogSelectProps.searchParamName = 'nome'
    props.DialogSelectProps.generateItemData = (categoria) => {
      return {
        value: categoria.id,
        label: categoria.nome,
        primary: categoria.nome
      }
    }

    props.DialogSelectProps.autoSelectDefinitons = [
      {
        eventName: 'ManutencaoCategoriaProduto',
        itemPropName: 'categoria'
      }
    ]

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhuma categoria foi encontrada',
        textButton: 'Cadastrar categoria',
        creationFunction: creationFunction
      }
    }

    return <InputDialogSelect {...props} />
  }
}

CategoriaInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CategoriaInputDialogSelect)
