import React, { Component } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NAKED_MARGIN_LEFT, OUTLINED_MARGIN_LEFT, OUTLINED_SMALL_MARGIN_LEFT } from 'support/components/input/core/Input'
import InputText from 'support/components/input/InputText'
import { PLACEHOLDER_COLOR } from 'support/style/ThemeManager.js'

import Constants from '../../../Constants'

const hoverOrFocusOption = 'rgba(0, 0, 0, 0.06)'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0
  },
  inputAutocompleteOutlined: {
    display: 'flex',
    padding: '13px 0px 11px ' + OUTLINED_MARGIN_LEFT + 'px !important'
  },
  inputAutocompleteOutlinedSmall: {
    display: 'flex',
    padding: '6px 0px 6px ' + OUTLINED_SMALL_MARGIN_LEFT + 'px !important',
    fontSize: '14px'
  },
  inputFilled: {
    display: 'flex',
    padding: 0,
    paddingTop: 2
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: theme.spacing(0.5) + 'px ' + theme.spacing(0.25) + 'px'
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
  },
  noOptionsMessage: {
    padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px'
  },
  singleValue: {
    fontSize: 16
  },
  singleValueOutlinedSmall: {
    fontSize: 14
  },
  placeholder: {
    position: 'absolute',
    left: 0,
    fontSize: 16
  },
  placeholderOutlined: {
    position: 'absolute',
    left: OUTLINED_MARGIN_LEFT,
    fontSize: 16
  },
  placeholderOutlinedSmall: {
    position: 'absolute',
    left: OUTLINED_MARGIN_LEFT,
    fontSize: 14
  },
  placeholderFilled: {
    position: 'absolute',
    left: theme.inputs.filledMarginLeft,
    fontSize: 16
  },
  placeholderNaked: {
    position: 'absolute',
    left: NAKED_MARGIN_LEFT,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  optionItemMenu: {
    '&:hover': {
      backgroundColor: hoverOrFocusOption + ' !important'
    }
  },
  optionItemMenuDisabled: {
    cursor: 'default',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  optionItemMenuSelected: {
    backgroundColor: hoverOrFocusOption + ' !important'
  },
  optionItemMenuSelectedDisabled: {
    cursor: 'default',
    pointerEvents: 'none',
    backgroundColor: 'transparent !important'
  }
})

function NoOptionsMessage(props) {
  let value = null
  if (props.selectProps.inputValue && props.selectProps.inputValue.trim().length > 0) {
    value = props.selectProps.inputValue
  }

  if (value !== null) {
    return (
      <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
        {props.children}
      </Typography>
    )
  }

  return []
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  const othersInputTextProps = props.selectProps.textFieldProps
  delete othersInputTextProps.loadStaticOptionsFunction
  delete othersInputTextProps.loadOptionsFunction
  delete othersInputTextProps.noOptionsMessage
  delete othersInputTextProps.preFilterFunction
  delete othersInputTextProps.suggestionMode
  delete othersInputTextProps.itemRenderProps
  delete othersInputTextProps.filterListOption
  delete othersInputTextProps.renderMenuWhenNoOptions

  return (
    <InputText
      customVariant={props.selectProps.customVariant}
      shrink={true}
      autoFocus={props.selectProps.autoFocus}
      InputProps={{
        inputComponent,
        inputProps: {
          className:
            props.selectProps.customVariant === 'outlined'
              ? props.selectProps.classes.inputAutocompleteOutlined
              : props.selectProps.customVariant === 'outlined-small'
              ? props.selectProps.classes.inputAutocompleteOutlinedSmall
              : props.selectProps.customVariant === 'filled'
              ? props.selectProps.classes.input
              : props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...othersInputTextProps}
    />
  )
}

function Option(props) {
  let itemOutput = props.children

  if (props.selectProps.itemRenderProps.renderFunction) {
    itemOutput = props.selectProps.itemRenderProps.renderFunction(props.data)
  }

  let styleProps = {}
  if (props.isSelected) {
    styleProps.fontWeight = 500
  }
  styleProps.fontWeight = 400

  if (props.selectProps.itemRenderProps.padding === false) {
    styleProps.padding = 0
    styleProps.height = 'auto'
  }

  return (
    <MenuItem
      classes={{
        root: !props.isDisabled ? props.selectProps.optionItemMenuClassName : props.selectProps.optionItemMenuDisabledClassName,
        selected: !props.isDisabled ? props.selectProps.optionItemMenuSelectedClassName : props.selectProps.optionItemMenuSelectedDisabledClassName
      }}
      buttonRef={props.innerRef}
      selected={props.isFocused && !props.isDisabled}
      component="div"
      style={styleProps}
      {...props.innerProps}
    >
      {itemOutput}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      style={{ color: PLACEHOLDER_COLOR }}
      className={
        props.selectProps.customVariant === 'filled'
          ? props.selectProps.classes.placeholderFilled
          : props.selectProps.customVariant === 'naked'
          ? props.selectProps.classes.placeholderNaked
          : props.selectProps.customVariant === 'outlined'
          ? props.selectProps.classes.placeholderOutlined
          : props.selectProps.customVariant === 'outlined-small'
          ? props.selectProps.classes.placeholderOutlinedSmall
          : props.selectProps.classes.placeholder
      }
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.customVariant === 'outlined-small' ? props.selectProps.classes.singleValueOutlinedSmall : props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  let showMenu = true

  if (props.options.length === 0 && (!props.selectProps.inputValue || props.selectProps.inputValue.trim().length === 0)) {
    showMenu = false
  } else if (props.hasValue) {
    showMenu = false
  }
  return (
    showMenu && (
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    )
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator: null
}

class InputAutocomplete extends Component {
  constructor(props) {
    super(props)

    this.componentUnmounted = false
    this.contadorCarregamento = 0
    this.state = {
      isLoadingOptions: false,
      options: props.options ? props.options : [],
      staticOptions: props.options ? props.options : [],
      inputValue: '',
      single: this.props.defaultValue !== undefined ? this.props.defaultValue : null,
      multi: null
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap['getObject'] = () => {
        if (this.state.single) {
          return this.state.single
        } else {
          return null
        }
      }
      this.props.functionsMap['getValue'] = () => {
        if (this.state.single) {
          return this.state.single.value
        } else {
          return null
        }
      }
      this.props.functionsMap['getInputValue'] = () => {
        if (this.state.single) {
          return this.state.single.label
        } else if (this.props.suggestionMode && this.state.inputValue) {
          return this.state.inputValue
        } else {
          return ''
        }
      }
      this.props.functionsMap['hasSelectedOption'] = () => {
        if (this.state.single) {
          return true
        } else {
          return false
        }
      }
      this.props.functionsMap['getValues'] = () => this.state.multi
    }
    if (this.props.loadStaticOptionsFunction) {
      this.loadStaticAsyncOptions()
    }
  }

  loadStaticAsyncOptions = () => {
    const callbackSuccess = (options) => {
      this.setState({ staticOptions: options, options: this.props.filterListOption ? this.props.filterListOption('', options) : options, isLoadingOptions: false })
    }

    let calls = 0
    const callLoadStaticAsyncOptionsFunction = (opts) => {
      if (!opts) {
        opts = {}
      }
      calls++
      if (calls <= 999999 && this.componentUnmounted === false && !opts.stopLoading) {
        let delay = 0
        if (calls > 1 && calls <= 10) {
          delay = 2000
        } else if (calls > 10) {
          delay = 10000
        }
        window.setTimeout(() => {
          this.props.loadStaticOptionsFunction(callbackSuccess, callLoadStaticAsyncOptionsFunction)
        }, delay)
      } else {
        this.setState({ staticOptions: [], options: [], isLoadingOptions: false })
      }
    }

    this.setState({ staticOptions: [], options: [], isLoadingOptions: true })
    callLoadStaticAsyncOptionsFunction()
  }

  handleChange = (name) => (value) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }

    this.setState({
      [name]: value
    })
  }

  executePreFilter = (options) => {
    const filteredOptions = []
    if (this.props.preFilterFunction) {
      for (let option of options) {
        if (this.props.preFilterFunction(option)) {
          filteredOptions.push(option)
        }
      }
      return filteredOptions
    } else {
      return options
    }
  }

  loadOptions = (inputValue, callback) => {
    this.ultimaSolicitacaoBusca = new Date().getTime()
    const carregamentoAtual = ++this.contadorCarregamento

    window.setTimeout(() => {
      if (new Date().getTime() - this.ultimaSolicitacaoBusca < Constants.DELAY_EXECUTAR_BUSCA_POR_DIGITACAO) {
        return
      }

      if (inputValue === undefined || inputValue === null) {
        inputValue = ''
      }

      const callbackSuccess = (options) => {
        if (carregamentoAtual === undefined || carregamentoAtual === this.contadorCarregamento) {
          callback(this.executePreFilter(options))
        }
      }

      let calls = 0
      const callLoadOptionsFunction = () => {
        calls++
        if (calls <= 3 && this.componentUnmounted === false) {
          window.setTimeout(
            () => {
              this.props.loadOptionsFunction(inputValue, callbackSuccess, callLoadOptionsFunction)
            },
            calls === 1 ? 0 : 2000
          )
        } else {
          callback(null)
        }
      }

      callLoadOptionsFunction()
    }, Constants.DELAY_EXECUTAR_BUSCA_POR_DIGITACAO + 15)
  }

  render() {
    const { classes, theme } = this.props

    let itemRenderProps = {}
    if (this.props.itemRenderProps) {
      itemRenderProps = this.props.itemRenderProps
    }

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        },
        marginLeft: 0
      }),
      menuList: (base) => {
        return { ...base, padding: itemRenderProps.padding === false ? 0 : 4 }
      },
      clearIndicator: (base) => {
        return { ...base, cursor: 'pointer', paddingTop: 6, paddingBottom: 6 }
      },
      dropdownIndicator: (base) => {
        return { ...base, cursor: 'pointer', paddingTop: 6, paddingBottom: 6, width: 1, paddingLeft: 0, paddingRight: 0 }
      }
    }

    const textFieldProps = {}
    for (let key in this.props) {
      if (key !== 'classes' && key !== 'theme') {
        textFieldProps[key] = this.props[key]
      }
    }

    const selectProps = {}
    selectProps.textFieldProps = textFieldProps
    selectProps.classes = classes
    selectProps.styles = selectStyles

    //selectProps.menuIsOpen = true;
    selectProps.menuIsOpen = this.props.menuIsOpen

    selectProps.components = components
    selectProps.autoFocus = this.props.autoFocus
    selectProps.placeholder = this.props.placeholder ? this.props.placeholder : ''
    selectProps.customVariant = this.props.customVariant
    selectProps.isDisabled = this.props.disabled
    selectProps.value = this.state.single
    selectProps.onChange = this.handleChange('single')
    selectProps.isClearable = true
    selectProps.itemRenderProps = itemRenderProps
    selectProps.filterOption = this.props.filterListOption ? () => true : this.props.filterOption
    selectProps.loadingMessage = () => {
      return 'Carregando...'
    }
    selectProps.noOptionsMessage = () => {
      if (this.props.renderMenuWhenNoOptions === false) {
        return null
      } else {
        return this.props.noOptionsMessage ? this.props.noOptionsMessage : 'Nenhum registro encontrado'
      }
    }
    selectProps.onInputChange = (string, action) => {
      if (this.props.onInputChange) {
        this.props.onInputChange(string)
      }
      if (this.props.filterListOption) {
        this.setState({ options: this.props.filterListOption(string, this.state.staticOptions) })
      }
      if (this.props.suggestionMode === true) {
        if (action.action !== 'menu-close' && action.action !== 'input-blur') {
          this.setState({ inputValue: string, single: null })
        }
      }
    }

    selectProps.optionItemMenuClassName = classes.optionItemMenu
    selectProps.optionItemMenuDisabledClassName = classes.optionItemMenuDisabled
    selectProps.optionItemMenuSelectedClassName = classes.optionItemMenuSelected
    selectProps.optionItemMenuSelectedDisabledClassName = classes.optionItemMenuSelectedDisabled

    if (this.props.suggestionMode === true) {
      selectProps.inputValue = this.state.inputValue
    }

    // corrigir bug: ao pressionar space com foco no input com valor já selecionado, o valor é trocado para o primeiro valor que apareceria nas sugestões
    selectProps.onKeyDown = (e) => {
      if (e.keyCode === 32 && this.state.single) {
        e.preventDefault()
      }
    }

    let select = null

    if (this.props.loadStaticOptionsFunction) {
      selectProps.defaultOptions = this.executePreFilter(this.state.staticOptions)
      selectProps.options = this.executePreFilter(this.state.options)
      selectProps.isLoading = this.state.isLoadingOptions
      select = <Select {...selectProps} />
    } else if (this.props.options) {
      //selectProps.defaultOptions=this.props.options;
      //selectProps.options=this.props.options;
      selectProps.defaultOptions = this.executePreFilter(this.state.staticOptions)
      selectProps.options = this.executePreFilter(this.state.options)
      select = <Select {...selectProps} />
    } else if (this.props.loadOptionsFunction) {
      selectProps.defaultOptions = true
      selectProps.loadOptions = this.loadOptions
      select = <AsyncSelect {...selectProps} />
    }

    return select
  }

  componentWillUnmount() {
    this.componentUnmounted = true
  }
}

export const InputAutocompleteUtils = {
  populateProps: (mainProps, props) => {
    if (mainProps.style !== undefined) {
      props.style = mainProps.style
    }

    if (mainProps.id !== undefined) {
      props.id = mainProps.id
    }

    if (mainProps.name !== undefined) {
      props.name = mainProps.name
    }

    if (mainProps.label !== undefined) {
      props.label = mainProps.label
    }

    if (mainProps.autoFocus !== undefined) {
      props.autoFocus = mainProps.autoFocus
    }

    if (mainProps.customVariant !== undefined) {
      props.customVariant = mainProps.customVariant
    }

    if (mainProps.placeholder !== undefined) {
      props.placeholder = mainProps.placeholder
    }

    if (mainProps.disabled !== undefined) {
      props.disabled = mainProps.disabled
    }

    if (mainProps.defaultValue !== undefined) {
      props.defaultValue = mainProps.defaultValue
    }

    if (mainProps.errorMessage !== undefined) {
      props.errorMessage = mainProps.errorMessage
    }

    if (mainProps.onChange !== undefined) {
      props.onChange = mainProps.onChange
    }

    if (mainProps.suggestionMode !== undefined) {
      props.suggestionMode = mainProps.suggestionMode
    }

    if (mainProps.functionsMap !== undefined) {
      props.functionsMap = mainProps.functionsMap
    }

    if (mainProps.preFilterFunction !== undefined) {
      props.preFilterFunction = mainProps.preFilterFunction
    }
  }
}

InputAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(InputAutocomplete)
