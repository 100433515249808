import PeriodoService from 'support/domain/periodo/PeriodoService'
import NivelParaleloEnum from 'support/domain/servico/NivelParaleloEnum'
import TimeUtil from 'support/util/TimeUtil'

const ID_QUALQUER_COLABORADOR = 0

const gerarHorariosDisponiveisPorColaborador = (periodosHorariosServicosSelecionados, periodosHorariosIndisponiveis, colaborador, negocio, variacao) => {
  let horariosDisponiveis = PeriodoService.gerarHorariosDisponiveis(
    colaborador.periodosHorariosDisponiveis,
    periodosHorariosIndisponiveis,
    negocio.configuracoesGerais.intervaloHorarioAgendamentoOnline.id,
    variacao.duracao,
    negocio.configuracoesGerais.horarioInteligenteAgendamentoOnline,
    colaborador.horariosDisponiveisFixos
  )

  const possiveisHorariosParaSequencia = []
  for (let periodoHorarioServicoParalelo of periodosHorariosServicosSelecionados.paralelos) {
    possiveisHorariosParaSequencia.push(TimeUtil.adiciconarMinutosHorarioInt(periodoHorarioServicoParalelo.de, variacao.duracao * -1))
    possiveisHorariosParaSequencia.push(periodoHorarioServicoParalelo.de)
    possiveisHorariosParaSequencia.push(periodoHorarioServicoParalelo.ate)
  }
  for (let periodoHorarioServicoNaoParalelo of periodosHorariosServicosSelecionados.naoParalelos) {
    possiveisHorariosParaSequencia.push(TimeUtil.adiciconarMinutosHorarioInt(periodoHorarioServicoNaoParalelo.de, variacao.duracao * -1))
    possiveisHorariosParaSequencia.push(periodoHorarioServicoNaoParalelo.ate)
  }

  if (possiveisHorariosParaSequencia.length > 0) {
    const horariosComplementaresParaSequenciaDeServicos = PeriodoService.gerarHorariosDisponiveis(
      colaborador.periodosHorariosDisponiveis,
      periodosHorariosIndisponiveis,
      negocio.configuracoesGerais.intervaloHorarioAgendamentoOnline.id,
      variacao.duracao,
      true,
      possiveisHorariosParaSequencia
    )
    for (let horarioComplementarParaSequenciaDeServicos of horariosComplementaresParaSequenciaDeServicos) {
      if (horariosDisponiveis.indexOf(horarioComplementarParaSequenciaDeServicos) < 0) {
        horariosDisponiveis.push(horarioComplementarParaSequenciaDeServicos)
      }
    }
    horariosDisponiveis.sort((a, b) => {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    })
  }

  return horariosDisponiveis
}

const getPeriodosHorariosServicosSelecionados = (itens, item, colaborador) => {
  const periodosHorariosServicosParalelos = []
  const periodosHorariosServicosNaoParalelos = []
  for (let itemParaVerificar of itens) {
    if (itemParaVerificar.periodoHorarioSelecionado) {
      let podeAgendarParalelo = true
      if (colaborador.id === itemParaVerificar.colaborador.id) {
        podeAgendarParalelo = false
      } else if (item.servico.nivelParalelo.id === NivelParaleloEnum.RESTRITO.id || itemParaVerificar.servico.nivelParalelo.id === NivelParaleloEnum.RESTRITO.id) {
        podeAgendarParalelo = false
      } else if (item.servico.nivelParalelo.id === NivelParaleloEnum.PARCIAL.id && itemParaVerificar.servico.nivelParalelo.id === NivelParaleloEnum.PARCIAL.id) {
        podeAgendarParalelo = false
      }
      if (podeAgendarParalelo === true) {
        periodosHorariosServicosParalelos.push({
          de: itemParaVerificar.periodoHorarioSelecionado.de,
          ate: itemParaVerificar.periodoHorarioSelecionado.ate
        })
      } else {
        periodosHorariosServicosNaoParalelos.push({
          de: itemParaVerificar.periodoHorarioSelecionado.de,
          ate: itemParaVerificar.periodoHorarioSelecionado.ate
        })
      }
    }
  }
  return {
    paralelos: periodosHorariosServicosParalelos,
    naoParalelos: periodosHorariosServicosNaoParalelos
  }
}

const gerarHorariosDisponiveis = (colaborador, negocio, item, itens) => {
  let horariosDisponiveis = []
  if (colaborador.id === ID_QUALQUER_COLABORADOR) {
    for (let itemColaborador of item.colaboradores) {
      const periodosHorariosServicosSelecionados = getPeriodosHorariosServicosSelecionados(itens, item, itemColaborador)
      let periodosHorariosIndisponiveis = periodosHorariosServicosSelecionados.naoParalelos.slice()
      if (item.servico.periodosHorariosIndisponiveis) {
        periodosHorariosIndisponiveis = PeriodoService.mesclarListaPeriodos(periodosHorariosIndisponiveis.concat(item.servico.periodosHorariosIndisponiveis))
      }

      const horariosDisponiveisPorColaborador = gerarHorariosDisponiveisPorColaborador(
        periodosHorariosServicosSelecionados,
        periodosHorariosIndisponiveis,
        itemColaborador,
        negocio,
        item.variacao
      )
      for (let horarioDisponivelPorColaborador of horariosDisponiveisPorColaborador) {
        if (horariosDisponiveis.indexOf(horarioDisponivelPorColaborador) < 0) {
          horariosDisponiveis.push(horarioDisponivelPorColaborador)
        }
      }
    }

    horariosDisponiveis.sort((a, b) => {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    })
  } else {
    const periodosHorariosServicosSelecionados = getPeriodosHorariosServicosSelecionados(itens, item, colaborador)
    let periodosHorariosIndisponiveis = periodosHorariosServicosSelecionados.naoParalelos.slice()
    if (item.servico.periodosHorariosIndisponiveis) {
      periodosHorariosIndisponiveis = PeriodoService.mesclarListaPeriodos(periodosHorariosIndisponiveis.concat(item.servico.periodosHorariosIndisponiveis))
    }

    horariosDisponiveis = gerarHorariosDisponiveisPorColaborador(periodosHorariosServicosSelecionados, periodosHorariosIndisponiveis, colaborador, negocio, item.variacao)
  }
  return horariosDisponiveis
}

export { gerarHorariosDisponiveisPorColaborador, getPeriodosHorariosServicosSelecionados, gerarHorariosDisponiveis }
