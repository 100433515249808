export function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str
  return str.substr(0, index) + chr + str.substr(index + 1)
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function searchPart(str, part, opts) {
  if (!opts) {
    opts = {}
  }
  if (!opts.matchFrom) {
    opts.matchFrom = 'any'
  }

  str = removeAccentsAndSpecialChars(str)
  part = removeAccentsAndSpecialChars(part)

  if (opts.anyDistanceBetweenWords) {
    part = part.replace(/\s/g, '.*')
  }

  let strRegex = (opts.matchFrom === 'start' ? '^' : '.*') + part + '.*'
  var regex = new RegExp(strRegex, 'gi')
  return str.match(regex)
}

export function removeAccentsAndSpecialChars(str) {
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  str = str.replace(/[^a-zA-Z0-9\s]/g, '')
  return str
}

export function applyMaxWords(string, props) {
  let stringParts = string.split(' ')
  if (stringParts.length > props.maxWords) {
    stringParts = stringParts.slice(0, props.maxWords)
  }
  return stringParts.join(' ')
}

const functionsObject = {
  setCharAt: setCharAt,
  capitalizeFirstLetter: capitalizeFirstLetter,
  applyMaxWords: applyMaxWords,
  searchPart: searchPart
}

export default functionsObject
