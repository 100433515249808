import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import BaseDialog from 'support/components/dialog/BaseDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class AlteracaoFotoDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorsMap: createErrorsMap(),
      ajaxing: false
    }
    this.dataFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState({
      ajaxing: true,
      errorsMap: createErrorsMap()
    })

    postAPI({
      url: 'erp/clientes.alterarInformacoesFotoGaleria',
      data: {
        id: this.props.foto.id,
        data: this.dataFunctionsMap.getDataAsInt()
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoFotoGaleria')
        this.props.setShouldCloseParent(true)
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) })
        focusFirstElementWithError('formAlteracaoFoto')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { ajaxing, errorsMap } = this.state
    const { foto } = this.props

    return (
      <BaseDialog
        {...dialogProps}
        title={'Alterar data'}
        actions={
          <Button color="secondary" onClick={this.salvar}>
            SALVAR
          </Button>
        }
        maxWidth={290}
        hasCloseButton={true}
      >
        <form id="formAlteracaoFoto" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
          <InputData
            disabled={ajaxing}
            functionsMap={this.dataFunctionsMap}
            keyboard={true}
            idname="xdata"
            label="Data"
            customVariant={'outlined-small'}
            shrink={false}
            errorMessage={errorsMap('data')}
            defaultValue={foto.data}
          />
          <FormUtil.HiddenSubmitButton />
        </form>
      </BaseDialog>
    )
  }
}

AlteracaoFotoDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AlteracaoFotoDialog)
