import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import TabContainer from 'support/components/tab/TabContainer'

const styles = (theme) => ({
  hasText: {},
  root: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2.5)
  },
  textContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      '$hasText &': {
        marginTop: theme.spacing(1)
      }
    }
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
})

class TabContainerPersistHeader extends Component {
  render() {
    const { classes, text, buttonsContent, addTabContainerPadding, align, ...others } = this.props

    const rootClasses = [classes.root]
    if (text) {
      rootClasses.push(classes.hasText)
    }

    let content = (
      <Grid container className={classNames(rootClasses)} alignItems="center" justify="center" {...others}>
        {text && (
          <Grid item xs={12} sm={true} className={classes.textContainer}>
            <Typography variant="body1" className={classes.text} align={align}>
              {text}
            </Typography>
          </Grid>
        )}
        {buttonsContent && (
          <Grid item className={classes.buttonContainer}>
            {buttonsContent}
          </Grid>
        )}
      </Grid>
    )

    if (addTabContainerPadding) {
      content = <TabContainer style={{ paddingBottom: 0, marginBottom: 0 }}>{content}</TabContainer>
    }

    return content
  }
}

TabContainerPersistHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabContainerPersistHeader)
