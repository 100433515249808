import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'
import moment from 'moment'
import { getSessaoPainel, verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import NotasPesquisaAvancadaDialog from 'pages/erp/painel/venda/NotasPesquisaAvancadaDialog'
import NotasResponsiveTable from 'pages/erp/painel/venda/table/NotasResponsiveTable'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import StatusNotaVendaEnum from 'support/domain/venda/StatusNotaVendaEnum'
import { converterDateParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { createEnum } from 'support/util/EnumUtil'

export const StatusFiltroEnum = createEnum({
  TODAS: { id: 1, descricao: 'Exibir Todas', statusNotaId: null },
  NOTA_ABERTA: { id: 2, descricao: 'Comandas Abertas', statusNotaId: StatusNotaVendaEnum.ABERTA.id, statusNota: StatusNotaVendaEnum.ABERTA },
  NOTA_FECHADA: { id: 3, descricao: 'Comandas Fechadas', statusNotaId: StatusNotaVendaEnum.FECHADA.id, statusNota: StatusNotaVendaEnum.FECHADA },
  CANCELADAS: { id: 4, descricao: 'Comandas Canceladas' }
})

const MAX_WIDTH_CONTENT = 1270

const styles = (theme) => ({})

class NotasPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      title: 'Comandas',
      status: this.colaboradorLocalPreferences.getPreference('NotasPanelPageFiltro_Status', StatusFiltroEnum.TODAS),
      ajaxing: false
    }

    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNota', 'ManipulacaoVendas', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  abrirPesquisaAvancadaDialog = () => {
    openBackableDialog(NotasPesquisaAvancadaDialog, {
      cliente: this.state.cliente,
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal,
      setParentState: (state) => {
        this.setState(state)
      }
    })
  }

  getRequestParameters = () => {
    let cancelado = null
    if (this.state.status.id === StatusFiltroEnum.CANCELADAS.id) {
      cancelado = true
    } else if (this.state.status.statusNota) {
      cancelado = false
    }

    let dataFinal = converterDateParaDataInt(this.state.dataFinal)
    const hojeAsDataInt = converterDateParaDataInt(new Date())

    if (!dataFinal) {
      dataFinal = hojeAsDataInt
    }

    return {
      nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null,
      idStatus: this.state.status.statusNota ? this.state.status.statusNota.id : null,
      cancelado: cancelado,
      idCliente: this.state.cliente ? this.state.cliente.id : null,
      dataInicial: converterDateParaDataInt(this.state.dataInicial),
      dataFinal: dataFinal
    }
  }

  getChipsFiltrosAvancados = () => {
    const { cliente, dataInicial, dataFinal } = this.state
    const chips = []

    if (cliente && cliente.id) {
      chips.push({
        label: 'Cliente: ' + cliente.nome,
        onDelete: () => this.setState({ cliente: undefined })
      })
    }

    if (dataInicial && dataFinal) {
      chips.push({
        label: moment(dataInicial).format('DD/MMM/YYYY') + ' até ' + moment(dataFinal).format('DD/MMM/YYYY'),
        onDelete: () => this.setState({ dataInicial: undefined, dataFinal: undefined })
      })
    } else {
      if (dataInicial) {
        chips.push({
          label: 'A partir: ' + moment(dataInicial).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataInicial: undefined })
        })
      }

      if (dataFinal) {
        chips.push({
          label: 'Até: ' + moment(dataFinal).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataFinal: undefined })
        })
      }
    }
    return chips
  }

  abrirNotaDialog = (event, nota) => {
    event.stopPropagation()
    openBackableDialog(NotaDialogPage, {
      idNota: nota.id,
      retornarVendas: false
    })
  }

  getLoaderKey = () => {
    const { status, cliente, dataInicial, dataFinal } = this.state

    let key = 'loader'

    if (status) {
      key += '-f' + status.id
    }

    if (cliente && cliente.id) {
      key += '-cli' + cliente
    }

    if (dataInicial) {
      key += '-di' + dataInicial
    }

    if (dataFinal) {
      key += '-df' + dataFinal
    }

    return key
  }

  downloadRelatorio = (event) => {
    const { dataInicial, dataFinal } = this.state

    let filename = 'Lista de Comandas'
    if (dataInicial || dataFinal) {
      filename += ' - ' + formatarPeriodoNomeArquivo(dataInicial, dataFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'vendas.gerarListaNotas',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { status } = this.state

    const permissaoPodeVender = verificarAutorizacao([PA.PODE_VENDER])

    const contadorInfo = {
      [StatusFiltroEnum.NOTA_ABERTA.descricao]: null,
      [StatusFiltroEnum.NOTA_FECHADA.descricao]: null,
      [StatusFiltroEnum.CANCELADAS.descricao]: null
    }
    for (let item of this.state.lista) {
      let descricaoAgrupamento = StatusFiltroEnum.getByStatusNotaId(item.status.id).descricao

      if (item.cancelada) {
        descricaoAgrupamento = StatusFiltroEnum.CANCELADAS.descricao
      }

      if (contadorInfo[descricaoAgrupamento] === null) {
        contadorInfo[descricaoAgrupamento] = {
          quantidade: 0,
          valor: 0
        }
      }
      const contadorInfoStatus = contadorInfo[descricaoAgrupamento]
      contadorInfoStatus.quantidade = contadorInfoStatus.quantidade + 1
      contadorInfoStatus.valor = contadorInfoStatus.valor + item.valorTotal
    }

    for (let descricaoAgrupamentoNull of Object.keys(contadorInfo).filter((descricao) => !contadorInfo[descricao])) {
      delete contadorInfo[descricaoAgrupamentoNull]
    }

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push(
      {
        icon: <RefreshIcon />,
        disabled: !permissaoPodeVender,
        handleAction: () => {
          this.loaderFunctionsMap.load()
        }
      },
      {
        icon: <TuneIcon />,
        disabled: !permissaoPodeVender,
        handleAction: this.abrirPesquisaAvancadaDialog
      }
    )

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <PanelPage {...others} title={this.state.title} toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent maxWidth={MAX_WIDTH_CONTENT} chips={this.getChipsFiltrosAvancados()} type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 16 }}>
              <InputSelect
                disabled={!permissaoPodeVender}
                nakedLeftPadding={false}
                customVariant="naked"
                value={status.id}
                fullWidth={false}
                onChange={(event) => {
                  const statusFiltro = StatusFiltroEnum.getById(parseInt(event.target.value, 10))
                  this.colaboradorLocalPreferences.setPreference('NotasPanelPageFiltro_Status', statusFiltro)
                  this.setState({ status: statusFiltro })
                }}
              >
                {StatusFiltroEnum.values().map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.descricao}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item xs style={{ marginRight: 16, minWidth: 200 }}>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    disabled={!permissaoPodeVender}
                    defaultValue={this.colaboradorLocalPreferences.getPreference('NotasPanelPageFiltro_Texto', '')}
                    nakedLeftPadding={false}
                    placeholder="Procurar por palavra..."
                    onChange={(event) => {
                      this.colaboradorLocalPreferences.setPreference('NotasPanelPageFiltro_Texto', event.target.value)
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>
        <br />
        <NotasResponsiveTable
          itemsPerRequest={20}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/vendas.buscarNotasVenda"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.abrirNotaDialog(event, item)
          }}
          emptyListProps={{
            text: 'Nenhuma comanda foi encontrada'
          }}
        />

        {verificarAutorizacao([PA.PODE_VISUALIZAR_TOTALIZADORES_VALORES]) && this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            {Object.keys(contadorInfo).map((descricao, index) => {
              const contadorInfoItem = contadorInfo[descricao]
              return (
                <React.Fragment key={index}>
                  <Typography variant="body2" style={{ paddingTop: index === 0 ? 6 : 0 }}>
                    <b>{descricao}</b>
                    <br />
                    <small>
                      Quantidade: <b>{contadorInfoItem.quantidade}</b>
                      {/*
													contadorInfoItem.valor > 0 &&
													<React.Fragment>
														, Total: <b>{formatarValorMonetario(contadorInfoItem.valor)}</b>
													</React.Fragment>
												*/}
                    </small>
                  </Typography>
                  {index < Object.keys(contadorInfo).length - 1 && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
                </React.Fragment>
              )
            })}
          </FloatContent>
        )}
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

NotasPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotasPanelPage)
