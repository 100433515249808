import React, { Component } from 'react'
import ReactImageLightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import 'support/components/lightbox/style.css'

const styles = (theme) => ({})

class Lightbox extends Component {
  componentDidMount() {
    this.onKeyDownFunction = (event) => {
      event.stopPropagation()
      if (event.keyCode === 27) {
        this.props.onClose()
      }
    }
    document.addEventListener('keydown', this.onKeyDownFunction, false)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.open === true && nextProps.open === false) {
      document.removeEventListener('keydown', this.onKeyDownFunction, false)
    }
    return true
  }

  render() {
    const { classes, theme, open, onClose, parentNode, ...others } = this.props
    let content = null

    let parentSelector = undefined
    if (parentNode) {
      parentSelector = () => parentNode
    }

    if (open) {
      content = (
        <ReactImageLightbox
          {...others}
          reactModalStyle={{
            overlay: {
              zIndex: theme.zIndex.modal
            }
          }}
          onCloseRequest={() => {
            onClose()
          }}
          reactModalProps={{
            isOpen: true,
            shouldReturnFocusAfterClose: false,
            shouldFocusAfterRender: true,
            parentSelector: parentSelector
          }}
        />
      )
    }
    return content
  }
}

Lightbox.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(Lightbox)
