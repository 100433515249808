import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import OpcoesClienteCancelamentoPacoteItemEnum from 'support/domain/cliente/OpcoesClienteCancelamentoPacoteItemEnum'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  labelOpcaovalorEstorno: {
    paddingTop: 2,
    paddingBottom: 2
  }
})

class CancelarServicoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Cancelar serviço',
      clientePacoteItem: this.props.clientePacoteItem,
      errorsMap: createErrorsMap(),
      ajaxing: false,
      destinoValorEstorno: OpcoesClienteCancelamentoPacoteItemEnum.SEM_ESTORNO,
      valorEstorno: null,
      paginaCarregada: false
    }

    this.inputValor = React.createRef()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/clientes.cancelarPacoteItem',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.setShouldCloseParent(true)
        this.props.handleCloseDialog()
        EventsManager.pub('ManutencaoPacote')
        EventsManager.pub('RecarregarAtendimentos')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formCliente')
      },
      errorKey: 'caixa'
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/clientes.buscarDadosCancelarPacoteItemPrePersistencia',
      params: {
        idPacoteItem: this.props.clientePacoteItem.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState((state) => ({
          clientePacoteItem: response.data.clientePacoteItem
        }))
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  getDados = () => {
    const dados = {
      id: this.props.clientePacoteItem.id,
      valorEstorno: this.state.valorEstorno,
      destinoValorEstorno: this.state.destinoValorEstorno
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, destinoValorEstorno, ajaxing, clientePacoteItem } = this.state
    let nomeServico = null
    let quantidadeDisponivel = null

    if (clientePacoteItem) {
      nomeServico = clientePacoteItem.servico.nome
      if (clientePacoteItem.variacaoServico) {
        nomeServico += ' - ' + clientePacoteItem.variacaoServico.nome
      }
      quantidadeDisponivel = clientePacoteItem.quantidadeDisponivel
    }

    let notaComPagamento = false
    let notaComEstornoDiferentePagamento = false
    let notaComEstornoDiferenteCredito = false

    if (clientePacoteItem && clientePacoteItem.clientePacote && clientePacoteItem.clientePacote.vendaPacote.venda.notaVenda.valorPago > 0) {
      notaComPagamento = true
      const pagamentos = clientePacoteItem.clientePacote.vendaPacote.venda.notaVenda.pagamentos

      if (pagamentos && pagamentos.length > 0) {
        pagamentos.forEach((pagamento) => {
          if (pagamento.valorTotalEstornos > 0 && pagamento.formaPagamentoEstorno) {
            if (pagamento.formaPagamentoEstorno.tipo.id !== pagamento.formaPagamento.tipo.id) {
              notaComEstornoDiferentePagamento = true
            }

            if (pagamento.formaPagamentoEstorno.tipo.id !== FormaPagamentoTipoEnum.CREDITO.id) {
              notaComEstornoDiferenteCredito = true
            }
          }
        })
      }
    }

    const complementoDescricaoEstornoPagamento = !notaComPagamento
      ? '(Essa opção está desabilitada porque não existem registro de pagamentos para este pacote)'
      : notaComEstornoDiferentePagamento
      ? '(Essa opção está desabilitada porque a fatura #' +
        clientePacoteItem.clientePacote.vendaPacote.venda.notaVenda.numero +
        ' já possui estorno em ' +
        (!notaComEstornoDiferenteCredito ? 'crédito' : 'dinheiro') +
        ')'
      : ''
    const complementoDescricaoEstornoCredito = !notaComPagamento
      ? '(Essa opção está desabilitada porque não existem registro de pagamentos para este pacote)'
      : notaComEstornoDiferenteCredito
      ? '(Essa opção está desabilitada porque a fatura #' + clientePacoteItem.clientePacote.vendaPacote.venda.notaVenda.numero + ' já possui estorno em outra forma de pagamento)'
      : ''

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <React.Fragment>
              <Typography variant="body1" style={{ marginBottom: 1 }}>
                <b>Serviço:</b> {nomeServico}
              </Typography>
              <Typography variant="body1" style={{ marginTop: 2, marginBottom: 8 }}>
                <b>Créditos Disponíveis:</b> {quantidadeDisponivel}
              </Typography>

              <form id="formCliente" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                <RadioGroup
                  className={classes.group}
                  value={destinoValorEstorno.id.toString()}
                  onChange={(event) => {
                    this.setState({
                      destinoValorEstorno: OpcoesClienteCancelamentoPacoteItemEnum.getById(parseInt(event.target.value, 10))
                    })
                  }}
                >
                  <FormControlLabel
                    disabled={ajaxing}
                    value={OpcoesClienteCancelamentoPacoteItemEnum.SEM_ESTORNO.id.toString()}
                    control={<Radio />}
                    label={
                      <Typography variant="body2" className={classes.labelOpcaovalorEstorno}>
                        Cancelar pacote sem realizar estorno de valor
                      </Typography>
                    }
                  />

                  <FormControlLabel
                    disabled={ajaxing || !notaComPagamento || notaComEstornoDiferentePagamento}
                    value={OpcoesClienteCancelamentoPacoteItemEnum.ESTORNO_PAGAMENTO.id.toString()}
                    control={<Radio />}
                    label={
                      <Typography variant="body2" className={classes.labelOpcaovalorEstorno}>
                        Cancelar pacote e estornar valor no pagamento da fatura {complementoDescricaoEstornoPagamento}
                      </Typography>
                    }
                  />

                  <FormControlLabel
                    disabled={ajaxing || !notaComPagamento || notaComEstornoDiferenteCredito}
                    value={OpcoesClienteCancelamentoPacoteItemEnum.ESTORNO_CREDITO.id.toString()}
                    control={<Radio />}
                    label={
                      <Typography variant="body2" className={classes.labelOpcaovalorEstorno}>
                        Cancelar pacote e estornar valor como crédito para cliente {complementoDescricaoEstornoCredito}
                      </Typography>
                    }
                  />
                </RadioGroup>

                {destinoValorEstorno &&
                  destinoValorEstorno.id &&
                  (destinoValorEstorno.id === OpcoesClienteCancelamentoPacoteItemEnum.ESTORNO_PAGAMENTO.id ||
                    destinoValorEstorno.id === OpcoesClienteCancelamentoPacoteItemEnum.ESTORNO_CREDITO.id) && (
                    <InputText
                      marginTop={true}
                      disabled={ajaxing}
                      idname="xvalor"
                      label="Valor do estorno (R$)"
                      customVariant={'outlined-small'}
                      shrink={false}
                      errorMessage={errorsMap('valorEstorno')}
                      inputComponent={InputMoneyFormat}
                      inputRef={this.inputValor}
                      onChange={(event) => {
                        const valor = FormUtil.convertStringToNumber(this.inputValor.current.value)
                        this.setState({
                          valorEstorno: valor
                        })
                      }}
                    />
                  )}

                <HiddenSubmitButton />

                {this.state.paginaCarregada && (
                  <div className={classes.containerFooterButtons}>
                    <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                      Cancelar
                    </Button>
                    <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                      {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                    </Button>
                  </div>
                )}
              </form>
            </React.Fragment>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

CancelarServicoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CancelarServicoDialogPage)
