import React, { Component } from 'react'

import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import FileUploaderDialog from 'support/components/fileuploader/FileUploaderDialog'
import PhotoPicker from 'support/components/picker/PhotoPicker'
import PA from 'support/domain/colaborador/PermissoesAcesso'

class ClientePhotoPicker extends Component {
  handleAlterarFoto = (event) => {
    if (!verificarAutorizacao([PA.PODE_CADASTRAR_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { cliente } = this.props

    openBackableDialog(FileUploaderDialog, {
      title: 'Foto do Perfil',
      cropsProps: {
        aspect: 1,
        exportSize: {
          width: 320,
          height: 320
        },
        saveCroppedImageFunction: (data, onStart, onSuccess, onError) => {
          onStart()
          postAPI({
            url: 'erp/clientes.alterarFotoPerfil',
            data: {
              cliente: {
                id: cliente.id
              },
              imageDataAsBase64: data
            },
            requerAutorizacao: true,
            timeout: 0,
            onSuccess: (response) => {
              onSuccess()
              EventsManager.pub('ManipulacaoCliente')
              EventsManager.pub('SnackBarSuccess', { open: true, message: 'Foto alterada com sucesso.' })
            },
            onError: (response) => {
              onError()
            }
          })
        }
      }
    })
  }

  handlerRemoverFoto = () => {
    if (!verificarAutorizacao([PA.PODE_CADASTRAR_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { cliente } = this.props

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Foto',
      text: (
        <span>
          Você tem certeza que deseja remover a foto do cliente <b>{cliente.apelido}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/clientes.alterarFotoPerfil',
        contextoUsuario: 'erp',
        data: {
          cliente: {
            id: cliente.id
          }
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoCliente')
          EventsManager.pub('SnackBarSuccess', { open: true, message: 'Foto removida com sucesso.' })
        }
      }
    })
  }

  render() {
    const { cliente, ...others } = this.props

    return (
      <PhotoPicker
        {...others}
        editFunction={this.handleAlterarFoto}
        removeFunction={this.handlerRemoverFoto}
        hasWritePermission={verificarAutorizacao([PA.PODE_CADASTRAR_CLIENTE])}
      />
    )
  }
}

export default ClientePhotoPicker
