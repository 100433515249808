import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import PanelPage from 'support/components/panel/PanelPage'

const styles = (theme) => ({})

class NfPanelPage extends Component {
  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} title="Notas Fiscais">
        <div className={classes.paperContent} style={{ textAlign: 'left', marginTop: 8, maxWidth: 640 }}>
          <Typography variant="body2" style={{ marginTop: 0 }}>
            Emita notas fiscais de serviços eletrônicas (NFS-e) em poucos cliques com o Salão99 Enterprise por apenas{' '}
            <b>
              <u>R$ 39,90 / mês</u>
            </b>
            . É possível emitir notas simples ou de acordo com a{' '}
            <b>
              <u>Lei do Salão Parceiro</u>
            </b>
            .
          </Typography>
          <br />
          <Typography variant="body2">
            Integração com mais de 5.000 prefeituras e municípios.
            <br />
            Para habilitar essa funcionalidade, entre em contato conosco através do chat.
          </Typography>
        </div>
      </PanelPage>
    )
  }
}

NfPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NfPanelPage)
