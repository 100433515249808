export function extrairVariacoesImagem(imagem) {
  let variacoes = null
  if (imagem) {
    variacoes = imagem.split(/\n/g)
  }
  return {
    get: (index) => {
      if (variacoes) {
        return process.env.REACT_APP_STATICCONTENT_ENDPOINT + '/' + variacoes[index]
      }
    }
  }
}

const functionsObject = {
  extrairVariacoesImagem: extrairVariacoesImagem
}

export default functionsObject
