import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 90,
    minWidth: 90
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaCliente: {
    flexGrow: 1.5
  },
  colunaIconeTipoVenda: {
    maxWidth: 36,
    minWidth: 36
  },
  colunaDescricao: {
    flexGrow: 1.5
  },
  colunaDescricaoMedium: {
    flexGrow: 1.5,
    overflow: 'hidden'
  }
})

class CobrancaEmNotasDetalhesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      lista: [],
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
    this.dataExporterFunctionsMap = {}
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      tipo: this.props.tipo,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      filtroComNotasFechadas: this.props.filtroComNotasFechadas,
      filtroComNotasAbertas: this.props.filtroComNotasAbertas,
      filtroPendentesParaFechamento: this.props.filtroPendentesParaFechamento,
      filtroAtendimentosFuturos: this.props.filtroAtendimentosFuturos
    }
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const content = {}
    const iconeEDescricao = VendaService.getIconeEDescricao(item)

    content.dataVenda = converterDataIntParaMoment(item.dataVenda).format('DD/MMM/YY')
    content.nomeCliente = item.cliente.apelido

    content.descricaoValor = VendaService.getDescricaoPreco(item, {
      format: size === 'medium' || size === 'small' ? 'minificado' : null,
      quebraLinhaUnidades: true
    })

    content.iconeTipoVenda = <iconeEDescricao.icon className={classes.iconeTipoVenda} />
    content.descricaoVenda = iconeEDescricao.descricao
    content.valorDesconto = formatarValorMonetario(item.valorDescontoCalculado)
    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Relatório de Vendas - ' + formatarPeriodoNomeArquivo(this.props.dataInicial, this.props.dataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarVendasEmNotasPorData',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    let valorTotal = 0

    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    for (let item of this.state.lista) {
      if (item.cancelado) {
        continue
      }
      if (item.atendimento && item.atendimento.status.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id) {
        continue
      }
      if (!item.clientePacoteItem) {
        valorTotal += item.valorNota
      }
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} title="Relatório" align="center" pageType="basicEntity" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Detalhamento de Vendas</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <div>
              <VirtualizedResponsiveTable
                showBackgroundDividers={true}
                scrollElement={dialogContentProps.scrollContainerRef.current}
                itemsPerRequest={30}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="erp"
                endpoint="erp/relatorios.buscarVendasEmNotasPorData"
                getRequestParametersFunction={this.getRequestParameters}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    this.setState({ lista: data.items })
                  }
                }}
                largeRenderProps={{
                  columns: [
                    { label: 'Data Venda', className: classes.colunaData },
                    { label: 'Cliente', className: classes.colunaCliente, props: { xs: true }, horizontalPadding: 'small' },
                    { label: '', className: classes.colunaIconeTipoVenda, isVisible: false, horizontalPadding: 'small' },
                    { label: 'Descrição', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } },
                    { label: 'Desconto', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                    { label: 'Valor Final', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                  ],
                  itemRenderer: (comissao, index) => {
                    const content = this.getRenderContent('large', comissao)

                    return {
                      itemData: [content.dataVenda, content.nomeCliente, content.iconeTipoVenda, content.descricaoVenda, content.valorDesconto, content.descricaoValor]
                    }
                  }
                }}
                mediumRenderProps={{
                  headerColumnHeight: 68,
                  rowHeight: 106,
                  columns: [
                    { label: 'Data Venda', className: classes.colunaData },
                    { label: '', className: classes.colunaIconeTipoVenda, isVisible: false, horizontalPadding: 'small' },
                    { label: 'Descrição', className: classes.colunaDescricaoMedium, horizontalPadding: 'small', props: { xs: true } },
                    { label: 'Desconto', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                    { label: 'Valor Final', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                  ],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('medium', item)

                    let descricaoContent = (
                      <React.Fragment>
                        <Typography variant="body2" style={{ fontWeight: 500 }} noWrap={true}>
                          {content.nomeCliente}
                        </Typography>
                        <Typography variant="body2" noWrap={true}>
                          {content.descricaoVenda}
                        </Typography>
                      </React.Fragment>
                    )

                    return {
                      itemData: [content.dataVenda, content.iconeTipoVenda, descricaoContent, content.valorDesconto, content.descricaoValor]
                    }
                  }
                }}
                smallRenderProps={{
                  rowHeight: 120,
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('small', item)

                    return {
                      itemData: (
                        <React.Fragment>
                          <Grid container>
                            <Grid item xs style={{ minWidth: 0 }}>
                              <Typography variant="body2">{content.dataVenda}</Typography>
                            </Grid>
                            <Grid item align="right">
                              <Typography variant="body2" style={{ fontWeight: 500 }}>
                                {content.descricaoValor}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography variant="body1" noWrap={true}>
                            <b>{content.nomeCliente}</b>
                          </Typography>
                          <Typography variant="body2" noWrap={true}>
                            {content.descricaoVenda}
                          </Typography>
                          <Typography variant="body2">Desconto: {content.valorDesconto}</Typography>
                        </React.Fragment>
                      )
                    }
                  }
                }}
                emptyListProps={{
                  text: 'Não existem valores para o período informado'
                }}
              />
            </div>

            {this.state.lista.length > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Total: <b>{formatarValorMonetario(valorTotal)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

CobrancaEmNotasDetalhesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CobrancaEmNotasDetalhesDialogPage)
