import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import NativeApp from 'support/components/nativeapp/NativeApp'
import CriacaoPagamentoPeriodo from 'support/components/pagamentoperiodo/CriacaoPagamentoPeriodo'
import DialogPage from 'support/components/page/DialogPage'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'
import NegocioService from 'support/domain/negocio/NegocioService'
import PagamentoPeriodoPlanoEnum from 'support/domain/negocio/PagamentoPeriodoPlanoEnum'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ClienteDadosBasicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      etapa: 1,
      paginaCarregada: false,
      title: 'Pagamento',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      pagamentoPeriodo: PagamentoPeriodoPlanoEnum.MENSAL,
      coupon: null,
      negocio: null
    }

    this.inputNome = React.createRef()
    this.inputEmail = React.createRef()
    this.inputNumeroDocumento = React.createRef()
  }

  carregarMainIdentificacaoPagamento = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/negocios.buscarIdentificacaoPagamento',
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState((state) => ({
          paginaCarregada: true,
          identificacaoPagamento: response.data
        }))
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarMainCriacaoPagamentoPlano = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/negocios.buscarDadosCarregamentoPlano',
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState((state) => ({
          paginaCarregada: true,
          negocio: response.data.negocio,
          mapPlanosPrecos: NegocioService.mapearPlanosPrecosPorPlano(response.data.planosPrecos)
        }))
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handleSubmitIdentificacaoPagamento = (event) => {
    event.preventDefault()
    this.salvarIdentificacaoPagamento()
  }

  getDadosIdentificacaoPagamento = () => {
    const { identificacaoPagamento } = this.state

    const dados = {
      id: identificacaoPagamento && identificacaoPagamento.id ? identificacaoPagamento.id : null,
      nome: this.inputNome.current.value,
      email: this.inputEmail.current.value
    }

    let numeroDocumento = this.inputNumeroDocumento.current.value.replace(/[^0-9]/g, '').trim()
    if (numeroDocumento === '') {
      numeroDocumento = null
    }
    dados.numeroDocumento = numeroDocumento

    return dados
  }

  salvarIdentificacaoPagamento = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/negocios.persistirIdentificacaoPagamento',
      data: this.getDadosIdentificacaoPagamento(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxing: false,
          etapa: 2,
          paginaCarregada: false
        })
        //this.props.handleCloseDialog();
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formIdentificacaoPagamento')
      }
    })
  }

  getDadosCriacaoPagamentoPlano = () => {
    const { pagamentoPeriodo, coupon } = this.state

    const dados = {
      pagamentoPeriodo,
      coupon
    }

    return dados
  }

  criarPagamentoPlano = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/negocios.criarPagamentoPlano',
      data: this.getDadosCriacaoPagamentoPlano(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxing: false,
          etapa: 3,
          pagamento: response.data
        })
        EventsManager.pub('CriacaoPagamentoPlano')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formCriacaoPagamentoPlano')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { etapa, errorsMap } = this.state

    const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
    const termosLicenciamentoLinkProps = TermosEPrivacidadeLinkProps.termosLicenciamento

    const content = {}

    if (etapa === 1) {
      let { nome, email, numeroDocumento } = this.state.identificacaoPagamento ? this.state.identificacaoPagamento : {}

      if (!email) {
        email = getSessaoPainel().usuario.email
      }

      const continuarFunction = this.salvarIdentificacaoPagamento

      if (this.state.paginaCarregada) {
        content.toolbarActions = {
          actions: [
            {
              label: 'Continuar',
              showOnlyMobile: true,
              handleAction: continuarFunction
            }
          ]
        }
        content.outerButtons = (
          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
              Cancelar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={continuarFunction}>
              {this.state.ajaxing ? 'Aguarde' : 'Continuar'}
            </Button>
          </div>
        )
      }

      content.innerPaper = (
        <ContentWithPreload key="mainIdentificacaoPagamento" loadFunction={this.carregarMainIdentificacaoPagamento}>
          {() => (
            <form id="formIdentificacaoPagamento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmitIdentificacaoPagamento(event)}>
              <Typography variant="h6">Identificação do Pagamento</Typography>
              <Typography variant="body2" style={{ marginBottom: 24 }}>
                O pagamento será feito em nome de:
              </Typography>

              <InputText
                idname="xnome"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={nome}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome"
                inputRef={this.inputNome}
              />

              <InputText
                marginTop={true}
                idname="xemail"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={email}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('email')}
                label="E-mail"
                inputRef={this.inputEmail}
              />

              <InputText
                marginTop={true}
                idname="xnumeroDocumento"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={numeroDocumento}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('numeroDocumento')}
                label="CNPJ ou CPF"
                inputRef={this.inputNumeroDocumento}
              />

              <HiddenSubmitButton />
            </form>
          )}
        </ContentWithPreload>
      )
    } else if (etapa === 2) {
      let { negocio, mapPlanosPrecos, pagamentoPeriodo } = this.state

      const continuarFunction = this.criarPagamentoPlano

      if (this.state.paginaCarregada) {
        content.toolbarActions = {
          actions: [
            {
              label: 'Continuar',
              showOnlyMobile: true,
              handleAction: continuarFunction
            }
          ]
        }
        content.outerButtons = (
          <div className={classes.containerFooterButtons}>
            <Button
              disabled={this.state.ajaxing}
              onClick={() => {
                this.setState({ etapa: 1, paginaCarregada: false })
              }}
            >
              Voltar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={continuarFunction}>
              {this.state.ajaxing ? 'Aguarde' : 'Continuar'}
            </Button>
          </div>
        )
      }

      content.innerPaper = (
        <ContentWithPreload key="mainCriacaoPagamentoPlano" loadFunction={this.carregarMainCriacaoPagamentoPlano}>
          {() => {
            const plano = PlanoEnum.getById(negocio.plano.id)
            const precosPlano = mapPlanosPrecos[plano.id]

            return (
              <CriacaoPagamentoPeriodo
                ajaxing={this.state.ajaxing}
                handleSubmit={this.handleSubmitIdentificacaoPagamento}
                pagamentoPeriod={pagamentoPeriodo?.descricao}
                negocio={this.state.negocio.plano}
                plano={plano}
                precosPlano={precosPlano}
                setPagamentoPeriodo={(value) => {
                  this.setState({
                    pagamentoPeriodo: PagamentoPeriodoPlanoEnum[value.toUpperCase()].id
                  })
                }}
                setCoupon={(coupon) => this.setState({ coupon })}
                termosLicenciamentoLinkProps={termosLicenciamentoLinkProps}
              />
            )
          }}
        </ContentWithPreload>
      )
    } else if (etapa === 3) {
      let { pagamento } = this.state

      const buttonProps = {}

      const dataLayerPushFunction = () => {
        var dataLayer = window.dataLayer || []
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:payment',
          eventAction: 'click:button',
          eventLabel: 'efetuar-pagamento-mp'
        })
      }

      if (pagamento.valor == 0) {
        dataLayerPushFunction()
        this.props.handleCloseDialog()
      }

      if (DeviceIdentifier.isNativeApp()) {
        buttonProps.onClick = () => {
          NativeApp.executeNativeMethod({
            method: 'openLink',
            url: pagamento.urlPagamentoMp
          })
          dataLayerPushFunction()
          this.props.handleCloseDialog()
        }
      } else {
        buttonProps.component = 'a'
        buttonProps.href = pagamento.urlPagamentoMp
        buttonProps.target = '_blank'
        buttonProps.onClick = () => {
          dataLayerPushFunction()
          this.props.handleCloseDialog()
        }
      }

      content.innerPaper = (
        <React.Fragment>
          <Typography variant="h6">Pronto! O pagamento está pronto pra ser realizado.</Typography>
          <Typography variant="body2" style={{ marginBottom: 8 }}>
            Os pagamentos são processados pelo MercadoPago.
            <br />
            Clique no botão abaixo para efetuar o pagamento.
          </Typography>
          <Button color="secondary" variant="contained" {...buttonProps}>
            Efetuar Pagamento
          </Button>
        </React.Fragment>
      )
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={490}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={content.toolbarActions}
      >
        {content.innerPaper}

        {content.outerButtons}
      </DialogPage>
    )
  }
}

ClienteDadosBasicosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ClienteDadosBasicosDialogPage)
