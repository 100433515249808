import { createInlineValuesObject } from 'support/components/inlinevalues/InlineValues'

const PermissoesAcesso = createInlineValuesObject({
  ACESSO_SISTEMA: { position: 0, descricao: 'Acesso ao sistema' },
  PODE_ACESSAR_SUPORTE_CHAT: { position: 1, descricao: 'Pode acessar o chat' },
  PODE_ENVIAR_MENSAGEM_SUPORTE_CHAT: { position: 2, descricao: 'Pode enviar mensagem no chat' },
  PODE_ACESSAR_LOGS_EMAILS: { position: 3, descricao: 'Pode acessar logs de e-mails' },
  PODE_ACESSAR_SOLICITACOES_TREINAMENTO: { position: 4, descricao: 'Pode acessar solicitações de treinamento' },
  PODE_ACESSAR_INFORMACOES_FINANCEIRAS: { position: 5, descricao: 'Pode acessar informações financeiras' },
  PODE_ACESSAR_PROCESSAMENTOS: { position: 6, descricao: 'Pode acessar processamentos' },
  PODE_GERENCIAR_USUARIOS: { position: 7, descricao: 'Pode gerenciar usuários' },
  PODE_GERENCIAR_NEGOCIOS: { position: 8, descricao: 'Pode gerenciar negócios' },
  PODE_GERENCIAR_NF: { position: 9, descricao: 'Pode gerenciar NF' },
  PODE_ALTERAR_VALORES_PLANO: { position: 10, descricao: 'Pode alterar valores de plano' },
  PODE_APAGAR_DADOS_FINANCEIROS: { position: 11, descricao: 'Pode apagar dados financeiros' },
  PODE_TROCAR_EMAIL_USUARIO: { position: 12, descricao: 'Pode trocar e-mail usuário' },
  PODE_TRANSFERIR_PAGAMENTO: { position: 13, descricao: 'Pode transferir pagamento entre dois negócios' },
  PODE_REMOVER_NEGOCIO: { position: 14, descricao: 'Pode excluir negócio' },
  PODE_ACESSAR_SERVICOS_NF: { position: 15, descricao: 'Pode acessar informações de serviços NF' },
  PODE_ALTERAR_SERVICOS_NF: { position: 16, descricao: 'Pode alterar informações de serviços NF' },
  PODE_ACESSAR_CONFIGURACOES_NF: { position: 17, descricao: 'Pode acessar configurações de NF' },
  PODE_ALTERAR_CONFIGURACOES_NF: { position: 18, descricao: 'Pode alterar configuraçòes de NF' },
  PODE_ALTERAR_COLABORADOR_ADMIN: { position: 19, descricao: 'Pode alterar colaborador admin de negócio' },
  PODE_ALTERAR_COLABORADOR_HORARIO_ATENDIMENTO_AO: { position: 20, descricao: 'Pode alterar horário de atendimento de colaborador no Agendamento Online' },
  PODE_ALTERAR_PERMISSAO_ADMIN: { position: 21, descricao: 'Pode alterar permissões do Usuário para admin no s99' },
  PODE_ENVIAR_COMUNICACAO_NEGOCIO: { position: 22, descricao: 'Pode enviar comunicação em lote para negócios' },
  PODE_GERENCIAR_CUPOM: { position: 23, descricao: 'Pode gerenciar os cupons de desconto do s99' },
  PODE_CRIAR_EDITAR_CAMPANHAS: { position: 24, descricao: 'Pode criar ou editar campanhas no s99' }
})

const MAPEAMENTO_PERMISSOES_ROTA = {
  '/suporte/chat': [PermissoesAcesso.PODE_ACESSAR_SUPORTE_CHAT],
  '/suporte/solicitacoes-treinamento': [PermissoesAcesso.PODE_ACESSAR_SOLICITACOES_TREINAMENTO],
  '/comunicacao': [PermissoesAcesso.PODE_ENVIAR_COMUNICACAO_NEGOCIO],
  '/email/log/envios': [PermissoesAcesso.PODE_ACESSAR_LOGS_EMAILS],
  '/relatorios': [PermissoesAcesso.PODE_ACESSAR_INFORMACOES_FINANCEIRAS],
  '/processamentos': [PermissoesAcesso.PODE_ACESSAR_PROCESSAMENTOS],
  '/servicosnf': [PermissoesAcesso.PODE_ACESSAR_SERVICOS_NF],
  '/usuarios': [PermissoesAcesso.PODE_GERENCIAR_USUARIOS],
  '/negocios': [PermissoesAcesso.PODE_GERENCIAR_NEGOCIOS],
  '/nfs': [PermissoesAcesso.PODE_GERENCIAR_NF],
  '/cupons': [PermissoesAcesso.PODE_GERENCIAR_CUPOM]
}

PermissoesAcesso['verificarAutorizacao'] = (permissoesRequeridas, permissoesConcedidas, and) => {
  const verificacaoInicial = PermissoesAcesso.verify([PermissoesAcesso.ACESSO_SISTEMA], permissoesConcedidas)
  if (verificacaoInicial === false) {
    return false
  }
  const result = PermissoesAcesso.verify(permissoesRequeridas, permissoesConcedidas, and)
  return result
}

PermissoesAcesso['verificarAutorizacaoPorRota'] = (rota, permissoesConcedidas, and) => {
  let buscaExata = true
  if (!rota) {
    rota = '/' + window.location.pathname.split('/p/', 2)[1]
    buscaExata = false
  }
  if (buscaExata) {
    const permissoes = MAPEAMENTO_PERMISSOES_ROTA[rota]
    if (permissoes) {
      if (permissoes.length > 0) {
        return PermissoesAcesso['verificarAutorizacao'](permissoes, permissoesConcedidas, and)
      } else if (permissoes.length === 0) {
        return true
      }
    }
  } else {
    for (let rotaMapeada of Object.keys(MAPEAMENTO_PERMISSOES_ROTA)) {
      if (rota.startsWith(rotaMapeada)) {
        const permissoes = MAPEAMENTO_PERMISSOES_ROTA[rotaMapeada]
        if (permissoes.length > 0) {
          return PermissoesAcesso['verificarAutorizacao'](permissoes, permissoesConcedidas, and)
        } else if (permissoes.length === 0) {
          return true
        }
        break
      }
    }
  }
  return false
}

export default PermissoesAcesso
