import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputPorcentagemValor from 'support/components/input/InputPorcentagemValor'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import DescontoDisponibilidadeEnum from 'support/domain/desconto/DescontoDisponibilidadeEnum'
import DescontoTipoLimiteEnum from 'support/domain/desconto/DescontoTipoLimiteEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class DescontoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idDesconto ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      desconto: {
        tipoLimiteUso: DescontoTipoLimiteEnum.ILIMITADO,
        disponibilidade: DescontoDisponibilidadeEnum.generateAllItems('111')
      }
    }

    this.inputNome = React.createRef()
    this.inputTipoLimiteUso = React.createRef()
    this.inputPrazoLimiteUsoPorQuantidade = React.createRef()
    this.inputValor = {}
    this.prazoLimiteUsoPorDataFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idDesconto) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/descontos.buscarPorId',
        params: {
          id: this.props.idDesconto
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          const desconto = response.data
          desconto.disponibilidade = DescontoDisponibilidadeEnum.generateAllItems(response.data.disponibilidade)

          this.setState((state) => ({
            desconto: desconto
          }))

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirDesconto()
  }

  persistirDesconto = () => {
    postAPI({
      url: 'erp/descontos.persistir',
      data: this.getDadosDesconto(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoDesconto')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formDesconto')
      }
    })
  }

  getDadosDesconto = () => {
    const dados = {}

    if (this.props.idDesconto) {
      dados.id = this.props.idDesconto
    }

    dados.nome = this.inputNome.current.value
    dados.valor = FormUtil.convertStringToNumber(this.inputValor.getValor())
    dados.tipoCalculoDesconto = this.inputValor.getTipoCalculo()
    dados.tipoLimiteUso = this.state.desconto.tipoLimiteUso

    dados.prazoLimiteUsoPorQuantidade = null
    if (this.inputPrazoLimiteUsoPorQuantidade && this.inputPrazoLimiteUsoPorQuantidade.current) {
      dados.prazoLimiteUsoPorQuantidade = this.inputPrazoLimiteUsoPorQuantidade.current.value
    }

    dados.prazoLimiteUsoPorData = null

    if (dados.tipoLimiteUso.id === DescontoTipoLimiteEnum.POR_DATA.id && Object.entries(this.prazoLimiteUsoPorDataFunctionsMap).length !== 0) {
      dados.prazoLimiteUsoPorData = this.prazoLimiteUsoPorDataFunctionsMap.getDataAsInt()
    }

    dados.disponibilidade = DescontoDisponibilidadeEnum.getString(this.state.desconto.disponibilidade)

    return dados
  }

  handleChangeDisponibilidade = (position, event) => {
    const desconto = this.state.desconto
    for (let disponibilidadeItem of desconto.disponibilidade) {
      if (disponibilidadeItem.position === position) {
        disponibilidadeItem.exists = event.target.checked
        break
      }
    }
    this.setState({ desconto: desconto })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state
    const { nome, valor, tipoCalculoDesconto /*tipoLimiteUso, prazoLimiteUsoPorQuantidade, prazoLimiteUsoPorData*/ } = this.state.desconto ? this.state.desconto : {}

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formDesconto" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                customVariant={'outlined-small'}
                shrink={false}
                defaultValue={nomeDefaultValue}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome"
                inputRef={this.inputNome}
              />

              <InputPorcentagemValor
                defaultValor={valor}
                defaultTipoCalculo={tipoCalculoDesconto}
                marginTop={true}
                disabled={this.state.ajaxing}
                label="Valor do Desconto"
                customVariant={'outlined-small'}
                shrink={false}
                errorMessage={errorsMap('valorDesconto')}
                functionsMap={this.inputValor}
              />

              <div style={{ marginBottom: '15px' }}>
                {/*										<Grid
										container
										direction="column"
										alignItems="flex-start"
									>

										<InputSelect
											marginTop={true}
											value={tipoLimiteUso.id}
											customVariant="naked"
											nakedLeftPadding={false}
											fullWidth={false}
											onChange={(event) => {
												const desconto = this.state.desconto;
												desconto.tipoLimiteUso = DescontoTipoLimiteEnum.getById(event.target.value);
												this.setState({
													desconto : desconto
												});
											}}
											inputRef={this.inputTipoLimiteUso}
										>
											{DescontoTipoLimiteEnum.values().map(tipoValidade => (
												<MenuItem key={tipoValidade.id} value={tipoValidade.id}>
													{tipoValidade.descricao}
												</MenuItem>
											))}

										</InputSelect>

										{
											tipoLimiteUso.id === DescontoTipoLimiteEnum.POR_QUANTIDADE.id &&
											<InputText
												marginTop={false}
												fullWidth={true}
												defaultValue={prazoLimiteUsoPorQuantidade}
												inputComponent={InputNumberFormat}
												inputProps={{
													minValue:1,
													maxValue:8000000
												}}
												idname="xvalidade"
												errorMessage={errorsMap("prazoLimiteUsoQuantidade")}
												helpMessage={"Informe a quantidade máxima de vezes que esse desconto poderá ser utilizado."}
												inputRef={this.inputPrazoLimiteUsoPorQuantidade}
											/>
										}

										{
											tipoLimiteUso.id === DescontoTipoLimiteEnum.POR_DATA.id &&
											<InputData
												clearable={false}
												keyboard={true}
												fullWidth={true}
												idname="xvalidade"
												defaultValue={prazoLimiteUsoPorData}
												label={tipoLimiteUso.descricao}
												errorMessage={errorsMap("prazoLimiteUsoData")}
												placeholder={"dd/mm/yyyy"}
												customVariant="naked"
												helpMessage={"Informe a data limite que o desconto poderá ser utilizado."}
												functionsMap={this.prazoLimiteUsoPorDataFunctionsMap}
											/>
										}
									</Grid>
*/}
              </div>

              {this.state.desconto.disponibilidade.map((disponibilidadeItem) => (
                <FormControlLabel
                  key={disponibilidadeItem.position}
                  control={
                    <Checkbox
                      color="primary"
                      disabled={this.state.ajaxing}
                      checked={disponibilidadeItem.exists}
                      onChange={(event) => this.handleChangeDisponibilidade(disponibilidadeItem.position, event)}
                    />
                  }
                  label={<Typography variant="body2">{disponibilidadeItem.descricao}</Typography>}
                />
              ))}

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

DescontoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DescontoDialogPage)
