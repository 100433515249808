import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  message: {
    //color : theme.palette.secondary.light
  },
  textoBotaoRemoverSnackbar: {
    color: theme.palette.secondary.light
  },
  textoBotaoCancelarSnackbar: {
    color: '#fff'
  }
})

class SelectedItemsActionSnackBar extends Component {
  render() {
    const { classes } = this.props
    return (
      <Snackbar
        className="needsWillChange"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={this.props.show}
        message={<span className={classes.message}>{this.props.label}</span>}
        action={[
          <Button key="remove" classes={{ text: classes.textoBotaoRemoverSnackbar }} size="small" onClick={this.props.action.handleFunction}>
            {this.props.action.label}
          </Button>,
          <IconButton key="close" onClick={this.props.action.cancelFunction}>
            <CloseIcon fontSize="small" className={classes.textoBotaoCancelarSnackbar} />
          </IconButton>
        ]}
      />
    )
  }
}

SelectedItemsActionSnackBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SelectedItemsActionSnackBar)
