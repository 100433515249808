import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  DATA_ATUAL: {
    id: 1,
    descricaoOpcao: 'Data Atual'
  },
  DATA_VENDA: {
    id: 2,
    descricaoOpcao: 'Data Venda'
  }
})

export default enumObject
