import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  INTERVALO_5: { id: 5, descricao: 'De 5 em 5 minutos' },
  INTERVALO_10: { id: 10, descricao: 'De 10 em 10 minutos' },
  INTERVALO_15: { id: 15, descricao: 'De 15 em 15 minutos' },
  INTERVALO_20: { id: 20, descricao: 'De 20 em 20 minutos' },
  INTERVALO_25: { id: 25, descricao: 'De 25 em 25 minutos' },
  INTERVALO_30: { id: 30, descricao: 'De 30 em 30 minutos' },
  INTERVALO_45: { id: 45, descricao: 'De 45 em 45 minutos' },
  INTERVALO_60: { id: 60, descricao: 'A cada 1 hora' },
  INTERVALO_90: { id: 90, descricao: 'A cada 1 hora e 30 minutos' },
  INTERVALO_120: { id: 120, descricao: 'A cada 2 horas' },
  INTERVALO_150: { id: 150, descricao: 'A cada 2 horas e 30 minutos' },
  INTERVALO_180: { id: 180, descricao: 'A cada 3 horas' },
  INTERVALO_240: { id: 240, descricao: 'A cada 4 horas' }
})
export default enumObject
