import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import NegocioDialogPage from 'pages/admin/painel/negocio/NegocioDialogPage'
import NegociosResponsiveTable from 'pages/admin/painel/negocio/table/NegociosResponsiveTable'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputText from 'support/components/input/InputText'
import PanelPage from 'support/components/panel/PanelPage'

const styles = (theme) => ({})

class NegociosPanelPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false,
      lista: []
    }

    this.loaderFunctionsMap = {}
    this.inputFiltro = React.createRef()
  }

  getRequestParameters = () => {
    return {
      filtro: this.inputFiltro.current ? this.inputFiltro.current.value : null
    }
  }

  handlerAbrirNegocio = (negocio) => {
    openBackableDialog(NegocioDialogPage, {
      negocio: negocio
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { ajaxing } = this.state

    let onClickRow = (negocio) => {
      this.handlerAbrirNegocio(negocio)
    }

    return (
      <PanelPage {...others} title="Negócios">
        <Grid container alignItems="center" justify="center">
          <Grid item xs>
            <InputText
              customVariant="naked"
              nakedLeftPadding={false}
              placeholder="Nome ou ID do negócio"
              onChange={(event) => {
                this.loaderFunctionsMap.load()
              }}
              inputRef={this.inputFiltro}
            />
          </Grid>
        </Grid>
        <br />

        <NegociosResponsiveTable
          disabled={ajaxing}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="admin"
          endpoint="admin/negocio.buscarNegocios"
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          onClickRow={onClickRow}
          emptyListProps={{
            text: 'Nenhum negócio foi encontrado'
          }}
        />
      </PanelPage>
    )
  }
}

NegociosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NegociosPanelPage)
