import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import InputServicoDialog from 'pages/erp/painel/servico/input/InputServicoDialog'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputText from 'support/components/input/InputText'

const styles = (theme) => ({
  clearIcon: {
    fontSize: 16
  }
})

class InputServico extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.defaultValue && this.props.defaultValue.servico && this.props.defaultValue.servico.id ? this.props.defaultValue : null
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getValue = () => {
        return this.state.value
      }
    }
  }

  handleClick = (event) => {
    if (!this.props.disabled && !this.props.readOnly) {
      openBackableDialog(InputServicoDialog, {
        colaborador: this.props.colaborador,
        selectionType: this.props.selectionType,
        dataAtendimento: this.props.dataAtendimento,
        pacoteItensDisponiveis: this.props.pacoteItensDisponiveis,
        setValue: (value) => {
          this.setValue(value)
        }
      })
    }
  }

  handleFocus = (event) => {}

  setValue = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.setState({ value: value })
  }

  render() {
    const { classes, ...othersInputProps } = this.props
    delete othersInputProps.defaultValue
    delete othersInputProps.selectionType
    delete othersInputProps.dataAtendimento
    delete othersInputProps.pacoteItensDisponiveis

    othersInputProps.onClick = this.handleClick
    othersInputProps.onFocus = this.handleFocus
    othersInputProps.readOnly = true

    if (!this.state.value) {
      othersInputProps.value = ''
    } else {
      const servico = this.state.value.servico
      if (servico) {
        const variacao = this.state.value.variacao
        let value = servico.nome
        if (variacao && variacao.nome) {
          value += ': ' + variacao.nome
        }
        othersInputProps.value = value
      }
    }

    if (this.state.value && !this.props.readOnly) {
      othersInputProps.endAdornment = (
        <IconButton
          disabled={this.props.disabled}
          onClick={(event) => {
            event.stopPropagation()
            this.setValue(null)
          }}
        >
          <CloseIcon className={classes.clearIcon} />
        </IconButton>
      )
    }
    return <InputText {...othersInputProps} />
  }
}

InputServico.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputServico)
