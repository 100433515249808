import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { VisaoEnum } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'

export function gerarItemAtendimento(atendimento) {
  return {
    data: atendimento.data,
    dataHorarioInicio: atendimento.dataHorarioInicio,
    horarioInicio: atendimento.horarioInicio,
    dataHorarioTermino: atendimento.dataHorarioTermino,
    horarioTermino: atendimento.horarioTermino,
    //  "1a": 1 - tem o objetivo de ordernar os atendimentos depois dos bloqueios caso tenham a mesma dataHorarioInicio. a - prefixo para diferenciar o id do item
    id: '1a' + atendimento.id,
    atendimento: atendimento
  }
}

export function gerarItemBloqueioHorario(bloqueioHorario) {
  return {
    data: bloqueioHorario.data,
    dataHorarioInicio: bloqueioHorario.dataHorarioInicio,
    horarioInicio: bloqueioHorario.horarioInicio,
    dataHorarioTermino: bloqueioHorario.dataHorarioTermino,
    horarioTermino: bloqueioHorario.horarioTermino,
    //  "0b": 0 - tem o objetivo de ordernar os bloqueios antes dos atendimentos caso tenham a mesma dataHorarioInicio. b - prefixo para diferenciar o id do item
    id: '0b' + bloqueioHorario.id,
    bloqueioHorario: bloqueioHorario
  }
}

const styles = (theme) => ({
  root: {
    position: 'absolute',
    zIndex: 20,
    width: '100%',
    textAlign: 'center'
  },
  loadingContainer: {
    margin: 16,
    display: 'inline-block',
    backgroundColor: '#fff',
    borderRadius: 16,
    ...theme.util.createBoxShadow('1px 1px 5px 1px rgba(0,0,0,0.15)')
  }
})

class AgendaItensLoader extends Component {
  constructor(props) {
    super(props)
    this.idCarregamento = 0
    this.state = {
      loaded: false,
      loading: false,
      itens: []
    }
    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['RecarregarAtendimentos', 'CancelamentoVendas'], (props) => {
      this.contentWithPreloadFunctions.refresh()
    })
    this.eventsManager.sub('ManipulacaoAtendimento', (props) => {
      const { itens } = this.state
      if (props.action === 'update' || props.action === 'create' || props.action === 'inicioAtendimento') {
        let possuiRepeticaoAntesOuDepois = false
        if (props.atendimentoParaCalendario.repeticao) {
          possuiRepeticaoAntesOuDepois = true
        }

        if (AtendimentoTipoEnum.HORA_MARCADA.id === props.atendimentoParaCalendario.tipo.id) {
          let atendimentoJaEstaNoPainel = false
          for (let i = 0; i < itens.length; i++) {
            const atendimento = itens[i].atendimento
            if (atendimento) {
              if (atendimento.id === props.atendimentoParaCalendario.id) {
                if (atendimento.repeticao) {
                  possuiRepeticaoAntesOuDepois = true
                }

                atendimentoJaEstaNoPainel = true
                /*if(atendimento.data !== props.atendimentoParaCalendario.data){
									itens.splice(i, 1);
								}else{*/
                itens[i] = gerarItemAtendimento(props.atendimentoParaCalendario)
                //}
                break
              }
            }
          }

          if (!possuiRepeticaoAntesOuDepois) {
            if (atendimentoJaEstaNoPainel === false /*&& converterMomentParaDataInt(this.props.dataSelecionada) === props.atendimentoParaCalendario.data*/) {
              itens.push(gerarItemAtendimento(props.atendimentoParaCalendario))
            }

            this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
            this.setState({ itens: itens })
          }
        }

        if (possuiRepeticaoAntesOuDepois) {
          this.contentWithPreloadFunctions.refresh()
        }
      } else if (props.action === 'alteracaoStatus') {
        for (let i = 0; i < itens.length; i++) {
          const atendimento = itens[i].atendimento
          if (atendimento) {
            if (atendimento.id === props.atendimento.id) {
              atendimento.status = props.atendimento.status
              break
            }
          }
        }
        this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
        this.setState({ itens: itens })
      }
    })
    this.eventsManager.sub('CancelamentoVenda', (props) => {
      const { itens } = this.state

      let possuiRepeticao = false

      for (let i = 0; i < itens.length; i++) {
        const atendimento = itens[i].atendimento
        if (atendimento) {
          if (atendimento.venda.id === props.venda.id) {
            if (atendimento.repeticao) {
              possuiRepeticao = true
            }

            atendimento.status = AtendimentoStatusEnum.CANCELADO
            atendimento.cancelado = true
            atendimento.venda.cancelado = true
            break
          }
        }
      }

      if (possuiRepeticao) {
        this.contentWithPreloadFunctions.refresh()
      } else {
        this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
        this.setState({ itens: itens })
      }
    })
    this.eventsManager.sub(['RemocaoVenda'], (props) => {
      const { itens } = this.state

      let possuiRepeticao = false

      for (let i = 0; i < itens.length; i++) {
        const atendimento = itens[i].atendimento
        if (atendimento) {
          if (atendimento.venda.id === props.venda.id) {
            if (atendimento.repeticao) {
              possuiRepeticao = true
            }

            itens.splice(i, 1)
            break
          }
        }
      }

      if (possuiRepeticao) {
        this.contentWithPreloadFunctions.refresh()
      } else {
        this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
        this.setState({ itens: itens })
      }
    })

    this.eventsManager.sub('AtendimentoConfirmacaoEnvioManualLembrete', (props) => {
      const { itens } = this.state
      for (let i = 0; i < itens.length; i++) {
        const atendimento = itens[i].atendimento
        if (atendimento) {
          if (props.idsAtendimentos.indexOf(atendimento.id) >= 0) {
            atendimento.lembreteManualEnviado = true
          }
        }
      }
      this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
      this.setState({ itens: itens })
    })
    this.eventsManager.sub('ManipulacaoVendas', (props) => {
      const { itens } = this.state
      if (props.action === 'alteracaoStatus') {
        for (let i = 0; i < itens.length; i++) {
          const atendimento = itens[i].atendimento
          if (atendimento) {
            if (props.vendas && props.vendas.length > 0) {
              for (let venda of props.vendas) {
                if (venda.id === atendimento.venda.id) {
                  if (venda.atendimento) {
                    atendimento.status = venda.atendimento.status
                  }
                  atendimento.venda.status = venda.status
                  if (props.notaVenda) {
                    atendimento.venda.notaVenda = props.notaVenda
                  }
                  break
                }
              }
            }
          }
        }
        this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
        this.setState({ itens: itens })
      }
    })

    this.eventsManager.sub('ManipulacaoBloqueioHorario', (props) => {
      const { itens } = this.state
      if (props.action === 'update' || props.action === 'create') {
        let bloqueioHorarioJaEstaNoPainel = false
        for (let i = 0; i < itens.length; i++) {
          const bloqueioHorario = itens[i].bloqueioHorario
          if (bloqueioHorario) {
            if (bloqueioHorario.id === props.bloqueioHorario.id) {
              bloqueioHorarioJaEstaNoPainel = true
              if (bloqueioHorario.data !== props.bloqueioHorario.data) {
                itens.splice(i, 1)
              } else {
                itens[i] = gerarItemBloqueioHorario(props.bloqueioHorario)
              }
              break
            }
          }
        }
        if (bloqueioHorarioJaEstaNoPainel === false) {
          itens.push(gerarItemBloqueioHorario(props.bloqueioHorario))
        }
        this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
        this.setState({ itens: itens })
      }
    })
    this.eventsManager.sub(['RemocaoBloqueioHorario'], (props) => {
      const { itens } = this.state
      for (let i = 0; i < itens.length; i++) {
        const bloqueioHorario = itens[i].bloqueioHorario
        if (bloqueioHorario) {
          if (bloqueioHorario.id === props.bloqueioHorario.id) {
            itens.splice(i, 1)
            break
          }
        }
      }
      this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
      this.setState({ itens: itens })
    })
  }

  carregarAtendimentos = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.idCarregamento++
    const idCarregamento = this.idCarregamento

    this.setState({
      loaded: false,
      loading: true,
      itens: []
    })

    let carregarAtendimentos = true
    let carregarBloqueios = true

    if (this.props.perspectiva.id === PerspectivaEnum.DIA.id && this.props.visao.id !== VisaoEnum.PROFISSIONAL.id) {
      carregarBloqueios = false
    } else if (this.props.perspectiva.id === PerspectivaEnum.LISTA.id || this.props.perspectiva.id === PerspectivaEnum.FILA_ESPERA.id) {
      carregarBloqueios = false
    }

    let dataInicial = null
    let dataFinal = null
    const idColaboradores = []

    if (this.props.perspectiva.id === PerspectivaEnum.DIA.id) {
      dataInicial = converterMomentParaDataInt(this.props.dataSelecionada)
      dataFinal = dataInicial
      for (let colaborador of this.props.colaboradores) {
        if (colaborador._show) {
          idColaboradores.push(colaborador.id)
        }
      }
      if (idColaboradores.length === 0) {
        for (let colaborador of this.props.colaboradores) {
          idColaboradores.push(colaborador.id)
        }
      }
    } else if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      dataInicial = this.props.colunas.asList[0].id
      dataFinal = this.props.colunas.asList[this.props.colunas.asList.length - 1].id
      idColaboradores.push(this.props.colaboradorSelecionado.id)
    } else if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
      dataInicial = this.props.dataInicial
      dataFinal = this.props.dataFinal
      idColaboradores.push(this.props.colaboradorSelecionado.id)
    }

    getAPI({
      url: 'erp/atendimentos.buscarAtendimentosEBloqueiosPorData',
      params: {
        carregarAtendimentos: carregarAtendimentos,
        carregarBloqueios: carregarBloqueios,
        idTipo: AtendimentoTipoEnum.HORA_MARCADA.id,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        idColaboradores: idColaboradores
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const itens = this.gerarItens({
          atendimentos: response.data.atendimentos,
          bloqueiosHorarios: response.data.bloqueiosHorarios
        })
        if (idCarregamento === this.idCarregamento) {
          this.prepararAtendimentos(itens, this.props.colunas, this.props.perspectiva, this.props.visao)
          this.setState({
            loaded: true,
            loading: false,
            itens: itens
          })
          notifyContentLoaded()
        }
      },
      onError: (response) => {
        if (idCarregamento === this.idCarregamento) {
          this.setState({
            loaded: false,
            loading: false
          })
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      }
    })
  }

  getColunasPorVisao = (item, perspectiva, visao) => {
    if (item.atendimento) {
      if (perspectiva.id === PerspectivaEnum.SEMANA.id) {
        const idsColunas = []
        if (item.atendimento.venda.colaborador.id === this.props.colaboradorSelecionado.id) {
          idsColunas.push({ id: item.atendimento.data })
        } else {
          if (item.atendimento.venda.assistentesAtendimento) {
            for (let assistente of item.atendimento.venda.assistentesAtendimento) {
              if (assistente.colaborador.id === this.props.colaboradorSelecionado.id) {
                idsColunas.push({ id: item.atendimento.data, isColunaAssistente: true })
              }
            }
          }
        }
        return idsColunas
      } else if (visao.id === VisaoEnum.PROFISSIONAL.id) {
        const idsColunas = [{ id: item.atendimento.venda.colaborador.id }]
        if (item.atendimento.venda.assistentesAtendimento) {
          for (let assistente of item.atendimento.venda.assistentesAtendimento) {
            if (idsColunas.indexOf(assistente.colaborador.id) <= -1) {
              idsColunas.push({ id: assistente.colaborador.id, isColunaAssistente: true })
            }
          }
        }
        return idsColunas
      } else if (visao.id === VisaoEnum.ANDAMENTO.id) {
        return [{ id: item.atendimento.status.id }]
      } else if (visao.id === VisaoEnum.PAGAMENTO.id) {
        return [{ id: item.atendimento.venda.status.id }]
      } /*
			else if(this.props.visao.id === VisaoEnum.SEMANA.id){
				return atendimento.data;
			}*/
    } else if (item.bloqueioHorario) {
      if (perspectiva.id === PerspectivaEnum.DIA.id) {
        if (visao.id === VisaoEnum.PROFISSIONAL.id) {
          return [{ id: item.bloqueioHorario.colaborador.id }]
        }
      } else if (perspectiva.id === PerspectivaEnum.SEMANA.id) {
        if (item.bloqueioHorario.colaborador.id === this.props.colaboradorSelecionado.id) {
          return [{ id: item.bloqueioHorario.data }]
        }
      }
    }
    return null
  }

  gerarItens = (params) => {
    const itens = []
    if (params.atendimentos && params.atendimentos.length > 0) {
      for (let i = 0; i < params.atendimentos.length; i++) {
        itens.push(gerarItemAtendimento(params.atendimentos[i]))
      }
    }
    if (params.bloqueiosHorarios && params.bloqueiosHorarios.length > 0) {
      for (let i = 0; i < params.bloqueiosHorarios.length; i++) {
        itens.push(gerarItemBloqueioHorario(params.bloqueiosHorarios[i]))
      }
    }
    return itens
  }

  prepararAtendimentos = (itens, colunasGrid, perspectiva, visao) => {
    if (itens) {
      if (
        (perspectiva.id === PerspectivaEnum.DIA.id && visao.id !== VisaoEnum.ANDAMENTO.id) ||
        perspectiva.id === PerspectivaEnum.SEMANA.id ||
        perspectiva.id === PerspectivaEnum.MES.id
      ) {
        const indicesItensParaRemover = []

        for (let i = 0; i < itens.length; i++) {
          const item = itens[i]
          if (item.atendimento) {
            if (AtendimentoStatusEnum.CLIENTE_FALTOU.id === item.atendimento.status.id || AtendimentoStatusEnum.CANCELADO.id === item.atendimento.status.id) {
              indicesItensParaRemover.push(i)
            }
          }
        }

        indicesItensParaRemover.reverse()

        for (let indiceItemParaRemover of indicesItensParaRemover) {
          itens.splice(indiceItemParaRemover, 1)
        }
      }

      itens.sort(function (a, b) {
        if (a.dataHorarioInicio < b.dataHorarioInicio) return -1
        if (a.dataHorarioInicio > b.dataHorarioInicio) return 1
        if (a.id < b.id) return -1
        if (a.id > b.id) return 1
        return 0
      })

      const mapColunasInternas = {}

      for (let item of itens) {
        item._instancias = []

        if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
          let _isColunaAssistente = null

          if (item.atendimento) {
            if (item.atendimento.venda.colaborador.id === this.props.colaboradorSelecionado.id) {
              _isColunaAssistente = false
            } else {
              for (let assistente of item.atendimento.venda.assistentesAtendimento) {
                if (assistente.colaborador.id === this.props.colaboradorSelecionado.id) {
                  _isColunaAssistente = true
                  break
                }
              }
            }
          } else if (item.bloqueioHorario) {
            _isColunaAssistente = false
          }

          if (_isColunaAssistente !== null) {
            item._instancias.push({
              _isColunaAssistente: _isColunaAssistente
            })
          }
        } else {
          const colunas = this.getColunasPorVisao(item, perspectiva, visao)

          if (colunas) {
            for (let coluna of colunas) {
              if (coluna && coluna.id) {
                let _coluna = colunasGrid.asMap[coluna.id]

                // a coluna não foi encontrada pq um card de principal/assistente pode pertencer a uma coluna que não está visível
                if (!_coluna) {
                  continue
                }

                let _colunaInterna = undefined

                if (!mapColunasInternas[_coluna.posicao.toString()]) {
                  mapColunasInternas[_coluna.posicao.toString()] = {}
                }

                const colunasInternas = mapColunasInternas[_coluna.posicao.toString()]
                let tentativaAtualColunaInterna = 0
                while (true) {
                  const colunaInterna = colunasInternas[tentativaAtualColunaInterna.toString()]
                  if (!colunaInterna || colunaInterna.horarioTermino <= item.horarioInicio) {
                    colunasInternas[tentativaAtualColunaInterna.toString()] = { horarioInicio: item.horarioInicio, horarioTermino: item.horarioTermino }
                    _colunaInterna = tentativaAtualColunaInterna
                    break
                  }
                  tentativaAtualColunaInterna++
                }

                if (_coluna._quantidadeColunasInternas === undefined || _coluna._quantidadeColunasInternas < tentativaAtualColunaInterna) {
                  _coluna._quantidadeColunasInternas = tentativaAtualColunaInterna
                }

                item._instancias.push({
                  _coluna: _coluna,
                  _colunaInterna: _colunaInterna,
                  _isColunaAssistente: coluna.isColunaAssistente
                })
              }
            }
          }
        }
      }
    }
  }

  render() {
    const { classes } = this.props
    const { loaded, loading, itens } = this.state

    const loadingContainerStyle = {}

    if (loaded === true) {
      loadingContainerStyle.display = 'none'
    } else if (loading === true) {
      loadingContainerStyle.borderRadius = 60
    } else {
      loadingContainerStyle.padding = 16
    }

    if (this.props.perspectiva.id === PerspectivaEnum.DIA.id) {
      loadingContainerStyle.marginTop = 86
    } else if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      loadingContainerStyle.marginTop = 108
    } else if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
      loadingContainerStyle.marginTop = 12
    }

    let content = this.props.children ? this.props.children : null
    if (typeof content == 'function') {
      content = content({
        loaded: loaded,
        itens: itens
      })
    }

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.loadingContainer} style={loadingContainerStyle}>
            <ContentWithPreload
              key={this.props.loadKey}
              functionsMap={this.contentWithPreloadFunctions}
              loadFunction={this.carregarAtendimentos}
              circularProgressVariant="contained"
            />
          </div>
        </div>
        {content}
      </React.Fragment>
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.visao.id !== nextProps.visao.id) {
      this.prepararAtendimentos(this.state.itens, nextProps.colunas, nextProps.perspectiva, nextProps.visao)
    }
    return true
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AgendaItensLoader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AgendaItensLoader)
