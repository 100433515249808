import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import EnterpriseHelmet from 'pages/erp/EnterpriseHelmet'
import IndexAppPage from 'pages/erp/IndexAppPage'
import { ROUTE_ENTERPRISE_MAIN, ROUTE_ENTERPRISE_PANEL } from 'pages/RouteMap'
import RouterContent from 'support/components/router/RouterContent'

class ErpPagesManager extends Component {
  render() {
    return (
      <React.Fragment>
        <EnterpriseHelmet />

        <Switch>
          <Route exact path={ROUTE_ENTERPRISE_MAIN + '/'} render={() => <Redirect to={ROUTE_ENTERPRISE_PANEL} push={false} />} />
          <Route path={ROUTE_ENTERPRISE_MAIN + '/index-app'} render={(props) => <RouterContent component={IndexAppPage} sendPageViewGA={true} {...props} />} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default ErpPagesManager
