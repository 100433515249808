import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import ReplayIcon from '@material-ui/icons/Replay'
import StarIcon from '@material-ui/icons/Star'
import classNames from 'classnames'
import moment from 'moment'
import { prepararListaItensParaRecuperacaoEstado } from 'pages/portal/negocio/NegocioPage'
import AtendimentoDetalheDialog from 'pages/portal/painel/meusagendamentos/AtendimentoDetalheDialog'
import AvaliacaoDialogPage from 'pages/portal/painel/meusagendamentos/AvaliacaoDialogPage'
import PortalPagesUtil from 'pages/portal/PortalPagesUtil'
import { ROUTE_PORTAL_MAIN_AS_PREFIX } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import AtendimentoStatusServicoEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import ServicoService from 'support/domain/servico/ServicoService'
import { converterDataIntParaMoment, converterMomentParaDataHoraMinutoLong, converterMomentParaDataInt } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  descricaoData: {
    fontSize: 15
  },
  descricaoGrandeData: {
    [theme.breakpoints.down(500)]: {
      display: 'none'
    }
  },
  descricaoPequenaData: {
    [theme.breakpoints.up(500)]: {
      display: 'none'
    }
  },
  iconeCancelado: {
    stroke: '#ef7676',
    strokeWidth: 2,
    marginTop: 2,
    marginBottom: -2,
    marginRight: 1,
    fontSize: 22
  },
  iconeMarcado: {
    stroke: '#60e099',
    strokeWidth: 2,
    marginTop: 2,
    fontSize: 22
  },
  containerAtendimento: {
    cursor: 'pointer'
  },
  botaoAvaliar: {
    textTransform: 'none',
    fontSize: 15,
    color: '#ffac14',
    marginLeft: -8,
    marginTop: -8,
    marginBottom: 6
  },
  botaoAvaliarIcone: {
    color: '#ffac14',
    fontSize: 24,
    marginTop: -2
  },
  botaoAvaliarEditar: {
    fontSize: 15,
    color: '#999',
    fontWeight: 400,
    marginLeft: 8
  }
})

const OpcoesPeriodoFiltroEnum = createEnum({
  ATUAIS: { id: 1, descricao: 'Atuais' },
  PASSADOS: { id: 2, descricao: 'Passados' }
})

class MeusAgendamentosPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.lista = []
    this.state = {
      periodoFiltro: OpcoesPeriodoFiltroEnum.ATUAIS,
      listaPreparada: []
    }
    this.virtualizedListFunctionMap = {}
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoAtendimento', 'ManipulacaoAvaliacao'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  prepararLista = (atendimentos) => {
    /*atendimentos.sort(function(a, b){
			if(a.data < b.data) return -1;
			if(a.data > b.data) return 1;
			if(a.negocio.id < b.negocio.id) return -1;
			if(a.negocio.id > b.negocio.id) return 1;
			if(a.horarioInicio < b.horarioInicio) return -1;
			if(a.horarioInicio > b.horarioInicio) return 1;
			return 0;
		});*/

    const lista = []
    let itemAtual = null
    let ultimoAtendimento = null
    for (let atendimento of atendimentos) {
      if (!itemAtual || (ultimoAtendimento && (ultimoAtendimento.negocio.id !== atendimento.negocio.id || ultimoAtendimento.data !== atendimento.data))) {
        itemAtual = {
          data: atendimento.data,
          negocio: atendimento.negocio,
          atendimentos: []
        }
        lista.push(itemAtual)
      }
      itemAtual.atendimentos.push(atendimento)
      ultimoAtendimento = atendimento
    }
    return lista
  }

  getRequestParameters = () => {
    const { periodoFiltro } = this.state
    let dataInicial = null
    let dataFinal = null

    if (periodoFiltro.id === OpcoesPeriodoFiltroEnum.ATUAIS.id) {
      dataInicial = converterMomentParaDataInt(moment())
    } else if (periodoFiltro.id === OpcoesPeriodoFiltroEnum.PASSADOS.id) {
      dataFinal = converterMomentParaDataInt(moment().add(-1, 'days'))
    }

    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal
    }
  }

  getRenderContent = (size, item, index) => {
    const { classes } = this.props
    const content = {}

    let containerAvaliacao = null
    const dataHoraMinutoLong = converterMomentParaDataHoraMinutoLong(moment())
    let podeSerAvaliado = false
    for (let atendimento of item.atendimentos) {
      if (dataHoraMinutoLong >= atendimento.dataHorarioInicio) {
        podeSerAvaliado = true
        break
      }
    }

    if (podeSerAvaliado) {
      let onClick = null
      let label = null

      if (item.negocio.avaliacoes && item.negocio.avaliacoes.length > 0) {
        const avaliacao = item.negocio.avaliacoes[0]
        label = (
          <span>
            Você deu Nota {avaliacao.nota} <span className={classes.botaoAvaliarEditar}>Editar</span>
          </span>
        )
        onClick = () => {
          openBackableDialog(AvaliacaoDialogPage, {
            negocio: item.negocio,
            nota: avaliacao.nota,
            comentario: avaliacao.comentario
          })
        }
      } else {
        label = 'Avaliar empresa/profissional'
        onClick = () => {
          openBackableDialog(AvaliacaoDialogPage, {
            negocio: item.negocio
          })
        }
      }
      containerAvaliacao = (
        <Button className={classes.botaoAvaliar} startIcon={<StarIcon className={classes.botaoAvaliarIcone} />} onClick={onClick}>
          {label}
        </Button>
      )
    }

    content.content = (
      <div style={{ padding: '18px 14px 14px 16px', border: '1px solid #e2e2e2', borderRadius: 8, marginTop: 8, marginBottom: 4 }}>
        {containerAvaliacao}

        <Grid container alignItems="center" wrap="nowrap" style={{ marginBottom: 8 }}>
          <Grid item xs style={{ minWidth: 0 }}>
            <Typography variant="body1" style={{ fontSize: 15 }} noWrap={true}>
              <b>{item.negocio.nome}</b>
            </Typography>
          </Grid>
          <Grid item style={{ paddingLeft: 16 }}>
            <Typography variant="body1" className={classNames(classes.descricaoData, classes.descricaoGrandeData)}>
              <b>{converterDataIntParaMoment(item.data).format('DD MMMM YYYY')}</b>
            </Typography>
            <Typography variant="body1" className={classNames(classes.descricaoData, classes.descricaoPequenaData)}>
              <b>{converterDataIntParaMoment(item.data).format('DD MMM YYYY')}</b>
            </Typography>
          </Grid>
        </Grid>

        {item.atendimentos.map((atendimento, index2) => {
          let iconContent = null

          if (AtendimentoStatusServicoEnum.CANCELADO.id === atendimento.status.id || AtendimentoStatusServicoEnum.CLIENTE_FALTOU.id === atendimento.status.id) {
            iconContent = <CloseIcon className={classes.iconeCancelado} />
          } else {
            iconContent = <DoneIcon className={classes.iconeMarcado} />
          }

          return (
            <Grid
              key={index2}
              container
              alignItems="center"
              className={classes.containerAtendimento}
              style={{ marginTop: index2 > 0 ? 0 : 0, minWidth: 0 }}
              wrap="nowrap"
              onClick={() => {
                openBackableDialog(AtendimentoDetalheDialog, {
                  idAtendimento: atendimento.id
                })
              }}
            >
              <Grid item xs style={{ paddingTop: 0, paddingBottom: 0, minWidth: 0 }}>
                <Typography variant="body2" style={{ fontSize: 15 }} noWrap={true}>
                  {formatarHorarioInt(atendimento.horarioInicio)} - {ServicoService.gerarNomeCompletoServico(atendimento.servico, atendimento.variacaoServico)}
                </Typography>
              </Grid>
              <Grid item style={{ paddingLeft: 6 }}>
                {iconContent}
              </Grid>
              <Grid item style={{ marginLeft: 4, marginRight: 0 }}>
                <IconButton style={{ padding: 4 }}>
                  <OpenInNewIcon color="primary" style={{ fontSize: 20 }} />
                </IconButton>
              </Grid>
            </Grid>
          )
        })}

        {item.negocio.paginaPortalAtiva && (
          <div style={{ textAlign: 'right', marginTop: 0, marginRight: -4, marginBottom: -6 }}>
            <Button
              style={{ textTransform: 'none', fontSize: 15, marginRight: 0 }}
              color="secondary"
              startIcon={<ReplayIcon style={{ fontSize: 20 }} />}
              onClick={() => {
                prepararListaItensParaRecuperacaoEstado(
                  item.negocio.id,
                  null,
                  item.atendimentos.map((atendimento) => ({
                    servico: atendimento.servico,
                    variacao: atendimento.variacaoServico,
                    colaborador: atendimento.venda.colaborador
                  }))
                )
                EventsManager.pub('RoutePush', {
                  path: ROUTE_PORTAL_MAIN_AS_PREFIX + PortalPagesUtil.gerarUrlNegocio({ id: item.negocio.id, hash: item.negocio.hash })
                })
              }}
            >
              Repetir Agendamento
            </Button>
          </div>
        )}
      </div>
    )
    return content
  }

  render() {
    const { classes, ...others } = this.props
    const { periodoFiltro } = this.state

    let textoNenhumAtendimentoEncontrado = null
    if (periodoFiltro.id === OpcoesPeriodoFiltroEnum.ATUAIS.id) {
      textoNenhumAtendimentoEncontrado = 'Não foi encontrado nenhum atendimento agendado para hoje ou próximos dias.'
    } else if (periodoFiltro.id === OpcoesPeriodoFiltroEnum.PASSADOS.id) {
      textoNenhumAtendimentoEncontrado = 'Não foi encontrado nenhum atendimento passado no seu cadastro.'
    }

    return (
      <PanelPage
        {...others}
        contentMaxWidth={520}
        title="Meus Agendamentos"
        toolbarActions={{
          actions: [
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.loaderFunctionsMap.load()
              }
            }
          ]
        }}
      >
        <RadioGroup
          row
          style={{ marginLeft: 4, marginBottom: this.state.listaPreparada && this.state.listaPreparada.length > 0 ? 4 : 10 }}
          name="envio"
          className={classes.group}
          value={periodoFiltro.id}
          onChange={(event) => {
            this.setState({
              periodoFiltro: OpcoesPeriodoFiltroEnum.getById(parseInt(event.target.value))
            })
          }}
        >
          {OpcoesPeriodoFiltroEnum.values().map((opcao, index) => (
            <FormControlLabel
              key={index}
              value={opcao.id}
              style={{ marginBottom: 8 }}
              control={<Radio style={{ padding: 6 }} />}
              label={
                <Typography variant="body2" style={{ fontWeight: 500 }}>
                  {opcao.descricao}
                </Typography>
              }
            />
          ))}
        </RadioGroup>

        <VirtualizedResponsiveTable
          loaderKey={periodoFiltro.id}
          showHeaderColumns={false}
          showRowBorders={false}
          itemsPerRequest={40}
          loaderFunctionsMap={this.loaderFunctionsMap}
          showBackgroundDividers={false}
          dynamicHeight={true}
          autoIncrementNewItems={false}
          loadingIndicatorRowHeight={200}
          contextoUsuario="portal"
          endpoint={'ptl/atendimentos.buscarMeusAtendimentos'}
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.listaPreparada}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.lista = []
                this.setState({ listaPreparada: [] })
              }
            } else if (data.status === 'loaded') {
              this.lista = this.lista.concat(data.items)
              this.setState({ listaPreparada: this.prepararLista(this.lista) })
            }
          }}
          largeRenderProps={{
            columns: [{ props: { xs: true }, horizontalPadding: 'none' }],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item, index)
              return {
                itemData: [content.content]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 80,
            horizontalPadding: 'none',
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item, index)
              return {
                itemData: content.content
              }
            }
          }}
          emptyListProps={{
            align: 'left',
            text: textoNenhumAtendimentoEncontrado
          }}
          listFunctionsMap={this.virtualizedListFunctionMap}
        />
      </PanelPage>
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.virtualizedListFunctionMap && this.virtualizedListFunctionMap.recomputeRowHeights) {
      this.virtualizedListFunctionMap.recomputeRowHeights(this.state.listaPreparada.length - 1)
    }
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

MeusAgendamentosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MeusAgendamentosPanelPage)
