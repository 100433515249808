import { useCallback, useEffect, useMemo, useState } from 'react'

import buscarDadosCarregamentoPlano from 'services/negocios/buscarDadosCarregamentoPlano'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentLoadStatus from 'support/components/contentload/ContentLoadStatus'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import NegocioPagamentoService from 'support/domain/negociopagamento/NegocioPagamentoService'

import NegocioDadosBasicosDialogPage from '../negocio/NegocioDadosBasicosDialogPage'
import IOSCustomContent from './IOSCustomContent/IOSCustomContent'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PlanoPanel({ negocioSessao, ...props }: { negocioSessao: any; props: any }) {
  const [isLoading, setIsLoading] = useState(true)

  const plano = useMemo(() => new URLSearchParams(window.location.search).get('plano'), [])
  const tipoPlano = useMemo(() => new URLSearchParams(window.location.search).get('tipo'), [])
  const isNativeiOS = useMemo(() => DeviceIdentifier.isNativeIOS(), [])

  const getDadosCarregamentoPlano = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await buscarDadosCarregamentoPlano()

      if (plano && tipoPlano) {
        return openBackableDialog(NegocioDadosBasicosDialogPage, { idNegocio: response.data.negocio.id, plano: plano, tipoPlano: tipoPlano })
      }
    } catch (error) {
      newrelic?.noticeError('message' in (error as Error) ? (error as Error) : (error as object).toString())
    }
    setIsLoading(false)
  }, [plano, tipoPlano])

  useEffect(() => {
    if (!isNativeiOS) {
      if (plano && tipoPlano) {
        getDadosCarregamentoPlano()
        return
      }

      window.location.href = NegocioPagamentoService.gerarUrlDeRedirecionamentoParaPlanos(negocioSessao.usaPagamentoRecorrente)
    }
  }, [getDadosCarregamentoPlano, negocioSessao, plano, tipoPlano, isNativeiOS])

  if (isNativeiOS) {
    return <IOSCustomContent negocioSessao={negocioSessao} {...props} />
  }

  return <ContentLoadStatus loading={isLoading} align="center" loadFunction={getDadosCarregamentoPlano} />
}

export default PlanoPanel
