import Bookmark from '@material-ui/icons/Bookmark'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'

import DeviceIdentifier from '../device/DeviceIdentifier'
import DrawerMenuItem from '../panel/DrawerMenuItem'
import DrawerMenuItemIcon from '../panel/DrawerMenuItemIcon'
import DrawerMenuItemText from '../panel/DrawerMenuItemText'
import SimpleDrawerMenuItem from '../panel/SimpleDrawerMenuItem'

type PlanoMenuItemProps = {
  readonly isMenuRetracted: boolean
  readonly classes: {
    [key: string]: string
  }
  readonly session: Pick<ReturnType<typeof getSessaoPainel>, 'negocio' | 'usuario'>
}

const iconObj = { component: Bookmark } as const

function PlanoMenuItem({ isMenuRetracted, classes, session }: PlanoMenuItemProps) {
  const menuText = session.negocio.plano.id === PlanoEnum.AVALIACAO.id ? 'Planos' : 'Plano Contratado'
  const onClick = () => {
    window.location.href = `${process.env.REACT_APP_MFE_ADDRESS}/planos${session?.negocio?.usaPagamentoRecorrente ? '/assinatura' : ''}`
  }

  if (DeviceIdentifier.isNativeIOS()) {
    return <SimpleDrawerMenuItem data-testid="simple-menuitem" key="plano" rota={'/plano'} regexUrl="plano" isMenuRetracted={isMenuRetracted} icon={iconObj} primary={menuText} />
  }

  return (
    <DrawerMenuItem key="plano" onClick={onClick} regexUrl="plano">
      <DrawerMenuItemIcon isMenuRetracted={isMenuRetracted} icon={iconObj} tooltipTitle={menuText} />
      <DrawerMenuItemText isMenuRetracted={isMenuRetracted} primary={menuText} classes={classes} extraMarginRight />
    </DrawerMenuItem>
  )
}

export default PlanoMenuItem
