import React, { Component } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import ImageIcon from '@material-ui/icons/ImageOutlined'
import LockIcon from '@material-ui/icons/Lock'
import ConfiguracoesCertificadoDigitalDialogPage from 'pages/erp/painel/configuracoes/ConfiguracoesCertificadoDigitalDialogPage'
import ConfiguracoesGeraisNfDialogPage from 'pages/erp/painel/configuracoes/ConfiguracoesGeraisNfDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import FileUploaderDialog from 'support/components/fileuploader/FileUploaderDialog'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'
import DialogPage from 'support/components/page/DialogPage'

const styles = (theme) => ({
  listContainer: {
    cursor: 'pointer',
    marginTop: -8
  }
})

class ConfiguracoesNfDialogPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      ajaxing: false
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoConfiguracoesGeraisNf', (props) => {
      this.forceUpdate()
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    let emissaoNf = false

    if (getSessaoPainel().negocio.configuracaoNf) {
      if (getSessaoPainel().negocio.configuracaoNf.emissaoNfs === true || getSessaoPainel().negocio.configuracaoNf.emissaoNfc === true) {
        emissaoNf = true
      }
    }

    return (
      <DialogPage {...dialogProps} title={'Emissão de Notas Fiscais'} align="center" contentMaxWidth={820} pageType="basicForm" ajaxing={this.state.ajaxing}>
        <List disablePadding component="nav" className={classes.listContainer}>
          <ListItem
            disableGutters
            disabled={false}
            onClick={() => {
              openBackableDialog(ConfiguracoesGeraisNfDialogPage)
            }}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <ReceiptLongIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Configurações Gerais</b>} secondary="Configure o CNPJ, inscrição municipal, endereço, alíquotas, RPS e mais." />
          </ListItem>
          {emissaoNf && (
            <ListItem
              disableGutters
              onClick={() => {
                openBackableDialog(FileUploaderDialog, {
                  title: 'Foto do Logotipo',
                  previewBorderRadius: 1,
                  cropsProps: {
                    aspect: 160 / 130,
                    exportSize: {
                      width: 160,
                      height: 130
                    },
                    saveCroppedImageFunction: (data, onStart, onSuccess, onError) => {
                      onStart()
                      postAPI({
                        url: 'erp/negocios.vincularLogotipoNf',
                        data: {
                          imageDataAsBase64: data
                        },
                        requerAutorizacao: true,
                        timeout: 0,
                        onSuccess: (response) => {
                          onSuccess()
                          EventsManager.pub('SnackBarSuccess', { open: true, message: 'Upload realizado com sucesso.' })
                        },
                        onError: (response) => {
                          onError()
                        }
                      })
                    }
                  }
                })
              }}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText
                primary={<b>Logotipo da Empresa</b>}
                secondary="Faça o upload da imagem do logotipo da empresa. A foto será exibida no PDF da nota fiscal de algumas prefeituras"
              />
            </ListItem>
          )}
          <ListItem
            disableGutters
            onClick={() => {
              openBackableDialog(ConfiguracoesCertificadoDigitalDialogPage)
            }}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Certificado Digital</b>} secondary="Configure o certificado digital da sua empresa para a emissão de notas fiscais" />
          </ListItem>
        </List>
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ConfiguracoesNfDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesNfDialogPage)
