import React, { Component } from 'react'

import { CrossIcon } from '@grupoboticario/flora-react-icons'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import ToolbarActions from 'support/components/toolbar/ToolbarActions'
import toTS from 'support/components/toTS'

const CONTENT_MAX_WIDTH_DEFAULT = 480

const styles = (theme) => ({
  paper: {
    overflow: 'hidden',
    [theme.breakpoints.up('xs')]: {
      //backgroundColor: theme.palette.background.default,
    }
  },
  toolbarRoot: {
    ...theme.container.basic()
  },
  toolbarRootWithHorizontalFixedMargin: {
    ...theme.container.basic({ horizontalFixedMargin: 16 })
  },
  appBar: {
    position: 'fixed',
    //paddingRight: "0px !important",
    //...theme.util.createBoxShadow("0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.05), 0px 1px 2px 0px rgba(0,0,0,0.23)"),
    ...theme.util.createBoxShadow('none')
  },
  titleContainer: {
    width: '100%',
    [theme.breakpoints.up(480)]: {
      '&:not($fullscreenDialogPage)': {
        borderTop: '1px solid ' + theme.palette.divider,
        borderLeft: '1px solid ' + theme.palette.divider,
        borderRight: '1px solid ' + theme.palette.divider,
        padding: '20px 30px 0px 30px',
        width: 'calc(100% - 60px - 2px)',
        marginTop: 20,
        borderRadius: '32px 32px 0px 0px'
        //...theme.util.createBoxShadow("0px -1px 2px -1px rgba(0,0,0,0.1), 0px -2px 1px 0px rgba(0,0,0,0.04)")
      }
    },
    [theme.breakpoints.up(600)]: {
      '&:not($fullscreenDialogPage)': {
        padding: '20px 36px 0px 36px',
        width: 'calc(100% - 72px - 2px)'
      }
    },
    [theme.breakpoints.up(720)]: {
      '&:not($fullscreenDialogPage)': {
        padding: '20px 40px 0px 40px',
        width: 'calc(100% - 80px - 2px)'
      }
    },
    [theme.breakpoints.up(860)]: {
      '&:not($fullscreenDialogPage)': {
        padding: '20px 48px 0px 48px',
        width: 'calc(100% - 96px - 2px)'
      }
    }
  },
  title: {
    flex: 1,
    marginTop: 16,
    marginBottom: 16,
    lineHeight: '24px'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    height: '100%',
    WebkitOverflowScrolling: 'touch' // melhorar o comportamento bugado no iOS de manipulação de scroll em elementos position: fixed,
    //WebkitTransform: "translate3d(0,0,0)"
  },
  innerContent: {},
  innerContentFullHeight: theme.containers.fullHeightMinusToolbar,
  innerInnerContent: {
    ...theme.container.basic({ extraPaddingBottom: true }),
    paddingBottom: '0px !important'
  },
  innerInnerContentWithHorizontalFixedMargin: {
    ...theme.container.basic({ extraPaddingBottom: true, horizontalFixedMargin: 16 })
  },
  fullscreenDialogPage: {},
  innerInnerInnerContent: {
    overflow: 'hidden',
    [theme.breakpoints.up(480)]: {
      '&:not($fullscreenDialogPage)': {
        borderBottom: '1px solid ' + theme.palette.divider,
        borderLeft: '1px solid ' + theme.palette.divider,
        borderRight: '1px solid ' + theme.palette.divider,
        padding: '0px 30px 40px 30px',
        borderRadius: '0px 0px 32px 32px'
        //...theme.util.createBoxShadow("0px -1px 2px -1px rgba(0,0,0,0.1), 0px -2px 1px 0px rgba(0,0,0,0.04)"),
      }
    },
    [theme.breakpoints.up(600)]: {
      '&:not($fullscreenDialogPage)': {
        padding: '0px 36px 40px 36px'
      }
    },
    [theme.breakpoints.up(720)]: {
      '&:not($fullscreenDialogPage)': {
        padding: '0px 40px 40px 40px'
      }
    },
    [theme.breakpoints.up(860)]: {
      '&:not($fullscreenDialogPage)': {
        padding: '0px 48px 40px 48px'
      }
    }
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class DialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.dialogRef = React.createRef()
    this.appBarRef = React.createRef()
    this.innerContentRef = React.createRef()
    this.innerInnerContentRef = React.createRef()

    this.state = {
      innerContentRef: null
    }
  }

  onEnter = () => {
    this.setState({
      innerContentRef: this.innerContentRef
    })
  }

  componentDidMount = () => {
    this.ajustarAppBarInterval = window.setInterval(() => {
      if (this.dialogRef.current && this.dialogRef.current.getAttribute('aria-hidden')) {
        window.clearInterval(this.ajustarAppBarInterval)
      }
      if (this.appBarRef.current && this.innerInnerContentRef.current) {
        const appBarWidth = this.appBarRef.current.offsetWidth - parseFloat(getComputedStyle(this.appBarRef.current).paddingRight)
        const contentWidth = this.innerInnerContentRef.current.offsetWidth
        if (appBarWidth > contentWidth) {
          this.appBarRef.current.style.paddingRight = appBarWidth - contentWidth + 'px'
          window.clearInterval(this.ajustarAppBarInterval)
        } else {
          window.clearInterval(this.ajustarAppBarInterval)
        }
      }
    }, 50)
  }

  render() {
    const { classes, hasCloseButton } = this.props

    let { contentMaxWidth } = this.props

    let childrenContent = null

    if (this.state.innerContentRef) {
      childrenContent = this.props.children
      if (typeof childrenContent == 'function') {
        childrenContent = childrenContent({ scrollContainerRef: this.innerContentRef })
      }
    }

    if (contentMaxWidth && contentMaxWidth === 'default') {
      contentMaxWidth = CONTENT_MAX_WIDTH_DEFAULT
    }

    const contentStyle = {
      maxWidth: contentMaxWidth
    }

    if (this.props.align === 'center') {
      contentStyle.marginLeft = 'auto'
      contentStyle.marginRight = 'auto'
    }

    const toolbarContentStyle = {
      display: 'flex',
      alignItems: 'center',
      ...contentStyle
    }
    const innerInnerContentStyle = {
      ...contentStyle
    }

    if (this.props.pageType === 'basicForm') {
      innerInnerContentStyle.paddingTop = 16
    } else if (this.props.pageType === 'basicEntity') {
      innerInnerContentStyle.paddingTop = 8
    }

    const titleContainerClasses = [classes.titleContainer]
    const innerInnerInnerContentClasses = [classes.innerInnerInnerContent]

    if (!contentMaxWidth) {
      titleContainerClasses.push(classes.fullscreenDialogPage)
      innerInnerInnerContentClasses.push(classes.fullscreenDialogPage)
    }

    const toolbarRootClasses = [classes.toolbarRoot]
    const innerInnerContentClasses = [classes.innerInnerContent]

    if (this.props.contentMaxWidth && this.props.align === 'center') {
      toolbarRootClasses.push(classes.toolbarRootWithHorizontalFixedMargin)
      innerInnerContentClasses.push(classes.innerInnerContentWithHorizontalFixedMargin)
    }

    return (
      <CustomDialog
        style={
          {
            /*position: "absolute",wwillChange: "ttransform"*/
          }
        }
        dialogProps={this.props}
        dialogRef={this.dialogRef}
        onEnter={this.onEnter}
        classes={{ paper: classes.paper }}
        fullScreen={true}
        TransitionComponent={Transition}
      >
        {' '}
        {/* willChange - Melhora performace de scroll no chrome para elementos fixed ou filhos de pais fixed. É necessarios para renderizar bem virtualize-react */}
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <AppBar className={classes.appBar} ref={this.appBarRef} style={{ position: 'static' }}>
            <Toolbar className={classNames(toolbarRootClasses)}>
              <div style={toolbarContentStyle} className={classNames(titleContainerClasses)}>
                {!this.props.hidebackButton && (
                  <IconButton
                    style={{ marginLeft: -16, visibility: this.props.ajaxing ? 'hidden' : 'visible' }}
                    color="inherit"
                    onClick={() => this.props.handleCloseDialog()}
                    aria-label="Close"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Typography variant="h6" color="inherit" className={classes.title} noWrap={this.props.titleWrap ? false : true}>
                  {this.props.ajaxing ? 'Aguarde...' : this.props.title}
                </Typography>  
                <ToolbarActions ajaxing={this.props.ajaxing} toolbarActions={this.props.toolbarActions} />
                {hasCloseButton && (
                  <IconButton onClick={this.props.handleCloseDialog}>
                    <CrossIcon color="#000" />
                  </IconButton>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <div
            className={classNames(classes.contentContainer, 'needsWillChange')}
            ref={this.innerContentRef}
            style={{
              position: 'relative',
              flex: '1 1 0',
              wwillChange: 'ttransform'
            }}
          >
            <div className={classNames(classes.innerContent)}>
              {' '}
              {/* willChange - Melhora performace de scroll no chrome para elementos fixed ou filhos de pais fixed. É necessarios para renderizar bem virtualize-react */}
              <div style={{ paddingBottom: this.props.noPadding ? '0px !important' : '' }} className={classNames(innerInnerContentClasses)} ref={this.innerInnerContentRef}>
                <div style={innerInnerContentStyle} className={classNames(innerInnerInnerContentClasses)}>
                  {childrenContent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    window.clearInterval(this.ajustarAppBarInterval)
  }
}

DialogPage.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired
}

export default toTS(withStyles(styles)(DialogPage))
