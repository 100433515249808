import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import PublicIcon from '@material-ui/icons/Public'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import ServicoDadosBasicosDialogPage from 'pages/erp/painel/servico/ServicoDadosBasicosDialogPage'
import TabColaboradores from 'pages/erp/painel/servico/TabColaboradores'
import TabRecursos from 'pages/erp/painel/servico/TabRecursos'
import TabVariacoesServicosProdutos from 'pages/erp/painel/servico/TabVariacoesServicosProdutos'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import Subject from 'support/components/page/subject/Subject'
import Tab from 'support/components/tab/Tab'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoConfiguracaoComissaoEnum from 'support/domain/comissao/TipoConfiguracaoComissaoEnum'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import { formatarValorCalculo } from 'support/util/CalculoFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { formatarDuracao } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  iconWithText: {
    verticalAlign: 'sub',
    fontSize: 20,
    marginRight: 5
  },
  variacaoContainer: {
    padding: '12px 16px 12px 18px',
    /*borderLeft: "5px solid "+theme.palette.primary.light,*/
    //boxShadow : "1px 1px 13px 0 rgba(0,0,0,.15)",
    border: '1px solid #eaeaea',
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7f7f7'
    }
  },
  gridVariacaoDuracao: {
    width: 100
  },
  gridVariacaoPreco: {
    width: 120
  },
  gridVariacaoIconButton: {
    padding: 5,
    marginLeft: 10
  },
  colunaDuracao: {
    [theme.breakpoints.down('sm')]: {
      display: 'nome'
    }
  }
})

class ServicoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}

    this.state = {
      servico: null,
      title: 'Serviço',
      ajaxing: false,
      tabValue: 0
    }

    this.inputNome = React.createRef()
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoServico', (props) => {
      if (props !== undefined && props.servico !== undefined) {
        this.setState({ servico: props.servico })
      } else {
        this.contentWithPreloadFunctions.reload()
      }
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/servicos.buscarPorId',
      params: {
        id: this.props.idServico
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        response.data.variacoes.sort(function (a, b) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })
        this.setState({
          servico: response.data,
          toolbarActions: {
            actions: [
              {
                label: 'Editar',
                handleAction: this.handlerEditar
              }
            ]
          }
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handlerEditar = () => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_CAD_SERVICOS])) {
      openBackableDialog(ServicoDadosBasicosDialogPage, { idServico: this.state.servico.id })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { tabValue, servico } = this.state

    const subjectInfos = []

    if (servico) {
      let descricaoComissaoSozinho = null
      if (servico.comissaoSozinho !== null && servico.comissaoSozinho !== undefined) {
        let labelComissaoSozinho = 'Comissão Sozinho'
        if (servico.tipoConfiguracaoComissao.id === TipoConfiguracaoComissaoEnum.SIMPLES.id) {
          labelComissaoSozinho = 'Comissão Padrão'
        }

        descricaoComissaoSozinho = labelComissaoSozinho + ': ' + formatarValorCalculo(servico.tipoCalculoComissaoSozinho, servico.comissaoSozinho)
      }
      let descricaoComissaoComAssistente = null
      if (
        servico.comissaoComAssistente !== null &&
        servico.comissaoComAssistente !== undefined &&
        servico.tipoConfiguracaoComissao.id === TipoConfiguracaoComissaoEnum.AVANCADA.id
      ) {
        descricaoComissaoComAssistente = 'Com Assistente: ' + formatarValorCalculo(servico.tipoCalculoComissaoComAssistente, servico.comissaoComAssistente)
      }
      let descricaoComissaoComoAssistente = null
      if (
        servico.comissaoComoAssistente !== null &&
        servico.comissaoComoAssistente !== undefined &&
        servico.tipoConfiguracaoComissao.id === TipoConfiguracaoComissaoEnum.AVANCADA.id
      ) {
        descricaoComissaoComoAssistente = 'Como Assistente: ' + formatarValorCalculo(servico.tipoCalculoComissaoComoAssistente, servico.comissaoComoAssistente)
      }

      subjectInfos.push({ type: 'text', marginTop: 'small', icon: FolderOpenIcon, text: servico.categoria.nome })

      if (getSessaoPainel().negocio.carregado && ControleAgendamentoOnlineEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleAgendamentoOnline.id) {
        if (servico.controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.HABILITADO.id) {
          subjectInfos.push(
            <Grid container alignItems="center" style={{ marginTop: 6, marginBottom: 8 }}>
              <Grid item style={{ marginRight: 6 }}>
                <PublicIcon style={{ display: 'block' }} fontSize="small" color="secondary" />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="secondary">
                  Agendamento Online Habilitado
                </Typography>
              </Grid>
            </Grid>
          )
        } else {
          subjectInfos.push(
            <Grid container alignItems="center" style={{ marginTop: 6, marginBottom: 8, opacity: 0.5 }}>
              <Grid item style={{ marginRight: 6 }}>
                <PublicIcon style={{ display: 'block', opacity: 0.8 }} fontSize="small" />
              </Grid>
              <Grid item>
                <Typography variant="body1">Agendamento Online Desabilitado</Typography>
              </Grid>
            </Grid>
          )
        }
        subjectInfos.push(<Divider style={{ marginTop: 16, marginBottom: 16 }} />)
      }

      if (descricaoComissaoSozinho === null && descricaoComissaoComAssistente === null && descricaoComissaoComoAssistente === null) {
        subjectInfos.push({ type: 'text', text: 'Comissão não cadastrada' })
      } else {
        if (descricaoComissaoSozinho) subjectInfos.push({ type: 'text', text: descricaoComissaoSozinho })
        if (descricaoComissaoComAssistente) subjectInfos.push({ type: 'text', text: descricaoComissaoComAssistente })
        if (descricaoComissaoComoAssistente) subjectInfos.push({ type: 'text', text: descricaoComissaoComoAssistente })
      }

      if (servico.variacoes) {
        if (servico.variacoes.length === 1) {
          const variacao = servico.variacoes[0]
          subjectInfos.push({
            type: 'text',
            text: (
              <b>
                {formatarDuracao(variacao.duracao, { descricao: 'minificada' })} | {formatarValorMonetario(variacao.preco)}
              </b>
            )
          })
        } else {
          for (let variacao of servico.variacoes) {
            subjectInfos.push({
              type: 'textWithIcon',
              marginTop: 'small',
              icon: SubdirectoryArrowRightIcon,
              text: (variacao.nome ? variacao.nome + ': ' : '') + formatarDuracao(variacao.duracao, { descricao: 'minificada' }) + ', ' + formatarValorMonetario(variacao.preco)
            })
          }
        }
      }
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={600}
        pageType="basicEntity"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
        onPreClose={() => {
          EventsManager.pub('TabSaveSnackBar', { open: false })
        }}
      >
        {(dialogContentProps) => (
          <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarMain} loadContentPadding={true}>
            {() => (
              <div>
                <Subject title={servico.nome} infos={subjectInfos} />

                <Tab
                  value={tabValue}
                  onChange={(event, value) => {
                    this.setState({ tabValue: value })
                  }}
                  tabs={[{ label: 'Profissionais' }, { label: 'Uso de produtos' }, { label: 'Recursos' }]}
                />
                <div>
                  {tabValue === 0 && <TabColaboradores scrollElement={dialogContentProps.scrollContainerRef.current} servico={this.state.servico} />}
                  {tabValue === 1 && <TabVariacoesServicosProdutos scrollElement={dialogContentProps.scrollContainerRef.current} servico={this.state.servico} />}
                  {tabValue === 2 && <TabRecursos scrollElement={dialogContentProps.scrollContainerRef.current} servico={this.state.servico} />}
                </div>
              </div>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ServicoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServicoDialogPage)
