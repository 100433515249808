import React, { useState } from 'react'

import { getTelefoneMaskIdByNumero, TelefoneMask } from '../../../util/Masks'
import InputText from '../InputText'

type InputTelefoneProps = {
  phoneRef: React.RefObject<HTMLInputElement>
  style?: { [key: string]: string | number }
  defaultPhone?: string
  placeholder?: string
  label?: string
  error?: string | null
  ajaxing?: boolean
  inputVariant?: 'filled' | 'naked' | 'outlined' | 'outlined-small'
}

const MASKFULLLENGTH = 9

export const InputTelefone = ({
  phoneRef,
  error,
  defaultPhone,
  style = {},
  label = 'Número do Celular',
  placeholder = '(00) 00000-0000',
  inputVariant = 'outlined-small',
  ajaxing = false
}: InputTelefoneProps) => {
  const [phoneMaskId, setPhoneMaskId] = useState(getTelefoneMaskIdByNumero(defaultPhone))

  return (
    <InputText
      style={style}
      idname="xcelular"
      shrink={false}
      customVariant={inputVariant}
      disabled={ajaxing}
      errorMessage={error}
      label={label}
      defaultValue={defaultPhone}
      inputProps={{
        ref: phoneRef,
        maskId: phoneMaskId,
        onBlur: () => {
          setPhoneMaskId(getTelefoneMaskIdByNumero(phoneRef?.current?.value))
        },
        onFocus: () => {
          setPhoneMaskId(MASKFULLLENGTH)
        }
      }}
      inputComponent={TelefoneMask}
      placeholder={placeholder}
    />
  )
}
