import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BlockIcon from '@material-ui/icons/CancelOutlined'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import ColaboradorDadosBasicosDialogPage from 'pages/erp/painel/colaborador/ColaboradorDadosBasicosDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import NativeApp from 'support/components/nativeapp/NativeApp'
import TabContainer from 'support/components/tab/TabContainer'
import TextMultiline from 'support/components/text/TextMultiline'
import TipoContaBancariaEnum from 'support/domain/banking/TipoContaBancariaEnum'
import TipoPessoaEnum from 'support/domain/banking/TipoPessoaEnum'
import ColaboradorCargoEnum from 'support/domain/colaborador/ColaboradorCargoEnum'
import EstadoEnum from 'support/domain/localizacao/EstadoEnum'
import TipoTelefoneEnum from 'support/domain/telefone/TipoTelefoneEnum'
import WhatsAppService from 'support/domain/whatsapp/WhatsAppService'
import { formatarCNPJ, formatarCPF, formatarDataNascimento } from 'support/util/TextFormatter'

const styles = (theme) => ({
  cardContainerfullHeight: {
    ...theme.container.card({ fullHeight: true })
  },
  cardContainer: {
    ...theme.container.card({ fullHeight: false })
  },
  acaoDesabilitada: {
    opacity: 0.4
  },
  checkIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginRight: 10,
    fontSize: 24,
    marginBottom: -1,
    '$acaoDesabilitada &': {
      opacity: 0.5
    }
  },
  tituloDadosCadastrais: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 0,
    [theme.breakpoints.down(600)]: {
      fontSize: 16
    }
  },
  iconeLinkExterno: {
    display: 'block',
    width: 17,
    height: 17
  },
  card: {
    borderRadius: 16
  }
})

class TabPerfil extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.state = {
      ajaxing: false
    }

    this.containerFunctionMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoColaborador', (props) => {
      this.containerFunctionMap.reload()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/colaboradores.buscarPorId',
      params: {
        id: this.props.colaborador.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          colaborador: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({ ajaxing: false })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handlerEditar = () => {
    openBackableDialog(ColaboradorDadosBasicosDialogPage, { idColaborador: this.props.colaborador.id })
  }

  render() {
    const { classes } = this.props
    const { colaborador } = this.state

    const dadosCadastraisContent = []
    let contatos = []
    let cargos = []
    let informacoesPessoais = []
    let meiosRecebimento = []
    let endereco = []

    if (colaborador) {
      if (colaborador.usuario && colaborador.usuario.email) {
        contatos.push(
          <Typography key={colaborador.usuario.email} variant="body2" style={{ wordBreak: 'break-all' }}>
            {colaborador.usuario.email}
          </Typography>
        )
      }

      if (colaborador.meiosRecebimento) {
        let meiosRecebimentoColaborador = colaborador.meiosRecebimento

        meiosRecebimentoColaborador.sort(function (a, b) {
          if (b.tipo.id < a.tipo.id) return -1
          if (b.tipo.id > a.tipo.id) return 1
          return 0
        })

        for (let i = 0; i < meiosRecebimentoColaborador.length; i++) {
          const meioRecebimento = meiosRecebimentoColaborador[i]

          if (meiosRecebimento.length > 0) {
            meiosRecebimento.push(<br key={'br_' + i} />)
          }

          if (meioRecebimento.chavePix) {
            meiosRecebimento.push(
              <Typography key={'pix_' + i} variant="body2" style={{ wordBreak: 'break-all' }}>
                Chave Pix: {meioRecebimento.chavePix.valor}
              </Typography>
            )
          } else {
            if (meioRecebimento.contaBancaria.banco) {
              meiosRecebimento.push(
                <Typography key={'nomeBanco_' + i} variant="body2">
                  {meioRecebimento.contaBancaria.banco.nome}
                </Typography>
              )
            }
            if (meioRecebimento.contaBancaria.agencia) {
              meiosRecebimento.push(
                <Typography key={'nomeAgencia_' + i} variant="body2">
                  Agência: {meioRecebimento.contaBancaria.agencia}
                </Typography>
              )
            }
            const descricaoConta =
              meioRecebimento.contaBancaria.tipo && meioRecebimento.contaBancaria.tipo.id ? TipoContaBancariaEnum.getById(meioRecebimento.contaBancaria.tipo.id).descricao : 'Conta'
            if (meioRecebimento.contaBancaria.conta) {
              meiosRecebimento.push(
                <Typography key={'conta_' + i} variant="body2">
                  {descricaoConta + ': ' + meioRecebimento.contaBancaria.conta}
                  {meioRecebimento.contaBancaria.contaDigito ? '-' + meioRecebimento.contaBancaria.contaDigito : ''}
                </Typography>
              )
            }
            if (meioRecebimento.contaBancaria.nome) {
              const descricaoNome =
                meioRecebimento.contaBancaria.tipoPessoa && meioRecebimento.contaBancaria.tipoPessoa.id === TipoPessoaEnum.PESSOA_JURIDICA.id ? 'Razão Social' : 'Nome'
              meiosRecebimento.push(
                <Typography key={'nomeContaBancaria_' + i} variant="body2">
                  {descricaoNome + ': ' + meioRecebimento.contaBancaria.nome}
                </Typography>
              )
            }
            if (meioRecebimento.contaBancaria.cpf || meioRecebimento.contaBancaria.cnpj) {
              const descricaoDocumento = meioRecebimento.contaBancaria.tipoPessoa.id === TipoPessoaEnum.PESSOA_JURIDICA.id ? 'CNPJ' : 'CPF'
              meiosRecebimento.push(
                <Typography key={'cpfcnpj_' + i} variant="body2">
                  {descricaoDocumento +
                    ': ' +
                    (meioRecebimento.contaBancaria.cpf ? formatarCPF(meioRecebimento.contaBancaria.cpf) : formatarCNPJ(meioRecebimento.contaBancaria.cnpj))}
                </Typography>
              )
            }
          }
        }
      }

      if (colaborador.endereco) {
        let enderecoColaborador = colaborador.endereco
        let logradouro = ''

        if (enderecoColaborador.endereco) {
          logradouro = enderecoColaborador.endereco
        }

        if (enderecoColaborador.numero) {
          logradouro += logradouro && logradouro.length > 0 ? ', ' : 'Número: '
          logradouro += enderecoColaborador.numero
        }
        if (enderecoColaborador.complemento) {
          logradouro += logradouro && logradouro.length > 0 ? ' - ' : 'Complemento: '
          logradouro += enderecoColaborador.complemento
        }

        if (logradouro) {
          endereco.push(
            <Typography key={'log_' + logradouro} variant="body2">
              {logradouro}
            </Typography>
          )
        }

        if (enderecoColaborador.cep) {
          endereco.push(
            <Typography key={'cep_' + enderecoColaborador.cep} variant="body2">
              {'Cep: ' + enderecoColaborador.cep}
            </Typography>
          )
        }

        if (enderecoColaborador.bairro) {
          endereco.push(
            <Typography key={'bairro_' + enderecoColaborador.bairro} variant="body2">
              {'Bairro: ' + enderecoColaborador.bairro}
            </Typography>
          )
        }

        if (enderecoColaborador.cidade) {
          endereco.push(
            <Typography key={'cidade_' + enderecoColaborador.cidade} variant="body2">
              {'Cidade: ' + enderecoColaborador.cidade}
            </Typography>
          )
        }

        if (enderecoColaborador.estado && enderecoColaborador.estado.id) {
          endereco.push(
            <Typography key={'estado_' + enderecoColaborador.estado.id} variant="body2">
              {'Estado: ' + EstadoEnum.getById(enderecoColaborador.estado.id).sigla}
            </Typography>
          )
        }
      }

      if (colaborador.cargos && colaborador.cargos.length > 0) {
        let cargosColaborador = ColaboradorCargoEnum.generateAllItems(colaborador.cargos)

        for (let i = 0; i < cargosColaborador.length; i++) {
          const cargo = cargosColaborador[i]
          if (cargo.exists) {
            cargos.push(
              <Typography key={cargo.descricao} variant="body2">
                {cargo.descricao}
              </Typography>
            )
          }
        }
      }

      if (colaborador.instagram) {
        const urlInstagram = 'http://www.instagram.com.br/' + colaborador.instagram
        const buttonPropsInstagram = {}

        if (DeviceIdentifier.isNativeApp()) {
          buttonPropsInstagram.onClick = () => {
            NativeApp.executeNativeMethod({
              method: 'openLink',
              url: urlInstagram
            })
          }
        } else {
          buttonPropsInstagram.component = 'a'
          buttonPropsInstagram.href = urlInstagram
          buttonPropsInstagram.target = '_blank'
        }

        contatos.push(
          <Grid key={'instagram'} container alignItems="center">
            <Grid item>
              <Typography key={'instagram'} variant="body2" style={{ wordBreak: 'break-all' }}>
                Instagram: {colaborador.instagram}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton size="small" {...buttonPropsInstagram} style={{ marginLeft: 5 }}>
                <OpenInNewIcon className={classes.iconeLinkExterno} />
              </IconButton>
            </Grid>
          </Grid>
        )
      }

      if (colaborador.telefones && colaborador.telefones.length) {
        colaborador.telefones.sort(function (a, b) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })

        for (let i = 0; i < colaborador.telefones.length; i++) {
          const telefone = colaborador.telefones[i]

          let buttonPropsWhatsapp = null

          if (telefone.tipo.id === TipoTelefoneEnum.WHATSAPP.id) {
            buttonPropsWhatsapp = {}

            buttonPropsWhatsapp = WhatsAppService.generateClickToChatLinkProps({
              phone: telefone.codigoDiscagemPais + telefone.numero.replace(/[() -]/g, ''),
              message: ''
            })
            buttonPropsWhatsapp.component = Link
          }

          contatos.push(
            <Grid key={'telefone' + telefone.id} container alignItems="center" style={{ marginTop: 0 }}>
              <Grid item>
                <Typography variant="body2">{TipoTelefoneEnum.getById(telefone.tipo.id).descricao + ': ' + telefone.numero}</Typography>
              </Grid>
              {buttonPropsWhatsapp && (
                <Grid item>
                  <IconButton size="small" {...buttonPropsWhatsapp} style={{ marginLeft: 5 }}>
                    <WhatsAppIcon className={classes.iconeLinkExterno} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )
        }
      }

      if (colaborador.diaNascimento && colaborador.mesNascimento) {
        informacoesPessoais.push(
          <Typography key={formatarDataNascimento(colaborador)} variant="body2">
            Nascimento: {formatarDataNascimento(colaborador)}
          </Typography>
        )
      }

      if (colaborador.rg) {
        informacoesPessoais.push(
          <Typography key={colaborador.rg} variant="body2">
            RG: {colaborador.rg}
          </Typography>
        )
      }

      if (colaborador.orgaoExpedidor) {
        informacoesPessoais.push(
          <Typography key={colaborador.orgaoExpedidor} variant="body2">
            Órgão Expedidor: {colaborador.orgaoExpedidor}
          </Typography>
        )
      }

      if (colaborador.cpf) {
        informacoesPessoais.push(
          <Typography key={formatarCPF(colaborador.cpf)} variant="body2">
            CPF: {formatarCPF(colaborador.cpf)}
          </Typography>
        )
      }

      if (colaborador.informacoesAdicionais) {
        informacoesPessoais.push(
          <Typography key="infAdicionais" variant="body2" style={{ marginTop: dadosCadastraisContent.length === 0 ? 0 : 2 }}>
            <TextMultiline>{colaborador.informacoesAdicionais}</TextMultiline>
          </Typography>
        )
      }
    }

    if (dadosCadastraisContent.length === 0) {
      dadosCadastraisContent.push(
        <Typography key={'informacoesnaopreenchidas'} variant="body2" style={{ marginTop: 4 }}>
          Informações não preenchidos
        </Typography>
      )
    }

    let possuiSegundaColunaGrid = false
    if (meiosRecebimento.length > 0 || endereco.length > 0) {
      possuiSegundaColunaGrid = true
    }

    return (
      <TabContainer>
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.containerFunctionMap}>
          {() => (
            <React.Fragment>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={possuiSegundaColunaGrid ? 6 : 12}>
                  <div className={classes.cardContainerfullHeight}>
                    <Typography key={colaborador.nome} variant="h6">
                      {colaborador.nome}
                    </Typography>
                    {colaborador.nome !== colaborador.apelido && (
                      <Typography key={colaborador.apelido} variant="body2" color="textSecondary">
                        <b>{colaborador.apelido}</b>
                      </Typography>
                    )}
                    <Typography variant="body2" style={{ marginTop: 12, marginBottom: 3 }} className={colaborador.realizaAtendimentos ? '' : classes.acaoDesabilitada}>
                      {colaborador.realizaAtendimentos ? <CheckIcon className={classes.checkIcon} /> : <BlockIcon className={classes.checkIcon} />}Executa Atendimentos
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: 3 }} className={colaborador.vendeProdutos ? '' : classes.acaoDesabilitada}>
                      {colaborador.vendeProdutos ? <CheckIcon className={classes.checkIcon} /> : <BlockIcon className={classes.checkIcon} />}Vende Produtos
                    </Typography>
                    <Typography variant="body2" className={colaborador.vendePacotes ? '' : classes.acaoDesabilitada}>
                      {colaborador.vendePacotes ? <CheckIcon className={classes.checkIcon} /> : <BlockIcon className={classes.checkIcon} />}Vende Pacotes
                    </Typography>
                    {contatos.length > 0 && (
                      <React.Fragment>
                        <Typography variant="body2" component="p">
                          <br />
                          <strong>{'Contato' + (contatos.length > 1 ? 's' : '')}</strong>
                        </Typography>
                        {contatos}
                      </React.Fragment>
                    )}
                    {cargos.length > 0 && (
                      <React.Fragment>
                        <Typography variant="body2" component="p">
                          <br />
                          <strong>{'Cargo' + (cargos.length > 1 ? 's' : '')}</strong>
                        </Typography>
                        {cargos}
                      </React.Fragment>
                    )}
                    {informacoesPessoais.length > 0 && (
                      <React.Fragment>
                        <Typography variant="body2" component="p">
                          <br />
                          <strong>Informações Pessoais</strong>
                        </Typography>
                        {informacoesPessoais}
                      </React.Fragment>
                    )}
                  </div>
                </Grid>
                {possuiSegundaColunaGrid && (
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1} direction="column">
                      {meiosRecebimento.length > 0 && (
                        <Grid item xs style={{ width: '100%' }}>
                          <div className={classes.cardContainer}>
                            <Typography variant="body2">
                              <strong>Dados Bancários</strong>
                            </Typography>
                            {meiosRecebimento}
                          </div>
                        </Grid>
                      )}
                      {endereco.length > 0 && (
                        <Grid item xs>
                          <div className={classes.cardContainer}>
                            <Typography variant="body2" component="p">
                              <strong>Endereço</strong>
                            </Typography>
                            {endereco}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
        </ContentWithPreload>
      </TabContainer>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabPerfil.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabPerfil)
