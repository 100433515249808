import { isMobile, isMobileSafari, isSafari } from 'react-device-detect'

import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import NativeApp from 'support/components/nativeapp/NativeApp'

function generateClickToChatLinkProps(params) {
  const phone = params.phone.replace(/[^0-9]/g, '')
  const message = encodeURIComponent(params.message)
  const onClickFunction = params.onClick

  const props = {}

  if (DeviceIdentifier.isNativeApp()) {
    props.onClick = () => {
      let urlPrefix = 'https://api.whatsapp.com/send?'
      if (DeviceIdentifier.isNativeAndroid()) {
        urlPrefix = 'whatsapp://send?'
      }
      NativeApp.executeNativeMethod({
        method: 'openLink',
        url: urlPrefix + 'phone=' + phone + '&text=' + message
      })
      if (onClickFunction) {
        onClickFunction()
      }
    }
  } else if (isMobile && !isSafari && !isMobileSafari) {
    props.href = 'whatsapp://send?phone=' + phone + '&text=' + message
    props.target = 'envioLembrete'
    props.onClick = () => {
      if (onClickFunction) {
        onClickFunction()
      }
    }
  } else {
    props.href = 'https://api.whatsapp.com/send?phone=' + phone + '&text=' + message
    props.target = 'envioLembrete'
    props.onClick = () => {
      if (onClickFunction) {
        onClickFunction()
      }
    }
  }

  return props
}

const functionsObject = {
  generateClickToChatLinkProps
}

export default functionsObject
