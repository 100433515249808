import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  ANTECEDENCIA_2: { id: 2, descricao: ' até 2 dias' },
  ANTECEDENCIA_3: { id: 3, descricao: ' até 3 dias' },
  ANTECEDENCIA_4: { id: 4, descricao: ' até 4 dias' },
  ANTECEDENCIA_5: { id: 5, descricao: ' até 5 dias' },
  ANTECEDENCIA_7: { id: 7, descricao: ' até 7 dias' },
  ANTECEDENCIA_15: { id: 15, descricao: 'até 15 dias' },
  ANTECEDENCIA_30: { id: 30, descricao: 'até 1 mês' },
  ANTECEDENCIA_60: { id: 60, descricao: 'até 2 meses' },
  ANTECEDENCIA_90: { id: 90, descricao: 'até 3 meses' },
  ANTECEDENCIA_120: { id: 120, descricao: 'até 4 meses' },
  ANTECEDENCIA_180: { id: 180, descricao: 'até 6 meses' },
  ANTECEDENCIA_365: { id: 365, descricao: 'até 1 ano' },
  ANTECEDENCIA_730: { id: 730, descricao: 'até 2 anos' },
  ANTECEDENCIA_1095: { id: 1095, descricao: 'até 3 anos' }
})
export default enumObject
