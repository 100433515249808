import { capitalizeFirstLetter } from 'support/util/StringUtil'

type GetByFunctions<T> = { [K in `getBy${Capitalize<string & keyof T>}`]: (value: T[keyof T]) => T | null }

type CreateEnumReturnType<T> = {
  [K in keyof T]: T[K]
} & { values(): T[keyof T][] }

export function createEnum<T extends Record<string, Record<string, unknown>>>(data?: T) {
  if (data === undefined) {
    throw new Error('createEnum: data is required')
  }

  const enumWithValuesFunction = { ...data } as CreateEnumReturnType<T>

  enumWithValuesFunction.values = () => (Object.keys(data) as (keyof T)[]).map((key) => data[key])

  const firstEnumItem = enumWithValuesFunction.values()[0]

  const getters = {} as GetByFunctions<typeof firstEnumItem>

  for (const key in firstEnumItem) {
    const capitalizedKey = `getBy${capitalizeFirstLetter(key)}` as keyof typeof getters

    getters[capitalizedKey] = (value) => {
      for (const enumItem of enumWithValuesFunction.values()) {
        if (enumItem[key] === value) {
          return enumItem
        }
      }
      return null
    }
  }

  return { ...enumWithValuesFunction, ...getters }
}
