import { makeStyles, Theme } from '@material-ui/core/styles'

const borderRadius = 5
const urlContainerHeight = 32

const floraV2Tokens = {
  nonIteractiveColor: '#CFCED3',
  actionableColor: '#264FEC',
  hover: '#002EC9'
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  root: {
    borderRadius: borderRadius,
    height: '236px',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
      margin: 0,
      width: '100%'
    }
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: 32,
    borderRadius: borderRadius,
    overflow: 'hidden',
    gap: 16,
    [theme.breakpoints.down('xs')]: {
      padding: 28
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  copyContainer: {
    display: 'flex',
    gap: 8
  },
  urlContainer: {
    border: '1px solid',
    borderColor: floraV2Tokens.nonIteractiveColor,
    borderRadius: 3,
    minWidth: 188,
    height: urlContainerHeight,
    justifyContent: 'center',
    alignItems: 'center'
  },
  urlLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  copyButton: {
    height: urlContainerHeight,
    textTransform: 'none',
    fontSize: 14,
    lineHeight: 20,
    backgroundColor: floraV2Tokens.actionableColor,
    color: 'white',
    '&:hover': {
      backgroundColor: floraV2Tokens.hover
    }
  },
  shareButton: {
    height: urlContainerHeight,
    textTransform: 'none',
    fontSize: 14,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: floraV2Tokens.actionableColor,
    color: floraV2Tokens.actionableColor,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: floraV2Tokens.actionableColor,
      color: 'white'
    }
  },
  iconButton: {
    padding: 0
  }
}))
