import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import BaseDialog from 'support/components/dialog/BaseDialog'

const styles = (theme) => ({})

class ReplicarAlteracoesServicosFuturosDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      alterarAtendimentosFuturos: true
    }

    this.inputAlterarAtendimentosFuturos = {}
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { alterarAtendimentosFuturos } = this.state
    const { quantidadeAtendimentos } = this.props
    let texto = ''

    texto = quantidadeAtendimentos === 1 ? 'Existe um atendimento futuro ' : 'Existem ' + quantidadeAtendimentos + ' atendimentos futuros '

    const content = (
      <React.Fragment>
        <DialogContentText>Atenção : {texto} com esse serviço.</DialogContentText>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              disabled={false}
              checked={alterarAtendimentosFuturos}
              onChange={(event) => {
                this.setState({ alterarAtendimentosFuturos: event.target.checked })
              }}
            />
          }
          label={<Typography variant="body2">Alterar preço dos atendimentos futuros</Typography>}
        />
      </React.Fragment>
    )

    const actions = (
      <Button
        color="primary"
        disabled={this.state.ajaxing}
        onClick={() => {
          this.props.handleCloseDialog({
            onClosedCallback: () => {
              this.props.clickAction(this.state.alterarAtendimentosFuturos)
            }
          })
        }}
      >
        Salvar
      </Button>
    )

    return (
      <BaseDialog {...dialogProps} maxWidth={510} hasCloseButton={true} actions={actions} title="Alterar Serviço">
        {content}
      </BaseDialog>
    )
  }
}

ReplicarAlteracoesServicosFuturosDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ReplicarAlteracoesServicosFuturosDialog)
