import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import DiaSemanaEnum from 'support/domain/datetime/DiaSemanaEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'

const styles = (theme) => ({
  containerHeader: {
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    marginBottom: 12,
    '& table': {
      width: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse'
    },
    '& table tr td': {
      textAlign: 'center',
      verticalAlign: 'top',
      fontSize: 12,
      fontWeight: 500
    }
  },
  containerBody: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    '& table': {
      width: '100%',
      minHeight: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse'
    },
    '& table tr td': {
      padding: 1,
      textAlign: 'center',
      verticalAlign: 'center'
    }
  },
  dia: {
    width: 32,
    cursor: 'pointer',
    height: 32,
    lineHeight: '33px',
    borderRadius: 34,
    fontSize: 14,
    marginLeft: 'auto',
    marginRight: 'auto',
    '&$diaSelecionado': {
      background: '#444',
      color: 'white'
    },
    '&:not($diaSelecionado):hover': {
      backgroundColor: '#f4f4f4'
    },
    '&$diaDesabilitado': {
      pointerEvents: 'none',
      textDecoration: 'line-through'
    }
  },
  diaSelecionado: {},
  diaDesabilitado: {}
})

class CalendarioSimples extends Component {
  constructor(props) {
    super(props)
    this.diasSemana = JSON.parse(JSON.stringify(DiaSemanaEnum.values()))
    this.diasSemana.sort(function (a, b) {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0
    })
    this.dataReferencia = this.props.data.clone()
    this.gerarDadosCalendario()
  }

  gerarDadosCalendario = () => {
    const primeiroDiaMes = this.dataReferencia.clone().startOf('month')
    const primeiroDiaMesAsInt = converterMomentParaDataInt(primeiroDiaMes)
    const dataBase = primeiroDiaMes.clone().subtract(parseInt(primeiroDiaMes.format('d'), 10), 'days')
    const ultimoDiaMes = this.dataReferencia.clone().endOf('month')
    const ultimoDiaMesAsInt = converterMomentParaDataInt(ultimoDiaMes)
    const semanas = []
    const hoje = moment()

    while (true) {
      const semana = []
      for (let i = 0; i <= 6; i++) {
        let dataAsInt = converterMomentParaDataInt(dataBase.clone())

        let isHoje = false
        if (hoje.isSame(dataBase, 'day')) {
          isHoje = true
        }

        let dentroMes = true
        if (dataAsInt < primeiroDiaMesAsInt || dataAsInt > ultimoDiaMesAsInt) {
          dentroMes = false
        }
        semana.push({
          dataAsMoment: dataBase.clone(),
          dataAsInt: dataAsInt,
          isHoje: isHoje,
          dentroMes: dentroMes,
          descricaoSemana: dataBase.format('ddd'),
          descricaoDia: dataBase.format('DD')
        })

        dataBase.add(1, 'days')
      }
      semanas.push(semana)
      if (dataBase.isAfter(ultimoDiaMes, 'day')) {
        break
      }
    }
    this.dadosCalendario = {
      primeiroDiaMesAsInt: primeiroDiaMesAsInt,
      ultimoDiaMesAsInt: ultimoDiaMesAsInt,
      semanas: semanas
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (!this.props.data.isSame(nextProps.data, 'day')) {
      this.dataReferencia = nextProps.data.clone()
      this.gerarDadosCalendario()
    }
    return true
  }

  render() {
    const { classes, data, dataInicial, dataFinal } = this.props

    return (
      <div style={{ border: '1px solid #ececec', padding: '12px 12px 20px 12px', borderRadius: 6 }}>
        <Grid container alignItems="center" style={{ marginTop: 0, marginBottom: 8 }}>
          <Grid item xs></Grid>
          <Grid item style={{ marginLeft: -8 }}>
            <IconButton
              onClick={() => {
                this.dataReferencia.add(-1, 'months')
                this.gerarDadosCalendario()
                this.forceUpdate()
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
          <Grid item style={{ marginRight: 8, marginLeft: 8 }}>
            <Typography variant="body1" style={{ fontSize: 18, fontWeight: 500 }}>
              {this.dataReferencia.format('MMMM, YYYY')}
            </Typography>
          </Grid>

          <Grid item style={{ marginRight: -8 }}>
            <IconButton
              onClick={() => {
                this.dataReferencia.add(1, 'months')
                this.gerarDadosCalendario()
                this.forceUpdate()
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Grid>
          <Grid item xs></Grid>
        </Grid>

        <Grid container alignItems="center" justisfy="center" atyle={{}}>
          <Grid item xs>
            <div style={{ maxxWidth: 276, marginTop: 8, mmaxWidth: 450, marginLeft: 'auto', marginRight: 'auto' }}>
              <div className={classes.containerHeader}>
                <table cellPadding={0} cellSpacing={0}>
                  <tbody>
                    <tr>
                      {this.diasSemana.map((diaSemana) => (
                        <td key={diaSemana.id}>{diaSemana.nomeResumido}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={classes.containerBody}>
                <table cellPadding={0} cellSpacing={0}>
                  <tbody>
                    {this.dadosCalendario.semanas.map((semana, index1) => (
                      <tr key={index1}>
                        {semana.map((dia, index2) => {
                          let opacityBody = 1
                          if (!dia.dentroMes) {
                            opacityBody = 0.27
                          }
                          let className = [classes.dia]
                          if (dataInicial && dataInicial.isAfter(dia.dataAsMoment, 'day')) {
                            className.push(classes.diaDesabilitado)
                          }
                          if (dataFinal && dataFinal.isBefore(dia.dataAsMoment, 'day')) {
                            className.push(classes.diaDesabilitado)
                          }
                          if (dia.dataAsMoment.isSame(data, 'day')) {
                            className.push(classes.diaSelecionado)
                          }

                          return (
                            <td key={index2} style={{ opacity: opacityBody }}>
                              <div
                                className={classNames(className)}
                                onClick={() => {
                                  this.props.onChange({ data: dia.dataAsMoment })
                                }}
                              >
                                {dia.descricaoDia}
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

CalendarioSimples.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CalendarioSimples)
