import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import TipoRespostaEnum from 'support/domain/fichacliente/TipoRespostaEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class FichaPerguntaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: 'Pergunta',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      tipoResposta: TipoRespostaEnum.TEXTO_LIVRE,
      alternativas: []
    }

    this.inputPergunta = React.createRef()
    this.keyGenerator = 0
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (this.props.pergunta) {
      const alternativas = this.props.pergunta.alternativas

      if (alternativas) {
        alternativas.forEach((alternativa, indexAlternativa) => {
          alternativa.key = this.generateKeyId()
          alternativa.inputAlternativa = React.createRef()
        })
      }

      if (alternativas && alternativas.length > 0) {
        this.ordenarAlternativas(alternativas)
      }

      this.setState({
        descricao: this.props.pergunta.descricao,
        alternativas: alternativas,
        tipoResposta: this.props.pergunta.tipoResposta
      })
    }

    this.registrarPaginaCarregada()
    notifyContentLoaded()
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  adicionarPerguntaAlternativa = () => {
    const alternativas = this.state.alternativas.slice()

    let alternativaPergunta = {
      key: this.generateKeyId(),
      inputAlternativa: React.createRef(),
      posicao: alternativas.length + 1
    }

    alternativas.push(alternativaPergunta)
    this.setState({ alternativas: alternativas })
  }

  removerPerguntaAlternativa = (index) => {
    const alternativas = this.state.alternativas.slice()
    alternativas.splice(index, 1)
    if (alternativas.length === 0) {
      alternativas.push({})
    } else {
      alternativas.forEach((alternativa, indexAlternativa) => {
        if (indexAlternativa >= index) {
          alternativa.posicao--
        }
      })
    }

    this.setState({ alternativas: alternativas })
  }

  moverCimaPerguntaAlternativa = (index) => {
    const alternativas = this.state.alternativas.slice()
    const alternativaAlterior = alternativas[index - 1]
    const alternativaAtual = alternativas[index]
    alternativaAlterior.posicao++
    alternativaAtual.posicao--
    alternativas.splice(index - 1, 1, alternativaAtual)
    alternativas.splice(index, 1, alternativaAlterior)

    this.setState({ alternativas: alternativas })
  }

  moverBaixoPerguntaAlternativa = (index) => {
    const alternativas = this.state.alternativas.slice()
    const proximaAlternativa = alternativas[index + 1]
    const alternativaAtual = alternativas[index]
    proximaAlternativa.posicao--
    alternativaAtual.posicao++
    alternativas.splice(index + 1, 1, alternativaAtual)
    alternativas.splice(index, 1, proximaAlternativa)

    this.setState({ alternativas: alternativas })
  }

  generateKeyId = () => {
    return ++this.keyGenerator
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/fichascliente.validarPergunta',
      data: { pergunta: this.getDadosPergunta() },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.adicionarPergunta(this.getDadosPergunta())
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formModelo')
      }
    })
  }

  getKeyErrorsMapPrefix(index) {
    return 'alternativas[' + index + ']'
  }

  ordenarAlternativas = (alternativas) => {
    if (!alternativas) {
      return alternativas
    }

    alternativas.sort((i1, i2) => {
      const alternativa1 = i1.posicao
      const alternativa2 = i2.posicao

      if (alternativa1 > alternativa2) {
        return 1
      } else if (alternativa1 < alternativa2) {
        return -1
      } else {
        return 0
      }
    })
  }

  getDadosPergunta = () => {
    const dados = {}

    if (this.props.pergunta) {
      dados.id = this.props.pergunta.id
    }

    dados.descricao = this.inputPergunta.current.value
    dados.tipoResposta = this.state.tipoResposta
    dados.posicao = this.props.posicao

    if (dados.tipoResposta.id !== TipoRespostaEnum.TEXTO_LIVRE.id) {
      dados.alternativas = []

      for (let alternativa of this.state.alternativas) {
        const perguntaParaSalvar = {}
        if (alternativa.id) {
          perguntaParaSalvar.id = alternativa.id
        }
        if (alternativa.inputAlternativa.current) {
          perguntaParaSalvar.descricao = alternativa.inputAlternativa.current.value
        }
        perguntaParaSalvar.posicao = alternativa.posicao
        dados.alternativas.push(perguntaParaSalvar)
      }
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, descricao, tipoResposta, alternativas } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          <form id="formModelo" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <InputText
              id="xpergunta"
              name="xpergunta"
              customVariant={'outlined-small'}
              shrink={false}
              label="Descrição da pergunta"
              defaultValue={descricao}
              errorMessage={errorsMap('pergunta')}
              inputProps={{
                maxLength: 4000
              }}
              inputRef={this.inputPergunta}
            />

            <InputSelect
              id="xtipoResposta"
              name="xtipoResposta"
              customVariant={'outlined-small'}
              shrink={false}
              label="Tipo da resposta"
              disabled={this.state.ajaxing}
              marginTop={true}
              errorMessage={errorsMap('tipoResposta')}
              value={tipoResposta.id}
              onChange={(event) => {
                const tipoResposta = TipoRespostaEnum.getById(parseInt(event.target.value, 10))
                const alternativas = [
                  {
                    key: this.generateKeyId(),
                    inputAlternativa: React.createRef(),
                    posicao: 1
                  }
                ]

                this.setState({
                  tipoResposta: tipoResposta,
                  alternativas: alternativas
                })
              }}
            >
              {TipoRespostaEnum.values().map((tipoResposta) => (
                <MenuItem key={tipoResposta.id} value={tipoResposta.id}>
                  {tipoResposta.descricao}
                </MenuItem>
              ))}
            </InputSelect>

            {tipoResposta.id && (tipoResposta.id === TipoRespostaEnum.UNICA_ESCOLHA.id || tipoResposta.id === TipoRespostaEnum.MULTIPLA_ESCOLHA.id) && (
              <SectionSeparator icon={<FormatListBulletedIcon />} title="Alternativas" />
            )}

            {tipoResposta.id &&
              (tipoResposta.id === TipoRespostaEnum.UNICA_ESCOLHA.id || tipoResposta.id === TipoRespostaEnum.MULTIPLA_ESCOLHA.id) &&
              alternativas.map((alternativa, index) => {
                let errorsMapDescricao = errorsMap(this.getKeyErrorsMapPrefix(index) + '.descricao')

                return (
                  <Grid style={{ marginTop: index > 0 ? 8 : 0 }} container direction="row" justify="center" flex-start="flex-start" key={alternativa.key}>
                    <Grid item xs>
                      <InputText
                        id="xalternativa"
                        name="xalternativa"
                        customVariant={'outlined-small'}
                        shrink={false}
                        multiline={true}
                        placeholder={'Alternativa ' + alternativa.posicao}
                        defaultValue={alternativa.descricao}
                        inputRef={alternativa.inputAlternativa}
                        inputProps={{
                          maxLength: 1000
                        }}
                        errorMessage={errorsMapDescricao}
                      />
                    </Grid>

                    <Grid item>
                      <IconButton
                        style={{ padding: 4, marginTop: 6 }}
                        disabled={(alternativas.length === 1 && (!alternativa || !alternativa.nome)) || alternativa.posicao === 1}
                        onClick={() => {
                          this.moverCimaPerguntaAlternativa(index)
                        }}
                      >
                        <KeyboardArrowUpIcon />
                      </IconButton>
                    </Grid>

                    <Grid item>
                      <IconButton
                        style={{ padding: 4, marginTop: 6 }}
                        disabled={(alternativas.length === 1 && (!alternativa || !alternativa.nome)) || alternativa.posicao === alternativas.length}
                        onClick={() => {
                          this.moverBaixoPerguntaAlternativa(index)
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </Grid>

                    <Grid item>
                      <IconButton
                        style={{ padding: 4, marginTop: 6, marginRight: -8 }}
                        disabled={alternativas.length === 1}
                        onClick={() => {
                          this.removerPerguntaAlternativa(index)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}

            {tipoResposta.id && (tipoResposta.id === TipoRespostaEnum.UNICA_ESCOLHA.id || tipoResposta.id === TipoRespostaEnum.MULTIPLA_ESCOLHA.id) && (
              <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Button color="secondary" type="button" onClick={this.adicionarPerguntaAlternativa}>
                  Adicionar mais itens
                </Button>
              </div>
            )}

            <HiddenSubmitButton />
          </form>
        </ContentWithPreload>

        {this.state.paginaCarregada && (
          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
              Cancelar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
              {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
            </Button>
          </div>
        )}
      </DialogPage>
    )
  }
}

FichaPerguntaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FichaPerguntaDialogPage)
