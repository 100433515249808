import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FichaClienteDialogPage from 'pages/erp/painel/fichacliente/FichaClienteDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import DialogSelect from 'support/components/dialogselect/DialogSelect'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SimpleExpansionPanel from 'support/components/expansionpanel/SimpleExpansionPanel'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import TextMultiline from 'support/components/text/TextMultiline'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { prepararPerguntas } from 'support/domain/fichacliente/FichaClienteService'
import TipoRespostaEnum from 'support/domain/fichacliente/TipoRespostaEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class TabFichaCliente extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {}
    this.containerFunctionMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoFichaCliente', (props) => {
      if (props.action === 'create' || props.action === 'remove') {
        this.containerFunctionMap.refresh()
      } else if (props.action === 'update') {
        for (let fichaCliente of this.state.fichasCliente) {
          if (fichaCliente.id === props.fichaCliente.id && fichaCliente.loaderFunctionsMap.refresh) {
            fichaCliente.loaderFunctionsMap.refresh()
            break
          }
        }
      }
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/fichascliente.buscarFichasCliente',
      params: {
        idCliente: this.props.cliente.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const fichasCliente = response.data

        for (let fichaCliente of fichasCliente) {
          fichaCliente.loaderFunctionsMap = {}
        }

        this.setState({ fichasCliente: fichasCliente })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handlerEditDialog = (ficha) => {
    openBackableDialog(FichaClienteDialogPage, {
      idFichaCliente: ficha.id
    })
  }

  handlerRemover = (fichaCliente) => {
    if (!verificarAutorizacao([PA.PODE_PERSISTIR_FICHAS_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Ficha',
      text: (
        <span>
          Você tem certeza que deseja remover a ficha <b>{fichaCliente.ficha.nome}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/fichascliente.removerFichaCliente',
        contextoUsuario: 'erp',
        data: {
          id: fichaCliente.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoFichaCliente', {
            action: 'remove'
          })
        }
      }
    })
  }

  carregarFichaCliente = (notifyContentLoaded, notifyContentNotLoaded, fichaCliente) => {
    getAPI({
      url: 'erp/fichascliente.buscarFichaClientePorId',
      params: {
        id: fichaCliente.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const fichaClienteOrganizada = prepararPerguntas(response.data)
        fichaCliente.perguntas = fichaClienteOrganizada.perguntas
        fichaCliente.perguntasRespondidas = fichaCliente.perguntas.filter((itemPergunta) => itemPergunta.resposta.valor && itemPergunta.resposta.valor.length > 0)
        const fichasCliente = this.state.fichasCliente
        this.setState({ fichasCliente: fichasCliente })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handlerOpenChooseDialog = () => {
    if (!verificarAutorizacao([PA.PODE_PERSISTIR_FICHAS_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(DialogSelect, {
      inputSearchPlaceholder: 'Selecione uma ficha...',
      endpoint: 'erp/fichascliente.buscarFichas',
      searchParamName: 'nome',
      generateItemData: (ficha) => {
        return {
          primary: ficha.nome,
          ficha: ficha
        }
      },
      onSelect: (item) => {
        const dados = {}
        dados.idCliente = this.props.cliente.id
        dados.fichaCliente = {}
        dados.fichaCliente.ficha = { id: item.ficha.id }
        dados.fichaCliente.respostas = []

        postAPI({
          url: 'erp/fichascliente.persistirFichaCliente',
          data: dados,
          requerAutorizacao: true,
          onSuccess: (response) => {
            EventsManager.pub('ManipulacaoFichaCliente', {
              action: 'create'
            })
            openBackableDialog(FichaClienteDialogPage, {
              idFichaCliente: response.data.id,
              idCliente: this.props.cliente.id
            })
          },
          onError: (response) => {
            this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
            FormUtil.focusFirstElementWithError('formFicha')
          }
        })
      },
      emptyListProps: {
        padding: true,
        text: (
          <span>
            Nenhuma ficha pré-cadastrada foi encontrada. Entre nas <b>Configurações</b> do sistema e cadastre uma Ficha de Cliente.
          </span>
        )
      }
    })
  }

  render() {
    const { fichasCliente } = this.state

    return (
      <TabContainer>
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.containerFunctionMap}>
          {() => (
            <React.Fragment>
              <TabContainerPersistHeader
                text={fichasCliente && fichasCliente.length > 0 ? 'Lista de fichas preenchidas' : 'Nenhuma ficha foi adicionada.'}
                buttonsContent={
                  <Button color="secondary" variant="text" style={{ marginRight: -8, marginLeft: -8 }} onClick={this.handlerOpenChooseDialog}>
                    <AddIcon />
                    <span style={{ marginLeft: 8, marginRight: 0 }}>ADICIONAR</span>
                  </Button>
                }
              />
              {fichasCliente &&
                fichasCliente.map((fichaCliente, indice) => {
                  return (
                    <SimpleExpansionPanel
                      title={(props) => {
                        if (props.expanded) {
                          return (
                            <Typography variant="body1">
                              <b>{fichaCliente.ficha.nome}</b>
                            </Typography>
                          )
                        } else {
                          return <Typography variant="body1">{fichaCliente.ficha.nome}</Typography>
                        }
                      }}
                      subtitle={'Data Criação: ' + converterDataIntParaMoment(fichaCliente.dataCriacao).format('DD/MMM/YYYY')}
                      key={'sep_' + indice}
                      content={() => (
                        <ContentWithPreload
                          loadFunction={(notifyContentLoaded, notifyContentNotLoaded) => {
                            this.carregarFichaCliente(notifyContentLoaded, notifyContentNotLoaded, fichaCliente)
                          }}
                          functionsMap={fichaCliente.loaderFunctionsMap}
                        >
                          {() => (
                            <div>
                              <Grid container direction="row" justify="flex-end" style={{ marginTop: -12 }}>
                                <Grid item>
                                  <IconButton
                                    style={{ padding: 8 }}
                                    onClick={(event) => {
                                      this.handlerEditDialog(fichaCliente)
                                    }}
                                  >
                                    <EditIcon fontSize="small" style={{ color: '#888' }} />
                                  </IconButton>
                                </Grid>
                                <Grid item style={{ marginRight: -5, marginLeft: -2 }}>
                                  <IconButton
                                    style={{ padding: 8 }}
                                    onClick={(event) => {
                                      this.handlerRemover(fichaCliente)
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" style={{ color: '#888' }} />
                                  </IconButton>
                                </Grid>
                              </Grid>

                              <div style={{ marginLeft: 0 }}>
                                {(!fichaCliente.perguntasRespondidas || fichaCliente.perguntasRespondidas.length === 0) && (
                                  <Typography variant="body2">Nenhum pergunta foi respondida</Typography>
                                )}
                                {fichaCliente.perguntasRespondidas &&
                                  fichaCliente.perguntasRespondidas.length > 0 &&
                                  fichaCliente.perguntas
                                    .filter((itemPergunta) => itemPergunta.resposta.valor && itemPergunta.resposta.valor.length > 0)
                                    .map((itemPergunta, i) => {
                                      const pergunta = itemPergunta.descricao
                                      let respostas = []

                                      if (itemPergunta.tipoResposta.id === TipoRespostaEnum.TEXTO_LIVRE.id) {
                                        respostas.push(itemPergunta.resposta.valor)
                                      } else {
                                        for (let alternativa of itemPergunta.alternativas) {
                                          const valoresResposta = JSON.parse('[' + itemPergunta.resposta.valor + ']')
                                          for (let valor of valoresResposta) {
                                            if (alternativa.id === parseInt(valor)) {
                                              respostas.push(alternativa.descricao)
                                            }
                                          }
                                        }
                                      }

                                      return (
                                        <div style={{ marginTop: i > 0 ? 16 : 0 }} key={'dp' + itemPergunta.id}>
                                          <Typography variant="body1">{pergunta}</Typography>
                                          {respostas.map((resposta, index) => {
                                            return (
                                              <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body2" color="textSecondary" key={'dr_' + index}>
                                                <TextMultiline>{resposta}</TextMultiline>
                                              </Typography>
                                            )
                                          })}
                                        </div>
                                      )
                                    })}
                              </div>
                            </div>
                          )}
                        </ContentWithPreload>
                      )}
                    ></SimpleExpansionPanel>
                  )
                })}
            </React.Fragment>
          )}
        </ContentWithPreload>
      </TabContainer>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabFichaCliente.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabFichaCliente)
