import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'

function isComissao(remuneracao) {
  if (TipoRemuneracaoEnum.COMISSAO.id === remuneracao.tipo.id) {
    return true
  }
  return false
}

function isGorjeta(remuneracao) {
  if (TipoRemuneracaoEnum.GORJETA.id === remuneracao.tipo.id) {
    return true
  }
  return false
}

function isSalario(remuneracao) {
  if (TipoRemuneracaoEnum.SALARIO.id === remuneracao.tipo.id) {
    return true
  }
  return false
}

function isBonificacao(remuneracao) {
  if (TipoRemuneracaoEnum.BONIFICACAO.id === remuneracao.tipo.id) {
    return true
  }
  return false
}

function getDescricao(remuneracao) {
  return TipoRemuneracaoEnum.getById(remuneracao.tipo.id).descricao
}

function getObservacao(remuneracao) {
  if (isSalario(remuneracao)) {
    return remuneracao.salario.observacao
  } else if (isBonificacao(remuneracao)) {
    return remuneracao.bonificacao.observacao
  }
}

function getValor(remuneracao) {
  if (isSalario(remuneracao)) {
    return remuneracao.salario.valor
  } else if (isBonificacao(remuneracao)) {
    return remuneracao.bonificacao.valor
  }
}

const functionsObject = {
  isComissao: isComissao,
  isGorjeta: isGorjeta,
  isSalario: isSalario,
  isBonificacao: isBonificacao,
  getDescricao: getDescricao,
  getValor: getValor,
  getObservacao: getObservacao
}

export default functionsObject
