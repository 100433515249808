import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  DIA: { id: 1, descricao: 'Visão Diária' },
  SEMANA: { id: 2, descricao: 'Visão Semanal' },
  MES: { id: 3, descricao: 'Visão Mensal' },
  ANO: { id: 4, descricao: 'Visão Anual' }
})

export default enumObject
