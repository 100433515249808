import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import BaseDialog from 'support/components/dialog/BaseDialog'

const styles = (theme) => ({})

class MessageDialog extends Component {
  render() {
    const { text, buttonLabel, onClick, actionButtons, ...others } = this.props

    let textContent = null
    if (typeof text === 'string' || (text && text.type && text.type === 'span')) {
      textContent = <DialogContentText>{text}</DialogContentText>
    } else {
      textContent = text
    }

    let actionsContent = null

    if (actionButtons) {
      actionsContent = actionButtons.map((actionButton, index) => {
        let onClickFunction = null
        if (actionButton.onClickFunction) {
          onClickFunction = () => {
            actionButton.onClickFunction(this)
          }
        } else if (actionButton.onClosedCallbackFunction) {
          onClickFunction = (event) => {
            event.stopPropagation()
            this.props.handleCloseDialog({ onClosedCallback: actionButton.onClosedCallbackFunction })
          }
        } else {
          onClickFunction = this.props.handleCloseDialog
        }
        return (
          <Button key={index} onClick={onClickFunction} href={actionButton.href} color="secondary">
            {actionButton.label}
          </Button>
        )
      })
    } else {
      let onClickFunction = null
      if (onClick) {
        onClickFunction = onClick
      } else {
        onClickFunction = this.props.handleCloseDialog
      }
      actionsContent = (
        <Button onClick={onClickFunction} color="secondary">
          {buttonLabel ? buttonLabel : 'OK'}
        </Button>
      )
    }

    return (
      <BaseDialog actions={actionsContent} {...others}>
        {textContent}
      </BaseDialog>
    )
  }
}

MessageDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MessageDialog)
