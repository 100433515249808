import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'
import moment from 'moment'
import CotaParteDialogPage from 'pages/erp/painel/notafiscal/CotaParteDialogPage'
import NfsPesquisaAvancadaDialog from 'pages/erp/painel/notafiscal/NfsPesquisaAvancadaDialog'
import NfsResponsiveTable from 'pages/erp/painel/notafiscal/table/NfsResponsiveTable'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import GerenciamentoFaturaNotasFiscaisDialogPage from 'pages/erp/painel/venda/GerenciamentoFaturaNotasFiscaisDialogPage'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import NativeApp from 'support/components/nativeapp/NativeApp'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import StatusApresentacaoNfEnum from 'support/domain/venda/StatusApresentacaoNfEnum'
import { converterDateParaDataInt } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

export const StatusFiltroEnum = createEnum({
  TODAS: { id: 0, descricaoFiltro: 'Todas' },
  EMITINDO: StatusApresentacaoNfEnum.EMITINDO,
  EMITIDA: StatusApresentacaoNfEnum.EMITIDA,
  COM_ERRO: StatusApresentacaoNfEnum.COM_ERRO,
  CANCELANDO: StatusApresentacaoNfEnum.CANCELANDO,
  CANCELADA: StatusApresentacaoNfEnum.CANCELADA
})

const styles = (theme) => ({})

class NfsPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      title: 'Notas Fiscais',
      tipo: this.colaboradorLocalPreferences.getPreference('NfsPanelPageFiltro_Tipo', null),
      status: this.colaboradorLocalPreferences.getPreference('NfsPanelPageFiltro_Status', StatusFiltroEnum.TODAS),
      ajaxing: false
    }

    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoNf'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  abrirPesquisaAvancadaDialog = () => {
    openBackableDialog(NfsPesquisaAvancadaDialog, {
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal,
      setParentState: (state) => {
        this.setState(state)
      }
    })
  }

  abrirCotaParteDialog = () => {
    openBackableDialog(CotaParteDialogPage, {
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal
    })
  }

  getRequestParameters = () => {
    return {
      nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null,
      idStatusApresentacao: this.state.status && this.state.status.id !== StatusFiltroEnum.TODAS.id ? this.state.status.id : null,
      dataInicial: converterDateParaDataInt(this.state.dataInicial),
      dataFinal: converterDateParaDataInt(this.state.dataFinal)
    }
  }

  getChipsFiltrosAvancados = () => {
    const { dataInicial, dataFinal } = this.state
    const chips = []

    if (dataInicial && dataFinal) {
      chips.push({
        label: moment(dataInicial).format('DD/MMM/YYYY') + ' até ' + moment(dataFinal).format('DD/MMM/YYYY'),
        onDelete: () => this.setState({ dataInicial: undefined, dataFinal: undefined })
      })
    } else {
      if (dataInicial) {
        chips.push({
          label: 'A partir: ' + moment(dataInicial).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataInicial: undefined })
        })
      }

      if (dataFinal) {
        chips.push({
          label: 'Até: ' + moment(dataFinal).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataFinal: undefined })
        })
      }
    }
    return chips
  }

  getLoaderKey = () => {
    const { status, dataInicial, dataFinal } = this.state

    let key = 'loader'

    if (status) {
      key += '-f' + status.id
    }

    if (dataInicial) {
      key += '-di' + dataInicial
    }

    if (dataFinal) {
      key += '-df' + dataFinal
    }
    return key
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  render() {
    const { classes, ...others } = this.props
    const { status, itemMenu, menuAnchorPosition } = this.state

    const permissaoPodeFecharConta = verificarAutorizacao([PA.PODE_FECHAR_CONTA])
    const permissaoPodeVisualizarNotas = verificarAutorizacao([PA.PODE_VISUALIZAR_NOTAS])
    const permissaoPodeEmitirNotasFiscais = verificarAutorizacao([PA.PODE_EMITIR_NOTAS_FISCAIS])
    const permissaoPodeCancelarNotasFiscais = verificarAutorizacao([PA.PODE_CANCELAR_NOTAS_FISCAIS])

    const contadorInfo = {
      [StatusFiltroEnum.EMITINDO.descricaoFiltro]: { quantidade: 0, valor: 0 },
      [StatusFiltroEnum.EMITIDA.descricaoFiltro]: { quantidade: 0, valor: 0 },
      [StatusFiltroEnum.COM_ERRO.descricaoFiltro]: { quantidade: 0, valor: 0 },
      [StatusFiltroEnum.CANCELANDO.descricaoFiltro]: { quantidade: 0, valor: 0 },
      [StatusFiltroEnum.CANCELADA.descricaoFiltro]: { quantidade: 0, valor: 0 }
    }

    for (let item of this.state.lista) {
      let descricaoAgrupamento = StatusFiltroEnum.getById(item.nf.statusApresentacao.id).descricaoFiltro
      contadorInfo[descricaoAgrupamento].quantidade = contadorInfo[descricaoAgrupamento].quantidade + 1
      contadorInfo[descricaoAgrupamento].valor = contadorInfo[descricaoAgrupamento].valor + item.nf.nfsValorTotal
    }

    for (let descricaoAgrupamentoNull of Object.keys(contadorInfo).filter((descricao) => contadorInfo[descricao].quantidade === 0)) {
      delete contadorInfo[descricaoAgrupamentoNull]
    }

    const toolbarActions = {
      actions: []
    }

    if (getSessaoPainel().negocio.configuracaoNf.nfsAplicarRegraSalaoParceiro) {
      toolbarActions.actions.push({
        label: 'Cotas',
        handleAction: this.abrirCotaParteDialog
      })
    }

    toolbarActions.actions.push(
      {
        icon: <RefreshIcon />,
        handleAction: () => {
          this.loaderFunctionsMap.load()
        }
      },
      {
        icon: <TuneIcon />,
        label: 'Filtrar',
        handleAction: this.abrirPesquisaAvancadaDialog
      }
    )

    const menuItemOpcoes = []

    if (itemMenu) {
      if (itemMenu.nf.emitido) {
        let pdfLinkProps = {}
        let xmlLinkProps = {}

        if (itemMenu && itemMenu.nf.emitido) {
          if (DeviceIdentifier.isNativeApp()) {
            pdfLinkProps.onClick = (event) => {
              event.stopPropagation()
              NativeApp.executeNativeMethod({
                method: 'downloadFile',
                fileName: itemMenu.nf.nfsNumero + '.pdf',
                url: itemMenu.nf.nfsUrlPdf
              })
              this.closeMenu('menuAnchorPosition')
            }
            xmlLinkProps.onClick = (event) => {
              event.stopPropagation()
              NativeApp.executeNativeMethod({
                method: 'downloadFile',
                fileName: itemMenu.nf.nfsNumero + '.xml',
                url: itemMenu.nf.nfsUrlXml
              })
              this.closeMenu('menuAnchorPosition')
            }
          } else {
            pdfLinkProps.href = itemMenu.nf.nfsUrlPdf
            pdfLinkProps.target = 'fileDownload'
            xmlLinkProps.href = itemMenu.nf.nfsUrlXml
            xmlLinkProps.target = 'fileDownload'
          }
        }

        menuItemOpcoes.push(
          <MenuItem
            key={'baixarPdf'}
            component="a"
            onClick={() => {
              this.closeMenu('menuAnchorPosition')
            }}
            {...pdfLinkProps}
          >
            Baixar PDF
          </MenuItem>
        )
        menuItemOpcoes.push(
          <MenuItem
            key={'baixarXml'}
            component="a"
            onClick={() => {
              this.closeMenu('menuAnchorPosition')
            }}
            {...xmlLinkProps}
          >
            Baixar XML
          </MenuItem>
        )
      } else if (!itemMenu.nf.descricaoErroApresentacao) {
        menuItemOpcoes.push(
          <MenuItem key={'baixarPdf'} disabled={true}>
            Baixar PDF
          </MenuItem>
        )
        menuItemOpcoes.push(
          <MenuItem key={'baixarPdf'} disabled={true}>
            Baixar XML
          </MenuItem>
        )
      }

      if (itemMenu.nf.descricaoErroApresentacao) {
        menuItemOpcoes.push(
          <MenuItem
            key={'visualizarErro'}
            onClick={() => {
              this.closeMenu('menuAnchorPosition')
              openBackableDialog(MessageDialog, {
                title: 'Erro:',
                text: itemMenu.nf.descricaoErroApresentacao
              })
            }}
          >
            Visualizar Erro
          </MenuItem>
        )
      }

      if (permissaoPodeFecharConta || permissaoPodeVisualizarNotas) {
        menuItemOpcoes.push(
          <MenuItem
            key={'abrirFatura'}
            onClick={() => {
              this.closeMenu('menuAnchorPosition')
              openBackableDialog(NotaDialogPage, {
                idNota: itemMenu.notaVenda.id
              })
            }}
          >
            Abrir Fatura
          </MenuItem>
        )
      }

      if (permissaoPodeEmitirNotasFiscais || permissaoPodeCancelarNotasFiscais) {
        if (StatusApresentacaoNfEnum.EMITINDO.id === itemMenu.nf.statusApresentacao.id || StatusApresentacaoNfEnum.CANCELANDO.id === itemMenu.nf.statusApresentacao.id) {
          menuItemOpcoes.push(
            <MenuItem key={'maisOpcoes'} disabled={true}>
              Mais Opções...
            </MenuItem>
          )
        } else {
          menuItemOpcoes.push(
            <MenuItem
              key={'maisOpcoes'}
              onClick={() => {
                this.closeMenu('menuAnchorPosition')
                openBackableDialog(GerenciamentoFaturaNotasFiscaisDialogPage, {
                  nota: itemMenu.notaVenda
                })
              }}
            >
              Mais Opções...
            </MenuItem>
          )
        }
      }
    }

    return (
      <PanelPage {...others} title={this.state.title} toolbarActions={toolbarActions}>
        <FloatContent chips={this.getChipsFiltrosAvancados()} type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 16 }}>
              <InputSelect
                nakedLeftPadding={false}
                customVariant="naked"
                value={status.id}
                fullWidth={false}
                onChange={(event) => {
                  const statusFiltro = StatusFiltroEnum.getById(parseInt(event.target.value, 10))
                  this.colaboradorLocalPreferences.setPreference('NfsPanelPageFiltro_Status', statusFiltro)
                  this.setState({ status: statusFiltro })
                }}
                renderValue={(value) => {
                  const status = StatusFiltroEnum.getById(parseInt(value, 10))
                  if (StatusFiltroEnum.TODAS.id === status.id) {
                    return 'Situação'
                  } else {
                    return status.descricaoFiltro
                  }
                }}
              >
                {StatusFiltroEnum.values().map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.descricaoFiltro}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item xs style={{ marginRight: 16, minWidth: 200 }}>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" style={{ display: 'block' }} />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    defaultValue={this.colaboradorLocalPreferences.getPreference('NfsPanelPageFiltro_Texto', '')}
                    nakedLeftPadding={false}
                    placeholder="Procurar por tomador..."
                    onChange={(event) => {
                      this.colaboradorLocalPreferences.setPreference('NfsPanelPageFiltro_Texto', event.target.value)
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>
        <br />
        <NfsResponsiveTable
          itemsPerRequest={20}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/vendas.buscarNegocioNfs"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.setState({
              menuAnchorPosition: {
                left: event.clientX,
                top: event.clientY
              },
              itemMenu: item
            })
          }}
          emptyListProps={{
            text: 'Nenhuma nota fiscal foi encontrada'
          }}
        />

        <Menu
          anchorReference="anchorPosition"
          anchorPosition={menuAnchorPosition}
          open={Boolean(menuAnchorPosition) && menuItemOpcoes.length > 0}
          onClose={() => this.closeMenu('menuAnchorPosition')}
        >
          {menuItemOpcoes}
        </Menu>

        {verificarAutorizacao([PA.PODE_VISUALIZAR_TOTALIZADORES_VALORES]) && this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            {Object.keys(contadorInfo).map((descricao, index) => {
              const contadorInfoItem = contadorInfo[descricao]
              return (
                <React.Fragment key={index}>
                  <Typography variant="body2" style={{ paddingTop: index === 0 ? 6 : 0 }}>
                    <b>{descricao}</b>
                    <br />
                    <small>
                      Quantidade: <b>{contadorInfoItem.quantidade}</b>
                      <br />
                      Total: <b>{formatarValorMonetario(contadorInfoItem.valor)}</b>
                    </small>
                  </Typography>
                  {index < Object.keys(contadorInfo).length - 1 && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
                </React.Fragment>
              )
            })}
          </FloatContent>
        )}
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

NfsPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NfsPanelPage)
