import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import DuracaoInputSelect from 'pages/erp/painel/atendimento/select/DuracaoInputSelect'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputText from 'support/components/input/InputText'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const styles = (theme) => ({})

class ServicoPesquisaAvancadaDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap()
    }

    this.inputPrecoInicial = React.createRef()
    this.inputPrecoFinal = React.createRef()
    this.inputComissaoInicial = React.createRef()
    this.inputComissaoFinal = React.createRef()
    this.duracaoInicialFunctionsMap = {}
    this.duracaoFinalFunctionsMap = {}
  }

  limparFiltro = () => {
    this.props.setParentState({
      precoInicial: null,
      precoFinal: null,
      comissaoInicial: null,
      comissaoFinal: null,
      duracaoInicial: null,
      duracaoFinal: null
    })
    this.props.handleCloseDialog()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.aplicarFiltro()
  }

  aplicarFiltro = () => {
    this.setState({ errorsMap: FormUtil.createErrorsMap() })

    const errorsMap = {}

    const precoInicial = FormUtil.convertStringToNumber(this.inputPrecoInicial.current.value)
    const precoFinal = FormUtil.convertStringToNumber(this.inputPrecoFinal.current.value)
    const comissaoInicial = FormUtil.convertStringToNumber(this.inputComissaoInicial.current.value)
    const comissaoFinal = FormUtil.convertStringToNumber(this.inputComissaoFinal.current.value)
    const duracaoInicial = FormUtil.convertStringToNumber(this.duracaoInicialFunctionsMap.getValue())
    const duracaoFinal = FormUtil.convertStringToNumber(this.duracaoFinalFunctionsMap.getValue())

    if (precoInicial !== null && precoInicial < 0) {
      errorsMap['precoInicial'] = 'Não pode ser menor que R$ 0,00'
    }

    if (precoFinal !== null && precoFinal < 0) {
      errorsMap['precoFinal'] = 'Não pode ser menor que R$ 0,00'
    }

    if (precoInicial !== null && precoFinal !== null && precoInicial > precoFinal) {
      errorsMap['precoFinal'] = 'O preço(até) não pode ser menor que o preço(de)'
    }

    if (comissaoInicial !== null && comissaoInicial < 0) {
      errorsMap['comissaoInicial'] = 'Não pode ser menor que 0'
    }

    if (comissaoFinal !== null && comissaoFinal < 0) {
      errorsMap['comissaoFinal'] = 'Não pode ser menor que 0'
    }

    if (comissaoInicial !== null && comissaoFinal !== null && comissaoInicial > comissaoFinal) {
      errorsMap['comissaoFinal'] = 'A comissão(até) não pode ser menor que a comissão(de)'
    }

    if (duracaoInicial !== null && duracaoFinal !== null && duracaoInicial > duracaoFinal) {
      errorsMap['duracaoFinal'] = 'A duração(até) não pode ser menor que a duração(de)'
    }

    if (Object.keys(errorsMap).length > 0) {
      this.setState({ errorsMap: FormUtil.createErrorsMap(errorsMap) })
      FormUtil.focusFirstElementWithError('formServicoPesquisaAvancada')
    } else {
      this.props.setParentState({
        precoInicial: precoInicial,
        precoFinal: precoFinal,
        comissaoInicial: comissaoInicial,
        comissaoFinal: comissaoFinal,
        duracaoInicial: duracaoInicial,
        duracaoFinal: duracaoFinal
      })
      this.props.handleCloseDialog()
    }
  }

  render() {
    const { errorsMap } = this.state

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogTitle>Pesquisa Avançada</DialogTitle>
        <DialogContent>
          <form id="formServicoPesquisaAvancada" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <InputHorizontalContainer
              inputTexts={[
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputText
                      customVariant="outlined-small"
                      shrink={false}
                      id="xprecoinicial"
                      name="xprecoinicial"
                      label="Preço (de)"
                      inputComponent={InputMoneyFormat}
                      inputRef={this.inputPrecoInicial}
                      defaultValue={this.props.precoInicial}
                      errorMessage={errorsMap('precoInicial')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputText
                      id="xprecofinal"
                      name="xprecofinal"
                      label="Preço (até)"
                      customVariant="outlined-small"
                      shrink={false}
                      inputComponent={InputMoneyFormat}
                      inputRef={this.inputPrecoFinal}
                      defaultValue={this.props.precoFinal}
                      errorMessage={errorsMap('precoFinal')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputText
                      id="xcomissaoinicial"
                      name="xcomissaoinicial"
                      label="Comissão (de)"
                      customVariant="outlined-small"
                      shrink={false}
                      inputComponent={InputMoneyFormat}
                      inputRef={this.inputComissaoInicial}
                      defaultValue={this.props.comissaoInicial}
                      errorMessage={errorsMap('comissaoInicial')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputText
                      id="xcomissaofinal"
                      name="xcomissaofinal"
                      label="Comissão (até)"
                      customVariant="outlined-small"
                      shrink={false}
                      inputComponent={InputMoneyFormat}
                      inputRef={this.inputComissaoFinal}
                      defaultValue={this.props.comissaoFinal}
                      errorMessage={errorsMap('comissaoFinal')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <DuracaoInputSelect
                      id="xduracaoinicial"
                      name="xduracaoinicial"
                      label="Duração (de)"
                      descricaoResumida={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.duracaoInicialFunctionsMap}
                      defaultValue={this.props.duracaoInicial}
                      errorMessage={errorsMap('duracaoInicial')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <DuracaoInputSelect
                      id="xduracaofinal"
                      name="xduracaofinal"
                      label="Duração (até)"
                      descricaoResumida={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.duracaoFinalFunctionsMap}
                      defaultValue={this.props.duracaoFinal}
                      errorMessage={errorsMap('duracaoFinal')}
                    />
                  )
                }
              ]}
            />

            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Voltar
          </Button>
          <Button color="secondary" onClick={this.limparFiltro}>
            LIMPAR
          </Button>
          <Button color="secondary" onClick={this.aplicarFiltro}>
            PESQUISAR
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

ServicoPesquisaAvancadaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServicoPesquisaAvancadaDialog)
