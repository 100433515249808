import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ChartLine from 'support/components/chart/ChartLine'
import ChartPie from 'support/components/chart/ChartPie'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import ComoConheceuEnum from 'support/domain/cliente/ComoConheceuEnum'
import SexoEnum from 'support/domain/cliente/SexoEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  tituloRelatorio: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaQuantidade: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaCliente: {
    flexGrow: 1
  },
  colunaValores: {
    maxWidth: 200,
    minWidth: 200
  }
})

class RelatorioClientesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxingMaisLucrativos: false,
      ajaxingValoresVendidosUltimosMeses: false,
      ajaxingSexo: false,
      ajaxingComoConheceu: false,
      clientesMaisLucrativosDataInicial: this.colaboradorLocalPreferences.getMomentPreference(
        'RelatorioClientesDialogPageFiltro_MaisLucrativosDataInicial',
        moment().startOf('month')
      ),
      clientesMaisLucrativosDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioClientesDialogPageFiltro_MaisLucrativosDataFinal', moment().endOf('month')),
      clientesMaisLucrativos: []
    }
    this.dadosValoresVendidosUltimosMesesLoaderFunctionsMap = {}
    this.dadosMaisLucrativosLoaderFunctionsMap = {}
    this.dadosSexoLoaderFunctionsMap = {}
    this.dadosComoConheceuLoaderFunctionsMap = {}
  }

  carregarDadosValoresVendidosUltimosMeses = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingValoresVendidosUltimosMeses: true })

    getAPI({
      url: 'erp/relatorios.buscarClientesUltimosMeses',
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingValoresVendidosUltimosMeses: false,
          dadosValoresVendidosUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(
            response.data.vendas,
            'Valor Gasto Médio Por Cliente',
            'valor',
            this.props.theme.palette.chart.list[0],
            (value, name, props) => [
              <span style={{ fontSize: 12 }}>
                {name}: {formatarValorMonetario(value)}
              </span>,
              null
            ]
          ),
          dadosQuantidadeUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(
            response.data.vendas,
            'Clientes Atendidos',
            'quantidade',
            this.props.theme.palette.chart.list[1],
            (value, name, props) => [
              <span style={{ fontSize: 12 }}>
                {name}: {value}
              </span>,
              null
            ]
          ),
          dadosClientesNovosUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(
            response.data.clientesNovos,
            'Clientes Novos',
            'quantidade',
            this.props.theme.palette.chart.list[1],
            (value, name, props) => [
              <span style={{ fontSize: 12 }}>
                {name}: {value}
              </span>,
              null
            ]
          )
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingValoresVendidosUltimosMeses: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosUltimosMesesParaGrafico = (dadosUltimosMeses, descricao, valueProp, color, tooltipFormatterFunction) => {
    const dadosUltimosMesesPorMes = {}
    if (dadosUltimosMeses && dadosUltimosMeses.length) {
      for (let dadosReceitaUltimosMeses of dadosUltimosMeses) {
        dadosUltimosMesesPorMes[dadosReceitaUltimosMeses.mes.toString()] = dadosReceitaUltimosMeses
      }
    }

    const agora = moment()
    const dataNavegacao = agora.clone().startOf('month').subtract(11, 'month')
    const criarItem = (mes, valor) => {
      const data = moment(mes.toString(), 'YYYYMM')
      return {
        mes: data.format('MMM/YY'),
        vl: valor === null || valor === undefined ? 0 : valor
      }
    }

    const data = []
    while (true) {
      const mesAtual = dataNavegacao.format('YYYYMM')
      const dadosReceitaUltimosMeses = dadosUltimosMesesPorMes[mesAtual]
      if (dadosReceitaUltimosMeses) {
        data.push(criarItem(mesAtual, dadosReceitaUltimosMeses[valueProp]))
      } else {
        data.push(criarItem(mesAtual, 0))
      }
      if (dataNavegacao.isSame(agora, 'month')) {
        break
      } else {
        dataNavegacao.add(1, 'month')
      }
    }
    return {
      xAxis: {
        dataKey: 'mes',
        height: 80
      },
      tooltip: {
        formatter: tooltipFormatterFunction
      },
      items: [
        {
          name: descricao,
          color: color,
          shortName: descricao,
          dataKey: 'vl'
        }
      ],
      data: data
    }
  }

  carregarDadosSexo = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingSexo: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosSexoClientes',
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingSexo: false,
          dadosSexo: this.prepararDadosSexoClientes(response.data)
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingSexo: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarDadosComoConheceu = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingComoConheceu: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosComoConheceuClientes',
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingComoConheceu: false,
          dadosComoConheceu: this.prepararDadosComoConheceuClientes(response.data)
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingComoConheceu: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarMaisLucrativos = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingMaisLucrativos: true })

    getAPI({
      url: 'erp/relatorios.buscarClientesMaisLucrativos',
      params: {
        dataInicial: converterMomentParaDataInt(this.state.clientesMaisLucrativosDataInicial),
        dataFinal: converterMomentParaDataInt(this.state.clientesMaisLucrativosDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        const dadosTopMaisLucrativos = this.prepararDadosTopMaisLucrativos(response.data)

        this.setState({
          ajaxingMaisLucrativos: false,
          clientesMaisLucrativos: response.data,
          dadosTopMaisLucrativos: dadosTopMaisLucrativos
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingMaisLucrativos: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosTopMaisLucrativos = (items) => {
    const dados = []
    for (let item of items) {
      const valorTotalPago = item.valorTotalPago ? item.valorTotalPago : 0

      if (valorTotalPago === 0) {
        continue
      }

      if (dados.length < 6) {
        dados.push({
          name: (
            <span>
              <b>{item.nomeCliente}</b>, {formatarValorMonetario(valorTotalPago)}
            </span>
          ),
          value: valorTotalPago
        })
      } else if (dados.length === 6) {
        dados.push({
          name: (
            <span>
              <b>Outros</b>, {formatarValorMonetario(valorTotalPago)}
            </span>
          ),
          value: valorTotalPago,
          quantidade: item.quantidade
        })
      } else {
        const ultimoItem = dados[dados.length - 1]
        ultimoItem.value = ultimoItem.value + valorTotalPago
        ultimoItem.quantidade = ultimoItem.quantidade + item.quantidade
        ultimoItem.name = (
          <span>
            <b>Outros</b>, {formatarValorMonetario(ultimoItem.value)}
          </span>
        )
      }
    }
    return dados
  }

  prepararDadosSexoClientes = (items) => {
    const dados = []
    for (let item of items) {
      dados.push({
        name: (
          <span>
            <b>{SexoEnum.getById(item.id).descricao}</b>, {item.quantidade}
          </span>
        ),
        value: item.quantidade
      })
    }
    return dados
  }

  prepararDadosComoConheceuClientes = (items) => {
    const dados = []
    for (let item of items) {
      dados.push({
        name: (
          <span>
            <b>{ComoConheceuEnum.getById(item.id).descricao}</b>, {item.quantidade}
          </span>
        ),
        value: item.quantidade
      })
    }
    return dados
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.nomeCliente = item.nomeCliente

    const valorTotal = item.valorTotal ? item.valorTotal : 0
    const valorTotalPago = item.valorTotalPago ? item.valorTotalPago : 0

    if (size === 'small') {
      content.quantidadeVendas = <React.Fragment>Qtd. Vendas: {item.quantidadeVendas.toString()}</React.Fragment>
      content.valorBruto = <React.Fragment>Valor Bruto: {formatarValorMonetario(item.valorBruto)}</React.Fragment>
      content.valorDescontos = <React.Fragment>Valor Descontos: {formatarValorMonetario(item.valorDescontos)}</React.Fragment>
      content.valorTotal = <React.Fragment>Valor Total: {formatarValorMonetario(valorTotal)}</React.Fragment>
      content.valorTotalPago = <React.Fragment>Valor Pago: {formatarValorMonetario(valorTotalPago)}</React.Fragment>
    } else {
      content.quantidadeVendas = item.quantidadeVendas.toString()
      content.valorBruto = formatarValorMonetario(item.valorBruto, { prefix: false })
      content.valorDescontos = formatarValorMonetario(item.valorDescontos, { prefix: false })
      content.valorTotal = formatarValorMonetario(valorTotal, { prefix: false })
      content.valorTotalPago = formatarValorMonetario(valorTotalPago, { prefix: false })
    }
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const {
      ajaxingValoresVendidosUltimosMeses,
      ajaxingMaisLucrativos,
      ajaxingSexo,
      ajaxingComoConheceu,
      dadosValoresVendidosUltimosMeses,
      dadosClientesNovosUltimosMeses,
      dadosQuantidadeUltimosMeses,
      clientesMaisLucrativosDataInicial,
      clientesMaisLucrativosDataFinal
    } = this.state

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.clientesMaisLucrativos) {
      valorTotal += item.valor
      quantidadeTotal += item.quantidade
    }

    let toolbarActions = null
    if (!ajaxingMaisLucrativos && !ajaxingValoresVendidosUltimosMeses && !ajaxingSexo && !ajaxingComoConheceu) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.dadosValoresVendidosUltimosMesesLoaderFunctionsMap.reload()
              this.dadosMaisLucrativosLoaderFunctionsMap.reload()
              this.dadosSexoLoaderFunctionsMap.reload()
              this.dadosComoConheceuLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let clientesMaisLucrativosLoadKey =
      converterMomentParaDataInt(this.state.clientesMaisLucrativosDataInicial).toString() + '-' + converterMomentParaDataInt(this.state.clientesMaisLucrativosDataFinal).toString()

    return (
      <DialogPage
        {...dialogProps}
        title="Clientes"
        align="center"
        contentMaxWidth={1200}
        pageType="basicEntity"
        ajaxing={ajaxingMaisLucrativos || ajaxingValoresVendidosUltimosMeses}
        toolbarActions={toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5" align="center" className={classes.tituloRelatorio}>
                Gráfico de Clientes Atendidos
              </Typography>
            </FloatContent>

            <ContentWithPreload functionsMap={this.dadosValoresVendidosUltimosMesesLoaderFunctionsMap} loadFunction={this.carregarDadosValoresVendidosUltimosMeses}>
              {() => (
                <div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 24 }}>
                    <ChartLine data={dadosQuantidadeUltimosMeses} height={300} />
                  </div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 24 }}>
                    <ChartLine data={dadosValoresVendidosUltimosMeses} height={300} />
                  </div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 24 }}>
                    <ChartLine data={dadosClientesNovosUltimosMeses} height={300} />
                  </div>
                </div>
              )}
            </ContentWithPreload>

            <div style={{ padding: '34px 24px 16px 24px', textAlign: 'center' }}>
              <Typography variant="h5" align="center" className={classes.tituloRelatorio}>
                Sexo
              </Typography>

              <ContentWithPreload functionsMap={this.dadosSexoLoaderFunctionsMap} loadFunction={this.carregarDadosSexo}>
                {() => (
                  <div style={{ width: '100%', paddingBottom: 32, paddingTop: 32 }}>
                    <ChartPie
                      data={{
                        items: this.state.dadosSexo
                      }}
                    />
                  </div>
                )}
              </ContentWithPreload>

              <Typography variant="h5" align="center" className={classes.tituloRelatorio}>
                Como Conheceu
              </Typography>

              <ContentWithPreload functionsMap={this.dadosComoConheceuLoaderFunctionsMap} loadFunction={this.carregarDadosComoConheceu}>
                {() => (
                  <div style={{ width: '100%', paddingBottom: 32, paddingTop: 32 }}>
                    <ChartPie
                      data={{
                        items: this.state.dadosComoConheceu
                      }}
                    />
                  </div>
                )}
              </ContentWithPreload>

              <Typography style={{ padding: '34px' }} variant="h5" align="center" className={classes.tituloRelatorio}>
                Top 20 Clientes Lucrativos
              </Typography>

              <div style={{ display: 'inline-block' }}>
                <DateRangeNavigator
                  expanded={false}
                  defaultValue={{
                    tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioClientesDialogPageFiltro_DadosFinanceirosTipoIntervalo', 'mes'),
                    dataInicial: clientesMaisLucrativosDataInicial,
                    dataFinal: clientesMaisLucrativosDataFinal
                  }}
                  onChange={(data) => {
                    this.colaboradorLocalPreferences.setPreference('RelatorioClientesDialogPageFiltro_DadosFinanceirosTipoIntervalo', data.tipoIntervalo.nome)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioClientesDialogPageFiltro_MaisLucrativosDataInicial', data.dataInicial)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioClientesDialogPageFiltro_MaisLucrativosDataFinal', data.dataFinal)
                    this.setState({
                      clientesMaisLucrativosDataInicial: data.dataInicial,
                      clientesMaisLucrativosDataFinal: data.dataFinal
                    })
                  }}
                />
              </div>
            </div>

            <ContentWithPreload functionsMap={this.dadosMaisLucrativosLoaderFunctionsMap} loadFunction={this.carregarMaisLucrativos} loadKey={clientesMaisLucrativosLoadKey}>
              {() => (
                <React.Fragment>
                  {/*
										<div style={{width: "100%", paddingBottom: 32, paddingTop: 32}}>
											<ChartPie data={{
												items: this.state.dadosTopMaisLucrativos
											}} />
										</div>
										*/}

                  {this.state.clientesMaisLucrativos && this.state.clientesMaisLucrativos.length > 0 && (
                    <React.Fragment>
                      <div>
                        <VirtualizedResponsiveTable
                          showBackgroundDividers={true}
                          scrollElement={dialogContentProps.scrollContainerRef.current}
                          items={this.state.clientesMaisLucrativos}
                          largeRenderProps={{
                            columns: [
                              { label: 'Cliente', className: classes.colunaCliente, props: { xs: true } },
                              { label: 'Qtd. Vendas', className: classes.colunaQuantidade, align: 'right' },
                              { label: 'Bruto (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                              { label: 'Descontos (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                              { label: 'Valor Total (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                              { label: 'Total Pago (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item)

                              return {
                                itemData: [content.nomeCliente, content.quantidadeVendas, content.valorBruto, content.valorDescontos, content.valorTotal, content.valorTotalPago]
                              }
                            }
                          }}
                          mediumRenderProps={{
                            headerColumnHeight: 68,
                            rowHeight: 106,
                            columns: [
                              { label: 'Cliente', className: classes.colunaCliente, props: { xs: true } },
                              { label: 'Qtd. Vendas', align: 'left' },
                              { label: 'Valores', className: classes.colunaValores, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('medium', item)

                              return {
                                itemData: [
                                  <span>
                                    <b>{content.nomeCliente}</b>
                                  </span>,
                                  content.quantidadeVendas,
                                  <React.Fragment>
                                    <Typography variant="body2" noWrap={true}>
                                      Valor Total: {content.valorTotal}
                                    </Typography>
                                    <Typography variant="body2" noWrap={true}>
                                      Valor Bruto: {content.valorBruto}
                                    </Typography>
                                    <Typography variant="body2" noWrap={true}>
                                      Total Descontos: {content.valorDescontos}
                                    </Typography>
                                    <Typography variant="body2" noWrap={true}>
                                      Valor Pago: {content.valorTotalPago}
                                    </Typography>
                                  </React.Fragment>
                                ]
                              }
                            }
                          }}
                          smallRenderProps={{
                            rowHeight: 150,
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('small', item)

                              return {
                                itemData: (
                                  <React.Fragment>
                                    <Typography variant="body2" noWrap={true}>
                                      <b>{content.nomeCliente}</b>
                                    </Typography>
                                    <Typography variant="body2">{content.quantidadeVendas}</Typography>
                                    <Typography variant="body2" noWrap={true}>
                                      {content.valorBruto}
                                    </Typography>
                                    <Typography variant="body2" noWrap={true}>
                                      {content.valorDescontos}
                                    </Typography>
                                    <Typography variant="body2" noWrap={true}>
                                      {content.valorTotal}
                                    </Typography>
                                    <Typography variant="body2" noWrap={true}>
                                      {content.valorTotalPago}
                                    </Typography>
                                  </React.Fragment>
                                )
                              }
                            }
                          }}
                          emptyListProps={{
                            text: 'Não existem pagamentos de clientes para o período informado'
                          }}
                        />
                      </div>

                      {quantidadeTotal > 1 && (
                        <FloatContent container="paper" context="DialogPage" type="tableSummary">
                          <Typography variant="body2">
                            Visitas: <b>{quantidadeTotal}</b>
                            <br />
                            Total: <b>{formatarValorMonetario(valorTotal)}</b>
                            <br />
                          </Typography>
                        </FloatContent>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioClientesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioClientesDialogPage)
