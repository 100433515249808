import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CustomDialog from 'support/components/dialog/CustomDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import PermissoesAcesso from 'support/domain/colaborador/PermissoesAcesso'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons,
  containerGrupoPermissoes: {
    padding: '24px 32px',
    border: '1px solid #e6e6e6',
    marginTop: 20,
    borderRadius: 4,
    [theme.breakpoints.down(410)]: {
      marginTop: 12,
      border: '0px solid #e6e6e6',
      padding: 0,
      marginBottom: 24
    }
  },
  textCheckbox: {
    paddingTop: 6,
    paddingBottom: 6
  }
})

class ConfiguracoesPermissoesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Permissões',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.horariosAtendimentoComponentFunctions = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/colaboradores.buscarPorId',
      params: {
        id: this.props.colaborador.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.atualizarColaborador(response.data)
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  atualizarColaborador = (colaborador) => {
    this.setState({
      ajaxing: false,
      colaborador: colaborador,
      permissoes: PermissoesAcesso.generateAllItems(colaborador.permissoes)
    })
  }

  verificarRemocaoPermissaoProprioAcesso = (permissaoParaRemover) => {
    if (getSessaoPainel().colaborador.id === this.state.colaborador.id) {
      if (permissaoParaRemover.position === PA.ACESSO_SISTEMA.position) {
        openBackableDialog(MessageDialog, {
          text: 'Você não pode remover o seu próprio acesso.'
        })
        return false
      }
      if (permissaoParaRemover.position === PA.PODE_ALTERAR_PERMISSOES_ACESSO.position) {
        openBackableDialog(MessageDialog, {
          text: 'Você não pode remover a sua própria permissão de gerenciar permissões.'
        })
        return false
      }
    }
    return true
  }

  handleChangePermissao = (permissaoAlterada, event) => {
    const checked = event.target.checked

    if (checked === false) {
      if (!this.verificarRemocaoPermissaoProprioAcesso(permissaoAlterada)) {
        return
      }
    }

    const permissoes = this.state.permissoes
    const positionsToIgnore = [permissaoAlterada.position]

    for (let permissao of permissoes) {
      if (permissao.exists === checked) {
        positionsToIgnore.push(permissao.position)
      }
    }

    const permissoesDependentes = PermissoesAcesso.buscarDependencias(permissaoAlterada, checked, positionsToIgnore)

    if (permissoesDependentes.length) {
      openBackableDialog(PermissoesDependentesDialog, {
        inclusao: checked,
        permissoesDependentes: permissoesDependentes,
        actionFunction: () => {
          if (checked === false) {
            for (let permissaoParaRemover of permissoesDependentes) {
              if (!this.verificarRemocaoPermissaoProprioAcesso(permissaoParaRemover)) {
                return
              }
            }
          }

          this.changePermissoes(permissoesDependentes.concat(permissaoAlterada), checked)
        }
      })
    } else {
      this.changePermissoes(permissaoAlterada, checked)
    }
  }

  changePermissoes = (permissoesParaAlteracao, checked) => {
    const changePermissaoFunction = (permissoes, permissaoParaAlterar) => {
      for (let permissao of permissoes) {
        if (permissao.position === permissaoParaAlterar.position) {
          permissao.exists = checked
          break
        }
      }
    }

    const permissoes = this.state.permissoes
    if (Array.isArray(permissoesParaAlteracao)) {
      for (let permissaoParaAlteracao of permissoesParaAlteracao) {
        changePermissaoFunction(permissoes, permissaoParaAlteracao)
      }
    } else {
      changePermissaoFunction(permissoes, permissoesParaAlteracao)
    }
    this.setState({ permissoes: permissoes })
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/colaboradores.alterarPermissoesAcesso',
      data: {
        idColaborador: this.props.colaborador.id,
        permissoes: PermissoesAcesso.getString(this.state.permissoes)
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.atualizarColaborador(response.data)
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoColaborador', { colaborador: response.data })
        EventsManager.pub('AtualizarSessao')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formColaboradorHorariosAtendimento')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { permissoes } = this.state

    let permissoesAcessoSistema = null
    const agrupamentoPermissoes = []

    if (permissoes && permissoes.length) {
      permissoesAcessoSistema = this.state.permissoes[0]
      for (let i = 1; i < permissoes.length; i++) {
        const permissao = permissoes[i]
        let agrupamentoAtual = null
        if (agrupamentoPermissoes.length > 0) {
          agrupamentoAtual = agrupamentoPermissoes[agrupamentoPermissoes.length - 1]
        }
        if (agrupamentoAtual === null || agrupamentoAtual.nomeGrupo !== permissao.grupo) {
          agrupamentoAtual = {
            nomeGrupo: permissao.grupo,
            permissoes: []
          }
          agrupamentoPermissoes.push(agrupamentoAtual)
        }
        agrupamentoAtual.permissoes.push(permissao)
      }
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={505}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.containerFunctionMap}>
          {() => {
            let content = null

            if (!verificarAutorizacao([PA.PODE_ALTERAR_PERMISSOES_ACESSO])) {
              content = (
                <Typography variant="body2" align="center">
                  Você não tem permissao para acessar estas informações.
                </Typography>
              )
            } else if (!this.state.colaborador.usuario || !this.state.colaborador.usuario.email) {
              content = (
                <Typography variant="body2" align="center">
                  Preencha o e-mail do colaborador na tela de cadastro antes de tentar definir as permissões de acesso. O colaborador só conseguirá acessar a conta desta empresa se
                  o e-mail dele estiver cadastrado.
                </Typography>
              )
            } else {
              content = (
                <form id="formColaboradorHorariosAtendimento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  <Grid container alignItems="center" style={{ marginTop: -8 }}>
                    <Grid item xs>
                      <Typography variant="body1" style={{ marginBottom: 6, fontWeight: 500 }}>
                        Habilitar acesso ao sistema
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch checked={permissoesAcessoSistema.exists} onChange={(event) => this.handleChangePermissao(permissoesAcessoSistema, event)} color="secondary" />
                    </Grid>
                  </Grid>

                  {agrupamentoPermissoes.map((agrupamento) => (
                    <div key={agrupamento.nomeGrupo} className={classes.containerGrupoPermissoes}>
                      <div style={{ opacity: permissoesAcessoSistema.exists ? 1 : 0.3 }}>
                        <Grid container wrap="nowrap" alignItems="center">
                          <Grid item style={{ marginRight: 16 }}>
                            <VpnKeyIcon style={{ color: '#3a3a3a' }} />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">{agrupamento.nomeGrupo}</Typography>
                          </Grid>
                        </Grid>

                        <FormGroup style={{ marginTop: 12, marginLeft: 10 }}>
                          {agrupamento.permissoes.map((permissao) => (
                            <FormControlLabel
                              key={permissao.position}
                              control={
                                <Checkbox
                                  style={{ padding: 5, marginRight: 8 }}
                                  color="primary"
                                  disabled={this.state.ajaxing || !permissoesAcessoSistema.exists}
                                  checked={permissao.exists}
                                  onChange={(event) => this.handleChangePermissao(permissao, event)}
                                />
                              }
                              label={
                                <Typography variant="body2" className={classes.textCheckbox}>
                                  {permissao.descricao}
                                </Typography>
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </div>
                  ))}

                  <HiddenSubmitButton />

                  {this.state.paginaCarregada && (
                    <div className={classes.containerFooterButtons}>
                      <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                        Cancelar
                      </Button>
                      <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                        {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                      </Button>
                    </div>
                  )}
                </form>
              )
            }
            return content
          }}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesPermissoesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesPermissoesDialogPage)

class PermissoesDependentesDialog extends Component {
  render() {
    const { inclusao } = this.props

    let descricao = 'Ao adicionar essa permissão, o sistema também adicionará as seguintes permissões:'
    if (!inclusao) {
      descricao = 'Ao remover essa permissão, o sistema também removerá as seguintes permissões:'
    }

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogContent>
          <Typography variant="body1" style={{ paddingBottom: 10, paddingTop: 16 }}>
            {descricao}
          </Typography>
          <div style={{ marginTop: 10, marginLeft: 16 }}>
            {this.props.permissoesDependentes.map((permissao) => (
              <Grid key={permissao.position} container wrap="nowrap" alignItems="center" spacing={2}>
                <Grid item>
                  <VpnKeyIcon fontSize="small" style={{ display: 'block' }} />
                </Grid>
                <Grid item>
                  <Typography variant="body2">{permissao.descricao}</Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            CANCELAR
          </Button>
          <Button
            onClick={() => {
              this.props.handleCloseDialog({
                onClosedCallback: () => {
                  this.props.actionFunction()
                }
              })
            }}
            color="secondary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}
