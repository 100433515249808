import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'

const styles = (theme) => ({
  paper: {
    width: '100%'
  },
  titleContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.screen.margin,
    paddingRight: theme.screen.margin - 14,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.screen.marginXs,
      paddingRight: theme.screen.marginXs - 14
    },
    [theme.breakpoints.down(380)]: {
      paddingLeft: theme.screen.margin380,
      paddingRight: theme.screen.margin380 - 14
    }
  },
  content: {
    ...theme.container.basic()
  }
})

class DetailsDialog extends Component {
  constructor(props) {
    super(props)
    this.paperRef = React.createRef()
  }

  render() {
    const { classes } = this.props

    let maxWidth = 'none'
    if (this.props.maxWidth) {
      maxWidth = this.props.maxWidth
    }

    let content = this.props.children ? this.props.children : null
    if (typeof content == 'function') {
      content = content({ scrollContainerRef: this.paperRef })
    }

    const headerGridContents = []
    let variant = 'default'

    if (this.props.variant) {
      variant = this.props.variant
    }

    if (variant === 'withHeaderActions') {
      headerGridContents.push(
        <Grid item key="closebutton" style={{ marginLeft: -16, marginRight: 6 }}>
          <IconButton onClick={() => this.props.handleCloseDialog()}>
            <ArrowBackIcon style={{ display: 'block' }} />
          </IconButton>
        </Grid>
      )
    }

    headerGridContents.push(
      <Grid item xs key="title">
        <Typography variant="h6">{this.props.title}</Typography>
        {this.props.subtitle && <Typography variant="body2">{this.props.subtitle}</Typography>}
      </Grid>
    )

    if (variant === 'withHeaderActions') {
      if (this.props.actions && this.props.actions.length > 0) {
        for (let i = 0; i < this.props.actions.length; i++) {
          let action = this.props.actions[i]
          const IconComponent = action.icon
          headerGridContents.push(
            <Grid item key={'actionbutton' + i}>
              <IconButton onClick={action.onClick}>
                <IconComponent style={{ display: 'block' }} />
              </IconButton>
            </Grid>
          )
        }
      }
    } else if (variant === 'default') {
      headerGridContents.push(
        <Grid item key="closebutton">
          <IconButton onClick={() => this.props.handleCloseDialog()}>
            <CloseIcon style={{ display: 'block' }} />
          </IconButton>
        </Grid>
      )
    }

    return (
      <CustomDialog
        dialogProps={this.props}
        classes={{ paper: classes.paper }}
        paperRef={this.paperRef}
        paperStyle={{
          maxWidth: maxWidth
        }}
      >
        <div style={{ maxWidth: maxWidth }}>
          <Grid container alignItems="center" className={classes.titleContainer}>
            {headerGridContents}
          </Grid>
          <div className={classes.content}>{content}</div>
        </div>
      </CustomDialog>
    )
  }
}

DetailsDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetailsDialog)
