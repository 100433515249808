import { getAPI as _getAPI, patchAPI as _patchAPI, postAPI as _postAPI } from 'support/components/api/ApiClient'

export function postAPI(opts) {
  opts.contextoUsuario = 'admin'
  _postAPI(opts)
}

export function getAPI(opts) {
  opts.contextoUsuario = 'admin'
  _getAPI(opts)
}

export function patchAPI(opts) {
  opts.contextoUsuario = 'admin'
  _patchAPI(opts)
}
