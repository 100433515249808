import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { createEnum } from 'support/util/EnumUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 90,
    minWidth: 90
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaCliente: {
    flexGrow: 1
  },
  colunaIconeTipoVenda: {
    maxWidth: 44,
    minWidth: 44
  }
})

export const VisaoEnum = createEnum({
  CLIENTE: { id: 1, descricao: 'Visualizar por Cliente' },
  VENDA: { id: 2, descricao: 'Visualizar por Venda' }
})

export const OrdenacaoEnum = createEnum({
  CLIENTE: { id: 1, descricao: 'Ordenar por Cliente' },
  VALOR: { id: 2, descricao: 'Ordenar por Valor' }
})

class VendasSemNotaDetalhesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      lista: [],
      visao: VisaoEnum.CLIENTE,
      ordenacao: OrdenacaoEnum.CLIENTE,
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
    this.dataExporterFunctionsMap = {}
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      idOrdenacao: this.state.ordenacao ? this.state.ordenacao.id : null
    }
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const content = {}

    if (this.state.visao.id === VisaoEnum.CLIENTE.id) {
      content.nomeCliente = item.descricao
      content.idCliente = item.id
      content.valor = formatarValorMonetario(item.valor)
    } else {
      content.descricaoValor = VendaService.getDescricaoPreco(item, {
        format: size === 'medium' || size === 'small' ? 'minificado' : null,
        quebraLinhaUnidades: true
      })

      content.colaborador = item.colaborador

      const iconeEDescricao = VendaService.getIconeEDescricao(item)
      content.iconeTipoVenda = <iconeEDescricao.icon className={classes.iconeTipoVenda} />
      content.descricaoVenda = iconeEDescricao.descricao

      if (VendaService.isAtendimento(item)) {
        if (size === 'small') {
          content.data = (
            <span>
              {converterDataIntParaMoment(item.dataVenda).format('DD/MMM/YY')} - {formatarHorarioInt(item.atendimento.horarioInicio)}
            </span>
          )
        } else {
          content.data = (
            <span>
              {converterDataIntParaMoment(item.dataVenda).format('DD/MMM/YY')}
              <br />
              <small style={{ color: '#aaa', marginTop: 3 }}>
                <AccessTimeIcon style={{ fontSize: 16, verticalAlign: 'top', marginRight: 3, marginTop: 1 }} />
                {formatarHorarioInt(item.atendimento.horarioInicio)}
              </small>
            </span>
          )
        }
      } else {
        content.data = converterDataIntParaMoment(item.dataVenda).format('DD/MMM/YY')
      }
      content.nomeCliente = item.cliente.apelido
    }

    return content
  }

  downloadRelatorio = (event) => {
    let endpoint = ''

    if (this.state.visao.id === VisaoEnum.CLIENTE.id) {
      endpoint = 'relatorios.gerarClientesComVendasSemNota'
    } else {
      endpoint = 'relatorios.gerarVendasSemNotas'
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Vendas Pendentes para Fechamento - ' + formatarPeriodoNomeArquivo(this.props.dataInicial, this.props.dataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: endpoint,
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { visao, ordenacao } = this.state
    let endPoint = null
    let largeRenderProps = undefined
    let mediumRenderProps = undefined
    let smallRenderProps = undefined
    let valorTotal = 0
    let quantidadeTotal = 0
    let descricaoRodape = undefined

    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    if (visao.id === VisaoEnum.CLIENTE.id) {
      endPoint = 'erp/relatorios.buscarClientesComVendasSemNota'

      largeRenderProps = {
        columns: [
          { label: 'Cliente', className: classes.colunaCliente, props: { xs: true } },
          { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('large', item)

          return {
            itemData: [content.nomeCliente, content.valor]
          }
        }
      }

      mediumRenderProps = largeRenderProps

      smallRenderProps = {
        rowHeight: 80,
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('small', item)

          return {
            itemData: (
              <React.Fragment>
                <Typography variant="body2" noWrap={true}>
                  <b>{content.nomeCliente}</b>
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {content.valor}
                </Typography>
              </React.Fragment>
            )
          }
        }
      }

      for (let item of this.state.lista) {
        if (!item.clientePacoteItem) {
          valorTotal += item.valor
        }
        quantidadeTotal++
      }

      descricaoRodape = 'Clientes:'
    } else {
      endPoint = 'erp/relatorios.buscarVendasSemNotasPorData'

      largeRenderProps = {
        columns: [
          { label: 'Data', className: classes.colunaData },
          { label: 'Cliente', className: classes.colunaCliente, props: { xs: true }, horizontalPadding: 'small' },
          { label: '', className: classes.colunaIconeTipoVenda, isVisible: false, horizontalPadding: 'small' },
          { label: 'Descrição', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } },
          { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (comissao, index) => {
          const content = this.getRenderContent('large', comissao)

          return {
            itemData: [content.data, content.nomeCliente, content.iconeTipoVenda, content.descricaoVenda, content.descricaoValor]
          }
        }
      }

      mediumRenderProps = largeRenderProps

      smallRenderProps = {
        rowHeight: 120,
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('small', item)

          return {
            itemData: (
              <React.Fragment>
                <Grid container>
                  <Grid item xs style={{ minWidth: 0 }}>
                    <Typography variant="body2">{content.data}</Typography>
                    <React.Fragment>
                      <Typography variant="body1" noWrap={true}>
                        <b>{content.nomeCliente}</b>
                      </Typography>
                      <Typography variant="body2" noWrap={true}>
                        {content.descricaoVenda}
                      </Typography>
                    </React.Fragment>
                  </Grid>
                  <Grid item align="right">
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                      {content.descricaoValor}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            )
          }
        }
      }

      for (let item of this.state.lista) {
        if (!item.clientePacoteItem) {
          valorTotal += item.valorTotal
        }
        quantidadeTotal++
      }

      descricaoRodape = 'Vendas:'
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} title="Detalhamento" align="center" pageType="basicEntity" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Aguardando Faturamento</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
              {this.state.lista && this.state.lista.length > 0 && (
                <Grid container direction="column" justify="center" alignItems="center" style={{ marginTop: 10 }}>
                  <Grid item>
                    <InputSelect
                      customVariant="naked"
                      nakedLeftPadding={false}
                      value={this.state.visao.id}
                      fullWidth={false}
                      onChange={(event) => {
                        const visao = VisaoEnum.getById(parseInt(event.target.value, 10))
                        this.setState({ visao: visao })
                      }}
                    >
                      {VisaoEnum.values().map((visao) => (
                        <MenuItem key={visao.id} value={visao.id}>
                          {visao.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </Grid>
                  {visao === VisaoEnum.CLIENTE && (
                    <Grid item>
                      <InputSelect
                        customVariant="naked"
                        nakedLeftPadding={false}
                        value={this.state.ordenacao.id}
                        fullWidth={false}
                        marginTop={-6}
                        onChange={(event) => {
                          const ordenacao = OrdenacaoEnum.getById(parseInt(event.target.value, 10))
                          this.setState({ ordenacao: ordenacao })
                        }}
                      >
                        {OrdenacaoEnum.values().map((ordenacao) => (
                          <MenuItem key={ordenacao.id} value={ordenacao.id}>
                            {ordenacao.descricao}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    </Grid>
                  )}
                </Grid>
              )}
            </FloatContent>

            <div>
              <VirtualizedResponsiveTable
                showBackgroundDividers={true}
                key={visao.id + '_' + ordenacao.id}
                scrollElement={dialogContentProps.scrollContainerRef.current}
                itemsPerRequest={30}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="erp"
                endpoint={endPoint}
                getRequestParametersFunction={this.getRequestParameters}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    this.setState({ lista: data.items })
                  }
                }}
                largeRenderProps={largeRenderProps}
                mediumRenderProps={mediumRenderProps}
                smallRenderProps={smallRenderProps}
                emptyListProps={{
                  text: 'Não existem vendas sem fatura para o período informado'
                }}
              />
            </div>

            {quantidadeTotal > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  {descricaoRodape} <b>{quantidadeTotal}</b>
                  <br />
                  Total: <b>{formatarValorMonetario(valorTotal)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

VendasSemNotaDetalhesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendasSemNotaDetalhesDialogPage)
