import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const styles = (theme) => ({
  rootContent: {
    minWidth: '0px !important'
  },
  successMessage: {
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  buttonClose: {
    color: '#ddd'
  }
})

const DEFAULT_MESSAGE = 'Dados salvos com sucesso'

class SnackBarSuccess extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      open: false,
      message: ''
    }
  }

  componentDidMount() {
    this.eventsManager.sub('SnackBarSuccess', (opts) => {
      if (opts.open === undefined) {
        opts.open = true
      }
      if (opts.open !== this.state.open) {
        this.setState({
          open: opts.open,
          message: opts.message
        })
      }
    })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    const { message } = this.state

    let messageContent = (
      <span className={classes.successMessage}>
        <CheckIcon style={{ marginRight: 6 }} />
        {message ? message : DEFAULT_MESSAGE}
      </span>
    )

    return (
      <Snackbar className="needsWillChange" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.open} onClose={this.handleClose} autoHideDuration={2800}>
        <SnackbarContent
          classes={{
            root: classes.rootContent
          }}
          message={messageContent}
          action={[
            <IconButton key="close" className={classes.buttonClose} onClick={this.handleClose}>
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          ]}
        />
      </Snackbar>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

SnackBarSuccess.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SnackBarSuccess)
