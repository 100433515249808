import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import PanelPage from 'support/components/panel/PanelPage'

const styles = (theme) => ({
  itemLista: {
    paddingTop: 4,
    paddingBottom: 4
  }
})

class SobrePanelPage extends Component {
  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} contentMaxWidth={800} title="Sobre o Salão99">
        <Typography variant="body1">
          Com o Salão99 você pode agendar online serviços de beleza e saúde com seus profissionais favoritos. Confira as principais funcionalidades:
        </Typography>
        <br />
        <ul>
          <li className={classes.itemLista}>
            <Typography variant="body2">
              Busque estabelecimento/profissionais que estão perto de você. É possível pesquisar por nome, tipos de serviços, localização e muito mais;
            </Typography>
          </li>
          <li className={classes.itemLista}>
            <Typography variant="body2">
              Agende serviços a qualquer momento! Consulte online a disponibilidade de horários dos profissionais e faça o agendamento diretamente pelo aplicativo;
            </Typography>
          </li>
          <li className={classes.itemLista}>
            <Typography variant="body2">
              Consulte avaliações, fotos e informações importantes caso precise marcar um horário em um estabelecimento que você ainda não conhece;
            </Typography>
          </li>
          <li className={classes.itemLista}>
            <Typography variant="body2">Cancele e remarque agendamentos com muita facilidade.</Typography>
          </li>
        </ul>
        <br />
        <Typography variant="body1">
          Se você é um profissional/empresa que trabalha com hora marcada e deseja fazer parte do Salão99, baixe agora o Salão99 Enterprise, nosso aplicativo de gestão para
          empresas/profissionais.
        </Typography>
      </PanelPage>
    )
  }
}

SobrePanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SobrePanelPage)
