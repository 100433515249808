import React, { Component } from 'react'

import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import TabFotos from 'pages/portal/negocio/TabFotos'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CustomDialog from 'support/components/dialog/CustomDialog'
import Tab from 'support/components/tab/Tab'
import NegocioFotoTipoEnum from 'support/domain/negocio/NegocioFotoTipoEnum'

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 1200,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  titulo: {
    fontSize: '28px'
  },
  tabRoot: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500
  }
})

class NegocioFotosDialog extends Component {
  constructor(props) {
    super(props)

    let fotosGeral = []
    let fotosApresentacao = []
    let fotosPortfolio = []

    if (props.fotos && props.fotos.length > 0) {
      for (let foto of props.fotos) {
        if (foto.tipo.id === NegocioFotoTipoEnum.APRESENTACAO_NEGOCIO.id) {
          fotosApresentacao.push(foto)
        } else if (foto.tipo.id === NegocioFotoTipoEnum.PORTFOLIO.id) {
          fotosPortfolio.push(foto)
        }
      }
      if (fotosApresentacao.length > 0 && fotosPortfolio.length === 0) {
        fotosGeral = fotosApresentacao
      } else if (fotosApresentacao.length === 0 && fotosPortfolio.length > 0) {
        fotosGeral = fotosPortfolio
      }
    }
    this.state = {
      mounted: false,
      tabValue: 0,
      fotosGeral: fotosGeral,
      fotosApresentacao: fotosApresentacao,
      fotosPortfolio: fotosPortfolio
    }
    this.scrollContainerRef = React.createRef()
  }

  componentDidMount = () => {
    this.setState({
      mounted: true
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { tabValue, fotosGeral, fotosApresentacao, fotosPortfolio } = this.state

    return (
      <CustomDialog classes={{ paper: classes.root }} dialogProps={dialogProps}>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent className={classNames('needsWillChange')}>
            {this.state.mounted && (
              <React.Fragment>
                <Grid container alignItems="center" style={{ marginBottom: fotosGeral.length > 0 ? 0 : 8 }}>
                  <Grid item xs>
                    <Typography variant="h6" className={classes.titulo}>
                      Galeria de Fotos
                    </Typography>
                  </Grid>
                  <Grid style={{ marginRight: -8 }}>
                    <IconButton
                      onClick={() => {
                        this.props.handleCloseDialog()
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {fotosGeral.length > 0 && <TabFotos scrollElement={this.scrollContainerRef.current} fotos={fotosGeral} />}
                {fotosGeral.length === 0 && (
                  <React.Fragment>
                    <Tab
                      classes={{
                        tabRoot: classes.tabRoot
                      }}
                      value={tabValue}
                      onChange={(event, value) => {
                        this.setState({ tabValue: value })
                      }}
                      tabs={[
                        { label: 'Apresentação', labelXs: 'Apresentação' },
                        { label: 'Portfólio / Trabalhos', labelXs: 'Portfólio / Trabalhos' }
                      ]}
                      tabsTextColor={'primary'}
                    ></Tab>
                    <div>
                      {tabValue === 0 && <TabFotos scrollElement={this.scrollContainerRef.current} fotos={fotosApresentacao} />}
                      {tabValue === 1 && <TabFotos scrollElement={this.scrollContainerRef.current} fotos={fotosPortfolio} />}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }
}

NegocioFotosDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NegocioFotosDialog)
