import { ChangeEvent, FocusEvent, RefObject, useEffect, useState } from 'react'

import { MenuItem, Slide } from '@material-ui/core'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'

import { cnpjMaskRegex, cpfMaskRegex } from './regexes'

const companyTypes = [
  'Selecione o tipo de empresa do seu negócio',
  'Micro empreendedor individual (MEI)',
  'Empresário individual',
  'Sociedade limitada (Ltda.)',
  'Empresa Individual de Responsabilidade Limitada (Eireli)',
  'Microempresa (ME)'
]
const cpfLength = 11
const cnpjLength = 14
const cnpjFormatedLength = 18

type UniqueIdentifierProps = {
  uniqueIdentifierRef: RefObject<HTMLInputElement>
  registeredNameRef: RefObject<HTMLInputElement>
  companyType: number
  onTypeSelect: (companyType: number) => void
  errorsMap: (key: string) => string | null
  ajaxing: boolean
  defaultValues: {
    cpfOuCnpj: string
    tipo: number
    razaoSocial: string
  }
}

function UniqueIdentifier({ uniqueIdentifierRef, registeredNameRef, companyType, onTypeSelect, errorsMap, ajaxing, defaultValues }: UniqueIdentifierProps) {
  const [isCnpj, setIsCnpj] = useState(false)
  const [uniqueIdentifier, setUniqueIdentifier] = useState('')

  const applyMask = (value: string) => {
    setIsCnpj(false)
    const cleanValue = value.replace(/[^\d]/g, '')
    if (cleanValue.length === cpfLength) {
      const maskedValue = cleanValue.replace(cpfMaskRegex, '$1.$2.$3-$4')
      setUniqueIdentifier(maskedValue)
    }
    if (cleanValue.length === cnpjLength) {
      setIsCnpj(true)
      const maskedValue = cleanValue.replace(cnpjMaskRegex, '$1.$2.$3/$4-$5')
      setUniqueIdentifier(maskedValue)
    }
  }

  const changeValue = (value: string) => {
    const allowedDigits = new RegExp(/^[\d\/\.\-]*$/)
    const lastDigit = value[value.length - 1]
    const digitNotAllowed = !allowedDigits.test(lastDigit)

    if (!value) {
      setUniqueIdentifier('')
    } else if (value.length > cnpjFormatedLength || digitNotAllowed) {
      return
    } else {
      setUniqueIdentifier(value)
    }
  }

  useEffect(() => {
    applyMask(defaultValues.cpfOuCnpj)
    onTypeSelect(defaultValues.tipo)
    // This implementation was proposital, the render must be triggered just on didMount cicle render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <InputText
        idname="cpfOuCnpj"
        customVariant="outlined-small"
        shrink={false}
        autoFocus={true}
        disabled={ajaxing}
        errorMessage={errorsMap('cpfOuCnpj')}
        label="CNPJ/CPF"
        inputRef={uniqueIdentifierRef}
        value={uniqueIdentifier}
        onChange={(e: ChangeEvent<HTMLInputElement>) => changeValue(e.target.value)}
        onBlur={(e: FocusEvent<HTMLInputElement>) => applyMask(e.target.value)}
        style={{ marginTop: 16 }}
      />
      {isCnpj && (
        <>
          <Slide direction="right" in={isCnpj} mountOnEnter unmountOnExit>
            <InputText
              idname="razaoSocial"
              customVariant="outlined-small"
              shrink={false}
              autoFocus={true}
              disabled={ajaxing}
              errorMessage={errorsMap('razaoSocial')}
              label="Razão Social"
              inputRef={registeredNameRef}
              style={{ marginTop: 16 }}
              defaultValue={defaultValues.razaoSocial}
            />
          </Slide>
          <Slide direction="right" in={isCnpj} mountOnEnter unmountOnExit>
            <InputSelect
              customVariant="outlined-small"
              value={companyType}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => onTypeSelect(Number(e.target.value))}
              label="Tipo de empresa"
              style={{ marginTop: 16 }}
              errorMessage={errorsMap('tipo')}
              disabled={ajaxing}
            >
              {companyTypes.map((companyType, idx) => (
                <MenuItem key={companyType} value={idx}>
                  {companyType}
                </MenuItem>
              ))}
            </InputSelect>
          </Slide>
        </>
      )}
    </>
  )
}

export default UniqueIdentifier
