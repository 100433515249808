import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import PanelPage from 'support/components/panel/PanelPage'
import { PocketDialog } from './PocketDialog'

const styles = (theme) => ({
  button: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 10,
    marginLeft: 0
  }
})

class ProcessamentosPanelPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lista: [],
      isOpen: false
    }
    this.loaderFunctionsMap = {}
  }
  
  handleOpenDialog = () => {
    this.setState({isOpen: !this.state.isOpen})
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <>
        <PanelPage
          {...others}
          title="Processamentos"
          toolbarActions={{
            actions: [
              {
                icon: <RefreshIcon />,
                handleAction: () => {
                  this.loaderFunctionsMap.load()
                }
              }
            ]
          }}
        >
          {[
            {
              label: 'Atualizar Serviços Mais Agendados',
              call: {
                method: 'post',
                url: 'admin/processamentos.atualizarNegociosVariacoesServicosPopulares'
              }
            },
            {
              label: 'Iniciar Processamento 1',
              call: {
                method: 'post',
                url: 'admin/processamentos.iniciarProcessamento1'
              }
            }
          ].map((processo, index) => (
            <React.Fragment key={index}>
              <Button
                className={classes.button}
                color="secondary"
                variant="outlined"
                onClick={(event) => {
                  openBackableDialog(EntityConfirmationDialog, {
                    parent: this,
                    text: (
                      <span>
                        Você tem certeza que deseja <b>{processo.label}</b>?
                      </span>
                    ),
                    textToConfirm: 'PROCESSAR',
                    cancelButtonLabel: 'VOLTAR',
                    confirmButtonLabel: 'CONFIRMAR',
                    call: {
                      method: processo.call.method,
                      url: processo.call.url,
                      data: processo.call.data,
                      params: processo.call.params,
                      contextoUsuario: 'admin',
                      onSuccess: (response, dialogConfirmationInstance) => {}
                    }
                  })
                }}
              >
                {processo.label}
              </Button>
              <br />
            </React.Fragment>
          ))}
            <Button
              className={classes.button}
              color="secondary"
              variant="outlined"
              onClick={this.handleOpenDialog}
              >
              Reprocessar Pocket
            </Button>
        </PanelPage>
        <PocketDialog isOpen={this.state.isOpen} onOpen={this.handleOpenDialog}/>
    </>
    )
  }
}

ProcessamentosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProcessamentosPanelPage)
