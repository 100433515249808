import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth from '@material-ui/core/withWidth'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import CaixaDialogPage from 'pages/erp/painel/caixa/CaixaDialogPage'
import DadosBasicosCaixaDialogPage from 'pages/erp/painel/caixa/DadosBasicosCaixaDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import AtivacaoFluxoCaixaEnum from 'support/domain/caixa/AtivacaoFluxoCaixaEnum'
import CaixaService from 'support/domain/caixa/CaixaService'
import CaixaStatusEnum from 'support/domain/caixa/CaixaStatusEnum'
import CaixaTipoEnum from 'support/domain/caixa/CaixaTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 0.8,
    maxWidth: 120,
    minWidth: 100
  },
  backgroundColorInherit: {
    backgroundColor: 'inherit'
  },
  nomeColaboradorContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 }),
    marginTop: 0
  }
})

class CaixasPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      title: 'Controle de Caixa',
      ajaxing: false
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCaixa', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  abrirCaixa = () => {
    if (verificarAutorizacao([PA.PODE_ABRIR_PROPRIO_CAIXA])) {
      openBackableDialog(DadosBasicosCaixaDialogPage)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerFecharCaixa = (caixa) => {
    if (verificarAutorizacao([PA.PODE_FECHAR_PROPRIO_CAIXA])) {
      openBackableDialog(EntityConfirmationDialog, {
        title: 'Confirmação',
        text: <span>Você tem certeza que deseja fechar este caixa?</span>,
        cancelButtonLabel: 'VOLTAR',
        confirmButtonLabel: 'FECHAR',
        call: {
          method: 'post',
          url: 'erp/caixas.fecharCaixa',
          contextoUsuario: 'erp',
          data: {
            idCaixa: caixa.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoCaixa')
          }
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.nomeCaixa = CaixaService.getNome(item)

    if (size === 'small') {
      content.data = converterDataIntParaMoment(item.dataInicial).format('DD/MMM/YY')
    } else {
      content.data = converterDataIntParaMoment(item.dataInicial).format('DD/MMM/YYYY')
    }

    content.abertura = formatarValorMonetario(item.fundoCaixa)

    const status = CaixaStatusEnum.getById(item.status.id)
    if (CaixaStatusEnum.ABERTO.id === status.id) {
      const buttonStyle = {}
      let buttonSize = undefined
      if (size === 'small') {
        buttonStyle.marginTop = -6
        buttonStyle.marginRight = -8
        buttonStyle.marginBottom = -4
      } else {
        buttonStyle.marginRight = -6
        buttonStyle.marginLeft = -6
        buttonSize = 'small'
      }

      content.fechamento = (
        <Button
          style={buttonStyle}
          color="secondary"
          size={buttonSize}
          onClick={(event) => {
            event.stopPropagation()
            this.handlerFecharCaixa(item)
          }}
        >
          FECHAR
        </Button>
      )
    } else {
      if (size === 'small') {
        content.fechamento = (
          <Typography variant="body2">
            <b>Fechado</b>
          </Typography>
        )
      } else {
        content.fechamento = 'Fechado'
      }
    }
    content.tipo = CaixaTipoEnum.getById(item.tipo.id).descricaoSimplificada

    return content
  }

  render() {
    const { classes, ...others } = this.props

    const toolbarActions = {
      actions: []
    }

    let title = 'Caixa Avançado'

    if (getSessaoPainel().negocio.carregado && AtivacaoFluxoCaixaEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleFluxoCaixa.id) {
      title = 'Controle de Caixa'
      toolbarActions.actions.push({
        icon: <RefreshIcon />,
        handleAction: () => {
          this.loaderFunctionsMap.load()
        }
      })
      toolbarActions.actions.push({
        icon: <AddIcon />,
        handleAction: this.abrirCaixa,
        'data-testid': 'add-icon'
      })
    }

    return (
      <PanelPage {...others} title={title} toolbarActions={toolbarActions}>
        {getSessaoPainel().negocio.carregado && (
          <React.Fragment>
            {AtivacaoFluxoCaixaEnum.DESABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleFluxoCaixa.id && (
              <div className={classes.paperContent} style={{ textAlign: 'left', marginTop: 8, maxWidth: 720 }}>
                {/*<PointOfSaleIcon2 />
								<Typography variant="h5" style={{fontSize: 20, marginTop: 10}}>O controle avançado de caixa está desabilitado.</Typography>*/}
                <Typography variant="body2" style={{ marginTop: 0 }}>
                  A função de controle avançado de caixa torna o sistema mais complexo e exige ações adicionais para controlar as contas, como abrir e fechar manualmente os caixas
                  todos os dias. Na maioria dos casos, o controle avançado de caixa{' '}
                  <b>
                    <u>não é necessário</u>
                  </b>
                  .
                </Typography>
                <br />
                <Typography variant="body2">Para habilitá-lo, basta entrar em contato conosco através do chat</Typography>
              </div>
            )}
            {AtivacaoFluxoCaixaEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleFluxoCaixa.id && (
              <VirtualizedResponsiveTable
                windowScroller={true}
                showBackgroundDividers={true}
                itemsPerRequest={30}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="erp"
                endpoint="erp/caixas.buscarCaixas"
                getRequestParametersFunction={() => ({})}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    const state = { lista: data.items }
                    this.setState(state)
                  }
                }}
                onClickRow={(item, event) => {
                  openBackableDialog(CaixaDialogPage, { idCaixa: item.id })
                }}
                largeRenderProps={{
                  columns: [
                    { label: 'Data', className: classes.colunaData },
                    { label: 'Caixa', props: { xs: true } },
                    { label: 'Tipo', className: classes.colunaData },
                    { label: 'Abertura', align: 'right', className: classes.colunaData },
                    { label: 'Fechamento', align: 'center', className: classes.colunaData }
                  ],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('large', item)

                    return {
                      itemData: [content.data, content.nomeCaixa, content.tipo, content.abertura, content.fechamento]
                    }
                  }
                }}
                mediumRenderProps={{
                  headerColumnHeight: 60,
                  rowHeight: 80,
                  columns: [
                    { label: 'Data', className: classes.colunaData },
                    { label: 'Caixa', props: { xs: true } },
                    { label: 'Fechamento', align: 'center', className: classes.colunaData }
                  ],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('large', item)

                    return {
                      itemData: [
                        content.data,
                        <React.Fragment>
                          <Typography variant="body2" noWrap={true}>
                            <b>{content.nomeCaixa}</b>
                          </Typography>
                          <Typography variant="body2" style={{ fontSize: 13 }}>
                            Tipo: {content.tipo}
                          </Typography>
                          <Typography variant="body2" style={{ fontSize: 13 }}>
                            Abertura: {content.abertura}
                          </Typography>
                        </React.Fragment>,
                        content.fechamento
                      ]
                    }
                  }
                }}
                smallRenderProps={{
                  rowHeight: 80,
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('small', item)

                    return {
                      itemData: (
                        <Grid data-testid='customer-check-out' container className={classes.backgroundColorInherit}>
                          <Grid item xs className={classes.backgroundColorInherit} style={{ minWidth: 0 }}>
                            <Typography variant="body2" noWrap={true}>
                              <b>{content.data}</b>
                            </Typography>
                            <Typography variant="body2" className={classes.nomeColaboradorContainerSmall}>
                              {content.nomeCaixa}
                            </Typography>
                            <Typography variant="body2" noWrap={true} style={{ fontSize: 13 }}>
                              Abertura: {content.abertura}
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingLeft: 8, textAlign: 'right' }}>
                            {content.fechamento}
                            <Typography variant="body2" style={{ fontSize: 13 }}>
                              {content.tipo}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    }
                  }
                }}
                emptyListProps={{
                  text: 'Nenhum caixa foi encontrado'
                }}
              />
            )}
          </React.Fragment>
        )}
      </PanelPage>
    )
  }
}

CaixasPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(CaixasPanelPage))
