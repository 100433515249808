import PortalPagesUtil from 'pages/portal/PortalPagesUtil'

const MIN_ID_HASH_LENGTH = 12

function mapearPlanosPrecosPorPlano(planosPrecos) {
  const map = {}
  for (let planoPreco of planosPrecos) {
    map[planoPreco.plano.id] = planoPreco
  }
  return map
}

function converterHashParaFormatoReduzido(hash) {
  return hash.substr(hash.length - MIN_ID_HASH_LENGTH)
}

function gerarNegocioIdWithHash(negocio) {
  return negocio.id + '-' + negocio.hash
}

function gerarUrlAgendamentoOnline(negocio, sufixoUrlPortal) {
  if (sufixoUrlPortal) {
    return '/' + sufixoUrlPortal
  } else {
    return PortalPagesUtil.gerarUrlNegocio(negocio)
  }
}

const functionsObject = {
  mapearPlanosPrecosPorPlano: mapearPlanosPrecosPorPlano,
  converterHashParaFormatoReduzido: converterHashParaFormatoReduzido,
  gerarNegocioIdWithHash: gerarNegocioIdWithHash,
  gerarUrlAgendamentoOnline: gerarUrlAgendamentoOnline
}

export default functionsObject
