import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import AdquirenteInputDialogSelect from 'pages/erp/painel/formapagamento/input/AdquirenteInputDialogSelect'
import BandeiraInputDialogSelect from 'pages/erp/painel/formapagamento/input/BandeiraInputDialogSelect'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import ArrayUtil from 'support/util/ArrayUtil'
import { createEnum } from 'support/util/EnumUtil'
import FormUtil from 'support/util/FormUtil'
import { createErrorsMap } from 'support/util/FormUtil'
import { InputPercentWithSuffixFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  tabelaParcelamento: {
    width: 200
  }
})

const PermitirParcelamentoEnum = createEnum({
  NAO: { id: 1, descricao: 'Não', valor: false },
  SIM: { id: 2, descricao: 'Sim', valor: true }
})

class FormaPagamentoConfiguracaoCreditoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: this.salvar
          }
        ]
      }
    }

    this.autocompleteAdquirente = {}
    this.autocompleteBandeira = {}
    this.containerAVistafunctionsMap = {}
    this.containerAPrazofunctionsMap = {}
    this.focarAdquirente = true
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idConfiguracaoCartao) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })

      getAPI({
        url: 'erp/formaspagamento.buscarConfiguracaoCartao',
        params: {
          idConfiguracaoCartao: this.props.idConfiguracaoCartao
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.adquirente = response.data.formaPagamentoAdquirenteBandeira.formaPagamentoAdquirente.adquirente

          if (!this.props.duplicar) {
            this.bandeira = response.data.formaPagamentoAdquirenteBandeira.bandeira
            this.idConfiguracaoCartaoCredito = response.data.configuracaoCartaoCredito.id
          } else {
            this.focarAdquirente = false
          }

          this.configuracoesAVista = {}
          this.configuracoesAVista.taxaVista = response.data.configuracaoCartaoCredito.taxaVista

          this.configuracoesAPrazo = {}
          const parcelas = response.data.configuracaoCartaoCredito.parcelas
          this.configuracoesAPrazo.parcelas = parcelas
          this.configuracoesAPrazo.permitirParcelamento = parcelas && parcelas.length > 0 ? true : false
          if (this.configuracoesAPrazo.permitirParcelamento) {
            this.configuracoesAPrazo.parcelas = parcelas
          }

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      title: this.props.duplicar ? 'Duplicar' : this.bandeira ? 'Alterar' : 'Criar',
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  getDadosConfiguracao = () => {
    const formaPagamento = {}

    if (this.props.idBandeira && !this.props.duplicar) {
      formaPagamento.idBandeira = this.props.idBandeira
    }

    formaPagamento.formaPagamento = { id: this.props.formaPagamento.id }

    formaPagamento.adquirente = {
      id: this.autocompleteAdquirente.getValue()
    }

    formaPagamento.bandeira = {
      id: this.autocompleteBandeira.getValue()
    }

    formaPagamento.taxaAVista = FormUtil.convertStringToNumber(this.containerAVistafunctionsMap.getTaxaAVista())
    formaPagamento.permitirParcelamento = this.containerAPrazofunctionsMap.getPermitirParcelamento()
    if (formaPagamento.permitirParcelamento) {
      formaPagamento.parcelamentos = this.containerAPrazofunctionsMap.getParcelamentos()
    }

    formaPagamento.idConfiguracaoCartaoCredito = this.idConfiguracaoCartaoCredito
    return formaPagamento
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: FormUtil.createErrorsMap()
    }))

    postAPI({
      url: 'erp/formaspagamento.persistirConfiguracaoCartaoCredito',
      data: this.getDadosConfiguracao(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoFormaPagamentoCartao')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        const errorsMap = createErrorsMap(response.data.errors)
        this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formConfiguracaoFormaPagamento')

        if (errorsMap('configuracaoCartaoCredito')) {
          openBackableDialog(MessageDialog, {
            title: 'Configuração existente',
            text: errorsMap('configuracaoCartaoCredito')
          })
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state
    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formConfiguracaoFormaPagamento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <AdquirenteInputDialogSelect
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('adquirente')}
                placeholder="Selecione a maquininha"
                defaultValue={this.adquirente}
                functionsMap={this.autocompleteAdquirente}
              />

              <BandeiraInputDialogSelect
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('bandeira')}
                placeholder="Selecione a bandeira"
                defaultValue={this.bandeira}
                functionsMap={this.autocompleteBandeira}
              />

              <ConfiguracoesAVista errorsMap={errorsMap} classes={classes} functionsMap={this.containerAVistafunctionsMap} values={this.configuracoesAVista} />

              <ConfiguracoesAPrazo errorsMap={errorsMap} classes={classes} functionsMap={this.containerAPrazofunctionsMap} values={this.configuracoesAPrazo} />

              <FormUtil.HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

class ConfiguracoesAVista extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.inputTaxaAVista = React.createRef()
    this.diasMesSelecionados = []
    this.diasSemanaSelecionados = []
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap['getTaxaAVista'] = () => {
        if (this.inputTaxaAVista.current.value) {
          return this.inputTaxaAVista.current.value === '' ? '0' : this.inputTaxaAVista.current.value.replace('%', '')
        } else {
          return '0'
        }
      }
    }
  }

  render() {
    const { errorsMap, values } = this.props
    return (
      <div>
        <InputText
          marginTop={true}
          id="xtaxaAVista"
          name="xtaxaAVista"
          customVariant="outlined-small"
          shrink={false}
          defaultValue={values ? values.taxaVista : undefined}
          disabled={this.state.ajaxing}
          inputComponent={InputPercentWithSuffixFormat}
          inputProps={{
            minValue: 0,
            maxValue: 100
          }}
          errorMessage={errorsMap('taxaAVista')}
          label="Taxa para vendas à vista"
          inputRef={this.inputTaxaAVista}
        />
      </div>
    )
  }
}

class ConfiguracoesAPrazo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      permitirParcelamento: this.props.values ? this.props.values.permitirParcelamento : PermitirParcelamentoEnum.NAO.valor
    }
    this.containerParcelamentoFunctionsMap = {}
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap['getPermitirParcelamento'] = () => {
        return this.state.permitirParcelamento
      }

      this.props.functionsMap['getParcelamentos'] = () => {
        if (Object.keys(this.containerParcelamentoFunctionsMap).length > 0) {
          return this.containerParcelamentoFunctionsMap.getParcelamentos()
        }

        return null
      }
    }
  }

  render() {
    const { classes, errorsMap } = this.props

    return (
      <div>
        <SectionSeparator title="Configuração de vendas parcelado" />
        <InputSelect
          value={this.state.permitirParcelamento}
          customVariant="outlined-small"
          shrink={false}
          label="Permitir Parcelamento"
          onChange={(event) => {
            this.setState({ permitirParcelamento: event.target.value })
          }}
          errorMessage={errorsMap('permitirParcelamento')}
        >
          {PermitirParcelamentoEnum.values().map((permitirParcelamento) => (
            <MenuItem key={permitirParcelamento.id} value={permitirParcelamento.valor}>
              {permitirParcelamento.descricao}
            </MenuItem>
          ))}
        </InputSelect>

        {this.state.permitirParcelamento && (
          <Parcelamentos errorsMap={errorsMap} classes={classes} functionsMap={this.containerParcelamentoFunctionsMap} values={this.props.values} />
        )}
      </div>
    )
  }
}

class Parcelamentos extends Component {
  constructor(props) {
    super(props)

    let parcelas = []
    if (this.props.values && this.props.values.parcelas) {
      parcelas = [{ numero: 1 }].concat(this.props.values.parcelas)
      this.ordenarParcelas(parcelas)
      for (let parcela of parcelas) {
        parcela.taxaInputRef = React.createRef()
      }
    }

    this.state = {
      ajaxing: false,
      parcelas: parcelas
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap['getParcelamentos'] = () => {
        const parcelamentos = []
        for (let parcelamento of this.state.parcelas) {
          if (parcelamento.numero !== 1) {
            let parcela = {
              taxa: 0
            }
            if (parcelamento.taxaInputRef.current) {
              parcela.taxa = parcelamento.taxaInputRef.current.value === '' ? 0 : FormUtil.convertStringToNumber(parcelamento.taxaInputRef.current.value.replace('%', ''))
            }
            parcela.numero = parcelamento.numero
            parcelamentos.push(parcela)
          }
        }

        return parcelamentos
      }
    }
  }

  ordenarParcelas = (parcelas) => {
    if (!parcelas) {
      return parcelas
    }

    parcelas.sort((i1, i2) => {
      const numeroParcela1 = i1.numero
      const numeroParcela2 = i2.numero

      if (numeroParcela1 > numeroParcela2) {
        return 1
      } else if (numeroParcela1 < numeroParcela2) {
        return -1
      } else {
        return 0
      }
    })
  }

  handlerChangeParcelamento = (event) => {
    let numeroParcelas = event.target.value
    if (numeroParcelas === '') {
      numeroParcelas = 0
    } else {
      numeroParcelas = parseInt(numeroParcelas, 10)
    }

    const parcelas = this.state.parcelas.slice()

    if (parcelas.length + 1 > numeroParcelas) {
      for (let i = parcelas.length; i >= 0; i--) {
        if (i + 1 > numeroParcelas) {
          parcelas.splice(i, 1)
        }
      }
    } else if (numeroParcelas > parcelas.length) {
      for (let i = parcelas.length + 1; i <= numeroParcelas; i++) {
        parcelas.push({
          taxaInputRef: React.createRef(),
          numero: i
        })
      }
    }

    this.setState({ parcelas: parcelas })
  }

  render() {
    const { classes, errorsMap } = this.props

    return (
      <div>
        <InputSelect
          value={this.state.permitirParcelamento}
          customVariant="outlined-small"
          shrink={false}
          marginTop={true}
          label="Parcelamento máximo permitido"
          defaultValue={this.state.parcelas.length}
          onChange={this.handlerChangeParcelamento}
          errorMessage={errorsMap('numeroParcelas')}
        >
          {ArrayUtil.createFromRange(2, 36).map((numeroParcela) => (
            <MenuItem key={numeroParcela} value={numeroParcela}>
              {numeroParcela}x
            </MenuItem>
          ))}
        </InputSelect>

        <div className={classes.tabelaParcelamento}>
          {this.state.parcelas &&
            this.state.parcelas
              .filter((parcela) => parcela.numero !== 1)
              .map((parcela, indice) => {
                return (
                  <InputText
                    key={parcela.numero}
                    marginTop={true}
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={parcela.taxa}
                    label={'Taxa para ' + parcela.numero + 'x (em %)'}
                    inputComponent={InputPercentWithSuffixFormat}
                    inputProps={{
                      maxValue: 100
                    }}
                    errorMessage={errorsMap('taxa[' + indice + ']')}
                    inputRef={parcela.taxaInputRef}
                  />
                )
              })}
        </div>
      </div>
    )
  }
}

FormaPagamentoConfiguracaoCreditoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FormaPagamentoConfiguracaoCreditoDialogPage)
