import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ChartLine from 'support/components/chart/ChartLine'
import ChartPie from 'support/components/chart/ChartPie'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  tituloInformacoesFinanceiras: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 })
  },
  colunaServico: {
    flexGrow: 1.0
  },
  colunaValor: {
    maxWidth: 120,
    minWidth: 120
  },
  colunaQuantidade: {
    maxWidth: 120,
    minWidth: 120
  }
})

class RelatorioServicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxingMaisLucrativos: false,
      ajaxingValoresCobradosUltimosMeses: false,
      servicosMaisLucrativosDataInicial: this.colaboradorLocalPreferences.getMomentPreference(
        'RelatorioServicosDialogPageFiltro_MaisLucrativosDataInicial',
        moment().startOf('month')
      ),
      servicosMaisLucrativosDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioServicosDialogPageFiltro_MaisLucrativosDataFinal', moment().endOf('month')),
      servicosMaisLucrativos: []
    }
    this.dadosValoresCobradosUltimosMesesLoaderFunctionsMap = {}
    this.dadosMaisLucrativosLoaderFunctionsMap = {}
  }

  carregarDadosValoresCobradosUltimosMeses = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingValoresCobradosUltimosMeses: true })

    getAPI({
      url: 'erp/relatorios.buscarServicosUltimosMeses',
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingValoresCobradosUltimosMeses: false,
          dadosValoresCobradosUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(
            response.data,
            'Valor Cobrado',
            'valor',
            this.props.theme.palette.chart.list[0],
            (value, name, props) => [
              <span style={{ fontSize: 12 }}>
                {name}: {formatarValorMonetario(value)}
              </span>,
              null
            ]
          ),
          dadosQuantidadeUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(
            response.data,
            'Quantidade de Atendimentos',
            'quantidade',
            this.props.theme.palette.chart.list[1],
            (value, name, props) => [
              <span style={{ fontSize: 12 }}>
                {name}: {value}
              </span>,
              null
            ]
          )
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingValoresCobradosUltimosMeses: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosUltimosMesesParaGrafico = (dadosUltimosMeses, descricao, valueProp, color, tooltipFormatterFunction) => {
    const dadosUltimosMesesPorMes = {}
    if (dadosUltimosMeses && dadosUltimosMeses.length) {
      for (let dadosReceitaUltimosMeses of dadosUltimosMeses) {
        dadosUltimosMesesPorMes[dadosReceitaUltimosMeses.mes.toString()] = dadosReceitaUltimosMeses
      }
    }

    const agora = moment()
    const dataNavegacao = agora.clone().startOf('month').subtract(11, 'month')
    const criarItem = (mes, valor) => {
      const data = moment(mes.toString(), 'YYYYMM')
      return {
        mes: data.format('MMM/YY'),
        vl: valor === null || valor === undefined ? 0 : valor
      }
    }

    const data = []
    while (true) {
      const mesAtual = dataNavegacao.format('YYYYMM')
      const dadosReceitaUltimosMeses = dadosUltimosMesesPorMes[mesAtual]
      if (dadosReceitaUltimosMeses) {
        data.push(criarItem(mesAtual, dadosReceitaUltimosMeses[valueProp]))
      } else {
        data.push(criarItem(mesAtual, 0))
      }
      if (dataNavegacao.isSame(agora, 'month')) {
        break
      } else {
        dataNavegacao.add(1, 'month')
      }
    }
    return {
      xAxis: {
        dataKey: 'mes',
        height: 80
      },
      tooltip: {
        formatter: tooltipFormatterFunction
      },
      items: [
        {
          name: descricao,
          color: color,
          shortName: descricao,
          dataKey: 'vl'
        }
      ],
      data: data
    }
  }

  carregarMaisLucrativos = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingMaisLucrativos: true })

    getAPI({
      url: 'erp/relatorios.buscarServicosMaisLucrativos',
      params: {
        dataInicial: converterMomentParaDataInt(this.state.servicosMaisLucrativosDataInicial),
        dataFinal: converterMomentParaDataInt(this.state.servicosMaisLucrativosDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxingMaisLucrativos: false,
          servicosMaisLucrativos: response.data,
          dadosTopMaisLucrativos: this.prepararDadosTopMaisLucrativos(response.data)
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingMaisLucrativos: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosTopMaisLucrativos = (items) => {
    const dados = []
    for (let item of items) {
      if (dados.length < 6) {
        dados.push({
          name: (
            <span>
              <b>{item.descricao}</b> - Qtd: {item.quantidade}, {formatarValorMonetario(item.valor)}
            </span>
          ),
          value: item.valor
        })
      } else if (dados.length === 6) {
        dados.push({
          name: (
            <span>
              <b>Outros</b> - Qtd: {item.quantidade}, {formatarValorMonetario(item.valor)}
            </span>
          ),
          value: item.valor,
          quantidade: item.quantidade
        })
      } else {
        const ultimoItem = dados[dados.length - 1]
        ultimoItem.value = ultimoItem.value + item.valor
        ultimoItem.quantidade = ultimoItem.quantidade + item.quantidade
        ultimoItem.name = (
          <span>
            <b>Outros</b> - Qtd: {ultimoItem.quantidade}, {formatarValorMonetario(ultimoItem.value)}
          </span>
        )
      }
    }
    return dados
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.descricao = item.descricao
    content.quantidade = item.quantidade.toString()
    content.valor = formatarValorMonetario(item.valor, { prefix: false })
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const {
      ajaxingValoresCobradosUltimosMeses,
      ajaxingMaisLucrativos,
      dadosValoresCobradosUltimosMeses,
      dadosQuantidadeUltimosMeses,
      servicosMaisLucrativosDataInicial,
      servicosMaisLucrativosDataFinal
    } = this.state

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.servicosMaisLucrativos) {
      valorTotal += item.valor
      quantidadeTotal += item.quantidade
    }

    let toolbarActions = null
    if (!ajaxingMaisLucrativos && !ajaxingValoresCobradosUltimosMeses) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.dadosValoresCobradosUltimosMesesLoaderFunctionsMap.reload()
              this.dadosMaisLucrativosLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let servicosMaisLucrativosLoadKey =
      converterMomentParaDataInt(this.state.servicosMaisLucrativosDataInicial).toString() + '-' + converterMomentParaDataInt(this.state.servicosMaisLucrativosDataFinal).toString()

    return (
      <DialogPage
        {...dialogProps}
        title="Serviços"
        align="center"
        contentMaxWidth={1200}
        pageType="basicEntity"
        ajaxing={ajaxingMaisLucrativos || ajaxingValoresCobradosUltimosMeses}
        toolbarActions={toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.tituloInformacoesFinanceiras}>
                Gráfico de Serviços Realizados
              </Typography>
            </FloatContent>

            <ContentWithPreload functionsMap={this.dadosValoresCobradosUltimosMesesLoaderFunctionsMap} loadFunction={this.carregarDadosValoresCobradosUltimosMeses}>
              {() => (
                <div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 24 }}>
                    <ChartLine data={dadosValoresCobradosUltimosMeses} height={300} />
                  </div>
                  <div style={{ width: '100%', paddingBottom: 8, paddingTop: 24 }}>
                    <ChartLine data={dadosQuantidadeUltimosMeses} height={300} />
                  </div>
                </div>
              )}
            </ContentWithPreload>

            <div style={{ padding: '34px 24px 16px 24px', textAlign: 'center' }}>
              <Typography variant="h5" align="center" className={classes.tituloInformacoesFinanceiras}>
                Serviços Mais Lucrativos
              </Typography>
              <div style={{ display: 'inline-block' }}>
                <DateRangeNavigator
                  expanded={false}
                  defaultValue={{
                    tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioServicosDialogPageFiltro_DadosFinanceirosTipoIntervalo', 'mes'),
                    dataInicial: servicosMaisLucrativosDataInicial,
                    dataFinal: servicosMaisLucrativosDataFinal
                  }}
                  onChange={(data) => {
                    this.colaboradorLocalPreferences.setPreference('RelatorioServicosDialogPageFiltro_DadosFinanceirosTipoIntervalo', data.tipoIntervalo.nome)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioServicosDialogPageFiltro_MaisLucrativosDataInicial', data.dataInicial)
                    this.colaboradorLocalPreferences.setMomentPreference('RelatorioServicosDialogPageFiltro_MaisLucrativosDataFinal', data.dataFinal)
                    this.setState({
                      servicosMaisLucrativosDataInicial: data.dataInicial,
                      servicosMaisLucrativosDataFinal: data.dataFinal
                    })
                  }}
                />
              </div>
            </div>

            <ContentWithPreload functionsMap={this.dadosMaisLucrativosLoaderFunctionsMap} loadFunction={this.carregarMaisLucrativos} loadKey={servicosMaisLucrativosLoadKey}>
              {() => (
                <React.Fragment>
                  <div style={{ width: '100%', paddingBottom: 32, paddingTop: 32 }}>
                    <ChartPie
                      data={{
                        items: this.state.dadosTopMaisLucrativos
                      }}
                    />
                  </div>

                  {this.state.servicosMaisLucrativos && this.state.servicosMaisLucrativos.length > 0 && (
                    <React.Fragment>
                      <div>
                        <VirtualizedResponsiveTable
                          showBackgroundDividers={true}
                          scrollElement={dialogContentProps.scrollContainerRef.current}
                          items={this.state.servicosMaisLucrativos}
                          largeRenderProps={{
                            columns: [
                              { label: 'Servico', className: classes.colunaServico, props: { xs: true } },
                              { label: 'Quantidade', className: classes.colunaQuantidade, align: 'right', horizontalPadding: 'small' },
                              { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item)

                              return {
                                itemData: [content.descricao, content.quantidade, content.valor]
                              }
                            }
                          }}
                          smallRenderProps={{
                            rowHeight: 112,
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('small', item)

                              return {
                                itemData: (
                                  <React.Fragment>
                                    <Typography variant="body2" className={classes.descricaoContainerSmall}>
                                      <b>{content.descricao}</b>
                                    </Typography>
                                    <Grid container style={{ marginTop: 4 }}>
                                      <Grid item xs>
                                        <Typography variant="body2">{content.valor}</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="body2">Qtd.: {content.quantidade}</Typography>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                )
                              }
                            }
                          }}
                          emptyListProps={{
                            text: 'Não existem Serviços para o período informado'
                          }}
                        />
                      </div>

                      {quantidadeTotal > 1 && (
                        <FloatContent container="paper" context="DialogPage" type="tableSummary">
                          <Typography variant="body2">
                            Serviços: <b>{quantidadeTotal}</b>
                            <br />
                            Total: <b>{formatarValorMonetario(valorTotal)}</b>
                            <br />
                          </Typography>
                        </FloatContent>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioServicosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioServicosDialogPage)
