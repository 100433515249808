import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import AtendimentoDialogPage from 'pages/erp/painel/atendimento/AtendimentoDialogPage'
import AtendimentosResponsiveTable from 'pages/erp/painel/atendimento/table/AtendimentosResponsiveTable'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDateParaHorarioInt } from 'support/util/TimeConverter'

const styles = (theme) => ({
  botaoAdicionar: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8
    }
  }
})

class FilaEsperaPanelPageContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lista: []
    }
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('RecarregarAtendimentos', (props) => {
      this.loaderFunctionsMap.load()
    })
    this.eventsManager.sub('ManipulacaoAtendimento', (props) => {
      const { lista: atendimentos } = this.state
      if (props.action === 'update' || props.action === 'create') {
        if (AtendimentoTipoEnum.ORDEM_CHEGADA.id === props.atendimentoParaCalendario.tipo.id) {
          let atendimentoJaEstaNaLista = false
          for (let i = 0; i < atendimentos.length; i++) {
            const atendimento = atendimentos[i]
            if (atendimento.id === props.atendimentoParaCalendario.id) {
              atendimentoJaEstaNaLista = true
              atendimentos[i] = props.atendimentoParaCalendario
              break
            }
          }
          if (atendimentoJaEstaNaLista === false) {
            atendimentos.push(props.atendimentoParaCalendario)
          }
          this.prepararAtendimentos(atendimentos)
          for (let i = 0; i < atendimentos.length; i++) {
            const atendimento = atendimentos[i]
            atendimento._update = true
          }
          this.setState({ atendimentos: atendimentos })
        }
      } else if (props.action === 'inicioAtendimento') {
        for (let i = 0; i < atendimentos.length; i++) {
          const atendimento = atendimentos[i]
          if (atendimento.id === props.atendimentoParaCalendario.id) {
            atendimentos.splice(i, 1)
            break
          }
        }
        for (let i = 0; i < atendimentos.length; i++) {
          const atendimento = atendimentos[i]
          atendimento._update = true
        }
        this.setState({ atendimentos: atendimentos })
      }
    })
    this.eventsManager.sub(['CancelamentoVenda', 'RemocaoVenda'], (props) => {
      const { lista: atendimentos } = this.state
      for (let i = 0; i < atendimentos.length; i++) {
        const atendimento = atendimentos[i]
        if (atendimento) {
          if (atendimento.venda.id === props.venda.id) {
            atendimentos.splice(i, 1)
            break
          }
        }
      }
      for (let i = 0; i < atendimentos.length; i++) {
        const atendimento = atendimentos[i]
        atendimento._update = true
      }
      this.setState({ atendimentos: atendimentos })
    })
  }

  prepararAtendimentos = (atendimentos) => {
    if (atendimentos) {
      atendimentos.sort(function (a, b) {
        if (a.dataHorarioEntradaFila < b.dataHorarioEntradaFila) return -1
        if (a.dataHorarioEntradaFila > b.dataHorarioEntradaFila) return 1
        if (a.id < b.id) return -1
        if (a.id > b.id) return 1
        return 0
      })
    }
  }

  cadastrarAtendimentoOrdemChegada = () => {
    if (verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
      openBackableDialog(AtendimentoDialogPage, {
        data: new Date(),
        tipoAtendimentoPreSelecionado: AtendimentoTipoEnum.ORDEM_CHEGADA,
        horarioPrePreenchido: converterDateParaHorarioInt(new Date())
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const { lista } = this.state
    const { classes } = this.props

    return (
      <div style={{ margin: '4px 0px', position: 'relative' }}>
        <Grid container alignItems="center" style={{ marginBottom: lista && lista.length > 0 ? 5 : -2, marginTop: 2 }}>
          <Grid item xs></Grid>
          <Grid item>
            <Button style={{ marginRight: -8 }} color="secondary" className={classes.botaoAdicionar} onClick={this.cadastrarAtendimentoOrdemChegada}>
              ADICIONAR
            </Button>
          </Grid>
        </Grid>

        <AtendimentosResponsiveTable
          contexto="filaespera"
          itemsPerRequest={20}
          contextoUsuario="erp"
          endpoint="erp/atendimentos.buscarAtendimentosFilaEspera"
          items={lista}
          loaderFunctionsMap={this.loaderFunctionsMap}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          emptyListProps={{
            padding: 'small',
            text: 'Fila de espera vazia'
          }}
        />
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

FilaEsperaPanelPageContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FilaEsperaPanelPageContent)
