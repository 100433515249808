import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import DeleteIcon from '@material-ui/icons/Delete'
import EventIcon from '@material-ui/icons/Event'
import LabelIcon from '@material-ui/icons/Label'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import PersonIcon from '@material-ui/icons/Person'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined'
import ExibicaoConflitoHorariosDialog from 'pages/erp/painel/atendimento/ExibicaoConflitoHorariosDialog'
import DuracaoInputSelect from 'pages/erp/painel/atendimento/select/DuracaoInputSelect'
import ClienteInputDialogSelect from 'pages/erp/painel/cliente/input/ClienteInputDialogSelect'
import RedirecionadorFluxoPosCadastroVendaDialog from 'pages/erp/painel/cliente/RedirecionadorFluxoPosCadastroVendaDialog'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import DescontoInputSelect from 'pages/erp/painel/configuracoes/desconto/input/DescontoInputSelect'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import InputServico from 'pages/erp/painel/servico/input/InputServico'
import VariacaoServicoProdutosDialogPage from 'pages/erp/painel/servico/VariacaoServicoProdutosDialogPage'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { setBannerJourney } from 'support/components/campanha/banner'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import LoadingDialog from 'support/components/dialog/preconstructed/LoadingDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputHorario from 'support/components/input/InputHorario'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import Repeticao from 'support/components/repeticao/Repeticao'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import DescontoDisponibilidadeEnum from 'support/domain/desconto/DescontoDisponibilidadeEnum'
import DescontoService from 'support/domain/desconto/DescontoService'
import RepeticaoService from 'support/domain/repeticao/RepeticaoService'
import TipoUnidadeMedida from 'support/domain/servico/TipoUnidadeMedida'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { formatarValorCalculo } from 'support/util/CalculoFormatter'
import { converterDataIntParaMoment, converterMomentParaDataInt, converterTudoParaMoment } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { logCORE } from 'support/util/Logger'
import { InputMoneyFormat } from 'support/util/Masks'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { converterHorarioIntParaMinutos, converterMinutosParaHorarioInt } from 'support/util/TimeConverter'

const INPUT_CUSTOM_VARIANT = 'outlined-small'
const TEXTO_DIALOG_CONFLITO_HORARIOS = 'O sistema encontrou um ou mais conflitos de horários para este atendimento:'
const QUANTIDADE_REPETICOES_PARA_MOSTRAR_LOADING = 30

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  iconVisibilidadeComissao: {
    verticalAlign: 'middle',
    marginLeft: 8,
    marginRight: 6,
    marginTop: -6,
    marginBottom: -4
  }
})

export const UtilizacaoPacoteEnum = createEnum({
  UTILIZAR_PACOTE: { id: 1, descricao: 'Sem Cobrança' },
  NAO_UTILIZAR_PACOTE: { id: 2, descricao: 'Não Descontar do Pacote' }
})

class AtendimentoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.mounted = true

    this.keyGenerator = 0

    let colaborador = props.colaboradorPreSelecionado

    if (colaborador && colaborador.realizaAtendimentos !== undefined && colaborador.realizaAtendimentos === false) {
      colaborador = null
    }

    if (!colaborador) {
      const colaboradorSessao = getSessaoPainel().colaborador
      if (colaboradorSessao && colaboradorSessao.realizaAtendimentos) {
        colaborador = colaboradorSessao
      }
    }

    this.state = {
      paginaCarregada: false,
      editavel: true,
      processado: false,
      utilizacaoPacote: UtilizacaoPacoteEnum.NAO_UTILIZAR_PACOTE,
      mensagemNaoEditavel: '',
      tipo: props.tipoAtendimentoPreSelecionado ? props.tipoAtendimentoPreSelecionado : AtendimentoTipoEnum.HORA_MARCADA,
      data: converterTudoParaMoment(props.data),
      cliente: props.cliente,
      colaborador: colaborador,
      assistentes: [],
      variacaoServicoProdutos: [],
      preco: null,
      duracao: null,
      atendimento: null,
      title: 'Atendimento',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.clienteFunctionsMap = {}
    this.dataFunctionsMap = {}
    this.colaboradorFunctionsMap = {}
    this.servicoFunctionsMap = {}
    this.horarioFunctionsMap = {}
    this.duracaoFunctionsMap = {}
    this.inputPreco = React.createRef()
    this.inputPrecoFunctionsMap = {}
    this.descontoFunctionsMap = {}
    this.descontoSelectFunctionsMap = {}
    this.inputObservacoes = React.createRef()
    this.repeticaoFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/atendimentos.buscarDadosPrePersistencia',
      params: {
        idCliente: this.state.cliente ? this.state.cliente.id : null,
        idAtendimento: this.props.idAtendimento
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const newState = {
          paginaCarregada: true,
          editavel: true
        }

        if (response.data.pacoteItensDisponiveis) {
          newState.pacoteItensDisponiveis = response.data.pacoteItensDisponiveis
        }

        const atendimento = response.data.atendimento

        if (atendimento && atendimento.id) {
          newState.atendimento = atendimento
          newState.data = converterTudoParaMoment(atendimento.data)
          newState.utilizacaoPacote = atendimento.venda.clientePacoteItem ? UtilizacaoPacoteEnum.UTILIZAR_PACOTE : UtilizacaoPacoteEnum.NAO_UTILIZAR_PACOTE
          newState.cliente = atendimento.venda.cliente
          newState.colaborador = atendimento.venda.colaborador
          if (atendimento.venda.assistentesAtendimento && atendimento.venda.assistentesAtendimento.length > 0) {
            newState.assistentes = []
            atendimento.venda.assistentesAtendimento.sort(function (a, b) {
              if (a.id < b.id) return -1
              if (a.id > b.id) return 1
              return 0
            })
            for (let assistenteAtendimento of atendimento.venda.assistentesAtendimento) {
              newState.assistentes.push(this.criarItemAssistente(assistenteAtendimento))
            }
          }
          this.selecionarServico(atendimento.servico, atendimento.variacaoServico)
          if (atendimento.variacaoServicoProdutos && atendimento.variacaoServicoProdutos.length) {
            atendimento.variacaoServicoProdutos.sort(function (a, b) {
              if (a.id < b.id) return -1
              if (a.id > b.id) return 1
              return 0
            })
            newState.variacaoServicoProdutos = atendimento.variacaoServicoProdutos
          }
          newState.tipo = atendimento.tipo
          newState.duracao = atendimento.duracao
          newState.preco = atendimento.venda.valorSubtotal

          if (StatusVendaEnum.NOTA_ABERTA.id === atendimento.venda.status.id && !atendimento.venda.cancelado) {
            newState.notaAbertaENaoCancelada = true
          }

          if (atendimento.venda.processada === true) {
            newState.processado = true
            if (atendimento.venda.cancelado === true) {
              newState.editavel = false
              newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Este atendimento está cancelado.'
            } else if (newState.notaAbertaENaoCancelada) {
              newState.mensagemNaoEditavel = (
                <span>
                  É possível alterar apenas a{' '}
                  <b>
                    <u>data</u>
                  </b>
                  ,{' '}
                  <b>
                    <u>horário</u>
                  </b>
                  ,{' '}
                  <b>
                    <u>duração</u>
                  </b>
                  ,{' '}
                  <b>
                    <u>preço</u>
                  </b>
                  ,{' '}
                  <b>
                    <u>desconto</u>
                  </b>{' '}
                  ou{' '}
                  <b>
                    <u>observações</u>
                  </b>{' '}
                  deste atendimento porque a fatura #{atendimento.venda.notaVenda.numero} está aberta.
                </span>
              )
            } else {
              newState.mensagemNaoEditavel = (
                <span>
                  É possível alterar apenas a{' '}
                  <b>
                    <u>data</u>
                  </b>
                  ,{' '}
                  <b>
                    <u>horário</u>
                  </b>
                  ,{' '}
                  <b>
                    <u>duração</u>
                  </b>{' '}
                  ou{' '}
                  <b>
                    <u>observações</u>
                  </b>{' '}
                  deste atendimento porque a fatura #{atendimento.venda.notaVenda.numero} está fechada.
                </span>
              )
            }
          } else if (atendimento.venda.excluido === true) {
            newState.editavel = false
            newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Este atendimento foi removido.'
          }
        }

        newState.toolbarActions = {
          actions: [
            {
              label: 'Salvar',
              show: newState.editavel,
              handleAction: () => this.salvar()
            },
            {
              label: 'Voltar',
              show: !newState.editavel,
              handleAction: () => this.props.handleCloseDialog()
            }
          ]
        }

        this.setState(newState)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  generateKeyId = () => {
    return ++this.keyGenerator
  }

  criarItemAssistente = (vendaAtendimentoAssistente, autoSelect) => {
    const obj = {
      key: this.generateKeyId(),
      id: null,
      colaborador: null,
      inputFunctionsMap: {},
      autoSelect: autoSelect
    }

    if (vendaAtendimentoAssistente) {
      obj.id = vendaAtendimentoAssistente.id
      obj.colaborador = vendaAtendimentoAssistente.colaborador
    }

    return obj
  }

  adicionarItemAssistente = () => {
    const assistentes = this.state.assistentes.slice()
    assistentes.push(this.criarItemAssistente())
    this.setState({ assistentes: assistentes })
  }

  removerItemAssistente = (index) => {
    const assistentes = this.state.assistentes.slice()
    assistentes.splice(index, 1)
    this.setState({ assistentes: assistentes })
  }

  getDadosNaoProcessado = () => {
    const { tipo, cliente, utilizacaoPacote } = this.state

    let dados = {}

    if (this.props.contexto === 'AdicionarItensFaturaExistente') {
      dados.nota = this.props.nota
    }

    dados.tipo = { id: tipo.id }
    dados.data = this.dataFunctionsMap.getDataAsInt()

    if (this.props.idAtendimento) {
      dados.id = this.props.idAtendimento
    }

    dados.cliente = cliente

    dados.colaborador = null

    const colaboradorObject = this.colaboradorFunctionsMap.getObject()
    if (colaboradorObject && colaboradorObject.colaborador) {
      dados.colaborador = colaboradorObject.colaborador
    }

    dados.assistentes = []
    for (let assistente of this.state.assistentes) {
      const assistenteParaSalvar = {
        id: assistente.id
      }
      const colaboradorAssistenteObject = assistente.inputFunctionsMap.getObject()
      if (colaboradorAssistenteObject && colaboradorAssistenteObject.colaborador) {
        assistenteParaSalvar.colaborador = colaboradorAssistenteObject.colaborador
      }
      dados.assistentes.push(assistenteParaSalvar)
    }

    dados.variacaoServicoProdutos = []
    for (let variacaoServicoProduto of this.state.variacaoServicoProdutos) {
      const variacaoServicoProdutoParaSalvar = {
        id: variacaoServicoProduto.id,
        produto: variacaoServicoProduto.produto,
        tipoUso: variacaoServicoProduto.tipoUso,
        quantidadeUso: variacaoServicoProduto.quantidadeUso
      }
      dados.variacaoServicoProdutos.push(variacaoServicoProdutoParaSalvar)
    }

    const servicoObject = this.servicoFunctionsMap.getValue()
    if (servicoObject) {
      if (servicoObject.servico) {
        dados.servico = {
          id: servicoObject.servico.id
        }
      }
      if (servicoObject.variacao) {
        dados.variacaoServico = {
          id: servicoObject.variacao.id
        }
      }
    }

    if (tipo.id === AtendimentoTipoEnum.ORDEM_CHEGADA.id) {
      dados.horarioEntradaFila = this.horarioFunctionsMap.getHorarioAsInt()
    } else if (tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id) {
      dados.horarioInicio = this.horarioFunctionsMap.getHorarioAsInt()
    }

    dados.duracao = this.duracaoFunctionsMap.getValue()
    dados.preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)
    dados.observacoes = this.inputObservacoes.current.value
    dados.repeticao = this.repeticaoFunctionsMap.getValues()
    dados.utilizarPacote = utilizacaoPacote.id === UtilizacaoPacoteEnum.UTILIZAR_PACOTE.id

    dados = { ...dados, aplicacaoDesconto: this.descontoFunctionsMap.getValues() }

    return dados
  }

  getDadosProcessado = () => {
    let dados = {}
    dados.id = this.props.idAtendimento
    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.horarioInicio = this.horarioFunctionsMap.getHorarioAsInt()
    dados.duracao = this.duracaoFunctionsMap.getValue()
    dados.observacoes = this.inputObservacoes.current.value

    if (this.state.notaAbertaENaoCancelada) {
      dados.preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)
      dados.aplicacaoDesconto = this.descontoFunctionsMap.getValues()
    }

    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    if (this.state.processado === true) {
      this.salvarProcessado()
    } else {
      this.salvarNaoProcessado()
    }
  }

  salvarProcessado = (ignorarConflitosHorarios) => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = this.getDadosProcessado()
    dados.ignorarConflitosHorarios = ignorarConflitosHorarios

    var dataLayer = window.dataLayer || []

    postAPI({
      url: 'erp/atendimentos.alterarDadosAposProcessamento',
      data: dados,
      requerAutorizacao: true,
      onSuccess: (response) => {
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:attendance',
          eventAction: 'editar-atendimento:callback',
          eventLabel: 'sucesso'
        })

        EventsManager.pub('ManipulacaoAtendimento', {
          atendimento: response.data.atendimento,
          atendimentoParaCalendario: response.data.atendimentoParaCalendario,
          action: 'update'
        })
        EventsManager.pub('ManipulacaoVenda', {
          action: 'update'
        })
        if (this.props.parent) {
          this.props.setShouldCloseParent(true)
        }
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        if (response.code === 400 && response.data.errors.conflitosHorarios) {
          openBackableDialog(ExibicaoConflitoHorariosDialog, {
            descricao: TEXTO_DIALOG_CONFLITO_HORARIOS,
            conflitosHorarios: response.data.errors.conflitosHorarios,
            onConfirm: () => {
              this.salvarProcessado(true)
            }
          })
          this.setState({ ajaxing: false, errorsMap: createErrorsMap() })
        } else {
          this.setState({
            ajaxing: false,
            errorsMap: createErrorsMap(response.data.errors)
          })
          focusFirstElementWithError('formAtendimento')
        }
      },
      onClientError: (response) => {
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:attendance',
          eventAction: 'editar-atendimento:callback',
          eventLabel: 'erro:dados-invalidos'
        })
      },
      onServerError: (response) => {
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:attendance',
          eventAction: 'editar-atendimento:callback',
          eventLabel: 'erro:servidor'
        })
      },
      onClientConnectionError: (response) => {
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:attendance',
          eventAction: 'editar-atendimento:callback',
          eventLabel: 'erro:conexao'
        })
      }
    })
  }

  salvarNaoProcessado = (ignorarConflitosHorarios, preDefinedPersistOptions) => {
    if (!this.state.editavel) {
      openBackableDialog(MessageDialog, {
        title: 'Aviso',
        text: this.state.mensagemNaoEditavel
      })
      return
    }

    this.repeticaoFunctionsMap.exibirSeNecessarioOpcoesPersistencia((persistOptions) => {
      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      const dados = this.getDadosNaoProcessado()

      if (this.props.contexto === 'AdicionarItensFaturaExistente') {
        dados.ignorarConflitosHorarios = true
      } else {
        dados.ignorarConflitosHorarios = ignorarConflitosHorarios
      }

      dados.repeticao = { ...dados.repeticao, ...persistOptions }

      let dialogLoadingPersistindoRepeticoes = null

      if (this.repeticaoFunctionsMap.getValues().habilitada === true) {
        let datasRepeticoes = RepeticaoService.gerarDatasRepeticoes(
          dados.repeticao.unidadeFrequencia,
          dados.repeticao.valorFrequencia,
          converterDataIntParaMoment(dados.data),
          converterDataIntParaMoment(dados.repeticao.dataFinal)
        )
        if (datasRepeticoes && datasRepeticoes.length >= QUANTIDADE_REPETICOES_PARA_MOSTRAR_LOADING) {
          openBackableDialog(LoadingDialog, {
            maxWidth: 264,
            message: (dados.id ? 'Alterando' : 'Criando') + ' as repetições...',
            onCreateInstance: (dialogInstance) => {
              dialogLoadingPersistindoRepeticoes = dialogInstance
            }
          })
        }
      }

      var dataLayer = window.dataLayer || []

      postAPI({
        url: 'erp/atendimentos.persistir',
        data: dados,
        timeout: 0,
        requerAutorizacao: true,
        onSuccess: (response) => {
          dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:attendance',
            eventAction: (this.props.idAtendimento ? 'editar-atendimento' : 'criar-atendimento') + ':callback',
            eventLabel: 'sucesso'
          })

          if (this.props.contexto !== 'AdicionarItensFaturaExistente') {
            EventsManager.pub('ManipulacaoAtendimento', {
              atendimento: response.data.atendimento,
              atendimentoParaCalendario: response.data.atendimentoParaCalendario,
              action: this.props.idAtendimento ? 'update' : 'create'
            })
            EventsManager.pub('ManipulacaoVenda', {
              action: this.props.idAtendimento ? 'update' : 'create'
            })
          }

          if (this.props.contexto === 'CriacaoPainelCliente' && this.state.tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id) {
            const dataParaNovoAtendimento = dados.data
            let horarioParaNovoAtendimento = converterMinutosParaHorarioInt(converterHorarioIntParaMinutos(dados.horarioInicio) + dados.duracao)

            EventsManager.pub('AlterarDataHorarioParaNovoAtendimento', {
              data: dataParaNovoAtendimento,
              horarioPrePreenchido: horarioParaNovoAtendimento
            })

            setBannerJourney('agendamento')

            const abrirDialogRedirecionamento = () => {
              openBackableDialog(RedirecionadorFluxoPosCadastroVendaDialog, {
                parent: this,
                shouldCloseParent: true,
                textoConfirmacaoCadastro: 'Atendimento cadastrado com sucesso!',
                tipoVenda: TipoVendaEnum.ATENDIMENTO,
                venderMaisProps: {
                  data: dataParaNovoAtendimento,
                  tipoAtendimentoPreSelecionado: this.state.tipo,
                  colaboradorPreSelecionado: dados.colaborador,
                  horarioPrePreenchido: horarioParaNovoAtendimento,
                  cliente: dados.cliente,
                  contexto: 'CriacaoPainelCliente'
                },
                setFecharPanelCliente: this.props.setFecharPanelCliente
                  ? this.props.setFecharPanelCliente
                  : () => {
                      this.props.setShouldCloseParent(true)
                    }
              })
            }

            if (dialogLoadingPersistindoRepeticoes) {
              dialogLoadingPersistindoRepeticoes.props.handleCloseDialog({
                onClosedCallback: () => {
                  abrirDialogRedirecionamento()
                }
              })
            } else {
              abrirDialogRedirecionamento()
            }
          } else {
            if (this.props.parent) {
              this.props.setShouldCloseParent(true)
            }

            let executarAcao = () => {
              if (this.props.contexto === 'AdicionarItensFaturaExistente') {
                EventsManager.pub('RecarregarAtendimentos')
                EventsManager.pub('ManipulacaoVendas')
              }

              this.props.handleCloseDialog()
            }

            if (dialogLoadingPersistindoRepeticoes) {
              dialogLoadingPersistindoRepeticoes.props.handleCloseDialog({
                onClosedCallback: () => {
                  executarAcao()
                }
              })
            } else {
              executarAcao()
            }
          }
        },
        onError: (response) => {
          let executarAcao = () => {
            if (response.code === 400 && response.data.errors.conflitosHorarios) {
              openBackableDialog(ExibicaoConflitoHorariosDialog, {
                descricao: TEXTO_DIALOG_CONFLITO_HORARIOS,
                conflitosHorarios: response.data.errors.conflitosHorarios,
                onConfirm: () => {
                  this.salvarNaoProcessado(true, persistOptions)
                }
              })
              this.setState({ ajaxing: false, errorsMap: createErrorsMap() })
            } else {
              if (response && response.data && response.data.errors) {
                let mensagemErro = null
                if (response.data.errors.erroNotaVenda) {
                  mensagemErro = response.data.errors.erroNotaVenda
                }
                if (mensagemErro) {
                  openBackableDialog(MessageDialog, {
                    maxWidth: 400,
                    title: 'Erro de Validação',
                    text: mensagemErro
                  })
                }
              }

              this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
              focusFirstElementWithError('formAtendimento')
            }
          }

          if (dialogLoadingPersistindoRepeticoes) {
            dialogLoadingPersistindoRepeticoes.props.handleCloseDialog({
              onClosedCallback: () => {
                executarAcao()
              }
            })
          } else {
            executarAcao()
          }
        },
        onClientError: (response) => {
          dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:attendance',
            eventAction: (this.props.idAtendimento ? 'editar-atendimento' : 'criar-atendimento') + ':callback',
            eventLabel: 'erro:dados-invalidos'
          })
        },
        onServerError: (response) => {
          dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:attendance',
            eventAction: (this.props.idAtendimento ? 'editar-atendimento' : 'criar-atendimento') + ':callback',
            eventLabel: 'erro:servidor'
          })
        },
        onClientConnectionError: (response) => {
          dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:attendance',
            eventAction: (this.props.idAtendimento ? 'editar-atendimento' : 'criar-atendimento') + ':callback',
            eventLabel: 'erro:conexao'
          })
        }
      })
    }, preDefinedPersistOptions)
  }

  selecionarServico = (servico, variacao) => {
    const newState = {
      servico: null,
      variacao: null,
      variacaoServicoProdutos: [],
      preco: null,
      duracao: null
    }

    if (servico) {
      newState.servico = servico
    }
    if (variacao) {
      newState.variacao = variacao
      newState.preco = variacao.preco
      newState.duracao = variacao.duracao
      if (variacao.variacaoServicoProdutos) {
        variacao.variacaoServicoProdutos.sort(function (a, b) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })
        newState.variacaoServicoProdutos = []
        for (let variacaoServicoProduto of variacao.variacaoServicoProdutos) {
          variacaoServicoProduto.id = null
          newState.variacaoServicoProdutos.push(variacaoServicoProduto)
        }
      }
    }

    newState.utilizacaoPacote = this.podeUtilizarPacote(servico, variacao) ? UtilizacaoPacoteEnum.UTILIZAR_PACOTE : UtilizacaoPacoteEnum.NAO_UTILIZAR_PACOTE

    this.setState(newState)
  }

  podeUtilizarPacote = (servico, variacao) => {
    if (this.state.pacoteItensDisponiveis) {
      for (let pacoteItemDisponivel of this.state.pacoteItensDisponiveis) {
        if (!pacoteItemDisponivel.dataVencimento || !this.state.data || pacoteItemDisponivel.dataVencimento >= converterMomentParaDataInt(this.state.data)) {
          if (servico && pacoteItemDisponivel.servico.id === servico.id) {
            if (!pacoteItemDisponivel.variacaoServico || (variacao && pacoteItemDisponivel.variacaoServico.id === variacao.id)) {
              if (pacoteItemDisponivel.quantidadeDisponivel > 0 || pacoteItemDisponivel.utilizadoPeloProprioAtendimento) {
                return true
              }
            }
          }
        }
      }
    }

    return false
  }

  recarregarPacoteItensDisponiveis = (idCliente) => {
    if (!this.contadorRecarregarPacoteItensDisponiveis) {
      this.contadorRecarregarPacoteItensDisponiveis = 1
    } else {
      this.contadorRecarregarPacoteItensDisponiveis++
    }

    if (!idCliente) {
      this.setState({ pacoteItensDisponiveis: [] })
      return
    }

    const contador = this.contadorRecarregarPacoteItensDisponiveis

    getAPI({
      url: 'erp/clientes.buscarPacoteItensDisponiveis',
      params: {
        idCliente: idCliente
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        if (contador === this.contadorRecarregarPacoteItensDisponiveis) {
          this.setState({ pacoteItensDisponiveis: response.data })
        }
      },
      sendErroToGenericSnackbar: false,
      repeat: {
        stopFunction: () => {
          return !this.mounted
        }
      }
    })
  }

  render() {
    const podeAlterarPrecoVenda = verificarAutorizacao([PA.PODE_ALTERAR_PRECO_VENDA])

    const dialogProps = extractDialogProps(this.props)
    const { classes, horarioPrePreenchido } = this.props
    const { tipo, data, cliente, atendimento, editavel, processado, ajaxing, utilizacaoPacote, notaAbertaENaoCancelada, errorsMap } = this.state

    const { venda, observacoes, repeticao } = atendimento ? atendimento : {}

    const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
    const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade

    const keyPorServicoEVariacao = (this.state.servico ? this.state.servico.id : 's') + '-' + (this.state.variacao ? this.state.variacao.id : 'v')

    let colaboradorDefaultValue = null

    if (this.state.colaborador) {
      colaboradorDefaultValue = this.state.colaborador
    }

    let horarioPlaceholder = 'Horário'
    let horarioDefaultValue = undefined
    if (tipo.id === AtendimentoTipoEnum.ORDEM_CHEGADA.id) {
      horarioPlaceholder = 'Chegada'
      horarioDefaultValue = new Date()
    }

    if (horarioPrePreenchido !== undefined && horarioPrePreenchido !== null) {
      horarioDefaultValue = horarioPrePreenchido
    }

    let servicoDefaultValue = {}
    let duracaoDefaultValue = undefined
    let precoDefaultValue = undefined
    const descontoDefaultValue = {}

    if (atendimento && atendimento.id) {
      if (tipo.id === AtendimentoTipoEnum.ORDEM_CHEGADA.id) {
        horarioDefaultValue = atendimento.horarioEntradaFila
      } else if (tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id) {
        horarioDefaultValue = atendimento.horarioInicio
      }

      descontoDefaultValue.desconto = venda.desconto
      descontoDefaultValue.descricaoDesconto = venda.descricaoDesconto
      descontoDefaultValue.tipoCalculo = venda.tipoCalculoDesconto
      descontoDefaultValue.valor = venda.valorDesconto
    }

    servicoDefaultValue.servico = this.state.servico
    servicoDefaultValue.variacao = this.state.variacao
    precoDefaultValue = this.state.preco
    duracaoDefaultValue = this.state.duracao

    let usoProdutoContent = null
    if (this.state.variacaoServicoProdutos && this.state.variacaoServicoProdutos.length) {
      usoProdutoContent = [
        <span key="header" style={{ display: 'block' }}>
          <b>Uso de Produtos</b>
          {editavel && !processado && (
            <Link
              color="secondary"
              style={{ display: 'inline-block', padding: '4px 8px', cursor: 'pointer' }}
              onClick={() => {
                openBackableDialog(VariacaoServicoProdutosDialogPage, {
                  servico: this.state.servico,
                  variacaoServico: this.state.variacao,
                  variacaoServicoProdutos: this.state.variacaoServicoProdutos,
                  onChange: (dados) => {
                    this.setState({
                      variacaoServicoProdutos: dados.variacaoServicoProdutos
                    })
                  }
                })
              }}
            >
              <b>Editar</b>
            </Link>
          )}
        </span>
      ]
      for (let variacaoServicoProduto of this.state.variacaoServicoProdutos) {
        let descricao = variacaoServicoProduto.produto.nome
        let quantidade = null
        if (TipoUnidadeMedida.QUANTIDADE.id === variacaoServicoProduto.tipoUso.id) {
          if (variacaoServicoProduto.produto.quantidadeEmbalagem && variacaoServicoProduto.produto.quantidadeEmbalagem > 0) {
            quantidade = variacaoServicoProduto.quantidadeUso * variacaoServicoProduto.produto.quantidadeEmbalagem + ' ' + variacaoServicoProduto.produto.unidadeMedidaEmbalagem
          } else {
            quantidade = variacaoServicoProduto.quantidadeUso + ' unidade' + (variacaoServicoProduto.quantidadeUso !== 1 ? 's' : '')
          }
        } else {
          if (variacaoServicoProduto.produto.quantidadeEmbalagem && variacaoServicoProduto.produto.quantidadeEmbalagem > 0) {
            quantidade = variacaoServicoProduto.quantidadeUso + ' ' + variacaoServicoProduto.produto.unidadeMedidaEmbalagem
          } else {
            quantidade = 'Redefinir configuração'
          }
        }

        usoProdutoContent.push(
          <span key={'item' + usoProdutoContent.length} style={{ display: 'block' }}>
            {'\u2022'} {descricao} - <b>Uso: {quantidade}</b>
          </span>
        )
      }
      usoProdutoContent = <span style={{ marginBottom: 4, display: 'block' }}>{usoProdutoContent}</span>
    }

    let inputServicoHelpMessage = null

    if (!usoProdutoContent) {
      inputServicoHelpMessage = (
        <span
          style={{ display: 'block', padding: 6, margin: '-2px -6px -6px -6px', cursor: 'pointer' }}
          onClick={(event) => {
            event.stopPropagation()
            openBackableDialog(MessageDialog, {
              text: (
                <span style={{ fontSize: 14 }}>
                  Cada serviço pode ter muitas configurações, como horário, valor, descontos, uso de produtos, comissões, observações e repetições. Por este motivo, cada serviço é
                  cadastrado separadamente.
                  <br />
                  <br />
                  Caso exista a necessidade de cadastrar mais de um serviço para o mesmo cliente, com horários seguidos, você pode utilizar o botão{' '}
                  <b>
                    <u>Adicionar Mais Serviços</u>
                  </b>{' '}
                  que é exibido logo após a criação do atendimento. Os sistema abrirá um novo formulário com todos os dados já preenchidos automaticamente, como cliente,
                  colaborador, data e horário de início do próximo serviço. Assim, você só precisará selecionar o <u>serviço seguinte</u> e clica no botão Salvar.
                  <br />
                  <br />
                  Lembre-se de que um <b>Atendimento</b> não representa a conta do cliente. Por mais que os serviços sejam adicionados separadamente, você pode juntá-los em uma
                  única <b>Fatura</b> e registrar o pagamento de todos ao mesmo tempo.
                </span>
              )
            })
          }}
        >
          Desejar adicionar mais serviço? <b>Clique aqui</b>
        </span>
      )
    }

    let pacoteItensDisponiveis = []
    if (this.state.pacoteItensDisponiveis) {
      pacoteItensDisponiveis = this.state.pacoteItensDisponiveis
    }

    if (this.state.atendimento && this.state.atendimento.venda.clientePacoteItem) {
      pacoteItensDisponiveis.push({
        ...this.state.atendimento.venda.clientePacoteItem,
        utilizadoPeloProprioAtendimento: true
      })
    }

    const exibirOpcaoUtilizacaoPacote = this.podeUtilizarPacote(this.state.servico, this.state.variacao)

    let exibirConfiguracoesRepeticao = false
    if (this.props.mostrarConfiguracaoRepeticao === undefined || this.props.mostrarConfiguracaoRepeticao === null || this.props.mostrarConfiguracaoRepeticao === true) {
      exibirConfiguracoesRepeticao = true
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} ajaxing={ajaxing} align="center" contentMaxWidth={570} pageType="basicForm" toolbarActions={this.state.toolbarActions}>
        {this.state.mensagemNaoEditavel && (
          <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
            <Typography variant="body2">{this.state.mensagemNaoEditavel}</Typography>
          </FloatContent>
        )}

        <ContentWithPreload
          loadFunction={this.carregarMain}
          onComponentDidUpdate={() => {
            if (this.inputPrecoFunctionsMap.updateSuperHelpMessage && this.descontoSelectFunctionsMap.updateSuperHelpMessage) {
              this.inputPrecoFunctionsMap.updateSuperHelpMessage()
              this.descontoSelectFunctionsMap.updateSuperHelpMessage()
            }
          }}
        >
          {() => (
            <form id="formAtendimento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)} style={{ margin: '0px' }}>
              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: -10 }}
                icon={<PersonIcon />}
                inputTexts={[
                  {
                    input: (
                      <ClienteInputDialogSelect
                        key="cliente"
                        disabled={ajaxing || !editavel || processado}
                        readOnly={this.props.cliente}
                        defaultValue={cliente}
                        functionsMap={this.clienteFunctionsMap}
                        label="Cliente"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('cliente')}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ cliente: value.cliente })
                            this.recarregarPacoteItensDisponiveis(value.cliente.id)
                          } else {
                            this.setState({ cliente: null })
                            this.recarregarPacoteItensDisponiveis(null)
                          }
                        }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<EventIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputData
                        disabled={ajaxing || !editavel}
                        functionsMap={this.dataFunctionsMap}
                        keyboard={true}
                        id="xdata"
                        name="xdata"
                        errorMessage={errorsMap('data')}
                        defaultValue={data}
                        onChange={(data) => {
                          this.setState({ data: converterTudoParaMoment(data) })
                        }}
                        label={'Data'}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<SupervisedUserCircleIcon />}
                inputTexts={[
                  {
                    input: (
                      <ColaboradorInputDialogSelect
                        key={'colaborador:' + this.state.tipo.id + '_' + keyPorServicoEVariacao}
                        context="atendimento"
                        idname={'xcolaborador'}
                        disabled={ajaxing || !editavel || processado}
                        defaultValue={colaboradorDefaultValue}
                        functionsMap={this.colaboradorFunctionsMap}
                        servico={this.state.servico ? this.state.servico : null}
                        DialogSelectProps={{
                          preProcessItemFunction: (colaborador) => {
                            if (colaborador.realizaAtendimentos === true) {
                              return colaborador
                            }
                            return null
                          }
                        }}
                        label="Profissional"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('colaborador')}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ colaborador: value.colaborador })
                          } else {
                            this.setState({ colaborador: null })
                          }
                        }}
                        style={{ marginTop: 0 }}
                      />
                    )
                  }
                ]}
              />

              {this.state.assistentes.length === 0 && editavel && !processado && (
                <InputHorizontalContainer
                  customVariant={INPUT_CUSTOM_VARIANT}
                  style={{ marginTop: 0, marginBottom: -14 }}
                  icon={<SupervisedUserCircleOutlinedIcon style={{ visibility: 'hidden' }} />}
                  inputTexts={[
                    {
                      input: (
                        <Grid
                          container
                          style={{ cursor: 'pointer', marginLeft: 0, marginTop: 1 }}
                          alignItems="center"
                          onClick={() => {
                            const { assistentes } = this.state
                            assistentes.push(this.criarItemAssistente(null, true))
                            this.setState({ assistentes: assistentes })
                          }}
                        >
                          <Grid item>
                            <Typography variant="body1" color="secondary" style={{ display: 'block' }}>
                              Adicionar Assistente
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton disabled={ajaxing || !editavel || processado} color="secondary" style={{ padding: 6 }}>
                              <AddCircleIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )
                    }
                  ]}
                />
              )}

              {this.state.assistentes.map((assistente, index) => (
                <Grid container wrap="nowrap" key={assistente.key}>
                  <Grid item xs>
                    <InputHorizontalContainer
                      customVariant={INPUT_CUSTOM_VARIANT}
                      style={{ marginTop: 6 }}
                      icon={<SupervisedUserCircleOutlinedIcon />}
                      inputTexts={[
                        {
                          input: (
                            <ColaboradorInputDialogSelect
                              key={'assistenteAtendimento' + assistente.key + ':' + this.state.tipo.id}
                              idname={'xassistente' + assistente.key}
                              autoSelect={assistente.autoSelect}
                              context="atendimento"
                              disabled={ajaxing || !editavel || processado}
                              defaultValue={assistente.colaborador}
                              functionsMap={assistente.inputFunctionsMap}
                              servico={this.state.servico ? this.state.servico : null}
                              DialogSelectProps={{
                                preProcessItemFunction: (colaborador) => {
                                  if (colaborador.realizaAtendimentos === true) {
                                    return colaborador
                                  }
                                  return null
                                }
                              }}
                              label="Assistente"
                              customVariant={INPUT_CUSTOM_VARIANT}
                              shrink={false}
                              errorMessage={errorsMap('assistentes[' + index + ']')}
                              style={{ marginTop: 0 }}
                            />
                          )
                        }
                      ]}
                    />
                  </Grid>

                  <Grid item>
                    <IconButton disabled={ajaxing || !editavel} style={{ padding: 6, marginTop: 18, marginRight: -8 }} onClick={() => this.removerItemAssistente(index)}>
                      <DeleteIcon style={{ color: '#bbb' }} />
                    </IconButton>
                  </Grid>

                  {this.state.assistentes.length === index + 1 && (
                    <Grid item>
                      <IconButton
                        disabled={ajaxing || !editavel}
                        color="primary"
                        style={{ marginTop: 18, padding: 6, marginRight: -8 }}
                        onClick={() => this.adicionarItemAssistente()}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<ListAltIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputServico
                        key={'servico'}
                        disabled={ajaxing || !editavel || processado}
                        colaborador={this.state.colaborador ? this.state.colaborador : null}
                        dataAtendimento={this.state.data}
                        pacoteItensDisponiveis={this.state.pacoteItensDisponiveis}
                        defaultValue={servicoDefaultValue}
                        functionsMap={this.servicoFunctionsMap}
                        errorMessage={errorsMap('servico')}
                        onChange={(value) => {
                          if (value) {
                            this.selecionarServico(value.servico, value.variacao)
                          } else {
                            this.selecionarServico(null, null)
                          }
                        }}
                        label="Serviço"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        helpMessage={inputServicoHelpMessage}
                        shrink={false}
                        style={{ marginTop: 0 }}
                        staticHelpMessage={usoProdutoContent}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<ScheduleIcon />}
                inputTexts={[
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <InputHorario
                        disabled={ajaxing || !editavel}
                        key={'horario:' + this.state.tipo.id}
                        functionsMap={this.horarioFunctionsMap}
                        keyboard={true}
                        idname="xhorario"
                        errorMessage={errorsMap('horario')}
                        style={{ marginTop: 0 }}
                        defaultValue={horarioDefaultValue}
                        label={horarioPlaceholder}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <DuracaoInputSelect
                        disabled={ajaxing || !editavel}
                        key={'duracao:' + keyPorServicoEVariacao}
                        functionsMap={this.duracaoFunctionsMap}
                        defaultValue={duracaoDefaultValue}
                        id="xduracao"
                        name="xduracao"
                        errorMessage={errorsMap('duracao')}
                        style={{ marginTop: 0 }}
                        label="Duração"
                        descricaoResumida={true}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6, display: exibirOpcaoUtilizacaoPacote ? undefined : 'none' }}
                icon={<TipoVendaEnum.VENDA_PACOTE.iconComponent />}
                inputTexts={[
                  {
                    input: (
                      <InputSelect
                        style={{ marginTop: 6, marginBottom: 2 }}
                        disabled={ajaxing || !editavel || processado}
                        key={'usoPacote:' + utilizacaoPacote.id}
                        label="Utilização de Pacote"
                        customVariant="outlined-small"
                        shrink={false}
                        errorMessage={errorsMap('utilizacaoPacote')}
                        value={utilizacaoPacote.id}
                        onChange={(event) => {
                          const utilizacaoPacote = UtilizacaoPacoteEnum.getById(parseInt(event.target.value, 10))
                          let preco = this.state.preco

                          if (utilizacaoPacote.id === UtilizacaoPacoteEnum.NAO_UTILIZAR_PACOTE.id) {
                            if (this.state.atendimento && this.state.atendimento.variacaoServico) {
                              preco = this.state.atendimento.variacaoServico.preco
                            } else {
                              preco = this.state.variacao.preco
                            }
                          }

                          this.setState({
                            utilizacaoPacote: utilizacaoPacote,
                            preco: preco
                          })
                        }}
                      >
                        {UtilizacaoPacoteEnum.values().map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.descricao}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6, display: utilizacaoPacote.id === UtilizacaoPacoteEnum.UTILIZAR_PACOTE.id ? 'none' : undefined }}
                icon={<AttachMoneyIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        disabled={!podeAlterarPrecoVenda || ajaxing || !editavel || (processado && !notaAbertaENaoCancelada)}
                        key={'preco:' + keyPorServicoEVariacao + '_' + utilizacaoPacote.id + '_' + precoDefaultValue}
                        idname="xpreco"
                        helpMessage={!podeAlterarPrecoVenda ? 'Você não tem permissão para alterar o preço' : null}
                        defaultValue={precoDefaultValue}
                        errorMessage={errorsMap('preco')}
                        label="Preço (R$)"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        inputComponent={InputMoneyFormat}
                        inputRef={this.inputPreco}
                        functionsMap={this.inputPrecoFunctionsMap}
                        onChange={(event) => {
                          this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                        }}
                        superHelpMessage={() => {
                          const preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)
                          const descontoValues = this.descontoFunctionsMap.getValues()
                          let precoDescontoAplicado = 0

                          if (!descontoValues.manual && descontoValues.desconto) {
                            precoDescontoAplicado = DescontoService.aplicarDesconto(preco, descontoValues.desconto.valor, descontoValues.desconto.tipoCalculoDesconto)
                          } else {
                            precoDescontoAplicado = DescontoService.aplicarDesconto(
                              preco,
                              descontoValues.valorDescontoPersonalizado,
                              descontoValues.tipoCalculoDescontoPersonalizado
                            )
                          }

                          if (precoDescontoAplicado !== undefined && precoDescontoAplicado !== null && precoDescontoAplicado >= 0) {
                            return {
                              content: 'Preço final com desconto: ' + formatarValorMonetario(precoDescontoAplicado)
                            }
                          }

                          return {
                            content: null
                          }
                        }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6, display: utilizacaoPacote.id === UtilizacaoPacoteEnum.UTILIZAR_PACOTE.id ? 'none' : undefined }}
                icon={<MoneyOffIcon />}
                inputTexts={[
                  {
                    input: (
                      <DescontoInputSelect
                        disabled={ajaxing || !editavel || (processado && !notaAbertaENaoCancelada)}
                        key={'desconto:' + keyPorServicoEVariacao}
                        disponibilidades={[DescontoDisponibilidadeEnum.SERVICO]}
                        onChange={(event) => {
                          this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          this.descontoSelectFunctionsMap.updateSuperHelpMessage()
                        }}
                        defaultValue={descontoDefaultValue}
                        selectProps={{
                          idname: 'xdesconto',
                          label: 'Desconto',
                          functionsMap: this.descontoSelectFunctionsMap,
                          superHelpMessage: () => {
                            let valorDescontoFormatado = null
                            const descontoValues = this.descontoFunctionsMap.getValues()
                            if (!descontoValues.manual && descontoValues.desconto) {
                              valorDescontoFormatado = formatarValorCalculo(descontoValues.desconto.tipoCalculoDesconto, descontoValues.desconto.valor)
                            }

                            if (valorDescontoFormatado) {
                              return {
                                content: 'Desconto: ' + valorDescontoFormatado
                              }
                            }
                            return {
                              content: null
                            }
                          }
                        }}
                        manualInputProps={{
                          style: { marginTop: 14 },
                          idname: 'xdescontoValorManual',
                          label: 'Valor do Desconto',
                          errorMessage: errorsMap('valorDescontoPersonalizado'),
                          onChange: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          },
                          onKeyUp: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          },
                          onChangeTipoCalculo: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          }
                        }}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        functionsMap={this.descontoFunctionsMap}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6, display: verificarAutorizacao([PA.PODE_VISUALIZAR_OBSERVACAO]) ? undefined : 'none' }}
                icon={<LabelIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        disabled={ajaxing || !editavel}
                        id="xobservacoes"
                        name="xobservacoes"
                        multiline={true}
                        defaultValue={observacoes}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        label="Observações"
                        inputRef={this.inputObservacoes}
                      />
                    )
                  }
                ]}
              />

              <div style={{ display: exibirConfiguracoesRepeticao ? undefined : 'none' }}>
                <Repeticao
                  key={tipo.id}
                  showLabelIcons={true}
                  title="Repetir Atendimento"
                  prefixoEntidade="do"
                  nomeEntidade="Atendimento"
                  tituloAlteracaoIndividual="Alterar apenas este atendimento"
                  descricaoAlteracaoIndividual="As alterações realizadas neste atendimeto não serão replicadas para os próximos atendimentos da repetição."
                  tituloAlteracaoLote="Replicar alterações para os próximos atendimentos"
                  descricaoAlteracaoLote="As alterações deste atendimento serão aplicadas também para todos os próximos atendimentos da replicação."
                  defaultValue={repeticao}
                  customVariant={INPUT_CUSTOM_VARIANT}
                  shrink={false}
                  dataInicial={data ? data : null}
                  disabled={ajaxing || !editavel || processado}
                  errorsMap={errorsMap}
                  showFunction={() => {
                    return tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id
                  }}
                  functionsMap={this.repeticaoFunctionsMap}
                />
              </div>

              <FormUtil.HiddenSubmitButton />

              {this.state.paginaCarregada && editavel && (
                <Grid container>
                  <Grid item xs style={{ paddingRight: 16 }}>
                    <Typography variant="body2">
                      Certifique-se de obter a autorização de pais ou responsáveis para cadastro de menores de 13 anos. Os dados serão tratados nos termos de nossa{' '}
                      <Link color="secondary" underline="always" {...politicaPrivacidadeLinkProps} rel="noopener noreferrer">
                        Política de Privacidade
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div className={classes.containerFooterButtons}>
                      <Button disabled={ajaxing} onClick={this.props.handleCloseDialog}>
                        Voltar
                      </Button>
                      <Button disabled={ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                        {ajaxing ? 'Aguarde' : 'Salvar'}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.mounted = false
  }
}

AtendimentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtendimentoDialogPage)
