import React from 'react'

import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  REGRA_PADRAO_SISTEMA: {
    id: 3,
    descricaoOpcao: 'Regra padrão do sistema',
    descricaoExplicacao: <span>Considera a regra padrão do sistema.</span>
  },
  NAO_APLICAR_DESCONTO: {
    id: 1,
    descricaoOpcao: 'Não aplicar desconto',
    descricaoExplicacao: (
      <span>Esta opção faz com que o sistema calcule a comissão de acordo com o valor integral do serviço ou venda, independente se houve ou não um desconto para o cliente.</span>
    )
  },
  APLICAR_DESCONTO: {
    id: 2,
    descricaoOpcao: 'Aplicar desconto',
    descricaoExplicacao: (
      <span>
        Esta opção faz com que o sistema aplique descontos concedidos à cliente antes de calcular o valor da comissão do profissional. Desta forma, o cálculo das comissões é
        realizado após a aplicações dos descontos, resultando em uma comissão menor.
      </span>
    )
  }
})
export default enumObject
