import { styled } from '@grupoboticario/flora'
import { Box } from '@grupoboticario/flora-react'

const BannerContainer = styled(Box, {
  position: 'fixed',
  zIndex: 10,
  top: 0,
  left: 0,
  width: '100%',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$6'
})

const MobileBanner = styled(Box, {
  position: 'fixed',
  bottom: 0,
  zIndex: 10,
  padding: '$4',
  margin: '$1 $2',
  borderRadius: '$small',
  background: 'var(--Background-Alt-Secondary, #222)',
  boxShadow: '$upSoft'
})

export { BannerContainer, MobileBanner }
