import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import TimerIcon from '@material-ui/icons/Timer'
import { KeyboardTimePicker, TimePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { openBackableUnmanaged } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputText from 'support/components/input/InputText'
import { isValidDate } from 'support/util/DateUtil'
import { autoCompleteValueOff } from 'support/util/FormUtil'
import { converterDateParaHorarioInt, converterHorarioIntParaDate, converterStringHHMMParaDate } from 'support/util/TimeConverter'

const styles = (theme) => ({})

const MINUTES_STEP_DEFAULT = 5

function CustomInputText(props) {
  const { error, helperText } = props
  let errorMessage = props.errorMessage
  if (error && helperText) {
    errorMessage = helperText
  }
  const inputProps = Object.assign({}, props)
  delete inputProps.onInputChange
  delete inputProps.disableOpenOnEnter

  return <InputText {...inputProps} errorMessage={errorMessage} />
}

class InputHorario extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      value: this.getDefaultValue(),
      isPickerOpen: false
    }
  }

  getDefaultValue = () => {
    if (this.props.defaultValue !== undefined && this.props.defaultValue !== null) {
      if (isValidDate(this.props.defaultValue)) {
        return this.props.defaultValue
      } else {
        return converterHorarioIntParaDate(this.props.defaultValue)
      }
    }
    return null
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getHorarioAsDate = () => {
        return this.getValue()
      }
      this.props.functionsMap.getHorarioAsInt = () => {
        return converterDateParaHorarioInt(this.getValue())
      }
    }
  }

  getValue = () => {
    return converterStringHHMMParaDate(this.inputRef.current.value)
  }

  openPicker = (event) => {
    openBackableUnmanaged({
      onCreateInstance: (instance) => {
        this.backableComponentInstance = instance
        this.setState({ isPickerOpen: true })
      },
      onClosed: () => {
        this.setState({ isPickerOpen: false })
      }
    })
  }

  handleChange = (date) => {
    if (this.props.onChange) {
      this.props.onChange(date)
    }
    this.setState({ value: date })
  }

  render() {
    const { keyboard, minutesStep, changeOnKeyPress, ...others } = this.props
    delete others.classes
    delete others.value
    delete others.defaultValue

    others.onClose = () => {
      this.backableComponentInstance.props.handleClose()
    }

    others.okLabel = 'OK'
    others.cancelLabel = null
    others.showTodayButton = true
    others.todayLabel = 'Agora'

    if (this.props.clearable !== false) {
      others.clearable = true
      others.clearLabel = 'Limpar'
    }

    others.ampm = false
    others.format = 'HH:mm'
    others.invalidDateMessage = 'Horário inválido'
    others.maxDateMessage = 'Horário inválido'
    others.minDateMessage = 'Horário inválido'
    //others.mask = [/\d/, /\d/, ":", /\d/, /\d/]}
    others.inputRef = this.inputRef
    others.autoComplete = autoCompleteValueOff
    others.value = this.state.value
    others.onChange = this.handleChange
    others.TextFieldComponent = CustomInputText

    if (minutesStep) {
      others.minutesStep = minutesStep
    } else {
      others.minutesStep = MINUTES_STEP_DEFAULT
    }

    let timePickerComponent = null

    if (this.props.keyboard) {
      others.open = this.state.isPickerOpen
      others.disableOpenOnEnter = true
      others.endAdornmentOverride = (
        <InputAdornment position="end">
          <IconButton disabled={this.props.disabled || this.props.readOnly} style={{ padding: 6, marginRight: 8 }} onClick={(event) => this.openPicker(event)}>
            <TimerIcon fontSize="small" />
          </IconButton>
          {/*

						<IconButton disabled={this.props.disabled || this.props.readOnly} style={{padding: 6}} onClick={event => {
							event.stopPropagation();
							this.setState({
								menuAnchorEl: event.currentTarget,
							});
						}}>
							<MoreVertIcon fontSize="small" />
						</IconButton>

					*/}
        </InputAdornment>
      )
      timePickerComponent = <KeyboardTimePicker {...others} />
    } else {
      others.open = this.state.isPickerOpen
      others.onOpen = () => {
        this.openPicker()
      }
      timePickerComponent = <TimePicker {...others} />
    }

    return (
      <React.Fragment>
        {timePickerComponent}
        <Menu
          anchorEl={this.state.menuAnchorEl}
          open={Boolean(this.state.menuAnchorEl)}
          onClose={() => {
            this.setState({ menuAnchorEl: null })
          }}
        >
          <MenuItem
            onClick={() => {
              this.setState({ value: new Date(), menuAnchorEl: null })
            }}
          >
            Agora
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ value: null, menuAnchorEl: null })
            }}
          >
            Limpar
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}

InputHorario.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputHorario)
