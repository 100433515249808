import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CategoriaInputDialogSelect from 'pages/erp/painel/inventario/input/CategoriaInputDialogSelect'
import FornecedorInputDialogSelect from 'pages/erp/painel/inventario/input/FornecedorInputDialogSelect'
import MarcaInputDialogSelect from 'pages/erp/painel/inventario/input/MarcaInputDialogSelect'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class ProdutosPesquisaAvancadaDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      categoria: this.props.categoria,
      marca: this.props.marca,
      fornecedor: this.props.fornecedor,
      alertaEstoqueBaixo: this.props.alertaEstoqueBaixo,
      arquivado: this.props.arquivado
    }

    this.autocompleteCategoria = {}
    this.autocompleteMarca = {}
    this.autocompleteFornecedor = {}
  }

  limparFiltro = () => {
    this.props.setParentState({
      categoria: { id: null },
      fornecedor: { id: null },
      marca: { id: null }
    })
    this.props.handleCloseDialog()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.aplicarFiltro()
  }

  aplicarFiltro = () => {
    this.setState({ errorsMap: FormUtil.createErrorsMap() })

    const errorsMap = {}

    if (Object.keys(errorsMap).length > 0) {
      this.setState({ errorsMap: FormUtil.createErrorsMap(errorsMap) })
      FormUtil.focusFirstElementWithError('formServicoPesquisaAvancada')
    } else {
      const categoria = this.autocompleteCategoria.getObject() ? this.autocompleteCategoria.getObject() : {}
      const fornecedor = this.autocompleteFornecedor.getObject() ? this.autocompleteFornecedor.getObject() : {}
      const marca = this.autocompleteMarca.getObject() ? this.autocompleteMarca.getObject() : {}

      this.props.setParentState({
        categoria: { id: categoria.value, nome: categoria.label },
        fornecedor: { id: fornecedor.value, nome: fornecedor.label },
        marca: { id: marca.value, nome: marca.label },
        alertaEstoqueBaixo: this.state.alertaEstoqueBaixo,
        arquivado: this.state.arquivado
      })
      this.props.handleCloseDialog()
    }
  }

  render() {
    return (
      <CustomDialog dialogProps={this.props}>
        <DialogTitle>Pesquisa Avançada</DialogTitle>
        <DialogContent>
          <form id="formServicoPesquisaAvancada" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <Grid container direction="column">
              <Grid item>
                <CategoriaInputDialogSelect
                  customVariant="outlined-small"
                  shrink={false}
                  disabled={this.state.ajaxing}
                  placeholder="Selecione a categoria"
                  defaultValue={this.state.categoria && this.state.categoria.id ? this.state.categoria : undefined}
                  functionsMap={this.autocompleteCategoria}
                />
              </Grid>
              <Grid item>
                <MarcaInputDialogSelect
                  customVariant="outlined-small"
                  shrink={false}
                  disabled={this.state.ajaxing}
                  marginTop={true}
                  placeholder="Selecione a marca"
                  defaultValue={this.state.marca && this.state.marca.id ? this.state.marca : undefined}
                  functionsMap={this.autocompleteMarca}
                />
              </Grid>
              <Grid item>
                <FornecedorInputDialogSelect
                  customVariant="outlined-small"
                  shrink={false}
                  disabled={this.state.ajaxing}
                  marginTop={true}
                  placeholder="Selecione o fornecedor"
                  defaultValue={this.state.fornecedor && this.state.fornecedor.id ? this.state.fornecedor : undefined}
                  functionsMap={this.autocompleteFornecedor}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ marginTop: 16 }}
                  control={
                    <Checkbox
                      color="primary"
                      disabled={this.state.ajaxing}
                      checked={this.state.alertaEstoqueBaixo}
                      onChange={(event) => {
                        const checked = event.target.checked
                        this.setState({ alertaEstoqueBaixo: checked })
                      }}
                    />
                  }
                  label={<Typography variant="body2">Apenas produtos com estoque baixo</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      disabled={this.state.ajaxing}
                      checked={this.state.arquivado}
                      onChange={(event) => {
                        const checked = event.target.checked
                        this.setState({ arquivado: checked })
                      }}
                    />
                  }
                  label={<Typography variant="body2">Apenas produtos arquivados</Typography>}
                />
              </Grid>
            </Grid>
            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Voltar
          </Button>
          <Button color="secondary" onClick={this.limparFiltro}>
            LIMPAR
          </Button>
          <Button color="secondary" onClick={this.aplicarFiltro}>
            PESQUISAR
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

ProdutosPesquisaAvancadaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProdutosPesquisaAvancadaDialog)
