import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PlaceIcon from '@material-ui/icons/PlaceOutlined'
import classNames from 'classnames'
import logo from 'img/logo_salao99_painel.png'
import PortalPageFooter from 'pages/portal/PortalPageFooter'
import { ROUTE_CONTA_MAIN, ROUTE_PORTAL_MAIN_AS_PREFIX, ROUTE_PORTAL_PANEL } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import CookieConsentManager from 'support/components/cookieconsent/CookieConsentManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import LoginIcon from 'support/components/icon/Login'
import InputPlace from 'support/components/input/InputPlace'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import LoginAvatar from 'support/components/loginavatar/LoginAvatar'
import UrlUtil from 'support/util/UrlUtil'

const styles = (theme) => ({
  basicContainer: theme.container.basic(),
  centeredContainer: theme.container.centered({ maxWidth: 99999 }),
  headerButtonRoot: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    '& svg': {
      fontSize: '28px !important'
    }
  },
  logoCentral: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    width: 216,
    [theme.breakpoints.down(1380)]: {
      width: 210
    },
    [theme.breakpoints.down(700)]: {
      width: 190
    },
    [theme.breakpoints.down(500)]: {
      width: 164
    }
  },
  titulo: {
    fontSize: '2.1rem',
    lineHeight: '2.6rem',
    letterSpacing: '.02125rem',
    fontWeight: '400',
    textAlign: 'center',
    maxWidth: 526,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 22,
    [theme.breakpoints.down(1380)]: {
      fontSize: '1.9rem',
      lineHeight: '2.4rem',
      maxWidth: 480
    },
    [theme.breakpoints.down(700)]: {
      fontSize: '1.7rem',
      lineHeight: '2.2rem',
      maxWidth: 450
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '1.5rem',
      lineHeight: '2.0rem',
      maxWidth: 400
    },
    [theme.breakpoints.down(440)]: {
      fontSize: '1.2rem',
      lineHeight: '1.7rem',
      fontWeight: '500',
      maxWidth: 320
    }
  },
  subtitulo: {
    marginTop: 22,
    //color: "#333",
    fontSize: 18,
    letterSpacing: '.03rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down(1380)]: {
      fontSize: 17
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 16
    },
    [theme.breakpoints.down(500)]: {
      marginTop: 16,
      fontSize: 16,
      maxWidth: 240
    }
  },
  containerCampoBusca: {
    marginTop: 26,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 520,
    marginBottom: 150,
    [theme.breakpoints.down(1380)]: {
      maxWidth: 490
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: 420
    },
    [theme.breakpoints.down(500)]: {
      marginTop: 22,
      maxWidth: 286
    }
  },
  containerBotaoBuscar: {
    [theme.breakpoints.down(500)]: {
      display: 'none'
    }
  },
  inputContainerCampoBusca: {
    borderRadius: 100,
    padding: 4,
    ...theme.util.createBoxShadow('2px 2px 4px 0px rgba(0,0,0,0.1)')
  },
  inputCampoBusca: {
    fontSize: 17,
    [theme.breakpoints.down(700)]: {
      fontSize: 16
    }
  },
  iconCampoBusca: {
    fontSize: 28,
    [theme.breakpoints.down(500)]: {
      fontSize: 24,
      marginRight: -4
    }
  },
  botaoBuscar: {
    textTransform: 'none',
    fontSize: 17,
    marginRight: 18,
    borderRadius: '16px',
    backgroundColor: 'transparent !important',
    [theme.breakpoints.down(500)]: {
      display: 'none'
    }
  }
})

class IndexPage extends Component {
  constructor(props) {
    super(props)

    DeviceIdentifier.registerDevice(UrlUtil.getUrlQueryStringParsed())

    document.body.classList.add('minheight100')
    this.inputPlaceFunctionsMap = {}

    this.globalLocalPreferences = LocalPreferences.new('global', null)
  }

  componentDidMount = () => {
    document.body.classList.add('minheight100')
    CookieConsentManager.executeScriptAndShowPopup()
  }

  buscar = (place) => {
    this.globalLocalPreferences.setPreference('Portal_EnderecoBusca', place, { type: 'permanent' })
    this.props.history.push(ROUTE_PORTAL_MAIN_AS_PREFIX + '/busca')
  }

  render() {
    const { classes } = this.props

    let paraEmpresasButton = null
    if (!DeviceIdentifier.isNativeApp()) {
      paraEmpresasButton = (
        <Link href="/enterprise" underline="none">
          <Button classes={{ root: classes.headerButtonRoot }} style={{ marginRight: 12 }}>
            Para Empresas
          </Button>
        </Link>
      )
    }

    let contaButton = null
    if (this.props.usuarioSessao && this.props.usuarioSessao.carregado) {
      contaButton = (
        <LoginAvatar
          name={this.props.usuarioSessao.nome}
          onClick={() => {
            this.props.history.push(ROUTE_PORTAL_PANEL)
          }}
        />
      )
    } else {
      contaButton = (
        <Button
          classes={{ root: classes.headerButtonRoot }}
          startIcon={<LoginIcon />}
          onClick={() => {
            this.props.history.push(ROUTE_CONTA_MAIN + '/login?continuacao=' + encodeURIComponent(window.location.href))
          }}
        >
          Login
        </Button>
      )
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Salão99 - Agende Serviços de Beleza e Bem-Estar</title>
          <meta name="description" content="O jeito mais fácil de agendar horários em salões de beleza, barbearias, clínicas de estética e Spas" />
        </Helmet>
        <div className={classNames(classes.centeredContainer, 'minheight100')}>
          <Grid container alignItems="center" direction="column" className="minheight100">
            <Grid item xs style={{ flexGrow: 0, width: '100%', flexWrap: 'nowrap' }} className={classNames(classes.basicContainer)}>
              <Grid container alignItems="center" style={{ paddingTop: 16, paddingBottom: 16 }} justify="flex-end">
                <Grid item>{paraEmpresasButton}</Grid>
                <Grid item>{contaButton}</Grid>
              </Grid>
            </Grid>

            <Grid item xs container direction="column" className={classNames(classes.basicContainer)} justify="center" style={{ flexWrap: 'nowrap', minHeight: 350 }}>
              <img src={logo} alt="Salão99" className={classes.logoCentral} />
              <Typography align="center" className={classes.titulo} component="h1">
                O jeito mais fácil de agendar serviços de beleza e bem-estar
              </Typography>
              <Grid container alignItems="center" className={classes.containerCampoBusca}>
                <Grid item xs>
                  <InputPlace
                    style={{ display: 'flex' }}
                    InputProps={{
                      className: classes.inputContainerCampoBusca,
                      inputProps: {
                        className: classes.inputCampoBusca
                      }
                    }}
                    idname="xendereco"
                    shrink={false}
                    customVariant="outlined"
                    placeholder="Em qual endereço você está?"
                    startAdornment={<PlaceIcon color="secondary" className={classes.iconCampoBusca} style={{ marginLeft: 14 }} />}
                    endAdornment={
                      <Button color="secondary" className={classes.botaoBuscar}>
                        Buscar
                      </Button>
                    }
                    focusEffectDisabled={true}
                    functionsMap={this.inputPlaceFunctionsMap}
                    onChange={(place) => {
                      if (place) {
                        this.buscar(place)
                      }
                    }}
                    dialogTitle={'Escolha um endereço para procurar opções próximas'}
                    showGetCurrentLocationButton={true}
                    loadUserHistoryPlaces={true}
                  />
                </Grid>
                <Grid item style={{ marginLeft: 8, display: 'none' }} className={classes.containerBotaoBuscar}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.botaoBuscar}
                    onClick={() => {
                      if (this.inputPlaceFunctionsMap.getPlace()) {
                        this.buscar(this.inputPlaceFunctionsMap.getPlace())
                      } else {
                        this.inputPlaceFunctionsMap.select((place) => {
                          this.buscar(place)
                        })
                      }
                    }}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs style={{ flexGrow: 0, width: '100%' }}>
              <PortalPageFooter />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    document.body.classList.remove('minheight100')
  }
}

IndexPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IndexPage)
