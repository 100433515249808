import moment from 'moment'

const PREFIX_KEY = 'LocalPreferences_'

function setLocalStorageObject(key, obj, opts) {
  localStorage.setItem(PREFIX_KEY + key, JSON.stringify(obj))
}

function createLocalStorageContent(value, opts) {
  if (!opts) {
    opts = {}
  }
  const content = {
    value: value
  }
  if (opts.type) {
    content.type = opts.type
  } else {
    content.type = null
  }
  return content
}

function getLocalStorageObject(key) {
  const objAsString = localStorage.getItem(PREFIX_KEY + key)
  if (objAsString) {
    return JSON.parse(objAsString)
  } else {
    return null
  }
}

function getLocalStorageContentValue(content) {
  if (typeof content !== 'object') {
    return null
  }
  if (content.type === undefined || content.value === undefined) {
    return null
  }
  return content.value
}

export function clearNoPermanentsLocalPreferences() {
  for (let key of Object.keys(localStorage)) {
    if (key.startsWith(PREFIX_KEY)) {
      let obj = localStorage.getItem(key)
      if (obj) {
        obj = JSON.parse(obj)
        if (typeof obj === 'object') {
          for (let preference of Object.keys(obj)) {
            const content = obj[preference]
            if (!content || content.type !== 'permanent') {
              delete obj[preference]
            }
          }
        }
      }
      localStorage.setItem(key, JSON.stringify(obj))
    }
  }
}

const MOMENT_PERSIST_FORMAT = 'YYYYMMDDHHmmss'

const LocalPreferences = {
  new: function (name, id) {
    return {
      getKey: function () {
        let key = name
        if (id) {
          key += id
        }
        return key
      },

      setPreference: function (preference, value, opts) {
        const key = this.getKey()
        let obj = getLocalStorageObject(key)
        if (!obj) {
          obj = {}
        }
        obj[preference] = createLocalStorageContent(value, opts)
        setLocalStorageObject(key, obj)
      },

      setMomentPreference: function (preference, value, opts) {
        if (value && moment.isMoment(value)) {
          value = value.format(MOMENT_PERSIST_FORMAT)
        }
        this.setPreference(preference, value, opts)
      },

      getPreference: function (preference, defaultValue) {
        const key = this.getKey()
        let obj = getLocalStorageObject(key)
        let value = null
        if (obj) {
          value = getLocalStorageContentValue(obj[preference])
        }
        return getValue(value, defaultValue)
      },

      getMomentPreference: function (preference, defaultValue) {
        let value = this.getPreference(preference)
        if (value) {
          value = moment(value, MOMENT_PERSIST_FORMAT)
        }
        return getValue(value, defaultValue)
      }
    }
  }
}

function getValue(value, defaultValue) {
  if (value === null || value === undefined) {
    if (defaultValue !== null && defaultValue !== undefined) {
      return defaultValue
    }
  }
  return value
}

export default LocalPreferences
