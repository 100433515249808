const baseEnum = {
  WHATSAPP: { id: 1, descricao: 'WhatsApp', descricaoTipoAbreviada: 'Cel.' },
  CELULAR: { id: 2, descricao: 'Celular', descricaoTipoAbreviada: 'Cel.' },
  RESIDENCIAL: { id: 3, descricao: 'Residencial', descricaoTipoAbreviada: 'Res.' },
  COMERCIAL: { id: 4, descricao: 'Comercial', descricaoTipoAbreviada: 'Com.' }
}


const enumObject = {
  ...baseEnum,
  values: () => Object.values(baseEnum),
  getById: (value) => {
    for (let enumItem of Object.values(baseEnum)) {
      if (enumItem.id === value) {
        return enumItem
      }
    }
    return baseEnum.WHATSAPP
  }
}

export default enumObject
