/* eslint-disable no-magic-numbers */
import { createRef, useCallback, useEffect, useState } from 'react'

import { v4 as uuid } from 'uuid'

import TipoTelefoneEnum from '../../../domain/telefone/TipoTelefoneEnum'
import { getTelefoneMaskIdByNumero } from '../../../util/Masks'
import { TelefoneFormatadoT, TelefoneT, TipoTelefoneT } from './types'

export const DEFAULTMASKID = 9

export const useInputListTelefoneList = (defaultPhones: TelefoneT[] | undefined, defaultPhoneType: TipoTelefoneT | undefined, maxNumberOfPhones: number) => {
  const createTelefoneFormatadoObject = useCallback(
    (telefone: TelefoneT | null): TelefoneFormatadoT => {
      let id = null
      let tipo = TipoTelefoneEnum.WHATSAPP
      let numero = ''

      if (defaultPhoneType) {
        tipo = defaultPhoneType
      }

      if (telefone) {
        id = telefone.id
        tipo = telefone.tipo
        numero = telefone.numero
      }

      return {
        key: uuid(),
        maskId: getTelefoneMaskIdByNumero(numero),
        inputRef: createRef<HTMLInputElement>(),
        id,
        tipo,
        numero
      }
    },
    [defaultPhoneType]
  )

  const [telefones, setTelefones] = useState<TelefoneFormatadoT[]>([])

  const handleMask = (key: string, currentMaskIdNumero: number, phoneNumber: string) => {
    const maskId = getTelefoneMaskIdByNumero(phoneNumber)
    if (currentMaskIdNumero !== maskId) {
      const telefonesAtualizado = telefones.map((telefone) => {
        if (key === telefone.key) {
          return {
            ...telefone,
            maskId
          }
        } else {
          return telefone
        }
      })
      setTelefones(telefonesAtualizado)
    }
  }

  const handleMaskDefault = (key: string) => {
    const telefonesAtualizado = telefones.map((telefone) => {
      if (key === telefone.key) {
        return {
          ...telefone,
          maskId: DEFAULTMASKID
        }
      } else {
        return telefone
      }
    })
    setTelefones(telefonesAtualizado)
  }

  const handleChangeTipoTelefone = (index: number, idTipo: number) => {
    const telefonesAtualizado = telefones.slice().map((telefone, idx) => {
      if (index === idx) {
        return {
          ...telefone,
          tipo: TipoTelefoneEnum.getById(idTipo)
        }
      } else {
        return telefone
      }
    })
    setTelefones(telefonesAtualizado)
  }

  const handleAdicionarTelefone = () => {
    if (telefones.length < maxNumberOfPhones) {
      setTelefones([...telefones, createTelefoneFormatadoObject(null)])
    }
  }

  const handleRemoverTelefone = (index: number) => {
    if (telefones.length > 1) {
      const telefonesAtualizado = telefones.slice()
      telefonesAtualizado.splice(index, 1)
      setTelefones(telefonesAtualizado)
    }
  }

  useEffect(() => {
    if (defaultPhones) {
      const sortedPhones = defaultPhones.sort((a: TelefoneT, b: TelefoneT) => {
        if (a.id < b.id) {
          return -1
        } else {
          return 1
        }
      })
      setTelefones(sortedPhones.map((telefone) => createTelefoneFormatadoObject(telefone)))
    } else {
      setTelefones([createTelefoneFormatadoObject(null)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPhones])

  return {
    telefones,
    handleMask,
    handleMaskDefault,
    handleAdicionarTelefone,
    handleChangeTipoTelefone,
    handleRemoverTelefone
  }
}
