import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import PortalPagesUtil from 'pages/portal/PortalPagesUtil'
import { ROUTE_PORTAL_MAIN_AS_PREFIX } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import { formatarMetrosEmDistanciaAproximada } from 'support/util/GeocodeFormatter'
import ImageUtil from 'support/util/ImageUtil'

const WIDTH_MODELO2 = 940

const styles = (theme) => ({
  containerNegocios: {
    width: 'calc(100% + 12px)',
    margin: -6
  },
  containerNegocio: {
    padding: 6,
    display: 'inline-flex',
    cursor: 'pointer',
    maxWidth: '33.333333%',
    flexBasis: '33.333333%',
    [theme.breakpoints.down(1180)]: {
      maxWidth: '50%',
      flexBasis: '50%'
    },
    [theme.breakpoints.down(770)]: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  innerContainerNegocio: {
    border: '1px solid #eee',
    padding: 22,
    borderRadius: 8,
    '$containerNegocio:hover &': {
      backgroundColor: '#fafafa'
    },
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      padding: 16
    },
    [theme.breakpoints.down(350)]: {
      padding: 10
    }
  },
  negocioFoto: {
    width: 84,
    height: 84,
    display: 'block',
    borderRadius: 110,
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      width: 74,
      height: 74
    }
  },
  negocioInfos: {
    marginLeft: 22,
    borderLeft: '1px solid  #f2f2f2',
    paddingLeft: 22,
    minWidth: 0,
    [theme.breakpoints.down(WIDTH_MODELO2)]: {
      marginLeft: 14,
      paddingLeft: 14
    },
    [theme.breakpoints.down(350)]: {
      marginLeft: 10,
      paddingLeft: 10
    }
  },
  negocioNome: {
    fontSize: 16
  },
  negocioEndereco: {
    fontSize: 13,
    marginTop: 5,
    marginBottom: 4,
    color: '#353535',
    ...theme.text.limitedLines({ maxLines: 2, withEllipsis: true })
  },
  negocioDistanciaRelativa: {
    fontSize: 12,
    color: '#888',
    fontWeight: 500,
    marginRight: 8
  },
  negocioAvalicaoIcone: {
    fontSize: 17,
    verticalAlign: 'middle',
    color: '#fdc050'
  },
  negocioAvalicaoNota: {
    color: '#ffb939',
    fontSize: 13,
    display: 'inline-block',
    marginLeft: 2,
    fontWeight: 500,
    verticalAlign: 'top',
    marginTop: 2
  },
  negocioAvalicaoQuantidade: {
    color: '#cfcfcf',
    paddingLeft: 2,
    '& span:nth-child(1)': {
      paddingRight: 1
    },
    '& span:nth-child(2)': {
      paddingLeft: 1
    }
  }
})

class ListagemNegociosModoGrid extends Component {
  render() {
    const { classes, negociosPreparadosBusca } = this.props

    return (
      <Grid container className={classes.containerNegocios}>
        {negociosPreparadosBusca.map((negocioPreparadosBusca) => {
          const {
            negocio,
            hash,
            sufixoUrlPortal,
            foto,
            nome,
            enderecoDescricao,
            notaMediaAvaliacoes,
            quantidadeAvaliacoes,
            distanciaRelativaBusca: negocioDistanciaRelativaBusca
          } = negocioPreparadosBusca

          const identifierSalao = sufixoUrlPortal ? '/' + sufixoUrlPortal : PortalPagesUtil.gerarUrlNegocio({ id: negocio.id, hash })
          const pathSalao = ROUTE_PORTAL_MAIN_AS_PREFIX + identifierSalao

          const variacoesFoto = ImageUtil.extrairVariacoesImagem(foto)

          let distanciaRelativaBusca = null
          if (negocioDistanciaRelativaBusca !== undefined && negocioDistanciaRelativaBusca !== null) {
            if (negocioDistanciaRelativaBusca > 0) {
              distanciaRelativaBusca = negocioDistanciaRelativaBusca
            } else {
              distanciaRelativaBusca = 1
            }
          }

          return (
            <Grid
              item
              className={classes.containerNegocio}
              xs
              key={negocio.id}
              onClick={() => {
                sessionStorage.setItem('businessLinkFromInternalSource', true)
                EventsManager.pub('RoutePush', {
                  path: pathSalao
                })
              }}
            >
              <Grid container alignItems="center" className={classes.innerContainerNegocio} wrap="nowrap">
                <Grid item>
                  <img src={variacoesFoto.get(0)} className={classes.negocioFoto} alt="" />
                </Grid>
                <Grid item xs className={classes.negocioInfos}>
                  <Typography variant="body1" className={classes.negocioNome} noWrap={true}>
                    <b>{nome}</b>
                  </Typography>
                  <Typography variant="body2" className={classes.negocioEndereco}>
                    {enderecoDescricao}
                  </Typography>
                  <div style={{ marginTop: 6 }}>
                    {distanciaRelativaBusca && (
                      <div style={{ display: 'inline-block', marginLeft: 1 }}>
                        <span className={classes.negocioDistanciaRelativa}>{formatarMetrosEmDistanciaAproximada(distanciaRelativaBusca)}</span>
                      </div>
                    )}
                    {quantidadeAvaliacoes > 0 && (
                      <div style={{ display: 'inline-block' }}>
                        <StarIcon className={classes.negocioAvalicaoIcone} />
                        <Typography className={classes.negocioAvalicaoNota} variant="body2">
                          {notaMediaAvaliacoes.toFixed(1)}{' '}
                          <span className={classes.negocioAvalicaoQuantidade}>
                            <span>(</span>
                            {quantidadeAvaliacoes}
                            <span>)</span>
                          </span>
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

ListagemNegociosModoGrid.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ListagemNegociosModoGrid)
