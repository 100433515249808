import React, { Component } from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

class PersonOff extends Component {
  render() {
    const { ...others } = this.props

    return (
      <SvgIcon viewBox="0 0 48 48" {...others}>
        <path d="M40.65 44.95 35.7 40H8v-4.7q0-1.9.95-3.25T11.4 30q3-1.35 5.775-2.1 2.775-.75 5.575-.85L3.05 7.35 5.2 5.2l37.6 37.6ZM11 37h21.7l-6.95-6.95q-.4-.05-.85-.05H24q-2.85 0-5.55.575-2.7.575-5.85 2.125-.7.35-1.15 1.075Q11 34.5 11 35.3Zm25.55-7q1.55.7 2.5 2.05.95 1.35.95 3.25v.4l-7.15-7.15q.9.3 1.825.675.925.375 1.875.775Zm-9.05-6.8-2.4-2.4q1.5-.35 2.45-1.55.95-1.2.95-2.8 0-1.9-1.3-3.2-1.3-1.3-3.2-1.3-1.6 0-2.8.95-1.2.95-1.55 2.45l-2.4-2.4q.95-1.9 2.775-2.95Q21.85 8.95 24 8.95q3.15 0 5.325 2.175Q31.5 13.3 31.5 16.45q0 2.15-1.05 3.975Q29.4 22.25 27.5 23.2ZM32.7 37H11h21.7ZM22.4 18.05Z" />
      </SvgIcon>
    )
  }
}

export default PersonOff
