import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class SubstituicaoEmailDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Substituição de E-mail',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputEmailOrigem = React.createRef()
    this.inputEmailDestino = React.createRef()
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/usuario.buscarUsuarioPorId',
      params: {
        idUsuario: this.props.usuario.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          usuario: response.data
        })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    const dados = {
      idUsuario: this.state.usuario.id,
      emailDestino: this.inputEmailDestino.current.value
    }

    return dados
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'admin/usuario.trocarEmail',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoUsuario')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formSubstituicaoEmail')
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, usuario } = this.state

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formSubstituicaoEmail" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xemailOrigem"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={usuario ? usuario.email : undefined}
                disabled={true}
                label="E-mail Antigo"
                inputRef={this.inputEmailOrigem}
              />

              <InputText
                idname="xemailDestino"
                customVariant="outlined-small"
                marginTop={true}
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('emailDestino')}
                label="E-mail Novo"
                inputRef={this.inputEmailDestino}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

SubstituicaoEmailDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SubstituicaoEmailDialogPage)
