import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import logo from 'img/logo_salao99_painel.png'
import RegisterPhoneDialogPage from 'pages/conta/RegisterPhoneDialogPage/RegisterPhoneDialogPage'
import PortalPagesManager from 'pages/portal/PortalPagesManager'
import PropTypes from 'prop-types'
import { getAPI, patchAPI, postAPI } from 'support/components/api/PortalApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import MainLoaderScreen from 'support/components/mainloaderscreen/MainLoaderScreen'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import { logCORE } from 'support/util/Logger'

const styles = (theme) => ({
  logoCentralWidth: {
    width: 220,
    maxWidth: '70%',
    [theme.breakpoints.up(342)]: {
      maxWidth: '60%'
    },
    [theme.breakpoints.up(380)]: {
      maxWidth: '52%'
    },
    [theme.breakpoints.up(410)]: {
      maxWidth: '45%'
    },
    [theme.breakpoints.up(500)]: {
      maxWidth: '40%'
    },
    [theme.breakpoints.up(540)]: {
      maxWidth: '35%'
    }
  }
})

class PortalMainLoader extends Component {
  componentUnmounted = false
  tentandoAtualizarSessao = false
  DURACAO_MINIMA_APRESENTACAO = DeviceIdentifier.isNativeApp() ? 0 : 1200
  inicioCarregamentoPagina = 0

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      usuarioSessao: this.prepararDadosSessao(null),
      portalCarregado: false
    }
    this.setupSubscribes()
    this.mainLoaderScreenFunctionsMap = {}
  }

  componentDidMount = () => {
    this.carregarPortal()
  }

  setupSubscribes() {
    this.eventsManager.sub(['FalhaAutenticacao', 'EfetuarLogout'], () => {
      this.logout()
    })
    this.eventsManager.sub('AtualizarSessao', (props) => {
      if (!props) {
        props = {}
      }
      if (!props.numeroMaximoTentativas) {
        props.numeroMaximoTentativas = 6
      }
      this.atualizarSessao(props, null)
    })
    this.eventsManager.sub('LimparSessao', (props) => {
      this.limparSessao(props)
    })
    this.eventsManager.sub('AdicionarNegocioFavorito', (props) => {
      if (props.idNegocio) {
        postAPI({
          url: 'ptl/usuarios.adicionarNegocioFavorito',
          data: {
            negocio: {
              id: props.idNegocio
            }
          },
          onSuccess: () => {
            if (props.onSuccess) {
              props.onSuccess()
            }
            const usuarioSessao = Object.assign({}, this.state.usuarioSessao)
            usuarioSessao.negociosFavoritos = usuarioSessao.negociosFavoritos.slice()
            if (!usuarioSessao.negociosFavoritos) {
              usuarioSessao.negociosFavoritos = []
            }
            usuarioSessao.negociosFavoritos.push({
              negocio: {
                id: props.idNegocio
              }
            })
            this.setState({ usuarioSessao: usuarioSessao })
            EventsManager.pub('ManipulacaoNegociosFavoritos')
          },
          onError: () => {
            if (props.onError) {
              props.onError()
            }
          },
          requerAutorizacao: true,
          sendErroToGenericSnackbar: false,
          repeat: {
            stopFunction: () => {
              return this.componentUnmounted
            }
          }
        })
      }
    })
    this.eventsManager.sub('RemoverNegocioFavorito', (props) => {
      if (props.idNegocio) {
        postAPI({
          url: 'ptl/usuarios.removerNegocioFavorito',
          data: {
            negocio: {
              id: props.idNegocio
            }
          },
          onSuccess: () => {
            if (props.onSuccess) {
              props.onSuccess()
            }
            const usuarioSessao = Object.assign({}, this.state.usuarioSessao)
            if (usuarioSessao.negociosFavoritos && usuarioSessao.negociosFavoritos.length > 0) {
              const newNegociosFavoritos = []
              for (let negocioFavorito of usuarioSessao.negociosFavoritos) {
                if (negocioFavorito.negocio.id !== props.idNegocio) {
                  newNegociosFavoritos.push(negocioFavorito)
                }
              }
              usuarioSessao.negociosFavoritos = newNegociosFavoritos
              this.setState({ usuarioSessao: usuarioSessao })
              EventsManager.pub('ManipulacaoNegociosFavoritos')
            }
          },
          onError: () => {
            if (props.onError) {
              props.onError()
            }
          },
          requerAutorizacao: true,
          sendErroToGenericSnackbar: false,
          repeat: {
            stopFunction: () => {
              return this.componentUnmounted
            }
          }
        })
      }
    })
  }

  carregarPortal = () => {
    this.inicioCarregamentoPagina = new Date().getTime()
    this.mainLoaderScreenFunctionsMap.notifyStart()

    if (SessaoUsuario.getUsuario()) {
      this.buscarDadosSessao({
        onSuccess: (response) => {
          if (!response.data.usuario.telefone && !DeviceIdentifier.isNativeApp()) {
            openBackableDialog(RegisterPhoneDialogPage, { name: response.data.usuario.nome })
          }

          this.registrarDadosSessao({
            usuario: response.data.usuario !== undefined ? response.data.usuario : null
          })
          this.notificarPortalCarregado()
        },
        onError: (response) => {
          if (response.code === -1) {
            this.mainLoaderScreenFunctionsMap.notifyError({
              message: 'Falha de conexão com a internet'
            })
            return
          }
          if (response.code === 400 || response.code === 401) {
            this.logout()
          } else {
            EventsManager.pub('AtualizarSessao', {
              numeroMaximoTentativas: 99999
            })
          }
          this.notificarPortalCarregado()
        },
        sendErroToGenericSnackbar: false
      })
    } else {
      this.notificarPortalCarregado()
    }
  }

  registrarDadosSessao = (opts) => {
    const state = {}
    if (opts.usuario !== undefined) {
      state.usuarioSessao = this.prepararDadosSessao(opts.usuario)
    }
    this.setState(state)
  }

  prepararDadosSessao = (entidade) => {
    if (entidade === null) {
      entidade = {
        carregado: false
      }
    } else {
      entidade['carregado'] = true
    }
    return entidade
  }

  buscarDadosSessao = (opts) => {
    getAPI({
      url: 'cda/usuarios.buscarDadosSessao',
      onSuccess: (response) => {
        opts.onSuccess(response)
      },
      onError: (response) => {
        opts.onError(response)
      },
      requerAutorizacao: true,
      sendErroToGenericSnackbar: opts.sendErroToGenericSnackbar
    })
  }

  notificarPortalCarregado = () => {
    let delay = this.DURACAO_MINIMA_APRESENTACAO - (new Date().getTime() - this.inicioCarregamentoPagina)
    if (delay < 0) {
      delay = 0
    }
    window.setTimeout(() => {
      this.setState({ portalCarregado: true })
      this.mainLoaderScreenFunctionsMap.notifyLoaded()
    }, delay)
  }

  atualizarSessao = (params, tentativa) => {
    if (!params.force) {
      if (this.tentandoAtualizarSessao) {
        return
      }
      if (this.componentUnmounted) {
        return
      }
    }

    this.tentandoAtualizarSessao = true

    this.buscarDadosSessao({
      onSuccess: (response) => {
        this.tentandoAtualizarSessao = false
        this.registrarDadosSessao({
          usuario: response.data.usuario !== undefined ? response.data.usuario : null
        })
        if (params.callback) {
          params.callback()
        }
      },
      onError: (response) => {
        this.tentandoAtualizarSessao = false

        if (response.code === 400 || response.code === 401) {
          this.logout()
          return
        }

        let tempoProximaTentativa = null
        if (tentativa === undefined) {
          tentativa = 0
          tempoProximaTentativa = 100
        } else if (tentativa <= 1) {
          tempoProximaTentativa = 500
        } else if (tentativa <= 3) {
          tempoProximaTentativa = 1500
        } else if (tentativa <= 10) {
          tempoProximaTentativa = 3000
        } else {
          tempoProximaTentativa = 15000
        }

        tentativa++

        if (!params.numeroMaximoTentativas || tentativa < params.numeroMaximoTentativas) {
          if (tempoProximaTentativa !== null) {
            window.setTimeout(() => {
              this.atualizarSessao(params, tentativa)
            }, tempoProximaTentativa)
          }
        }
      },
      sendErroToGenericSnackbar: false
    })
  }

  logout = () => {
    patchAPI({
      url: 'cda/usuarios.logout',
      onSuccess: () => {
        logCORE('Logout', { component: 'PortalMainLoader', context: 'Logout - success' })
      },
      onError: () => {
        logCORE('Logout', { component: 'PortalMainLoader', context: 'Logout - error' })
      },
      requerAutorizacao: true
    })

    SessaoUsuario.limparSessao()
    this.limparSessao({ usuario: true })
  }

  limparSessao = (props) => {
    if (!props) {
      props = {}
    }
    const obj = {}
    if (props.usuario === true) {
      obj.usuario = null
    }
    this.registrarDadosSessao(obj)
  }

  render() {
    const { classes, ...others } = this.props

    const loaderProps = {
      portalCarregado: this.state.portalCarregado,
      usuarioSessao: this.state.usuarioSessao,
      ...others
    }

    let showLogo = true
    if (this.props.history.location.pathname === '/' || this.props.history.location.pathname === '/index-app') {
      showLogo = false
    }

    return (
      <React.Fragment>
        <MainLoaderScreen
          image={<img src={logo} className={classes.logoCentralWidth} alt="Salão99" />}
          loadFunction={this.carregarPortal}
          functionsMap={this.mainLoaderScreenFunctionsMap}
          showLogo={showLogo}
        />

        <PortalPagesManager {...loaderProps} />
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    this.componentUnmounted = true
  }
}

PortalMainLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired
}

export default withStyles(styles)(PortalMainLoader)
