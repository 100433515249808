import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import CompraProdutoDialogPage from 'pages/erp/painel/inventario/CompraProdutoDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import FormUtil from 'support/util/FormUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    minWidth: 108,
    mxnWidth: 108
  },
  colunaQuantidade: {
    minWidth: 90,
    mxnWidth: 90
  },
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class TabCompras extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      produto: this.props.produto,
      lista: [],
      paginaCarregada: false
    }
    this.loaderFunctionsMap = {}
    this.listFunctionsMap = {}
  }

  handlerRemover = (item) => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Compra',
      text: <span>Você tem certeza que deseja remover esta compra de produto? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'erp/inventario.removerCompraProduto',
        contextoUsuario: 'erp',
        data: {
          idCompraProduto: item.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoProduto')
        },
        errorKey: 'caixa'
      }
    })
  }

  handlerOpenCreateDialog = () => {
    if (verificarAutorizacao([PA.PODE_MOVIMENTAR_ESTOQUE])) {
      openBackableDialog(CompraProdutoDialogPage, {
        produto: this.state.produto
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = (item) => {
    openBackableDialog(CompraProdutoDialogPage, {
      produto: this.state.produto,
      idCompra: item.id
    })
  }

  getRequestParameters = () => {
    return {
      idProduto: this.props.produto.id
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    if (size === 'small') {
      content.dataMovimentacao = converterDataIntParaMoment(item.data).format('DD/MMM/YY')
    } else {
      content.dataMovimentacao = converterDataIntParaMoment(item.data).format('DD/MMM/YYYY')
    }

    content.precoUnitario = formatarValorMonetario(item.precoUnitario)
    content.quantidade = item.quantidade

    const buttonStyle = {}

    if (size === 'small') {
      buttonStyle.marginRight = -8
    }

    content.botao = (
      <IconButton
        style={buttonStyle}
        onClick={(event) => {
          event.stopPropagation()
          this.handlerRemover(item)
        }}
      >
        <DeleteIcon />
      </IconButton>
    )
    return content
  }

  render() {
    const { paginaCarregada, lista } = this.state
    const { classes } = this.props

    return (
      <TabContainer padding={false}>
        {paginaCarregada && (
          <TabContainerPersistHeader
            addTabContainerPadding={true}
            text={lista && lista.length > 0 ? 'Registro de compras' : 'Nenhuma compra foi realizada'}
            buttonsContent={
              <Button color="secondary" variant="text" style={{ marginRight: -8, marginLeft: -8 }} onClick={this.handlerOpenCreateDialog}>
                <AddIcon />
                <span style={{ marginLeft: 8, marginRight: 0 }}>ADICIONAR</span>
              </Button>
            }
          />
        )}

        <VirtualizedResponsiveTable
          minTableWidth={460}
          showBackgroundDividers={true}
          scrollElement={this.props.scrollElement}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/inventario.buscarCompras"
          getRequestParametersFunction={this.getRequestParameters}
          items={lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [], paginaCarregada: false })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items, paginaCarregada: true })
            }
          }}
          onClickRow={(item, event) => {
            this.handlerEditar(item)
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', props: { xs: true } },
              { label: 'Quantidade', props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Valor Unitário', horizontalPadding: 'small', props: { xs: true } },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item, index)

              return {
                itemData: [content.dataMovimentacao, content.quantidade, content.precoUnitario, content.botao]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 94,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center">
                      <Grid item xs style={{ marginRight: 4 }}>
                        <Typography variant="body2">
                          <b>{content.dataMovimentacao}</b>
                        </Typography>
                        <Typography variant="body2">Quantidade: {content.quantidade}</Typography>
                        <Typography variant="body2">Valor Unitário: {content.precoUnitario}</Typography>
                      </Grid>
                      <Grid item>{content.botao}</Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{}}
        />
      </TabContainer>
    )
  }
}

TabCompras.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabCompras)
