import React from 'react'

import Typography from '@material-ui/core/Typography'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

type PagamentoPeriodoOptionProps = {
  period: string
  title: string
  subtitle?: string
  description: string
  price: number
}

function PagamentoPeriodoOption(props: PagamentoPeriodoOptionProps) {
  const { title, subtitle, price, description } = props

  return (
    <React.Fragment>
      <Typography variant="body1" color="secondary" style={{ fontSize: 18 }}>
        {title}
      </Typography>
      {!!subtitle && (
        <Typography variant="body2">
          <span style={{ fontSize: 12, opacity: 0.5 }}>{subtitle}</span>
        </Typography>
      )}
      <Typography variant="body2">{description}</Typography>
      <Typography variant="body1">
        <b>{formatarValorMonetario(price)}</b>{' '}
      </Typography>
    </React.Fragment>
  )
}

export default PagamentoPeriodoOption
