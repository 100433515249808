import { Component } from 'react'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import FormHelperText from 'support/components/input/core/FormHelperText'
import Input from 'support/components/input/core/Input'
import InputLabel from 'support/components/input/core/InputLabel'
import PassiveDynamicContent from 'support/components/passivedynamiccontent/PassiveDynamicContent'
import toTS from 'support/components/toTS'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  root: {
    maxWidth: '100%'
  },
  inputSelect: {
    background: 'transparent',
    marginTop: '0px !important',
    '&:focus': {
      background: 'transparent'
    },
    paddingRight: 22,
    minWidth: 10
  },
  inputSelectWithPlaceholderValue: {
    color: theme.palette.placeholder
  },
  nakedInput: {
    marginTop: '0px !important',
    borderBottom: '0px solid !important',
    '&:before': {
      borderBottom: '0px solid !important'
    },
    '&:after': {
      borderBottom: '0px solid !important'
    },
    '&:hover': {
      borderBottom: '0px solid !important'
    },
    '&:focus': {
      borderBottom: '0px solid !important'
    }
  },
  filledInput: {
    marginTop: '0px !important',
    borderBottom: '0px solid !important',
    '&:before': {
      borderBottom: '0px solid !important'
    },
    '&:after': {
      borderBottom: '0px solid !important'
    },
    '&:hover': {
      borderBottom: '0px solid !important'
    },
    '&:focus': {
      borderBottom: '0px solid !important'
    }
  }
})

class InputSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue !== undefined && props.defaultValue !== null ? props.defaultValue : ''
    }
    this.superHelpMessageFunctionMap = {}
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap['getValue'] = () => {
        return this.state.value !== null && this.state.value !== undefined && this.state.value !== '' ? this.state.value : null
      }
      this.props.functionsMap['updateSuperHelpMessage'] = () => {
        this.superHelpMessageFunctionMap.update()
      }
    }
  }

  handleChange = (event) => {
    const state = this.state
    state.value = event.target.value

    if (this.props.onChange) {
      this.props.onChange(event)
    }
    if (this.props.value === undefined) {
      this.setState(state)
    }
  }

  removeUnrecognizedInputProps = (props) => {
    delete props.functionsMap
    delete props.clearableLabel
    delete props.superHelpMessage
    delete props.marginTop
    delete props.displayEmpty
    delete props.renderValue
    delete props.backgroundColor
  }

  render() {
    const { classes, idname, staticHelpMessage, superHelpMessage, hideIconComponent, SelectDisplayProps, ...inputProps } = this.props
    delete inputProps.children
    delete inputProps.style
    delete inputProps.defaultValue

    if (idname) {
      inputProps.id = idname
      inputProps.name = idname
    }

    const { fullWidth, errorMessage } = this.props
    let { helpMessage } = this.props

    let style = Object.assign({}, this.props.style)

    let variant = undefined
    if (this.props.customVariant !== 'filled' && this.props.customVariant !== 'naked') {
      if (this.props.customVariant === 'outlined-small') {
        variant = 'outlined'
      } else {
        variant = this.props.customVariant
      }
    }

    if (errorMessage !== undefined && errorMessage !== null) {
      if (errorMessage.trim().length > 0) {
        helpMessage = errorMessage
      }
    }

    if (!style) {
      style = {}
    }

    if (style.marginTop === undefined && this.props.marginTop) {
      if (this.props.marginTop === true) {
        style.marginTop = 20
      } else {
        style.marginTop = this.props.marginTop
      }
    }

    const selectClasses = []
    selectClasses.push(classes.inputSelect)
    selectClasses.push(classes.inputUnderline)
    if (this.props.customVariant === 'naked') {
      selectClasses.push(classes.nakedInput)
    } else if (this.props.customVariant === 'filled') {
      selectClasses.push(classes.filledInput)
    } else if (this.props.customVariant === 'outlined' || this.props.customVariant === 'outlined-small') {
      if (this.props.backgroundColor) {
        style.backgroundColor = this.props.backgroundColor
      } else {
        style.backgroundColor = '#fff'
      }
    }

    let value = this.props.value !== undefined && this.props.value !== null ? this.props.value : this.state.value

    if (value === '' && this.props.placeholder) {
      selectClasses.push(classes.inputSelectWithPlaceholderValue)
    }

    let displayEmpty = false
    if (this.props.displayEmpty || this.props.placeholder) {
      displayEmpty = true
    }

    this.removeUnrecognizedInputProps(inputProps)

    const rootClasses = [classes.root]
    if (this.props.className) {
      rootClasses.push(this.props.className)
    }

    const selectProps = {}

    if (hideIconComponent) {
      selectProps.IconComponent = function () {
        return ''
      }
    }

    if (SelectDisplayProps) {
      selectProps.SelectDisplayProps = SelectDisplayProps
    }

    return (
      <FormControl
        variant={variant}
        disabled={this.props.disabled}
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        style={style}
        error={FormUtil.errorFlag(errorMessage)}
        className={classNames(rootClasses)}
      >
        <InputLabel
          label={this.props.label}
          shrink={this.props.shrink || this.props.shrink === false ? this.props.shrink : true}
          LabelProps={this.props.LabelProps}
          customVariant={this.props.customVariant}
          htmlFor={inputProps.id}
        />

        <Select
          classes={{ select: classNames(selectClasses) }}
          value={value}
          displayEmpty={displayEmpty}
          renderValue={this.props.renderValue}
          onChange={this.handleChange}
          MenuProps={{
            disableRestoreFocus: false,
            disableEnforceFocus: true
          }}
          input={<Input {...inputProps} />}
          {...selectProps}
        >
          {this.props.clearableLabel && (
            <MenuItem key={0} value="">
              <em>{this.props.clearableLabel}</em>
            </MenuItem>
          )}

          {this.props.placeholder && (
            <MenuItem value="" disabled>
              {this.props.placeholder}
            </MenuItem>
          )}

          {this.props.children}
        </Select>

        {helpMessage !== undefined && helpMessage !== null && helpMessage !== '' && (
          <FormHelperText customVariant={this.props.customVariant} nakedLeftPadding={this.props.nakedLeftPadding}>
            {helpMessage}
          </FormHelperText>
        )}

        {staticHelpMessage !== undefined && staticHelpMessage !== null && staticHelpMessage !== '' && (
          <FormHelperText customVariant={this.props.customVariant} nakedLeftPadding={this.props.nakedLeftPadding}>
            {staticHelpMessage}
          </FormHelperText>
        )}

        {superHelpMessage && (
          <PassiveDynamicContent
            updateFunction={() => {
              const result = superHelpMessage()
              const content = result.content

              if (content) {
                result.content = (
                  <FormHelperText error={false} customVariant={this.props.customVariant} nakedLeftPadding={this.props.nakedLeftPadding}>
                    {content}
                  </FormHelperText>
                )
              }

              return result
            }}
            functionsMap={this.superHelpMessageFunctionMap}
          />
        )}
      </FormControl>
    )
  }
}

InputSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default toTS(withStyles(styles)(InputSelect))
