import React from 'react'

import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  COLABORADOR: {
    id: 1,
    descricaoOpcao: 'Colaborador é mais importante',
    descricaoExplicacao: (
      <span>
        Esta opção faz com que o sistema utilize a configuração de comissão padrão do colaborador, mesmo que o produto também tenha uma comissão padrão configurada. Exemplo: A
        funcionária Maria tem a taxa de 30% de comissão padrão configurada e o a venda do produto shampoo tem a taxa de 40% de comissão padrão configurada. Quando a Maria vender um
        shampoo, o sistema irá considerar a taxa de 30% de comissão.
      </span>
    )
  },
  SERVICO: {
    id: 2,
    descricaoOpcao: 'Produto é mais importante',
    descricaoExplicacao: (
      <span>
        Esta opção faz com que o sistema utilize a configuração de comissão padrão do produto, mesmo que o colaborador também tenha uma comissão padrão configurada. Exemplo: O
        funcionário João tem a taxa de 40% de comissão padrão configurada e a venda do produto creme tem a taxa de 35% de comissão padrão configurada. Quando a João fizer uma venda
        de creme, o sistema irá considerar a taxa de 35% de comissão.
      </span>
    )
  }
})
export default enumObject
