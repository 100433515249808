import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_PORTAL_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/ApiClient'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'

const styles = (theme) => ({
  root: theme.page.parentCenteredPaper,
  paper: theme.page.paper({ maxWidth: 536 }),
  contentPeriodoTeste: {
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.main,
    fontSize: 16
  }
})

class VerificarEmail extends Component {
  constructor(props) {
    super(props)
    this.token = this.getTokenFromURL()
    this.state = {
      ajaxing: true,
      verificado: false,
      erroNaoEsperado: false,
      tokenIncorreto: false,
      acessarSistema: false
    }
  }

  componentDidMount() {
    this.verificarEmail()
  }

  getTokenFromURL() {
    let partesCaminhosURL = window.location.pathname.split('/')
    return decodeURIComponent(partesCaminhosURL[partesCaminhosURL.length - 1])
  }

  verificarEmail = () => {
    this.setState((state) => ({
      ajaxing: true,
      tokenIncorreto: false,
      erroNaoEsperado: false
    }))

    postAPI({
      url: 'cda/usuarios.verificarEmail',
      data: {
        token: this.token
      },
      onSuccess: (response) => {
        this.setState((state) => ({ verificado: true }))
      },
      onError: (response) => {
        if (response.code >= 400 && response.code < 500) {
          this.setState((state) => ({ tokenIncorreto: true }))
        } else {
          this.setState((state) => ({ erroNaoEsperado: true }))
        }
      },
      onPreFinal: () => {
        this.setState((state) => ({ ajaxing: false }))
      }
    })
  }

  handleAcessarSistema = () => {
    this.setState((state) => ({ acessarSistema: true }))
  }

  render() {
    if (this.state.acessarSistema === true) {
      const urlContinucao = SessaoUsuario.getUrlContinuacaoAposLogin()
      if (urlContinucao) {
        window.location.href = decodeURIComponent(urlContinucao)
        return ''
      } else {
        return <Redirect to={ROUTE_PORTAL_MAIN} />
      }
    }

    const { classes } = this.props

    let mensagemErroVerificacao
    let botaoVerificar
    let opcoesEmailVerificado

    if (this.state.erroNaoEsperado === true) {
      mensagemErroVerificacao = (
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          Ocorreu um erro inesperado durante a verificação do e-mail. Clique no botão abaixo e tente novamente.
        </Typography>
      )
    } else if (this.state.tokenIncorreto === true) {
      mensagemErroVerificacao = (
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          Não foi possível verificar o e-mail.
          <br />
          URL Inválida.
        </Typography>
      )
    }

    if (this.state.verificado === false && this.state.tokenIncorreto === false) {
      if (this.state.ajaxing) {
        botaoVerificar = (
          <Typography variant="subtitle1" style={{ marginTop: 28, fontSize: '18px' }}>
            Verificando e-mail...
          </Typography>
        )
      } else {
        botaoVerificar = (
          <div>
            <Button disabled={this.state.ajaxing} variant="contained" color="primary" size="large" style={{ marginTop: 24 }} type="button" onClick={this.verificarEmail}>
              {this.state.ajaxing ? 'AGUARDE...' : 'VERIFICAR E-MAIL'}
            </Button>
          </div>
        )
      }
    }

    if (this.state.verificado === true) {
      opcoesEmailVerificado = (
        <div>
          <Typography variant="h5" style={{ marginTop: 22 }}>
            Conta verificada!
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 20 }}>
            Parabéns! A sua conta do Salão99 foi verificada com sucesso. Caso tenha alguma dúvida ou precise de ajuda, entre em contato conosco através do nosso Chat de Suporte.
          </Typography>
          {SessaoUsuario.isUsuarioLogado() && (
            <Button variant="contained" color="primary" size="large" style={{ marginTop: 24 }} type="button" onClick={this.handleAcessarSistema}>
              ACESSAR SISTEMA
            </Button>
          )}
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <div style={{ textAlign: 'center', marginTop: 4 }}>
            <img src={logo} width="100" alt="Salão99" />
            {mensagemErroVerificacao}
            {botaoVerificar}
            {opcoesEmailVerificado}
          </div>
        </Paper>
      </div>
    )
  }
}

VerificarEmail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VerificarEmail)
