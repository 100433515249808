import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputDialogSelect from 'support/components/input/InputDialogSelect'

const styles = (theme) => ({})

class BancoInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    if (!props.label) props.label = 'Banco'
    if (!props.id) props.id = 'xbanco'
    if (!props.label) props.label = 'xbanco'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome,
        banco: props.defaultValue
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Banco...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'erp/bancos.buscar'
    props.DialogSelectProps.searchParamName = 'nome'

    props.DialogSelectProps.generateItemData = (banco) => {
      return {
        value: banco.id,
        label: banco.nome,
        primary: banco.nome,
        banco: banco
      }
    }

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum banco foi encontrado'
      }
    }

    return <InputDialogSelect {...props} />
  }
}

BancoInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BancoInputDialogSelect)
