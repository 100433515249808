import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import BloqueioHorarioGrupoDialogPage from 'pages/erp/painel/colaborador/BloqueioHorarioGrupoDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class BloqueioHorarioGruposDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManutencaoBloqueioHorarioGrupo', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerRemover = (bloqueioHorarioGrupo) => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Indisponibilidade',
      text: (
        <span>
          Você tem certeza que deseja remover a indisponibilidade <b>{bloqueioHorarioGrupo.descricao}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/colaboradores.removerBloqueioHorarioGrupo',
        contextoUsuario: 'erp',
        data: {
          id: bloqueioHorarioGrupo.id
        },
        onSuccess: () => {
          EventsManager.pub('ManutencaoBloqueioHorarioGrupo')
        }
      }
    })
  }

  handlerOpenCreateDialog = () => {
    openBackableDialog(BloqueioHorarioGrupoDialogPage)
  }

  handlerEditar = (item) => {
    openBackableDialog(BloqueioHorarioGrupoDialogPage, {
      idBloqueioHorarioGrupo: item.id
    })
  }

  getRenderContent = (size, bloqueioHorarioGrupo) => {
    const content = {}

    content.descricao = bloqueioHorarioGrupo.descricao
    content.botaoRemover = (
      <IconButton
        style={{ marginRight: size === 'small' ? -16 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.handlerRemover(bloqueioHorarioGrupo)
        }}
      >
        <DeleteIcon />
      </IconButton>
    )
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage
        {...dialogProps}
        title="Indisponibilidades"
        align="center"
        contentMaxWidth={600}
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              icon: <AddIcon />,
              handleAction: this.handlerOpenCreateDialog
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              itemsPerRequest={20}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/colaboradores.buscarBloqueioHorarioGrupos"
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              onClickRow={(item, event) => {
                this.handlerEditar(item)
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Nome', props: { xs: true } },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.descricao, content.botaoRemover]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 80,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography variant="body2">{content.descricao}</Typography>
                          </Grid>
                          <Grid item>{content.botaoRemover}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhuma indisponibilidade foi encontrada',
                textButton: 'Cadastrar Indisponibilidade',
                creationFunction: this.handlerOpenCreateDialog,
                padding: true
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

BloqueioHorarioGruposDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BloqueioHorarioGruposDialogPage)
