import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PagamentosComEstornoResponsiveTable from 'pages/erp/painel/relatorio/table/PagamentosComEstornoResponsiveTable'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class DetalhesEstornosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }

    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNotaVendaPagamento', 'ManipulacaoVendas', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal
    }
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Detalhamento de Estornos - ' + formatarPeriodoNomeArquivo(this.props.dataInicial, this.props.dataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarNotaVendaPagamentosComEstorno',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    let valorTotalEstorno = 0

    for (let item of this.state.lista) {
      valorTotalEstorno = valorTotalEstorno + item.valorTotalEstornos
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} align="center" title="Detalhamento" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Estornos</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <PagamentosComEstornoResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              itemsPerRequest={30}
              contextoUsuario="erp"
              endpoint="erp/vendas.buscarNotaVendaPagamentosComEstornoPorData"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({
                    lista: data.items
                  })
                }
              }}
              margin={true}
              emptyListProps={{
                text: 'Não existem estornos para o período informado'
              }}
            />

            {this.state.lista.length > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Total Estorno: <b>{formatarValorMonetario(valorTotalEstorno)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

DetalhesEstornosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesEstornosDialogPage)
