import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'
import SettingsIcon from '@material-ui/icons/Settings'
import moment from 'moment'
import AgendaBody from 'pages/erp/painel/atendimento/agendainterface/AgendaBody'
import AgendaHeader from 'pages/erp/painel/atendimento/agendainterface/AgendaHeader'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import VendaSupport from 'pages/erp/painel/venda/VendaSupport'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import DiaSemanaEnum from 'support/domain/datetime/DiaSemanaEnum'
import { createEnum } from 'support/util/EnumUtil'
import { converterMomentParaHorarioInt } from 'support/util/TimeConverter'

export const HEADER_HEIGHT = 64

const styles = (theme) => ({
  root: {
    height: 'calc(100% + 20px)'
  },
  messageContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 680
  }
})

export const VisaoEnum = createEnum({
  PROFISSIONAL: { id: 1, descricao: 'Por Profissional', descricaoResumida: 'Profissional' },
  //SEMANA : {id:2,descricao:"Por Semana",descricaoResumida:"Semana"},
  ANDAMENTO: { id: 2, descricao: 'Por Andamento', descricaoResumida: 'Andamento' },
  PAGAMENTO: { id: 3, descricao: 'Por Pagamento', descricaoResumida: 'Pagamento' }
})

export const RegraCorCardEnum = createEnum({
  SERVICO: { id: 1, descricao: 'Por Serviço' },
  COLABORADOR: { id: 2, descricao: 'Por Profissional' },
  ANDAMENTO: { id: 3, descricao: 'Por Andamento' },
  PAGAMENTO: { id: 4, descricao: 'Por Pagamento' }
})

export const SecaoMenuEsquerdo = createEnum({
  FILA_ESPERA: { id: 1, descricao: 'Fila de Espera', icon: <DirectionsWalkIcon style={{ display: 'block' }} /> },
  CONFIGURACOES: { id: 3, descricao: 'Configurações', icon: <SettingsIcon style={{ display: 'block' }} /> }
})

export const COLUNA_ITENS_WIDTH_DEFAULT = 220
export const COLUNA_ITENS_MIN_WIDTH = 80
export const COLUNA_ITENS_MIN_DEFAULT_WIDTH = 140
export const INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT = 30
export const PIXELS_POR_MINUTO_BASE = 1

export function calcularPixelsPorMinuto(intervaloMinutosExibicaoTempo) {
  return (60 / intervaloMinutosExibicaoTempo) * PIXELS_POR_MINUTO_BASE
}

class Agenda extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.contentWithPreloadFunctions = {}

    /*let dataSelecionada = this.colaboradorLocalPreferences.getMomentPreference("AgendaInterface_DataSelecionada",moment());
		if(this.isPrecisaPosicionarDataSelecionadaParaHoje()){
			dataSelecionada = moment();
		}*/

    const diasSemana = JSON.parse(JSON.stringify(DiaSemanaEnum.values()))
    diasSemana.sort(function (a, b) {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0
    })

    this.prepararDiasSemana(diasSemana)

    this.state = {
      interfaceCarregada: false,
      diasSemana: diasSemana,
      numeroMaximoExibicaoAtendimentosMes: this.colaboradorLocalPreferences.getPreference('AgendaInterface_NumeroMaximoExibicaoAtendimentosMes', 8),
      toolsExpanded: this.colaboradorLocalPreferences.getPreference('AgendaInterface_ToolsExpanded', true),
      visao: this.colaboradorLocalPreferences.getPreference('AgendaInterface_Visao', VisaoEnum.PROFISSIONAL),
      regraCorCard: this.colaboradorLocalPreferences.getPreference('AgendaInterface_RegraCorCard', RegraCorCardEnum.SERVICO),
      secaoMenuEsquedo: SecaoMenuEsquerdo.getById(this.colaboradorLocalPreferences.getPreference('AgendaInterface_BarraFerramentasEsquerdaMenu', SecaoMenuEsquerdo.FILA_ESPERA).id)
    }

    this.posicionarDataSelecionadaEHorarioInterval = null
  }

  componentDidMount() {
    this.eventsManager.sub('AgendaInterface', (props) => {
      if (props.action === 'adicionarVenda') {
        VendaSupport.adicionarVenda({
          data: props.data !== undefined ? props.data : this.props.dataSelecionada.toDate(),
          tipoAtendimentoPreSelecionado: props.tipoAtendimentoPreSelecionado ? props.tipoAtendimentoPreSelecionado : AtendimentoTipoEnum.HORA_MARCADA,
          horarioPrePreenchido: props.horarioPrePreenchido,
          colaboradorPreSelecionado: props.colaboradorPreSelecionado
        })
      }
    })
    /*this.posicionarDataSelecionadaEHorario();
		this.posicionarDataSelecionadaEHorarioInterval = window.setInterval(() => {
			this.posicionarDataSelecionadaEHorario();
		},1000);*/
  }

  prepararDiasSemana = (diasSemana) => {
    const idsColaboradoresNaoVisiveisPreferencias = this.colaboradorLocalPreferences.getPreference('AgendaInterface_IdsDiasSemanaNaoVisiveis', [])

    for (let i = 0; i < diasSemana.length; i++) {
      const diaSemana = diasSemana[i]
      if (idsColaboradoresNaoVisiveisPreferencias.indexOf(diaSemana.id) >= 0) {
        diaSemana._show = false
      } else {
        diaSemana._show = true
      }
    }
    this.ajustarDiasSemana(diasSemana)
  }

  ajustarDiasSemana = (diasSemana) => {
    let nenhumVisivel = true
    for (let i = 0; i < diasSemana.length; i++) {
      const diaSemana = diasSemana[i]
      if (diaSemana._show === true) {
        nenhumVisivel = false
        break
      }
    }
    if (nenhumVisivel) {
      for (let i = 0; i < diasSemana.length; i++) {
        const diaSemana = diasSemana[i]
        diaSemana._show = true
      }
    }
  }

  changeExibicaoDiasSemana = (idDiaSemana, show) => {
    const diasSemana = this.state.diasSemana.slice()
    const idsdiasSemanaNaoVisiveis = []
    let nenhumVisivel = true
    let diaSemanaAlterado = null
    for (let i = 0; i < diasSemana.length; i++) {
      const diaSemana = diasSemana[i]
      if (diaSemana.id === idDiaSemana) {
        diaSemanaAlterado = diaSemana
        diaSemanaAlterado._show = show
      }
      if (diaSemana._show === true) {
        nenhumVisivel = false
      }
    }
    if (nenhumVisivel === true && diaSemanaAlterado) {
      diaSemanaAlterado._show = true
    }
    for (let i = 0; i < diasSemana.length; i++) {
      const diaSemana = diasSemana[i]
      if (diaSemana._show === false) {
        idsdiasSemanaNaoVisiveis.push(diaSemana.id)
      }
    }
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_IdsDiasSemanaNaoVisiveis', idsdiasSemanaNaoVisiveis, { type: 'permanent' })
    this.setState({ diasSemana: diasSemana })
  }

  posicionarDataSelecionadaEHorario = () => {
    const agora = moment()
    if (this.isPrecisaPosicionarDataSelecionadaParaHoje()) {
      this.changeDataSelecionada(agora.clone(), true)
      EventsManager.pub('AgendaInterfacePosicionarHorario', { horarioInt: converterMomentParaHorarioInt(agora.clone()) })
    }
  }

  isPrecisaPosicionarDataSelecionadaParaHoje = () => {
    const agora = moment()
    if (this.colaboradorLocalPreferences.getMomentPreference('AgendaInterface_PosicionamentoGridAutomatico', false)) {
      return true
    } else {
      const ultimoAcessoAgendaInterface = this.colaboradorLocalPreferences.getMomentPreference('AgendaInterface_UltimoAcesso', null)
      if (ultimoAcessoAgendaInterface && agora.diff(ultimoAcessoAgendaInterface, 'minutes') > 240) {
        this.colaboradorLocalPreferences.setMomentPreference('AgendaInterface_PosicionamentoGridAutomatico', true)
        return true
      }
    }
    return false
  }

  changeDataSelecionada = (data, automatico) => {
    if (!automatico) {
      this.colaboradorLocalPreferences.setMomentPreference('AgendaInterface_UltimoAcesso', moment())
      this.colaboradorLocalPreferences.setMomentPreference('AgendaInterface_PosicionamentoGridAutomatico', false)
    }
    this.props.changeDataSelecionada(data)
  }

  changeVisao = (visao) => {
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_Visao', visao)
    this.setState({ visao: visao })
  }

  changeNumeroMaximoExibicaoAtendimentosMes = (numero) => {
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_NumeroMaximoExibicaoAtendimentosMes', numero, { type: 'permanent' })
    this.setState({ numeroMaximoExibicaoAtendimentosMes: numero })
  }

  changeRegraCorCard = (regra) => {
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_RegraCorCard', regra, { type: 'permanent' })
    this.setState({ regraCorCard: regra })
  }

  changeToolsExpanded = (expanded) => {
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_ToolsExpanded', expanded, { type: 'permanent' })
    this.setState({ toolsExpanded: expanded })
    EventsManager.pub('CustomScrollbars', { action: 'forceUpdate', delay: 200 })
  }

  changeSecaoMenuEsquerdo = (secaoMenuEsquedo) => {
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_BarraFerramentasEsquerdaMenu', { id: secaoMenuEsquedo.id }, { type: 'permanent' })
    this.setState({ secaoMenuEsquedo: secaoMenuEsquedo })
  }

  render() {
    const { classes } = this.props

    if (!verificarAutorizacao([PA.PODE_ACESSAR_PROPRIO_CALENDARIO])) {
      return (
        <div className={classes.messageContainer}>
          <div style={{ padding: 24 }}>
            <Typography variant="body1" align="center">
              Você não tem acesso para acessar a tela de atendimentos
            </Typography>
          </div>
        </div>
      )
    }

    const sharedProps = {
      changeDesktopDrawerOpen: this.props.changeDesktopDrawerOpen,
      changeToolbarExpanded: this.props.changeToolbarExpanded,
      toolbarExpanded: this.props.toolbarExpanded,
      toolsExpanded: this.state.toolsExpanded,
      changeToolsExpanded: this.changeToolsExpanded,
      dataSelecionada: this.props.dataSelecionada,
      changeDataSelecionada: this.changeDataSelecionada,
      perspectiva: this.props.perspectiva,
      colaboradorSelecionado: this.props.colaboradorSelecionado,
      changeColaboradorSelecionado: this.props.changeColaboradorSelecionado,
      diasSemana: this.state.diasSemana,
      changeExibicaoDiasSemana: this.changeExibicaoDiasSemana,
      numeroMaximoExibicaoAtendimentosMes: this.state.numeroMaximoExibicaoAtendimentosMes,
      changeNumeroMaximoExibicaoAtendimentosMes: this.changeNumeroMaximoExibicaoAtendimentosMes,
      visao: this.state.visao,
      changeVisao: this.changeVisao,
      changeRegraCorCard: this.changeRegraCorCard,
      regraCorCard: this.state.regraCorCard,
      colaboradores: this.props.colaboradores,
      changeExibicaoColaborador: this.props.changeExibicaoColaborador,
      secaoMenuEsquedo: this.state.secaoMenuEsquedo,
      changeSecaoMenuEsquerdo: this.changeSecaoMenuEsquerdo
    }

    return (
      <div className={classes.root}>
        <AgendaHeader {...sharedProps} />
        <AgendaBody {...sharedProps} />
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    window.clearInterval(this.posicionarDataSelecionadaEHorarioInterval)
  }
}

Agenda.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Agenda)
