import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import DetalhesFluxoCaixaDialogPage from 'pages/erp/painel/caixa/DetalhesFluxoCaixaDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import PanelPage from 'support/components/panel/PanelPage'
import CustomScrollbars from 'support/components/scroll/CustomScrollbars'
import TipoDespesaEnum from 'support/domain/despesa/TipoDespesaEnum'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import TipoNegocioMovimentacaoEnum from 'support/domain/fluxocaixa/TipoNegocioMovimentacaoEnum'
import VisaoFluxoCaixaEnum from 'support/domain/fluxocaixa/VisaoFluxoCaixaEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const HEADER_HEIGHT = 70
const MIN_WIDTH_DESCRICAO = 120

const styles = (theme) => ({
  root: {
    height: 'calc(100% + 20px)'
  },
  containerHeader: {
    position: 'relative',
    zIndex: 5,
    ...theme.panelPage.containerHeaderFullScreen({ headerHeight: HEADER_HEIGHT })
  },
  containerBody: {
    position: 'relative',
    zIndex: 5,
    ...theme.panelPage.containerBodyFullScreen({ headerHeight: HEADER_HEIGHT })
  },
  containerBodyContent: {
    ...theme.panelPage.container(),
    paddingRight: '0px !important',
    height: '100%',
    position: 'relative'
  },
  table: {
    minWidth: 'calc(100% - 32px)',
    borderCollapse: 'collapse',
    [theme.breakpoints.down('xs')]: {},
    '& tr': {
      backgroundColor: 'white'
    },
    '& td': {
      fontSize: 14,
      borderBottom: '1px solid #eee',
      padding: '16px 10px',
      cursor: 'pointer'
    },
    '& tr td:first-child': {
      left: 0,
      position: 'sticky',
      zIndex: 10,
      backgroundColor: 'inherit',
      minWidth: MIN_WIDTH_DESCRICAO,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      [theme.breakpoints.down(500)]: {
        maxWidth: MIN_WIDTH_DESCRICAO
      }
    },
    '& tr:first-child td': {
      padding: '0px 10px 16px 10px',
      top: 0,
      position: 'sticky',
      zIndex: 11,
      backgroundColor: 'inherit'
    },
    '& tr:first-child td:first-child': {
      left: 0,
      top: 0,
      position: 'sticky',
      zIndex: 12,
      backgroundColor: 'inherit'
    },
    '& tr:last-child td': {
      borderBottom: '0px solid #eee'
    }
  },
  descricaoItem: {
    fontWeight: '500'
  },
  descricaoSubItem: {
    paddingLeft: '20px !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      whiteSpace: 'inherit !important'
    }
  },
  visao: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    fontWeight: '500'
  },
  valores: {
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  iconAbrirDetalhes: {
    fontSize: 14,
    verticalAlign: 'top',
    marginLeft: 4,
    color: '#888',
    marginTop: 1
  },
  iconExpandirRetrair: {
    verticalAlign: 'middle',
    marginLeft: 8,
    marginTop: -6,
    marginBottom: -4
  }
})

class FluxoCaixaPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      visao: this.colaboradorLocalPreferences.getPreference('FluxoCaixaPanelPage_Visao', VisaoFluxoCaixaEnum.DIA),
      exibirDetalhesReceita: this.colaboradorLocalPreferences.getPreference('FluxoCaixaPanelPage_ExibirDetalhesReceita', false),
      exibirDetalhesDespesas: this.colaboradorLocalPreferences.getPreference('FluxoCaixaPanelPage_ExibirDetalhesDespesas', false)
    }
    this.contentWithPreloadFunctions = {}
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNotaVendaPagamento', 'ManipulacaoVendas', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.contentWithPreloadFunctions.reload()
    })
    EventsManager.pub('PanelFullScreenMode', true)
  }

  changeVisao = (visao) => {
    this.colaboradorLocalPreferences.setPreference('FluxoCaixaPanelPage_Visao', visao)

    this.setState({
      visao: visao
    })
  }

  changeExibirDetalhesReceita = (flag) => {
    this.colaboradorLocalPreferences.setPreference('FluxoCaixaPanelPage_ExibirDetalhesReceita', flag)
    this.setState({ exibirDetalhesReceita: flag })
  }

  changeExibirDetalhesDespesas = (flag) => {
    this.colaboradorLocalPreferences.setPreference('FluxoCaixaPanelPage_ExibirDetalhesDespesas', flag)
    this.setState({ exibirDetalhesDespesas: flag })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    const params = {
      idVisao: this.state.visao.id
    }

    getAPI({
      url: 'erp/fluxocaixa.buscarDadosFluxoCaixa',
      params: params,
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const periodosFluxoCaixa = response.data.periodosFluxoCaixa

        this.setState({
          paginaCarregada: true,
          periodosFluxoCaixa: periodosFluxoCaixa
        })

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  abrirDetalhes = (dataInicial, dataFinal, tipoNegocioMovimentacao, receita, despesa) => {
    openBackableDialog(DetalhesFluxoCaixaDialogPage, {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      visao: this.state.visao,
      tipoNegocioMovimentacao: tipoNegocioMovimentacao,
      receita: receita,
      despesa: despesa
    })
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Fluxo de Caixa - ' + moment().format('YYYYDDMM'),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'fluxocaixa.gerarFluxoCaixa',
        params: {
          idVisao: this.state.visao.id,
          exibirDetalhesReceita: this.state.exibirDetalhesReceita,
          exibirDetalhesDespesas: this.state.exibirDetalhesDespesas
        }
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { periodosFluxoCaixa } = this.state

    const actions = [
      {
        icon: <RefreshIcon />,
        handleAction: () => {
          this.contentWithPreloadFunctions.reload()
        }
      }
    ]

    actions.push({
      icon: <GetAppIcon />,
      label: 'Exportar',
      handleAction: (event) => this.downloadRelatorio(event)
    })

    const receitas = {}
    const despesas = {}
    if (periodosFluxoCaixa) {
      periodosFluxoCaixa.forEach((periodo, indicePeriodo) => {
        periodo.receitas.forEach((receita, i) => {
          if (!receitas[receita.descricao]) {
            receitas[receita.descricao] = Array(periodosFluxoCaixa.length).fill({ valor: 0 })
          }
        })

        periodo.despesas.forEach((despesa, i) => {
          if (!despesas[TipoDespesaEnum.getById(despesa.id).descricao]) {
            despesas[TipoDespesaEnum.getById(despesa.id).descricao] = Array(periodosFluxoCaixa.length).fill({ valor: 0 })
          }
        })
      })

      let saldoInicial = 0

      periodosFluxoCaixa.forEach((periodo, indicePeriodo) => {
        let resultadoLiquido = 0
        let totalReceitas = 0
        let totalDespesas = 0

        periodo.receitas.forEach((receita) => {
          const descricao = receita.descricao
          totalReceitas += receita.valor
          resultadoLiquido += receita.valor
          receitas[descricao].splice(indicePeriodo, 1, {
            valor: receita.valor,
            idFormaPagamento: receita.id,
            dataInicial: periodo.dataInicial,
            dataFinal: periodo.dataFinal,
            descricao: descricao
          })
        })

        periodo.despesas.forEach((despesa) => {
          const descricao = TipoDespesaEnum.getById(despesa.id).descricao
          totalDespesas += despesa.valor
          resultadoLiquido -= despesa.valor
          despesas[descricao].splice(indicePeriodo, 1, {
            valor: despesa.valor,
            idTipoDespesa: despesa.id,
            dataInicial: periodo.dataInicial,
            dataFinal: periodo.dataFinal,
            descricao: descricao
          })
        })

        periodo.totalDespesas = totalDespesas
        periodo.totalReceitas = totalReceitas
        periodo.resultadoLiquido = resultadoLiquido
        periodo.saldoInicial = saldoInicial
        periodo.saldoFinal = saldoInicial + resultadoLiquido
        saldoInicial = periodo.saldoFinal
      })
    }

    return (
      <PanelPage
        {...others}
        title="Fluxo de Caixa"
        toolbarRetractable={false}
        toolbarActions={{
          actions: actions
        }}
      >
        <div className={classes.root}>
          <div className={classes.containerHeader}>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX]} />

            <Grid container alignItems="center">
              <Grid item style={{ marginLeft: -4 }}>
                <InputSelect
                  marginTop={20}
                  idname="xidvisao"
                  value={this.state.visao.id}
                  fullWidth={false}
                  customVariant="naked"
                  onChange={(event) => {
                    this.changeVisao(VisaoFluxoCaixaEnum.getById(parseInt(event.target.value, 10)))
                  }}
                >
                  {VisaoFluxoCaixaEnum.values().map((visao) => (
                    <MenuItem key={visao.id} value={visao.id}>
                      {visao.descricao}
                    </MenuItem>
                  ))}
                </InputSelect>
              </Grid>
            </Grid>
          </div>

          <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadContentPadding={true} loadFunction={this.carregarMain} loadKey={this.state.visao.id}>
            {() => {
              const { exibirDetalhesReceita, exibirDetalhesDespesas } = this.state

              let IconExpandirRetrairReceita = null
              let IconExpandirRetrairDespesas = null

              if (exibirDetalhesReceita) {
                IconExpandirRetrairReceita = ExpandLessIcon
              } else {
                IconExpandirRetrairReceita = ExpandMoreIcon
              }

              if (exibirDetalhesDespesas) {
                IconExpandirRetrairDespesas = ExpandLessIcon
              } else {
                IconExpandirRetrairDespesas = ExpandMoreIcon
              }

              return (
                <div className={classes.containerBody}>
                  <div className={classes.containerBodyContent}>
                    <CustomScrollbars color="rgba(0,0,0,0.1)">
                      <table className={classes.table}>
                        <tbody>
                          <tr>
                            <td></td>
                            {periodosFluxoCaixa.map((periodo, index) => (
                              <td
                                key={index}
                                className={classes.visao}
                                style={{ pointerEvents: periodo.totalDespesas !== 0 || periodo.totalReceitas !== 0 ? 'auto' : 'none' }}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  this.abrirDetalhes(periodo.dataInicial, periodo.dataFinal)
                                }}
                              >
                                {periodo.descricao}
                                {(periodo.totalDespesas !== 0 || periodo.totalReceitas !== 0) && <OpenInNewIcon className={classes.iconAbrirDetalhes} />}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className={classes.descricaoItem}>Saldo Inicial</td>

                            {periodosFluxoCaixa.map((periodo, index) => (
                              <td key={index} className={classes.valores}>
                                {periodo.saldoInicial !== 0 ? formatarValorMonetario(periodo.saldoInicial) : '-'}
                              </td>
                            ))}
                          </tr>
                          <tr style={{ backgroundColor: '#f1fff7' }}>
                            <td
                              className={classes.descricaoItem}
                              onClick={() => {
                                this.changeExibirDetalhesReceita(!exibirDetalhesReceita)
                              }}
                            >
                              <b>Receitas</b> {Object.entries(receitas).length > 0 && <IconExpandirRetrairReceita className={classes.iconExpandirRetrair} />}
                            </td>

                            {periodosFluxoCaixa.map((periodo, index) => (
                              <td
                                key={index}
                                className={classes.valores}
                                style={{ pointerEvents: periodo.totalReceitas !== 0 ? 'auto' : 'none' }}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  this.abrirDetalhes(periodo.dataInicial, periodo.dataFinal, TipoNegocioMovimentacaoEnum.RECEITA)
                                }}
                              >
                                {formatarValorMonetario(periodo.totalReceitas)}
                                {periodo.totalReceitas !== 0 && <OpenInNewIcon className={classes.iconAbrirDetalhes} />}
                              </td>
                            ))}
                          </tr>
                          {exibirDetalhesReceita && (
                            <React.Fragment>
                              {Object.entries(receitas).map(([formaPagamento, item], index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr style={{ backgroundColor: '#f1fff7' }}>
                                      <td className={classes.descricaoSubItem}>{formaPagamento}</td>
                                      {item.map((receita, index2) => (
                                        <td
                                          key={index2}
                                          className={classes.valores}
                                          style={{ pointerEvents: receita.valor !== 0 ? 'auto' : 'none' }}
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            this.abrirDetalhes(receita.dataInicial, receita.dataFinal, TipoNegocioMovimentacaoEnum.RECEITA, receita)
                                          }}
                                        >
                                          {formatarValorMonetario(receita.valor)}
                                          {receita.valor !== 0 && <OpenInNewIcon className={classes.iconAbrirDetalhes} />}
                                        </td>
                                      ))}
                                    </tr>
                                  </React.Fragment>
                                )
                              })}
                            </React.Fragment>
                          )}

                          <tr style={{ backgroundColor: '#fff6f8' }}>
                            <td
                              className={classes.descricaoItem}
                              onClick={() => {
                                this.changeExibirDetalhesDespesas(!exibirDetalhesDespesas)
                              }}
                            >
                              Despesas {Object.entries(despesas).length > 0 && <IconExpandirRetrairDespesas className={classes.iconExpandirRetrair} />}
                            </td>
                            {periodosFluxoCaixa.map((periodo, index) => (
                              <td
                                key={index}
                                className={classes.valores}
                                style={{ pointerEvents: periodo.totalDespesas !== 0 ? 'auto' : 'none' }}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  this.abrirDetalhes(periodo.dataInicial, periodo.dataFinal, TipoNegocioMovimentacaoEnum.DESPESA)
                                }}
                              >
                                {formatarValorMonetario(periodo.totalDespesas)}
                                {periodo.totalDespesas !== 0 && <OpenInNewIcon className={classes.iconAbrirDetalhes} />}
                              </td>
                            ))}
                          </tr>

                          {exibirDetalhesDespesas && (
                            <React.Fragment>
                              {Object.entries(despesas).map(([categoriaDespesa, item], index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr style={{ backgroundColor: '#fff6f8' }}>
                                      <td className={classes.descricaoSubItem}>{categoriaDespesa}</td>
                                      {item.map((despesa, index2) => (
                                        <td
                                          key={index2}
                                          className={classes.valores}
                                          style={{ pointerEvents: despesa.valor !== 0 ? 'auto' : 'none' }}
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            this.abrirDetalhes(despesa.dataInicial, despesa.dataFinal, TipoNegocioMovimentacaoEnum.DESPESA, null, despesa)
                                          }}
                                        >
                                          {formatarValorMonetario(despesa.valor)}
                                          {despesa.valor !== 0 && <OpenInNewIcon className={classes.iconAbrirDetalhes} />}
                                        </td>
                                      ))}
                                    </tr>
                                  </React.Fragment>
                                )
                              })}
                            </React.Fragment>
                          )}

                          <tr>
                            <td className={classes.descricaoItem}>Resultado Líquido</td>
                            {periodosFluxoCaixa.map((periodo, index) => (
                              <td key={index} className={classes.valores}>
                                {formatarValorMonetario(periodo.resultadoLiquido)}
                              </td>
                            ))}
                          </tr>

                          <tr>
                            <td className={classes.descricaoItem}>Saldo Final</td>
                            {periodosFluxoCaixa.map((periodo, index) => (
                              <td key={index} className={classes.valores}>
                                {formatarValorMonetario(periodo.saldoFinal)}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </CustomScrollbars>
                  </div>
                </div>
              )
            }}
          </ContentWithPreload>
        </div>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

FluxoCaixaPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FluxoCaixaPanelPage)
