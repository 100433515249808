import React, { Component } from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import AtendimentoDialogPage from 'pages/erp/painel/atendimento/AtendimentoDialogPage'
import VendaCreditoDialogPage from 'pages/erp/painel/cliente/VendaCreditoDialogPage'
import VendaProdutoDialogPage from 'pages/erp/painel/inventario/VendaProdutoDialogPage'
import VendaPacoteDialogPage from 'pages/erp/painel/pacotes/VendaPacoteDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import FechamentoContaDialogPage from 'pages/erp/painel/venda/FechamentoContaDialogPage'
import VendasNotaResponsiveTable from 'pages/erp/painel/venda/table/VendasNotaResponsiveTable'
import VendaSupport from 'pages/erp/painel/venda/VendaSupport'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import DetailsDialog from 'support/components/dialog/preconstructed/DetailsDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import OrigemCreditoEnum from 'support/domain/cliente/OrigemCreditoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import StatusNotaVendaEnum from 'support/domain/venda/StatusNotaVendaEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { converterMomentParaHorarioInt } from 'support/util/TimeConverter'

const styles = (theme) => ({
  colunaFixa: {
    flexGrow: 1,
    maxWidth: 180,
    minWidth: 90
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 210,
    minWidth: 200
  }
})

class VisualizacaoItensNotaDialog extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      adicionarMenuAnchorPosition: null,
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoVenda', 'ManipulacaoVendas'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  openAdicionarMenuAnchorPosition = (event) => {
    const { nota } = this.props

    if (nota.cancelada || StatusNotaVendaEnum.FECHADA.id === nota.status.id) {
      let situacao = ''
      if (nota.cancelada) {
        situacao = 'cancelada'
      } else if (StatusNotaVendaEnum.FECHADA.id === nota.status.id) {
        situacao = 'fechada'
      }

      openBackableDialog(MessageDialog, {
        maxWidth: 400,
        text: 'A fatura #' + this.props.nota.numero + ' está ' + situacao + ' e não é possível incluir novos itens.'
      })

      return
    }

    this.setState({
      adicionarMenuAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      }
    })
  }

  closeAdicionarMenuAnchorPosition = () => {
    this.setState({
      adicionarMenuAnchorPosition: null
    })
  }

  getRenderContent = (size, item) => {
    const content = {}

    if (item.clientePacoteItem) {
      content.valorSubtotal = (
        <span>
          Sem cobrança
          <br />
          <small>Utilização de pacote</small>
        </span>
      )
      content.valorDesconto = '-'
    } else {
      content.valorSubtotal = formatarValorMonetario(item.valorSubtotal)
      content.valorDesconto = formatarValorMonetario(item.valorDescontoCalculado)
    }
    content.dataVenda = converterDataIntParaMoment(item.dataVenda).format('DD/MM/YYYY')
    content.descricao = VendaService.getDescricao(item)
    content.quantidade = item.quantidade.toString()
    return content
  }

  abrirAtendimentoDialogPage = () => {
    if (!verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
      openBackableDialog(AccessDeniedDialog, {
        message: 'Você não tem permissão para criar atendimentos.'
      })
      this.closeAdicionarMenuAnchorPosition()
      return
    }
    this.abrirVendaDialogPage(AtendimentoDialogPage, {
      horarioPrePreenchido: converterMomentParaHorarioInt(moment()),
      mostrarConfiguracaoRepeticao: false
    })
  }

  abrirVendaProdutoDialogPage = () => {
    if (!verificarAutorizacao([PA.PODE_VENDER])) {
      openBackableDialog(AccessDeniedDialog, {
        message: 'Você não tem permissão para registrar vendas.'
      })
      this.closeAdicionarMenuAnchorPosition()
      return
    }
    this.abrirVendaDialogPage(VendaProdutoDialogPage)
  }

  abrirVendaPacoteDialogPage = () => {
    if (!verificarAutorizacao([PA.PODE_VENDER])) {
      openBackableDialog(AccessDeniedDialog, {
        message: 'Você não tem permissão para registrar vendas.'
      })
      this.closeAdicionarMenuAnchorPosition()
      return
    }
    this.abrirVendaDialogPage(VendaPacoteDialogPage)
  }

  abrirVendaDialogPage = (dialogPage, props) => {
    this.closeAdicionarMenuAnchorPosition()
    openBackableDialog(dialogPage, {
      cliente: this.props.cliente,
      nota: this.props.nota,
      data: moment(),
      contexto: 'AdicionarItensFaturaExistente',
      ...props
    })
  }

  adicionarItemNaoFaturado = () => {
    if (!verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(AccessDeniedDialog, {
        message: 'Você não tem permissão para faturar novos itens.'
      })
      this.closeAdicionarMenuAnchorPosition()
      return
    }
    this.closeAdicionarMenuAnchorPosition()
    if (verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(FechamentoContaDialogPage, {
        contexto: 'AdicionarItensFaturaExistente',
        cliente: this.props.cliente,
        nota: this.props.nota,
        retornarVendas: false
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  openItemMenu = (event, venda) => {
    this.setState({ itemMenuAnchorEl: event.currentTarget, vendaItemMenu: venda })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  tentarEditarVenda = (venda, referenciaEntidade, edicaoFunction) => {
    if (VendaService.isAtendimento(venda)) {
      if (!verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
        openBackableDialog(AccessDeniedDialog)
        return
      }
    } else {
      if (!verificarAutorizacao([PA.PODE_VENDER])) {
        openBackableDialog(AccessDeniedDialog)
        return
      }
    }
    edicaoFunction()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { adicionarMenuAnchorPosition, vendaItemMenu, itemMenuAnchorEl } = this.state

    return (
      <DetailsDialog
        {...dialogProps}
        variant="withHeaderActions"
        title={'Fatura #' + this.props.nota.numero}
        subtitle={'Itens da Fatura'}
        actions={[
          {
            icon: AddIcon,
            onClick: (event) => {
              this.openAdicionarMenuAnchorPosition(event)
            }
          }
        ]}
        maxWidth={900}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <Menu
              anchorReference="anchorPosition"
              anchorPosition={adicionarMenuAnchorPosition}
              open={Boolean(adicionarMenuAnchorPosition)}
              onClose={this.closeAdicionarMenuAnchorPosition}
            >
              <MenuItem onClick={this.abrirAtendimentoDialogPage}>Novo Atendimento</MenuItem>
              <MenuItem onClick={this.abrirVendaProdutoDialogPage}>Nova Venda de Produto</MenuItem>
              <MenuItem onClick={this.abrirVendaPacoteDialogPage}>Nova Venda de Pacote</MenuItem>
              <MenuItem onClick={this.adicionarItemNaoFaturado}>Incluir Item Não Faturado</MenuItem>
            </Menu>

            <VendasNotaResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/vendas.buscarItensNota"
              getRequestParametersFunction={() => {
                return {
                  idNota: this.props.nota.id
                }
              }}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const state = { lista: data.items }
                  if (!this.state.categorias || !this.state.categorias.length) {
                    state.paginaCarregada = true
                    state.toolbarActions = {
                      actions: []
                    }
                  }
                  this.setState(state)
                }
              }}
              margin={true}
              acao={{
                icon: MoreVertIcon,
                onClick: (event, venda) => {
                  event.stopPropagation()
                  this.openItemMenu(event, venda)
                }
              }}
              emptyListProps={{
                text: 'Nenhum item foi encontrado. Você pode não ter permissão para visualizar essas informações.'
              }}
            />
            <Menu anchorEl={itemMenuAnchorEl} open={Boolean(itemMenuAnchorEl)} onClose={() => this.closeMenu('itemMenuAnchorEl')}>
              <MenuItem
                onClick={() => {
                  if (VendaService.isAtendimento(vendaItemMenu)) {
                    this.tentarEditarVenda(vendaItemMenu, 'este atendimento', () => {
                      openBackableDialog(AtendimentoDialogPage, {
                        idAtendimento: vendaItemMenu.atendimento.id
                      })
                    })
                  } else if (VendaService.isVendaProduto(vendaItemMenu)) {
                    this.tentarEditarVenda(vendaItemMenu, 'este atendimento', () => {
                      openBackableDialog(VendaProdutoDialogPage, {
                        idVendaProduto: vendaItemMenu.vendaProduto.id
                      })
                    })
                    /*openBackableDialog(MessageDialog,{
											maxWidth : 400,
											text : "Não é possível editar vendas de produto após a fatura ser aberta."
										});*/
                  } else if (VendaService.isVendaPacote(vendaItemMenu)) {
                    this.tentarEditarVenda(vendaItemMenu, 'este atendimento', () => {
                      openBackableDialog(VendaPacoteDialogPage, {
                        idVendaPacote: vendaItemMenu.vendaPacote.id
                      })
                    })
                    /*openBackableDialog(MessageDialog,{
											maxWidth : 400,
											text : "Não é possível editar vendas de pacote após a fatura ser aberta."
										});*/
                  } else if (VendaService.isVendaCredito(vendaItemMenu)) {
                    this.tentarEditarVenda(vendaItemMenu, 'este atendimento', () => {
                      openBackableDialog(VendaCreditoDialogPage, {
                        idVendaCredito: vendaItemMenu.vendaCredito.id,
                        origem: OrigemCreditoEnum.VENDA
                      })
                    })
                    /*openBackableDialog(MessageDialog,{
											maxWidth : 400,
											text : "Não é possível editar vendas de crédito após a fatura ser aberta."
										});*/
                  }
                  this.closeMenu('itemMenuAnchorEl')
                }}
              >
                Editar
              </MenuItem>
              <MenuItem
                onClick={() => {
                  VendaSupport.cancelarVenda({ venda: vendaItemMenu })
                  this.closeMenu('itemMenuAnchorEl')
                }}
              >
                Cancelar
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </DetailsDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

VisualizacaoItensNotaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VisualizacaoItensNotaDialog)
