import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  GERAL: { id: 1, descricao: 'Despesas Gerais' },
  COMISSAO: { id: 2, descricao: 'Remunerações Pagas' },
  PRODUTO: { id: 3, descricao: 'Compra de Produtos' },
  VALE: { id: 4, descricao: 'Vale de Funcionário' },
  TAXA_RECEBIMENTO: { id: 5, descricao: 'Taxa de Forma de Pagamento' },
  ESTORNO_DINHEIRO: { id: 6, descricao: 'Estorno Dinheiro' },
  SERVICO: { id: 7, descricao: 'Despesa de Serviço' }
})

export default enumObject
