import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import FileUploader from 'support/components/fileuploader/FileUploader'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ConfiguracoesCertificadoDigitalDialogPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      errorsMap: createErrorsMap(),
      ajaxing: false,
      certificadoConfigurado: false
    }
    this.inputNome = React.createRef()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    const { files } = this.state

    if (files && files.length > 0) {
      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      const formData = new FormData()
      formData.append('senha', this.inputNome.current.value)
      formData.append('arquivo', files[0])

      postAPI({
        url: 'erp/negocios.vincularCertificadoDigitalNf',
        isMultiparFormData: true,
        data: formData,
        requerAutorizacao: true,
        timeout: 0,
        onSuccess: (response) => {
          this.setState({ ajaxing: false, certificadoConfigurado: true })
        },
        onError: (response) => {
          this.setState({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) })
          if (response.code === 413) {
            EventsManager.pub('MensagemSnackBar', 'O total de dados enviados é muito grande.')
          } else if (response.code !== 403) {
            if (response.data.errors['erroValidacao']) {
              openBackableDialog(MessageDialog, {
                text: response.data.errors['erroValidacao']
              })
            } else {
              focusFirstElementWithError('formConfiguracoesCertificadoDigital')
            }
          }
        }
      })
    } else {
      openBackableDialog(MessageDialog, {
        title: 'Carregue um certificado',
        text: 'É necessário carregar o arquivo do certificado digital no formato .PFX ou .P12'
      })
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { certificadoConfigurado, ajaxing, errorsMap, files } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={'Certificado Digital'}
        align="center"
        contentMaxWidth={520}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              label: 'Salvar',
              disabled: ajaxing,
              show: certificadoConfigurado ? false : true,
              handleAction: () => this.salvar()
            }
          ]
        }}
      >
        {certificadoConfigurado === true && <Typography variant="body2">O certificado digital foi carregado com sucesso.</Typography>}

        {certificadoConfigurado === false && (
          <form id="formConfiguracoesCertificadoDigital" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <Typography variant="body2">Configure o certificado digital da empresa para emitir notas fiscais de serviços diretamente pelo sistema.</Typography>

            <div style={{ display: 'block', marginTop: 16, marginBottom: 24 }}>
              <FileUploader
                accept={'.pfx,.p12'}
                dropzoneText={
                  <span>
                    Arraste o arquivo .PFX ou .P12 do certificado digital ou <b>clique aqui</b> para selecioná-lo
                  </span>
                }
                multiple={false}
                uploading={ajaxing}
                disabled={ajaxing}
                files={files}
                onDropChange={(files) => {
                  this.setState({
                    files: files
                  })
                }}
                onRemoveFile={(index) => {
                  const files = this.state.files.slice(0)
                  files.splice(index, 1)
                  this.setState({
                    files: files
                  })
                }}
              />
            </div>
            <InputText
              id="xsenha"
              customVariant="outlined-small"
              shrink={true}
              disabled={ajaxing}
              errorMessage={errorsMap('senha')}
              label="Senha do Certificado"
              type={'password'}
              inputProps={{ maxLength: 255 }}
              inputRef={this.inputNome}
            />
          </form>
        )}

        {certificadoConfigurado === false && (
          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
              Cancelar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
              {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
            </Button>
          </div>
        )}

        {certificadoConfigurado === true && (
          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.props.handleCloseDialog}>
              Ok
            </Button>
          </div>
        )}
      </DialogPage>
    )
  }
}

ConfiguracoesCertificadoDigitalDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesCertificadoDigitalDialogPage)
