import React, { Component } from 'react'

import Chip from '@material-ui/core/Chip'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  container: {
    mmarginLeft: 'auto',
    mmarginRight: 'auto',
    width: '100%'
  },
  containerTypeSectionTitle: {
    paddingBottom: theme.spacing(4)
  },
  containerTypeSectionMiddleTitle: {
    padding: '24px 0px 4px 0px'
  },
  containerContextDialogPage: {
    [theme.breakpoints.down('xs')]: {
      //paddingTop: theme.spacing(1)
    }
  },
  mcontentHorizontalMargin: {
    ...theme.screen.horizontalMarginStyles()
  },
  containerPaper: {
    marginTop: theme.spacing(1),
    backgroundColor: '#fff',
    ...theme.util.createBoxShadow('0 3px 1px -2px rgba(0,0,0,.19), 0 2px 3px 0 rgba(0,0,0,.038), 0 1px 5px 0 rgba(0,0,0,.13)')
  },
  containerTypeTableSummary: {
    width: 'auto',
    display: 'inline-block'
  },
  content: {
    textAlign: 'center',
    //paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      //paddingBottom: theme.spacing(2),
    }
  },
  contentHorizontalMarginSmall: {
    [theme.breakpoints.down(380)]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  contentTypeFilter: {
    [theme.breakpoints.up('sm')]: {
      ppaddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 4,
      paddingBottom: 4
    }
  },
  contentTypeTableSummary: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    padding: 22,
    border: '1px solid ' + theme.palette.divider,
    borderRadius: 16
  },
  containerChips: {
    marginLeft: 0,
    textAlign: 'left',
    marginBottom: 4
  },
  rootChip: {
    marginTop: 8,
    marginRight: 6,
    background: darken(theme.palette.secondary.light, 0.05),
    '&:focus': {
      background: darken(theme.palette.secondary.light, 0.05)
    },
    height: 30
  },
  labelChip: {
    //color: "#2f8bdc",
    color: 'white',
    paddingLeft: 10
  },
  deleteIconChip: {
    //color: "#3b9ff7",
    color: 'white',
    fontSize: 20
  }
})

class FloatContent extends Component {
  render() {
    const { classes } = this.props

    let marginTop = null
    if (this.props.marginTop) {
      marginTop = this.props.marginTop
    }

    let textAlign = 'left'

    const containerClasses = [classes.container]

    if (this.props.container === 'paper') {
      //containerClasses.push(classes.containerPaper);
    }

    if (this.props.context === 'DialogPage') {
      containerClasses.push(classes.containerContextDialogPage)
    }

    const contentClasses = [classes.content, classes.contentHorizontalMargin]

    if (this.props.horizontalMarginSmall) {
      contentClasses.push(classes.contentHorizontalMarginSmall)
    }

    if (this.props.type === 'filter') {
      contentClasses.push(classes.contentTypeFilter)
    } else if (this.props.type === 'tableSummary') {
      containerClasses.push(classes.containerTypeTableSummary)
      contentClasses.push(classes.contentTypeTableSummary)
      textAlign = 'right'
    } else if (this.props.type === 'sectionTitle') {
      containerClasses.push(classes.containerTypeSectionTitle)
    } else if (this.props.type === 'sectionMiddleTitle') {
      containerClasses.push(classes.containerTypeSectionMiddleTitle)
    }

    if (this.props.textAlign) {
      textAlign = this.props.textAlign
    }

    const content = (
      <div className={classNames(containerClasses)} style={{ maxWidth: this.props.maxWidth }}>
        <div className={classNames(contentClasses)} style={{ marginTop: marginTop, textAlign: textAlign }}>
          {this.props.children}
          {this.props.chips && this.props.chips.length > 0 && (
            <div className={classes.containerChips}>
              {this.props.chips.map((item, index) => {
                return (
                  <HiddenItem key={index} rule={item.hiddenRule}>
                    <Chip
                      label={item.label}
                      onDelete={item.onDelete}
                      classes={{
                        root: classes.rootChip,
                        label: classes.labelChip,
                        deleteIcon: classes.deleteIconChip
                      }}
                    />
                  </HiddenItem>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )

    if (this.props.type === 'tableSummary') {
      return <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'right', maxWidth: this.props.maxWidth }}>{content}</div>
    } else {
      return content
    }
  }
}

function HiddenItem(props) {
  if (props.rule) {
    const hiddenProps = { [props.rule]: true }

    if (props.implementation) {
      hiddenProps.implementation = props.implementation
    } else {
      hiddenProps.implementation = 'js'
    }
    return <Hidden {...hiddenProps}>{props.children}</Hidden>
  } else {
    return props.children
  }
}

FloatContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FloatContent)
