import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import DetalhesPagamentosDialogPage from 'pages/erp/painel/relatorio/DetalhesPagamentosDialogPage'
import ResumoPagamentosAdquirentesBandeirasDialogPage from 'pages/erp/painel/relatorio/ResumoPagamentosAdquirentesBandeirasDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ChartPie from 'support/components/chart/ChartPie'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaFormaPagamento: {
    flexGrow: 1
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaQuantidade: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaIcone: {
    maxWidth: 66,
    minWidth: 66
  },
  tituloInformacoesFinanceiras: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  }
})

class RelatorioFormasPagamentoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxingMaisUtilizados: false,
      detalhamentoFormasPagamentoDataInicial: this.colaboradorLocalPreferences.getMomentPreference(
        'RelatorioFormasPagamentoDialogPageFiltro_MaisLucrativosDataInicial',
        moment().startOf('month')
      ),
      detalhamentoFormasPagamentoDataFinal: this.colaboradorLocalPreferences.getMomentPreference(
        'RelatorioFormasPagamentoDialogPageFiltro_MaisLucrativosDataFinal',
        moment().endOf('month')
      ),
      detalhamentoFormasPagamento: []
    }
    this.dadosMaisUtilizadosLoaderFunctionsMap = {}
  }

  carregarMaisUtilizados = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingMaisUtilizados: true })

    getAPI({
      url: 'erp/relatorios.buscarDetalhamentoFormasDePagamento',
      params: {
        dataInicial: converterMomentParaDataInt(this.state.detalhamentoFormasPagamentoDataInicial),
        dataFinal: converterMomentParaDataInt(this.state.detalhamentoFormasPagamentoDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxingMaisUtilizados: false,
          detalhamentoFormasPagamento: response.data,
          dadosTopMaisLucrativos: this.prepararDadosTopMaisLucrativos(response.data)
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingMaisUtilizados: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosTopMaisLucrativos = (items) => {
    const dados = []
    for (let item of items) {
      if (dados.length < 6) {
        dados.push({
          name: (
            <span>
              <b>{item.nome}</b> - Qtd: {item.quantidade}, {formatarValorMonetario(item.valor, { prefix: false })}
            </span>
          ),
          value: item.valor
        })
      } else if (dados.length === 6) {
        dados.push({
          name: (
            <span>
              <b>Outros</b> - Qtd: {item.quantidade}, {formatarValorMonetario(item.valor, { prefix: false })}
            </span>
          ),
          value: item.valor,
          quantidade: item.quantidade
        })
      } else {
        const ultimoItem = dados[dados.length - 1]
        ultimoItem.value = ultimoItem.value + item.valor
        ultimoItem.quantidade = ultimoItem.quantidade + item.quantidade
        ultimoItem.name = (
          <span>
            <b>Outros</b> - Qtd: {ultimoItem.quantidade}, {formatarValorMonetario(ultimoItem.value, { prefix: false })}
          </span>
        )
      }
    }
    return dados
  }

  abrirDetalhes = (item) => {
    if (item.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id || item.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id) {
      openBackableDialog(ResumoPagamentosAdquirentesBandeirasDialogPage, {
        dataInicial: this.state.detalhamentoFormasPagamentoDataInicial,
        dataFinal: this.state.detalhamentoFormasPagamentoDataFinal,
        idFormaPagamento: item.id,
        tituloDialog: FormaPagamentoTipoEnum.getById(item.tipo.id).descricao
      })
    } else {
      openBackableDialog(DetalhesPagamentosDialogPage, {
        dataInicial: this.state.detalhamentoFormasPagamentoDataInicial,
        dataFinal: this.state.detalhamentoFormasPagamentoDataFinal,
        tituloDialog: item.nome,
        idFormaPagamento: item.id
      })
    }
  }

  getRenderContent = (size, item) => {
    const content = {}
    if (size === 'small') {
      content.valor = formatarValorMonetario(item.valor, { prefix: true })
      content.abrirDetalhes = (
        <IconButton
          style={{ marginTop: -10, marginBottom: -6, padding: 8 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    } else {
      content.valor = formatarValorMonetario(item.valor, { prefix: false })
      content.abrirDetalhes = (
        <IconButton
          style={{ marginRight: 0, padding: 6 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    }

    content.descricao = item.nome
    content.quantidade = item.quantidade.toString()

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxingMaisUtilizados, detalhamentoFormasPagamentoDataInicial, detalhamentoFormasPagamentoDataFinal } = this.state

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.detalhamentoFormasPagamento) {
      valorTotal += item.valor
      quantidadeTotal += item.quantidade
    }

    let toolbarActions = null
    if (!ajaxingMaisUtilizados) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.dadosMaisUtilizadosLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let detalhamentoFormasPagamentoLoadKey =
      converterMomentParaDataInt(this.state.detalhamentoFormasPagamentoDataInicial).toString() +
      '-' +
      converterMomentParaDataInt(this.state.detalhamentoFormasPagamentoDataFinal).toString()

    return (
      <DialogPage
        {...dialogProps}
        title="Formas de Pagamento"
        align="center"
        contentMaxWidth={1200}
        pageType="basicEntity"
        ajaxing={ajaxingMaisUtilizados}
        toolbarActions={toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Informações Gerais
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <DateRangeNavigator
                    expanded={false}
                    defaultValue={{
                      tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioFormasPagamentoDialogPageFiltro_DadosFinanceirosTipoIntervalo', 'mes'),
                      dataInicial: detalhamentoFormasPagamentoDataInicial,
                      dataFinal: detalhamentoFormasPagamentoDataFinal
                    }}
                    onChange={(data) => {
                      this.colaboradorLocalPreferences.setPreference('RelatorioFormasPagamentoDialogPageFiltro_DadosFinanceirosTipoIntervalo', data.tipoIntervalo.nome)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioFormasPagamentoDialogPageFiltro_MaisLucrativosDataInicial', data.dataInicial)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioFormasPagamentoDialogPageFiltro_MaisLucrativosDataFinal', data.dataFinal)
                      this.setState({
                        detalhamentoFormasPagamentoDataInicial: data.dataInicial,
                        detalhamentoFormasPagamentoDataFinal: data.dataFinal
                      })
                    }}
                  />
                </div>
              </div>
            </FloatContent>

            <ContentWithPreload functionsMap={this.dadosMaisUtilizadosLoaderFunctionsMap} loadFunction={this.carregarMaisUtilizados} loadKey={detalhamentoFormasPagamentoLoadKey}>
              {() => (
                <React.Fragment>
                  <div style={{ width: '100%', paddingBottom: 32, paddingTop: 32 }}>
                    <ChartPie
                      data={{
                        items: this.state.dadosTopMaisLucrativos
                      }}
                    />
                  </div>

                  {this.state.detalhamentoFormasPagamento && this.state.detalhamentoFormasPagamento.length > 0 && (
                    <React.Fragment>
                      <div>
                        <VirtualizedResponsiveTable
                          showBackgroundDividers={true}
                          scrollElement={dialogContentProps.scrollContainerRef.current}
                          items={this.state.detalhamentoFormasPagamento}
                          onClickRow={(item, event) => {
                            this.abrirDetalhes(item)
                          }}
                          largeRenderProps={{
                            columns: [
                              { label: 'Forma de Pagamento', className: classes.colunaCliente, props: { xs: true } },
                              { label: 'Quantidade', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                              { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item)

                              return {
                                itemData: [content.descricao, content.quantidade, content.valor, content.abrirDetalhes]
                              }
                            }
                          }}
                          smallRenderProps={{
                            rowHeight: 88,
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('small', item)

                              return {
                                itemData: (
                                  <React.Fragment>
                                    <Grid container style={{ minWidth: 0 }} alignItems="center">
                                      <Grid item xs style={{ minWidth: 0 }}>
                                        <Typography variant="body2" noWrap={true}>
                                          <b>{content.descricao}</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item>{content.abrirDetalhes}</Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: 4 }} alignItems="center">
                                      <Grid item xs>
                                        <Typography variant="body2" noWrap={true}>
                                          {content.valor}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="body2">Qtd.: {content.quantidade}</Typography>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                )
                              }
                            }
                          }}
                          emptyListProps={{
                            text: 'Não existem formas de pagamento para o período informado'
                          }}
                        />
                      </div>

                      {quantidadeTotal > 1 && (
                        <FloatContent container="paper" context="DialogPage" type="tableSummary">
                          <Typography variant="body2">
                            Quantidade: <b>{quantidadeTotal}</b>
                            <br />
                            Total: <b>{formatarValorMonetario(valorTotal)}</b>
                            <br />
                          </Typography>
                        </FloatContent>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioFormasPagamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioFormasPagamentoDialogPage)
