import { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import InputFormaPagamentoDialog from 'pages/erp/painel/formapagamento/input/InputFormaPagamentoDialog'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputText from 'support/components/input/InputText'
import FormaPagamentoService from 'support/domain/formapagamento/FormaPagamentoService'

const styles = (theme) => ({
  clearIcon: {
    fontSize: 16,
    color: '#bbb'
  }
})

class InputFormaPagamento extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : null
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getValues = () => {
        const value = this.state.value ? this.state.value : {}
        const result = {}
        if (value.formaPagamento) {
          result.formaPagamento = { id: value.formaPagamento.id }
        } else {
          result.formaPagamento = {}
        }
        if (value.adquirente) {
          result.adquirente = { id: value.adquirente.id }
        } else {
          result.adquirente = {}
        }
        if (value.bandeira) {
          result.bandeira = { id: value.bandeira.id }
        } else {
          result.bandeira = {}
        }
        if (value.numeroParcelas) {
          result.numeroParcelas = value.numeroParcelas
        } else {
          result.numeroParcelas = 1
        }
        return result
      }
    }
  }

  handleClick = (event) => {
    if (!this.props.disabled) {
      openBackableDialog(InputFormaPagamentoDialog, {
        selectionType: this.props.selectionType,
        cliente: this.props.cliente,
        setValue: (value) => {
          this.setValue(value)
        },
        valorTotal: this.props.valorTotal,
        notaDialogPageProps: this.props.notaDialogPageProps
      })
    }
  }

  handleFocus = (event) => {}

  setValue = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.setState({ value: value })
  }

  render() {
    const { classes, ...othersInputProps } = this.props
    delete othersInputProps.defaultValue
    delete othersInputProps.selectionType

    othersInputProps.onClick = this.handleClick
    othersInputProps.onFocus = this.handleFocus
    othersInputProps.readOnly = true

    if (!this.state.value) {
      othersInputProps.value = ''
    } else {
      const formaPagamento = this.state.value.formaPagamento
      const numeroParcelas = this.state.value.numeroParcelas
      const adquirente = this.state.value.adquirente
      const bandeira = this.state.value.bandeira
      if (formaPagamento) {
        othersInputProps.value = FormaPagamentoService.gerarDescricaoInline(formaPagamento, adquirente, bandeira, numeroParcelas)
      }
    }

    if (this.state.value) {
      othersInputProps.endAdornment = (
        <IconButton
          disabled={this.props.disabled}
          onClick={(event) => {
            event.stopPropagation()
            this.setValue(null)
          }}
        >
          <CloseIcon className={classes.clearIcon} />
        </IconButton>
      )
    }
    return <InputText {...othersInputProps} />
  }
}

InputFormaPagamento.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputFormaPagamento)
