export const ROUTE_ENTERPRISE_MAIN = '/enterprise/sistema'
export const ROUTE_ENTERPRISE_MAIN_REGEX = '\\/enterprise\\/sistema'
export const ROUTE_ENTERPRISE_PANEL = ROUTE_ENTERPRISE_MAIN + '/painel'
export const ROUTE_ENTERPRISE_PANEL_REGEX = ROUTE_ENTERPRISE_MAIN_REGEX + '\\/painel'

export const ROUTE_ADMIN_MAIN = '/admin'
export const ROUTE_ADMIN_MAIN_REGEX = '\\/admin'
export const ROUTE_ADMIN_PANEL = ROUTE_ADMIN_MAIN + '/painel'
export const ROUTE_ADMIN_PANEL_REGEX = ROUTE_ADMIN_MAIN_REGEX + '\\/painel'

export const ROUTE_PORTAL_MAIN = '/' // Pode ser usado como url final, Ex: /portal ou /
export const ROUTE_PORTAL_MAIN_AS_PREFIX = '' // mão pode ser usado como url final e tem o objetivo apenas de ser prefixos de outras paginas do portal, Ex: /portal ou "" (se fosse /, como a ROUTE_PORTAL_MAIN, ao juntar com outras paginas do portal, ficaria incorreto. Ex: //busca)
export const ROUTE_PORTAL_MAIN_REGEX = '\\/'
export const ROUTE_PORTAL_MAIN_REGEX_AS_PREFIX = ''
export const ROUTE_PORTAL_PANEL = ROUTE_PORTAL_MAIN_AS_PREFIX + '/minhaconta'
export const ROUTE_PORTAL_PANEL_REGEX = ROUTE_PORTAL_MAIN_REGEX_AS_PREFIX + '\\/minhaconta'

export const ROUTE_CONTA_MAIN = '/conta'
export const ROUTE_CONTA_MAIN_REGEX = '\\/conta'
