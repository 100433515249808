import React, { Component } from 'react'

class Transition extends Component {
  render() {
    const { component: ComponentToRender, children, ...others } = this.props

    return (
      <ComponentToRender {...others}>
        <TransitionContent children={children} />
      </ComponentToRender>
    )
  }
}

function TransitionContent(props) {
  const { children, nodeRef, ...others } = props
  return children(others)
}

export default Transition
