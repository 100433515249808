import { createInlineValuesObject } from 'support/components/inlinevalues/InlineValues'

const obj = createInlineValuesObject({
  PROPRIETARIO: { position: 0, descricao: 'Proprietário' },
  GERENTE: { position: 1, descricao: 'Gerente' },
  PROFISSIONAL: { position: 2, descricao: 'Profissional' },
  ATENDENTE: { position: 3, descricao: 'Atendente' }
})

export default obj
