import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import CustomDialog from 'support/components/dialog/CustomDialog'
import NegocioSegmentoAtuacaoEnum from 'support/domain/negocio/NegocioSegmentoAtuacaoEnum'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 540,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  }
})

class BuscaFiltrosDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      segmentosAtuacao: NegocioSegmentoAtuacaoEnum.generateAllItems(props.segmentosAtuacaoString)
    }
  }

  limparFiltro = () => {
    this.props.handleCloseDialog({
      onClosedCallback: () => {
        this.props.onSelect({
          segmentosAtuacaoString: ''
        })
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.aplicarFiltro()
  }

  aplicarFiltro = () => {
    this.props.handleCloseDialog({
      onClosedCallback: () => {
        this.props.onSelect({
          segmentosAtuacaoString: NegocioSegmentoAtuacaoEnum.getString(this.state.segmentosAtuacao)
        })
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <CustomDialog classes={{ paper: classes.root }} dialogProps={dialogProps}>
        <DialogTitle>Pesquisa Avançada</DialogTitle>
        <DialogContent>
          <form id="formServicoPesquisaAvancada" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <div style={{ padding: ' 0px 4px' }}>
              {this.state.segmentosAtuacao.map((segmento, index) => (
                <React.Fragment key={index}>
                  <UncontrolledCheckbox
                    key={index}
                    id={index === 0 ? 'segmentosAtuacao' : null}
                    style={{ padding: 5 }}
                    formControlProps={{
                      style: { display: 'inline-flex', width: 150, marginLeft: -8 },
                      label: <Typography variant="body2">{segmento.descricao}</Typography>
                    }}
                    color="primary"
                    defaultChecked={segmento.exists}
                    onChange={(checked) => {
                      segmento.exists = checked
                    }}
                  />
                  {index === 2 && <Divider style={{ marginTop: 8, marginBottom: 10, background: '#eee' }} />}
                </React.Fragment>
              ))}
              <FormUtil.HiddenSubmitButton />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Voltar
          </Button>
          <Button color="secondary" onClick={this.limparFiltro}>
            LIMPAR
          </Button>
          <Button color="secondary" onClick={this.aplicarFiltro}>
            PESQUISAR
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

BuscaFiltrosDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BuscaFiltrosDialog)
