const MODAL_PADDING = '$6'
const INNER_PADDING = '$2'
const TOOLBAR_HEIGHT = 64
const BANNER_HEIGHT = 50
const MODAL_MOBILE_HEIGHT = 350

const modalContentStyle = (position: DOMRect, tooltipDirection: 'up' | 'down') => {
  const styles = {
    down: {
      top: `calc(${position.top - MODAL_MOBILE_HEIGHT}px + ${MODAL_PADDING})`,
      before: {
        bottom: '-12px',
        'clip-path': 'polygon(50% 100%, 0 0, 100% 0)'
      }
    },
    up: {
      top: `calc(${position.top + position.height}px + ${MODAL_PADDING})`,
      before: {
        top: '-12px',
        'clip-path': 'polygon(50% 0, 0 100%, 100% 100%)'
      }
    },
    tablet: {
      down: {
        top: `calc(${position.top - position.height}px - ${TOOLBAR_HEIGHT}px - ${INNER_PADDING})`,
        before: {
          top: '85%'
        }
      },
      up: {
        top: `calc(${position.top}px + ${MODAL_PADDING} + ${TOOLBAR_HEIGHT}px + ${INNER_PADDING} + ${BANNER_HEIGHT}px)`,
        before: {
          top: '10%'
        }
      }
    }
  }

  return {
    padding: MODAL_PADDING,
    borderRadius: '$medium',
    top: styles[tooltipDirection].top,
    left: '$3',
    minWidth: '328px',
    width: '328px',
    outline: 'none',
    '&::before': {
      content: '',
      display: 'block',
      position: 'absolute',
      width: '30px',
      height: '15px',
      left: `30px`,
      backgroundColor: '#fff',
      ...styles[tooltipDirection].before
    },

    '@tablet': {
      top: styles.tablet[tooltipDirection].top,
      left: '510px',
      minWidth: '419px',
      width: '419px',
      '&::before': {
        width: '15px',
        height: '30px',
        top: styles.tablet[tooltipDirection].before.top,
        left: `-10px`,
        backgroundColor: '#fff',
        'clip-path': 'polygon(0 50%, 100% 100%, 100% 0)'
      }
    }
  }
}

const tagStyle = {
  color: '$actionConversionStatic',
  borderColor: '$actionConversionStatic'
}

const closeButtonStyle = {
  position: 'absolute',
  right: '$4',
  top: '$4'
}

const modalHeaderStyle = { padding: `${INNER_PADDING} 0 0` }

const titleStyle = { fontWeight: 500, '@tablet': { fontSize: '2rem', lineHeight: '2.5rem' } }

const modalBodyStyle = { padding: `${INNER_PADDING} 0`, display: 'flex', overflowX: 'unset', '& > span': { lineHeight: '$base' } }

const modalFooterStyle = { padding: `${INNER_PADDING} 0` }

export { modalContentStyle, tagStyle, closeButtonStyle, modalHeaderStyle, titleStyle, modalBodyStyle, modalFooterStyle }
