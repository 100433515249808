import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import EventIcon from '@material-ui/icons/Event'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PersonIcon from '@material-ui/icons/Person'
import SendIcon from '@material-ui/icons/Send'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import SubjectIcon from '@material-ui/icons/Subject'
import moment from 'moment'
import EnviarLembreteManualDialog from 'pages/erp/painel/atendimento/agendainterface/EnviarLembreteManualDialog'
import AtendimentoDialogPage from 'pages/erp/painel/atendimento/AtendimentoDialogPage'
import VendaCreditoDialogPage from 'pages/erp/painel/cliente/VendaCreditoDialogPage'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import VendaProdutoDialogPage from 'pages/erp/painel/inventario/VendaProdutoDialogPage'
import VendaPacoteDialogPage from 'pages/erp/painel/pacotes/VendaPacoteDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import RemuneracoesDetalhesDialogPage from 'pages/erp/painel/remuneracao/RemuneracoesDetalhesDialogPage'
import FechamentoContaDialogPage from 'pages/erp/painel/venda/FechamentoContaDialogPage'
import GerenciamentoFaturaNotasFiscaisDialogPage from 'pages/erp/painel/venda/GerenciamentoFaturaNotasFiscaisDialogPage'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import VendaSupport from 'pages/erp/painel/venda/VendaSupport'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractPopupProps, openBackableDialog, openBackableUnmanaged } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import AtendimentoStatusIcon from 'support/components/icon/AtendimentoStatus'
import NfStatusIcon from 'support/components/icon/NfStatus'
import ProductsIcon from 'support/components/icon/Products'
import VendaStatusIcon from 'support/components/icon/VendaStatus'
import InputData from 'support/components/input/InputData'
import InputHorario from 'support/components/input/InputHorario'
import BasePopover from 'support/components/popover/BasePopover'
import FacilitadorRemocaoRepeticao from 'support/components/repeticao/FacilitadorRemocaoRepeticao'
import TextMultiline from 'support/components/text/TextMultiline'
import AtendimentoService from 'support/domain/atendimento/AtendimentoService'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import OrigemCreditoEnum from 'support/domain/cliente/OrigemCreditoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import EmissaoNfEnum from 'support/domain/venda/EmissaoNfEnum'
import StatusApresentacaoNfEnum from 'support/domain/venda/StatusApresentacaoNfEnum'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import { converterMomentParaHorarioInt } from 'support/util/TimeConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

import TagLembreteAutomatico from './TagLembreteAutomatico'

const styles = (theme) => ({
  iconSection: {
    display: 'block',
    //fontSize: 20,
    color: '#252525'
  },
  containerDisabled: {
    opacity: 0.4
  },
  gridData: {
    [theme.breakpoints.down(460)]: {
      maxWidth: '55%',
      flexBasis: '55%'
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  gridHorario: {
    [theme.breakpoints.down(460)]: {
      maxWidth: '45%',
      flexBasis: '45%'
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  atendimentoStatusContainer: {
    cursor: 'pointer',
    display: 'inline-block',
    padding: theme.spacing(1),
    marginTop: 12,
    marginBottom: -8,
    marginLeft: -8,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  containerDescricaoCancelado: {
    color: theme.palette.text.main
  },
  popoverPaper: {
    ...theme.util.createBoxShadow('0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)'),
    borderRadius: 32
  }
})

class VendaPopup extends Component {
  constructor(props) {
    super(props)
    this.mounted = true
    this.eventsManager = EventsManager.new()

    const venda = this.getVenda()
    let statusAtendimento = null
    if (venda && VendaService.isAtendimento(venda)) {
      statusAtendimento = venda.atendimento.status
    }

    this.state = {
      errorsMap: createErrorsMap(),
      venda: venda,
      statusAtendimento: statusAtendimento,
      ajaxingIniciarAtendimento: false,
      ajaxingAlteracaoStatus: false
    }

    this.iniciarAtendimentoDataFunctionMap = {}
    this.iniciarAtendimentoHorarioFunctionMap = {}
    this.iniciarAtendimentoColaboradorFunctionMap = {}
    this.facilitadorRemocaoRepeticaoFunctionMap = {}
  }

  closeItemMenu() {
    this.setState((state) => ({
      menuMaisOpcoesAnchorEl: null
    }))
  }

  getVenda = () => {
    if (this.props.venda) {
      return this.props.venda
    } else if (this.props.atendimento) {
      return VendaService.converterAtendimentoParaVenda(this.props.atendimento)
    }
  }

  isAtendimento = () => {
    return VendaService.isAtendimento(this.state.venda)
  }

  isVendaProduto = () => {
    return VendaService.isVendaProduto(this.state.venda)
  }

  isVendaPacote = () => {
    return VendaService.isVendaPacote(this.state.venda)
  }

  isVendaCredito = () => {
    return VendaService.isVendaCredito(this.state.venda)
  }

  componentDidMount() {
    if (this.isAtendimento()) {
      this.eventsManager.sub('ManipulacaoAtendimento', (props) => {
        if (props.action === 'update' || props.action === 'create') {
          this.setState({ venda: VendaService.converterAtendimentoParaVenda(props.atendimentoParaCalendario) })
        }
      })
      this.eventsManager.sub('AtendimentoConfirmacaoEnvioManualLembrete', (props) => {
        const { venda } = this.state
        const { atendimento } = venda
        if (props.idsAtendimentos.indexOf(atendimento.id) >= 0) {
          atendimento.lembreteManualEnviado = true
          this.setState({ venda: venda })
        }
      })
    } else if (this.isVendaProduto()) {
      this.eventsManager.sub('ManipulacaoVendaProduto', (props) => {
        if (props.action === 'update' || props.action === 'create') {
          this.setState({ venda: VendaService.converterVendaProdutoParaVenda(props.vendaProduto) })
        }
      })
    } else if (this.isVendaPacote()) {
      this.eventsManager.sub('ManipulacaoVendaPacote', (props) => {
        if (props.action === 'update' || props.action === 'create') {
          this.setState({ venda: VendaService.converterVendaPacoteParaVenda(props.vendaPacote) })
        }
      })
    } else if (this.isVendaCredito()) {
      this.eventsManager.sub('ManipulacaoVendaCredito', (props) => {
        if (props.action === 'update' || props.action === 'create') {
          this.setState({ venda: VendaService.converterVendaCreditoParaVenda(props.vendaPacote) })
        }
      })
    }

    this.eventsManager.sub('ManipulacaoVendas', (props) => {
      const vendaState = this.state.venda
      if (props.action === 'alteracaoStatus') {
        if (props.vendas && props.vendas.length > 0) {
          for (let venda of props.vendas) {
            if (vendaState.id === venda.id) {
              vendaState.status = venda.status
              if (props.notaVenda) {
                vendaState.notaVenda = props.notaVenda
              }
              break
            }
          }
          this.setState({ venda: vendaState })
        }
      }
    })

    this.eventsManager.sub('CancelamentoVenda', (props) => {
      if (props.venda) {
        const { venda } = this.state
        if (venda && venda.id === props.venda.id) {
          venda.cancelado = true
          this.setState({ venda: venda })
        }
      }
    })

    this.eventsManager.sub('CancelamentoVendas', (props) => {
      if (props.vendas) {
        const { venda } = this.state
        for (let vendaCancelada of props.vendas) {
          if (venda && venda.id === vendaCancelada.id) {
            venda.cancelado = true
            this.setState({ venda: venda })
            break
          }
        }
      }
    })

    this.eventsManager.sub('RemocaoVenda', (props) => {
      /*const {venda} = this.state;
			if(venda && venda.id === props.venda.id){
				this.props.handleCloseDialog();
			}*/
    })
  }

  fecharConta = () => {
    if (verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(FechamentoContaDialogPage, {
        cliente: this.state.venda.cliente,
        retornarVendas: true,
        parent: this,
        shouldCloseParent: true
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  openMenuStatusAtendimento = (event) => {
    if (verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
      const clientX = event.clientX
      const clientY = event.clientY
      openBackableUnmanaged({
        onCreateInstance: (instance) => {
          this.backableComponentInstance = instance
          this.setState({
            statusAtendimentoAnchorPosition: {
              left: clientX,
              top: clientY
            }
          })
        },
        onClosed: () => {
          this.setState({ statusAtendimentoAnchorPosition: null })
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  closeMenuStatusAtendimento = () => {
    this.backableComponentInstance.props.handleClose()
    //this.setState({statusAtendimentoAnchorPosition : null});
  }

  confirmarAlteracaoStatusAtendimento = (status) => {
    if (status.id === AtendimentoStatusEnum.CANCELADO.id) {
      this.backableComponentInstance.props.setParent(this)
      this.backableComponentInstance.props.setShouldCloseParent(true)
      VendaSupport.cancelarVenda({ venda: this.state.venda, parentDialog: this.backableComponentInstance })
    } else if (status.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id) {
      openBackableDialog(EntityConfirmationDialog, {
        parent: this.backableComponentInstance,
        shouldCloseParent: true,
        text: (
          <span>
            Você tem certeza que deseja alterar a situação desse atendimento para <b>Cliente Faltou</b>? Depois dessa confirmação, não será mais possível alterar o status deste
            atendimento e essa operação não poderá ser desfeita.
          </span>
        ),
        cancelButtonLabel: 'VOLTAR',
        confirmButtonLabel: 'CONFIRMAR',
        call: {
          method: 'post',
          url: 'erp/atendimentos.alterarStatus',
          contextoUsuario: 'erp',
          data: {
            id: this.state.venda.atendimento.id,
            status: status
          },
          onSuccess: (response, dialogConfirmationInstance) => {
            EventsManager.pub('ManipulacaoAtendimento', {
              //atendimento:{
              //id:this.state.venda.atendimento.id,
              //status:status
              //atendimentoParaCalendario : response.data.atendimentoParaCalendario
              //},
              //action:"alteracaoStatus"
              atendimentoParaCalendario: response.data.atendimentoParaCalendario,
              action: 'update'
            })
            EventsManager.pub('ManipulacaoVenda')
            this.setState({
              ajaxingAlteracaoStatus: false,
              statusAtendimento: status
            })
            this.backableComponentInstance.props.setParent(this)
            this.backableComponentInstance.props.setShouldCloseParent(true)
            dialogConfirmationInstance.props.handleCloseDialog()
          }
        }
      })
    } else {
      this.alterarStatusAtendimento(status)
    }
  }

  alterarStatusAtendimento = (status) => {
    this.setState({
      ajaxingAlteracaoStatus: true,
      statusAtendimento: status
    })
    this.closeMenuStatusAtendimento()

    postAPI({
      url: 'erp/atendimentos.alterarStatus',
      data: {
        id: this.state.venda.atendimento.id,
        status: status
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoAtendimento', {
          atendimento: {
            id: this.state.venda.atendimento.id,
            status: status
          },
          action: 'alteracaoStatus'
        })
        EventsManager.pub('ManipulacaoVenda')
        this.setState({ ajaxingAlteracaoStatus: false })
        this.props.handleCloseDialog()
      },
      sendErroToGenericSnackbar: false,
      repeat: {
        stopFunction: () => {
          return !this.mounted
        }
      }
    })
  }

  handleSubmitIniciarAtendimento = (event) => {
    event.preventDefault()
    this.iniciarAtendimento()
  }

  iniciarAtendimento = () => {
    if (!verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    this.setState({
      ajaxingIniciarAtendimento: true,
      errorsMap: createErrorsMap()
    })

    postAPI({
      url: 'erp/atendimentos.iniciarAtendimento',
      data: {
        id: this.state.venda.atendimento.id,
        data: this.iniciarAtendimentoDataFunctionMap.getDataAsInt(),
        horarioInicio: this.iniciarAtendimentoHorarioFunctionMap.getHorarioAsInt(),
        colaborador: {
          id: this.iniciarAtendimentoColaboradorFunctionMap.getValue()
        }
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoAtendimento', {
          atendimentoParaCalendario: response.data,
          action: 'inicioAtendimento'
        })
        EventsManager.pub('ManipulacaoVenda')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState({ ajaxingIniciarAtendimento: false, errorsMap: createErrorsMap(response.data.errors) })
        focusFirstElementWithError('formIniciarAtendimento')
      }
    })
  }

  handleVisualizarRemuneracoes = (item) => {
    openBackableDialog(RemuneracoesDetalhesDialogPage, {
      venda: item,
      tipo: TipoRemuneracaoEnum.COMISSAO
    })
  }

  tentarEditarVenda = (venda, referenciaEntidade, edicaoFunction) => {
    if (this.isAtendimento()) {
      if (!verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
        openBackableDialog(AccessDeniedDialog)
        return
      }
    } else {
      if (!verificarAutorizacao([PA.PODE_VENDER])) {
        openBackableDialog(AccessDeniedDialog)
        return
      }
    }

    /*if(venda.notaVenda){
			openBackableDialog(MessageDialog,{
				title : "Aviso",
				text : "Não é mais possível alterar "+referenciaEntidade+" porque a conta já foi fechada e a fatura #"+venda.notaVenda.id+" já foi gerada. É possível apenas cancelar "+referenciaEntidade+"."
			});
		}else if(venda.cancelado){
			openBackableDialog(MessageDialog,{
				title : "Aviso",
				text : "Não é possível realiza a alteração. Este item já está cancelado."
			});
		}else{*/
    edicaoFunction()
    /*}*/
  }

  render() {
    const popupProps = extractPopupProps(this.props)
    const { classes, origem } = this.props
    const { venda } = this.state

    const isOrigemCliente = origem === 'cliente' ? true : false

    popupProps.style = {
      paddingBottom: venda.cancelado ? 16 : 8
    }

    const vendaStatus = StatusVendaEnum.getById(venda.status.id)
    let notaContent = null
    let nfContent = null
    let botaoFecharConta = null

    if (venda.notaVenda) {
      notaContent = (
        <Grid key="notaContent" container alignItems="center" style={{ marginTop: 4, marginBottom: -10 }}>
          <Grid item style={{ marginRight: 17 }}>
            <VendaStatusIcon status={venda.status} vendaCancelada={venda.cancelado} className={classes.iconSection} />
          </Grid>
          <Grid item style={{ marginTop: 1 }}>
            <Typography variant="body2">
              {venda.cancelado ? 'Fatura' : vendaStatus.descricao}: #{venda.notaVenda.numero}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                let mensagemPermissaoNegada = null
                if (!verificarAutorizacao([PA.PODE_VISUALIZAR_VALOR_VENDA])) {
                  mensagemPermissaoNegada = 'Você não pode abrir esta fatura porque não tem permissão para visualizar valores de vendas/atendimentos.'
                }
                if (mensagemPermissaoNegada) {
                  openBackableDialog(AccessDeniedDialog, {
                    message: mensagemPermissaoNegada
                  })
                  EventsManager.pub('AtualizarSessao')
                  return
                }
                openBackableDialog(NotaDialogPage, {
                  parent: this,
                  shouldCloseParent: true,
                  idNota: venda.notaVenda.id,
                  retornarVendas: true
                })
              }}
            >
              <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      )

      if (venda.nf && getSessaoPainel().negocio.carregado && EmissaoNfEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.emissaoNf.id) {
        nfContent = (
          <Grid key="nfContent" container alignItems="center" style={{ marginTop: 10, marginBottom: -4 }}>
            <Grid item style={{ marginRight: 13, marginLeft: -2 }}>
              <NfStatusIcon nf={venda.nf} className={classes.iconSection} />
            </Grid>
            <Grid item style={{ marginTop: 1 }}>
              <Typography variant="body2">{StatusApresentacaoNfEnum.getById(venda.nf.statusApresentacao.id).descricao}</Typography>
            </Grid>
            {getSessaoPainel().negocio.carregado &&
              EmissaoNfEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.emissaoNf.id &&
              verificarAutorizacao([PA.PODE_VISUALIZAR_NOTAS_FISCAIS]) && (
                <Grid item>
                  <IconButton
                    onClick={() => {
                      openBackableDialog(GerenciamentoFaturaNotasFiscaisDialogPage, {
                        nota: venda.notaVenda
                      })
                    }}
                  >
                    <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
                  </IconButton>
                </Grid>
              )}
          </Grid>
        )
      }
    } else {
      if (isOrigemCliente === false) {
        botaoFecharConta = (
          <div key="botaoFecharConta" style={{ textAlign: 'right', marginBottom: -8 }}>
            <Button color="secondary" onClick={this.fecharConta} style={{ marginTop: 11 }}>
              Faturar
            </Button>
          </div>
        )
      }
    }

    let topIconButtons = []
    let content = []
    let itensMenuMaisOpcoes = []

    if (this.isAtendimento()) {
      const { errorsMap, ajaxingAlteracaoStatus, statusAtendimento, venda } = this.state
      const { atendimento } = venda

      let dataAtendimentoAsMoment = converterDataIntParaMoment(atendimento.data)

      let isOrdemChegada = false
      let isHoraMarcada = false

      if (atendimento.tipo.id === AtendimentoTipoEnum.ORDEM_CHEGADA.id) {
        isOrdemChegada = true
      } else if (atendimento.tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id) {
        isHoraMarcada = true
      }

      let isClienteFaltou = false
      if (atendimento.status.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id) {
        botaoFecharConta = null
        isClienteFaltou = true
      }
      if (atendimento.status.id === AtendimentoStatusEnum.CANCELADO.id || venda.cancelado) {
        botaoFecharConta = null
      }

      if (venda.status.id !== StatusVendaEnum.NOTA_PENDENTE.id && !venda.cancelado) {
        itensMenuMaisOpcoes.push(
          <MenuItem
            key="consultar_comissoes"
            onClick={() => {
              this.handleVisualizarRemuneracoes(this.state.itemMenu)
              this.closeItemMenu()
            }}
          >
            Consultar Comissões
          </MenuItem>
        )
      }

      topIconButtons = [
        {
          tooltip: 'Enviar lembrete',
          icon: SendIcon,
          disabled: venda.cancelado || isOrdemChegada ? true : false || isClienteFaltou,
          iconProps: {
            color: atendimento.lembreteManualEnviado ? 'secondary' : undefined
          },
          onClick: () => {
            if (!verificarAutorizacao([PA.PODE_VISUALIZAR_DADOS_CONTATO_CLIENTE])) {
              openBackableDialog(AccessDeniedDialog, {
                message: (
                  <span>
                    Você precisa ter a permissão de <u>visualizar dados de contato dos clientes</u> para pode enviar lembrete.
                  </span>
                )
              })
              EventsManager.pub('AtualizarSessao')
            } else if (verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
              openBackableDialog(EnviarLembreteManualDialog, {
                parent: this,
                atendimento: VendaService.converterVendaParaAtendimento(venda)
              })
            } else {
              openBackableDialog(AccessDeniedDialog)
            }
          }
        },
        {
          tooltip: 'Editar',
          icon: EditIcon,
          disabled: isClienteFaltou,
          onClick: () => {
            this.tentarEditarVenda(venda, 'este atendimento', () => {
              openBackableDialog(AtendimentoDialogPage, {
                parent: this,
                idAtendimento: atendimento.id
              })
            })
          }
        },
        {
          tooltip: venda.notaVenda && !venda.cancelado ? 'Cancelar atendimento' : 'Remover atendimento',
          icon: DeleteIcon,
          disabled: venda.excluido,
          onClick: (event) => {
            if (venda.notaVenda && !venda.cancelado) {
              VendaSupport.cancelarVenda({ venda: venda, parentDialog: this })
            } else {
              VendaSupport.removerVenda({ venda: venda, parentDialog: this, event: event, facilitadorRemocaoRepeticaoFunctionMap: this.facilitadorRemocaoRepeticaoFunctionMap })
            }
          }
        },
        {
          show: !isOrigemCliente && verificarAutorizacao([PA.PODE_ACESSAR_CAD_CLIENTES]),
          tooltip: 'Abrir Cliente',
          icon: PersonIcon,
          onClick: () => {
            VendaSupport.adicionarVenda({
              //data: dataAtendimentoAsMoment,
              horarioPrePreenchido: converterMomentParaHorarioInt(moment()),
              cliente: venda.cliente,
              tipoAtendimentoPreSelecionado: atendimento.tipo,

              parent: this,
              shouldCloseParent: true
            })
          }
        },
        {
          icon: MoreVertIcon,
          disabled: itensMenuMaisOpcoes.length === 0,
          onClick: (event) => {
            this.setState({ menuMaisOpcoesAnchorEl: event.currentTarget, itemMenu: venda })
          }
        }
      ]

      let descricaoDataHorario

      let formatoExibicaoData = 'ddd, DD/MMM'
      if (isWidthUp('sm', this.props.width)) {
        formatoExibicaoData = 'dddd, DD [de ] MMMM'
      }

      if (isOrdemChegada) {
        descricaoDataHorario = (
          <span>
            {dataAtendimentoAsMoment.format(formatoExibicaoData)} &#8226; Chegada: {formatarHorarioInt(atendimento.horarioEntradaFila)}
          </span>
        )
      } else if (isHoraMarcada) {
        descricaoDataHorario = (
          <span>
            {dataAtendimentoAsMoment.format(formatoExibicaoData)} &#8226; {formatarHorarioInt(atendimento.horarioInicio)} até {formatarHorarioInt(atendimento.horarioTermino)}
          </span>
        )
      }

      const headerProps = {
        key: 'header',
        classesObject: classes,
        icon: isHoraMarcada ? EventIcon : null,
        atendimento: atendimento,
        venda: venda,
        clientePacoteItem: venda.clientePacoteItem
      }
      if (isOrigemCliente) {
        headerProps.titulo = AtendimentoService.gerarDescricaoServico(atendimento)
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = venda.colaborador.apelido
      } else {
        headerProps.titulo = venda.cliente.apelido
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = AtendimentoService.gerarDescricaoServico(atendimento)
      }

      content.push(<Header {...headerProps} />)

      let contentObservacoes = null
      if (atendimento.observacoes && verificarAutorizacao([PA.PODE_VISUALIZAR_OBSERVACAO])) {
        contentObservacoes = (
          <Grid key="observacoes" container style={{ marginTop: 20 }}>
            <Grid item style={{ marginRight: 16 }}>
              <SubjectIcon className={classes.iconSection} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                <TextMultiline>{atendimento.observacoes}</TextMultiline>
              </Typography>
            </Grid>
          </Grid>
        )
      }

      let contentObservacoesCliente = null
      if (atendimento.observacoesCliente) {
        contentObservacoesCliente = (
          <Grid key="observacoesCliente" container style={{ marginTop: 20 }}>
            <Grid item style={{ marginRight: 16 }}>
              <SubjectIcon className={classes.iconSection} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2">Observações do cliente: {atendimento.observacoesCliente.replace(/\n/g, ' ')}</Typography>
            </Grid>
          </Grid>
        )
      }

      if (isOrdemChegada) {
        const { ajaxingIniciarAtendimento } = this.state

        let colaboradorDefaultValue = venda.colaborador
        /*if(!verificarAutorizacao([PA.PODE_ACESSAR_TODOS_CALENDARIOS]) && colaboradorDefaultValue.id !== getSessaoPainel().colaborador.id){
					colaboradorDefaultValue = getSessaoPainel().colaborador;
				}*/

        content.push(contentObservacoes)
        content.push(contentObservacoesCliente)
        content.push(
          <React.Fragment key="formIniciarAtendimento">
            <form id="formIniciarAtendimento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmitIniciarAtendimento(event)}>
              <Grid container style={{ marginTop: 8 }} spacing={1}>
                <Grid item xs className={classes.gridData}>
                  <InputData
                    marginTop={true}
                    disabled={ajaxingIniciarAtendimento}
                    label={'Data Atendimento'}
                    functionsMap={this.iniciarAtendimentoDataFunctionMap}
                    keyboard={true}
                    idname="xdata"
                    errorMessage={errorsMap('data')}
                    defaultValue={new Date()}
                    onChange={(data) => {
                      this.setState({ data: data })
                    }}
                  />
                </Grid>
                <Grid item xs className={classes.gridHorario}>
                  <InputHorario
                    marginTop={true}
                    disabled={ajaxingIniciarAtendimento}
                    label={'Início Atendimento'}
                    functionsMap={this.iniciarAtendimentoHorarioFunctionMap}
                    keyboard={true}
                    idname="xhorario"
                    errorMessage={errorsMap('horario')}
                    defaultValue={new Date()}
                    onChange={(data) => {
                      this.setState({ data: data })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ColaboradorInputDialogSelect
                    context="atendimento"
                    marginTop={true}
                    disabled={ajaxingIniciarAtendimento}
                    defaultValue={colaboradorDefaultValue}
                    servico={atendimento.servico}
                    functionsMap={this.iniciarAtendimentoColaboradorFunctionMap}
                    DialogSelectProps={{}}
                    placeholder="Profissional"
                    errorMessage={errorsMap('colaborador')}
                  />
                </Grid>
              </Grid>
              <div style={{ textAlign: 'right', marginBottom: -8 }}>
                <Button disabled={ajaxingIniciarAtendimento} color="secondary" style={{ marginTop: 11 }} type="submit">
                  Iniciar Atendimento
                </Button>
              </div>
            </form>
          </React.Fragment>
        )
      } else if (isHoraMarcada) {
        if (!this.state.venda.cancelado) {
          let onClickAlterarStatusAtendimento = null
          let classNameDisabledAlterarStatusAtendimento = classes.containerDisabled
          if (!ajaxingAlteracaoStatus) {
            onClickAlterarStatusAtendimento = this.openMenuStatusAtendimento
            classNameDisabledAlterarStatusAtendimento = null
          }

          let isReadonly = statusAtendimento.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id ? true : false

          if (isReadonly) {
            onClickAlterarStatusAtendimento = null
          }

          content.push(
            <React.Fragment key="botaoAlterarStatus">
              <div className={classes.atendimentoStatusContainer} onClick={onClickAlterarStatusAtendimento}>
                <Grid container alignItems="center">
                  <Grid item style={{ marginRight: 16 }} className={classNameDisabledAlterarStatusAtendimento}>
                    <AtendimentoStatusIcon className={classes.iconSection} status={statusAtendimento} />
                  </Grid>
                  <Grid item className={classNameDisabledAlterarStatusAtendimento}>
                    <Typography variant="body2">{AtendimentoStatusEnum.getById(statusAtendimento.id).descricao}</Typography>
                  </Grid>
                  {!isReadonly && (
                    <Grid item style={{ marginLeft: 8 }} className={classNameDisabledAlterarStatusAtendimento}>
                      <ArrowDropDownIcon className={classes.iconSection} />
                    </Grid>
                  )}
                  {ajaxingAlteracaoStatus && (
                    <Grid item style={{ marginLeft: 8 }}>
                      <CircularProgress size={18} thickness={5.5} />
                    </Grid>
                  )}
                </Grid>
              </div>

              <Popover
                anchorReference="anchorPosition"
                anchorPosition={this.state.statusAtendimentoAnchorPosition}
                open={Boolean(this.state.statusAtendimentoAnchorPosition)}
                onClose={this.closeMenuStatusAtendimento}
                disablePortal={true}
              >
                <div style={{ padding: '16px' }}>
                  <span variant="body1" style={{ fontSize: 12, color: '#afafaf' }}>
                    Alterar situação:
                  </span>
                </div>
                {AtendimentoStatusEnum.values().map((status) => {
                  if (status.selectable) {
                    return (
                      <ItemMenuStatusAtendimento
                        key={status.id}
                        status={status}
                        selected={status.id === statusAtendimento.id}
                        onClick={() => this.confirmarAlteracaoStatusAtendimento(status)}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </Popover>
            </React.Fragment>
          )
        }

        content.push(notaContent)
        content.push(contentObservacoes)
        content.push(contentObservacoesCliente)
        content.push(<TagLembreteAutomatico atendimento={atendimento} />)
        content.push(botaoFecharConta)
        content.push(nfContent)
        content.push(<FacilitadorRemocaoRepeticao key="FacilitadorRemocaoRepeticao" functionsMap={this.facilitadorRemocaoRepeticaoFunctionMap} />)
      }
    } else if (this.isVendaProduto()) {
      const { vendaProduto } = this.state.venda

      let dataVendaProdutoAsMoment = converterDataIntParaMoment(vendaProduto.data)

      if (venda.status.id !== StatusVendaEnum.NOTA_PENDENTE.id && !venda.cancelado) {
        itensMenuMaisOpcoes.push(
          <MenuItem
            key="consultar_comissoes"
            onClick={() => {
              this.handleVisualizarRemuneracoes(this.state.itemMenu)
              this.closeItemMenu()
            }}
          >
            Consultar Comissões
          </MenuItem>
        )
      }

      topIconButtons = [
        {
          tooltip: 'Editar',
          icon: EditIcon,
          onClick: () => {
            this.tentarEditarVenda(venda, 'esta venda', () => {
              openBackableDialog(VendaProdutoDialogPage, {
                parent: this,
                idVendaProduto: vendaProduto.id
              })
            })
          }
        },
        {
          tooltip: venda.notaVenda && !venda.cancelado ? 'Cancelar venda' : 'Remover venda',
          icon: DeleteIcon,
          onClick: () => {
            /*if(!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])){
							openBackableDialog(AccessDeniedDialog);
							return;
						}*/

            if (venda.notaVenda && !venda.cancelado) {
              VendaSupport.cancelarVenda({ venda: venda, parentDialog: this })
              /*openBackableDialog(EntityConfirmationDialog, {
								parent : this,
								title : "Cancelar venda",
								text : <span>Você tem certeza que deseja cancelar esta venda? Essa operação não poderá ser desfeita</span>,
								textToConfirm:"CANCELAR",
								cancelButtonLabel : "VOLTAR",
								confirmButtonLabel : "CONFIRMAR",
								call : {
									method: "post",
									url: "erp/inventario.cancelarVendaProduto",
									contextoUsuario : "erp",
									data : {
										idVendaProduto : vendaProduto.id
									},
									onSuccess : (response, dialogConfirmationInstance) => {

										dialogConfirmationInstance.props.setShouldCloseParent(true);

										EventsManager.pub("CancelamentoVenda",{venda : {id : venda.id}});
										EventsManager.pub("ManipulacaoVenda");
									}
								}
							});*/
            } else {
              VendaSupport.removerVenda({ venda: venda, parentDialog: this })

              /*

							openBackableDialog(EntityRemovalDialog, {
								title : "Remover venda",
								text : <span>Você tem certeza que deseja remover esta venda? Essa operação não poderá ser desfeita</span>,
								call : {
									method: "post",
									url: "erp/inventario.removerVendaProduto",
									contextoUsuario : "erp",
									data : {
										idVendaProduto : vendaProduto.id
									},
									onSuccess : (response) => {
										EventsManager.pub("RemocaoVenda",{venda : {id : venda.id}});
										EventsManager.pub("ManipulacaoVenda");
									}
								}
							});*/
            }
          }
        },
        {
          show: !isOrigemCliente && verificarAutorizacao([PA.PODE_ACESSAR_CAD_CLIENTES]),
          tooltip: 'Abrir Cliente',
          icon: PersonIcon,
          onClick: () => {
            VendaSupport.adicionarVenda({
              //data: dataVendaProdutoAsMoment,
              cliente: venda.cliente,
              parent: this,
              shouldCloseParent: true
            })
          }
        },
        {
          icon: MoreVertIcon,
          disabled: itensMenuMaisOpcoes.length === 0,
          onClick: (event) => {
            this.setState({ menuMaisOpcoesAnchorEl: event.currentTarget, itemMenu: venda })
          }
        }
      ]

      let formatoExibicaoData = 'ddd, DD/MMM'
      if (isWidthUp('sm', this.props.width)) {
        formatoExibicaoData = 'dddd, DD [de ] MMMM'
      }

      let descricaoDataHorario = dataVendaProdutoAsMoment.format(formatoExibicaoData)

      const headerProps = {
        key: 'header',
        classesObject: classes,
        icon: ProductsIcon,
        venda: venda
      }
      if (isOrigemCliente) {
        headerProps.titulo = vendaProduto.produto.nome
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = venda.colaborador.apelido
      } else {
        headerProps.titulo = venda.cliente.apelido
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = vendaProduto.produto.nome
      }

      content.push(<Header {...headerProps} />)

      content.push(notaContent)
      content.push(botaoFecharConta)
      content.push(nfContent)
    } else if (this.isVendaPacote()) {
      const { vendaPacote } = this.state.venda

      let dataVendaPacoteAsMoment = converterDataIntParaMoment(vendaPacote.data)

      topIconButtons = [
        {
          tooltip: 'Editar',
          icon: EditIcon,
          onClick: () => {
            this.tentarEditarVenda(venda, 'esta venda', () => {
              openBackableDialog(VendaPacoteDialogPage, {
                parent: this,
                idVendaPacote: vendaPacote.id
              })
            })
          }
        },
        {
          tooltip: venda.notaVenda && !venda.cancelado ? 'Cancelar venda' : 'Remover venda',
          icon: DeleteIcon,
          onClick: () => {
            /*if(!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])){
							openBackableDialog(AccessDeniedDialog);
							return;
						}*/

            if (venda.notaVenda && !venda.cancelado) {
              VendaSupport.cancelarVenda({ venda: venda, parentDialog: this })

              /*openBackableDialog(EntityConfirmationDialog, {
								parent : this,
								title : "Cancelar venda",
								text : <span>Você tem certeza que deseja cancelar esta venda? Essa operação não poderá ser desfeita</span>,
								textToConfirm:"CANCELAR",
								cancelButtonLabel : "VOLTAR",
								confirmButtonLabel : "CONFIRMAR",
								call : {
									method: "post",
									url: "erp/pacotes.cancelarVenda",
									contextoUsuario : "erp",
									data : {
										idVendaPacote : vendaPacote.id
									},
									onSuccess : (response, dialogConfirmationInstance) => {
										dialogConfirmationInstance.props.setShouldCloseParent(true);
										EventsManager.pub("CancelamentoVenda",{venda : {id : venda.id}});
										EventsManager.pub("ManipulacaoVenda");
									}
								}
							});*/
            } else {
              VendaSupport.removerVenda({ venda: venda, parentDialog: this })

              /*openBackableDialog(EntityRemovalDialog, {
								title : "Remover venda",
								text : <span>Você tem certeza que deseja remover esta venda? Essa operação não poderá ser desfeita</span>,
								call : {
									method: "post",
									url: "erp/pacotes.removerVenda",
									contextoUsuario : "erp",
									data : {
										idVendaPacote : vendaPacote.id
									},
									onSuccess : (response) => {
										EventsManager.pub("RemocaoVenda",{venda : {id : venda.id}});
										EventsManager.pub("ManipulacaoVenda");
									}
								}
							});*/
            }
          }
        },
        {
          show: !isOrigemCliente && verificarAutorizacao([PA.PODE_ACESSAR_CAD_CLIENTES]),
          tooltip: 'Abrir Cliente',
          icon: PersonIcon,
          onClick: () => {
            VendaSupport.adicionarVenda({
              //data: dataVendaPacoteAsMoment,
              cliente: venda.cliente,
              parent: this,
              shouldCloseParent: true
            })
          }
        },
        {
          icon: MoreVertIcon,
          disabled: itensMenuMaisOpcoes.length === 0
        }
      ]

      let formatoExibicaoData = 'ddd, DD/MMM'
      if (isWidthUp('sm', this.props.width)) {
        formatoExibicaoData = 'dddd, DD [de ] MMMM'
      }

      let descricaoDataHorario = dataVendaPacoteAsMoment.format(formatoExibicaoData)

      const headerProps = {
        key: 'header',
        classesObject: classes,
        icon: ShoppingBasketIcon,
        venda: venda
      }
      if (isOrigemCliente) {
        headerProps.titulo = vendaPacote.pacote.nome
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = venda.colaborador.apelido
      } else {
        headerProps.titulo = venda.cliente.apelido
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = vendaPacote.pacote.nome
      }

      content.push(<Header {...headerProps} />)
      content.push(notaContent)
      content.push(botaoFecharConta)
      content.push(nfContent)
    } else if (this.isVendaCredito()) {
      const { vendaCredito } = this.state.venda

      let dataVendaCreditoAsMoment = converterDataIntParaMoment(vendaCredito.data)

      topIconButtons = [
        {
          tooltip: 'Editar',
          icon: EditIcon,
          onClick: () => {
            this.tentarEditarVenda(venda, 'esta venda', () => {
              openBackableDialog(VendaCreditoDialogPage, {
                parent: this,
                idVendaCredito: vendaCredito.id,
                origem: OrigemCreditoEnum.VENDA
              })
            })
          }
        },
        {
          tooltip: venda.notaVenda && !venda.cancelado ? 'Cancelar venda' : 'Remover venda',
          icon: DeleteIcon,
          onClick: () => {
            if (venda.notaVenda && !venda.cancelado) {
              VendaSupport.cancelarVenda({ venda: venda, parentDialog: this })
            } else {
              VendaSupport.removerVenda({ venda: venda, parentDialog: this })
            }
          }
        },
        {
          show: !isOrigemCliente && verificarAutorizacao([PA.PODE_ACESSAR_CAD_CLIENTES]),
          tooltip: 'Abrir Cliente',
          icon: PersonIcon,
          onClick: () => {
            VendaSupport.adicionarVenda({
              cliente: venda.cliente,
              parent: this,
              shouldCloseParent: true
            })
          }
        },
        {
          icon: MoreVertIcon,
          disabled: itensMenuMaisOpcoes.length === 0
        }
      ]

      let formatoExibicaoData = 'ddd, DD/MMM'
      if (isWidthUp('sm', this.props.width)) {
        formatoExibicaoData = 'dddd, DD [de ] MMMM'
      }

      let descricaoDataHorario = dataVendaCreditoAsMoment.format(formatoExibicaoData)

      const headerProps = {
        key: 'header',
        classesObject: classes,
        icon: AttachMoneyIcon,
        venda: venda
      }
      if (isOrigemCliente) {
        headerProps.titulo = VendaService.getDescricao(venda)
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = venda.colaborador.apelido
      } else {
        headerProps.titulo = venda.cliente.apelido
        headerProps.subtitulo1 = descricaoDataHorario
        headerProps.subtitulo2 = VendaService.getDescricao(venda)
      }

      content.push(<Header {...headerProps} />)
      content.push(notaContent)
      content.push(botaoFecharConta)
      content.push(nfContent)
    }

    content.push(
      <Menu key="menuMaisOpcoes" anchorEl={this.state.menuMaisOpcoesAnchorEl} open={Boolean(this.state.menuMaisOpcoesAnchorEl)} onClose={() => this.closeItemMenu()}>
        {itensMenuMaisOpcoes.map((itemMenuMaisOpcoes) => {
          return itemMenuMaisOpcoes
        })}
      </Menu>
    )

    return (
      <BasePopover
        {...popupProps}
        classes={{
          popoverPaper: classes.popoverPaper
        }}
        topIconButtons={topIconButtons}
        body={content}
      />
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    this.mounted = false
  }
}

function Header(props) {
  let valorContent = null

  if (verificarAutorizacao([PA.PODE_VISUALIZAR_VALOR_VENDA])) {
    valorContent = VendaService.getDescricaoPreco(props.venda, { format: 'minificado' })
  }

  if (props.atendimento && props.atendimento.status.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id) {
    valorContent = null
  }

  return (
    <Grid container alignItems="center">
      {props.icon && (
        <Grid item style={{ marginRight: 16 }}>
          <props.icon className={props.classesObject.iconSection} />
        </Grid>
      )}
      <Grid item xs>
        <Typography variant="body1" style={{ fontSize: 20 }}>
          {props.titulo}
        </Typography>
        <Typography variant="body2" style={{ marginTop: 1 }}>
          {props.subtitulo1}
        </Typography>
        <Typography variant="body2" style={{ marginTop: 2 }}>
          {props.subtitulo2}
        </Typography>
        {valorContent && (
          <Typography variant="body2" style={{ marginTop: 2, fontWeight: 500 }}>
            {valorContent}
          </Typography>
        )}
        {props.venda.cancelado && (
          <Typography variant="body2">
            <b>Cancelado</b>
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

function ItemMenuStatusAtendimento(props) {
  const { status } = props

  return (
    <MenuItem style={props.style} onClick={props.onClick}>
      <Grid container style={{ width: '100%' }} justify="center" alignItems="center">
        <Grid item style={{ marginRight: 16 }}>
          <AtendimentoStatusIcon status={status} />
        </Grid>
        <Grid item xs align="left">
          {status.descricao}
        </Grid>
        {props.selected && (
          <Grid item align="right" style={{ paddingLeft: 20 }}>
            <CheckIcon />
          </Grid>
        )}
      </Grid>
    </MenuItem>
  )
}

VendaPopup.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(VendaPopup))
