import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import BonificacaoDialogPage from 'pages/erp/painel/colaborador/BonificacaoDialogPage'
import SalarioDialogPage from 'pages/erp/painel/colaborador/SalarioDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import FacilitadorRemocaoRepeticao from 'support/components/repeticao/FacilitadorRemocaoRepeticao'
import TabContainer from 'support/components/tab/TabContainer'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import RemuneracaoService from 'support/domain/remuneracao/RemuneracaoService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import FormUtil from 'support/util/FormUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaDescricao: {
    flexGrow: 1.0
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 100
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 }),
    marginTop: 2
  },
  colunaPagamento: {
    flexGrow: 0.8,
    maxWidth: 100,
    minWidth: 100
  },
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

const OpcoesPeriodoFiltroPagamentosEnum = createEnum({
  ATE_ESSE_MES: { id: 1, descricao: 'Até esse mês' },
  FUTUROS: { id: 2, descricao: 'Futuros' }
})

class TabSalarios extends Component {
  constructor(props) {
    super(props)

    const periodoFiltro = OpcoesPeriodoFiltroPagamentosEnum.ATE_ESSE_MES
    const datas = this.getIntervaloDatasPorPeriodoFiltro(periodoFiltro)

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      periodoFiltro: periodoFiltro,
      dataInicial: datas.dataInicial,
      dataFinal: datas.dataFinal,
      lista: []
    }
    this.loaderFunctionsMap = {}
    this.facilitadorRemocaoRepeticaoFunctionMap = {}
  }

  openCriarItemMenu = (event) => {
    this.setState({ criarItemMenuAdicionarAnchorEl: event.currentTarget })
  }

  abrirSalarioDialogPage = () => {
    openBackableDialog(SalarioDialogPage, { idColaborador: this.props.colaborador.id })
  }

  abrirBonificacaoDialogPage = () => {
    openBackableDialog(BonificacaoDialogPage, { idColaborador: this.props.colaborador.id })
  }

  handlerEditar = (event, remuneracao) => {
    event.stopPropagation()
    if (remuneracao.tipo.id === TipoRemuneracaoEnum.SALARIO.id) {
      openBackableDialog(SalarioDialogPage, {
        idSalario: remuneracao.salario.id,
        idColaborador: this.props.colaborador.id
      })
    } else if (remuneracao.tipo.id === TipoRemuneracaoEnum.BONIFICACAO.id) {
      openBackableDialog(BonificacaoDialogPage, {
        idBonificacao: remuneracao.bonificacao.id,
        idColaborador: this.props.colaborador.id
      })
    }
  }

  handlerRemover = (event, item) => {
    if (RemuneracaoService.isSalario(item)) {
      this.removerSalario(event, item.salario)
    } else if (RemuneracaoService.isBonificacao(item)) {
      this.removerBonificacao(event, item.bonificacao)
    }
  }

  removerSalario(event, salario) {
    event.stopPropagation()

    const data = converterDataIntParaMoment(salario.data).format('DD/MMM/YYYY')

    this.facilitadorRemocaoRepeticaoFunctionMap.remover({
      abrirMenu: salario.repeticao && salario.repeticao.id ? true : false,
      event: event,
      call: {
        endpoint: 'erp/colaboradores.removerSalario',
        data: {
          id: salario.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoColaborador')
        }
      },
      dialog: {
        title: 'Remover Salário',
        itemDescription: (
          <span>
            o salário de <b>{data}</b>
          </span>
        )
      }
    })
  }

  removerBonificacao(event, bonificacao) {
    event.stopPropagation()

    const data = converterDataIntParaMoment(bonificacao.data).format('DD/MMM/YYYY')

    this.facilitadorRemocaoRepeticaoFunctionMap.remover({
      abrirMenu: bonificacao.repeticao && bonificacao.repeticao.id ? true : false,
      event: event,
      call: {
        endpoint: 'erp/colaboradores.removerBonificacao',
        data: {
          id: bonificacao.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoColaborador')
        }
      },
      dialog: {
        title: 'Remover Bonificação',
        itemDescription: (
          <span>
            a bonificação de <b>{data}</b>
          </span>
        )
      }
    })
  }

  getLoaderKey = () => {
    const { dataInicial, dataFinal } = this.state

    let key = 'loader'

    if (dataInicial) {
      key += '-di' + dataInicial
    }

    if (dataFinal) {
      key += '-df' + dataFinal
    }

    return key
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.data = converterDataIntParaMoment(item.dataRemuneracao).format('DD/MMM/YYYY')
    content.valor = formatarValorMonetario(RemuneracaoService.getValor(item), { prefix: false })
    content.descricao = RemuneracaoService.getDescricao(item)
    const observacao = RemuneracaoService.getObservacao(item)

    if (observacao) {
      content.descricao += ' - ' + observacao
    }

    let descricaoPagamento = null
    if (item.valorPendentePagamento === 0) {
      descricaoPagamento = 'Pago'
    } else if (item.valorPago > 0) {
      descricaoPagamento = 'Parcial'
    } else {
      descricaoPagamento = 'Em Aberto'
    }

    if (size === 'small') {
      content.pagamento = (
        <span>
          <b>{descricaoPagamento}</b>
        </span>
      )
    } else {
      content.pagamento = descricaoPagamento
    }

    if (size === 'large') {
      content.botaoMenu = (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            this.setState({ itemMenuAnchorEl: event.currentTarget, itemMenu: item })
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )
    } else if (size === 'small') {
      content.botaoMenu = (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            this.setState({ itemMenuAnchorEl: event.currentTarget, itemMenu: item })
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )
    }

    return content
  }

  handleChangePeriodoFiltro(periodoFiltro) {
    const datas = this.getIntervaloDatasPorPeriodoFiltro(periodoFiltro)
    this.setState({
      periodoFiltro: periodoFiltro,
      dataInicial: datas.dataInicial,
      dataFinal: datas.dataFinal
    })
  }

  getRequestParameters = () => {
    return {
      idColaborador: this.props.colaborador.id,
      dataInicial: converterMomentParaDataInt(this.state.dataInicial),
      dataFinal: converterMomentParaDataInt(this.state.dataFinal)
    }
  }

  getIntervaloDatasPorPeriodoFiltro = (periodoFiltro) => {
    let dataInicial = null
    let dataFinal = null

    if (periodoFiltro.id === OpcoesPeriodoFiltroPagamentosEnum.FUTUROS.id) {
      dataInicial = moment().add(1, 'months').startOf('month')
    } else {
      dataFinal = moment().endOf('month')
    }
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal
    }
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  render() {
    const { ajaxing, lista, criarItemMenuAdicionarAnchorEl, itemMenuAnchorEl, periodoFiltro } = this.state
    const { classes } = this.props

    return (
      <TabContainer>
        <VirtualizedResponsiveTable
          header={
            <Grid container alignItems="center" style={{ marginBottom: 16 }}>
              <Grid item xs style={{ marginRight: 4 }}>
                <InputSelect
                  fullWidth={false}
                  shrink={false}
                  displayEmpty={true}
                  defaultValue={periodoFiltro.id}
                  nakedLeftPadding={false}
                  customVariant="naked"
                  onChange={(event) => {
                    this.handleChangePeriodoFiltro(OpcoesPeriodoFiltroPagamentosEnum.getById(parseInt(event.target.value, 10)))
                  }}
                >
                  {OpcoesPeriodoFiltroPagamentosEnum.values().map((periodo) => (
                    <MenuItem key={periodo.id} value={periodo.id}>
                      {periodo.descricao}
                    </MenuItem>
                  ))}
                </InputSelect>
              </Grid>
              <Grid item>
                <Button color="secondary" variant="text" style={{ marginRight: -8, marginLeft: -8 }} onClick={this.openCriarItemMenu}>
                  <AddIcon />
                  <span style={{ marginLeft: 8, marginRight: 0 }}>ADICIONAR</span>
                </Button>
              </Grid>
            </Grid>
          }
          disabled={ajaxing}
          scrollElement={this.props.scrollElement}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/colaborador/remuneracoes.buscarRemuneracoesParaGestaoSalario"
          loaderKey={this.getLoaderKey()}
          getRequestParametersFunction={this.getRequestParameters}
          items={lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          onClickRow={(item, event) => {
            this.handlerEditar(event, item)
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Descrição', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } },
              { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
              { label: 'Pagamento', className: classes.colunaPagamento, align: 'right' },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [content.data, content.descricao, content.valor, content.pagamento, content.botaoMenu]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 116,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center" style={{ minWidth: 0 }}>
                      <Grid item xs style={{ marginRight: 4, minWidth: 0 }}>
                        <Typography variant="body2">
                          <b>{content.data}</b>
                        </Typography>
                        <Typography variant="body2" className={classes.descricaoContainerSmall}>
                          {content.descricao}
                        </Typography>
                        <Typography variant="body2">R$ {content.valor}</Typography>
                        <Typography variant="body2">{content.pagamento}</Typography>
                      </Grid>
                      <Grid item>{content.botaoMenu}</Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            padding: false,
            text: 'Nenhum salário foi encontrado'
          }}
        />

        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          anchorEl={criarItemMenuAdicionarAnchorEl}
          open={Boolean(criarItemMenuAdicionarAnchorEl)}
          onClose={() => this.closeMenu('criarItemMenuAdicionarAnchorEl')}
        >
          <MenuItem
            onClick={() => {
              this.abrirSalarioDialogPage()
              this.closeMenu('criarItemMenuAdicionarAnchorEl')
            }}
          >
            Salário
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.abrirBonificacaoDialogPage()
              this.closeMenu('criarItemMenuAdicionarAnchorEl')
            }}
          >
            Bonificação
          </MenuItem>
        </Menu>

        <Menu anchorEl={itemMenuAnchorEl} open={Boolean(itemMenuAnchorEl)} onClose={() => this.closeMenu('itemMenuAnchorEl')}>
          <MenuItem
            onClick={(event) => {
              this.handlerEditar(event, this.state.itemMenu)
              this.closeMenu('itemMenuAnchorEl')
            }}
          >
            Abrir {this.state.itemMenu ? RemuneracaoService.getDescricao(this.state.itemMenu) : ''}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              this.handlerRemover(event, this.state.itemMenu)
              this.closeMenu('itemMenuAnchorEl')
            }}
          >
            Remover {this.state.itemMenu ? RemuneracaoService.getDescricao(this.state.itemMenu) : ''}
          </MenuItem>
        </Menu>

        <FacilitadorRemocaoRepeticao functionsMap={this.facilitadorRemocaoRepeticaoFunctionMap} />
      </TabContainer>
    )
  }
}

TabSalarios.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabSalarios)
