import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_CONTA_MAIN, ROUTE_ENTERPRISE_PANEL } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import InputText from 'support/components/input/InputText'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import FormUtil from 'support/util/FormUtil'
import UrlUtil from 'support/util/UrlUtil'

const styles = (theme) => ({
  root: theme.page.parentCenteredPaper,
  paper: {
    ...theme.page.paper({ maxWidth: 320 }),
    width: '100% !important',
    maxWidth: '320px !important'
  },
  progress: {
    display: 'block'
  }
})

class IndexAppPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap({}),
      showPassword: false,
      ajaxing: false
    }

    this.inputEmail = React.createRef()
    DeviceIdentifier.registerDevice(UrlUtil.getUrlQueryStringParsed())

    const campaignValue = UrlUtil.getUrlQueryStringParsed()['campaign']
    if (campaignValue) {
      localStorage.setItem('S99_Campaign', campaignValue)
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: FormUtil.createErrorsMap()
    }))

    getAPI({
      url: 'cda/usuarios.verificarExistenciaEmail',
      params: {
        email: this.inputEmail.current.value
      },
      onSuccess: (response) => {
        if (response.data) {
          this.props.history.push(ROUTE_CONTA_MAIN + '/login?' + '&continuacao=' + encodeURIComponent(UrlUtil.getFullUrlFromRoute(ROUTE_ENTERPRISE_PANEL)))
        } else {
          this.props.history.push(ROUTE_CONTA_MAIN + '/criar-conta?' + '&continuacao=' + encodeURIComponent(UrlUtil.getFullUrlFromRoute(ROUTE_ENTERPRISE_PANEL)))
        }
      },
      onError: (response) => {
        this.setState({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) })
        FormUtil.focusFirstElementWithError('formLogin')
      }
    })
  }

  render() {
    if (SessaoUsuario.isUsuarioLogado()) {
      return <Redirect to={ROUTE_ENTERPRISE_PANEL} />
    }

    if (DeviceIdentifier.isNativeIOS()) {
      return <Redirect to={ROUTE_CONTA_MAIN + '/login?&continuacao=' + encodeURIComponent(UrlUtil.getFullUrlFromRoute(ROUTE_ENTERPRISE_PANEL))} />
    }

    const { classes } = this.props
    const { errorsMap } = this.state

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ textAlign: 'center', marginTop: 4 }}>
            <img src={logo} width="120" alt="Salão99" />
            <Typography variant="subtitle1" style={{ marginTop: 16, letterSpacing: '.1px' }}>
              Informe o seu e-mail para acessar
            </Typography>
          </div>

          <form id="formLogin" noValidate autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
            <InputText
              style={{ marginTop: 24 }}
              autoFocus={true}
              idname="xemail"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              errorMessage={errorsMap('email')}
              label="Digite o seu e-mail"
              inputRef={this.inputEmail}
              endAdornment={
                <React.Fragment>
                  {this.state.ajaxing && (
                    <Grid container style={{ height: '100%', width: 'auto' }} justify="center" alignItems="center">
                      <Grid item style={{ marginRight: 12 }}>
                        <CircularProgress
                          size={24}
                          thickness={4.8}
                          classes={{
                            root: classes.progress
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {!this.state.ajaxing && (
                    <IconButton type="submit">
                      <ArrowForwardIcon />
                    </IconButton>
                  )}
                </React.Fragment>
              }
            />
          </form>
        </Paper>
      </div>
    )
  }
}

IndexAppPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IndexAppPage)
