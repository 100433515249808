import React from 'react'

import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  FECHAMENTO_CONTA: {
    id: 1,
    descricaoOpcao: 'Na geração da fatura',
    descricaoExplicacao: <span>Esta opção faz com que o sistema gere as comissões no momento em que uma fatura é gerada para atendimentos e vendas de um cliente.</span>
  },
  FECHAMENTO_NOTA: {
    id: 2,
    descricaoOpcao: 'No fechamento da fatura',
    descricaoExplicacao: <span>Esta opção faz com que o sistema gere as comissões apenas no momento do fechamento das comandas.</span>
  }
})
export default enumObject
