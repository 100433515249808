import { Component } from 'react'

import { Box } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import DeviceHub from '@material-ui/icons/DeviceHub'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import PaletteIcon from '@material-ui/icons/Palette'
import PublicIcon from '@material-ui/icons/Public'
import SendIcon from '@material-ui/icons/Send'
import SettingsCellIcon from '@material-ui/icons/SettingsCell'
import StoreIcon from '@material-ui/icons/Store'
import WorkIcon from '@material-ui/icons/Work'
import AgendamentoOnlineDialogPage from 'pages/erp/painel/agendamentoonline/AgendamentoOnlineDialogPage'
import BloqueioHorarioGruposDialogPage from 'pages/erp/painel/colaborador/BloqueioHorarioGruposDialogPage'
import ConfiguracoesComissoesDialogPage from 'pages/erp/painel/configuracoes/ConfiguracoesComissoesDialogPage'
import ConfiguracoesEnvioMensagemDialogPage from 'pages/erp/painel/configuracoes/ConfiguracoesEnvioMensagemDialogPage'
import ConfiguracoesNfDialogPage from 'pages/erp/painel/configuracoes/ConfiguracoesNfDialogPage'
import DescontosDialogPage from 'pages/erp/painel/configuracoes/desconto/DescontosDialogPage'
import RecursosDialogPage from 'pages/erp/painel/configuracoes/recurso/RecursosDialogPage'
import ConfiguracoesTemaDialogPage from 'pages/erp/painel/configuracoes/tema/ConfiguracoesTemaDialogPage'
import FichasDialogPage from 'pages/erp/painel/fichacliente/FichasDialogPage'
import FormasPagamentoDialogPage from 'pages/erp/painel/formapagamento/FormasPagamentoDialogPage'
import NegocioDialogPage from 'pages/erp/painel/negocio/NegocioDialogPage'
//import AulaTesteDialog from 'pages/erp/painel/configuracoes/AulaTesteDialog';
import { getSessaoPainel, verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'
import PanelPage from 'support/components/panel/PanelPage'
import { TagNew } from 'support/components/TagNew'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import EmissaoNfEnum from 'support/domain/venda/EmissaoNfEnum'
import { logCORE } from 'support/util/Logger'

const styles = (theme) => ({
  novoLabel: {
    display: 'inline-block',
    fontSize: '9px',
    background: theme.palette.secondary.main,
    marginTop: 2,
    marginLeft: 6,
    position: 'absolute',
    borderRadius: 20,
    fontWeight: 700,
    color: 'white',
    textTransform: 'uppercase',
    padding: '3px 6px'
  },
  listContainer: {
    cursor: 'pointer'
  }
})

class ConfiguracoesPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
    this.state = {
      hasDevices: sessionStorage.getItem('hasDeviceIntegrated') || false
    }
    /*window.setTimeout(() => {
			openBackableDialog(AulaTesteDialog);
		},1000)*/
  }

  componentDidMount() {
    this.handlePagamentoIntegrado()
  }

  abrirConfiguracoesDadosEmpresa = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(NegocioDialogPage, { idNegocio: this.props.negocioSessao.id })
  }

  abrirConfiguracoesAgendamentoOnline = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(AgendamentoOnlineDialogPage)
  }

  abrirConfiguracoesTema = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(ConfiguracoesTemaDialogPage, { idNegocio2: this.props.negocioSessao.id })
  }

  abrirConfiguracoesIndisponibilidadesHorarios = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CAD_INDISPONIBILIDADE_HORARIO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(BloqueioHorarioGruposDialogPage)
  }

  abrirConfiguracoesFormaPagamento = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CAD_FORMAPAGTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(FormasPagamentoDialogPage)
  }

  abrirConfiguracoesComissoes = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(ConfiguracoesComissoesDialogPage, { idNegocio: this.props.negocioSessao.id })
  }

  abrirConfiguracoesDescontos = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CAD_DESCONTOS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(DescontosDialogPage)
  }

  abrirConfiguracoesFichaCliente = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CAD_FICHACLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(FichasDialogPage)
  }

  abrirConfiguracoesEnvioLembretes = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(ConfiguracoesEnvioMensagemDialogPage)
  }

  abrirConfiguracoesRecursos = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(RecursosDialogPage)
  }

  abrirConfiguracoesNf = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(ConfiguracoesNfDialogPage)
  }

  abrirMaquininhaMercadoPago = () => {
    const { hasDevices } = this.state
    if (hasDevices) {
      EventsManager.pub('NavegarPaginaPainel', { rota: '/gerenciar-maquininha' })
    } else {
      window.location.href = `${process.env.REACT_APP_MFE_ADDRESS}/pagamento-integrado`
    }
  }

  handlePagamentoIntegrado() {
    try {
      postAPI({
        url: 'erp/vendas/integracoes-mp/primeiros-passos',
        requerAutorizacao: true,
        onSuccess: (response) => {
          if (response.data.devices.length > 0) {
            this.setState({ hasDevices: true })
            sessionStorage.setItem('hasDeviceIntegrated', 'true')
          } else {
            sessionStorage.removeItem('hasDeviceIntegrated')
          }
        }
      })
    } catch (error) {
      logCORE(error, { component: 'ConfiguracoesPanelPage', context: 'handlePagamentoIntegrado' })
    }
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} contentMaxWidth={860} title="Configurações">
        <List disablePadding component="nav" className={classes.listContainer}>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])}
            onClick={() => this.abrirConfiguracoesDadosEmpresa()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText
              primary={<b>Perfil do Negócio</b>}
              secondary="Visualize e altere dados do seu negócio, como nome, fotos, telefones, endereço, horário de atendimento e etc"
            />
          </ListItem>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])}
            onClick={() => this.abrirConfiguracoesTema()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <PaletteIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Tema do Sistema</b>} secondary="Escolha as cores das telas do sistema para todos os funcionários" />
          </ListItem>

          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])}
            onClick={() => this.abrirConfiguracoesAgendamentoOnline()}
            classes={{ root: classes.listItem }}
            style={{ display: 'none' }}
          >
            <ListItemIcon>
              <PublicIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <b>
                  Agendamento Online <span className={classes.novoLabel}>Novo</span>
                </b>
              }
              secondary="Habilite e configure o Agendamento Online para que os seus clientes agendem serviços diretamente pelo aplicativo."
            />
          </ListItem>

          {/*
						<ListItem button disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])} onClick={() => this.abrirConfiguracoesFluxoCaixa()} classes={{root : classes.listItem}}>
							<ListItemIcon>
								<AccountBalanceIcon />
							</ListItemIcon>
							<ListItemText primary={Fluxo de Caixa} secondary="Ative ou desative a opção de fluxo de caixa no sistema" />
						</ListItem>
					*/}

          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])}
            onClick={() => this.abrirConfiguracoesComissoes()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Comissões e Gorjetas</b>} secondary="Configure as regras para o cálculo de comissões e controle de gorjetas dos profissionais" />
          </ListItem>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CAD_INDISPONIBILIDADE_HORARIO])}
            onClick={() => this.abrirConfiguracoesIndisponibilidadesHorarios()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <EventBusyIcon />
            </ListItemIcon>
            <ListItemText
              primary={<b>Indisponibilidades de Horários</b>}
              secondary="Configure dia e horários indisponíveis nas agendas, como feriados, afastamentos, férias e mais."
            />
          </ListItem>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CAD_FORMAPAGTO])}
            onClick={() => this.abrirConfiguracoesFormaPagamento()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <CreditCardIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Formas de Pagamento</b>} secondary="Configure as formas de pagamento que a sua empresa oferece" />
          </ListItem>
          <ListItem disableGutters onClick={() => this.abrirMaquininhaMercadoPago()} classes={{ root: classes.listItem }}>
            <ListItemIcon>
              <SettingsCellIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box alignItems="center">
                  <b>Maquininha integrada</b>
                  <TagNew />
                </Box>
              }
              secondary="Integre e gerencie suas maquininhas integradas"
            />
          </ListItem>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CAD_FICHACLIENTE])}
            onClick={() => this.abrirConfiguracoesFichaCliente()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={<b>Fichas de Clientes</b>}
              secondary="Configure fichas personalizadas para o seu negócio. Você poderá utilizar essas fichas para preencher dados dos seus clientes, como por exemplo criando uma ficha ANAMNESE"
            />
          </ListItem>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CAD_DESCONTOS])}
            onClick={() => this.abrirConfiguracoesDescontos()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Descontos</b>} secondary="Configure descontos pré-definidos" />
          </ListItem>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])}
            onClick={() => this.abrirConfiguracoesEnvioLembretes()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText
              primary={<b>Envio de Mensagens</b>}
              secondary="Configure o modelo das mensagens que o sistema irá gerar para enviar os lembretes de agendamentos e aniversário pelo WhatsApp"
            />
          </ListItem>
          <ListItem
            disableGutters
            disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])}
            onClick={() => this.abrirConfiguracoesRecursos()}
            classes={{ root: classes.listItem }}
          >
            <ListItemIcon>
              <DeviceHub />
            </ListItemIcon>
            <ListItemText
              primary={<b>Controle de Recursos</b>}
              secondary="Configure recursos como salas, cadeiras, equipamentos e etc. Através dos Recursos é possível evitar que dois profissionais marquem atendimentos para o mesmo horário utilizando a mesma sala, cadeira ou equipamento."
            />
          </ListItem>
          {getSessaoPainel().negocio.carregado && EmissaoNfEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.emissaoNf.id && (
            <ListItem
              disableGutters
              disabled={!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])}
              onClick={() => this.abrirConfiguracoesNf()}
              classes={{ root: classes.listItem }}
            >
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Emissão de Notas Fiscais</b>} secondary="Configure os dados para a emissão de notas fiscais de serviços." />
            </ListItem>
          )}
        </List>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ConfiguracoesPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesPanelPage)
