import AddIcon from '@material-ui/icons/Add'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import PanelPage from 'support/components/panel/PanelPage'

import { CuponsAddPage } from './CuponsAddPage'
import { CuponsDialogPage } from './CuponsDialogPage'

export const CuponsPanelPage = ({ ...props }) => {
  const handleOpenCuponPage = () => {
    openBackableDialog(CuponsAddPage)
  }
  return (
    <PanelPage
      {...props}
      title="Cupons"
      toolbarActions={{
        actions: [
          {
            icon: <AddIcon />,
            handleAction: handleOpenCuponPage
          }
        ]
      }}
    >
      <CuponsDialogPage />
    </PanelPage>
  )
}
