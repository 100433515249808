import StyleUtil from 'support/util/StyleUtil'

const MAX_WIDTH_DEFAULT = 420

function getStyles({ defaultTheme, tema, constant }) {
  return {
    page: {
      parentCenteredPaper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '100vh',
        position: 'relative'
      },

      paper: function (opts) {
        if (opts === undefined) {
          opts = {}
        }

        const style = {}
        const styleDownXs = {}
        const styleDown380 = {}
        style[defaultTheme.breakpoints.down('xs')] = styleDownXs
        style[defaultTheme.breakpoints.down(380)] = styleDown380

        style.maxWidth = opts.maxWidth ? opts.maxWidth : MAX_WIDTH_DEFAULT
        style.marginLeft = 'auto'
        style.marginRight = 'auto'
        style.marginTop = defaultTheme.spacing(6)
        style.marginBottom = defaultTheme.spacing(6)
        style.padding = '40px 48px'
        style.borderRadius = 8
        StyleUtil.setBoxShadow(style, '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)')
        //StyleUtil.setBoxShadow(style,"none");

        styleDownXs.backgroundColor = 'transparent'
        styleDownXs.marginTop = 0
        styleDownXs.maxWidth = 'none'
        styleDownXs.marginBottom = 0
        styleDownXs.padding = defaultTheme.spacing(5)
        StyleUtil.setBoxShadow(styleDownXs, 'none')

        styleDown380.padding = defaultTheme.spacing(3)

        return style
      }
    }
  }
}

const objectFunctions = {
  getStyles: getStyles
}

export default objectFunctions
