import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const styles = (theme) => ({
  rootContent: {
    minWidth: '0px !important'
  },
  actionContent: {
    paddingLeft: 0
  },
  saveButton: {
    marginTop: 2,
    color: theme.palette.secondary.light
  },
  cancelButton: {
    fontWeight: 400,
    textTransform: 'none',
    color: '#d0d0d0'
  },
  successMessage: {
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  buttonClose: {
    color: '#bbb'
  }
})

class TabSaveSnackBar extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      open: false,
      loading: false
    }
  }

  componentDidMount() {
    this.eventsManager.sub('TabSaveSnackBar', (opts) => {
      if (opts.open !== this.state.open) {
        if (opts.open === true) {
          EventsManager.pub('SnackBarSuccess', { open: false })
        }
        if (opts.functionsMap) {
          opts.functionsMap.save = (props) => {
            if (!props) {
              props = {}
            }
            this.handleSave({
              onSuccessCallback: props.onSuccessCallback
            })
          }
          opts.functionsMap.isOpen = () => {
            return this.state.open
          }
        }

        this.setState({
          open: opts.open,
          loading: false,
          saveFunction: opts.saveFunction,
          cancelFunction: opts.cancelFunction
        })
      }
    })
  }

  onStart = () => {
    this.setState({ loading: true })
  }

  onError = () => {
    this.setState({ loading: false })
  }

  handleSave = (props) => {
    this.state.saveFunction(
      this.onStart,
      (message) => {
        this.setState({ loading: true, open: false })
        EventsManager.pub('SnackBarSuccess', { open: true, message: message })
        if (props.onSuccessCallback) {
          props.onSuccessCallback()
        }
      },
      this.onError
    )
  }

  handleCancel = () => {
    this.setState({ open: false })
    this.state.cancelFunction()
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    const { loading } = this.state

    let message = undefined
    if (loading) {
      message = 'Salvando...'
    }

    return (
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.open} onClose={this.handleClose}>
        <SnackbarContent
          classes={{
            root: classes.rootContent,
            action: classes.actionContent
          }}
          message={message}
          action={
            !loading
              ? [
                  <Button key="save" classes={{ text: classes.saveButton }} onClick={this.handleSave}>
                    SALVAR ALTERAÇÕES
                  </Button>,
                  <IconButton key="close" className={classes.buttonClose} onClick={this.handleCancel}>
                    <CloseIcon style={{ fontSize: 20 }} />
                  </IconButton>
                ]
              : undefined
          }
        />
      </Snackbar>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabSaveSnackBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabSaveSnackBar)
