import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import VendasResponsiveTable from 'pages/erp/painel/venda/table/VendasResponsiveTable'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'

const styles = (theme) => ({})

class DetalhesVendasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }

    this.dataExporterFunctionsMap = {}
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      idColaborador: this.props.colaborador.id,
      tipo: this.props.tipo,
      cancelado: false,
      buscarComoPrincipal: true
    }
  }

  downloadRelatorio = (event) => {
    const { dataInicial, dataFinal } = this.state

    let filename = 'Lista de Vendas'
    if (dataInicial || dataFinal) {
      filename += ' - ' + formatarPeriodoNomeArquivo(dataInicial, dataFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'vendas.gerarListaVendas',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')
    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} align="center" title="Detalhamento" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">{this.props.tituloDialog}</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <VendasResponsiveTable
              origem="colaborador"
              scrollElement={dialogContentProps.scrollContainerRef.current}
              itemsPerRequest={15}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/vendas.buscarVendas"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              emptyListProps={{
                padding: false,
                text: 'Nenhuma atividade foi encontrada'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

DetalhesVendasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesVendasDialogPage)
