function getJSONFromString(string) {
  try {
    var obj = JSON.parse(string)
    if (obj && typeof obj === 'object' && obj !== null) {
      return obj
    }
  } catch (err) {}
  return null
}

const functionsObject = {
  getJSONFromString: getJSONFromString
}

export default functionsObject
