import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerEstrelaPintada: {
    '& svg': {
      color: '#ffc55b',
      fontSize: 18,
      marginLeft: -1,
      marginRight: -1
    }
  },
  containerEstrelaApagada: {
    '& svg': {
      color: '#e6e6e6',
      fontSize: 18,
      marginLeft: -1,
      marginRight: -1
    }
  },
  containerFooterButtons: theme.form.containerFooterButtons()
})

class AvaliacaoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputResposta = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/negocios.buscarAvaliacaoPorId',
      params: {
        id: this.props.idAvaliacao
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const avaliacao = response.data
        this.setState({
          avaliacao: avaliacao
        })
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.editarResposta()
  }

  editarResposta = () => {
    postAPI({
      url: 'erp/negocios.editarAvaliacaoResposta',
      data: this.getDadosDesconto(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoAvaliacao')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) })
        focusFirstElementWithError('formAvaliacao')
      }
    })
  }

  getDadosDesconto = () => {
    const dados = {}
    dados.id = this.props.idAvaliacao
    dados.resposta = this.inputResposta.current.value
    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, ajaxing } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={'Avaliação'}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => {
            const { avaliacao } = this.state

            return (
              <form id="formAvaliacao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                <Typography variant="body1">
                  <b>{avaliacao.cliente.nome}</b>
                </Typography>
                <Typography variant="body2">{moment(avaliacao.data).format('DD [de] MMMM [de] YYYY')}</Typography>
                <div style={{ position: 'relative', height: 16, width: 79, marginTop: 2, marginBottom: 4, display: 'inline-flex' }}>
                  <div className={classNames(classes.containerEstrelaApagada)} style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap' }}>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </div>
                  <div
                    className={classNames(classes.containerEstrelaPintada)}
                    style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap', width: avaliacao.nota * 20 + 1 + '%', overflow: 'hidden' }}
                  >
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </div>
                </div>
                <Typography variant="body2">{avaliacao.comentario}</Typography>
                <br />
                <br />
                <InputText
                  idname="xresposta"
                  autoFocus={true}
                  defaultValue={avaliacao.resposta}
                  customVariant="outlined-small"
                  shrink={true}
                  multiline={true}
                  rows={6}
                  rowsMax={8}
                  maxLength={400}
                  disabled={ajaxing}
                  errorMessage={errorsMap('resposta')}
                  label="Resposta"
                  placeholder="Escreva uma resposta..."
                  inputRef={this.inputResposta}
                />

                <HiddenSubmitButton />

                {this.state.paginaCarregada && (
                  <div className={classes.containerFooterButtons}>
                    <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                      Cancelar
                    </Button>
                    <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                      {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                    </Button>
                  </div>
                )}
              </form>
            )
          }}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

AvaliacaoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AvaliacaoDialogPage)
