import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginBottom: '40px',
      fontWeight: 600,
      fontSize: '16px'
    },
    subtitle: {
      fontWeight: 400,
      fontSize: '12px'
    },
    content: {
      padding: '24px 32px',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 536,
      width: '100%'
    },
    wrapperBox: {
      flexDirection: 'column',
      display: 'flex',
      gap: '4px',
      marginTop: '40px'
    },
    box: {
      display: 'flex',
      padding: '4px',
      borderRadius: '4px',
      border: '1px solid #dedede',
      paddingLeft: '16px'
    },
    wrapperButtons: {
      marginTop: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '16px',
      '& > button': {
        textTransform: 'capitalize'
      }
    },
    wrapperLoading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '32px'
    },
    loading: {
      fontSize: '80px'
    },
    waitingTitle: {
      marginTop: '16px',
      fontSize: '20px',
      fontWeight: 400
    },
    waitingSubtitle: {
      fontSize: '16px',
      color: '#666666'
    }
  })
)
