import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaCheckbox: {
    maxWidth: 50,
    minWidth: 50
  },
  colunaData: {
    flexGrow: 1,
    maxWidth: 106,
    minWidth: 106,
    [theme.breakpoints.down(686)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaDescricao: {
    flexGrow: 1.5
  },
  colunaIconeTipoVenda: {
    maxWidth: 44,
    minWidth: 44,
    [theme.breakpoints.down(680)]: {
      display: 'none'
    }
  },
  iconeTipoVenda: {
    display: 'block',
    fontSize: '24px'
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 110
  },
  colunaQuantidade: {
    flexGrow: 1,
    maxWidth: 70,
    minWidth: 70
  },
  colunaAcoes: {
    flexGrow: 1,
    maxWidth: 90,
    minWidth: 90,
    [theme.breakpoints.down(760)]: {
      maxWidth: 50,
      minWidth: 50
    }
  }
})

class VendasNotaResponsiveTable extends Component {
  getRenderContent = (size, item) => {
    const { classes, theme, acao } = this.props

    const content = {}

    if (item.utilizacaoPacote === true || item.clientePacoteItem) {
      if (size === 'large') {
        content.valorDesconto = '-'
        content.valorTotal = (
          <span>
            Sem cobrança
            <br />
            <small>Crédito de pacote</small>
          </span>
        )
      } else if (size === 'medium') {
        content.valorDesconto = (
          <Typography variant="body2" style={{ fontSize: 12 }}>
            {content.valorDesconto}
          </Typography>
        )
        content.valorTotal = (
          <span>
            Sem cobrança
            <br />
            <small>Crédito de pacote</small>
          </span>
        )
      } else {
        content.valorDesconto = 'Desconto: R$ 0,00'
        content.valorTotal = 'Crédito de pacote'
      }
    } else {
      if (size === 'large') {
        content.valorTotal = formatarValorMonetario(item.valorTotal, { prefix: false })
        content.valorDesconto = formatarValorMonetario(item.valorDescontoCalculado, { prefix: false })
      } else if (size === 'medium') {
        content.valorTotal = formatarValorMonetario(item.valorTotal, { prefix: false })
        content.valorDesconto = (
          <Typography variant="body2" style={{ fontSize: 12 }}>
            Desconto: {formatarValorMonetario(item.valorDescontoCalculado)}
          </Typography>
        )
      } else {
        content.valorTotal = formatarValorMonetario(item.valorTotal)
        content.valorDesconto = 'Desconto: ' + formatarValorMonetario(item.valorDescontoCalculado)
      }
    }

    if (item.cancelado === true) {
      content.valorDesconto = (
        <span>
          <strike>{content.valorDesconto}</strike>
        </span>
      )
      if (size === 'medium') {
        if (item.excluido) {
          content.valorTotal = (
            <span>
              <strike>{content.valorTotal}</strike>
              <br />
              <b style={{ color: theme.palette.secondary.main }}>Removido</b>
            </span>
          )
        } else {
          content.valorTotal = (
            <span>
              <strike>{content.valorTotal}</strike>
              <br />
              <b style={{ color: theme.palette.secondary.main }}>Cancelado</b>
            </span>
          )
        }
      } else {
        content.valorTotal = (
          <span>
            <strike>{content.valorTotal}</strike>
          </span>
        )
      }
    }

    content._checked = item._checked
    content.dataVenda = converterDataIntParaMoment(item.dataVenda).format('DD/MM/YY')

    const iconeEDescricao = VendaService.getIconeEDescricao(item)
    content.iconeTipoVenda = <iconeEDescricao.icon className={classes.iconeTipoVenda} />

    content.descricao = VendaService.getDescricao(item)

    content.quantidade = item.quantidade.toString()

    if (size === 'large') {
      if (item.cancelado) {
        if (item.excluido) {
          content.acao = (
            <Typography variant="body2" color="secondary">
              <b>Removido</b>
            </Typography>
          )
        } else {
          content.acao = (
            <Typography variant="body2" color="secondary">
              <b>Cancelado</b>
            </Typography>
          )
        }
      } else {
        content.acao = (
          <IconButton
            onClick={(event) => {
              acao.onClick(event, item)
            }}
          >
            <acao.icon />
          </IconButton>
        )
      }
    } else if (size === 'medium') {
      content.acao = (
        <IconButton
          disabled={item.cancelado}
          onClick={(event) => {
            acao.onClick(event, item)
          }}
        >
          <acao.icon />
        </IconButton>
      )
    } else {
      if (item.cancelado) {
        if (item.excluido) {
          content.acao = (
            <Typography variant="body2" color="secondary">
              <b>Removido</b>
            </Typography>
          )
        } else {
          content.acao = (
            <Typography variant="body2" color="secondary">
              <b>Cancelado</b>
            </Typography>
          )
        }
      } else {
        content.acao = (
          <IconButton
            onClick={(event) => {
              acao.onClick(event, item)
            }}
          >
            <acao.icon />
          </IconButton>
        )
      }
    }

    return content
  }

  render() {
    const { classes, margin, selecaoProps, acao, ...others } = this.props

    const largeRendeColumns = []
    const mediumRenderProps = []

    let newColumn = null
    let horizontalPaddingNone = true

    if (selecaoProps) {
      newColumn = {
        className: classes.colunaCheckbox,
        label: <Checkbox onClick={selecaoProps.selecionarTodosFunction} checked={selecaoProps.selecionarTodosValue} />,
        horizontalPadding: margin ? null : 'none'
      }
      largeRendeColumns.push(newColumn)
      mediumRenderProps.push(newColumn)
      horizontalPaddingNone = false
    }

    newColumn = { className: classes.colunaData, label: 'Data', horizontalPadding: horizontalPaddingNone && !margin ? 'none' : null }
    largeRendeColumns.push(newColumn)
    mediumRenderProps.push(newColumn)

    newColumn = { label: '', className: classes.colunaIconeTipoVenda, isVisible: false, horizontalPadding: 'small' }
    largeRendeColumns.push(newColumn)
    mediumRenderProps.push(newColumn)

    newColumn = { label: 'Descrição', horizontalPadding: 'small', props: { xs: true } }
    largeRendeColumns.push(newColumn)
    mediumRenderProps.push(newColumn)

    newColumn = { className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right', label: 'Qtd.' }
    largeRendeColumns.push(newColumn)

    newColumn = { className: classes.colunaValor, horizontalPadding: 'small', align: 'right', label: 'Descontos (R$)' }
    largeRendeColumns.push(newColumn)

    if (acao) {
      horizontalPaddingNone = true
    }

    newColumn = { className: classes.colunaValor, horizontalPadding: horizontalPaddingNone && !margin ? 'none' : 'small', align: 'right', label: 'Valor Final (R$)' }
    largeRendeColumns.push(newColumn)
    mediumRenderProps.push(newColumn)

    if (acao) {
      newColumn = { className: classes.colunaAcoes, align: 'right', isVisible: false, label: 'Ações', horizontalPadding: margin ? 'small' : 'none' }
      largeRendeColumns.push(newColumn)
      mediumRenderProps.push(newColumn)
    }

    const smallContainerStyle = {}
    if (margin) {
      smallContainerStyle.marginLeft = 16
      smallContainerStyle.marginRight = 16
    }

    return (
      <VirtualizedResponsiveTable
        showBackgroundDividers={true}
        maxMediumContentBreakpoint="sm"
        largeRenderProps={{
          columns: largeRendeColumns,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('large', item)
            const itemData = []

            if (selecaoProps) {
              itemData.push(<Checkbox checked={content._checked === undefined ? false : content._checked} />)
            }
            itemData.push(content.dataVenda)
            itemData.push(content.iconeTipoVenda)
            itemData.push(content.descricao)
            itemData.push(content.quantidade)
            itemData.push(content.valorDesconto)
            itemData.push(content.valorTotal)
            if (content.acao) {
              itemData.push(content.acao)
            }
            return {
              selected: content._checked,
              itemData: itemData
            }
          }
        }}
        mediumRenderProps={{
          headerColumnHeight: 50,
          rowHeight: 86,
          columns: mediumRenderProps,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('medium', item)

            const itemData = []

            if (selecaoProps) {
              itemData.push(<Checkbox checked={content._checked === undefined ? false : content._checked} />)
            }
            itemData.push(content.dataVenda)
            itemData.push(content.iconeTipoVenda)
            itemData.push(
              <React.Fragment>
                <Typography variant="body2">{content.descricao}</Typography>
                <Typography variant="body2">Quantidade: {content.quantidade}</Typography>
                {content.valorDesconto}
              </React.Fragment>
            )
            itemData.push(content.valorTotal)
            if (content.acao) {
              itemData.push(content.acao)
            }

            return {
              selected: content._checked,
              itemData: itemData
            }
          }
        }}
        smallRenderProps={{
          rowHeight: 120,
          horizontalPadding: 'none',
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('small', item)

            return {
              selected: content._checked,
              itemData: (
                <div style={smallContainerStyle}>
                  <Grid container alignItems="center">
                    {selecaoProps && (
                      <Grid item>
                        <Checkbox checked={content._checked === undefined ? false : content._checked} />
                      </Grid>
                    )}
                    <Grid item xs style={{ minWidth: 0 /*minWidth - para ativar as reticências */ }}>
                      <Grid container>
                        <Grid item>
                          <Typography variant="body2">
                            <b>{content.dataVenda}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs align="right">
                          <Typography variant="body2" style={{ fontWeight: 500 }}>
                            {content.valorTotal}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="body2" noWrap={true}>
                        <b>{content.descricao}</b>
                      </Typography>
                      <Grid container>
                        <Grid item xs>
                          <Typography variant="body2">Quantidade: {content.quantidade}</Typography>
                          <Typography variant="body2">{content.valorDesconto}</Typography>
                        </Grid>
                        <Grid item align="right">
                          {item.cancelado && (
                            <Typography variant="body2" color="secondary" style={{ fontWeight: 500 }}>
                              Cancelado
                            </Typography>
                          )}
                          {!item.cancelado && (
                            <IconButton
                              onClick={(event) => {
                                acao.onClick(event, item)
                              }}
                            >
                              <acao.icon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )
            }
          }
        }}
        {...others}
      />
    )
  }
}

VendasNotaResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(VendasNotaResponsiveTable)
