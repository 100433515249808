import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ContentLoadStatus from 'support/components/contentload/ContentLoadStatus'

const styles = (theme) => ({})

class ContentWithPreload extends Component {
  constructor(props) {
    super(props)

    this.loadKey = this.props.loadKey
    this.contadorPesquisa = 0
    this.state = {
      loaded: false,
      loading: false,
      messageErrorCode: ''
    }
    this.offset = 0
    this.mounted = true
  }

  componentDidMount() {
    if (this.props.functionsMap !== undefined) {
      this.props.functionsMap['reload'] = this.reload
      this.props.functionsMap['refresh'] = this.reload
      this.props.functionsMap['reloadInBackground'] = () => {
        this.load({ inBackground: true })
      }
    }
    this.load()
  }

  load = (props) => {
    if (!props) {
      props = {}
    }

    if (!props.inBackground) {
      this.setState((state) => ({
        loading: true,
        messageErrorCode: ''
      }))
    }

    this.props.loadFunction(this.notifyContentLoaded, this.notifyContentNotLoaded, props.inBackground)
  }

  reload = () => {
    this.setState((state) => ({
      loaded: false
    }))
    this.load()
  }

  notifyContentLoaded = () => {
    if (this.mounted) {
      this.setState((state) => ({
        loading: false,
        loaded: true
      }))
    }
  }

  notifyContentNotLoaded = (opts) => {
    if (this.mounted) {
      if (opts === undefined) {
        opts = {}
      }

      this.setState((state) => ({
        loading: false,
        messageErrorCode: opts.messageErrorCode
      }))
    }
  }

  componentDidUpdate = () => {
    if (this.loadKey !== this.props.loadKey) {
      this.loadKey = this.props.loadKey
      this.reload()
    } else {
      if (this.props.onComponentDidUpdate) {
        this.props.onComponentDidUpdate()
      }
    }
  }

  render() {
    let content = null

    if (this.state.loading === false && this.state.loaded === true) {
      content = this.props.children ? this.props.children : null
      if (typeof content == 'function') {
        content = content({ innerContentRef: this.innerContentRef })
      }
    } else {
      content = (
        <ContentLoadStatus
          key="ContentLoadStatus"
          align={this.props.align}
          padding={this.props.loadContentPadding}
          loading={this.state.loading}
          loaded={this.state.loaded}
          messageErrorCode={this.state.messageErrorCode}
          loadFunction={this.load}
          notLoadedContentTemplate={this.props.notLoadedContentTemplate}
          circularProgressVariant={this.props.circularProgressVariant}
        />
      )
    }
    return content
  }

  componentWillUnmount = () => {
    this.mounted = false
  }
}

ContentWithPreload.propTypes = {
  classes: PropTypes.object.isRequired,
  functionsMap: PropTypes.object,
  loadFunction: PropTypes.func,
  align: PropTypes.string
}

export default withStyles(styles)(ContentWithPreload)
