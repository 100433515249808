import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { calcularVariacao, getPercentualRestanteMes, ValorComVariacao } from 'support/domain/admin/relatorio/RelatorioService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaPeriodo: {
    maxWidth: 100,
    minWidth: 100
  },
  colunaQuantidade: {
    flexGrow: 0.7
  }
})

class IndicadoresFinanceirosSalao99DialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item, index) => {
    const content = {}
    content.periodo = converterDataIntParaMoment(item.periodo).format('MMM/YYYY')
    content.agendamentosOnline = (
      <span>
        <b>{item.agendamentosOnline}</b>
      </span>
    )
    if (item.agendamentosOnlineDifQtd) {
      let sinal = ''

      if (item.agendamentosOnlineDifQtd > 0) {
        sinal = '+'
      }
      content.agendamentosOnline = (
        <span>
          {content.agendamentosOnline}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.agendamentosOnlineDifQtd}>
            <small>
              ({sinal + item.agendamentosOnlineDifQtd}) {item.agendamentosOnlineDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.cadastrosNovos = (
      <span>
        <b>{item.cadastrosNovos}</b>
      </span>
    )
    if (item.cadastrosNovosDifQtd) {
      let sinal = ''

      if (item.cadastrosNovosDifQtd > 0) {
        sinal = '+'
      }
      content.cadastrosNovos = (
        <span>
          {content.cadastrosNovos}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.cadastrosNovosDifQtd}>
            <small>
              ({sinal + item.cadastrosNovosDifQtd}) {item.cadastrosNovosDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.cadastrosNovosOrganico = (
      <span>
        <b>{item.cadastrosNovosOrganico}</b>
      </span>
    )
    if (item.cadastrosNovosOrganicoDifQtd) {
      let sinal = ''
      if (item.cadastrosNovosOrganicoDifQtd > 0) {
        sinal = '+'
      }
      content.cadastrosNovosOrganico = (
        <span>
          {content.cadastrosNovosOrganico}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.cadastrosNovosOrganicoDifQtd}>
            <small>
              ({sinal + item.cadastrosNovosOrganicoDifQtd}) {item.cadastrosNovosOrganicoDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.cadastrosNovosAds = (
      <span>
        <b>{item.cadastrosNovosAds}</b>
      </span>
    )
    if (item.cadastrosNovosAdsDifQtd) {
      let sinal = ''
      if (item.cadastrosNovosAdsDifQtd > 0) {
        sinal = '+'
      }
      content.cadastrosNovosAds = (
        <span>
          {content.cadastrosNovosAds}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.cadastrosNovosAdsDifQtd}>
            <small>
              ({sinal + item.cadastrosNovosAdsDifQtd}) {item.cadastrosNovosAdsDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.clientesNovosOrganico = (
      <span>
        <b>{item.clientesNovosOrganico}</b>
      </span>
    )
    if (item.clientesNovosOrganicoDifQtd) {
      let sinal = ''
      if (item.clientesNovosOrganicoDifQtd > 0) {
        sinal = '+'
      }
      content.clientesNovosOrganico = (
        <span>
          {content.clientesNovosOrganico}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.clientesNovosOrganicoDifQtd}>
            <small>
              ({sinal + item.clientesNovosOrganicoDifQtd}) {item.clientesNovosOrganicoDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.clientesNovosAds = (
      <span>
        <b>{item.clientesNovosAds}</b>
      </span>
    )
    if (item.clientesNovosAdsDifQtd) {
      let sinal = ''
      if (item.clientesNovosAdsDifQtd > 0) {
        sinal = '+'
      }
      content.clientesNovosAds = (
        <span>
          {content.clientesNovosAds}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.clientesNovosAdsDifQtd}>
            <small>
              ({sinal + item.clientesNovosAdsDifQtd}) {item.clientesNovosAdsDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.clientesDesistentes = (
      <span>
        <b>{item.clientesDesistentes}</b>
      </span>
    )
    if (item.clientesDesistentesDifQtd) {
      let sinal = ''
      if (item.clientesDesistentesDifQtd > 0) {
        sinal = '+'
      }
      content.clientesDesistentes = (
        <span>
          {content.clientesDesistentes}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.clientesDesistentesDifQtd}>
            <small>
              ({sinal + item.clientesDesistentesDifQtd}) {item.clientesDesistentesDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.clientesPagantesReal = (
      <span>
        <b>{item.clientesPagantesReal}</b>
      </span>
    )
    if (item.clientesPagantesRealDifQtd) {
      let sinal = ''
      if (item.clientesPagantesRealDifQtd > 0) {
        sinal = '+'
      }
      content.clientesPagantesReal = (
        <span>
          {content.clientesPagantesReal}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.clientesPagantesRealDifQtd}>
            <small>
              ({sinal + item.clientesPagantesRealDifQtd}) {item.clientesPagantesRealDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.clientesPagantesAjustado = (
      <span>
        <b>{item.clientesPagantesAjustado}</b>
      </span>
    )
    if (item.clientesPagantesAjustadoDifQtd) {
      let sinal = ''
      if (item.clientesPagantesAjustadoDifQtd > 0) {
        sinal = '+'
      }
      content.clientesPagantesAjustado = (
        <span>
          {content.clientesPagantesAjustado}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.clientesPagantesAjustadoDifQtd}>
            <small>
              ({sinal + item.clientesPagantesAjustadoDifQtd}) {item.clientesPagantesAjustadoDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.receitaReal = (
      <span>
        <b>{formatarValorMonetario(item.receitaReal)}</b>
      </span>
    )
    if (item.receitaRealDifQtd) {
      let sinal = ''
      if (item.receitaRealDifQtd > 0) {
        sinal = '+'
      }
      content.receitaReal = (
        <span>
          {content.receitaReal}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.receitaRealDifQtd}>
            <small>
              ({sinal + item.receitaRealDifQtd.toFixed(0)}) {sinal + item.receitaRealDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.receitaAjustada = (
      <span>
        <b>{formatarValorMonetario(item.receitaAjustada)}</b>
      </span>
    )
    if (item.receitaAjustadaDifQtd) {
      let sinal = ''
      if (item.receitaAjustadaDifQtd > 0) {
        sinal = '+'
      }
      content.receitaAjustada = (
        <span>
          {content.receitaAjustada}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.receitaAjustadaDifQtd}>
            <small>
              ({sinal + item.receitaAjustadaDifQtd.toFixed(0)}) {sinal + item.receitaAjustadaDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    return content
  }

  prepararListas = (items) => {
    for (let i = 0; i < items.length - 1; i++) {
      const item = items[i]
      const itemAnterior = items[i + 1]
      item.agendamentosOnlineDifQtd = item.agendamentosOnline - itemAnterior.agendamentosOnline
      item.agendamentosOnlineDifPer = calcularVariacao(item.agendamentosOnline, itemAnterior.agendamentosOnline)
      item.cadastrosNovosDifQtd = item.cadastrosNovos - itemAnterior.cadastrosNovos
      item.cadastrosNovosDifPer = calcularVariacao(item.cadastrosNovos, itemAnterior.cadastrosNovos)
      item.cadastrosNovosOrganicoDifQtd = item.cadastrosNovosOrganico - itemAnterior.cadastrosNovosOrganico
      item.cadastrosNovosOrganicoDifPer = calcularVariacao(item.cadastrosNovosOrganico, itemAnterior.cadastrosNovosOrganico)
      item.cadastrosNovosAdsDifQtd = item.cadastrosNovosAds - itemAnterior.cadastrosNovosAds
      item.cadastrosNovosAdsDifPer = calcularVariacao(item.cadastrosNovosAds, itemAnterior.cadastrosNovosAds)
      item.clientesNovosOrganicoDifQtd = item.clientesNovosOrganico - itemAnterior.clientesNovosOrganico
      item.clientesNovosOrganicoDifPer = calcularVariacao(item.clientesNovosOrganico, itemAnterior.clientesNovosOrganico)
      item.clientesNovosAdsDifQtd = item.clientesNovosAds - itemAnterior.clientesNovosAds
      item.clientesNovosAdsDifPer = calcularVariacao(item.clientesNovosAds, itemAnterior.clientesNovosAds)
      item.clientesDesistentesDifQtd = item.clientesDesistentes - itemAnterior.clientesDesistentes
      item.clientesDesistentesDifPer = calcularVariacao(item.clientesDesistentes, itemAnterior.clientesDesistentes)
      item.clientesPagantesRealDifQtd = item.clientesPagantesReal - itemAnterior.clientesPagantesReal
      item.clientesPagantesRealDifPer = calcularVariacao(item.clientesPagantesReal, itemAnterior.clientesPagantesReal)
      item.clientesPagantesAjustadoDifQtd = item.clientesPagantesAjustado - itemAnterior.clientesPagantesAjustado
      item.clientesPagantesAjustadoDifPer = calcularVariacao(item.clientesPagantesAjustado, itemAnterior.clientesPagantesAjustado)
      item.receitaRealDifQtd = item.receitaReal - itemAnterior.receitaReal
      item.receitaRealDifPer = calcularVariacao(item.receitaReal, itemAnterior.receitaReal)
      item.receitaAjustadaDifQtd = item.receitaAjustada - itemAnterior.receitaAjustada
      item.receitaAjustadaDifPer = calcularVariacao(item.receitaAjustada, itemAnterior.receitaAjustada)
    }
    return items
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage
        {...dialogProps}
        title="Indicadores"
        toolbarActions={{
          actions: [
            {
              label: getPercentualRestanteMes().toFixed(0).toString() + '%',
              disabled: true
            },
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.loaderFunctionsMap.load()
              }
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <br />
            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="admin"
              endpoint="admin/relatorios.buscarIndicadoresFinanceirosSalao99"
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const state = { lista: this.prepararListas(data.items) }
                  this.setState(state)
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Período', horizontalPadding: 'small', className: classes.colunaPeriodo },
                  { label: 'Agendamentos Online', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  { label: 'Cadastros Orgânicos', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  { label: 'Cadastros Ads', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  /*{label : "Cadastros Total", horizontalPadding: "small", className : classes.colunaQuantidade, props: {xs:true}},*/
                  { label: 'Novos Orgânicos', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  { label: 'Novos Ads', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  { label: 'Desistentes', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  { label: 'Pagamentos', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  { label: 'Pagamentos (Ajustado)', horizontalPadding: 'small', className: classes.colunaQuantidade, props: { xs: true } },
                  { label: 'Receita (Caixa)', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Receita (Competência)', horizontalPadding: 'small', props: { xs: true } }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item, index)
                  return {
                    itemData: [
                      content.periodo,
                      content.agendamentosOnline,
                      content.cadastrosNovosOrganico,
                      content.cadastrosNovosAds,
                      /*content.cadastrosNovos,*/
                      content.clientesNovosOrganico,
                      content.clientesNovosAds,
                      content.clientesDesistentes,
                      content.clientesPagantesReal,
                      content.clientesPagantesAjustado,
                      content.receitaReal,
                      content.receitaAjustada
                    ]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 250,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body1" noWrap={true}>
                          {content.periodo}
                        </Typography>
                        <Typography variant="body2">Agendamentos Online : {content.agendamentosOnline}</Typography>
                        <Typography variant="body2">Cadastros Orgânicos : {content.cadastrosNovosOrganico}</Typography>
                        <Typography variant="body2">Cadastros Ads : {content.cadastrosNovosAds}</Typography>
                        <Typography variant="body2">Novos Orgânicos : {content.clientesNovosOrganico}</Typography>
                        <Typography variant="body2">Novos Ads: {content.clientesNovosAds}</Typography>
                        <Typography variant="body2">Desistentes : {content.clientesDesistentes}</Typography>
                        <Typography variant="body2">Pagamentos : {content.clientesPagantesReal}</Typography>
                        <Typography variant="body2">Pagamentos (Ajustado) : {content.clientesPagantesAjustado}</Typography>
                        <Typography variant="body2">Receita (Caixa) : {content.receitaReal}</Typography>
                        <Typography variant="body2">Receita (Competência) : {content.receitaAjustada}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum registro encontrado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

IndicadoresFinanceirosSalao99DialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IndicadoresFinanceirosSalao99DialogPage)
