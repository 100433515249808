import { createInlineValuesObject } from 'support/components/inlinevalues/InlineValues'
import ArrayUtil from 'support/util/ArrayUtil'

const GRUPO_AGENDAMENTO_ONLINE = 'Agendamento Online'
const GRUPO_NF = 'Notas Fiscais'

const PermissoesAcesso = createInlineValuesObject({
  AO_EMAIL_PROPRIO_AGENDAMENTO: {
    position: 0,
    grupo: GRUPO_AGENDAMENTO_ONLINE,
    descricao: 'Receber e-mail quando um cliente agendar/cancelar Online um serviço para este colaborador'
  },
  AO_NOTIFICACAO_PROPRIO_AGENDAMENTO: {
    position: 1,
    grupo: GRUPO_AGENDAMENTO_ONLINE,
    descricao: 'Receber notificação na tela atendimentos quando um cliente agendar/cancelar Online um serviço para este colaborador'
  },
  AO_EMAIL_TODOS_AGENDAMENTOS: {
    position: 2,
    grupo: GRUPO_AGENDAMENTO_ONLINE,
    descricao: 'Receber e-mail quando um cliente agendar/cancelar Online um serviço para qualquer colaborador'
  },
  AO_NOTIFICACAO_TODOS_AGENDAMENTOS: {
    position: 3,
    grupo: GRUPO_AGENDAMENTO_ONLINE,
    descricao: 'Receber notificação na tela atendimentos quando um cliente agendar/cancelar Online um serviço para qualquer colaborador'
  },

  NF_EMAIL_ERRO_EMISSAO_CANCELAMENTO: { position: 4, grupo: GRUPO_NF, descricao: 'Receber e-mail quando ocorrer um erro na emissão ou no cancelamento de uma nota fiscal' }
})

const MAPEAMENTO_DEPENDENCIAS_INCLUSAO = {
  [PermissoesAcesso.AO_EMAIL_TODOS_AGENDAMENTOS.position]: [PermissoesAcesso.AO_EMAIL_PROPRIO_AGENDAMENTO],

  [PermissoesAcesso.AO_NOTIFICACAO_TODOS_AGENDAMENTOS.position]: [PermissoesAcesso.AO_NOTIFICACAO_PROPRIO_AGENDAMENTO]
}

const MAPEAMENTO_DEPENDENCIAS_REMOCAO = (function () {
  const mapeamentoRemocao = {}
  Object.keys(MAPEAMENTO_DEPENDENCIAS_INCLUSAO).forEach(function (position) {
    for (let permissao of MAPEAMENTO_DEPENDENCIAS_INCLUSAO[position]) {
      if (mapeamentoRemocao[permissao.position] === undefined) {
        mapeamentoRemocao[permissao.position] = []
      }
      mapeamentoRemocao[permissao.position].push(PermissoesAcesso.getByPosition(parseInt(position, 10)))
    }
  })
  return mapeamentoRemocao
})()

PermissoesAcesso['buscarDependencias'] = (permissao, inclusao, positionsToIgnore, appendList, recursiveCall) => {
  if (!appendList) {
    appendList = []
  }
  const permissoesDependentes = inclusao ? MAPEAMENTO_DEPENDENCIAS_INCLUSAO[permissao.position] : MAPEAMENTO_DEPENDENCIAS_REMOCAO[permissao.position]
  if (permissoesDependentes && permissoesDependentes.length > 0) {
    for (let permissaoDependente of permissoesDependentes) {
      PermissoesAcesso.buscarDependencias(permissaoDependente, inclusao, null, appendList, true)
    }
  }
  if (recursiveCall) {
    appendList.push(permissao)
  } else {
    if (!positionsToIgnore) {
      positionsToIgnore = []
    }
    if (!inclusao) {
      appendList.reverse()
    }
    appendList = ArrayUtil.removerElementosDuplicados(appendList, 'position')
    for (let i = appendList.length - 1; i >= 0; i--) {
      if (positionsToIgnore.indexOf(appendList[i].position) >= 0) {
        appendList.splice(i, 1)
      }
    }
    return appendList
  }
}

export default PermissoesAcesso
