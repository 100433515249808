import React, { Component } from 'react'

import { Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PhoneIcon from '@material-ui/icons/Phone'
import moment from 'moment'
import ClienteDadosBasicosDialogPage from 'pages/erp/painel/cliente/ClienteDadosBasicosDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { clearBackableComponents, extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { setBannerJourney } from 'support/components/campanha/banner'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import BaseDialog from 'support/components/dialog/BaseDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import AtendimentoService from 'support/domain/atendimento/AtendimentoService'
import WhatsAppService from 'support/domain/whatsapp/WhatsAppService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import SimpleMessageTemplateUtil from 'support/util/SimpleMessageTemplateUtil'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

export const EscopoAgendamentoEnvioEnum = createEnum({
  INDIVIDUAL: { id: 1, descricao: 'Enviar o lembrete apenas deste agendamento' },
  AGRUPAMENTO_POR_DIA: { id: 2, descricao: 'Enviar o lembrete com todos os agendamentos marcados para este dia' },
  AGRUPAMENTO_POR_DIA_E_FUTUROS: { id: 3, descricao: 'Enviar o lembrete com todos os agendamentos marcados para este dia e futuros' }
})

const styles = (theme) => ({})

class EnviarLembreteManualDialog extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      paginaCarregada: false,
      escopoServicoEnvio: EscopoAgendamentoEnvioEnum.INDIVIDUAL,
      telefones: []
    }
    this.contentWithPreloadFunctions = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCliente', (props) => {
      this.contentWithPreloadFunctions.reload()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/atendimentos.buscarDadosParaEnvioManualLembrete',
      params: {
        idCliente: this.props.atendimento.venda.cliente.id,
        data: this.props.atendimento.data
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const telefones = response.data.telefones
        if (telefones && telefones.length > 0) {
          telefones.sort(function (a, b) {
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          })
        }
        let possuiMaisAtendimentosNoDia = false
        let possuiAtendimentosFuturos = false
        for (let atendimento of response.data.atendimentos) {
          if (atendimento.data === this.props.atendimento.data && atendimento.id !== this.props.atendimento.id) {
            possuiMaisAtendimentosNoDia = true
          }
          if (atendimento.data !== this.props.atendimento.data) {
            possuiAtendimentosFuturos = true
          }
          if (possuiMaisAtendimentosNoDia && possuiAtendimentosFuturos) {
            break
          }
        }
        this.setState({
          paginaCarregada: true,
          telefones: telefones,
          configuracaoLembrete: response.data.configuracaoLembrete,
          atendimentos: response.data.atendimentos,
          possuiAtendimentosFuturos: possuiAtendimentosFuturos,
          possuiMaisAtendimentosNoDia: possuiMaisAtendimentosNoDia
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getAtendimentosParaEnvio = () => {
    const { escopoServicoEnvio } = this.state

    const atendimentosParaEnvio = []
    if (escopoServicoEnvio.id === EscopoAgendamentoEnvioEnum.INDIVIDUAL.id) {
      for (let atendimento of this.state.atendimentos) {
        if (atendimento.id === this.props.atendimento.id) {
          atendimentosParaEnvio.push(atendimento)
          break
        }
      }
    } else if (escopoServicoEnvio.id === EscopoAgendamentoEnvioEnum.AGRUPAMENTO_POR_DIA.id) {
      for (let atendimento of this.state.atendimentos) {
        if (atendimento.data === this.props.atendimento.data) {
          atendimentosParaEnvio.push(atendimento)
        }
      }
    } else if (escopoServicoEnvio.id === EscopoAgendamentoEnvioEnum.AGRUPAMENTO_POR_DIA_E_FUTUROS.id) {
      for (let atendimento of this.state.atendimentos) {
        if (atendimento.data >= this.props.atendimento.data) {
          atendimentosParaEnvio.push(atendimento)
        }
      }
    }
    return atendimentosParaEnvio
  }

  generateMessage = (atendimentosParaEnvio) => {
    const { atendimento } = this.props
    const { configuracaoLembrete } = this.state
    const valuesMap = {}
    const agora = moment()
    const hoje = moment().startOf('day')

    if (agora.hour() >= 12 && agora.hour() <= 18) {
      valuesMap['[saudacao]'] = 'Boa tarde'
    } else if (agora.hour() >= 19 || agora.hour() <= 3) {
      valuesMap['[saudacao]'] = 'Boa noite'
    } else {
      valuesMap['[saudacao]'] = 'Bom dia'
    }

    const dataAtendimentoAsMoment = converterDataIntParaMoment(atendimento.data)

    const diferencaDias = dataAtendimentoAsMoment.startOf('day').diff(hoje, 'days')
    let descricaoData = ''
    if (diferencaDias === 0) {
      descricaoData = 'Hoje, '
    } else if (diferencaDias === 1) {
      descricaoData = 'Amanhã, '
    }
    descricaoData += dataAtendimentoAsMoment.format('dddd, DD [de] MMMM')

    /*if(atendimentosParaEnvio.length > 1){
			descricaoData += ", a partir das ";
			descricaoData += formatarHorarioInt(atendimento.horarioInicio);
		}*/

    valuesMap['[data-agendamento]'] = descricaoData

    valuesMap['[servicos]'] = ''
    valuesMap['[servicos-sem-profissional]'] = ''
    valuesMap['[servicos-com-data]'] = ''
    valuesMap['[servicos-com-preco]'] = ''
    valuesMap['[horarios-servicos]'] = ''

    for (let i = 0; i < atendimentosParaEnvio.length; i++) {
      const atendimentoParaEnvio = atendimentosParaEnvio[i]
      if (i > 0) {
        valuesMap['[servicos]'] += '\n'
        valuesMap['[servicos-sem-profissional]'] += '\n'
        valuesMap['[servicos-com-data]'] += '\n\n'
        valuesMap['[servicos-com-preco]'] += '\n\n'
        valuesMap['[horarios-servicos]'] += '\n'
      }

      let descricaoDatas = ''
      if (this.state.escopoServicoEnvio.id === EscopoAgendamentoEnvioEnum.AGRUPAMENTO_POR_DIA_E_FUTUROS.id) {
        descricaoDatas += converterDataIntParaMoment(atendimentoParaEnvio.data).format('DD/MM/YY - ddd') + ' - '
      }
      descricaoDatas += formatarHorarioInt(atendimentoParaEnvio.horarioInicio) + ' - '

      let descricaoPreco = formatarValorMonetario(atendimentoParaEnvio.venda.valorTotal)
      if (atendimentoParaEnvio.venda.clientePacoteItem) {
        descricaoPreco = 'Crédito de Pacote'
      }

      valuesMap['[servicos]'] += '_' + descricaoDatas + AtendimentoService.gerarDescricaoServico(atendimentoParaEnvio) + ' - ' + atendimentoParaEnvio.venda.colaborador.nome + '_'
      valuesMap['[servicos-sem-profissional]'] += '_' + descricaoDatas + AtendimentoService.gerarDescricaoServico(atendimentoParaEnvio) + '_'
      valuesMap['[servicos-com-data]'] +=
        '_' +
        converterDataIntParaMoment(atendimentoParaEnvio.data).format('DD/MM/YYYY - ddd') +
        ' - ' +
        formatarHorarioInt(atendimentoParaEnvio.horarioInicio) +
        ' - ' +
        AtendimentoService.gerarDescricaoServico(atendimentoParaEnvio) +
        ' - ' +
        atendimentoParaEnvio.venda.colaborador.nome +
        '_'
      valuesMap['[servicos-com-preco]'] +=
        '_' +
        converterDataIntParaMoment(atendimentoParaEnvio.data).format('DD/MM/YYYY - ddd') +
        ' - ' +
        formatarHorarioInt(atendimentoParaEnvio.horarioInicio) +
        ' - ' +
        AtendimentoService.gerarDescricaoServico(atendimentoParaEnvio) +
        ' - ' +
        atendimentoParaEnvio.venda.colaborador.nome +
        ' - ' +
        descricaoPreco +
        '_'
      valuesMap['[horarios-servicos]'] +=
        '_' + converterDataIntParaMoment(atendimentoParaEnvio.data).format('DD/MM/YYYY - ddd') + ' - ' + formatarHorarioInt(atendimentoParaEnvio.horarioInicio) + '_'
    }

    valuesMap['[nome-empresa]'] = getSessaoPainel().negocio.nome
    valuesMap['[nome-cliente]'] = atendimento.venda.cliente.nome
    valuesMap['[apelido-cliente]'] = atendimento.venda.cliente.apelido

    return SimpleMessageTemplateUtil.processTemplate(configuracaoLembrete.modeloMensagemManual, valuesMap)
  }

  abrirDialogConfirmacaoEnvio = (atendimentosParaEnvio) => {
    this.props.setShouldCloseParent(true)

    let mensagem = 'Deseja marcar que o lembrete '
    if (atendimentosParaEnvio.length === 1) {
      mensagem += 'deste agendamento '
    } else {
      mensagem += 'destes agendamentos '
    }
    mensagem += 'foi enviado?'

    const idsAtendimentos = []
    for (let atendimentoParaEnvio of atendimentosParaEnvio) {
      idsAtendimentos.push(atendimentoParaEnvio.id)
    }

    window.setTimeout(() => {
      openBackableDialog(EntityConfirmationDialog, {
        parent: this,
        shouldCloseParent: true,
        title: 'Confirmação',
        text: mensagem,
        call: {
          method: 'post',
          url: 'erp/atendimentos.marcarEnvioLembreteManual',
          contextoUsuario: 'erp',
          data: {
            idsAtendimentos: idsAtendimentos
          },
          onSuccess: () => {
            EventsManager.pub('AtendimentoConfirmacaoEnvioManualLembrete', { idsAtendimentos: idsAtendimentos })
          }
        }
      })
    }, 1000)
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, atendimento } = this.props
    const { telefones, escopoServicoEnvio, paginaCarregada } = this.state

    let content = null
    let actions = null

    if (paginaCarregada) {
      if (telefones && telefones.length) {
        content = (
          <React.Fragment>
            <RadioGroup
              style={{ marginTop: -4 }}
              name="envio"
              className={classes.group}
              value={escopoServicoEnvio.id}
              onChange={(event) => {
                this.setState({
                  escopoServicoEnvio: EscopoAgendamentoEnvioEnum.getById(parseInt(event.target.value))
                })
              }}
            >
              {EscopoAgendamentoEnvioEnum.values()
                .filter((opcao) => {
                  if (this.state.atendimentos && this.state.atendimentos.length > 0) {
                    if (opcao.id === EscopoAgendamentoEnvioEnum.INDIVIDUAL.id) {
                      return true
                    }
                    if (opcao.id === EscopoAgendamentoEnvioEnum.AGRUPAMENTO_POR_DIA.id && this.state.possuiMaisAtendimentosNoDia) {
                      return true
                    }
                    if (opcao.id === EscopoAgendamentoEnvioEnum.AGRUPAMENTO_POR_DIA_E_FUTUROS.id && this.state.possuiAtendimentosFuturos) {
                      return true
                    }
                  }
                  return false
                })
                .map((opcao, index) => (
                  <FormControlLabel key={index} value={opcao.id} style={{ marginBottom: 8 }} control={<Radio />} label={opcao.descricao} />
                ))}
            </RadioGroup>
            <Typography variant="body2" style={{ marginTop: 16, marginBottom: 16 }}>
              Enviar para o telefone:
            </Typography>
            {telefones.map((telefone) => {
              const numeroDestino = telefone.codigoDiscagemPais + telefone.numero
              const atendimentosParaEnvio = this.getAtendimentosParaEnvio()
              const messagem = this.generateMessage(atendimentosParaEnvio)
              const linkProps = WhatsAppService.generateClickToChatLinkProps({
                phone: numeroDestino,
                message: messagem,
                onClick: () => {
                  setBannerJourney('lembrete')
                  this.abrirDialogConfirmacaoEnvio(atendimentosParaEnvio)
                }
              })

              return (
                <MenuItem {...linkProps} key={telefone.id} component={Link}>
                  <Grid container style={{ width: '100%' }} justify="center" alignItems="center">
                    <Grid item>
                      <PhoneIcon style={{ display: 'block' }} />
                    </Grid>
                    <Grid item xs style={{ marginLeft: 12 }}>
                      {telefone.numero}
                    </Grid>
                    <Grid item align="right" style={{ marginRight: -8 }}>
                      <NavigateNextIcon style={{ display: 'block' }} />
                    </Grid>
                  </Grid>
                </MenuItem>
              )
            })}
          </React.Fragment>
        )
      } else {
        content = (
          <React.Fragment>
            <Typography variant="body1">Este cliente não possui nenhum telefone de contato cadastrado.</Typography>
          </React.Fragment>
        )
        actions = (
          <Button
            color="secondary"
            onClick={() => {
              openBackableDialog(ClienteDadosBasicosDialogPage, { idCliente: atendimento.venda.cliente.id })
            }}
          >
            CADASTRAR TELEFONES
          </Button>
        )
      }
    }

    return (
      <BaseDialog
        {...dialogProps}
        title={atendimento.venda.cliente.apelido}
        actions={actions}
        onPreClose={() => {
          setBannerJourney('lembrete')
          clearBackableComponents()
        }}
        maxWidth={510}
        hasCloseButton={true}
      >
        <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarMain}>
          {() => content}
        </ContentWithPreload>
      </BaseDialog>
    )
  }

  componentWillUnmount() {
    EventsManager.pub('ManipulacaoColaborador')
    this.eventsManager.unsubscribe()
  }
}

EnviarLembreteManualDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EnviarLembreteManualDialog)
