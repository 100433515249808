import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import classNames from 'classnames'
import logo from 'img/logo_salao99_apresentacao.png'
import PropTypes from 'prop-types'
import { clearBackableComponents } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import MainLoaderScreen from 'support/components/mainloaderscreen/MainLoaderScreen'

export const TOOLBAR_EXPANDED_CLASS_NAME = 'toolbarExpanded'

const styles = (theme) => ({
  logoCentralWidth: {
    width: 244,
    maxWidth: '70%',
    [theme.breakpoints.up(342)]: {
      maxWidth: '60%'
    },
    [theme.breakpoints.up(380)]: {
      maxWidth: '52%'
    },
    [theme.breakpoints.up(410)]: {
      maxWidth: '45%'
    },
    [theme.breakpoints.up(500)]: {
      maxWidth: '40%'
    },
    [theme.breakpoints.up(540)]: {
      maxWidth: '35%'
    }
  },
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    width: '100%'
  }
})

class Panel extends Component {
  componentUnmounted = false
  tentandoAtualizarSessao = false
  DURACAO_MINIMA_APRESENTACAO = DeviceIdentifier.isNativeApp() ? 0 : 1200
  inicioCarregamentoPagina = 0

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.localPreferences = LocalPreferences.new('global', null)
    this.state = {
      toolbarExpanded: this.localPreferences.getPreference('Panel_ToolbarExpanded', true),
      desktopDrawerOpen: this.localPreferences.getPreference('Panel_DesktopDrawerOpen', true),
      mobileDrawerOpen: false,
      painelCarregado: this.props.hasSyncLoad === false ? true : false,
      panelPageFullScreenMode: false
    }
    this.setupSubscribes()
    this.mainLoaderScreenFunctionsMap = {}
  }

  componentDidMount = () => {
    if (this.props.functionsMap) {
      this.props.functionsMap['notificarPainelCarregado'] = this.notificarPainelCarregado
      this.props.functionsMap['notificarErroCarregamentoPainel'] = this.notificarErroCarregamentoPainel
      this.props.functionsMap['alterarRota'] = this.alterarRota
    }
    if (this.props.hasSyncLoad !== false) {
      this.carregarPainel()
    } else {
      this.props.carregarPainel()
    }
  }

  componentDidUpdate() {
    const matchUrl = document.location.href.match(this.props.regexFullScreenPages)
    !matchUrl && this.removeFullScreenMode()
  }

  addFullScreenMode = () => {
    const { panelPageFullScreenMode } = this.state

    if (!panelPageFullScreenMode) {
      this.setState({ panelPageFullScreenMode: true })
      document.body.classList.add('height100')
    }
  }

  removeFullScreenMode = () => {
    const { panelPageFullScreenMode } = this.state

    if (panelPageFullScreenMode) {
      this.setState({ panelPageFullScreenMode: false })
      document.body.classList.remove('height100')
    }
  }

  carregarPainel = () => {
    this.inicioCarregamentoPagina = new Date().getTime()
    this.mainLoaderScreenFunctionsMap.notifyStart()
    this.props.carregarPainel()
  }

  notificarPainelCarregado = (props) => {
    if (props.rota) {
      this.alterarRota(props.rota, true)
    }

    if (this.props.hasSyncLoad !== false) {
      let delay = this.DURACAO_MINIMA_APRESENTACAO - (new Date().getTime() - this.inicioCarregamentoPagina)
      if (delay < 0) {
        delay = 0
      }
      window.setTimeout(() => {
        this.setState({ painelCarregado: true })
        this.mainLoaderScreenFunctionsMap.notifyLoaded()
      }, delay)
    }
  }

  notificarErroCarregamentoPainel = (props) => {
    this.mainLoaderScreenFunctionsMap.notifyError({
      message: props.mensagem
    })
  }

  atualizarSessao = (params, tentativa) => {
    if (!params.force) {
      if (this.tentandoAtualizarSessao) {
        return
      }

      if (this.componentUnmounted) {
        return
      }
    }

    this.tentandoAtualizarSessao = true

    this.props.atualizarSessao({
      onSuccess: () => {
        this.tentandoAtualizarSessao = false

        if (params.callback) {
          params.callback()
        }
      },
      onError: () => {
        this.tentandoAtualizarSessao = false

        let tempoProximaTentativa = null
        if (tentativa === undefined) {
          tentativa = 0
          tempoProximaTentativa = 100
        } else if (tentativa <= 1) {
          tempoProximaTentativa = 500
        } else if (tentativa <= 3) {
          tempoProximaTentativa = 1500
        } else {
          tempoProximaTentativa = 3000
        }

        tentativa++

        if (!params.numeroMaximoTentativas || tentativa < params.numeroMaximoTentativas) {
          if (tempoProximaTentativa !== null) {
            window.setTimeout(() => {
              this.atualizarSessao(params, tentativa)
            }, tempoProximaTentativa)
          }
        }
      }
    })
  }

  alterarRota = (pathname, replace) => {
    if (replace) {
      this.props.history.replace(pathname)
    } else {
      this.props.history.push(pathname)
    }
  }

  changeToolbarExpanded = (expanded) => {
    this.localPreferences.setPreference('Panel_ToolbarExpanded', expanded)
    this.setState({ toolbarExpanded: expanded })
  }

  changeDrawerMobileOpen = (open) => {
    this.setState({ mobileDrawerOpen: open })
  }

  handleDrawerMobileToggle = () => {
    this.setState({ mobileDrawerOpen: !this.state.mobileDrawerOpen })
  }

  changeDesktopDrawerOpen = (open) => {
    this.localPreferences.setPreference('Panel_DesktopDrawerOpen', open)
    this.setState({ desktopDrawerOpen: open })
  }

  handleDesktopDrawerToggle = () => {
    const open = !this.state.desktopDrawerOpen
    this.localPreferences.setPreference('Panel_DesktopDrawerOpen', open)
    this.setState({ desktopDrawerOpen: open })
  }

  setupSubscribes() {
    this.eventsManager.sub('FalhaAutenticacao', (props) => {
      this.props.handleLogout()
    })
    this.eventsManager.sub('AtualizarSessao', (props) => {
      if (!props) {
        props = {}
      }
      if (!props.numeroMaximoTentativas) {
        props.numeroMaximoTentativas = 6
      }
      this.atualizarSessao(props, null)
    })

    this.eventsManager.sub('LimparSessao', (props) => {
      this.props.limparSessao(props)
    })

    this.eventsManager.sub('NavegarPaginaPainel', (props) => {
      clearBackableComponents()
      if (this.state.mobileDrawerOpen !== false) {
        this.setState({ mobileDrawerOpen: false })
      }
      window.setTimeout(() => {
        this.props.navegarPaginaPainel(props)
      }, 0)
    })

    this.eventsManager.sub('PanelFullScreenMode', (props) => (props ? this.addFullScreenMode() : this.removeFullScreenMode()))
  }

  render() {
    const { regexWhiteBackgroundPages } = this.props

    this.hasContainerBackground = true
    if (regexWhiteBackgroundPages && document.location.href.match(regexWhiteBackgroundPages)) {
      this.hasContainerBackground = false
    }

    const { classes } = this.props

    let isMenuRetracted = false
    if (isWidthUp('sm', this.props.width) && !this.state.desktopDrawerOpen) {
      isMenuRetracted = true
    }

    const renderDrawerAndPagesProps = {
      desktopDrawerOpen: this.state.desktopDrawerOpen,
      toolbarExpanded: this.state.toolbarExpanded,
      changeToolbarExpanded: this.changeToolbarExpanded,
      panelPageFullScreenMode: this.state.panelPageFullScreenMode,
      changeDesktopDrawerOpen: this.changeDesktopDrawerOpen,
      handleDesktopDrawerToggle: this.handleDesktopDrawerToggle,
      changeDrawerMobileOpen: this.changeDrawerMobileOpen,
      handleDrawerMobileToggle: this.handleDrawerMobileToggle,
      paginaCarregada: this.state.painelCarregado,
      mobileDrawerOpen: this.state.mobileDrawerOpen,
      isMenuRetracted: isMenuRetracted,
      handleLogout: this.props.handleLogout,
      changePanelContainerTheme: this.props.changePanelContainerTheme
    }

    const renderDrawerProps = {
      ...renderDrawerAndPagesProps
    }

    const renderPagesProps = {
      ...renderDrawerAndPagesProps
    }
    return (
      <React.Fragment>
        <div className="height100">
          {this.props.hasSyncLoad !== false && (
            <MainLoaderScreen
              image={<img src={logo} className={classes.logoCentralWidth} alt="Salão99" />}
              loadFunction={this.carregarPainel}
              functionsMap={this.mainLoaderScreenFunctionsMap}
            />
          )}

          <div className={classNames(classes.root, 'height100')}>
            {this.props.renderDrawer(renderDrawerProps)}
            {this.props.renderPages(renderPagesProps)}
          </div>
        </div>
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    this.componentUnmounted = true
    this.eventsManager.unsubscribe()
  }
}

Panel.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(Panel))
