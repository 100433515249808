import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import InterfaceChatSuporte, { VisaoEnum } from 'support/components/chat/InterfaceChatSuporte'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PanelPage from 'support/components/panel/PanelPage'

const HORARIOS_ATENDIMENTO = [
  { dia: 1, horarioInicio: 800, horarioTermino: 2200 },
  { dia: 2, horarioInicio: 800, horarioTermino: 2200 },
  { dia: 3, horarioInicio: 800, horarioTermino: 2200 },
  { dia: 4, horarioInicio: 800, horarioTermino: 2200 },
  { dia: 5, horarioInicio: 800, horarioTermino: 2200 },
  { dia: 6, horarioInicio: 1000, horarioTermino: 1900 }
]

const styles = (theme) => ({})

class SuporteChatPanelPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      excecoesDisponibilidade: []
    }
    this.carregarMainFunctionsMap = {}
  }

  componentDidMount() {
    this.recarregarExcecoesDisponibilidadeInterval = window.setInterval(() => {
      this.carregarMainFunctionsMap.reloadInBackground()
    }, 1000 * 60 * 10)
    EventsManager.pub('PanelFullScreenMode', true)
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/chat.buscarExcecoesDisponibilidade',
      params: {},
      onSuccess: (response) => {
        this.setState({
          excecoesDisponibilidade: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      },
      requerAutorizacao: true
    })
  }

  render() {
    //console.log("RENDER","SuporteChatPanelPage");

    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} title="Suporte - Chat">
        <ContentWithPreload functionsMap={this.carregarMainFunctionsMap} loadFunction={this.carregarMain}>
          {() => {
            return (
              <InterfaceChatSuporte
                maxWidth={900}
                visao={VisaoEnum.USUARIO}
                permissaoAcessarChat={true}
                permissaoEnviarMensagemChat={true}
                containerPadding="basic"
                controlarHorariosAtendimento={true}
                horariosAtendimento={HORARIOS_ATENDIMENTO}
                excecoesDisponibilidade={this.state.excecoesDisponibilidade}
              />
            )
          }}
        </ContentWithPreload>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    window.clearInterval(this.recarregarExcecoesDisponibilidadeInterval)
  }
}

SuporteChatPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SuporteChatPanelPage)
