import MotivoMovimentacaoEstoqueEnum from 'support/domain/inventario/MotivoMovimentacaoEstoqueEnum'
import VendaService from 'support/domain/venda/VendaService'

function getDescricaoMotivoMovimentacaoEstoque(movimentacaoEstoque) {
  let motivo = ''
  if (movimentacaoEstoque.motivo.id === MotivoMovimentacaoEstoqueEnum.ATENDIMENTO.id) {
    motivo = movimentacaoEstoque.venda.cliente.apelido.split(' ')[0] + ' - ' + VendaService.getDescricao(movimentacaoEstoque.venda)
  } else if (movimentacaoEstoque.motivo.id === MotivoMovimentacaoEstoqueEnum.CANCELAMENTO_ATENDIMENTO.id) {
    motivo = 'Cancelamento: ' + movimentacaoEstoque.venda.cliente.apelido.split(' ')[0] + ' - ' + VendaService.getDescricao(movimentacaoEstoque.venda)
  } else if (movimentacaoEstoque.motivo.id === MotivoMovimentacaoEstoqueEnum.VENDA.id) {
    motivo = movimentacaoEstoque.venda.cliente.apelido + ' - Venda de produto'
  } else if (movimentacaoEstoque.motivo.id === MotivoMovimentacaoEstoqueEnum.CANCELAMENTO_VENDA.id) {
    motivo = 'Cancelamento: ' + movimentacaoEstoque.venda.cliente.apelido + ' - Venda de produto'
  } else {
    motivo = MotivoMovimentacaoEstoqueEnum.getById(movimentacaoEstoque.motivo.id).descricao
  }

  return motivo
}

const functionsObject = {
  getDescricaoMotivoMovimentacaoEstoque: getDescricaoMotivoMovimentacaoEstoque
}

export default functionsObject
