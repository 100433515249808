import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import MovimentacaoEstoqueDialogPage from 'pages/erp/painel/inventario/MovimentacaoEstoqueDialogPage'
import ProdutoPhotoPicker from 'pages/erp/painel/inventario/picker/ProdutoPhotoPicker'
import ProdutoDadosBasicosDialogPage from 'pages/erp/painel/inventario/ProdutoDadosBasicosDialogPage'
import TabCompras from 'pages/erp/painel/inventario/TabCompras'
import TabProdutoGeral from 'pages/erp/painel/inventario/TabProdutoGeral'
import TabProdutoMovimentacoesEstoque from 'pages/erp/painel/inventario/TabProdutoMovimentacoesEstoque'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import Subject from 'support/components/page/subject/Subject'
import Tab from 'support/components/tab/Tab'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import MotivoMovimentacaoEstoqueEnum from 'support/domain/inventario/MotivoMovimentacaoEstoqueEnum'
import TipoMovimentacaoEstoqueEnum from 'support/domain/inventario/TipoMovimentacaoEstoqueEnum'
import ImageUtil from 'support/util/ImageUtil'

const styles = (theme) => ({})

class ProdutoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      produto: null,
      title: 'Produto',
      tabValue: 0,
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/inventario.buscarProdutoPorId',
      params: {
        id: this.props.idProduto
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const produto = response.data

        const toolbarActions = {
          desktopItemsShow: 1,
          mobileItemsShow: 1,
          actions: []
        }

        if (produto.controleEstoque) {
          toolbarActions.actions.push({
            label: 'Editar Produto',
            handleAction: this.handlerEditar
          })
          toolbarActions.actions.push({
            label: 'Entrada de Estoque',
            handleAction: this.abrirPainelMovimentacaoEstoqueAdicionar
          })
          toolbarActions.actions.push({
            label: 'Saída de Estoque',
            handleAction: this.abrirPainelMovimentacaoEstoqueRemover
          })
        } else {
          toolbarActions.actions.push({
            label: 'Editar',
            handleAction: this.handlerEditar
          })
        }

        this.setState({
          produto: produto,
          toolbarActions: toolbarActions
        })

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoProduto', (props) => {
      if (props !== undefined && props.produto !== undefined) {
        this.setState({ produto: props.produto })
      } else {
        this.loaderFunctionsMap.reload()
      }
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerEditar = () => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])) {
      openBackableDialog(ProdutoDadosBasicosDialogPage, { idProduto: this.state.produto.id })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  abrirPainelMovimentacaoEstoqueAdicionar = () => {
    if (verificarAutorizacao([PA.PODE_MOVIMENTAR_ESTOQUE])) {
      openBackableDialog(MovimentacaoEstoqueDialogPage, {
        produto: this.state.produto,
        tipoMovimentacao: TipoMovimentacaoEstoqueEnum.ENTRADA
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  abrirPainelMovimentacaoEstoqueRemover = () => {
    if (verificarAutorizacao([PA.PODE_MOVIMENTAR_ESTOQUE])) {
      openBackableDialog(MovimentacaoEstoqueDialogPage, {
        produto: this.state.produto,
        tipoMovimentacao: TipoMovimentacaoEstoqueEnum.SAIDA,
        motivoDefaultValue: MotivoMovimentacaoEstoqueEnum.UTILIZACAO
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { produto, tabValue } = this.state

    const subjectProps = {}
    let textoEstoque = null
    let textoVendasSemEstoque = null
    if (produto) {
      let existeUnidadeMedida = false
      textoEstoque = ''
      const textoUnidade = produto.estoqueAtualQuantidade === 1 || produto.estoqueAtualQuantidade === -1 ? ' unidade' : ' unidades'
      if (produto.unidadeMedidaEmbalagem) {
        existeUnidadeMedida = true
        textoEstoque += produto.estoqueAtualUnidadePersonalizada + ' ' + produto.unidadeMedidaEmbalagem + ' ('
      }
      textoEstoque += produto.estoqueAtualQuantidade + textoUnidade
      if (existeUnidadeMedida) {
        textoEstoque += ')'
      }

      if (produto.estoqueAtualQuantidade < 0) {
        textoVendasSemEstoque = 'Vendas sem estoque: ' + textoEstoque
        textoEstoque = 'Estoque: 0'
      } else {
        textoEstoque = 'Estoque: ' + textoEstoque
      }
    }

    if (produto) {
      subjectProps.infos = [{ type: 'text', marginTop: 'small', text: <span style={{ fontSize: 14 }}>{produto.categoria.nome}</span> }]
      if (produto.controleEstoque) {
        subjectProps.infos.push({ type: 'text', marginTop: false, text: textoEstoque })
        if (textoVendasSemEstoque) {
          subjectProps.infos.push({ type: 'text', marginTop: false, text: textoVendasSemEstoque })
        }
      }
    }

    let variacoesFoto = null
    if (produto) {
      variacoesFoto = ImageUtil.extrairVariacoesImagem(produto.foto)
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={560}
        pageType="basicEntity"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => (
          <ContentWithPreload functionsMap={this.loaderFunctionsMap} loadContentPadding={true} loadFunction={this.carregarMain}>
            {() => (
              <div>
                <Subject
                  photo={<ProdutoPhotoPicker produto={produto} photo1x={variacoesFoto.get(0)} photo2x={variacoesFoto.get(0)} framedImage={true} width={148} />}
                  title={produto.nome}
                  {...subjectProps}
                />

                <Tab
                  value={tabValue}
                  onChange={(event, value) => {
                    this.setState({ tabValue: value })
                  }}
                  tabs={[{ label: 'Geral' }, { label: 'Compras' }, { label: 'Histórico' }]}
                ></Tab>
                <div>
                  {tabValue === 0 && <TabProdutoGeral produto={this.state.produto} />}
                  {tabValue === 1 && <TabCompras produto={this.state.produto} scrollElement={dialogContentProps.scrollContainerRef.current} />}
                  {tabValue === 2 && <TabProdutoMovimentacoesEstoque produto={this.state.produto} scrollElement={dialogContentProps.scrollContainerRef.current} />}
                </div>
              </div>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

ProdutoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProdutoDialogPage)
