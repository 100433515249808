import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    textAlign: 'center'
  },
  padding: {
    padding: theme.spacing(4)
  },
  smallPadding: {
    padding: theme.spacing(2)
  },
  icon: {
    fontSize: 122,
    color: '#eee',
    marginBottom: 10
  },
  button: {
    marginTop: 8
  }
})

class EmptyListContent extends Component {
  render() {
    const { classes, show, text, footerText, icon, loaded, loading, items, padding, align } = this.props

    if (loaded === true && loading === false && (text || this.props.creationFunction) && (items === null || items.length === 0)) {
      let button = null
      if (this.props.textButton !== undefined) {
        button = (
          <Button className={classes.button} variant="outlined" color="primary" type="button" onClick={() => this.props.creationFunction()}>
            {this.props.textButton}
          </Button>
        )
      }

      let iconContent = null
      if (icon === 'search') {
        iconContent = <SearchIcon className={classes.icon} />
      } else if (icon === 'add') {
        iconContent = <AddCircleOutlineIcon className={classes.icon} />
      }

      const containerClasses = [classes.root]
      if (padding === true) {
        containerClasses.push(classes.padding)
      } else if (padding === 'small') {
        containerClasses.push(classes.smallPadding)
      }

      const style = {}
      if (align) {
        style.textAlign = align
      }

      let content = null
      if (show === undefined || show) {
        content = (
          <div className={classNames(containerClasses)} style={style}>
            {iconContent}
            <Typography variant="body2">{text}</Typography>
            {button}
            {footerText && (
              <Typography variant="body2" style={{ marginTop: 8, fontSize: 12 }}>
                {footerText}
              </Typography>
            )}
          </div>
        )
      }

      return content
    }

    return []
  }
}

EmptyListContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EmptyListContent)
