import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    marginTop: 28,
    marginBottom: 24
  },
  gridIcon: {
    '& svg': {
      color: theme.palette.primaryStrong.main,
      display: 'block'
    }
  },
  title: {
    color: theme.palette.primaryStrong.main
  },
  titleWithIcon: {
    marginLeft: 12
  }
})

class SectionSeparator extends Component {
  render() {
    const { classes, icon } = this.props

    return (
      <Grid className={classes.root} container direction="row" justify="flex-start" alignItems="center">
        {icon && (
          <Grid item className={classes.gridIcon}>
            {icon}
          </Grid>
        )}
        <Grid item>
          <Typography className={classNames(classes.title, icon ? classes.titleWithIcon : null)} variant="body1" color="secondary">
            {this.props.title}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

SectionSeparator.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SectionSeparator)
