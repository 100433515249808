// Urls
export const CONTRATEI_URL = 'https://docs.google.com/forms/d/e/1FAIpQLScTTT_Mq1-_x96dCOCE3vzV9l01Rf1wycaiuT1oFZ2-AG5lZg/viewform'
export const JA_TINHA_URL = 'https://forms.gle/zk5fWE64EaWbENaq6'
export const CANCELAR_INTEGRACAO = 'https://www.mercadopago.com.br/account/security/applications/connections'
export const CONTRATAR_MAQUININHA = `${process.env.REACT_APP_MFE_ADDRESS}/pagamento-integrado`

//Images
export const LOGO_SALAO_MERCADO_PAGO = '/enterprise/images/logo-salao-mercado-pago.png'
export const POINT_SMART_IMAGE = '/enterprise/images/maquininha-smart.png'
export const POINT_PRO2_IMAGE = '/enterprise/images/maquininha-pro2.png'

// Colors
export const iconColor = '#00325F'
