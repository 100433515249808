function generateBackgroundLines(height, border, mainColor, borderColor) {
  const finalHeight = height * 2
  const size1 = ((border / finalHeight) * 100).toFixed(2)
  const size2 = ((border / finalHeight) * 100 + 50).toFixed(2)

  return {
    backgroundSize: finalHeight + 'px ' + finalHeight + 'px',
    backgroundImage:
      'linear-gradient(0deg, ' +
      borderColor +
      ' ' +
      size1 +
      '%, ' +
      mainColor +
      ' ' +
      size1 +
      '%, ' +
      mainColor +
      ' 50%, ' +
      borderColor +
      ' 50%, ' +
      borderColor +
      ' ' +
      size2 +
      '%, ' +
      mainColor +
      ' ' +
      size2 +
      '%, ' +
      mainColor +
      ' 100%)'
  }
}

const functionsObject = {
  generateBackgroundLines: generateBackgroundLines
}

export default functionsObject
