import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  USO: { id: 1 },
  TRANSFERENCIA_SERVICO_ORIGEM: { id: 2 },
  CANCELAMENTO: { id: 3 },
  COMPRA: { id: 4 },
  TRANSFERENCIA_SERVICO_DESTINO: { id: 5 }
})

export default enumObject
