import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorPercentual } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaPeriodo: {
    maxWidth: 100,
    minWidth: 100
  }
})

class IndicadoresChurnsSalao99DialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      lista: [],
      dataInicial: moment().subtract(1, 'months').startOf('month'),
      dataFinal: moment().subtract(1, 'months').endOf('month')
    }
    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.mes = item.mes
    content.quantidade = item.quantidade
    content.taxa = formatarValorPercentual((item.quantidade / this.state.totalNegociosAtivosPagantes) * 100)

    return content
  }

  getRequestParameters = () => {
    const { dataInicial, dataFinal } = this.state
    return {
      dataInicial: converterMomentParaDataInt(dataInicial),
      dataFinal: converterMomentParaDataInt(dataFinal)
    }
  }

  getLoaderKey = () => {
    const { dataInicial, dataFinal } = this.state

    let key = 'loader'

    if (dataInicial) {
      key += '-i' + dataInicial
    }
    if (dataFinal) {
      key += '-f' + dataFinal
    }

    return key
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/relatorios.buscarTotalNegociosAtivosPagantesSalao99',
      params: this.getRequestParameters(),
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({ totalNegociosAtivosPagantes: response.data })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { dataInicial, dataFinal, totalNegociosAtivosPagantes } = this.state

    let totalChurn = 0

    for (let item of this.state.lista) {
      totalChurn += item.quantidade
    }

    return (
      <DialogPage
        {...dialogProps}
        title="Churn"
        align="center"
        contentMaxWidth={500}
        toolbarActions={{
          actions: [
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.loaderFunctionsMap.load()
              }
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <ContentWithPreload loadFunction={this.carregarMain} key={this.getLoaderKey()}>
            {() => (
              <React.Fragment>
                <DateRangeNavigator
                  expanded={false}
                  defaultValue={{
                    tipoIntervalo: 'mes',
                    dataInicial: dataInicial,
                    dataFinal: dataFinal
                  }}
                  onChange={(data) => {
                    this.setState({
                      dataInicial: data.dataInicial,
                      dataFinal: data.dataFinal
                    })
                  }}
                />

                <br />

                <VirtualizedResponsiveTable
                  scrollElement={dialogContentProps.scrollContainerRef.current}
                  loaderFunctionsMap={this.loaderFunctionsMap}
                  contextoUsuario="admin"
                  endpoint="admin/relatorios.buscarIndicadoresChurnSalao99"
                  getRequestParametersFunction={this.getRequestParameters}
                  items={this.state.lista}
                  loadTrackingFunction={(data) => {
                    if (data.status === 'loading') {
                      if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                        this.setState({ lista: [] })
                      }
                    } else if (data.status === 'loaded') {
                      const state = { lista: data.items }
                      this.setState(state)
                    }
                  }}
                  largeRenderProps={{
                    columns: [
                      { label: 'Meses', horizontalPadding: 'small', props: { xs: true } },
                      { label: 'Desistências', horizontalPadding: 'small', props: { xs: true } },
                      { label: 'Taxa', horizontalPadding: 'small', props: { xs: true } }
                    ],
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('large', item)
                      return {
                        itemData: [content.mes, content.quantidade, content.taxa]
                      }
                    }
                  }}
                  smallRenderProps={{
                    rowHeight: 150,
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('small', item)
                      return {
                        itemData: (
                          <React.Fragment>
                            <Typography variant="body2" noWrap={true}>
                              Quantidade Meses: {content.mes}
                            </Typography>
                            <Typography variant="body2">
                              Quantidade Churn: {content.quantidade} ({content.taxa})
                            </Typography>
                          </React.Fragment>
                        )
                      }
                    }
                  }}
                  emptyListProps={{
                    text: 'Nenhum registro encontrado'
                  }}
                />

                {this.state.lista.length > 0 && (
                  <FloatContent container="paper" context="DialogPage" type="tableSummary">
                    <Typography variant="body2">
                      Total Negócios: <b>{totalNegociosAtivosPagantes}</b>
                      <br />
                      Total Desistências: <b>{totalChurn}</b>
                      <br />
                      Taxa de Desistências: <b>{formatarValorPercentual((totalChurn / totalNegociosAtivosPagantes) * 100)}</b>
                      <br />
                    </Typography>
                  </FloatContent>
                )}
              </React.Fragment>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

IndicadoresChurnsSalao99DialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IndicadoresChurnsSalao99DialogPage)
