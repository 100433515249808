import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { createEnum } from 'support/util/EnumUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

export const OrdenacaoEnum = createEnum({
  CLIENTE_CRESCENTE: { id: 1, descricao: 'Ordenar por Cliente (A-Z)' },
  CLIENTE_DECRESCENTE: { id: 2, descricao: 'Ordenar por Cliente (Z-A)' },
  VALOR_CRESCENTE: { id: 3, descricao: 'Ordenar por Valor (Menor Para Maior)' },
  VALOR_DECRESCENTE: { id: 4, descricao: 'Ordenar por Valor (Maior Para Menor)' }
})

class DetalhesCreditosClientesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false,
      ordenacao: OrdenacaoEnum.CLIENTE_CRESCENTE
    }

    this.dataExporterFunctionsMap = {}
  }

  getRequestParameters = () => {
    return {
      idOrdenacao: this.state.ordenacao ? this.state.ordenacao.id : null
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.valor = formatarValorMonetario(item.valor, { prefix: false })
    content.descricao = item.descricao

    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Detalhamento de Créditos Disponíveis',
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarCreditosDisponiveis',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ordenacao } = this.state

    let valorTotalCredito = 0

    for (let item of this.state.lista) {
      valorTotalCredito = valorTotalCredito + item.valor
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} align="center" title="Detalhamento" contentMaxWidth={800} ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Créditos</Typography>

              <Grid container direction="column" justify="center" alignItems="center" style={{ marginTop: 10 }}>
                <Grid item>
                  <InputSelect
                    customVariant="naked"
                    nakedLeftPadding={false}
                    value={this.state.ordenacao.id}
                    marginTop={-6}
                    fullWidth={false}
                    onChange={(event) => {
                      const ordenacao = OrdenacaoEnum.getById(parseInt(event.target.value, 10))
                      this.setState({ ordenacao: ordenacao })
                    }}
                  >
                    {OrdenacaoEnum.values().map((ordenacao) => (
                      <MenuItem key={ordenacao.id} value={ordenacao.id}>
                        {ordenacao.descricao}
                      </MenuItem>
                    ))}
                  </InputSelect>
                </Grid>
              </Grid>
            </FloatContent>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              key={ordenacao.id}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              itemsPerRequest={30}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarCreditosClientes"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Cliente', className: classes.colunaDescricao, props: { xs: true }, horizontalPadding: 'small' },
                  { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (comissao, index) => {
                  const content = this.getRenderContent('large', comissao)

                  return {
                    itemData: [content.descricao, content.valor]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 85,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body2">
                          <b>{content.descricao}</b>
                        </Typography>
                        <Typography variant="body2">R$ {content.valor}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Não existem clientes com crédito'
              }}
            />

            {this.state.lista.length > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Total Créditos: <b>{formatarValorMonetario(valorTotalCredito)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

DetalhesCreditosClientesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesCreditosClientesDialogPage)
