import { createInlineValuesObject } from 'support/components/inlinevalues/InlineValues'
import ArrayUtil from 'support/util/ArrayUtil'

const GRUPO_ATENDIMENTOS = 'Atendimentos'
const GRUPO_VENDAS = 'Vendas e Financeiro'
const GRUPO_NF = 'Notas Fiscais'
const GRUPO_COMISSOES = 'Remunerações e Vales'
const GRUPO_PRODUTOS = 'Produtos e Estoque'
const GRUPO_CLIENTES = 'Clientes'
const GRUPO_COLABORADORES = 'Colaboradores'
const GRUPO_SERVICOS = 'Serviços'
const GRUPO_DESPESAS = 'Despesas'
const GRUPO_CONFIGURACOES = 'Configuracões'
const GRUPO_CONTROLE_CAIXA = 'Controle de Caixa'
const GRUPO_FLUXO_CAIXA = 'Fluxo de Caixa'
const GRUPO_RELATORIOS = 'Relatórios'
const GRUPO_PACOTES = 'Pacotes'
const GRUPO_CONTRATACAO_PLANOS_E_SERVICOS = 'Contratação de Planos e Serviços'

const PermissoesAcesso = createInlineValuesObject({
  ACESSO_SISTEMA: { position: 0, descricao: 'Acesso ao sistema' },

  PODE_ACESSAR_PROPRIO_CALENDARIO: { position: 1, grupo: GRUPO_ATENDIMENTOS, descricao: 'Pode acessar o próprio calendário' },
  PODE_ACESSAR_TODOS_CALENDARIOS: { position: 2, grupo: GRUPO_ATENDIMENTOS, descricao: 'Pode acessar o calendário de outros profissionais' },
  PODE_MARCAR_ATENDIMENTO: { position: 3, grupo: GRUPO_ATENDIMENTOS, descricao: 'Pode marcar atendimentos (agendados ou por ordem de chegada)' },
  PODE_REMOVER_ATENDIMENTO: { position: 35, grupo: GRUPO_ATENDIMENTOS, descricao: 'Pode remover atendimentos (agendados ou por ordem de chegada)' },
  PODE_CANCELAR_ATENDIMENTO: { position: 36, grupo: GRUPO_ATENDIMENTOS, descricao: 'Pode cancelar atendimentos com comandas geradas' },
  PODE_VISUALIZAR_OBSERVACAO: { position: 73, grupo: GRUPO_ATENDIMENTOS, descricao: 'Pode visualizar e alterar as observações dos atendimentos' },
  PODE_PERSISTIR_PROPRIO_BLOQUEIO_HORARIO: { position: 81, grupo: GRUPO_ATENDIMENTOS, descricao: 'Pode criar e alterar os seus próprios bloqueios de horários' },
  PODE_PERSISTIR_BLOQUEIOS_HORARIOS_OUTROS_COLABORADORES: {
    position: 82,
    grupo: GRUPO_ATENDIMENTOS,
    descricao: 'Pode criar e alterar próprios bloqueios de horários de outros profissionais'
  },

  PODE_VISUALIZAR_VALOR_VENDA: { position: 69, grupo: GRUPO_VENDAS, descricao: 'Pode visualizar o valor das vendas/atendimentos' },
  PODE_VISUALIZAR_VENDAS_OUTROS_COLABORADORES: { position: 59, grupo: GRUPO_VENDAS, descricao: 'Pode visualizar vendas/atendimentos de outros profissionais' },
  PODE_VENDER: { position: 4, grupo: GRUPO_VENDAS, descricao: 'Pode vender produtos ou pacotes' },
  PODE_FECHAR_CONTA: { position: 5, grupo: GRUPO_VENDAS, descricao: 'Pode faturar vendas/atendimentos' },
  PODE_FECHAR_CONTA_VENDAS_OUTROS_COLABORADORES: { position: 61, grupo: GRUPO_VENDAS, descricao: 'Pode faturar vendas/atendimentos de outros colaboradores' },
  PODE_ALTERAR_PRECO_VENDA: { position: 6, grupo: GRUPO_VENDAS, descricao: 'Pode alterar preços ao marcar agendamento, vender produtos ou fechar contas' },
  PODE_CANCELAR_VENDA: { position: 7, grupo: GRUPO_VENDAS, descricao: 'Pode cancelar vendas' },
  PODE_APLICAR_DESCONTO_CADASTRADO: { position: 8, grupo: GRUPO_VENDAS, descricao: 'Pode aplicar descontos cadastrados' },
  PODE_APLICAR_DESCONTO_NCADASTRADO: { position: 9, grupo: GRUPO_VENDAS, descricao: 'Pode aplicar descontos não cadastrados' },
  PODE_VISUALIZAR_NOTAS: { position: 60, grupo: GRUPO_VENDAS, descricao: 'Pode visualizar listagem de comandas' },
  PODE_REGISTRAR_PAGAMENTO: { position: 31, grupo: GRUPO_VENDAS, descricao: 'Pode registrar pagamentos de comandas' },
  PODE_REGISTRAR_ESTORNO: { position: 32, grupo: GRUPO_VENDAS, descricao: 'Pode efetuar estornos de pagamentos de comandas' },
  PODE_ENVIAR_NOTA_CLIENTE: { position: 66, grupo: GRUPO_VENDAS, descricao: 'Pode enviar comandas em PDF por e-mail para clientes' },
  PODE_ALTERAR_DATA_NOTA: { position: 83, grupo: GRUPO_VENDAS, descricao: 'Pode alterar a data das comandas' },
  PODE_FECHAR_E_REABRIR_NOTA: { position: 33, grupo: GRUPO_VENDAS, descricao: 'Pode fechar e reabrir comandas' },
  PODE_CANCELAR_NOTA: { position: 34, grupo: GRUPO_VENDAS, descricao: 'Pode cancelar comandas' },
  PODE_VISUALIZAR_TOTALIZADORES_VALORES: { position: 62, grupo: GRUPO_VENDAS, descricao: 'Pode visualizar totalizadores de valores' },

  PODE_VISUALIZAR_NOTAS_FISCAIS: { position: 75, grupo: GRUPO_NF, descricao: 'Pode visualizar notas fiscais' },
  PODE_EMITIR_NOTAS_FISCAIS: { position: 76, grupo: GRUPO_NF, descricao: 'Pode emitir notas fiscais' },
  PODE_CANCELAR_NOTAS_FISCAIS: { position: 77, grupo: GRUPO_NF, descricao: 'Pode cancelar notas fiscais' },

  PODE_ACESSAR_PROPRIA_REMUNERACAO: { position: 37, grupo: GRUPO_COMISSOES, descricao: 'Pode visualizar as próprias remunerações' },
  PODE_ACESSAR_PROPRIO_VALE: { position: 70, grupo: GRUPO_COMISSOES, descricao: 'Pode visualizar os próprios vales' },
  PODE_ACESSAR_TODAS_REMUNERACOES: { position: 38, grupo: GRUPO_COMISSOES, descricao: 'Pode visualizar as remunerações dos demais colaboradores' },
  PODE_ACESSAR_TODOS_VALES: { position: 71, grupo: GRUPO_COMISSOES, descricao: 'Pode visualizar os vales dos demais colaboradores' },
  PODE_ACESSAR_PRECO_VENDA_REMUNERACAO: { position: 67, grupo: GRUPO_COMISSOES, descricao: 'Pode visualizar valor das vendas e detalhes de cálculo das remunerações' },
  PODE_PAGAR_REMUNERACOES: { position: 39, grupo: GRUPO_COMISSOES, descricao: 'Pode pagar as remunerações de todos os colaboradores' },
  PODE_GERENCIAR_VALES: { position: 72, grupo: GRUPO_COMISSOES, descricao: 'Pode gerenciar vales de todos os colaboradores' },
  PODE_GERENCIAR_SALARIOS: { position: 79, grupo: GRUPO_COMISSOES, descricao: 'Pode gerenciar salários e bonificações de todos colaboradores' },

  PODE_ACESSAR_FLUXO_CAIXA: { position: 74, grupo: GRUPO_FLUXO_CAIXA, descricao: 'Pode visualizar o fluxo de caixa do negócio' },

  PODE_VISUALIZAR_PROPRIO_CAIXA: { position: 53, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode visualizar próprio caixa' },
  PODE_ABRIR_PROPRIO_CAIXA: { position: 30, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode abrir o próprio caixa' },
  PODE_ALTERAR_PROPRIO_CAIXA: { position: 50, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode alterar o próprio caixa' },
  PODE_REGISTRAR_PROPRIA_ENTRADA: { position: 40, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode gerenciar entradas no próprio caixa' },
  PODE_REGISTRAR_PROPRIA_SANGRIA: { position: 41, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode gerenciar sangrias no próprio caixa' },
  PODE_FECHAR_PROPRIO_CAIXA: { position: 42, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode fechar próprio caixa' },
  PODE_REABRIR_PROPRIO_CAIXA: { position: 43, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode reabrir próprio caixa' },
  PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES: { position: 49, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode visualizar caixas gerais e de todos colaboradores' },
  PODE_ABRIR_CAIXA_OUTROS_COLABORADORES: { position: 44, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode abrir caixas gerais ou de outros colaboradores' },
  PODE_ALTERAR_CAIXA_OUTROS_COLABORADORES: { position: 51, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode alterar caixas gerais ou de outros colaboradores' },
  PODE_REGISTRAR_ENTRADA_OUTROS_COLABORADORES: { position: 45, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode gerenciar entradas no caixas gerais ou de outros colaboradores' },
  PODE_REGISTRAR_SANGRIA_OUTROS_COLABORADORES: { position: 46, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode gerenciar sangrias no caixas gerais ou de outros colaboradores' },
  PODE_FECHAR_CAIXA_OUTROS_COLABORADORES: { position: 47, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode fechar caixa gerais ou de outros colaboradores' },
  PODE_REABRIR_CAIXA_OUTROS_COLABORADORES: { position: 48, grupo: GRUPO_CONTROLE_CAIXA, descricao: 'Pode reabrir caixas gerais ou de outros colaboradores' },

  PODE_ACESSAR_DESPESA: { position: 28, grupo: GRUPO_DESPESAS, descricao: 'Pode acessar a tela de despesas' },
  PODE_ALTERAR_DESPESA: { position: 29, grupo: GRUPO_DESPESAS, descricao: 'Pode cadastrar e alterar despesas' },
  PODE_REMOVER_DESPESA: { position: 23, grupo: GRUPO_DESPESAS, descricao: 'Pode remover despesas' },

  PODE_ACESSAR_CAD_SERVICOS: { position: 16, grupo: GRUPO_SERVICOS, descricao: 'Pode acessar o cadastro de serviços' },
  PODE_ALTERAR_CAD_SERVICOS: { position: 17, grupo: GRUPO_SERVICOS, descricao: 'Pode cadastrar e alterar serviços' },
  PODE_REMOVER_SERVICO: { position: 58, grupo: GRUPO_SERVICOS, descricao: 'Pode remover serviços' },

  PODE_VISUALIZAR_PRODUTO: { position: 18, grupo: GRUPO_PRODUTOS, descricao: 'Pode visualizar produtos' },
  PODE_GERENCIAR_PRODUTO: { position: 19, grupo: GRUPO_PRODUTOS, descricao: 'Pode cadastrar e alterar produtos' },
  PODE_REMOVER_PRODUTO: { position: 52, grupo: GRUPO_PRODUTOS, descricao: 'Pode arquivar e remover produtos' },
  PODE_MOVIMENTAR_ESTOQUE: { position: 54, grupo: GRUPO_PRODUTOS, descricao: 'Pode movimentar estoque (entrada e saída de produtos)' },

  PODE_VISUALIZAR_PACOTE: { position: 20, grupo: GRUPO_PACOTES, descricao: 'Pode visualizar pacotes' },
  PODE_GERENCIAR_PACOTE: { position: 21, grupo: GRUPO_PACOTES, descricao: 'Pode cadastrar, alterar e remover pacotes' },

  PODE_ACESSAR_CAD_CLIENTES: { position: 10, grupo: GRUPO_CLIENTES, descricao: 'Pode acessar o cadastro dos clientes' },
  PODE_CADASTRAR_CLIENTE: { position: 11, grupo: GRUPO_CLIENTES, descricao: 'Pode cadastrar novos clientes' },
  PODE_ALTERAR_CLIENTE: { position: 64, grupo: GRUPO_CLIENTES, descricao: 'Pode alterar clientes' },
  PODE_VISUALIZAR_DADOS_CONTATO_CLIENTE: { position: 65, grupo: GRUPO_CLIENTES, descricao: 'Pode visualizar dados de contato de clientes' },
  PODE_ALTERAR_DADOS_CONTATO_CLIENTE: { position: 87, grupo: GRUPO_CLIENTES, descricao: 'Pode alterar dados de contato de clientes' },
  PODE_VISUALIZAR_DADOS_PESSOAIS_CLIENTE: { position: 84, grupo: GRUPO_CLIENTES, descricao: 'Pode visualizar dados pessoais de clientes' },
  PODE_ALTERAR_DADOS_PESSOAIS_CLIENTE: { position: 86, grupo: GRUPO_CLIENTES, descricao: 'Pode alterar dados pessoais de clientes' },
  PODE_VISUALIZAR_DADOS_ENDERECO_CLIENTE: { position: 85, grupo: GRUPO_CLIENTES, descricao: 'Pode visualizar dados de endereço de clientes' },
  PODE_ALTERAR_DADOS_ENDERECO_CLIENTE: { position: 88, grupo: GRUPO_CLIENTES, descricao: 'Pode alterar dados de endereço de clientes' },
  PODE_PERSISTIR_FOTOS_CLIENTE: { position: 55, grupo: GRUPO_CLIENTES, descricao: 'Pode adicionar ou remover fotos da galeria de clientes' },
  PODE_VISUALIZAR_FICHAS_CLIENTE: { position: 80, grupo: GRUPO_CLIENTES, descricao: 'Pode visualizar fichas de clientes' },
  PODE_PERSISTIR_FICHAS_CLIENTE: { position: 56, grupo: GRUPO_CLIENTES, descricao: 'Pode preencher fichas de clientes' },
  PODE_REMOVER_CLIENTE: { position: 12, grupo: GRUPO_CLIENTES, descricao: 'Pode remover clientes' },
  PODE_VISUALIZAR_VALORES_PENDENTES_SALDO_CLIENTE: { position: 78, grupo: GRUPO_CLIENTES, descricao: 'Pode visualizar valores pendentes e saldo de clientes' },

  PODE_ACESSAR_CAD_COLABORADORES: {
    position: 13,
    grupo: GRUPO_COLABORADORES,
    descricao: 'Pode acessar o cadastro dos colaboradores e visualizar dados cadastrais, configurações de comissões e mais '
  },
  PODE_PERSISTIR_COLABORADOR: {
    position: 14,
    grupo: GRUPO_COLABORADORES,
    descricao:
      'Pode incluir ou alterar colaboradores e configurações (configurações gerais, serviços prestados, configurações de comissões, horários de atendimento, notificações e etc)'
  },
  PODE_ALTERAR_PERMISSOES_ACESSO: { position: 15, grupo: GRUPO_COLABORADORES, descricao: 'Pode alterar as permissões de acesso dos colaboradores ao sistema' },
  PODE_REMOVER_COLABORADOR: { position: 57, grupo: GRUPO_COLABORADORES, descricao: 'Pode remover e reativar colaboradores' },

  PODE_ACESSAR_CONFIG_GERAIS: {
    position: 22,
    grupo: GRUPO_CONFIGURACOES,
    descricao: 'Pode acessar e alterar as configurações gerais no negócio, como dados da empresa, tema , regras para comissões e modelos de mensagens de lembretes.'
  },
  PODE_ACESSAR_CAD_DESCONTOS: { position: 24, grupo: GRUPO_CONFIGURACOES, descricao: 'Pode acessar e alterar o cadastro de descontos' },
  PODE_ACESSAR_CAD_FORMAPAGTO: { position: 25, grupo: GRUPO_CONFIGURACOES, descricao: 'Pode acessar e alterar o cadastro de formas de pagamento' },
  PODE_ACESSAR_CAD_FICHACLIENTE: { position: 26, grupo: GRUPO_CONFIGURACOES, descricao: 'Pode acessar e alterar o cadastro de fichas pré-cadastradas' },
  PODE_ACESSAR_CAD_INDISPONIBILIDADE_HORARIO: { position: 68, grupo: GRUPO_CONFIGURACOES, descricao: 'Pode acessar e alterar o cadastro de indisponibilidades de horários' },

  PODE_ACESSAR_RELATORIOS: { position: 27, grupo: GRUPO_RELATORIOS, descricao: 'Pode acessar relatórios' },

  PODE_VISUALIZAR_E_REALIZAR_PAGAMENTO_PLANO: {
    position: 63,
    grupo: GRUPO_CONTRATACAO_PLANOS_E_SERVICOS,
    descricao: 'Pode visualizar e realizar pagamentos de planos para uso do sistema.'
  }
})

const MAPEAMENTO_PERMISSOES_ROTA = {
  '/minhaconta': [],
  '/negocios': [],
  '/atendimentos': [PermissoesAcesso.PODE_ACESSAR_PROPRIO_CALENDARIO],
  '/vendas': [PermissoesAcesso.PODE_VENDER, PermissoesAcesso.PODE_VISUALIZAR_VENDAS_OUTROS_COLABORADORES],
  '/notas': [PermissoesAcesso.PODE_VISUALIZAR_NOTAS],
  '/remuneracoes': [PermissoesAcesso.PODE_ACESSAR_PROPRIA_REMUNERACAO],
  '/servicos': [PermissoesAcesso.PODE_ACESSAR_CAD_SERVICOS],
  '/inventario': [PermissoesAcesso.PODE_VISUALIZAR_PRODUTO],
  '/clientes': [PermissoesAcesso.PODE_ACESSAR_CAD_CLIENTES],
  '/colaboradores': [PermissoesAcesso.PODE_ACESSAR_CAD_COLABORADORES],
  '/whatsapp': [PermissoesAcesso.PODE_ACESSAR_CONFIG_GERAIS],
  '/pacotes': [PermissoesAcesso.PODE_VISUALIZAR_PACOTE],
  '/relatorios': [PermissoesAcesso.PODE_ACESSAR_RELATORIOS],
  '/agendamentoonline': [PermissoesAcesso.PODE_ACESSAR_CONFIG_GERAIS],
  '/configuracoes': [
    PermissoesAcesso.PODE_ACESSAR_CONFIG_GERAIS,
    PermissoesAcesso.PODE_ACESSAR_CAD_DESCONTOS,
    PermissoesAcesso.PODE_ACESSAR_CAD_FORMAPAGTO,
    PermissoesAcesso.PODE_ACESSAR_CAD_FICHACLIENTE
  ],
  '/despesas': [PermissoesAcesso.PODE_ACESSAR_DESPESA],
  '/fluxocaixa': [PermissoesAcesso.PODE_ACESSAR_FLUXO_CAIXA],
  '/caixas': [PermissoesAcesso.PODE_VISUALIZAR_PROPRIO_CAIXA],
  '/nfs': [PermissoesAcesso.PODE_VISUALIZAR_NOTAS_FISCAIS],
  '/suporte/chat': [],
  '/plano': [],
  '/apresentacaonf': [],
  '/atualizacoes': [],
  '/termoseprivacidade': [],
  '/centraldeajuda': [],
  '/gerenciar-maquininha': []
}

const MAPEAMENTO_DEPENDENCIAS_INCLUSAO = {
  [PermissoesAcesso.PODE_ACESSAR_TODOS_CALENDARIOS.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIO_CALENDARIO],
  [PermissoesAcesso.PODE_MARCAR_ATENDIMENTO.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIO_CALENDARIO],
  [PermissoesAcesso.PODE_VISUALIZAR_OBSERVACAO.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIO_CALENDARIO],
  [PermissoesAcesso.PODE_REMOVER_ATENDIMENTO.position]: [PermissoesAcesso.PODE_MARCAR_ATENDIMENTO],
  [PermissoesAcesso.PODE_CANCELAR_ATENDIMENTO.position]: [PermissoesAcesso.PODE_MARCAR_ATENDIMENTO],
  [PermissoesAcesso.PODE_PERSISTIR_PROPRIO_BLOQUEIO_HORARIO.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIO_CALENDARIO],
  [PermissoesAcesso.PODE_PERSISTIR_BLOQUEIOS_HORARIOS_OUTROS_COLABORADORES.position]: [
    PermissoesAcesso.PODE_ACESSAR_TODOS_CALENDARIOS,
    PermissoesAcesso.PODE_PERSISTIR_PROPRIO_BLOQUEIO_HORARIO
  ],

  [PermissoesAcesso.PODE_FECHAR_CONTA.position]: [PermissoesAcesso.PODE_VENDER, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_FECHAR_CONTA_VENDAS_OUTROS_COLABORADORES.position]: [PermissoesAcesso.PODE_FECHAR_CONTA, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_ALTERAR_PRECO_VENDA.position]: [PermissoesAcesso.PODE_VENDER, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_CANCELAR_VENDA.position]: [PermissoesAcesso.PODE_VENDER],
  [PermissoesAcesso.PODE_APLICAR_DESCONTO_CADASTRADO.position]: [PermissoesAcesso.PODE_VENDER, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_APLICAR_DESCONTO_NCADASTRADO.position]: [PermissoesAcesso.PODE_VENDER, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_VISUALIZAR_NOTAS.position]: [PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_REGISTRAR_PAGAMENTO.position]: [PermissoesAcesso.PODE_FECHAR_CONTA, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_REGISTRAR_ESTORNO.position]: [PermissoesAcesso.PODE_REGISTRAR_PAGAMENTO, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_ENVIAR_NOTA_CLIENTE.position]: [PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_ALTERAR_DATA_NOTA.position]: [PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_FECHAR_E_REABRIR_NOTA.position]: [PermissoesAcesso.PODE_REGISTRAR_PAGAMENTO, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_CANCELAR_NOTA.position]: [PermissoesAcesso.PODE_CANCELAR_VENDA, PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_VISUALIZAR_TOTALIZADORES_VALORES.position]: [PermissoesAcesso.PODE_VISUALIZAR_VALOR_VENDA],
  [PermissoesAcesso.PODE_EMITIR_NOTAS_FISCAIS.position]: [PermissoesAcesso.PODE_VISUALIZAR_NOTAS_FISCAIS],
  [PermissoesAcesso.PODE_CANCELAR_NOTAS_FISCAIS.position]: [PermissoesAcesso.PODE_VISUALIZAR_NOTAS_FISCAIS],

  [PermissoesAcesso.PODE_CADASTRAR_CLIENTE.position]: [PermissoesAcesso.PODE_ACESSAR_CAD_CLIENTES],
  [PermissoesAcesso.PODE_REMOVER_CLIENTE.position]: [PermissoesAcesso.PODE_CADASTRAR_CLIENTE],
  [PermissoesAcesso.PODE_ALTERAR_CLIENTE.position]: [PermissoesAcesso.PODE_CADASTRAR_CLIENTE],
  [PermissoesAcesso.PODE_ALTERAR_DADOS_PESSOAIS_CLIENTE.position]: [PermissoesAcesso.PODE_ALTERAR_CLIENTE, PermissoesAcesso.PODE_VISUALIZAR_DADOS_PESSOAIS_CLIENTE],
  [PermissoesAcesso.PODE_ALTERAR_DADOS_CONTATO_CLIENTE.position]: [PermissoesAcesso.PODE_ALTERAR_CLIENTE, PermissoesAcesso.PODE_VISUALIZAR_DADOS_CONTATO_CLIENTE],
  [PermissoesAcesso.PODE_ALTERAR_DADOS_ENDERECO_CLIENTE.position]: [PermissoesAcesso.PODE_ALTERAR_CLIENTE, PermissoesAcesso.PODE_VISUALIZAR_DADOS_ENDERECO_CLIENTE],
  [PermissoesAcesso.PODE_PERSISTIR_FOTOS_CLIENTE.position]: [PermissoesAcesso.PODE_ACESSAR_CAD_CLIENTES],
  [PermissoesAcesso.PODE_VISUALIZAR_VALORES_PENDENTES_SALDO_CLIENTE.position]: [PermissoesAcesso.PODE_ACESSAR_CAD_CLIENTES],
  [PermissoesAcesso.PODE_VISUALIZAR_FICHAS_CLIENTE.position]: [PermissoesAcesso.PODE_ACESSAR_CAD_CLIENTES],
  [PermissoesAcesso.PODE_PERSISTIR_FICHAS_CLIENTE.position]: [PermissoesAcesso.PODE_VISUALIZAR_FICHAS_CLIENTE],

  [PermissoesAcesso.PODE_PERSISTIR_COLABORADOR.position]: [PermissoesAcesso.PODE_ACESSAR_CAD_COLABORADORES],
  [PermissoesAcesso.PODE_ALTERAR_PERMISSOES_ACESSO.position]: [PermissoesAcesso.PODE_PERSISTIR_COLABORADOR],
  [PermissoesAcesso.PODE_REMOVER_COLABORADOR.position]: [PermissoesAcesso.PODE_PERSISTIR_COLABORADOR],

  [PermissoesAcesso.PODE_ALTERAR_CAD_SERVICOS.position]: [PermissoesAcesso.PODE_ACESSAR_CAD_SERVICOS],
  [PermissoesAcesso.PODE_REMOVER_SERVICO.position]: [PermissoesAcesso.PODE_ALTERAR_CAD_SERVICOS],

  [PermissoesAcesso.PODE_ALTERAR_DESPESA.position]: [PermissoesAcesso.PODE_ACESSAR_DESPESA],
  [PermissoesAcesso.PODE_REMOVER_DESPESA.position]: [PermissoesAcesso.PODE_ALTERAR_DESPESA],

  [PermissoesAcesso.PODE_ACESSAR_PROPRIA_REMUNERACAO.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIO_VALE],
  [PermissoesAcesso.PODE_ACESSAR_TODAS_REMUNERACOES.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIA_REMUNERACAO, PermissoesAcesso.PODE_ACESSAR_TODOS_VALES],
  [PermissoesAcesso.PODE_ACESSAR_TODOS_VALES.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIO_VALE],
  [PermissoesAcesso.PODE_ACESSAR_PRECO_VENDA_REMUNERACAO.position]: [PermissoesAcesso.PODE_ACESSAR_PROPRIA_REMUNERACAO],

  [PermissoesAcesso.PODE_PAGAR_REMUNERACOES.position]: [PermissoesAcesso.PODE_ACESSAR_TODAS_REMUNERACOES, PermissoesAcesso.PODE_ACESSAR_TODOS_VALES],

  [PermissoesAcesso.PODE_GERENCIAR_VALES.position]: [PermissoesAcesso.PODE_ACESSAR_TODOS_VALES],

  [PermissoesAcesso.PODE_ABRIR_PROPRIO_CAIXA.position]: [PermissoesAcesso.PODE_VISUALIZAR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_ALTERAR_PROPRIO_CAIXA.position]: [PermissoesAcesso.PODE_ABRIR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_REGISTRAR_PROPRIA_ENTRADA.position]: [PermissoesAcesso.PODE_ABRIR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_REGISTRAR_PROPRIA_SANGRIA.position]: [PermissoesAcesso.PODE_ABRIR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_FECHAR_PROPRIO_CAIXA.position]: [PermissoesAcesso.PODE_ABRIR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_REABRIR_PROPRIO_CAIXA.position]: [PermissoesAcesso.PODE_ABRIR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES.position]: [PermissoesAcesso.PODE_VISUALIZAR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_ABRIR_CAIXA_OUTROS_COLABORADORES.position]: [PermissoesAcesso.PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES, PermissoesAcesso.PODE_ABRIR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_REGISTRAR_ENTRADA_OUTROS_COLABORADORES.position]: [
    PermissoesAcesso.PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES,
    PermissoesAcesso.PODE_REGISTRAR_PROPRIA_ENTRADA
  ],
  [PermissoesAcesso.PODE_REGISTRAR_SANGRIA_OUTROS_COLABORADORES.position]: [
    PermissoesAcesso.PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES,
    PermissoesAcesso.PODE_REGISTRAR_PROPRIA_SANGRIA
  ],
  [PermissoesAcesso.PODE_FECHAR_CAIXA_OUTROS_COLABORADORES.position]: [PermissoesAcesso.PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES, PermissoesAcesso.PODE_FECHAR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_REABRIR_CAIXA_OUTROS_COLABORADORES.position]: [PermissoesAcesso.PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES, PermissoesAcesso.PODE_REABRIR_PROPRIO_CAIXA],
  [PermissoesAcesso.PODE_ALTERAR_CAIXA_OUTROS_COLABORADORES.position]: [PermissoesAcesso.PODE_VISUALIZAR_CAIXA_OUTROS_COLABORADORES, PermissoesAcesso.PODE_ALTERAR_PROPRIO_CAIXA],

  [PermissoesAcesso.PODE_GERENCIAR_PRODUTO.position]: [PermissoesAcesso.PODE_VISUALIZAR_PRODUTO],
  [PermissoesAcesso.PODE_REMOVER_PRODUTO.position]: [PermissoesAcesso.PODE_VISUALIZAR_PRODUTO],
  [PermissoesAcesso.PODE_MOVIMENTAR_ESTOQUE.position]: [PermissoesAcesso.PODE_VISUALIZAR_PRODUTO],

  [PermissoesAcesso.PODE_GERENCIAR_PACOTE.position]: [PermissoesAcesso.PODE_VISUALIZAR_PACOTE]
}

const MAPEAMENTO_DEPENDENCIAS_REMOCAO = (function () {
  const mapeamentoRemocao = {}
  Object.keys(MAPEAMENTO_DEPENDENCIAS_INCLUSAO).forEach(function (position) {
    for (let permissao of MAPEAMENTO_DEPENDENCIAS_INCLUSAO[position]) {
      if (mapeamentoRemocao[permissao.position] === undefined) {
        mapeamentoRemocao[permissao.position] = []
      }
      mapeamentoRemocao[permissao.position].push(PermissoesAcesso.getByPosition(parseInt(position, 10)))
    }
  })
  return mapeamentoRemocao
})()

PermissoesAcesso['verificarAutorizacao'] = (permissoesRequeridas, permissoesConcedidas, and) => {
  const verificacaoInicial = PermissoesAcesso.verify([PermissoesAcesso.ACESSO_SISTEMA], permissoesConcedidas)
  if (verificacaoInicial === false) {
    return false
  }
  const result = PermissoesAcesso.verify(permissoesRequeridas, permissoesConcedidas, and)
  return result
}

PermissoesAcesso['verificarAutorizacaoPorRota'] = (rota, permissoesConcedidas, and) => {
  let buscaExata = true
  if (!rota) {
    rota = '/' + window.location.pathname.split('/p/', 2)[1]
    buscaExata = false
  }
  if (buscaExata) {
    const permissoes = MAPEAMENTO_PERMISSOES_ROTA[rota]
    if (permissoes) {
      if (permissoes.length > 0) {
        return PermissoesAcesso['verificarAutorizacao'](permissoes, permissoesConcedidas, and)
      } else if (permissoes.length === 0) {
        return true
      }
    }
  } else {
    for (let rotaMapeada of Object.keys(MAPEAMENTO_PERMISSOES_ROTA)) {
      if (rota.startsWith(rotaMapeada)) {
        const permissoes = MAPEAMENTO_PERMISSOES_ROTA[rotaMapeada]
        if (permissoes.length > 0) {
          return PermissoesAcesso['verificarAutorizacao'](permissoes, permissoesConcedidas, and)
        } else if (permissoes.length === 0) {
          return true
        }
        break
      }
    }
  }
  return false
}

PermissoesAcesso['buscarDependencias'] = (permissao, inclusao, positionsToIgnore, appendList, recursiveCall) => {
  if (!appendList) {
    appendList = []
  }
  const permissoesDependentes = inclusao ? MAPEAMENTO_DEPENDENCIAS_INCLUSAO[permissao.position] : MAPEAMENTO_DEPENDENCIAS_REMOCAO[permissao.position]
  if (permissoesDependentes && permissoesDependentes.length > 0) {
    for (let permissaoDependente of permissoesDependentes) {
      PermissoesAcesso.buscarDependencias(permissaoDependente, inclusao, null, appendList, true)
    }
  }
  if (recursiveCall) {
    appendList.push(permissao)
  } else {
    if (!positionsToIgnore) {
      positionsToIgnore = []
    }
    if (!inclusao) {
      appendList.reverse()
    }
    appendList = ArrayUtil.removerElementosDuplicados(appendList, 'position')
    for (let i = appendList.length - 1; i >= 0; i--) {
      if (positionsToIgnore.indexOf(appendList[i].position) >= 0) {
        appendList.splice(i, 1)
      }
    }
    return appendList
  }
}

export default PermissoesAcesso
