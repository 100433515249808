import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PagamentosResponsiveTable from 'pages/erp/painel/relatorio/table/PagamentosResponsiveTable'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class DetalhesPagamentosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }

    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNotaVendaPagamento', 'ManipulacaoVendas', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      idFormaPagamento: this.props.idFormaPagamento,
      idAdquirente: this.props.idAdquirente,
      idBandeira: this.props.idBandeira
    }
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Detalhamento de Pagamentos - ' + formatarPeriodoNomeArquivo(this.props.dataInicial, this.props.dataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarNotaVendaPagamentos',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)

    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    const contadorInfo = {}

    for (let item of this.state.lista) {
      let contadorItem = contadorInfo[item.formaPagamento.nome]

      if (contadorItem === undefined) {
        contadorItem = {
          quantidade: 0,
          valor: 0
        }
        contadorInfo[item.formaPagamento.nome] = contadorItem
      }

      contadorItem.quantidade = contadorItem.quantidade + 1
      contadorItem.valor = contadorItem.valor + item.valorPagoFinal
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} align="center" title="Detalhamento" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">{this.props.tituloDialog}</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <PagamentosResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              itemsPerRequest={30}
              contextoUsuario="erp"
              endpoint="erp/vendas.buscarNotaVendaPagamentosPorData"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({
                    lista: data.items.filter((pagamento) => {
                      return pagamento.formaPagamento.tipo.id !== FormaPagamentoTipoEnum.CREDITO.id ? true : false
                    })
                  })
                }
              }}
              margin={true}
              emptyListProps={{
                text: 'Não existem pagamentos para o período informado'
              }}
            />

            {this.state.lista.length > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                {Object.keys(contadorInfo).map((descricao, index) => {
                  const contadorInfoItem = contadorInfo[descricao]
                  return (
                    <React.Fragment key={index}>
                      <Typography variant="body2" style={{ paddingTop: index === 0 ? 6 : 0 }}>
                        <b>{descricao}</b>
                        <br />
                        <small>
                          Quantidade: <b>{contadorInfoItem.quantidade}</b>, Total: <b>{formatarValorMonetario(contadorInfoItem.valor)}</b>
                        </small>
                      </Typography>
                      {index < Object.keys(contadorInfo).length - 1 && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
                    </React.Fragment>
                  )
                })}
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

DetalhesPagamentosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesPagamentosDialogPage)
