import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import moment from 'moment'
import PainelClienteContent from 'pages/erp/painel/cliente/PainelClienteContent'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import FechamentoContaDialogPage from 'pages/erp/painel/venda/FechamentoContaDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const MAX_WIDTH_PAPER_DEFAULT = 820
const MAX_WIDTH_PAPER_TAB_VENDAS = 1080

const styles = (theme) => ({})

class ClienteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      cliente: null,
      title: 'Cliente',
      clienteInfoTabValue: 0,
      ajaxing: false
    }

    this.contentWithPreloadFunctions = {}
    this.paperRef = React.createRef()
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCliente', (props) => {
      if (props !== undefined && props.cliente !== undefined) {
        this.setState({ cliente: props.cliente })
      } else {
        this.contentWithPreloadFunctions.reload()
      }
    })

    this.eventsManager.sub('AlteracaoSaldoCliente', (props) => {
      this.contentWithPreloadFunctions.reloadInBackground()
    })

    this.eventsManager.sub('ManutencaoPacote', (props) => {
      this.contentWithPreloadFunctions.reloadInBackground()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/clientes.buscarPorId',
      params: {
        id: this.props.idCliente
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const cliente = response.data
        this.setState({
          cliente: cliente,
          toolbarActions: {
            actions: [
              {
                label: 'Fechar comanda',
                handleAction: this.fecharConta
              }
            ]
          }
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  fecharConta = () => {
    if (verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(FechamentoContaDialogPage, {
        cliente: this.state.cliente,
        retornarVendas: true
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { cliente, clienteInfoTabValue } = this.state

    const tabsWithLargeWidth = [1]
    if (verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      tabsWithLargeWidth.push(2)
    }

    let contentMaxWidth = MAX_WIDTH_PAPER_DEFAULT
    if (tabsWithLargeWidth.indexOf(clienteInfoTabValue) >= 0) {
      contentMaxWidth = MAX_WIDTH_PAPER_TAB_VENDAS
    }

    let title = this.state.cliente ? this.state.cliente.apelido : ''
    if (isWidthDown('xs', this.props.width)) {
      title = title.split(' ')[0]
    }

    return (
      <DialogPage
        {...dialogProps}
        title={title}
        titleWrap={true}
        align="center"
        contentMaxWidth={contentMaxWidth}
        pageType="basicEntity"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
        onPreClose={() => {
          EventsManager.pub('TabSaveSnackBar', { open: false })
        }}
      >
        {(dialogContentProps) => (
          <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarMain} loadContentPadding={true}>
            {() => (
              <PainelClienteContent
                exibirNomeCliente={false}
                parentDialog={this}
                cliente={cliente}
                containerScrollElement={dialogContentProps.scrollContainerRef.current}
                infoTabValue={clienteInfoTabValue}
                onChangeTab={(value) => {
                  EventsManager.pub('TabSaveSnackBar', { open: false })
                  this.setState({ clienteInfoTabValue: value })
                }}
                vendaDialogProps={{
                  data: moment()
                }}
              />
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ClienteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(ClienteDialogPage))
