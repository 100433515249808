import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import PropTypes from 'prop-types'
import toTS from 'support/components/toTS'
import Transition from 'support/components/transition/Transition'

const styles = () => ({
  containerApresentacao: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 1250,
    backgroundColor: '#fff'
  },
  containerApresentacaoZoom: {
    textAlign: 'center'
  },
  progressCentral: {
    margin: 16,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  progressCentralSVG: {
    color: '#333'
  },
  hideScroll: {
    overflow: 'hidden'
  }
})

class MainLoaderScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ativacaoFade: true,
      duracaoFade: 0,
      ativacaoZoomLogo: false,
      exibirApresentacao: true,
      erroPaginaCarregamento: ''
    }
  }

  componentDidMount = () => {
    if (this.props.functionsMap) {
      this.props.functionsMap['notifyStart'] = this.notifyStart
      this.props.functionsMap['notifyLoaded'] = this.notifyLoaded
      this.props.functionsMap['notifyError'] = this.notifyError
    }
    this.setState({ ativacaoZoomLogo: true })
  }

  notifyStart = () => {
    document.body.classList.add(this.props.classes.hideScroll)
    this.setState({
      carregando: true
    })
  }

  notifyLoaded = () => {
    const duracaoFade = 700
    this.setState({ painelCarregado: true, carregando: false, ativacaoFade: false, duracaoFade })
    document.body.classList.remove(this.props.classes.hideScroll)
    window.setTimeout(() => {
      this.setState({ exibirApresentacao: false })
    }, duracaoFade)
  }

  notifyError = (props) => {
    this.setState({
      carregando: false,
      erroPaginaCarregamento: props.message
    })
  }

  render() {
    const { classes } = this.props
    const { exibirApresentacao, ativacaoFade, duracaoFade, ativacaoZoomLogo } = this.state

    if (!exibirApresentacao) {
      return []
    }

    let imageContent = null

    if (this.props.showLogo !== false || (!this.state.carregando && !this.state.painelCarregado)) {
      imageContent = this.props.image
    }

    return (
      <Transition component={Fade} in={ativacaoFade} timeout={duracaoFade}>
        {(fadeTransitionProps) => (
          <div className={classes.containerApresentacao} {...fadeTransitionProps}>
            <Grid style={{ height: '100%' }} container alignItems="center" direction="row" justifyContent="center">
              <Grid item style={{ width: '100%' }}>
                <Transition component={Zoom} in={ativacaoZoomLogo} timeout={180}>
                  {(zoomTransitionProps) => (
                    <div className={classes.containerApresentacaoZoom} {...zoomTransitionProps}>
                      {imageContent}
                      {(this.state.carregando === true || this.state.ativacaoFade === false) && (
                        <React.Fragment>
                          <div style={{ marginTop: 24, marginBottom: 20 }}>
                            <CircularProgress
                              size={34}
                              thickness={5.2}
                              classes={{
                                root: classes.progressCentral,
                                svg: classes.progressCentralSVG
                              }}
                            />
                          </div>
                        </React.Fragment>
                      )}
                      {this.state.carregando === false && this.state.ativacaoFade === true && (
                        <div style={{ color: '#333', marginTop: 24 }}>
                          <Typography align="center" variant="body2" color="inherit">
                            {this.state.erroPaginaCarregamento}
                          </Typography>
                          <Button style={{ marginTop: 10 }} variant="outlined" color="inherit" type="button" size="small" onClick={this.props.loadFunction}>
                            Tentar Novamente
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </Transition>
              </Grid>
            </Grid>
          </div>
        )}
      </Transition>
    )
  }
}

MainLoaderScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  functionsMap: PropTypes.object,
  showLogo: PropTypes.bool,
  image: PropTypes.node,
  loadFunction: PropTypes.func
}

export default toTS(withStyles(styles)(MainLoaderScreen))
