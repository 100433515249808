import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputStringNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ServicoNFDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idServicoNf ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputDescricao = React.createRef()
    this.inputCodigoIbgeCidade = React.createRef()
    this.inputCodigoServicoMunicipio = React.createRef()
    this.inputItemListaServicoLc116 = React.createRef()
    this.inputCnae = React.createRef()
    this.inputDescricaoServicoMunicipio = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idServicoNf) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })

      getAPI({
        url: 'admin/nfs.buscarServicoNFPorId',
        params: {
          id: this.props.idServicoNf
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState({ servicoNf: response.data })

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirServicoNf()
  }

  persistirServicoNf = () => {
    postAPI({
      url: 'admin/nfs.persistirServicoNf',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoNFsServico', {
          servicoNf: response.data
        })
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formServicoNf')
      }
    })
  }

  getDados = () => {
    const dados = {}
    if (this.props.idServicoNf) {
      dados.id = this.props.idServicoNf
    }

    dados.descricao = this.inputDescricao.current.value
    dados.codigoIbgeCidade = this.inputCodigoIbgeCidade.current.value
    dados.codigoServicoMunicipio = this.inputCodigoServicoMunicipio.current.value
    dados.itemListaServicoLc116 = this.inputItemListaServicoLc116.current.value
    dados.cnae = this.inputCnae.current.value
    dados.descricaoServicoMunicipio = this.inputDescricaoServicoMunicipio.current.value

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state
    const { descricao, codigoIbgeCidade, codigoServicoMunicipio, itemListaServicoLc116, cnae, descricaoServicoMunicipio } = this.state.servicoNf ? this.state.servicoNf : {}

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formServicoNf" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xdescricao"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={descricao}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('descricao')}
                label="Descrição"
                inputProps={{
                  maxLength: 500
                }}
                inputRef={this.inputDescricao}
              />

              <InputText
                idname="xcodigoibgecidade"
                customVariant="outlined-small"
                shrink={false}
                marginTop={true}
                defaultValue={codigoIbgeCidade}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('codigoIbgeCidade')}
                label="Código IBGE Cidade"
                inputComponent={InputStringNumberFormat}
                inputProps={{ maxLength: 20 }}
                inputRef={this.inputCodigoIbgeCidade}
              />

              <InputText
                idname="xcodigoservicomunicipio"
                customVariant="outlined-small"
                shrink={false}
                marginTop={true}
                defaultValue={codigoServicoMunicipio}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('codigoServicoMunicipio')}
                label="Código Serviço Municipio"
                inputProps={{ maxLength: 45 }}
                inputRef={this.inputCodigoServicoMunicipio}
              />

              <InputText
                idname="xdescricaoservicomunicipio"
                customVariant="outlined-small"
                shrink={false}
                marginTop={true}
                defaultValue={descricaoServicoMunicipio}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('descricaoservicomunicipio')}
                label="Descrição Serviço Município"
                inputProps={{ maxLength: 512 }}
                inputRef={this.inputDescricaoServicoMunicipio}
              />

              <InputText
                idname="xitemlistaservicolc116"
                customVariant="outlined-small"
                shrink={false}
                marginTop={true}
                defaultValue={itemListaServicoLc116}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('itemListaServicoLc116')}
                label="Item Lista Servico Lc116"
                inputProps={{ maxLength: 45 }}
                inputRef={this.inputItemListaServicoLc116}
              />

              <InputText
                idname="xcnae"
                customVariant="outlined-small"
                shrink={false}
                marginTop={true}
                defaultValue={cnae}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('cnae')}
                label="Cnae"
                inputProps={{ maxLength: 45 }}
                inputRef={this.inputCnae}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ServicoNFDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServicoNFDialogPage)
