import TimeUtil from 'support/util/TimeUtil'

function arredondarPeriodoCincoMinutos(periodo, paraDisponibilidade) {
  const minutosSobrandoDe = periodo.de % 5
  const minutosSobrandoAte = periodo.ate % 5
  if (minutosSobrandoDe !== 0 || minutosSobrandoAte !== 0) {
    if (paraDisponibilidade) {
      periodo.de = TimeUtil.adiciconarMinutosHorarioInt(periodo.de, 5 - minutosSobrandoDe)
      periodo.ate = periodo.ate - minutosSobrandoAte
    } else {
      periodo.de = periodo.de - minutosSobrandoDe
      periodo.ate = TimeUtil.adiciconarMinutosHorarioInt(periodo.ate, 5 - minutosSobrandoAte)
    }
  }
}

function buscarPeriodosDisponiveis(periodosPossiveis, periodosIndisponiveis) {
  if (!periodosPossiveis || periodosPossiveis.length === 0) {
    return []
  }

  const periodosPossiveisMesclados = mesclarListaPeriodos(periodosPossiveis)
  let periodosIndisponiveisMesclados = []

  if (periodosIndisponiveis && periodosIndisponiveis.length > 0) {
    periodosIndisponiveisMesclados = mesclarListaPeriodos(periodosIndisponiveis)
  }

  for (let periodoParaArredondar of periodosPossiveisMesclados) {
    arredondarPeriodoCincoMinutos(periodoParaArredondar, true)
  }

  for (let periodoParaArredondar of periodosIndisponiveisMesclados) {
    arredondarPeriodoCincoMinutos(periodoParaArredondar, false)
  }

  const periodosDisponiveis = []
  let contadorDisponiveis = 0
  let contadorIndisponiveis = 0
  while (true) {
    if (periodosPossiveisMesclados.length === contadorDisponiveis) {
      break
    } else {
      let periodoPossivelAtual = periodosPossiveisMesclados[contadorDisponiveis]
      while (true) {
        if (periodosIndisponiveisMesclados === null || periodosIndisponiveisMesclados.length === contadorIndisponiveis) {
          break
        } else {
          let periodoIndisponivel = periodosIndisponiveisMesclados[contadorIndisponiveis]
          if (periodoIndisponivel.ate <= periodoPossivelAtual.de) {
            contadorIndisponiveis++
            continue
          } else if (periodoIndisponivel.de >= periodoPossivelAtual.ate) {
            break
          } else if (periodoIndisponivel.de > periodoPossivelAtual.de) {
            periodosDisponiveis.push({ de: periodoPossivelAtual.de, ate: periodoIndisponivel.de })
            periodoPossivelAtual = { de: periodoIndisponivel.de, ate: periodoPossivelAtual.ate }
          } else if (periodoIndisponivel.ate < periodoPossivelAtual.ate) {
            periodoPossivelAtual = { de: periodoIndisponivel.ate, ate: periodoPossivelAtual.ate }
            contadorIndisponiveis++
          } else {
            periodoPossivelAtual = null
            break
          }
        }
      }
      if (periodoPossivelAtual != null) {
        periodosDisponiveis.push(periodoPossivelAtual)
      }
      contadorDisponiveis++
    }
  }

  return periodosDisponiveis
}

function gerarHorariosDisponiveis(periodosPossiveis, periodosIndisponiveis, intervalo, duracao, gerarHorariosInteligentes, horariosFixos) {
  if (!intervalo == null) {
    return null
  }

  if (!duracao == null) {
    return null
  }

  if (horariosFixos && horariosFixos.length > 0) {
    intervalo = 5
  }

  const periodosDisponiveis = buscarPeriodosDisponiveis(periodosPossiveis, periodosIndisponiveis)
  let horariosDisponiveis = []

  if (periodosDisponiveis.length > 0) {
    for (let periodoDisponivel of periodosDisponiveis) {
      let horarioReferencia = periodoDisponivel.de
      let minutosAdicionaisDoCicloPorHora = horarioReferencia % 100
      let ultimoHorarioDisponivelPeriodo = null //ultimo período dentro do periodoDisponivel atual. Não é possível utilizar o "horariosDisponiveis[horariosDisponiveis.length-1]" pq ele seria geral, e nao apenas para o periodoDisponivel atual.
      if (minutosAdicionaisDoCicloPorHora > 0) {
        if (gerarHorariosInteligentes) {
          let horarioReferenciaAte = TimeUtil.adiciconarMinutosHorarioInt(horarioReferencia, duracao)
          if (horarioReferenciaAte <= periodoDisponivel.ate) {
            horariosDisponiveis.push(horarioReferencia)
            ultimoHorarioDisponivelPeriodo = horarioReferencia
          }
        }
        let minutosParaCompletarCicloPorHora = 60 - minutosAdicionaisDoCicloPorHora
        if (intervalo > minutosParaCompletarCicloPorHora || minutosParaCompletarCicloPorHora % intervalo > 0) {
          horarioReferencia = TimeUtil.adiciconarMinutosHorarioInt(horarioReferencia, minutosParaCompletarCicloPorHora)
        }
      }
      while (true) {
        let horarioReferenciaAte = TimeUtil.adiciconarMinutosHorarioInt(horarioReferencia, duracao)
        if (horarioReferenciaAte <= periodoDisponivel.ate) {
          horariosDisponiveis.push(horarioReferencia)
          ultimoHorarioDisponivelPeriodo = horarioReferencia
          horarioReferencia = TimeUtil.adiciconarMinutosHorarioInt(horarioReferencia, intervalo)
        } else {
          break
        }
      }
      if (gerarHorariosInteligentes) {
        let horarioInteligente = TimeUtil.adiciconarMinutosHorarioInt(periodoDisponivel.ate, duracao * -1)
        if (ultimoHorarioDisponivelPeriodo != null && horarioInteligente > ultimoHorarioDisponivelPeriodo) {
          horariosDisponiveis.push(horarioInteligente)
        }
      }
    }
  }

  if (horariosFixos && horariosFixos.length > 0) {
    const horariosDisponiveisTratados = []
    for (let horarioFixo of horariosFixos) {
      if (horariosDisponiveis.indexOf(horarioFixo) >= 0) {
        horariosDisponiveisTratados.push(horarioFixo)
      }
    }
    horariosDisponiveis = horariosDisponiveisTratados
  }

  return [...new Set(horariosDisponiveis)]
}

function mesclarListaPeriodos(periodos) {
  if (!periodos || periodos.length === 0) {
    return null
  }

  periodos.sort(function (a, b) {
    if (a.de < b.de) return -1
    if (a.de > b.de) return 1
    if (a.ate < b.ate) return -1
    if (a.ate > b.ate) return 1
    return 0
  })

  const periodosMesclados = []
  let de = -1
  let ate = -1
  for (let periodo of periodos) {
    if (de === -1 || (periodo.de > ate && periodo.ate > ate)) {
      if (de !== -1) {
        periodosMesclados.push({ de: de, ate: ate })
      }
      de = periodo.de
      ate = periodo.ate
    } else if (periodo.ate > ate) {
      ate = periodo.ate
    }
  }
  periodosMesclados.push({ de: de, ate: ate })
  return periodosMesclados
}

const functionsObject = {
  arredondarPeriodoCincoMinutos: arredondarPeriodoCincoMinutos,
  buscarPeriodosDisponiveis: buscarPeriodosDisponiveis,
  gerarHorariosDisponiveis: gerarHorariosDisponiveis,
  mesclarListaPeriodos: mesclarListaPeriodos
}

export default functionsObject
