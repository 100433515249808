import { createEnum } from 'support/util/EnumUtil'

const StatusServicoEnum = createEnum({
  MARCADO: { id: 1, descricao: 'Horário Marcado', descricaoResumida: 'Marcado', cor: 13, selectable: true },
  CONFIRMADO: { id: 2, descricao: 'Confirmado', descricaoResumida: 'Confirmado', cor: 8, selectable: true },
  CLIENTE_CHEGOU: { id: 3, descricao: 'Cliente Chegou', descricaoResumida: 'Cliente chegou', cor: 15, selectable: true },
  EM_ATENDIMENTO: { id: 4, descricao: 'Em Atendimento', descricaoResumida: 'Em atendimento', cor: 4, selectable: true },
  CONCLUIDO: { id: 5, descricao: 'Concluído', descricaoResumida: 'Concluído', cor: 2, selectable: true },
  FILA_ESPERA: { id: 6, descricao: 'Fila de Espera', descricaoResumida: 'Fila de Espera', cor: 2, selectable: false },
  CLIENTE_FALTOU: { id: 8, descricao: 'Cliente Faltou', descricaoResumida: 'Cliente Faltou', cor: 16, selectable: true },
  CANCELADO: { id: 7, descricao: 'Cancelado', descricaoResumida: 'Cancelado', cor: 17, selectable: true }
})
export default StatusServicoEnum
