/* eslint-disable no-magic-numbers */
import { createStyles, makeStyles } from '@material-ui/core'

const INPUT_WIDTH = 138

export const useStyles = makeStyles((theme) =>
  createStyles({
    inputContainer: {
      width: INPUT_WIDTH + 110,
      [theme.breakpoints.down(380)]: {
        width: INPUT_WIDTH + 104
      },
      [theme.breakpoints.down(360)]: {
        width: INPUT_WIDTH + 84
      },
      [theme.breakpoints.down(340)]: {
        width: INPUT_WIDTH + 64
      }
    },
    containerTipo: {
      maxWidth: 110,
      [theme.breakpoints.down(380)]: {
        maxWidth: 104
      },
      [theme.breakpoints.down(360)]: {
        maxWidth: 84
      },
      [theme.breakpoints.down(340)]: {
        maxWidth: 64
      }
    },
    selectRootClass: {
      marginTop: '2px !important'
    }
  })
)
