import React from 'react'

import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  CAIXA_INDIVIDUAL: {
    id: 1,
    descricao: 'Caixa Individual',
    descricaoSimplificada: 'Individual',
    descricaoOpcao: 'Caixa Individual',
    descricaoExplicacao: <span>Apenas o colaborador dono do caixa pode manipulá-lo e adicionar entradas e saídas.</span>
  },
  CAIXA_COMPARTILHADO: {
    id: 2,
    descricao: 'Caixa Compartilhado',
    descricaoSimplificada: 'Compartilhado',
    descricaoOpcao: 'Caixa Compartilhado',
    descricaoExplicacao: <span>Todos os colaboradores com permissão podem manipular o caixa.</span>
  }
})

export default enumObject
