import React, { Component } from 'react'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionActions'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  panelRoot: {
    //boxShadow: "0px -1px 0px 0px rgba(0,0,0,0.1), 0px 1px 0px 0px rgba(0,0,0,0.1), 1px 0px 0px 0px rgba(0, 0, 0, 0.1), -1px 0px 1px 0px rgba(0,0,0,0.1)",
    border: '1px solid #e8e8e8',
    boxShadow: 'none',
    borderRadius: 10,

    '&:not(:last-child)': {
      //borderBottom: 0,
    },
    '&:last-child': {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10
    },
    '&:not(:first-child)': {
      marginTop: -1
    },
    '&:before': {
      display: 'none'
    }
  },
  panelExpanded: {
    margin: 'auto'
  },
  summaryRoot: {
    //backgroundColor: 'rgba(0,0,0,.03)',
    //borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$summaryExpanded': {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      //backgroundColor: '#f7f7f7',
      borderBottom: '1px solid #e8e8e8',
      minHeight: 56
    }
  },
  summaryContent: {
    margin: '22px 0 20px',
    '&$summaryExpanded': {
      margin: '22px 0 20px'
    }
  },
  summaryExpanded: {},
  summaryContainer: {
    display: 'flex'
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  detailsRoot: {
    padding: theme.spacing(2)
  },
  detailsContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'block',
    '& > :not(:first-child)': {
      marginLeft: 0
    }
  }
})

class SimpleExpansionPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: this.props.defaultExpanded ? this.props.defaultExpanded : false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.expandKey !== prevProps.expandKey && this.props.defaultExpanded !== undefined && this.props.defaultExpanded !== prevState.expanded) {
      this.setState({ expanded: this.props.defaultExpanded })
    }
  }

  render() {
    const { classes } = this.props
    const expanded = this.state.expanded

    let title = this.props.title
    if (typeof title == 'function') {
      title = title({ expanded: expanded })
    } else {
      title = <Typography variant="body1">{title}</Typography>
    }

    let subtitle = this.props.subtitle
    if (subtitle && (typeof subtitle === 'string' || (subtitle.type && subtitle.type === 'span'))) {
      subtitle = (
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )
    }

    let content = null
    if (expanded) {
      content = this.props.content
      if (typeof content === 'function') {
        content = content({ expanded: expanded })
      }
    }

    return (
      <Accordion
        classes={{
          root: classes.panelRoot,
          expanded: classes.panelExpanded
        }}
        expanded={expanded}
        onChange={() => {
          this.setState({ expanded: !expanded })
        }}
      >
        <AccordionSummary
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expanded: classes.summaryExpanded
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={classes.summaryContainer}>
            <div>
              {title}
              {subtitle}
            </div>
            {expanded && this.props.actionButton}
          </div>
        </AccordionSummary>
        {content && <AccordionDetails className={classes.detailsContainer}>{content}</AccordionDetails>}
      </Accordion>
    )
  }
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SimpleExpansionPanel)
