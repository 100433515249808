import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import MinhaContaPanelPage from 'pages/conta/painel/MinhaContaPanelPage'
import FavoritosPanelPage from 'pages/portal/painel/favorito/FavoritosPanelPage'
import MeusAgendamentosPanelPage from 'pages/portal/painel/meusagendamentos/MeusAgendamentosPanelPage'
import SobrePanelPage from 'pages/portal/painel/sobre/SobrePanelPage'
import TermosEPrivacidadePanelPage from 'pages/portal/painel/termoseprivacidade/TermosEPrivacidadePanelPage'
import { ROUTE_PORTAL_PANEL } from 'pages/RouteMap'
import RouterContent from 'support/components/router/RouterContent'

class RoutesPortal extends Component {
  /*shouldComponentUpdate(nextProps, nextState) {

		if (this.props.desktopDrawerOpen !== undefined && this.props.desktopDrawerOpen !== null) {
			if((this.props.desktopDrawerOpen !== nextProps.desktopDrawerOpen || this.props.mobileDrawerOpen !== nextProps.mobileDrawerOpen) && this.props.panelPagesProps.toolbarExpanded === nextProps.panelPagesProps.toolbarExpanded){
				console.log("[OTIMIZACAO]: render RoutesPortal");
				return false;
			}

		}
		return true;

	}*/

  render() {
    const panelPagesProps = this.props

    if (!this.props.usuarioSessao || this.props.usuarioSessao.carregado === false) {
      return []
    }

    return (
      <Switch>
        <Route
          path={ROUTE_PORTAL_PANEL + '/p/conta'}
          render={(props) => <RouterContent title="Minha Conta" {...panelPagesProps} component={MinhaContaPanelPage} sendPageViewGA={true} />}
        />
        <Route
          path={ROUTE_PORTAL_PANEL + '/p/agendamentos'}
          render={(props) => <RouterContent title="Meus Agendamentos" {...panelPagesProps} component={MeusAgendamentosPanelPage} sendPageViewGA={true} />}
        />
        <Route
          path={ROUTE_PORTAL_PANEL + '/p/favoritos'}
          render={(props) => <RouterContent title="Favoritos" {...panelPagesProps} component={FavoritosPanelPage} sendPageViewGA={true} />}
        />
        <Route
          path={ROUTE_PORTAL_PANEL + '/p/sobresalao99'}
          render={(props) => <RouterContent title="Sobre o Salão99" {...panelPagesProps} component={SobrePanelPage} sendPageViewGA={true} />}
        />
        <Route
          path={ROUTE_PORTAL_PANEL + '/p/termoseprivacidade'}
          render={(props) => <RouterContent title="Termos e Privacidade" {...panelPagesProps} component={TermosEPrivacidadePanelPage} sendPageViewGA={true} />}
        />
      </Switch>
    )
  }
}

export default RoutesPortal
