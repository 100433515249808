import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'

const MAX_WIDTH_CONTENT = 760

const styles = (theme) => ({
  containerPageFullScreenMode: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: MAX_WIDTH_CONTENT,
    textAlign: 'center',
    ...theme.screen.marginStyles()
  }
})

class AcessoNegadoPanelPage extends Component {
  render() {
    const { classes, ...others } = this.props

    let content = (
      <React.Fragment>
        <Typography variant="body1" align="center">
          <b>Você não tem permissão para acessar essa página.</b>
        </Typography>
        <Typography variant="body2" align="center">
          Se você acredita que deve ter acesso a essa funcionalidade, entre em contato com o responsável pelo sistema na sua empresa.
        </Typography>
      </React.Fragment>
    )

    if (this.props.panelPageFullScreenMode) {
      content = <div className={classes.containerPageFullScreenMode}>{content}</div>
    } else {
      content = (
        <FloatContent maxWidth={MAX_WIDTH_CONTENT} align="center">
          {content}
        </FloatContent>
      )
    }

    return (
      <PanelPage {...others} title={this.props.title}>
        {content}
      </PanelPage>
    )
  }
}

AcessoNegadoPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AcessoNegadoPanelPage)
