import StyleUtil from 'support/util/StyleUtil'

function getStyles({ defaultTheme, tema, constant }) {
  return {
    util: {
      showMobile: function () {
        return {
          [defaultTheme.breakpoints.up('sm')]: {
            display: 'none'
          }
        }
      },
      showDesktop: function () {
        return {
          [defaultTheme.breakpoints.down('xs')]: {
            display: 'none'
          }
        }
      },
      createBoxShadow: function (value) {
        return StyleUtil.createBoxShadow(value)
      }
    }
  }
}

const objectFunctions = {
  getStyles: getStyles
}

export default objectFunctions
