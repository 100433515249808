import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/ApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class DadosCadastraisDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: 'Dados Cadastrais',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'cda/usuarios.buscarPorSessao',
      params: {},
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({ usuario: response.data })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirUsuario()
  }

  persistirUsuario = () => {
    postAPI({
      url: 'cda/usuarios.alterarUsuario',
      data: this.getDadosUsuario(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoUsuario')
        EventsManager.pub('AtualizarSessao')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formUsuario')
      }
    })
  }

  getDadosUsuario = () => {
    const dados = {}
    dados.id = this.state.usuario.id
    dados.nome = this.inputNome.current.value
    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, usuario } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <div className={classes.papersContent}>
              <form id="formUsuario" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                <InputText
                  id="xnome"
                  defaultValue={usuario.nome}
                  customVariant={'outlined-small'}
                  shrink={false}
                  autoFocus={true}
                  disabled={this.state.ajaxing}
                  errorMessage={errorsMap('nome')}
                  label="Nome"
                  inputProps={{
                    maxLength: 255
                  }}
                  inputRef={this.inputNome}
                />

                <HiddenSubmitButton />
              </form>

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </div>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

DadosCadastraisDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DadosCadastraisDialogPage)
