import React, { Component } from 'react'

import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import EventIcon from '@material-ui/icons/Event'
import ScheduleIcon from '@material-ui/icons/Schedule'
import StoreIcon from '@material-ui/icons/Store'
import classNames from 'classnames'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CustomDialog from 'support/components/dialog/CustomDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import AtendimentoNotificacaoUsuarioTipoEnum from 'support/domain/atendimento/AtendimentoNotificacaoUsuarioTipoEnum'
import ServicoService from 'support/domain/servico/ServicoService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarNome } from 'support/util/TextFormatter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  header: {
    //...theme.container.basic(),
    position: 'relative',
    paddingTop: 4,
    paddingBottom: 16
  },
  container: {
    alignItems: 'flex-start'
  },
  root: {
    minWidth: 300,
    maxWidth: 460,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  dialogContent: {
    maxHeight: 1200,
    ...theme.container.basic(),
    paddingTop: theme.spacing(0),
    paddingBottom: 32
  },
  containerMarcado: {
    padding: '8px',
    color: '#00b170',
    bbackground: '#16c59e',
    borderRadius: 36,
    fontSize: 14,
    display: 'inline-block'
  },
  containerCancelado: {
    padding: '8px',
    color: '#e64b4b',
    bbackground: '#ec6969',
    borderRadius: 36,
    fontSize: 14,
    display: 'inline-block'
  },
  notificacaoIconeDataHora: {
    verticalAlign: 'middle',
    marginTop: -2,
    marginRight: 8,
    marginLeft: -2,
    fontSize: 20
  },
  notificacaoContainerEmpresa: {
    ppadding: 8,
    pbackground: '#f5f5f5',
    fontSize: 12,
    marginTop: 8,
    borderRadius: 22
  }
})

class AtendimentosNotificacoesUsuarioDialog extends Component {
  constructor(props) {
    super(props)

    this.mounted = true
    this.eventsManager = EventsManager.new()
    this.idUltimaNotificacaoAtendimentoLida = getSessaoPainel().usuarioEstadoAtualizado.idUltimaNotificacaoAtendimentoLida

    this.state = {
      lista: []
    }

    this.scrollContainerRef = React.createRef()
    this.virtualizedListFunctionMap = {}
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {}

  getRenderContent = (size, item, index) => {
    const { classes } = this.props
    const content = {}

    let descricaoAcao = ''
    let indicadorAcao = ''
    if (AtendimentoNotificacaoUsuarioTipoEnum.AGENDAMENTO.id === item.tipo.id) {
      descricaoAcao = 'Agendado em '
      indicadorAcao = <div className={classes.containerMarcado}>Agendamento</div>
    } else if (AtendimentoNotificacaoUsuarioTipoEnum.CANCELAMENTO.id === item.tipo.id) {
      descricaoAcao = 'Cancelado em '
      indicadorAcao = <div className={classes.containerCancelado}>Cancelamento</div>
    }

    const atendimento = item.atendimento

    let itemLido = true
    if (this.idUltimaNotificacaoAtendimentoLida && this.idUltimaNotificacaoAtendimentoLida < item.id) {
      itemLido = false
    }

    content.content = (
      <div style={{ padding: '18px 18px', border: '1px solid #eee', borderRadius: 8, marginTop: 0, marginBottom: 4, background: !itemLido ? '#f8f8f8' : '' }}>
        <Grid container alignItems="center" direction="row-reverse" style={{ marginBottom: 10 }}>
          <Grid item>{indicadorAcao}</Grid>
          <Grid item xs style={{ minWidtsh: 190, marginBottom: -2 }}>
            <Typography variant="body1" style={{ fontSize: 14, marginBottom: 2 }}>
              <EventIcon className={classes.notificacaoIconeDataHora} />
              {converterDataIntParaMoment(atendimento.data).format('DD/MMM/YY')}
            </Typography>
            <Typography variant="body1" style={{ fontSize: 14 }}>
              <ScheduleIcon className={classes.notificacaoIconeDataHora} />
              {formatarHorarioInt(atendimento.horarioInicio)}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="body2" style={{ marginBottom: 0 }}>
          <b>{ServicoService.gerarNomeCompletoServico(atendimento.servico, atendimento.variacaoServico)}</b>
        </Typography>
        <Typography variant="body2">Cliente: {formatarNome(atendimento.venda.cliente.apelido, 2)}</Typography>
        <Typography variant="body2">Profissional: {formatarNome(atendimento.venda.colaborador.apelido, 2)}</Typography>
        {atendimento.observacoesCliente && <Typography variant="body2">Observações: {atendimento.observacoesCliente}</Typography>}
        <Typography variant="caption" component="p" style={{ opacity: 0.6, marginTop: 2 }}>
          {descricaoAcao} {moment(item.dataCriacao).format('DD/MMM/YYYY [às] HH:mm:ss')}
        </Typography>
        {getSessaoPainel().negocio.id !== atendimento.negocio.id && (
          <div className={classes.notificacaoContainerEmpresa}>
            <Grid container alignItems="center">
              <Grid item style={{ marginRight: 4, marginLeft: 4 }}>
                <StoreIcon style={{ fontSize: 18, display: 'block' }} />
              </Grid>
              <Grid item xs>
                <b>{atendimento.negocio.nome}</b>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    )
    return content
  }

  marcarNotificacoesComoLidas = (items) => {
    if (this.jaMarcouNotificacoesComoLidas) {
      return
    }

    if (items.length > 0) {
      postAPI({
        url: 'erp/atendimentos.marcarUltimaNotificacaoAtendimentoLida',
        data: {
          idUltimaNotificacaoAtendimentoLida: items[0].id
        },
        onSuccess: () => {
          EventsManager.pub('AtualizarUsuarioEstadoAtualizado')
        },
        requerAutorizacao: true,
        sendErroToGenericSnackbar: false
      })
      this.jaMarcouNotificacoesComoLidas = true
    }
  }

  render() {
    const { classes } = this.props
    const dialogProps = extractDialogProps(this.props)

    return (
      <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={dialogProps} disabledOnClose={this.state.ajaxing}>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent className={classNames(classes.dialogContent, 'needsWillChange')}>
            <div className={classes.header}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="h6">Notificações</Typography>
                  <Typography variant="body2">Agendamentos Online</Typography>
                </Grid>
                <Grid item style={{ marginRight: -8 }}>
                  <IconButton
                    onClick={() => {
                      this.props.handleCloseDialog()
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <div style={{ width: '100%', position: 'relative' }}>
              <VirtualizedResponsiveTable
                scrollElement={this.scrollContainerRef.current}
                showHeaderColumns={false}
                showRowBorders={false}
                itemsPerRequest={20}
                loaderFunctionsMap={this.loaderFunctionsMap}
                showBackgroundDividers={false}
                dynamicHeight={true}
                loadingIndicatorRowHeight={200}
                contextoUsuario="erp"
                endpoint={'erp/atendimentos.buscarAtendimentosNotificacoesUsuario'}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    this.setState({ lista: data.items })
                    this.marcarNotificacoesComoLidas(data.items)
                  }
                }}
                largeRenderProps={{
                  //rowHeight : 200,
                  columns: [{ props: { xs: true }, horizontalPadding: 'none' }],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('large', item, index)
                    return {
                      itemData: [content.content]
                    }
                  }
                }}
                smallRenderProps={{
                  //rowHeight : 200,
                  horizontalPadding: 'none',
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('small', item, index)
                    return {
                      itemData: content.content
                    }
                  }
                }}
                emptyListProps={{
                  align: 'center',
                  padding: true,
                  text: 'Você não possui nenhuma notificação de agendamento online'
                }}
                listFunctionsMap={this.virtualizedListFunctionMap}
              />
            </div>
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.virtualizedListFunctionMap && this.virtualizedListFunctionMap.recomputeRowHeights && this.mounted) {
      this.virtualizedListFunctionMap.recomputeRowHeights(this.state.lista.length - 1)
    }
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    this.mounted = false
  }
}

AtendimentosNotificacoesUsuarioDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtendimentosNotificacoesUsuarioDialog)
