import React, { Component } from 'react'
import 'react-image-lightbox/style.css'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import UploadFotosDialog from 'pages/erp/painel/negocio/UploadFotosDialog'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog, openBackableLightbox } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LightboxFotoGaleria from 'support/components/lightbox/LightboxFotoGaleria'
import 'support/components/lightbox/style.css'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import NegocioFotoTipoEnum from 'support/domain/negocio/NegocioFotoTipoEnum'
import ImageUtil from 'support/util/ImageUtil'

const IMAGE_GRID_HEIGHT = 240
const IMAGE_GRID_MARGIN = 12

const styles = (theme) => ({
  titulo: {
    fontSize: 16
  },
  subtitulo: {
    lineHeight: '17px',
    display: 'block',
    fontSize: '14px',
    color: '#afafaf',
    marginTop: 1
  },
  containerFoto: {
    height: IMAGE_GRID_HEIGHT,
    width: '100%',
    position: 'relative'
  },
  foto: {
    cursor: 'pointer',
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    '&:hover': {
      opacity: 0.85
    }
  },
  fotoInfo: {
    borderRadius: 6,
    padding: '100px 12px 12px 8px',
    opacity: 1,
    textShadow: '1px 1px 1px rgba(0,0,0,0.4)',
    color: 'white',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    textAlign: 'right',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);'
  },
  fotoInfoData: {
    display: 'inline-block',
    fontSize: '12px'
  }
})

class TabFotos extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      paginaCarregada: false,
      lista: [],
      listaComoLinhasFotos: []
    }
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoFotoGaleria', (props) => {
      this.loaderFunctionsMap.refresh()
    })
  }

  handlerOpenChooseDialog = () => {
    if (verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(UploadFotosDialog, {
        negocio: this.props.negocio,
        tipo: this.props.tipo
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  openLightboxFotoGaleria = (fotos, index) => {
    openBackableLightbox(LightboxFotoGaleria, {
      removerFotoFunction: (dialogInstance, foto) => {
        if (verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
          openBackableDialog(EntityRemovalDialog, {
            parent: dialogInstance,
            title: 'Remover Foto',
            text: <span>Você tem certeza que deseja remover esta foto? Essa operação não poderá ser desfeita.</span>,
            call: {
              method: 'post',
              url: 'erp/negocios.removerFotoGaleria',
              contextoUsuario: 'erp',
              data: {
                id: foto.id
              },
              onSuccess: (response, dialog) => {
                dialog.props.setShouldCloseParent(true)
                EventsManager.pub('ManipulacaoFotoGaleria')
              }
            }
          })
        } else {
          openBackableDialog(AccessDeniedDialog)
        }
      },
      mostrarOpcaoDownload: true,
      fotos: fotos,
      index: index
    })
  }

  createFotoElement = (linhaFotos, linha, coluna, size) => {
    const { listaComoLinhasFotos } = this.state
    const { classes } = this.props
    const fotoNegocio = linhaFotos[coluna]

    if (fotoNegocio) {
      const variacoesFoto = ImageUtil.extrairVariacoesImagem(fotoNegocio.foto)
      const style = {}
      if (size === 'large') {
        if (coluna === 0) {
          style.right = IMAGE_GRID_MARGIN / 2
        } else if (coluna === 1) {
          style.left = IMAGE_GRID_MARGIN / 2
          style.right = style.left
        } else if (coluna === 2) {
          style.left = IMAGE_GRID_MARGIN / 2
        }
        if (linha === 0) {
          style.bottom = IMAGE_GRID_MARGIN / 2
        } else if (linha === listaComoLinhasFotos.length - 1) {
          style.top = IMAGE_GRID_MARGIN / 2
        } else {
          style.top = IMAGE_GRID_MARGIN / 2
          style.bottom = IMAGE_GRID_MARGIN / 2
        }
      } else {
        style.marginBottom = IMAGE_GRID_MARGIN
      }

      style.backgroundImage = 'url(' + variacoesFoto.get(1) + ')'
      return (
        <div className={classes.containerFoto} key={fotoNegocio.id}>
          <div
            className={classes.foto}
            style={style}
            onClick={() => {
              const { lista } = this.state
              const index = fotoNegocio._index
              this.openLightboxFotoGaleria(lista, index)
            }}
          ></div>
        </div>
      )
    } else {
      return ''
    }
  }

  render() {
    const { classes } = this.props
    const { lista, listaComoLinhasFotos } = this.state

    let textoFotosEncontradas = null
    //let textoFotosNaoEncontradas = null;

    if (NegocioFotoTipoEnum.APRESENTACAO_NEGOCIO.id === this.props.tipo.id) {
      textoFotosEncontradas = (
        <span className={classes.titulo}>
          Apresentação do Negócio<span className={classes.subtitulo}>Adicione fotos da fachada do estabelecimento, ambientes internos, instalações, equipamentos e etc.</span>
        </span>
      )
      //textoFotosNaoEncontradas = "Nenhuma foto foi adicionada. Adicione fotos da fachada do estabelecimento, ambientes internos, instalações, equipamentos e etc."
    } else if (NegocioFotoTipoEnum.PORTFOLIO.id === this.props.tipo.id) {
      textoFotosEncontradas = (
        <span className={classes.titulo}>
          Portfólio de Serviços<span className={classes.subtitulo}>Adicione fotos que mostrem a qualidade dos serviços realizados recentemente.</span>
        </span>
      )
      //textoFotosNaoEncontradas = "Nenhuma foto foi adicionada. Adicione fotos que mostrem a qualidade dos serviços realizados recentemente."
    }

    return (
      <TabContainer>
        {this.state.paginaCarregada && (
          <TabContainerPersistHeader
            text={lista && lista.length > 0 ? textoFotosEncontradas : textoFotosEncontradas}
            buttonsContent={
              <Button color="secondary" variant="text" style={{ marginRight: -8, marginLeft: -8 }} onClick={this.handlerOpenChooseDialog}>
                <AddIcon />
                <span style={{ marginLeft: 8, marginRight: 0 }}>ADICIONAR</span>
              </Button>
            }
          />
        )}

        <VirtualizedResponsiveTable
          scrollElement={this.props.scrollElement}
          showBackgroundDividers={false}
          showHeaderColumns={false}
          showRowBorders={false}
          loaderFunctionsMap={this.loaderFunctionsMap}
          columns={[
            { label: 'Foto1', horizontalPadding: 'none', props: { xs: true } },
            { label: 'Foto2', horizontalPadding: 'none', props: { xs: true } },
            { label: 'Foto3', horizontalPadding: 'none', props: { xs: true } }
          ]}
          contextoUsuario="erp"
          dynamicHeight={true}
          endpoint="erp/negocios.buscarFotos"
          getRequestParametersFunction={() => ({
            idNegocio: this.props.negocio.id,
            idTipo: this.props.tipo.id
          })}
          items={listaComoLinhasFotos}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const listaComoLinhasFotos = []
              let linhaAtual = null

              for (let i = 0; i < data.items.length; i++) {
                const fotoNegocio = data.items[i]
                fotoNegocio._index = i
                if (i % 3 === 0) {
                  linhaAtual = []
                  listaComoLinhasFotos.push(linhaAtual)
                }
                linhaAtual.push(fotoNegocio)
              }

              this.setState({
                paginaCarregada: true,
                listaComoLinhasFotos: listaComoLinhasFotos,
                lista: data.items
              })
            }
          }}
          largeRenderProps={{
            columns: [
              { label: 'Foto1', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false },
              { label: 'Foto2', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false },
              { label: 'Foto3', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false }
            ],
            itemRenderer: (linhaFotos, index) => {
              const createFotoElement = (linha, coluna, size) => {
                const fotoNegocio = linhaFotos[coluna]
                if (fotoNegocio) {
                  const variacoesFoto = ImageUtil.extrairVariacoesImagem(fotoNegocio.foto)
                  const style = {}
                  if (size === 'large') {
                    if (coluna === 0) {
                      style.right = IMAGE_GRID_MARGIN / 2
                    } else if (coluna === 1) {
                      style.left = IMAGE_GRID_MARGIN / 2
                      style.right = style.left
                    } else if (coluna === 2) {
                      style.left = IMAGE_GRID_MARGIN / 2
                    }
                    if (linha === 0) {
                      style.bottom = IMAGE_GRID_MARGIN / 2
                    } else if (linha === listaComoLinhasFotos.length - 1) {
                      style.top = IMAGE_GRID_MARGIN / 2
                    } else {
                      style.top = IMAGE_GRID_MARGIN / 2
                      style.bottom = IMAGE_GRID_MARGIN / 2
                    }
                  } else {
                    style.marginBottom = IMAGE_GRID_MARGIN
                  }

                  style.backgroundImage = 'url(' + variacoesFoto.get(1) + ')'
                  return (
                    <div className={classes.containerFoto}>
                      <div
                        className={classes.foto}
                        style={style}
                        onClick={() => {
                          const { lista } = this.state
                          const index = fotoNegocio._index
                          this.openLightboxFotoGaleria(lista, index)
                        }}
                      ></div>
                    </div>
                  )
                } else {
                  return ''
                }
              }

              return {
                itemData: [createFotoElement(index, 0, 'large'), createFotoElement(index, 1, 'large'), createFotoElement(index, 2, 'large')]
              }
            }
          }}
          smallRenderProps={{
            horizontalPadding: 'none',
            itemRenderer: (linhaFotos, index) => {
              return {
                itemData: [
                  this.createFotoElement(linhaFotos, index, 0, 'small'),
                  this.createFotoElement(linhaFotos, index, 1, 'small'),
                  this.createFotoElement(linhaFotos, index, 2, 'small')
                ]
              }
            }
          }}
        />
      </TabContainer>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabFotos.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabFotos)
