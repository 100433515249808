import NegocioService from 'support/domain/negocio/NegocioService'

function gerarUrlNegocio(negocio) {
  return '/' + negocio.id + '/' + NegocioService.converterHashParaFormatoReduzido(negocio.hash)
}

const functionsObject = {
  gerarUrlNegocio: gerarUrlNegocio
}

export default functionsObject
