import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { calcularPixelsPorMinuto, COLUNA_ITENS_WIDTH_DEFAULT, HEADER_HEIGHT, INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import AgendaOperacao from 'pages/erp/painel/atendimento/agendainterface/AgendaOperacao'
import BarraFerramentasEsquerda from 'pages/erp/painel/atendimento/agendainterface/BarraFerramentasEsquerda'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'

const styles = (theme) => ({
  root: {
    display: 'flex',
    ...theme.panelPage.containerBodyFullScreen({ headerHeight: HEADER_HEIGHT })
  }
})

class AgendaBody extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)

    const intervaloMinutosExibicaoTempo = this.colaboradorLocalPreferences.getPreference('AgendaInterface_IntervaloMinutosExibicaoTempo', INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT)

    this.state = {
      colunaWidth: this.colaboradorLocalPreferences.getPreference('AgendaInterface_ColunaWidth', COLUNA_ITENS_WIDTH_DEFAULT),
      containerColunasWidth: this.colaboradorLocalPreferences.getPreference('AgendaInterface_ContainerColunasWidth', 0),
      colunaAutoWidth: this.colaboradorLocalPreferences.getPreference('AgendaInterface_ColunaAutoWidth', true),
      intervaloMinutosExibicaoTempo: intervaloMinutosExibicaoTempo,
      pixelsPorMinuto: calcularPixelsPorMinuto(intervaloMinutosExibicaoTempo)
    }
  }

  componentDidMount() {
    this.eventsManager.sub('AgendaInterface', (props) => {
      if (props.action === 'changeColunaWidth') {
        this.changeColunaWidth(props.colunaWidth)
      }
    })
  }

  changeColunaWidth = (colunaWidth) => {
    if (this.state.colunaWidth !== colunaWidth) {
      this.colaboradorLocalPreferences.setPreference('AgendaInterface_ColunaWidth', colunaWidth, { type: 'permanent' })
      this.setState({ colunaWidth: colunaWidth })
    }
  }

  render() {
    const { classes, ...othersProps } = this.props
    delete othersProps.children
    delete othersProps.style

    othersProps.colunaWidth = this.state.colunaWidth
    othersProps.changeColunaWidth = (colunaWidth) => {
      this.changeColunaWidth(colunaWidth)
    }
    othersProps.containerColunasWidth = this.state.containerColunasWidth
    othersProps.changeContainerColunasWidth = (containerColunasWidth) => {
      this.colaboradorLocalPreferences.setPreference('AgendaInterface_ContainerColunasWidth', containerColunasWidth, { type: 'permanent' })
      this.setState({ containerColunasWidth: containerColunasWidth })
    }
    othersProps.colunaAutoWidth = this.state.colunaAutoWidth
    othersProps.changeColunaAutoWidth = (colunaAutoWidth) => {
      this.colaboradorLocalPreferences.setPreference('AgendaInterface_ColunaAutoWidth', colunaAutoWidth, { type: 'permanent' })
      this.setState({ colunaAutoWidth: colunaAutoWidth })
    }
    othersProps.intervaloMinutosExibicaoTempo = this.state.intervaloMinutosExibicaoTempo
    othersProps.calcularIntervaloMinutosExibicaoTempo = (intervaloMinutosExibicaoTempo) => {
      return {
        intervaloMinutosExibicaoTempo: intervaloMinutosExibicaoTempo,
        pixelsPorMinuto: calcularPixelsPorMinuto(intervaloMinutosExibicaoTempo)
      }
    }
    othersProps.changeIntervaloMinutosExibicaoTempo = (intervaloMinutosExibicaoTempo) => {
      const dados = othersProps.calcularIntervaloMinutosExibicaoTempo(intervaloMinutosExibicaoTempo)
      this.colaboradorLocalPreferences.setPreference('AgendaInterface_IntervaloMinutosExibicaoTempo', dados.intervaloMinutosExibicaoTempo, { type: 'permanent' })
      this.setState(dados)
    }
    othersProps.pixelsPorMinuto = this.state.pixelsPorMinuto

    return (
      <div className={classes.root}>
        <BarraFerramentasEsquerda {...othersProps} />
        <AgendaOperacao {...othersProps} />
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AgendaBody.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AgendaBody)
