import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { MonetizationOn } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import SecurityIcon from '@material-ui/icons/Security'
import AlteracaoSenhaDialogPage from 'pages/conta/painel/AlteracaoSenhaDialogPage'
import DadosCadastraisDialogPage from 'pages/conta/painel/DadosCadastraisDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PanelPage from 'support/components/panel/PanelPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import NegocioPagamentoService from 'support/domain/negociopagamento/NegocioPagamentoService'

const styles = (theme) => ({
  listItem: {
    cursor: 'pointer',
    paddingTop: 2,
    paddingBottom: 2
  }
})

class MinhaContaPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'cda/usuarios.buscarPorSessao',
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          usuario: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoUsuario', (props) => {
      this.contentWithPreloadFunctions.reload()
    })
  }

  abrirDadosCadastrais = () => {
    openBackableDialog(DadosCadastraisDialogPage, { usuario: this.state.usuario })
  }

  abrirAlteracaoSenha = () => {
    openBackableDialog(AlteracaoSenhaDialogPage, { usuario: this.state.usuario })
  }

  shouldShowSubscription = () => {
    return this.props.negocioSessao && !DeviceIdentifier.isNativeIOS() && verificarAutorizacao([PA.PODE_VISUALIZAR_E_REALIZAR_PAGAMENTO_PLANO])
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} title="Minha Conta">
        <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarMain}>
          {() => (
            <React.Fragment>
              <Typography variant="h5">
                <b>{this.state.usuario.nome}</b>
              </Typography>
              <Typography variant="body1" style={{ marginBottom: 26 }}>
                {this.state.usuario.email}
              </Typography>

              <Divider />

              <List disablePadding component="nav" style={{ marginTop: 18 }}>
                <ListItem disableGutters onClick={() => this.abrirDadosCadastrais()} classes={{ root: classes.listItem }}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={<b>Dados Cadastrais</b>} secondary="Altere dados cadastrais da sua conta, como nome, e-mail e celular" />
                </ListItem>

                {this.shouldShowSubscription() && (
                  <ListItem
                    disableGutters
                    onClick={() => {
                      this.contentWithPreloadFunctions.reload()
                      window.location.href = NegocioPagamentoService.gerarUrlDeRedirecionamentoParaPlanos(this.props.negocioSessao.usaPagamentoRecorrente)
                    }}
                    classes={{ root: classes.listItem }}
                  >
                    <ListItemIcon>
                      <MonetizationOn />
                    </ListItemIcon>
                    <ListItemText primary={<b>Plano e Pagamento</b>} secondary="Altere seu plano ou forma de pagamento" />
                  </ListItem>
                )}

                <ListItem disableGutters onClick={() => this.abrirAlteracaoSenha()} classes={{ root: classes.listItem }}>
                  <ListItemIcon>
                    <SecurityIcon />
                  </ListItemIcon>
                  <ListItemText primary={<b>Alterar Senha</b>} secondary="Configure uma nova senha para acessar a sua conta" />
                </ListItem>
              </List>
            </React.Fragment>
          )}
        </ContentWithPreload>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

MinhaContaPanelPage.propTypes = {
  classes: PropTypes.object.isRequired,
  negocioSessao: PropTypes.object,
  usuarioSessao: PropTypes.object,
  getValue: PropTypes.func
}

export default withStyles(styles)(MinhaContaPanelPage)
