import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import NotaAbertaIcon from 'support/components/icon/NotaAberta'
import NotaFechadaIcon from 'support/components/icon/NotaFechada'
import NotaPendenteIcon from 'support/components/icon/NotaPendente'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'

const styles = (theme) => ({
  icon: {
    display: 'block'
  },
  regular: {
    fontSize: 24
  },
  regularNotaAberta: {
    fontSize: 22
  },
  small: {
    fontSize: 20
  },
  smallNotaAberta: {
    fontSize: 18
  },
  mini: {
    fontSize: 18
  },
  miniNotaAberta: {
    fontSize: 16
  }
})

class VendaStatusIcon extends Component {
  render() {
    const { classes, className, hasTooltip, status, vendaCancelada, size, ...others } = this.props

    let IconComponent = null
    let iconClasses = [className, classes.icon]

    let prefixClassName = size
    if (!prefixClassName) {
      prefixClassName = 'regular'
    }

    let descricaoStatus = ''
    if (vendaCancelada) {
      IconComponent = CancelOutlinedIcon
      iconClasses.push(classes[prefixClassName])
      descricaoStatus = 'Cancelado'
    } else if (status.id === StatusVendaEnum.NOTA_PENDENTE.id) {
      IconComponent = NotaPendenteIcon
      iconClasses.push(classes[prefixClassName])
      descricaoStatus = StatusVendaEnum.NOTA_PENDENTE.descricao
    } else if (status.id === StatusVendaEnum.NOTA_ABERTA.id) {
      IconComponent = NotaAbertaIcon
      iconClasses.push(classes[prefixClassName + 'NotaAberta'])
      descricaoStatus = StatusVendaEnum.NOTA_ABERTA.descricao
    } else if (status.id === StatusVendaEnum.NOTA_FECHADA.id) {
      IconComponent = NotaFechadaIcon
      iconClasses.push(classes[prefixClassName])
      descricaoStatus = StatusVendaEnum.NOTA_FECHADA.descricao
    }

    if (IconComponent) {
      const icon = <IconComponent {...others} className={classNames(iconClasses)} />
      if (hasTooltip && descricaoStatus) {
        return <Tooltip title={descricaoStatus}>{icon}</Tooltip>
      } else {
        return icon
      }
    } else {
      return null
    }
  }
}

VendaStatusIcon.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendaStatusIcon)
