import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import EventIcon from '@material-ui/icons/Event'
import HistoryIcon from '@material-ui/icons/History'
import StoreIcon from '@material-ui/icons/Store'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PortalApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CustomDialog from 'support/components/dialog/CustomDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import AntecedenciaCancelamentoAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaCancelamentoAgendamentoOnlineEnum'
import AtendimentoStatusServicoEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import ServicoService from 'support/domain/servico/ServicoService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import LocalizacaoFormatter from 'support/util/LocalizacaoFormatter'
import { formatarNome } from 'support/util/TextFormatter'
import { converterHorarioIntParaMinutos } from 'support/util/TimeConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'
import { formatarDuracao } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 600,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  container: {
    alignItems: 'flex-start'
  },
  contentContainer: {
    ...theme.container.basic(),
    paddingTop: 24,
    paddingBottom: 30
  },
  tituloDataHora: {
    fontSize: '28px',
    fontWeight: 700,
    marginLeft: 4,
    [theme.breakpoints.down(470)]: {
      fontSize: '24px',
      lineHeight: '30px',
      marginLeft: 6,
      marginTop: 4
    },
    [theme.breakpoints.down(130)]: {},
    [theme.breakpoints.down(190)]: {
      marginTop: 0,
      marginLeft: 4,
      fontSize: '24px',
      lineHeight: '30px'
    }
  },
  containerConcluido: {
    padding: '8px 12px',
    color: '#00bd92',
    background: '#e4fff5',
    borderRadius: 36,
    display: 'inline-block'
  },
  containerMarcado: {
    padding: '8px 12px',
    color: '#00bd92',
    background: '#e4fff5',
    borderRadius: 36,
    display: 'inline-block'
  },
  containerMarcadoIcone: {
    fontSize: 20,
    stroke: '#00bd92',
    strokeWidth: 1,
    marginTop: -3,
    verticalAlign: 'middle'
  },
  containerCancelado: {
    padding: '8px 12px',
    color: '#d62f2f',
    background: '#ffecec',
    borderRadius: 36,
    display: 'inline-block'
  },
  containerAusencia: {
    padding: '8px 12px',
    color: '#d62f2f',
    background: '#ffecec',
    borderRadius: 36,
    display: 'inline-block'
  },
  iconeSecao: {
    fontSize: 24
  },
  containerCancelamentoDialog: {
    color: theme.palette.text.secondary,
    marginBottom: 16
  }
})

class AtendimentoDetalheDialog extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {}
    this.contentWithPreloadFunctions = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoAtendimento', (props) => {
      this.contentWithPreloadFunctions.reload()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'ptl/atendimentos.buscarAtendimentoDetalhadoPorId',
      params: {
        id: this.props.idAtendimento
      },
      onSuccess: (response) => {
        const atendimento = response.data
        atendimento.historicoStatus.sort(function (a, b) {
          if (a.dataCriacao < b.dataCriacao) return -1
          if (a.dataCriacao > b.dataCriacao) return 1
          return 0
        })
        this.setState({
          atendimento: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      },
      requerAutorizacao: true
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { atendimento } = this.state

    return (
      <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={dialogProps}>
        <div className={classes.contentContainer}>
          <ContentWithPreload loadContentPadding={true} loadFunction={this.carregarMain} functionsMap={this.contentWithPreloadFunctions}>
            {() => {
              const dataHoraAtendimentoAsMoment = converterDataIntParaMoment(atendimento.data)
                .startOf('day')
                .add(converterHorarioIntParaMinutos(atendimento.horarioInicio), 'minutes')

              let statusContent = null
              let cancelado = false
              let processado = false

              if (AtendimentoStatusServicoEnum.CANCELADO.id === atendimento.status.id) {
                cancelado = true
                statusContent = (
                  <div className={classes.containerCancelado}>
                    <Typography variant="body1">
                      <b>Cancelado</b>
                    </Typography>
                  </div>
                )
              } else if (AtendimentoStatusServicoEnum.CLIENTE_FALTOU.id === atendimento.status.id) {
                cancelado = true
                statusContent = (
                  <div className={classes.containerAusencia}>
                    <Typography variant="body1">
                      <b>Ausência</b>
                    </Typography>
                  </div>
                )
              } else if (AtendimentoStatusServicoEnum.CONCLUIDO.id === atendimento.status.id) {
                statusContent = (
                  <div className={classes.containerConcluido}>
                    <Typography variant="body1">
                      <DoneIcon className={classes.containerMarcadoIcone} /> <b>Concluído</b>
                    </Typography>
                  </div>
                )
              } else {
                statusContent = (
                  <div className={classes.containerMarcado}>
                    <Typography variant="body1">
                      <DoneIcon className={classes.containerMarcadoIcone} /> <b>Marcado</b>
                    </Typography>
                  </div>
                )
              }

              if (atendimento.venda.notaVenda && atendimento.venda.notaVenda.id) {
                processado = true
              }

              let exibirBotaoCancelamento = false
              if (!cancelado && !processado && dataHoraAtendimentoAsMoment.diff(moment(), 'minutes') / 60 > 0) {
                exibirBotaoCancelamento = true
              }

              const descricaoEndereco = LocalizacaoFormatter.formatarEnderecoCompleto(atendimento.negocio.endereco)

              return (
                <React.Fragment>
                  <Grid container style={{ marginBottom: 24 }}>
                    <Grid item xs style={{ paddingRight: 8 }}>
                      <Typography variant="h6" className={classes.tituloDataHora}>
                        {converterDataIntParaMoment(atendimento.data).format('DD MMM YYYY')}{' '}
                        <span style={{ whiteSpace: 'nowrap' }}>Às {formatarHorarioInt(atendimento.horarioInicio)}</span>
                      </Typography>
                    </Grid>
                    <Grid style={{ marginRight: 0 }}>{statusContent}</Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 28 }}>
                    <Grid item>
                      <StoreIcon className={classes.iconeSecao} />
                    </Grid>
                    <Grid item xs style={{ marginLeft: 16 }}>
                      <Typography variant="body1">
                        <b>{atendimento.negocio.nome}</b>
                      </Typography>
                      {descricaoEndereco && <Typography variant="body1">{descricaoEndereco}</Typography>}
                      <Typography variant="body1">Telefones: {atendimento.negocio.telefones.map((telefone) => telefone.numero).join(', ')}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 24 }}>
                    <Grid item style={{ paddingBottom: exibirBotaoCancelamento ? 38 : 0 }}>
                      <EventIcon className={classes.iconeSecao} />
                    </Grid>
                    <Grid item style={{ marginLeft: 16 }} xs>
                      <Typography variant="body1">
                        <b>{ServicoService.gerarNomeCompletoServico(atendimento.servico, atendimento.variacaoServico)}</b>
                      </Typography>
                      <Typography variant="body1">Duração: {formatarDuracao(atendimento.variacaoServico.duracao, { descricao: 'minificada' })}</Typography>
                      <Typography variant="body1">Profissional: {formatarNome(atendimento.venda.colaborador.nome, 2)}</Typography>
                      {atendimento.observacoesCliente && <Typography variant="body1">Observações: {atendimento.observacoesCliente}</Typography>}
                      {exibirBotaoCancelamento && (
                        <Button
                          style={{ textTransform: 'none', fontSize: 16, marginLeft: -8, color: '#de4e4e' }}
                          onClick={() => {
                            const horasParaAtendimento = dataHoraAtendimentoAsMoment.diff(moment(), 'minutes') / 60

                            let dialogContent = null
                            let confirmButtonLabel = null

                            if (horasParaAtendimento >= atendimento.negocio.configuracoesGerais.antecedenciaCancelamentoAgendamentoOnline.id) {
                              dialogContent = 'Você confirmar o cancelamento deste agendamento?'
                              confirmButtonLabel = 'Confirmar'
                            } else {
                              dialogContent = (
                                <Typography variant="body1" style={{ color: '#d43333' }}>
                                  Sugerimos que entre em contato diretamente com a empresa/profissional para cancelar este serviço. Caso confirme o cancelamento por aqui, você
                                  estará ciente de que não está respeitando o prazo mínimo para cancelamento de acordo com a política de cancelamento da empresa/profissional.
                                </Typography>
                              )
                              confirmButtonLabel = 'Cancelar Mesmo Assim'
                            }

                            dialogContent = (
                              <div className={classes.containerCancelamentoDialog}>
                                <Typography variant="body1">
                                  <b>Política de cancelamento: </b>O agendamento pode ser cancelado até{' '}
                                  {
                                    AntecedenciaCancelamentoAgendamentoOnlineEnum.getById(atendimento.negocio.configuracoesGerais.antecedenciaCancelamentoAgendamentoOnline.id)
                                      .descricao
                                  }{' '}
                                  do horário marcado. {atendimento.negocio.configuracoesGerais.textoPoliticaCancelamentoAgendamentoOnline}
                                </Typography>
                                <br />
                                {dialogContent}
                              </div>
                            )

                            openBackableDialog(EntityConfirmationDialog, {
                              text: dialogContent,
                              confirmButtonLabel: confirmButtonLabel,
                              cancelButtonLabel: 'Voltar',
                              call: {
                                method: 'post',
                                url: 'ptl/atendimentos.cancelarAtendimento',
                                contextoUsuario: 'portal',
                                data: {
                                  idNegocio: atendimento.negocio.id,
                                  idAtendimento: atendimento.id
                                },
                                onSuccess: (response, dialogConfirmationInstance) => {
                                  dialogConfirmationInstance.props.handleCloseDialog({
                                    onClosedCallback: () => {
                                      EventsManager.pub('ManipulacaoAtendimento')
                                    }
                                  })
                                },
                                errorKey: 'atendimento'
                              }
                            })
                          }}
                        >
                          Cancelar Agendamento
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  {atendimento.historicoStatus && atendimento.historicoStatus.length > 0 && (
                    <Grid container alignItems="center" style={{ marginTop: 20 }}>
                      <Grid item>
                        <HistoryIcon className={classes.iconeSecao} />
                      </Grid>
                      <Grid item style={{ marginLeft: 16 }} xs>
                        {atendimento.historicoStatus
                          .filter((registro, index) =>
                            registro.status.id === AtendimentoStatusServicoEnum.MARCADO.id ||
                            registro.status.id === AtendimentoStatusServicoEnum.CONCLUIDO.id ||
                            registro.status.id === AtendimentoStatusServicoEnum.CANCELADO.id
                              ? true
                              : false
                          )
                          .map((registro, index) => {
                            let descricaoHistorico = index + 1 + '.'

                            if (AtendimentoStatusServicoEnum.MARCADO.id === registro.status.id) {
                              descricaoHistorico += ' Marcado'
                            } else if (AtendimentoStatusServicoEnum.CANCELADO.id === registro.status.id) {
                              descricaoHistorico += ' Cancelado'
                            } else if (AtendimentoStatusServicoEnum.CONCLUIDO.id === registro.status.id) {
                              descricaoHistorico += ' Concluído'
                            }

                            if (registro.colaborador && registro.colaborador.id) {
                              descricaoHistorico += ' pela Empresa/Profissional'
                            } else if (registro.cliente && registro.cliente.id) {
                              descricaoHistorico += ' por você'
                            }

                            const dataHora = moment(registro.dataCriacao)
                            descricaoHistorico += ' em ' + dataHora.format('DD/MM/YY [às] HH:mm')

                            return (
                              <Typography key={index} variant="body2" style={{ color: '#888', paddingTop: 2, paddingBottom: 2 }}>
                                {descricaoHistorico}
                              </Typography>
                            )
                          })}
                      </Grid>
                    </Grid>
                  )}

                  <div style={{ textAlign: 'right', marginTop: 20, marginRight: 0 }}>
                    <Button
                      style={{ textTransform: 'none', fontSize: 16, marginRight: 8 }}
                      color="primary"
                      onClick={() => {
                        EventsManager.pub('LimparMarcacaoServicos')
                        this.props.handleCloseDialog()
                      }}
                    >
                      Fechar
                    </Button>
                  </div>
                </React.Fragment>
              )
            }}
          </ContentWithPreload>
        </div>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AtendimentoDetalheDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtendimentoDetalheDialog)
