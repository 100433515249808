function gerarNomeCompletoServico(servico, variacao) {
  let nome = servico.nome
  if (variacao && variacao.nome) {
    nome += ': ' + variacao.nome
  }
  return nome
}

const functionsObject = {
  gerarNomeCompletoServico: gerarNomeCompletoServico
}

export default functionsObject
