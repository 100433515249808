import EstadoEnum from 'support/domain/localizacao/EstadoEnum'

function formatarEnderecoSimples(endereco) {
  return endereco.replace(/,\s[0-9]{5,5}-[0-9]{3,3}/i, '').replace(/,\sBra(s|z)il/i, '')
}

function formatarEnderecoResumido(endereco, format) {
  let enderecoFormatado = ''
  let ultimoElementoConcatenado = null

  if (endereco.endereco) {
    ultimoElementoConcatenado = 'endereco'
    enderecoFormatado = endereco.endereco
  }

  if (endereco.numero) {
    ultimoElementoConcatenado = 'numero'
    enderecoFormatado += ', ' + endereco.numero
  }

  if (endereco.complemento) {
    if (ultimoElementoConcatenado) {
      enderecoFormatado += ' - '
    }
    ultimoElementoConcatenado = 'complemento'
    enderecoFormatado += endereco.complemento
  }

  if (endereco.bairro) {
    if (ultimoElementoConcatenado) {
      if (ultimoElementoConcatenado === 'numero') {
        enderecoFormatado += ' - '
      } else {
        enderecoFormatado += ', '
      }
    }
    ultimoElementoConcatenado = 'bairro'
    enderecoFormatado += endereco.bairro
  }

  if (ultimoElementoConcatenado && ultimoElementoConcatenado !== 'bairro') {
    if (endereco.cidade) {
      if (ultimoElementoConcatenado) {
        if (ultimoElementoConcatenado === 'numero') {
          enderecoFormatado += ' - '
        } else {
          enderecoFormatado += ', '
        }
      }
      ultimoElementoConcatenado = 'cidade'
      enderecoFormatado += endereco.cidade
    }
  }

  return enderecoFormatado
}

function formatarEnderecoCompleto(endereco, format) {
  let enderecoFormatado = ''
  let ultimoElementoConcatenado = null

  if (endereco.endereco) {
    ultimoElementoConcatenado = 'endereco'
    enderecoFormatado = endereco.endereco
  }

  if (endereco.numero) {
    ultimoElementoConcatenado = 'numero'
    enderecoFormatado += ', ' + endereco.numero
  }

  if (endereco.complemento) {
    if (ultimoElementoConcatenado) {
      enderecoFormatado += ' - '
    }
    ultimoElementoConcatenado = 'complemento'
    enderecoFormatado += endereco.complemento
  }

  if (endereco.bairro) {
    if (ultimoElementoConcatenado) {
      if (ultimoElementoConcatenado === 'numero') {
        enderecoFormatado += ' - '
      } else {
        enderecoFormatado += ', '
      }
    }
    ultimoElementoConcatenado = 'bairro'
    enderecoFormatado += endereco.bairro
  }

  if (endereco.cidade) {
    if (ultimoElementoConcatenado) {
      if (ultimoElementoConcatenado === 'numero') {
        enderecoFormatado += ' - '
      } else {
        enderecoFormatado += ', '
      }
    }
    ultimoElementoConcatenado = 'cidade'
    enderecoFormatado += endereco.cidade
  }

  if (endereco.estado) {
    endereco.estado = EstadoEnum.getById(endereco.estado.id)

    if (ultimoElementoConcatenado === 'numero' || ultimoElementoConcatenado === 'cidade') {
      enderecoFormatado += ' - '
    } else {
      enderecoFormatado += ', '
    }
    ultimoElementoConcatenado = 'estado'
    enderecoFormatado += endereco.estado.sigla
  }

  if (endereco.cep) {
    enderecoFormatado += ', '
    enderecoFormatado += endereco.cep
  }

  return enderecoFormatado
}

const functionsObject = {
  formatarEnderecoSimples: formatarEnderecoSimples,
  formatarEnderecoResumido: formatarEnderecoResumido,
  formatarEnderecoCompleto: formatarEnderecoCompleto
}

export default functionsObject
