import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ConsultaAbatimentosDialogPage from 'pages/erp/painel/colaborador/ConsultaAbatimentosDialogPage'
import ValeDialogPage from 'pages/erp/painel/colaborador/ValeDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import FormUtil from 'support/util/FormUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 }),
    marginTop: 2
  },
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class TabVales extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      lista: [],
      paginaCarregada: false
    }
    this.loaderFunctionsMap = {}
  }

  handlerOpenCreateDialog = () => {
    if (!verificarAutorizacao([PA.PODE_GERENCIAR_VALES])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(ValeDialogPage, { idColaborador: this.props.colaborador.id })
  }

  handlerConsultarAbatimentos = (item) => {
    openBackableDialog(ConsultaAbatimentosDialogPage, { vale: item })
  }

  handlerRemover = (item) => {
    if (!verificarAutorizacao([PA.PODE_GERENCIAR_VALES])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Vale',
      text: (
        <span>
          Você tem certeza que deseja remover o vale <b>{item.nome}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/colaboradores.removerVale',
        contextoUsuario: 'erp',
        data: {
          id: item.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoColaborador')
        },
        errorKey: 'caixa'
      }
    })
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YYYY')
    content.descricao = item.nome
    content.valor = formatarValorMonetario(item.valor, { prefix: false })
    if (item.manipulavel) {
      if (size === 'small') {
        content.valorAbatido = 'Abatimentos: ' + formatarValorMonetario(item.valorAbatido)
      } else {
        content.valorAbatido = formatarValorMonetario(item.valorAbatido, { prefix: false })
      }
    } else {
      if (size === 'small') {
        content.valorAbatido = null
      } else {
        content.valorAbatido = '-'
      }
    }

    if (size === 'large') {
      content.botaoMenu = (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            this.setState({ valeMenuAnchorEl: event.currentTarget, itemMenu: item })
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )
    } else if (size === 'small') {
      content.botaoMenu = (
        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            this.setState({ valeMenuAnchorEl: event.currentTarget, itemMenu: item })
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )
    }

    return content
  }

  closeItemMenu() {
    this.setState((state) => ({
      valeMenuAnchorEl: null
    }))
  }

  render() {
    const { ajaxing, paginaCarregada, lista } = this.state
    const { classes } = this.props

    let headerText = null
    if (lista && lista.length > 0) {
      headerText = 'Registro de vales cadastrados'
    } else {
      headerText = 'Nenhum vale foi cadastrado.'
    }

    return (
      <TabContainer padding={false}>
        {paginaCarregada && (
          <TabContainerPersistHeader
            addTabContainerPadding={true}
            text={headerText}
            buttonsContent={
              <Button color="secondary" size="small" variant="outlined" onClick={this.handlerOpenCreateDialog}>
                <AddIcon style={{ marginLeft: -4 }} fontSize="small" />
                <span style={{ marginLeft: 4, marginRight: 0 }}>ADICIONAR</span>
              </Button>
            }
          />
        )}

        <VirtualizedResponsiveTable
          disabled={ajaxing}
          scrollElement={this.props.scrollElement}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/colaboradores.buscarValesPorColaborador"
          getRequestParametersFunction={() => {
            return {
              idColaborador: this.props.colaborador.id
            }
          }}
          items={lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ errorsMap: FormUtil.createErrorsMap(), lista: [], paginaCarregada: false })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items, paginaCarregada: true })
            }
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Descrição', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
              { label: 'Abatimentos (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [content.data, content.descricao, content.valor, content.valorAbatido, content.botaoMenu]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 130,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center" style={{ minWidth: 0 }}>
                      <Grid item xs style={{ marginRight: 4, minWidth: 0 }}>
                        <Typography variant="body2">
                          <b>{content.data}</b>
                        </Typography>
                        <Typography variant="body2" className={classes.descricaoContainerSmall}>
                          {content.descricao}
                        </Typography>
                        <Typography variant="body2">Valor: R$ {content.valor}</Typography>
                        <Typography variant="body2">{content.valorAbatido}</Typography>
                      </Grid>
                      <Grid item>{content.botaoMenu}</Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{}}
        />

        <Menu anchorEl={this.state.valeMenuAnchorEl} open={Boolean(this.state.valeMenuAnchorEl)} onClose={() => this.closeItemMenu()}>
          <MenuItem
            onClick={() => {
              this.handlerConsultarAbatimentos(this.state.itemMenu)
              this.closeItemMenu()
            }}
          >
            Consultar Abatimentos
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handlerRemover(this.state.itemMenu)
              this.closeItemMenu()
            }}
          >
            Remover Vale
          </MenuItem>
        </Menu>
      </TabContainer>
    )
  }
}

TabVales.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabVales)
