import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const MAX_MONTHS = 26

const styles = (theme) => ({
  container: {
    alignItems: 'flex-start'
  },
  root: {
    minWidth: 300,
    maxWidth: 560,
    width: '100%',
    margin: 4
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    position: 'relative',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    zIndex: 1
  },
  titleMainContent: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(12)
  },
  rightButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  },
  leftButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1)
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0
  },
  monthContainer: {
    marginTop: 24,
    marginLeft: 8,
    marginRight: 8,
    display: 'inline-block',
    maxWidth: 232,
    textAlign: 'center'
  },
  monthTitleContainer: {
    fontSize: 16,
    fontWeight: 500
  },
  dayContainer: {
    display: 'inline-block',
    width: 15,
    height: 15,
    textAlign: 'center',
    padding: 8,
    margin: 1,
    fontSize: 13,
    borderRadius: 20
  },
  dayContainerSelectable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee'
    }
  },
  dayContainerSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  }
})

class MultiDateDialogSelect extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      calendarData: this.generateCalendarData()
    }

    this.listaCached = []

    this.inputPesquisa = React.createRef()
    this.scrollContainerRef = React.createRef()
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {}

  generateCalendarData = () => {
    const baseDate = moment().startOf('month')
    const data = []
    const selectedDates = this.props.defaultValue ? this.props.defaultValue : []

    for (let i = 1; i <= MAX_MONTHS; i++) {
      let monthData = {
        description: baseDate.format('MMMM/YYYY'),
        days: []
      }

      const weekDay = parseInt(baseDate.format('d'), 10)

      if (weekDay !== 0) {
        for (let j = 0; j < weekDay; j++) {
          monthData.days.push({
            isEmpty: true,
            description: '0'
          })
        }
      }

      const today = parseInt(moment().format('DD'), 10)
      const lastMonthDay = parseInt(baseDate.clone().endOf('month').format('DD'), 10)

      for (let j = parseInt(baseDate.format('DD'), 10); j <= lastMonthDay; j++) {
        let dayAsInt = parseInt(baseDate.format('YYYYMMDD'), 10)
        let disabled = false

        if (i === 1 && j < today) {
          disabled = true
        }

        monthData.days.push({
          disabled: disabled,
          selected: selectedDates.indexOf(dayAsInt) >= 0 ? true : false,
          dayAsInt: dayAsInt,
          description: baseDate.format('D')
        })
        baseDate.add(1, 'days')
      }

      data.push(monthData)
    }
    return data
  }

  handleChangeDay = (dayAsInt, selected) => {
    const calendarData = JSON.parse(JSON.stringify(this.state.calendarData))
    main: for (let monthData of calendarData) {
      for (let day of monthData.days) {
        if (day.dayAsInt === dayAsInt) {
          day.selected = selected
          break main
        }
      }
    }
    this.setState({ calendarData: calendarData })
  }

  render() {
    const { classes } = this.props

    return (
      <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={this.props} disabledOnClose={this.state.ajaxing}>
        <DialogTitle disableTypography className={classes.rootTitle}>
          <IconButton
            className={classes.leftButton}
            onClick={() => {
              this.props.handleCloseDialog()
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={classes.titleMainContent}>
            Selecione...
          </Typography>
          <Button
            color="secondary"
            className={classes.rightButton}
            onClick={() => {
              const dates = []
              for (let monthData of this.state.calendarData) {
                for (let day of monthData.days) {
                  if (day.selected) {
                    dates.push(day.dayAsInt)
                  }
                }
              }
              this.props.onSelect(dates)
              this.props.handleCloseDialog()
            }}
          >
            OK
          </Button>
        </DialogTitle>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent className={classNames(classes.dialogContent, 'needsWillChange')} style={{ wwillChange: 'ttransform' }}>
            {this.state.calendarData.map((monthData, index) => {
              return (
                <MonthContainer
                  key={index}
                  classesObject={classes}
                  monthData={monthData}
                  onChange={(dayAsInt, selected) => {
                    this.handleChangeDay(dayAsInt, selected)
                  }}
                />
              )
            })}
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

class MonthContainer extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const propsClone = Object.assign({}, this.props.monthData)
    const nextPropsClone = Object.assign({}, nextProps.monthData)

    const filter = function (key, value) {
      if (key.charAt(0) === '_' || key === 'current') {
        return
      }
      return value
    }

    const propsCloneString = JSON.stringify(propsClone, filter)
    const nextPropsCloneString = JSON.stringify(nextPropsClone, filter)

    if (propsCloneString !== nextPropsCloneString) {
      return true
    } else {
      return false
    }
  }

  render() {
    const { classesObject } = this.props

    return (
      <div className={classesObject.monthContainer}>
        <span className={classesObject.monthTitleContainer}>{this.props.monthData.description}</span>
        <div style={{ textAlign: 'left', marginTop: 12 }}>
          {this.props.monthData.days.map((day, index) => {
            const spanProps = {
              style: {}
            }
            const dayClasses = [classesObject.dayContainer]

            if (day.isEmpty) {
              spanProps.style.visibility = 'hidden'
            } else {
              if (day.selected) {
                dayClasses.push(classesObject.dayContainerSelected)
              }

              if (day.disabled) {
                spanProps.style.opacity = 0.4
              } else {
                dayClasses.push(classesObject.dayContainerSelectable)
                spanProps.onClick = () => {
                  this.props.onChange(day.dayAsInt, !day.selected)
                }
              }
            }
            spanProps.className = classNames(dayClasses)
            return (
              <span key={index} {...spanProps}>
                {day.description}
              </span>
            )
          })}
        </div>
      </div>
    )
  }
}

MultiDateDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MultiDateDialogSelect)
