import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import AtivacaoFluxoCaixaEnum from 'support/domain/caixa/AtivacaoFluxoCaixaEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ConfiguracoesGeraisDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Configurações Gerais',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/negocio.buscarConfiguracoesGeraisNegocio',
      params: {
        idNegocio: this.props.negocio.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const configuracaoGeral = response.data.configuracaoGeral

        this.setState({
          controleFluxoCaixa: configuracaoGeral.controleFluxoCaixa,
          exibicaoCategoriaServicosMaisAgendados: configuracaoGeral.exibicaoCategoriaServicosMaisAgendados,
          exibicaoQualquerProfissional: configuracaoGeral.exibicaoQualquerProfissional,
          permitirFecharContaManualmenteVendaPacote: configuracaoGeral.permitirFecharContaManualmenteVendaPacote,
          horarioInteligenteAgendamentoOnline: configuracaoGeral.horarioInteligenteAgendamentoOnline
        })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  salvar = () => {
    postAPI({
      url: 'admin/negocios.alterarConfiguracoesGerais',
      data: {
        controleFluxoCaixa: {
          id: this.state.controleFluxoCaixa.id
        },
        horarioInteligenteAgendamentoOnline: this.state.horarioInteligenteAgendamentoOnline,
        exibicaoCategoriaServicosMaisAgendados: this.state.exibicaoCategoriaServicosMaisAgendados,
        exibicaoQualquerProfissional: this.state.exibicaoQualquerProfissional,
        permitirFecharContaManualmenteVendaPacote: this.state.permitirFecharContaManualmenteVendaPacote,
        idNegocio: this.props.negocio.id
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoNegocio')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formConfigGerais')
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formConfigGerais" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Grid container direction="column" alignItems="flex-start">
                <Grid item>
                  <InputSelect
                    style={{ marginTop: 2, marginBottom: 2 }}
                    label="Fluxo de Caixa"
                    fullWidth={false}
                    customVariant="outlined-small"
                    shrink={false}
                    value={this.state.controleFluxoCaixa.id}
                    onChange={(event) => {
                      const id = event.target.value
                      this.setState({ controleFluxoCaixa: AtivacaoFluxoCaixaEnum.getById(id) })
                    }}
                  >
                    {AtivacaoFluxoCaixaEnum.values().map((opcao) => (
                      <MenuItem key={opcao.id} value={opcao.id}>
                        {opcao.descricaoOpcao}
                      </MenuItem>
                    ))}
                  </InputSelect>
                </Grid>
                <Grid item>
                  <UncontrolledCheckbox
                    formControlProps={{
                      label: 'Horário Inteligente AO'
                    }}
                    defaultChecked={this.state.horarioInteligenteAgendamentoOnline}
                    onChange={(value) => {
                      this.setState((state) => {
                        state.horarioInteligenteAgendamentoOnline = value
                        return { horarioInteligenteAgendamentoOnline: state.horarioInteligenteAgendamentoOnline }
                      })
                    }}
                  />
                </Grid>
                <Grid item>
                  <UncontrolledCheckbox
                    formControlProps={{
                      label: 'Exibição Categoria Servicos Mais Agendados'
                    }}
                    defaultChecked={this.state.exibicaoCategoriaServicosMaisAgendados}
                    onChange={(value) => {
                      this.setState((state) => {
                        state.exibicaoCategoriaServicosMaisAgendados = value
                        return { exibicaoCategoriaServicosMaisAgendados: state.exibicaoCategoriaServicosMaisAgendados }
                      })
                    }}
                  />
                </Grid>
                <Grid item>
                  <UncontrolledCheckbox
                    formControlProps={{
                      label: 'Exibição Qualquer Profissional'
                    }}
                    defaultChecked={this.state.exibicaoQualquerProfissional}
                    onChange={(value) => {
                      this.setState((state) => {
                        state.exibicaoQualquerProfissional = value
                        return { exibicaoQualquerProfissional: state.exibicaoQualquerProfissional }
                      })
                    }}
                  />
                </Grid>
                <Grid item>
                  <UncontrolledCheckbox
                    formControlProps={{
                      label: 'Permitir Fechar Conta Manualmente Venda Pacote'
                    }}
                    defaultChecked={this.state.permitirFecharContaManualmenteVendaPacote}
                    onChange={(value) => {
                      this.setState((state) => {
                        state.permitirFecharContaManualmenteVendaPacote = value
                        return { permitirFecharContaManualmenteVendaPacote: state.permitirFecharContaManualmenteVendaPacote }
                      })
                    }}
                  />
                </Grid>
              </Grid>

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesGeraisDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesGeraisDialogPage)
