import React, { Component } from 'react'
import 'react-image-lightbox/style.css'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { openBackableLightbox } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import LightboxFotoGaleria from 'support/components/lightbox/LightboxFotoGaleria'
import 'support/components/lightbox/style.css'
import TabContainer from 'support/components/tab/TabContainer'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import ImageUtil from 'support/util/ImageUtil'

const IMAGE_GRID_HEIGHT = 260
const IMAGE_GRID_MARGIN = 20

const styles = (theme) => ({
  containerFoto: {
    height: IMAGE_GRID_HEIGHT,
    width: '100%',
    position: 'relative'
  },
  foto: {
    cursor: 'pointer',
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    '&:hover': {
      opacity: 0.85
    }
  },
  fotoInfo: {
    borderRadius: 6,
    padding: '100px 12px 12px 8px',
    opacity: 1,
    textShadow: '1px 1px 1px rgba(0,0,0,0.4)',
    color: 'white',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    textAlign: 'right',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);'
  },
  fotoInfoData: {
    display: 'inline-block',
    fontSize: '12px'
  },
  tabContainerRootNormal: {
    paddingBottom: 10
  }
})

class TabFotos extends Component {
  constructor(props) {
    super(props)

    const listaComoLinhasFotos = []
    let linhaAtual = null
    for (let i = 0; i < this.props.fotos.length; i++) {
      const fotoNegocio = this.props.fotos[i]
      fotoNegocio._index = i
      if (i % 3 === 0) {
        linhaAtual = []
        listaComoLinhasFotos.push(linhaAtual)
      }
      linhaAtual.push(fotoNegocio)
    }

    this.state = {
      listaComoLinhasFotos: listaComoLinhasFotos
    }
  }

  openLightboxFotoGaleria = (fotos, index) => {
    openBackableLightbox(LightboxFotoGaleria, {
      fotos: fotos,
      index: index
    })
  }

  createFotoElement = (linhaFotos, linha, coluna, size) => {
    const { listaComoLinhasFotos } = this.state
    const { classes } = this.props
    const fotoNegocio = linhaFotos[coluna]

    if (fotoNegocio) {
      const variacoesFoto = ImageUtil.extrairVariacoesImagem(fotoNegocio.foto)
      const style = {}
      if (size === 'large') {
        if (coluna === 0) {
          style.right = IMAGE_GRID_MARGIN / 2
        } else if (coluna === 1) {
          style.left = IMAGE_GRID_MARGIN / 2
          style.right = style.left
        } else if (coluna === 2) {
          style.left = IMAGE_GRID_MARGIN / 2
        }
        if (linha === 0) {
          style.bottom = IMAGE_GRID_MARGIN / 2 - 1
        } else if (linha === listaComoLinhasFotos.length - 1) {
          style.top = IMAGE_GRID_MARGIN / 2 - 1
        } else {
          style.top = IMAGE_GRID_MARGIN / 2 - 1
          style.bottom = IMAGE_GRID_MARGIN / 2 - 1
        }
      } else {
        style.marginBottom = IMAGE_GRID_MARGIN
      }

      style.backgroundImage = 'url(' + variacoesFoto.get(1) + ')'
      return (
        <div className={classes.containerFoto} key={fotoNegocio.id}>
          <div
            className={classes.foto}
            style={style}
            onClick={() => {
              const index = fotoNegocio._index
              this.openLightboxFotoGaleria(this.props.fotos, index)
            }}
          ></div>
        </div>
      )
    } else {
      return ''
    }
  }

  render() {
    const { classes } = this.props
    const { listaComoLinhasFotos } = this.state

    return (
      <TabContainer style={{ paddingTop: 20 }} classes={{ rootNormal: classes.tabContainerRootNormal }}>
        <VirtualizedResponsiveTable
          scrollElement={this.props.scrollElement}
          showBackgroundDividers={false}
          showHeaderColumns={false}
          showRowBorders={false}
          dynamicHeight={true}
          items={listaComoLinhasFotos}
          largeRenderProps={{
            columns: [
              { label: 'Foto1', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false },
              { label: 'Foto2', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false },
              { label: 'Foto3', horizontalPadding: 'none', props: { xs: true }, simulateEllipsisPaddingRight: false }
            ],
            itemRenderer: (linhaFotos, index) => {
              return {
                itemData: [
                  this.createFotoElement(linhaFotos, index, 0, 'large'),
                  this.createFotoElement(linhaFotos, index, 1, 'large'),
                  this.createFotoElement(linhaFotos, index, 2, 'large')
                ]
              }
            }
          }}
          smallRenderProps={{
            horizontalPadding: 'none',
            itemRenderer: (linhaFotos, index) => {
              return {
                itemData: [
                  this.createFotoElement(linhaFotos, index, 0, 'small'),
                  this.createFotoElement(linhaFotos, index, 1, 'small'),
                  this.createFotoElement(linhaFotos, index, 2, 'small')
                ]
              }
            }
          }}
        />
      </TabContainer>
    )
  }
}

TabFotos.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabFotos)
