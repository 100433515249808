import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/admin/usuario/PermissoesAcesso'

const styles = (theme) => ({
  colunaId: {
    flexGrow: 1,
    maxWidth: 50,
    minWidth: 50
  },
  colunaIcone: {
    minWidth: 70,
    maxWidth: 70
  },
  nomeNegocio: {
    ...theme.text.limitedLines({ maxLines: 3 })
  }
})

class SangriaEntradaResponsiveTable extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  getRenderContent = (size, item) => {
    const content = {}

    content.nomeNegocio = item.nome
    content.id = item.id

    if (item.colaboradores) {
      for (let colaborador of item.colaboradores) {
        if (colaborador.admin) {
          content.admin = colaborador.usuario?.email
          break
        }
      }
    }

    if (item.excluido) {
      content.situacao = (
        <span style={{ color: 'red' }}>
          <b>REMOVIDO</b>
        </span>
      )
    } else if (item.ativo) {
      content.situacao = <span style={{ color: 'green' }}>Ativo</span>
    } else {
      content.situacao = <span style={{ color: 'red' }}>Inativo</span>
    }

    if (!item.excluido) {
      content.botaoOpcoes = (
        <IconButton
          style={{ marginRight: size === 'small' ? -4 : 0 }}
          onClick={(event) => {
            event.stopPropagation()
            this.setState({ negocioMenuAnchorEl: event.currentTarget, itemMenu: item })
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )
    }

    return content
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  handlerRemover = () => {
    if (!verificarAutorizacao([PA.PODE_REMOVER_NEGOCIO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover negócio',
      text: (
        <span>
          Você tem certeza que deseja remover o negócio <b>{this.state.itemMenu.nome}</b>?
        </span>
      ),
      call: {
        method: 'post',
        url: 'admin/negocios.remover',
        contextoUsuario: 'admin',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoNegocio')
        }
      }
    })
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <React.Fragment>
        <VirtualizedResponsiveTable
          showHeaderColumns={false}
          showBackgroundDividers={true}
          itemsPerRequest={30}
          largeRenderProps={{
            columns: [
              { label: 'ID', className: classes.colunaId },
              { label: 'Negócio', props: { xs: true } },
              { label: 'Adm', props: { xs: true } },
              { label: 'Situação', horizontalPadding: 'small' },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)

              return {
                itemData: [content.id, content.nomeNegocio, content.admin, content.situacao, content.botaoOpcoes]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 90,
            showFirstItemBorderTop: false,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center" style={{ minWidth: 0 }}>
                      <Grid item xs>
                        <Typography variant="body2" noWrap={true}>
                          <b>Id:</b> {content.id}
                        </Typography>
                        <Typography variant="body2" className={classes.nomeNegocio}>
                          {content.nomeNegocio}
                        </Typography>
                        <Typography variant="body2">Admin: {content.admin}</Typography>
                        <Typography variant="body2">{content.situacao}</Typography>
                      </Grid>
                      <Grid item>{content.botaoOpcoes}</Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          {...others}
        />

        <Menu anchorEl={this.state.negocioMenuAnchorEl} open={Boolean(this.state.negocioMenuAnchorEl)} onClose={() => this.closeMenu('negocioMenuAnchorEl')}>
          <MenuItem
            onClick={() => {
              this.handlerRemover()
              this.closeMenu('negocioMenuAnchorEl')
            }}
          >
            Remover
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}

SangriaEntradaResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SangriaEntradaResponsiveTable)
