import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import AdquirenteInputDialogSelect from 'pages/erp/painel/formapagamento/input/AdquirenteInputDialogSelect'
import BandeiraInputDialogSelect from 'pages/erp/painel/formapagamento/input/BandeiraInputDialogSelect'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputPercentWithSuffixFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class FormaPagamentoConfiguracaoCreditoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.autocompleteAdquirente = {}
    this.autocompleteBandeira = {}
    this.containerAVistafunctionsMap = {}
    this.focarAdquirente = true
    this.inputTaxaAVista = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idConfiguracaoCartao) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })

      getAPI({
        url: 'erp/formaspagamento.buscarConfiguracaoCartao',
        params: {
          idConfiguracaoCartao: this.props.idConfiguracaoCartao
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.adquirente = response.data.formaPagamentoAdquirenteBandeira.formaPagamentoAdquirente.adquirente

          if (!this.props.duplicar) {
            this.bandeira = response.data.formaPagamentoAdquirenteBandeira.bandeira
            this.idConfiguracaoCartaoDebito = response.data.configuracaoCartaoDebito.id
          } else {
            this.focarAdquirente = false
          }

          this.configuracoesAVista = {}
          this.configuracoesAVista.taxaVista = response.data.configuracaoCartaoDebito.taxaVista

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      title: this.props.duplicar ? 'Duplicar' : this.bandeira ? 'Alterar' : 'Criar',
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  getDadosConfiguracao = () => {
    const formaPagamento = {}

    if (this.props.idBandeira && !this.props.duplicar) {
      formaPagamento.idBandeira = this.props.idBandeira
    }

    formaPagamento.formaPagamento = { id: this.props.formaPagamento.id }

    formaPagamento.adquirente = {
      id: this.autocompleteAdquirente.getValue()
    }

    formaPagamento.bandeira = {
      id: this.autocompleteBandeira.getValue()
    }

    if (this.inputTaxaAVista.current.value) {
      formaPagamento.taxaAVista = this.inputTaxaAVista.current.value === '' ? 0 : FormUtil.convertStringToNumber(this.inputTaxaAVista.current.value.replace('%', ''))
    } else {
      formaPagamento.taxaAVista = 0
    }

    formaPagamento.idConfiguracaoCartaoDebito = this.idConfiguracaoCartaoDebito
    return formaPagamento
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/formaspagamento.persistirConfiguracaoCartaoDebito',
      data: this.getDadosConfiguracao(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoFormaPagamentoCartao')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        const errorsMap = createErrorsMap(response.data.errors)
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formConfiguracaoFormaPagamento')

        if (errorsMap('configuracaoCartaoDebito')) {
          openBackableDialog(MessageDialog, {
            title: 'Configuração existente',
            text: errorsMap('configuracaoCartaoDebito')
          })
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formConfiguracaoFormaPagamento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <AdquirenteInputDialogSelect
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('adquirente')}
                placeholder="Selecione a maquininha"
                defaultValue={this.adquirente}
                functionsMap={this.autocompleteAdquirente}
              />

              <BandeiraInputDialogSelect
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('bandeira')}
                placeholder="Selecione a bandeira"
                defaultValue={this.bandeira}
                functionsMap={this.autocompleteBandeira}
              />

              <InputText
                marginTop={true}
                id="xtaxaAVista"
                name="xtaxaAVista"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={this.configuracoesAVista ? this.configuracoesAVista.taxaVista : undefined}
                disabled={this.state.ajaxing}
                inputComponent={InputPercentWithSuffixFormat}
                inputProps={{
                  minValue: 0,
                  maxValue: 100
                }}
                errorMessage={errorsMap('taxaAVista')}
                label="Taxa"
                inputRef={this.inputTaxaAVista}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

FormaPagamentoConfiguracaoCreditoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FormaPagamentoConfiguracaoCreditoDialogPage)
