import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import moment from 'moment'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CustomDataRangeDialog from 'support/components/navigator/CustomDataRangeDialog'
import TextDatePicker from 'support/components/picker/TextDatePicker'
import { createEnum } from 'support/util/EnumUtil'

export const TipoIntervaloEnum = createEnum({
  DIA: {
    id: 1,
    nome: 'dia',
    descricao: 'Dia',
    formato: 'ddd, DD/MMMM',
    chronoUnit: 'days',
    opcoes: (tipoIntervalo, dataInicial, dataFinal, onChange) => {
      return [
        {
          descricao: 'Hoje',
          dataInicial: moment(),
          dataFinal: moment()
        },
        {
          descricao: 'Ontem',
          dataInicial: moment().subtract(1, 'days'),
          dataFinal: moment().subtract(1, 'days')
        },
        {
          customContent: (
            <TextDatePicker
              key="outro"
              value={tipoIntervalo.id === TipoIntervaloEnum.DIA.id ? dataInicial.toDate() : new Date()}
              onChange={(date) => {
                onChange(TipoIntervaloEnum.DIA, moment(date), moment(date))
              }}
              renderComponent={(props) => {
                return (
                  <MenuItem
                    onClick={(event) => {
                      props.openPicker(event.clientX, event.clientY)
                    }}
                    align="center"
                  >
                    Outro...
                  </MenuItem>
                )
              }}
              autoOk={true}
            />
          )
        }
      ]
    }
  },
  SEMANA: {
    id: 2,
    nome: 'semana',
    descricao: 'Semana',
    formato: 'DD/MMM',
    chronoUnit: 'weeks',
    opcoes: (tipoIntervalo, dataInicial, dataFinal, onChange) => {
      return [
        {
          descricao: 'Essa semana',
          dataInicial: moment().startOf('week'),
          dataFinal: moment().endOf('week')
        },
        {
          descricao: 'Semana passada',
          dataInicial: moment().subtract(1, 'week').startOf('week'),
          dataFinal: moment().subtract(1, 'week').endOf('week')
        }
      ]
    }
  },
  MES: {
    id: 3,
    nome: 'mes',
    descricao: 'Mês',
    formato: 'MMMM/YYYY',
    chronoUnit: 'months',
    opcoes: (tipoIntervalo, dataInicial, dataFinal, onChange) => {
      const opcoes = []

      for (let i = 0; i < 6; i++) {
        const mes = moment().subtract(i, 'month')
        opcoes.push({
          descricao: mes.format('MMMM/YYYY'),
          dataInicial: mes.clone().startOf('month'),
          dataFinal: mes.clone().endOf('month')
        })
      }

      return opcoes
    }
  },
  PERSONALIZADO: {
    id: 4,
    nome: 'personalizado',
    descricao: 'Personalizado',
    formato: 'DD/MM/YY'
  }
})

const styles = (theme) => ({
  setaVoltarExpanded: {
    flexGrow: 0.3,
    textAlign: 'left',
    [theme.breakpoints.down(460)]: {
      flexGrow: 0.3
    },
    [theme.breakpoints.down(325)]: {
      flexGrow: 0.1
    }
  },
  setaAvancarExpanded: {
    flexGrow: 0.25,
    textAlign: 'center',
    [theme.breakpoints.down(460)]: {
      flexGrow: 0.3
    },
    [theme.breakpoints.down(325)]: {
      flexGrow: 0.1
    }
  },
  botaoSeta: {
    padding: 6,
    [theme.breakpoints.down(340)]: {
      padding: 4
    }
  },
  labelContainerExpanded: {
    flexGrow: 1.3
  },
  label: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(460)]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0)
    },
    [theme.breakpoints.down(340)]: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0)
    }
  },
  labelExpanded: {
    minWidth: 160
  },
  setaContainerAbrirExpanded: {
    flexGrow: 0.2
  },
  setaAbrir: {
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    marginTop: -2
  }
})

class DateRangeNavigator extends Component {
  constructor(props) {
    super(props)
    const { defaultValue } = props

    this.state = {
      tipoIntervaloMenu: null
    }

    if (defaultValue) {
      this.state.tipoIntervalo = TipoIntervaloEnum.getByNome(defaultValue.tipoIntervalo)
      this.state.dataInicial = defaultValue.dataInicial
      this.state.dataFinal = defaultValue.dataFinal
    } else {
      this.state.tipoIntervalo = TipoIntervaloEnum.MES
      this.state.dataInicial = moment().startOf('month')
      this.state.dataFinal = moment().endOf('month')
    }
  }

  alterarTipoIntervalo = (tipoIntervalo) => {
    if (tipoIntervalo.id === TipoIntervaloEnum.PERSONALIZADO.id) {
      this.closeMenu('periodoItemMenuAnchorEl')
      openBackableDialog(CustomDataRangeDialog, {
        setDatas: (datas) => {
          this.onChange(tipoIntervalo, datas.dataPesquisaInicial, datas.dataPesquisaFinal)
        }
      })
    } else {
      this.setState({ tipoIntervaloMenu: tipoIntervalo })
    }
  }

  selecionarOpcao = (tipoIntervalo, dataInicial, dataFinal) => {
    this.onChange(tipoIntervalo, dataInicial, dataFinal)
    this.closeMenu('periodoItemMenuAnchorEl')
  }

  onChange = (tipoIntervalo, dataInicial, dataFinal) => {
    if (this.props.onChange) {
      this.props.onChange({ tipoIntervalo, dataInicial, dataFinal })
    }

    this.setState({
      tipoIntervalo: tipoIntervalo,
      dataInicial: dataInicial,
      dataFinal: dataFinal
    })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  calcularDatasNavegacao = (avancar) => {
    const { tipoIntervalo, dataInicial, dataFinal } = this.state

    let novaDataInicial = dataInicial.clone().startOf('day')
    let novaDataFinal = dataFinal.clone().startOf('day')

    if (tipoIntervalo.chronoUnit) {
      if (avancar) {
        novaDataInicial = novaDataInicial.add(1, tipoIntervalo.chronoUnit)
        novaDataFinal = novaDataFinal.add(1, tipoIntervalo.chronoUnit)
      } else {
        novaDataInicial = novaDataInicial.subtract(1, tipoIntervalo.chronoUnit)
        novaDataFinal = novaDataFinal.subtract(1, tipoIntervalo.chronoUnit)
      }
    } else {
      let diferencaDias = novaDataFinal.diff(novaDataInicial, 'days') + 1
      if (avancar) {
        novaDataInicial = novaDataInicial.add(diferencaDias, 'days')
        novaDataFinal = novaDataFinal.add(diferencaDias, 'days')
      } else {
        novaDataInicial = novaDataInicial.subtract(diferencaDias, 'days')
        novaDataFinal = novaDataFinal.subtract(diferencaDias, 'days')
      }
    }

    if (tipoIntervalo.id === TipoIntervaloEnum.MES.id) {
      novaDataInicial = novaDataInicial.clone().startOf('month')
      novaDataFinal = novaDataInicial.clone().endOf('month')
    }

    return {
      dataInicial: novaDataInicial,
      dataFinal: novaDataFinal
    }
  }

  render() {
    const { expanded, classes } = this.props
    const { tipoIntervaloMenu, tipoIntervalo, dataInicial, dataFinal, periodoItemMenuAnchorEl } = this.state

    let labelData

    if (tipoIntervalo.id === TipoIntervaloEnum.SEMANA.id || tipoIntervalo.id === TipoIntervaloEnum.PERSONALIZADO.id) {
      labelData = dataInicial.format(tipoIntervalo.formato) + ' - ' + dataFinal.format(tipoIntervalo.formato)
    } else {
      labelData = dataInicial.format(tipoIntervalo.formato)
    }

    const rootStyle = {}
    if (expanded) {
      rootStyle.maxWidth = 280
      rootStyle.marginLeft = 'auto'
      rootStyle.marginRight = 'auto'
    }

    return (
      <React.Fragment>
        <Grid style={rootStyle} container justify="center" alignItems="center">
          <Grid item xs={expanded} className={expanded ? classes.setaVoltarExpanded : null}>
            <IconButton
              style={{ padding: 6 }}
              onClick={() => {
                const datas = this.calcularDatasNavegacao(false)

                this.onChange(tipoIntervalo, datas.dataInicial, datas.dataFinal)
              }}
            >
              <ChevronLeftIcon style={{ display: 'block' }} />
            </IconButton>
          </Grid>
          <Grid item style={{ marginRight: 4 }} xs={expanded} className={expanded ? classes.setaAvancarExpanded : null}>
            <IconButton
              className={classes.botaoSeta}
              onClick={() => {
                const datas = this.calcularDatasNavegacao(true)
                this.onChange(tipoIntervalo, datas.dataInicial, datas.dataFinal)
              }}
            >
              <ChevronRightIcon style={{ display: 'block' }} />
            </IconButton>
          </Grid>

          <Grid item xs className={expanded ? classes.labelContainerExpanded : null}>
            <Grid
              container
              alignItems="center"
              style={{ paddingTop: 1, cursor: 'pointer' }}
              onClick={(e) => {
                this.setState({ tipoIntervaloMenu: null, periodoItemMenuAnchorEl: e.currentTarget })
              }}
            >
              <Grid item xs={expanded} className={expanded ? classes.labelExpanded : classes.label}>
                <Typography variant="body1" align="center">
                  {labelData}
                </Typography>
              </Grid>
              <Grid item className={expanded ? classes.setaContainerAbrirExpanded : null} xs={expanded}>
                <ArrowDropDownIcon className={classes.setaAbrir} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Popover
          PaperProps={{ style: { paddingTop: 8, paddingBottom: 8, minWidth: 120 } }}
          anchorEl={periodoItemMenuAnchorEl}
          open={Boolean(periodoItemMenuAnchorEl)}
          onClose={() => this.closeMenu('periodoItemMenuAnchorEl')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {tipoIntervaloMenu && (
            <Grid container alignItems="center" style={{ height: 40, paddingLeft: 16, paddingRight: 16 }}>
              <Grid item style={{ marginRight: 8 }}>
                <IconButton
                  onClick={() => {
                    this.setState({ tipoIntervaloMenu: null })
                  }}
                  size="small"
                  style={{ marginLeft: -6 }}
                >
                  <ArrowBackIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body2" style={{ fontSize: 12, display: 'block', opacity: 0.6 }}>
                  <b>{tipoIntervaloMenu ? 'Voltar' : 'Selecionar'}</b>
                </Typography>
              </Grid>
            </Grid>
          )}

          {!tipoIntervaloMenu && (
            <React.Fragment>
              {TipoIntervaloEnum.values().map((item) => {
                return (
                  <MenuItem
                    onClick={() => {
                      this.alterarTipoIntervalo(item)
                    }}
                    align="center"
                    key={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                )
              })}
            </React.Fragment>
          )}

          {tipoIntervaloMenu && tipoIntervaloMenu.id !== TipoIntervaloEnum.PERSONALIZADO.id && (
            <React.Fragment>
              {tipoIntervaloMenu.opcoes(tipoIntervalo, this.state.dataInicial, this.state.dataFinal, this.selecionarOpcao).map((opcao, index) => {
                if (opcao.customContent) {
                  return opcao.customContent
                } else {
                  return (
                    <MenuItem
                      onClick={() => {
                        this.selecionarOpcao(tipoIntervaloMenu, opcao.dataInicial, opcao.dataFinal)
                      }}
                      align="center"
                      key={index}
                    >
                      {opcao.descricao}
                    </MenuItem>
                  )
                }
              })}
            </React.Fragment>
          )}
        </Popover>
      </React.Fragment>
    )
  }
}

DateRangeNavigator.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DateRangeNavigator)
