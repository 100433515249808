import React, { Component } from 'react'

import ReceiptLongIcon from 'support/components/icon/ReceiptLong'

class Icon extends Component {
  render() {
    let { style, ...others } = this.props

    if (!style) {
      style = {}
    } else {
      style = Object.assign({}, style)
    }

    style.position = 'relative'
    style.width = 28
    style.height = 24
    style.display = 'flex'

    return (
      <div style={style} {...others}>
        <ReceiptLongIcon style={{ position: 'absolute' }} />
      </div>
    )
  }
}

export default Icon
