import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class SangriaEntradaResponsiveTable extends Component {
  getRenderContent = (size, item) => {
    const content = {}

    content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YYYY')
    content.valor = formatarValorMonetario(item.valor)
    content.botao = (
      <IconButton
        style={{ marginRight: -6 }}
        onClick={(event) => {
          event.stopPropagation()
          this.props.removeFunction(item)
        }}
      >
        <DeleteIcon />
      </IconButton>
    )

    return content
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <VirtualizedResponsiveTable
        showHeaderColumns={false}
        showBackgroundDividers={true}
        largeRenderProps={{
          columns: [
            { label: 'Data', props: { xs: true } },
            { label: 'Valor', horizontalPadding: 'small', props: { xs: true } },
            {
              label: (
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              ),
              isVisible: false
            }
          ],
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('large', item)
            return {
              itemData: [content.data, content.valor, content.botao]
            }
          }
        }}
        smallRenderProps={{
          rowHeight: 82,
          showFirstItemBorderTop: true,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('small', item)

            return {
              itemData: (
                <Grid container>
                  <Grid item xs>
                    <Typography variant="body2">
                      <b>{content.data}</b>
                    </Typography>
                    <Typography variant="body2">Valor: {content.valor}</Typography>
                  </Grid>
                  <Grid item style={{ paddingLeft: 8, textAlign: 'right' }}>
                    <Typography variant="body2">{content.botao}</Typography>
                  </Grid>
                </Grid>
              )
            }
          }
        }}
        {...others}
      />
    )
  }
}

SangriaEntradaResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SangriaEntradaResponsiveTable)
