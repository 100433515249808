import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputDialogSelect from 'support/components/input/InputDialogSelect'

const styles = (theme) => ({})

class UfIbgeInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    if (!props.label) props.label = 'Estado'
    if (!props.id) props.id = 'xuf'
    if (!props.label) props.label = 'xuf'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome,
        uf: props.defaultValue
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Estado...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'svc/localizacao.buscarUfIbge'
    props.DialogSelectProps.generateItemData = (uf) => {
      return {
        value: uf.id,
        label: uf.nome + ' (' + uf.sigla + ')',
        primary: uf.nome + ' (' + uf.sigla + ')',
        uf: uf
      }
    }

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum estado foi encontrado'
      }
    }

    return <InputDialogSelect {...props} />
  }
}

UfIbgeInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UfIbgeInputDialogSelect)
