import { useMemo } from 'react'

import { grupoboticarioTheme } from '@grupoboticario/flora'
import { Box, Tag, useIsMobile } from '@grupoboticario/flora-react'
import { Tooltip } from '@material-ui/core'
import Send from '@material-ui/icons/Send'
import { useFeatureFlag } from 'configcat-react'
import { AtendimentoStatusEnum } from 'support/domain/atendimento/AtendimentoStatusEnum'
import StatusServicoEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'

const TAG_TEXT = 'Seu cliente será lembrado automaticamente deste agendamento'

const TypedStatusEnum = StatusServicoEnum as AtendimentoStatusEnum

type TagLembreteAutomaticoProps = {
  atendimento: {
    id: number
    tipo: {
      id: number
    }
    origem: {
      id: number
    }
    servico: {
      id: number
      nome: string
      categoria: {
        id: number
        nome: string
        cor: number
      }
    }
    variacaoServico: {
      id: number
    }
    status: {
      id: number
      descricao: string
    }
    data: number
    horarioInicio: number
    dataHorarioInicio: number
    horarioTermino: number
    duracao: number
    observacoes: string
    lembreteManualEnviado: boolean
    cancelado: boolean
    excluido: boolean
  }
}

function TagLembreteAutomatico({ atendimento }: TagLembreteAutomaticoProps) {
  const ftExibirTag = useFeatureFlag('FT_CRM_TAG_LEMBRETE_AUTOMATICO', false)
  const isMobile = useIsMobile()

  const naoDeveExibirTag = useMemo(() => !ftExibirTag.value || atendimento?.status.id !== TypedStatusEnum.MARCADO.id, [atendimento.status.id, ftExibirTag.value])

  if (naoDeveExibirTag) {
    return null
  }

  const tagElement = (
    <Tag css={{ marginTop: '$3', maxWidth: '100%' }} variant="brand-1" leftIcon={<Send />}>
      <Box as="span" css={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {TAG_TEXT}
      </Box>
    </Tag>
  )

  return (
    <Box className={grupoboticarioTheme}>
      {isMobile ? (
        <Tooltip title={TAG_TEXT} placement="top-start">
          {tagElement}
        </Tooltip>
      ) : (
        tagElement
      )}
    </Box>
  )
}

export default TagLembreteAutomatico
