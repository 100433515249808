import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MultiDateDialogSelect from 'support/components/input/dialog/MultiDateDialogSelect'
import InputText from 'support/components/input/InputText'

const styles = (theme) => ({
  clearIcon: {
    fontSize: 16
  }
})

class InputMultiDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dates: this.props.defaultValue ? this.props.defaultValue : []
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getValue = () => {
        if (this.state.dates && this.state.dates.length > 0) {
          return this.state.dates
        } else {
          return null
        }
      }
    }
  }

  openDialog = () => {
    if (!this.props.disabled) {
      openBackableDialog(MultiDateDialogSelect, {
        defaultValue: this.state.dates,
        onSelect: (dates) => {
          this.setValue(dates)
        }
      })
    }
  }

  handleClick = (event) => {
    this.openDialog()
  }

  setValue = (dates) => {
    if (this.props.onChange) {
      this.props.onChange(dates)
    }
    this.setState({ dates: dates })
  }

  render() {
    const { classes, readOnly, ...othersInputProps } = this.props
    delete othersInputProps.defaultValue
    delete othersInputProps.DialogSelectProps
    delete othersInputProps.onChange

    if (!readOnly) {
      othersInputProps.onClick = this.handleClick
    }

    othersInputProps.readOnly = true

    if (this.state.dates && this.state.dates.length > 0) {
      let sufixoPlural = ''
      if (this.state.dates.length > 1) {
        sufixoPlural = 's'
      }
      othersInputProps.value = this.state.dates.length + ' data' + sufixoPlural + ' selecionada' + sufixoPlural
      othersInputProps.shrink = true
    } else {
      othersInputProps.value = ''
    }

    if (this.state.item && !readOnly) {
      othersInputProps.endAdornment = (
        <IconButton
          disabled={this.props.disabled}
          onClick={(event) => {
            event.stopPropagation()
            this.setValue(null)
          }}
        >
          <CloseIcon className={classes.clearIcon} />
        </IconButton>
      )
    }
    return <InputText {...othersInputProps} />
  }
}

InputMultiDate.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputMultiDate)
