import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { callAPI } from 'support/components/api/ApiClient'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import BaseDialog from 'support/components/dialog/BaseDialog'
import InputText from 'support/components/input/InputText'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main
  }
})

class EntityConfirmationDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false,
      errorMessage: null,
      contentLoaded: this.props.contentWithPreload ? false : true,
      errorsMap: createErrorsMap()
    }

    this.inputTextToConfirm = React.createRef()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.confirmar()
  }

  confirmar = () => {
    this.setState({
      ajaxing: true,
      errorsMap: createErrorsMap()
    })

    if (this.props.textToConfirm) {
      const textoToConfirmValue = this.inputTextToConfirm.current.value
      if (!textoToConfirmValue || textoToConfirmValue.trim().toLowerCase() !== this.props.textToConfirm.trim().toLowerCase()) {
        this.setState({
          ajaxing: false,
          errorsMap: createErrorsMap({
            textToConfirm: textoToConfirmValue ? 'Texto digitado incorretamente' : 'Para confirmar esta ação, é preciso digitar "' + this.props.textToConfirm + '"'
          })
        })
        focusFirstElementWithError('formConfirmacao')
        return
      }
    }

    callAPI({
      method: this.props.call.method,
      url: this.props.call.url,
      data: this.props.call.data,
      params: this.props.call.params,
      contextoUsuario: this.props.call.contextoUsuario,
      requerAutorizacao: true,
      onSuccess: (reponse) => {
        this.props.call.onSuccess(reponse, this)
        if (this.props.closeOnSuccess === undefined || this.props.closeOnSuccess === true) {
          this.props.handleCloseDialog()
        }
      },
      onError: (reponse) => {
        if (this.props.call.onError) {
          this.props.call.onError(reponse, this)
        }

        const { errorKey } = this.props.call

        if (errorKey) {
          let errorKeyAsArray = []

          if (Array.isArray(errorKey)) {
            errorKeyAsArray = errorKeyAsArray.concat(errorKey)
          } else {
            errorKeyAsArray.push(errorKey)
          }

          let errorMessage = null

          if (reponse.data.errors) {
            for (let i = 0; i < errorKeyAsArray.length; i++) {
              const errorMessageByKey = reponse.data.errors[errorKeyAsArray[i]]
              if (errorMessageByKey) {
                errorMessage = errorMessageByKey
                break
              }
            }
          }

          if (errorMessage) {
            errorMessage = errorMessage.trim()
            if (errorMessage.length > 0) {
              this.setState((state) => ({ errorMessage: errorMessage }))
            }
          }
        }
        this.setState((state) => ({ ajaxing: false }))
      }
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    const newNotifyContentLoaded = () => {
      this.setState({ contentLoaded: true })
      notifyContentLoaded()
    }
    this.props.contentWithPreload.loadFunction(newNotifyContentLoaded, notifyContentNotLoaded)
  }

  render() {
    const { contentLoaded, errorsMap } = this.state
    const { classes, text, textToConfirm, ...others } = this.props
    let { cancelButtonLabel, confirmButtonLabel } = this.props

    delete others.cancelButtonLabel
    delete others.confirmButtonLabel

    if (!cancelButtonLabel) {
      cancelButtonLabel = 'CANCELAR'
    }

    if (!confirmButtonLabel) {
      confirmButtonLabel = 'CONFIRMAR'
    }

    let actions = null
    if (contentLoaded) {
      actions = (
        <React.Fragment>
          {!this.state.ajaxing && (
            <Button onClick={this.props.handleCloseDialog} color="primary" disabled={this.state.ajaxing}>
              {cancelButtonLabel}
            </Button>
          )}
          <Button onClick={this.confirmar} color="primary" autoFocus disabled={this.state.ajaxing}>
            {this.state.ajaxing ? (
              <span>
                <CircularProgress size={18} thickness={5.5} style={{ verticalAlign: 'middle', marginRight: 8 }} /> AGUARDE...
              </span>
            ) : (
              confirmButtonLabel
            )}
          </Button>
        </React.Fragment>
      )
    }

    let textContent = null
    if (typeof text === 'string' || (text && text.type && text.type === 'span')) {
      textContent = <DialogContentText>{text}</DialogContentText>
    } else {
      textContent = text
    }

    let content = (
      <React.Fragment>
        {textContent}
        {this.state.errorMessage && (
          <Typography variant="body2" className={classes.errorText} style={{ marginTop: 16, marginBottom: 16 }}>
            {this.state.errorMessage}
          </Typography>
        )}

        {this.props.bottomContent}

        {textToConfirm && (
          <form id="formConfirmacao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <InputText
              id="xtextToConfirm"
              defaultValue=""
              disabled={this.state.ajaxing}
              errorMessage={errorsMap('textToConfirm')}
              label={'Digite "' + textToConfirm + '" para confirmar'}
              inputProps={{
                maxLength: 255
              }}
              inputRef={this.inputTextToConfirm}
            />
            <HiddenSubmitButton />
          </form>
        )}
      </React.Fragment>
    )

    let mainContent = null

    if (this.props.contentWithPreload) {
      mainContent = <ContentWithPreload loadFunction={this.carregarMain}>{() => <span>{content}</span>}</ContentWithPreload>
    } else {
      mainContent = content
    }

    return (
      <BaseDialog disabledOnClose={this.state.ajaxing} actions={actions} {...others}>
        {mainContent}
      </BaseDialog>
    )
  }
}

EntityConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EntityConfirmationDialog)
