import { Component } from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const styles = (theme) => ({
  menuItem: {
    paddingLeft: 24,
    paddingTop: 3,
    paddingBottom: 3,
    color: theme.palette.text.primary,
    listStyle: 'none',
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40
  },
  menuItemSelectable: {
    '&:hover': {
      //background: "rgba(0,0,0,0.1)"
      background: '#f6f6f6'
    }
  },
  menuItemSelected: {
    //background: "rgba(0,0,0,0.15)",
    background: lighten(theme.palette.secondary.light, 0.92)
  },
  menuItemSelectedDisabled: {
    cursor: 'default',
    pointerEvents: 'none'
  }
})

class DrawerMenuItem extends Component {
  render() {
    const { classes, className, rightContent, 'data-testid': dataTestid } = this.props

    let onClickFunction = null
    let disabled = false
    let button = true

    const classesArray = [className, classes.menuItem]

    if (this.props.disabled) {
      disabled = true
    } else {
      if (!this.props.rota && !this.props.onClick) {
        button = false
      } else if (this.props.onClick) {
        onClickFunction = this.props.onClick
      } else {
        onClickFunction = () => {
          EventsManager.pub('NavegarPaginaPainel', { rota: this.props.rota })
        }
      }
    }

    if (this.props.selectable !== false) {
      classesArray.push(classes.menuItemSelectable)
    }

    if (this.props.regexUrl) {
      const regex = new RegExp('.*\\/p\\/' + this.props.regexUrl, 'i')
      if (document.location.href.match(regex)) {
        classesArray.push(classes.menuItemSelected)
        classesArray.push('selected')
        if (!this.props.alwaysClickable) {
          classesArray.push(classes.menuItemSelectedDisabled)
        }
      }
    }

    return (
      <ListItem className={classNames(classesArray)} button={button} onClick={onClickFunction} disabled={disabled} data-testid={dataTestid}>
        {this.props.children}
        {rightContent && <ListItemSecondaryAction>{rightContent}</ListItemSecondaryAction>}
      </ListItem>
    )
  }
}

DrawerMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  rota: PropTypes.string,
  regexUrl: PropTypes.string,
  disabled: PropTypes.bool,
  alwaysClickable: PropTypes.bool,
  selectable: PropTypes.bool,
  rightContent: PropTypes.node,
  children: PropTypes.node
}

export default withStyles(styles)(DrawerMenuItem)
