import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PollIcon from '@material-ui/icons/Poll'
import PublicIcon from '@material-ui/icons/Public'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswerOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import classNames from 'classnames'
import portalIconApp192 from 'img/portal-icon-app-192x192.png'
import AgendamentoOnlineDialogPage from 'pages/erp/painel/agendamentoonline/AgendamentoOnlineDialogPage'
import AvaliacoesDialogPage from 'pages/erp/painel/agendamentoonline/AvaliacoesDialogPage'
import RastreamentoDialogPage from 'pages/erp/painel/agendamentoonline/RastreamentoDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { setBannerJourney } from 'support/components/campanha/banner'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import NativeApp from 'support/components/nativeapp/NativeApp'
import PanelPage from 'support/components/panel/PanelPage'
import AntecedenciaCancelamentoAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaCancelamentoAgendamentoOnlineEnum'
import AntecedenciaMaximaAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaMaximaAgendamentoOnlineEnum'
import AntecedenciaMinimaAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaMinimaAgendamentoOnlineEnum'
import IntervaloHorarioAgendamentoOnlineEnum from 'support/domain/agendamentoonline/IntervaloHorarioAgendamentoOnlineEnum'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import NegocioService from 'support/domain/negocio/NegocioService'

import { OnlineSchedulingLP } from './OnlineSchedulingLP'

const styles = (theme) => ({
  containerStatus: {
    padding: '12px 14px',
    fontWeight: 500,
    borderRadius: 36,
    display: 'inline-block'
  },
  containerStatusHabilitado: {
    color: '#35c107',
    background: '#e4ffec',
    paddingTop: 14,
    paddingLeft: 18,
    paddingRight: 18
  },
  iconStatusHabilitado: {
    color: '#35c107',
    verticalAlign: 'middle',
    marginTop: -5
  },
  containerStatusDesabilitado: {
    color: '#999',
    background: '#f5f5f5'
  },
  gridCanalAgendamentoIcon: {
    textAlign: 'center',
    minWidth: 42,
    maxWidth: 42,
    marginRight: 8
  },
  listContainer: {
    cursor: 'pointer'
  }
})

class ConfiguracoesPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
    this.state = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoConfiguracaoAgendamentoOnline', (props) => {
      this.contentWithPreloadFunctions.reload()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    setBannerJourney('agendamento')

    getAPI({
      url: 'erp/negocios.buscarConfiguracoesAgendamentoOnline',
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const { negocio, configuracoesGerais, pendencias, pendenciasBuscavel } = response.data
        this.setState({
          negocio: negocio,
          configuracoesGerais: configuracoesGerais,
          pendencias: pendencias,
          pendenciasBuscavel: pendenciasBuscavel,
          controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(configuracoesGerais.controleAgendamentoOnline.id),
          intervaloHorarioAgendamentoOnline: IntervaloHorarioAgendamentoOnlineEnum.getById(configuracoesGerais.intervaloHorarioAgendamentoOnline.id),
          antecedenciaMinimaAgendamentoOnline: AntecedenciaMinimaAgendamentoOnlineEnum.getById(configuracoesGerais.antecedenciaMinimaAgendamentoOnline.id),
          antecedenciaMaximaAgendamentoOnline: AntecedenciaMaximaAgendamentoOnlineEnum.getById(configuracoesGerais.antecedenciaMaximaAgendamentoOnline.id),
          antecedenciaCancelamentoAgendamentoOnline: AntecedenciaCancelamentoAgendamentoOnlineEnum.getById(configuracoesGerais.antecedenciaCancelamentoAgendamentoOnline.id)
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage {...others} contentMaxWidth={1010} title="Agendamento Online">
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.contentWithPreloadFunctions}>
          {() => {
            const { configuracoesGerais, negocio, controleAgendamentoOnline, pendencias, pendenciasBuscavel } = this.state

            const siteLink = process.env.REACT_APP_WEB_ADDRESS
            const siteAppAndroid = 'https://play.google.com/store/apps/details?id=br.com.salao99.portal'
            const siteAppIOS = 'https://apps.apple.com/br/app/sal%C3%A3o99/id1555141764'

            let containerStatus = null
            let urlPaginaNegocio = process.env.REACT_APP_WEB_ADDRESS

            if (controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.HABILITADO.id) {
              containerStatus = (
                <div className={classNames(classes.containerStatus, classes.containerStatusHabilitado)}>
                  Habilitado <CheckCircleIcon className={classes.iconStatusHabilitado} />
                </div>
              )
              urlPaginaNegocio += NegocioService.gerarUrlAgendamentoOnline(negocio, configuracoesGerais.sufixoUrlPortal)
            } else if (controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.DESABILITADO.id) {
              containerStatus = <div className={classNames(classes.containerStatus, classes.containerStatusDesabilitado)}>Desabilitado</div>
            }

            const linkPaginaNegocioProps = {}
            const linkSiteProps = {}
            const linkAppAndroidProps = {}
            const linkAppiOSProps = {}

            if (DeviceIdentifier.isNativeApp()) {
              linkPaginaNegocioProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: urlPaginaNegocio
                })
              }
              linkSiteProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: siteLink
                })
              }
              linkAppAndroidProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: siteAppAndroid
                })
              }
              linkAppiOSProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: siteAppIOS
                })
              }
            } else {
              linkPaginaNegocioProps.href = urlPaginaNegocio
              linkPaginaNegocioProps.target = 'urlPaginaNegocio'
              linkSiteProps.href = siteLink
              linkSiteProps.target = 'siteLink'
              linkAppAndroidProps.href = siteAppAndroid
              linkAppAndroidProps.target = 'siteAppAndroid'
              linkAppiOSProps.href = siteAppIOS
              linkAppiOSProps.target = 'siteAppIOS'
            }

            if (controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.DESABILITADO.id) {
              return (
                <OnlineSchedulingLP
                  onClick={() => {
                    openBackableDialog(AgendamentoOnlineDialogPage)
                  }}
                />
              )
            }

            return (
              <React.Fragment>
                <Typography variant="body1" style={{ fontWeight: 400, marginTop: 4, marginBottom: 18 }}>
                  Economize tempo e alcance mais clientes. Com o Agendamento Online os seus clientes podem agendar horários 24h pelo aplicativo ou site.
                </Typography>
                <Grid container alignItems="center">
                  <Grid item>{containerStatus}</Grid>
                </Grid>

                {this.state.controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.HABILITADO.id && (
                  <React.Fragment>
                    {pendencias && pendencias.length > 0 && (
                      <div style={{ background: '#f6f6f6', padding: 20, marginTop: 40, borderRadius: 16 }}>
                        <Typography variant="body1">
                          <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginTop: -5, marginRight: 10 }} />
                          <b>Pendências</b>
                        </Typography>
                        <Typography variant="body2" style={{ marginTop: 2 }}>
                          Para que o seu perfil fique disponível para receber agendamentos online, é preciso resolver as seguintes pendências:
                        </Typography>
                        <ul>
                          {pendencias.map((pendencia, index) => (
                            <li key={index} style={{ paddingTop: 4, paddingBottom: 4 }}>
                              <Typography variant="body2">{pendencia};</Typography>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {(!pendencias || pendencias.length === 0) && (
                      <div>
                        <Divider style={{ marginTop: 30, marginBottom: 18 }} />

                        <List disablePadding component="nav" className={classes.listContainer}>
                          <ListItem disableGutters onClick={() => openBackableDialog(AgendamentoOnlineDialogPage)}>
                            <ListItemIcon>
                              <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<b>Configurações Gerais</b>}
                              secondary="Habilite ou desabilite o agendamento online, defina intervalos de horários, endereço do link da página e mais"
                            />
                          </ListItem>
                          <ListItem disableGutters onClick={() => openBackableDialog(RastreamentoDialogPage)}>
                            <ListItemIcon>
                              <PollIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<b>Rastreamento e Analytics</b>}
                              secondary="Configure a sua conta do Google Analytics para rastrear e acompanhar os acessos dos usuários na sua página de agendamento e pelo aplicativo."
                            />
                          </ListItem>
                          <ListItem disableGutters onClick={() => openBackableDialog(AvaliacoesDialogPage)}>
                            <ListItemIcon>
                              <QuestionAnswerIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<b>Responder Avaliações</b>}
                              secondary="Adicione respostas às avalições que o seu estabelecimento recebe de clientes pelo Agendamento Online"
                            />
                          </ListItem>
                        </List>

                        <Divider style={{ marginTop: 20, marginBottom: 32 }} />

                        <Typography variant="body1" style={{ marginBottom: 2 }}>
                          Link da página do seu negócio:
                        </Typography>
                        <Link {...linkPaginaNegocioProps} rel="noopener noreferrer" underline="none">
                          <Typography variant="body2" style={{ fontSize: 16, wordBreak: 'break-all' }}>
                            <b>{urlPaginaNegocio.replace('https://', '')}</b>
                            <OpenInNewIcon style={{ verticalAlign: 'bottom', marginBottom: 2, marginLeft: 3, fontSize: 20 }} />
                          </Typography>
                        </Link>

                        {pendenciasBuscavel && pendenciasBuscavel.length > 0 && (
                          <div style={{ background: '#f6f6f6', padding: 20, marginTop: 40, borderRadius: 16 }}>
                            <Typography variant="body1">
                              <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginTop: -5, marginRight: 10 }} />
                              <b>A página não está sendo exibida nas buscas do Site e App</b>
                            </Typography>
                            <Typography variant="body2" style={{ marginTop: 2 }}>
                              Usuários não podem encontrar a sua empresa na página de busca do Site ou do Aplicativo Salão99. Resolva as seguintes pendências:
                            </Typography>
                            <ul>
                              {pendenciasBuscavel.map((pendenciasBuscavel, index) => (
                                <li key={index}>
                                  <Typography variant="body2">{pendenciasBuscavel};</Typography>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {(!pendenciasBuscavel || pendenciasBuscavel.length === 0) && !configuracoesGerais.buscavelAgendamentoOnline && (
                          <div style={{ background: '#f6f6f6', padding: 20, marginTop: 40, borderRadius: 16 }}>
                            <Typography variant="body1">
                              <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginTop: -5, marginRight: 10 }} />
                              <b>A página não está sendo exibida nas buscas do Site e App</b>
                            </Typography>
                            <Typography variant="body2" style={{ marginTop: 2 }}>
                              Habilite a opção "Exibir na busca do site e App" para permitir que usuários encontrem a sua empresa na página de busca do Site ou do Aplicativo
                              Salão99
                            </Typography>
                          </div>
                        )}

                        {(!pendenciasBuscavel || pendenciasBuscavel.length === 0) && configuracoesGerais.buscavelAgendamentoOnline && (
                          <div>
                            <Divider style={{ marginTop: 32, marginBottom: 32 }} />

                            <Typography variant="body1">Como o cliente pode encontrar a sua empresa e realizar o agendamento online?</Typography>

                            <Link {...linkSiteProps} rel="noopener noreferrer" underline="none">
                              <Grid container alignItems="center" style={{ marginTop: 12 }} wrap="nowrap">
                                <Grid item className={classes.gridCanalAgendamentoIcon}>
                                  <PublicIcon style={{ display: 'inline-block', fontSize: 32 }} />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2">Pelo site do Salão99 ou pelo link direto para a a página do seu negócio.</Typography>
                                </Grid>
                              </Grid>
                            </Link>

                            <Link {...linkAppAndroidProps} rel="noopener noreferrer" underline="none">
                              <Grid container alignItems="center" style={{ marginTop: 8 }} wrap="nowrap">
                                <Grid item className={classes.gridCanalAgendamentoIcon}>
                                  <img src={portalIconApp192} width={32} height={32} alt="Aplicativo" />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2">Pelo novo aplicativo Salão99 para Android na Google Play Store.</Typography>
                                </Grid>
                              </Grid>
                            </Link>

                            <Link {...linkAppiOSProps} rel="noopener noreferrer" underline="none">
                              <Grid container alignItems="center" style={{ marginTop: 8 }} wrap="nowrap">
                                <Grid item className={classes.gridCanalAgendamentoIcon}>
                                  <img src={portalIconApp192} width={32} height={32} alt="Aplicativo" />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2">Pelo novo aplicativo Salão99 pra iOS na App Store.</Typography>
                                </Grid>
                              </Grid>
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          }}
        </ContentWithPreload>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ConfiguracoesPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesPanelPage)
