import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class VendasPesquisaAvancadaDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      dataInicial: this.props.dataInicial,
      dataFinal: this.props.dataFinal
    }

    this.dataInicialFunctionsMap = {}
    this.dataFinalFunctionsMap = {}
  }

  limparFiltro = () => {
    this.props.setParentState({
      dataInicial: null,
      dataFinal: null
    })
    this.props.handleCloseDialog()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.aplicarFiltro()
  }

  aplicarFiltro = () => {
    this.setState({ errorsMap: FormUtil.createErrorsMap() })

    const errorsMap = {}
    const dataInicial = this.dataInicialFunctionsMap.getDataAsDate()
    const dataFinal = this.dataFinalFunctionsMap.getDataAsDate()

    if (dataInicial !== null && dataFinal !== null && dataInicial > dataFinal) {
      errorsMap['dataFinal'] = 'A data(até) não pode ser menor que a data(de)'
    }

    if (Object.keys(errorsMap).length > 0) {
      this.setState({ errorsMap: FormUtil.createErrorsMap(errorsMap) })
      FormUtil.focusFirstElementWithError('formNfsPesquisaAvancada')
    } else {
      this.props.setParentState({
        dataInicial: dataInicial,
        dataFinal: dataFinal
      })
      this.props.handleCloseDialog()
    }
  }

  render() {
    const { errorsMap } = this.state

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogTitle>Pesquisa Avançada</DialogTitle>
        <DialogContent>
          <form id="formNfsPesquisaAvancada" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <InputHorizontalContainer
              inputTexts={[
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputData
                      id="xdatainicial"
                      name="xdatainicial"
                      label="Data (de)"
                      keyboard={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.dataInicialFunctionsMap}
                      defaultValue={this.state.dataInicial}
                      errorMessage={errorsMap('dataInicial')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputData
                      id="xdatafinal"
                      name="xdatafinal"
                      label="Data (até)"
                      keyboard={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.dataFinalFunctionsMap}
                      defaultValue={this.state.dataFinal}
                      errorMessage={errorsMap('dataFinal')}
                    />
                  )
                }
              ]}
            />

            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Voltar
          </Button>
          <Button color="secondary" onClick={this.limparFiltro}>
            LIMPAR
          </Button>
          <Button color="secondary" onClick={this.aplicarFiltro}>
            PESQUISAR
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

VendasPesquisaAvancadaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendasPesquisaAvancadaDialog)
