import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth from '@material-ui/core/withWidth'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import TipoCalculoEnum from 'support/domain/calculo/TipoCalculoEnum'
import PrioridadeComissaoPadraoProdutoEnum from 'support/domain/comissao/PrioridadeComissaoPadraoProdutoEnum'
import { formatarValorMonetario, formatarValorPercentual } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaValor: {
    maxWidth: 90,
    minWidth: 90
  },
  colunaTipo: {
    maxWidth: 122,
    minWidth: 122
  },
  nomeProdutoContainerSmall: {
    fontSize: 14,
    fontWeight: 500,
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

class DetalhesComissoesPorProdutoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false,
      lista: []
    }

    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.nomeCategoria = item.produto.categoria.nome
    content.nomeProduto = item.produto.nome
    if (item.valorComissao && item.valorComissao > 0) {
      if (item.tipoCalculoComissao.id === TipoCalculoEnum.PORCENTAGEM.id) {
        content.valorComissao = formatarValorPercentual(item.valorComissao)
      } else {
        content.valorComissao = formatarValorMonetario(item.valorComissao)
      }
    } else {
      content.valorComissao = formatarValorPercentual(0)
    }

    return content
  }

  getRequestParameters = () => {
    return {
      idColaborador: this.props.colaborador.id
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing } = this.state

    let textoDescricaoPrioridadeComissao = 'O sistema está considerando primeiro '

    if (getSessaoPainel().negocio.configuracoesGerais.prioridadeComissaoProdutoPadrao.id === PrioridadeComissaoPadraoProdutoEnum.COLABORADOR.id) {
      textoDescricaoPrioridadeComissao +=
        'as comissões configuradas para este colaborador. Se o colaborador não possui comissão configurada, o sistema utiliza a comissão do produto. '
    } else {
      textoDescricaoPrioridadeComissao += 'as comissões configuradas nos produtos. Se o produto não possui comissão configurada, o sistema utiliza a comissão do colaborador. '
    }

    textoDescricaoPrioridadeComissao += 'Para inverter esse comportamento, acesse as Configurações do sistema e selecione o comportamento desejado.'

    return (
      <DialogPage {...dialogProps} title="Detalhamento de Comissões" align="center" contentMaxWidth={800} pageType="basicEntity" ajaxing={ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <Typography variant="body2" style={{ marginBottom: 20 }}>
              {textoDescricaoPrioridadeComissao}
            </Typography>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              loaderFunctionsMap={this.loaderFunctionsMap}
              itemsPerRequest={30}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              contextoUsuario="erp"
              endpoint="erp/colaboradores.buscarComissoesPorProduto"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Categoria', props: { xs: true } },
                  { label: 'Produto', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Comissão', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.nomeCategoria, content.nomeProduto, content.valorComissao]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 90,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body2" className={classes.nomeProdutoContainerSmall}>
                          {content.nomeProduto}
                        </Typography>
                        <Typography variant="body2" noWrap={true} style={{ fontSize: 13, fontWeight: 500 }}>
                          {content.nomeCategoria}
                        </Typography>
                        <Typography variant="body2">Sozinho: {content.valorComissao}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                padding: true,
                text: 'Este colaborador não vende nenhum produto'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

DetalhesComissoesPorProdutoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(DetalhesComissoesPorProdutoDialogPage))
