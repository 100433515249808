import React, { Component } from 'react'

import { Tag } from '@grupoboticario/flora-react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { darken, emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import classNames from 'classnames'
import { withConfigCatClient } from 'configcat-react'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CustomDialog from 'support/components/dialog/CustomDialog'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import DeviceTipoEnum from 'support/domain/mercadopago/DeviceTipo'
import { MercadoPagoUtils } from 'support/util/MercadoPagoUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

import { PagamenteOpenedDialog } from '../../venda/PagamentoOpenedDialog'

const ROW_HEIGHT = 54

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 480,
    width: '100%',
    margin: 4
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    position: 'relative',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    zIndex: 1
  },
  titleMainContent: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(4)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1)
  },
  dialogContent: {
    maxHeight: 1200,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0
  },
  rootListItem: {
    marginTop: 0,
    marginBottom: 0,
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:not(&rootListItemDisabled):hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  rootListItemDisabled: {
    cursor: 'auto',
    pointerEvents: 'none',
    '& *': {
      opacity: 0.6,
      color: '#1d1d1d !important'
    }
  },
  contentListItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 8
  },
  primaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 15
  },
  secondaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: emphasize(darken(theme.palette.secondary.light, 0.15), 0.3)
  },
  iconListItem: {
    marginTop: 4,
    marginRight: -4
  },
  tag: {
    borderColor: '#7E15E3',
    color: '#7E15E3',
    borderRadius: 20,
    marginRight: 8,
    fontSize: '10px',
    fontWeight: 600
  }
})

class InputFormaPagamentoDialog extends Component {
  constructor(props) {
    super(props)
    this.contentWithPreloadFunctions = {}

    this.state = {
      scrollContainerRef: null,
      step: 0,
      formasPagamento: [],
      mercadoPago: {
        parcelas: null,
        formaPagamento: null,
        valor: null,
        tipo: null,
        dataPagamento: null
      },
      isMercadoPagoDialogVisible: false,
      mercadoPagoDebitPayment: null
    }
    this.device = ''

    this.scrollContainerRef = React.createRef()
  }

  check = (ref, onBind) => {
    if (!ref.current) {
      window.setTimeout(() => {
        this.check(ref, onBind)
      }, 15)
    } else {
      onBind()
    }
  }

  componentDidMount = () => {
    this.check(this.scrollContainerRef, () => {
      this.setState({
        scrollContainerRef: this.scrollContainerRef
      })
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/formaspagamento.buscarFormasPagamentoEConfiguracoes',
      params: {
        idCliente: this.props.cliente ? this.props.cliente.id : null
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.cliente = response.data.cliente

        this.setState({
          formasPagamento: response.data.formasPagamento.filter((formaPagamento) => {
            if (!response.data.cliente && formaPagamento.tipo.id === FormaPagamentoTipoEnum.CREDITO.id) {
              return false
            } else {
              return true
            }
          })
        })

        const possuiCredito = this.cliente && this.cliente.saldo > 0 ? true : false

        for (let formaPagamento of this.state.formasPagamento) {
          if (formaPagamento.tipo.id === FormaPagamentoTipoEnum.CREDITO.id && !possuiCredito) {
            formaPagamento.disabled = true
          }
        }

        const filteredPagamento = this.state.formasPagamento.filter((pagamento) => pagamento.tipo.id !== FormaPagamentoTipoEnum.MERCADO_PAGO.id)

        postAPI({
          url: 'erp/vendas/integracoes-mp/primeiros-passos',
          requerAutorizacao: true,
          onSuccess: (response) => {
            if (response.data.devices.length <= 0) {
              this.setState({ formasPagamento: filteredPagamento })
            } else {
              const device = response.data.devices.find((device) => device.operating_mode === DeviceTipoEnum.PDV.type)

              if (device) {
                this.device = device
              } else {
                this.setState({ formasPagamento: filteredPagamento })
              }
            }

            this.state.formasPagamento.sort(function (f1, f2) {
              if (f1.tipo.id === FormaPagamentoTipoEnum.CREDITO.id) {
                return possuiCredito ? -1 : 1
              } else if (f2.tipo.id === FormaPagamentoTipoEnum.CREDITO.id) {
                return possuiCredito ? 1 : -1
              } else {
                return 0
              }
            })

            notifyContentLoaded()
          },
          onError: () => {
            this.setState({ formasPagamento: filteredPagamento })
            notifyContentLoaded()
          }
        })

        this.configuracoesCartaoCredito = response.data.configuracaoAdquirentesCartaoCredito
        this.configuracoesCartaoDebito = response.data.configuracaoAdquirentesCartaoDebito
        this.configuracaoCheque = response.data.configuracaoCheque
        this.configuracaoPix = response.data.configuracaoPix

        this.setState({
          step: 1
        })
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  returnDadosPagamento() {
    this.props.setValue({
      formaPagamento: {
        ...this.formaPagamento,
        numeroParcelas: this.numeroParcelas,
        mercadoPago: {
          tipo: this.mercadoPago || this.state.mercadoPago.tipo,
          formaPagamento: this.mercadoPago || this.formaPagamento,
          parcelas: this.numeroParcelas,
          deviceId: this.device.id
        }
      },
      numeroParcelas: this.numeroParcelas,
      adquirente: this.adquirente,
      bandeira: this.bandeira,
      cliente: this.cliente,
      pagamento: { numeroParcelas: this.numeroParcelas }
    })
    this?.props?.handleCloseDialog()
  }

  renderChequeParcelasRow = (chequeParcelas, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={chequeParcelas.numero === 1 ? 'À vista' : chequeParcelas.numero + 'x'}
          onClick={() => {
            this.numeroParcelas = chequeParcelas.numero
            this.returnDadosPagamento()
          }}
        />
      </div>
    )
  }

  renderBandeiraParcelasRow = (bandeiraParcelas, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={bandeiraParcelas.numero === 1 ? 'À vista' : bandeiraParcelas.numero + 'x'}
          onClick={() => {
            this.numeroParcelas = bandeiraParcelas.numero
            this.returnDadosPagamento()
          }}
        />
      </div>
    )
  }

  renderBandeiraCreditoRow = (bandeira, { index, key, style }) => {
    const { scrollContainerRef } = this.state

    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={bandeira.nome}
          onClick={() => {
            scrollContainerRef.current.scrollTop = 0
            this.bandeira = bandeira
            this.bandeira.parcelas = []

            if (bandeira.numeroParcelas && bandeira.numeroParcelas > 0) {
              for (let i = this.bandeira.parcelas.length + 1; i <= bandeira.numeroParcelas + 1; i++) {
                this.bandeira.parcelas.push({
                  numero: i
                })
              }

              this.setState({ step: 7 })
            } else {
              this.numeroParcelas = 1
              this.returnDadosPagamento()
            }
          }}
        />
      </div>
    )
  }

  renderBandeiraDebitoRow = (bandeira, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={bandeira.nome}
          onClick={() => {
            this.bandeira = bandeira
            this.returnDadosPagamento()
          }}
        />
      </div>
    )
  }

  renderAdquirenteCreditoRow = (adquirente, { index, key, style }) => {
    const { scrollContainerRef } = this.state

    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={adquirente.nome}
          onClick={() => {
            scrollContainerRef.current.scrollTop = 0
            this.adquirente = adquirente
            this.bandeiras = adquirente.bandeiras
            this.setState({ step: 5 })
          }}
        />
      </div>
    )
  }

  renderAdquirenteDebitoRow = (adquirente, { index, key, style }) => {
    const { scrollContainerRef } = this.state

    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={adquirente.nome}
          onClick={() => {
            scrollContainerRef.current.scrollTop = 0
            this.adquirente = adquirente
            this.bandeiras = adquirente.bandeiras
            this.setState({ step: 6 })
          }}
        />
      </div>
    )
  }

  getAdquirentesEBandeirasCartao = () => {
    let configuracaoAdquirentes = []
    let configuracoesCartao

    if (this.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id || MercadoPagoUtils.isMercadoPago(this.formaPagamento)) {
      configuracoesCartao = this.configuracoesCartaoCredito
    } else if (this.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id || MercadoPagoUtils.isMercadoPago(this.formaPagamento)) {
      configuracoesCartao = this.configuracoesCartaoDebito
    }

    configuracoesCartao.forEach((configuracao, index) => {
      let adquirente = configuracaoAdquirentes.find((config) => config.id === configuracao.formaPagamentoAdquirenteBandeira.formaPagamentoAdquirente.adquirente.id)

      if (!adquirente) {
        adquirente = Object.assign({}, configuracao.formaPagamentoAdquirenteBandeira.formaPagamentoAdquirente.adquirente)
        configuracaoAdquirentes.push(adquirente)
      }

      if (!adquirente.bandeiras) {
        adquirente.bandeiras = []
      }

      let bandeira = configuracao.formaPagamentoAdquirenteBandeira.bandeira

      if (this.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id) {
        bandeira.numeroParcelas = configuracao.configuracaoCartaoCredito.quantidadeParcelas
        bandeira.parcelas = configuracao.configuracaoCartaoCredito.parcelas
      }

      adquirente.bandeiras.push(configuracao.formaPagamentoAdquirenteBandeira.bandeira)
    })

    configuracaoAdquirentes.sort(function (a, b) {
      if (a.nome < b.nome) return -1
      if (a.nome > b.nome) return 1
      return 0
    })

    configuracaoAdquirentes.forEach((configuracao, index) => {
      configuracao.bandeiras.sort(function (a, b) {
        if (a.nome < b.nome) return -1
        if (a.nome > b.nome) return 1
        return 0
      })
    })

    return configuracaoAdquirentes
  }

  renderFormasPagamentoRow = (formaPagamento, { index, key, style }) => {
    const { scrollContainerRef } = this.state

    return (
      <div key={key} style={style}>
        <ListItemSelectable
          isMercadoPago={MercadoPagoUtils.isMercadoPago(formaPagamento)}
          disabled={formaPagamento.disabled}
          classesNamesObject={this.props.classes}
          primary={formaPagamento.nome}
          secondary={formaPagamento.tipo.id === FormaPagamentoTipoEnum.CREDITO.id ? 'Saldo Disponível: ' + formatarValorMonetario(this.cliente.saldo) : null}
          onClick={() => {
            scrollContainerRef.current.scrollTop = 0
            this.formaPagamento = formaPagamento

            switch (formaPagamento.tipo.id) {
              case FormaPagamentoTipoEnum.MERCADO_PAGO.id: {
                getAPI({
                  url: 'erp/vendas/integracoes-mp/dispositivo/pagamentos-pendentes',
                  requerAutorizacao: true,
                  onSuccess: (responseDevices) => {
                    const hasAnotherPayment = responseDevices.data.find((item) => item.deviceId === this.device.id && (item.status === 'ABERTO' || item.status === 'PENDENTE'))
                    if (hasAnotherPayment) {
                      return this.setState({ isMercadoPagoDialogVisible: true, mercadoPagoDebitPayment: hasAnotherPayment })
                    }
                    this.setState({ step: 9 })
                  },
                  onError: () => {
                    this.props.handleCloseDialog()
                  }
                })
                break
              }
              case FormaPagamentoTipoEnum.CARTAO_CREDITO.id: {
                this.setState({ step: 2 })
                break
              }

              case FormaPagamentoTipoEnum.CARTAO_DEBITO.id: {
                this.setState({ step: 3 })
                break
              }

              case FormaPagamentoTipoEnum.CHEQUE.id: {
                if (this.configuracaoCheque.permitirPreDatado) {
                  this.configuracaoCheque.parcelas = []

                  for (let i = this.configuracaoCheque.parcelas.length + 1; i <= this.configuracaoCheque.numeroParcelas; i++) {
                    this.configuracaoCheque.parcelas.push({
                      numero: i
                    })
                  }

                  this.setState({ step: 4 })
                } else {
                  this.returnDadosPagamento()
                }
                break
              }

              default:
                this.returnDadosPagamento()
                break
            }
          }}
        />
      </div>
    )
  }

  ordenarPorNome = (a, b) => {
    if (a.nome === 'Maquininha Mercado Pago') {
      return -1
    } else if (b.nome === 'Maquininha Mercado Pago') {
      return 1
    } else {
      return a.nome.localeCompare(b.nome)
    }
  }

  handleClosePagamentoOpenedDialog = () => {
    this.setState({ isMercadoPagoDialogVisible: !this.state.isMercadoPagoDialogVisible })
  }

  render() {
    const { classes } = this.props
    const { isMercadoPagoDialogVisible, mercadoPagoDebitPayment, step, formasPagamento } = this.state

    const formasPagamentosOrdenado = formasPagamento.sort(this.ordenarPorNome)

    let quantidadeItensScroll = 2
    let titulo = ''

    if (step === 1) {
      quantidadeItensScroll = formasPagamento ? formasPagamento.length : 0
    } else if (step === 2) {
      quantidadeItensScroll = this.configuracoesCartaoCredito ? this.configuracoesCartaoCredito.length : 0
      titulo = this.formaPagamento.nome
    } else if (step === 3) {
      quantidadeItensScroll = this.configuracoesCartaoDebito ? this.configuracoesCartaoDebito.length : 0
      titulo = this.formaPagamento.nome
    } else if (step === 4) {
      quantidadeItensScroll = this.configuracaoCheque.numeroParcelas
      titulo = 'Escolha o parcelamento...'
    } else if (step === 5) {
      quantidadeItensScroll = this.bandeiras.length
      titulo = 'Escolha a bandeira...'
    } else if (step === 6) {
      quantidadeItensScroll = this.bandeiras.length
      titulo = 'Escolha a bandeira...'
    } else if (step === 7) {
      quantidadeItensScroll = this.bandeira.numeroParcelas
      titulo = 'Escolha o parcelamento...'
    }

    if (quantidadeItensScroll < 1) {
      quantidadeItensScroll = 1
    }

    const formasPagamentoCreditoDebito = formasPagamento.filter(
      (pagamento) => pagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id || pagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id
    )
    const totalParcelas = Math.floor(this.props.valorTotal / 10)
    const numeroParcelasCalc = totalParcelas > 12 ? 12 : totalParcelas <= 1 ? 1 : totalParcelas
    const parcelasMercadoPago = Array.from({ length: numeroParcelasCalc }, (_, index) => ({ numero: index + 1 }))

    return (
      <>
        <CustomDialog classes={{ paper: classes.root }} dialogProps={this.props} disabledOnClose={this.state.ajaxing}>
          <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarMain} loadContentPadding={true}>
            <DialogTitle disableTypography className={classes.rootTitle}>
              {step === 1 && [
                <Typography key="title" variant="h6">
                  Selecione...
                </Typography>
              ]}
              {step > 1 &&
                step <= 4 && [
                  <IconButton
                    key="voltar"
                    className={classes.backButton}
                    onClick={() => {
                      let stepBack = 1
                      this.setState({ step: stepBack })
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>,
                  <Typography key="title" variant="h6" className={classes.titleMainContent}>
                    {titulo}
                  </Typography>
                ]}
              {step === 5 && [
                <IconButton
                  key="voltar"
                  className={classes.backButton}
                  onClick={() => {
                    let stepBack = 2
                    this.setState({ step: stepBack })
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>,
                <Typography key="title" variant="h6" className={classes.titleMainContent}>
                  {titulo}
                </Typography>
              ]}
              {step === 6 && [
                <IconButton
                  key="voltar"
                  className={classes.backButton}
                  onClick={() => {
                    let stepBack = 3
                    this.setState({ step: stepBack })
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>,
                <Typography key="title" variant="h6" className={classes.titleMainContent}>
                  {titulo}
                </Typography>
              ]}
              {step === 7 && [
                <IconButton
                  key="voltar"
                  className={classes.backButton}
                  onClick={() => {
                    let stepBack = 5
                    this.setState({ step: stepBack })
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>,
                <Typography key="title" variant="h6" className={classes.titleMainContent}>
                  {titulo}
                </Typography>
              ]}
              {step === 9 && [
                <IconButton
                  key="voltar"
                  className={classes.backButton}
                  onClick={() => {
                    let stepBack = 1
                    this.setState({ step: stepBack })
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>,
                <Typography key="title" variant="h6" className={classes.titleMainContent}>
                  Selecione a forma de pagamento
                </Typography>
              ]}
              {step === 10 && [
                <IconButton
                  key="voltar"
                  className={classes.backButton}
                  onClick={() => {
                    let stepBack = 9
                    this.setState({ step: stepBack })
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>,
                <Typography key="title" variant="h6" className={classes.titleMainContent}>
                  Selecione as parcelas
                </Typography>
              ]}

              <IconButton className={classes.closeButton} onClick={this.props.handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classNames(classes.dialogContent, 'needsWillChange')} style={{ wwillChange: 'ttransform' }} ref={this.scrollContainerRef}>
              {this.state.scrollContainerRef && this.state.scrollContainerRef.current && (
                <React.Fragment>
                  {step === 1 && (
                    <VirtualizedList
                      key="formasPagamento"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={formasPagamentosOrdenado}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderFormasPagamentoRow}
                      emptyListProps={{
                        padding: true,
                        text: (
                          <span>
                            Nenhuma forma de pagamento foi encontrada. Entre nas <b>Configurações</b> do sistema de cadastre as formas de pagamento que a sua empresa aceita.
                          </span>
                        )
                      }}
                    />
                  )}
                  {step === 2 && (
                    <VirtualizedList
                      key="adquirentesCC"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={this.getAdquirentesEBandeirasCartao()}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderAdquirenteCreditoRow}
                      emptyListProps={{
                        padding: true,
                        text: (
                          <span>
                            Nenhuma maquininha/bandeira foi encontrada. Entre nas <b>Configurações</b> do sistema e faça a configuração desta forma de pagamento.
                          </span>
                        )
                      }}
                    />
                  )}
                  {step === 3 && (
                    <VirtualizedList
                      key="adquirentesCD"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={this.getAdquirentesEBandeirasCartao()}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderAdquirenteDebitoRow}
                      emptyListProps={{
                        padding: true,
                        text: (
                          <span>
                            Nenhuma maquininha/bandeira foi encontrada. Entre nas <b>Configurações</b> do sistema e faça a configuração desta forma de pagamento.
                          </span>
                        )
                      }}
                    />
                  )}
                  {step === 4 && (
                    <VirtualizedList
                      key="chequeParcelas"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={this.configuracaoCheque.parcelas}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderChequeParcelasRow}
                    />
                  )}
                  {step === 5 && (
                    <VirtualizedList
                      key="configuracaoBandeirasCredito"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={this.bandeiras}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderBandeiraCreditoRow}
                      emptyListProps={{
                        padding: true,
                        text: (
                          <span>
                            Nenhuma bandeira foi encontrada. Entre nas <b>Configurações</b> do sistema de cadastre as bandeiras desta forma de pagamento.
                          </span>
                        )
                      }}
                    />
                  )}
                  {step === 6 && (
                    <VirtualizedList
                      key="configuracaoBandeirasDebito"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={this.bandeiras}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderBandeiraDebitoRow}
                      emptyListProps={{
                        padding: true,
                        text: (
                          <span>
                            Nenhuma bandeira foi encontrada. Entre nas <b>Configurações</b> do sistema de cadastre as bandeiras desta forma de pagamento.
                          </span>
                        )
                      }}
                    />
                  )}
                  {step === 7 && (
                    <VirtualizedList
                      key="bandeiraParcelas"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={this.bandeira.parcelas}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderBandeiraParcelasRow}
                    />
                  )}
                  {step === 8 && (
                    <VirtualizedList
                      key="bandeiraParcelas"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={this.bandeira.parcelas}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderBandeiraParcelasRow}
                    />
                  )}
                  {step === 9 && (
                    <VirtualizedList
                      key="mercadoPagoCredito"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={formasPagamentoCreditoDebito}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={(pagamento, { key, style }) => {
                        return (
                          <div key={key} style={style}>
                            <ListItemSelectable
                              classesNamesObject={this.props.classes}
                              primary={pagamento.nome}
                              onClick={() => {
                                if (pagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id) {
                                  this.setState({ step: 10, mercadoPago: { tipo: pagamento } })
                                } else {
                                  this.setState({ step: 11 })
                                  this.mercadoPago = pagamento
                                  this.returnDadosPagamento()
                                  this.props.handleCloseDialog()
                                }
                              }}
                            />
                          </div>
                        )
                      }}
                    />
                  )}
                  {step === 10 && (
                    <VirtualizedList
                      key="creditoParcelas"
                      windowScroller={true}
                      scrollElement={this.state.scrollContainerRef.current}
                      items={parcelasMercadoPago}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={this.renderBandeiraParcelasRow}
                    />
                  )}
                </React.Fragment>
              )}
            </DialogContent>
          </ContentWithPreload>
        </CustomDialog>
        <PagamenteOpenedDialog
          isVisible={isMercadoPagoDialogVisible}
          onVisible={this.handleClosePagamentoOpenedDialog}
          status={'OUTRO_PAGAMENTO'}
          handleCloseDialog={this.props.handleCloseDialog}
          thisNotaDialogPage={this}
          mercadoPagoDebitPayment={mercadoPagoDebitPayment}
          notaId={mercadoPagoDebitPayment?.notaVenda?.idNotaVenda}
          device={this.device}
        />
      </>
    )
  }
}

function ListItemSelectable(props) {
  const rootClasses = [props.classesNamesObject.rootListItem]
  let onClick = props.onClick
  if (props.disabled) {
    rootClasses.push(props.classesNamesObject.rootListItemDisabled)
    onClick = null
  }

  return (
    <Grid container alignItems="center" className={classNames(rootClasses)} onClick={onClick}>
      <Grid item xs className={props.classesNamesObject.contentListItem}>
        <Typography variant="body2" className={props.classesNamesObject.primaryListItem}>
          {props.primary}
        </Typography>
        {props.secondary && (
          <Typography variant="body2" className={props.classesNamesObject.secondaryListItem}>
            {props.secondary}
          </Typography>
        )}
      </Grid>
      {props.isMercadoPago && (
        <Tag className={props.classesNamesObject.tag} shape="ghost">
          INTEGRADA
        </Tag>
      )}
      <Grid item>
        <NavigateNextIcon className={props.classesNamesObject.iconListItem} />
      </Grid>
    </Grid>
  )
}

InputFormaPagamentoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func,
  getValue: PropTypes.func
}

export default withStyles(styles)(withConfigCatClient(InputFormaPagamentoDialog))
