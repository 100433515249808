import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'

const MAX_WIDTH = 460

const styles = (theme) => ({})

class EntityDuplicationDialog extends Component {
  constructor(props) {
    super(props)
    this.paperRef = React.createRef()
    this.state = {
      content: null
    }
  }

  render() {
    const { title, descricao } = this.props

    const content = (
      <React.Fragment>
        {descricao}
        <br key="br1" />
        <br key="br2" />

        {this.props.conteudo.map((item) => {
          return (
            <Grid key={'i_' + item.id} container alignItems="center" wrap="nowrap">
              <Grid item style={{ marginRight: 16 }}>
                {item.icone}
              </Grid>
              <Grid>
                <Typography variant="body2">{item.descricao}</Typography>
              </Grid>
            </Grid>
          )
        })}
      </React.Fragment>
    )

    return (
      <CustomDialog dialogProps={this.props} paperStyle={{ maxWidth: MAX_WIDTH }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent ref={this.paperRef}>{content}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              this.props.handleCloseDialog({
                onClosedCallback: () => {
                  this.props.onConfirm()
                }
              })
            }}
          >
            SALVAR MESMO ASSIM
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

EntityDuplicationDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EntityDuplicationDialog)
