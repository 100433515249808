import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import ClienteDadosBasicosDialogPage from 'pages/erp/painel/cliente/ClienteDadosBasicosDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class ClienteInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    const creationFunction = (props) => {
      openBackableDialog(ClienteDadosBasicosDialogPage, {
        nome: props.text
      })
    }

    if (!props.label) props.label = 'Cliente'
    if (!props.id) props.id = 'xcliente'
    if (!props.name) props.name = 'xcliente'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.apelido
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.creationFunction = creationFunction
    props.DialogSelectProps.creationPermission = verificarAutorizacao([PA.PODE_CADASTRAR_CLIENTE])
    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Cliente...'
    props.DialogSelectProps.endpoint = 'erp/clientes.buscarLista'
    props.DialogSelectProps.searchParamName = 'nome'
    props.DialogSelectProps.generateItemData = (cliente) => {
      return {
        value: cliente.id,
        label: cliente.apelido,
        primary: cliente.apelido,
        cliente: cliente
      }
    }

    props.DialogSelectProps.autoSelectDefinitons = [
      {
        eventName: 'ManipulacaoCliente',
        itemPropName: 'cliente'
      }
    ]

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum cliente foi encontrado',
        textButton: 'Cadastrar cliente',
        creationFunction: creationFunction
      }
    }

    return <InputDialogSelect {...props} />
  }
}

ClienteInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ClienteInputDialogSelect)
