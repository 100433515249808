/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { Device } from 'support/domain/mercadopago/MercadoPago'
import { DevicesTypeEnum } from 'support/util/MercadoPagoUtil'

import { Maquininha } from './Maquininha'
import { useStyles } from './MaquininhaIntegrada.styles'
import { MaquininhaIntegradaCardTaxs } from './MaquininhaIntegradaCardTaxs'
import { ModalMaquininha } from './ModalMaquininha'

type DevicesResponse = {
  data: {
    devices: Device[]
  }
}

const ASC = -1
const DESC = 1

export const MaquininhaIntegrada = () => {
  const classes = useStyles()

  const [devices, setDevices] = React.useState<Device[]>([])
  const [loading, setLoading] = React.useState(false)
  const [loadingDevice, setLoadingDevice] = React.useState(false)
  const [selectedDevice, setSelectedDevice] = React.useState<Device | null>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const sortArray = (dataDevices: Device[]) => [...dataDevices].sort((a) => (a.id.includes(DevicesTypeEnum.GERTEC) ? ASC : DESC))

  const handleDevices = () => {
    setLoading(true)
    postAPI({
      url: 'erp/vendas/integracoes-mp/primeiros-passos',
      requerAutorizacao: true,
      onSuccess: (response: DevicesResponse) => {
        if (response.data.devices.length > 0) {
          const sortedDevices = sortArray(response.data.devices)
          setDevices(sortedDevices)
        }
        setLoading(false)
        setIsOpen(false)
      }
    })
  }

  const handleEditDevice = (device: Device, cb?: () => void) => {
    setLoadingDevice(true)
    postAPI({
      url: 'erp/vendas/integracoes-mp/dispositivos',
      data: {
        id: device.id,
        operating_mode: device.operating_mode,
        alias: inputValue.length > 0 ? inputValue : device.alias
      },
      requerAutorizacao: true,
      onSuccess: () => {
        const newDevices = devices?.map((item) => {
          if (item.id === device.id) {
            return {
              ...item,
              operating_mode: device.operating_mode,
              alias: inputValue.length > 0 ? inputValue : device.alias
            }
          }
          return item
        })
        setDevices(newDevices)
        setIsOpen(false)
        setLoadingDevice(false)
        setInputValue('')
        cb?.()
      }
    })
  }

  const handleOpenLink = (url: string) => {
    window.open(url, '_blank')
  }

  React.useEffect(() => {
    handleDevices()
  }, [])

  if (loading) {
    return (
      <Box className={classes.loadingContent}>
        <CircularProgress data-testid="loading" size={34} thickness={5.2} />
      </Box>
    )
  }

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.wrapperCard}>
          <MaquininhaIntegradaCardTaxs onOpenLink={handleOpenLink} />
          <Box className={classes.grid}>
            {devices.map((device) => (
              <Maquininha
                key={device.id}
                deviceItem={device}
                selectedDevice={selectedDevice as Device}
                onSelecDevice={setSelectedDevice}
                onInputValue={setInputValue}
                onOpenLink={handleOpenLink}
                onEditDevice={handleEditDevice}
                onOpen={setIsOpen}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <ModalMaquininha
        isOpen={isOpen}
        inputValue={inputValue}
        loading={loadingDevice}
        selectedDevice={selectedDevice as Device}
        onEditDevice={handleEditDevice}
        onInputValue={setInputValue}
        onOpen={setIsOpen}
      />
    </>
  )
}
