import React, { Component } from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

class Login extends Component {
  render() {
    let { style, ...others } = this.props

    if (!style) {
      style = {}
    }

    if (!style.marginTop) {
      style.marginTop = 0
    }
    style.marginTop = style.marginTop + 2

    if (!style.marginLeft) {
      style.marginLeft = 0
    }
    style.marginLeft = style.marginLeft + 6

    if (!style.marginRight) {
      style.marginRight = 0
    }
    style.marginRight = style.marginRight - 6

    if (!style.marginBottom) {
      style.marginBottom = 0
    }
    style.marginBottom = style.marginBottom - 2

    return (
      <SvgIcon viewBox="0 0 24 24" {...others} style={style}>
        <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="Dribbble-Light-Preview" transform="translate(-385.000000, -7399.000000)">
            <g id="icons" transform="translate(56.000000, 160.000000)">
              <path
                d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                id="facebook-[#176]"
              ></path>
            </g>
          </g>
        </g>
      </SvgIcon>
    )
  }
}

export default Login
