import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputSelect from 'support/components/input/InputSelect'
import { formatarDuracao } from 'support/util/TimeFormatter'

const styles = (theme) => ({})

const options = [
  gerarOption(5),
  gerarOption(10),
  gerarOption(15),
  gerarOption(20),
  gerarOption(25),
  gerarOption(30),
  gerarOption(35),
  gerarOption(40),
  gerarOption(45),
  gerarOption(50),
  gerarOption(55),
  gerarOption(60),
  gerarOption(70),
  gerarOption(75),
  gerarOption(80),
  gerarOption(90),
  gerarOption(100),
  gerarOption(105),
  gerarOption(110),
  gerarOption(120),
  gerarOption(135),
  gerarOption(150),
  gerarOption(165),
  gerarOption(180),
  gerarOption(195),
  gerarOption(210),
  gerarOption(225),
  gerarOption(240),
  gerarOption(270),
  gerarOption(300),
  gerarOption(330),
  gerarOption(360),
  gerarOption(390),
  gerarOption(420),
  gerarOption(480),
  gerarOption(540),
  gerarOption(600),
  gerarOption(660),
  gerarOption(720)
]

function gerarOption(minutos) {
  return {
    value: minutos,
    descricao: formatarDuracao(minutos),
    descricaoResumida: formatarDuracao(minutos, { descricao: 'resumida' }),
    descricaoMinificada: formatarDuracao(minutos, { descricao: 'minificada' })
  }
}

class DuracaoInputSelect extends Component {
  render() {
    const { classes, descricaoResumida, ...othersInputSelect } = this.props

    return (
      <InputSelect {...othersInputSelect}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {!descricaoResumida ? option.descricao : option.descricaoResumida}
          </MenuItem>
        ))}
      </InputSelect>
    )
  }
}

DuracaoInputSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DuracaoInputSelect)
