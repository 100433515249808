enum DiscountPlansEnum {
  AVALIACAO = 'AVALIACAO',
  INDIVIDUAL = 'INDIVIDUAL',
  EMPRESA = 'EMPRESA'
}

const PlansIdToName = {
  1: DiscountPlansEnum.AVALIACAO,
  2: DiscountPlansEnum.INDIVIDUAL,
  3: DiscountPlansEnum.EMPRESA
}

export const formatDiscountPlanIdToName = (id: number) => PlansIdToName[id as keyof typeof PlansIdToName]
