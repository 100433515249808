export enum StatusPagamentoEnum {
  ABERTO = 'ABERTO',
  CANCELADO = 'CANCELADO',
  PENDENTE = 'PENDENTE',
  CONCLUIDO = 'CONCLUIDO',
  ESTORNADO = 'ESTORNADO'
}

export enum TipoPagamentoEnum {
  CARTAO_CREDITO = 'Cartão de Crédito',
  CARTAO_DEBITO = 'Cartão de Débito'
}

export type StatusPagamentoType = keyof typeof StatusPagamentoEnum
