import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import ColaboradorDadosBasicosDialogPage from 'pages/erp/painel/colaborador/ColaboradorDadosBasicosDialogPage'
import ColaboradorDialogPage from 'pages/erp/painel/colaborador/ColaboradorDialogPage'
import ColaboradorPhotoPicker from 'pages/erp/painel/colaborador/picker/ColaboradorPhotoPicker'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { createEnum } from 'support/util/EnumUtil'
import ImageUtil from 'support/util/ImageUtil'

const styles = (theme) => ({
  colunaFoto: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaEmail: {
    flexGrow: 1.2
  },
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

export const VisaoEnum = createEnum({
  ATIVOS: { id: 1, descricao: 'Ativos' },
  EXCLUIDOS: { id: 2, descricao: 'Excluídos' }
})

class ColaboradoresPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      visao: VisaoEnum.ATIVOS,
      lista: []
    }
    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoColaborador', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  getRequestParameters = () => {
    const ativo = this.state.visao.id === VisaoEnum.ATIVOS.id ? true : false
    const excluido = this.state.visao.id === VisaoEnum.EXCLUIDOS.id ? true : false
    return {
      nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null,
      ativo: ativo,
      excluido: excluido
    }
  }

  closeItemMenu() {
    this.setState((state) => ({
      colaboradorMenuAnchorEl: null
    }))
  }

  handlerOpenCreateDialog = () => {
    if (!verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(ColaboradorDadosBasicosDialogPage)
  }

  handlerEditar = (colaborador) => {
    openBackableDialog(ColaboradorDialogPage, {
      idColaborador: colaborador.id
    })
  }

  handlerRemover = () => {
    if (this.state.itemMenu.admin) {
      openBackableDialog(MessageDialog, {
        title: 'Acesso Negado',
        text: 'Não é permitido remover o administrador do sistema.'
      })
      return
    }

    if (this.state.itemMenu.id === getSessaoPainel().colaborador.id) {
      openBackableDialog(MessageDialog, {
        title: 'Acesso Negado',
        text: 'Não é permitido remover você mesmo.'
      })
      return
    }

    if (!verificarAutorizacao([PA.PODE_REMOVER_COLABORADOR])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover colaborador',
      text: (
        <span>
          Você tem certeza que deseja remover o colaborador <b>{this.state.itemMenu.nome}</b>?
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/colaboradores.remover',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoColaborador')
        },
        errorKey: 'colaborador'
      }
    })
  }

  handlerReativar = () => {
    if (!verificarAutorizacao([PA.PODE_REMOVER_COLABORADOR])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityConfirmationDialog, {
      title: 'Reativar colaborador',
      text: (
        <span>
          Você tem certeza que deseja reativar o colaborador <b>{this.state.itemMenu.nome}</b>?
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/colaboradores.reativar',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoColaborador')
          this.setState({ visao: VisaoEnum.ATIVOS })
        },
        errorKey: ['colaborador', 'erroPlanoIndividual']
      }
    })
  }

  getRenderContent = (size, colaborador) => {
    const content = {}

    let email = 'Não cadastrado'
    if (colaborador.usuario.email) {
      email = colaborador.usuario.email
    }

    const variacoesFoto = ImageUtil.extrairVariacoesImagem(colaborador.foto)

    content.foto = <ColaboradorPhotoPicker colaborador={colaborador} photo1x={variacoesFoto.get(2)} width={38} preCheckWritePermission={true} />
    content.nomeColaborador = colaborador.apelido
    content.email = email
    content.botaoOpcoes = (
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ colaboradorMenuAnchorEl: event.currentTarget, itemMenu: colaborador })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )
    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Lista de Colaboradores - ' + moment().format('YYYYDDMM'),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'colaboradores.gerarListaColaboradores',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { visao } = this.state

    let onClickRow = null

    if (getSessaoPainel().negocio.ativo) {
      onClickRow = (item) => {
        this.handlerEditar(item)
      }
    }

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push({
      icon: <AddIcon />,
      disabled: !getSessaoPainel().negocio.ativo,
      handleAction: this.handlerOpenCreateDialog
    })

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS]) && visao.id === VisaoEnum.ATIVOS.id) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        disabled: !getSessaoPainel().negocio.ativo,
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <PanelPage {...others} title="Colaboradores" toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 16 }} disabled={!verificarAutorizacao([PA.PODE_REMOVER_COLABORADOR])}>
              {verificarAutorizacao([PA.PODE_REMOVER_COLABORADOR]) && (
                <InputSelect
                  customVariant="naked"
                  nakedLeftPadding={false}
                  value={this.state.visao.id}
                  fullWidth={false}
                  onChange={(event) => {
                    const visao = VisaoEnum.getById(parseInt(event.target.value, 10))
                    this.setState({ visao: visao })
                  }}
                >
                  {VisaoEnum.values().map((visao) => (
                    <MenuItem key={visao.id} value={visao.id}>
                      {visao.descricao}
                    </MenuItem>
                  ))}
                </InputSelect>
              )}
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    nakedLeftPadding={false}
                    placeholder="Procurar por nome..."
                    onChange={(event) => {
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>

        <br />

        <VirtualizedResponsiveTable
          key={visao.id}
          showBackgroundDividers={true}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/colaboradores.buscar"
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          onClickRow={visao.id === VisaoEnum.ATIVOS.id ? onClickRow : undefined}
          largeRenderProps={{
            columns: [
              { className: classes.colunaFoto },
              { label: 'Nome', props: { xs: true } },
              { label: 'E-mail', className: classes.colunaEmail, props: { xs: true }, showOnlyUp: '800', horizontalPadding: 'small' },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [content.foto, content.nomeColaborador, content.email, content.botaoOpcoes]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 80,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center">
                      <Grid item style={{ marginRight: 16 }}>
                        {content.foto}
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2">{content.nomeColaborador}</Typography>
                      </Grid>
                      <Grid item>{content.botaoOpcoes}</Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            padding: true,
            text: 'Nenhum colaborador foi encontrado',
            textButton: 'Cadastrar Colaborador',
            creationFunction: this.handlerOpenCreateDialog
          }}
        />

        <Menu anchorEl={this.state.colaboradorMenuAnchorEl} open={Boolean(this.state.colaboradorMenuAnchorEl)} onClose={() => this.closeItemMenu()}>
          {visao.id === VisaoEnum.ATIVOS.id && (
            <React.Fragment>
              <MenuItem
                disabled={!getSessaoPainel().negocio.ativo}
                onClick={() => {
                  this.handlerEditar(this.state.itemMenu)
                  this.closeItemMenu()
                }}
              >
                Editar Colaborador
              </MenuItem>
              <MenuItem
                disabled={!verificarAutorizacao([PA.PODE_REMOVER_COLABORADOR])}
                onClick={() => {
                  this.handlerRemover()
                  this.closeItemMenu()
                }}
              >
                Remover Colaborador
              </MenuItem>
            </React.Fragment>
          )}
          {visao.id === VisaoEnum.EXCLUIDOS.id && (
            <React.Fragment>
              <MenuItem
                disabled={!verificarAutorizacao([PA.PODE_REMOVER_COLABORADOR])}
                onClick={() => {
                  this.handlerReativar()
                  this.closeItemMenu()
                }}
              >
                Reativar Colaborador
              </MenuItem>
            </React.Fragment>
          )}
        </Menu>

        {this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            <Typography variant="body2">
              Colaboradores cadastrados: <b>{this.state.lista.length}</b>
              <br />
            </Typography>
          </FloatContent>
        )}
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ColaboradoresPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ColaboradoresPanelPage)
