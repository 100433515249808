import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import EventIcon from '@material-ui/icons/Event'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import PersonIcon from '@material-ui/icons/Person'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import ClienteInputDialogSelect from 'pages/erp/painel/cliente/input/ClienteInputDialogSelect'
import RedirecionadorFluxoPosCadastroVendaDialog from 'pages/erp/painel/cliente/RedirecionadorFluxoPosCadastroVendaDialog'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import DescontoInputSelect from 'pages/erp/painel/configuracoes/desconto/input/DescontoInputSelect'
import PacoteInputDialogSelect from 'pages/erp/painel/pacotes/input/PacoteInputDialogSelect'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import DescontoDisponibilidadeEnum from 'support/domain/desconto/DescontoDisponibilidadeEnum'
import DescontoService from 'support/domain/desconto/DescontoService'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { formatarValorCalculo } from 'support/util/CalculoFormatter'
import { converterDataIntParaDate, converterTudoParaMoment } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const INPUT_CUSTOM_VARIANT = 'outlined-small'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class VendaPacoteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    let colaborador = props.colaboradorPreSelecionado

    if (colaborador && colaborador.vendePacotes !== undefined && colaborador.vendePacotes === false) {
      colaborador = null
    }

    if (!colaborador) {
      const colaboradorSessao = getSessaoPainel().colaborador
      if (colaboradorSessao && colaboradorSessao.vendePacotes) {
        colaborador = colaboradorSessao
      }
    }

    this.state = {
      paginaCarregada: false,
      editavel: true,
      processado: false,
      mensagemNaoEditavel: '',
      data: converterTudoParaMoment(props.data),
      cliente: props.cliente,
      colaborador: colaborador,
      fechamentoContaAutomatico: true,
      vendaPacote: null,
      title: 'Pacote',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.clienteFunctionsMap = {}
    this.dataFunctionsMap = {}
    this.colaboradorFunctionsMap = {}
    this.pacoteFunctionsMap = {}
    this.inputPreco = React.createRef()
    this.inputPrecoFunctionsMap = {}
    this.descontoFunctionsMap = {}
    this.descontoSelectFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/pacotes.buscarDadosVendaPrePersistencia',
      params: {
        idVendaPacote: this.props.idVendaPacote
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const newState = {
          paginaCarregada: true,
          editavel: true
        }

        const vendaPacote = response.data.vendaPacote

        if (vendaPacote && vendaPacote.id) {
          newState.vendaPacote = vendaPacote
          newState.data = converterDataIntParaDate(vendaPacote.data)
          newState.cliente = vendaPacote.venda.cliente
          newState.colaborador = vendaPacote.venda.colaborador
          this.selecionarPacote(vendaPacote.pacote)
          newState.preco = vendaPacote.venda.valorUnitario

          if (vendaPacote.venda.processada === true) {
            newState.processado = true
            if (vendaPacote.venda.cancelado === true) {
              newState.editavel = false
              newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Esta venda está cancelada.'
            } else {
              newState.mensagemNaoEditavel = (
                <span>
                  É possível alterar apenas a{' '}
                  <b>
                    <u>data</u>
                  </b>{' '}
                  desta venda porque a fatura #{vendaPacote.venda.notaVenda.numero} já foi gerada.
                </span>
              )
            }
          } else if (vendaPacote.venda.excluido === true) {
            newState.editavel = false
            newState.mensagemNaoEditavel = 'Não é possível realizar alterações. Este venda foi removida.'
          }
        }

        newState.toolbarActions = {
          actions: [
            {
              label: 'Salvar',
              show: newState.editavel,
              handleAction: () => this.salvar()
            },
            {
              label: 'Voltar',
              show: !newState.editavel,
              handleAction: () => this.props.handleCloseDialog()
            }
          ]
        }

        this.setState(newState)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDadosProcessado = () => {
    let dados = {}
    dados.id = this.props.idVendaPacote
    dados.data = this.dataFunctionsMap.getDataAsInt()
    return dados
  }

  getDadosNaoProcessado = () => {
    let dados = {}

    dados.data = this.dataFunctionsMap.getDataAsInt()

    if (this.props.idVendaPacote) {
      dados.id = this.props.idVendaPacote
    }

    if (this.props.contexto === 'AdicionarItensFaturaExistente') {
      dados.nota = this.props.nota
      dados.fechamentoContaAutomatico = false
    } else {
      dados.fechamentoContaAutomatico = this.state.fechamentoContaAutomatico
    }

    dados.cliente = this.state.cliente

    dados.colaborador = {
      id: this.colaboradorFunctionsMap.getValue()
    }

    const idPacote = this.pacoteFunctionsMap.getValue()
    if (idPacote) {
      dados.pacote = {
        id: idPacote
      }
    }
    dados.preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)

    dados = { ...dados, aplicacaoDesconto: this.descontoFunctionsMap.getValues() }
    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    if (this.state.processado === true) {
      this.salvarProcessado()
    } else {
      this.salvarNaoProcessado()
    }
  }

  salvarProcessado = (ignorarConflitosHorarios) => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = this.getDadosProcessado()

    postAPI({
      url: 'erp/pacotes.alterarDadosVendaAposProcessamento',
      data: dados,
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoVendaPacote', {
          action: 'update',
          vendaPacote: response.data
        })
        EventsManager.pub('ManipulacaoVenda', {
          action: 'update'
        })
        if (this.props.parent) {
          this.props.setShouldCloseParent(true)
        }
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formVendaPacote')
      }
    })
  }

  salvarNaoProcessado = () => {
    if (!this.state.editavel) {
      openBackableDialog(MessageDialog, {
        title: 'Aviso',
        text: this.state.mensagemNaoEditavel
      })
      return
    }

    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    let textoConfirmacaoCadastro = ''

    if (this.state.fechamentoContaAutomatico) {
      textoConfirmacaoCadastro = 'Os créditos já estão disponíveis no cadastro do cliente e você já pode agendar os serviços!'
    } else {
      textoConfirmacaoCadastro = 'Venda cadastrada com sucesso!'
    }

    const dados = this.getDadosNaoProcessado()
    postAPI({
      url: 'erp/pacotes.persistirVenda',
      data: dados,
      requerAutorizacao: true,
      onSuccess: (response) => {
        if (this.props.contexto !== 'AdicionarItensFaturaExistente') {
          EventsManager.pub('ManipulacaoVendaPacote', {
            action: this.props.idVendaPacote ? 'update' : 'create',
            vendaPacote: response.data
          })
          EventsManager.pub('ManipulacaoVenda', {
            action: this.props.idVendaPacote ? 'update' : 'create'
          })
        }

        if (this.props.contexto === 'CriacaoPainelCliente') {
          openBackableDialog(RedirecionadorFluxoPosCadastroVendaDialog, {
            parent: this,
            shouldCloseParent: true,
            textoConfirmacaoCadastro: textoConfirmacaoCadastro,
            tipoVenda: TipoVendaEnum.VENDA_PACOTE,
            realizarPagamentoProps: {
              nota: response.data.venda.notaVenda
            },
            fechamentoContaAutomatico: this.state.fechamentoContaAutomatico,
            setFecharPanelCliente: this.props.setFecharPanelCliente
              ? this.props.setFecharPanelCliente
              : () => {
                  this.props.setShouldCloseParent(true)
                }
          })
        } else {
          if (this.props.parent) {
            this.props.setShouldCloseParent(true)
          }
          if (this.props.contexto === 'AdicionarItensFaturaExistente') {
            EventsManager.pub('RecarregarAtendimentos')
            EventsManager.pub('ManipulacaoVendas')
          }
          this.props.handleCloseDialog()
        }
      },
      onError: (response) => {
        if (response && response.data && response.data.errors) {
          let mensagemErro = null
          if (response.data.errors.erroNotaVenda) {
            mensagemErro = response.data.errors.erroNotaVenda
          }
          if (mensagemErro) {
            openBackableDialog(MessageDialog, {
              maxWidth: 400,
              title: 'Erro de Validação',
              text: mensagemErro
            })
          }
        }

        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formVendaPacote')
      }
    })
  }

  selecionarPacote = (pacote) => {
    const newState = {
      pacote: null,
      preco: null
    }
    if (pacote) {
      newState.pacote = pacote
      newState.preco = pacote.preco
    }
    this.setState(newState)
  }

  render() {
    const podeAlterarPrecoVenda = verificarAutorizacao([PA.PODE_ALTERAR_PRECO_VENDA])

    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { data, cliente, vendaPacote, editavel, fechamentoContaAutomatico, ajaxing, processado, errorsMap } = this.state

    const { venda } = vendaPacote ? vendaPacote : {}

    const keyPorPacote = this.state.pacote ? this.state.pacote.id : ''

    let colaboradorDefaultValue = null

    if (this.state.colaborador) {
      colaboradorDefaultValue = this.state.colaborador
    }

    let pacoteDefaultValue = undefined
    let precoDefaultValue = undefined
    const descontoDefaultValue = {}

    if (vendaPacote && vendaPacote.id) {
      descontoDefaultValue.desconto = venda.desconto
      descontoDefaultValue.descricaoDesconto = venda.descricaoDesconto
      descontoDefaultValue.tipoCalculo = venda.tipoCalculoDesconto
      descontoDefaultValue.valor = venda.valorDesconto
    }

    const permitirFecharContaManualmenteVendaPacote = getSessaoPainel().negocio.configuracoesGerais.permitirFecharContaManualmenteVendaPacote
    let esconderOpcaoFecharContaManualmenteVendaPacote = false

    if (this.props.contexto === 'AdicionarItensFaturaExistente') {
      esconderOpcaoFecharContaManualmenteVendaPacote = true
    }

    pacoteDefaultValue = this.state.pacote
    precoDefaultValue = this.state.preco

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={570} pageType="basicForm" ajaxing={ajaxing} toolbarActions={this.state.toolbarActions}>
        {this.state.mensagemNaoEditavel && (
          <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
            <Typography variant="body2">{this.state.mensagemNaoEditavel}</Typography>
          </FloatContent>
        )}

        <ContentWithPreload
          loadFunction={this.carregarMain}
          onComponentDidUpdate={() => {
            if (this.inputPrecoFunctionsMap.updateSuperHelpMessage && this.descontoSelectFunctionsMap.updateSuperHelpMessage) {
              this.inputPrecoFunctionsMap.updateSuperHelpMessage()
              this.descontoSelectFunctionsMap.updateSuperHelpMessage()
            }
          }}
        >
          {() => (
            <form id="formVendaPacote" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: -10 }}
                icon={<PersonIcon />}
                inputTexts={[
                  {
                    input: (
                      <ClienteInputDialogSelect
                        disabled={ajaxing || !editavel || processado}
                        readOnly={!vendaPacote || !editavel ? true : false}
                        defaultValue={cliente}
                        functionsMap={this.clienteFunctionsMap}
                        label="Cliente"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('cliente')}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ cliente: value.cliente })
                          } else {
                            this.setState({ cliente: null })
                          }
                        }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<EventIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputData
                        disabled={ajaxing || !editavel}
                        functionsMap={this.dataFunctionsMap}
                        keyboard={true}
                        id="xdata"
                        name="xdata"
                        errorMessage={errorsMap('data')}
                        defaultValue={data}
                        onChange={(data) => {
                          this.setState({ data: converterTudoParaMoment(data) })
                        }}
                        label={'Data'}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<SupervisedUserCircleIcon />}
                inputTexts={[
                  {
                    input: (
                      <ColaboradorInputDialogSelect
                        context="vendaPacote"
                        disabled={ajaxing || !editavel || processado}
                        defaultValue={colaboradorDefaultValue}
                        functionsMap={this.colaboradorFunctionsMap}
                        label="Profissional"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        errorMessage={errorsMap('colaborador')}
                        DialogSelectProps={{
                          preProcessItemFunction: (colaborador) => {
                            if (colaborador.vendePacotes === true) {
                              return colaborador
                            }
                            return null
                          }
                        }}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ colaborador: value.colaborador })
                          } else {
                            this.setState({ colaborador: null })
                          }
                        }}
                        style={{ marginTop: 0 }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<ShoppingBasketIcon />}
                inputTexts={[
                  {
                    input: (
                      <PacoteInputDialogSelect
                        disabled={ajaxing || !editavel || processado}
                        defaultValue={pacoteDefaultValue}
                        functionsMap={this.pacoteFunctionsMap}
                        errorMessage={errorsMap('pacote')}
                        onChange={(value) => {
                          if (value) {
                            this.selecionarPacote(value.pacote)
                          } else {
                            this.selecionarPacote(null)
                          }
                        }}
                        label="Pacote"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        style={{ marginTop: 0 }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<AttachMoneyIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        disabled={!podeAlterarPrecoVenda || ajaxing || !editavel || processado}
                        key={'preco:' + keyPorPacote}
                        idname="xpreco"
                        helpMessage={podeAlterarPrecoVenda ? null : 'Você não tem permissão para alterar o preço'}
                        defaultValue={precoDefaultValue}
                        errorMessage={errorsMap('preco')}
                        label="Preço (R$)"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        inputComponent={InputMoneyFormat}
                        inputRef={this.inputPreco}
                        functionsMap={this.inputPrecoFunctionsMap}
                        onChange={(event) => {
                          this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                        }}
                        superHelpMessage={() => {
                          const preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)
                          const descontoValues = this.descontoFunctionsMap.getValues()
                          let precoDescontoAplicado = 0

                          if (!descontoValues.manual && descontoValues.desconto) {
                            precoDescontoAplicado = DescontoService.aplicarDesconto(preco, descontoValues.desconto.valor, descontoValues.desconto.tipoCalculoDesconto)
                          } else {
                            precoDescontoAplicado = DescontoService.aplicarDesconto(
                              preco,
                              descontoValues.valorDescontoPersonalizado,
                              descontoValues.tipoCalculoDescontoPersonalizado
                            )
                          }

                          if (precoDescontoAplicado !== undefined && precoDescontoAplicado !== null && precoDescontoAplicado >= 0) {
                            return {
                              content: 'Preço final com desconto: ' + formatarValorMonetario(precoDescontoAplicado)
                            }
                          }

                          return {
                            content: null
                          }
                        }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 6 }}
                icon={<MoneyOffIcon />}
                inputTexts={[
                  {
                    input: (
                      <DescontoInputSelect
                        disabled={ajaxing || !editavel || processado}
                        key={'desconto:' + keyPorPacote}
                        disponibilidades={[DescontoDisponibilidadeEnum.PACOTE]}
                        onChange={(event) => {
                          this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          this.descontoSelectFunctionsMap.updateSuperHelpMessage()
                        }}
                        defaultValue={descontoDefaultValue}
                        selectProps={{
                          idname: 'xdesconto',
                          label: 'Desconto',
                          functionsMap: this.descontoSelectFunctionsMap,
                          superHelpMessage: () => {
                            let valorDescontoFormatado = null
                            const descontoValues = this.descontoFunctionsMap.getValues()
                            if (!descontoValues.manual && descontoValues.desconto) {
                              valorDescontoFormatado = formatarValorCalculo(descontoValues.desconto.tipoCalculoDesconto, descontoValues.desconto.valor)
                            }

                            if (valorDescontoFormatado) {
                              return {
                                content: 'Desconto: ' + valorDescontoFormatado
                              }
                            }
                            return {
                              content: null
                            }
                          }
                        }}
                        manualInputProps={{
                          style: { marginTop: 14 },
                          idname: 'xdescontoValorManual',
                          label: 'Valor do Desconto',
                          errorMessage: errorsMap('valorDescontoPersonalizado'),
                          disabled: ajaxing,
                          readOnly: !editavel,
                          onChange: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          },
                          onKeyUp: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          },
                          onChangeTipoCalculo: () => {
                            this.inputPrecoFunctionsMap.updateSuperHelpMessage()
                          }
                        }}
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        functionsMap={this.descontoFunctionsMap}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 2, display: permitirFecharContaManualmenteVendaPacote && !esconderOpcaoFecharContaManualmenteVendaPacote ? undefined : 'none' }}
                icon={<MoneyOffIcon style={{ visibility: 'hidden' }} />}
                inputTexts={[
                  {
                    input: (
                      <FormControlLabel
                        key="cb"
                        control={
                          <Checkbox
                            color="default"
                            disabled={ajaxing || !editavel || processado}
                            checked={fechamentoContaAutomatico}
                            onChange={(event) => {
                              this.setState({
                                fechamentoContaAutomatico: event.target.checked
                              })
                            }}
                          />
                        }
                        label={<Typography variant="body2">Gerar fatura automaticamente</Typography>}
                      />
                    )
                  }
                ]}
              />

              <FormUtil.HiddenSubmitButton />

              {this.state.paginaCarregada && editavel && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={ajaxing} onClick={this.props.handleCloseDialog}>
                    Voltar
                  </Button>
                  <Button disabled={ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

VendaPacoteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendaPacoteDialogPage)
