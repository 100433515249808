import React, { Component } from 'react'

import DrawerMUI from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import DrawerMenuDivider from 'support/components/panel/DrawerMenuDivider'
import DrawerMenuItem from 'support/components/panel/DrawerMenuItem'
import CustomScrollbars from 'support/components/scroll/CustomScrollbars'
import { shouldShowTrainingBanner } from 'support/util/TrainingBannerEligibility'

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white'
  },
  drawerDocked: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 5
    }
  },
  drawerDockedPageFullScreenMode: {},
  drawerPaper: {
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    borderRight: '0px solid',
    width: theme.drawer.expandedWidth
  },
  drawerPaperClose: {
    overflow: 'hidden',
    width: theme.drawer.retractedWidth
  },
  drawerInner: {
    position: 'relative',
    flex: '1 1 0',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  drawerInnerClose: {
    width: theme.drawer.expandedWidth,
    paddingRight: '100px'
  }
})

class Drawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shadowTopOpacity: 1,
      isBannerVisible: false
    }
  }

  componentDidUpdate() {
    const { usuarioSessao, negocioSessao, colaboradorSessao } = this.props

    if (usuarioSessao?.carregado && negocioSessao?.carregado && colaboradorSessao?.carregado) {
      const shouldShowBanner = shouldShowTrainingBanner(negocioSessao.plano.id, usuarioSessao.dataVerificacao, colaboradorSessao.id)

      if (!this.state.isBannerVisible && shouldShowBanner) {
        this.setState({
          isBannerVisible: true
        })
      }

      if (this.state.isBannerVisible && !shouldShowBanner) {
        this.setState({
          isBannerVisible: false
        })
      }
    }
  }

  render() {
    const { classes, fixedTopContent, setScrollbarRef } = this.props

    const drawer = (
      <div className={classes.root}>
        {/* Pode mover para aqui o container com o logo para ficar fixo no menu */}

        <React.Fragment>
          <div>
            <Grid className={classes.toolbar} container alignItems="center">
              <Grid item style={{ width: '100%' }}>
                <List>
                  <DrawerMenuItem selectable={false}>
                    <Grid container alignItems="center" wrap="nowrap">
                      {!this.props.isMenuRetracted && (
                        <React.Fragment>
                          <Grid item xs>
                            {this.props.headerImage}
                          </Grid>
                          {this.props.desktopDrawerToggleDisabled !== true && (
                            <Grid item style={{ marginRight: -8 }}>
                              <Hidden key="DesktopDrawerToggle" smDown>
                                <IconButton onClick={this.props.handleDesktopDrawerToggle} style={{ padding: 8, color: '#333' }}>
                                  <ChevronLeftIcon style={{ display: 'block' }} />
                                </IconButton>
                              </Hidden>
                              <Hidden key="MobileDrawerToggle" mdUp>
                                <IconButton onClick={this.props.handleDrawerMobileToggle} style={{ padding: 8, color: '#333' }}>
                                  <ChevronLeftIcon style={{ display: 'block' }} />
                                </IconButton>
                              </Hidden>
                            </Grid>
                          )}
                        </React.Fragment>
                      )}
                      {this.props.isMenuRetracted && (
                        <Grid item>
                          <IconButton onClick={this.props.handleDesktopDrawerToggle} style={{ padding: 8, color: '#333', marginLeft: -8 }}>
                            <ChevronRightIcon style={{ display: 'block' }} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </DrawerMenuItem>
                </List>
              </Grid>
            </Grid>
          </div>
          <DrawerMenuDivider style={{ marginTop: -1 }} />
          {fixedTopContent && (
            <React.Fragment>
              {fixedTopContent}
              <DrawerMenuDivider />
            </React.Fragment>
          )}
        </React.Fragment>

        <div className={classNames(classes.drawerInner, !this.props.desktopDrawerOpen && classes.drawerInnerClose)}>
          <CustomScrollbars
            hideTracksWhenNotNeeded={true}
            showOnHover={true}
            verticalScrollWidth={6}
            onUpdate={(values) => {
              const { scrollTop } = values
              const shadowTopOpacity = (1 / 10) * Math.min(scrollTop, 10)
              this.setState({
                shadowTopOpacity: shadowTopOpacity
              })
            }}
            color={'#0000001a'}
            setScrollbarRef={setScrollbarRef}
          >
            <div style={{ paddingBottom: 20, paddingRight: 20 }}>{this.props.children}</div>
          </CustomScrollbars>

          <div
            style={{
              zIndex: 25,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: this.state.shadowTopOpacity === 1 ? 8 : 0,
              background: this.state.shadowTopOpacity === 1 ? 'linear-gradient(rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 100%)' : 'none'
            }}
          />
        </div>
        {this.props.desktopDrawerOpen && this.props.fixedBottomContent && (
          <div style={{ position: 'relative' }}>
            <DrawerMenuDivider />
            {this.props.fixedBottomContent}
          </div>
        )}
      </div>
    )

    return [
      <Hidden key="mobileDrawer" mdUp>
        <DrawerMUI
          variant="temporary"
          anchor={'left'}
          open={this.props.mobileDrawerOpen}
          onClose={this.props.handleDrawerMobileToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {' '}
          {/*// keepMounted : true - Better open performance on mobile.*/}
          {drawer}
        </DrawerMUI>
      </Hidden>,
      <Hidden key="desktopDrawer" xsUp={!this.props.hasMenu} smDown implementation="css">
        <DrawerMUI
          style={{ position: 'fixed', top: this.state.isBannerVisible ? this.props.theme.banner.height : 0, bottom: 0, left: 0, wwillChange: 'ttransform' }}
          variant="permanent"
          open={this.props.desktopDrawerOpen}
          className="needsWillChange"
          classes={{
            docked: classNames(classes.drawerDocked, this.props.panelPageFullScreenMode ? classes.drawerDockedPageFullScreenMode : null),
            paper: classNames(classes.drawerPaper, !this.props.desktopDrawerOpen && classes.drawerPaperClose)
          }}
        >
          {' '}
          {/* willChange - Melhora performace de scroll no chrome para elementos fixed ou filhos de pais fixed. É necessarios para renderizar bem virtualize-react */}
          {drawer}
        </DrawerMUI>
      </Hidden>
    ]
  }
}

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  usuarioSessao: PropTypes.object,
  negocioSessao: PropTypes.object,
  colaboradorSessao: PropTypes.object,
  getValue: PropTypes.func,
  theme: PropTypes.object
}

export default withStyles(styles, { withTheme: true })(Drawer)
