import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import PlaceIcon from '@material-ui/icons/Place'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import EstadoEnum from 'support/domain/localizacao/EstadoEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { CEPMask, InputStringNumberFormat } from 'support/util/Masks'
import { InputTelefoneList } from 'support/components/input/InputTelefoneList'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

const INPUT_CUSTOM_VARIANT = 'outlined-small'

class FornecedorDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idFornecedor ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
    this.inputDescricao = React.createRef()
    this.inputContatoNome = React.createRef()
    this.inputContatoEmail = React.createRef()
    this.telefonesContatoComponentFunctions = {}
    this.inputEnderecoEndereco = React.createRef()
    this.inputEnderecoNumero = React.createRef()
    this.inputEnderecoComplemento = React.createRef()
    this.inputEnderecoBairro = React.createRef()
    this.inputEnderecoCidade = React.createRef()
    this.enderecoEstadoFunctionMap = {}
    this.inputEnderecoCep = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idFornecedor) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/inventario.buscarFornecedorPorId',
        params: {
          id: this.props.idFornecedor
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState({ fornecedor: response.data })

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirFornecedor()
  }

  persistirFornecedor = () => {
    postAPI({
      url: 'erp/inventario.persistirFornecedor',
      data: this.getDadosFornecedor(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoFornecedor', {
          fornecedor: response.data
        })
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formFornecedor')
      }
    })
  }

  getDadosFornecedor = () => {
    const dados = {}
    if (this.props.idFornecedor) {
      dados.id = this.props.idFornecedor
    }

    dados.nome = this.inputNome.current.value
    dados.descricao = this.inputDescricao.current.value

    dados.contato = {
      id: this.state.fornecedor && this.state.fornecedor.contato ? this.state.fornecedor.contato.id : null,
      nome: this.inputContatoNome.current.value,
      email: this.inputContatoEmail.current.value,
      telefones: this.telefonesContatoComponentFunctions.getValues()
    }
    let cep = this.inputEnderecoCep.current.value ? this.inputEnderecoCep.current.value : null
    let estado = {
      id: this.enderecoEstadoFunctionMap.getValue()
    }

    dados.endereco = {
      id: this.state.fornecedor && this.state.fornecedor.endereco ? this.state.fornecedor.endereco.id : null,
      endereco: this.inputEnderecoEndereco.current.value,
      numero: this.inputEnderecoNumero.current.value,
      complemento: this.inputEnderecoComplemento.current.value,
      bairro: this.inputEnderecoBairro.current.value,
      cidade: this.inputEnderecoCidade.current.value,
      estado: estado,
      cep: cep
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state

    let objFornecedor = {}
    let objEndereco = {}
    let objContato = {}

    if (this.state.fornecedor) {
      objFornecedor = this.state.fornecedor
      if (this.state.fornecedor.endereco) {
        objEndereco = this.state.fornecedor.endereco
      }
      if (this.state.fornecedor.contato) {
        objContato = this.state.fornecedor.contato
      }
    }

    const { nome, descricao } = objFornecedor
    const { nomeContato, emailContato, telefonesContato } = objContato
    const { endereco, numero, complemento, bairro, cidade, estado, cep } = objEndereco

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formFornecedor" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={nomeDefaultValue}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome do Fornecedor"
                inputProps={{
                  maxLength: 255
                }}
                inputRef={this.inputNome}
              />

              <InputText
                idname="xdescricao"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={descricao}
                marginTop={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('descricao')}
                label="Descrição"
                inputProps={{
                  maxLength: 2000
                }}
                inputRef={this.inputDescricao}
              />

              <SectionSeparator icon={<ContactPhoneIcon />} title="Contato" />

              <InputText
                idname="xnome"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={nomeContato}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('contato.nome')}
                label="Nome"
                inputProps={{
                  maxLength: 255
                }}
                inputRef={this.inputContatoNome}
              />

              <InputText
                marginTop={true}
                customVariant="outlined-small"
                shrink={false}
                idname="xemail"
                label="E-mail"
                defaultValue={emailContato}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('email')}
                inputProps={{
                  maxLength: 320
                }}
                inputRef={this.inputContatoEmail}
              />

              <InputTelefoneList
                defaultPhones={telefonesContato}
                maxNumberOfPhones={2}
                disabled={this.state.ajaxing}
                errorsMap={this.state.errorsMap}
                componentFunctions={this.telefonesContatoComponentFunctions}
              />

              <SectionSeparator icon={<PlaceIcon />} title="Endereço" />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        idname="xendereco"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        defaultValue={endereco}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.endereco')}
                        label="Endereço"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoEndereco}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    containerProps: { xs: 5, sm: 4 },
                    input: (
                      <InputText
                        idname="xenderecoNumero"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        defaultValue={numero}
                        inputComponent={InputStringNumberFormat}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.numero')}
                        label="Número"
                        inputProps={{
                          maxLength: 10
                        }}
                        inputRef={this.inputEnderecoNumero}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 7, sm: 8 },
                    input: (
                      <InputText
                        idname="xenderecoComplemento"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        disabled={this.state.ajaxing}
                        defaultValue={complemento}
                        errorMessage={errorsMap('endereco.complemento')}
                        label="Complemento"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoComplemento}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        idname="xenderecoBairro"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        defaultValue={bairro}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.bairro')}
                        label="Bairro"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoBairro}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        idname="xenderecoCidade"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        disabled={this.state.ajaxing}
                        defaultValue={cidade}
                        errorMessage={errorsMap('endereco.cidade')}
                        label="Cidade"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoCidade}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    containerProps: { xs: 7 },
                    input: (
                      <InputSelect
                        idname="xenderecoEstado"
                        customVariant="outlined-small"
                        shrink={false}
                        label="Estado"
                        defaultValue={estado ? estado.id : null}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.estado')}
                        helpMessage="Selecione o estado"
                        functionsMap={this.enderecoEstadoFunctionMap}
                      >
                        {EstadoEnum.values().map((estado) => (
                          <MenuItem key={estado.id} value={estado.id}>
                            {estado.nome}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    )
                  },
                  {
                    containerProps: { xs: 5 },
                    input: (
                      <InputText
                        idname="xenderecoCep"
                        customVariant="outlined-small"
                        shrink={false}
                        defaultValue={cep}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.cep')}
                        label="CEP"
                        inputComponent={CEPMask}
                        inputRef={this.inputEnderecoCep}
                      />
                    )
                  }
                ]}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

FornecedorDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FornecedorDialogPage)
