import TipoNegocioMovimentacaoEnum from 'support/domain/fluxocaixa/TipoNegocioMovimentacaoEnum'

function isMovimentacaoReceita(movimentacao) {
  if (TipoNegocioMovimentacaoEnum.RECEITA.id === movimentacao.tipo.id) {
    return true
  }
  return false
}

function isMovimentacaoDespesa(movimentacao) {
  if (TipoNegocioMovimentacaoEnum.DESPESA.id === movimentacao.tipo.id) {
    return true
  }
  return false
}

function getDescricao(movimentacao) {
  if (isMovimentacaoReceita(movimentacao)) {
    return 'Pagamento registrado para a fatura #' + movimentacao.receita.pagamento.notaVenda.numero
  } else if (isMovimentacaoDespesa(movimentacao)) {
    return 'Despesa - ' + movimentacao.despesa.nome
  }
}

function getValorMovimentacao(movimentacao) {
  if (isMovimentacaoDespesa(movimentacao)) {
    return movimentacao.valor * -1
  } else if (isMovimentacaoReceita(movimentacao)) {
    return movimentacao.valor
  }
}

const functionsObject = {
  isMovimentacaoReceita: isMovimentacaoReceita,
  isMovimentacaoDespesa: isMovimentacaoDespesa,
  getDescricao: getDescricao,
  getValorMovimentacao: getValorMovimentacao
}

export default functionsObject
