import React, { Component } from 'react'

import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'

const styles = (theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#f1f3f4'
  },
  mainContent: {
    paddingBottom: 20,
    paddingTop: 20,
    letterSpacing: '0.1px',
    wordSpacing: '0.2px'
  },
  line: {
    fontSize: 13,
    color: '#666'
  },
  link: {
    '&:active,&:visited,&:hover,&:link': {
      color: '#666'
    }
  },
  largeContent: {
    display: 'none',
    [theme.breakpoints.up(670)]: {
      display: 'block'
    }
  },
  mediumContent: {
    display: 'none',
    [theme.breakpoints.between(360, 669)]: {
      display: 'block'
    }
  },
  smallContent: {
    display: 'none',
    [theme.breakpoints.down(360)]: {
      display: 'block'
    }
  }
})

class PortalPageFooter extends Component {
  render() {
    const { classes } = this.props
    const descricaoRazaoSocial = 'EXXOS INFORMÁTICA E PUBLICIDADE'
    const descricaoDireitosReservados = 'Salão99 © 2020 Todos os direitos reservados'
    const descricaoEndereco = 'Avenida Rui Barbosa, 4110, São José dos Pinhais, Paraná'
    const descricaoCNPJ = '17.818.983/0001-70'
    const descricaoEmailContato = 'contato@salao99.com.br'

    const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
    const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade
    const termosUsoLinkProps = TermosEPrivacidadeLinkProps.termosUso

    return (
      <div className={classes.root}>
        <div className={classes.mainContent}>
          <div className={classes.largeContent}>
            <div className={classes.line} style={{ paddingBottom: 7 }}>
              {descricaoRazaoSocial} | {descricaoDireitosReservados}
            </div>
            <div className={classes.line} style={{ paddingBottom: 7 }}>
              {descricaoEndereco} | CNPJ: {descricaoCNPJ} | {descricaoEmailContato}
            </div>
          </div>
          <div className={classes.mediumContent}>
            <div className={classes.line} style={{ paddingBottom: 7 }}>
              {descricaoDireitosReservados}
            </div>
            <div className={classes.line} style={{ paddingBottom: 7 }}>
              CNPJ: {descricaoCNPJ} | {descricaoEmailContato}
            </div>
          </div>
          <div className={classes.smallContent}>
            <div className={classes.line} style={{ paddingBottom: 7 }}>
              {descricaoDireitosReservados}
            </div>
          </div>
          <div className={classes.line}>
            <Link className={classes.link} {...termosUsoLinkProps} rel="noopener noreferrer" underline="always">
              Termos de Uso
            </Link>{' '}
            |{' '}
            <Link className={classes.link} {...politicaPrivacidadeLinkProps} rel="noopener noreferrer" underline="always">
              Política de Privacidade
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

PortalPageFooter.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PortalPageFooter)
