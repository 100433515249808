import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import Grid from '@material-ui/core/Grid'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { createEnum } from 'support/util/EnumUtil'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

export const TipoDadosParaEnvioEnotasEnum = createEnum({
  RPS: { id: 1 },
  TOKEN: { id: 2 },
  USUARIO_SENHA: { id: 3 }
})

class AlteraDadosEnotasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Alterar Dados E-notas',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      paginaCarregada: true,
      tipoEnvioDados: TipoDadosParaEnvioEnotasEnum.RPS,
    }

    this.inputSequencialNFe = React.createRef()
    this.inputSequencialLoteNFe = React.createRef()
    this.inputSerieNFe = React.createRef()
    this.inputToken = React.createRef()
    this.inputUsuario = React.createRef()
    this.inputSenha = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.registrarPaginaCarregada()
    notifyContentLoaded()
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Alterar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'admin/negocios.alterarDadosNegocioEnotas',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formAlterarDados')
      }
    })
  }

  getDados = () => {
    const dados = {
      idNegocio: this.props.negocio.id,
      sequencialNFe: this.inputSequencialNFe.current ? FormUtil.convertStringToNumber(this.inputSequencialNFe.current.value) : null,
      sequencialLoteNFe: this.inputSequencialLoteNFe.current ? FormUtil.convertStringToNumber(this.inputSequencialLoteNFe.current.value) : null,
      serieNFe: this.inputSerieNFe.current ? this.inputSerieNFe.current.value : null,
      token: this.inputToken.current ? this.inputToken.current.value : null,
      usuario: this.inputUsuario.current ? this.inputUsuario.current.value : null,
      senha: this.inputSenha.current ? this.inputSenha.current.value : null,
      tipoDadosParaEnvioEnotas: this.state.tipoEnvioDados
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, tipoEnvioDados } = this.state

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formAlterarDados" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>

              <RadioGroup
                name="xtipoEnvioDados"
                id="xtipoEnvioDados"
                value={tipoEnvioDados.id.toString()}
                onChange={(event) => {
                  this.setState({
                    tipoEnvioDados: TipoDadosParaEnvioEnotasEnum.getById(parseInt(event.target.value, 10))
                  })
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  value={TipoDadosParaEnvioEnotasEnum.RPS.id.toString()}
                  disabled={this.state.ajaxing}
                  label={
                    <React.Fragment>
                      <Typography variant="body1" style={{ fontSize: 18 }}>
                        <b>RPS</b>
                      </Typography>
                    </React.Fragment>
                  }
                />

                {
                  tipoEnvioDados &&
                  tipoEnvioDados.id === TipoDadosParaEnvioEnotasEnum.RPS.id &&
                  <Grid>
                    <Grid item>
                      <InputText
                        idname="xSequencialNFe"
                        customVariant="outlined-small"
                        shrink={false}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('sequencialNFe')}
                        label="Sequencial NFe"
                        inputRef={this.inputSequencialNFe}
                      />
                    </Grid>
                    <Grid item>
                      <InputText
                        idname="xSerieNFe"
                        customVariant="outlined-small"
                        shrink={false}
                        marginTop={true}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('serieNFe')}
                        label="Série NFe"
                        inputRef={this.inputSerieNFe}
                      />
                    </Grid>
                    <Grid item>
                      <InputText
                        idname="xSequencialLoteNFe"
                        customVariant="outlined-small"
                        shrink={false}
                        marginTop={true}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('sequencialLoteNFe')}
                        label="Sequencial Lote NFe"
                        inputRef={this.inputSequencialLoteNFe}
                      />
                    </Grid>
                  </Grid>
                }

                <FormControlLabel
                  control={<Radio />}
                  value={TipoDadosParaEnvioEnotasEnum.TOKEN.id.toString()}
                  disabled={this.state.ajaxing}
                  label={
                    <React.Fragment>
                      <Typography variant="body1" style={{ fontSize: 18 }}>
                        <b>Token</b>
                      </Typography>
                    </React.Fragment>
                  }
                />

                {
                  tipoEnvioDados &&
                  tipoEnvioDados.id === TipoDadosParaEnvioEnotasEnum.TOKEN.id &&
                  <InputText
                    idname="xToken"
                    customVariant="outlined-small"
                    shrink={false}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('token')}
                    label="Token"
                    inputRef={this.inputToken}
                  />
                }

                <FormControlLabel
                  control={<Radio />}
                  value={TipoDadosParaEnvioEnotasEnum.USUARIO_SENHA.id.toString()}
                  disabled={this.state.ajaxing}
                  label={
                    <React.Fragment>
                      <Typography variant="body1" style={{ fontSize: 18 }}>
                        <b>Usuário e Senha</b>
                      </Typography>
                    </React.Fragment>
                  }
                />

                {
                  tipoEnvioDados &&
                  tipoEnvioDados.id === TipoDadosParaEnvioEnotasEnum.USUARIO_SENHA.id &&
                  <Grid>
                    <Grid item>
                      <InputText
                        idname="xUsuario"
                        customVariant="outlined-small"
                        shrink={false}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('usuario')}
                        label="Usuário"
                        inputRef={this.inputUsuario}
                      />
                    </Grid>
                    <Grid item>
                      <InputText
                        idname="xSenha"
                        marginTop={true}
                        customVariant="outlined-small"
                        shrink={false}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('senha')}
                        label="Senha"
                        inputRef={this.inputSenha}
                      />
                    </Grid>
                  </Grid>
                }
              </RadioGroup>

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Voltar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Alterar'}
                  </Button>
                </div>
              )}

            </form>
          )}
        </ContentWithPreload>
      </DialogPage >
    )
  }
}

AlteraDadosEnotasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AlteraDadosEnotasDialogPage)