import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import RemuneracoesPagasDetalhesDialogPage from 'pages/erp/painel/remuneracao/RemuneracoesPorPagamentoDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DataExporter from 'support/components/dataexporter/DataExporter'
import DialogPage from 'support/components/page/DialogPage'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { createErrorsMap } from 'support/util/FormUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class RemuneracaoPagamentoDialogPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Pagamento',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      caixas: []
    }

    this.dataExporterFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/colaborador/remuneracoes.buscarRegistroPagamento',
      params: {
        idPagamento: this.props.idPagamento
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const remuneracaoPagamento = response.data
        this.setState({
          title: 'Pagamento #' + remuneracaoPagamento.numero,
          remuneracaoPagamento: remuneracaoPagamento,
          paginaCarregada: true
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  downloadRelatorio = (event) => {
    const { remuneracaoPagamento } = this.state

    let nomeArquivo = 'Registro de Pagamento - ' + remuneracaoPagamento.colaborador.apelido
    if (remuneracaoPagamento.periodoDataInicial) {
      nomeArquivo += ' - ' + formatarPeriodoNomeArquivo(remuneracaoPagamento.periodoDataInicial, remuneracaoPagamento.periodoDataFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: nomeArquivo,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'colaborador/remuneracoes.registroPagamento',
        params: { idPagamento: this.props.idPagamento }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { paginaCarregada, remuneracaoPagamento } = this.state

    const toolbarActions = {
      actions: []
    }

    if (paginaCarregada) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={380} pageType="basicForm" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX]} />

        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => {
            if (remuneracaoPagamento.periodoDataInicial) {
              return (
                <React.Fragment>
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <b>{remuneracaoPagamento.colaborador.apelido}</b>
                  </Typography>
                  <Typography variant="body2">
                    Período: {converterDataIntParaMoment(remuneracaoPagamento.periodoDataInicial).format('DD/MMM/YY')} até{' '}
                    {converterDataIntParaMoment(remuneracaoPagamento.periodoDataFinal).format('DD/MMM/YY')}
                  </Typography>
                  <Typography variant="body2">Remunerações para Pagamento: {remuneracaoPagamento.remuneracoes.length}</Typography>
                  <Typography variant="body2">Total a Pagar: {formatarValorMonetario(remuneracaoPagamento.valorTotalAPagar)}</Typography>
                  <Button
                    style={{ marginLeft: -4, marginBottom: 12, marginTop: 10 }}
                    color="secondary"
                    size="small"
                    onClick={() => {
                      openBackableDialog(RemuneracoesPagasDetalhesDialogPage, {
                        pagamento: this.state.remuneracaoPagamento
                      })
                    }}
                  >
                    Visualizar Remunerações
                  </Button>

                  {remuneracaoPagamento.valeAbatimentos && remuneracaoPagamento.valeAbatimentos.length > 0 && (
                    <React.Fragment>
                      <br />
                      <Typography variant="body1" style={{ marginBottom: 8 }}>
                        <b>Abatimentos de Vales</b>
                      </Typography>
                      {remuneracaoPagamento.valeAbatimentos.map((valeAbatimento, index) => (
                        <Typography variant="body2" key={index}>
                          {valeAbatimento.vale.nome} - {converterDataIntParaMoment(valeAbatimento.vale.data).format('DD/MMM/YY')} -{' '}
                          {formatarValorMonetario(valeAbatimento.valorAbatimento)}
                        </Typography>
                      ))}
                    </React.Fragment>
                  )}
                  <br />

                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <b>Pagamento</b>
                  </Typography>

                  <Typography variant="body2">
                    Abatimentos de Vales: {formatarValorMonetario(remuneracaoPagamento.valorAbatimentos)}
                    <br />
                    Valor do Pagamento: {formatarValorMonetario(remuneracaoPagamento.valorPagamento)}
                    <br />
                    Data do Pagamento: {converterDataIntParaMoment(remuneracaoPagamento.dataPagamento).format('DD/MMM/YY')}
                    <br />
                    {remuneracaoPagamento.observacoes && (
                      <React.Fragment>
                        Observações: {remuneracaoPagamento.observacoes}
                        <br />
                      </React.Fragment>
                    )}
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    <b>Total Quitado: {formatarValorMonetario(remuneracaoPagamento.valorTotalQuitado)}</b>
                  </Typography>
                </React.Fragment>
              )
            } else {
              return (
                <React.Fragment>
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <b>{remuneracaoPagamento.colaborador.apelido}</b>
                  </Typography>
                  <Typography variant="body2">Remunerações para Pagamento: {remuneracaoPagamento.remuneracoes.length}</Typography>
                  <Button
                    style={{ marginLeft: -4, marginBottom: 12, marginTop: 10 }}
                    color="secondary"
                    size="small"
                    onClick={() => {
                      openBackableDialog(RemuneracoesPagasDetalhesDialogPage, {
                        pagamento: this.state.remuneracaoPagamento
                      })
                    }}
                  >
                    Visualizar Remunerações
                  </Button>
                  <br />
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <b>Pagamento</b>
                  </Typography>
                  <Typography variant="body2">
                    Valor do Pagamento: {formatarValorMonetario(remuneracaoPagamento.valorPagamento)}
                    <br />
                    Data do Pagamento: {converterDataIntParaMoment(remuneracaoPagamento.dataPagamento).format('DD/MMM/YY')}
                    <br />
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    <b>Total Quitado: {formatarValorMonetario(remuneracaoPagamento.valorTotalQuitado)}</b>
                  </Typography>
                </React.Fragment>
              )
            }
          }}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

RemuneracaoPagamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RemuneracaoPagamentoDialogPage)
