import TipoClienteTransacaoSaldoEnum from 'support/domain/credito/TipoClienteTransacaoSaldoEnum'

function isVendaCredito(clienteTransacaoSaldo) {
  if (TipoClienteTransacaoSaldoEnum.VENDA_CREDITO.id === clienteTransacaoSaldo.tipo.id) {
    return true
  }
  return false
}

function isCreditoTrocoPagamento(clienteTransacaoSaldo) {
  if (TipoClienteTransacaoSaldoEnum.CREDITO_TROCO_PAGAMENTO.id === clienteTransacaoSaldo.tipo.id) {
    return true
  }
  return false
}

function isManipulacaoDeliberadaSaldo(clienteTransacaoSaldo) {
  if (TipoClienteTransacaoSaldoEnum.MANIPULACAO_DELIBERADA_SALDO.id === clienteTransacaoSaldo.tipo.id) {
    return true
  }
  return false
}

function isPagamento(clienteTransacaoSaldo) {
  if (TipoClienteTransacaoSaldoEnum.PAGAMENTO.id === clienteTransacaoSaldo.tipo.id) {
    return true
  }
  return false
}

function isEstornoPagamento(clienteTransacaoSaldo) {
  if (TipoClienteTransacaoSaldoEnum.ESTORNO_PAGAMENTO.id === clienteTransacaoSaldo.tipo.id) {
    return true
  }
  return false
}

function isEstornoPacote(clienteTransacaoSaldo) {
  if (TipoClienteTransacaoSaldoEnum.ESTORNO_PACOTE.id === clienteTransacaoSaldo.tipo.id) {
    return true
  }
  return false
}

function getDescricao(clienteTransacaoSaldo) {
  if (isVendaCredito(clienteTransacaoSaldo)) {
    return TipoClienteTransacaoSaldoEnum.VENDA_CREDITO.descricao
  } else if (isCreditoTrocoPagamento(clienteTransacaoSaldo)) {
    return TipoClienteTransacaoSaldoEnum.CREDITO_TROCO_PAGAMENTO.descricao
  } else if (isManipulacaoDeliberadaSaldo(clienteTransacaoSaldo)) {
    return TipoClienteTransacaoSaldoEnum.MANIPULACAO_DELIBERADA_SALDO.descricao
  } else if (isPagamento(clienteTransacaoSaldo)) {
    return TipoClienteTransacaoSaldoEnum.PAGAMENTO.descricao
  } else if (isEstornoPagamento(clienteTransacaoSaldo)) {
    return TipoClienteTransacaoSaldoEnum.ESTORNO_PAGAMENTO.descricao
  } else if (isEstornoPacote(clienteTransacaoSaldo)) {
    return TipoClienteTransacaoSaldoEnum.ESTORNO_PACOTE.descricao
  }
}

const functionsObject = {
  isVendaCredito: isVendaCredito,
  isCreditoTrocoPagamento: isCreditoTrocoPagamento,
  isManipulacaoDeliberadaSaldo: isManipulacaoDeliberadaSaldo,
  isPagamento: isPagamento,
  getDescricao: getDescricao
}

export default functionsObject
