import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import TabContainer from 'support/components/tab/TabContainer'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import InventarioService from 'support/domain/inventario/InventarioService'
import TipoMovimentacaoEstoqueEnum from 'support/domain/inventario/TipoMovimentacaoEstoqueEnum'
import TipoUnidadeMedida from 'support/domain/servico/TipoUnidadeMedida'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 128,
    minWidth: 128
  },
  colunaEstoque: {
    maxWidth: 128,
    minWidth: 128
  },
  descricaoMotivoContainerSmall: {
    marginTop: 2,
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

class TabProdutoMovimentacoesEstoque extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lista: [],
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
  }

  calcularEstoque = (itens) => {
    let estoqueAtual = 0
    for (let i = itens.length - 1; i >= 0; i--) {
      let item = itens[i]
      if (item.tipo.id === TipoMovimentacaoEstoqueEnum.ENTRADA.id) {
        estoqueAtual = estoqueAtual + item.quantidade
      } else {
        estoqueAtual = estoqueAtual - item.quantidade
      }
      item.estoque = estoqueAtual
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.data = converterDataIntParaMoment(item.dataMovimentacao).format('DD/MMM/YYYY')
    content.motivo = <span style={{ fontSize: 13 }}>{InventarioService.getDescricaoMotivoMovimentacaoEstoque(item)}</span>
    const sinal = item.tipo.id === TipoMovimentacaoEstoqueEnum.SAIDA.id ? '-' : ''
    const existeQuantidadePersonalizada = item.tipoUnidadeMedida.id === TipoUnidadeMedida.PERSONALIZADA.id

    content.quantidade = (
      <span>
        {item.tipoUnidadeMedida.id === TipoUnidadeMedida.PERSONALIZADA.id && (
          <React.Fragment>
            <span>{sinal + item.quantidadeUnidadeMedida + ' ' + item.produto.unidadeMedidaEmbalagem}</span>
            <br />
          </React.Fragment>
        )}
        <span>
          {(existeQuantidadePersonalizada ? ' (' : '') +
            sinal +
            item.quantidadeUnidades +
            (item.quantidadeUnidades !== 1 ? ' unids' : ' unid') +
            (existeQuantidadePersonalizada ? ')' : '')}
        </span>
      </span>
    )

    return content
  }

  render() {
    const { classes } = this.props

    return (
      <TabContainer padding={true}>
        <VirtualizedResponsiveTable
          scrollElement={this.props.scrollElement}
          showBackgroundDividers={true}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/inventario.buscarMovimentacoesEstoque"
          getRequestParametersFunction={() => ({
            idProduto: this.props.produto.id
          })}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const movimentacoes = data.items
              this.calcularEstoque(movimentacoes)
              this.setState({ lista: movimentacoes })
            }
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Motivo', props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Estoque', className: classes.colunaEstoque, align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item, index)

              return {
                itemData: [content.data, content.motivo, content.quantidade]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 88,
            showFirstItemBorderTop: false,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center">
                      <Grid item xs style={{ marginRight: 4 }}>
                        <Typography variant="body2">
                          <b>{content.data}</b>
                        </Typography>
                        <Typography variant="body2" className={classes.descricaoMotivoContainerSmall}>
                          {content.motivo}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" style={{ fontSize: 12, textAlign: 'right' }}>
                          {content.quantidade}
                        </Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhuma movimentação de estoque registrada para este produto'
          }}
        />
      </TabContainer>
    )
  }
}

TabProdutoMovimentacoesEstoque.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabProdutoMovimentacoesEstoque)
