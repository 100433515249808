import React, { Component } from 'react'
import Helmet from 'react-helmet'

import { withStyles } from '@material-ui/core/styles'
import DrawerPortal from 'pages/portal/painel/DrawerPortal'
import RoutesPortal from 'pages/portal/painel/RoutesPortal'
import { ROUTE_PORTAL_MAIN_AS_PREFIX, ROUTE_PORTAL_PANEL, ROUTE_PORTAL_PANEL_REGEX } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { patchAPI } from 'support/components/api/PortalApiClient'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import Panel from 'support/components/panel/Panel'
import { logCORE } from 'support/util/Logger'

const styles = () => ({})

class PainelPortal extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {}
    this.panelFunctionMap = {}
  }

  carregarPainel = (props) => {
    let rota = undefined
    if (window.location.pathname.match(new RegExp('.*' + ROUTE_PORTAL_PANEL_REGEX + '(\\/p){0,1}\\/{0,1}$', 'gi'))) {
      rota = ROUTE_PORTAL_PANEL + '/p/agendamentos'
    }
    this.panelFunctionMap.notificarPainelCarregado({ rota: rota })
  }

  atualizarSessao = (props) => {}

  limparSessao = (props) => {}

  logout = () => {
    patchAPI({
      url: 'cda/usuarios.logout',
      onSuccess: () => {
        logCORE('Logout', { component: 'PainelPortal', context: 'Logout - success' })
      },
      onError: () => {
        logCORE('Logout', { component: 'PainelPortal', context: 'Logout - error' })
      },
      requerAutorizacao: true
    })

    EventsManager.pub('EfetuarLogout')
    EventsManager.pub('RoutePush', {
      path: ROUTE_PORTAL_MAIN_AS_PREFIX + '/busca'
    })
  }

  navegarPaginaPainel = (props) => {
    this.panelFunctionMap.alterarRota(ROUTE_PORTAL_PANEL + '/p' + props.rota)
  }

  render() {
    if (this.props.portalCarregado && (!this.props.usuarioSessao || !this.props.usuarioSessao.carregado)) {
      this.logout()
      return []
    }

    const renderDrawerAndPagesProps = {
      hasMenu: true,
      usuarioSessao: this.props.usuarioSessao
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Salão99 - Minha Conta</title>
          <meta name="description" content="Minha conta, meus atendimentos, favoritos e mais." />
        </Helmet>
        <Panel
          {...this.props}
          hasSyncLoad={false}
          functionsMap={this.panelFunctionMap}
          carregarPainel={this.carregarPainel}
          atualizarSessao={this.atualizarSessao}
          limparSessao={this.limparSessao}
          handleLogout={this.logout}
          navegarPaginaPainel={this.navegarPaginaPainel}
          renderDrawer={(props) => <DrawerPortal {...props} {...renderDrawerAndPagesProps} />}
          renderPages={(props) => <RoutesPortal {...props} {...renderDrawerAndPagesProps} />}
        />
      </React.Fragment>
    )
  }
}

PainelPortal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PainelPortal)
