import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PortalApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  starIcon: {
    color: '#ffac14',
    fontSize: 32,
    [theme.breakpoints.down(680)]: {
      fontSize: 28
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 24
    }
  },
  containerFooterButtons: theme.form.containerFooterButtons()
})

class AvaliacaoDialogPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nota: this.props.nota ? this.props.nota : 5,
      title: '',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputComentario = React.createRef()
  }

  handleSubmit(event) {
    event.preventDefault()
    this.salvar()
  }

  getDados = () => {
    return {
      negocio: {
        id: this.props.negocio.id
      },
      nota: this.state.nota,
      comentario: this.inputComentario.current.value
    }
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'ptl/negocios.persistirAvaliacao',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoAvaliacao')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState({ ajaxing: false })
        focusFirstElementWithError('formAvaliacao')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { nota, errorsMap, ajaxing } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              label: 'Salvar',
              handleAction: () => this.salvar()
            }
          ]
        }}
      >
        <form id="formAvaliacao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
          <Typography variant="h6" style={{ marginBottom: 10, textAlign: 'center' }}>
            <b>{this.props.negocio.nome}</b>
          </Typography>

          <Grid container justify="center" style={{ maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }}>
            {[1, 2, 3, 4, 5].map((notaOpcao) => {
              let Icon = StarIcon
              if (notaOpcao > nota) {
                Icon = StarBorderIcon
              }
              return (
                <Grid item xs key={notaOpcao} style={{ textAlign: 'center' }}>
                  <IconButton
                    onClick={() => {
                      this.setState({ nota: notaOpcao })
                    }}
                  >
                    <Icon className={classes.starIcon} />
                  </IconButton>
                </Grid>
              )
            })}
          </Grid>

          <InputText
            marginTop={16}
            idname="xcomentario"
            autoFocus={true}
            defaultValue={this.props.comentario}
            customVariant="outlined-small"
            shrink={true}
            multiline={true}
            rows={6}
            rowsMax={8}
            maxLength={400}
            disabled={ajaxing}
            errorMessage={errorsMap('comentario')}
            label="Comentário"
            placeholder="Faça um comentário..."
            inputRef={this.inputComentario}
          />
          <br />
          <br />

          <HiddenSubmitButton />
        </form>
      </DialogPage>
    )
  }
}

AvaliacaoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AvaliacaoDialogPage)
