import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import VendaSupport from 'pages/erp/painel/venda/VendaSupport'
import PropTypes from 'prop-types'
import NfStatusIcon from 'support/components/icon/NfStatus'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import StatusApresentacaoNfEnum from 'support/domain/venda/StatusApresentacaoNfEnum'
import TipoNfEnum from 'support/domain/venda/TipoNfEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 110
  },
  colunaNumero: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 110
  },
  colunaOrigem: {
    flexGrow: 1,
    maxWidth: 190,
    minWidth: 190
  },
  colunaTomador: {
    flexGrow: 1
  },
  colunaIconeStatusApresentaco: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  colunaSituacao: {
    maxWidth: 94,
    minWidth: 94,
    [theme.breakpoints.down(686)]: {
      display: 'none'
    }
  }
})

class VendasResponsiveTable extends Component {
  constructor(props) {
    super(props)
    this.facilitadorRemocaoRepeticaoFunctionMap = {}
    this.podeVisualizarValorVenda = verificarAutorizacao([PA.PODE_VISUALIZAR_VALOR_VENDA]) ? true : false
  }

  removerVenda = (venda, event) => {
    VendaSupport.removerVenda({ venda: venda, event: event, facilitadorRemocaoRepeticaoFunctionMap: this.facilitadorRemocaoRepeticaoFunctionMap })
  }

  getRenderContent = (size, item) => {
    const content = {}

    if (item.nf.tipo.id === TipoNfEnum.NFSE.id) {
      content.data = moment(item.nf.nfsDataCompetencia).format('DD/MMM/YY')
      content.numero = item.nf.nfsNumero
      content.origem = 'Fatura #' + item.notaVenda.numero
      content.valor = formatarValorMonetario(item.nf.nfsValorTotal)
      content.tomadorNome = item.nf.tomadorNome
      content.descricaoStatus = StatusApresentacaoNfEnum.getById(item.nf.statusApresentacao.id).descricaoResumida
      content.iconeStatus = <NfStatusIcon nf={item.nf} hasTooltip={true} />
    }

    return content
  }

  render() {
    const { classes, origem, ...others } = this.props

    return (
      <React.Fragment>
        <VirtualizedResponsiveTable
          showBackgroundDividers={true}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Numero', className: classes.colunaNumero },
              { label: 'Origem', className: classes.colunaOrigem, horizontalPadding: 'small', props: { xs: true } },
              { label: 'Tomador/Cliente', className: classes.colunaTomador, horizontalPadding: 'small', props: { xs: true } },
              { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right', display: this.podeVisualizarValorVenda },
              { label: 'Situação', className: classes.colunaSituacao },
              { label: '', className: classes.colunaIconeStatusApresentaco, align: 'right', horizontalPadding: 'small', isVisible: false }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [content.data, content.numero, content.origem, content.tomadorNome, content.valor, content.descricaoStatus, content.iconeStatus]
              }
            }
          }}
          mediumRenderProps={{
            headerColumnHeight: 52,
            rowHeight: 88,
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Informações', props: { xs: true } },
              { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right', display: this.podeVisualizarValorVenda },
              { label: 'Situação', className: classes.colunaSituacao },
              { label: '', className: classes.colunaIconeStatusApresentaco, align: 'right', horizontalPadding: 'small', isVisible: false }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('medium', item)

              return {
                itemData: [
                  content.data,
                  <React.Fragment>
                    <Typography variant="body2" noWrap={true}>
                      <b>{content.tomadorNome}</b>
                    </Typography>
                    {content.numero && (
                      <Typography variant="body2" noWrap={true}>
                        Número: {content.numero}
                      </Typography>
                    )}
                    <Typography variant="body2" noWrap={true}>
                      {content.origem}
                    </Typography>
                  </React.Fragment>,
                  content.valor,
                  content.descricaoStatus,
                  content.iconeStatus
                ]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 116,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center">
                      <Grid item xs style={{ minWidth: 0 /*minWidth - para ativar as reticências */ }}>
                        <Typography variant="body2">{content.data}</Typography>
                        <Typography variant="body2" noWrap={true}>
                          <b>{content.tomadorNome}</b>
                        </Typography>
                        {content.numero && (
                          <Typography variant="body2" noWrap={true}>
                            Número: {content.numero}
                          </Typography>
                        )}
                        <Typography variant="body2">{content.origem}</Typography>
                        <Typography variant="body2">{content.valor}</Typography>
                      </Grid>
                      <Grid item align="right">
                        {content.iconeStatus}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          {...others}
        />
      </React.Fragment>
    )
  }
}

VendasResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendasResponsiveTable)
