import React, { Component } from 'react'

import { Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PhoneIcon from '@material-ui/icons/Phone'
import moment from 'moment'
import ClienteDadosBasicosDialogPage from 'pages/erp/painel/cliente/ClienteDadosBasicosDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import BaseDialog from 'support/components/dialog/BaseDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import WhatsAppService from 'support/domain/whatsapp/WhatsAppService'
import SimpleMessageTemplateUtil from 'support/util/SimpleMessageTemplateUtil'

const styles = (theme) => ({})

class EnviarMensagemManualAniversarioDialog extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      paginaCarregada: false,
      telefones: []
    }
    this.contentWithPreloadFunctions = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCliente', (props) => {
      this.contentWithPreloadFunctions.reload()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/clientes.buscarDadosParaEnvioManualAniversario',
      params: {
        idCliente: this.props.cliente.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const telefones = response.data.telefones
        if (telefones && telefones.length > 0) {
          telefones.sort(function (a, b) {
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          })
        }
        this.setState({
          paginaCarregada: true,
          telefones: telefones,
          configuracaoMensagemAniversario: response.data.configuracaoMensagemAniversario
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  generateMessage = () => {
    const { cliente } = this.props
    const { configuracaoMensagemAniversario } = this.state

    const valuesMap = {}
    const agora = moment()

    if (agora.hour() >= 12 && agora.hour() <= 18) {
      valuesMap['[saudacao]'] = 'Boa tarde'
    } else if (agora.hour() >= 19 || agora.hour() <= 3) {
      valuesMap['[saudacao]'] = 'Boa noite'
    } else {
      valuesMap['[saudacao]'] = 'Bom dia'
    }

    valuesMap['[nome-empresa]'] = getSessaoPainel().negocio.nome

    valuesMap['[nome-cliente]'] = cliente.nome
    valuesMap['[apelido-cliente]'] = cliente.apelido

    return SimpleMessageTemplateUtil.processTemplate(configuracaoMensagemAniversario.modeloMensagemManual, valuesMap)
  }

  abrirDialogConfirmacaoEnvio = () => {
    this.props.setShouldCloseParent(true)

    window.setTimeout(() => {
      openBackableDialog(EntityConfirmationDialog, {
        parent: this,
        shouldCloseParent: true,
        title: 'Confirmação',
        text: 'Deseja marcar que a mensagem de parabéns foi enviada?',
        call: {
          method: 'post',
          url: 'erp/clientes.marcarEnvioMensagemAniversarioManual',
          contextoUsuario: 'erp',
          data: {
            idCliente: this.props.cliente.id
          },
          onSuccess: () => {
            EventsManager.pub('ClienteConfirmacaoEnvioManualAniversario')
          }
        }
      })
    }, 1000)
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { cliente } = this.props
    const { telefones, paginaCarregada } = this.state

    let content = null
    let actions = null

    if (paginaCarregada) {
      if (telefones && telefones.length) {
        content = (
          <React.Fragment>
            <Typography variant="body2" style={{ marginTop: 6, marginBottom: 16 }}>
              Enviar para o telefone:
            </Typography>
            {telefones.map((telefone) => {
              const numeroDestino = telefone.codigoDiscagemPais + telefone.numero
              const messagem = this.generateMessage()
              const linkProps = WhatsAppService.generateClickToChatLinkProps({
                phone: numeroDestino,
                message: messagem,
                onClick: () => {
                  this.abrirDialogConfirmacaoEnvio()
                }
              })
              return (
                <MenuItem {...linkProps} key={telefone.id} component={Link}>
                  <Grid container style={{ width: '100%' }} justify="center" alignItems="center">
                    <Grid item>
                      <PhoneIcon style={{ display: 'block' }} />
                    </Grid>
                    <Grid item xs style={{ marginLeft: 12 }}>
                      {telefone.numero}
                    </Grid>
                    <Grid item align="right" style={{ marginRight: -8 }}>
                      <NavigateNextIcon style={{ display: 'block' }} />
                    </Grid>
                  </Grid>
                </MenuItem>
              )
            })}
          </React.Fragment>
        )
      } else {
        content = (
          <React.Fragment>
            <Typography variant="body1">Este cliente não possui nenhum telefone de contato cadastrado.</Typography>
          </React.Fragment>
        )
        actions = (
          <Button
            color="secondary"
            onClick={() => {
              openBackableDialog(ClienteDadosBasicosDialogPage, { idCliente: cliente.id })
            }}
          >
            CADASTRAR TELEFONES
          </Button>
        )
      }
    }

    return (
      <BaseDialog {...dialogProps} title={cliente.apelido} actions={actions} maxWidth={510} hasCloseButton={true}>
        <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarMain}>
          {() => content}
        </ContentWithPreload>
      </BaseDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

EnviarMensagemManualAniversarioDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EnviarMensagemManualAniversarioDialog)
