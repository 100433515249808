import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import CaixaStatusEnum from 'support/domain/caixa/CaixaStatusEnum'
import CaixaTipoEnum from 'support/domain/caixa/CaixaTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterMomentParaDataInt, converterTudoParaMoment } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class DadosBasicosCaixaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: this.props.idCaixa ? 'Editar Caixa' : 'Abrir Caixa',
      errorsMap: createErrorsMap(),
      caixaTipo: this.props.caixa ? this.props.caixa.tipo : CaixaTipoEnum.CAIXA_COMPARTILHADO,
      ajaxing: false,
      caixa: this.props.caixa,
      dataInicial: moment(),
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }

    this.inputValor = React.createRef()
    this.inputObservacoes = React.createRef()
    this.dataFunctionsMap = {}
    this.colaboradorFunctionsMap = {}
  }

  componentDidMount() {
    this.setState({
      paginaCarregada: true
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/caixas.buscarDadosCaixaPrePersistencia',
      params: {
        idCaixa: this.props.idCaixa
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const state = this.state

        if (response.data.caixa) {
          state.caixa = response.data.caixa
          state.caixaTipo = CaixaTipoEnum.getById(response.data.caixa.tipo.id)
          state.dataInicial = converterTudoParaMoment(response.data.caixa.dataInicial)
        } else {
          state.saldosDisponiveisCaixa = response.data.saldosDisponiveisCaixa
        }

        this.setState(state)

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const endpoint = !this.state.caixa ? 'erp/caixas.abrirCaixa' : 'erp/caixas.alterarCaixa'

    postAPI({
      url: endpoint,
      data: this.getDadosCaixa(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoCaixa')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formAberturaCaixa')
      }
    })
  }

  getDadosCaixa = () => {
    const dados = {}

    let idColaborador = null

    if (this.state.caixa) {
      if (this.state.caixa.colaborador) {
        idColaborador = this.state.caixa.colaborador.id
      }
    } else {
      idColaborador = getSessaoPainel().colaborador.id
    }

    if (this.state.caixa) {
      dados.caixa = { idCaixa: this.state.caixa.id }
    } else {
      if (verificarAutorizacao([PA.PODE_ABRIR_CAIXA_OUTROS_COLABORADORES])) {
        idColaborador = this.colaboradorFunctionsMap.getValue()
      }
    }

    dados.valor = FormUtil.convertStringToNumber(this.inputValor.current.value)
    dados.observacao = this.inputObservacoes.current.value
    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.tipo = {
      id: this.state.caixaTipo.id
    }

    dados.colaborador = {
      id: idColaborador
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, errorsMap, caixa, saldosDisponiveisCaixa, utilizarValorFechamentoUltimoCaixa } = this.state
    const { fundoCaixa, observacao, colaborador } = caixa ? caixa : {}

    let saldoDisponivelCaixa = this.state.saldoDisponivelCaixa
    let colaboradorDefaultValue = colaborador
    let valorFundoCaixaDefault = 0

    if (saldosDisponiveisCaixa && saldosDisponiveisCaixa.length > 0) {
      const tipoCaixa = this.state.caixaTipo
      const colaboradorSelecionado = this.state.colaborador
      saldosDisponiveisCaixa.forEach((item) => {
        if (item.tipoCaixa.id === tipoCaixa.id && item.dataCaixa < converterMomentParaDataInt(this.state.dataInicial)) {
          if (!item.colaborador && !colaboradorSelecionado) {
            saldoDisponivelCaixa = item
          } else if (item.colaborador && colaboradorSelecionado && item.colaborador.id === colaboradorSelecionado.id) {
            saldoDisponivelCaixa = item
          }
        }
      })
    }

    if (fundoCaixa) {
      valorFundoCaixaDefault = fundoCaixa
    } else if (utilizarValorFechamentoUltimoCaixa) {
      valorFundoCaixaDefault = saldoDisponivelCaixa.valorTotalDisponivelCaixa
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formAberturaCaixa" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputSelect
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.caixaTipo.id}
                disabled={ajaxing || (this.state.caixa && !this.state.caixa.colaborador) ? true : false}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ caixaTipo: CaixaTipoEnum.getById(id) })
                }}
              >
                {CaixaTipoEnum.values().map((tipo) => (
                  <MenuItem key={tipo.id} value={tipo.id}>
                    {tipo.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>

              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.caixaTipo.descricaoExplicacao}
              </Typography>

              <InputData
                marginTop={true}
                functionsMap={this.dataFunctionsMap}
                keyboard={true}
                disabled={caixa || ajaxing ? true : false}
                defaultValue={this.state.dataInicial ? this.state.dataInicial.toDate() : null}
                idname="xdata"
                errorMessage={errorsMap(['data', 'caixa'])}
                label={'Data do caixa'}
                customVariant={'outlined-small'}
                shrink={false}
                onChange={(data) => {
                  this.setState({
                    dataInicial: converterTudoParaMoment(data)
                  })
                }}
              />

              {((!this.state.caixa && verificarAutorizacao([PA.PODE_ABRIR_CAIXA_OUTROS_COLABORADORES])) ||
                (this.state.caixa && verificarAutorizacao([PA.PODE_ALTERAR_CAIXA_OUTROS_COLABORADORES]))) && (
                <ColaboradorInputDialogSelect
                  disabled={ajaxing || this.state.caixa ? true : false}
                  marginTop={true}
                  customVariant={'outlined-small'}
                  shrink={false}
                  defaultValue={colaboradorDefaultValue}
                  functionsMap={this.colaboradorFunctionsMap}
                  errorMessage={errorsMap('colaborador')}
                  helpMessage="Deixe este campo em branco para torna o caixa geral e sem dono. O caixa fica disponível para todos os colaboradores com permissão."
                  label={'Colaborador dono do caixa'}
                  onChange={(value) => {
                    this.setState({ utilizarValorFechamentoUltimoCaixa: false })

                    if (value) {
                      this.setState({ colaborador: value.colaborador })
                    } else {
                      this.setState({ colaborador: null })
                    }
                  }}
                />
              )}

              <InputText
                marginTop={true}
                customVariant={'outlined-small'}
                shrink={false}
                key={'valor:' + valorFundoCaixaDefault}
                disabled={ajaxing || utilizarValorFechamentoUltimoCaixa}
                idname="xvalorAbertura"
                label="Valor Abertura (R$)"
                errorMessage={errorsMap('valor')}
                defaultValue={valorFundoCaixaDefault}
                inputComponent={InputMoneyFormat}
                inputProps={{
                  minValue: 0,
                  maxValue: 999999
                }}
                inputRef={this.inputValor}
              />

              {saldoDisponivelCaixa && saldoDisponivelCaixa.valorTotalDisponivelCaixa > 0 && (
                <FormControlLabel
                  style={{ marginTop: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      disabled={this.state.ajaxing}
                      key={'k' + utilizarValorFechamentoUltimoCaixa}
                      checked={utilizarValorFechamentoUltimoCaixa}
                      onChange={(event) => {
                        const checked = event.target.checked
                        this.setState((state) => {
                          state.utilizarValorFechamentoUltimoCaixa = checked
                          return { state: state }
                        })
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Utilizar o valor de abertura de <b>{formatarValorMonetario(saldoDisponivelCaixa.valorTotalDisponivelCaixa)}</b> (último caixa{' '}
                      {saldoDisponivelCaixa.statusCaixa.id === CaixaStatusEnum.ABERTO.id ? 'aberto' : 'fechado'})
                    </Typography>
                  }
                />
              )}

              <InputText
                id="xobservacoes"
                name="xobservacoes"
                customVariant={'outlined-small'}
                shrink={false}
                multiline={true}
                marginTop={true}
                disabled={ajaxing}
                defaultValue={observacao}
                label="Observações"
                inputProps={{
                  maxLength: 2000
                }}
                inputRef={this.inputObservacoes}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

DadosBasicosCaixaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DadosBasicosCaixaDialogPage)
