import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  VENDA_CREDITO: { id: 1, descricao: 'Venda de Crédito' },
  CREDITO_TROCO_PAGAMENTO: { id: 2, descricao: 'Troco de Venda' },
  MANIPULACAO_DELIBERADA_SALDO: { id: 3, descricao: 'Adição de Crédito' },
  PAGAMENTO: { id: 4, descricao: 'Pagamento' },
  ESTORNO_PAGAMENTO: { id: 5, descricao: 'Estorno de Pagamento' },
  ESTORNO_PACOTE: { id: 6, descricao: 'Estorno de Pacote' }
})

export default enumObject
