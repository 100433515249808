import PubSub from 'pubsub-js'

const TOPIC_PREFIX = 'T_'

window.pubAppEvent = pub

function pub(topic, props) {
  //console.log("EVENT SUB: "+topic);
  if (props === undefined || props === null) {
    props = {}
  }
  PubSub.publish(TOPIC_PREFIX + topic, props)
}

function sub(topic, func) {
  //console.log("Registrando SUB: "+topic);
  return PubSub.subscribe(TOPIC_PREFIX + topic, function (topico, props) {
    func(props)
  })
}

function unsubscribe(param) {
  if (Array.isArray(param)) {
    param.forEach((token) => {
      unsubscribe(token)
    })
  } else {
    //console.log("Removendo SUB: "+param);
    PubSub.unsubscribe(param)
  }
}

var EventsManager = {
  new: () => {
    return {
      subscribersTokens: [],
      sub: function (topic, func) {
        if (!Array.isArray(topic)) {
          topic = [topic]
        }
        for (let i = 0; i < topic.length; i++) {
          this.subscribersTokens.push(sub(topic[i], func))
        }
      },
      unsubscribe: function () {
        unsubscribe(this.subscribersTokens)
      }
    }
  },

  pub: pub
}

export default EventsManager
