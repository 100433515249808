import React, { Component } from 'react'

import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import moment from 'moment'
import AtendimentoDialogPage from 'pages/erp/painel/atendimento/AtendimentoDialogPage'
import ClientePhotoPicker from 'pages/erp/painel/cliente/picker/ClientePhotoPicker'
import TabAtividades from 'pages/erp/painel/cliente/TabAtividades'
import TabCredito from 'pages/erp/painel/cliente/TabCredito'
import TabDadosConsolidados from 'pages/erp/painel/cliente/TabDadosConsolidados'
import TabFichaCliente from 'pages/erp/painel/cliente/TabFichaCliente'
import TabFotos from 'pages/erp/painel/cliente/TabFotos'
import TabGeral from 'pages/erp/painel/cliente/TabGeral'
import TabPacotes from 'pages/erp/painel/cliente/TabPacotes'
import VendaCreditoDialogPage from 'pages/erp/painel/cliente/VendaCreditoDialogPage'
import VendaProdutoDialogPage from 'pages/erp/painel/inventario/VendaProdutoDialogPage'
import VendaPacoteDialogPage from 'pages/erp/painel/pacotes/VendaPacoteDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import FechamentoContaDialogPage from 'pages/erp/painel/venda/FechamentoContaDialogPage'
import NotasPagamentoPendentePorClienteDialogPage from 'pages/erp/painel/venda/NotasPagamentoPendentePorClienteDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import NotaAbertaIcon from 'support/components/icon/NotaAberta'
import Subject from 'support/components/page/subject/Subject'
import Tab from 'support/components/tab/Tab'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDataIntParaMoment, converterTudoParaMoment } from 'support/util/DateConverter'
import ImageUtil from 'support/util/ImageUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class PainelClienteContent extends Component {
  constructor(props) {
    super(props)
    this.vendaDialogProps = Object.assign({}, props.vendaDialogProps)
    this.eventsManager = EventsManager.new()
    this.state = {
      data: props.vendaDialogProps && props.vendaDialogProps.data !== undefined ? converterTudoParaMoment(props.vendaDialogProps.data) : moment(),
      horarioPrePreenchido: props.vendaDialogProps && props.vendaDialogProps.horarioPrePreenchido ? props.vendaDialogProps.horarioPrePreenchido : null
    }
    this.contentWithPreloadFunctions = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoVenda', 'FechamentoConta', 'ManipulacaoNotaVendaPagamento', 'FechamentoNota', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.contentWithPreloadFunctions.reload()
    })
    this.eventsManager.sub('AlterarDataHorarioParaNovoAtendimento', (props) => {
      const newState = { data: converterTudoParaMoment(props.data) }
      if (props.horarioPrePreenchido !== undefined) {
        newState.horarioPrePreenchido = props.horarioPrePreenchido
      }
      this.setState(newState)
    })
  }

  carregarInformacoesVendas = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/clientes.buscarInformacoesVendas',
      params: {
        id: this.props.cliente.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          informacoesVendas: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handleChangeTab = (value) => {
    this.props.onChangeTab(value)
  }

  fecharConta = () => {
    if (verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(FechamentoContaDialogPage, {
        cliente: this.props.cliente,
        retornarVendas: true
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  abrirNotasPagamentoPendentePorCliente = () => {
    if (verificarAutorizacao([PA.PODE_FECHAR_CONTA])) {
      openBackableDialog(NotasPagamentoPendentePorClienteDialogPage, {
        cliente: this.props.cliente
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const { cliente, infoTabValue, containerScrollElement } = this.props
    const { informacoesVendas } = this.state

    let variacoesFoto = null
    const subjectInfos = []
    if (cliente) {
      variacoesFoto = ImageUtil.extrairVariacoesImagem(cliente.foto)

      let firstMarginTop = false
      let infosTypographyVariants = 'body1'

      if (this.props.exibirNomeCliente) {
        firstMarginTop = true
        infosTypographyVariants = 'body2'
      }

      subjectInfos.push(
        <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarInformacoesVendas} align="left">
          {() => {
            const contadoresContent = []
            let possuiProdutosVendidos = false
            let possuiPacotesVendidos = false

            if (informacoesVendas.quantidadeAtendimentos) {
              contadoresContent.push(
                <React.Fragment key="Atendimentos">
                  <Typography variant={infosTypographyVariants} style={{ marginTop: firstMarginTop ? 2 : 0 }}>
                    <b>Atendimentos: {informacoesVendas.quantidadeAtendimentos ? informacoesVendas.quantidadeAtendimentos : 0}</b>
                  </Typography>
                  {informacoesVendas.dataUltimoAtendimento && (
                    <Typography variant="body2" style={{ fontSize: 12 }}>
                      Último em {converterDataIntParaMoment(informacoesVendas.dataUltimoAtendimento).format('DD/MMM/YY')}
                    </Typography>
                  )}
                </React.Fragment>
              )
            }

            if (informacoesVendas.quantidadeProdutosVendidos) {
              possuiProdutosVendidos = true
              contadoresContent.push(
                <React.Fragment key="VendaProdutos">
                  <Typography variant={infosTypographyVariants} style={{ marginTop: 3 }}>
                    <b>Produtos Vendidos: {informacoesVendas.quantidadeProdutosVendidos ? informacoesVendas.quantidadeProdutosVendidos : 0}</b>
                  </Typography>
                  {informacoesVendas.dataUltimaVendaProduto && (
                    <Typography variant="body2" style={{ fontSize: 12 }}>
                      Último em {converterDataIntParaMoment(informacoesVendas.dataUltimaVendaProduto).format('DD/MMM/YY')}
                    </Typography>
                  )}
                </React.Fragment>
              )
            }

            if (informacoesVendas.quantidadePacotesVendidos) {
              possuiPacotesVendidos = true
              contadoresContent.push(
                <React.Fragment key="VendaPacotes">
                  <Typography variant={infosTypographyVariants} style={{ marginTop: 3 }}>
                    <b>Pacotes Vendidos: {informacoesVendas.quantidadePacotesVendidos ? informacoesVendas.quantidadePacotesVendidos : 0}</b>
                  </Typography>
                  {informacoesVendas.dataUltimaVendaPacote && (
                    <Typography variant="body2" style={{ fontSize: 12 }}>
                      Último em {converterDataIntParaMoment(informacoesVendas.dataUltimaVendaPacote).format('DD/MMM/YY')}
                    </Typography>
                  )}
                </React.Fragment>
              )
            }

            const totalValoresPendentes =
              (informacoesVendas.valorTotalPendenteContaAberta ? informacoesVendas.valorTotalPendenteContaAberta : 0) +
              (informacoesVendas.valorTotalPendenteNotasAbertas ? informacoesVendas.valorTotalPendenteNotasAbertas : 0)

            if (totalValoresPendentes && verificarAutorizacao([PA.PODE_VISUALIZAR_VALORES_PENDENTES_SALDO_CLIENTE])) {
              contadoresContent.push(
                <ContainerEmAberto
                  key="ValoresPendentes"
                  infosTypographyVariants={infosTypographyVariants}
                  informacoesVendas={informacoesVendas}
                  fecharConta={this.fecharConta}
                  abrirNotasPagamentoPendentePorCliente={this.abrirNotasPagamentoPendentePorCliente}
                />
              )
            }

            if (cliente.saldo && verificarAutorizacao([PA.PODE_VISUALIZAR_VALORES_PENDENTES_SALDO_CLIENTE])) {
              contadoresContent.push(
                <React.Fragment key="Saldo">
                  <Typography variant={infosTypographyVariants} style={{ marginTop: 3 }}>
                    <b>Saldo: {formatarValorMonetario(cliente.saldo)}</b>
                  </Typography>
                </React.Fragment>
              )
            }

            if (contadoresContent.length === 0) {
              contadoresContent.push(
                <React.Fragment key="Todos">
                  <Typography variant={infosTypographyVariants} style={{ marginTop: firstMarginTop ? 2 : 0 }}>
                    <b>Atendimentos: 0</b>
                  </Typography>
                  <Typography variant={infosTypographyVariants}>
                    <b>Produtos Vendidos: 0</b>
                  </Typography>
                  <Typography variant={infosTypographyVariants}>
                    <b>Pacotes Vendidos: 0</b>
                  </Typography>
                </React.Fragment>
              )
            }

            if (contadoresContent.length === 5) {
              contadoresContent.splice(1, 2)
            } else if (contadoresContent.length === 4) {
              if (possuiProdutosVendidos && possuiPacotesVendidos) {
                contadoresContent.splice(2, 1)
              } else {
                contadoresContent.splice(1, 1)
              }
            }

            return contadoresContent
          }}
        </ContentWithPreload>
      )
    }

    const tabs = [{ label: 'Geral' }, { label: 'Atividades' }, { label: 'Galeria de fotos', labelXs: 'Fotos' }, { label: 'Pacotes' }]
    const content = [
      <TabGeral cliente={cliente} scrollElement={containerScrollElement} />,
      <TabAtividades cliente={cliente} scrollElement={containerScrollElement} />,
      <TabFotos cliente={cliente} scrollElement={containerScrollElement} />,
      <TabPacotes cliente={cliente} scrollElement={containerScrollElement} />
    ]
    let indiceAbaConsumo = 1
    let indiceAbaFichas = 3
    let indiceAbaSaldo = 4

    if (verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      indiceAbaFichas++
      indiceAbaSaldo++
      tabs.splice(indiceAbaConsumo, 0, { label: 'Consumo' })
      content.splice(indiceAbaConsumo, 0, <TabDadosConsolidados cliente={cliente} scrollElement={containerScrollElement} />)
    }

    if (verificarAutorizacao([PA.PODE_VISUALIZAR_FICHAS_CLIENTE])) {
      indiceAbaSaldo++
      tabs.splice(indiceAbaFichas, 0, { label: 'Fichas' })
      content.splice(indiceAbaFichas, 0, <TabFichaCliente cliente={cliente} scrollElement={containerScrollElement} />)
    }

    if (verificarAutorizacao([PA.PODE_VISUALIZAR_VALORES_PENDENTES_SALDO_CLIENTE])) {
      tabs.splice(indiceAbaSaldo, 0, { label: 'Saldo' })
      content.splice(indiceAbaSaldo, 0, <TabCredito cliente={cliente} scrollElement={containerScrollElement} />)
    }

    return (
      <div>
        <Subject
          photo={<ClientePhotoPicker cliente={cliente} photo1x={variacoesFoto.get(0)} framedImage={true} width={148} />}
          title={this.props.exibirNomeCliente ? cliente.apelido : null}
          infos={subjectInfos}
          rightActionButton={
            <BotaoAdicionar
              parentDialog={this.props.parentDialog}
              cliente={cliente}
              data={this.state.data}
              horarioPrePreenchido={this.state.horarioPrePreenchido}
              vendaDialogProps={this.vendaDialogProps}
            />
          }
        />

        <Tab
          value={infoTabValue}
          onChange={(event, value) => {
            this.handleChangeTab(value)
          }}
          tabs={tabs}
        />
        <div>{content[infoTabValue]}</div>
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

class ContainerEmAberto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuAnchorPosition: null
    }
  }

  openMenuAnchorPosition = (event) => {
    this.setState({
      menuAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      }
    })
  }

  closeMenuAnchorPosition = () => {
    this.setState({
      menuAnchorPosition: null
    })
  }

  render() {
    const { menuAnchorPosition } = this.state
    const { infosTypographyVariants, informacoesVendas } = this.props

    const totalValoresPendentes =
      (informacoesVendas.valorTotalPendenteContaAberta ? informacoesVendas.valorTotalPendenteContaAberta : 0) +
      (informacoesVendas.valorTotalPendenteNotasAbertas ? informacoesVendas.valorTotalPendenteNotasAbertas : 0)

    const possuiValorPendenteContaAberta = informacoesVendas.valorTotalPendenteContaAberta && informacoesVendas.valorTotalPendenteContaAberta > 0 ? true : false
    const possuiValorPendenteNotasAbertas = informacoesVendas.valorTotalPendenteNotasAbertas && informacoesVendas.valorTotalPendenteNotasAbertas > 0 ? true : false

    return (
      <React.Fragment key="Todos">
        <span key="VendaValoresPendentes" style={{ cursor: 'pointer', display: 'block', marginTop: 3 }} onClick={this.openMenuAnchorPosition}>
          <Typography variant={infosTypographyVariants}>
            <b>Pendente: {formatarValorMonetario(totalValoresPendentes)}</b>
          </Typography>
          {totalValoresPendentes && (
            <Typography variant="body2" style={{ fontSize: 12 }} color="secondary">
              <b>Ver Detalhes</b>
            </Typography>
          )}
        </span>
        <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={this.closeMenuAnchorPosition}>
          {possuiValorPendenteContaAberta && (
            <MenuItem
              style={{ display: 'block' }}
              onClick={() => {
                this.props.fecharConta()
                this.closeMenuAnchorPosition()
              }}
            >
              <Grid container alignItems="center">
                <Grid item style={{ marginRight: 12 }}>
                  <ShoppingCartOutlinedIcon />
                </Grid>
                <Grid item xs>
                  Faturamento Pendente
                  <br />
                  <small style={{ opacity: 0.6 }}>
                    <b>{formatarValorMonetario(informacoesVendas.valorTotalPendenteContaAberta)}</b>
                  </small>
                </Grid>
              </Grid>
            </MenuItem>
          )}
          {possuiValorPendenteNotasAbertas && (
            <MenuItem
              style={{ display: 'block' }}
              onClick={() => {
                this.props.abrirNotasPagamentoPendentePorCliente()
                this.closeMenuAnchorPosition()
              }}
            >
              <Grid container alignItems="center">
                <Grid item style={{ marginRight: 12 }}>
                  <NotaAbertaIcon />
                </Grid>
                <Grid item xs>
                  Faturas em Aberto
                  <br />
                  <small style={{ opacity: 0.6 }}>
                    <b>{formatarValorMonetario(informacoesVendas.valorTotalPendenteNotasAbertas)}</b>
                  </small>
                </Grid>
              </Grid>
            </MenuItem>
          )}
        </Menu>
      </React.Fragment>
    )
  }
}

class BotaoAdicionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adicionarMenuAnchorPosition: null
    }
  }

  openAdicionarMenuAnchorPosition = (event) => {
    this.setState({
      adicionarMenuAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      }
    })
  }

  closeAdicionarMenuAnchorPosition = () => {
    this.setState({
      adicionarMenuAnchorPosition: null
    })
  }

  abrirAtendimentoDialogPage = () => {
    this.abrirVendaDialogPage(AtendimentoDialogPage)
  }

  abrirVendaProdutoDialogPage = () => {
    this.abrirVendaDialogPage(VendaProdutoDialogPage)
  }

  abrirVendaPacoteDialogPage = () => {
    this.abrirVendaDialogPage(VendaPacoteDialogPage)
  }

  abrirVendaCreditoDialogPage = () => {
    this.abrirVendaDialogPage(VendaCreditoDialogPage)
  }

  abrirVendaDialogPage = (dialogPage) => {
    openBackableDialog(dialogPage, {
      ...this.props.vendaDialogProps,
      data: this.props.data,
      horarioPrePreenchido: this.props.horarioPrePreenchido,
      cliente: this.props.cliente,
      parent: this.props.parentDialog,
      contexto: 'CriacaoPainelCliente'
    })
    this.closeAdicionarMenuAnchorPosition()
  }

  render() {
    const { adicionarMenuAnchorPosition } = this.state

    const podeMarcarAtendimento = verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])
    const podeVender = verificarAutorizacao([PA.PODE_VENDER])

    return (
      <React.Fragment>
        <Fab data-testid='menu' color="secondary" onClick={this.openAdicionarMenuAnchorPosition}>
          <AddIcon />
        </Fab>
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={adicionarMenuAnchorPosition}
          open={Boolean(adicionarMenuAnchorPosition)}
          onClose={this.closeAdicionarMenuAnchorPosition}
        >
          <MenuItem onClick={this.abrirAtendimentoDialogPage} disabled={!podeMarcarAtendimento}>
            Novo Atendimento
          </MenuItem>
          <MenuItem onClick={this.abrirVendaProdutoDialogPage} disabled={!podeVender}>
            Venda de Produto
          </MenuItem>
          <MenuItem onClick={this.abrirVendaPacoteDialogPage} disabled={!podeVender}>
            Venda de Pacote
          </MenuItem>
          <MenuItem onClick={this.abrirVendaCreditoDialogPage} disabled={!podeVender}>
            Adicionar Crédito
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}

PainelClienteContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PainelClienteContent)
