import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import EditIcon from '@material-ui/icons/Edit'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PrintIcon from '@material-ui/icons/Print'
import classNames from 'classnames'
import { getSessaoPainel, verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import EnviarNotaParaClienteDialog from 'pages/erp/painel/venda/EnviarNotaParaClienteDialog'
import EstornoPagamentoDialogPage from 'pages/erp/painel/venda/EstornoPagamentoDialogPage'
import FecharNotaDialog from 'pages/erp/painel/venda/FecharNotaDialog'
import GerenciamentoFaturaNotasFiscaisDialogPage from 'pages/erp/painel/venda/GerenciamentoFaturaNotasFiscaisDialogPage'
import NotaDadosBasicosDialogPage from 'pages/erp/painel/venda/NotaDadosBasicosDialogPage'
import NotaPagamentoDialogPage from 'pages/erp/painel/venda/NotaPagamentoDialogPage'
import VisualizacaoItensNotaDialog from 'pages/erp/painel/venda/VisualizacaoItensNotaDialog'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { clearBackableComponents, extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { setBannerJourney, SetBannerJourney } from 'support/components/campanha/banner'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import FileExportDownloadDialog from 'support/components/dialog/preconstructed/FileExportDownloadDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import FormaPagamentoService from 'support/domain/formapagamento/FormaPagamentoService'
import { StatusPagamentoEnum } from 'support/domain/mercadopago/MercadoPagoEnum'
import DestinoValorAdicionalPagamentoEnum from 'support/domain/venda/DestinoValorAdicionalPagamentoEnum'
import EmissaoNfEnum from 'support/domain/venda/EmissaoNfEnum'
import StatusNotaVendaEnum from 'support/domain/venda/StatusNotaVendaEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { isPendingPayment, MercadoPagoUtils } from 'support/util/MercadoPagoUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

import { PagamentoMenuButton } from './PagamentoMenuButton'
import { PagamenteOpenedDialog } from './PagamentoOpenedDialog'

const styles = (theme) => ({
  containerHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(2.5)
  },
  indicadorStatus: {
    display: 'inline-block',
    padding: '10px 12px',
    color: theme.palette.text.primary,
    backgroundColor: '#f5f5f5',
    bborder: '1px solid #dcdcdc',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1'
    },
    borderRadius: 6,
    [theme.breakpoints.down(350)]: {
      marginTop: 8,
      marginBottom: 8,
      textAlign: 'center'
    }
  },
  indicadorStatusIcon: {
    color: 'rgba(0,0,0,0.65)',
    fontSize: 26
  },
  containerFecharNota: {
    borderRadius: 6,
    cursor: 'pointer',
    padding: '6px 10px',
    textAlign: 'center',
    maxWidth: 350,
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  containerFooter: {
    float: 'right',
    display: 'inline-block',
    maxWidth: 400,
    width: '100%',
    padding: 10,
    paddingBottom: 0,
    marginTop: theme.spacing(3.5)
  },
  containerPagamento: {
    '&:last-child': {
      borderBottom: '1px solid #ddd',
      paddingBottom: '12px'
    }
  },
  textError: {
    color: theme.palette.error.main
  }
})

class NotaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.state = {
      nota: null,
      title: 'Comanda',
      tabValue: 0,
      ajaxing: false,
      isMercadoPagoDialogVisible: false,
      status: ''
    }

    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(
      [
        'ManipulacaoNota',
        'ManipulacaoVenda',
        'ManipulacaoVendas',
        'ManipulacaoNotaVendaPagamento',
        'FechamentoNota',
        'ReaberturaNota',
        'CancelamentoNota',
        'EstornoNotaVendaPagamento'
      ],
      (props) => {
        this.loaderFunctionsMap.reload()
      }
    )
    this.props.setCanCloseFunction(this.canCloseDialog)
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/vendas.buscarNotaVendaPorId',
      params: {
        idNota: this.props.idNota
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const nota = response.data

        if (nota.pagamentos) {
          nota.pagamentos.sort(function (a, b) {
            if (a.dataPagamento < b.dataPagamento) return -1
            if (a.dataPagamento > b.dataPagamento) return 1
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          })
        }

        const toolbarActions = {
          container: {
            marginRight: 8
          },
          actions: [
            {
              icon: <PrintIcon />,
              handleAction: (event) => {
                this.setState({ imprimirItemMenuAnchorEl: event.currentTarget })
              }
            }
          ]
        }

        if (
          getSessaoPainel().negocio.carregado &&
          EmissaoNfEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.emissaoNf.id &&
          verificarAutorizacao([PA.PODE_VISUALIZAR_NOTAS_FISCAIS])
        ) {
          toolbarActions.actions.push({
            icon: <ReceiptLongIcon />,
            handleAction: (event) => {
              openBackableDialog(GerenciamentoFaturaNotasFiscaisDialogPage, {
                nota: nota
              })
            }
          })
        }
        const hasStackedUrl = window.location.href.split(',').length <= 2
        const hasPendingMercadoPagoPayment = nota?.pagamentos.some((item) => isPendingPayment(item?.pagamentoIntegrado?.status))
        const isMercadoPagoIntegrado = MercadoPagoUtils.isMercadoPago(nota?.pagamentos?.[0]?.formaPagamento)

        if (hasPendingMercadoPagoPayment) {
          const payment = nota?.pagamentos.find((item) => isPendingPayment(item?.pagamentoIntegrado?.status))
          postAPI({
            url: `erp/vendas/integracoes-mp/${payment?.id}`,
            requerAutorizacao: true,
            onSuccess: (response) => {
              if (response.data.status === StatusPagamentoEnum.CANCELADO) {
                this.setState({ isMercadoPagoDialogVisible: false })
                this.carregarMain(notifyContentLoaded, notifyContentNotLoaded)
              } else {
                this.setState({
                  nota: nota,
                  title: 'Comanda  #' + nota.numero,
                  paginaCarregada: true,
                  toolbarActions: toolbarActions,
                  isMercadoPagoIntegrado,
                  isMercadoPagoDialogVisible: (isPendingPayment(response.data.status) && hasStackedUrl) || this.props?.isMercadoPagoDialogVisible
                })
                notifyContentLoaded?.()
              }
            }
          })
        } else {
          this.setState({
            nota: nota,
            title: 'Comanda  #' + nota.numero,
            paginaCarregada: true,
            toolbarActions: toolbarActions,
            isMercadoPagoIntegrado
          })
          notifyContentLoaded?.()
        }
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  abrirNotaDadosBasicosDialogPage = () => {
    if (!verificarAutorizacao([PA.PODE_ALTERAR_DATA_NOTA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(NotaDadosBasicosDialogPage, {
      idNota: this.state.nota.id
    })
  }

  openMenuStatus = (event) => {
    event.stopPropagation()
    this.setState({
      statusAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      }
    })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  closeMenuStatus = (event) => {
    event.stopPropagation()
    this.setState({ statusAnchorPosition: null })
  }

  setPagamentosMercadoCancelado = () => {
    const { nota } = this.state
    this.setState({ ...this.state, nota: { ...nota, pagamentos: [] } })
  }

  abrirPagamentoDialog(props) {
    if (!verificarAutorizacao([PA.PODE_REGISTRAR_PAGAMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const nota = props?.nota || this.state?.nota

    if (!props) {
      props = {}
    }

    let valorPendentePagamento = nota.valorTotal - nota.valorPagoVendas
    if (valorPendentePagamento < 0) {
      valorPendentePagamento = 0
    }
    setBannerJourney('pagamento')

    openBackableDialog(NotaPagamentoDialogPage, {
      cliente: nota.cliente,
      nota: nota,
      pagamento: props.pagamento,
      valorPendentePagamento: valorPendentePagamento,
      retornarVendas: this?.props?.retornarVendas ? this?.props?.retornarVendas : null,
      thisNotaDialogPage: this
    })
  }

  abrirItensDialog(props) {
    const { nota } = this.state

    if (!props) {
      props = {}
    }

    openBackableDialog(VisualizacaoItensNotaDialog, {
      cliente: nota.cliente,
      nota: nota
    })
  }

  openMenuPagamento = (item, event) => {
    event.stopPropagation()
    this.setState({
      pagamentoAnchorPosition: {
        left: event.clientX,
        top: event.clientY,
        pagamento: item
      },
      pagamento: item
    })
  }

  closeMenuPagamento = (event) => {
    event.stopPropagation()
    this.setState({ pagamentoAnchorPosition: null })
  }

  removerPagamento = (props) => {
    if (!verificarAutorizacao([PA.PODE_REGISTRAR_PAGAMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Pagamento',
      text: <span>Você tem certeza que deseja remover este pagamento?</span>,
      call: {
        method: 'post',
        url: 'erp/vendas.removerNotaPagamento',
        contextoUsuario: 'erp',
        data: {
          id: props.pagamento.id
        },
        onSuccess: () => {
          EventsManager.pub('AlteracaoSaldoCliente')
          EventsManager.pub('ManipulacaoNotaVendaPagamento')
        },
        errorKey: 'caixa'
      }
    })
  }

  estornarPagamento = (props) => {
    if (!verificarAutorizacao([PA.PODE_REGISTRAR_ESTORNO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EstornoPagamentoDialogPage, {
      pagamento: props.pagamento,
      nota: this.state.nota
    })
  }

  Pagamento = (props) => {
    if (!verificarAutorizacao([PA.PODE_REGISTRAR_PAGAMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Pagamento',
      text: <span>Você tem certeza que deseja remover este pagamento?</span>,
      call: {
        method: 'post',
        url: 'erp/vendas.removerNotaPagamento',
        contextoUsuario: 'erp',
        data: {
          id: props.pagamento.id
        },
        onSuccess: () => {
          EventsManager.pub('AlteracaoSaldoCliente')
          EventsManager.pub('ManipulacaoNotaVendaPagamento')
        },
        errorKey: 'caixa'
      }
    })
  }

  reabrirNota = () => {
    if (!verificarAutorizacao([PA.PODE_FECHAR_E_REABRIR_NOTA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { nota } = this.state

    let title = 'Reabrir Comanda'
    let confirmButtonLabel = undefined

    openBackableDialog(EntityConfirmationDialog, {
      parent: this,
      title: title,
      text: (
        <span>
          Você confirma a reabertura da comanda <b>#{nota.numero}</b>?
        </span>
      ),
      confirmButtonLabel: confirmButtonLabel,
      cancelButtonLabel: 'Voltar',
      idNota: nota.id,
      call: {
        method: 'post',
        url: 'erp/vendas.reabrirNota',
        contextoUsuario: 'erp',
        data: {
          id: nota.id
        },
        onSuccess: (response) => {
          EventsManager.pub('ReaberturaNota')
        }
      }
    })
  }

  fecharNotaManualmente = () => {
    if (!verificarAutorizacao([PA.PODE_FECHAR_E_REABRIR_NOTA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { nota } = this.state

    let title = 'Fechar Comanda'
    let textoFechamento = null
    let confirmButtonLabel = undefined
    if (nota.valorSubtotal <= 0 || nota.valorPagoVendas >= nota.valorTotal) {
      textoFechamento = (
        <span>
          Você confirma o fechamento da comanda <b>#{nota.numero}</b>?
        </span>
      )
    } else {
      title = 'Pagamento pendente!'
      textoFechamento = (
        <span>
          <span className={this.props.classes.textError}>
            O valor de <b>{formatarValorMonetario(nota.valorTotal - nota.valorPago)}</b> ainda está pendente para pagamento do cliente
          </span>
          . Se você fechar esta comanda agora, <b>o valor pendente não entrará como receita no sistema</b> e não será mais possível registrar pagamentos.
        </span>
      )
      confirmButtonLabel = 'Fechar Comanda com Inadimplência'
    }

    openBackableDialog(FecharNotaDialog, {
      title: title,
      hasCloseButton: true,
      text: textoFechamento,
      confirmButtonLabel: confirmButtonLabel,
      cancelButtonLabel: 'Voltar',
      retornarVendas: this.props.retornarVendas,
      idNota: nota.id
    })
  }

  canCloseDialog = () => {
    if (!this.state.nota.pagamentos.length || this.deveSugerirFechamentoNota() === false || this.canCloseDialogFlag || !verificarAutorizacao([PA.PODE_FECHAR_E_REABRIR_NOTA])) {
      return true
    } else {
      this.canCloseDialogFlag = true
      const { nota } = this.state

      let textoSugestaoFechamento = null
      if (nota.valorSubtotal <= 0) {
        textoSugestaoFechamento = <span>Esta comanda não gerou custos para o cliente e está pronta para ser fechada. Gostaria de fechá-la antes de sair da página?</span>
      } else {
        textoSugestaoFechamento = <span>Todos os valores pendentes já foram pagos, porém esta comanda ainda está ABERTA. Gostaria de fechá-la antes de sair da página?</span>
      }

      openBackableDialog(FecharNotaDialog, {
        parent: this,
        shouldCloseParent: true,
        disabledOnClose: true,
        title: 'Fechar Comanda',
        text: textoSugestaoFechamento,
        cancelButtonLabel: 'Ignorar',
        retornarVendas: this.props.retornarVendas,
        idNota: nota.id
      })

      return false
    }
  }

  deveSugerirFechamentoNota = () => {
    const { nota } = this.state
    if (nota && StatusNotaVendaEnum.ABERTA.id === nota.status.id && !nota.cancelada && nota.valorPago >= nota.valorTotal) {
      return true
    }
    return false
  }

  cancelarNota = () => {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_NOTA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { nota } = this.state

    openBackableDialog(EntityConfirmationDialog, {
      title: 'Cancelar Comanda',
      text: (
        <span>
          Você tem certeza que deseja cancelar a comanda <b>#{nota?.numero}</b>? Essa operação não poderá ser desfeita
        </span>
      ),
      textToConfirm: 'CANCELAR',
      cancelButtonLabel: 'VOLTAR',
      call: {
        method: 'post',
        url: 'erp/vendas.cancelarNota',
        contextoUsuario: 'erp',
        data: {
          id: nota.id,
          retornarVendas: this.props.retornarVendas ? this.props.retornarVendas : null
        },
        onSuccess: (response) => {
          EventsManager.pub('CancelamentoNota')
          EventsManager.pub('ManipulacaoVendas')
          EventsManager.pub('AlteracaoSaldoCliente')
          if (this.props.retornarVendas) {
            EventsManager.pub('CancelamentoVendas', {
              vendas: response.data.vendas
            })
          }
        },
        onError: (_, context) => {
          context.props.handleCloseDialog()
          this.setState({ isMercadoPagoDialogVisible: true, status: 'ERROR_CANCELAMENTO_COMANDA' })
        },
        errorKey: ['caixa', 'vendasOutrosColaboradores']
      }
    })
  }

  downloadRelatorioComoPDF = () => {
    openBackableDialog(FileExportDownloadDialog, {
      accessTokenContext: 'colaborador',
      filename: 'Comanda ' + this.state.nota.numero,
      format: FileFormatEnum.PDF,
      downloadCall: {
        url: 'vendas.gerarNota',
        params: {
          idNota: this.state.nota.id
        }
      }
    })
  }

  enviarNotaParaCliente = () => {
    openBackableDialog(EnviarNotaParaClienteDialog, {
      nota: this.state.nota
    })
  }

  onMercadoPagoModalVisible = () => {
    this.setState({ isMercadoPagoDialogVisible: !this.state.isMercadoPagoDialogVisible })
  }

  handleCloseMercadoPagoDialog = () => {
    this.props.setCanCloseFunction(() => false)
    this.props.handleCloseDialog()
  }

  handleCloseAllStack = () => {
    const params = new URLSearchParams(location.search)
    params.delete('bc')
    history.replaceState(null, '', location.pathname)
    clearBackableComponents()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { nota, isMercadoPagoDialogVisible, statusAnchorPosition, pagamentoAnchorPosition, imprimirItemMenuAnchorEl } = this.state
    const hasPendingPayment = nota?.pagamentos.some((item) => isPendingPayment(item?.pagamentoIntegrado?.status))
    const mercadoPagoPayment = nota?.pagamentos.find((payment) => MercadoPagoUtils.isMercadoPago(payment.formaPagamento))
    const isMercadoPagoIntegrado = mercadoPagoPayment || this.props?.isMercadoPagoDialogVisible

    let containerPagamentos = null
    let containerTotaisPagamentos = []
    if (nota) {
      if (nota.pagamentos && nota.pagamentos.length > 0) {
        containerPagamentos = nota.pagamentos.map((pagamento, index) => (
          <Pagamento
            classes={classes}
            key={index}
            index={index}
            abrirPagamentoDialog={this.abrirPagamentoDialog}
            removerPagamento={this.removerPagamento}
            estornarPagamento={this.estornarPagamento}
            nota={nota}
            pagamento={pagamento}
            closeMenuPagamento={this.closeMenuPagamento}
            pagamentoAnchorPosition={pagamentoAnchorPosition}
            openMenuPagamento={this.openMenuPagamento}
          />
        ))
        if (!nota.cancelada) {
          if (nota.valorEstorno > 0) {
            containerTotaisPagamentos.push(
              <Grid key="totalPagamentos" container alignItems="center" justify="flex-end" style={{ marginTop: 24 }}>
                <Grid item xs>
                  <Typography variant="body2" align="right" style={{ fontWeight: 500 }}>
                    Pagamentos
                  </Typography>
                </Grid>
                <Grid item style={{ minWidth: 100 }}>
                  <Typography variant="body2" align="right">
                    {formatarValorMonetario(nota.valorEstorno + nota.valorPago)}
                  </Typography>
                </Grid>
              </Grid>
            )

            containerTotaisPagamentos.push(
              <Grid key="totalEstorno" container alignItems="center" justify="flex-end" style={{ marginTop: 0 }}>
                <Grid item xs>
                  <Typography variant="body2" align="right" style={{ fontWeight: 500 }}>
                    Estornos
                  </Typography>
                </Grid>
                <Grid item style={{ minWidth: 100 }}>
                  <Typography variant="body2" align="right">
                    {formatarValorMonetario(nota.valorEstorno * -1)}
                  </Typography>
                </Grid>
              </Grid>
            )
          }
          containerTotaisPagamentos.push(
            <Grid key="totalPago" container alignItems="center" justify="flex-end" style={{ marginTop: containerTotaisPagamentos.length === 0 ? 24 : 0 }}>
              <Grid item xs>
                <Typography variant="body2" align="right" style={{ fontWeight: 500 }}>
                  Total Pago
                </Typography>
              </Grid>
              <Grid item style={{ minWidth: 100 }}>
                <Typography variant="body2" align="right">
                  {formatarValorMonetario(nota.valorPago)}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      } else {
        containerPagamentos = (
          <Typography variant="body2" style={{ color: '#666' }}>
            Nenhum pagamento registrado
          </Typography>
        )
      }
    }

    return (
      <DialogPage
        {...dialogProps}
        align="center"
        contentMaxWidth={480}
        title={this.state.title}
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
        onPreClose={() => {
          EventsManager.pub('TabSaveSnackBar', { open: false })
        }}
        isMercadoPagoIntegrado={isMercadoPagoIntegrado}
        hasCloseButton
        hidebackButton
        handleCloseDialog={isMercadoPagoIntegrado ? this.handleCloseAllStack : this.props.handleCloseDialog}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <Menu anchorEl={imprimirItemMenuAnchorEl} open={Boolean(imprimirItemMenuAnchorEl)} onClose={() => this.closeMenu('imprimirItemMenuAnchorEl')}>
              <MenuItem
                onClick={() => {
                  this.downloadRelatorioComoPDF()
                  this.closeMenu('imprimirItemMenuAnchorEl')
                }}
              >
                Exportar PDF
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.enviarNotaParaCliente()
                  this.closeMenu('imprimirItemMenuAnchorEl')
                }}
              >
                Enviar para o cliente...
              </MenuItem>
            </Menu>

            <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.loaderFunctionsMap}>
              {() => {
                let exibirSecaoPagamentos = true
                if (StatusNotaVendaEnum.ABERTA.id === nota.status.id && !nota.cancelada) {
                  if (nota.valorSubtotal === 0 || nota.valorTotal === 0) {
                    exibirSecaoPagamentos = false
                  }
                } else if (!nota.pagamentos || nota.pagamentos.length === 0) {
                  exibirSecaoPagamentos = false
                }

                if (nota.pagamentos && nota.pagamentos.length > 0) {
                  exibirSecaoPagamentos = true
                }

                return (
                  <React.Fragment>
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                      <Grid item xs>
                        <Typography variant="body1" style={{ marginTop: -2 }}>
                          <b>{nota.cliente.apelido}</b>
                        </Typography>
                        <Grid alignItems="center" container style={{ marginTop: -1 }}>
                          <Grid item>
                            <Typography variant="body2">{converterDataIntParaMoment(nota.data).format('DD/MMMM/YYYY')}</Typography>
                          </Grid>
                          <Grid item style={{ marginTop: -6, marginLeft: 0 }}>
                            <IconButton size="small" style={{ padding: 6 }} onClick={this.abrirNotaDadosBasicosDialogPage}>
                              <EditIcon fontSize="small" style={{ fontSize: 20, opacity: 0.8 }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <IndicadorStatus classesObject={classes} nota={nota} onClick={this.openMenuStatus} />
                        <Menu
                          anchorReference="anchorPosition"
                          anchorPosition={statusAnchorPosition}
                          open={Boolean(statusAnchorPosition)}
                          onClose={(event) => this.closeMenuStatus(event)}
                        >
                          {StatusNotaVendaEnum.ABERTA.id === nota.status.id && (
                            <MenuItem
                              onClick={(event) => {
                                this.closeMenuStatus(event)
                                this.fecharNotaManualmente()
                              }}
                            >
                              Fechar Comanda
                            </MenuItem>
                          )}
                          {StatusNotaVendaEnum.FECHADA.id === nota.status.id && (
                            <MenuItem
                              onClick={(event) => {
                                this.closeMenuStatus(event)
                                this.reabrirNota()
                              }}
                            >
                              Reabrir Comanda
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={(event) => {
                              this.closeMenuStatus(event)
                              this.cancelarNota()
                            }}
                          >
                            Cancelar Comanda
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </Grid>

                    <Divider style={{ marginTop: 24 }} />

                    <Grid container alignItems="center" style={{ marginBottom: 8, marginTop: 14 }}>
                      <Grid item xs={6}>
                        <Typography align="left" variant="body1" style={{ fontWeight: 500 }}>
                          Resumo
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button color="secondary" style={{ marginRight: -8, textAlign: 'right' }} onClick={() => this.abrirItensDialog()}>
                          Gerenciar Itens
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography align="left" variant="body2">
                          Quantidade
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right" variant="body2">
                          {nota.quantidadeItens} ite{nota.quantidadeItens === 1 ? 'm' : 'ns'}
                        </Typography>
                      </Grid>
                    </Grid>
                    {nota.quantidadeUtilizacaoPacotes > 0 && (
                      <Grid container alignItems="center" style={{ marginBottom: 14 }}>
                        <Grid item xs={6}>
                          <Typography align="left" variant="body2">
                            Utilização de Pacotes
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right" variant="body2">
                            {nota.quantidadeUtilizacaoPacotes} serviço{nota.quantidadeUtilizacaoPacotes === 1 ? '' : 's'}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography align="left" variant="body2">
                          Subtotal
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right" variant="body2">
                          {formatarValorMonetario(nota.valorSubtotal)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography align="left" variant="body2">
                          Descontos
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right" variant="body2">
                          {formatarValorMonetario(nota.valorDesconto)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {nota.valorItensCancelados > 0 && (
                      <Grid container alignItems="center">
                        <Grid item xs={6}>
                          <Typography align="left" variant="body2">
                            Itens Cancelados
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right" variant="body2">
                            {formatarValorMonetario(nota.valorItensCancelados * -1)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography align="left" variant="body2">
                          Valor Total
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right" variant="body2">
                          {formatarValorMonetario(nota.valorTotal)}
                        </Typography>
                      </Grid>
                    </Grid>

                    {exibirSecaoPagamentos && (
                      <React.Fragment>
                        <Divider style={{ marginTop: 24 }} />

                        <Grid container alignItems="center" style={{ marginBottom: 8, marginTop: 14 }}>
                          <Grid item xs={6}>
                            <Typography align="left" variant="body1" style={{ fontWeight: 500 }}>
                              Pagamentos
                            </Typography>
                          </Grid>
                          {!nota.cancelada && StatusNotaVendaEnum.ABERTA.id === nota.status.id && nota.valorTotal > 0 && (
                            <Grid item xs={6}>
                              <Typography align="right" variant="body1">
                                <Button color="secondary" style={{ marginRight: -8, textAlign: 'right' }} onClick={() => this.abrirPagamentoDialog()}>
                                  Adicionar Pagamento
                                </Button>
                              </Typography>
                            </Grid>
                          )}
                          {!isMercadoPagoIntegrado ||
                            (!nota.cancelada && StatusNotaVendaEnum.FECHADA.id === nota.status.id && !hasPendingPayment && (
                              <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Button
                                  color="secondary"
                                  style={{ marginRight: -8, textAlign: 'right' }}
                                  onClick={() => {
                                    openBackableDialog(MessageDialog, {
                                      title: 'Reembolso / Devolução',
                                      text: <span>Para registrar o reembolso ou devolução de um pagamento, reabra a comanda e registre o estorno.</span>
                                    })
                                  }}
                                >
                                  Estorno / Devolução
                                </Button>
                              </Grid>
                            ))}
                        </Grid>
                        <div style={{ marginTop: 8 }}>
                          <div>{containerPagamentos}</div>
                          {containerTotaisPagamentos}
                        </div>
                      </React.Fragment>
                    )}
                    <PagamenteOpenedDialog
                      isVisible={isMercadoPagoDialogVisible}
                      onVisible={this.onMercadoPagoModalVisible}
                      status={this.state.status || mercadoPagoPayment?.pagamentoIntegrado?.status}
                      handleCloseDialog={this.handleCloseMercadoPagoDialog}
                      thisNotaDialogPage={this}
                      pagamento={mercadoPagoPayment}
                      notaId={nota.id}
                    />
                  </React.Fragment>
                )
              }}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

function IndicadorStatus(props) {
  if (props.nota) {
    const status = StatusNotaVendaEnum.getById(props.nota.status.id)
    const containerClasses = [props.classesObject.indicadorStatus]

    let descricaoStatus = status.descricao
    let onClickFunction = props.onClick
    if (props.nota.cancelada) {
      descricaoStatus = 'Cancelada'
      onClickFunction = null
    }

    return (
      <div className={classNames(containerClasses)} onClick={onClickFunction}>
        <div style={{ textAlign: 'center' }}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {descricaoStatus}
              </Typography>
            </Grid>
            {!props.nota.cancelada && (
              <Grid item>
                <ArrowDropDownIcon style={{ marginRight: -8, display: 'block' }} />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    )
  } else {
    return []
  }
}

function Pagamento(props) {
  const { classes, pagamento, pagamentoAnchorPosition, closeMenuPagamento } = props
  const findPagamento = props.nota.pagamentos.find((item) => item.id === props.pagamento.id)
  const hasEstorno = findPagamento.valorEstornoVenda >= props.nota.valorPago
  const hasPendingPayment = isPendingPayment(pagamento?.pagamentoIntegrado?.status)
  const isMercadoPagoIntegrado = MercadoPagoUtils.isMercadoPago(pagamento.formaPagamento)
  const isMenuVisible = (isMercadoPagoIntegrado && !hasEstorno) || (!isMercadoPagoIntegrado && !props.nota.cancelada)

  let contentValorAdicional = null
  if (pagamento.valorAdicional && pagamento.destinoValorAdicional && pagamento.destinoValorAdicional.id) {
    if (DestinoValorAdicionalPagamentoEnum.TROCO.id === pagamento.destinoValorAdicional.id) {
      contentValorAdicional = (
        <Typography align="left" variant="caption" component="p" style={{ marginTop: -2 }}>
          Troco: {formatarValorMonetario(pagamento.valorAdicional)}
        </Typography>
      )
    } else if (DestinoValorAdicionalPagamentoEnum.GORJETA.id === pagamento.destinoValorAdicional.id) {
      contentValorAdicional = (
        <Typography align="left" variant="caption" component="p" style={{ marginTop: -2 }}>
          Gorjetas: {formatarValorMonetario(pagamento.valorAdicional)}
        </Typography>
      )
    } else if (DestinoValorAdicionalPagamentoEnum.CREDITO.id === pagamento.destinoValorAdicional.id) {
      contentValorAdicional = (
        <Typography align="left" variant="caption" component="p" style={{ marginTop: -2 }}>
          Crédito: {formatarValorMonetario(pagamento.valorAdicional)}
        </Typography>
      )
    }
  }

  let contentDescricaoEstorno = null
  let contentValorEstorno = null
  if (pagamento.valorTotalEstornos) {
    contentDescricaoEstorno = (
      <Typography align="left" variant="caption" component="p" style={{ marginTop: -2 }}>
        Estorno em: {converterDataIntParaMoment(pagamento.dataEstorno).format('DD/MMM/YYYY')}
      </Typography>
    )
    contentValorEstorno = (
      <Typography align="right" variant="body2">
        {formatarValorMonetario(pagamento.valorTotalEstornos * -1)}
      </Typography>
    )
  }

  let descricaoFormaPagamento = FormaPagamentoService.gerarDescricaoInline(pagamento.formaPagamento, pagamento.adquirente, pagamento.bandeira, pagamento.numeroParcelas)
  let descricaoPagoEm = 'Pago em: ' + converterDataIntParaMoment(pagamento.dataPagamento).format('DD/MMM/YYYY')
  if (hasPendingPayment) {
    descricaoPagoEm = 'Solicitado em: ' + converterDataIntParaMoment(pagamento.dataPagamento).format('DD/MMM/YYYY')
  }
  let descricaoValor = formatarValorMonetario(pagamento.valorPago)

  if (props.nota.cancelada) {
    descricaoFormaPagamento = <strike>{descricaoFormaPagamento}</strike>
    descricaoPagoEm = <strike>{descricaoPagoEm}</strike>
    descricaoValor = <strike>{descricaoValor}</strike>
    contentDescricaoEstorno = <strike>{contentDescricaoEstorno}</strike>
    contentValorEstorno = <strike>{contentValorEstorno}</strike>
    contentValorAdicional = <strike>{contentValorAdicional}</strike>
  }

  return (
    <Grid
      container
      alignItems="center"
      style={{ cursor: isMenuVisible ? 'pointer' : null }}
      className={classes.containerPagamento}
      onClick={(event) => {
        if (!hasPendingPayment && isMenuVisible) {
          event.stopPropagation()
          props.openMenuPagamento(props.pagamento, event)
        }
      }}
    >
      {!hasPendingPayment && isMenuVisible && (
        <Grid item>
          <IconButton style={{ marginLeft: -20 }}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      )}

      <Grid item xs style={{ padding: '10px 8px 8px 0px' }}>
        <Typography align="left" variant="body2">
          {descricaoFormaPagamento}
        </Typography>
        <Typography align="left" variant="caption" component="p" style={{ marginTop: -2 }}>
          {descricaoPagoEm}
        </Typography>
        {hasPendingPayment && (
          <Typography align="left" variant="caption" component="p" style={{ marginTop: -2, color: '#DE1616' }}>
            Finalize ou cancele na maquininha
          </Typography>
        )}
        {contentValorAdicional}
        {contentDescricaoEstorno}
      </Grid>
      <Grid item style={{ padding: '10px 0px 8px' }}>
        <Typography align="right" variant="body2">
          {descricaoValor}
        </Typography>
        {contentValorEstorno}
      </Grid>
      <PagamentoMenuButton
        nota={props.pagamento}
        anchorPosition={pagamentoAnchorPosition}
        isOpen={pagamentoAnchorPosition?.pagamento?.id === props.pagamento.id}
        onClose={closeMenuPagamento}
        onEdit={() =>
          props.abrirPagamentoDialog({
            pagamento: props.pagamento,
            nota: props.nota
          })
        }
        onRemove={() =>
          props.removerPagamento({
            pagamento: props.pagamento
          })
        }
        onReverse={() => {
          props.estornarPagamento({
            pagamento: props.pagamento
          })
        }}
      />
    </Grid>
  )
}

Pagamento.propTypes = {
  nota: PropTypes.object.isRequired,
  pagamento: PropTypes.object.isRequired
}

NotaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired,
  isMercadoPagoDialogVisible: PropTypes.bool.isRequired
}

export default withStyles(styles)(NotaDialogPage)
