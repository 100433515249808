import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  ANTECEDENCIA_1: { id: 1, descricao: '1 hora antes' },
  ANTECEDENCIA_2: { id: 2, descricao: '2 horas antes' },
  ANTECEDENCIA_3: { id: 3, descricao: '3 hora antes' },
  ANTECEDENCIA_4: { id: 4, descricao: '4 horas antes' },
  ANTECEDENCIA_5: { id: 5, descricao: '5 horas antes' },
  ANTECEDENCIA_6: { id: 6, descricao: '6 horas antes' },
  ANTECEDENCIA_7: { id: 7, descricao: '7 horas antes' },
  ANTECEDENCIA_8: { id: 8, descricao: '8 horas antes' },
  ANTECEDENCIA_12: { id: 12, descricao: '12 horas antes' },
  ANTECEDENCIA_24: { id: 24, descricao: '1 dia antes' },
  ANTECEDENCIA_48: { id: 48, descricao: '2 dias antes' },
  ANTECEDENCIA_72: { id: 72, descricao: '3 dias antes' },
  ANTECEDENCIA_96: { id: 96, descricao: '4 dias antes' },
  ANTECEDENCIA_120: { id: 120, descricao: '5 dias antes' },
  ANTECEDENCIA_144: { id: 144, descricao: '6 dias antes' },
  ANTECEDENCIA_168: { id: 168, descricao: '7 dias antes' },
  ANTECEDENCIA_336: { id: 336, descricao: '14 dias antes' },
  ANTECEDENCIA_720: { id: 720, descricao: '30 dias antes' }
})
export default enumObject
