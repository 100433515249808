import DeviceIdentifier from 'support/components/device/DeviceIdentifier'

function executeNativeMethod(params) {
  if (DeviceIdentifier.isNativeAndroid()) {
    /*const method = Array.prototype.shift.apply(arguments);
		window.JavaScriptInterface[method].apply(this,arguments);*/
    window.JavaScriptInterface.execute(JSON.stringify(params))
  } else if (DeviceIdentifier.isNativeIOS()) {
    window.webkit.messageHandlers.nativeapp.postMessage(params)
  }
}

const functionsObject = {
  executeNativeMethod: executeNativeMethod
}

export default functionsObject
