import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ConfiguracoesComissaoGorjetaDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesComissaoGorjetaDialogPage'
import ConfiguracoesServicosDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesServicosDialogPage'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import InputListIntervaloHorarioSemana from 'support/components/input/InputListIntervaloHorarioSemana'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons
})

class ConfiguracoesHorariosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Horários',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.horariosAtendimentoComponentFunctions = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/colaboradores.buscarHorariosAtendimento',
      params: {
        id: this.props.colaborador.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.atualizarHorarios(response.data)
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  atualizarHorarios = (colaborador) => {
    this.setState({
      ajaxing: false,
      horariosAtendimento: colaborador.horariosAtendimento ? colaborador.horariosAtendimento : []
    })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/colaboradores.salvarHorariosAtendimento',
      data: {
        idColaborador: this.props.colaborador.id,
        horariosAtendimento: this.horariosAtendimentoComponentFunctions.getValues()
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.atualizarHorarios(response.data)

        if (!this.props.origem || this.props.origem !== 'novoColaborador') {
          this.props.handleCloseDialog()
        } else {
          if (this.props.colaborador.realizaAtendimentos) {
            openBackableDialog(ConfiguracoesServicosDialogPage, {
              parent: this,
              shouldCloseParent: true,
              colaborador: this.props.colaborador,
              origem: this.props.origem
            })
          } else if (this.props.colaborador.vendeProdutos) {
            openBackableDialog(ConfiguracoesComissaoGorjetaDialogPage, {
              parent: this,
              shouldCloseParent: true,
              colaborador: this.props.colaborador,
              origem: this.props.origem
            })
          } else {
            this.props.handleCloseDialog()
          }
        }
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={505}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => (
            <form id="formColaboradorHorariosAtendimento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Typography variant="body1" style={{ marginBottom: 20 }}>
                Configure os horários de atendimento semanal do colaborador
              </Typography>

              <InputListIntervaloHorarioSemana
                disabled={this.state.ajaxing}
                errorsMap={this.state.errorsMap}
                componentFunctions={this.horariosAtendimentoComponentFunctions}
                defaultValue={this.state.horariosAtendimento}
                maxIntervalos={5}
                adicionarSeparador={true}
                adicionarUltimoSeparador={false}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesHorariosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesHorariosDialogPage)
