import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ConfiguracoesComissaoGorjetaDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesComissaoGorjetaDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import InterfaceDefinicaoComissoes from 'pages/erp/painel/servico/InterfaceDefinicaoComissoes'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons
})

class ConfiguracoesServicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Serviços',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      lista: []
    }

    this.interfaceDefinicaoComissoesFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar({ fecharDialog: true })
  }

  salvar = (props) => {
    this.interfaceDefinicaoComissoesFunctionsMap.salvar(null, props.onSuccessCallback, null)

    if (this.props.origem && this.props.origem === 'novoColaborador') {
      openBackableDialog(ConfiguracoesComissaoGorjetaDialogPage, {
        parent: this,
        shouldCloseParent: true,
        colaborador: this.props.colaborador,
        origem: this.props.origem
      })
    } else if (props.fecharDialog) {
      this.props.handleCloseDialog()
    }

    EventsManager.pub('ManipulacaoColaborador')
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing } = this.state

    const podePersistirColaborador = verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])

    let texto = <span>Selecione os serviços que este profissional pode realizar.</span>

    if (!podePersistirColaborador) {
      texto = (
        <span>
          {texto}.<br />
          <span style={{ fontSize: 14, color: '#999' }}>Você não possui permissão para alterar estes dados.</span>
        </span>
      )
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={505}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              label: 'Salvar',
              disabled: ajaxing,
              handleAction: () => this.salvar({ fecharDialog: true })
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            {texto}
            <br />
            <br />

            <InterfaceDefinicaoComissoes
              entidade="servico"
              acao="selecionarEntidade"
              scrollElement={dialogContentProps.scrollContainerRef.current}
              colaborador={this.props.colaborador}
              saveFunction={this.salvar}
              onChange={(props) => {
                const newState = {}
                if (props.errorsMap !== undefined) newState.errorsMap = props.errorsMap
                if (props.scrollToIndex !== undefined) newState.scrollToIndex = props.scrollToIndex
                if (props.ajaxing !== undefined) newState.ajaxing = props.ajaxing
                if (props.lista !== undefined) newState.lista = props.lista
                if (props.paginaCarregada !== undefined) newState.paginaCarregada = props.paginaCarregada
                this.setState(newState)
              }}
              errorsMap={this.state.errorsMap}
              scrollToIndex={this.state.scrollToIndex}
              ajaxing={this.state.ajaxing}
              lista={this.state.lista}
              paginaCarregada={this.state.paginaCarregada}
              functionsMap={this.interfaceDefinicaoComissoesFunctionsMap}
            />

            <HiddenSubmitButton />

            {this.state.paginaCarregada && (
              <div className={classes.containerFooterButtons}>
                <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                  Cancelar
                </Button>
                <Button
                  disabled={this.state.ajaxing}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.salvar({ fecharDialog: true })
                  }}
                >
                  {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

ConfiguracoesServicosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesServicosDialogPage)
