import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class TabColaboradores extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ajaxing: false,
      lista: [],
      paginaCarregada: false
    }
    this.loaderFunctionsMap = {}
    this.tabSaveSnackBarFM = {}
    EventsManager.pub('TabSaveSnackBar', { open: false })
  }

  getDados = () => {
    const idsRecursos = []
    for (let recurso of this.state.lista) {
      if (recurso._checked) {
        idsRecursos.push(recurso.id)
      }
    }
    return {
      idServico: this.props.servico.id,
      idsRecursos: idsRecursos
    }
  }

  habilitarOpcaoSalvar = () => {
    EventsManager.pub('TabSaveSnackBar', {
      open: true,
      functionsMap: this.tabSaveSnackBarFM,
      saveFunction: (onStart, onSuccess, onError) => {
        const lista = this.state.lista
        for (let posicao = 0; posicao < lista.length; posicao++) {
          const item = lista[posicao]
          item._update = true
        }

        this.setState({
          ajaxing: true
        })

        if (onStart) onStart()

        postAPI({
          url: 'erp/servicos.alterarServicosRecursos',
          data: this.getDados(),
          requerAutorizacao: true,
          onSuccess: (response) => {
            if (onSuccess) onSuccess('Configurações salvas com sucesso.')
            this.setState({ ajaxing: false })
            EventsManager.pub('AssociacaoServicoRecurso')
            if (this.props.onSaved) {
              this.props.onSaved()
            }
            this.loaderFunctionsMap.load()
          },
          onError: (response) => {
            if (onError) onError()
          }
        })
      },
      cancelFunction: () => {
        this.loaderFunctionsMap.load()
      }
    })
  }

  prepararListaRecursos = (lista) => {
    for (let recurso of lista) {
      recurso._update = true
      recurso._checked = recurso.servicosRecursos && recurso.servicosRecursos.length > 0 ? true : false
    }
    return lista
  }

  changeCheckboxRecurso = (item, event, index) => {
    const lista = this.state.lista
    item._checked = !item._checked
    item._update = true
    this.setState({ lista: lista })
    this.habilitarOpcaoSalvar()
  }

  getRenderContent = (size, item, index) => {
    const content = {}

    content.selected = item._checked
    content.recurso = (
      <Grid key={index} container alignItems="center" style={{ minWidth: 0, backgroundColor: 'inherit' }}>
        <Grid item>
          <Checkbox style={{ padding: 7 }} color="default" checked={item._checked === undefined ? false : item._checked} />
        </Grid>
        <Grid item xs style={{ minWidth: 0, backgroundColor: 'inherit' }}>
          <Typography variant="body2" noWrap={true}>
            {item.nome}
          </Typography>
        </Grid>
      </Grid>
    )

    return content
  }

  salvar = (props) => {
    if (!props) {
      props = {}
    }
    if (this.tabSaveSnackBarFM.isOpen && this.tabSaveSnackBarFM.isOpen() && this.tabSaveSnackBarFM.save) {
      this.tabSaveSnackBarFM.save({
        onSuccessCallback: props.onSuccessCallback
      })
    } else {
      if (props.onSuccessCallback) {
        props.onSuccessCallback()
      }
    }
  }

  render() {
    const { paginaCarregada, ajaxing } = this.state
    const podeAlterarCadastroServico = verificarAutorizacao([PA.PODE_ALTERAR_CAD_SERVICOS])

    let texto = (
      <span>
        Selecione os recursos para este serviço.
        <small style={{ opacity: ajaxing ? 0.3 : 0.6, cursor: ajaxing ? 'auto' : 'pointer', display: 'block', padding: 8, margin: -8 }}>
          O sistema irá verificar a disponibilidade dos recursos selecionado no momento da criação de um novo atendimento para este serviço.
        </small>
      </span>
    )

    if (!podeAlterarCadastroServico) {
      texto = (
        <span>
          {texto}.<br />
          <span style={{ fontSize: 14, color: '#999' }}>Você não possui permissão para configurar os recursos deste serviço.</span>
        </span>
      )
    }

    return (
      <TabContainer padding={false}>
        {paginaCarregada && this.state.lista.length > 0 && <TabContainerPersistHeader addTabContainerPadding={true} text={texto} />}

        <VirtualizedResponsiveTable
          showHeaderColumns={false}
          scrollElement={this.props.scrollElement}
          showBackgroundDividers={true}
          showRowBorders={false}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/recursos.buscarParaAssociacaoServico"
          getRequestParametersFunction={() => ({
            idServico: this.props.servico.id
          })}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ paginaCarregada: true, lista: this.prepararListaRecursos(data.items) })
            }
          }}
          onClickRow={this.changeCheckboxRecurso}
          largeRenderProps={{
            rowHeight: 40,
            columns: [{ label: 'Recurso', props: { xs: true } }],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [content.recurso]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 40,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                itemData: content.recurso
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum recurso foi encontrado. Para cadastrar um novo Recurso, entre no menu de Configurações e depois na opção Recursos',
            padding: true
          }}
        />
      </TabContainer>
    )
  }
}

TabColaboradores.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabColaboradores)
