import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  EMITINDO: { id: 1, descricao: 'Emitindo NF', descricaoResumida: 'Emitindo...', descricaoFiltro: 'Emitindo...' },
  EMITIDA: { id: 2, descricao: 'NF Emitida', descricaoResumida: 'Emitida', descricaoFiltro: 'Emitidas' },
  COM_ERRO: { id: 3, descricao: 'NF com Erro', descricaoResumida: 'Com Erro', descricaoFiltro: 'Com Erro' },
  CANCELANDO: { id: 4, descricao: 'Cancelando NF', descricaoResumida: 'Cancelando...', descricaoFiltro: 'Cancelando...' },
  CANCELADA: { id: 5, descricao: 'NF Cancelada', descricaoResumida: 'Cancelada', descricaoFiltro: 'Canceladas' }
})

export default enumObject
