import React, { Component } from 'react'

import CheckCircleOutlineIcon from '@material-ui/icons/DoneOutline'

class NotaFechada extends Component {
  render() {
    return <CheckCircleOutlineIcon {...this.props} />
  }
}

export default NotaFechada
