import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { openBackableUnmanaged } from 'support/components/backablecomponentsmanager/BackableComponentsManager'

const styles = (theme) => ({})

class TextDatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      left: 0,
      top: 0
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.openPicker = (event) => {
        //this.setState({isOpen:true, left : event.clientX, top: event.clientY});
        this.openPicker(event.clientX, event.clientY)
      }
    }
  }

  openPicker = (clientX, clientY) => {
    openBackableUnmanaged({
      onCreateInstance: (instance) => {
        this.backableComponentInstance = instance
        this.setState({ isOpen: true, left: clientX, top: clientY })
      },
      onClosed: () => {
        this.setState({ isOpen: false })
      }
    })
  }

  render() {
    const { functionsMap, ...others } = this.props

    others.onClose = () => {
      this.backableComponentInstance.props.handleClose()
    }

    let renderComponent = undefined
    if (this.props.renderComponent) {
      if (typeof this.props.renderComponent == 'function') {
        renderComponent = (props) => {
          const newProps = Object.assign({ openPicker: this.openPicker }, props)
          return this.props.renderComponent(newProps)
        }
      } else {
        renderComponent = this.props.renderComponent
      }
    }

    return (
      <DatePicker
        PopoverProps={{
          anchorReference: 'anchorPosition',
          transformOrigin: {
            horizontal: 'center',
            vertical: 'top'
          },
          anchorPosition: {
            left: this.state.left,
            top: this.state.top
          }
        }}
        variant="dialog"
        open={this.state.isOpen}
        value={this.props.value}
        dateObject={this.props.value}
        onChange={this.props.onChange}
        animateYearScrolling={false}
        TextFieldComponent={renderComponent}
        autoOk={true}
        clearable={false}
        cancelLabel={''}
        okLabel={''}
        showTodayButton={false}
        todayLabel={''}
        {...others}
      />
    )
  }
}

TextDatePicker.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TextDatePicker)
