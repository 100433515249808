import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

import EventsManager from 'support/components/eventsmanager/EventsManager'

class AgendaItensScrollbars extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.elementRef = React.createRef()
    this.state = {
      shadowTopOpacity: 0
      //shadowBottomOpacity: 0
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap['scrollTop'] = this.scrollTop
      this.props.functionsMap['getScrollTop'] = () => {
        return this.elementRef.current.getScrollTop()
      }
      this.props.functionsMap['scrollLeft'] = this.scrollLeft
      this.props.functionsMap['getScrollLeft'] = () => {
        return this.elementRef.current.getScrollLeft()
      }
      this.props.functionsMap['getScrollRef'] = () => {
        return this.elementRef.current
      }
    }

    this.eventsManager.sub('CustomScrollbars', (props) => {
      if (props.action === 'forceUpdate') {
        window.setTimeout(
          () => {
            this.forceUpdate()
          },
          props.delay ? props.delay : 0
        )
      }
    })
  }

  handleUpdate = (values) => {
    const { scrollTop } = values
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20)
    //const bottomScrollTop = scrollHeight - clientHeight;
    //const shadowBottomOpacity = 1 / 20 * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    this.setState({
      shadowTopOpacity: shadowTopOpacity
      //shadowBottomOpacity: shadowBottomOpacity
    })
  }

  scrollLeft = (left) => {
    this.elementRef.current.scrollLeft(left)
  }

  scrollTop = (top) => {
    this.elementRef.current.scrollTop(top)
  }

  render() {
    return (
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <Scrollbars
          style={{ overflow: 'hidden' }}
          onUpdate={this.handleUpdate}
          ref={this.elementRef}
          onScroll={this.props.onScroll ? (event) => this.props.onScroll(event, this.elementRef.current) : undefined}
          onScrollStart={this.props.onScrollStart}
          onScrollStop={this.props.onScrollStop}
          hideTracksWhenNotNeeded={true}
          renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, left: 3, width: 8, backgroundColor: 'rgba(0,0,0,0.15)', borderRadius: 3 }} />}
          renderTrackVertical={({ style, ...props }) => {
            return <div {...props} style={{ ...style, right: 0, bottom: 3, width: 13, height: '100%', zIndex: 20 }} />
          }}
          renderThumbHorizontal={({ style, ...props }) => <div {...props} style={{ ...style, top: 3, height: 8, backgroundColor: 'rgba(0,0,0,0.15)', borderRadius: 3 }} />}
          renderTrackHorizontal={({ style, ...props }) => {
            return <div {...props} style={{ ...style, bottom: 0, right: 3, height: 14, width: '100%', zIndex: 20 }} />
          }}
        >
          {this.props.children}
        </Scrollbars>
        <div
          style={{
            zIndex: 25,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: this.state.shadowTopOpacity === 1 ? 4 : 0,
            borderTop: '1px solid #e0e0e0',
            background:
              this.state.shadowTopOpacity === 1
                ? 'linear-gradient(rgba(0, 0, 0, 0.09) 0%, rgba(0, 0, 0, 0) 100%)'
                : 'linear-gradient(rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 100%)'
          }}
        />
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

export default AgendaItensScrollbars
