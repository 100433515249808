import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import DespesasDetalhesDialogPage from 'pages/erp/painel/relatorio/DespesasDetalhesDialogPage'
import DetalhesCreditosClientesDialogPage from 'pages/erp/painel/relatorio/DetalhesCreditosClientesDialogPage'
import DetalhesEstornosDialogPage from 'pages/erp/painel/relatorio/DetalhesEstornosDialogPage'
import DetalhesPagamentosDialogPage from 'pages/erp/painel/relatorio/DetalhesPagamentosDialogPage'
import NotasEmAbertoDetalhesDialogPage from 'pages/erp/painel/relatorio/NotasEmAbertoDetalhesDialogPage'
import RelatorioDescontosDetalhadosDialogPage from 'pages/erp/painel/relatorio/RelatorioDescontosDetalhadosDialogPage'
import VendasSemNotaDetalhesDialogPage from 'pages/erp/painel/relatorio/VendasSemNotaDetalhesDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ChartLine from 'support/components/chart/ChartLine'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerInformacoes: {
    marginTop: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '4px'
    }
  },
  paperInformacoes: {
    /*border: "1px solid #eee",
    	borderRadius: 14,
		height: "calc(100% - 64px)",
		padding: "18px 30px 34px",
		[theme.breakpoints.down("xs")]: {
			height: "calc(100% - 48px)",
			padding: theme.spacing(2)+"px "+theme.spacing(3)+"px "+theme.spacing(3)+"px ",
		}*/
    ...theme.container.card({ fullHeight: true })
  },
  tituloInformacoesFinanceiras: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  tituloCard: {
    fontSize: 20,
    paddingTop: 8,
    paddingBottom: 8
  }
})

class RelatorioFinanceiroDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      paginaCarregada: false,
      ajaxingDadosFinanceiros: false,
      dadosFinanceirosDataInicial: this.colaboradorLocalPreferences.getMomentPreference(
        'RelatorioFinanceiroDialogPageFiltro_DadosFinanceirosDataInicial',
        moment().startOf('month')
      ),
      dadosFinanceirosDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioFinanceiroDialogPageFiltro_DadosFinanceirosDataFinal', moment().endOf('month'))
    }
    this.dadosReceitasUltimosMesesLoaderFunctionsMap = {}
    this.dadosFinanceirosLoaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNotaVendaPagamento', 'ManipulacaoVendas', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.dadosFinanceirosLoaderFunctionsMap.reload()
    })
  }

  carregarDadosReceitaUltimosMeses = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingDadosReceitaUltimosMeses: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosFinanceiros',
      params: {
        dadosReceitasUltimosMeses: true
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingDadosReceitaUltimosMeses: false,
          dadosReceitasUltimosMeses: this.prepararDadosReceitaUltimosMesesParaGrafico(response.data.dadosReceitasUltimosMeses)
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingDadosReceitaUltimosMeses: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosReceitaUltimosMesesParaGrafico = (dadosReceitasUltimosMeses) => {
    const dadosReceitasUltimosMesesPorMes = {}
    if (dadosReceitasUltimosMeses && dadosReceitasUltimosMeses.length) {
      for (let dadosReceitaUltimosMeses of dadosReceitasUltimosMeses) {
        dadosReceitasUltimosMesesPorMes[dadosReceitaUltimosMeses.mes.toString()] = dadosReceitaUltimosMeses
      }
    }

    const agora = moment()
    const dataNavegacao = agora.clone().startOf('month').subtract(11, 'month')
    const criarItem = (mes, ValorPagamentos, valorLucroLiquido, valorTotalDespesasPagas) => {
      const data = moment(mes.toString(), 'YYYYMM')
      return {
        mes: data.format('MMM/YY'),
        rb: ValorPagamentos === null || ValorPagamentos === undefined ? 0 : ValorPagamentos,
        ll: valorLucroLiquido === null || valorLucroLiquido === undefined ? 0 : valorLucroLiquido,
        de: valorTotalDespesasPagas === null || valorTotalDespesasPagas === undefined ? 0 : valorTotalDespesasPagas
      }
    }

    const data = []
    while (true) {
      const mesAtual = dataNavegacao.format('YYYYMM')
      const dadosReceitaUltimosMeses = dadosReceitasUltimosMesesPorMes[mesAtual]
      if (dadosReceitaUltimosMeses) {
        data.push(criarItem(mesAtual, dadosReceitaUltimosMeses.valorPagamentos, dadosReceitaUltimosMeses.valorLucroLiquido, dadosReceitaUltimosMeses.valorTotalDespesasPagas))
      } else {
        data.push(criarItem(mesAtual, 0, 0, 0))
      }
      if (dataNavegacao.isSame(agora, 'month')) {
        break
      } else {
        dataNavegacao.add(1, 'month')
      }
    }
    return {
      xAxis: {
        dataKey: 'mes',
        height: 80
      },
      tooltip: {
        formatter: (value, name, props) => {
          return [
            <span style={{ fontSize: 12 }}>
              {name}: {formatarValorMonetario(value)}
            </span>,
            null
          ]
        }
      },
      items: [
        {
          name: 'Receita Bruta',
          color: this.props.theme.palette.chart.positiveList[0],
          shortName: 'Receita',
          dataKey: 'rb'
        },
        {
          name: 'Lucro Líquido',
          color: this.props.theme.palette.chart.positiveList[1],
          shortName: 'Líquido',
          dataKey: 'll'
        },
        {
          name: 'Despesas',
          color: this.props.theme.palette.chart.negativeList[0],
          shortName: 'Despesas',
          dataKey: 'de',
          hasBackground: false
        }
      ],
      data: data
    }
  }

  carregarDadosFinanceiros = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingDadosFinanceiros: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosFinanceiros',
      params: {
        receitaUltimosMeses: false,
        dadosFinanceiros: true,
        dadosFinanceirosDataInicial: converterMomentParaDataInt(this.state.dadosFinanceirosDataInicial),
        dadosFinanceirosDataFinal: converterMomentParaDataInt(this.state.dadosFinanceirosDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        const dadosFinanceiros = response.data.dadosFinanceiros
        if (dadosFinanceiros.pagamentos && dadosFinanceiros.pagamentos.length) {
          dadosFinanceiros.pagamentos = dadosFinanceiros.pagamentos.filter((pagamento) => pagamento.valor > 0)
        }
        if (dadosFinanceiros.despesasPagas && dadosFinanceiros.despesasPagas.length) {
          dadosFinanceiros.despesasPagas = dadosFinanceiros.despesasPagas.filter((despesa) => despesa.valor > 0)
        }

        this.setState({
          ajaxingDadosFinanceiros: false,
          dadosFinanceiros: dadosFinanceiros
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingDadosFinanceiros: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  abrirDetalhesPagamentos = () => {
    openBackableDialog(DetalhesPagamentosDialogPage, {
      dataInicial: this.state.dadosFinanceirosDataInicial,
      dataFinal: this.state.dadosFinanceirosDataFinal,
      tituloDialog: 'Receita Bruta'
    })
  }

  abrirDetalhesEstornos = () => {
    openBackableDialog(DetalhesEstornosDialogPage, {
      dataInicial: this.state.dadosFinanceirosDataInicial,
      dataFinal: this.state.dadosFinanceirosDataFinal
    })
  }

  abrirDetalhesDescontos = () => {
    openBackableDialog(RelatorioDescontosDetalhadosDialogPage, {
      dataInicial: this.state.dadosFinanceirosDataInicial,
      dataFinal: this.state.dadosFinanceirosDataFinal,
      apenasVendasFaturadas: true,
      titulo: 'Vendas com desconto'
    })
  }

  abrirDetalhesCreditosClientes = () => {
    openBackableDialog(DetalhesCreditosClientesDialogPage, {
      dataInicial: this.state.dadosFinanceirosDataInicial,
      dataFinal: this.state.dadosFinanceirosDataFinal
    })
  }

  abrirDetalhesDespesas = (despesasPagas) => {
    openBackableDialog(DespesasDetalhesDialogPage, {
      despesasPagas: despesasPagas,
      dataInicial: this.state.dadosFinanceirosDataInicial,
      dataFinal: this.state.dadosFinanceirosDataFinal
    })
  }

  abrirDetalhesValorNotasEmAberto = () => {
    openBackableDialog(NotasEmAbertoDetalhesDialogPage, {
      dataInicial: this.state.dadosFinanceirosDataInicial,
      dataFinal: this.state.dadosFinanceirosDataFinal
    })
  }

  abrirDetalhesValorVendasSemNotas = () => {
    openBackableDialog(VendasSemNotaDetalhesDialogPage, {
      dataInicial: this.state.dadosFinanceirosDataInicial,
      dataFinal: this.state.dadosFinanceirosDataFinal
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxingDadosReceitaUltimosMeses, ajaxingDadosFinanceiros, dadosReceitasUltimosMeses, dadosFinanceirosDataInicial, dadosFinanceirosDataFinal, dadosFinanceiros } =
      this.state

    let toolbarActions = null
    if (!ajaxingDadosFinanceiros && !ajaxingDadosReceitaUltimosMeses) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.dadosReceitasUltimosMesesLoaderFunctionsMap.reload()
              this.dadosFinanceirosLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let dadosFinanceirosLoadKey =
      converterMomentParaDataInt(this.state.dadosFinanceirosDataInicial).toString() + '-' + converterMomentParaDataInt(this.state.dadosFinanceirosDataFinal).toString()

    return (
      <DialogPage
        {...dialogProps}
        title="Financeiro"
        align="center"
        contentMaxWidth={1200}
        pageType="basicEntity"
        ajaxing={ajaxingDadosFinanceiros || ajaxingDadosReceitaUltimosMeses}
        toolbarActions={toolbarActions}
      >
        <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
          <Typography variant="h5" align="center" className={classes.tituloInformacoesFinanceiras}>
            Gráfico de Receitas
          </Typography>
        </FloatContent>

        <ContentWithPreload functionsMap={this.dadosReceitasUltimosMesesLoaderFunctionsMap} loadFunction={this.carregarDadosReceitaUltimosMeses}>
          {() => (
            <div style={{ width: '100%', paddingBottom: 8, paddingTop: 0 }}>
              <ChartLine data={dadosReceitasUltimosMeses} />
            </div>
          )}
        </ContentWithPreload>

        <div style={{ padding: '34px 24px 16px 24px', textAlign: 'center' }}>
          <Typography variant="h5" align="center" className={classes.tituloInformacoesFinanceiras}>
            Informações Financeiras
          </Typography>
          <div style={{ display: 'inline-block' }}>
            <DateRangeNavigator
              expanded={false}
              defaultValue={{
                tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioFinanceiroDialogPageFiltro_DadosFinanceirosTipoIntervalo', 'mes'),
                dataInicial: dadosFinanceirosDataInicial,
                dataFinal: dadosFinanceirosDataFinal
              }}
              onChange={(data) => {
                this.colaboradorLocalPreferences.setPreference('RelatorioFinanceiroDialogPageFiltro_DadosFinanceirosTipoIntervalo', data.tipoIntervalo.nome)
                this.colaboradorLocalPreferences.setMomentPreference('RelatorioFinanceiroDialogPageFiltro_DadosFinanceirosDataInicial', data.dataInicial)
                this.colaboradorLocalPreferences.setMomentPreference('RelatorioFinanceiroDialogPageFiltro_DadosFinanceirosDataFinal', data.dataFinal)
                this.setState({
                  dadosFinanceirosDataInicial: data.dataInicial,
                  dadosFinanceirosDataFinal: data.dataFinal
                })
              }}
            />
          </div>
        </div>

        <ContentWithPreload functionsMap={this.dadosFinanceirosLoaderFunctionsMap} loadFunction={this.carregarDadosFinanceiros} loadKey={dadosFinanceirosLoadKey}>
          {() => (
            <div className={classes.containerInformacoes}>
              <Grid container spacing={isWidthDown('xs', this.props.width) ? 1 : 3}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Receita Bruta
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesPagamentos()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores de todos os pagamentos recebidos de clientes.
                    </Typography>
                    {dadosFinanceiros.pagamentos &&
                      dadosFinanceiros.pagamentos.length > 0 &&
                      dadosFinanceiros.pagamentos.map((pagamento, index) => (
                        <React.Fragment key={index}>
                          <Grid container alignItems="center" style={{ marginTop: index === 0 ? 20 : 0 }}>
                            <Grid item xs style={{ fontSize: 12, textTransform: 'uppercase' }}>
                              <b>{pagamento.descricao}</b>
                            </Grid>
                            <Grid item style={{ fontSize: 12, textTransform: 'uppercase' }}>
                              {formatarValorMonetario(pagamento.valor ? pagamento.valor : 0)}
                            </Grid>
                          </Grid>
                          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        </React.Fragment>
                      ))}
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorTotalPagamentos ? dadosFinanceiros.valorTotalPagamentos : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Despesas Contabilizadas
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              color="secondary"
                              style={{ marginRight: -8 }}
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesDespesas(true)
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores de todas as despesas registradas pagas, taxas de pagamentos, vales, pagamento de comissões e compra de produtos.
                    </Typography>
                    {dadosFinanceiros.despesasPagas &&
                      dadosFinanceiros.despesasPagas.length > 0 &&
                      dadosFinanceiros.despesasPagas.map((despesa, index) => (
                        <React.Fragment key={index}>
                          <Grid container alignItems="center" style={{ marginTop: index === 0 ? 20 : 0 }}>
                            <Grid item xs style={{ fontSize: 12, textTransform: 'uppercase' }}>
                              <b>{despesa.descricao}</b>
                            </Grid>
                            <Grid item style={{ fontSize: 12, textTransform: 'uppercase' }}>
                              {formatarValorMonetario(despesa.valor ? despesa.valor : 0)}
                            </Grid>
                          </Grid>
                          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        </React.Fragment>
                      ))}
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorTotalDespesasPagas ? dadosFinanceiros.valorTotalDespesasPagas : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Typography variant="h5" color="secondary" className={classes.tituloCard} style={{ marginTop: -10 }}>
                      Lucro Líquido
                    </Typography>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores de todos os pagamentos recebidos menos todas as despesas.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorLucroLiquido ? dadosFinanceiros.valorLucroLiquido : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Comandas em Aberto
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesValorNotasEmAberto()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores não pagos em comandas que estão abertas.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorEmAbertoVendasEmNotas ? dadosFinanceiros.valorEmAbertoVendasEmNotas : 0)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Faturamento Pendente
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesValorVendasSemNotas()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores de todas as vendas/atendimentos que estão pendentes para faturamento.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorEmAbertoVendasSemNotas ? dadosFinanceiros.valorEmAbertoVendasSemNotas : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Despesas Não Pagas
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              color="secondary"
                              style={{ marginRight: -8 }}
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesDespesas(false)
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores de todas as despesas que ainda não foram pagas.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorTotalDespesasNaoPagas ? dadosFinanceiros.valorTotalDespesasNaoPagas : 0)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Estornos
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesEstornos()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores de todos os estornos de pagamentos realizados.
                    </Typography>
                    {dadosFinanceiros.estornos &&
                      dadosFinanceiros.estornos.length > 0 &&
                      dadosFinanceiros.estornos.map((estorno, index) => (
                        <React.Fragment key={index}>
                          <Grid container alignItems="center" style={{ marginTop: index === 0 ? 20 : 0 }}>
                            <Grid item xs style={{ fontSize: 12, textTransform: 'uppercase' }}>
                              <b>{estorno.descricao}</b>
                            </Grid>
                            <Grid item style={{ fontSize: 12, textTransform: 'uppercase' }}>
                              {formatarValorMonetario(estorno.valor ? estorno.valor : 0)}
                            </Grid>
                          </Grid>
                          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        </React.Fragment>
                      ))}
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorTotalEstornos ? dadosFinanceiros.valorTotalEstornos : 0)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Descontos
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesDescontos()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Soma dos valores de todos os descontos concedidos em atendimentos e vendas para clientes.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorTotalDescontos ? dadosFinanceiros.valorTotalDescontos : 0)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Typography variant="h5" color="secondary" className={classes.tituloCard} style={{ marginTop: -10 }}>
                      Valor Médio
                    </Typography>
                    <Grid container alignItems="center" style={{ marginTop: 20 }}>
                      <Grid item xs style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        <b>POR FATURA</b>
                      </Grid>
                      <Grid item style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosFinanceiros.valorTicketMedioNotas ? dadosFinanceiros.valorTicketMedioNotas : 0)}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        <b>POR SERVIÇO</b>
                      </Grid>
                      <Grid item style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosFinanceiros.valorTicketMedioServicos ? dadosFinanceiros.valorTicketMedioServicos : 0)}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        <b>POR PRODUTO</b>
                      </Grid>
                      <Grid item style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosFinanceiros.valorTicketMedioProdutos ? dadosFinanceiros.valorTicketMedioProdutos : 0)}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        <b>POR PACOTE</b>
                      </Grid>
                      <Grid item style={{ fontSize: 12, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosFinanceiros.valorTicketMedioPacotes ? dadosFinanceiros.valorTicketMedioPacotes : 0)}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard} style={{ marginTop: -10 }}>
                          Créditos Disponíveis
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesCreditosClientes()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Valor total de créditos disponíveis na conta dos clientes.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosFinanceiros.valorCreditosDisponiveis ? dadosFinanceiros.valorCreditosDisponiveis : 0)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

RelatorioFinanceiroDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(RelatorioFinanceiroDialogPage))
