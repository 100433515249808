import { useEffect } from 'react'

import { ROUTE_CONTA_MAIN } from 'pages/RouteMap'
import { postAPI } from 'support/components/api/ApiClient'
import { ErrorResponseType, SuccessResponseType } from 'support/components/api/types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import MainLoaderScreen from 'support/components/mainloaderscreen/MainLoaderScreen'
import MainLoaderScreenFunctionsMap from 'support/components/mainloaderscreen/MainLoaderScreenFunctionsMap'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import { redirectUserAfterSuccessfulLogin } from 'support/domain/usuario/routerUserAfterSuccessfulLogin'
import { logAGE } from 'support/util/Logger'
import UrlUtil from 'support/util/UrlUtil'

import logo from '../../../img/logo_salao99_painel.png'

const GoogleSSOPage = () => {
  const getUserCodeFromCodeSSO = (codeSSO: string) => {
    MainLoaderScreenFunctionsMap.notifyStart()
    postAPI({
      url: `cda/usuarios.login?code=${codeSSO}`,
      onSuccess: ({ data }: SuccessResponseType) => {
        SessaoUsuario.registrarUsuario(data)
        MainLoaderScreenFunctionsMap.notifyLoaded()
        redirectUserAfterSuccessfulLogin()
      },
      onError: (response: ErrorResponseType) => {
        logAGE(response, { component: 'GoogleSSO', context: 'getUserCodeFromCodeSSO' })
        MainLoaderScreenFunctionsMap.notifyError({ message: response.userMessage || '' })
      }
    })
  }

  const initialize = () => {
    const { getUrlQueryStringParameter, getFullUrlFromRoute } = UrlUtil
    SessaoUsuario.registrarUrlContinuacaoAposLogin('state')
    const googleCodeSSO = encodeURIComponent(getUrlQueryStringParameter('code'))

    if (googleCodeSSO) {
      getUserCodeFromCodeSSO(googleCodeSSO)
    } else {
      const redirectDelayTime = 5000
      logAGE('Erro ao recuperar o code do google SSO', { component: 'GoogleSSO' })
      EventsManager.pub('MensagemSnackBar', 'Erro inesperado (cod:1). Redirecionando para a página de Login.')
      setInterval(() => {
        window.location.href = getFullUrlFromRoute(ROUTE_CONTA_MAIN + '/login')
      }, redirectDelayTime)
    }
  }

  useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MainLoaderScreen image={<img src={logo} alt="Salão99" />} functionsMap={MainLoaderScreenFunctionsMap} showLogo={true} loadFunction={initialize} />
    </>
  )
}

export default GoogleSSOPage
