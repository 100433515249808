import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DateRangeIcon from '@material-ui/icons/DateRange'
import moment from 'moment'
import ClienteDialogPage from 'pages/erp/painel/cliente/ClienteDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 120,
    minWidth: 120
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  }
})

class RelatorioAusenciaClientesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.loaderFunctionsMap = {}
    this.dataExporterFunctionsMap = {}
    this.state = {
      ajaxingAusencias: false,
      dataPesquisaAte: this.colaboradorLocalPreferences.getMomentPreference('RelatorioAusenciaClientesDialogPageFiltro_DataPesquisaAte', moment().subtract(2, 'week')),
      lista: []
    }
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.nomeCliente = item.cliente.apelido
    content.idCliente = item.cliente.id
    content.diasAusentes = moment().diff(converterDataIntParaMoment(item.dataUltimaVenda), 'days')

    if (item.valorTotalPagamentos) {
      content.valorTotalPagamentos = formatarValorMonetario(item.valorTotalPagamentos, { prefix: false })
    } else {
      content.valorTotalPagamentos = formatarValorMonetario(0, { prefix: false })
    }

    if (size === 'small') {
      content.dataUltimaVenda = converterDataIntParaMoment(item.dataUltimaVenda).format('DD/MMM/YY')
    } else {
      content.dataUltimaVenda = converterDataIntParaMoment(item.dataUltimaVenda).format('DD/MMM/YYYY')
    }

    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Clientes Ausentes - ' + formatarNomeArquivo(this.state.dataPesquisaAte),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarClientesAusentes',
        params: this.getRequestParameters()
      }
    })
  }

  getRequestParameters = () => {
    return {
      dataLimite: converterMomentParaDataInt(this.state.dataPesquisaAte)
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCliente', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxingAusencias, dataPesquisaAte } = this.state

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.lista) {
      valorTotal += item.valor
      quantidadeTotal += item.quantidade
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} title="Clientes Sumidos" align="center" contentMaxWidth={900} pageType="basicEntity" ajaxing={ajaxingAusencias} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Clientes Sumidos
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <DateRangeIcon style={{ marginLeft: 4 }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" style={{ marginLeft: 12, marginTop: 2, color: 'rgba(0,0,0,0.7)' }}>
                        Desde
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginRight: -8 }}>
                      <InputData
                        fullWidth={false}
                        style={{ marginLeft: 10, maxWidth: 118 }}
                        idname="xdataAte"
                        defaultValue={dataPesquisaAte.toDate()}
                        onChange={(data) => {
                          const dataAsMoment = moment(data)
                          this.colaboradorLocalPreferences.setMomentPreference('RelatorioAusenciaClientesDialogPageFiltro_DataPesquisaAte', dataAsMoment)
                          this.setState({ dataPesquisaAte: dataAsMoment })
                        }}
                        placeholder={'Data'}
                        customVariant="naked"
                        nakedLeftPadding={false}
                        showPickerIndicator={true}
                        clearable={false}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </FloatContent>

            <VirtualizedResponsiveTable
              key={converterMomentParaDataInt(this.state.dataPesquisaAte).toString()}
              showBackgroundDividers={true}
              itemsPerRequest={20}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarClientesAusentesPorDataLimite"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Cliente', className: classes.colunaCliente, props: { xs: true } },
                  { label: 'Última Venda', className: classes.colunaData, horizontalPadding: 'small', align: 'right' },
                  { label: 'Dias Ausentes', className: classes.colunaData, horizontalPadding: 'small', align: 'right' },
                  { label: 'Total Pago (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)

                  return {
                    itemData: [content.nomeCliente, content.dataUltimaVenda, content.diasAusentes, content.valorTotalPagamentos]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 120,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body2" noWrap={true}>
                          <b>{content.nomeCliente}</b>
                        </Typography>
                        <Typography variant="body2">Última Venda: {content.dataUltimaVenda}</Typography>
                        <Typography variant="body2">Dias Ausentes: {content.diasAusentes}</Typography>
                        <Typography variant="body2">Total Pago (R$): {content.valorTotalPagamentos}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              onClickRow={(item) => {
                openBackableDialog(ClienteDialogPage, {
                  idCliente: item.cliente.id
                })
              }}
              emptyListProps={{
                text: 'Não existem dados para o período informado'
              }}
            />

            {quantidadeTotal > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Total: <b>{formatarValorMonetario(valorTotal)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

RelatorioAusenciaClientesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioAusenciaClientesDialogPage)
