import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import { darken, emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import HistoryIcon from '@material-ui/icons/History'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PlaceIcon from '@material-ui/icons/PlaceOutlined'
import classNames from 'classnames'
import mapMarkerImg from 'img/map-marker.png'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import CustomDialog from 'support/components/dialog/CustomDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import NativeApp from 'support/components/nativeapp/NativeApp'
import EstadoEnum from 'support/domain/localizacao/EstadoEnum'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'
import LocalizacaoFormatter from 'support/util/LocalizacaoFormatter'
import { CEPMask } from 'support/util/Masks'
import { cloneObjectWithoutReference } from 'support/util/ObjectUtil'
import { searchPart } from 'support/util/StringUtil'

import UncontrolledCheckbox from '../checkbox/UncontrolledCheckbox'

const DEFAULT_ROW_HEIGHT = 62
const COMPLEMENTO_SIZE = 12

const styles = (theme) => ({
  header: {
    //...theme.container.basic(),
    paddingTop: 16,
    paddingBottom: 16
  },
  title: {
    lineHeight: '26px'
  },
  inputMinhaLocalizacao: {
    '&, & *': {
      cursor: 'pointer'
    }
  },
  inputVoltar: {
    '&, & *': {
      cursor: 'pointer'
    }
  },
  container: {
    alignItems: 'flex-start'
  },
  root: {
    minWidth: 300,
    maxWidth: 620,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  dialogContent: {
    maxHeight: 1200,
    ...theme.container.basic(),
    paddingTop: theme.spacing(0),
    paddingBottom: 32
  },
  rootListItem: {
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    },
    [theme.breakpoints.down(390)]: {
      paddingLeft: 18,
      paddingRight: 18
    }
  },
  contentListItem: {
    marginRight: 8
  },
  primaryListItem: {
    ...theme.text.limitedLines({ maxLines: 2, withEllipsis: true }),
    fontSize: 14
  },
  secondaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: emphasize(darken(theme.palette.secondary.light, 0.15), 0.3)
  },
  iconListItem: {
    marginTop: 4,
    marginRight: -4
  },
  containerFooterButtons: {
    textAlign: 'right',
    marginTop: 32,
    '& button': {
      textTransform: 'none'
    },
    '& > button:not(:last-child)': {
      marginRight: 12
    }
  },
  containerMap: {
    ...theme.container.basicNegativeMargin()
  },
  markerLabel: {
    backgroundColor: 'white',
    textAlign: 'center',
    padding: '16px 14px',
    ...theme.util.createBoxShadow('0px 0px 8px 2px rgba(0,0,0,0.1)'),
    borderRadius: 12,
    marginTop: -93,
    '&:after': {
      content: '"Ajuste a localização"',
      marginTop: 3,
      display: 'block',
      fontSize: 12,
      opacity: 0.5
    }
  },
  inputEndereco: {
    width: '60%'
  },
  checkBoxSemNumero: {
    marginRight: 0,
    marginLeft: 0
  },
  complementoLabel: {
    fontSize: 'small',
    color: theme.palette.text.secondary,
    marginTop: 1
  }
})

class PlacePickerDialog extends Component {
  constructor(props) {
    super(props)

    this.mounted = true
    this.eventsManager = EventsManager.new()

    let step = 0

    if (!this.props.showGetCurrentLocationButton) {
      step = 1
    }

    this.state = {
      places: [],
      historyPlaces: [],
      obtendoLocalizacaoAtual: false,
      initialStep: step,
      step: step,
      checkEnderecoSemNumero: false,
      errorsMap: createErrorsMap()
    }

    this.scrollContainerRef = React.createRef()
    this.inputBuscaEndereco = React.createRef()
    this.inputEnderecoNumero = React.createRef()
    this.inputEnderecoComplemento = React.createRef()
    this.inputEnderecoBairro = React.createRef()
    this.inputEnderecoCep = React.createRef()

    this.mapFunctionsMap = {}
  }

  componentDidMount() {
    if (this.props.loadUserHistoryPlaces) {
      this.loadUserHistoryPlaces()
    }
    this.eventsManager.sub('setCurrentLocation', (props) => {
      this.getDetailsFromCoordinates(props.lat, props.lng, (placeWithDetails) => {
        this.select(placeWithDetails)
      })
    })

    this.eventsManager.sub('currentLocationUnavailable', (props) => {
      if (props && props.showDialog === true) {
        openBackableDialog(MessageDialog, {
          text: 'Não foi possível obter a sua localização atual. Verifique se você concedeu a permissão para acessar a sua localização e se o GPS está ativo.'
        })
      }
      this.setState({ obtendoLocalizacaoAtual: false })
    })
    if (DeviceIdentifier.isNativeAndroid()) {
      NativeApp.executeNativeMethod({
        method: 'initServiceLocation'
      })
    }

    if (this.state.initialStep === 1) {
      this.setFocusInputBuscaEndereco()
    }
  }

  setFocusInputBuscaEndereco = () => {
    window.setTimeout(() => {
      if (this.inputBuscaEndereco && this.inputBuscaEndereco.current) {
        this.inputBuscaEndereco.current.focus()
      }
    }, 100)
  }

  loadUserHistoryPlaces = () => {
    getAPI({
      url: 'ptl/usuarios.buscarEnderecosHistorico',
      requerAutorizacao: true,
      onSuccess: (response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            historyPlaces: response.data.map((enderecoHistorico) => ({
              description: enderecoHistorico.descricao,
              coordinates: { lat: enderecoHistorico.latitude, lng: enderecoHistorico.longitude }
            }))
          })
        } else {
          this.setState({ historyPlaces: [] })
        }
      },
      sendErroToGenericSnackbar: false,
      repeat: {
        times: 7,
        stopFunction: () => {
          return !this.mounted
        }
      }
    })
  }

  renderRowPlaces = (place, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={place.description}
          iconComponent={PlaceIcon}
          onClick={() => {
            if (this.props.onlyPreciseAddress) {
              this.getDetailsFromPlace(place, (placeWithDetails) => {
                this.setState({
                  step: 2,
                  placeToComplete: placeWithDetails
                })
              })
            } else {
              if (place.place_id) {
                this.getDetailsFromPlace(place, (placeWithDetails) => {
                  this.select(placeWithDetails)
                })
              } else if (place.coordinates) {
                this.select(place)
              }
            }
          }}
        />
      </div>
    )
  }

  renderRowHistoryPlaces = (place, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={place.description}
          iconComponent={HistoryIcon}
          onClick={() => {
            if (place.place_id) {
              this.getDetailsFromPlace(place, (placeWithDetails) => {
                this.select(placeWithDetails)
              })
            } else if (place.coordinates) {
              this.select(place)
            }
          }}
        />
      </div>
    )
  }

  getDetailsFromAddress = (address, callback) => {
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ address: address }, (results, status) => {
      let callbackCalled = false
      if (status === 'OK') {
        if (results[0]) {
          callbackCalled = true
          callback({
            description: results[0].formatted_address,
            rawData: results[0],
            coordinates: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            }
          })
        }
      }
      if (!callbackCalled) {
        callback(null)
      }
    })
  }

  getDetailsFromPlace = (place, callback) => {
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ placeId: place.place_id }, (results, status) => {
      let callbackCalled = false
      if (status === 'OK') {
        if (results[0]) {
          callbackCalled = true
          callback({
            description: place.description,
            rawData: results[0],
            coordinates: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            }
          })
        }
      }
      if (!callbackCalled) {
        window.setTimeout(() => {
          this.getDetailsFromPlace(place, callback)
        }, 100)
      }
    })
  }

  getDetailsFromCoordinates = (lat, lng, callback) => {
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ latLng: new window.google.maps.LatLng(lat, lng) }, (results, status) => {
      let callbackCalled = false
      if (status === 'OK') {
        if (results[0]) {
          callbackCalled = true

          let description = results[0].formatted_address.replace(/[^,]*,\s[0-9]{1,}\s-\s/, '')
          description = LocalizacaoFormatter.formatarEnderecoSimples(description)

          callback({
            description: description,
            coordinates: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            }
          })
        }
      }
      if (!callbackCalled) {
        window.setTimeout(() => {
          this.getDetailsFromCoordinates(lat, lng, callback)
        }, 100)
      }
    })
  }

  convertPlaceToAddressObject = (place, valuesFromInput) => {
    const address = {
      formattedAddress: place.formatted_address,
      address: this.getAddressComponentByType(place.address_components, 'route', 'long_name')
    }

    address.number = valuesFromInput.number
    address.district = valuesFromInput.district
    address.postalCode = valuesFromInput.postalCode
    address.complement = valuesFromInput.complement
    address.city = this.getAddressComponentByType(place.address_components, 'administrative_area_level_2', 'long_name')
    address.state = this.getAddressComponentByType(place.address_components, 'administrative_area_level_1', 'short_name')
    address.country = this.getAddressComponentByType(place.address_components, 'country', 'short_name')
    address.lat = place.geometry.location.lat()
    address.lng = place.geometry.location.lng()

    return address
  }

  getCurrentLocation = () => {
    if (DeviceIdentifier.isNativeApp()) {
      NativeApp.executeNativeMethod({
        method: 'getCurrentLocation'
      })
      this.setState({ obtendoLocalizacaoAtual: true })
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.getDetailsFromCoordinates(position.coords.latitude, position.coords.longitude, (placeWithDetails) => {
          this.select(placeWithDetails)
        })
      })
    }
  }

  select = (place) => {
    if (this.props.closeDialogOnSelect === false) {
      this.props.onSelect(place)
    } else {
      this.props.handleCloseDialog({
        onClosedCallback: () => {
          this.props.onSelect(place)
        }
      })
    }
  }

  handleSearchChange = (event) => {
    if (this.props.loadAllAndCache) {
      const termo = this.inputPesquisa.current ? this.inputPesquisa.current.value.trim() : ''
      const listaFiltrada = []
      for (let item of this.listaCached) {
        if (searchPart(item.label, termo)) {
          item._update = true
          listaFiltrada.push(item)
        }
      }
      this.setState({ lista: listaFiltrada })
    } else {
      this.loaderFunctionsMap.load()
    }
  }

  backToStep0 = () => {
    if (this.state.step === 2 && this.state.initialStep === 1) {
      this.setState({ step: 1, places: [] })
      this.setFocusInputBuscaEndereco()
    } else if (this.state.step === 1 && this.state.initialStep === 1) {
      this.props.handleCloseDialog()
    } else {
      this.setState({ step: 0, places: [] })
    }
  }

  getAddressComponentByType = (address_components, type, attribute) => {
    for (let addressComponent of address_components) {
      if (addressComponent.types.indexOf(type) >= 0) {
        if (attribute) {
          return addressComponent[attribute]
        }
        return addressComponent
      }
    }
    return null
  }

  handleSubmitComplementoEndereco = (event) => {
    event.preventDefault()
    this.complementarEndereco()
  }

  complementarEndereco = () => {
    const numberInput = this.inputEnderecoNumero.current
    const districtInput = this.inputEnderecoBairro.current
    const complementInput = this.inputEnderecoComplemento.current
    const cepInput = this.inputEnderecoCep.current

    if (numberInput && !numberInput.value.trim()) {
      return this.setState({ errorsMap: createErrorsMap({ 'endereco.numero': 'O número é obrigatório' }) })
    }
    if (districtInput && !districtInput.value.trim()) {
      return this.setState({ errorsMap: createErrorsMap({ 'endereco.bairro': 'O bairro é obrigatório' }) })
    }
    if (cepInput && !cepInput.value.trim()) {
      return this.setState({ errorsMap: createErrorsMap({ 'endereco.cep': 'O cep é obrigatório' }) })
    }
    if (cepInput && cepInput.value.trim().length != 9) {
      return this.setState({ errorsMap: createErrorsMap({ 'endereco.cep': 'Insira um cep válido' }) })
    }
    const { placeToComplete } = this.state

    this.setState({
      ajaxing: true
    })

    let place = placeToComplete.rawData

    const street = this.getAddressComponentByType(place.address_components, 'route', 'long_name')
    let number = this.getAddressComponentByType(place.address_components, 'street_number', 'long_name')
    let district = this.getAddressComponentByType(place.address_components, 'sublocality', 'long_name')
    const city = this.getAddressComponentByType(place.address_components, 'administrative_area_level_2', 'short_name')
    const state = this.getAddressComponentByType(place.address_components, 'administrative_area_level_1', 'short_name')
    let postalCode = this.getAddressComponentByType(place.address_components, 'postal_code', 'long_name')

    if (numberInput && numberInput.value) {
      number = numberInput.value
    }
    if (districtInput && districtInput.value) {
      district = districtInput.value
    }
    if (cepInput && cepInput.value) {
      postalCode = cepInput.value
    }

    const address = `${street}, ${number} - ${district}, ${city} - ${state}, ${postalCode}`

    this.getDetailsFromAddress(address, (placeWithDetails) => {
      if (placeWithDetails) {
        place = placeWithDetails.rawData
      }
    })

    this.goToStep3(place, { district, number, postalCode, complement: complementInput.value })
  }

  goToStep3 = (place, valuesFromInput) =>
    this.setState({
      step: 3,
      ajaxing: false,
      addressToSetCoordinates: this.convertPlaceToAddressObject(place, valuesFromInput)
    })

  backToStep2 = () => {
    this.setState({ step: 2 })
  }

  handleSubmitDefinirCoordenadas = (event) => {
    event.preventDefault()
    this.complementarEndereco()
  }

  definirCoordenadas = () => {
    const marketCoordinates = this.mapFunctionsMap.getMarkerCoordinates()
    const address = cloneObjectWithoutReference(this.state.addressToSetCoordinates)
    address.lat = marketCoordinates.lat
    address.lng = marketCoordinates.lng
    address.estado = EstadoEnum.getBySigla(marketCoordinates.state)
    this.select(address)
  }

  render() {
    const { classes, onlyPreciseAddress, dialogTitle, showBackButton, showGetCurrentLocationButton } = this.props
    const { step, errorsMap, historyPlaces, places } = this.state
    const dialogProps = extractDialogProps(this.props)

    const headerContent = []
    const bodyContent = []

    if (step === 0 || step === 1) {
      headerContent.push(
        <Typography key="title" variant="h6" className={classes.title}>
          {dialogTitle}
        </Typography>
      )

      headerContent.push(
        <InputText
          key="inputbuscaendereco"
          marginTop={true}
          idname="xbuscaendereco"
          shrink={false}
          customVariant="outlined"
          placeholder={onlyPreciseAddress ? 'Buscar por endereço e número' : 'Buscar por rua, bairro ou cidade...'}
          startAdornment={
            step === 0 ? (
              <PlaceIcon color="secondary" />
            ) : (
              <IconButton onClick={this.backToStep0} style={{ marginLeft: -12, marginRight: -12 }}>
                <ArrowBackIcon />
              </IconButton>
            )
          }
          onFocus={() => {
            this.setState({ step: 1 })
          }}
          onChange={(event) => {
            const service = new window.google.maps.places.AutocompleteService()
            service.getPlacePredictions(
              { input: event.target.value, language: 'pt_BR', componentRestrictions: { country: ['br'] }, types: [onlyPreciseAddress ? 'address' : 'geocode'] },
              (predictions, status) => {
                let places = []
                if (predictions && predictions.length > 0) {
                  places = predictions.map((prediction) => {
                    return {
                      description: prediction.description,
                      place_id: prediction.place_id,
                      prediction: prediction
                    }
                  })
                }
                this.setState({
                  places: places
                })
              }
            )
          }}
          inputRef={this.inputBuscaEndereco}
        />
      )

      if (step === 0) {
        if (showGetCurrentLocationButton && (navigator.geolocation || DeviceIdentifier.isNativeApp())) {
          headerContent.push(
            <InputText
              key="inputminhalocalizacao"
              style={{ displays: 'flex' }}
              className={classes.inputMinhaLocalizacao}
              marginTop={8}
              disabled={this.state.obtendoLocalizacaoAtual}
              idname="xminhalocalizacao"
              shrink={false}
              readOnly
              customVariant="outlined"
              value="Usar minha localização"
              startAdornment={<MyLocationIcon />}
              endAdornment={
                this.state.obtendoLocalizacaoAtual ? (
                  <div style={{ paddingRight: 16 }}>
                    <CircularProgress style={{ display: 'block', opacity: 0.5 }} size={22} thickness={5.8} />
                  </div>
                ) : null
              }
              focusEffectDisabled={true}
              onClick={this.getCurrentLocation}
            />
          )
        }

        if (showBackButton) {
          headerContent.push(
            <InputText
              key="inputbotaovoltar"
              style={{ displays: 'flex' }}
              className={classes.inputVoltar}
              marginTop={8}
              idname="xbotaovoltar"
              shrink={false}
              readOnly
              customVariant="outlined"
              value="Voltar"
              startAdornment={<ArrowBackIcon />}
              focusEffectDisabled={true}
              onClick={() => {
                this.props.handleCloseDialog()
              }}
            />
          )
        }

        if (historyPlaces && historyPlaces.length > 0) {
          bodyContent.push(
            <VirtualizedList
              key="HistoryPlacesList"
              rowHeight={DEFAULT_ROW_HEIGHT}
              windowScroller={true}
              scrollElement={this.scrollContainerRef.current}
              items={historyPlaces}
              rowRenderer={this.renderRowHistoryPlaces}
            />
          )
        }
      }

      if (step === 1) {
        let emptyListProps = null
        let textEmptylist = null
        if (this.inputBuscaEndereco && this.inputBuscaEndereco.current && this.inputBuscaEndereco.current.value) {
          textEmptylist = (
            <span>
              Nenhum endereço foi encontrado para a busca <b>"{this.inputBuscaEndereco.current.value}"</b>
            </span>
          )
        }
        emptyListProps = {
          text: textEmptylist
        }

        bodyContent.push(
          <VirtualizedList
            key="FoundPlacesList"
            rowHeight={DEFAULT_ROW_HEIGHT}
            windowScroller={true}
            scrollElement={this.scrollContainerRef.current}
            items={places}
            rowRenderer={this.renderRowPlaces}
            emptyListProps={emptyListProps}
          />
        )
      }
    } else if (step === 2) {
      const { placeToComplete } = this.state

      const streetNumber = this.getAddressComponentByType(placeToComplete.rawData.address_components, 'street_number', 'long_name')
      const district = this.getAddressComponentByType(placeToComplete.rawData.address_components, 'sublocality', 'long_name')
      const cep = this.getAddressComponentByType(placeToComplete.rawData.address_components, 'postal_code', 'long_name')

      headerContent.push(
        <Grid key="headerComplementoEndereco" container alignItems="center">
          <Grid item>
            <IconButton onClick={this.backToStep0} style={{ marginLeft: -16, marginRight: 4 }}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography key="title" variant="h6" style={{ fontSize: 18, lineHeight: '24px' }}>
              {placeToComplete.description}
            </Typography>
          </Grid>
        </Grid>
      )

      bodyContent.push(
        <form key="inputsComplementoEndereco" id="formComplementoEndereco" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmitComplementoEndereco(event)}>
          <Grid container direction="column" style={{ width: '101.5%' }}>
            {!streetNumber && (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                xs={COMPLEMENTO_SIZE}
                sm={COMPLEMENTO_SIZE}
                md={COMPLEMENTO_SIZE}
                lg={COMPLEMENTO_SIZE}
                style={{ marginBottom: '1px' }}
              >
                <InputText
                  marginTop={6}
                  idname="xenderecoNumero"
                  customVariant="outlined-small"
                  shrink={false}
                  disabled={this.state.ajaxing || this.state.checkEnderecoSemNumero}
                  isRequired
                  errorMessage={errorsMap('endereco.numero')}
                  label={this.state.checkEnderecoSemNumero ? 's/n' : 'Número'}
                  inputRef={this.inputEnderecoNumero}
                  className={classes.inputEndereco}
                />
                <UncontrolledCheckbox
                  className={classes.checkBoxSemNumero}
                  formControlProps={{
                    label: 'Sem número'
                  }}
                  disabled={this.state.ajaxing}
                  defaultChecked={this.state.checkEnderecoSemNumero}
                  onChange={(value) => {
                    if (value) {
                      this.inputEnderecoNumero.current.value = 's/n'
                      this.setState({ errorsMap: createErrorsMap({ 'endereco.numero': '' }) })
                    } else {
                      this.inputEnderecoNumero.current.value = ''
                    }
                    this.setState({ checkEnderecoSemNumero: value })
                  }}
                />
              </Grid>
            )}
            {!district && (
              <Grid container xs={COMPLEMENTO_SIZE} sm={COMPLEMENTO_SIZE} md={COMPLEMENTO_SIZE} lg={COMPLEMENTO_SIZE}>
                <InputText
                  idname="xenderecoBairro"
                  customVariant="outlined-small"
                  shrink={false}
                  marginTop={true}
                  disabled={this.state.ajaxing}
                  errorMessage={errorsMap('endereco.bairro')}
                  label="Bairro"
                  inputRef={this.inputEnderecoBairro}
                  inputProps={{
                    maxLength: 60
                  }}
                />
                <div className={classes.complementoLabel}>Tamanho máximo de 60 caracteres</div>
              </Grid>
            )}
            {(!cep || cep.length != 9) && (
              <InputText
                marginTop={true}
                idname="xcep"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={cep}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('endereco.cep')}
                label="CEP"
                inputComponent={CEPMask}
                inputRef={this.inputEnderecoCep}
              />
            )}
            <Grid container xs={COMPLEMENTO_SIZE} sm={COMPLEMENTO_SIZE} md={COMPLEMENTO_SIZE} lg={COMPLEMENTO_SIZE}>
              <InputText
                idname="xenderecoComplemento"
                customVariant="outlined-small"
                shrink={false}
                marginTop={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('endereco.complemento')}
                label="Complemento"
                inputRef={this.inputEnderecoComplemento}
                inputProps={{
                  maxLength: 30
                }}
              />
              <div className={classes.complementoLabel}>Tamanho máximo de 30 caracteres</div>
            </Grid>
          </Grid>

          <HiddenSubmitButton />

          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.backToStep0}>
              Voltar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.complementarEndereco}>
              {this.state.ajaxing ? 'Aguarde' : 'Continuar'}
            </Button>
          </div>
        </form>
      )
    } else if (step === 3) {
      const { addressToSetCoordinates } = this.state

      let ultimoElementoConcatenado = null
      let enderecoFormatado = ''

      if (addressToSetCoordinates.address) {
        ultimoElementoConcatenado = 'endereco'
        enderecoFormatado = addressToSetCoordinates.address
        if (addressToSetCoordinates.number) {
          ultimoElementoConcatenado = 'numero'
          enderecoFormatado += ', ' + addressToSetCoordinates.number
        }
      }

      if (addressToSetCoordinates.complement) {
        if (ultimoElementoConcatenado) {
          enderecoFormatado += ' - '
        }
        ultimoElementoConcatenado = 'complemento'
        enderecoFormatado += addressToSetCoordinates.complement
      }

      if (addressToSetCoordinates.district) {
        if (ultimoElementoConcatenado) {
          if (ultimoElementoConcatenado === 'numero') {
            enderecoFormatado += ' - '
          } else {
            enderecoFormatado += ', '
          }
        }
        ultimoElementoConcatenado = 'bairro'
        enderecoFormatado += addressToSetCoordinates.district
      }

      if (addressToSetCoordinates.city) {
        if (ultimoElementoConcatenado) {
          if (ultimoElementoConcatenado === 'numero') {
            enderecoFormatado += ' - '
          } else {
            enderecoFormatado += ', '
          }
        }
        ultimoElementoConcatenado = 'cidade'
        enderecoFormatado += addressToSetCoordinates.city
      }

      if (addressToSetCoordinates.state) {
        if (ultimoElementoConcatenado) {
          if (ultimoElementoConcatenado === 'numero' || ultimoElementoConcatenado === 'cidade') {
            enderecoFormatado += ' - '
          } else {
            enderecoFormatado += ', '
          }
        }
        enderecoFormatado += addressToSetCoordinates.state
      }

      headerContent.push(
        <Grid key="headerComplementoEndereco" container alignItems="center">
          <Grid item>
            <IconButton onClick={this.backToStep2} style={{ marginLeft: -16, marginRight: 4 }}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography key="title" variant="h6" style={{ fontSize: 18, lineHeight: '24px' }}>
              {enderecoFormatado}
            </Typography>
          </Grid>
        </Grid>
      )

      bodyContent.push(
        <form key="inputsComplementoEndereco" id="formComplementoEndereco" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmitDefinirCoordenadas(event)}>
          <div className={classes.containerMap}>
            <Map classNames={classes} lat={addressToSetCoordinates.lat} lng={addressToSetCoordinates.lng} functionsMap={this.mapFunctionsMap} />
          </div>
          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.backToStep2}>
              Voltar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.definirCoordenadas}>
              {this.state.ajaxing ? 'Aguarde' : 'Concluir'}
            </Button>
          </div>
        </form>
      )
    }

    return (
      <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={dialogProps} disabledOnClose={this.state.ajaxing}>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent className={classNames(classes.dialogContent, 'needsWillChange')} style={{ wwillChange: 'ttransform' }}>
            <div className={classes.header}>{headerContent}</div>
            <div style={{ width: '100%', position: 'relative' }}>{bodyContent}</div>
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    this.mounted = false
  }
}

function ListItemSelectable(props) {
  let onClick = props.onClick
  let classes = [props.classesNamesObject.rootListItem]

  return (
    <Grid container alignItems="center" className={classNames(classes)} onClick={onClick}>
      <Grid item style={{ opacity: 0.8, marginRight: 16, marginLeft: -10 }}>
        <props.iconComponent className={props.classesNamesObject.iconListItem} />
      </Grid>
      <Grid item xs className={props.classesNamesObject.contentListItem}>
        <Typography variant="body1" className={props.classesNamesObject.primaryListItem}>
          {props.primary}
        </Typography>
      </Grid>
      <Grid item style={{ marginRight: -16 }}>
        <NavigateNextIcon className={props.classesNamesObject.iconListItem} />
      </Grid>
    </Grid>
  )
}

let ID_MAP_PREFIX = 's99GoogleMapContainerToSetCoordinates'
let ID_MAP_GENERATOR = 0

class Map extends Component {
  constructor(props) {
    super(props)
    this.mapId = ID_MAP_PREFIX + '-' + ID_MAP_GENERATOR++
  }

  componentDidMount() {
    const coordinates = { lat: this.props.lat, lng: this.props.lng }
    const map = new window.google.maps.Map(document.getElementById(this.mapId), {
      clickableIcons: false,
      scaleControl: false,
      streetViewControl: false,
      panControl: false,
      controlSize: 22,
      zoomControl: true,
      gestureHandling: 'cooperative', //"greedy",
      center: coordinates,
      zoom: 17
    })
    const marker = new window.google.maps.Marker({
      map,
      position: coordinates,
      draggable: true,
      label: { text: 'O endereço é Aqui?', className: this.props.classNames.markerLabel },
      icon: {
        url: mapMarkerImg,
        scaledSize: new window.google.maps.Size(38, 38)
      }
    })

    if (this.props.functionsMap !== undefined) {
      this.props.functionsMap['getMarkerCoordinates'] = () => {
        return {
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng()
        }
      }
    }
  }

  render() {
    return <div id={this.mapId} style={{ height: 350 }}></div>
  }
}

PlacePickerDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PlacePickerDialog)
