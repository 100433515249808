import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import HorarioAtendimentoAODialogPage from 'pages/admin/painel/negocio/HorarioAtendimentoAODialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import DiaSemanalEnum from 'support/domain/datetime/DiaSemanalEnum'
import { createErrorsMap } from 'support/util/FormUtil'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class HorariosAtendimentoAODialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Horários Atendimento Agendamento Online',
      lista: [],
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
    this.loaderFunctionsMap = {}
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoHorarioAtendimentoAO', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  handlerOpenCreateDialog = () => {
    openBackableDialog(HorarioAtendimentoAODialogPage, {
      idNegocio: this.props.idNegocio,
      idColaborador: this.props.idColaborador
    })
  }

  handlerEditar = (item) => {
    openBackableDialog(HorarioAtendimentoAODialogPage, {
      idColaboradorHorarioAtendimentoAO: item.id,
      idNegocio: this.props.idNegocio,
      idColaborador: this.props.idColaborador
    })
  }

  handlerRemover = (item) => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Horário Atendimento AO',
      text: (
        <span>
          Você tem certeza que deseja remover os horários do dia <b>{DiaSemanalEnum.getById(item.dia).descricao}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'admin/colaboradores.removerColaboradorHorarioAtendimentoAO',
        contextoUsuario: 'admin',
        data: {
          id: item.id,
          idNegocio: this.props.idNegocio
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoHorarioAtendimentoAO')
        }
      }
    })
  }

  getRenderContent = (size, item, index) => {
    const content = {}

    content.dia = DiaSemanalEnum.getById(item.dia).descricao
    content.horariosFixos = item.horariosFixos

    content.botaoOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -4 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ horarioAtendimentoMenuAnchorEl: event.currentTarget, itemMenu: item })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )

    return content
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={600}
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              icon: <AddIcon />,
              handleAction: this.handlerOpenCreateDialog
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="admin"
              endpoint="admin/colaboradores.buscarColaboradorHorariosAtendimentoAO"
              getRequestParametersFunction={() => ({
                idColaborador: this.props.idColaborador
              })}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Dia Semana', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Horários Fixos', horizontalPadding: 'small', props: { xs: true } },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.dia, content.horariosFixos, content.botaoOpcoes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 60,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center" style={{ minWidth: 0 }}>
                          <Grid item xs style={{ minWidth: 0 }}>
                            <React.Fragment>
                              <Typography variant="body2">{content.dia}</Typography>
                              <Typography variant="body2">{content.horariosFixos}</Typography>
                            </React.Fragment>
                          </Grid>
                          <Grid item>{content.botaoOpcoes}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum horário configurado foi encontrado',
                textButton: 'Cadastrar horário atendimento fixo AO',
                creationFunction: this.handlerOpenCreateDialog
              }}
            />

            <Menu
              anchorEl={this.state.horarioAtendimentoMenuAnchorEl}
              open={Boolean(this.state.horarioAtendimentoMenuAnchorEl)}
              onClose={() => this.closeMenu('horarioAtendimentoMenuAnchorEl')}
            >
              <MenuItem
                onClick={() => {
                  this.handlerEditar(this.state.itemMenu)
                  this.closeMenu('horarioAtendimentoMenuAnchorEl')
                }}
              >
                Abrir/Editar
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handlerRemover(this.state.itemMenu)
                  this.closeMenu('horarioAtendimentoMenuAnchorEl')
                }}
              >
                Remover
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

HorariosAtendimentoAODialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(HorariosAtendimentoAODialogPage)
