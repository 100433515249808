import React from 'react'

import AtendimentoService from 'support/domain/atendimento/AtendimentoService'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

function isAtendimento(venda) {
  if (TipoVendaEnum.ATENDIMENTO.id === venda.tipo.id) {
    return true
  }
  return false
}

function isVendaProduto(venda) {
  if (TipoVendaEnum.VENDA_PRODUTO.id === venda.tipo.id) {
    return true
  }
  return false
}

function isVendaPacote(venda) {
  if (TipoVendaEnum.VENDA_PACOTE.id === venda.tipo.id) {
    return true
  }
  return false
}

function isVendaCredito(venda) {
  if (TipoVendaEnum.VENDA_CREDITO.id === venda.tipo.id) {
    return true
  }
  return false
}

function getDescricao(venda) {
  if (isAtendimento(venda)) {
    return AtendimentoService.gerarDescricaoServico(venda.atendimento)
  } else if (isVendaProduto(venda)) {
    return venda.vendaProduto.produto.nome
  } else if (isVendaPacote(venda)) {
    return venda.vendaPacote.pacote.nome
  } else if (isVendaCredito(venda)) {
    return TipoVendaEnum.VENDA_CREDITO.descricao
  }
}

function getIconeEDescricao(venda) {
  if (TipoVendaEnum.ATENDIMENTO.id === venda.tipo.id) {
    return {
      descricao: AtendimentoService.gerarDescricaoServico(venda.atendimento),
      icon: TipoVendaEnum.ATENDIMENTO.iconComponent
    }
  } else if (TipoVendaEnum.VENDA_PRODUTO.id === venda.tipo.id) {
    return {
      descricao: venda.vendaProduto.produto.nome,
      icon: TipoVendaEnum.VENDA_PRODUTO.iconComponent
    }
  } else if (TipoVendaEnum.VENDA_PACOTE.id === venda.tipo.id) {
    return {
      descricao: venda.vendaPacote.pacote.nome,
      icon: TipoVendaEnum.VENDA_PACOTE.iconComponent
    }
  } else if (TipoVendaEnum.VENDA_CREDITO.id === venda.tipo.id) {
    return {
      descricao: getDescricao(venda),
      icon: TipoVendaEnum.VENDA_CREDITO.iconComponent
    }
  }
}

function converterAtendimentoParaVenda(atendimento) {
  atendimento = JSON.parse(JSON.stringify(atendimento))
  if (atendimento) {
    const venda = atendimento.venda
    if (venda) {
      venda.atendimento = atendimento
      delete venda.atendimento.venda
      return venda
    }
  }
  return false
}

function converterVendaParaAtendimento(venda) {
  venda = JSON.parse(JSON.stringify(venda))
  if (venda) {
    const atendimento = venda.atendimento
    if (atendimento) {
      atendimento.venda = venda
      delete atendimento.venda.atendimento
      return atendimento
    }
  }
  return false
}

function converterVendaProdutoParaVenda(vendaProduto) {
  vendaProduto = JSON.parse(JSON.stringify(vendaProduto))
  if (vendaProduto) {
    const venda = vendaProduto.venda
    if (venda) {
      venda.vendaProduto = vendaProduto
      delete venda.vendaProduto.venda
      return venda
    }
  }
  return false
}

function converterVendaPacoteParaVenda(vendaPacote) {
  vendaPacote = JSON.parse(JSON.stringify(vendaPacote))
  if (vendaPacote) {
    const venda = vendaPacote.venda
    if (venda) {
      venda.vendaPacote = vendaPacote
      delete venda.vendaPacote.venda
      return venda
    }
  }
  return false
}

function converterVendaCreditoParaVenda(vendaCredito) {
  vendaCredito = JSON.parse(JSON.stringify(vendaCredito))
  if (vendaCredito) {
    const venda = vendaCredito.venda
    if (venda) {
      venda.vendaCredito = vendaCredito
      delete venda.vendaCredito.venda
      return venda
    }
  }
  return false
}

function getDescricaoPreco(venda, props) {
  if (!props) {
    props = {}
  }

  const style = {}
  if (venda.cancelado || (venda.atendimento && venda.atendimento.status.id === AtendimentoStatusEnum.CLIENTE_FALTOU.id)) {
    style.textDecoration = 'line-through'
    style.color = '#aaa'
  }

  if (venda.clientePacoteItem) {
    if (props.format === 'minificado') {
      return <span style={style}>Crédito de Pacote</span>
    } else {
      return (
        <span style={style}>
          Sem Cobrança
          <br />
          <small style={{ color: '#aaa' }}>Crédito de Pacote</small>
        </span>
      )
    }
  } else {
    let descricaoValor = formatarValorMonetario(venda.valorTotal)
    if (isVendaProduto(venda)) {
      return (
        <span style={style}>
          {descricaoValor}
          {props.quebraLinhaUnidades && (
            <small style={{ color: '#aaa' }}>
              <br /> {venda.quantidade} unidade{venda.quantidade > 1 ? 's' : ''}
            </small>
          )}
          {!props.quebraLinhaUnidades && (
            <span>
              {' '}
              ({venda.quantidade} unidade{venda.quantidade > 1 ? 's' : ''})
            </span>
          )}
        </span>
      )
    } else {
      return <span style={style}>{descricaoValor}</span>
    }
  }
}

const functionsObject = {
  isAtendimento: isAtendimento,
  isVendaProduto: isVendaProduto,
  isVendaPacote: isVendaPacote,
  isVendaCredito: isVendaCredito,
  getDescricao: getDescricao,
  getIconeEDescricao: getIconeEDescricao,
  getDescricaoPreco: getDescricaoPreco,
  converterAtendimentoParaVenda: converterAtendimentoParaVenda,
  converterVendaParaAtendimento: converterVendaParaAtendimento,
  converterVendaProdutoParaVenda: converterVendaProdutoParaVenda,
  converterVendaPacoteParaVenda: converterVendaPacoteParaVenda,
  converterVendaCreditoParaVenda: converterVendaCreditoParaVenda
}

export default functionsObject
