import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import EventIcon from '@material-ui/icons/Event'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  layoutToolbar: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 0,
    position: 'relative',
    zIndex: 2
  },
  layoutBody: {
    display: 'flex',
    overflow: 'hidden',
    height: 262
  },
  layoutMenu: {
    width: 174,
    overflow: 'hidden',
    height: '100%',
    //...theme.util.createBoxShadow("0 0 10px 0 rgba(0,0,0,0.2)"),
    position: 'relative',
    zIndex: 1,
    borderRadius: 6
    //backgroundColor : theme.palette.drawer.background
  },
  layoutMenuItem: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40
  },
  layoutMenuItemSelected: {
    background: lighten(theme.palette.secondary.light, 0.92)
  },
  layoutMenuItemIcon: {
    color: theme.palette.text.primary,
    fontSize: 20,
    '$layoutMenuItemSelected &': {
      color: theme.palette.secondary.main
    }
  },
  layoutMenuItemText: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    '$layoutMenuItemSelected &': {
      color: theme.palette.secondary.main
    }
  },
  layoutContent: {
    position: 'relative',
    flex: '1 1 0',
    height: '100%'
  },
  layoutInnerContent: {
    [theme.breakpoints.down(420)]: {
      display: 'none'
    }
  },
  textThemeColor: {
    color: theme.palette.secondary.main
  }
})

class ConfiguracoesTemaPaginaExemploConteudo extends Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <div className={classes.layoutBody} style={{ display: 'flex' }}>
          <div className={classes.layoutMenu}>
            <div style={{ padding: '15px 0px' }}>
              {[
                { icon: EventIcon, label: 'Agenda' },
                { icon: AccountCircleIcon, label: 'Clientes', selected: true },
                { icon: SupervisedUserCircleIcon, label: 'Vendas' },
                { icon: ShowChartIcon, label: 'Financeiro' }
              ].map((item, index) => (
                <Grid container alignItems="center" className={classNames(classes.layoutMenuItem, item.selected ? classes.layoutMenuItemSelected : null)} key={index} wrap="nowrap">
                  <Grid item style={{ marginLeft: 16 }}>
                    <item.icon className={classes.layoutMenuItemIcon} style={{ display: 'block' }} />
                  </Grid>
                  <Grid item style={{ marginLeft: 16 }}>
                    <Typography className={classes.layoutMenuItemText} variant="body2">
                      {item.label}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </div>
          </div>
          <div className={classes.layoutContent}>
            <div className={classes.layoutToolbar}>
              <Grid container alignItems="center">
                <Grid item style={{ marginLeft: 16 }}>
                  <Typography variant="h6" style={{ fontWeight: 400 }}>
                    <b>Demonstração</b>
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: 16 }}>
              <Typography variant="body2">Tela de demostração para visualização das cores do tema escolhido</Typography>
              <Typography variant="body1" className={classes.textThemeColor} style={{ marginTop: 12 }}>
                Demonstração
              </Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ConfiguracoesTemaPaginaExemploConteudo.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ConfiguracoesTemaPaginaExemploConteudo)
