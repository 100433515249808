import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InterfaceDefinicaoComissoes from 'pages/erp/painel/servico/InterfaceDefinicaoComissoes'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ComissoesPersonalizadasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      scrollToIndex: -1,
      ajaxing: false,
      lista: [],
      paginaCarregada: false
    }
    EventsManager.pub('TabSaveSnackBar', { open: false })
    this.interfaceDefinicaoComissoesFunctionsMap = {}
  }

  salvar = () => {
    this.interfaceDefinicaoComissoesFunctionsMap.salvar()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    let nomeEntidade = null

    if (this.props.entidade === 'servico') {
      nomeEntidade = this.props.colaborador.apelido
    } else if (this.props.entidade === 'colaborador') {
      nomeEntidade = this.props.servico.nome
    }

    let toolbarActions = null
    if (this.state.paginaCarregada && this.state.lista.length > 0) {
      toolbarActions = {
        actions: [
          {
            label: 'Salvar',
            handleAction: this.salvar
          }
        ]
      }
    }

    return (
      <DialogPage {...dialogProps} title={'Comissões'} align="center" contentMaxWidth={534} pageType="basicForm" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            {this.state.paginaCarregada && this.state.lista.length > 0 && (
              <div style={{ paddingBottom: 28 }}>
                <Typography variant="h6">{nomeEntidade}</Typography>

                <Typography variant="body2">
                  Para configurar comissões personalizadas, preencha os campos abaixo.
                  <br />
                  Caso os campos fiquem em branco, o sistema utilizará as <u>comissões padrões</u>.
                </Typography>
              </div>
            )}

            <InterfaceDefinicaoComissoes
              entidade={this.props.entidade}
              acao="configurarComissoes"
              scrollElement={dialogContentProps.scrollContainerRef.current}
              servico={this.props.servico}
              colaborador={this.props.colaborador}
              saveFunction={this.salvar}
              onChange={(props) => {
                const newState = {}
                if (props.errorsMap !== undefined) newState.errorsMap = props.errorsMap
                if (props.scrollToIndex !== undefined) newState.scrollToIndex = props.scrollToIndex
                if (props.ajaxing !== undefined) newState.ajaxing = props.ajaxing
                if (props.lista !== undefined) newState.lista = props.lista
                if (props.paginaCarregada !== undefined) newState.paginaCarregada = props.paginaCarregada
                this.setState(newState)
              }}
              errorsMap={this.state.errorsMap}
              scrollToIndex={this.state.scrollToIndex}
              ajaxing={this.state.ajaxing}
              lista={this.state.lista}
              paginaCarregada={this.state.paginaCarregada}
              onSaved={() => {
                this.props.handleCloseDialog()
              }}
              functionsMap={this.interfaceDefinicaoComissoesFunctionsMap}
            />

            {this.state.paginaCarregada && this.state.lista.length > 0 && (
              <div className={classes.containerFooterButtons}>
                <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                  Cancelar
                </Button>
                <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                  {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

ComissoesPersonalizadasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ComissoesPersonalizadasDialogPage)
