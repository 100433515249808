import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import VisualizacaoEmailDialogPage from 'pages/admin/painel/email/VisualizacaoEmailDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import EmailCategoriaEnum from 'support/domain/email/EmailCategoriaEnum'

const styles = (theme) => ({})

class EmailsLogsPanelPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.data = moment(item.dataCriacao).format('DD/MM/YY HH:mm:ss')
    content.categoria = EmailCategoriaEnum.getById(item.categoria.id).descricao
    content.remetente = item.remetente
    content.destinatarios = item.destinatarios
    return content
  }

  render() {
    const { classes, ...others } = this.props

    return (
      <PanelPage
        {...others}
        title="Logs de E-mails"
        toolbarActions={{
          actions: [
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.loaderFunctionsMap.load()
              }
            }
          ]
        }}
      >
        <VirtualizedResponsiveTable
          itemsPerRequest={20}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="admin"
          endpoint="admin/emails.buscarLogEnvios"
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            openBackableDialog(VisualizacaoEmailDialogPage, {
              destinatario: item.destinatarios,
              assunto: item.assunto,
              conteudo: item.conteudo
            })
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Tipo', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Remetente', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Destinatário', horizontalPadding: 'small', props: { xs: true } }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [content.data, content.categoria, content.remetente, content.destinatarios]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 120,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                itemData: (
                  <React.Fragment>
                    <Typography variant="body2" style={{ fontSize: 12 }} noWrap={true}>
                      {content.data} - {content.categoria}
                    </Typography>
                    <Typography variant="body2">{content.remetente}</Typography>
                    <Typography variant="body2">{content.destinatarios}</Typography>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum registro encontrado'
          }}
        />
      </PanelPage>
    )
  }
}

EmailsLogsPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EmailsLogsPanelPage)
