import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import InputPorcentagemValor from 'support/components/input/InputPorcentagemValor'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'

const styles = (theme) => ({
  comissaoDesabilitada: {
    color: theme.palette.text.disabled
  },
  comissaoDesabilitadaSmall: {
    color: theme.palette.text.disabled,
    marginTop: 0
  },
  accentCheckboxRoot: {
    color: theme.palette.secondary.light,
    '&$accentCheckboxChecked': {
      color: theme.palette.secondary.light
    }
  },
  accentCheckboxChecked: {},
  textoCategoria: {
    color: theme.palette.secondary.main,
    fontWeight: 500
  }
})

class DefinicaoComissoesResponsiveTable extends Component {
  constructor(props) {
    super(props)
    if (props.entidade === 'colaborador') {
      this.campoDescricao = 'Selecionar todos'
      this.errorsMapPrefixCampoComissao = 'colaborador'
      this.onChangeCheckboxFunction = this.props.changeCheckboxColaborador
      this.smallRenderMarginTop = -4
      this.showFirstItemBorderTop = true
    } else if (props.entidade === 'servico') {
      this.campoDescricao = 'Serviço'
      this.errorsMapPrefixCampoComissao = 'servico'
      this.onChangeCheckboxFunction = this.props.changeCheckboxServico
      this.smallRenderMarginTop = -20
      this.showFirstItemBorderTop = false
    }
  }

  getRenderContent = (size, item, index) => {
    const { classes } = this.props
    const content = {}

    let checkboxClasses = {}
    let comissaoInputsContent = null
    let onChangeCheckbox = null
    let descricaoItem = null
    let descricaoCategoria = null

    let widthInput = 130

    if (item.tipo === 'categoria') {
      onChangeCheckbox = this.props.changeCheckboxCategoria
      content.rowStyle = 'accent'
      checkboxClasses = { root: classes.accentCheckboxRoot, checked: classes.accentCheckboxChecked }
      descricaoItem = (
        <Typography variant="body2" className={classes.textoCategoria} noWrap={true}>
          {item.nome}
        </Typography>
      )
      descricaoCategoria = descricaoItem
    } else {
      onChangeCheckbox = this.onChangeCheckboxFunction
      descricaoItem = (
        <Typography variant="body2" noWrap={true}>
          {item.nome}
        </Typography>
      )
      if (item.categoria) {
        descricaoCategoria = (
          <Typography variant="body2" className={classes.textoCategoria} noWrap={true}>
            {item.categoria.nome}
          </Typography>
        )
      }

      comissaoInputsContent = (
        <React.Fragment>
          <InputPorcentagemValor
            nakedLeftPadding={false}
            tipoCalculoVariant="small"
            defaultValor={item.colaboradorServico.comissaoSozinho}
            defaultTipoCalculo={item.colaboradorServico.tipoCalculoComissaoSozinho}
            customVariant="outlined-small"
            backgroundColor="inherit"
            label="Comissão Sozinho"
            style={{ maxWidth: widthInput, marginTop: 8 }}
            InputProps={{
              style: {
                maxWidth: widthInput
              }
            }}
            errorMessage={this.props.errorsMap(this.errorsMapPrefixCampoComissao + ':' + item.id + '.comissaoSozinho')}
            onChange={(event) => {
              if (this.props.onDataChange) {
                this.props.onDataChange()
              }
              item.colaboradorServico.comissaoSozinho = event.target.value
            }}
            onChangeTipoCalculo={(tipoCalculo) => {
              if (this.props.onDataChange) {
                this.props.onDataChange()
              }
              item.colaboradorServico.tipoCalculoComissaoSozinho = tipoCalculo
            }}
          />

          <InputPorcentagemValor
            nakedLeftPadding={false}
            tipoCalculoVariant="small"
            defaultValor={item.colaboradorServico.comissaoComAssistente}
            defaultTipoCalculo={item.colaboradorServico.tipoCalculoComissaoComAssistente}
            customVariant="outlined-small"
            backgroundColor="inherit"
            label="Com Assistentes"
            style={{ maxWidth: widthInput, marginLeft: -1, marginRight: -1, marginTop: 8 }}
            InputProps={{
              style: {
                maxWidth: widthInput
              }
            }}
            errorMessage={this.props.errorsMap(this.errorsMapPrefixCampoComissao + ':' + item.id + '.comissaoComAssistente')}
            onChange={(event) => {
              if (this.props.onDataChange) {
                this.props.onDataChange()
              }
              item.colaboradorServico.comissaoComAssistente = event.target.value
            }}
            onChangeTipoCalculo={(tipoCalculo) => {
              if (this.props.onDataChange) {
                this.props.onDataChange()
              }
              item.colaboradorServico.tipoCalculoComissaoComAssistente = tipoCalculo
            }}
          />

          <InputPorcentagemValor
            nakedLeftPadding={false}
            tipoCalculoVariant="small"
            defaultValor={item.colaboradorServico.comissaoComoAssistente}
            defaultTipoCalculo={item.colaboradorServico.tipoCalculoComissaoComoAssistente}
            customVariant="outlined-small"
            backgroundColor="inherit"
            label="Como Assistente"
            style={{ maxWidth: widthInput, marginTop: 8 }}
            InputProps={{
              style: {
                maxWidth: widthInput
              }
            }}
            errorMessage={this.props.errorsMap(this.errorsMapPrefixCampoComissao + ':' + item.id + '.comissaoComoAssistente')}
            onChange={(event) => {
              if (this.props.onDataChange) {
                this.props.onDataChange()
              }
              item.colaboradorServico.comissaoComoAssistente = event.target.value
            }}
            onChangeTipoCalculo={(tipoCalculo) => {
              if (this.props.onDataChange) {
                this.props.onDataChange()
              }
              item.colaboradorServico.tipoCalculoComissaoComoAssistente = tipoCalculo
            }}
          />
          <br />
          <UncontrolledCheckbox
            formControlProps={{
              style: { marginRight: 0, marginTop: 2 },
              label: (
                <small style={{ lineHeight: '16px', display: 'inline-block', padding: '8px 0px' }}>
                  Em atendimentos com assistentes, descontar as comissões dos assistentes da comissão do profissional
                </small>
              )
            }}
            style={{ marginLeft: 2 }}
            color="default"
            defaultChecked={item.colaboradorServico.descontarComissaoComAssistente}
            onChange={(value) => {
              if (this.props.onDataChange) {
                this.props.onDataChange()
              }
              item.colaboradorServico.descontarComissaoComAssistente = value
            }}
          />
        </React.Fragment>
      )
    }

    content.checkbox = <Checkbox style={{ padding: 7 }} color="default" classes={checkboxClasses} checked={item._checked === undefined ? false : item._checked} />
    content.itemData = []

    if (this.props.acao === 'selecionarEntidade') {
      content.selected = item._checked
      content.onClickRow = (item, event, index) => {
        onChangeCheckbox(!item._checked, index)
      }
      content.itemData.push(
        <Grid key={index} container alignItems="center" style={{ minWidth: 0, backgroundColor: 'inherit' }}>
          <Grid item>{content.checkbox}</Grid>
          <Grid item xs style={{ minWidth: 0, backgroundColor: 'inherit' }}>
            {descricaoItem}
          </Grid>
        </Grid>
      )
    } else if (this.props.acao === 'configurarComissoes') {
      content.itemData.push(
        <React.Fragment key={index}>
          <Grid container alignItems="center" style={{ minWidth: 0, backgroundColor: 'inherit', marginBottom: 8 }}>
            <Grid item xs style={{ minWidth: 0, backgroundColor: 'inherit' }}>
              {descricaoCategoria}
              {descricaoItem}
            </Grid>
          </Grid>
          {comissaoInputsContent}
        </React.Fragment>
      )
    }

    return content
  }

  render() {
    const { classes, ...others } = this.props

    let rowHeightLarge = null
    let rowHeightSmall = null
    let showRowBorders = null

    if (this.props.acao === 'selecionarEntidade') {
      rowHeightLarge = 40
      rowHeightSmall = 40
      showRowBorders = false
    } else if (this.props.acao === 'configurarComissoes') {
      if (this.props.entidade === 'colaborador') {
        rowHeightLarge = 190
        rowHeightSmall = 290
      } else if (this.props.entidade === 'servico') {
        rowHeightLarge = 200
        rowHeightSmall = 300
      }

      showRowBorders = true
    }

    return (
      <VirtualizedResponsiveTable
        showHeaderColumns={false}
        showRowBorders={showRowBorders}
        largeRenderProps={{
          rowHeight: rowHeightLarge,
          columns: [{ label: this.campoDescricao, props: { xs: true } }],
          showFirstItemBorderTop: true,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('large', item, index)
            return {
              rowStyle: content.rowStyle,
              onClickRow: content.onClickRow,
              itemData: content.itemData
            }
          }
        }}
        smallRenderProps={{
          rowHeight: rowHeightSmall,
          showFirstItemBorderTop: true,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('small', item, index)
            return {
              rowStyle: content.rowStyle,
              onClickRow: content.onClickRow,
              itemData: content.itemData
            }
          }
        }}
        {...others}
      />
    )
  }
}

DefinicaoComissoesResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DefinicaoComissoesResponsiveTable)
