import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PacoteDialogPage from 'pages/erp/painel/pacotes/PacoteDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class PacoteInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    const creationFunction = (props) => {
      openBackableDialog(PacoteDialogPage, {
        nome: props.text
      })
    }

    if (!props.label) props.label = 'Pacote'
    if (!props.id) props.id = 'xpacote'
    if (!props.label) props.label = 'xpacote'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.creationFunction = creationFunction
    props.DialogSelectProps.creationPermission = verificarAutorizacao([PA.PODE_GERENCIAR_PACOTE])
    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Pacote...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'erp/pacotes.buscar'
    props.DialogSelectProps.searchParamName = 'nome'
    props.DialogSelectProps.generateItemData = (pacote) => {
      return {
        value: pacote.id,
        label: pacote.nome,
        primary: pacote.nome,
        pacote: pacote
      }
    }

    props.DialogSelectProps.autoSelectDefinitons = [
      {
        eventName: 'ManipulacaoPacote',
        itemPropName: 'pacote'
      }
    ]

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum pacote foi encontrado',
        textButton: 'Cadastrar pacote',
        creationFunction: creationFunction
      }
    }

    return <InputDialogSelect {...props} />
  }
}

PacoteInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PacoteInputDialogSelect)
