import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import EventIcon from '@material-ui/icons/Event'
import LabelIcon from '@material-ui/icons/Label'
import PersonIcon from '@material-ui/icons/Person'
import ScheduleIcon from '@material-ui/icons/Schedule'
import moment from 'moment'
import ExibicaoConflitoHorariosDialog from 'pages/erp/painel/atendimento/ExibicaoConflitoHorariosDialog'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputHorario from 'support/components/input/InputHorario'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import Repeticao from 'support/components/repeticao/Repeticao'
import { converterDataIntParaDate } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class BloqueioHorarioDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      data: this.props.data,
      bloqueioHorario: null,
      title: 'Bloqueio de horário',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.dataFunctionsMap = {}
    this.colaboradorFunctionsMap = {}
    this.horarioInicioFunctionsMap = {}
    this.horarioTerminoFunctionsMap = {}
    this.inputDescricao = React.createRef()
    this.repeticaoFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/colaboradores.buscarDadosBloqueioPrePersistencia',
      params: {
        idBloqueioHorario: this.props.idBloqueioHorario
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const bloqueioHorario = response.data.bloqueioHorario
        if (bloqueioHorario) {
          this.setState({
            bloqueioHorario: bloqueioHorario,
            data: converterDataIntParaDate(bloqueioHorario.data)
          })
        }
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  getDados = () => {
    let dados = {}

    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.horarioInicio = this.horarioInicioFunctionsMap.getHorarioAsInt()
    dados.horarioTermino = this.horarioTerminoFunctionsMap.getHorarioAsInt()
    dados.descricao = this.inputDescricao.current.value

    if (this.props.idBloqueioHorario) {
      dados.id = this.props.idBloqueioHorario
    }

    dados.colaborador = {
      id: this.colaboradorFunctionsMap.getValue()
    }

    dados.repeticao = this.repeticaoFunctionsMap.getValues()

    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = (ignorarConflitosHorarios, preDefinedPersistOptions) => {
    this.repeticaoFunctionsMap.exibirSeNecessarioOpcoesPersistencia((persistOptions) => {
      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      const dados = this.getDados()
      dados.ignorarConflitosHorarios = ignorarConflitosHorarios
      dados.repeticao = { ...dados.repeticao, ...persistOptions }
      postAPI({
        url: 'erp/colaboradores.persistirBloqueioHorario',
        data: dados,
        requerAutorizacao: true,
        onSuccess: (response) => {
          EventsManager.pub('ManipulacaoBloqueioHorario', {
            action: this.props.idBloqueioHorario ? 'update' : 'create',
            bloqueioHorario: response.data.bloqueioHorario,
            bloqueioHorarioParaCalendario: response.data.bloqueioHorarioParaCalendario
          })
          if (this.props.parent) {
            this.props.setShouldCloseParent(true)
          }
          this.props.handleCloseDialog()
        },
        onError: (response) => {
          if (response.code === 400 && response.data.errors.conflitosHorarios) {
            openBackableDialog(ExibicaoConflitoHorariosDialog, {
              descricao: 'O sistema encontrou um ou mais conflitos de horários para este bloqueio de horário:',
              conflitosHorarios: response.data.errors.conflitosHorarios,
              onConfirm: () => {
                this.salvar(true, persistOptions)
              }
            })
            this.setState({ ajaxing: false, errorsMap: createErrorsMap() })
          } else {
            this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
            focusFirstElementWithError('formBloqueioHorario')
          }
        }
      })
    }, preDefinedPersistOptions)
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, colaboradorPreSelecionado, dataPrePreenchida, horarioInicioPrePreenchido, horarioTerminoPrePreenchido } = this.props
    const { data, bloqueioHorario, ajaxing, errorsMap } = this.state
    let dataDefault = data
    let { colaborador, descricao, horarioInicio, horarioTermino, repeticao } = bloqueioHorario ? bloqueioHorario : {}
    let horarioInicioDefaultValue = undefined
    let horarioTerminoDefaultValue = undefined
    if (horarioInicioPrePreenchido) {
      horarioInicioDefaultValue = horarioInicioPrePreenchido
    }

    if (!bloqueioHorario) {
      if (colaboradorPreSelecionado) {
        colaborador = colaboradorPreSelecionado
      } else {
        const colaboradorSessao = getSessaoPainel().colaborador
        if (colaboradorSessao && colaboradorSessao.realizaAtendimentos) {
          colaborador = colaboradorSessao
        }
      }
    } else {
      if (dataPrePreenchida) {
        dataDefault = converterDataIntParaDate(dataPrePreenchida)
      }

      if (horarioInicio) {
        horarioInicioDefaultValue = horarioInicio
      }

      if (horarioTerminoPrePreenchido) {
        horarioTerminoDefaultValue = horarioTerminoPrePreenchido
      } else if (horarioTermino) {
        horarioTerminoDefaultValue = horarioTermino
      }
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={520} pageType="basicForm" ajaxing={ajaxing} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formVendaPacote" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputHorizontalContainer
                style={{ marginTop: -10 }}
                icon={<PersonIcon />}
                inputTexts={[
                  {
                    input: (
                      <ColaboradorInputDialogSelect
                        context="atendimento"
                        disabled={ajaxing}
                        defaultValue={colaborador}
                        functionsMap={this.colaboradorFunctionsMap}
                        placeholder="Profissional"
                        customVariant="outlined-small"
                        shrink={false}
                        errorMessage={errorsMap('colaborador')}
                        onChange={(value) => {
                          if (value) {
                            this.setState({ colaborador: value.colaborador })
                          } else {
                            this.setState({ colaborador: null })
                          }
                        }}
                        style={{ marginTop: 0 }}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                style={{ marginTop: 10 }}
                icon={<EventIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputData
                        disabled={ajaxing}
                        functionsMap={this.dataFunctionsMap}
                        keyboard={true}
                        id="xdata"
                        name="xdata"
                        errorMessage={errorsMap('data')}
                        defaultValue={dataDefault}
                        onChange={(data) => {
                          this.setState({ data: data })
                        }}
                        placeholder={'Data'}
                        customVariant="outlined-small"
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                style={{ marginTop: 6 }}
                icon={<ScheduleIcon />}
                inputTexts={[
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <InputHorario
                        disabled={ajaxing}
                        functionsMap={this.horarioInicioFunctionsMap}
                        keyboard={true}
                        idname="xhorarioinicio"
                        errorMessage={errorsMap('horarioInicio')}
                        style={{ marginTop: 0 }}
                        defaultValue={horarioInicioDefaultValue}
                        placeholder="Horário Início"
                        customVariant="outlined-small"
                        shrink={false}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <InputHorario
                        disabled={ajaxing}
                        functionsMap={this.horarioTerminoFunctionsMap}
                        keyboard={true}
                        idname="xhorariotermino"
                        errorMessage={errorsMap('horarioTermino')}
                        style={{ marginTop: 0 }}
                        defaultValue={horarioTerminoDefaultValue}
                        placeholder="Horário Término"
                        customVariant="outlined-small"
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                style={{ marginTop: 6 }}
                icon={<LabelIcon />}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        disabled={ajaxing}
                        id="xdescricao"
                        name="xdescricao"
                        multiline={true}
                        defaultValue={descricao}
                        errorMessage={errorsMap('descricao')}
                        customVariant="outlined-small"
                        shrink={false}
                        placeholder="Descrição"
                        inputRef={this.inputDescricao}
                      />
                    )
                  }
                ]}
              />

              <Repeticao
                showLabelIcons={true}
                title="Repetir Bloqueio de Horário"
                prefixoEntidade="de"
                nomeEntidade="Bloqueio de Horário"
                tituloAlteracaoIndividual="Alterar apenas este bloqueio"
                descricaoAlteracaoIndividual="As alterações realizadas neste bloqueio não serão replicadas para os próximos bloqueios da repetição."
                tituloAlteracaoLote="Replicar alterações para os próximos bloqueios"
                descricaoAlteracaoLote="As alterações deste bloqueio serão aplicadas também para todos os próximos bloqueios da repetição."
                defaultValue={repeticao}
                customVariant="outlined-small"
                shrink={false}
                dataInicial={data ? moment(data) : null}
                disabled={ajaxing}
                errorsMap={errorsMap}
                functionsMap={this.repeticaoFunctionsMap}
                hasEntityChange={() => {
                  const { bloqueioHorario } = this.state
                  const novoBloqueio = this.getDados()

                  if (
                    this.props.idBloqueioHorario &&
                    bloqueioHorario.descricao === novoBloqueio.descricao &&
                    bloqueioHorario.horarioInicio === novoBloqueio.horarioInicio &&
                    bloqueioHorario.horarioTermino === novoBloqueio.horarioTermino &&
                    bloqueioHorario.data === novoBloqueio.data
                  ) {
                    return false
                  } else {
                    return true
                  }
                }}
              />

              <FormUtil.HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button
                    disabled={this.state.ajaxing}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.salvar()
                    }}
                  >
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

BloqueioHorarioDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BloqueioHorarioDialogPage)
