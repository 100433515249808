import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

//import IndexPageTemp from 'pages/portal/index/IndexPageTemp';
import BuscaPage from 'pages/portal/busca/BuscaPage'
import IndexPage from 'pages/portal/index/IndexPage'
import NegocioPage from 'pages/portal/negocio/NegocioPage'
import PainelPortal from 'pages/portal/painel/PainelPortal'
import PortalHelmet from 'pages/portal/PortalHelmet'
import { ROUTE_PORTAL_MAIN, ROUTE_PORTAL_MAIN_AS_PREFIX, ROUTE_PORTAL_PANEL } from 'pages/RouteMap'
import RouterContent from 'support/components/router/RouterContent'

class PortalPagesManager extends Component {
  render() {
    if (!this.props.portalCarregado) {
      return []
    }

    return (
      <React.Fragment>
        <PortalHelmet />
        <Switch>
          {/*<Route exact path={ROUTE_PORTAL_MAIN} render={props => <RouterContent component={IndexPageTemp} sendPageViewGA={true} {...this.props} {...props} />} />*/}
          <Route exact path={ROUTE_PORTAL_MAIN} render={(props) => <RouterContent component={IndexPage} sendPageViewGA={true} {...this.props} {...props} />} />
          <Route path={ROUTE_PORTAL_MAIN_AS_PREFIX + '/index-app'} render={(props) => <RouterContent component={IndexPage} sendPageViewGA={true} {...this.props} {...props} />} />
          <Route
            path={ROUTE_PORTAL_MAIN_AS_PREFIX + '/busca/:filtros?'}
            render={(props) => <RouterContent component={BuscaPage} sendPageViewGA={true} {...this.props} {...props} />}
          />
          <Route path={ROUTE_PORTAL_PANEL} render={(props) => <RouterContent {...this.props} {...props} component={PainelPortal} />} />
          <Route
            path={'/agendamento/:regiao?/:nomeNegocio?/:idNegocio/:hash'}
            render={(props) => <RouterContent component={NegocioPage} sendPageViewGA={true} {...this.props} {...props} />}
          />
          <Route path={'/:idNegocio/:hash'} render={(props) => <RouterContent component={NegocioPage} sendPageViewGA={true} {...this.props} {...props} />} />
          <Route path={'/:sufixoUrlPortal'} render={(props) => <RouterContent component={NegocioPage} sendPageViewGA={true} {...this.props} {...props} />} />
        </Switch>

        {/* Adicionar snackbars antes do DialogsManager. Se o DialogsManager é adicionado antes, por algum motivo (em algumas situações) os snackbars sofrem o unmount e não aparecem mais na aplicação
				<SnackBarSuccess />
				<TabSaveSnackBar />
				<SnackBarError />

				<DialogsManager />*/}
      </React.Fragment>
    )
  }
}

export default PortalPagesManager
