import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DetalhesGastosProdutoDialogPage from 'pages/erp/painel/relatorio/DetalhesGastosProdutoDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  colunaQuantidade: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  colunaIcone: {
    maxWidth: 66,
    minWidth: 66
  }
})

class DetalhesResumoGastosProdutosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      idColaborador: this.props.colaborador.id
    }
  }

  abrirDetalhes = (item) => {
    const { dataInicial, dataFinal } = this.props

    openBackableDialog(DetalhesGastosProdutoDialogPage, {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      produto: item.produto
    })
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.descricao = item.produto.nome
    content.valor = formatarValorMonetario(item.valorCustoTotal, { prefix: false })

    content.quantidadeUso = (
      <React.Fragment>
        {item.produto.unidadeMedidaEmbalagem && (
          <React.Fragment>
            <span style={{ fontSize: 14 }}>{item.quantidadeUsoUnidadePersonalizada + ' ' + item.produto.unidadeMedidaEmbalagem}</span>
            <br />
          </React.Fragment>
        )}
        <span style={{ fontSize: 14 }}>{item.quantidadeUsoQuantidade} unid.</span>
      </React.Fragment>
    )

    if (size === 'small') {
      content.abrirDetalhes = (
        <IconButton
          style={{ marginTop: -10, marginBottom: -6, padding: 8 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    } else {
      content.abrirDetalhes = (
        <IconButton
          style={{ marginRight: 0, padding: 6 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    }

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, dataInicial, dataFinal } = this.props
    const { ajaxing } = this.state

    const descricaoFiltroPeriodo = dataInicial.format('DD/MM/YYYY') + ' - ' + dataFinal.format('DD/MM/YYYY')

    return (
      <DialogPage {...dialogProps} align="center" title={this.props.colaborador.apelido} ajaxing={ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Lista de uso de produtos</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              itemsPerRequest={20}
              loaderFunctionsMap={this.loaderFunctionsMap}
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarResumoGastosProdutos"
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              onClickRow={(item, event) => {
                this.abrirDetalhes(item)
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Produto', props: { xs: true } },
                  { label: 'Quantidade', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                  { label: 'Valor Gasto (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.descricao, content.quantidadeUso, content.valor, content.abrirDetalhes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 100,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container style={{ minWidth: 0 }} alignItems="center">
                          <Grid item xs style={{ minWidth: 0 }}>
                            <Typography variant="body2" noWrap={true}>
                              <b>{content.descricao}</b>
                            </Typography>
                            <Typography variant="body2" noWrap={true}>
                              {content.quantidadeUso}
                            </Typography>
                            <Typography variant="body2" noWrap={true}>
                              Total: {content.valor}
                            </Typography>
                          </Grid>
                          <Grid item>{content.abrirDetalhes}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum custo foi encontrado.'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

DetalhesResumoGastosProdutosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesResumoGastosProdutosDialogPage)
