import { useState } from 'react'

import { postAPI } from 'support/components/api/ApiClient'
import { ErrorResponseType, SuccessResponseType } from 'support/components/api/types'

import { formatDiscountPlanIdToName } from './DiscountCouponUtils'
import { DiscountCouponResponse } from './types'

type ValidateCouponParams = {
  coupon: string
  callback: ({ coupon, validCoupon }: { coupon?: string; validCoupon?: DiscountCouponResponse }) => void
  selectedType: string
  negocio: { id: number }
}

type DiscountCouponErrorResponse = {
  errors: { coupon: string | undefined }
  userMessage: string
}

const INTERNAL_SERVER_ERROR_CODE = 500

function useDiscountCoupon() {
  const [isLoading, setIsLoading] = useState(false)
  const [validDiscountCoupon, setValidDiscountCoupon] = useState<DiscountCouponResponse>()
  const [validationError, setValidationError] = useState<string>()

  function validateCoupon({ coupon, callback, selectedType, negocio }: ValidateCouponParams) {
    setIsLoading(true)
    postAPI({
      url: 'erp/discountCoupon.validate',
      contextoUsuario: 'erp',
      data: { coupon, plan: formatDiscountPlanIdToName(negocio?.id), period: selectedType?.toUpperCase() },
      requerAutorizacao: true,
      onPostFinal: () => setIsLoading(false),
      onSuccess: (response: SuccessResponseType) => {
        const validCoupon: DiscountCouponResponse = response.data as DiscountCouponResponse

        if (!validCoupon['is-valid']) {
          callback?.({})
          setValidDiscountCoupon(undefined)
          setValidationError(validCoupon.description)
          return
        }

        setValidDiscountCoupon(validCoupon)
        callback?.({
          coupon,
          validCoupon
        })
        setValidationError(undefined)
      },
      onError: (response: ErrorResponseType) => {
        if (response.code < INTERNAL_SERVER_ERROR_CODE) {
          const { errors } = response.data as DiscountCouponErrorResponse
          setValidationError(errors.coupon)
          setValidDiscountCoupon(undefined)
          callback?.({})
        }
      }
    })
  }

  return {
    isLoading,
    validDiscountCoupon,
    validateCoupon,
    validationError
  }
}

export default useDiscountCoupon
