import React, { Component } from 'react'

import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import EventIcon from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import PolicyIcon from '@material-ui/icons/PolicyOutlined'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_PORTAL_MAIN_AS_PREFIX } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import Drawer from 'support/components/panel/Drawer'
import DrawerMenuDivider from 'support/components/panel/DrawerMenuDivider'
import DrawerMenuItem from 'support/components/panel/DrawerMenuItem'
import DrawerMenuItemIcon from 'support/components/panel/DrawerMenuItemIcon'
import DrawerMenuItemText from 'support/components/panel/DrawerMenuItemText'
import SimpleDrawerMenuItem from 'support/components/panel/SimpleDrawerMenuItem'

const styles = (theme) => ({
  singleLine: theme.text.singleLine(),
  drawerUserColor: {
    color: '#333'
  },
  drawerUserName: {
    fontSize: '16px',
    fontWeight: 500
  }
})

class DrawerPortal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, ...others } = this.props
    const { isMenuRetracted } = this.props

    let menuItems = []
    let menuGroupItems = []

    menuItems.push(menuGroupItems)

    menuGroupItems.push(
      <SimpleDrawerMenuItem
        key="agendamentos"
        rota={'/agendamentos'}
        regexUrl="agendamentos"
        isMenuRetracted={isMenuRetracted}
        icon={{ component: EventIcon }}
        primary="Meus Agendamentos"
      />
    )

    menuGroupItems.push(
      <DrawerMenuItem
        key="busca"
        alwaysClickable={true}
        onClick={() => {
          EventsManager.pub('RoutePush', {
            path: ROUTE_PORTAL_MAIN_AS_PREFIX + '/busca'
          })
        }}
      >
        <DrawerMenuItemIcon
          isMenuRetracted={isMenuRetracted}
          icon={{
            component: SearchIcon
          }}
        />
        <DrawerMenuItemText
          isMenuRetracted={isMenuRetracted}
          extraMarginRight={true}
          primary={'Buscar'}
          classes={{
            primary: classNames(classes.singleLine),
            secondary: classNames(classes.singleLine)
          }}
        />
      </DrawerMenuItem>
    )

    menuGroupItems.push(
      <SimpleDrawerMenuItem
        key="favoritos"
        rota={'/favoritos'}
        regexUrl="favoritos"
        isMenuRetracted={isMenuRetracted}
        icon={{ component: FavoriteBorderIcon }}
        primary="Favoritos"
      />
    )

    menuGroupItems = []
    menuItems.push(menuGroupItems)

    menuGroupItems.push(
      <SimpleDrawerMenuItem
        key="sobresalao99"
        rota={'/sobresalao99'}
        regexUrl="sobresalao99"
        isMenuRetracted={isMenuRetracted}
        icon={{ component: InfoOutlinedIcon }}
        primary="Sobre o Salão99"
      />
    )

    menuGroupItems.push(
      <SimpleDrawerMenuItem
        key="termoseprivacidade"
        rota={'/termoseprivacidade'}
        regexUrl="termoseprivacidade"
        isMenuRetracted={isMenuRetracted}
        icon={{ component: PolicyIcon }}
        primary="Termos e Privacidade"
      />
    )

    menuGroupItems.push(
      <DrawerMenuItem
        key="sair"
        alwaysClickable={true}
        onClick={() => {
          openBackableDialog(MessageDialog, {
            disablePortal: false,
            text: <span>Deseja realmente sair da sua conta?</span>,
            actionButtons: [
              {
                label: 'Voltar'
              },
              {
                label: 'Confirmar',
                onClosedCallbackFunction: () => {
                  this.props.handleLogout()
                }
              }
            ]
          })
        }}
      >
        <DrawerMenuItemIcon
          isMenuRetracted={isMenuRetracted}
          icon={{
            component: ExitToAppIcon
          }}
        />
        <DrawerMenuItemText
          isMenuRetracted={isMenuRetracted}
          extraMarginRight={true}
          primary={'Logout'}
          classes={{
            primary: classNames(classes.singleLine),
            secondary: classNames(classes.singleLine)
          }}
        />
      </DrawerMenuItem>
    )

    menuItems = menuItems.filter((menuGroupItems) => menuGroupItems.length > 0)

    return (
      <Drawer
        {...others}
        headerImage={<img src={logo} width="84" alt="Salão99" style={{ marginLeft: 0, marginTop: 3 }} />}
        desktopDrawerToggleDisabled={true}
        fixedTopContent={
          <List>
            <DrawerMenuItem rota={'/conta'} regexUrl={'conta'}>
              <DrawerMenuItemIcon
                isMenuRetracted={isMenuRetracted}
                icon={{
                  component: AccountCircleIcon
                }}
              />
              <DrawerMenuItemText
                isMenuRetracted={isMenuRetracted}
                extraMarginRight={true}
                primary={this.props.usuarioSessao.nome}
                classes={{
                  primary: classNames(classes.singleLine),
                  secondary: classNames(classes.singleLine)
                }}
              />
            </DrawerMenuItem>
          </List>
        }
      >
        {menuItems.length === 0 && <DrawerMenuDivider />}

        {menuItems.length > 0 &&
          menuItems.map((menuGroupItems, index) => (
            <React.Fragment key={index}>
              <List>{menuGroupItems}</List>
              <DrawerMenuDivider />
            </React.Fragment>
          ))}
      </Drawer>
    )
  }
}

DrawerPortal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DrawerPortal)
