import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import InputServico from 'pages/erp/painel/servico/input/InputServico'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import PacoteTipoLimiteVendaEnum from 'support/domain/pacote/PacoteTipoLimiteVendaEnum'
import PacoteTipoValidadeEnum from 'support/domain/pacote/PacoteTipoValidadeEnum'
import FormUtil from 'support/util/FormUtil'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputMoneyFormat, InputNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  textPrimary: {
    color: theme.palette.text.primary
  },
  textSecondary: {
    color: theme.palette.text.secondary
  }
})

class PacoteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      pacote: {
        tipoValidade: PacoteTipoValidadeEnum.ILIMITADA,
        tipoLimiteVenda: PacoteTipoLimiteVendaEnum.ILIMITADA,
        itens: []
      },
      title: !this.props.idPacote ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
    this.inputPreco = React.createRef()
    this.inputTipoValidade = React.createRef()
    this.inputTipoLimiteVenda = React.createRef()
    this.inputPrazoLimiteVendaPorQuantidade = React.createRef()
    this.prazoLimiteVendaPorDataFunctionsMap = {}
    this.inputPrazoValidade = React.createRef()
    this.inputComissao = {}
    this.keyGenerator = 0
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idPacote) {
      this.adicionarItemPacote()
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/pacotes.buscarPorId',
        params: {
          id: this.props.idPacote
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          response.data.itens.sort(function (a, b) {
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          })

          for (let item of response.data.itens) {
            item.key = this.generateKeyId()
            item.inputServico = {}
            item.inputValorReferencia = React.createRef()
            item.inputQuantidade = React.createRef()
          }
          this.setState((state) => ({
            pacote: response.data
          }))

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PACOTE])) {
      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      this.persistirPacote()
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  persistirPacote() {
    postAPI({
      url: 'erp/pacotes.persistir',
      data: this.getDadosPacote(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoPacote', {
          pacote: response.data
        })
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formPacote')
      }
    })
  }

  getKeyErrorsMapPrefix(index) {
    return 'itens[' + index + ']'
  }

  getDadosPacote = () => {
    let pacote = {
      itens: []
    }

    if (this.props.idPacote) {
      pacote.id = this.props.idPacote
    }
    pacote.nome = this.inputNome.current.value

    pacote.prazoValidade = null
    if (this.inputPrazoValidade && this.inputPrazoValidade.current) {
      pacote.prazoValidade = this.inputPrazoValidade.current.value
    }

    pacote.prazoLimiteVendaPorQuantidade = null
    if (this.inputPrazoLimiteVendaPorQuantidade && this.inputPrazoLimiteVendaPorQuantidade.current) {
      pacote.prazoLimiteVendaPorQuantidade = this.inputPrazoLimiteVendaPorQuantidade.current.value
    }

    pacote.tipoValidade = this.state.pacote.tipoValidade
    pacote.tipoLimiteVenda = this.state.pacote.tipoLimiteVenda

    pacote.prazoLimiteVendaPorData = null
    if (pacote.tipoLimiteVenda.id === PacoteTipoLimiteVendaEnum.POR_DATA.id && Object.entries(this.prazoLimiteVendaPorDataFunctionsMap).length !== 0) {
      pacote.prazoLimiteVendaPorData = this.prazoLimiteVendaPorDataFunctionsMap.getDataAsInt()
    }

    pacote.preco = FormUtil.convertStringToNumber(this.inputPreco.current.value)
    for (let item of this.state.pacote.itens) {
      const itemParaSalvar = {
        valorReferencia: FormUtil.convertStringToNumber(item.inputValorReferencia.current.value),
        quantidade: FormUtil.convertStringToNumber(item.inputQuantidade.current.value)
      }
      if (item.id) {
        itemParaSalvar.id = item.id
      }
      if (item.inputServico.getValue() && item.inputServico.getValue().servico && item.inputServico.getValue().servico.id) {
        itemParaSalvar.servico = { id: item.inputServico.getValue().servico.id }
        if (item.inputServico.getValue().variacao && item.inputServico.getValue().variacao.id) {
          itemParaSalvar.variacaoServico = { id: item.inputServico.getValue().variacao.id }
        }
      }
      pacote.itens.push(itemParaSalvar)
    }
    //pacote.comissaoVenda =  FormUtil.convertStringToNumber(this.inputComissao.getValor());
    //pacote.tipoCalculoComissao = this.inputComissao.getTipoCalculo();
    return pacote
  }

  removerItemPacote = (index) => {
    const pacote = this.state.pacote
    pacote.itens = this.state.pacote.itens.slice()
    pacote.itens.splice(index, 1)
    if (pacote.itens.length === 0) {
      this.adicionarItemPacote()
    }

    this.setState({ pacote: pacote })
  }

  adicionarItemPacote = () => {
    const pacote = this.state.pacote
    pacote.itens = this.state.pacote.itens.slice()
    let pacoteItem = {
      key: this.generateKeyId(),
      inputServico: {},
      inputQuantidade: React.createRef(),
      inputValorReferencia: React.createRef()
    }

    pacote.itens.push(pacoteItem)
    this.setState({ pacote: pacote })
  }

  generateKeyId = () => {
    return ++this.keyGenerator
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state
    let { nome, preco, tipoValidade, prazoValidade /*comissaoVenda, tipoCalculoComissaoVenda, tipoLimiteVenda, prazoLimiteVendaPorQuantidade, prazoLimiteVendaPorData*/ } =
      this.state.pacote

    if (tipoValidade) {
      tipoValidade = PacoteTipoValidadeEnum.getById(tipoValidade.id)
    }

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={450}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formPacote" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                defaultValue={nomeDefaultValue}
                shrink={false}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome do Pacote"
                customVariant="outlined-small"
                inputRef={this.inputNome}
                onChange={this.handlerChangeQuantidade}
              />

              <InputText
                marginTop={true}
                shrink={false}
                idname="xpreco"
                label="Preço Pacote (R$)"
                customVariant="outlined-small"
                defaultValue={preco}
                inputComponent={InputMoneyFormat}
                errorMessage={errorsMap('preco')}
                inputRef={this.inputPreco}
              />

              {/*
							<InputPorcentagemValor
								marginTop={true}
								idname="xcomissao"
								label="Comissão de venda"
								defaultValor={comissaoVenda}
								defaultTipoCalculo={tipoCalculoComissaoVenda}
								functionsMap={this.inputComissao}
							/>
							*/}

              <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                <Grid item xs>
                  <InputSelect
                    marginTop={true}
                    style={{ marginLeft: 2 }}
                    value={tipoValidade.id}
                    customVariant="naked"
                    nakedLeftPadding={false}
                    fullWidth={false}
                    onChange={(event) => {
                      const pacote = this.state.pacote
                      pacote.tipoValidade = PacoteTipoValidadeEnum.getById(event.target.value)
                      this.setState({
                        pacote: pacote
                      })
                    }}
                    inputRef={this.inputTipoValidade}
                  >
                    {PacoteTipoValidadeEnum.values().map((tipoValidade) => (
                      <MenuItem key={tipoValidade.id} value={tipoValidade.id}>
                        {tipoValidade.descricao}
                      </MenuItem>
                    ))}
                  </InputSelect>

                  {tipoValidade.id !== PacoteTipoValidadeEnum.ILIMITADA.id && (
                    <InputText
                      marginTop={true}
                      idname="xvalidade"
                      shrink={false}
                      customVariant="outlined-small"
                      label={tipoValidade.periodo + ' para vencer'}
                      defaultValue={prazoValidade}
                      inputComponent={InputNumberFormat}
                      errorMessage={errorsMap('prazoValidade')}
                      helpMessage={'Informe a quantidade de ' + tipoValidade.periodo.toLowerCase() + ' que o cliente tem para utilizar os itens do pacote após a data da compra.'}
                      inputRef={this.inputPrazoValidade}
                    />
                  )}
                </Grid>
              </Grid>

              {/*<Grid
								container
								direction="column"
								alignItems="flex-start"
							>
								<InputSelect
									marginTop={true}
									value={tipoLimiteVenda.id}
									customVariant="naked"
									nakedLeftPadding={false}
									fullWidth={false}
									onChange={(event) => {
										const pacote = this.state.pacote;
										pacote.tipoLimiteVenda = PacoteTipoLimiteVendaEnum.getById(event.target.value);
										this.setState({
											pacote : pacote
										});
									}}
									inputRef={this.inputTipoLimiteVenda}
								>
									{PacoteTipoLimiteVendaEnum.values().map(tipoValidade => (
										<MenuItem key={tipoValidade.id} value={tipoValidade.id}>
											{tipoValidade.descricao}
										</MenuItem>
									))}
								</InputSelect>

								{
									tipoLimiteVenda.id === PacoteTipoLimiteVendaEnum.POR_QUANTIDADE.id &&
									<InputText
										marginTop={false}
										fullWidth={true}
										defaultValue={prazoLimiteVendaPorQuantidade}
										inputComponent={InputNumberFormat}
										inputProps={{
											minValue:1,
											maxValue:30000
										}}
										idname="xvalidade"
										errorMessage={errorsMap("prazoLimiteVendaQuantidade")}
										helpMessage={"Informe a quantidade de pacotes que poderão ser vendidas."}
										inputRef={this.inputPrazoLimiteVendaPorQuantidade}
									/>
								}

								{
									tipoLimiteVenda.id === PacoteTipoLimiteVendaEnum.POR_DATA.id &&
									<InputData
										clearable={false}
										keyboard={true}
										fullWidth={true}
										idname="xvalidade"
										defaultValue={prazoLimiteVendaPorData}
										label={tipoLimiteVenda.descricao}
										errorMessage={errorsMap("prazoLimiteVendaData")}
										placeholder={"dd/mm/yyyy"}
										customVariant="naked"
										helpMessage={"Informe a data limite que os pacotes poderão ser vendidos."}
										functionsMap={this.prazoLimiteVendaPorDataFunctionsMap}
									/>
								}
							</Grid>
*/}
              <SectionSeparator icon={<ShoppingBasketIcon />} title="Itens do Pacote" />

              {this.state.pacote.itens &&
                this.state.pacote.itens.map((itemPacote, index) => {
                  let errorsMapNome = errorsMap(this.getKeyErrorsMapPrefix(index) + '.nome')
                  let errorsMapValorUnitario = errorsMap(this.getKeyErrorsMapPrefix(index) + '.valorUnitario')
                  let errorsMapQuant = errorsMap(this.getKeyErrorsMapPrefix(index) + '.quant')

                  return (
                    <Grid key={itemPacote.key} container alignItems="center" style={{ position: 'relative', marginTop: index === 0 ? 40 : 32 }}>
                      <Grid item style={{ width: 5, backgroundColor: '#d8d8d8', height: '100%', position: 'absolute', borderRadius: 1 }}></Grid>

                      <Grid item xs={true} style={{ marginLeft: 14 }}>
                        <Grid container justify="center" alignItems="flex-start" spacing={1}>
                          <Grid item xs={12}>
                            <InputServico
                              style={{ marginTop: 0 }}
                              shrink={false}
                              customVariant="outlined-small"
                              label="Escolha o Serviço"
                              selectionType="servico|variacao"
                              disabled={this.state.ajaxing}
                              defaultValue={{ servico: itemPacote.servico, variacao: itemPacote.variacaoServico }}
                              functionsMap={itemPacote.inputServico}
                              errorMessage={errorsMapNome}
                            />
                          </Grid>

                          <Grid item xs={true}>
                            <InputText
                              style={{ marginTop: 6 }}
                              shrink={false}
                              customVariant="outlined-small"
                              idname="xvalorReferencia"
                              defaultValue={itemPacote.valorReferencia}
                              disabled={this.state.ajaxing}
                              label="Valor Unitário (R$)"
                              inputComponent={InputMoneyFormat}
                              inputRef={itemPacote.inputValorReferencia}
                              errorMessage={errorsMapValorUnitario}
                              endAdornment={
                                <HelpOutlineIcon
                                  style={{ opacity: 0.5, padding: 8, margin: '-8px 0px -8px -8px', cursor: 'pointer' }}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    openBackableDialog(MessageDialog, {
                                      text: (
                                        <span className={classes.textPrimary}>
                                          O Valor Unitário é utilizado para modificar o preço padrão unitário do serviço dentro de um pacote. Exemplo:
                                          <br />
                                          <br />
                                          <span className={classes.textSecondary} style={{ fontSize: 14 }}>
                                            - Serviço de manicure custa por padrão R$ 30,00;
                                            <br />- O pacote com 10 serviços de manicure custa R$ 250,00;
                                          </span>
                                          <br />
                                          <br />
                                          Neste caso, no pacote com 10 serviços de manicure, cada serviço custa R$ 25,00. Então, para configurar esse pacote, preencha:
                                          <br />
                                          <br />
                                          <span className={classes.textSecondary} style={{ fontSize: 14 }}>
                                            <b>Preço do pacote:</b> R$ 250,00
                                            <br />
                                            <Grid container style={{ marginTop: 4 }}>
                                              <Grid item>
                                                <div style={{ height: '100%', width: 4, backgroundColor: '#aaa', marginRight: 6 }}></div>
                                              </Grid>
                                              <Grid item xs>
                                                <b>Serviço:</b> Manicure
                                                <br />
                                                <b>Preço Unitário:</b> R$ 25,00 <b>Qtd:</b> 10
                                              </Grid>
                                            </Grid>
                                          </span>
                                        </span>
                                      )
                                    })
                                  }}
                                />
                              }
                              staticHelpMessage={
                                <span>
                                  Para o sistema considerar o valor padrão deste serviço, deixe o <b>Valor Unitário</b> em branco
                                </span>
                              }
                            />
                          </Grid>

                          <Grid item style={{ width: 60 }}>
                            <InputText
                              style={{ marginTop: 6 }}
                              shrink={false}
                              customVariant="outlined-small"
                              id="xquantidade"
                              name="xquantidade"
                              defaultValue={itemPacote.quantidade ? itemPacote.quantidade : ''}
                              errorMessage={errorsMapQuant}
                              disabled={this.state.ajaxing}
                              inputComponent={InputNumberFormat}
                              label="Qtd."
                              inputRef={itemPacote.inputQuantidade}
                            />
                          </Grid>

                          {!(this.state.pacote && this.state.pacote.itens.length === 1 && (!itemPacote || !itemPacote.servico || itemPacote.servico.nome === undefined)) && (
                            <Grid item style={{ marginRight: -8 }}>
                              <IconButton
                                style={{ padding: 4, marginTop: 10 }}
                                disabled={
                                  this.state.pacote && this.state.pacote.itens.length === 1 && (!itemPacote || !itemPacote.servico || itemPacote.servico.nome === undefined)
                                }
                                onClick={() => {
                                  this.removerItemPacote(index)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}

              <div style={{ textAlign: 'right', marginTop: 20, marginRight: -8 }}>
                <Button color="secondary" type="button" onClick={this.adicionarItemPacote}>
                  Adicionar mais itens
                </Button>
              </div>

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

PacoteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PacoteDialogPage)
