import React, { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ConfiguracoesTemaPaginaExemploConteudo from 'pages/erp/painel/configuracoes/tema/ConfiguracoesTemaPaginaExemploConteudo'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import { TemaEnum } from 'support/style/Tema'
import { createS99Theme } from 'support/style/ThemeManager.js'

const styles = (theme) => ({})

class ConfiguracoesTemaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Temas & Cores',
      ajaxing: false
    }

    this.temaFunctionMap = {}
  }

  componentDidMount = () => {
    this.mounted = true
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    const params = {}

    if (this.props.idNegocio) {
      params.idNegocio = this.props.idNegocio
    }

    getAPI({
      url: 'erp/negocios.buscarConfiguracoesGerais',
      params: params,
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          tema: TemaEnum.getById(response.data.temaAplicacao.id)
        })
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState({
      ajaxing: true
    })

    postAPI({
      url: 'erp/negocios.alterarConfiguracoesGerais',
      data: {
        idNegocio: this.props.idNegocio ? this.props.idNegocio : null,
        temaAplicacao: TemaEnum.getById(this.temaFunctionMap.getValue())
      },
      requerAutorizacao: true,
      onSuccess: () => {
        if (this.mounted) {
          if (!this.props.idNegocio) {
            EventsManager.pub('AlterarTemaAplicacao', {
              tema: TemaEnum.getById(this.temaFunctionMap.getValue())
            })
          }
          this.setState({
            ajaxing: false
          })
        }

        //this.props.handleCloseDialog();
      },
      sendErroToGenericSnackbar: false,
      repeat: {
        stopFunction: () => {
          return !this.mounted
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    const toolbarActions = {
      actions: []
    }

    if (this.props.idNegocio) {
      toolbarActions.actions.push({
        label: 'Continuar',
        disabled: this.state.ajaxing,
        handleAction: () => {
          this.props.handleCloseDialog()
        }
      })
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={600} ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => (
            <form id="formConfiguracoesComissoes" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <div>
                <Typography variant="body1">
                  Escolha as cores do sistema para a sua empresa. Todos os colaboradores da empresa irão visualizar o as telas do sistema com mesmo padrão de cores.
                </Typography>

                <Grid container alignItems="center" style={{ marginTop: 24, marginBottom: 16 }}>
                  <Grid item>
                    <InputSelect
                      style={{ marginTop: 2, marginBottom: 2 }}
                      InputProps={{
                        style: {
                          width: 174
                        }
                      }}
                      disabled={this.state.ajaxing}
                      customVariant={'outlined-small'}
                      label="Cor do sistema"
                      shrink={false}
                      value={this.state.tema.id}
                      functionsMap={this.temaFunctionMap}
                      onChange={(event) => {
                        if (this.state.ajaxing) {
                          return
                        }

                        const id = event.target.value
                        this.setState({ tema: TemaEnum.getById(parseInt(id, 10)) })
                        this.salvar()
                      }}
                    >
                      {TemaEnum.values().map((tema) => (
                        <MenuItem key={tema.id} value={tema.id}>
                          {tema.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </Grid>
                  {this.state.ajaxing && (
                    <Grid item style={{ marginLeft: 8 }}>
                      <CircularProgress size={18} thickness={5.5} />
                    </Grid>
                  )}
                </Grid>
              </div>

              <ThemeProvider theme={createS99Theme(this.state.tema, this.props.theme)}>
                <ConfiguracoesTemaPaginaExemploConteudo />
              </ThemeProvider>
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }

  componentWillUnmount = () => {
    this.mounted = false
  }
}

ConfiguracoesTemaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ConfiguracoesTemaDialogPage)
