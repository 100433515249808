import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import RemuneracoesDetalhadasResponsiveTable from 'pages/erp/painel/remuneracao/table/RemuneracoesDetalhadasResponsiveTable'
import RemuneracoesTableFooter from 'pages/erp/painel/remuneracao/table/RemuneracoesTableFooter'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DataExporter from 'support/components/dataexporter/DataExporter'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import TipoVisualizacaoRemuneracaoEnum from 'support/domain/comissao/TipoVisualizacaoRemuneracaoEnum'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'

const styles = (theme) => ({})

class RemuneracoesDetalhesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      lista: [],
      tipo: this.props.tipo,
      ajaxing: false,
      tipoVisualizacao: !this.props.venda ? TipoVisualizacaoRemuneracaoEnum.POR_COLABORADOR : TipoVisualizacaoRemuneracaoEnum.POR_VENDAS
    }

    this.loaderFunctionsMap = {}
    this.dataExporterFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.tipo) {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/colaborador/remuneracoes.buscarDadosParaExibirRemuneracoes',
        params: {
          idColaborador: this.props.colaborador.id
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState({
            valorValesDisponiveis: response.data.valorValesDisponiveis
          })

          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    } else {
      notifyContentLoaded()
    }
  }

  getRequestParameters = () => {
    const dataPesquisaInicial = converterMomentParaDataInt(this.props.dataPesquisaInicial)
    const dataPesquisaFinal = converterMomentParaDataInt(this.props.dataPesquisaFinal)

    const idsTiposRemuneracao = []
    if (this.props.tipo) {
      idsTiposRemuneracao.push(this.props.tipo.id)
    }

    return {
      idColaborador: this.props.colaborador ? this.props.colaborador.id : null,
      dataInicial: dataPesquisaInicial,
      dataFinal: dataPesquisaFinal,
      apenasComPagamentoRegistrado: this.props.apenasComPagamentoRegistrado,
      idVenda: this.props.venda ? this.props.venda.id : null,
      idsTiposRemuneracoes: idsTiposRemuneracao.length > 0 ? idsTiposRemuneracao : null
    }
  }

  downloadRelatorio = (event) => {
    let nomeArquivo = 'Relatório de Remunerações'

    if (this.state.tipoVisualizacao.id === TipoVisualizacaoRemuneracaoEnum.POR_COLABORADOR.id) {
      nomeArquivo += ' - ' + formatarPeriodoNomeArquivo(this.props.dataPesquisaInicial, this.props.dataPesquisaFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: nomeArquivo,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'colaborador/remuneracoes.gerarListaDetalhesRemuneracoes',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { tipo, tipoVisualizacao } = this.state

    let descricaoTitulo = ''
    let descricaoSubtitulo = ''
    let descricaoResultados = ''
    let emptyListText = ''

    descricaoResultados = 'Lista detalhada de ' + (tipo ? TipoRemuneracaoEnum.getById(tipo.id).descricaoPlural.toLowerCase() : 'remunerações')
    let descricaoPeriodo = ''

    if (tipoVisualizacao && tipoVisualizacao.id === TipoVisualizacaoRemuneracaoEnum.POR_COLABORADOR.id) {
      if (this.props.dataPesquisaInicial.isSame(this.props.dataPesquisaFinal, 'day')) {
        descricaoSubtitulo = 'Remunerações de ' + this.props.dataPesquisaFinal.format('DD/MM/YYYY')
        descricaoPeriodo = (
          <React.Fragment>
            no dia <b>{this.props.dataPesquisaFinal.format('DD/MM/YYYY')}</b>
          </React.Fragment>
        )
      } else {
        descricaoSubtitulo = this.props.dataPesquisaInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataPesquisaFinal.format('DD/MM/YYYY')
        descricaoPeriodo = (
          <React.Fragment>
            entre <b>{this.props.dataPesquisaInicial.format('DD/MM/YYYY')}</b> e <b>{this.props.dataPesquisaFinal.format('DD/MM/YYYY')}</b>
          </React.Fragment>
        )
      }

      emptyListText = (
        <span>
          Nenhuma remuneração foi encontrada para <b>{this.props.colaborador.apelido}</b> {descricaoPeriodo}
        </span>
      )

      descricaoTitulo = this.props.colaborador.apelido
    } else if (tipoVisualizacao && tipoVisualizacao.id === TipoVisualizacaoRemuneracaoEnum.POR_VENDAS.id) {
      emptyListText = <span>Nenhuma remuneração foi encontrada para esta venda</span>

      if (this.props.venda.tipo.id === TipoVendaEnum.ATENDIMENTO.id) {
        descricaoTitulo = this.props.venda.cliente.apelido + ' - ' + this.props.venda.atendimento.servico.nome
        descricaoSubtitulo = 'Data do atendimento: ' + converterDataIntParaMoment(this.props.venda.atendimento.data).format('DD/MM/YYYY')
      } else if (this.props.venda.tipo.id === TipoVendaEnum.VENDA_PRODUTO.id) {
        descricaoTitulo = this.props.venda.cliente.apelido + ' - ' + this.props.venda.vendaProduto.produto.nome
        descricaoSubtitulo = 'Data da venda: ' + converterDataIntParaMoment(this.props.venda.vendaProduto.data).format('DD/MM/YYYY')
      }
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_PRECO_VENDA_REMUNERACAO])) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <DialogPage {...dialogProps} title="Detalhes" align="center" contentMaxWidth={1024} ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <ContentWithPreload loadFunction={this.carregarMain}>
              {() => (
                <React.Fragment>
                  <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
                    <Typography variant="h5">{descricaoTitulo}</Typography>
                    <Typography variant="body1">
                      <b>{descricaoSubtitulo}</b>
                    </Typography>
                    <Typography variant="body1">{descricaoResultados}</Typography>
                  </FloatContent>

                  <RemuneracoesDetalhadasResponsiveTable
                    scrollElement={dialogContentProps.scrollContainerRef.current}
                    loaderFunctionsMap={this.loaderFunctionsMap}
                    contextoUsuario="erp"
                    endpoint="erp/colaborador/remuneracoes.buscarRemuneracoes"
                    getRequestParametersFunction={this.getRequestParameters}
                    items={this.state.lista}
                    tipoVisualizacao={tipoVisualizacao}
                    loadTrackingFunction={(data) => {
                      if (data.status === 'loading') {
                        if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                          this.setState({ lista: [] })
                        }
                      } else if (data.status === 'loaded') {
                        this.setState({ lista: data.items })
                      }
                    }}
                    emptyListProps={{
                      text: emptyListText
                    }}
                  />

                  <RemuneracoesTableFooter lista={this.state.lista} valorValesDisponiveis={this.state.valorValesDisponiveis} />
                </React.Fragment>
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RemuneracoesDetalhesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RemuneracoesDetalhesDialogPage)
