import React, { Component } from 'react'

import InputLabelMaterialU from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  inputLabel: {
    color: '#666',
    whiteSpace: 'nowrap'
  },
  inputLabelFilled: {
    color: '#666',
    whiteSpace: 'nowrap',
    transform: 'translate(0, -3px) scale(0.75) !important'
  },
  inputLabelOutlined: {
    whiteSpace: 'nowrap',
    backgroundColor: 'inherit',
    padding: '0px 6px',
    marginLeft: -4,
    '&$inputLabelOutlined:not($error)': {
      color: theme.palette.text.secondary
    }
  },
  inputLabelOutlinedFocusEffectDisabled: {
    '&$focused:not($error)': {
      color: theme.palette.text.secondary
    }
  },
  focused: {},
  error: {},
  inputLabelOutlinedSmall: {
    whiteSpace: 'nowrap',
    padding: '0px 5px',
    fontSize: '14px',
    transform: 'translate(7px, 17px) scale(1) !important',
    backgroundColor: 'inherit',
    zIndex: 1
  },
  inputLabelOutlinedSmallFocused: {
    color: theme.palette.text.secondary
  },
  inputLabelOutlinedSmallShrink: {
    transform: 'translate(7px, -4px) scale(0.85) !important',
    backgroundColor: 'inherit'
  }
})

class InputLabel extends Component {
  render() {
    const { classes } = this.props
    let { label } = this.props

    let labelProps = this.props.LabelProps
    if (labelProps === undefined) {
      labelProps = {}
    }

    if (this.props.customVariant !== 'filled' && this.props.customVariant !== 'naked') {
      if (this.props.shrink !== undefined && this.props.shrink === true) {
        labelProps.shrink = true
      }
    } else {
      label = ''
    }

    const inputClasses = {
      error: classes.error,
      focused: classes.focused
    }
    if (this.props.customVariant === 'outlined') {
      inputClasses.root = [classes.inputLabelOutlined]
      if (this.props.focusEffectDisabled) {
        inputClasses.root.push(classes.inputLabelOutlinedFocusEffectDisabled)
      }
    } else if (this.props.customVariant === 'outlined-small') {
      inputClasses.root = [classes.inputLabelOutlinedSmall]
      inputClasses.shrink = classes.inputLabelOutlinedSmallShrink
      if (this.props.focusEffectDisabled) {
        inputClasses.root.push(classes.inputLabelOutlinedFocusEffectDisabled)
      }
    } else {
      inputClasses.root = [classes.inputLabel]
    }

    inputClasses.root = classNames(inputClasses.root)

    let content = null
    if (label) {
      content = (
        <InputLabelMaterialU {...labelProps} htmlFor={this.props.htmlFor} classes={inputClasses}>
          {label}
        </InputLabelMaterialU>
      )
    }

    return content
  }
}

InputLabel.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputLabel)
