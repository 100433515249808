import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Configuracoes from 'pages/erp/painel/atendimento/agendainterface/Configuracoes'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'

const styles = (theme) => ({})

class ConfiguracoesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      title: 'Configurações',
      colunaWidth: this.props.colunaWidth,
      containerColunasWidth: this.props.containerColunasWidth,
      colunaAutoWidth: this.props.colunaAutoWidth,
      intervaloMinutosExibicaoTempo: this.props.intervaloMinutosExibicaoTempo,
      pixelsPorMinuto: this.props.pixelsPorMinuto,
      regraCorCard: this.props.regraCorCard,
      colaboradores: this.props.colaboradores,
      diasSemana: this.props.diasSemana
    }
  }

  componentDidMount() {
    this.eventsManager.sub('AgendaInterface', (props) => {
      if (props.action === 'changeColunaWidth') {
        this.setState({ colunaWidth: props.colunaWidth })
      }
    })
  }

  changeColunaWidth = (colunaWidth) => {
    this.setState({ colunaWidth: colunaWidth })
    this.props.changeColunaWidth(colunaWidth)
  }

  changeContainerColunasWidth = (containerColunasWidth) => {
    this.setState({ containerColunasWidth: containerColunasWidth })
    this.props.changeContainerColunasWidth(containerColunasWidth)
  }

  changeColunaAutoWidth = (colunaAutoWidth) => {
    this.setState({ colunaAutoWidth: colunaAutoWidth })
    this.props.changeColunaAutoWidth(colunaAutoWidth)
  }

  changeIntervaloMinutosExibicaoTempo = (intervaloMinutosExibicaoTempo) => {
    this.setState(this.props.calcularIntervaloMinutosExibicaoTempo(intervaloMinutosExibicaoTempo))
    this.props.changeIntervaloMinutosExibicaoTempo(intervaloMinutosExibicaoTempo)
  }

  changeRegraCorCard = (value) => {
    this.setState({ regraCorCard: value })
    this.props.changeRegraCorCard(value)
  }

  changeExibicaoColaborador = (idColaborador, show) => {
    const colaboradores = this.state.colaboradores.slice()
    for (let i = 0; i < colaboradores.length; i++) {
      const colaborador = colaboradores[i]
      if (colaborador.id === idColaborador) {
        colaborador._show = show
        break
      }
    }
    this.setState({ colaboradores: colaboradores })
    this.props.changeExibicaoColaborador(idColaborador, show)
  }

  changeExibicaoDiasSemana = (idDiaSemana, show) => {
    const diasSemana = this.state.diasSemana.slice()
    for (let i = 0; i < diasSemana.length; i++) {
      const diaSemana = diasSemana[i]
      if (diaSemana.id === idDiaSemana) {
        diaSemana._show = show
        break
      }
    }
    this.setState({ diasSemana: diasSemana })
    this.props.changeExibicaoDiasSemana(idDiaSemana, show)
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, ...others } = this.props

    others.colunaWidth = this.state.colunaWidth
    others.changeColunaWidth = this.changeColunaWidth
    others.containerColunasWidth = this.state.containerColunasWidth
    others.changeContainerColunasWidth = this.changeContainerColunasWidth
    others.colunaAutoWidth = this.state.colunaAutoWidth
    others.changeColunaAutoWidth = this.changeColunaAutoWidth
    others.intervaloMinutosExibicaoTempo = this.state.intervaloMinutosExibicaoTempo
    others.changeIntervaloMinutosExibicaoTempo = this.changeIntervaloMinutosExibicaoTempo
    others.pixelsPorMinuto = this.state.pixelsPorMinuto
    others.regraCorCard = this.state.regraCorCard
    others.changeRegraCorCard = this.changeRegraCorCard
    others.colaboradores = this.state.colaboradores
    others.changeExibicaoColaborador = this.changeExibicaoColaborador
    others.changeExibicaoDiasSemana = this.changeExibicaoDiasSemana

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={324} ajaxing={false} toolbarActions={this.state.toolbarActions}>
        <Configuracoes {...others} />
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ConfiguracoesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesDialogPage)
