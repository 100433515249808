import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ListAltIcon from '@material-ui/icons/ListAlt'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import ProductsIcon from 'support/components/icon/Products'
import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  ATENDIMENTO: { id: 1, descricao: 'Atendimento', iconComponent: ListAltIcon },
  VENDA_PRODUTO: { id: 2, descricao: 'Venda de Produto', iconComponent: ProductsIcon },
  VENDA_PACOTE: { id: 3, descricao: 'Venda de Pacote', iconComponent: ShoppingBasketIcon },
  VENDA_CREDITO: { id: 4, descricao: 'Crédito Avulso', iconComponent: AttachMoneyIcon }
})

export default enumObject
