import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import InputProdutoDialog from 'pages/erp/painel/inventario/input/InputProdutoDialog'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputText from 'support/components/input/InputText'

const styles = (theme) => ({
  clearIcon: {
    fontSize: 16
  }
})

class InputProduto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.defaultValue && this.props.defaultValue.produto && this.props.defaultValue.produto.id ? this.props.defaultValue : null
    }
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getValue = () => {
        return this.state.value
      }
    }
  }

  handleClick = (event) => {
    if (!this.props.disabled && !this.props.readOnly) {
      openBackableDialog(InputProdutoDialog, {
        vendaExterna: this.props.vendaExterna,
        arquivado: this.props.arquivado,
        selectionType: this.props.selectionType,
        setValue: (value) => {
          this.setValue(value)
        }
      })
    }
  }

  handleFocus = (event) => {}

  setValue = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.setState({ value: value })
  }

  render() {
    const { classes, ...othersInputProps } = this.props
    delete othersInputProps.defaultValue
    delete othersInputProps.selectionType
    delete othersInputProps.vendaExterna
    delete othersInputProps.arquivado

    othersInputProps.onClick = this.handleClick
    othersInputProps.onFocus = this.handleFocus
    othersInputProps.readOnly = true

    if (!this.state.value) {
      othersInputProps.value = ''
    } else {
      const produto = this.state.value.produto
      if (produto) {
        othersInputProps.value = produto.nome
      }
    }

    if (this.state.value && !this.props.readOnly) {
      othersInputProps.endAdornment = (
        <IconButton
          disabled={this.props.disabled}
          onClick={(event) => {
            event.stopPropagation()
            this.setValue(null)
          }}
        >
          <CloseIcon className={classes.clearIcon} />
        </IconButton>
      )
    }
    return <InputText {...othersInputProps} />
  }
}

InputProduto.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputProduto)
