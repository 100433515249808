import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import HorariosAtendimentoAODialogPage from 'pages/admin/painel/negocio/HorariosAtendimentoAODialogPage'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/admin/usuario/PermissoesAcesso'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 70,
    maxWidth: 70
  }
})

class ColaboradoresDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      ajaxing: false
    }
  }

  handlerDefinirAdmin = (event, item) => {
    if (!verificarAutorizacao([PA.PODE_ALTERAR_COLABORADOR_ADMIN])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityConfirmationDialog, {
      parent: this,
      text: (
        <span>
          Você tem certeza que deseja definir o colaborador <b>{item.nome}</b> como Administrador?
        </span>
      ),
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'admin/negocios.alterarAdmin',
        data: {
          idColaborador: item.id,
          idNegocio: this.props.negocio.id
        },
        contextoUsuario: 'admin',
        onSuccess: (response, dialogConfirmationInstance) => {
          EventsManager.pub('ManipulacaoColaborador')
        },
        errorKey: 'email'
      }
    })
  }

  handlerHorariosAtendimentoAO = (event, item) => {
    openBackableDialog(HorariosAtendimentoAODialogPage, {
      idNegocio: this.props.negocio.id,
      idColaborador: item.id
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoColaborador', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  getRequestParameters = () => {
    return {
      idNegocio: this.props.negocio.id
    }
  }

  closeItemMenu() {
    this.setState((state) => ({
      colaboradorMenuAnchorEl: null
    }))
  }

  getRenderContent = (size, colaborador) => {
    const content = {}

    let email = 'Não cadastrado'
    if (colaborador.usuario && colaborador.usuario.email) {
      email = colaborador.usuario.email
    }

    content.nomeColaborador = colaborador.apelido
    content.email = email
    content.admin = colaborador.admin ? <span style={{ color: 'green' }}>Admin</span> : ''
    content.botaoOpcoes = (
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ colaboradorMenuAnchorEl: event.currentTarget, itemMenu: colaborador })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage {...dialogProps} title="Colaboradores" align="center" pageType="basicForm" contentMaxWidth={880} ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <div>
              <VirtualizedResponsiveTable
                showBackgroundDividers={true}
                loaderFunctionsMap={this.loaderFunctionsMap}
                scrollElement={dialogContentProps.scrollContainerRef.current}
                contextoUsuario="admin"
                endpoint="admin/negocios.buscarColaboradores"
                getRequestParametersFunction={this.getRequestParameters}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    this.setState({ lista: data.items })
                  }
                }}
                largeRenderProps={{
                  columns: [
                    { label: 'Nome', props: { xs: true } },
                    { label: 'E-mail', className: classes.colunaEmail, props: { xs: true }, showOnlyUp: '800', horizontalPadding: 'small' },
                    { horizontalPadding: 'small', align: 'right' },
                    { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                  ],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('large', item)
                    return {
                      itemData: [content.nomeColaborador, content.email, content.admin, content.botaoOpcoes]
                    }
                  }
                }}
                smallRenderProps={{
                  rowHeight: 80,
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('small', item)
                    return {
                      itemData: (
                        <React.Fragment>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography variant="body2">{content.nomeColaborador}</Typography>
                              <Typography variant="body2">{content.admin}</Typography>
                            </Grid>
                            <Grid item>{content.botaoOpcoes}</Grid>
                          </Grid>
                        </React.Fragment>
                      )
                    }
                  }
                }}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum colaborador foi encontrado'
                }}
              />
            </div>

            <Menu anchorEl={this.state.colaboradorMenuAnchorEl} open={Boolean(this.state.colaboradorMenuAnchorEl)} onClose={() => this.closeItemMenu()}>
              <MenuItem
                onClick={(event) => {
                  this.handlerHorariosAtendimentoAO(event, this.state.itemMenu)
                  this.closeItemMenu()
                }}
              >
                Horários Atendimento AO
              </MenuItem>
              <MenuItem
                disabled={!verificarAutorizacao([PA.PODE_ALTERAR_COLABORADOR_ADMIN])}
                onClick={(event) => {
                  this.handlerDefinirAdmin(event, this.state.itemMenu)
                  this.closeItemMenu()
                }}
              >
                Definir Como Admin
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

ColaboradoresDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ColaboradoresDialogPage)
