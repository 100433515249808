interface functionsMapType {
  notifyStart: () => void
  notifyLoaded: () => void
  notifyError: (props: { message: string }) => void
}

const mainLoaderScreenFunctionsMap: functionsMapType = {
  notifyStart: () => null,
  notifyLoaded: () => null,
  notifyError: (props) => props
}

export default mainLoaderScreenFunctionsMap
