import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'
import LinkMUI from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { withConfigCatClient } from 'configcat-react'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_CONTA_MAIN, ROUTE_ENTERPRISE_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/ApiClient'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import GoogleAnalyticsSupport from 'support/components/googleanalytics/GoogleAnalyticsSupport'
import GoogleSSOButton from 'support/components/googleSSOButton'
import InputText from 'support/components/input/InputText'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'
import { redirectUserAfterSuccessfulLogin } from 'support/domain/usuario/routerUserAfterSuccessfulLogin'
import FormUtil from 'support/util/FormUtil'
import UrlUtil from 'support/util/UrlUtil'

const styles = (theme) => ({
  root: theme.page.parentCenteredPaper,
  paper: theme.page.paper({ maxWidth: 360 }),
  footerContainerInstalarApp: {
    marginTop: 32,
    [theme.breakpoints.up(381)]: {
      marginBottom: -16
    }
  }
})

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap({}),
      showPassword: false,
      ajaxing: false,
      loginRealizado: false,
      isIOSNativeSignupEnabled: false
    }

    this.inputEmail = React.createRef()
    this.inputSenha = React.createRef()

    const campaignValue = UrlUtil.getUrlQueryStringParsed()['campaign']
    if (campaignValue) {
      localStorage.setItem('S99_Campaign', campaignValue)
    }

    SessaoUsuario.registrarUrlContinuacaoAposLogin()
  }

  componentDidMount() {
    GoogleAnalyticsSupport.sendPlataform()
    this.evaluateIOSSignupFeatureFlag()
  }

  evaluateIOSSignupFeatureFlag() {
    const { configCatClient, getValue } = this.props

    configCatClient.forceRefreshAsync().then(() => {
      getValue('FT_CRM_IOS_SIGNUP', false)
        .then((value) => {
          this.setState({ isIOSNativeSignupEnabled: value })
        })
        .catch((error) => {
          logCRM(error, { component: 'LoginPage', context: 'evaluateIOSSignupFeatureFlag' })
        })
    })
  }

  handleNativeIOSSignup() {
    const continuationUrl = SessaoUsuario.getUrlContinuacaoAposLogin()

    if (this.state.isIOSNativeSignupEnabled) {
      return true
    }

    return !(DeviceIdentifier.isNativeIOS() && continuationUrl.indexOf(ROUTE_ENTERPRISE_MAIN) >= 0)
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState(() => ({
      errorsMap: FormUtil.createErrorsMap()
    }))

    if (!this.inputEmail.current.value || !this.inputSenha.current.value) {
      return this.setState(() => ({ errorsMap: FormUtil.createErrorsMap({ login: 'O e-mail e a senha são obrigatórios!' }) }))
    }

    this.setState(() => ({
      ajaxing: true
    }))

    postAPI({
      url: 'cda/usuarios.login',
      data: {
        email: this.inputEmail.current.value.trim(),
        senha: this.inputSenha.current.value
      },
      onSuccess: (response) => {
        const usuario = response.data
        SessaoUsuario.registrarUsuario(usuario)
        this.setState(() => ({ loginRealizado: true }))
      },
      onPreFinal: () => {
        this.setState(() => ({ ajaxing: false }))
      },
      onClientError: (response) => {
        this.setState(() => ({ errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formLogin')
      }
    })
  }

  render() {
    const { classes } = this.props
    const { errorsMap, loginRealizado } = this.state
    const { getUrlContinuacaoAposLogin, isUsuarioLogado } = SessaoUsuario
    const continuationUrl = getUrlContinuacaoAposLogin()

    if (isUsuarioLogado() || loginRealizado === true) {
      redirectUserAfterSuccessfulLogin(continuationUrl)
    }

    let emailAutoFocus = true
    if (DeviceIdentifier.isNativeApp()) {
      emailAutoFocus = false
    }

    const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
    const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Fazer Login na conta do Salão99</title>
          <meta name="description" content="Acesse sua Conta do Salão99." />
        </Helmet>

        <Paper className={classes.paper}>
          <div style={{ textAlign: 'center', marginTop: 4 }}>
            <img src={logo} width="100" alt="Salão99" />
            <Typography variant="h5" style={{ marginTop: 22 }}>
              Login
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: 8, letterSpacing: '.1px' }}>
              Acesse sua Conta do Salão99
            </Typography>
          </div>

          <form id="formLogin" noValidate autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
            <InputText
              style={{ marginTop: 32 }}
              autoFocus={emailAutoFocus}
              idname="xemail"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              error={!!errorsMap('login')}
              label="E-mail"
              inputRef={this.inputEmail}
            />

            <InputText
              style={{ marginTop: 22 }}
              id="xsenha"
              name="xsenha"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              type={this.state.showPassword ? 'text' : 'password'}
              errorMessage={errorsMap('login')}
              label="Senha"
              inputRef={this.inputSenha}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <Typography style={{ marginTop: 34, marginBottom: 2, color: '#999', fontSize: 14, textAlign: 'center' }}>Não consegue acessar a sua conta?</Typography>
            <Typography style={{ padding: '3px 0px', textAlign: 'center' }}>
              <Link
                onClick={() => {
                  this.props.history.push(ROUTE_CONTA_MAIN + '/iniciar-redefinicao-senha')
                }}
              >
                Clique aqui e <b>Redefina a sua Senha</b>
              </Link>
            </Typography>

            <Grid container spacing={3} style={{ marginTop: '26px', marginBottom: 14 }} alignItems="center" justifyContent="center">
              {this.handleNativeIOSSignup() && (
                <Grid item xs={7} sm={6}>
                  <Button
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      this.props.history.push(ROUTE_CONTA_MAIN + '/criar-conta')
                    }}
                  >
                    Criar conta
                  </Button>
                </Grid>
              )}
              <Grid item xs={5} sm={6}>
                <Button disabled={this.state.ajaxing} variant="contained" color="primary" size="large" fullWidth type="submit">
                  {this.state.ajaxing ? 'AGUARDE' : 'ENTRAR'}
                </Button>
              </Grid>

              <GoogleSSOButton disabled={this.state.ajaxing} continuationUrl={continuationUrl} withSeparator />
            </Grid>
            {
              <Typography variant="body2" style={{ marginTop: 40, marginBottom: 3, color: '#666' }} align="center">
                Os dados aqui coletados serão tratados nos termos de nossa{' '}
                <LinkMUI className={classes.link} {...politicaPrivacidadeLinkProps} rel="noopener noreferrer" underline="always">
                  Política de Privacidade
                </LinkMUI>
              </Typography>
            }
          </form>

          {!this.handleNativeIOSSignup() && (
            <React.Fragment>
              <Divider style={{ marginTop: 24, marginBottom: 24 }} />
              <Typography variant="body2" style={{ marginTop: 22, textAlign: 'center' }}>
                A versão do aplicativo Salão99 Enterprise para iOS (iPhone e iPad) não possui a funcionalidade de criar conta. A gente sabe que é chato. Depois que tiver a sua
                conta, você pode acessar o Salão99 Enterprise neste aplicativo.
              </Typography>
            </React.Fragment>
          )}
        </Paper>
      </div>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withStyles(styles)(withConfigCatClient(LoginPage))
