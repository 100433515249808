import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class ConsultaAbatimentosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      ajaxing: false
    }
  }

  getRequestParameters = () => {
    return {
      idVale: this.props.vale.id
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.numeroPagamento = '#' + item.colaboradorRemuneracaoPagamento.numero
    content.dataPagamento = converterDataIntParaMoment(item.colaboradorRemuneracaoPagamento.dataPagamento).format('DD/MMM/YY')
    content.valorAbatimento = formatarValorMonetario(item.valorAbatimento)

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)

    const toolbarActions = {
      actions: []
    }

    return (
      <DialogPage {...dialogProps} title="Abatimentos" ajaxing={this.state.ajaxing} align="center" contentMaxWidth={500} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h6" style={{ marginTop: 8 }}>
                {this.props.vale.nome}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: 8 }}>
                Lista de abatimentos feitos em pagamentos de remunerações
              </Typography>
            </FloatContent>

            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              itemsPerRequest={30}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/colaborador/remuneracoes.buscarRemuneracoesComAbatimentoVale"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Pagamento', props: { xs: true }, horizontalPadding: 'none' },
                  { label: 'Data', props: { xs: true }, horizontalPadding: 'none' },
                  { label: 'Valor', props: { xs: true }, align: 'right', horizontalPadding: 'small' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.numeroPagamento, content.dataPagamento, content.valorAbatimento]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 74,
                showFirstItemBorderTop: true,
                horizontalPadding: 'none',
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body2">
                          <b>Pagamento {content.numeroPagamento}</b>
                        </Typography>
                        <Typography variant="body2">{content.dataPagamento}</Typography>
                        <Typography variant="body2" noWrap={true}>
                          {content.valorAbatimento}
                        </Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum abatimento foi encontrado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

ConsultaAbatimentosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConsultaAbatimentosDialogPage)
