import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'

const styles = (theme) => ({
  container: {
    width: '100%',
    position: 'absolute'
  }
})

class OpcoesPersistenciaRepeticaoDialog extends Component {
  render() {
    const { ajaxing, tituloAlteracaoIndividual, descricaoAlteracaoIndividual, tituloAlteracaoLote, descricaoAlteracaoLote } = this.props

    return (
      <CustomDialog dialogProps={this.props} disabledOnClose={ajaxing}>
        <DialogContent style={{ paddingBottom: 6 }}>
          <div
            style={{ marginTop: 16, cursor: 'pointer' }}
            onClick={() => {
              this.props.handleCloseDialog()
              this.props.persistFunction({ replicarAlteracoes: false })
            }}
          >
            <Typography variant="body1" color="secondary">
              {tituloAlteracaoIndividual}
            </Typography>
            <Typography variant="body2">{descricaoAlteracaoIndividual}</Typography>
          </div>
          <div
            style={{ marginTop: 24, cursor: 'pointer' }}
            onClick={() => {
              this.props.handleCloseDialog()
              this.props.persistFunction({ replicarAlteracoes: true })
            }}
          >
            <Typography variant="body1" color="secondary">
              {tituloAlteracaoLote}
            </Typography>
            <Typography variant="body2">{descricaoAlteracaoLote}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="primary" disabled={ajaxing}>
            Cancelar
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

OpcoesPersistenciaRepeticaoDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(OpcoesPersistenciaRepeticaoDialog)
