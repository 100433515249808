import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import AssignmentIcon from '@material-ui/icons/Assignment'
import LanguageIcon from '@material-ui/icons/Language'
import LinkIcon from '@material-ui/icons/Link'
import PersonIcon from '@material-ui/icons/Person'
import PlaceIcon from '@material-ui/icons/Place'
import EfetuarPagamentoPlanoDialogPage from 'pages/erp/painel/plano/EfetuarPagamentoPlanoDialogPage'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import FacebookIcon from 'support/components/icon/Facebook'
import InstagramIcon from 'support/components/icon/Instagram'
import InputListIntervaloHorarioSemana from 'support/components/input/InputListIntervaloHorarioSemana'
import InputPlace from 'support/components/input/InputPlace'
import { InputTelefoneList } from 'support/components/input/InputTelefoneList'
import InputText from 'support/components/input/InputText'
import NativeApp from 'support/components/nativeapp/NativeApp'
import DialogPage from 'support/components/page/DialogPage'
import EstadoEnum from 'support/domain/localizacao/EstadoEnum'
import NegocioSegmentoAtuacaoEnum from 'support/domain/negocio/NegocioSegmentoAtuacaoEnum'
import { createErrorsMap, focusFirstElementWithError, focusOnFirstInput, HiddenSubmitButton } from 'support/util/FormUtil'
import LocalizacaoFormatter from 'support/util/LocalizacaoFormatter'
import { logAGE } from 'support/util/Logger'

import { getSessaoPainel } from '../PainelErp'
import UniqueIdentifier from './UniqueIdentifier'

const styles = (theme) => ({
  showMobile: theme.util.showMobile(),
  showDesktop: theme.util.showDesktop(),
  containerFooterButtons: theme.form.containerFooterButtons()
})

class NegocioDadosBasicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.idNegocio = null
    this.plano = null
    this.negocioCriado = null
    this.campaigns = ['fbads', 'blzpro']

    if (props.idNegocio !== undefined && props.idNegocio !== undefined && props.idNegocio !== null) {
      this.idNegocio = props.idNegocio
    }

    this.inputNome = React.createRef()
    this.inputDescricao = React.createRef()
    this.inputSite = React.createRef()
    this.inputFacebook = React.createRef()
    this.inputInstagram = React.createRef()

    this.inputPlaceFunctionsMap = {}

    this.inputEnderecoEndereco = React.createRef()
    this.inputEnderecoNumero = React.createRef()
    this.inputEnderecoComplemento = React.createRef()
    this.inputEnderecoBairro = React.createRef()
    this.inputEnderecoCidade = React.createRef()
    this.enderecoEstadoFunctionMap = {}
    this.inputEnderecoCep = React.createRef()
    this.inputNome = React.createRef()

    this.telefonesComponentFunctions = {}
    this.horariosAtendimentoComponentFunctions = {}
    this.dataLayer = window.dataLayer || []

    this.inputUniqueIdentifier = React.createRef()
    this.inputRegisteredName = React.createRef()

    this.isPagamentoRecorrente = this.props.plano === 'pagamento-recorrente' && this.props.tipoPlano

    if (props.idNegocio !== undefined && props.idNegocio !== null) {
      this.idNegocio = props.idNegocio
    }

    this.state = {
      paginaCarregada: false,
      negocio: null,
      endereco: null,
      tipoNegocio: 0,
      segmentosAtuacao: NegocioSegmentoAtuacaoEnum.generateAllItems(),
      title: this.idNegocio === null ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }
  }

  componentDidMount = () => {
    this.props.setOnClosed(() => {
      if (this.negocioCriado) {
        EventsManager.pub('ManutencaoNegocio', {
          action: 'create',
          negocio: this.negocioCriado
        })
      }
    })
    this.setTitleOnPlanflow()
  }

  setTitleOnPlanflow = () => {
    if (this.props.plano !== undefined && this.props.plano !== null) {
      this.plano = this.props.plano
      this.setState({ title: 'Revisão' })
    }
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (this.idNegocio === null) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })

      getAPI({
        url: 'erp/negocios.buscarPorId',
        params: {
          id: this.idNegocio,
          removerCamposObrigatoriosInvalidos: true
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          const { endereco } = response.data

          if (endereco && endereco.estado) {
            endereco.estado = EstadoEnum.getById(endereco.estado.id)
          }

          const segmentosAtuacao = response.data.publicoAlvo + response.data.segmentosAtuacao

          this.setState((state) => ({
            negocio: response.data,
            segmentosAtuacao: NegocioSegmentoAtuacaoEnum.generateAllItems(segmentosAtuacao),
            endereco: endereco
          }))
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: this.plano ? 'Contratar plano' : 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleChangeSegmentoAtuacao = (position, checked) => {
    const segmentosAtuacao = this.state.segmentosAtuacao
    for (let segmentoAtuacao of segmentosAtuacao) {
      if (segmentoAtuacao.position === position) {
        segmentoAtuacao.exists = checked
        break
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  getBusinessInfo = () => {
    const cnpjLength = 14

    const segmentosAtuacao = NegocioSegmentoAtuacaoEnum.getString(this.state.segmentosAtuacao)

    const uniqueIdentifier = this.inputUniqueIdentifier.current.value
    const cleanUniqueIdentifier = uniqueIdentifier.replace(/[^\d]/g, '') || null

    let cleanRegisteredName = null
    let tipo = null
    if (cleanUniqueIdentifier && cleanUniqueIdentifier.length === cnpjLength) {
      const registeredName = this.inputRegisteredName.current.value
      cleanRegisteredName = registeredName ? registeredName.trim() : null
      tipo = this.state.tipoNegocio > 0 ? this.state.tipoNegocio : null
    }

    return {
      cpfOuCnpj: cleanUniqueIdentifier,
      publicoAlvo: segmentosAtuacao.substring(0, 3),
      segmentosAtuacao: segmentosAtuacao.substring(3),
      empresa: {
        tipo,
        razaoSocial: cleanRegisteredName
      }
    }
  }

  getBusinessAddress = () => {
    const { endereco } = this.state

    if (endereco) {
      return {
        descricaoFormatada: endereco.descricaoFormatada,
        endereco: endereco.endereco,
        numero: endereco.numero,
        complemento: endereco.complemento,
        bairro: endereco.bairro,
        cidade: endereco.cidade,
        estado: endereco.estado,
        cep: endereco.cep,
        latitude: endereco.latitude,
        longitude: endereco.longitude
      }
    }

    return null
  }

  montarObjetoNegocio = () => {
    const endereco = this.getBusinessAddress()
    const { cpfOuCnpj, publicoAlvo, segmentosAtuacao, empresa } = this.getBusinessInfo()

    let negocio = {
      id: this.idNegocio,
      nome: this.inputNome.current.value,
      descricao: this.inputDescricao.current.value,
      site: this.inputSite.current.value,
      facebook: this.inputFacebook.current.value,
      instagram: this.inputInstagram.current.value,
      telefones: this.telefonesComponentFunctions.getValues(),
      horariosAtendimento: this.horariosAtendimentoComponentFunctions.getValues(),
      publicoAlvo,
      segmentosAtuacao,
      endereco,
      cpfOuCnpj,
      empresa
    }

    return negocio
  }

  checkAnySegmentIsEnabled = (segments) => {
    const str = NegocioSegmentoAtuacaoEnum.getString(segments)
    const stringToCheck = String(str)
    const indicesGreaterThanTwo = stringToCheck.slice(3).split('')
    return indicesGreaterThanTwo.some((value) => value !== '0')
  }

  invalidBusinessError = (error) => {
    const errorsMap = createErrorsMap(error)
    this.setState({
      errorsMap: errorsMap
    })
    focusOnFirstInput('formNegocio')
    return true
  }

  invalidBusiness = () => {
    if (!this.montarObjetoNegocio().telefones.length) {
      return this.invalidBusinessError({ telefone: 'É necessário informar pelo menos um telefone' })
    }
    if (!this.checkAnySegmentIsEnabled(this.state.segmentosAtuacao)) {
      return this.invalidBusinessError({ segmentosAtuacao: 'É necessário selecionar pelo menos um segmento de atuação' })
    }
    if (this.state.endereco === null || this.state.endereco === undefined) {
      return this.invalidBusinessError({ endereco: 'O endereco é obrigatório' })
    }
    if (this.state.endereco && !this.state.endereco.cep) {
      return this.invalidBusinessError({ endereco: 'O cep é obrigatório' })
    }
    if (this.state.endereco && this.state.endereco.cep && this.state.endereco.cep.length !== 9) {
      return this.invalidBusinessError({ endereco: 'Insira um cep válido' })
    }
    return false
  }

  salvar = () => {
    if (this.invalidBusiness()) {
      return
    }

    if (this.idNegocio === null) {
      this.dataLayer.push({
        event: 'event',
        eventCategory: 'salao99:create-business',
        eventAction: 'click:button',
        eventLabel: 'criar-negocio'
      })
    }
    this.setState({
      ajaxing: true,
      errorsMap: createErrorsMap()
    })
    postAPI({
      url: 'erp/negocios.persistir',
      data: this.montarObjetoNegocio(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.negocioCriado = response.data

        try {
          this.dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:create-business',
            eventAction: 'callback',
            eventLabel: 'sucesso',
            userId: getSessaoPainel().usuario.id || '',
            businessId: this.negocioCriado.id || ''
          })

          if (this.isPagamentoRecorrente) {
            return window.location.replace(`${process.env.REACT_APP_MFE_ADDRESS}/planos/identificacao?tipo=${this.props.tipoPlano}`)
          }
        } catch (error) {
          logAGE(error, { component: 'NegociosDadosBasicosDialogPage', context: 'salvar' })
        }

        const plano = this.plano
        if (plano !== null && plano !== undefined) {
          postAPI({
            url: 'erp/negocios.contratarPlano',
            data: {
              plano: plano
            },
            requerAutorizacao: true,
            onSuccess: () => {
              this.setState(() => ({ ajaxing: false }))
              openBackableDialog(MessageDialog, {
                title: 'Plano Contratado',
                text: (
                  <span>
                    O {plano.nome} foi contratado com sucesso!
                    <br />
                    Efetue o pagamento para ativá-lo.
                  </span>
                ),
                actionButtons: [
                  {
                    label: 'FECHAR',
                    onClosedCallbackFunction: () => {
                      EventsManager.pub('ContratacaoPlano')
                      EventsManager.pub('AtualizarSessao')
                    }
                  },
                  {
                    label: 'INICIAR PAGAMENTO',
                    onClosedCallbackFunction: () => {
                      EventsManager.pub('ContratacaoPlano')
                      EventsManager.pub('AtualizarSessao')
                      openBackableDialog(EfetuarPagamentoPlanoDialogPage)
                    }
                  }
                ]
              })
            },
            onError: (response) => {
              const errorsMap = createErrorsMap(response.data.errors)
              this.setState({
                ajaxing: false,
                errorsMap: errorsMap
              })
              focusFirstElementWithError('formNegocio')
              if (errorsMap('segmentosAtuacao')) {
                document.querySelector('#segmentosAtuacao').focus()
              }
            }
          })
        } else {
          EventsManager.pub('ManutencaoNegocio')
          EventsManager.pub('AtualizarSessao')
          this.props.handleCloseDialog()
        }
      },
      onError: (response) => {
        let errors = response.data.errors
        const errorsMap = createErrorsMap(errors)
        this.setState({
          ajaxing: false,
          errorsMap: errorsMap
        })
        focusFirstElementWithError('formNegocio')
        if (errorsMap('segmentosAtuacao')) {
          document.querySelector('#segmentosAtuacao').focus()
        }
      },
      onClientError: () => {
        if (this.idNegocio === null) {
          this.dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:create-business',
            eventAction: 'callback',
            eventLabel: 'erro:dados-invalidos'
          })
        }
      },
      onServerError: () => {
        if (this.idNegocio === null) {
          this.dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:create-business',
            eventAction: 'callback',
            eventLabel: 'erro:servidor'
          })
        }
      },
      onClientConnectionError: () => {
        if (this.idNegocio === null) {
          this.dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:create-business',
            eventAction: 'callback',
            eventLabel: 'erro:conexao'
          })
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, endereco } = this.state

    let objNegocio = {}

    if (this.state.negocio) {
      objNegocio = this.state.negocio
    }

    const { nome, descricao, site, facebook, instagram, telefones, horariosAtendimento } = objNegocio

    let uniqueIdentifierDefaultValues = {
      cpfOuCnpj: '',
      tipo: 0,
      razaoSocial: ''
    }

    if (objNegocio) {
      uniqueIdentifierDefaultValues = {
        ...uniqueIdentifierDefaultValues,
        cpfOuCnpj: objNegocio.cpfOuCnpj || ''
      }
      if (objNegocio.empresa) {
        uniqueIdentifierDefaultValues = {
          ...uniqueIdentifierDefaultValues,
          tipo: objNegocio.empresa.tipo || 0,
          razaoSocial: objNegocio.empresa.razaoSocial || ''
        }
      }
    }

    const buscaCepLinkProps = {}
    const buscaCepUrl = 'http://www.buscacep.correios.com.br/'

    const onCloseDialog = () => {
      if (this.isPagamentoRecorrente) {
        return window.location.replace(`${process.env.REACT_APP_MFE_ADDRESS}/planos`)
      }

      return this.props.handleCloseDialog()
    }

    if (DeviceIdentifier.isNativeApp()) {
      buscaCepLinkProps.onClick = () => {
        NativeApp.executeNativeMethod({
          method: 'openLink',
          url: buscaCepUrl
        })
      }
    } else {
      buscaCepLinkProps.href = buscaCepUrl
      buscaCepLinkProps.target = 'buscaCep'
    }

    let situacaoCriacaoNegocioPorIOS = false
    if (DeviceIdentifier.isNativeIOS() && this.idNegocio === null) {
      situacaoCriacaoNegocioPorIOS = true
      this.dataLayer.push({
        event: 'event',
        eventCategory: 'salao99:create-business',
        eventAction: 'view:message',
        eventLabel: 'versao-do-app-sem-funcionalidade-para-ios'
      })
    }

    return (
      <DialogPage
        {...dialogProps}
        handleCloseDialog={onCloseDialog}
        ajaxing={this.state.ajaxing}
        align="center"
        contentMaxWidth={580}
        pageType="basicForm"
        title={this.state.title}
        toolbarActions={this.state.toolbarActions}
      >
        {situacaoCriacaoNegocioPorIOS && (
          <div>
            <Typography variant="body1" style={{ marginTop: 8 }}>
              A versão do aplicativo Salão99 Enterprise para iOS (iPhone e iPad) não possui a funcionalidade de criar uma nova conta de empresa.
            </Typography>
          </div>
        )}

        {!situacaoCriacaoNegocioPorIOS && (
          <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
            {() => (
              <React.Fragment>
                {this.state.negocio === null && (
                  <div>
                    <Typography variant="h6" style={{ marginTop: 8 }}>
                      Criar novo negócio
                    </Typography>
                    <Typography variant="body2" style={{ marginTop: 0, marginBottom: 30 }}>
                      Preencha os dados abaixo para criar o cadastro do seu negócio/empresa.
                    </Typography>
                  </div>
                )}

                {this.state.negocio !== null && this.plano && (
                  <div>
                    <Typography variant="h6" style={{ marginTop: 8 }}>
                      Revisão de dados
                    </Typography>
                    <Typography variant="body2" style={{ marginTop: 0, marginBottom: 30 }}>
                      Antes de efetuar o pagamento é importante revisar os dados do seu negócio.
                    </Typography>
                  </div>
                )}

                <form id="formNegocio" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  <InputText
                    idname="xnome"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={nome}
                    autoFocus={true}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('nome')}
                    label="Nome do negócio / empresa"
                    inputRef={this.inputNome}
                  />

                  <UniqueIdentifier
                    uniqueIdentifierRef={this.inputUniqueIdentifier}
                    registeredNameRef={this.inputRegisteredName}
                    companyType={this.state.tipoNegocio}
                    onTypeSelect={(companyType) => this.setState({ tipoNegocio: companyType })}
                    ajaxing={this.state.ajaxing}
                    errorsMap={errorsMap}
                    defaultValues={uniqueIdentifierDefaultValues}
                  />

                  <InputText
                    marginTop={true}
                    idname="xdescricao"
                    customVariant="outlined-small"
                    shrink={true}
                    defaultValue={descricao}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('descricao')}
                    placeholder="Escreva um breve texto de apresentação sobre o seu negócio"
                    label="Texto de Apresentação"
                    multiline={true}
                    rowsMax={20}
                    rows={3}
                    maxLength="2000"
                    inputRef={this.inputDescricao}
                  />

                  <InputTelefoneList
                    defaultPhones={telefones}
                    maxNumberOfPhones={4}
                    disabled={this.state.ajaxing}
                    errorsMap={this.state.errorsMap}
                    componentFunctions={this.telefonesComponentFunctions}
                  />

                  {errorsMap('telefone') && (
                    <Typography variant="body2" color="error" style={{ marginTop: 8 }}>
                      {errorsMap('telefone')}
                    </Typography>
                  )}
                  <br />

                  <SectionSeparator icon={<PersonIcon />} title="Público-alvo" />

                  {errorsMap('publicoAlvo') && (
                    <Typography variant="body2" color="error" style={{ marginBottom: 8 }}>
                      {errorsMap('publicoAlvo')}
                    </Typography>
                  )}

                  <div style={{ padding: ' 0px 4px' }}>
                    {this.state.segmentosAtuacao.map((segmento, index) => (
                      <React.Fragment key={index}>
                        <UncontrolledCheckbox
                          key={index}
                          id={index === 0 ? 'segmentosAtuacao' : null}
                          disabled={this.state.ajaxing}
                          style={{ padding: 5 }}
                          formControlProps={{
                            style: { display: 'inline-flex', width: 150, marginLeft: -8 },
                            label: <Typography variant="body2">{segmento.descricao}</Typography>
                          }}
                          color="primary"
                          defaultChecked={segmento.exists}
                          onChange={(checked) => this.handleChangeSegmentoAtuacao(segmento.position, checked)}
                        />
                        {index === 2 && (
                          <div style={{ paddingTop: 8 }}>
                            <br />
                            <SectionSeparator icon={<AssignmentIcon />} title="Segmentos de Atuação" />
                            {errorsMap('segmentosAtuacao') && (
                              <Typography variant="body2" color="error" style={{ marginBottom: 8 }}>
                                {errorsMap('segmentosAtuacao')}
                              </Typography>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>

                  <br />
                  <SectionSeparator icon={<LinkIcon />} title="Links e Redes Sociais" />

                  <InputText
                    idname="xsite"
                    customVariant="outlined-small"
                    shrink={true}
                    defaultValue={site}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('site')}
                    placeholder="http://..."
                    label="Site"
                    startAdornment={<LanguageIcon style={{ color: '#666', opacity: this.state.ajaxing ? 0.4 : 1 }} />}
                    inputRef={this.inputSite}
                  />

                  <InputText
                    marginTop={true}
                    idname="xfacebook"
                    customVariant="outlined-small"
                    shrink={true}
                    defaultValue={facebook}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('facebook')}
                    placeholder="http://..."
                    label="Facebook"
                    startAdornment={<FacebookIcon style={{ color: '#666', opacity: this.state.ajaxing ? 0.4 : 1 }} />}
                    inputRef={this.inputFacebook}
                  />

                  <InputText
                    marginTop={true}
                    idname="xinstagram"
                    customVariant="outlined-small"
                    shrink={true}
                    defaultValue={instagram}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('instagram')}
                    placeholder="@meuinstagram"
                    label="Instagram"
                    startAdornment={<InstagramIcon style={{ color: '#666', opacity: this.state.ajaxing ? 0.4 : 1 }} />}
                    inputRef={this.inputInstagram}
                  />

                  <br />
                  <br />
                  <SectionSeparator icon={<PlaceIcon />} title="Localização" />

                  <InputPlace
                    idname="xendereco"
                    customVariant="outlined-small"
                    shrink={false}
                    label="Endereço"
                    functionsMap={this.inputPlaceFunctionsMap}
                    onChange={(place) => {
                      if (place) {
                        const endereco = {
                          descricaoFormatada: place.formattedAddress,
                          endereco: place.address,
                          numero: place.number,
                          complemento: place.complement,
                          bairro: place.district,
                          cidade: place.city,
                          estado: EstadoEnum.getBySigla(place.state),
                          cep: place.postalCode,
                          latitude: place.lat,
                          longitude: place.lng
                        }
                        this.setState({
                          endereco: endereco
                        })
                      }
                    }}
                    onlyPreciseAddress={true}
                    errorMessage={errorsMap('endereco')}
                    value={endereco && endereco.latitude ? LocalizacaoFormatter.formatarEnderecoCompleto(endereco) : ''}
                    dialogTitle="Informe o endereço..."
                    showBackButton={true}
                    loadUserHistoryPlaces={false}
                    mapProps={{
                      show: endereco ? true : false,
                      lat: endereco ? endereco.latitude : null,
                      lng: endereco ? endereco.longitude : null
                    }}
                  />

                  <br />
                  <SectionSeparator icon={<AccessTimeIcon />} title="Horários de Atendimento" />

                  <InputListIntervaloHorarioSemana
                    disabled={this.state.ajaxing}
                    errorsMap={this.state.errorsMap}
                    componentFunctions={this.horariosAtendimentoComponentFunctions}
                    defaultValue={horariosAtendimento}
                    maxIntervalos={5}
                    adicionarSeparador={true}
                    adicionarUltimoSeparador={false}
                  />

                  <HiddenSubmitButton />

                  {this.state.paginaCarregada && (
                    <div className={classes.containerFooterButtons}>
                      <Button disabled={this.state.ajaxing} onClick={onCloseDialog}>
                        Cancelar
                      </Button>
                      <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                        {this.state.ajaxing ? 'Aguarde' : this.plano ? 'Contratar Plano' : 'Salvar'}
                      </Button>
                    </div>
                  )}
                </form>
              </React.Fragment>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

export default withStyles(styles)(NegocioDadosBasicosDialogPage)
