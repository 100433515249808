import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BlockIcon from '@material-ui/icons/Block'
import RefreshIcon from '@material-ui/icons/Refresh'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import NfCanceladaIcon from 'support/components/icon/NfCancelada'
import NfErroIcon from 'support/components/icon/NfErro'
import NfGeradaIcon from 'support/components/icon/NfGerada'
import NfPendenteIcon from 'support/components/icon/NfPendente'
import NfProcessandoIcon from 'support/components/icon/NfProcessando'
import NativeApp from 'support/components/nativeapp/NativeApp'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import StatusApresentacaoNfEnum from 'support/domain/venda/StatusApresentacaoNfEnum'
import TipoNfEnum from 'support/domain/venda/TipoNfEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaCheckbox: {
    maxWidth: 50,
    minWidth: 50
  },
  colunaData: {
    flexGrow: 1,
    maxWidth: 106,
    minWidth: 106,
    [theme.breakpoints.down(686)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaIconeTipoVenda: {
    maxWidth: 44,
    minWidth: 44,
    [theme.breakpoints.down(680)]: {
      display: 'none'
    }
  },
  iconeTipoVenda: {
    display: 'block',
    fontSize: '24px'
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 110
  },
  colunaQuantidade: {
    flexGrow: 1,
    maxWidth: 70,
    minWidth: 70
  },
  colunaVenda: {
    minWidth: 240
  },
  colunaSituacao: {
    flexGrow: 0.8
  },
  textVerticalPadding: {
    paddingTop: 8,
    paddingBottom: 8
  },
  containerFooterButtons: theme.form.containerFooterButtons({ showMobile: true })
})

class GerenciamentoFaturaNotasFiscaisDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.state = {
      lista: [],
      selecionarTodos: false,
      possuiVendasParaEmitirNF: false,
      title: 'Notas Fiscais',
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoNf', (props) => {
      this.atualizarLista()
    })
  }

  atualizarLista = () => {
    this.loaderFunctionsMap.reload()
    this.setState({ selecionarTodos: false })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true, lista: [] })

    getAPI({
      url: 'erp/vendas.buscarDadosParaEmissaoNf',
      params: {
        idNota: this.props.nota.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const { state } = this
        state.negocioConfiguracaoNf = response.data.negocioConfiguracaoNf
        state.lista = response.data.vendas
        state.possuiVendasParaEmitirNF = response.data.false

        for (let i = 0; i < state.lista.length; i++) {
          const venda = state.lista[i]
          venda._show = true
          venda.podeEmitir = this.podeEmitir(venda)
          if (venda.podeEmitir) {
            state.possuiVendasParaEmitirNF = true
          }
        }

        state.paginaCarregada = true

        state.toolbarActions = {
          actions: [
            {
              icon: <RefreshIcon />,
              handleAction: this.atualizarLista
            }
          ]
        }

        this.setState(state)

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  selecionarTodos = (event) => {
    const lista = this.state.lista
    const listaPodeEmitir = lista.filter((venda) => venda.podeEmitir)

    if (event.target.checked) {
      for (let item of listaPodeEmitir) {
        if (item._show) {
          item._checked = true
        } else {
          item._checked = false
        }
      }
    } else {
      for (let item of listaPodeEmitir) {
        item._checked = false
      }
    }

    for (let item of lista) {
      item._update = true
    }

    this.setState({ lista: lista, selecionarTodos: event.target.checked })
  }

  getStatusSelecionarTodos = () => {
    const lista = this.state.lista.filter((venda) => venda.podeEmitir)
    for (let i = 0; i < lista.length; i++) {
      const itemLista = lista[i]
      if (itemLista._show) {
        if (!itemLista._checked) {
          return false
        }
      }
    }
    return true
  }

  selecionarItem = (item) => {
    const lista = this.state.lista
    item._checked = !item._checked
    item._update = true
    this.setState({ selecionarTodos: this.getStatusSelecionarTodos(), lista: lista })
  }

  getDadosEmitirNf = () => {
    let dados = {}
    dados.vendas = []
    for (let venda of this.state.lista) {
      if (venda._checked === true) {
        dados.vendas.push({ id: venda.id })
      }
    }
    return dados
  }

  emitirNf = () => {
    if (!verificarAutorizacao([PA.PODE_EMITIR_NOTAS_FISCAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    let totalItens = 0

    for (let venda of this.state.lista) {
      if (venda._checked === true) {
        totalItens++
      }
    }

    if (totalItens > 0) {
      openBackableDialog(EntityConfirmationDialog, {
        parent: this,
        closeOnSuccess: false,
        title: 'Confirmação',
        text: (
          <span>
            Você confirma a emissão de nota fiscal para as vendas selecionadas ({totalItens} {totalItens === 1 ? 'venda selecionada' : 'vendas selecionadas'})?
          </span>
        ),
        cancelButtonLabel: 'VOLTAR',
        confirmButtonLabel: 'Confirmar',
        call: {
          method: 'post',
          url: 'erp/vendas.emitirNfsPorVendas',
          contextoUsuario: 'erp',
          data: this.getDadosEmitirNf(),
          onSuccess: (response, dialogConfirmationInstance) => {
            EventsManager.pub('ManipulacaoNf')
            dialogConfirmationInstance.props.handleCloseDialog()
          },
          errorKey: ['erroEmissaoNota']
        }
      })
    } else {
      openBackableDialog(MessageDialog, {
        title: 'Selecione as vendas desejadas',
        text: 'É necessário selecionar pelo menos 1 venda para emitir a nota fiscal.'
      })
    }
  }

  cancelarNf = (nf) => {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_NOTAS_FISCAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    if (nf.tipo.id === TipoNfEnum.NFSE.id) {
      if (this.state.negocioConfiguracaoNf.nfsPermiteCancelamento) {
        openBackableDialog(EntityConfirmationDialog, {
          parent: this,
          closeOnSuccess: false,
          title: 'Confirmação',
          text: <span>Você deseja realmente cancelar esta nota fiscal?</span>,
          cancelButtonLabel: 'VOLTAR',
          confirmButtonLabel: 'Confirmar',
          call: {
            method: 'post',
            url: 'erp/vendas.cancelarNf',
            contextoUsuario: 'erp',
            data: {
              id: nf.id
            },
            onSuccess: (response, dialogConfirmationInstance) => {
              EventsManager.pub('ManipulacaoNf')
              dialogConfirmationInstance.props.handleCloseDialog()
            }
          }
        })
      } else {
        openBackableDialog(MessageDialog, {
          text: 'Este município não permite o cancelamento de NFS-e pelo sistema.'
        })
      }
    }
  }

  podeEmitir = (venda) => {
    if (venda.excluido === true || venda.cancelado === true || TipoVendaEnum.VENDA_CREDITO.id === venda.tipo.id) {
      return false
    }
    if (this.state.negocioConfiguracaoNf.emissaoNfs === false && (TipoVendaEnum.ATENDIMENTO.id === venda.tipo.id || TipoVendaEnum.VENDA_PACOTE.id === venda.tipo.id)) {
      return false
    }
    if (this.state.negocioConfiguracaoNf.emissaoNfsVendaPacote === false && TipoVendaEnum.VENDA_PACOTE.id === venda.tipo.id) {
      return false
    }
    if (this.state.negocioConfiguracaoNf.emissaoNfsUsoPacote === false && (venda.utilizacaoPacote === true || venda.clientePacoteItem)) {
      return false
    }
    if (this.state.negocioConfiguracaoNf.emissaoNfc === false && TipoVendaEnum.VENDA_PRODUTO.id === venda.tipo.id) {
      return false
    }
    if (venda.nf) {
      if (
        (venda.nf.emitido && venda.nf.statusApresentacao.id !== StatusApresentacaoNfEnum.CANCELADA.id) ||
        venda.nf.statusApresentacao.id === StatusApresentacaoNfEnum.EMITINDO.id
      ) {
        return false
      }
    }
    return true
  }

  getRenderContent = (size, item, index) => {
    const { classes } = this.props

    const content = {}

    content.podeEmitir = item.podeEmitir

    content._checked = item._checked

    const iconeEDescricao = VendaService.getIconeEDescricao(item)
    content.iconeTipoVenda = <iconeEDescricao.icon className={classes.iconeTipoVenda} />

    content.descricao = converterDataIntParaMoment(item.dataVenda).format('DD/MM/YY') + ' - ' + VendaService.getDescricao(item)

    if (TipoVendaEnum.VENDA_PRODUTO.id === item.tipo.id) {
      content.descricao += ' - ' + item.quantidade + 'UN'
    }

    if (this.state.negocioConfiguracaoNf.emissaoNfsUsoPacote === true || (!item.utilizacaoPacote && !item.clientePacoteItem)) {
      content.descricao += ' - ' + formatarValorMonetario(item.valorTotal)
    }

    if (item.cancelado === true) {
      content.descricao = <strike>{content.descricao}</strike>
    }

    content.descricao = (
      <Typography variant="body2" className={classes.textVerticalPadding} style={{ paddingLeft: 6 }}>
        {content.descricao}
      </Typography>
    )

    if ((TipoVendaEnum.ATENDIMENTO.id === item.tipo.id || TipoVendaEnum.VENDA_PACOTE.id === item.tipo.id) && this.state.negocioConfiguracaoNf.emissaoNfs === false) {
      content.situacao = (
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <BlockIcon style={{ opacsity: 0.3 }} />
          </Grid>
          <Grid item style={{ marginLeft: 16 }}>
            <Typography variant="body2" className={classes.textVerticalPadding}>
              Emissão de NF de serviço não configurada
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (TipoVendaEnum.VENDA_PACOTE.id === item.tipo.id && this.state.negocioConfiguracaoNf.emissaoNfsVendaPacote === false) {
      content.situacao = (
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <BlockIcon style={{ opacsity: 0.3 }} />
          </Grid>
          <Grid item style={{ marginLeft: 16 }}>
            <Typography variant="body2" className={classes.textVerticalPadding}>
              Emissão de NF de venda de pacote não configurada
            </Typography>
          </Grid>
        </Grid>
      )
    } else if ((item.utilizacaoPacote === true || item.clientePacoteItem) && this.state.negocioConfiguracaoNf.emissaoNfsUsoPacote === false) {
      content.situacao = (
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <BlockIcon style={{ opacsity: 0.3 }} />
          </Grid>
          <Grid item style={{ marginLeft: 16 }}>
            <Typography variant="body2" className={classes.textVerticalPadding}>
              Emissão de NF de uso de pacote não configurada
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (TipoVendaEnum.VENDA_PRODUTO.id === item.tipo.id && this.state.negocioConfiguracaoNf.emissaoNfc === false) {
      content.situacao = (
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <BlockIcon style={{ opacsity: 0.3 }} />
          </Grid>
          <Grid item style={{ marginLeft: 16 }}>
            <Typography variant="body2" className={classes.textVerticalPadding}>
              Emissão de NF de produto não configurada
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (TipoVendaEnum.VENDA_CREDITO.id === item.tipo.id) {
      content.situacao = (
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <BlockIcon style={{ opacsity: 0.3 }} />
          </Grid>
          <Grid item style={{ marginLeft: 16 }}>
            <Typography variant="body2" className={classes.textVerticalPadding}>
              Emissão de NF indisponível para vendas de créditos
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (item.cancelado === true && !item.nf) {
      content.situacao = (
        <Grid container alignItems="center">
          <Grid item>
            <BlockIcon style={{ opacsity: 0.3 }} />
          </Grid>
          <Grid item style={{ marginLeft: 16 }}>
            <Typography variant="body2" className={classes.textVerticalPadding}>
              Venda cancelada
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (!item.nf) {
      content.situacao = (
        <Grid container alignItems="center">
          <Grid item>
            <NfPendenteIcon />
          </Grid>
          <Grid item style={{ marginLeft: 16 }}>
            <Typography variant="body2" className={classes.textVerticalPadding}>
              NF não emitida
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (item.nf.tipo.id === TipoNfEnum.NFSE.id) {
      let pdfLinkProps = {}
      let xmlLinkProps = {}

      if (DeviceIdentifier.isNativeApp()) {
        pdfLinkProps.onClick = (event) => {
          event.stopPropagation()
          NativeApp.executeNativeMethod({
            method: 'downloadFile',
            fileName: item.nf.nfsNumero + '.pdf',
            url: item.nf.nfsUrlPdf
          })
        }
        xmlLinkProps.onClick = (event) => {
          event.stopPropagation()
          NativeApp.executeNativeMethod({
            method: 'downloadFile',
            fileName: item.nf.nfsNumero + '.xml',
            url: item.nf.nfsUrlXml
          })
        }
      } else {
        pdfLinkProps.href = item.nf.nfsUrlPdf
        pdfLinkProps.target = 'fileDownload'
        xmlLinkProps.href = item.nf.nfsUrlXml
        xmlLinkProps.target = 'fileDownload'
      }

      if (item.nf.statusApresentacao.id === StatusApresentacaoNfEnum.EMITINDO.id) {
        content.situacao = (
          <Grid container alignItems="center">
            <Grid item>
              <NfProcessandoIcon />
            </Grid>
            <Grid item style={{ marginLeft: 16 }}>
              <Typography variant="body2" className={classes.textVerticalPadding}>
                Emitindo NF...
              </Typography>
            </Grid>
          </Grid>
        )
      } else if (item.nf.statusApresentacao.id === StatusApresentacaoNfEnum.CANCELANDO.id) {
        content.situacao = (
          <Grid container alignItems="center">
            <Grid item>
              <NfProcessandoIcon />
            </Grid>
            <Grid item style={{ marginLeft: 16 }}>
              <Typography variant="body2" className={classes.textVerticalPadding}>
                Cancelando NF...
              </Typography>
            </Grid>
          </Grid>
        )
      } else if (item.nf.statusApresentacao.id === StatusApresentacaoNfEnum.EMITIDA.id) {
        content.situacao = (
          <Grid container alignItems="center">
            <Grid item>
              <NfGeradaIcon />
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Button style={{ minWidth: 0 }} component="a" {...pdfLinkProps}>
                PDF
              </Button>
            </Grid>
            <Grid item>
              <Button style={{ minWidth: 0 }} component="a" {...xmlLinkProps}>
                XML
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ minWidth: 0 }}
                onClick={(event) => {
                  event.stopPropagation()
                  this.cancelarNf(item.nf)
                }}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        )
      } else if (item.nf.statusApresentacao.id === StatusApresentacaoNfEnum.COM_ERRO.id) {
        content.situacao = (
          <Grid container alignItems="center">
            <Grid item>
              <NfErroIcon />
            </Grid>
            <Grid item style={{ marginLeft: 8 }}>
              <Button
                style={{ minWidth: 0 }}
                onClick={(event) => {
                  event.stopPropagation()
                  openBackableDialog(MessageDialog, {
                    title: 'Erro:',
                    text: item.nf.descricaoErroApresentacao
                      ? item.nf.descricaoErroApresentacao
                      : 'Erro não identificado. Entre em contato com o suporte pelo chat e informe o número desta Fatura'
                  })
                }}
              >
                VER ERRO
              </Button>
            </Grid>
            {item.nf.emitido && (
              <Grid item>
                <Button
                  style={{ minWidth: 0 }}
                  onClick={(event) => {
                    event.stopPropagation()
                    this.cancelarNf(item.nf)
                  }}
                >
                  CANCELAR
                </Button>
              </Grid>
            )}
          </Grid>
        )
      } else if (item.nf.statusApresentacao.id === StatusApresentacaoNfEnum.CANCELADA.id) {
        content.situacao = (
          <Grid container alignItems="center">
            <Grid item>
              <NfCanceladaIcon />
            </Grid>
            <Grid item style={{ marginLeft: 16 }}>
              <Typography variant="body2" className={classes.textVerticalPadding}>
                NF Cancelada
              </Typography>
            </Grid>
          </Grid>
        )
      }
    }

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { possuiVendasParaEmitirNF } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        pageType="basicEntity"
        contentMaxWidth={1000}
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => {
          const listaExibicao = this.state.lista.filter((venda) => venda._show)

          let onClickRowFunction = (item, event, index) => {
            this.selecionarItem(item)
          }

          return (
            <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.loaderFunctionsMap}>
              {() => {
                return (
                  <React.Fragment>
                    <div>
                      <Typography variant="body2">
                        {possuiVendasParaEmitirNF
                          ? 'Selecione as vendas desejadas para emitir as notas fiscais'
                          : 'Todas as solicitações para a emissão de notas fiscais já foram feitas'}
                      </Typography>
                      <br />

                      <VirtualizedResponsiveTable
                        scrollElement={dialogContentProps.scrollContainerRef.current}
                        dynamicHeight={true}
                        showBackgroundDividers={true}
                        items={listaExibicao}
                        largeRenderProps={{
                          headerColumnHeight: 40,
                          columns: [
                            {
                              className: classes.colunaCheckbox,
                              label: (
                                <Checkbox
                                  onClick={this.selecionarTodos}
                                  checked={this.state.selecionarTodos}
                                  disabled={!possuiVendasParaEmitirNF}
                                  style={{ marginTop: -8, marginBottom: -8, opacity: possuiVendasParaEmitirNF ? 1 : 0.35 }}
                                />
                              ),
                              horizontalPadding: 'none'
                            },
                            { label: 'Venda', horizontalPadding: 'small', props: { xs: true }, className: classes.colunaVenda },
                            { label: 'Situação', props: { xs: true }, className: classes.colunaSituacao }
                          ],
                          itemRenderer: (item, index) => {
                            const content = this.getRenderContent('large', item, index)
                            const itemData = []

                            itemData.push(
                              <Checkbox
                                checked={content._checked === undefined ? false : content._checked}
                                disabled={!content.podeEmitir}
                                style={{ marginTop: 4, marginBottom: 4, opacity: content.podeEmitir ? 1 : 0.35 }}
                              />
                            )
                            itemData.push(content.descricao)
                            itemData.push(content.situacao)
                            return {
                              selected: content._checked,
                              onClickRow: content.podeEmitir ? onClickRowFunction : undefined,
                              itemData: itemData
                            }
                          }
                        }}
                        smallRenderProps={{
                          horizontalPadding: 'none',
                          itemRenderer: (item, index) => {
                            const content = this.getRenderContent('small', item)

                            return {
                              selected: content._checked,
                              onClickRow: content.podeEmitir ? onClickRowFunction : undefined,
                              itemData: (
                                <div style={{ marginTop: 8, marginBottom: 8 }}>
                                  <Grid container alignItems="center" wrap="nowrap" style={{ marginBottom: -4 }}>
                                    <Grid item style={{ marginLeft: -8 }}>
                                      <Checkbox
                                        checked={content._checked === undefined ? false : content._checked}
                                        disabled={!content.podeEmitir}
                                        style={{ opacity: content.podeEmitir ? 1 : 0.35 }}
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <b>{content.descricao}</b>
                                    </Grid>
                                  </Grid>
                                  {content.situacao}
                                </div>
                              )
                            }
                          }
                        }}
                        emptyListProps={{
                          padding: false,
                          text: 'Nenhum item disponível para a emissão de nota fiscal'
                        }}
                      />
                    </div>

                    {this.state.paginaCarregada && this.state.lista.length > 0 && (
                      <div className={classes.containerFooterButtons}>
                        <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                          Voltar
                        </Button>
                        {possuiVendasParaEmitirNF && (
                          <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.emitirNf}>
                            {this.state.ajaxing ? 'Aguarde' : 'Emitir'}
                          </Button>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )
              }}
            </ContentWithPreload>
          )
        }}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

GerenciamentoFaturaNotasFiscaisDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GerenciamentoFaturaNotasFiscaisDialogPage)
