import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import ChartLine from 'support/components/chart/ChartLine'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import TabContainer from 'support/components/tab/TabContainer'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  titulo: {
    fontSize: 16,
    fontWeight: 500
  },
  textoDados: {
    fontSize: 14,
    marginTop: '5px'
  }
})

class TabDadosConsolidados extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ajaxingValoresUltimosMeses: false
    }

    this.dadosUltimosMesesLoaderFunctionsMap = {}
    this.dadosInformacoesFinanceirasLoaderFunctionsMap = {}
  }

  carregardadosUltimosMeses = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingValoresUltimosMeses: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosVendasClienteUltimosMeses',
      params: {
        idCliente: this.props.cliente.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingValoresUltimosMeses: false,
          dadosUltimosMeses: this.prepararDadosUltimosMesesParaGrafico(response.data, this.props.theme.palette.chart.pieList, (value, name, props) => [
            <span style={{ fontSize: 12 }}>
              {name}: {formatarValorMonetario(value)}
            </span>,
            null
          ])
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingValoresUltimosMeses: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarInformacoesVendas = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/clientes.buscarInformacoesVendas',
      params: {
        id: this.props.cliente.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          informacoesVendas: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararDadosUltimosMesesParaGrafico = (dadosUltimosMeses, colors, tooltipFormatterFunction) => {
    const dadosUltimosMesesPorMes = {}
    if (dadosUltimosMeses && dadosUltimosMeses.length) {
      for (let dadosReceitaUltimosMeses of dadosUltimosMeses) {
        dadosUltimosMesesPorMes[dadosReceitaUltimosMeses.mes.toString()] = dadosReceitaUltimosMeses
      }
    }

    const agora = moment()
    const dataNavegacao = agora.clone().startOf('month').subtract(11, 'month')
    const criarItem = (mes, vatend, vprod, vpac) => {
      const data = moment(mes.toString(), 'YYYYMM')
      vatend = vatend === null || vatend === undefined ? 0 : vatend
      vprod = vprod === null || vprod === undefined ? 0 : vprod
      vpac = vpac === null || vpac === undefined ? 0 : vpac
      return {
        mes: data.format('MMM/YY'),
        vatend: vatend,
        vprod: vprod,
        vpac: vpac,
        vtotal: vatend + vprod + vpac
      }
    }

    const data = []
    while (true) {
      const mesAtual = dataNavegacao.format('YYYYMM')
      const dadosReceitaUltimosMeses = dadosUltimosMesesPorMes[mesAtual]
      if (dadosReceitaUltimosMeses) {
        data.push(criarItem(mesAtual, dadosReceitaUltimosMeses.valorAtendimentos, dadosReceitaUltimosMeses.valorVendasProduto, dadosReceitaUltimosMeses.valorVendasPacote))
      } else {
        data.push(criarItem(mesAtual, 0, 0, 0))
      }
      if (dataNavegacao.isSame(agora, 'month')) {
        break
      } else {
        dataNavegacao.add(1, 'month')
      }
    }
    return {
      xAxis: {
        dataKey: 'mes',
        height: 80
      },
      tooltip: {
        formatter: tooltipFormatterFunction
      },
      items: [
        {
          name: 'Todos',
          color: colors[0],
          shortName: 'Todos',
          dataKey: 'vtotal'
        },
        {
          name: 'Atendimentos',
          color: colors[1],
          shortName: 'Atendimentos',
          dataKey: 'vatend'
        },
        {
          name: 'Venda de Produtos',
          color: colors[2],
          shortName: 'Produtos',
          dataKey: 'vprod'
        },
        {
          name: 'Venda de Pacotes',
          color: colors[3],
          shortName: 'Pacotes',
          dataKey: 'vpac'
        }
      ],
      data: data
    }
  }

  render() {
    const { classes } = this.props
    const { dadosUltimosMeses, informacoesVendas } = this.state

    return (
      <TabContainer>
        <React.Fragment>
          <ContentWithPreload functionsMap={this.dadosInformacoesFinanceirasLoaderFunctionsMap} loadFunction={this.carregarInformacoesVendas} align="left">
            {() => (
              <div>
                <Typography variant="h6" className={classes.titulo} style={{ marginTop: 8 }}>
                  Dados Consolidados
                </Typography>
                <Typography variant="body2" className={classes.textoDados}>
                  Valor Total Vendas: {formatarValorMonetario(informacoesVendas.valorTotalVendas ? informacoesVendas.valorTotalVendas : 0)}
                </Typography>
                <Typography variant="body2" className={classes.textoDados}>
                  Total Atendimentos: {informacoesVendas.quantidadeAtendimentos ? informacoesVendas.quantidadeAtendimentos : 0}
                </Typography>
                <Typography variant="body2" className={classes.textoDados}>
                  Valor Atendimentos: {formatarValorMonetario(informacoesVendas.valorTotalAtendimentos ? informacoesVendas.valorTotalAtendimentos : 0)}
                </Typography>
                <Typography variant="body2" className={classes.textoDados}>
                  Total Produtos Vendidos: {informacoesVendas.quantidadeProdutosVendidos ? informacoesVendas.quantidadeProdutosVendidos : 0}
                </Typography>
                <Typography variant="body2" className={classes.textoDados}>
                  Valor Venda Produtos: {formatarValorMonetario(informacoesVendas.valorTotalVendaProdutos ? informacoesVendas.valorTotalVendaProdutos : 0)}
                </Typography>
                <Typography variant="body2" className={classes.textoDados}>
                  Total Pacotes Vendidos: {informacoesVendas.quantidadePacotesVendidos ? informacoesVendas.quantidadePacotesVendidos : 0}
                </Typography>
                <Typography variant="body2" className={classes.textoDados}>
                  Valor Venda Pacotes: {formatarValorMonetario(informacoesVendas.valorTotalVendaPacotes ? informacoesVendas.valorTotalVendaPacotes : 0)}
                </Typography>
              </div>
            )}
          </ContentWithPreload>
          <ContentWithPreload functionsMap={this.dadosUltimosMesesLoaderFunctionsMap} loadFunction={this.carregardadosUltimosMeses}>
            {() => (
              <div>
                <Typography variant="h6" className={classes.titulo} style={{ marginTop: 20 }}>
                  Gráfico Mensal
                </Typography>
                <div style={{ width: '100%', paddingBottom: 8, paddingTop: 0 }}>
                  <ChartLine data={dadosUltimosMeses} height={300} />
                </div>
              </div>
            )}
          </ContentWithPreload>
        </React.Fragment>
      </TabContainer>
    )
  }
}

TabDadosConsolidados.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(TabDadosConsolidados)
