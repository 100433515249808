/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/core'

const laptop = 1460
const tablet = 960
const smallDevice = 415
const blueColor = '#264FEC'
const blackColor = '#222'
const spaceBetween = 'space-between'

export const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '1440px',
    width: '100%',
    [theme.breakpoints.down(tablet)]: {
      marginTop: '85px'
    }
  },
  wrapperCard: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginTop: '20px',
    flexDirection: 'column'
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(550px, auto) minmax(550px, auto)',
    gap: '20px',
    [theme.breakpoints.down(laptop)]: {
      gridTemplateColumns: '1fr'
    }
  },
  row: {
    width: '100%',
    display: 'flex',
    gap: '20px'
  },
  cardContent: {
    margin: '24px',
    [theme.breakpoints.down(tablet)]: {
      margin: '16px'
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: '#00000026',
    width: '100%',
    [theme.breakpoints.down(tablet)]: {
      margin: '0px auto'
    }
  },
  wrapperTitleContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: spaceBetween
  },
  wrapperIconContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: spaceBetween,
    gap: '8px',
    width: '100%',
    borderBottom: '1px solid #CFCED3',
    paddingBottom: '16px',
    '& > button': {
      marginRight: '40px',
      minWidth: '50px',
      [theme.breakpoints.down(tablet)]: {
        marginRight: '0px'
      }
    }
  },
  wrapperIconText: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%'
  },
  iconButton: {
    color: '#000',
    width: '30px',
    height: '30px'
  },
  iconText: {
    fontWeight: 500,
    fontSize: '20px',
    color: blackColor,
    [theme.breakpoints.down(tablet)]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down(smallDevice)]: {
      fontSize: '14px'
    }
  },
  wrapperImg: {
    paddingTop: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    '& > img': {
      [theme.breakpoints.down(tablet)]: {
        padding: '10px',
        borderRadius: '8px',
        backgroundColor: '#F5F5F5',
        maxHeight: '88px',
        maxWidth: '88px'
      }
    }
  },
  wrapperImgMaquininha: {
    paddingTop: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '60px',
    [theme.breakpoints.down(tablet)]: {
      gap: '20px'
    },
    '& > img': {
      height: '155px',
      width: '155px',
      [theme.breakpoints.down(tablet)]: {
        maxHeight: '100%',
        maxWidth: '88px',
        padding: '10px',
        borderRadius: '8px',
        backgroundColor: '#F5F5F5',
        objectFit: 'contain'
      }
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  cardText: {
    color: blackColor,
    [theme.breakpoints.down(tablet)]: {
      maxWidth: '186px',
      fontSize: '14px',
      lineHeight: '20px'
    }
  },
  wrapperData: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    fontSize: '24px',
    '& > svg': {
      [theme.breakpoints.down(tablet)]: {
        fontSize: '20px'
      }
    }
  },
  wrapperStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginTop: '20px',
    fontSize: '24px',
    '& > svg': {
      [theme.breakpoints.down(tablet)]: {
        fontSize: '20px'
      }
    }
  },
  wrapperTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    marginBottom: '8px'
  },
  tooltip: {
    backgroundColor: blueColor,
    fontSize: '12px',
    padding: '16px'
  },
  tooltipIcon: {
    fill: blueColor
  },
  statusLabel: {
    color: blackColor,
    fontWeight: 400,
    fontSize: '14px',
    [theme.breakpoints.down(tablet)]: {
      fontSize: '12px'
    }
  },
  statusValue: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#011E38',
    [theme.breakpoints.down(tablet)]: {
      fontSize: '12px'
    }
  },
  wrapperButtonsTaxs: {
    marginTop: '26px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down(tablet)]: {
      display: 'none'
    },
    '& > button': {
      maxHeight: '35px',
      border: '1px solid',
      borderColor: blueColor
    },
    '& > button:nth-of-type(1)': {
      backgroundColor: blueColor
    },
    '& > button:nth-of-type(2)': {
      backgroundColor: 'transparent',
      color: blueColor
    }
  },
  wrapperButtonsTaxsMobile: {
    display: 'none',
    [theme.breakpoints.down(tablet)]: {
      display: 'flex',
      marginTop: '26px',
      alignItems: 'center',
      gap: '8px',
      flexWrap: 'wrap'
    },

    '& > button': {
      maxHeight: '35px',
      border: '1px solid',
      borderColor: blueColor,
      [theme.breakpoints.down(smallDevice)]: {
        maxWidth: '100%',
        width: '100%'
      }
    },
    '& > button:nth-of-type(1)': {
      backgroundColor: blueColor
    },
    '& > button:nth-of-type(2)': {
      backgroundColor: 'transparent',
      color: blueColor
    }
  },
  backButton: {
    paddingLeft: '0px',
    [theme.breakpoints.up(tablet)]: {
      display: 'none'
    }
  },
  pageTitle: {
    fontSize: '24px',
    fontWeight: 500
  },
  wrapperButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    [theme.breakpoints.down(tablet)]: {
      display: 'none',
      width: '100%',
      justifyContent: spaceBetween
    },
    '& > button': {
      border: '1px solid',
      borderColor: blueColor,
      minHeight: '48px',
      [theme.breakpoints.down(tablet)]: {
        width: '100%'
      }
    },
    '& > button:nth-of-type(1)': {
      backgroundColor: blueColor,
      color: '#fff'
    },
    '& > button:nth-of-type(2)': {
      backgroundColor: 'transparent',
      color: blueColor
    }
  },
  wrapperButtonsMobile: {
    display: 'none',
    alignItems: 'center',
    gap: '10px',
    [theme.breakpoints.down(tablet)]: {
      display: 'flex',
      width: '100%',
      justifyContent: spaceBetween
    },
    '& > button': {
      border: '1px solid',
      borderColor: blueColor,
      minHeight: '48px',
      [theme.breakpoints.down(tablet)]: {
        width: '100%'
      }
    },
    '& > button:nth-of-type(1)': {
      backgroundColor: blueColor,
      color: '#fff'
    },
    '& > button:nth-of-type(2)': {
      backgroundColor: 'transparent',
      color: blueColor
    }
  },
  wrapperTitleDialog: {
    width: '100%',
    maxWidth: '1440px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: spaceBetween,
    [theme.breakpoints.down(tablet)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '24px'
    }
  },
  wrapperTitle: {
    width: '100%',
    maxWidth: '1440px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: spaceBetween,
    [theme.breakpoints.down(tablet)]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  wrapperIcon: {
    [theme.breakpoints.down(tablet)]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  loadingContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
}))
