import React, { Component } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import DeleteIcon from '@material-ui/icons/Delete'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import SettingsIcon from '@material-ui/icons/Settings'
import ContactsIcon from '@material-ui/icons/Contacts'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import ColaboradoresDialogPage from 'pages/admin/painel/negocio/ColaboradoresDialogPage'
import ConfiguracoesGeraisDialogPage from 'pages/admin/painel/negocio/ConfiguracoesGeraisDialogPage'
import ConfiguracoesNFDialogPage from 'pages/admin/painel/negocio/ConfiguracoesNFDialogPage'
import PagamentosNegocioDialogPage from 'pages/admin/painel/negocio/PagamentosNegocioDialogPage'
import ImportacaoClientesNegocioDialogPage from 'pages/admin/painel/negocio/ImportacaoClientesNegocioDialogPage'
import PlanoDialogPage from 'pages/admin/painel/negocio/PlanoDialogPage'
import ValoresPlanosDialogPage from 'pages/admin/painel/negocio/ValoresPlanosDialogPage'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'
import DialogPage from 'support/components/page/DialogPage'
import PermissoesAcesso from 'support/domain/admin/usuario/PermissoesAcesso'
import AlteraDadosEnotasDialogPage from 'pages/admin/painel/negocio/AlteraDadosEnotasDialogPage'

const MAX_WIDTH_PAPER_DEFAULT = 720

const styles = (theme) => ({})

class NegocioDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
    this.eventsManager = EventsManager.new()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/negocio.buscarNegocio',
      params: {
        id: this.props.negocio.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          negocio: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handleConfiguracoesGerais = () => {
    openBackableDialog(ConfiguracoesGeraisDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handleConfiguracoesNF = () => {
    if (!verificarAutorizacao([PermissoesAcesso.PODE_ACESSAR_CONFIGURACOES_NF])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(ConfiguracoesNFDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handlePlano = () => {
    openBackableDialog(PlanoDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handleColaboradores = () => {
    openBackableDialog(ColaboradoresDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handlePagamentos = () => {
    openBackableDialog(PagamentosNegocioDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handleImportacaoClientes = () => {
    openBackableDialog(ImportacaoClientesNegocioDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handleAlterarDadosEnotas = () => {
    openBackableDialog(AlteraDadosEnotasDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handleGerenciarValoresPlano = () => {
    openBackableDialog(ValoresPlanosDialogPage, {
      parent: this,
      negocio: this.props.negocio
    })
  }

  handleApagarDadosFinanceiros = () => {
    openBackableDialog(EntityConfirmationDialog, {
      parent: this,
      title: 'Apagar financeiro',
      text: <span>Você tem certeza que deseja cancelar este atendimento? Essa operação não poderá ser desfeita.</span>,
      textToConfirm: 'APAGAR TODO FINANCEIRO',
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'admin/negocios.removerDadosFinanceiro',
        contextoUsuario: 'admin',
        data: {
          idNegocio: this.props.negocio.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('ManipulacaoNegocio')
        },
        errorKey: 'caixa'
      }
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoNegocio', (props) => {
      this.loaderFunctionsMap.reload()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { negocio } = this.state

    let maxWidth = MAX_WIDTH_PAPER_DEFAULT

    return (
      <DialogPage
        {...dialogProps}
        title={negocio ? negocio.nome : ''}
        ajaxing={this.state.ajaxing}
        align="center"
        contentMaxWidth={maxWidth}
        pageType="basicEntity"
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => (
          <ContentWithPreload functionsMap={this.loaderFunctionsMap} loadFunction={this.carregarMain} loadContentPadding={true}>
            {() => (
              <div>
                <List disablePadding component="nav" style={{ marginTop: 2, cursor: 'pointer' }}>
                  <ListItem disableGutters onClick={() => this.handleConfiguracoesGerais()}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configurações Gerais" secondary="Habilite ou desabilite o fluxo de caixa do negócio." />
                  </ListItem>
                  <ListItem disableGutters onClick={() => this.handleConfiguracoesNF()}>
                    <ListItemIcon>
                      <ReceiptLongIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configurações NF" secondary="Configuração de Nota Fiscal." />
                  </ListItem>
                  <ListItem disableGutters onClick={() => this.handlePlano()}>
                    <ListItemIcon>
                      <BookmarkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Plano" secondary="Altere o tipo do plano e data de vencimento do negócio." />
                  </ListItem>
                  <ListItem disableGutters onClick={() => this.handleColaboradores()}>
                    <ListItemIcon>
                      <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Colaboradores" secondary="Colaboradores do negócio." />
                  </ListItem>
                  <ListItem disableGutters onClick={() => this.handleGerenciarValoresPlano()}>
                    <ListItemIcon>
                      <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Valores" secondary="Gerencie os valores do plano do estabelecimento." />
                  </ListItem>
                  <ListItem disableGutters onClick={() => this.handlePagamentos()}>
                    <ListItemIcon>
                      <BookmarkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pagamentos" secondary="Pagamentos realizados pelo negócio." />
                  </ListItem>
                  <ListItem disableGutters onClick={() => this.handleImportacaoClientes()}>
                    <ListItemIcon>
                      <ContactsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Importação de Clientes" secondary="Importe clientes através de uma planilha" />
                  </ListItem>
                  <ListItem disableGutters onClick={() => this.handleAlterarDadosEnotas()}>
                    <ListItemIcon>
                      <ReceiptLongIcon />
                    </ListItemIcon>
                    <ListItemText primary="Alterar Dados E-notas" secondary="Altere dados do negócio no e-notas" />
                  </ListItem>
                  {verificarAutorizacao([PermissoesAcesso.PODE_APAGAR_DADOS_FINANCEIROS]) && (
                    <ListItem disableGutters onClick={() => this.handleApagarDadosFinanceiros()}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Apagar dados financeiros"
                        secondary="Apague todos os dados financeiros do negócio, permanecendo apenas clientes, produtos, pacotes e colaboradores."
                      />
                    </ListItem>
                  )}
                </List>
              </div>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

NegocioDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NegocioDialogPage)
