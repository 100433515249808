import React, { Component } from 'react'

import InputMaterialUI from '@material-ui/core/Input'
import InputBase from '@material-ui/core/InputBase'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import FormUtil from 'support/util/FormUtil'

export const NAKED_MARGIN_LEFT = 6
export const OUTLINED_MARGIN_LEFT = 14
export const OUTLINED_SMALL_MARGIN_LEFT = 12

const styles = (theme) => ({
  inputBase: {
    height: 'auto'
  },
  inputUnderlineTransparent: {
    '&:before': {
      borderBottom: '1px solid transparent'
    }
  },
  inputOutlinedSingleline: {
    paddingLeft: OUTLINED_MARGIN_LEFT,
    '&:focus': {
      border: '0px solid !important'
    }
  },
  inputOutlinedMultiline: {
    paddingLeft: OUTLINED_MARGIN_LEFT,
    paddingTop: 13,
    paddingRight: OUTLINED_MARGIN_LEFT,
    paddingBottom: 12
  },
  contanierOutlinedMultiline: {
    padding: '6px 0px'
  },
  inputOutlinedSmallSingleline: {
    padding: '14px 12px 12px ' + OUTLINED_SMALL_MARGIN_LEFT + 'px',
    fontSize: 14
  },
  inputOutlinedSmallMultiline: {
    padding: '9px 12px 6px ' + OUTLINED_SMALL_MARGIN_LEFT + 'px',
    fontSize: 14,
    lineHeight: '18px'
  },
  contanierOutlinedSmallMultiline: {
    padding: '6px 0px',
    fontSize: 14
  },
  focus: {},
  error: {},
  inputOutlinednotchedOutlineFocusEffectDisabled: {
    '$inputBase$focus &, $inputBase:hover$focus &': {
      borderWidth: 1
    },
    '$inputBase$focus:not($error) &, $inputBase:hover$focus:not($error) &': {
      border: '1px solid ' + theme.inputs.borderColor
    }
  },
  inputFilled: {
    backgroundColor: theme.inputs.filledBackgroundColor,
    borderRadius: 3,
    paddingLeft: theme.inputs.filledMarginLeft,
    paddingTop: 3,
    paddingBottom: 2,
    borderBottom: '0px solid !important',
    '&:before, *:hover &:before': {
      borderBottom: '0px solid !important'
    },
    '&:after': {
      borderBottom: '0px solid !important'
    },
    '&:hover': {
      borderBottom: '0px solid !important'
    },
    '&:focus': {
      borderBottom: '0px solid !important'
    }
  },
  inputMultilineFilled: {
    paddingTop: 6,
    paddingBottom: 6
  },
  inputMultilineExtraVerticalPaddingFilled: {
    paddingTop: 9,
    paddingBottom: 9
  },
  inputNakedRoot: {
    borderRadius: 4,
    paddingLeft: NAKED_MARGIN_LEFT,
    paddingTop: 1,
    paddingRight: 6
  },
  inputNakedMultilineRoot: {
    borderRadius: 4,
    paddingLeft: NAKED_MARGIN_LEFT,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 6
  },
  inputMultilineNakedRoot: {
    paddingTop: 7,
    paddingBottom: 7
  },
  inputNakedError: {
    backgroundColor: '#fff0f0'
  }
})

class Input extends Component {
  removeUnrecognizedProps = (props) => {
    delete props.customVariant
    delete props.nakedLeftPadding
    delete props.focusEffectDisabled
    delete props.shrink
    delete props.errorMessage
    delete props.helpMessage
    delete props.helperText
    delete props.extraVerticalPadding
  }

  render() {
    const { classes, InputProps, inputProps, inputStyle, inputRef, inputComponent, endAdornmentOverride, maxLength, ...othersInputProps } = this.props

    let finalInputProps = othersInputProps
    if (this.props.InputProps) {
      finalInputProps = Object.assign(finalInputProps, this.props.InputProps)
    }

    const inputContainerClasses = []
    if (this.props.InputProps && this.props.InputProps.className) {
      inputContainerClasses.push(finalInputProps.className)
    }

    if (finalInputProps.style) {
      finalInputProps.style = Object.assign({}, finalInputProps.style)
    }

    finalInputProps.className = FormUtil.errorClass(this.props.errorMessage)

    if (!finalInputProps.inputProps) {
      finalInputProps.inputProps = {}
    }

    if (maxLength) {
      finalInputProps.inputProps.maxLength = maxLength
    }

    finalInputProps.autoComplete = FormUtil.autoCompleteValueOff
    finalInputProps.inputProps.autoComplete = FormUtil.autoCompleteValueOff

    if (inputProps !== undefined) {
      finalInputProps.inputProps = Object.assign(finalInputProps.inputProps, inputProps)
      //if(inputRef!==undefined){
      //	finalInputProps.inputProps.ref = inputRef;
      //}
    } /*}else */
    if (inputRef !== undefined) {
      finalInputProps.inputProps.ref = inputRef
    }

    if (inputComponent !== undefined) {
      finalInputProps.inputComponent = inputComponent
    }

    if (endAdornmentOverride !== undefined) {
      finalInputProps.endAdornment = endAdornmentOverride
    }

    finalInputProps.error = FormUtil.errorFlag(this.props.errorMessage) || this.props.error

    this.removeUnrecognizedProps(finalInputProps)

    let inputElement = null

    let inputMultilineClass = null
    if (this.props.customVariant === 'filled') {
      if (this.props.extraVerticalPadding) {
        inputMultilineClass = classes.inputMultilineExtraVerticalPaddingFilled
      } else {
        inputMultilineClass = classes.inputMultilineFilled
      }
    }

    if (!this.props.customVariant || this.props.customVariant === 'standard' || this.props.customVariant === 'filled') {
      if (this.props.customVariant === 'filled') {
        inputContainerClasses.push(classes.inputFilled)
      }

      inputElement = (
        <InputMaterialUI
          {...finalInputProps}
          classes={{
            root: classNames(inputContainerClasses),
            input: classes.inputBase,
            inputMultiline: inputMultilineClass,
            underline: this.props.customVariant === 'filled' ? classes.inputUnderlineTransparent : null
          }}
        />
      )
    } else if (this.props.customVariant === 'naked') {
      if (this.props.nakedLeftPadding === false) {
        if (!finalInputProps.style) {
          finalInputProps.style = {}
        }
        finalInputProps.style.marginLeft = NAKED_MARGIN_LEFT * -1
      }
      inputContainerClasses.push(classes.inputNakedRoot)
      let _inputClasses = [classes.inputBase]
      if (this.props.multiline) {
        inputContainerClasses.push(classes.inputNakedMultilineRoot)
        _inputClasses.push(classes.inputMultilineNakedRoot)
      }
      inputElement = <InputBase {...finalInputProps} classes={{ root: classNames(inputContainerClasses), input: classNames(_inputClasses), error: classes.inputNakedError }} />
    } else if (this.props.customVariant === 'outlined' || this.props.customVariant === 'outlined-small') {
      inputContainerClasses.push(classes.inputBase)
      const _inputClasses = [classes.inputBase]
      const _multilineClasses = []
      if (this.props.customVariant === 'outlined-small') {
        if (this.props.multiline) {
          _inputClasses.push(classes.inputOutlinedSmallMultiline)
        } else {
          _inputClasses.push(classes.inputOutlinedSmallSingleline)
        }
        _multilineClasses.push(classes.contanierOutlinedSmallMultiline)
      } else {
        if (this.props.multiline) {
          _inputClasses.push(classes.inputOutlinedMultiline)
        } else {
          _inputClasses.push(classes.inputOutlinedSingleline)
        }
        _multilineClasses.push(classes.contanierOutlinedMultiline)
      }

      let notchedOutlineClassName = null
      if (this.props.focusEffectDisabled === true) {
        notchedOutlineClassName = classes.inputOutlinednotchedOutlineFocusEffectDisabled
      }

      inputElement = (
        <OutlinedInput
          {...finalInputProps}
          labelWidth={0}
          notched={false}
          classes={{
            root: classNames(inputContainerClasses),
            input: classNames(_inputClasses),
            multiline: classNames(_multilineClasses),
            notchedOutline: notchedOutlineClassName,
            focused: classes.focus,
            error: classes.error
          }}
        />
      )
    }

    return inputElement
  }
}

Input.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Input)
