import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'

function gerarDescricaoInline(formaPagamento, adquirente, bandeira, numeroParcelas) {
  if (
    FormaPagamentoTipoEnum.CARTAO_CREDITO.id === formaPagamento.tipo.id ||
    FormaPagamentoTipoEnum.CARTAO_DEBITO.id === formaPagamento.tipo.id ||
    FormaPagamentoTipoEnum.CHEQUE.id === formaPagamento.tipo.id
  ) {
    let descricao = formaPagamento.nome
    if (numeroParcelas && FormaPagamentoTipoEnum.CARTAO_CREDITO.id === formaPagamento.tipo.id) {
      descricao += ' (' + (numeroParcelas === 1 ? 'À vista' : numeroParcelas + 'x') + ')'
    }
    if (numeroParcelas && FormaPagamentoTipoEnum.CHEQUE.id === formaPagamento.tipo.id) {
      descricao += numeroParcelas > 1 ? ' (' + numeroParcelas + 'x)' : ''
    }
    return descricao
  } else {
    return formaPagamento.nome
  }
}

const functionsObject = {
  gerarDescricaoInline: gerarDescricaoInline
}

export default functionsObject
