import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import classNames from 'classnames'
import NegocioSupport from 'pages/erp/painel/negocio/NegocioSupport'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import VendaPopup from 'pages/erp/painel/venda/VendaPopup'
import PropTypes from 'prop-types'
import { openBackablePopup } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AtendimentoStatusIcon from 'support/components/icon/AtendimentoStatus'
import VendaStatusIcon from 'support/components/icon/VendaStatus'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 80,
    minWidth: 80
  },
  colunaHorario: {
    flexGrow: 1,
    maxWidth: 128,
    minWidth: 128
  },
  colunaDataHora: {
    flexGrow: 1,
    maxWidth: 108,
    minWidth: 108
  },
  colunaCliente: {
    flexGrow: 1.1
  },
  colunaDescricao: {
    flexGrow: 1.2
  },
  colunaDescricaoMedium: {
    flexGrow: 1.2,
    overflow: 'hidden'
  },
  colunaIconeStatusNota: {
    maxWidth: 80,
    minWidth: 80
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  iconeStatusNota: {
    display: 'block',
    fontSize: 26,
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  },
  hide: {
    display: 'none'
  },
  singleLine: theme.text.singleLine()
})

class AtendimentosResponsiveTable extends Component {
  constructor(props) {
    super(props)
    this.podeVisualizarValorVenda = verificarAutorizacao([PA.PODE_VISUALIZAR_VALOR_VENDA]) ? true : false
  }

  getRenderContent = (size, atendimento) => {
    const content = {}

    content.data = converterDataIntParaMoment(atendimento.data).format('DD/MMM/YY')

    if (atendimento.tipo.id === AtendimentoTipoEnum.ORDEM_CHEGADA.id) {
      content.horario = formatarHorarioInt(atendimento.horarioEntradaFila)
      if (size === 'medium') {
        content.horario = 'Chegada:' + content.horario
      }
    } else if (atendimento.tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id) {
      content.horario = formatarHorarioInt(atendimento.horarioInicio) + ' - ' + formatarHorarioInt(atendimento.horarioTermino)
    }

    content.nomeCliente = atendimento.venda.cliente.apelido

    content.descricao = atendimento.servico.nome
    if (atendimento.observacoes) {
      content.descricao = '*' + content.descricao
    }

    content.nomeColaborador = atendimento.venda.colaborador ? atendimento.venda.colaborador.apelido : null

    if (this.podeVisualizarValorVenda) {
      content.descricaoValor = VendaService.getDescricaoPreco(atendimento.venda, {
        format: size === 'medium' || size === 'small' ? 'minificado' : null,
        quebraLinhaUnidades: true
      })
    }

    content.icons = []
    if (atendimento.lembreteManualEnviado) {
      content.icons.push(
        <Tooltip title="Lembrete enviado">
          <SendIcon style={{ fontSize: 20 }} />
        </Tooltip>
      )
    }

    if (atendimento.venda.clientePacoteItem && !atendimento.venda.cancelado) {
      content.icons.push(
        <Tooltip title="Crédito de Pacote">
          <ShoppingBasketIcon style={{ fontSize: 20 }} />
        </Tooltip>
      )
    }

    if (AtendimentoStatusEnum.MARCADO.id !== atendimento.status.id) {
      content.icons.push(<AtendimentoStatusIcon status={atendimento.status} size="small" hasTooltip={true} />)
    }
    if (StatusVendaEnum.NOTA_PENDENTE.id !== atendimento.venda.status.id && !atendimento.venda.cancelado) {
      content.icons.push(<VendaStatusIcon status={atendimento.venda.status} size="small" hasTooltip={true} />)
    }

    content.icons = content.icons.map((icon, index) => (
      <div key={index} style={{ display: 'inline-block', padding: '0px 2px' }}>
        {icon}
      </div>
    ))

    if (size === 'medium') {
      content.data = (
        <span>
          {content.data}
          <br />
          <span style={{ fontSize: 12, opacity: 0.6 }}>{content.horario}</span>
        </span>
      )
    }

    return content
  }

  abrirVendaPopup = (event, atendimento) => {
    event.stopPropagation()

    const clientX = event.clientX
    const clientY = event.clientY

    NegocioSupport.tentarExibirAvisoImportante({
      onIgnore: () => {
        openBackablePopup(VendaPopup, {
          clientX: clientX,
          clientY: clientY,
          anchorReference: 'anchorPosition',
          atendimento: atendimento
        })
      }
    })
  }

  render() {
    const { classes, origem, ...others } = this.props

    const largeColunaClienteClasses = [classes.colunaCliente]
    const colunasIconesClasses = [classes.colunaIconeStatusNota]

    const isOrigemCliente = origem === 'cliente' ? true : false
    if (isOrigemCliente) {
      largeColunaClienteClasses.push(classes.hide)
    }

    let nomeColunaHorario = ''
    if (this.props.contexto === 'filaespera') {
      nomeColunaHorario = 'Chegada'
      colunasIconesClasses.push(classes.hide)
    } else if (this.props.contexto === 'horamarcada') {
      nomeColunaHorario = 'Horário'
    }

    return (
      <React.Fragment>
        <VirtualizedResponsiveTable
          showBackgroundDividers={true}
          onClickRow={(item, event) => {
            this.abrirVendaPopup(event, item)
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: nomeColunaHorario, className: classes.colunaHorario },
              { label: 'Cliente', className: classNames(largeColunaClienteClasses), horizontalPadding: 'small', props: { xs: true } },
              { label: 'Descrição', className: classes.colunaDescricao, props: { xs: true } },
              { label: 'Profissional', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right', display: this.podeVisualizarValorVenda },
              { label: '', className: classNames(colunasIconesClasses), align: 'center', isVisible: false }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [
                  content.data,
                  content.horario,
                  content.nomeCliente,
                  content.descricao,
                  content.nomeColaborador ? content.nomeColaborador : '-',
                  content.descricaoValor,
                  content.icons
                ]
              }
            }
          }}
          mediumRenderProps={{
            headerColumnHeight: 52,
            rowHeight: 88,
            columns: [
              { label: 'Data/Horário', className: classes.colunaDataHora },
              { label: '', className: classes.colunaIconeTipoVenda, isVisible: false, horizontalPadding: 'small' },
              { label: 'Atendimento', className: classes.colunaDescricaoMedium, horizontalPadding: 'small', props: { xs: true } },
              { label: 'Profissional', showOnlyUp: 'xs', props: { xs: true } },
              { label: '', className: classNames(colunasIconesClasses), align: 'center', horizontalPadding: 'small', isVisible: false }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('medium', item)

              let descricaoContent = null
              if (origem === 'cliente') {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body2" style={{ fontWeight: 500 }} noWrap={true}>
                      {content.descricao}
                    </Typography>
                    {this.podeVisualizarValorVenda && (
                      <Typography variant="body2" className={classes.singleLine}>
                        {content.descricaoValor}
                      </Typography>
                    )}
                  </React.Fragment>
                )
              } else {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body2" style={{ fontWeight: 500 }} noWrap={true}>
                      {content.nomeCliente}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.descricao}
                    </Typography>
                    {this.podeVisualizarValorVenda && (
                      <Typography variant="body2" className={classes.singleLine}>
                        {content.descricaoValor}
                      </Typography>
                    )}
                  </React.Fragment>
                )
              }

              return {
                itemData: [content.data, content.iconeTipoVenda, descricaoContent, content.nomeColaborador ? content.nomeColaborador : '-', content.icons]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 108,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              let descricaoContent = null
              if (origem === 'cliente') {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body1" noWrap={true}>
                      {content.descricao}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      <b>{content.nomeColaborador}</b>
                    </Typography>
                  </React.Fragment>
                )
              } else {
                descricaoContent = (
                  <React.Fragment>
                    <Typography variant="body1" noWrap={true}>
                      <b>{content.nomeCliente}</b>
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.descricao}
                    </Typography>
                    {content.nomeColaborador && (
                      <Typography variant="body2" noWrap={true}>
                        <b>Profissional:</b> {content.nomeColaborador}
                      </Typography>
                    )}
                  </React.Fragment>
                )
              }

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs style={{ minWidth: 0 /*minWidth - para ativar as reticências */ }}>
                        <Typography variant="body2">{content.data}</Typography>
                        {descricaoContent}
                      </Grid>
                      <Grid item align="right">
                        {this.podeVisualizarValorVenda && (
                          <Typography variant="body2" style={{ fontWeight: 500 }}>
                            {content.descricaoValor}
                          </Typography>
                        )}
                        <div style={{ fontAlign: 'center', marginTop: 8 }}>{content.icons}</div>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          {...others}
        />
      </React.Fragment>
    )
  }
}

AtendimentosResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtendimentosResponsiveTable)
