import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_CONTA_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/ApiClient'
import InputText from 'support/components/input/InputText'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  root: theme.page.parentCenteredPaper,
  paper: theme.page.paper({ maxWidth: 400 })
})

class VerificarEmail extends Component {
  constructor(props) {
    super(props)
    this.token = this.getTokenFromURL()
    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      showPassword: false,
      ajaxing: true,
      formCarregado: false,
      senhaAlterada: false,
      tokenIncorreto: false,
      erroNaoEsperado: false
    }
    this.inputSenha = React.createRef()
    this.inputSenhaConfirmacao = React.createRef()
  }

  componentDidMount() {
    this.carregarForm()
  }

  getTokenFromURL() {
    let partesCaminhosURL = window.location.pathname.split('/')
    return decodeURIComponent(partesCaminhosURL[partesCaminhosURL.length - 1])
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  carregarForm = () => {
    this.setState((state) => ({
      ajaxing: true,
      tokenIncorreto: false,
      erroNaoEsperado: false
    }))

    getAPI({
      url: 'cda/usuarios.verificarTokenRedefinicaoSenha',
      params: {
        token: this.token
      },
      onSuccess: (response) => {
        this.setState((state) => ({ formCarregado: true }))
      },
      onError: (response) => {
        if (response.code >= 400 && response.code < 500) {
          this.setState((state) => ({ tokenIncorreto: true }))
        } else {
          this.setState((state) => ({ erroNaoEsperado: true }))
        }
      },
      onPreFinal: () => {
        this.setState((state) => ({ ajaxing: false }))
      }
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState((state) => ({
      ajaxing: true
    }))

    postAPI({
      url: 'cda/usuarios.redefinirSenha',
      data: {
        token: this.token,
        senha: this.inputSenha.current.value,
        senhaConfirmacao: this.inputSenhaConfirmacao.current.value
      },
      onSuccess: (response) => {
        this.setState((state) => ({ senhaAlterada: true }))
      },
      onPreFinal: () => {
        this.setState((state) => ({ ajaxing: false }))
      },
      onClientError: (response) => {
        this.setState((state) => ({ errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formRedefinirSenha')
      }
    })
  }

  render() {
    const { classes } = this.props
    const { errorsMap } = this.state

    let container

    if (this.state.formCarregado === false) {
      if (this.state.ajaxing === true) {
        container = (
          <div>
            <Typography variant="subtitle1" style={{ marginTop: 28, fontSize: '18px' }}>
              Carregando...
            </Typography>
          </div>
        )
      } else if (this.state.erroNaoEsperado === true) {
        container = (
          <div>
            <Typography variant="subtitle1" style={{ marginTop: 20 }}>
              Ocorreu um erro inesperado durante o carregamento desta página. Clique no botão abaixo e tente novamente.
            </Typography>
            <Button disabled={this.state.ajaxing} variant="contained" color="primary" size="large" style={{ marginTop: 24 }} type="button" onClick={this.carregarForm}>
              {this.state.ajaxing ? 'CARREGANDO...' : 'REDEFINIR SENHA'}
            </Button>
          </div>
        )
      } else if (this.state.tokenIncorreto === true) {
        container = (
          <Typography variant="subtitle1" style={{ marginTop: 20 }}>
            Não é possível redefinir a senha.
            <br />
            URL Inválida.
          </Typography>
        )
      }
    } else {
      if (this.state.senhaAlterada === false) {
        container = (
          <div>
            <Typography variant="h5" style={{ marginTop: 22 }}>
              Redefina a sua Senha
            </Typography>
            <form style={{ textAlign: 'center' }} id="formRedefinirSenha" noValidate autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                style={{ marginTop: 36 }}
                autoFocus={true}
                id="xsenha"
                name="xsenha"
                shrink={false}
                customVariant="outlined"
                disabled={this.state.ajaxing}
                type={this.state.showPassword ? 'text' : 'password'}
                errorMessage={errorsMap('senha')}
                helpMessage="Use oito ou mais caracteres com uma combinação de letras e números"
                label="Nova Senha"
                inputRef={this.inputSenha}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <InputText
                style={{ marginTop: 18 }}
                id="xsenhaConfirmacao"
                name="xsenhaConfirmacao"
                shrink={false}
                customVariant="outlined"
                disabled={this.state.ajaxing}
                type={this.state.showPassword ? 'text' : 'password'}
                errorMessage={errorsMap('senhaConfirmacao')}
                label="Confirmar senha"
                inputRef={this.inputSenhaConfirmacao}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Button disabled={this.state.ajaxing} style={{ marginTop: 36 }} variant="contained" color="primary" size="large" type="submit">
                {this.state.ajaxing ? 'CARREGANDO' : 'REDEFINIR SENHA'}
              </Button>
            </form>
          </div>
        )
      } else {
        container = (
          <div>
            <Typography variant="subtitle1" style={{ marginTop: 22 }}>
              A sua senha foi alterada com sucesso!
            </Typography>
            <Link to={ROUTE_CONTA_MAIN + '/login'} style={{ textDecoration: 'none' }}>
              <Button style={{ marginTop: 26 }} variant="contained" color="primary" size="large">
                VOLTAR PARA O LOGIN
              </Button>
            </Link>
          </div>
        )
      }
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <div style={{ textAlign: 'center', marginTop: 4 }}>
            <img src={logo} width="100" alt="Salão99" />
            {container}
          </div>
        </Paper>
      </div>
    )
  }
}

VerificarEmail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VerificarEmail)
