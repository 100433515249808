import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import TransferirPagamentoNegocioDialog from 'pages/admin/painel/negocio/TransferirPagamentoNegocioDialog'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/admin/usuario/PermissoesAcesso'
import PagamentoPeriodoPlanoEnum from 'support/domain/negocio/PagamentoPeriodoPlanoEnum'
import PagamentoStatusEnum from 'support/domain/negocio/PagamentoStatusEnum'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  errorText: {
    color: theme.palette.text.negative
  },
  colunaId: {
    flexGrow: 1,
    maxWidth: 100,
    minWidth: 60
  },
  colunaDataHora: {
    flexGrow: 1,
    maxWidth: 120,
    minWidth: 100
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 80,
    minWidth: 60
  },
  colunaPlano: {
    flexGrow: 1,
    maxWidth: 140,
    minWidth: 100
  },
  colunaSituacao: {
    flexGrow: 1,
    maxWidth: 80,
    minWidth: 60
  },
  colunaObservacao: {
    flexGrow: 1,
    maxWidth: 200,
    minWidth: 160
  },
  colunaTipo: {
    flexGrow: 1,
    maxWidth: 100,
    minWidth: 80
  },
  colunaIcone: {
    minWidth: 60,
    maxWidth: 60
  }
})

class PagamentosNegocioDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      title: 'Pagamentos',
      lista: [],
      ajaxing: false
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoPagamento', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  getRequestParameters = () => {
    return {
      idNegocio: this.props.negocio.id
    }
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  getRenderContent = (size, item) => {
    const content = {}

    const dataCriacaoAsMoment = moment(item.dataCriacao)

    content.id = item.idPagamentoAprovadoMp ?? '-'
    content.dataCriacao = dataCriacaoAsMoment.format('DD/MM/YY HH:mm')
    content.situacao = PagamentoStatusEnum.getById(item.status.id).nome
    content.plano = PlanoEnum.getById(item.pagamentoPlano.plano.id).nome + ' - ' + PagamentoPeriodoPlanoEnum.getById(item.pagamentoPlano.periodo.id).descricao
    content.observacao = item.observacao ?? '-'
    content.formaPagamento = item.formaPagamentoUtilizado === 'CARTAO_TOKENIZADO' ? 'Recorrente' : 'Link'
    content.valor = formatarValorMonetario(item.valor, { prefix: false })

    content.botaoOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -4 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ pagamentoMenuAnchorEl: event.currentTarget, itemMenu: item })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )

    return content
  }

  transferirPagamento = () => {
    if (!verificarAutorizacao([PA.PODE_TRANSFERIR_PAGAMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(TransferirPagamentoNegocioDialog, {
      idPagamento: this.state.itemMenu.id,
      idNegocioOrigem: this.props.negocio.id
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage {...dialogProps} title="Histórico de Pagamentos" align="center" pageType="basicForm" contentMaxWidth={880} ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <div>
              <VirtualizedResponsiveTable
                marginTop={20}
                scrollElement={dialogContentProps.scrollContainerRef.current}
                showBackgroundDividers={true}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="admin"
                endpoint="admin/negocios.buscarPagamentosPlanos"
                getRequestParametersFunction={this.getRequestParameters}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    this.setState({ lista: data.items })
                  }
                }}
                largeRenderProps={{
                  columns: [
                    { label: 'ID', className: classes.colunaId, horizontalPadding: 'small' },
                    { label: 'Data Criação', className: classes.colunaDataHora },
                    { label: 'Situação', className: classes.colunaSituacao, horizontalPadding: 'small' },
                    { label: 'Plano', className: classes.colunaPlano, props: { xs: true }, horizontalPadding: 'small' },
                    { label: 'Tipo', className: classes.colunaTipo, horizontalPadding: 'small' },
                    { label: 'Observação', className: classes.colunaObservacao, props: { xs: true }, horizontalPadding: 'small' },
                    { label: 'Valor (R$)', className: classes.valor, align: 'right' },
                    { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                  ],
                  itemRenderer: (pagamento, index) => {
                    const content = this.getRenderContent('large', pagamento)
                    return {
                      itemData: [content.id, content.dataCriacao, content.situacao, content.plano, content.formaPagamento, content.observacao, content.valor, content.botaoOpcoes]
                    }
                  }
                }}
                smallRenderProps={{
                  rowHeight: 150,
                  itemRenderer: (pagamento, index) => {
                    const content = this.getRenderContent('small', pagamento)
                    return {
                      itemData: (
                        <div>
                          <Typography variant="body1">{content.plano}</Typography>

                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography variant="body2">
                                <b>ID:</b> {content.id}
                              </Typography>
                              <Typography variant="body2">
                                <b>Data:</b> {content.dataCriacao}
                              </Typography>
                              <Typography variant="body2">
                                <b>Situação:</b> {content.situacao}
                              </Typography>
                              {content.observacao && (
                                <Typography variant="body2">
                                  <b>Observação:</b> {content.observacao}
                                </Typography>
                              )}
                              <Typography variant="body2">
                                <b>Tipo:</b> {content.formaPagamento}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">
                                <small>VALOR</small>
                                <br />
                                <b>R$ {content.valor}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    }
                  }
                }}
                emptyListProps={{
                  align: 'center',
                  text: 'Nenhum pagamento aprovado foi encontrado'
                }}
                listFunctionsMap={this.virtualizedListFunctionMap}
              />
            </div>

            <Menu anchorEl={this.state.pagamentoMenuAnchorEl} open={Boolean(this.state.pagamentoMenuAnchorEl)} onClose={() => this.closeMenu('pagamentoMenuAnchorEl')}>
              <MenuItem
                onClick={() => {
                  this.transferirPagamento()
                  this.closeMenu('pagamentoMenuAnchorEl')
                }}
              >
                Transferir Pagamento
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

PagamentosNegocioDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PagamentosNegocioDialogPage)
