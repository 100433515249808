import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ServicoNFDialogPage from 'pages/admin/painel/notafiscal/ServicoNFDialogPage'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/admin/usuario/PermissoesAcesso'
import ConsultaMunicipiosNFDialogPage from 'pages/admin/painel/notafiscal/ConsultaMunicipiosNFDialogPage'
import ConsultaDadosNFEnotasDialogPage from 'pages/admin/painel/notafiscal/ConsultaDadosNFEnotasDialogPage'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  },
  colunaDescricao: {
    flexGrow: 3
  }
})

class ServicosNFPanelPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false,
      lista: []
    }

    this.loaderFunctionsMap = {}
    this.inputFiltro = React.createRef()
    this.eventsManager = EventsManager.new()
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoNFsServico', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  closeItemMenu() {
    this.setState((state) => ({
      menuMaisOpcoesAnchorEl: null
    }))
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.descricao = item.descricao
    content.codigoIbge = item.codigoIbgeCidade
    content.codigoServicoMunicipio = item.codigoServicoMunicipio
    content.descricaoServicoMunicipio = item.descricaoServicoMunicipio
    content.itemListaServicoLc116 = item.itemListaServicoLc116
    content.cnae = item.cnae

    content.botaoOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -4 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ servicosNfMenuAnchorEl: event.currentTarget, itemMenu: item })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )

    return content
  }

  handlerConsultaMunicipios = () => {
    openBackableDialog(ConsultaMunicipiosNFDialogPage)
  }

  handlerConsultaDadosEnotas = () => {
    openBackableDialog(ConsultaDadosNFEnotasDialogPage)
  }

  handlerAbrirServicoNF = (servicoNf) => {
    openBackableDialog(ServicoNFDialogPage, {
      idServicoNf: servicoNf.id
    })
  }

  getRequestParameters = () => {
    return {
      codigoIgbe: this.inputFiltro.current ? this.inputFiltro.current.value : null
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handlerOpenCreateDialog = () => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_SERVICOS_NF])) {
      openBackableDialog(ServicoNFDialogPage)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerRemover = () => {
    if (!verificarAutorizacao([PA.PODE_ALTERAR_SERVICOS_NF])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Serviço NF',
      text: (
        <span>
          Você tem certeza que deseja remover o serviço código ibge cidade <b>{this.state.itemMenu.codigoIbgeCidade}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'admin/nfs.removerServicoNf',
        contextoUsuario: 'admin',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoNFsServico')
        }
      }
    })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  render() {
    const { classes, ...others } = this.props

    let onClickRow = (servicoNF) => {
      this.handlerAbrirServicoNF(servicoNF)
    }

    return (
      <PanelPage
        {...others}
        title="Serviços Notas Fiscais"
        toolbarActions={{
          actions: [
            {
              icon: <AddIcon />,
              handleAction: this.handlerOpenCreateDialog
            },
            {
              icon: <MoreVertIcon />,
              handleAction: (event) => {
                this.setState({ menuMaisOpcoesAnchorEl: event.currentTarget })
              }
            }
          ]
        }}
      >
        <Grid container alignItems="center" justify="center">
          <Grid item xs>
            <InputText
              customVariant="naked"
              nakedLeftPadding={false}
              placeholder="Código IBGE"
              onChange={(event) => {
                this.loaderFunctionsMap.load()
              }}
              inputRef={this.inputFiltro}
            />
          </Grid>
        </Grid>
        <br />

        <VirtualizedResponsiveTable
          showBackgroundDividers={true}
          itemsPerRequest={20}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="admin"
          endpoint="admin/nfs.buscarServicosNf"
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={onClickRow}
          largeRenderProps={{
            columns: [
              { label: 'Descrição', className: classes.colunaDescricao, props: { xs: true } },
              { label: 'Código IBGE', props: { xs: true } },
              { label: 'Código Serviço Municipio', props: { xs: true } },
              { label: 'Descrição Serviço Municipio', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } },
              { label: 'Item Lista Servico Lc116', props: { xs: true } },
              { label: 'Cnae', horizontalPadding: 'small', align: 'right', props: { xs: true } },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [
                  content.descricao,
                  content.codigoIbge,
                  content.codigoServicoMunicipio,
                  content.descricaoServicoMunicipio,
                  content.itemListaServicoLc116,
                  content.cnae,
                  content.botaoOpcoes
                ]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 220,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center">
                      <Grid item xs style={{ minWidth: 0 }}>
                        <Typography variant="body2">
                          <b>Descrição:</b> {content.descricao}
                        </Typography>
                        <Typography variant="body2">
                          <b>Código IBGE:</b> {content.codigoIbge}
                        </Typography>
                        <Typography variant="body2">
                          <b>Código Serviço Município:</b> {content.codigoServicoMunicipio}
                        </Typography>
                        <Typography variant="body2">
                          <b>Descrição Serviço Município:</b> {content.descricaoServicoMunicipio}
                        </Typography>
                        <Typography variant="body2">
                          <b>Item Lista Lc116:</b> {content.itemListaServicoLc116}
                        </Typography>
                        <Typography variant="body2">
                          <b>Cnae:</b> {content.cnae}
                        </Typography>
                      </Grid>
                      <Grid item>{content.botaoOpcoes}</Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum serviço de nota fiscal foi encontrado'
          }}
        />

        <Menu anchorEl={this.state.servicosNfMenuAnchorEl} open={Boolean(this.state.servicosNfMenuAnchorEl)} onClose={() => this.closeMenu('servicosNfMenuAnchorEl')}>
          <MenuItem
            onClick={() => {
              this.handlerRemover()
              this.closeMenu('servicosNfMenuAnchorEl')
            }}
          >
            Remover
          </MenuItem>
        </Menu>

        <Menu anchorEl={this.state.menuMaisOpcoesAnchorEl} open={Boolean(this.state.menuMaisOpcoesAnchorEl)} onClose={() => this.closeMenu('menuMaisOpcoesAnchorEl')}>
          <MenuItem
            onClick={() => {
              this.handlerConsultaMunicipios()
              this.closeMenu('menuMaisOpcoesAnchorEl')
            }}
          >
            Consulta Municípios
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handlerConsultaDadosEnotas()
              this.closeMenu('menuMaisOpcoesAnchorEl')
            }}
          >
            Consulta Dados E-notas
          </MenuItem>
        </Menu>


      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ServicosNFPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServicosNFPanelPage)
