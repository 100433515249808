import { Component } from 'react'
import { isIOS } from 'react-device-detect'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NotificationsIcon from '@material-ui/icons/Notifications'
import RefreshIcon from '@material-ui/icons/Refresh'
import ShareIcon from '@material-ui/icons/Share'
import moment from 'moment'
import Agenda from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import AtendimentosListaPanelPageContent from 'pages/erp/painel/atendimento/AtendimentosListaPanelPageContent'
import AtendimentosNotificacoesUsuarioDialog from 'pages/erp/painel/atendimento/AtendimentosNotificacoesUsuarioDialog'
import FilaEsperaPanelPageContent from 'pages/erp/painel/atendimento/FilaEsperaPanelPageContent'
import { getSessaoPainel, verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import Banner from 'support/components/campanha/banner'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import PanelPage from 'support/components/panel/PanelPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { createEnum } from 'support/util/EnumUtil'

import AgendamentoOnlineTelaAtendimentoDialogPage from '../agendamentoonline/AgendamentoOnlineTelaAtendimentoDialogPage/AgendamentoOnlineTelaAtendimentoDialogPage'

export const PerspectivaEnum = createEnum({
  DIA: { id: 1, key: 'dia', descricao: 'Dia', descricaoCurta: 'Dia' },
  SEMANA: { id: 2, key: 'semana', descricao: 'Semana', descricaoCurta: 'Semana' },
  MES: { id: 3, key: 'mes', descricao: 'Mês', descricaoCurta: 'Mês' },
  LISTA: { id: 4, key: 'lista', descricao: 'Lista', descricaoCurta: 'Lista' },
  FILA_ESPERA: { id: 5, key: 'filaespera', descricao: 'Fila de Espera', descricaoCurta: 'Fila' }
})

const styles = () => ({
  mainTitle: {}
})

class AtendimentosPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_Perspectiva', PerspectivaEnum.DIA)
    let dataSelecionada = this.colaboradorLocalPreferences.getMomentPreference('AgendaInterface_DataSelecionada', moment())

    this.state = {
      paginaCarregada: false,
      dataSelecionada: dataSelecionada,
      colaboradores: [],
      perspectiva: this.colaboradorLocalPreferences.getPreference('AgendaInterface_Perspectiva', PerspectivaEnum.DIA),
      agendamentoOnlineConfig: null,
      shouldShowNPS: false
    }

    this.contentWithPreloadFunctions = {}
  }

  setPerspectiva = (perspectiva) => {
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_Perspectiva', perspectiva)
    this.setState({ perspectiva })

    const isFullScreen = !(perspectiva.id === PerspectivaEnum.LISTA.id || perspectiva.id === PerspectivaEnum.FILA_ESPERA.id)

    EventsManager.pub('PanelFullScreenMode', isFullScreen)
  }

  componentDidMount() {
    EventsManager.pub('PanelFullScreenMode', true)
    this.eventsManager.sub('ManipulacaoColaborador', () => {
      this.contentWithPreloadFunctions.refresh()
    })
    this.eventsManager.sub('RecarregarAtendimentos', () => {
      if (this.state.paginaCarregada === false || this.state.colaboradores.length === 0) {
        this.contentWithPreloadFunctions.refresh()
      }
    })
    this.eventsManager.sub('AlterarPerspectivaAgenda', (props) => {
      if (props.idColaboradorSelecionado) {
        for (let colaborador of this.state.colaboradores) {
          if (colaborador.id === props.idColaboradorSelecionado) {
            this.changeColaboradorSelecionado(colaborador)
            break
          }
        }
      }
      if (props.dataSelecionada) {
        this.changeDataSelecionada(props.dataSelecionada)
      }
      this.setPerspectiva(props.perspectiva)
    })
    this.eventsManager.sub('ShowNPS', () => {
      this.setState({ shouldShowNPS: true })
    })

    this.eventsManager.sub('ChangeUrlForNPS', () => {
      const isNPSEnabled = localStorage.getItem('s99_NPS') === 'true'
      if (isNPSEnabled && this.state.shouldShowNPS && window.KAMPYLE_ONSITE_SDK) {
        const url = new URL(window.location.href)
        url.searchParams.append('nps_dialog', true)
        window.history.replaceState(null, '', url)
        window.KAMPYLE_ONSITE_SDK.updatePageView()
      }
    })

    const baseURL = window.location.href
    window.addEventListener('popstate', function () {
      if (window.location.href === baseURL) {
        EventsManager.pub('ChangeUrlForNPS', true)
      }
    })
  }

  changeDataSelecionada = (data) => {
    this.colaboradorLocalPreferences.setMomentPreference('AgendaInterface_DataSelecionada', data)
    if (!this.state.dataSelecionada.isSame(data, 'day')) {
      this.setState({ dataSelecionada: data })
    }
  }

  changeColaboradorSelecionado = (colaborador) => {
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_ColaboradorSelecionado', colaborador)
    this.setState({ colaboradorSelecionado: colaborador })
  }

  setAgendamentoOnlineConfig = (agendamentoOnlineConfig) => {
    const deveSalvarAgendaOnLine =
      agendamentoOnlineConfig && agendamentoOnlineConfig.agendamentoOnlineHabilitado && agendamentoOnlineConfig.urlPortal.length > 0 && !agendamentoOnlineConfig.pendencias

    if (deveSalvarAgendaOnLine) {
      this.setState({ agendamentoOnlineConfig })
    }
  }

  carregarMain = async (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/atendimentos.buscarDadosCarregamentoPainelAtendimento',
      requerAutorizacao: true,
      subscriptionEnabled: !isIOS,
      useSubscription: getSessaoPainel().negocio.usaPagamentoRecorrente && !isIOS,
      onSuccess: (response) => {
        this.setAgendamentoOnlineConfig(response.data.configuracao)

        const colaboradores = response.data.colaboradores
        this.prepararColaboradores(colaboradores)

        let colaboradorSelecionadoPadrao = null
        for (let colaborador of colaboradores) {
          if (colaborador._show) {
            colaboradorSelecionadoPadrao = colaborador
            break
          }
        }
        if (!colaboradorSelecionadoPadrao) {
          colaboradorSelecionadoPadrao = colaboradores[0]
        }

        this.setState({
          paginaCarregada: true,
          colaboradorSelecionado: this.colaboradorLocalPreferences.getPreference('AgendaInterface_ColaboradorSelecionado', colaboradorSelecionadoPadrao),
          colaboradores: colaboradores
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararColaboradores = (colaboradores) => {
    const idsColaboradoresNaoVisiveisPreferencias = this.colaboradorLocalPreferences.getPreference('AgendaInterface_IdsColaboradoresNaoVisiveis', [])

    for (let i = 0; i < colaboradores.length; i++) {
      const colaborador = colaboradores[i]
      colaborador.horariosAtendimentoPorDia = {}
      if (colaborador.horariosAtendimento && colaborador.horariosAtendimento.length > 0) {
        colaborador.horariosAtendimento.sort((a, b) => {
          if (a.de < b.de) {
            return -1
          }
          if (a.de > b.de) {
            return 1
          }
          if (a.ate < b.ate) {
            return -1
          }
          if (a.ate > b.ate) {
            return 1
          }
          return 0
        })
        for (let i2 = 0; i2 < colaborador.horariosAtendimento.length; i2++) {
          const horarioAtendimento = colaborador.horariosAtendimento[i2]
          let horariosAtendimentoPorDia = colaborador.horariosAtendimentoPorDia[horarioAtendimento.dia]
          if (!horariosAtendimentoPorDia) {
            horariosAtendimentoPorDia = []
            colaborador.horariosAtendimentoPorDia[horarioAtendimento.dia] = horariosAtendimentoPorDia
          }
          horariosAtendimentoPorDia.push(horarioAtendimento)
        }
      }
      if (idsColaboradoresNaoVisiveisPreferencias.indexOf(colaborador.id) >= 0) {
        colaborador._show = false
      } else {
        colaborador._show = true
      }
    }
    colaboradores.sort((a, b) => {
      if (a.configuracaoAtendimento.posicao < b.configuracaoAtendimento.posicao) {
        return -1
      }
      if (a.configuracaoAtendimento.posicao > b.configuracaoAtendimento.posicao) {
        return 1
      }
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })

    this.ajustarColaboradores(colaboradores)
  }

  ajustarColaboradores = (colaboradores) => {
    let nenhumVisivel = true
    for (let i = 0; i < colaboradores.length; i++) {
      const colaborador = colaboradores[i]
      if (colaborador._show === true) {
        nenhumVisivel = false
        break
      }
    }
    if (nenhumVisivel) {
      for (let i = 0; i < colaboradores.length; i++) {
        const colaborador = colaboradores[i]
        colaborador._show = true
      }
    }
  }

  changeExibicaoColaborador = (idColaborador, show) => {
    const colaboradores = this.state.colaboradores.slice()
    const idsColaboradoresNaoVisiveis = []
    let nenhumVisivel = true
    let colaboradorAlterado = null
    for (let i = 0; i < colaboradores.length; i++) {
      const colaborador = colaboradores[i]
      if (colaborador.id === idColaborador) {
        colaboradorAlterado = colaborador
        colaboradorAlterado._show = show
      }
      if (colaborador._show === true) {
        nenhumVisivel = false
      }
    }
    if (nenhumVisivel === true && colaboradorAlterado) {
      colaboradorAlterado._show = true
    }
    for (let i = 0; i < colaboradores.length; i++) {
      const colaborador = colaboradores[i]
      if (colaborador._show === false) {
        idsColaboradoresNaoVisiveis.push(colaborador.id)
      }
    }
    this.colaboradorLocalPreferences.setPreference('AgendaInterface_IdsColaboradoresNaoVisiveis', idsColaboradoresNaoVisiveis, { type: 'permanent' })
    this.setState({ colaboradores: colaboradores })
  }

  abrirNotificacoes = () => {
    openBackableDialog(AtendimentosNotificacoesUsuarioDialog)
    if (this.props.usuarioEstadoAtualizado && this.props.usuarioEstadoAtualizado.notificacoesAtendimentosNaoLidas > 0) {
      EventsManager.pub('RecarregarAtendimentos')
    }
  }

  render() {
    const { classes, ...others } = this.props
    const { perspectiva, agendamentoOnlineConfig } = this.state

    const permissoAcessarProprioCalendario = verificarAutorizacao([PA.PODE_ACESSAR_PROPRIO_CALENDARIO])

    const actions = [
      {
        disabled: !permissoAcessarProprioCalendario,
        icon: <NotificationsIcon />,
        badgeValue: this.props.usuarioEstadoAtualizado ? this.props.usuarioEstadoAtualizado.notificacoesAtendimentosNaoLidas : null,
        handleAction: () => {
          this.abrirNotificacoes()
        }
      },
      {
        disabled: !permissoAcessarProprioCalendario,
        icon: <RefreshIcon />,
        handleAction: () => {
          EventsManager.pub('RecarregarAtendimentos')
        }
      }
    ]

    actions.push({
      menu: {
        value: {
          text: perspectiva.descricaoCurta
        },
        items: PerspectivaEnum.values().map((item) => ({ ...item, label: item.descricao })),
        onClick: (perspectiva) => {
          this.setPerspectiva(perspectiva)
        }
      }
    })

    if (agendamentoOnlineConfig) {
      actions.unshift({
        disabled: !permissoAcessarProprioCalendario,
        icon: <ShareIcon />,
        badgeValue: null,
        handleAction: () => {
          openBackableDialog(AgendamentoOnlineTelaAtendimentoDialogPage, { url: agendamentoOnlineConfig.urlPortal })
        }
      })
    }

    return (
      <PanelPage
        {...others}
        title={<span className={classes.mainTitle}>Atendimentos</span>}
        toolbarRetractable={false}
        toolbarActions={{
          actions: actions
        }}
      >
        <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadContentPadding={true} loadFunction={this.carregarMain}>
          {() => {
            const { colaboradores } = this.state

            if (colaboradores && colaboradores.length >= 1) {
              const { colaboradorSelecionado, dataSelecionada } = this.state
              const { changeDesktopDrawerOpen, changeToolbarExpanded, toolbarExpanded } = this.props
              return (
                <>
                  {(perspectiva.id === PerspectivaEnum.DIA.id || perspectiva.id === PerspectivaEnum.SEMANA.id || perspectiva.id === PerspectivaEnum.MES.id) && (
                    <Agenda
                      colaboradorSelecionado={colaboradorSelecionado}
                      changeColaboradorSelecionado={this.changeColaboradorSelecionado}
                      colaboradores={colaboradores}
                      changeExibicaoColaborador={this.changeExibicaoColaborador}
                      changeDesktopDrawerOpen={changeDesktopDrawerOpen}
                      changeToolbarExpanded={changeToolbarExpanded}
                      toolbarExpanded={toolbarExpanded}
                      perspectiva={perspectiva}
                      dataSelecionada={dataSelecionada}
                      changeDataSelecionada={this.changeDataSelecionada}
                    />
                  )}
                  {perspectiva.id === PerspectivaEnum.LISTA.id && <AtendimentosListaPanelPageContent />}
                  {perspectiva.id === PerspectivaEnum.FILA_ESPERA.id && <FilaEsperaPanelPageContent />}
                  <Banner />
                </>
              )
            } else {
              return (
                <div className={classes.messageContainer}>
                  <div style={{ padding: 24 }}>
                    <Typography variant="body1" align="center">
                      Não existe nenhum colaborador cadastrado com a função <b>"Realiza Atendimentos"</b>.
                    </Typography>
                    <Typography variant="body2" align="center">
                      Acesse a opção do menu <b>Colaboradores</b> e cadastre os profissionais da sua empresa, marcando a opção <b>"Realiza Atendimentos"</b> e associando os
                      serviços que eles podem realizar.
                    </Typography>
                  </div>
                </div>
              )
            }
          }}
        </ContentWithPreload>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AtendimentosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired,
  negocioSessao: PropTypes.object,
  usuarioSessao: PropTypes.object,
  getValue: PropTypes.func
}

export default withStyles(styles)(AtendimentosPanelPage)
