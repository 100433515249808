import Cookies from 'js-cookie'
import { parseStringToObject } from 'support/util/ObjectUtil'

export const rehydrateCookieFromLocalStorage = (cookieName: string, sessionKey: string) => {
  if (!Cookies.get(cookieName)) {
    const sessionValue = localStorage.getItem(sessionKey)

    if (sessionValue) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const accessTokenObj = parseStringToObject(sessionValue, '; ') as any
      Cookies.set(cookieName, accessTokenObj.accessToken, { ...accessTokenObj, Secure: Object.keys(accessTokenObj).includes('Secure') || false, accessToken: '' })
    }
  }
}
