import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { formatarValorPercentual } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  chartContainer: {
    maxWidth: 380,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

class ChartPie extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { data, classes } = this.props

    if (!data.items || !data.items.length) {
      return (
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          Dados não encontrados
        </Typography>
      )
    }

    const colors = this.props.theme.palette.chart.pieList
    const items = []

    for (let item of data.items) {
      items.push({
        name: item.name,
        value: item.value,
        color: colors[items.length % colors.length]
      })
    }

    const itemsReverse = items.slice(0).reverse()
    itemsReverse.unshift(itemsReverse[itemsReverse.length - 1])
    itemsReverse.splice(itemsReverse.length - 1, 1)
    itemsReverse.unshift(itemsReverse[itemsReverse.length - 1])
    itemsReverse.splice(itemsReverse.length - 1, 1)

    let totalValue = 0
    for (let item of data.items) {
      totalValue += item.value
    }

    return (
      <div>
        <div style={{ textAlign: 'center', paddingBottom: 0, paddingLeft: 8, paddingRight: 9 }}>
          {items.map((item, index) => {
            return (
              <div key={index} style={{ display: 'inline-block', marginLeft: 4, marginRight: 4, marginBottom: 8 }}>
                <Grid container alignItems="flex-start" wrap="nowrap">
                  <Grid item>
                    <div style={{ backgroundColor: item.color, width: 14, height: 14, borderRadius: 10, marginTop: 2 }}></div>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <Typography variant="body2" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
                      {item.name}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )
          })}
        </div>
        <div className={classes.chartContainer}>
          <ResponsiveContainer minHeight={220} aspect={1}>
            <PieChart margin={{ top: 48, right: 48, bottom: 48, left: 48 }}>
              <Pie
                dataKey="value"
                innerRadius={50}
                data={itemsReverse}
                label={(p1, p2, p3, p4) => {
                  return (
                    <text x={p1.x} y={p1.y} cx={p1.cx} cy={p1.cy} textAnchor={p1.textAnchor} fontSize={14} fill={p1.fill}>
                      {formatarValorPercentual((p1.value / totalValue) * 100)}
                    </text>
                  )
                }}
                isAnimationActive={false}
              >
                {itemsReverse.map((item, index) => {
                  return <Cell key={'cell-' + index} fill={item.color} stroke={'transparent'} />
                })}
              </Pie>
              <Pie dataKey="value" data={itemsReverse} innerRadius={46} outerRadius={52} label={false} isAnimationActive={false}>
                {itemsReverse.map((item, index) => {
                  const color = lighten(item.color, 0.3)
                  return <Cell key={'cell-' + index} fill={color} stroke={'transparent'} />
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

ChartPie.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ChartPie)
