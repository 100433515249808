import { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import DrawerMenuItem from 'support/components/panel/DrawerMenuItem'
import DrawerMenuItemIcon from 'support/components/panel/DrawerMenuItemIcon'
import DrawerMenuItemText from 'support/components/panel/DrawerMenuItemText'

const styles = (theme) => ({
  primary: {},
  secondary: {}
})

class SimpleDrawerMenuItem extends Component {
  render() {
    const { classes, rota, regexUrl, isMenuRetracted, icon, primary, isNew, className, 'data-testid': dataTestId, onClick } = this.props
    return (
      <DrawerMenuItem data-testid={dataTestId} rota={rota} regexUrl={regexUrl} className={className} onClick={onClick}>
        <DrawerMenuItemIcon isMenuRetracted={isMenuRetracted} icon={icon} tooltipTitle={primary} />
        <DrawerMenuItemText isNew={isNew} isMenuRetracted={isMenuRetracted} primary={primary} classes={{ primary: classes.primary, secondary: classes.secondary }} />
      </DrawerMenuItem>
    )
  }
}

SimpleDrawerMenuItem.propTypes = {
  classes: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string
  }).isRequired,
  rota: PropTypes.any,
  regexUrl: PropTypes.any,
  isMenuRetracted: PropTypes.bool,
  icon: PropTypes.any,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isNew: PropTypes.bool,
  className: PropTypes.string
}

export default withStyles(styles)(SimpleDrawerMenuItem)
