import React from 'react'

import ClienteDadosBasicosDialogPage from 'pages/erp/painel/cliente/ClienteDadosBasicosDialogPage'
import PainelAtendimentoCliente from 'pages/erp/painel/cliente/PainelAtendimentoCliente'
import NegocioSupport from 'pages/erp/painel/negocio/NegocioSupport'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import DialogSelect from 'support/components/dialogselect/DialogSelect'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import VendaService from 'support/domain/venda/VendaService'

function adicionarVenda(params) {
  NegocioSupport.tentarExibirAvisoImportante({
    onIgnore: () => {
      if (!params) {
        params = {}
      }
      if (params.data === undefined) {
        params.data = new Date()
      }

      if (!params.cliente) {
        abrirSelecaoCliente(params)
      } else {
        abrirPainelAtendimento(params)
      }
    }
  })
}

function abrirSelecaoCliente(params) {
  const creationFunction = (props) => {
    openBackableDialog(ClienteDadosBasicosDialogPage, {
      nome: props.text
    })
  }
  openBackableDialog(DialogSelect, {
    creationFunction: creationFunction,
    creationPermission: verificarAutorizacao([PA.PODE_CADASTRAR_CLIENTE]),
    inputSearchPlaceholder: 'Selecione um cliente...',
    endpoint: 'erp/clientes.buscarLista',
    searchParamName: 'nome',
    autoSelectDefinitons: [
      {
        eventName: 'ManipulacaoCliente',
        itemPropName: 'cliente'
      }
    ],
    generateItemData: (cliente) => {
      return {
        primary: cliente.apelido,
        cliente: cliente
      }
    },
    onSelect: (item) => {
      abrirPainelAtendimento({
        ...params,
        cliente: item.cliente
      })
    },
    emptyListProps: {
      padding: true,
      text: 'Nenhum cliente foi encontrado',
      textButton: 'Cadastrar cliente',
      creationFunction: creationFunction
    }
  })
}

function abrirPainelAtendimento(params) {
  openBackableDialog(PainelAtendimentoCliente, params)
}

function cancelarVenda(opts) {
  const { venda, parentDialog } = opts

  if (VendaService.isAtendimento(venda)) {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_ATENDIMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    let textToConfirm = undefined
    if (venda.notaVenda) {
      textToConfirm = 'CANCELAR'
    }

    var dataLayer = window.dataLayer || []

    openBackableDialog(EntityConfirmationDialog, {
      parent: parentDialog,
      title: 'Cancelar atendimento',
      text: <span>Você tem certeza que deseja cancelar este atendimento? Essa operação não poderá ser desfeita.</span>,
      textToConfirm: textToConfirm,
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'erp/atendimentos.cancelarAtendimento',
        contextoUsuario: 'erp',
        data: {
          idAtendimento: venda.atendimento.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:attendance',
            eventAction: 'cancelar-atendimento:callback',
            eventLabel: 'sucesso'
          })

          dialogConfirmationInstance.props.setShouldCloseParent(true)

          EventsManager.pub('CancelamentoVenda', { venda: { id: venda.id } })
          EventsManager.pub('ManipulacaoVenda')
        },
        errorKey: 'caixa'
      }
    })
  } else if (VendaService.isVendaProduto(venda)) {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { vendaProduto } = venda

    openBackableDialog(EntityConfirmationDialog, {
      parent: parentDialog,
      title: 'Cancelar venda',
      text: <span>Você tem certeza que deseja cancelar esta venda? Essa operação não poderá ser desfeita.</span>,
      textToConfirm: 'CANCELAR',
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'erp/inventario.cancelarVendaProduto',
        contextoUsuario: 'erp',
        data: {
          idVendaProduto: vendaProduto.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('CancelamentoVenda', { venda: { id: venda.id } })
          EventsManager.pub('ManipulacaoVenda')
        },
        errorKey: 'caixa'
      }
    })
  } else if (VendaService.isVendaPacote(venda)) {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { vendaPacote } = venda

    openBackableDialog(EntityConfirmationDialog, {
      parent: parentDialog,
      title: 'Cancelar venda',
      text: <span>Você tem certeza que deseja cancelar esta venda? Essa operação não poderá ser desfeita.</span>,
      textToConfirm: 'CANCELAR',
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'erp/pacotes.cancelarVenda',
        contextoUsuario: 'erp',
        data: {
          idVendaPacote: vendaPacote.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('CancelamentoVenda', { venda: { id: venda.id } })
          EventsManager.pub('ManipulacaoVenda')
        },
        errorKey: 'caixa'
      }
    })
  } else if (VendaService.isVendaCredito(venda)) {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { vendaCredito } = venda

    openBackableDialog(EntityConfirmationDialog, {
      parent: parentDialog,
      title: 'Cancelar crédito',
      text: <span>Você tem certeza que deseja cancelar esta venda de crédito? Essa operação não poderá ser desfeita.</span>,
      textToConfirm: 'CANCELAR',
      cancelButtonLabel: 'VOLTAR',
      confirmButtonLabel: 'CONFIRMAR',
      call: {
        method: 'post',
        url: 'erp/clientes.cancelarVendaCredito',
        contextoUsuario: 'erp',
        data: {
          idVendaCredito: vendaCredito.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('CancelamentoVenda', { venda: { id: venda.id } })
          EventsManager.pub('ManipulacaoVenda')
          EventsManager.pub('AlteracaoSaldoCliente')
        },
        errorKey: 'caixa'
      }
    })
  }
}

function removerVenda(opts) {
  const { venda, event, facilitadorRemocaoRepeticaoFunctionMap, parentDialog } = opts

  if (VendaService.isAtendimento(venda)) {
    if (!verificarAutorizacao([PA.PODE_REMOVER_ATENDIMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { atendimento } = venda

    let isOrdemChegada = false
    let isHoraMarcada = false

    if (atendimento.tipo.id === AtendimentoTipoEnum.ORDEM_CHEGADA.id) {
      isOrdemChegada = true
    } else if (atendimento.tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id) {
      isHoraMarcada = true
    }

    var dataLayer = window.dataLayer || []

    if (isOrdemChegada || venda.cancelado) {
      openBackableDialog(EntityRemovalDialog, {
        parent: parentDialog,
        title: 'Remover Atendimento',
        text: <span>Você tem certeza que deseja remover este atendimento? Essa operação não poderá ser desfeita.</span>,
        call: {
          method: 'post',
          url: 'erp/atendimentos.removerAtendimento',
          contextoUsuario: 'erp',
          data: {
            idAtendimento: atendimento.id
          },
          onSuccess: (response, dialogConfirmationInstance) => {
            dataLayer.push({
              event: 'event',
              eventCategory: 'salao99:attendance',
              eventAction: 'remover-atendimento:callback',
              eventLabel: 'sucesso'
            })

            dialogConfirmationInstance.props.setShouldCloseParent(true)
            EventsManager.pub('RemocaoVenda', { venda: { id: venda.id } })
            EventsManager.pub('ManipulacaoVenda')
          }
        }
      })
    } else if (isHoraMarcada) {
      facilitadorRemocaoRepeticaoFunctionMap.remover({
        abrirMenu: atendimento.repeticao && atendimento.repeticao.id ? true : false,
        event: event,
        call: {
          endpoint: 'erp/atendimentos.removerAtendimento',
          data: {
            idAtendimento: atendimento.id
          },
          onSuccess: (response, dialogConfirmationInstance) => {
            dataLayer.push({
              event: 'event',
              eventCategory: 'salao99:attendance',
              eventAction: 'remover-atendimento:callback',
              eventLabel: 'sucesso'
            })

            dialogConfirmationInstance.props.setShouldCloseParent(true)
            EventsManager.pub('RemocaoVenda', { venda: { id: venda.id } })
            EventsManager.pub('ManipulacaoVenda')
          }
        },
        dialog: {
          parent: parentDialog,
          title: 'Remover Atendimento',
          itemDescription: <span>este atendimento</span>
        }
      })
    }
  } else if (VendaService.isVendaProduto(venda)) {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { vendaProduto } = venda

    openBackableDialog(EntityRemovalDialog, {
      parent: parentDialog,
      title: 'Remover venda',
      text: <span>Você tem certeza que deseja remover esta venda? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'erp/inventario.removerVendaProduto',
        contextoUsuario: 'erp',
        data: {
          idVendaProduto: vendaProduto.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('RemocaoVenda', { venda: { id: venda.id } })
          EventsManager.pub('ManipulacaoVenda')
        }
      }
    })
  } else if (VendaService.isVendaPacote(venda)) {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { vendaPacote } = venda

    openBackableDialog(EntityRemovalDialog, {
      parent: parentDialog,
      title: 'Remover venda',
      text: <span>Você tem certeza que deseja remover esta venda? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'erp/pacotes.removerVenda',
        contextoUsuario: 'erp',
        data: {
          idVendaPacote: vendaPacote.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('RemocaoVenda', { venda: { id: venda.id } })
          EventsManager.pub('ManipulacaoVenda')
        }
      }
    })
  } else if (VendaService.isVendaCredito(venda)) {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_VENDA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { vendaCredito } = venda

    openBackableDialog(EntityRemovalDialog, {
      parent: parentDialog,
      title: 'Remover venda',
      text: <span>Você tem certeza que deseja remover esta venda? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'erp/vendas.removerVendaCredito',
        contextoUsuario: 'erp',
        data: {
          idVendaCredito: vendaCredito.id
        },
        onSuccess: (response, dialogConfirmationInstance) => {
          dialogConfirmationInstance.props.setShouldCloseParent(true)
          EventsManager.pub('RemocaoVenda', { venda: { id: venda.id } })
          EventsManager.pub('ManipulacaoVenda')
        }
      }
    })
  }
}

const functionsObject = {
  adicionarVenda: adicionarVenda,
  cancelarVenda: cancelarVenda,
  removerVenda: removerVenda
}

export default functionsObject
