import React, { Component } from 'react'

import { postAPI } from 'support/components/api/ApiClient'

class GlobalErrorHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorMessage: ''
    }
    window.addEventListener('error', this.onError)
  }

  /*static getDerivedStateFromError(error) {
		if(process.env.REACT_APP_RENDER_JS_ERROR === "true"){
			return { hasError: true, errorMessage : error.stack };
		}
		return null;
	}*/

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_RENDER_JS_ERROR === 'true') {
      this.setState({
        hasError: true,
        errorMessage: error.stack
      })
    }
    postAPI({
      url: 'ifr/performance.registrarErro',
      data: {
        url: window.location.href,
        descricao: 'componentDidCatch: ' + navigator.userAgent + ' | ' + error?.stack
      },
      sendErroToGenericSnackbar: false
    })
  }

  onError = (errorEvent) => {
    /*this.setState({
			hasError: true,
			errorMessage : errorEvent.error.stack
		});*/
    postAPI({
      url: 'ifr/performance.registrarErro',
      data: {
        url: window.location.href,
        descricao: 'onError: ' + navigator.userAgent + ' | ' + errorEvent.error?.stack
      },
      sendErroToGenericSnackbar: false
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: 30 }}>
          <h2>Ocorreu um erro inesperado.</h2>
          <h4 style={{ fontWeight: 400 }}>
            Enviei um email para <strong>suporte@salao99.com.br</strong> com o códido de erro abaixo:
          </h4>
          {this.state.errorMessage && (
            <div style={{ fontSize: 14, padding: 20, maxWidth: 600, wordBreak: 'break-all', backgroundColor: '#f5f5f5' }}>{this.state.errorMessage.toString()}</div>
          )}
        </div>
      )
    }
    return this.props.children
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.onError)
  }
}

export default GlobalErrorHandler
