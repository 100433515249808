import React, { Component } from 'react'

import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'

class EntityRemovalDialog extends Component {
  render() {
    const { classes, ...others } = this.props
    return <EntityConfirmationDialog {...others} confirmButtonLabel="REMOVER" />
  }
}

export default EntityRemovalDialog
