import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  HABILITADO: {
    id: 1,
    descricaoOpcao: 'Habilitado'
  },
  DESABILITADO: {
    id: 2,
    descricaoOpcao: 'Desabilitado'
  }
})

export default enumObject
