import { Tag } from '@grupoboticario/flora-react'
import { ChevronRightIcon, MinusCircleIcon, PersonCircleIcon, SmileCircleIcon, SquareListIcon, ThreePeopleIcon } from '@grupoboticario/flora-react-icons'

import * as S from './ItemMenu.styles'

const iconsType = {
  smile: <SmileCircleIcon />,
  tag: (
    <Tag className="tag" as="span">
      Novo
    </Tag>
  ),
  people: <ThreePeopleIcon />,
  person: <PersonCircleIcon />,
  minus: <MinusCircleIcon />,
  square: <SquareListIcon />
}

type ItemMenuProps = {
  title: string
  description: string
  icons: string
  onClick: () => void
}

export const ItemMenu = ({ title, description, icons, onClick }: ItemMenuProps) => {
  return (
    <S.Container onClick={onClick}>
      <S.WrapperIcons>{iconsType[icons as keyof typeof iconsType]}</S.WrapperIcons>
      <S.WrapperContent>
        <S.WrapperTitle>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.WrapperTitle>
        <ChevronRightIcon />
      </S.WrapperContent>
    </S.Container>
  )
}
