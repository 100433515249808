import React, { Component } from 'react'
import { CirclePicker } from 'react-color'

import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import ColorSchema from 'support/domain/color/ColorSchema'

const styles = (theme) => ({
  color: {
    width: '14px',
    height: '14px',
    borderRadius: '14px'
  },
  swatch: {
    cursor: 'pointer',
    padding: 5,
    display: 'inline-block',
    background: '#fff',
    boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.125)',
    borderRadius: 16
  }
})

class InputColorSelect extends Component {
  constructor(props) {
    super(props)

    let defaultColor = ColorSchema.getDefaultColor().hex
    if (props.defaultValue) {
      const color = ColorSchema.getColorById(props.defaultValue)
      defaultColor = color.hex
    }

    this.state = {
      anchorEl: null,
      value: defaultColor
    }
  }

  componentDidMount = () => {
    this.setValue(this.state.value)
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleChange = (color) => {
    this.setValue(color.hex)
    this.setState({ anchorEl: false })
  }

  setValue = (value) => {
    if (this.props.inputRef) {
      this.props.inputRef.current = { value: ColorSchema.getColorByHex(value) }
    }
    this.setState({ value: value })
  }

  render() {
    const { classes, style } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div style={style}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid style={{ paddingRight: 10 }}>
            <Typography variant="body2">{this.props.label}</Typography>
          </Grid>
          <Grid>
            <div className={classes.swatch} onClick={this.handleClick}>
              <div className={classes.color} style={{ backgroundColor: this.state.value }} />
            </div>
          </Grid>
        </Grid>

        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div style={{ padding: 16 }}>
            <CirclePicker colors={ColorSchema.getHexColors()} width={182} onChange={this.handleChange} circleSize={22} circleSpacing={8} />
          </div>
        </Popover>
      </div>
    )
  }
}

InputColorSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputColorSelect)
