import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import InputHorario from 'support/components/input/InputHorario'

let ID_COUNTER = 0

const styles = (theme) => ({})

export function criarIntervalo(id, de, ate) {
  return {
    key: ++ID_COUNTER,
    id: id !== undefined ? id : null,
    de: de !== undefined ? de : null,
    deInputFunctionsMap: {},
    ate: ate !== undefined ? ate : null,
    ateInputFunctionsMap: {}
  }
}

export function adicionarIntervaloFormObject(intervalos) {
  intervalos.push(criarIntervalo())
}

export const changeIntervaloFormObject = (intervalos, index, prop, value) => {
  intervalos[index][prop] = value
}

export function removerIntervaloFormObject(intervalos, index) {
  intervalos.splice(index, 1)
}

function getKeyErrorsMapPrefix(dia, index) {
  return 'horariosAtendimento[' + dia + '][' + index + ']'
}

class InputListIntervaloHorario extends Component {
  INPUT_WIDTH = 60

  handleAdicionarIntervaloHorario = () => {
    if (this.props.onChange) this.props.onChange()
    this.props.handleAdicionarIntervaloHorario(this.props.dia)
  }

  handleRemoverIntervaloHorario = (index) => {
    if (this.props.onChange) this.props.onChange()
    if (this.props.errorsMap) {
      const KeyErrorsMapPrefix = getKeyErrorsMapPrefix(this.props.dia, index)
      this.props.errorsMap(KeyErrorsMapPrefix + '.de', 'remove')
      this.props.errorsMap(KeyErrorsMapPrefix + '.ate', 'remove')
    }
    this.props.handleRemoverIntervaloHorario(this.props.dia, index)
  }

  render() {
    const { intervalos, errorsMap } = this.props

    let inputWidth = this.INPUT_WIDTH
    if (this.props.width !== undefined) {
      inputWidth = this.props.width
    }

    const listItems = intervalos.map((intervalo, index) => {
      const deId = 'xde' + this.props.dia + '-' + index
      const ateId = 'xate' + this.props.dia + '-' + index

      let deErrorsMap = null
      let ateErrorsMap = null
      if (this.props.errorsMap) {
        deErrorsMap = errorsMap(getKeyErrorsMapPrefix(this.props.dia, index) + '.de')
        ateErrorsMap = errorsMap(getKeyErrorsMapPrefix(this.props.dia, index) + '.ate')
      }

      return (
        <div style={{ marginTop: 0, display: 'inline-block' }} key={intervalo.key}>
          <InputHorario
            clearable
            errorMessage={deErrorsMap}
            disabled={this.props.disabled}
            id={deId}
            name={deId}
            label="De"
            style={{ width: inputWidth, marginRight: 8, marginTop: 10, verticalAlign: 'top' }}
            defaultValue={intervalo.de}
            onChange={() => {
              if (this.props.onChange) this.props.onChange()
            }}
            customVariant="outlined-small"
            functionsMap={intervalo.deInputFunctionsMap}
          />

          <InputHorario
            clearable
            errorMessage={ateErrorsMap}
            disabled={this.props.disabled}
            id={ateId}
            name={ateId}
            label="Até"
            style={{ width: inputWidth, marginRight: 8, marginTop: 10, verticalAlign: 'top' }}
            defaultValue={intervalo.ate}
            onChange={() => {
              if (this.props.onChange) this.props.onChange()
            }}
            customVariant="outlined-small"
            functionsMap={intervalo.ateInputFunctionsMap}
          />

          {
            <IconButton
              disabled={this.props.disabled}
              color="primary"
              aria-label="Add to shopping cart"
              style={{ padding: 6, marginTop: 14, verticalAlign: 'top', marginLeft: -8, marginRight: index < this.props.intervalos.length - 1 ? 8 : 0 }}
              onClick={() => this.handleRemoverIntervaloHorario(index)}
            >
              <DeleteIcon style={{ color: '#ccc' }} />
            </IconButton>
          }
          {index === this.props.intervalos.length - 1 && this.props.intervalos.length < this.props.maxIntervalos && (
            <IconButton
              disabled={this.props.disabled}
              color="primary"
              aria-label="Add to shopping cart"
              style={{ padding: 6, marginTop: 14, marginLeft: -8, verticalAlign: 'top' }}
              onClick={() => this.handleAdicionarIntervaloHorario()}
            >
              <AddCircleIcon />
            </IconButton>
          )}
        </div>
      )
    })

    return <div>{listItems}</div>
  }
}

InputListIntervaloHorario.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputListIntervaloHorario)
