import React, { Component } from 'react'

import Avatar from '@material-ui/core/Avatar'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import StarIcon from '@material-ui/icons/Star'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CustomDialog from 'support/components/dialog/CustomDialog'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import NegocioService from 'support/domain/negocio/NegocioService'

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 620,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  titulo: {
    fontSize: '28px'
  },
  contadorPrincipal: {
    fontSize: '50px',
    lineHeight: 'normal',
    textAlign: 'center',
    fontWeight: 500,
    letterSpacing: -2,
    marginTop: -8
  },
  containerEstrelaPintada: {
    '& svg': {
      color: '#ffc55b',
      fontSize: 20,
      marginLeft: -1,
      marginRight: -1
    },
    '&$containerEstrelaPequena svg': {
      fontSize: 15
    }
  },
  containerEstrelaApagada: {
    '& svg': {
      color: '#e6e6e6',
      fontSize: 20,
      marginLeft: -1,
      marginRight: -1
    },
    '&$containerEstrelaPequena svg': {
      fontSize: 15
    }
  },
  containerEstrelaPequena: {},
  containerBarraDistribuicao: {
    width: '100%',
    position: 'absolute',
    height: 10,
    borderRadius: 4
  },
  avatar: {
    color: '#444',
    backgroundColor: '#e2e2e2'
  }
})

class NegocioAvaliacoesDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lista: []
    }
    this.scrollContainerRef = React.createRef()
  }

  componentDidMount() {}

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'ptl/negocios.buscaResumoAvaliacoes',
      params: {
        idWithHash: NegocioService.gerarNegocioIdWithHash(this.props.negocio)
      },
      onSuccess: (response) => {
        this.setState({
          resumoAvaliacoes: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  renderRow = (avaliacao, { index, key, style }) => {
    const { classes } = this.props
    return (
      <div key={key} style={{ ...style, padding: '20px 0px', borderTop: index > 0 ? '1px solid #eee' : '0px solid', background: 'white' }}>
        <div style={{ padding: '0px 8px' }}>
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 12 }}>
              <Avatar className={classes.avatar}>{avaliacao.cliente.nome.charAt(0).toUpperCase()}</Avatar>
            </Grid>
            <Grid item xs>
              <Typography variant="body2" style={{ colsor: '#777' }}>
                {avaliacao.cliente.nome}
              </Typography>
              <Grid container alignItems="center">
                <Grid item style={{ marginRight: 8 }}>
                  <div style={{ position: 'relative', height: 16, width: 65 }}>
                    <div className={classNames(classes.containerEstrelaApagada, classes.containerEstrelaPequena)} style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap' }}>
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                    </div>
                    <div
                      className={classNames(classes.containerEstrelaPintada, classes.containerEstrelaPequena)}
                      style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap', width: avaliacao.nota * 20 + 1 + '%', overflow: 'hidden' }}
                    >
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" style={{ color: '#aaa', fontSize: 12 }}>
                    {moment(avaliacao.data).format('DD [de] MMMM [de] YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {avaliacao.comentario && (
            <Typography variant="body2" style={{ color: '#666', padding: '10px 2px 0px 2px' }}>
              {avaliacao.comentario.charAt(0).toUpperCase() + avaliacao.comentario.slice(1)}
            </Typography>
          )}
          {avaliacao.resposta && (
            <React.Fragment>
              <div
                style={{
                  marginTop: 10,
                  marginLeft: 16,
                  width: 0,
                  height: 0,
                  border: '0 solid transparent',
                  borderRightWidth: 9,
                  borderLeftWidth: 9,
                  borderBottom: '14px solid #f3f3f3'
                }}
              />
              <Typography variant="body2" style={{ color: '#666', padding: '14px', background: '#f0f0f0', borderRadius: 6 }}>
                <b>{this.props.negocio.nome}</b>
                <br />
                {avaliacao.resposta.charAt(0).toUpperCase() + avaliacao.resposta.slice(1)}
              </Typography>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <CustomDialog classes={{ paper: classes.root }} dialogProps={dialogProps}>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent>
            <Grid container alignItems="center" style={{ marginBottom: 8 }}>
              <Grid item xs>
                <Typography variant="h6" className={classes.titulo}>
                  Avaliações
                </Typography>
              </Grid>
              <Grid style={{ marginRight: -8 }}>
                <IconButton
                  onClick={() => {
                    this.props.handleCloseDialog()
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <ContentWithPreload loadContentPadding={true} loadFunction={this.carregarMain}>
              {() => {
                const { resumoAvaliacoes } = this.state

                const notas = []
                let maiorQuantidade = 0
                for (let i = 5; i >= 1; i--) {
                  if (resumoAvaliacoes.notas[i]) {
                    const quantidadeNota = resumoAvaliacoes.notas[i]
                    if (quantidadeNota > maiorQuantidade) {
                      maiorQuantidade = quantidadeNota
                    }
                    notas.push({
                      label: i.toString(),
                      value: quantidadeNota
                    })
                  } else {
                    notas.push({
                      label: i.toString(),
                      value: 0
                    })
                  }
                }

                let partePreenchimentoEstrelas = (resumoAvaliacoes.notaMediaAvaliacoes / 5) * 100 + 1
                if (partePreenchimentoEstrelas > 100) {
                  partePreenchimentoEstrelas = 100
                }

                return (
                  <React.Fragment>
                    <div style={{ padding: 28, border: '1px solid #eee', borderRadius: 6, marginTop: 16, marginBottom: 8 }}>
                      <Grid container alignItems="center">
                        <Grid item style={{ marginRight: 32 }}>
                          <Typography variant="h6" className={classes.contadorPrincipal}>
                            {resumoAvaliacoes.notaMediaAvaliacoes.toFixed(1)}
                          </Typography>
                          <div style={{ position: 'relative', height: 24, width: 90 }}>
                            <div className={classes.containerEstrelaApagada} style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap' }}>
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                            </div>
                            <div
                              className={classes.containerEstrelaPintada}
                              style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap', width: partePreenchimentoEstrelas + '%', overflow: 'hidden' }}
                            >
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                            </div>
                          </div>
                          <Typography variant="body1" style={{ fontSize: 12, color: '#aaa', textAlign: 'center' }}>
                            {resumoAvaliacoes.quantidadeAvaliacoes} Avaliaç{resumoAvaliacoes.quantidadeAvaliacoes !== 1 ? 'ões' : 'ão'}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          {notas.map((item) => (
                            <Grid key={item.label} container alignItems="center">
                              <Grid item style={{ marginRight: 16 }}>
                                <Typography variant="body2">{item.label}</Typography>
                              </Grid>
                              <Grid item xs>
                                <div style={{ position: 'relative', height: 10, width: '100%' }}>
                                  <div className={classes.containerBarraDistribuicao} style={{ background: '#eee' }}></div>
                                  <div className={classes.containerBarraDistribuicao} style={{ background: '#666', width: item.value * (100 / maiorQuantidade) + '%' }}></div>
                                </div>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </div>

                    <div style={{ width: '100%', position: 'relative' }}>
                      <VirtualizedList
                        scrollElement={this.scrollContainerRef.current}
                        itemsPerRequest={30}
                        showBackgroundDividers={false}
                        dynamicHeight={true}
                        loadingIndicatorRowHeight={100}
                        contextoUsuario="portal"
                        endpoint={'ptl/negocios.buscarAvaliacoes'}
                        getRequestParametersFunction={() => {
                          return {
                            idWithHash: NegocioService.gerarNegocioIdWithHash(this.props.negocio)
                          }
                        }}
                        items={this.state.lista}
                        loadTrackingFunction={(data) => {
                          if (data.status === 'loading') {
                            if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                              this.setState({ lista: [] })
                            }
                          } else if (data.status === 'loaded') {
                            this.setState({ lista: data.items })
                          }
                        }}
                        rowRenderer={this.renderRow}
                        emptyListProps={{
                          align: 'center',
                          padding: true,
                          text: ''
                        }}
                      />
                    </div>
                  </React.Fragment>
                )
              }}
            </ContentWithPreload>
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }
}

NegocioAvaliacoesDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NegocioAvaliacoesDialog)
