import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import NotasResponsiveTable from 'pages/erp/painel/venda/table/NotasResponsiveTable'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class NotasPagamentoPendentePorClienteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      ajaxing: false
    }
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNotaVendaPagamento', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  getRequestParameters = () => {
    return {
      idCliente: this.props.cliente.id
    }
  }

  abrirNotaDialog = (event, nota) => {
    event.stopPropagation()
    openBackableDialog(NotaDialogPage, {
      idNota: nota.id,
      retornarVendas: false
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.lista) {
      valorTotal += item.valorTotal - item.valorPago
      quantidadeTotal++
    }

    return (
      <DialogPage {...dialogProps} title="Detalhamento" ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Comandas Abertas com Pagamento Pendente</Typography>
              <Typography variant="body1">
                <b>{this.props.cliente.apelido}</b>
              </Typography>
            </FloatContent>

            <NotasResponsiveTable
              itemsPerRequest={20}
              abrirNotaDialog={this.abrirNotaDialog}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/vendas.buscarNotasAbertaComPagamentoPendente"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const state = { lista: data.items }
                  this.setState(state)
                }
              }}
              onClickRow={(item, event) => {
                this.abrirNotaDialog(event, item)
              }}
              emptyListProps={{
                text: 'Nenhuma fatura foi encontrada'
              }}
            />

            {quantidadeTotal > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Total de Comandas: <b>{quantidadeTotal}</b>
                  <br />
                  Pendente: <b>{formatarValorMonetario(valorTotal)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

NotasPagamentoPendentePorClienteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotasPagamentoPendentePorClienteDialogPage)
