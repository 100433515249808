import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import NegocioDialogPage from 'pages/admin/painel/negocio/NegocioDialogPage'
import NegociosResponsiveTable from 'pages/admin/painel/negocio/table/NegociosResponsiveTable'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'

const styles = (theme) => ({})

class NegociosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      ajaxing: false,
      lista: []
    }
    this.loaderFunctionsMap = {}
    this.eventsManager = EventsManager.new()
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoNegocio', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerAbrirNegocio = (negocio) => {
    openBackableDialog(NegocioDialogPage, {
      negocio: negocio,
      usuario: this.props.usuario
    })
  }

  getRequestParameters = () => {
    return {
      idUsuario: this.props.usuario ? this.props.usuario.id : null
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { ajaxing } = this.state

    let onClickRow = (negocio) => {
      this.handlerAbrirNegocio(negocio)
    }

    return (
      <DialogPage {...dialogProps} title="Listagem de negócios" align="center" contentMaxWidth={1000} pageType="basicEntity" ajaxing={ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <NegociosResponsiveTable
              disabled={ajaxing}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="admin"
              endpoint="admin/negocio.buscarNegocios"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              onClickRow={onClickRow}
              emptyListProps={{
                text: 'Nenhum negócio foi encontrado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

NegociosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(NegociosDialogPage)
