import moment from 'moment'
import UnidadeFrequencia from 'support/domain/repeticao/UnidadeFrequencia'

const PERIODO_MAXIMO_REPETICOES = 3650
const QUANTIDADE_MAXIMA_REPETICOES = 20

function gerarDatasRepeticoes(unidadeFrequencia, valorFrequencia, dataInicialParaRepeticao, dataFinalRepeticao) {
  if (!unidadeFrequencia || !valorFrequencia) {
    return null
  }

  if (dataFinalRepeticao) {
    dataFinalRepeticao = dataFinalRepeticao.clone().startOf('day')
  }

  let dataInicial = dataInicialParaRepeticao ? dataInicialParaRepeticao.clone().startOf('day') : moment().startOf('day')
  let dataLimite = dataInicial.clone()
  let contadorRepeticoes = 0

  const datas = []

  while (true) {
    if (++contadorRepeticoes > QUANTIDADE_MAXIMA_REPETICOES) {
      break
    }

    let novaDataLimite = dataLimite.clone()
    if (unidadeFrequencia.id === UnidadeFrequencia.DIAS.id) {
      novaDataLimite.add(valorFrequencia, 'days')
    } else if (unidadeFrequencia.id === UnidadeFrequencia.SEMANAS.id) {
      novaDataLimite.add(valorFrequencia, 'weeks')
    } else if (unidadeFrequencia.id === UnidadeFrequencia.MESES.id) {
      novaDataLimite.add(valorFrequencia, 'months')
    }

    if (novaDataLimite.diff(dataInicial, 'days') > PERIODO_MAXIMO_REPETICOES || (dataFinalRepeticao && novaDataLimite.isAfter(dataFinalRepeticao, 'day'))) {
      break
    }
    dataLimite = novaDataLimite
    datas.push(dataLimite.clone())
  }

  return datas
}

function calcularDataLimiteRepeticao(unidadeFrequencia, valorFrequencia, dataInicialParaRepeticao, dataFinalRepeticao) {
  const datas = gerarDatasRepeticoes(unidadeFrequencia, valorFrequencia, dataInicialParaRepeticao, dataFinalRepeticao)
  if (datas && datas.length) {
    return datas[datas.length - 1]
  } else {
    return datas
  }
}

const functionsObject = {
  gerarDatasRepeticoes: gerarDatasRepeticoes,
  calcularDataLimiteRepeticao: calcularDataLimiteRepeticao
}

export default functionsObject
