import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import DadosBasicosCaixaDialogPage from 'pages/erp/painel/caixa/DadosBasicosCaixaDialogPage'
import TabCaixaResumo from 'pages/erp/painel/caixa/TabCaixaResumo'
import TabEntradas from 'pages/erp/painel/caixa/TabEntradas'
import TabMovimentacoes from 'pages/erp/painel/caixa/TabMovimentacoes'
import TabSangrias from 'pages/erp/painel/caixa/TabSangrias'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import Subject from 'support/components/page/subject/Subject'
import Tab from 'support/components/tab/Tab'
import CaixaService from 'support/domain/caixa/CaixaService'
import CaixaStatusEnum from 'support/domain/caixa/CaixaStatusEnum'
import CaixaTipoEnum from 'support/domain/caixa/CaixaTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarNomeArquivo } from 'support/util/DateFormatter'

const MAX_WIDTH_PAPER_DEFAULT = 720
const MAX_WIDTH_PAPER_TAB_MOVIMENTACOES = 850

const styles = (theme) => ({
  observacoes: {
    marginBottom: theme.spacing(1.5)
  }
})

class CaixaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      caixa: null,
      title: 'Caixa',
      tabValue: 0,
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
    this.paperRef = React.createRef()
    this.dataExporterResumoFunctionsMap = {}
    this.dataExporterMovimentacoesFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/caixas.buscarCaixaPorId',
      params: {
        id: this.props.idCaixa
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const toolbarActions = {
          desktopItemsShow: 1,
          mobileItemsShow: 1,
          actions: [
            {
              label: 'Editar/Configurações',
              handleAction: this.handlerEditar
            }
          ]
        }

        if (response.data.status.id === CaixaStatusEnum.ABERTO.id) {
          toolbarActions.actions.push({
            label: 'Fechar Caixa',
            handleAction: this.handlerFecharCaixa
          })
        } else {
          toolbarActions.actions.push({
            label: 'Reabrir Caixa',
            handleAction: this.handlerReabrirCaixa
          })
        }

        if (verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
          toolbarActions.actions.push(
            {
              label: 'Exportar Resumo',
              handleAction: (event) => this.downloadRelatorioResumo(event)
            },
            {
              label: 'Exportar Movimentação',
              handleAction: (event) => this.downloadRelatorio(event)
            }
          )
        }

        this.setState({
          caixa: response.data,
          title: CaixaService.getNome(response.data, { prefix: true }),
          toolbarActions: toolbarActions
        })

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCaixa', (props) => {
      if (props !== undefined && props.caixa !== undefined) {
        this.setState({ caixa: props.caixa })
      } else {
        this.loaderFunctionsMap.reload()
      }
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerFecharCaixa = () => {
    if (verificarAutorizacao([PA.PODE_FECHAR_PROPRIO_CAIXA])) {
      openBackableDialog(EntityConfirmationDialog, {
        title: 'Confirmação',
        text: <span>Você tem certeza que deseja fechar este caixa?</span>,
        cancelButtonLabel: 'VOLTAR',
        confirmButtonLabel: 'FECHAR',
        call: {
          method: 'post',
          url: 'erp/caixas.fecharCaixa',
          contextoUsuario: 'erp',
          data: {
            idCaixa: this.state.caixa.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoCaixa')
          }
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerReabrirCaixa = () => {
    if (verificarAutorizacao([PA.PODE_REABRIR_PROPRIO_CAIXA])) {
      openBackableDialog(EntityConfirmationDialog, {
        title: 'Confirmação',
        text: <span>Você tem certeza que deseja reabrir este caixa?</span>,
        cancelButtonLabel: 'VOLTAR',
        confirmButtonLabel: 'REABRIR',
        call: {
          method: 'post',
          url: 'erp/caixas.reabrirCaixa',
          contextoUsuario: 'erp',
          data: {
            idCaixa: this.state.caixa.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoCaixa')
          }
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = () => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_PROPRIO_CAIXA])) {
      openBackableDialog(DadosBasicosCaixaDialogPage, { idCaixa: this.state.caixa.id })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  downloadRelatorioResumo = (event) => {
    const filename = 'Controle de Caixa - Resumo - ' + formatarNomeArquivo(this.state.caixa.dataInicial)

    this.dataExporterResumoFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'caixa.gerarCaixaResumo',
        params: {
          idCaixa: this.state.caixa.id
        }
      }
    })
  }

  downloadRelatorio = (event) => {
    const filename = 'Movimentacões de Caixa - ' + formatarNomeArquivo(this.state.caixa.dataInicial)

    this.dataExporterMovimentacoesFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'caixa.gerarListaCaixaMovimentacoes',
        params: {
          idCaixa: this.state.caixa.id
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { caixa, tabValue } = this.state
    const subjectInfos = []

    let maxWidth = MAX_WIDTH_PAPER_DEFAULT
    if (tabValue === 1) {
      maxWidth = MAX_WIDTH_PAPER_TAB_MOVIMENTACOES
    }

    if (caixa) {
      subjectInfos.push({ type: 'text', marginTop: true, text: converterDataIntParaMoment(caixa.dataInicial).format('DD/MMM/YYYY') })
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        ajaxing={this.state.ajaxing}
        align="center"
        contentMaxWidth={maxWidth}
        pageType="basicEntity"
        toolbarActions={this.state.toolbarActions}
        onPreClose={() => {
          EventsManager.pub('TabSaveSnackBar', { open: false })
        }}
      >
        {(dialogContentProps) => (
          <ContentWithPreload functionsMap={this.loaderFunctionsMap} loadFunction={this.carregarMain} loadContentPadding={true}>
            {() => (
              <div>
                <DataExporter functionsMap={this.dataExporterResumoFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX]} />

                <DataExporter functionsMap={this.dataExporterMovimentacoesFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

                <Subject
                  title={CaixaService.getNome(caixa)}
                  infos={[
                    {
                      type: 'text',
                      marginTop: 'small',
                      text: (
                        <span>
                          {CaixaTipoEnum.getById(caixa.tipo.id).descricao} - <span>{CaixaStatusEnum.getById(caixa.status.id).descricao}</span>
                        </span>
                      )
                    },
                    { type: 'text', text: converterDataIntParaMoment(caixa.dataInicial).format('DD/MMM/YYYY') }
                  ]}
                />

                {caixa.observacao && (
                  <div className={classes.observacoes}>
                    <div style={{ padding: 10, background: '#f5f5f5', borderRadius: 4 }}>
                      <Grid container alignItems="center">
                        <Grid item style={{ marginRight: 8 }}>
                          <InfoIcon style={{ display: 'block' }} />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.7)' }}>
                            {caixa.observacao}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}

                <Tab
                  value={tabValue}
                  onChange={(event, value) => {
                    EventsManager.pub('TabSaveSnackBar', { open: false })
                    this.setState({ tabValue: value })
                  }}
                  tabs={[{ label: 'Resumo' }, { label: 'Movimentações' }, { label: 'Sangrias' }, { label: 'Entradas' }]}
                ></Tab>
                <div>
                  {tabValue === 0 && <TabCaixaResumo caixa={this.state.caixa} />}
                  {tabValue === 1 && <TabMovimentacoes caixa={this.state.caixa} scrollContainerRef={dialogContentProps.scrollContainerRef.current} />}

                  {tabValue === 2 && <TabSangrias caixa={this.state.caixa} scrollContainerRef={dialogContentProps.scrollContainerRef.current} />}

                  {tabValue === 3 && <TabEntradas caixa={this.state.caixa} scrollContainerRef={dialogContentProps.scrollContainerRef.current} />}
                </div>
              </div>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

CaixaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CaixaDialogPage)
