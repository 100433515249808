import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ConsultaDadosNFEnotasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Consulta Dados E-notas',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      paginaCarregada: true
    }

    this.inputIdNegocio = React.createRef()
    this.inputNumeroNf = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.registrarPaginaCarregada()
    notifyContentLoaded()
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Buscar',
            handleAction: () => this.buscarDados()
          }
        ]
      }
    }))
  }

  buscarDados = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    getAPI({
      url: 'admin/nfs.buscarDadosEnviadosEnotas',
      params: {
        idNegocio: this.inputIdNegocio.current.value,
        numeroNf: this.inputNumeroNf.current.value
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState((state) => ({
          idEmpresaEnotas: response.data.idEmpresaEnotas,
          idNfEnotas: response.data.idNfEnotas,
          ultimoJson: response.data.jsonEnviado
        }))
        this.registrarPaginaCarregada()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formConsultaJson')
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.buscarDados()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formConsultaJson" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>

              <InputText
                idname="xIdNegocio"
                customVariant="outlined-small"
                marginTop={true}
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('idNegocio')}
                label="ID Negócio"
                inputRef={this.inputIdNegocio}
              />

              <InputText
                idname="xNumeroNf"
                customVariant="outlined-small"
                marginTop={true}
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('idNumeroNf')}
                label="Número Nf"
                inputRef={this.inputNumeroNf}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Voltar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.buscarDados}>
                    {this.state.ajaxing ? 'Aguarde' : 'Buscar'}
                  </Button>
                </div>
              )}

              {
                this.state.idEmpresaEnotas &&
                <Typography variant="body2">Id Empresa E-notas: {this.state.idEmpresaEnotas}</Typography>
              }

              {
                this.state.idNfEnotas &&
                <Typography variant="body2">Id Nf E-notas: {this.state.idNfEnotas}</Typography>
              }

              {
                this.state.ultimoJson &&
                <React.Fragment>
                  <Typography variant="body2">Último json enviado:
                    {
                      <pre style={{ whiteSpace: "pre-wrap" }}>
                        {
                          JSON.stringify(JSON.parse(this.state.ultimoJson), null, 2)
                        }
                      </pre>
                    }
                  </Typography>

                </React.Fragment>
              }
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConsultaDadosNFEnotasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConsultaDadosNFEnotasDialogPage)