import React from 'react'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ContentLoadStatus from 'support/components/contentload/ContentLoadStatus'
import EmptyListContent from 'support/components/list/EmptyListContent'
import DynamicHeightList from 'support/components/list/virtualizedlist/DynamicHeightList'
import VirtualizedListLoader from 'support/components/list/virtualizedlist/VirtualizedListLoader'
import VirtualizedListLoadMoreFooter from 'support/components/list/virtualizedlist/VirtualizedListLoadMoreFooter'
import BackgroundUtil from 'support/util/BackgroundUtil'
import DocumentUtil from 'support/util/DocumentUtil'
import WindowUtil from 'support/util/WindowUtil'

export const ROW_HEIGHT_DEFAULT = 60

const styles = (theme) => ({
  loadingContainer: {
    marginTop: 1,
    width: '100%',
    position: 'absolute',
    backgroundPosition: '0px 0px',
    overflow: 'hidden',
    maxHeight: '100%'
  },
  disabledContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#fff',
    zIndex: 10,
    opacity: 0.5
  },
  loadingLabelContainer: {
    paddingLeft: 30,
    width: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.28)'
  },
  gridContainer: {}
})

let idGenerator = 0
function generateClassId() {
  ++idGenerator
  return 'scroll-container-id-' + idGenerator
}

class VirtualizedList extends React.Component {
  constructor(props) {
    super(props)
    this.scrollClassId = generateClassId()
    this.themeInstanceId = this.props.theme.instanceId
    this.lastWindowWidth = WindowUtil.getWidth()

    let loaded = false
    if (!props.endpoint) {
      loaded = true
    } else if (props.items && props.items.length > 0) {
      loaded = true
    }

    this.state = {
      loaded: loaded,
      loading: false,
      messageErrorCode: '',
      lastRequestWithError: false
    }
    this.loaderFunctionsMap = props.loaderFunctionsMap ? props.loaderFunctionsMap : {}
    this.fixedHeightListRef = React.createRef()
    this.windowScrollerRef = React.createRef()
    this.dynamicHeightListFunctionsMap = {}
  }

  getRowHeight = () => {
    if (this.props.rowHeight) {
      return this.props.rowHeight
    } else {
      return ROW_HEIGHT_DEFAULT
    }
  }

  onWindowResize = () => {
    window.setTimeout(() => {
      const windowWidth = WindowUtil.getWidth()
      if (this.lastWindowWidth !== windowWidth) {
        this.lastWindowWidth = windowWidth
        this.recomputeRowHeights()
      }
    }, 30)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize)

    this.ajustarReferenciaPosicaoTopInterval = window.setInterval(() => {
      if (this.windowScrollerRef.current) {
        let windowScrollerTop = this.windowScrollerRef.current._child.getBoundingClientRect().top
        let containerHeight = null
        let moveScrollFunction = null
        let updatePositionFunction = null
        if (this.props.scrollElement) {
          windowScrollerTop += this.props.scrollElement.scrollTop
          containerHeight = this.props.scrollElement.scrollHeight
          moveScrollFunction = () => {
            if (this.props.scrollElement.scrollTop === 0) {
              this.props.scrollElement.scrollTop = this.props.scrollElement.scrollTop + 1
            } else {
              this.props.scrollElement.scrollTop = this.props.scrollElement.scrollTop - 1
            }
          }
        } else {
          windowScrollerTop += window.scrollY
          containerHeight = DocumentUtil.getHeight()
          moveScrollFunction = () => {
            if (window.scrollY === 0) {
              window.scrollTo(0, window.scrollY + 1)
            } else {
              window.scrollTo(0, window.scrollY - 1)
            }
          }
        }
        updatePositionFunction = () => {
          this.windowScrollerRef.current.updatePosition()
          window.setTimeout(() => {
            moveScrollFunction()
          }, 10)
        }

        if (this.windowScrollerTop === undefined || this.windowScrollerTop !== windowScrollerTop) {
          if (this.containerHeight === undefined || this.containerHeight !== containerHeight) {
            updatePositionFunction()
          }
        }

        this.containerHeight = containerHeight
        this.windowScrollerTop = windowScrollerTop
      }
    }, 400)

    const rowHeight = this.getRowHeight()

    if (this.props.functionsMap !== undefined) {
      this.props.functionsMap['scrollToIndex'] = (index) => {
        if (this.props.dynamicHeight) {
          this.dynamicHeightListFunctionsMap.scrollToRow(index)
        } else {
          let scrollElementTopFromPage = null
          let scrollElementScrollTop = null
          let scrollTopFunction = null
          if (this.props.scrollElement) {
            scrollElementTopFromPage = this.props.scrollElement.getBoundingClientRect().y
            scrollElementScrollTop = this.props.scrollElement.scrollTop
            scrollTopFunction = (top) => {
              this.props.scrollElement.scrollTop = top
            }
          } else {
            scrollElementTopFromPage = 0
            scrollElementScrollTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
            scrollTopFunction = (top) => {
              window.scrollTo(0, top)
            }
          }
          window.setTimeout(() => {
            let scrollTop =
              index * rowHeight + (document.querySelector('.' + this.scrollClassId).getBoundingClientRect().y + scrollElementScrollTop - scrollElementTopFromPage) - 20
            if (scrollTop < 0) {
              scrollTop = 0
            }
            scrollTopFunction(scrollTop)
          }, 50)
        }
      }
      this.props.functionsMap['recomputeRowHeights'] = (index) => {
        //console.log("recomputeRowHeights");

        /*if(this.dynamicHeightListFunctionsMap.recomputeRowHeights){
					this.dynamicHeightListFunctionsMap.recomputeRowHeights(index);
				}*/
        this.recomputeRowHeights(index)
      }
      this.props.functionsMap['softRecomputeRowHeights'] = (index) => {
        //console.log("recomputeRowHeights");
        this.softRecomputeRowHeights(index)
      }

      this.props.functionsMap['forceUpdateGrid'] = (index) => {
        //console.log("forceUpdateGrid");
        this.clearCachedItems()
        if (this.fixedHeightListRef.current && this.fixedHeightListRef.current.forceUpdateGrid) {
          this.fixedHeightListRef.current.forceUpdateGrid()
        }
      }
    }
  }

  recomputeRowHeights = (index) => {
    //console.log("recomputeRowHeights");
    if (this.dynamicHeightListFunctionsMap.recomputeRowHeights) {
      this.dynamicHeightListFunctionsMap.recomputeRowHeights(index)
    }
  }

  softRecomputeRowHeights = (index) => {
    //console.log("softRecomputeRowHeights");
    if (this.dynamicHeightListFunctionsMap.softRecomputeRowHeights) {
      this.dynamicHeightListFunctionsMap.softRecomputeRowHeights(index)
    }
  }

  refresh = () => {
    this.setState({ loaded: false })
    this.loaderFunctionsMap.refresh()
  }

  getItems = () => {
    return this.props.items
  }

  cachedItems = {}

  clearCachedItems = () => {
    this.cachedItems = {}
  }

  isScrollEnding = () => {
    if (this.props.scrollElement) {
      if (this.props.scrollElement.scrollHeight - (this.props.scrollElement.offsetHeight + this.props.scrollElement.scrollTop) <= 300 && this.props.scrollElement.scrollTop > 0) {
        return true
      }
    } else {
      if (document.body.offsetHeight - (window.innerHeight + window.pageYOffset) <= 300 && window.pageYOffset > 0) {
        return true
      }
    }
    return false
  }

  tryLoadMore = (params) => {
    if (this.props.dynamicHeight !== true || this.isScrollEnding()) {
      const { index } = params
      if (!this.state.lastRequestWithError && this.props.itemsPerRequest && index === this.getItems().length - 1) {
        window.setTimeout(() => {
          if (this.loaderFunctionsMap.loadMore) {
            this.loaderFunctionsMap.loadMore(index)
          }
        }, 0)
      }
    }
  }

  rowRendererFixedHeight = (params) => {
    this.tryLoadMore(params)

    const item = this.getItems()[params.index]
    const cachedItem = this.cachedItems[params.index]
    if (cachedItem && cachedItem.isVisible === params.isVisible && item._update === false) {
      //console.log("VirtualizedList - Cached",params.index);
      item._rerender = false
      return cachedItem.itemRendered
    } else {
      //console.log("VirtualizedList - New",params.index);
      item._rerender = true
      const newCachedItem = { isVisible: params.isVisible, itemRendered: this.props.rowRenderer(item, params) }
      this.cachedItems[params.index] = newCachedItem
      item._update = false

      return newCachedItem.itemRendered
    }
  }

  rowRendererDynamicHeight = (params) => {
    this.tryLoadMore(params)
    const item = this.getItems()[params.index]
    return this.props.rowRenderer(item, params)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.themeInstanceId !== nextProps.theme.instanceId) {
      for (let item of nextProps.items) {
        item._update = true
      }
    }
    this.themeInstanceId = nextProps.theme.instanceId
    return true
  }

  render() {
    const {
      classes,
      disabled,
      windowScroller,
      scrollElement,
      loaderKey,
      endpoint,
      contextoUsuario,
      getRequestParametersFunction,
      itemsPerRequest,
      autoIncrementNewItems,
      scrollToIndex,
      loadTrackingFunction,
      onLoad,
      loadingIndicatorRowHeight,
      overscanRowCount
    } = this.props
    const rowHeight = this.getRowHeight()

    if (scrollElement) {
      //console.log("scrollElement COM REFERENCIA", endpoint,scrollElement)
    } else {
      //console.log("scrollElement NULL", endpoint)
    }

    let components = []

    components.push(
      <ContentLoadStatus
        key="ContentLoadStatus"
        padding={this.props.emptyListProps ? this.props.emptyListProps.padding : false}
        align={this.props.emptyListProps ? this.props.emptyListProps.align : undefined}
        loading={this.state.loading}
        loaded={this.state.loaded}
        messageErrorCode={this.state.codigoMensagemErro}
        loadFunction={() => {
          this.loaderFunctionsMap.load()
        }}
        notLoadedContentTemplate={this.props.notLoadedContentTemplate}
      />
    )

    components.push(<EmptyListContent key="EmptyListContent" loading={this.state.loading} loaded={this.state.loaded} items={this.getItems()} {...this.props.emptyListProps} />)

    let itemsLength = this.state.loaded ? this.getItems().length : 0

    if (itemsLength > 0) {
      const loadingContainerStyle = {}
      if (this.props.showBackgroundDividers) {
        const backgroundProps = BackgroundUtil.generateBackgroundLines(rowHeight, 1, '#ffffff', '#e8e8e8')
        loadingContainerStyle.backgroundSize = backgroundProps.backgroundSize
        loadingContainerStyle.backgroundImage = backgroundProps.backgroundImage
        /*loadingContainerStyle.backgroundImage = "linear-gradient(0deg, #e8e8e8 0.89%, #ffffff 0.89%, #ffffff 50%, #e8e8e8 50%, #e8e8e8 50.89%, #ffffff 50.89%, #ffffff 100%)";
				loadingContainerStyle.backgroundSize = "112.00px 112.00px";*/
      }
      if (this.props.showRowLoadingMessage === undefined || this.props.showRowLoadingMessage === true) {
        components.push(
          <div key="LoadingLabelContainer" className={classes.loadingContainer} style={loadingContainerStyle}>
            <div className={classes.loadingLabelContainer} style={{ lineHeight: loadingIndicatorRowHeight ? loadingIndicatorRowHeight + 'px' : rowHeight + 'px' }}>
              {this.getItems().map(() => 'Carregando... ')}
            </div>
          </div>
        )
      }

      if (disabled) {
        components.push(<div key="DisabledContainer" className={classes.disabledContainer}></div>)
      }
    }

    if (itemsLength > 0 && (windowScroller === undefined || windowScroller)) {
      components.push(
        <WindowScroller key="WindowScroller" ref={this.windowScrollerRef} scrollElement={scrollElement ? scrollElement : window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <div ref={registerChild} className={this.scrollClassId}>
              <AutoSizer disableHeight>
                {({ width }) => {
                  if (this.props.dynamicHeight) {
                    return (
                      <DynamicHeightList
                        style={{ outline: 'none', marginTop: 0 }}
                        className="needsWillChange"
                        getItems={this.getItems}
                        rowRenderer={this.rowRendererDynamicHeight}
                        rowCount={itemsLength}
                        width={width}
                        height={height ? height : 0}
                        autoHeight
                        overscanRowCount={overscanRowCount ? overscanRowCount : 0}
                        onScroll={(params) => {
                          //ScrollOptimizer.onScroll(params.scrollTop);
                          onChildScroll(params)
                        }}
                        scrollToIndex={scrollToIndex}
                        scrollTop={scrollTop}
                        functionsMap={this.dynamicHeightListFunctionsMap}
                      />
                    )
                  } else {
                    return (
                      <List
                        ref={this.fixedHeightListRef}
                        style={{ outline: 'none', marginTop: 0 }}
                        className="needsWillChange"
                        height={height ? height : 0}
                        overscanRowCount={overscanRowCount ? overscanRowCount : 0}
                        rowCount={itemsLength}
                        rowHeight={rowHeight}
                        rowRenderer={this.rowRendererFixedHeight}
                        width={width}
                        autoHeight
                        onScroll={(params) => {
                          //ScrollOptimizer.onScroll(params.scrollTop);
                          onChildScroll(params)
                        }}
                        scrollToIndex={scrollToIndex}
                        scrollTop={scrollTop}
                      />
                    )
                  }
                }}
              </AutoSizer>
            </div>
          )}
        </WindowScroller>
      )
    }

    if (itemsPerRequest) {
      components.push(
        <VirtualizedListLoadMoreFooter
          key="VirtualizedListLoadMoreFooter"
          loading={this.state.loading}
          loaded={this.state.loaded}
          lastRequestWithError={this.state.lastRequestWithError}
          loadMoreFunction={() => this.loaderFunctionsMap.loadMore()}
        />
      )
    }

    if (endpoint) {
      components.push(
        <VirtualizedListLoader
          key={'VirtualizedListLoader'}
          customKey={loaderKey ? loaderKey : 'VirtualizedListLoader'}
          functionsMap={this.loaderFunctionsMap}
          endpoint={endpoint}
          contextoUsuario={contextoUsuario}
          getRequestParametersFunction={getRequestParametersFunction}
          itemsPerRequest={itemsPerRequest}
          onStart={(data) => {
            let state = { loaded: this.state.loaded }
            if (data.action === 'load' || data.action === 'refresh') {
              state.loaded = false
            }
            state.loading = true
            this.setState(state)
            if (loadTrackingFunction) {
              loadTrackingFunction({ status: 'loading', action: data.action })
            }
          }}
          onSuccess={(data) => {
            this.setState({ loading: false, loaded: true, lastRequestWithError: false })
            if (data.action === 'load' || data.action === 'refresh') {
              this.clearCachedItems()
            } else if (data.action === 'loadmore' && (autoIncrementNewItems === undefined || autoIncrementNewItems)) {
              data.items = this.getItems().concat(data.items)
            }
            if (loadTrackingFunction) {
              loadTrackingFunction({ status: 'loaded', action: data.action, items: data.items })
            }
            if (onLoad) {
              onLoad(data.items)
            }
          }}
          onError={(response) => {
            this.setState({ loading: false, lastRequestWithError: true, codigoMensagemErro: response.code })
            if (loadTrackingFunction) {
              loadTrackingFunction({ status: 'notloaded' })
            }
          }}
        />
      )
    }

    return components
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
    window.clearInterval(this.ajustarReferenciaPosicaoTopInterval)
  }
}

VirtualizedList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(VirtualizedList)
