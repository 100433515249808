import { capitalizeFirstLetter } from 'support/util/StringUtil'

import { CuponsPeriodEnum, CuponsPlansEnum } from '../CuponsEnum'

export const cuponsPeriodList = Object.keys(CuponsPeriodEnum).map((period) => ({ label: capitalizeFirstLetter(period.toLowerCase()), value: period }))

export const cuponsPlansList = [
  {
    label: 'Avaliação',
    value: CuponsPlansEnum.AVALIACAO
  },
  {
    label: 'Individual',
    value: CuponsPlansEnum.INDIVIDUAL
  },
  {
    label: 'Empresa',
    value: CuponsPlansEnum.EMPRESA
  }
]
