import React from 'react'

import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  HABILITADO: {
    id: 1,
    descricaoOpcao: 'Habilitado',
    descricaoExplicacao: <span>Esta opção habilita a funcionalidade de fluxo de caixa. É possivel abrir um caixa para cada profissional ou compartilhar o acesso.</span>
  },
  DESABILITADO: {
    id: 2,
    descricaoOpcao: 'Desabilitado',
    descricaoExplicacao: (
      <span>
        Esta opção desabilita a funcionalidade de fluxo de caixa, proporcionando um gerenciamento mais simples e facilitando o processo de lançamento de despesas e registros de
        pagamentos.
      </span>
    )
  }
})
export default enumObject
