import { lighten } from '@material-ui/core/styles/colorManipulator'
import { converteHexStrToRgbStr } from 'support/util/ColorUtil'

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 618,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  container: {
    alignItems: 'flex-start'
  },
  contentContainer: {
    ...theme.container.basic(),
    paddingTop: 24,
    paddingBottom: 30
  },
  titulo: {
    fontSize: '24px'
  },
  diaSelecionadoContainer: {
    background: theme.palette.background.default,
    padding: '20px 12px',
    marginBottom: 4,
    marginTop: 12,
    borderRadius: 8,
    textAlign: 'center'
  },
  colaboradorNaoSelecionado: {}, //Para trabalhar apenas com referencias $colaboradorNaoSelecionado
  containerItem: {
    paddingTop: 30,
    paddingBottom: 30,
    position: 'relative',
    borderTop: '1px solid #eee'
  },
  containerItemNavegacao: {
    border: '1px solid rgb(238, 238, 238)',
    display: 'inline-block',
    position: 'absolute',
    top: -18,
    right: 0,
    background: '#fff',
    borderRadius: 8
  },
  containerItemNavegacaoHeaderIconButton: {
    padding: 4,
    '& svg': {
      color: '#888'
    }
  },
  nomeServico: {
    fontSize: 16,
    lineHeight: '19px',
    [theme.breakpoints.down(420)]: {
      fontSize: 15,
      lineHeight: '18px'
    },
    [theme.breakpoints.down(360)]: {
      fontSize: 14,
      lineHeight: '17px'
    }
  },
  precoDuracaoServico: {
    marginTop: 6,
    fontSize: 14,
    [theme.breakpoints.down(360)]: {
      fontSize: 13
    }
  },
  textoInformativoSelecaoProfissional: {
    marginTop: 6,
    color: '#b6b6b6'
  },
  precoDuracaoServicoLabel: {
    [theme.breakpoints.down(410)]: {
      display: 'none'
    }
  },
  colaboradorContainer: {
    marginLeft: 16,
    marginRight: -0,
    minWidth: 88,
    maxWidth: 88,
    cursor: 'pointer',
    [theme.breakpoints.down(410)]: {
      marginLeft: 16
    }
  },
  colaboradorNomeContainer: {
    marginBottom: 0,
    marginTop: 6,
    display: 'inline-flex',
    maxWidth: 44
  },
  colaboradorNome: {
    fontSize: 13,
    lineHeight: '16px',
    '$colaboradorNaoSelecionado &': {
      color: theme.palette.secondary.main
    }
  },
  colaboradorNomeIconeContainer: {
    marginLeft: -1,
    marginRight: -12,
    marginTop: -7,
    marginBottom: -8,
    '$colaboradorNaoSelecionado & svg': {
      color: theme.palette.secondary.main
    }
  },
  fotoColaborador: {
    width: 40,
    height: 40,
    display: 'block',
    borderRadius: 80,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  semFotoColaboradorContainer: {
    display: 'block',
    width: 40,
    height: 40,
    borderRadius: 40,
    background: '#f2f2f2',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  semFotoColaboradorIcone: {
    fontSize: 26,
    padding: 7,
    color: '#999999'
  },
  fotoSelecioneColaboradorContainer: {
    display: 'block',
    width: 40,
    height: 40,
    borderRadius: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ffffff',
    animation: '$pulse 1.5s infinite',
    border: '2px solid ' + lighten(theme.palette.secondary.main, 0.33)
  },
  fotoSelecioneColaboradorIcone: {
    fontSize: 26,
    padding: 7,
    color: theme.palette.secondary.main
  },
  '@keyframes pulse': {
    '0%': {
      ...theme.util.createBoxShadow('0 0 0 0 ' + converteHexStrToRgbStr(theme.palette.secondary.main, 0.2))
    },
    '100%': {
      ...theme.util.createBoxShadow('0 0 0 16px ' + converteHexStrToRgbStr(theme.palette.secondary.main, 0))
    }
  },
  adicionarObservacoes: {
    cursor: 'pointer',
    padding: 4,
    margin: '1px -4px -4px -4px',
    display: 'block',
    fontSize: 14,
    color: '#b6b6b6'
  },
  campoObservacoesBotaoFechar: {
    padding: 5,
    position: 'absolute',
    right: -12,
    top: 0,
    background: 'white !important',
    border: '1px solid #d0d0d0'
  },
  campoObservacoesBotaoFecharIcone: {
    fontSize: 14,
    color: '#999',
    stroke: '#999',
    strokeWidth: 1
  },
  containerHorarioDisponivel: {
    display: 'inline-block',
    padding: 6,
    border: '1px solid #dfdfdf',
    borderRadius: 6,
    margin: 4,
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      background: '#f4f4f4'
    }
  },
  containerHorarioIndisponivel: {
    display: 'inline-block',
    padding: 6,
    border: '1px solid #dfdfdf',
    borderRadius: 6,
    margin: 4,
    fontSize: 14
  },
  containerHorarioEscolhido: {
    display: 'inline-block',
    padding: '12px 12px',
    border: '1px solid ' + theme.palette.secondary.main,
    marginLeft: 3,
    color: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.95)
    }
  },
  containerHorarioEscolhidoIcone: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginTop: -4,
    marginLeft: 4,
    marginRight: -3
  },
  fotoColaboradorMenu: {
    width: 50,
    height: 50,
    display: 'block',
    borderRadius: 80,
    marginRight: 16
  },
  semFotoColaboradorContainerMenu: {
    display: 'block',
    width: 50,
    height: 50,
    borderRadius: 60,
    background: '#f1f1f1',
    marginRight: 16
  },
  semFotoColaboradorIconeMenu: {
    fontSize: 34,
    padding: 8,
    color: '#999999'
  },
  confirmacaoNomeServico: {
    fontSize: 16,
    lineHeight: '19px'
  },
  confirmacaoData: {
    marginTop: 6
  },
  confirmacaoProfissional: {
    marginTop: 4
  },
  confirmacaoObservacoes: {
    marginTop: 4,
    color: '#a7a7a7'
  },
  textWithIconOnTheLeft: {
    display: 'flex',
    gap: '16px',
    paddingBottom: '30px',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: '14px'
    }
  }
})

export default styles
