import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import MarcaDialogPage from 'pages/erp/painel/inventario/MarcaDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class MarcaInputDialog extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    const creationFunction = (props) => {
      openBackableDialog(MarcaDialogPage, {
        nome: props.text
      })
    }

    if (!props.label) props.label = 'Marca'
    if (!props.id) props.id = 'xmarca'
    if (!props.name) props.name = 'xmarca'

    if (props.defaultValue !== undefined) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.creationFunction = creationFunction
    props.DialogSelectProps.creationPermission = verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])
    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Marca...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'erp/inventario.buscarMarcasPorNome'
    props.DialogSelectProps.searchParamName = 'nome'
    props.DialogSelectProps.generateItemData = (marca) => {
      return {
        value: marca.id,
        label: marca.nome,
        primary: marca.nome
      }
    }

    props.DialogSelectProps.autoSelectDefinitons = [
      {
        eventName: 'ManutencaoMarca',
        itemPropName: 'marca'
      }
    ]

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhuma marca foi encontrada',
        textButton: 'Cadastrar marca',
        creationFunction: creationFunction
      }
    }

    return <InputDialogSelect {...props} />
  }
}

MarcaInputDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MarcaInputDialog)
