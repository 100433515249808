import React from 'react'
import { isMobile } from 'react-device-detect'

import { Box, Button } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import classNames from 'classnames'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import CustomDialog from 'support/components/dialog/CustomDialog'

import { useStyles } from './styles'

interface AgendamentoOnlineTelaAtendimentoDialogPageProps {
  handleCloseDialog: () => void
  url: string
}

const copyLabelTimeout = 3000

const AgendamentoOnlineTelaAtendimentoDialogPage = (props: AgendamentoOnlineTelaAtendimentoDialogPageProps) => {
  const { handleCloseDialog, url } = props
  const dataLayer = window.dataLayer || []
  const classes = useStyles()
  const dialogProps = extractDialogProps(props)
  const [copyLabel, setCopyLabel] = React.useState('Copiar')

  return (
    <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={dialogProps} fullScreen={false}>
      <DialogContent className={classNames(classes.dialogContent)}>
        <Box className={classes.header}>
          <Typography variant="h6">Compartilhar agenda</Typography>
          <IconButton onClick={handleCloseDialog} data-testid="closeButton" className={classes.iconButton}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="body2">Compartilhe o link da agenda do seu negócio para seus clientes marcarem horários online.</Typography>

        <Box className={classes.copyContainer}>
          <Grid container direction="row" className={classes.urlContainer}>
            <Typography variant="body1" className={classes.urlLabel}>
              <Link style={{ verticalAlign: 'bottom', marginBottom: -1, marginRight: 6 }} />
              <b>{url}</b>
            </Typography>
          </Grid>
          <Button
            onClick={() => {
              dataLayer.push({
                event: 'analytics-event',
                event_name: 'interaction_modal_agendamento',
                cd_modal_name: 'compartilhar-agenda',
                cd_interaction_detail: 'click:copiar-link-do-negocio'
              })
              navigator.clipboard.writeText(url)
              setCopyLabel('Copiado')
              setTimeout(() => {
                setCopyLabel('Copiar')
              }, copyLabelTimeout)
            }}
            variant="contained"
            className={classes.copyButton}
            disableElevation
          >
            <FileCopyOutlinedIcon style={{ marginRight: 4 }} fontSize="small" />
            {copyLabel}
          </Button>
        </Box>

        {(isMobile || DeviceIdentifier.isNativeApp()) && navigator.share !== undefined && (
          <Button onClick={async () => await navigator.share({ url })} variant="contained" className={classes.shareButton} disableElevation>
            Compartilhar link
          </Button>
        )}
      </DialogContent>
    </CustomDialog>
  )
}

export default AgendamentoOnlineTelaAtendimentoDialogPage
