import { styled } from '@grupoboticario/flora'
import { ModalBody as FloraModalBody, ModalContent as FloraModalContent, Spinner } from '@grupoboticario/flora-react'

const ModalBody = styled(FloraModalBody, {
  '&&': {
    height: '100svh',
    overflow: 'hidden',
    padding: 0,

    '@desktop': {
      height: '700px'
    }
  }
})

const ModalContent = styled(FloraModalContent, {
  '&&': {
    '@mobile': {
      borderRadius: 0,
      width: '100%'
    },

    '@desktop': {
      borderRadius: '$medium',
      padding: '$1'
    }
  }
})

const Form = styled('iframe', {
  width: '100%',
  height: '100%',
  border: 'none'
})

const LoadingAnimation = styled(Spinner, {
  position: 'absolute',
  top: '50%',
  left: '50%'
})

export { ModalBody, ModalContent, Form, LoadingAnimation }
