import React from 'react'

import { Box, Button, MenuItem, Typography } from '@material-ui/core'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import { Installments } from 'support/domain/formapagamento/FormaPagamentoType'
import { TipoPagamentoEnum } from 'support/domain/mercadopago/MercadoPagoEnum'
import FormUtil from 'support/util/FormUtil'
import { InputPercentWithSuffixFormat } from 'support/util/Masks'

import { useStyles } from './FormaPagamentoMercadoPago.styles'

type FormPagamentoMercadoPagoProps = {
  formaPagamento: { nome: string }
  typePayment: string
  form: {
    device: string
    taxCredit: string
    taxDebit: string
    hasInstallments: string
    installmentsList: Installments[]
    installments: string
    loading: boolean
  }
  onSubmit: () => void
  onFormChange: (key: 'device' | 'taxCredit' | 'taxDebit' | 'hasInstallments' | 'installmentsList' | 'installments' | 'responseData' | 'loading', val: unknown) => void
  onCloseDialog: () => void
}

export const FormPagamentoMercadoPago = ({ formaPagamento, typePayment, form, onFormChange, onSubmit, onCloseDialog }: FormPagamentoMercadoPagoProps) => {
  const classes = useStyles()
  const list = [{ label: formaPagamento.nome, value: formaPagamento.nome }]
  const typeInstallmentsList = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
  ]

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>, installment: Installments) => {
    const newList = form.installmentsList.map((item) => {
      if (item.numero === installment.numero) {
        return { numero: item.numero, taxa: FormUtil.convertStringToNumber(event.target.value.replace('%', '')) }
      }
      return item
    })
    onFormChange('installmentsList', newList)
  }

  return (
    <form className={classes.form}>
      <InputSelect disabled defaultValue={form.device} customVariant="outlined-small" shrink={false} label="Maquininha" fullWidth>
        {list.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.value}
          </MenuItem>
        ))}
      </InputSelect>
      {typePayment === TipoPagamentoEnum.CARTAO_CREDITO ? (
        <InputText
          idname="tax-credit"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onFormChange('taxCredit', FormUtil.convertStringToNumber(event.target.value.replace('%', '')))}
          marginTop={true}
          customVariant="outlined-small"
          shrink={false}
          value={form.taxCredit}
          inputComponent={InputPercentWithSuffixFormat}
          inputProps={{
            minValue: 0,
            maxValue: 100
          }}
          label="Taxa para vendas à vista"
        />
      ) : (
        <InputText
          idname="tax-debit"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onFormChange('taxDebit', FormUtil.convertStringToNumber(event.target.value.replace('%', '')))}
          marginTop={true}
          customVariant="outlined-small"
          shrink={false}
          value={form.taxDebit}
          inputComponent={InputPercentWithSuffixFormat}
          inputProps={{
            minValue: 0,
            maxValue: 100
          }}
          label="Taxa"
        />
      )}

      {typePayment === TipoPagamentoEnum.CARTAO_CREDITO && (
        <>
          <Typography className={classes.subTitle} variant="body1" color="secondary">
            Configuração de vendas parcelado
          </Typography>
          <InputSelect
            data-testid="hasInstallments"
            idname="hasinstallments"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onFormChange('hasInstallments', event.target.value)}
            value={form.hasInstallments}
            customVariant="outlined-small"
            shrink={false}
            label="Permitir Parcelamento"
          >
            {typeInstallmentsList?.map((item) => (
              <MenuItem key={item.label} aria-label={item.label} value={String(item.value)}>
                {item.label}
              </MenuItem>
            ))}
          </InputSelect>
          {form.hasInstallments === 'true' && (
            <React.Fragment>
              <InputSelect
                data-testid="installments"
                idname="installments"
                marginTop
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onFormChange('installments', event.target.value)
                }}
                value={form.installments}
                customVariant="outlined-small"
                shrink={false}
                label="Parcelamento máximo permitido"
              >
                {form?.installmentsList?.map((item: Installments) => (
                  <MenuItem key={item?.numero} value={item.numero}>
                    {`${item.numero}x`}
                  </MenuItem>
                ))}
              </InputSelect>
              <Box className={classes.grid}>
                {form?.installmentsList
                  ?.filter((item: Installments) => item.numero <= Number(form.installments))
                  ?.map((parcel: Installments) => (
                    <InputText
                      idname={`parcel-${parcel.numero}`}
                      key={parcel.numero}
                      marginTop={true}
                      customVariant="outlined-small"
                      shrink={false}
                      label={`Taxa para ${parcel.numero}x (em %)`}
                      inputComponent={InputPercentWithSuffixFormat}
                      value={parcel.taxa}
                      inputProps={{
                        maxValue: 100
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeQuantity(event, parcel)}
                    />
                  ))}
              </Box>
            </React.Fragment>
          )}
        </>
      )}
      <Box className={classes.wrapperButtons}>
        <Button onClick={onCloseDialog}>Cancelar</Button>
        <Button disabled={form.loading} onClick={onSubmit} variant="contained" color="secondary">
          Salvar
        </Button>
      </Box>
    </form>
  )
}
