export function cloneObjectWithoutReference(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function parseStringToObject(inputString, separator = ';') {
  const rows = inputString.split(separator)

  const result = {}

  rows.forEach((row) => {
    const [key, value] = row.split('=')
    result[key] = value
  })
  return result
}

const functionsObject = {
  cloneObjectWithoutReference: cloneObjectWithoutReference
}

export default functionsObject
