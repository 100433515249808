import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import CustomDialog from 'support/components/dialog/CustomDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import FormUtil from 'support/util/FormUtil'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({})

class RecalcularRemuneracoesDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      colaborador: this.props.colaborador,
      dataInicial: this.props.dataInicial,
      dataFinal: this.props.dataFinal
    }

    this.autocompleteColaborador = {}
    this.dataInicialFunctionsMap = {}
    this.dataFinalFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.recalcular()
  }

  recalcular = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/colaborador/remuneracoes.recalcularRemuneracoes',
      data: {
        idColaborador: this.props.colaborador.id,
        dataInicial: this.dataInicialFunctionsMap.getDataAsInt(),
        dataFinal: this.dataFinalFunctionsMap.getDataAsInt()
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoComissao')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formRecalcular')
      }
    })
  }

  render() {
    const { errorsMap } = this.state

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogTitle>Recalcular Remunerações - {this.state.colaborador.apelido}</DialogTitle>
        <DialogContent>
          <form id="formRecalcular" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <InputHorizontalContainer
              inputTexts={[
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputData
                      id="xdatainicial"
                      name="xdatainicial"
                      label="Data (de)"
                      keyboard={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.dataInicialFunctionsMap}
                      defaultValue={this.state.dataInicial}
                      errorMessage={errorsMap('dataInicial')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputData
                      id="xdatafinal"
                      name="xdatafinal"
                      label="Data (até)"
                      keyboard={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.dataFinalFunctionsMap}
                      defaultValue={this.state.dataFinal}
                      errorMessage={errorsMap('dataFinal')}
                    />
                  )
                }
              ]}
            />

            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
            Cancelar
          </Button>
          <Button color="secondary" disabled={this.state.ajaxing} onClick={this.recalcular}>
            {this.state.ajaxing ? 'Aguarde' : 'Recalcular'}
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

RecalcularRemuneracoesDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RecalcularRemuneracoesDialog)
