import { useState } from 'react'

import { grupoBoticarioTheme } from '@grupoboticario/flora'
import { Flex, Heading, IconButton, Modal, ModalHeader, useIsMobile } from '@grupoboticario/flora-react'
import { CrossIcon } from '@grupoboticario/flora-react-icons'

import { Form, LoadingAnimation, ModalBody, ModalContent } from './TrainingModal.styles'

type Props = {
  title: string
  isOpen: boolean
  onClose: () => void
  href: string
}

const TrainingModal = ({ title, isOpen, onClose, href }: Props) => {
  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(true)

  return (
    <Modal open={isOpen}>
      <ModalContent size="standard" isFull={isMobile} className={grupoBoticarioTheme}>
        <ModalHeader>
          <Flex align="center" justify="space-between">
            <Heading level="6">{title}</Heading>

            <IconButton aria-label="Fechar Formulário" onClick={() => onClose()}>
              <CrossIcon />
            </IconButton>
          </Flex>
        </ModalHeader>
        <ModalBody>
          {isLoading && <LoadingAnimation size="lg" />}
          <Form aria-label="Formulário de Treinamento" src={href} loading="lazy" onLoad={() => setIsLoading(false)}></Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TrainingModal
