import React from 'react'
import NumberFormat from 'react-number-format'
import MaskedInput from 'react-text-mask'

function getInputRefForMaskedInput(inputRef) {
  return (ref) => {
    inputRef(ref ? ref.inputElement : null)
  }
}

export function getTelefoneMaskIdByNumero(numero) {
  if (numero !== undefined && numero.trim().length <= 14) {
    return 8
  } else {
    return 9
  }
}

export function getDataNascimentoAnoMaskIdByNumero(dataNascimento) {
  if (dataNascimento !== undefined && dataNascimento.trim().length <= 5) {
    return false
  } else {
    return true
  }
}

export function TelefoneMask(props) {
  const { inputRef, maskId, ...other } = props
  let mask = null

  if (maskId === 8) {
    mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  } else if (maskId === 9) {
    mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  return <MaskedInput {...other} ref={getInputRefForMaskedInput(inputRef)} mask={mask} placeholderChar={'\u2000'} showMask={false} />
}

export function CEPMask(props) {
  const { inputRef, ...other } = props
  let mask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
  return <MaskedInput {...other} ref={getInputRefForMaskedInput(inputRef)} mask={mask} placeholderChar={'\u2000'} showMask={false} />
}

export function CPFMask(props) {
  const { inputRef, ...other } = props
  let mask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
  return <MaskedInput {...other} ref={getInputRefForMaskedInput(inputRef)} mask={mask} placeholderChar={'\u2000'} showMask={false} />
}

export function CNPJMask(props) {
  const { inputRef, ...other } = props
  let mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
  return <MaskedInput {...other} ref={getInputRefForMaskedInput(inputRef)} mask={mask} placeholderChar={'\u2000'} showMask={false} />
}

export function DataNascimentoMask(props) {
  const { inputRef, maskAnoNascimento, ...other } = props
  let mask = null
  if (!maskAnoNascimento) {
    mask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/]
  } else {
    mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  }

  return <MaskedInput {...other} ref={getInputRefForMaskedInput(inputRef)} mask={mask} placeholderChar={'\u2000'} showMask={false} />
}

export function InputStringNumberFormat(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={getInputRefForMaskedInput(inputRef)}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
    />
  )
}

export function BaseNumberFormat(props) {
  const { inputRef, minValue, maxValue, ...other } = props
  return (
    <NumberFormat
      {...other}
      isAllowed={(values) => {
        const { floatValue } = values
        return !floatValue || (floatValue >= (minValue ? minValue : 0) && floatValue <= (maxValue ? maxValue : 99999999))
      }}
      getInputRef={inputRef}
    />
  )
}

export function InputNumberFormat(props) {
  return <BaseNumberFormat {...props} decimalScale={0} />
}

export function InputMoneyFormat(props) {
  return <BaseNumberFormat {...props} thousandSeparator="." decimalSeparator="," decimalScale={2} fixedDecimalScale={true} />
}

export function InputFloatNumberFormat(props) {
  return <BaseNumberFormat {...props} decimalSeparator="," thousandSeparator="." decimalScale={2} />
}

export function InputPercentFormat(props) {
  return <BaseNumberFormat {...props} decimalSeparator="," thousandSeparator="." decimalScale={2} />
}

export function InputPercentWithSuffixFormat(props) {
  const { inputRef, ...other } = props
  return <BaseNumberFormat {...other} suffix="%" decimalSeparator="," thousandSeparator="." decimalScale={2} inputRef={inputRef} fixedDecimalScale={true} />
}
