import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const styles = (theme) => ({
  textColor: {
    color: theme.palette.snackbar.error.text
  },
  root: {
    zIndex: 9999999
  },
  content: {
    background: theme.palette.snackbar.error.background
  }
})

class SnackBarError extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      _snackbarErrorOpen: false,
      _snackbarErrorMessage: ''
    }
    this.setupSubscribes()
  }

  setupSubscribes() {
    this.eventsManager.sub('MensagemSnackBar', (message) => {
      if (message !== undefined && message.length > 0) {
        this.setState({ _snackbarErrorOpen: true, _snackbarErrorMessage: message })
      }
    })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ _snackbarErrorOpen: false })
  }

  render() {
    const { classes } = this.props

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state._snackbarErrorOpen}
        classes={{
          root: classes.root
        }}
        ContentProps={{
          classes: {
            root: classes.content
          }
        }}
        autoHideDuration={5000}
        onClose={this.handleClose}
        className="needsWillChange"
        style={{ marginTop: 0, wwillChange: 'trransform' }}
        message={<span className={classes.textColor}>{this.state._snackbarErrorMessage}</span>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

SnackBarError.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SnackBarError)
