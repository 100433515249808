import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'
import moment from 'moment'
import NegocioSupport from 'pages/erp/painel/negocio/NegocioSupport'
import { getSessaoPainel, verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import InputTipoVenda from 'pages/erp/painel/venda/input/InputTipoVenda'
import VendasResponsiveTable from 'pages/erp/painel/venda/table/VendasResponsiveTable'
import VendaPopup from 'pages/erp/painel/venda/VendaPopup'
import VendasPesquisaAvancadaDialog from 'pages/erp/painel/venda/VendasPesquisaAvancadaDialog'
import VendaSupport from 'pages/erp/painel/venda/VendaSupport'
import PropTypes from 'prop-types'
import { openBackableDialog, openBackablePopup } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterDateParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { createEnum } from 'support/util/EnumUtil'
import { converterMomentParaHorarioInt } from 'support/util/TimeConverter'

const LABEL_UTILIZACAO_PACOTE = 'Utilização Pacote'

export const StatusFiltroEnum = createEnum({
  TODAS: { id: 1, descricao: 'Todas', statusVendaId: null },
  SEM_NOTA: { id: 2, descricao: 'Pendentes - Sem Fatura', statusVendaId: StatusVendaEnum.NOTA_PENDENTE.id, statusVenda: StatusVendaEnum.NOTA_PENDENTE },
  NOTA_ABERTA: { id: 3, descricao: 'Com Fatura Aberta', statusVendaId: StatusVendaEnum.NOTA_ABERTA.id, statusVenda: StatusVendaEnum.NOTA_ABERTA },
  NOTA_FECHADA: { id: 4, descricao: 'Com Fatura Fechada', statusVendaId: StatusVendaEnum.NOTA_FECHADA.id, statusVenda: StatusVendaEnum.NOTA_FECHADA },
  CANCELADAS: { id: 5, descricao: 'Canceladas', statusVendaId: null }
})

const styles = (theme) => ({})

class VendasPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      title: 'Vendas',
      tipo: this.colaboradorLocalPreferences.getPreference('VendasPanelPageFiltro_Tipo', null),
      status: this.colaboradorLocalPreferences.getPreference('VendasPanelPageFiltro_Status', StatusFiltroEnum.TODAS),
      ajaxing: false
    }

    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoVenda', 'ManipulacaoVendas'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  abrirPesquisaAvancadaDialog = () => {
    openBackableDialog(VendasPesquisaAvancadaDialog, {
      colaborador: this.state.colaborador,
      cliente: this.state.cliente,
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal,
      exibirVendasFuturas: this.state.exibirVendasFuturas,
      setParentState: (state) => {
        this.setState(state)
      }
    })
  }

  getRequestParameters = () => {
    let cancelado = null
    if (this.state.status.id === StatusFiltroEnum.CANCELADAS.id) {
      cancelado = true
    } else if (this.state.status.statusVenda) {
      cancelado = false
    }

    const exibirVendasFuturas = this.state.exibirVendasFuturas

    let dataFinal = converterDateParaDataInt(this.state.dataFinal)
    const hojeAsDataInt = converterDateParaDataInt(new Date())

    if (!exibirVendasFuturas && !dataFinal) {
      dataFinal = hojeAsDataInt
    }

    return {
      nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null,
      idColaborador: this.state.colaborador ? this.state.colaborador.id : null,
      idStatus: this.state.status.statusVenda ? this.state.status.statusVenda.id : null,
      cancelado: cancelado,
      idCliente: this.state.cliente ? this.state.cliente.id : null,
      dataInicial: converterDateParaDataInt(this.state.dataInicial),
      dataFinal: dataFinal,
      tipo: this.state.tipo ? this.state.tipo.id : null
    }
  }

  getChipsFiltrosAvancados = () => {
    const { colaborador, cliente, dataInicial, dataFinal, exibirVendasFuturas } = this.state
    const chips = []

    if (exibirVendasFuturas) {
      chips.push({
        label: 'Exibir vendas futuras',
        onDelete: () => this.setState({ exibirVendasFuturas: false })
      })
    }

    if (colaborador && colaborador.id) {
      chips.push({
        label: 'Colaborador: ' + colaborador.nome,
        onDelete: () => this.setState({ colaborador: undefined })
      })
    }

    if (cliente && cliente.id) {
      chips.push({
        label: 'Cliente: ' + cliente.nome,
        onDelete: () => this.setState({ cliente: undefined })
      })
    }

    if (dataInicial && dataFinal) {
      chips.push({
        label: moment(dataInicial).format('DD/MMM/YYYY') + ' até ' + moment(dataFinal).format('DD/MMM/YYYY'),
        onDelete: () => this.setState({ dataInicial: undefined, dataFinal: undefined })
      })
    } else {
      if (dataInicial) {
        chips.push({
          label: 'A partir: ' + moment(dataInicial).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataInicial: undefined })
        })
      }

      if (dataFinal) {
        chips.push({
          label: 'Até: ' + moment(dataFinal).format('DD/MMM/YYYY'),
          onDelete: () => this.setState({ dataFinal: undefined })
        })
      }
    }
    return chips
  }

  getLoaderKey = () => {
    const { status, colaborador, cliente, dataInicial, dataFinal, exibirVendasFuturas, tipo } = this.state

    let key = 'loader'

    if (exibirVendasFuturas) {
      key += '-evf' + exibirVendasFuturas
    }

    if (status) {
      key += '-f' + status.id
    }

    if (colaborador && colaborador.id) {
      key += '-col' + colaborador.id
    }

    if (cliente && cliente.id) {
      key += '-cli' + cliente.id
    }

    if (dataInicial) {
      key += '-di' + dataInicial
    }

    if (dataFinal) {
      key += '-df' + dataFinal
    }

    if (tipo && tipo.id) {
      key += '-tp' + tipo.id
    }

    return key
  }

  abrirVendaPopup = (event, venda) => {
    event.stopPropagation()

    const clientX = event.clientX
    const clientY = event.clientY

    NegocioSupport.tentarExibirAvisoImportante({
      onIgnore: () => {
        openBackablePopup(VendaPopup, {
          clientX: clientX,
          clientY: clientY,
          anchorReference: 'anchorPosition',
          venda: venda
        })
      }
    })
  }

  adicionarVenda = () => {
    VendaSupport.adicionarVenda({
      horarioPrePreenchido: converterMomentParaHorarioInt(moment())
    })
  }

  downloadRelatorio = (event) => {
    const { dataInicial, dataFinal } = this.state

    let filename = 'Lista de Vendas'
    if (dataInicial || dataFinal) {
      filename += ' - ' + formatarPeriodoNomeArquivo(dataInicial, dataFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'vendas.gerarListaVendas',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { tipo, status } = this.state

    const permissaoPodeVender = verificarAutorizacao([PA.PODE_VENDER])

    const contadorInfo = {
      [StatusFiltroEnum.SEM_NOTA.descricao]: 0,
      [StatusFiltroEnum.NOTA_ABERTA.descricao]: 0,
      [StatusFiltroEnum.NOTA_FECHADA.descricao]: 0,
      [StatusFiltroEnum.CANCELADAS.descricao]: 0,
      [LABEL_UTILIZACAO_PACOTE]: 0
    }

    for (let item of this.state.lista) {
      let descricaoAgrupamento = StatusFiltroEnum.getByStatusVendaId(item.status.id).descricao

      if (item.cancelado) {
        descricaoAgrupamento = StatusFiltroEnum.CANCELADAS.descricao
      }

      contadorInfo[descricaoAgrupamento] = contadorInfo[descricaoAgrupamento] + 1

      if (item.clientePacoteItem) {
        contadorInfo[LABEL_UTILIZACAO_PACOTE] = contadorInfo[LABEL_UTILIZACAO_PACOTE] + 1
      }
    }

    for (let descricaoAgrupamentoNull of Object.keys(contadorInfo).filter((descricao) => contadorInfo[descricao] === 0)) {
      delete contadorInfo[descricaoAgrupamentoNull]
    }

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push(
      {
        icon: <RefreshIcon />,
        disabled: !permissaoPodeVender,
        handleAction: () => {
          this.loaderFunctionsMap.load()
        }
      },
      {
        icon: <AddIcon />,
        disabled: !permissaoPodeVender,
        handleAction: this.adicionarVenda,
        'data-testid': 'add-icon'
      },
      {
        icon: <TuneIcon />,
        label: 'Filtrar',
        disabled: !permissaoPodeVender,
        handleAction: this.abrirPesquisaAvancadaDialog
      }
    )

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <PanelPage {...others} title={this.state.title} toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent chips={this.getChipsFiltrosAvancados()} type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 16 }}>
              <InputTipoVenda
                descricaoTodos="Tipo"
                disabled={!permissaoPodeVender}
                nakedLeftPadding={false}
                customVariant="naked"
                value={tipo ? tipo.id : null}
                fullWidth={false}
                onChange={(event) => {
                  const tipo = TipoVendaEnum.getById(parseInt(event.target.value, 10))
                  this.colaboradorLocalPreferences.setPreference('VendasPanelPageFiltro_Tipo', tipo)
                  this.setState({ tipo: tipo })
                }}
              />
            </Grid>

            <Grid item style={{ marginRight: 16 }}>
              <InputSelect
                disabled={!permissaoPodeVender}
                nakedLeftPadding={false}
                customVariant="naked"
                value={status.id}
                fullWidth={false}
                onChange={(event) => {
                  const statusFiltro = StatusFiltroEnum.getById(parseInt(event.target.value, 10))
                  this.colaboradorLocalPreferences.setPreference('VendasPanelPageFiltro_Status', statusFiltro)
                  this.setState({ status: statusFiltro })
                }}
                renderValue={(value) => {
                  const status = StatusFiltroEnum.getById(parseInt(value, 10))
                  if (StatusFiltroEnum.TODAS.id === status.id) {
                    return 'Situação'
                  } else {
                    return status.descricao
                  }
                }}
              >
                {StatusFiltroEnum.values().map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.descricao}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item xs style={{ marginRight: 16, minWidth: 200 }}>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" style={{ display: 'block' }} />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    disabled={!permissaoPodeVender}
                    defaultValue={this.colaboradorLocalPreferences.getPreference('VendasPanelPageFiltro_Texto', '')}
                    nakedLeftPadding={false}
                    placeholder="Procurar por palavra..."
                    onChange={(event) => {
                      this.colaboradorLocalPreferences.setPreference('VendasPanelPageFiltro_Texto', event.target.value)
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>
        <br />
        <VendasResponsiveTable
          itemsPerRequest={20}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/vendas.buscarVendas"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.abrirVendaPopup(event, item)
          }}
          emptyListProps={{
            text: 'Nenhuma venda foi encontrada',
            textButton: 'Adicionar Venda',
            creationFunction: this.adicionarVenda
          }}
        />

        {verificarAutorizacao([PA.PODE_VISUALIZAR_TOTALIZADORES_VALORES]) && this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            {Object.keys(contadorInfo).map((descricao, index) => {
              const contadorInfoItem = contadorInfo[descricao]
              return (
                <React.Fragment key={index}>
                  <Typography variant="body2" style={{ paddingTop: index === 0 ? 6 : 0 }}>
                    <b>{descricao}</b>
                    <br />
                    <small>
                      Quantidade: <b>{contadorInfoItem}</b>
                    </small>
                  </Typography>
                  {index < Object.keys(contadorInfo).length - 1 && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
                </React.Fragment>
              )
            })}
          </FloatContent>
        )}
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

VendasPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VendasPanelPage)
