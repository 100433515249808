import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'
import moment from 'moment'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import CategoriaServicoDialogPage from 'pages/erp/painel/servico/CategoriaServicoDialogPage'
import ServicoDadosBasicosDialogPage from 'pages/erp/painel/servico/ServicoDadosBasicosDialogPage'
import ServicoDialogPage from 'pages/erp/painel/servico/ServicoDialogPage'
import ServicoPesquisaAvancadaDialog from 'pages/erp/painel/servico/ServicoPesquisaAvancadaDialog'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import SelectedItemsActionSnackBar from 'support/components/snackbar/SelectedItemsActionSnackBar'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import ColorSchema from 'support/domain/color/ColorSchema'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import ArrayUtil from 'support/util/ArrayUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { formatarDuracao } from 'support/util/TimeFormatter'

const styles = (theme) => ({
  colunaCheckbox: {
    maxWidth: 58,
    minWidth: 58
  },
  colunaCor: {
    maxWidth: 36,
    minWidth: 36
  },
  colunaServico: {
    flexGrow: 1.3
  },
  colunaDuracao: {
    maxWidth: 100,
    minWidth: 100
  },
  colunaDuracaoMedium: {
    maxWidth: 100,
    minWidth: 100,
    [theme.breakpoints.down(720)]: {
      display: 'none'
    }
  },
  colunaPreco: {
    maxWidth: 120,
    minWidth: 120
  },
  indicadorCor: {
    width: 13,
    height: 13,
    borderRadius: 15,
    backgroundColor: '#ff8989',
    border: '1px solid white'
  },
  backgroundColorInherit: {
    backgroundColor: 'inherit'
  },
  nomeServicoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 }),
    marginTop: 0
  }
})

class ServicosPanelPage extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.loaderFunctionsMap = {}

    this.state = {
      paginaCarregada: false,
      categorias: [],
      lista: [],

      categoria: { id: null },
      precoInicial: null,
      precoFinal: null,
      comissaoInicial: null,
      comissaoFinal: null,
      duracaoInicial: null,
      duracaoFinal: null,

      selecionarTodos: false
    }
    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCategoriaServico', (props) => {
      if (props.categorias) {
        let categoriaState = { id: null }
        for (let categoria of props.categorias) {
          if (categoria.id === this.state.categoria.id) {
            categoriaState = categoria
            break
          }
        }
        this.setState({ categorias: props.categorias, categoria: categoriaState })
      }
      this.loaderFunctionsMap.load()
    })
    this.eventsManager.sub('ManipulacaoServico', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  selecionarTodos = (event) => {
    const lista = this.state.lista
    if (event.target.checked) {
      for (let item of lista) {
        item._checked = true
        item._update = true
      }
    } else {
      for (let item of lista) {
        item._checked = false
        item._update = true
      }
    }
    this.setState({ lista: lista, selecionarTodos: event.target.checked })
  }

  selecionarItem = (event, index) => {
    const lista = this.state.lista
    const item = lista[index]
    item._checked = event.target.checked
    item._update = true
    let selecionarTodos = true
    for (let i = 0; i < lista.length; i++) {
      if (!lista[i]._checked) {
        selecionarTodos = false
        break
      }
    }
    this.setState({ selecionarTodos: selecionarTodos, lista: lista })
  }

  desabilitarRemocaoLote = () => {
    const { lista } = this.state
    for (let i = 0; i < lista.length; i++) {
      const item = lista[i]
      item._checked = false
      item._update = true
    }
    this.setState({ lista: lista, selecionarTodos: false })
  }

  openCriarItemMenu = (event) => {
    this.setState({ criarItemMenuAnchorEl: event.currentTarget })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  abrirPesquisaAvancadaDialog = () => {
    openBackableDialog(ServicoPesquisaAvancadaDialog, {
      categorias: this.state.categorias,
      precoInicial: this.state.precoInicial,
      precoFinal: this.state.precoFinal,
      comissaoInicial: this.state.comissaoInicial,
      comissaoFinal: this.state.comissaoFinal,
      duracaoInicial: this.state.duracaoInicial,
      duracaoFinal: this.state.duracaoFinal,
      setParentState: (state) => {
        this.setState(state)
      }
    })
  }

  abrirServicoDialog = (props) => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_CAD_SERVICOS])) {
      openBackableDialog(ServicoDadosBasicosDialogPage, props)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  abrirCategoriaServicoDialog = () => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_CAD_SERVICOS])) {
      openBackableDialog(CategoriaServicoDialogPage)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = (tipo) => {
    if (tipo === 'categoria') {
      if (verificarAutorizacao([PA.PODE_ALTERAR_CAD_SERVICOS])) {
        openBackableDialog(CategoriaServicoDialogPage, { idCategoriaServico: this.state.itemMenu.id })
      } else {
        openBackableDialog(AccessDeniedDialog)
      }
    } else if (tipo === 'servico') {
      const servico = this.state.itemMenu.servicos[0]
      openBackableDialog(ServicoDialogPage, {
        idServico: servico.id
      })
    }
  }

  handlerRemover = (tipo) => {
    if (!verificarAutorizacao([PA.PODE_REMOVER_SERVICO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    if (tipo === 'categoria') {
      openBackableDialog(EntityRemovalDialog, {
        title: 'Remover categoria',
        text: (
          <span>
            Você tem certeza que deseja remover a categoria <b>{this.state.itemMenu.nome}</b>? Essa operação não poderá ser desfeita
          </span>
        ),
        call: {
          method: 'post',
          url: 'erp/servicos.removerCategoria',
          contextoUsuario: 'erp',
          data: {
            id: this.state.itemMenu.id
          },
          onSuccess: (response) => {
            EventsManager.pub('ManipulacaoCategoriaServico', { categorias: response.data })
          },
          errorKey: 'categoria'
        }
      })
    } else if (tipo === 'servico') {
      const servico = this.state.itemMenu.servicos[0]

      openBackableDialog(EntityRemovalDialog, {
        title: 'Remover serviço',
        text: (
          <span>
            Você tem certeza que deseja remover o serviço <b>{servico.nome}</b>? Essa operação não poderá ser desfeita.
          </span>
        ),
        call: {
          method: 'post',
          url: 'erp/servicos.remover',
          contextoUsuario: 'erp',
          data: {
            id: servico.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoServico')
          },
          errorKey: 'pacote'
        }
      })
    }
  }

  getQuantidadeItensSelecionadosRemocao = () => {
    const { lista } = this.state
    let itensSelecionados = 0
    for (let i = 0; i < lista.length; i++) {
      const item = lista[i]
      if (item._checked) {
        itensSelecionados++
      }
    }
    return itensSelecionados
  }

  removerItensSelecionados = () => {
    if (!verificarAutorizacao([PA.PODE_REMOVER_SERVICO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const itensSelecionados = this.getQuantidadeItensSelecionadosRemocao()
    let textSelecionados = 'o item selecionado'
    if (itensSelecionados > 1) {
      textSelecionados = (
        <span>
          os <b>{itensSelecionados} itens</b> selecionados
        </span>
      )
    }

    const idsCategoria = []
    const idsServico = []
    const idsVariacao = []

    for (let item of this.state.lista) {
      if (item._checked) {
        idsCategoria.push(item.id)
        if (item.servicos && item.servicos.length > 0) {
          const servico = item.servicos[0]
          idsServico.push(servico.id)
          if (servico.variacoes && servico.variacoes.length > 0) {
            const variacao = servico.variacoes[0]
            idsVariacao.push(variacao.id)
          }
        }
      }
    }

    const data = {}
    if (idsCategoria.length > 0) {
      data.idsCategoria = idsCategoria
    }
    if (idsServico.length > 0) {
      data.idsServico = idsServico
    }
    if (idsVariacao.length > 0) {
      data.idsVariacao = idsVariacao
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover itens',
      text: <span>Você tem certeza que deseja remover {textSelecionados}? Essa operação não poderá ser desfeita</span>,
      call: {
        method: 'post',
        url: 'erp/servicos.removerEntidadesServicos',
        contextoUsuario: 'erp',
        data: data,
        onSuccess: (response) => {
          EventsManager.pub('ManipulacaoCategoriaServico', { categorias: response.data })
        },
        errorKey: ['categoria', 'pacote']
      }
    })
  }

  getLoaderKey = () => {
    const { categoria, precoInicial, precoFinal, comissaoInicial, comissaoFinal, duracaoInicial, duracaoFinal } = this.state

    let key = 'loader'

    if (categoria.id) {
      key += '-c' + this.state.categoria.id
    }
    if (precoInicial !== null) {
      key += '-pi' + precoInicial
    }
    if (precoFinal !== null) {
      key += '-pf' + precoFinal
    }
    if (comissaoInicial !== null) {
      key += '-ci' + comissaoInicial
    }
    if (comissaoFinal !== null) {
      key += '-cf' + comissaoFinal
    }
    if (duracaoInicial !== null) {
      key += '-di' + duracaoInicial
    }
    if (duracaoFinal !== null) {
      key += '-df' + duracaoFinal
    }

    return key
  }

  getRequestParameters = () => {
    return {
      idCategoria: this.state.categoria.id ? this.state.categoria.id : null,
      nomeServico: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null,
      precoInicial: this.state.precoInicial,
      precoFinal: this.state.precoFinal,
      comissaoInicial: this.state.comissaoInicial,
      comissaoFinal: this.state.comissaoFinal,
      duracaoInicial: this.state.duracaoInicial,
      duracaoFinal: this.state.duracaoFinal
    }
  }

  getNomeCategoriaPorId = (id) => {
    for (let categoria of this.state.categorias) {
      if (categoria.id === id) {
        return categoria.nome
      }
    }
    return ''
  }

  getChipsFiltrosAvancados = () => {
    const { precoInicial, precoFinal, comissaoInicial, comissaoFinal, duracaoInicial, duracaoFinal } = this.state
    const chips = []

    if (precoInicial !== undefined && precoInicial !== null) {
      chips.push({
        label: 'Preço: a partir de ' + formatarValorMonetario(precoInicial, { fixedDecimalScale: false }),
        onDelete: () => this.setState({ precoInicial: null })
      })
    }

    if (precoFinal !== undefined && precoFinal !== null) {
      chips.push({
        label: 'Preço: até ' + formatarValorMonetario(precoFinal, { fixedDecimalScale: false }),
        onDelete: () => this.setState({ precoFinal: null })
      })
    }

    if (comissaoInicial !== undefined && comissaoInicial !== null) {
      chips.push({
        label: 'Comissão: a partir de ' + formatarValorMonetario(comissaoInicial, { prefix: false, fixedDecimalScale: false }),
        onDelete: () => this.setState({ comissaoInicial: null })
      })
    }

    if (comissaoFinal !== undefined && comissaoFinal !== null) {
      chips.push({
        label: 'Comissão: até ' + formatarValorMonetario(comissaoFinal, { prefix: false, fixedDecimalScale: false }),
        onDelete: () => this.setState({ comissaoFinal: null })
      })
    }

    if (duracaoInicial !== undefined && duracaoInicial !== null) {
      chips.push({
        label: 'Duração: a partir de ' + formatarDuracao(duracaoInicial, { descricao: 'minificada' }),
        onDelete: () => this.setState({ duracaoInicial: null })
      })
    }

    if (duracaoFinal !== undefined && duracaoFinal !== null) {
      chips.push({
        label: 'Duração: até ' + formatarDuracao(duracaoFinal, { descricao: 'minificada' }),
        onDelete: () => this.setState({ duracaoFinal: null })
      })
    }

    return chips
  }

  getRenderContent = (size, item, index) => {
    const { classes } = this.props
    const content = {}

    const checkboxStyle = {}

    if (size === 'small') {
      checkboxStyle.marginLeft = -8
    }
    if (!this.podeRemoverServico) {
      checkboxStyle.display = 'none'
    }

    content.checkbox = (
      <Checkbox
        style={checkboxStyle}
        disabled={!verificarAutorizacao([PA.PODE_REMOVER_SERVICO])}
        checked={item._checked === undefined ? false : item._checked}
        onClick={(event) => {
          event.stopPropagation()
          this.selecionarItem(event, index)
        }}
      />
    )
    content.indicadorCor = <div className={classes.indicadorCor} style={{ backgroundColor: ColorSchema.getColorById(item.cor).light }}></div>
    content.nomeCategoria = item.nome

    let servico = null
    if (item.servicos) {
      servico = item.servicos[0]

      if (servico.variacoes) {
        const variacao = servico.variacoes[0]
        const nomeVariacao = variacao.nome
        content.nomeServico = servico.nome + (nomeVariacao ? ': ' + nomeVariacao : '')

        content.duracao = formatarDuracao(variacao.duracao, { descricao: 'minificada' })
        content.preco = formatarValorMonetario(variacao.preco, { prefix: false })
      }

      if (size === 'small') {
        content.duracaoEPreco = (
          <Typography variant="body2">
            <b>
              {content.duracao}, {content.preco}
            </b>
          </Typography>
        )
      }
    } else {
      content.nomeServico = 'Nenhum serviço cadastrado'
    }

    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Lista de Servicos - ' + moment().format('YYYYDDMM'),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'servicos.gerarListaServicos',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    this.podeRemoverServico = verificarAutorizacao([PA.PODE_REMOVER_SERVICO])

    const checkboxStyle = {}
    if (!this.podeRemoverServico) {
      checkboxStyle.display = 'none'
    }

    const { classes, ...others } = this.props
    const { criarItemMenuAnchorEl, menuAnchorPosition } = this.state

    const quantidadeItensSelecionadosRemocao = this.getQuantidadeItensSelecionadosRemocao()

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push(
      {
        icon: <TuneIcon />,
        handleAction: this.abrirPesquisaAvancadaDialog
      },
      {
        icon: <AddIcon />,
        handleAction: this.openCriarItemMenu
      }
    )

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <PanelPage {...others} title="Serviços" toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent chips={this.getChipsFiltrosAvancados()} type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 16 }}>
              <InputSelect
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.categoria.id}
                style={{ maxWidth: 200 }}
                fullWidth={false}
                displayEmpty
                onChange={(event) => {
                  this.setState({ categoria: { id: event.target.value } })
                }}
              >
                <MenuItem value="">{this.state.paginaCarregada ? 'Todas as categorias' : 'Carregando...'}</MenuItem>
                {this.state.categorias.map((categoria) => (
                  <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item xs style={{ marginRight: 16, minWidth: 250 }}>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    nakedLeftPadding={false}
                    placeholder="Procurar por serviço..."
                    onChange={(event) => {
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>

        <br />

        <VirtualizedResponsiveTable
          windowScroller={true}
          showBackgroundDividers={true}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/servicos.buscar"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              if (this.state.paginaCarregada === false) {
                state.paginaCarregada = true
                state.categorias = ArrayUtil.removerElementosDuplicados(data.items, 'id')
              }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.setState({
              menuAnchorPosition: {
                left: event.clientX,
                top: event.clientY
              },
              itemMenu: item
            })
          }}
          largeRenderProps={{
            columns: [
              { className: classes.colunaCheckbox, horizontalPadding: 'small' },
              { className: classes.colunaCor, horizontalPadding: 'small' },
              { label: 'Categoria', horizontalPadding: 'small', props: { xs: true } },
              { className: classes.colunaServico, label: 'Serviço', horizontalPadding: 'small', props: { xs: true } },
              { className: classes.colunaDuracao, align: 'right', label: 'Duração', props: { xs: true } },
              { className: classes.colunaPreco, align: 'right', label: 'Preço (R$)', props: { xs: true } }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item, index)

              return {
                selected: item._checked,
                itemData: [content.checkbox, content.indicadorCor, content.nomeCategoria, content.nomeServico, content.duracao, content.preco]
              }
            }
          }}
          mediumRenderProps={{
            headerColumnHeight: 62,
            rowHeight: 70,
            columns: [
              { className: classes.colunaCheckbox, horizontalPadding: 'small' },
              { className: classes.colunaCor, horizontalPadding: 'small' },
              { label: 'Serviço', horizontalPadding: 'small', props: { xs: true } },
              { className: classes.colunaDuracaoMedium, align: 'right', label: 'Duração', props: { xs: true } },
              { className: classes.colunaPreco, align: 'right', label: 'Preço (R$)', props: { xs: true } }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item, index)

              return {
                selected: item._checked,
                itemData: [
                  content.checkbox,
                  content.indicadorCor,
                  <React.Fragment>
                    <Typography variant="body2" noWrap={true} style={{ fontSize: 13 }}>
                      {content.nomeCategoria}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.nomeServico}
                    </Typography>
                  </React.Fragment>,
                  content.duracao,
                  content.preco
                ]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 90,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item, index)

              return {
                selected: item._checked,
                itemData: (
                  <Grid container alignItems="center" className={classes.backgroundColorInherit}>
                    <Grid item style={{ marginRight: 8 }}>
                      {content.checkbox}
                    </Grid>
                    <Grid item xs style={{ minWidth: 0 }} className={classes.backgroundColorInherit}>
                      <Typography variant="body2" noWrap={true} style={{ fontSize: 13 }}>
                        {content.nomeCategoria}
                      </Typography>
                      <Typography variant="body2" className={classes.nomeServicoContainerSmall}>
                        {content.nomeServico}
                      </Typography>
                      {content.duracaoEPreco}
                    </Grid>
                    <Grid item style={{ marginLeft: 4 }}>
                      {content.indicadorCor}
                    </Grid>
                  </Grid>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum serviço foi encontrado',
            textButton: 'Cadastrar Serviço',
            creationFunction: this.abrirServicoDialog
          }}
        />

        <Menu anchorEl={criarItemMenuAnchorEl} open={Boolean(criarItemMenuAnchorEl)} onClose={() => this.closeMenu('criarItemMenuAnchorEl')}>
          <MenuItem
            onClick={() => {
              this.abrirCategoriaServicoDialog()
              this.closeMenu('criarItemMenuAnchorEl')
            }}
          >
            Categoria
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.abrirServicoDialog()
              this.closeMenu('criarItemMenuAnchorEl')
            }}
          >
            Serviço
          </MenuItem>
        </Menu>

        <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={() => this.closeMenu('menuAnchorPosition')}>
          {this.state.itemMenu &&
            this.state.itemMenu.servicos &&
            this.state.itemMenu.servicos.length > 0 && [
              <MenuItem
                key={'editarServico'}
                onClick={() => {
                  this.handlerEditar('servico')
                  this.closeMenu('menuAnchorPosition')
                }}
              >
                Editar serviço
              </MenuItem>,
              <MenuItem
                key={'removerServico'}
                onClick={() => {
                  this.handlerRemover('servico')
                  this.closeMenu('menuAnchorPosition')
                }}
              >
                Remover serviço
              </MenuItem>
            ]}
          {(!this.state.itemMenu || !this.state.itemMenu.servicos || this.state.itemMenu.servicos.length === 0) && (
            <MenuItem
              onClick={() => {
                this.abrirServicoDialog({ categoria: this.state.itemMenu })
                this.closeMenu('menuAnchorPosition')
              }}
            >
              Adicionar serviço
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              this.handlerEditar('categoria')
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Editar categoria
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handlerRemover('categoria')
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Remover categoria
          </MenuItem>
        </Menu>

        <SelectedItemsActionSnackBar
          show={quantidadeItensSelecionadosRemocao > 0}
          label={'Selecionados: ' + quantidadeItensSelecionadosRemocao}
          action={{
            label: 'Remover',
            handleFunction: this.removerItensSelecionados,
            cancelFunction: this.desabilitarRemocaoLote
          }}
        />

        {this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            <Typography variant="body2">
              Serviços cadastrados: <b>{this.state.lista.length}</b>
              <br />
            </Typography>
          </FloatContent>
        )}
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ServicosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServicosPanelPage)
