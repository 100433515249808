import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    '& > button': {
      maxWidth: '200px',
      alignSelf: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        width: '100%'
      }
    }
  },
  wrapperInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  wrapperCheckbox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '20px'
  }
}))
