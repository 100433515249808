import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { calcularVariacao, getPercentualRestanteMes, ValorComVariacao } from 'support/domain/admin/relatorio/RelatorioService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaPeriodo: {
    maxWidth: 100,
    minWidth: 100
  }
})

class IndicadoresFinanceirosGeralDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.periodo = converterDataIntParaMoment(item.periodo).format('MMM/YYYY')

    content.despesas = (
      <span>
        <b>{formatarValorMonetario(item.despesas)}</b>
      </span>
    )
    if (item.despesasDifQtd) {
      let sinal = ''
      if (item.despesasDifQtd > 0) {
        sinal = '+'
      }
      content.despesas = (
        <span>
          {content.despesas}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.despesasDifQtd}>
            <small>
              ({sinal + item.despesasDifQtd.toFixed(0)}) {sinal + item.despesasDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }

    content.valorCobrado = (
      <span>
        <b>{formatarValorMonetario(item.valorCobrado)}</b>
      </span>
    )
    if (item.valorCobradoDifQtd) {
      let sinal = ''
      if (item.valorCobradoDifQtd > 0) {
        sinal = '+'
      }
      content.valorCobrado = (
        <span>
          {content.valorCobrado}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.valorCobradoDifQtd}>
            <small>
              ({sinal + item.valorCobradoDifQtd.toFixed(0)}) {sinal + item.valorCobradoDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }
    content.valorRecebido = (
      <span>
        <b>{formatarValorMonetario(item.valorRecebido)}</b>
      </span>
    )
    if (item.valorRecebidoDifQtd) {
      let sinal = ''
      if (item.valorRecebidoDifQtd > 0) {
        sinal = '+'
      }
      content.valorRecebido = (
        <span>
          {content.valorRecebido}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.valorRecebidoDifQtd}>
            <small>
              ({sinal + item.valorRecebidoDifQtd.toFixed(0)}) {sinal + item.valorRecebidoDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }

    content.lucro = (
      <span>
        <b>{formatarValorMonetario(item.lucro)}</b>
      </span>
    )
    if (item.lucroDifQtd) {
      let sinal = ''
      if (item.lucroDifQtd > 0) {
        sinal = '+'
      }
      content.lucro = (
        <span>
          {content.lucro}{' '}
          <ValorComVariacao valorReferenciaVariacao={item.lucroDifQtd}>
            <small>
              ({sinal + item.lucroDifQtd.toFixed(0)}) {sinal + item.lucroDifPer}
            </small>
          </ValorComVariacao>
        </span>
      )
    }

    return content
  }

  prepararListas = (items) => {
    for (let i = 0; i < items.length; i++) {
      items[i].lucro = items[i].valorRecebido - items[i].despesas
    }

    for (let i = 0; i < items.length - 1; i++) {
      const item = items[i]
      const itemAnterior = items[i + 1]
      item.valorCobradoDifQtd = item.valorCobrado - itemAnterior.valorCobrado
      item.valorCobradoDifPer = calcularVariacao(item.valorCobrado, itemAnterior.valorCobrado)
      item.valorRecebidoDifQtd = item.valorRecebido - itemAnterior.valorRecebido
      item.valorRecebidoDifPer = calcularVariacao(item.valorRecebido, itemAnterior.valorRecebido)
      item.despesasDifQtd = item.despesas - itemAnterior.despesas
      item.despesasDifPer = calcularVariacao(item.despesas, itemAnterior.despesas)
      item.lucroDifQtd = item.lucro - itemAnterior.lucro
      item.lucroDifPer = calcularVariacao(item.lucro, itemAnterior.lucro)
    }
    return items
  }

  render() {
    const dialogProps = extractDialogProps(this.props)

    return (
      <DialogPage
        {...dialogProps}
        title="Financeiro"
        toolbarActions={{
          actions: [
            {
              label: getPercentualRestanteMes().toFixed(0).toString() + '%',
              disabled: true
            },
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.loaderFunctionsMap.load()
              }
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <br />
            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="admin"
              endpoint="admin/relatorios.buscarIndicadoresFinanceirosGeral"
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const state = { lista: this.prepararListas(data.items) }
                  this.setState(state)
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Período', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Valor Cobrado', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Valor Recebido', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Despesas', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Lucro', horizontalPadding: 'small', props: { xs: true } }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.periodo, content.valorCobrado, content.valorRecebido, content.despesas, content.lucro]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 150,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body1" noWrap={true}>
                          {content.periodo}
                        </Typography>
                        <Typography variant="body2">Valor Cobrado : {content.valorCobrado}</Typography>
                        <Typography variant="body2">Valor Recebido : {content.valorRecebido}</Typography>
                        <Typography variant="body2">Despesas : {content.despesas}</Typography>
                        <Typography variant="body2">Lucro : {content.lucro}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum registro encontrado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

IndicadoresFinanceirosGeralDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IndicadoresFinanceirosGeralDialogPage)
