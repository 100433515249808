import React, { Component } from 'react'

import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import FormHelperText from 'support/components/input/core/FormHelperText'
import Input from 'support/components/input/core/Input'
import InputLabel from 'support/components/input/core/InputLabel'
import PassiveDynamicContent from 'support/components/passivedynamiccontent/PassiveDynamicContent'
import toTS from 'support/components/toTS'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class InputText extends Component {
  constructor(props) {
    super(props)
    this.superHelpMessageFunctionMap = {}
    /*this.inputRef = {};
		if(this.props.inputRef){
			this.inputRef = this.props.inputRef;
		}else if(this.props.inputProps && this.props.inputProps.ref){
			this.inputRef = this.props.inputProps.ref;
		}else if(this.props.InputProps && this.props.InputProps.inputProps && this.props.InputProps.inputProps.ref){
			this.inputRef = this.props.InputProps.inputProps.ref;
		}*/
  }

  componentDidMount = () => {
    const { functionsMap } = this.props
    if (functionsMap) {
      functionsMap['updateSuperHelpMessage'] = () => {
        this.superHelpMessageFunctionMap.update()
      }
    }
  }

  removeUnrecognizedInputProps = (props) => {
    delete props.functionsMap
    delete props.superHelpMessage
    delete props.marginTop
    delete props.backgroundColor
  }

  render() {
    const { classes, idname, staticHelpMessage, superHelpMessage, ...othersInputProps } = this.props
    delete othersInputProps.children
    delete othersInputProps.style

    if (idname) {
      othersInputProps.id = idname
      othersInputProps.name = idname
    }

    const { fullWidth } = this.props
    let { errorMessage, helpMessage, error } = this.props
    let style = Object.assign({}, this.props.style)

    let variant = undefined
    if (this.props.customVariant !== 'filled' && this.props.customVariant !== 'naked') {
      if (this.props.customVariant === 'outlined-small') {
        variant = 'outlined'
      } else {
        variant = this.props.customVariant
      }
    }

    if (errorMessage !== undefined && errorMessage !== null) {
      if (errorMessage.trim().length > 0) {
        helpMessage = errorMessage
      }
    }

    if (!style) {
      style = {}
    }

    if (this.props.customVariant === 'outlined' || this.props.customVariant === 'outlined-small') {
      if (this.props.backgroundColor) {
        style.backgroundColor = this.props.backgroundColor
      } else {
        style.backgroundColor = '#fff'
      }
    }

    if (style.marginTop === undefined && this.props.marginTop) {
      if (this.props.marginTop === true) {
        style.marginTop = 20
      } else {
        style.marginTop = this.props.marginTop
      }
    }

    this.removeUnrecognizedInputProps(othersInputProps)

    return (
      <FormControl
        className={this.props.className}
        variant={variant}
        disabled={this.props.disabled}
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        style={style}
        error={FormUtil.errorFlag(errorMessage) || error}
      >
        <InputLabel
          label={this.props.label}
          shrink={this.props.shrink || this.props.shrink === false ? this.props.shrink : true}
          LabelProps={this.props.LabelProps}
          customVariant={this.props.customVariant}
          focusEffectDisabled={this.props.focusEffectDisabled}
          htmlFor={othersInputProps.id}
        />

        <Input {...othersInputProps} />

        {helpMessage !== undefined && helpMessage !== null && helpMessage !== '' && (
          <FormHelperText customVariant={this.props.customVariant} nakedLeftPadding={this.props.nakedLeftPadding}>
            {helpMessage}
          </FormHelperText>
        )}

        {staticHelpMessage !== undefined && staticHelpMessage !== null && staticHelpMessage !== '' && (
          <FormHelperText customVariant={this.props.customVariant} nakedLeftPadding={this.props.nakedLeftPadding} error={false}>
            {staticHelpMessage}
          </FormHelperText>
        )}

        {superHelpMessage && (
          <PassiveDynamicContent
            updateFunction={() => {
              const result = superHelpMessage()
              const content = result.content

              if (content) {
                result.content = (
                  <FormHelperText error={false} customVariant={this.props.customVariant} nakedLeftPadding={this.props.nakedLeftPadding}>
                    {content}
                  </FormHelperText>
                )
              }

              return result
            }}
            functionsMap={this.superHelpMessageFunctionMap}
          />
        )}
      </FormControl>
    )
  }
}

InputText.propTypes = {
  classes: PropTypes.object.isRequired
}

export default toTS(withStyles(styles)(InputText))
