import DeviceIdentifier from 'support/components/device/DeviceIdentifier'

function podeMostrarFluxoPagamento() {
  if (DeviceIdentifier.isNativeIOS()) {
    return false
  }
  return true
}

function gerarUrlDeRedirecionamentoParaPlanos(usaPagamentoRecorrente) {
  if (usaPagamentoRecorrente) {
    return `${process.env.REACT_APP_MFE_ADDRESS}/planos/assinatura`
  }
  return `${process.env.REACT_APP_MFE_ADDRESS}/planos`
}

const functionsObject = {
  podeMostrarFluxoPagamento,
  gerarUrlDeRedirecionamentoParaPlanos
}

export default functionsObject
