import React, { Component } from 'react'

//import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
//import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox';
import { StatusFiltroEnum, StatusVendaFiltroEnum } from 'pages/erp/painel/atendimento/AtendimentosListaPanelPageContent'
import ClienteInputDialogSelect from 'pages/erp/painel/cliente/input/ClienteInputDialogSelect'
import ColaboradorInputDialogSelect from 'pages/erp/painel/colaborador/input/ColaboradorInputDialogSelect'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class AtendimentosListaPesquisaAvancadaDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      status: this.props.status,
      statusVenda: this.props.statusVenda,
      colaborador: this.props.colaborador,
      cliente: this.props.cliente,
      dataInicial: this.props.dataInicial,
      dataFinal: this.props.dataFinal
    }

    this.autocompleteColaborador = {}
    this.autocompleteCliente = {}
    this.dataInicialFunctionsMap = {}
    this.dataFinalFunctionsMap = {}
    this.statusFunctionMap = {}
    this.statusVendaFunctionMap = {}
    this.apenasPossuiConflitoHorarioFunctionsMap = {}
  }

  limparFiltro = () => {
    this.props.setParentState({
      status: StatusFiltroEnum.TODOS,
      statusVenda: StatusVendaFiltroEnum.TODOS,
      colaborador: { id: null },
      cliente: { id: null },
      dataInicial: null,
      dataFinal: null
    })
    this.props.handleCloseDialog()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.aplicarFiltro()
  }

  aplicarFiltro = () => {
    this.setState({ errorsMap: FormUtil.createErrorsMap() })

    const errorsMap = {}
    const colaborador = this.autocompleteColaborador.getObject() ? this.autocompleteColaborador.getObject() : {}
    const cliente = this.autocompleteCliente.getObject() ? this.autocompleteCliente.getObject() : {}
    const dataInicial = this.dataInicialFunctionsMap.getDataAsDate()
    const dataFinal = this.dataFinalFunctionsMap.getDataAsDate()
    //const apenasPossuiConflitoHorario = this.apenasPossuiConflitoHorarioFunctionsMap.getValue();
    const apenasPossuiConflitoHorario = null

    if (dataInicial !== null && dataFinal !== null && dataInicial > dataFinal) {
      errorsMap['dataFinal'] = 'A data(até) não pode ser menor que a data(de)'
    }

    if (Object.keys(errorsMap).length > 0) {
      this.setState({ errorsMap: FormUtil.createErrorsMap(errorsMap) })
      FormUtil.focusFirstElementWithError('formAtendimentosPesquisaAvancada')
    } else {
      this.props.setParentState({
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        colaborador: { id: colaborador.value, nome: colaborador.label },
        cliente: { id: cliente.value, nome: cliente.label },
        status: {
          id: parseInt(this.statusFunctionMap.getValue(), 10)
        },
        statusVenda: {
          id: parseInt(this.statusVendaFunctionMap.getValue(), 10)
        },
        apenasPossuiConflitoHorario: apenasPossuiConflitoHorario
      })
      this.props.handleCloseDialog()
    }
  }

  render() {
    const { status, statusVenda } = this.props
    const { errorsMap } = this.state

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogTitle>Pesquisa Avançada</DialogTitle>
        <DialogContent>
          <form id="formAtendimentosPesquisaAvancada" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            {/*

							<UncontrolledCheckbox
								formControlProps={{
									style : {marginBottom: 14},
									label : <Typography variant="body2">Exibir apenas com Conflitos de Horários</Typography>
								}}
								color="primary"
								defaultChecked={this.props.apenasPossuiConflitoHorario}
								functionsMap={this.apenasPossuiConflitoHorarioFunctionsMap}
							/>

						*/}

            <InputHorizontalContainer
              inputTexts={[
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputData
                      id="xdatainicial"
                      name="xdatainicial"
                      label="Data (de)"
                      keyboard={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.dataInicialFunctionsMap}
                      defaultValue={this.state.dataInicial}
                      errorMessage={errorsMap('dataInicial')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12, sm: 6 },
                  input: (
                    <InputData
                      id="xdatafinal"
                      name="xdatafinal"
                      label="Data (até)"
                      keyboard={true}
                      customVariant="outlined-small"
                      shrink={false}
                      functionsMap={this.dataFinalFunctionsMap}
                      defaultValue={this.state.dataFinal}
                      errorMessage={errorsMap('dataFinal')}
                    />
                  )
                },
                {
                  containerProps: { xs: 12 },
                  input: (
                    <ColaboradorInputDialogSelect
                      disabled={this.state.ajaxing}
                      defaultValue={this.state.colaborador && this.state.colaborador.id ? this.state.colaborador : undefined}
                      functionsMap={this.autocompleteColaborador}
                      errorMessage={errorsMap('colaborador')}
                      customVariant="outlined-small"
                      shrink={false}
                    />
                  )
                },
                {
                  containerProps: { xs: 12 },
                  input: (
                    <ClienteInputDialogSelect
                      key={'cliente'}
                      disabled={this.state.ajaxing}
                      defaultValue={this.state.cliente && this.state.cliente.id ? this.state.cliente : undefined}
                      functionsMap={this.autocompleteCliente}
                      errorMessage={errorsMap('cliente')}
                      customVariant="outlined-small"
                      shrink={false}
                    />
                  )
                },
                {
                  containerProps: { xs: 12 },
                  input: (
                    <InputSelect
                      label="Andamento"
                      disabled={this.state.ajaxing}
                      customVariant="outlined-small"
                      shrink={false}
                      defaultValue={status.id}
                      functionsMap={this.statusFunctionMap}
                    >
                      {StatusFiltroEnum.values().map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  )
                },
                {
                  containerProps: { xs: 12 },
                  input: (
                    <InputSelect
                      label="Situação"
                      disabled={this.state.ajaxing}
                      customVariant="outlined-small"
                      shrink={false}
                      defaultValue={statusVenda.id}
                      functionsMap={this.statusVendaFunctionMap}
                    >
                      {StatusVendaFiltroEnum.values().map((statusVenda) => (
                        <MenuItem key={statusVenda.id} value={statusVenda.id}>
                          {statusVenda.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  )
                }
              ]}
            />

            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Voltar
          </Button>
          <Button color="secondary" onClick={this.limparFiltro}>
            LIMPAR
          </Button>
          <Button color="secondary" onClick={this.aplicarFiltro}>
            PESQUISAR
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

AtendimentosListaPesquisaAvancadaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtendimentosListaPesquisaAvancadaDialog)
