import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import mapMarkerImg from 'img/map-marker.png'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputText from 'support/components/input/InputText'
import PlacePickerDialog from 'support/components/placepicker/PlacePickerDialog'

const styles = (theme) => ({})

class InputPlace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      place: this.props.defaultValue ? this.props.defaultValue : null
    }
    this.contadorAtualizacaoEndereco = 1
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getPlace = () => {
        return this.state.place
      }
      this.props.functionsMap.select = (callback) => {
        openBackableDialog(PlacePickerDialog, {
          loadUserHistoryPlaces: this.props.loadUserHistoryPlaces,
          onSelect: (place) => {
            this.setValue(place)
            if (callback) {
              callback(place)
            }
          }
        })
      }
    }
  }

  setValue = (place) => {
    this.contadorAtualizacaoEndereco++
    if (this.props.onChange) {
      this.props.onChange(place)
    }
    this.setState({ place: place })
  }

  render() {
    const { classes, defaultValue, onlyPreciseAddress, dialogTitle, loadUserHistoryPlaces, showBackButton, showGetCurrentLocationButton, mapProps, ...others } = this.props

    if (!others.readOnly && !others.disabled) {
      others.onClick = () => {
        openBackableDialog(PlacePickerDialog, {
          onlyPreciseAddress: onlyPreciseAddress,
          dialogTitle: dialogTitle,
          loadUserHistoryPlaces: loadUserHistoryPlaces,
          showBackButton: showBackButton,
          showGetCurrentLocationButton: showGetCurrentLocationButton,
          onSelect: (place) => {
            this.setValue(place)
          }
        })
      }
    }

    if (onlyPreciseAddress) {
      others.multiline = true
      others.rows = 1
      others.rowsMax = 10
    }

    /*if(this.state.place){
			others.value = this.state.place.description;
		}else{
			others.value = "";
		}*/

    return (
      <React.Fragment>
        <InputText {...others} readOnly={true} />
        {mapProps && mapProps.show && mapProps.lat && mapProps.lng && (
          <div style={{ marginTop: 16 }}>
            <Map lat={mapProps.lat} lng={mapProps.lng} key={this.contadorAtualizacaoEndereco} />
          </div>
        )}
      </React.Fragment>
    )
  }
}

let ID_MAP_PREFIX = 's99GoogleMapContainerAddress'
let ID_MAP_GENERATOR = 0

class Map extends Component {
  constructor(props) {
    super(props)
    this.mapId = ID_MAP_PREFIX + '-' + ID_MAP_GENERATOR++
  }

  componentDidMount() {
    const coordinates = { lat: this.props.lat, lng: this.props.lng }
    const map = new window.google.maps.Map(document.getElementById(this.mapId), {
      clickableIcons: false,
      scaleControl: false,
      streetViewControl: false,
      panControl: false,
      controlSize: 22,
      zoomControl: true,
      gestureHandling: 'cooperative', //"greedy",
      center: coordinates,
      zoom: 17
    })
    new window.google.maps.Marker({
      map,
      position: coordinates,
      icon: {
        url: mapMarkerImg,
        scaledSize: new window.google.maps.Size(38, 38)
      }
    })
  }

  render() {
    return <div id={this.mapId} style={{ height: 250 }}></div>
  }
}

InputPlace.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputPlace)
