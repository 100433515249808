import React, { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'

const styles = (theme) => ({
  messageRoot: {
    marginBottom: theme.spacing(2)
  }
})

class LoadingDialog extends Component {
  render() {
    const { classes, message, ...others } = this.props

    return (
      <MessageDialog
        {...others}
        disabledOnClose={true}
        text={
          <Grid container alignItems="center" justify="center" className={classes.messageRoot}>
            <Grid item>
              <CircularProgress size={18} thickness={5.5} style={{ marginRight: 12, display: 'block' }} />
            </Grid>
            <Grid item>{message ? message : 'Carregando...'}</Grid>
          </Grid>
        }
        hasButtons={false}
      />
    )
  }
}

LoadingDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LoadingDialog)
