import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  rootNormal: {
    //overflow:"hidden",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  rootNoPadding: {
    padding: 0
  }
})

class TabContainer extends Component {
  render() {
    const { classes, padding, children, className, style } = this.props

    const rootClasses = [classes.rootNormal, classes.rootHorizontalMargin]

    if (padding === false) {
      rootClasses.push(classes.rootNoPadding)
    }
    if (className) {
      rootClasses.push(className)
    }

    return (
      <div style={style} className={classNames(rootClasses)}>
        {children}
      </div>
    )
  }
}

TabContainer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabContainer)
