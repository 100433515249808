import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import toTS from '../toTS'

const styles = () => ({
  root: {
    wwillChange: 'ttransform'
  },
  container: {
    width: '100%',
    position: 'absolute'
  },
  paper: {}, //para possiblitar override na property "classes"
  paperNoFullScreen: {
    margin: 4,
    maxHeight: 'calc(100% - 8px)'
  }
})

class CustomDialog extends Component {
  handleCloseDialog = () => {
    if (!this.props.disabledOnClose && !this.props.dialogProps.disabledOnClose) {
      this.props.dialogProps.handleCloseDialog()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.dialogProps.open === true && nextProps.dialogProps.open === false) {
      if (this.props.dialogProps.onPreClose) {
        this.props.dialogProps.onPreClose()
      }
    }
    return true
  }

  render() {
    const { classes } = this.props

    const classesPaper = [classes.paper]
    if (!this.props.fullScreen) {
      classesPaper.push(classes.paperNoFullScreen)
    }

    return (
      <Dialog
        onEnter={this.props.onEnter}
        style={this.props.style}
        className={this.props.className}
        ref={this.props.dialogRef}
        key={this.props.dialogProps.id}
        classes={{ root: classNames(classes.root, 'needsWillChange'), container: classes.container, paper: classNames(classesPaper) }}
        PaperProps={{
          ref: this.props.paperRef,
          style: this.props.paperStyle
        }}
        scroll="paper"
        open={this.props.dialogProps.open}
        onClose={this.handleCloseDialog}
        fullScreen={this.props.fullScreen}
        TransitionComponent={this.props.Transition}
        disablePortal={this.props.disablePortal !== undefined ? this.props.disablePortal : true}
      >
        {this.props.dialogProps.open && this.props.children}
      </Dialog>
    )
  }
}

CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default toTS(withStyles(styles)(CustomDialog))
