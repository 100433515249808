import React, { Component } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { TOOLBAR_EXPANDED_CLASS_NAME } from 'support/components/panel/Panel'
import PanelPageBody from 'support/components/panel/PanelPageBody'
import ToolbarActions from 'support/components/toolbar/ToolbarActions'
import { converteHexStrToRgbStr } from 'support/util/ColorUtil'
import { shouldShowTrainingBanner } from 'support/util/TrainingBannerEligibility'

import Banner from '../banner/Banner'

const styles = (theme) => ({
  appBarBadge: {
    color: theme.palette.badge.primaryContrast.text,
    backgroundColor: theme.palette.badge.primaryContrast.background,
    animation: '$pulse 1.5s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      ...theme.util.createBoxShadow('0 0 0 0 ' + converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 1))
    },
    '85%': {
      ...theme.util.createBoxShadow('0 0 0 14px ' + converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 0))
    },
    '100%': {
      ...theme.util.createBoxShadow('0 0 0 0 ' + converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 0))
    }
  },
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 3,
    [theme.breakpoints.up('md')]: {
      xzIndex: theme.zIndex.drawer + 4,
      marginLeft: theme.drawer.retractedWidth,
      width: `calc(100% - ${theme.drawer.retractedWidth}px)`
    }
  },
  appBarClear: {
    zIndex: theme.zIndex.drawer + 3,
    [theme.breakpoints.up('md')]: {
      xzIndex: theme.zIndex.drawer + 4,
      marginLeft: theme.drawer.retractedWidth,
      width: `calc(100% - ${theme.drawer.retractedWidth}px)`
    }
  },
  appBarShift: {
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 3,
      marginLeft: theme.drawer.expandedWidth,
      width: `calc(100% - ${theme.drawer.expandedWidth}px)`
    }
  },

  appBarBoxShadow: {
    [theme.breakpoints.up('xs')]: {
      ...theme.util.createBoxShadow('none')
    }
  },

  appBarRetracted: {
    maxHeight: 0,
    boxShadow: 'none',
    WebkitBoxShadow: 'none',
    MozBoxShadow: 'none',
    msBoxShadow: 'none'
  },

  appBarFullWidth: {
    width: '100%',
    marginLeft: '0px !important',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: '0px !important'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px !important'
    }
  },

  appBarWithBanner: {
    '@media (min-width: 600px)': {
      marginTop: theme.banner.height
    }
  },

  toolbarRoot: {
    ...theme.container.basic({ fullWidth: true })
  },
  toolbarTitle: {
    flexGrow: 1
  },
  menuButtonDrawerClosed: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHide: {
    marginRight: '8px',
    marginLeft: -16,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  menuButtonMobileHide: {
    marginRight: '8px',
    marginLeft: -12,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.drawer.retractedWidth
    },
    [theme.breakpoints.down('xs')]: {}
  },
  contentShift: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.drawer.expandedWidth
    }
  },
  contentFullScreenMode: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,

    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.drawer.retractedWidth,
      width: 'calc(100% - ' + theme.drawer.retractedWidth + 'px)'
    },

    marginTop: theme.mixins.toolbar.minHeight,
    height: 'calc(100% - ' + theme.mixins.toolbar.minHeight + 'px)',
    '@media (min-width: 0px) and (orientation: landscape)': {
      marginTop: theme.mixins.toolbar['@media (min-width: 0px) and (orientation: landscape)'].minHeight,
      height: 'calc(100% - ' + theme.mixins.toolbar['@media (min-width: 0px) and (orientation: landscape)'].minHeight + 'px)'
    },
    '@media (min-width: 600px)': {
      marginTop: theme.mixins.toolbar['@media (min-width: 600px)'].minHeight,
      height: 'calc(100% - ' + theme.mixins.toolbar['@media (min-width: 600px)'].minHeight + 'px)'
    }
  },
  contentShiftFullScreenMode: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,

    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.drawer.expandedWidth,
      width: 'calc(100% - ' + theme.drawer.expandedWidth + 'px)'
    },

    marginTop: theme.mixins.toolbar.minHeight,
    height: 'calc(100% - ' + theme.mixins.toolbar.minHeight + 'px)',
    '@media (min-width: 0px) and (orientation: landscape)': {
      marginTop: theme.mixins.toolbar['@media (min-width: 0px) and (orientation: landscape)'].minHeight,
      height: 'calc(100% - ' + theme.mixins.toolbar['@media (min-width: 0px) and (orientation: landscape)'].minHeight + 'px)'
    },
    '@media (min-width: 600px)': {
      marginTop: theme.mixins.toolbar['@media (min-width: 600px)'].minHeight,
      height: 'calc(100% - ' + theme.mixins.toolbar['@media (min-width: 600px)'].minHeight + 'px)'
    }
  },
  contentWithBanner: {
    '@media (min-width: 0px) and (orientation: landscape)': {
      marginTop: theme.mixins.toolbar['@media (min-width: 0px) and (orientation: landscape)'].minHeight + theme.banner.height
    },
    '@media (min-width: 600px)': {
      marginTop: theme.mixins.toolbar['@media (min-width: 600px)'].minHeight + theme.banner.height
    }
  },
  innerContentWithBanner: {
    '@media (min-width: 0px) and (orientation: landscape)': {
      marginTop: theme.banner.height
    },
    '@media (min-width: 600px)': {
      marginTop: theme.banner.height
    }
  },
  contentWithToolbarRetracted: {
    height: 'calc(100% + 1px)',
    marginTop: -1
  },
  contentFullWidth: {
    marginLeft: '0px !important',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px !important'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px !important'
    }
  },
  innerContent: {
    ...theme.container.basic({ paddingTop: 0, extraPaddingBottom: true })
  },
  innerContentFullScreen: {
    padding: 3,
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  }
})

class PanelPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBannerVisible: false
    }
  }

  componentDidMount() {
    const { negocioSessao, usuarioSessao, colaboradorSessao } = this.props

    this.setState({
      isBannerVisible: shouldShowTrainingBanner(negocioSessao?.plano?.id, usuarioSessao?.dataVerificacao, colaboradorSessao?.id)
    })
  }

  render() {
    const { variant, classes, negocioSessao, usuarioSessao, colaboradorSessao } = this.props

    let toolbarExpanded = this.props.toolbarExpanded
    if (!this.props.toolbarRetractable) {
      toolbarExpanded = true
    }

    let pageTitle = this.props.title
    if (pageTitle === undefined || pageTitle === null || pageTitle.length === 0) {
      pageTitle = 'Carregando...'
    }

    if (typeof pageTitle === 'string' || (pageTitle && pageTitle.type && pageTitle.type === 'span')) {
      pageTitle = (
        <>
          {variant === 'clear' ? null : (
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              {pageTitle}
            </Typography>
          )}
        </>
      )
    } else {
      pageTitle = <div style={{ flexGrow: 1 }}>{pageTitle}</div>
    }

    const handleBannerDismiss = () => {
      this.setState({
        isBannerVisible: shouldShowTrainingBanner(negocioSessao?.plano?.id, usuarioSessao?.dataVerificacao, colaboradorSessao?.id)
      })
    }

    const toolbarStyle = {}

    if (this.props.toolbarPaddingTop) {
      toolbarStyle.paddingTop = this.props.toolbarPaddingTop
    }

    let mainContent = null
    if (this.props.paginaCarregada) {
      const contentClasses = [classes.content, 'height100']
      if (this.props.desktopDrawerOpen) {
        contentClasses.push(classes.contentShift)
      }
      if (this.props.hasMenu === false) {
        contentClasses.push(classes.contentFullWidth)
      }
      if (this.props.toolbarExpanded) {
        contentClasses.push(TOOLBAR_EXPANDED_CLASS_NAME)
      }

      let contentStyle = {
        maxWidth: this.props.contentMaxWidth
      }

      if (this.props.align === 'center') {
        toolbarStyle.maxWidth = this.props.contentMaxWidth
        toolbarStyle.marginLeft = 'auto'
        toolbarStyle.marginRight = 'auto'
        contentStyle.marginLeft = 'auto'
        contentStyle.marginRight = 'auto'
      }

      if (this.props.panelPageFullScreenMode) {
        contentStyle.height = '100%'
      }

      mainContent = (
        <main key="main" className={classNames(contentClasses)}>
          {toolbarExpanded && <div className={classes.toolbar} />}
          <div
            className={classNames(
              this.props.panelPageFullScreenMode ? (this.props.desktopDrawerOpen ? classes.contentShiftFullScreenMode : classes.contentFullScreenMode) : classes.innerContent,
              !toolbarExpanded ? classes.contentWithToolbarRetracted : null,
              this.state.isBannerVisible ? (this.props.panelPageFullScreenMode ? classes.contentWithBanner : classes.innerContentWithBanner) : null
            )}
          >
            <PanelPageBody open={this.props.desktopDrawerOpen} mobileDrawerOpen={this.props.mobileDrawerOpen} toolbarExpanded={toolbarExpanded}>
              <div style={contentStyle}>{this.props.children}</div>
            </PanelPageBody>
          </div>
        </main>
      )
    }

    const appBarClasses = [classes.appBarBoxShadow, variant === 'clear' ? classes.appBarClear : classes.appBar]

    if (this.props.toolbarExpanded) {
      appBarClasses.push(TOOLBAR_EXPANDED_CLASS_NAME)
    }

    if (this.props.desktopDrawerOpen) {
      appBarClasses.push(classes.appBarShift)
    }

    if (!toolbarExpanded) {
      appBarClasses.push(classes.appBarRetracted)
    }

    if (this.props.panelPageFullScreenMode) {
      appBarClasses.push(classes.appBarFullScreenMode)
    }

    if (this.props.hasMenu === false) {
      appBarClasses.push(classes.appBarFullWidth)
    }

    if (this.state.isBannerVisible) {
      appBarClasses.push(classes.appBarWithBanner)
    }

    return [
      <React.Fragment key={`PanelPage-${this.props?.title}`}>
        {this.state.isBannerVisible && (
          <Banner
            title="Aproveite seu período de teste grátis com o nosso treinamento online"
            buttonText="Quero me inscrever"
            contributorId={colaboradorSessao.id}
            href={process.env.REACT_APP_GOOGLE_FORM_TRAINING}
            onHide={handleBannerDismiss}
          />
        )}

        <AppBar key="AppBar" position="absolute" className={classNames(appBarClasses, 'needsWillChange')}>
          <Toolbar className={classes.toolbarRoot} style={{ visibility: toolbarExpanded ? 'visible' : 'hidden', ...toolbarStyle }}>
            {variant !== 'clear' && this.props.hasMenu && (
              <IconButton color="inherit" aria-label="Open drawer" onClick={this.props.handleDrawerMobileToggle} className={classes.menuButtonMobileHide}>
                <Badge classes={{ badge: classes.appBarBadge }} badgeContent={this.props.menuBadgeCount} max={9} overlap="rectangular">
                  <MenuIcon />
                </Badge>
              </IconButton>
            )}
            {variant === 'clear' ? this.props.clearContent : pageTitle}
            <ToolbarActions toolbarActions={this.props.toolbarActions} />
          </Toolbar>
        </AppBar>
      </React.Fragment>,
      mainContent
    ]
  }
}

PanelPage.defaultProps = {
  hasMenu: true,
  variant: 'full'
}

PanelPage.propTypes = {
  classes: PropTypes.object.isRequired,
  usuarioSessao: PropTypes.object,
  negocioSessao: PropTypes.object,
  colaboradorSessao: PropTypes.object,
  getValue: PropTypes.func,
  panelPageFullScreenMode: PropTypes.bool,
  hasMenu: PropTypes.bool,
  variant: PropTypes.string,
  clearContent: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  toolbarActions: PropTypes.object,
  contentMaxWidth: PropTypes.number
}

export default withStyles(styles)(PanelPage)
