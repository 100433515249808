import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

const styles = (theme) => ({})

class NegocioInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    if (!props.label) props.label = 'Negocio'
    if (!props.id) props.id = 'xnegocio'
    if (!props.label) props.label = 'xnegocio'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Negócio...'
    props.DialogSelectProps.endpoint = 'admin/negocio.buscarNegocios'
    props.DialogSelectProps.searchParamName = 'filtro'
    props.DialogSelectProps.contextoUsuario = 'admin'
    props.DialogSelectProps.generateItemData = (negocio) => {
      return {
        value: negocio.id,
        label: negocio.nome,
        primary: negocio.nome,
        secondary:
          'Id: ' +
          negocio.id +
          ' - ' +
          (negocio.excluido ? 'Removido' : negocio.ativo ? 'Ativo até ' + converterDataIntParaMoment(negocio.dataSistemaAtivo).format('DD/MM/YYYY') : 'Inativo'),
        negocio: negocio
      }
    }

    props.DialogSelectProps.autoSelectDefinitons = [
      {
        eventName: 'ManipulacaoNegocio',
        itemPropName: 'negocio'
      }
    ]

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum negócio foi encontrado'
      }
    }

    return <InputDialogSelect {...props} />
  }
}

NegocioInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NegocioInputDialogSelect)
