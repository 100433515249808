import React from 'react'

import { Box, Button } from '@material-ui/core'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import { clearBackableComponents, extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import FormUtil from 'support/util/FormUtil'

import { useStyles } from './Cupons.style'
import { CuponsAddPageForm } from './CuponsAddPageForm'

const initialForm = {
  coupon: '',
  period: '',
  title: '',
  price: '',
  plan: '',
  dueDate: '',
  description: '',
  cycles: 1,
  active: false,
  isValidForFirstPayment: false
}

export const CuponsAddPage = ({ ...rest }) => {
  const classes = useStyles()
  const dialogProps = extractDialogProps(rest)
  const [loading, setLoading] = React.useState(false)
  const [form, setForm] = React.useState(initialForm)

  const handleChange = (key: string, value: string | boolean | number) => {
    setForm((prevVal) => ({ ...prevVal, [key]: value }))
  }

  const handleCreateCoupon = () => {
    setLoading(true)
    const data = { ...form, price: FormUtil.convertStringToNumber(form.price) }
    postAPI({
      url: 'erp/discountCoupon',
      data,
      requerAutorizacao: true,
      onSuccess: () => {
        EventsManager.pub('SnackBarSuccess', { open: true, message: 'Cupom criado com sucesso!' })
        setForm(initialForm)
        clearBackableComponents()
      }
    })
    setLoading(false)
  }

  const isValid = Object.values(form).every((formItem) => formItem.toString().length > 0 || formItem)

  return (
    <DialogPage {...dialogProps} {...rest} title="Criar" align="center" contentMaxWidth={845}>
      <Box className={classes.content}>
        <CuponsAddPageForm onChange={handleChange} form={form} />
        <Button data-testid="cupom-button" onClick={handleCreateCoupon} disabled={!isValid || loading} variant="contained" color="secondary">
          Criar cupom
        </Button>
      </Box>
    </DialogPage>
  )
}
