import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import EmissaoNfEnum from 'support/domain/venda/EmissaoNfEnum'
import OpcaoEmissaoNfFechamentoNotaEnum from 'support/domain/venda/OpcaoEmissaoNfFechamentoNotaEnum'

const styles = (theme) => ({
  textCheckbox: {
    color: theme.palette.text.secondary
  }
})

class FecharNotaDialog extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()

    let mostrarOpcaoEmissaoNf = false
    let emitirNfs = false
    if (
      getSessaoPainel().negocio.carregado &&
      getSessaoPainel().negocio.configuracaoNf &&
      EmissaoNfEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.emissaoNf.id &&
      verificarAutorizacao([PA.PODE_EMITIR_NOTAS_FISCAIS])
    ) {
      if (getSessaoPainel().negocio.configuracaoNf.emissaoAtiva === true) {
        if (getSessaoPainel().negocio.configuracaoNf.opcaoEmissaoNfFechamentoNota.id === OpcaoEmissaoNfFechamentoNotaEnum.EXIBIR_SELECIONADO.id) {
          mostrarOpcaoEmissaoNf = true
          emitirNfs = true
        } else if (getSessaoPainel().negocio.configuracaoNf.opcaoEmissaoNfFechamentoNota.id === OpcaoEmissaoNfFechamentoNotaEnum.EXIBIR_NAO_SELECIONADO.id) {
          mostrarOpcaoEmissaoNf = true
        }
      }
    }

    this.state = {
      possuiAtendimentosNaoConluidos: false,
      concluirTodosAtendimentos: false,
      mostrarOpcaoEmissaoNf: mostrarOpcaoEmissaoNf,
      emitirNfs: emitirNfs
    }
    this.inputConcluirAtendimentos = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/vendas.buscarDadosParaFechamentoNota',
      params: {
        idNota: this.props.idNota
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          possuiAtendimentosNaoConluidos: response.data.possuiAtendimentoNaoConcluidos,
          concluirTodosAtendimentos: response.data.possuiAtendimentoNaoConcluidos
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { possuiAtendimentosNaoConluidos, concluirTodosAtendimentos, emitirNfs } = this.state

    let bottomContent = null

    if (possuiAtendimentosNaoConluidos) {
      bottomContent = (
        <FormControlLabel
          style={{ marginTop: 12, marginBottom: 6 }}
          control={
            <Checkbox
              color="primary"
              disabled={false}
              checked={this.state.concluirTodosAtendimentos}
              onChange={(event) => {
                this.setState({ concluirTodosAtendimentos: event.target.checked })
              }}
            />
          }
          label={
            <Typography variant="body2" className={classes.textCheckbsox}>
              Alterar atendimentos para <b>Concluído</b>
            </Typography>
          }
        />
      )
    }

    if (this.state.mostrarOpcaoEmissaoNf) {
      bottomContent = (
        <FormControlLabel
          style={{ marginTop: 12, marginBottom: 6 }}
          control={
            <Checkbox
              color="primary"
              disabled={false}
              checked={emitirNfs}
              onChange={(event) => {
                this.setState({ emitirNfs: event.target.checked })
              }}
            />
          }
          label={
            <Typography variant="body2" className={classes.textCheckbsox}>
              Emitir notas fiscais
            </Typography>
          }
        />
      )
    }

    return (
      <EntityConfirmationDialog
        maxWidth={500}
        {...others}
        contentWithPreload={{
          loadFunction: this.carregarMain
        }}
        bottomContent={bottomContent}
        call={{
          method: 'post',
          url: 'erp/vendas.fecharNota',
          contextoUsuario: 'erp',
          data: {
            id: this.props.idNota,
            concluirAtendimentos: concluirTodosAtendimentos,
            emitirNfs: emitirNfs,
            //retornarVendas : this.props.retornarVendas?this.props.retornarVendas:null,
            retornarVendas: true
          },
          onSuccess: (response) => {
            EventsManager.pub('FechamentoNota')
            if (this.props.retornarVendas) {
              EventsManager.pub('ManipulacaoVendas', {
                action: 'alteracaoStatus',
                notaVenda: response.data.notaVenda,
                vendas: response.data.vendas
              })
            }
          },
          errorKey: ['pacoteCancelado', 'erroEmissaoNota']
        }}
      />
    )
  }
}

FecharNotaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FecharNotaDialog)
