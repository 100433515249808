import React, { Component } from 'react'
import Linkify from 'react-linkify'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import SendIcon from '@material-ui/icons/Send'
import WarningIcon from '@material-ui/icons/Warning'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/ApiClient'
import ContentLoadStatus from 'support/components/contentload/ContentLoadStatus'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PersonIcon from 'support/components/icon/PersonOff'
import InputText from 'support/components/input/InputText'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import NativeApp from 'support/components/nativeapp/NativeApp'
import CustomScrollbars from 'support/components/scroll/CustomScrollbars'
import CategoriaMensagemEnum from 'support/domain/suporte/chat/CategoriaMensagemEnum'
import ChatService from 'support/domain/suporte/chat/ChatService'
import OrigemMensagemEnum from 'support/domain/suporte/chat/OrigemMensagemEnum'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import JSONUtil from 'support/util/JSONUtil'
import PlatformUtil from 'support/util/PlatformUtil'
import { converterMomentParaHorarioInt } from 'support/util/TimeConverter'
import uuidv4 from 'uuid/v4'

const SEPARADOR_MENSAGEM = '[{s1}]'
const TAMANHO_LOTE_MENSAGENS = 30

const MARGIN_MENSAGEM = 12
const MARGIN_MENSAGEM_MESMO_REMETENTE = 2
const MARGIN_SEPARADOR_DATA = 24
const MARGIN_INDICADOR_CARREGAMENTO = 12

const INTERVALO_BUSCA_MENSAGENS_NOVAS = 3500

export const VisaoEnum = createEnum({
  ATENDENTE: { id: 1, origem: OrigemMensagemEnum.ATENDENTE },
  USUARIO: { id: 2, origem: OrigemMensagemEnum.USUARIO }
})

export const TipoItemEnum = createEnum({
  MENSAGEM: { id: 1 },
  SEPARADOR_DATA: { id: 2 },
  INDICADOR_CARREGAMENTO: { id: 3 }
})

const styles = (theme) => ({
  loadingContainer: {
    margin: 16,
    display: 'inline-block',
    backgroundColor: '#fff',
    ...theme.util.createBoxShadow('1px 1px 5px 1px rgba(0,0,0,0.15)'),
    borderRadius: 60
  },
  root: {
    width: '100%',
    //marginLeft: "auto",
    //marginRight: "auto",
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentWrapper: {
    height: '100%',
    width: '100%'
    //marginLeft: "auto",
    //marginRight: "auto"
  },
  parentPadding: {
    ...theme.container.basic({ fullWidth: true })
  },
  childPadding: {},
  containerMensagens: {
    paddingTop: 8,
    position: 'relative',
    flex: '1 1 0',
    width: '99.9%' // width:100% causa bug no mobile (mobile real no celular, nao simulado no browser) o scroll horizontal fica sempre visivel e faz o [virtualizedtable + willChange:transform]  nao funcionar (lagando o scroll como se nao tivesse o atributo willChange:transform)
  },
  containerInput: {
    position: 'relative',
    width: '100%'
    //...theme.util.createBoxShadow("-1px -1px 7px -1px rgb(0,0,0,0.1)")
  },
  containerMensagemRecebidaWrapper: {
    textAlign: 'left'
  },
  containerMensagemEnviadaWrapper: {
    textAlign: 'right'
  },
  containerSeparadorDataWrapper: {
    textAlign: 'center'
  },
  containerIndicadorCarregamentoWrapper: {
    textAlign: 'center'
  },
  containerMensagemWrapper: {
    display: 'inline-block',
    minWidth: 156,
    maxWidth: '85%'
  },
  containerMensagem: {
    color: 'rgba(0,0,0,.90)',
    padding: '14px 14px',
    position: 'relative'
    //maxWidth: 400
  },
  containerMensagemRecebida: {
    borderRadius: '20px',
    backgroundColor: '#fff',
    border: '1px solid #e8e8e8'
  },
  containerMensagemEnviada: {
    borderRadius: '20px',
    backgroundColor: 'rgb(245,245,245)'
  },
  containerSeparadorData: {
    padding: '6px 10px',
    borderRadius: '6px'
    //backgroundColor: lighten(theme.palette.secondary.light,0.8),
    //border: "0px solid "+lighten(theme.palette.secondary.light,0.70)
  },
  containerMensagemMesmoRemetente: {
    borderRadius: '16px'
  },
  nomeRemetente: {
    color: theme.palette.secondary.main,
    fontSize: 13
  },
  textoMensagem: {
    textAlign: 'left',
    fontSize: 13,
    '&:after': {
      marginRight: 78,
      content: '""',
      display: 'inline'
    }
  },
  textoData: {
    color: '#b7b7b7',
    fontSize: 16
    //textTransform: "uppercase"
  },
  mensagemCircularProgressCircle: {
    color: '#999'
  },
  containerInfoMensagem: {
    position: 'absolute',
    right: 10,
    bottom: 6,
    display: 'inline-block'
  },
  horarioMensagem: {
    color: 'rgba(0,0,0,.35)',
    fontSize: 11,
    height: 15,
    lineHeight: '15px',
    whiteSpace: 'nowrap'
  },
  iconConfirmacaoEnvio: {
    color: 'rgba(0,0,0,.35)',
    fontSize: 16,
    marginLeft: 4
  },
  containerBarraEnvio: {
    minHeight: 60,
    border: '1px solid #e8e8e8',
    borderRadius: 30,
    marginTop: 0,
    marginBottom: 16
  },
  containerAtendimentoOffline: {
    marginBottom: -27,
    paddingTop: 8,
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 32,
    color: '#ff5050',
    fontSize: '14px',
    fontWeight: 500
  },
  infoRetornoAtendimentoOffline: {
    color: '#909090',
    fontSize: '12px',
    paddingLeft: 4,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
})

class InterfaceChatSuporte extends Component {
  constructor(props) {
    super(props)

    this.itensLength = 0
    this.mounted = true

    this.dataUltimaBuscaMensagens = 0
    this.buscarMensagens = true
    this.buscandoMensagens = false

    this.itensPendentesAtualizacaoServidor = {}
    this.posicoesItensPendentesAtualizadosServidor = []
    this.ultimoIdMensagem = null

    this.primeiraDataCorrente = null
    this.ultimaDataCorrente = null

    this.autoScrollBottom = true

    this.primeiroIdMensagem = null
    this.podeCarregarMensagensHistorico = false
    this.carregandoMensagensHistorico = false

    this.state = {
      permissaoAcessarChat: props.permissaoAcessarChat,
      permissaoEnviarMensagemChat: props.permissaoEnviarMensagemChat,
      paginaCarregada: false,
      itens: []
    }

    this.inputMensagemFunctionMap = {}
    this.scrollFunctionsMap = {}
    this.virtualizedListFunctionMap = {}

    this.stringPlataforma = PlatformUtil.getPlatformString()
  }

  componentDidMount = () => {
    this.tentarBuscarNovasMensagens()
    this.tentarEnviarMensagens()
  }

  programarBuscaMensagens = (delay) => {
    window.setTimeout(
      () => {
        this.buscarMensagens = true
      },
      delay ? delay : 0
    )
  }

  tentarBuscarNovasMensagens = () => {
    if (!this.mounted) {
      return
    }

    if (this.buscarMensagens || new Date().getTime() - this.dataUltimaBuscaMensagens > INTERVALO_BUSCA_MENSAGENS_NOVAS) {
      if (this.buscandoMensagens === false) {
        this.buscandoMensagens = true
        this.buscarMensagens = false
        this.dataUltimaBuscaMensagens = new Date().getTime()
        let endpoint = null
        let contextoUsuario = null
        let idUsuario = null
        if (this.props.visao.id === VisaoEnum.ATENDENTE.id) {
          contextoUsuario = 'admin'
          endpoint = 'admin/chat.buscarUltimasMensagens'
          idUsuario = this.props.conversa.usuario.id
        } else if (this.props.visao.id === VisaoEnum.USUARIO.id) {
          contextoUsuario = 'erp'
          endpoint = 'erp/chat.buscarUltimasMensagens'
        }

        getAPI({
          url: endpoint,
          contextoUsuario: contextoUsuario,
          params: {
            idMensagemBase: this.ultimoIdMensagem,
            idUsuario: idUsuario
          },
          requerAutorizacao: true,
          onSuccess: (response) => {
            let { itens } = this.state
            const quantidadeItensAntesCarregamento = itens.length
            const mensagensNovas = response.data
            const newState = {}
            if (this.state.paginaCarregada === false) {
              newState.paginaCarregada = true
            }
            if (mensagensNovas && mensagensNovas.length > 0) {
              mensagensNovas.reverse()

              for (let mensagemNova of mensagensNovas) {
                mensagemNova.dataCriacao = moment(mensagemNova.dataCriacao)
                const itemPendentesAtualizacaoServidor = this.itensPendentesAtualizacaoServidor[mensagemNova.clientUuid]
                if (itemPendentesAtualizacaoServidor) {
                  itemPendentesAtualizacaoServidor.mensagem = mensagemNova
                  for (var indexItem = 0; indexItem < itens.length; indexItem++) {
                    const item = itens[indexItem]
                    if (item.mensagem && item.mensagem.clientUuid === mensagemNova.clientUuid) {
                      this.posicoesItensPendentesAtualizadosServidor.push(indexItem)
                    }
                  }
                } else {
                  this.adicionarItemMensagemFinalLista({
                    tipo: TipoItemEnum.MENSAGEM,
                    mensagemEnviada: true,
                    mensagem: mensagemNova
                  })
                }
                if (this.ultimoIdMensagem === null || mensagemNova.id > this.ultimoIdMensagem) {
                  this.ultimoIdMensagem = mensagemNova.id
                }
                if (this.primeiroIdMensagem === null || mensagemNova.id < this.primeiroIdMensagem) {
                  this.primeiroIdMensagem = mensagemNova.id
                }
              }
              newState.itens = itens
            }
            this.setState(newState)
            if (quantidadeItensAntesCarregamento === 0 && mensagensNovas && mensagensNovas.length >= TAMANHO_LOTE_MENSAGENS) {
              this.podeCarregarMensagensHistorico = true
            }
            EventsManager.pub('MarcarChatMensagensSuporteLidas')
            this.buscandoMensagens = false
          },
          onError: (response) => {
            if (response.code === 401 || response.code === 403) {
              this.setState({ permissaoAcessarChat: false })
            } else {
              this.programarBuscaMensagens(500)
            }
            this.buscandoMensagens = false
          },
          sendErroToGenericSnackbar: false
        })
      }
    }
    window.setTimeout(() => {
      this.tentarBuscarNovasMensagens()
    }, 100)
  }

  tentarEnviarMensagens = (delay) => {
    if (!this.mounted) {
      return
    }
    window.setTimeout(
      () => {
        const { itens } = this.state
        const itensPendentesEnvio = []
        for (let item of itens) {
          if (item.tipo.id === TipoItemEnum.MENSAGEM.id) {
            if (item.mensagemEnviada === false) {
              itensPendentesEnvio.push(item)
            }
          }
        }
        if (itensPendentesEnvio && itensPendentesEnvio.length > 0) {
          let endpoint = null
          let contextoUsuario = null
          let idUsuario = null
          let plataforma = null

          if (this.props.visao.id === VisaoEnum.ATENDENTE.id) {
            endpoint = 'admin/chat.criarMensagens'
            contextoUsuario = 'admin'
            idUsuario = this.props.conversa.usuario.id
          } else if (this.props.visao.id === VisaoEnum.USUARIO.id) {
            endpoint = 'erp/chat.criarMensagens'
            contextoUsuario = 'erp'
            plataforma = this.stringPlataforma
          }

          const mensagens = []
          for (let itemPendenteEnvio of itensPendentesEnvio) {
            mensagens.push({
              texto: itemPendenteEnvio.mensagem.texto,
              plataforma: plataforma,
              clientUuid: itemPendenteEnvio.mensagem.clientUuid
            })
          }

          postAPI({
            url: endpoint,
            data: {
              mensagens: mensagens,
              usuario: {
                id: idUsuario
              }
            },
            contextoUsuario: contextoUsuario,
            requerAutorizacao: true,
            onSuccess: () => {
              for (let itemPendenteEnvio of itensPendentesEnvio) {
                itemPendenteEnvio.mensagemEnviada = true
              }
              this.programarBuscaMensagens(0)
              this.tentarEnviarMensagens(100)
            },
            onError: (response) => {
              if (response.code === 401 || response.code === 403) {
                this.setState({ permissaoEnviarMensagemChat: false })
              } else {
                this.tentarEnviarMensagens(100)
              }
            },
            sendErroToGenericSnackbar: false
          })
        } else {
          this.tentarEnviarMensagens(1000)
        }
      },
      delay ? delay : 0
    )
  }

  handleSubmitEnviarMensagem = (event) => {
    event.preventDefault()
    this.enviarMensagem()
  }

  enviarMensagem = () => {
    const textoMensagem = this.inputMensagemFunctionMap.getValue()
    if (textoMensagem) {
      const { itens } = this.state
      const clientUuid = uuidv4()
      const novoItem = {
        tipo: TipoItemEnum.MENSAGEM,
        mensagemEnviada: false,
        mensagem: {
          origem: { id: this.props.visao.origem.id },
          clientUuid: clientUuid,
          texto: textoMensagem,
          dataCriacao: moment()
        }
      }
      this.itensPendentesAtualizacaoServidor[clientUuid] = novoItem
      this.adicionarItemMensagemFinalLista(novoItem)
      this.setState({ itens: itens })
      this.autoScrollBottom = true
    }
  }

  moverScrollUltimaMensagem = () => {
    window.setTimeout(() => {
      this.scrollFunctionsMap.scrollTop(99999999, true)
      window.setTimeout(() => {
        this.scrollFunctionsMap.scrollTop(99999999, true)
        window.setTimeout(() => {
          this.scrollFunctionsMap.scrollTop(99999999, true)
        }, 50)
      }, 50)
    }, 50)
  }

  adicionarItemMensagemFinalLista = (itemMensagem) => {
    const { itens } = this.state
    if (itens.length === 0 || itemMensagem.mensagem.dataCriacao.clone().startOf('day').diff(this.ultimaDataCorrente, 'days') !== 0) {
      if (this.primeiraDataCorrente === null) {
        this.primeiraDataCorrente = itemMensagem.mensagem.dataCriacao.clone().startOf('day')
      }
      this.ultimaDataCorrente = itemMensagem.mensagem.dataCriacao.clone().startOf('day')
      itens.push({
        tipo: TipoItemEnum.SEPARADOR_DATA,
        data: itemMensagem.mensagem.dataCriacao.clone()
      })
    }
    itens.push(itemMensagem)
  }

  adicionarItemMensagemInicioLista = (itemMensagem, ultimoDoLoteDeBusca) => {
    const { itens } = this.state
    if (itens[0].mensagem.dataCriacao.clone().startOf('day').diff(this.primeiraDataCorrente, 'days') !== 0) {
      itens.unshift({
        tipo: TipoItemEnum.SEPARADOR_DATA,
        data: this.primeiraDataCorrente.clone()
      })
      this.primeiraDataCorrente = itemMensagem.mensagem.dataCriacao.clone().startOf('day')
    }
    itens.unshift(itemMensagem)
    if (ultimoDoLoteDeBusca) {
      itens.unshift({
        tipo: TipoItemEnum.SEPARADOR_DATA,
        data: this.primeiraDataCorrente.clone()
      })
    }
  }

  carregarMaisMensagensHistorico = (param) => {
    if (this.podeCarregarMensagensHistorico) {
      this.podeCarregarMensagensHistorico = false
      this.carregandoMensagensHistorico = true

      let { itens } = this.state
      itens.unshift({
        tipo: TipoItemEnum.INDICADOR_CARREGAMENTO
      })

      this.setState({ itens: itens })

      let endpoint = null
      let contextoUsuario = null
      let idUsuario = null
      if (this.props.visao.id === VisaoEnum.ATENDENTE.id) {
        endpoint = 'admin/chat.buscarMensagensHistorico'
        contextoUsuario = 'admin'
        idUsuario = this.props.conversa.usuario.id
      } else if (this.props.visao.id === VisaoEnum.USUARIO.id) {
        endpoint = 'erp/chat.buscarMensagensHistorico'
        contextoUsuario = 'erp'
      }

      getAPI({
        url: endpoint,
        contextoUsuario: contextoUsuario,
        params: {
          idMensagemBase: this.primeiroIdMensagem,
          idUsuario: idUsuario
        },
        requerAutorizacao: true,
        onSuccess: (response) => {
          const mensagensNovas = response.data
          const newState = { itens: itens }
          itens.splice(0, 1)
          if (mensagensNovas && mensagensNovas.length > 0) {
            itens.splice(0, 1)
            const quantidadeItensAntesCarregamento = itens.length
            for (let i = 0; i < mensagensNovas.length; i++) {
              const mensagemNova = mensagensNovas[i]
              mensagemNova.dataCriacao = moment(mensagemNova.dataCriacao)
              this.adicionarItemMensagemInicioLista(
                {
                  tipo: TipoItemEnum.MENSAGEM,
                  mensagemEnviada: true,
                  mensagem: mensagemNova
                },
                i === mensagensNovas.length - 1
              )
              if (mensagemNova.id < this.primeiroIdMensagem) {
                this.primeiroIdMensagem = mensagemNova.id
              }
            }

            this.posicionarScrollAposCarregamentoHistorico = {
              index: itens.length - quantidadeItensAntesCarregamento
            }
          }
          if (mensagensNovas.length >= TAMANHO_LOTE_MENSAGENS) {
            this.podeCarregarMensagensHistorico = true
          }
          this.carregandoMensagensHistorico = false
          this.setState(newState)
        },
        onError: (response) => {
          if (response.code === 401 || response.code === 403) {
            this.setState({ permissaoAcessarChat: false })
          }
        },
        sendErroToGenericSnackbar: false,
        repeat: {
          stopFunction: () => {
            return !this.mounted
          }
        }
      })
    }
  }

  ajustarVisualizacaoTopoScroll = () => {
    /*
		Ao segurar o scroll com o mouse no top, o componente em certas vezes nao renderiza os primeiros itens da lista, apresentando o campo
		de visao do scroll sem nenhum item renderizado. Esse metodo movimenta o scroll um pouco para baixo (5% do tamanho da area) para força ultima
		nova renderizacao. Metodos como recomputeRowHeights(), forceUpdateGrid() do react-virtualized nao fazem o re-render nesse caso. Apenas a movimentacao
		so scroll se mostrou eficiente
		*/
    this.ultimaChamada = new Date().getTime()
    window.setTimeout(() => {
      const diferencaTempo = new Date().getTime() - this.ultimaChamada
      if (diferencaTempo >= 499) {
        const scrollRef = this.scrollFunctionsMap.getScrollRef()
        if (scrollRef) {
          if (scrollRef.getScrollTop() <= 1) {
            this.scrollFunctionsMap.scrollTop(scrollRef.getScrollHeight() * 0.05, true)
          }
        }
      }
    }, 500)
  }

  getHorarioAtendimentoPorData = (dataAsMoment) => {
    const { excecoesDisponibilidade } = this.props
    if (excecoesDisponibilidade) {
      for (let excecaoDisponibilidade of excecoesDisponibilidade) {
        if (excecaoDisponibilidade.data === converterMomentParaDataInt(dataAsMoment)) {
          return {
            dia: converterDataIntParaMoment(excecaoDisponibilidade.data).isoWeekday(),
            horarioInicio: excecaoDisponibilidade.horarioInicio,
            horarioTermino: excecaoDisponibilidade.horarioTermino
          }
        }
      }
    }

    for (let horariosAtendimento of this.props.horariosAtendimento) {
      if (horariosAtendimento.dia === dataAsMoment.isoWeekday()) {
        return horariosAtendimento
      }
    }
    return null
  }

  isHorarioAtendimentoValidoEComDisponibilidade = (horarioAtendimento) => {
    if (!horarioAtendimento || (!horarioAtendimento.horarioInicio && !horarioAtendimento.horarioTermino)) {
      return false
    } else {
      return true
    }
  }

  gerarMensagemAtendimentoOffline = () => {
    const agora = moment()
    agora.utcOffset(parseInt(process.env.REACT_APP_SERVER_TIME_ZONE_IN_MINUTES, 10))

    const horarioAtendimentoAgora = this.getHorarioAtendimentoPorData(agora)
    const horarioAgora = converterMomentParaHorarioInt(agora)
    let online = true
    if (!this.isHorarioAtendimentoValidoEComDisponibilidade(horarioAtendimentoAgora)) {
      online = false
    } else {
      if (horarioAgora < horarioAtendimentoAgora.horarioInicio || horarioAgora > horarioAtendimentoAgora.horarioTermino) {
        online = false
      }
    }
    if (!online) {
      if (horarioAtendimentoAgora && horarioAtendimentoAgora.horarioInicio && horarioAgora < horarioAtendimentoAgora.horarioInicio) {
        return 'volta hoje'
      } else {
        let dataReferencia = agora.clone()
        let tentativasEncontrarProximoAtendimento = 0
        while (tentativasEncontrarProximoAtendimento <= 7) {
          dataReferencia.add(1, 'days')
          let horarioAtendimento = this.getHorarioAtendimentoPorData(dataReferencia)
          if (this.isHorarioAtendimentoValidoEComDisponibilidade(horarioAtendimento)) {
            const diferencaDias = dataReferencia.clone().startOf('day').diff(agora.clone().startOf('day'), 'days')
            let descricaoProximoDia = null
            if (diferencaDias === 0) {
              descricaoProximoDia = 'hoje'
            } else if (diferencaDias === 1) {
              descricaoProximoDia = 'amanhã'
            } else if (diferencaDias > 1) {
              descricaoProximoDia = dataReferencia.format('dddd').toLowerCase()
            }
            if (descricaoProximoDia) {
              return 'volta ' + descricaoProximoDia
            }
          }
          tentativasEncontrarProximoAtendimento++
        }
      }
    }
    return null
  }

  render() {
    const { classes } = this.props
    const { permissaoAcessarChat, permissaoEnviarMensagemChat, paginaCarregada } = this.state

    const contentWrapperParent = [classes.contentWrapper]
    if (this.props.containerPadding === 'basic') {
      contentWrapperParent.push(classes.parentPadding)
    }

    let atendimentoOfflineContent = null
    if (this.props.controlarHorariosAtendimento) {
      const mensagemAtendimentoOffline = this.gerarMensagemAtendimentoOffline()
      if (mensagemAtendimentoOffline) {
        atendimentoOfflineContent = (
          <div className={classes.containerAtendimentoOffline}>
            <Grid container alignItems="center">
              <Grid item>
                <PersonIcon style={{ display: 'block' }} />
              </Grid>
              <Grid item xs style={{ paddingLeft: 4 }}>
                Atendimento Off-line{' '}
                <span className={classes.infoRetornoAtendimentoOffline}>
                  <b>({mensagemAtendimentoOffline})</b>
                </span>
              </Grid>
            </Grid>
          </div>
        )
      }
    }

    return (
      <div className={classes.root}>
        <div className={classes.containerMensagens}>
          <CustomScrollbars
            hideTracksWhenNotNeeded={false}
            applyWillChangeOnScrollContainer={true}
            horizontalScrollDisabled={true}
            color="rgba(0,0,0,0.19)"
            functionsMap={this.scrollFunctionsMap}
            onScrollStart={(data) => {
              this.autoScrollBottom = false
            }}
            onScrollStop={(data) => {
              const scrollRef = this.scrollFunctionsMap.getScrollRef()
              //scrollRef.container.children[0].style.overflowAnchor = "auto";
              if (scrollRef) {
                if (scrollRef.getScrollTop() === 0) {
                  this.carregarMaisMensagensHistorico({ scrollRef: scrollRef })
                }
                if (scrollRef.getScrollHeight() - (scrollRef.getClientHeight() + scrollRef.getScrollTop()) < 16) {
                  this.autoScrollBottom = true
                } else {
                  this.autoScrollBottom = false
                }
              }
            }}
          >
            {(scrollProps) => (
              <div className={classNames(contentWrapperParent)} style={{ maxWidth: this.props.maxWidth }}>
                <div>
                  <div style={{ padding: '16px 0px 24px', textAlign: 'center' }}>
                    {!permissaoAcessarChat && (
                      <div style={{ textAlign: 'center' }}>
                        <Typography variant="body1">Permissão de acesso negada</Typography>
                      </div>
                    )}

                    {!paginaCarregada && (
                      <div className={classes.loadingContainer}>
                        <ContentLoadStatus loading={true} loaded={false} circularProgressVariant="contained" />
                      </div>
                    )}

                    {permissaoAcessarChat && paginaCarregada && (
                      <VirtualizedList
                        scrollElement={scrollProps.scrollElement}
                        showRowBorders={false}
                        showBackgroundDividers={false}
                        showRowLoadingMessage={false}
                        backgroundColor="transparent"
                        dynamicHeight={true}
                        items={this.state.itens}
                        rowRenderer={(item, { index, key, style }) => {
                          let content = null

                          if (item.tipo.id === TipoItemEnum.MENSAGEM.id) {
                            const { mensagem } = item

                            let mesmoRemetente = false
                            let marginTop = MARGIN_MENSAGEM

                            let itemAnterior = null
                            if (index > 0) {
                              itemAnterior = this.state.itens[index - 1]
                              if (itemAnterior.tipo.id === TipoItemEnum.SEPARADOR_DATA.id) {
                                marginTop = MARGIN_SEPARADOR_DATA
                              } else if (itemAnterior.tipo.id === TipoItemEnum.INDICADOR_CARREGAMENTO.id) {
                                marginTop = MARGIN_INDICADOR_CARREGAMENTO
                              } else {
                                const mensagemAnterior = itemAnterior.mensagem
                                const mensagemAnteriorIdCategoria = mensagemAnterior.categoria ? mensagemAnterior.categoria : null
                                const mensagemIdCategoria = mensagem.categoria ? mensagem.categoria : null
                                if (
                                  mensagemAnterior.origem.id === mensagem.origem.id &&
                                  mensagemAnteriorIdCategoria === mensagemIdCategoria &&
                                  (!mensagem.id || mensagemAnterior.nomeRemetente === mensagem.nomeRemetente)
                                ) {
                                  marginTop = MARGIN_MENSAGEM_MESMO_REMETENTE
                                  mesmoRemetente = true
                                }
                              }
                            }

                            if (mensagem.origem.id === this.props.visao.origem.id) {
                              content = <MensagemEnviada visao={this.props.visao} classes={classes} mensagem={mensagem} mesmoRemetente={mesmoRemetente} marginTop={marginTop} />
                            } else {
                              content = <MensagemRecebida visao={this.props.visao} classes={classes} mensagem={mensagem} mesmoRemetente={mesmoRemetente} marginTop={marginTop} />
                            }
                          } else if (item.tipo.id === TipoItemEnum.SEPARADOR_DATA.id) {
                            content = <SeparadorData classes={classes} data={item.data} />
                          } else if (item.tipo.id === TipoItemEnum.INDICADOR_CARREGAMENTO.id) {
                            content = <IndicadorCarregamento classes={classes} data={item.data} />
                          }

                          return (
                            <div key={key} style={style}>
                              {content}
                            </div>
                          )
                        }}
                        functionsMap={this.virtualizedListFunctionMap}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </CustomScrollbars>
        </div>

        {permissaoEnviarMensagemChat && paginaCarregada && (
          <div className={classes.containerInput}>
            <div className={classNames(contentWrapperParent)} style={{ maxWidth: this.props.maxWidth }}>
              <div style={{ paddingTop: 8 }}>
                {atendimentoOfflineContent}
                <form id="formEnviarMensagem" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmitEnviarMensagem(event)}>
                  <Grid container alignItems="center" className={classes.containerBarraEnvio}>
                    {/*

											<Grid item>
												<IconButton disabled={true}>
													<AttachFileIcon />
												</IconButton>
											</Grid>

											*/}

                    <Grid item xs style={{ paddingLeft: 24 }}>
                      <InputTextMensagem
                        idname="xmensagem"
                        multiline={true}
                        rowsMax={3}
                        InputProps={{
                          inputProps: {
                            style: { overflowY: 'auto', fontSize: 15 }
                          }
                        }}
                        customVariant="naked"
                        placeholder="Mensagem..."
                        onEnterPressAsSubmit={(value) => {
                          this.enviarMensagem()
                        }}
                        functionsMap={this.inputMensagemFunctionMap}
                      />
                    </Grid>
                    {/*
											(this.props.showEmojisButton === undefined || this.props.showEmojisButton === null || this.props.showEmojisButton === true) &&
											<Grid item>
												<IconButton disabled={true}>
													<InsertEmoticonIcon />
												</IconButton>
											</Grid>
										*/}

                    <Grid item>
                      <IconButton type="submit">
                        <SendIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.virtualizedListFunctionMap && this.virtualizedListFunctionMap.recomputeRowHeights && this.itensLength !== this.state.itens.length) {
      this.virtualizedListFunctionMap.recomputeRowHeights()
      this.ajustarVisualizacaoTopoScroll()
    }

    if (this.posicoesItensPendentesAtualizadosServidor.length > 0) {
      for (let posicaoItemPendenteAtualizadoServidor of this.posicoesItensPendentesAtualizadosServidor) {
        this.virtualizedListFunctionMap.softRecomputeRowHeights(posicaoItemPendenteAtualizadoServidor)
      }
      this.posicoesItensPendentesAtualizadosServidor = []
    }

    this.itensLength = this.state.itens.length
    if (this.posicionarScrollAposCarregamentoHistorico) {
      if (this.posicionarScrollAposCarregamentoHistorico && !this.carregandoMensagensHistorico) {
        const indexToScroll = this.posicionarScrollAposCarregamentoHistorico.index
        this.posicionarScrollAposCarregamentoHistorico = false
        this.virtualizedListFunctionMap.scrollToIndex(indexToScroll)
      }
    } else if (this.autoScrollBottom && this.scrollFunctionsMap.scrollTop) {
      this.moverScrollUltimaMensagem()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }
}

class InputTextMensagem extends Component {
  constructor(props) {
    super(props)
    this.inputMensagem = React.createRef()
  }

  componentDidMount = () => {
    const message = new URLSearchParams(window.location.search).get('message')

    if (message) {
      this.inputMensagem.current.value = message
    }

    if (this.props.functionsMap) {
      this.props.functionsMap.getValue = () => {
        return this.getValue()
      }
      this.props.functionsMap.setValue = (value) => {
        this.inputMensagem.current.value = value
      }
    }
  }

  getValue = () => {
    const value = this.inputMensagem.current.value
    this.inputMensagem.current.value = ''
    if (value.replace(/[\s\n\r\t\f]/gi, '').length > 0) {
      return value.trim()
    } else {
      return ''
    }
  }

  getCaret = (el) => {
    if (el.selectionStart) {
      return el.selectionStart
    } else if (document.selection) {
      el.focus()
      var r = document.selection.createRange()
      if (r == null) {
        return 0
      }
      var re = el.createTextRange(),
        rc = re.duplicate()
      re.moveToBookmark(r.getBookmark())
      rc.setEndPoint('EndToStart', re)
      return rc.text.length
    }
    return 0
  }

  render() {
    const { onEnterPressAsSubmit, ...others } = this.props

    return (
      <InputText
        {...others}
        inputRef={this.inputMensagem}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
          }
        }}
        onKeyUp={(event) => {
          const element = event.target
          if (event.keyCode === 13) {
            var content = element.value
            var caret = this.getCaret(element)
            if (event.shiftKey) {
              element.value = content.substring(0, caret - 1) + '\n' + content.substring(caret, content.length)
              event.stopPropagation()
            } else {
              event.preventDefault()
              element.value = content.substring(0, caret) + content.substring(caret, content.length)
              onEnterPressAsSubmit(element.value)
            }
          }
        }}
      />
    )
  }
}

function processarMensagem(mensagem) {
  const fragmentosMensagem = mensagem.split(SEPARADOR_MENSAGEM)
  if (fragmentosMensagem.length > 1) {
    for (let i = 0; i < fragmentosMensagem.length; i++) {
      const fragmentoMensagem = fragmentosMensagem[i]
      const jsonObject = JSONUtil.getJSONFromString(fragmentoMensagem)
      if (jsonObject) {
        if (jsonObject.component === 'button') {
          let buttonProps = {}
          if (jsonObject.action === 'NavegarPaginaPainel') {
            let rota = null
            if (jsonObject.page === 'plano') {
              rota = '/plano'
            }
            buttonProps.onClick = () => {
              EventsManager.pub('NavegarPaginaPainel', { rota: rota })
            }
          } else if (jsonObject.action === 'AbrirLinkExterno') {
            if (DeviceIdentifier.isNativeApp()) {
              buttonProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: jsonObject.url
                })
              }
            } else {
              buttonProps.component = 'a'
              buttonProps.href = jsonObject.url
              buttonProps.target = '_blank'
            }
          }
          fragmentosMensagem[i] = (
            <Button key={i} color="secondary" size="small" variant="contained" {...buttonProps} style={{ marginTop: 6, marginBottom: 8 }}>
              {jsonObject.label}
            </Button>
          )
        }
      }
    }
  }

  return fragmentosMensagem.map((fragmentoMensagem) => {
    if (typeof fragmentoMensagem === 'string') {
      return fragmentoMensagem.split('\n').map(function (item, idx) {
        return (
          <React.Fragment key={idx}>
            <Linkify
              componentDecorator={(href, text, key) => {
                const linkProps = {}

                if (DeviceIdentifier.isNativeApp()) {
                  linkProps.onClick = () => {
                    NativeApp.executeNativeMethod({
                      method: 'openLink',
                      url: href
                    })
                  }
                } else {
                  linkProps.href = href
                  linkProps.target = '_blank'
                }

                return (
                  <Link key={key} {...linkProps} color="secondary">
                    {text}
                  </Link>
                )
              }}
            >
              {item}
            </Linkify>
            <br />
          </React.Fragment>
        )
      })
    } else {
      return fragmentoMensagem
    }
  })
}

function MensagemRecebida(props) {
  const { classes, mensagem, mesmoRemetente, marginTop } = props

  let prefixoNomeRemetente = null
  if (mensagem.categoria) {
    if (mensagem.categoria.id === CategoriaMensagemEnum.ALERTA.id) {
      prefixoNomeRemetente = <WarningIcon style={{ fontSize: 16, verticalAlign: 'top', marginRight: 4, marginBottom: 6 }} />
    } else if (mensagem.categoria.id === CategoriaMensagemEnum.INFORMACAO.id) {
      prefixoNomeRemetente = <InfoOutlinedIcon style={{ fontSize: 16, verticalAlign: 'top', marginRight: 4, marginBottom: 6 }} />
    } else if (mensagem.categoria.id === CategoriaMensagemEnum.NOVIDADE.id) {
      prefixoNomeRemetente = <NewReleasesIcon style={{ fontSize: 16, verticalAlign: 'top', marginRight: 4, marginBottom: 6 }} />
    }
  }

  return (
    <div className={classes.containerMensagemRecebidaWrapper}>
      <div className={classes.containerMensagemWrapper}>
        <div
          style={{ marginTop: marginTop }}
          className={classNames(classes.containerMensagem, classes.containerMensagemRecebida, mesmoRemetente ? classes.containerMensagemMesmoRemetente : null)}
        >
          {!mesmoRemetente && (
            <Typography variant="body2" className={classes.nomeRemetente}>
              <b>
                {prefixoNomeRemetente}
                {ChatService.getNomeRemetente(mensagem)}
              </b>
            </Typography>
          )}
          {VisaoEnum.ATENDENTE.id === props.visao.id && !mesmoRemetente && mensagem.idNegocio && mensagem.idColaborador && (
            <Typography variant="body2" color="secondary" style={{ marginTop: -2, fontSize: 12 }}>
              n:{mensagem.idNegocio} c:{mensagem.idColaborador}
            </Typography>
          )}
          <Typography variant="body2" className={classes.textoMensagem}>
            {processarMensagem(mensagem.texto)}
          </Typography>

          <div className={classes.containerInfoMensagem}>
            <Typography variant="body2" className={classes.horarioMensagem}>
              {VisaoEnum.ATENDENTE.id === props.visao.id && mensagem.plataforma && mensagem.plataforma + ' - '}
              {mensagem.dataCriacao.format('HH:mm')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

function MensagemEnviada(props) {
  const { classes, mensagem, mesmoRemetente, marginTop } = props

  return (
    <div className={classes.containerMensagemEnviadaWrapper}>
      <div className={classes.containerMensagemWrapper}>
        <div
          style={{ marginTop: marginTop }}
          className={classNames(classes.containerMensagem, classes.containerMensagemEnviada, mesmoRemetente ? classes.containerMensagemMesmoRemetente : null)}
        >
          {!mesmoRemetente && props.visao.id === VisaoEnum.ATENDENTE.id && (
            <Typography variant="body2" className={classes.nomeRemetente} style={{ textAlign: 'left' }}>
              <b>{ChatService.getNomeRemetente(mensagem)}</b>
            </Typography>
          )}
          <Typography variant="body2" className={classes.textoMensagem}>
            {processarMensagem(mensagem.texto)}
          </Typography>
          <div className={classes.containerInfoMensagem}>
            {!mensagem.id && <CircularProgress size={13} thickness={5.5} classes={{ circle: classes.mensagemCircularProgressCircle }} />}
            {mensagem.id && (
              <Grid container alignItems="center" wrap="nowrap" style={{ marginBottom: -3 }}>
                <Grid item>
                  <Typography variant="body2" className={classes.horarioMensagem}>
                    {mensagem.dataCriacao.format('HH:mm')}
                  </Typography>
                </Grid>
                <Grid item>
                  <DoneAllIcon className={classes.iconConfirmacaoEnvio} />
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function SeparadorData(props) {
  const { classes, data } = props

  const dias = moment().startOf('day').diff(data.startOf('day'), 'days')
  let descricaoData = null
  if (dias === 0) {
    descricaoData = 'Hoje'
  } else if (dias === 1) {
    descricaoData = 'Ontem'
  } else {
    descricaoData = data.format('DD [de] MMMM [de] YYYY')
  }

  return (
    <div className={classes.containerSeparadorDataWrapper}>
      <div className={classes.containerMensagemWrapper}>
        <div style={{ marginTop: MARGIN_SEPARADOR_DATA }} className={classNames(classes.containerMensagem, classes.containerSeparadorData)}>
          <Typography variant="body2" className={classes.textoData}>
            {descricaoData}
          </Typography>
        </div>
      </div>
    </div>
  )
}

function IndicadorCarregamento(props) {
  const { classes } = props

  return (
    <div className={classes.containerIndicadorCarregamentoWrapper}>
      <div className={classes.containerMensagemWrapper}>
        <div style={{ marginTop: MARGIN_INDICADOR_CARREGAMENTO }} className={classNames(classes.loadingContainer, classes.loadingContainer)}>
          <ContentLoadStatus loading={true} loaded={false} circularProgressVariant="contained" />
        </div>
      </div>
    </div>
  )
}

InterfaceChatSuporte.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InterfaceChatSuporte)
