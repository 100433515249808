import React from 'react'

import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  REGRA_PADRAO_SISTEMA: {
    id: 3,
    descricaoOpcao: 'Regra padrão do sistema',
    descricaoExplicacao: <span>Considera a regra padrão do sistema.</span>
  },
  NAO_APLICAR_TAXA: {
    id: 1,
    descricaoOpcao: 'Não aplicar taxa de forma de pagamento',
    descricaoExplicacao: (
      <span>
        Esta opção faz com que o sistema calcule a comissão de acordo com o valor integral do serviço ou venda, independente das despesas com taxas de forma de pagamento. No caso
        de gorjetas, o sistema também não descontará as taxas de forma de pagamento.
      </span>
    )
  },
  APLICAR_TAXA: {
    id: 2,
    descricaoOpcao: 'Aplicar taxa de forma de pagamento',
    descricaoExplicacao: (
      <span>
        Esta opção faz com que o sistema desconte os valores das despesas com taxas de forma de pagamento das comissões. Assim, o cálculo das comissões é realizado após a
        aplicações das despesas das taxas, resultando em uma comissão menor. No caso de gorjetas, o sistema também descontará as taxas de forma de pagamento, resultando em uma
        gorjeta menor para o colaborador.
      </span>
    )
  }
})
export default enumObject
