import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { fade, lighten } from '@material-ui/core/styles/colorManipulator'
import Tooltip from '@material-ui/core/Tooltip'
import BlockIcon from '@material-ui/icons/Block'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import PublicIcon from '@material-ui/icons/Public'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendIcon from '@material-ui/icons/Send'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import classNames from 'classnames'
import moment from 'moment'
import { RegraCorCardEnum } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import BloqueioHorarioPopup from 'pages/erp/painel/colaborador/BloqueioHorarioPopup'
import NegocioSupport from 'pages/erp/painel/negocio/NegocioSupport'
import VendaPopup from 'pages/erp/painel/venda/VendaPopup'
import PropTypes from 'prop-types'
import { openBackablePopup } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AtendimentoStatusIcon from 'support/components/icon/AtendimentoStatus'
import VendaStatusIcon from 'support/components/icon/VendaStatus'
import AtendimentoOrigemEnum from 'support/domain/atendimento/AtendimentoOrigemEnum'
import StatusServicoEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import AtendimentoStatusEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import ColorSchema from 'support/domain/color/ColorSchema'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { converterHorarioIntParaMinutos } from 'support/util/TimeConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const PADDING_LEFT_CARD_CONTENT = 0 //8;
const Z_INDEX = 4

const styles = (theme) => ({
  singleLine: theme.text.singleLine({ withEllipsis: false }),
  root: {
    position: 'relative',
    zIndex: Z_INDEX,
    overflow: 'hidden',
    cursor: 'pointer',
    color: 'white',
    borderRadius: 4,
    textAlign: 'left'
  },
  rootMensal: {
    '& $container': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 3
      }
    },
    '& $linhaLateral': {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  },
  container: {
    paddingLeft: 8
  },
  icon: {
    color: 'inherit',
    display: 'block'
  },
  smallIcon: {
    fontSize: 16
  },
  regularIcon: {
    fontSize: 18
  },
  linhaLateral: {
    position: 'absolute',
    width: 5,
    left: 0,
    bottom: 0,
    top: 0
  },
  gridHeader: {
    minHeight: 18
  },
  gridHorario: {
    fontSize: 11
  },
  gridIcone: {
    paddingRight: 2
  },
  nomeCliente: {
    fontSize: 13,
    paddingLeft: 0,
    fontWeight: 500,
    marginTop: 1
  },
  descricaoServico: {
    fontSize: 12,
    paddingLeft: 0,
    marginTop: 3,
    wordBreak: 'break-all'
  }
})

class ItemCard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getCorCardAtendimento = () => {
    const { item } = this.props
    if (this.props.regraCorCard.id === RegraCorCardEnum.SERVICO.id) {
      return ColorSchema.getColorById(item.atendimento.servico.categoria.cor)
    } else if (this.props.regraCorCard.id === RegraCorCardEnum.COLABORADOR.id) {
      return ColorSchema.getColorById(item.atendimento.venda.colaborador ? item.atendimento.venda.colaborador.cor : null)
    } else if (this.props.regraCorCard.id === RegraCorCardEnum.ANDAMENTO.id) {
      return ColorSchema.getColorById(StatusServicoEnum.getById(item.atendimento.status.id).cor)
    } else if (this.props.regraCorCard.id === RegraCorCardEnum.PAGAMENTO.id) {
      return ColorSchema.getColorById(StatusVendaEnum.getById(item.atendimento.venda.status.id).cor)
    }
    return null
  }

  abrirItemPopup = (event) => {
    event.stopPropagation()

    const clientX = event.clientX
    const clientY = event.clientY

    NegocioSupport.tentarExibirAvisoImportante({
      onIgnore: () => {
        const { item, itens } = this.props
        if (item.atendimento) {
          const atendimentos = []
          if (itens) {
            for (let i = 0; i < itens.length; i++) {
              const itemLista = itens[i]
              if (itemLista.atendimento) {
                atendimentos.push(itemLista.atendimento)
              }
            }
          }
          this.abrirVendaPopup(clientX, clientY, item.atendimento, atendimentos)
        } else if (item.bloqueioHorario) {
          this.abrirBloqueioHorarioPopup(clientX, clientY, item.bloqueioHorario)
        }
      }
    })
  }

  abrirVendaPopup = (clientX, clientY, atendimento, atendimentos) => {
    openBackablePopup(VendaPopup, {
      clientX: clientX,
      clientY: clientY,
      anchorReference: 'anchorPosition',
      atendimento: atendimento,
      atendimentos: atendimentos
    })
  }

  abrirBloqueioHorarioPopup = (clientX, clientY, bloqueioHorario) => {
    openBackablePopup(BloqueioHorarioPopup, {
      clientX: clientX,
      clientY: clientY,
      anchorReference: 'anchorPosition',
      bloqueioHorario: bloqueioHorario
    })
  }

  adicionarPropriedadesPosicionamentoGridCard = (item, rootProps) => {
    const { itemInstancia } = this.props

    const horarioInicioMinutos = converterHorarioIntParaMinutos(item.horarioInicio)
    const horarioTerminoMinutos = converterHorarioIntParaMinutos(item.horarioTermino)
    const duracaoMinutos = horarioTerminoMinutos - horarioInicioMinutos

    const colunaPosicao = itemInstancia._coluna.posicao
    const colunaMaxWidth = this.props.colunaWidth - 2
    const colunaInternaWidth = colunaMaxWidth / (itemInstancia._coluna._quantidadeColunasInternas + 1)
    const colunaLeftPosition = colunaPosicao * this.props.colunaWidth + (colunaPosicao + 1) * 1

    rootProps.style.position = 'absolute'
    if (itemInstancia._colunaInterna > 0) {
      rootProps.style.border = '1px solid #fff'
    }
    rootProps.style.zIndex = itemInstancia._colunaInterna + Z_INDEX
    rootProps.style.top = horarioInicioMinutos * this.props.pixelsPorMinuto + 1
    rootProps.style.left = colunaLeftPosition + colunaInternaWidth * itemInstancia._colunaInterna
    rootProps.style.width =
      colunaInternaWidth * (itemInstancia._coluna._quantidadeColunasInternas + 1 - itemInstancia._colunaInterna) -
      (itemInstancia._colunaInterna === 0 ? 0 : 1) -
      PADDING_LEFT_CARD_CONTENT
    //rootProps.style.paddingLeft = PADDING_LEFT_CARD_CONTENT;
    rootProps.style.height = duracaoMinutos * this.props.pixelsPorMinuto - 3
    rootProps.onMouseMove = (event) => {
      this.props.guiaGridAdicionarRef.current.style.display = 'none'
      event.stopPropagation()
    }

    if (rootProps.style.top + rootProps.style.height > this.props.heightTotalGrid) {
      rootProps.style.height = this.props.heightTotalGrid - rootProps.style.top
    }
  }

  render() {
    const { classes, item, itemInstancia } = this.props

    let linha1 = null
    let linha2 = null
    let linha3 = null

    let corCard = {}

    const rootProps = {
      className: classes.root,
      style: {}
    }

    const containerProps = {
      className: classes.container,
      style: {}
    }

    if (this.props.perspectiva.id !== PerspectivaEnum.MES.id) {
      rootProps.onClick = this.abrirItemPopup
    } else {
      rootProps.className = classNames(classes.root, classes.rootMensal)
    }

    let iconLembreteEnviado = null
    let iconAgendamentoOnline = null
    let iconUtilizacaoPacote = null
    let iconStatusAtendimento = null
    let iconStatusNota = null
    let paddingTop = 4
    let paddingBottom = 0

    let cardLinhaLateralBackgroundProps = {}

    if (item.atendimento) {
      const atendimento = item.atendimento
      corCard = this.getCorCardAtendimento()

      rootProps.style.color = corCard.cardTexto

      let descricaoServico = atendimento.servico.nome
      if (atendimento.observacoes) {
        descricaoServico = '*' + descricaoServico
      }

      if (atendimento.tipo.id === AtendimentoTipoEnum.ORDEM_CHEGADA.id) {
        const dataAtendimentoAsMoment = converterDataIntParaMoment(atendimento.data).startOf('day')

        rootProps.style.backgroundColor = lighten(corCard.cardBackground, 0.12)
        linha1 = 'Chegada: '
        const diasAtras = moment().startOf('day').diff(dataAtendimentoAsMoment, 'days')
        if (diasAtras === 1) {
          linha1 += 'Ontem, às '
        } else if (diasAtras !== 0) {
          linha1 += dataAtendimentoAsMoment.format('DD/MMM/YY') + ', às '
        }
        linha1 += formatarHorarioInt(atendimento.horarioEntradaFila)
        rootProps.style.height = this.props.height
        //rootProps.style.paddingLeft = PADDING_LEFT_CARD_CONTENT;
        linha2 = <div className={classNames(classes.singleLine, classes.nomeCliente)}>{atendimento.venda.cliente.apelido}</div>
        linha3 = <div className={classes.descricaoServico}>{descricaoServico}</div>
      } else if (atendimento.tipo.id === AtendimentoTipoEnum.HORA_MARCADA.id) {
        rootProps.style.backgroundColor = fade(corCard.cardBackground, 0.9)

        if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
          paddingTop = 5
          paddingBottom = 4
          rootProps.style.borderRadius = 3
          //rootProps.style.paddingLeft = 6;
          rootProps.style.marginTop = 0
          cardLinhaLateralBackgroundProps.width = 4
          linha1 = (
            <span>
              {formatarHorarioInt(atendimento.horarioInicio)} <b>{atendimento.venda.cliente.apelido.split(' ')[0]}</b>, {descricaoServico}
            </span>
          )
        } else {
          if (!itemInstancia._coluna) {
            return []
          }

          this.adicionarPropriedadesPosicionamentoGridCard(item, rootProps)

          if (atendimento.lembreteManualEnviado) {
            iconLembreteEnviado = (
              <Grid item align="right" className={classes.gridIcone} style={{ color: corCard.cardIcones }}>
                <Tooltip title="Lembrete enviado">
                  <SendIcon className={classNames(classes.icon, classes.smallIcon)} />
                </Tooltip>
              </Grid>
            )
          }
          if (atendimento.origem.id === AtendimentoOrigemEnum.PORTAL.id) {
            iconAgendamentoOnline = (
              <Grid item align="right" className={classes.gridIcone} style={{ color: corCard.cardIcones }}>
                <Tooltip title="Agendado pelo Cliente">
                  <PublicIcon className={classNames(classes.icon, classes.smallIcon)} />
                </Tooltip>
              </Grid>
            )
          }
          if (atendimento.origem.id === AtendimentoOrigemEnum.CHATBOT.id) {
            iconAgendamentoOnline = (
              <Grid item align="right" className={classes.gridIcone} style={{ color: corCard.cardIcones }}>
                <Tooltip title="Agendado pelo cliente via Chatbot">
                  <WhatsAppIcon className={classNames(classes.icon, classes.smallIcon)} />
                </Tooltip>
              </Grid>
            )
          }
          if (atendimento.venda.clientePacoteItem && !atendimento.venda.cancelado) {
            iconUtilizacaoPacote = (
              <Grid item align="right" className={classes.gridIcone} style={{ color: corCard.cardIcones }}>
                <Tooltip title="Crédito de Pacote">
                  <ShoppingBasketIcon className={classNames(classes.icon, classes.smallIcon)} />
                </Tooltip>
              </Grid>
            )
          }
          if (AtendimentoStatusEnum.MARCADO.id !== atendimento.status.id) {
            iconStatusAtendimento = (
              <Grid item align="right" className={classes.gridIcone} style={{ color: corCard.cardIcones }}>
                <AtendimentoStatusIcon status={atendimento.status} size="mini" hasTooltip={true} />
              </Grid>
            )
          }
          if (StatusVendaEnum.NOTA_PENDENTE.id !== atendimento.venda.status.id && !atendimento.venda.cancelado) {
            iconStatusNota = (
              <Grid item align="right" className={classes.gridIcone} style={{ color: corCard.cardIcones }}>
                <VendaStatusIcon status={atendimento.venda.status} size="mini" hasTooltip={true} />
              </Grid>
            )
          }

          if (rootProps.style.height <= 34) {
            paddingTop = 1
            linha1 = (
              <span>
                {formatarHorarioInt(atendimento.horarioInicio)} - <b>{atendimento.venda.cliente.apelido.split(' ')[0]}</b>, {descricaoServico}
              </span>
            )
          } else if (rootProps.style.height <= 52) {
            paddingTop = 2
            linha1 = (
              <span>
                {formatarHorarioInt(atendimento.horarioInicio)} - <b>{atendimento.venda.cliente.apelido}</b>
              </span>
            )
            linha2 = <div className={classes.descricaoServico}>{descricaoServico}</div>
          } else {
            linha1 = formatarHorarioInt(atendimento.horarioInicio) + ' - ' + formatarHorarioInt(atendimento.horarioTermino)
            linha2 = <div className={classNames(classes.singleLine, classes.nomeCliente)}>{atendimento.venda.cliente.apelido}</div>
            linha3 = <div className={classes.descricaoServico}>{descricaoServico}</div>
          }
        }

        if (itemInstancia && itemInstancia._isColunaAssistente) {
          cardLinhaLateralBackgroundProps = Object.assign(cardLinhaLateralBackgroundProps, corCard.cardLinhaLateralBackground.assistenteProps)
        } else {
          cardLinhaLateralBackgroundProps = Object.assign(cardLinhaLateralBackgroundProps, corCard.cardLinhaLateralBackground.principalProps)
        }
      }
    } else if (item.bloqueioHorario) {
      const bloqueioHorario = item.bloqueioHorario
      corCard = {
        cardBackground: '#dedede',
        cardIcones: '#666666',
        cardTexto: '#111111',
        cardLinhaLateralBackground: {
          principalProps: {
            backgroundColor: '#9c9c9c'
          }
        }
      }

      rootProps.style.backgroundColor = fade(corCard.cardBackground, 0.9)
      rootProps.style.color = corCard.cardTexto

      cardLinhaLateralBackgroundProps = Object.assign(cardLinhaLateralBackgroundProps, corCard.cardLinhaLateralBackground.principalProps)

      if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
        paddingTop = 5
        paddingBottom = 4
        rootProps.style.borderRadius = 3
        //rootProps.style.paddingLeft = 6;
        rootProps.style.marginTop = 0
        cardLinhaLateralBackgroundProps.width = 4
        linha1 = (
          <span>
            {formatarHorarioInt(bloqueioHorario.horarioInicio)} {bloqueioHorario.descricao}
          </span>
        )
      } else {
        if (!itemInstancia._coluna) {
          return []
        }

        this.adicionarPropriedadesPosicionamentoGridCard(item, rootProps)

        if (rootProps.style.height <= 34) {
          paddingTop = 1
          linha1 = (
            <span>
              {formatarHorarioInt(bloqueioHorario.horarioInicio)} - {bloqueioHorario.descricao}
            </span>
          )
        } else {
          if (rootProps.style.height <= 52) {
            paddingTop = 2
          }
          linha1 = formatarHorarioInt(bloqueioHorario.horarioInicio) + ' - ' + formatarHorarioInt(bloqueioHorario.horarioTermino)
          linha2 = <div className={classes.descricaoServico}>{bloqueioHorario.descricao}</div>
        }

        let IconComponent = BlockIcon
        if (bloqueioHorario.grupo && bloqueioHorario.grupo.id) {
          IconComponent = EventBusyIcon
        }

        iconStatusAtendimento = (
          <Grid item align="right" className={classes.gridIcone} style={{ color: corCard.cardIcones }}>
            <Tooltip title="Bloqueio de Horário">
              <IconComponent className={classNames(classes.icon, classes.smallIcon)} />
            </Tooltip>
          </Grid>
        )
      }
    }

    return (
      <div {...rootProps}>
        <div className={classes.linhaLateral} style={{ ...cardLinhaLateralBackgroundProps }}></div>
        <div {...containerProps}>
          <Grid
            className={classes.gridHeader}
            style={{ paddingTop: paddingTop, paddingBottom: paddingBottom }}
            container
            wrap="nowrap"
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs className={classNames(classes.singleLine, classes.gridHorario)}>
              {linha1}
            </Grid>
            {iconLembreteEnviado}
            {iconAgendamentoOnline}
            {iconUtilizacaoPacote}
            {iconStatusAtendimento}
            {iconStatusNota}
          </Grid>
          {linha2}
          {linha3}
        </div>
      </div>
    )
  }
}

ItemCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ItemCard)
