import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputSelect from 'support/components/input/InputSelect'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'

const styles = (theme) => ({})

class InputTipoVenda extends Component {
  render() {
    const { classes, descricaoTodos, ...others } = this.props

    return (
      <InputSelect
        {...others}
        displayEmpty={true}
        renderValue={(value) => {
          if (value) {
            const IconComponent = TipoVendaEnum.getById(parseInt(value, 10)).iconComponent
            return <IconComponent style={{ display: 'block', marginBottom: -2, marginTop: -3 }} />
          } else {
            return descricaoTodos ? descricaoTodos : 'Todos'
          }
        }}
      >
        <MenuItem key="todas" value="">
          Exibir Todos
        </MenuItem>
        {TipoVendaEnum.values().map((tipo) => (
          <MenuItem key={tipo.id} value={tipo.id}>
            <tipo.iconComponent style={{ display: 'block', marginBottom: -2, marginTop: -3, marginRight: 12 }} /> {tipo.descricao}
          </MenuItem>
        ))}
      </InputSelect>
    )
  }
}

InputTipoVenda.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputTipoVenda)
