import ReactDOM from 'react-dom'

import '@grupoboticario/flora-screenrecording-addon'
import { ThemeProvider } from '@material-ui/core/styles'
import { createDefaultTheme } from 'support/style/ThemeManager.js'

import App from './App'
import GlobalErrorHandler from './GlobalErrorHandler'
import './index.css'
import Initializer from './Initializer'

Initializer()
ReactDOM.render(
  <GlobalErrorHandler>
    <ThemeProvider theme={createDefaultTheme()}>
      <App />
    </ThemeProvider>
  </GlobalErrorHandler>,
  document.getElementById('root')
)

// @TODO - Remover REACT_APP_ENV, nunca entra nessa linha assim como serviceWorker
if ('serviceWorker' in navigator && process.env.REACT_APP_ENV === 'production') {
  navigator.serviceWorker.register('/sw.js').then(() => {
    // eslint-disable-next-line no-console
    console.log('Service Worker Registered')
  })
}
