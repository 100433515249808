import TipoCalculoEnum from 'support/domain/calculo/TipoCalculoEnum'
import { formatarValorMonetario, formatarValorPercentual } from 'support/util/NumberFormatter'

export function formatarValorCalculo(tipoCalculoComissao, valor) {
  if (valor !== null && valor !== undefined) {
    if (tipoCalculoComissao && tipoCalculoComissao.id === TipoCalculoEnum.REAL.id) {
      return formatarValorMonetario(valor)
    } else {
      return formatarValorPercentual(valor, { fixedDecimalScale: false })
    }
  }
  return ''
}
