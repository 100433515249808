import React, { Component } from 'react'
import { isAndroid, isChrome, isEdge, isMacOs, isWindows } from 'react-device-detect'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import BackableComponentNode from 'support/components/backablecomponentsmanager/BackableComponentNode'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import UrlUtil from 'support/util/UrlUtil'

const DELAY_REMOVE_COMPONENT = 500

const EVENT_NAME = 'BackableComponentsManager'
const URL_PARAMETER_NAME = 'bc'

const DIALOG_TYPE = 'dialog'
const POPUP_TYPE = 'popup'
const LIGHTBOX_TYPE = 'lightbox'
const UNMANAGED_TYPE = 'unmanaged'

const CLASS_NAME_PREFIX_VIEW_GROUP = 'S99_BCM_'

let nextOnCloseCallback = null

window.repeatHistoryBackDelay = 45
window.repeatHistoryBackTryCount = 10

export function openBackableDialog(backableComponent, props) {
  openBackableComponent(DIALOG_TYPE, backableComponent, props)
}

export function openBackablePopup(backableComponent, props) {
  openBackableComponent(POPUP_TYPE, backableComponent, props)
}

export function openBackableLightbox(backableComponent, props) {
  openBackableComponent(LIGHTBOX_TYPE, backableComponent, props)
}

export function openBackableUnmanaged(props) {
  openBackableComponent(UNMANAGED_TYPE, null, props)
}

function openBackableComponent(type, backableComponent, props) {
  if (props === undefined) {
    props = {}
  }
  props.type = type
  EventsManager.pub(EVENT_NAME, {
    action: 'open',
    backableComponent: backableComponent,
    props: props
  })
}

export function clearBackableComponents() {
  EventsManager.pub(EVENT_NAME, {
    action: 'clear'
  })
}

export function extractDialogProps(props) {
  const backableDialogProps = {}
  backableDialogProps['id'] = props.id
  backableDialogProps['open'] = props.open
  backableDialogProps['handleCloseDialog'] = props.handleCloseDialog
  backableDialogProps['disabledOnClose'] = props.disabledOnClose
  return backableDialogProps
}

export function extractPopupProps(props) {
  const backablePopupProps = {}
  backablePopupProps['id'] = props.id
  backablePopupProps['open'] = props.open
  backablePopupProps['anchorReference'] = props.anchorReference
  if (props.anchorReference === 'anchorEl') {
    backablePopupProps['anchorEl'] = props.anchorEl
  } else if (props.anchorReference === 'anchorPosition') {
    backablePopupProps['anchorPosition'] = props.anchorPosition
  }
  backablePopupProps['onClose'] = props.handleCloseDialog
  return backablePopupProps
}

export function extractLightboxProps(props) {
  const backableLightboxProps = {}
  backableLightboxProps['id'] = props.id
  backableLightboxProps['open'] = props.open
  backableLightboxProps['onClose'] = props.handleCloseDialog
  backableLightboxProps['viewGroupClassName'] = props.viewGroupClassName
  return backableLightboxProps
}

let openedComponents = 0

export function getOpenedComponents() {
  return openedComponents
}

const styles = (theme) => ({
  viewGroup: {
    position: 'relative',
    zIndex: 1300
  },
  showViewGroup: {
    opacity: '1 !important',
    visibility: 'visible'
  },
  hideViewGroup: {
    opacity: '0 !important',
    visibility: 'hidden',
    transition: 'visibility 0s 0.2s, opacity 0s 0.2s'
    /*"&:not(:last-child)":{
		},
		"&:not(:last-child) > *:last-child > *:nth-child(3)":{
		}*/
  }
})

class BackableComponentsManager extends Component {
  idComponentGenerator = 0
  urlComponents = []

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      components: []
    }
    this.setupSubscribes()
  }

  onPopStateFunction = (event) => {
    let urlComponents = this.getUrlComponentsFromUrl()
    if (urlComponents.length < this.urlComponents.length) {
      const componentsIdsToClose = this.urlComponents.filter(function (i) {
        return urlComponents.indexOf(i) < 0
      })
      if (componentsIdsToClose.length > 0) {
        const stateComponent = this.getStateComponentById(componentsIdsToClose[0]) // Em situações nao simuladas o stateComponent fica null.
        let historyBacks = 0
        if (stateComponent) {
          historyBacks = this.getHistoryBacks(0, stateComponent)
        }
        if (historyBacks < 0) {
          this.safeHistoryBack(historyBacks)
          //window.history.go(historyBacks);
        } else {
          this.closeComponent(componentsIdsToClose)
        }
      }
    }
  }

  componentDidMount() {
    window.history.replaceState(null, document.title, UrlUtil.removeQueryParamsFromUrl([URL_PARAMETER_NAME]))
    window.addEventListener('popstate', this.onPopStateFunction)
  }

  setupSubscribes() {
    this.eventsManager.sub(EVENT_NAME, (props) => {
      if (props.action === 'open') {
        this.openComponent(props.backableComponent, props.props)
      } else if (props.action === 'clear') {
        this.clearComponents()
      }
    })
  }

  getStateComponentById = (id) => {
    for (let i = 0; i < this.state.components.length; i++) {
      const component = this.state.components[i]
      if (component.props.id === id) {
        return component
      }
    }
    return null
  }

  getUrlComponentsFromUrl() {
    let urlComponents = []
    const urlComponentsParam = UrlUtil.getUrlQueryStringParameter(URL_PARAMETER_NAME)
    if (urlComponentsParam.length > 0) {
      urlComponents = urlComponentsParam.split(',')
    }
    if (urlComponents.length > 0) {
      for (let i = 0; i < urlComponents.length; i++) {
        urlComponents[i] = parseInt(urlComponents[i], 10)
      }
    }
    return urlComponents
  }

  openComponent = (backableComponent, props) => {
    if (props === undefined) {
      props = {}
    }

    const newComponent = {
      props: props,
      backableComponent: backableComponent
    }

    const newId = ++this.idComponentGenerator

    let urlComponents = this.getUrlComponentsFromUrl()
    urlComponents.push(newId)
    window.history.pushState(null, document.title, '?' + URL_PARAMETER_NAME + '=' + urlComponents.join(','))
    this.urlComponents = urlComponents

    props.setParent = (parent) => {
      props.parent = parent
    }

    props.setShouldCloseParent = (value) => {
      props.shouldCloseParent = value
    }

    props.getShouldCloseParent = () => {
      return props.shouldCloseParent
    }

    props.setCanCloseFunction = (canCloseFunction) => {
      props.canClose = canCloseFunction
    }

    props.setOnClosed = (onClosed) => {
      props.onClosed = onClosed
    }

    props.setCustomProps = (customProps) => {
      if (customProps) {
        for (var customPropsKey of Object.keys(customProps)) {
          props[customPropsKey] = customProps[customPropsKey]
        }
      }
    }

    props.id = newId
    props.closeFunctionExecuted = false

    const onCloseFunction = (localProps) => {
      if (props.canClose && !props.canClose()) {
        return
      }

      let topOpenedComponent = null

      for (let i = this.state.components.length - 1; i >= 0; i--) {
        const stateComponent = this.state.components[i]
        if (stateComponent.props.closeFunctionExecuted === false) {
          topOpenedComponent = stateComponent
          break
        }
      }

      if (!topOpenedComponent || topOpenedComponent.props.id !== props.id) {
        return
      }

      if (props.closeFunctionExecuted === false) {
        props.closeFunctionExecuted = true
        if (localProps && localProps.onClosedCallback) {
          nextOnCloseCallback = localProps.onClosedCallback
        }
        let historyBacks = this.getHistoryBacks(-1, newComponent)

        this.safeHistoryBack(historyBacks)
        //window.history.go(historyBacks);
      }
    }

    if (props.type === DIALOG_TYPE) {
      props.open = true
      props.handleCloseDialog = onCloseFunction
    } else if (props.type === POPUP_TYPE) {
      props.open = true
      if (props.anchorReference === 'anchorEl') {
        props.anchorEl = props.currentTarget
      } else if (props.anchorReference === 'anchorPosition') {
        props.anchorPosition = {
          left: props.clientX,
          top: props.clientY
        }
      }
      props.handleCloseDialog = onCloseFunction
    } else if (props.type === LIGHTBOX_TYPE) {
      props.open = true
      props.handleCloseDialog = onCloseFunction
    } else if (props.type === UNMANAGED_TYPE) {
      props.handleClose = onCloseFunction
    }

    let topOpenedComponentIndex = -1
    for (let i = this.state.components.length - 1; i >= 0; i--) {
      if (this.state.components[i].props.closeFunctionExecuted === false) {
        topOpenedComponentIndex = i
        break
      }
    }

    this.state.components.splice(topOpenedComponentIndex + 1, 0, newComponent)

    openedComponents++

    if (props.onCreateInstance) {
      props.onCreateInstance(newComponent)
    }

    if (props.type !== UNMANAGED_TYPE) {
      this.setState({ components: this.state.components })
    }
  }

  getHistoryBacks = (start, component) => {
    let historyBacks = start
    let child = component
    let parent = component.props.parent

    while (true) {
      if (parent && child.props.getShouldCloseParent()) {
        historyBacks = historyBacks - 1
        child = parent
        parent = child.props.parent
      } else {
        break
      }
    }
    return historyBacks
  }

  closeComponent = (componentsIdsToClose) => {
    let posicoesParaRemover = []
    for (let i = 0; i < this.state.components.length; i++) {
      const component = this.state.components[i]
      if (componentsIdsToClose.indexOf(component.props.id) >= 0) {
        component.props.closeFunctionExecuted = true
        posicoesParaRemover.push(i)
        if (component.props.type === DIALOG_TYPE) {
          component.props.open = false
        } else if (component.props.type === POPUP_TYPE) {
          component.props.open = false
        } else if (component.props.type === LIGHTBOX_TYPE) {
          component.props.open = false
        }
        component.props.closedDate = new Date().getTime()
        if (component.props.onClosed) {
          component.props.onClosed(component.props)
        }
      }
    }

    if (posicoesParaRemover.length > 0) {
      for (let componentIdToClose of componentsIdsToClose) {
        openedComponents--
        this.urlComponents.splice(this.urlComponents.indexOf(componentIdToClose), 1)
      }
      this.setState({ components: this.state.components })
      this.removeClosedComponents(DELAY_REMOVE_COMPONENT + 100)
      this.removeClosedComponents(DELAY_REMOVE_COMPONENT + 700)
    }

    if (nextOnCloseCallback) {
      nextOnCloseCallback()
      nextOnCloseCallback = null
    }
  }

  safeHistoryBack = (delta) => {
    if ((isWindows || isAndroid || isMacOs) && (isChrome || isEdge)) {
      console.log('safeHistoryBack', delta)
      this.safeHistoryBackRepeat(delta, 0, document.location.href)
    } else {
      window.history.go(delta)
    }
  }

  safeHistoryBackRepeat = (delta, tryCount, currentUrl) => {
    let url = document.location.href
    if (currentUrl === url && tryCount < window.repeatHistoryBackTryCount) {
      if (tryCount >= 1) {
        console.log('safeHistoryBackRepeat', tryCount, currentUrl, url)
      }
      tryCount = tryCount + 1
      window.history.go(delta)
      window.setTimeout(() => {
        this.safeHistoryBackRepeat(delta, tryCount, currentUrl)
      }, window.repeatHistoryBackDelay)
    }
  }

  clearComponents() {
    openedComponents = 0
    this.idComponentGenerator = 0
    this.urlComponents = []
    this.setState({ components: [] })
  }

  removeClosedComponents(delay) {
    window.setTimeout(() => {
      // enquanto nao estiverem todos fechados, manter as instancias para controlar corretamente os keys dos viewGroups
      if (this.state.components.filter((component) => !component.props.closedDate).length > 0) {
        this.forceUpdate()
        return
      }

      const components = []
      const agora = new Date().getTime()
      for (let i = 0; i < this.state.components.length; i++) {
        const component = this.state.components[i]
        if (!component.props.closedDate || agora - component.props.closedDate < DELAY_REMOVE_COMPONENT) {
          components.push(component)
        }
      }
      this.setState({ components: components })
    }, delay)
  }

  render() {
    const { classes } = this.props

    /*const componentNodes = [];
		if(this.state.components.length > 0){
			this.state.components.filter(component => component.backableComponent).forEach((component,index) => {
				//componentNodes.push(<component.backableComponent key={component.props.id} {...component.props} />);
				componentNodes.push(<BackableComponentNode component={component.backableComponent} key={component.props.id} {...component.props} />);
			});
		}*/

    const components = this.state.components.filter((component) => component.backableComponent)

    let viewGroups = []
    let viewGroup = null
    let lastViewGroupOpenedIndex = 0

    const agora = new Date().getTime()

    if (this.state.components.length > 0) {
      for (let component of components) {
        if (viewGroup == null || (component.props.type === DIALOG_TYPE && component.backableComponent.isDialogPage)) {
          viewGroup = {
            id: component.props.id,
            className: CLASS_NAME_PREFIX_VIEW_GROUP + component.props.id,
            items: []
          }
          viewGroups.push(viewGroup)
        }

        component.props.viewGroupClassName = viewGroup.className

        if (!component.props.closedDate || agora - component.props.closedDate < DELAY_REMOVE_COMPONENT) {
          viewGroup.items.push(<BackableComponentNode component={component.backableComponent} key={component.props.id} {...component.props} />)
        }

        if (component.props.open) {
          lastViewGroupOpenedIndex = viewGroups.length - 1
        }
      }
    }

    return (
      <React.Fragment>
        {/*componentNodes*/}
        {viewGroups.map((viewGroup, index) => {
          let classesArray = [classes.viewGroup, viewGroup.className]
          if (index !== lastViewGroupOpenedIndex) {
            classesArray.push(classes.hideViewGroup)
          } else {
            classesArray.push(classes.showViewGroup)
          }
          return (
            <div className={classNames(classesArray)} key={index}>
              {viewGroup.items}
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    window.removeEventListener('popstate', this.onPopStateFunction)
  }
}

BackableComponentsManager.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BackableComponentsManager)
