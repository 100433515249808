import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import TabContainer from 'support/components/tab/TabContainer'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class TabProdutoGeral extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false
    }
  }

  render() {
    const { produto } = this.props

    const informacaoNaoPreenchidaMasculino = 'Não preenchido'
    const informacaoNaoPreenchidaFeminino = 'Não preenchido'

    return (
      <TabContainer>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Quantidade por embalagem:</b> {produto.quantidadeEmbalagem ? produto.quantidadeEmbalagem : informacaoNaoPreenchidaFeminino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Unidade de medida:</b> {produto.unidadeMedidaEmbalagem ? produto.unidadeMedidaEmbalagem : informacaoNaoPreenchidaMasculino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Marca:</b> {produto.marca ? produto.marca.nome : informacaoNaoPreenchidaFeminino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Fornecedor:</b> {produto.fornecedor ? produto.fornecedor.nome : informacaoNaoPreenchidaMasculino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Código de Barras:</b> {produto.codigoBarras ? produto.codigoBarras : informacaoNaoPreenchidaMasculino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>SKU:</b> {produto.sku ? produto.sku : informacaoNaoPreenchidaMasculino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Estoque Mínimo:</b> {produto.estoqueMinimo ? produto.estoqueMinimo : informacaoNaoPreenchidaMasculino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Produto Arquivado:</b> {produto.arquivado ? 'Sim' : 'Não'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Preço de Compra:</b> {produto.precoCompraMaisRecente ? formatarValorMonetario(produto.precoCompraMaisRecente) : informacaoNaoPreenchidaMasculino}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <b>Preço de Venda:</b> {produto.valorVendaExterna ? formatarValorMonetario(produto.valorVendaExterna) : informacaoNaoPreenchidaMasculino}
            </Typography>
          </Grid>
          {produto.precoCompraMaisRecente && produto.estoqueAtualQuantidade > 0 && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <b>Valor em Estoque (Compra):</b> {formatarValorMonetario(produto.precoCompraMaisRecente * produto.estoqueAtualQuantidade)}
              </Typography>
            </Grid>
          )}
          {produto.valorVendaExterna && produto.estoqueAtualQuantidade > 0 && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <b>Valor em Estoque (Venda):</b> {formatarValorMonetario(produto.valorVendaExterna * produto.estoqueAtualQuantidade)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </TabContainer>
    )
  }
}

TabProdutoGeral.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabProdutoGeral)
