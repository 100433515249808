import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import InputCaixa from 'pages/erp/painel/caixa/input/InputCaixa'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ValeDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idVale ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      caixas: []
    }

    this.inputNome = React.createRef()
    this.inputValor = React.createRef()
    this.dataFunctionsMap = {}
    this.inputCaixaFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/colaboradores.buscarDadosParaCriarVale',
      params: {},
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const caixas = response.data.caixas
        this.setState((state) => ({
          caixas: caixas,
          paginaCarregada: true,
          toolbarActions: {
            actions: [
              {
                showOnlyMobile: true,
                label: 'Salvar',
                handleAction: () => this.salvar()
              }
            ]
          }
        }))
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirVale()
  }

  persistirVale = () => {
    postAPI({
      url: 'erp/colaboradores.criarVale',
      data: this.getDadosVale(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoColaborador')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formVale')
      }
    })
  }

  getDadosVale = () => {
    const dados = {}

    if (this.props.idVale) {
      dados.id = this.props.idVale
    }

    dados.idColaborador = this.props.idColaborador
    dados.nome = this.inputNome.current.value
    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.valor = FormUtil.convertStringToNumber(this.inputValor.current.value)
    dados.caixa = this.inputCaixaFunctionsMap.getCaixa()

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { caixas, errorsMap, ajaxing } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formVale" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                id="xnome"
                autoFocus={true}
                customVariant={'outlined-small'}
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Descrição do Vale"
                inputRef={this.inputNome}
              />

              <InputData
                disabled={ajaxing}
                marginTop={true}
                customVariant={'outlined-small'}
                shrink={false}
                functionsMap={this.dataFunctionsMap}
                keyboard={true}
                id="xdata"
                name="xdata"
                label="Data"
                errorMessage={errorsMap('data')}
                defaultValue={new Date()}
                onChange={(data) => {
                  this.inputCaixaFunctionsMap.setData(data ? moment(data) : null)
                }}
              />

              <InputText
                disabled={ajaxing}
                id="xvalor"
                name="xvalor"
                marginTop={true}
                customVariant={'outlined-small'}
                shrink={false}
                errorMessage={errorsMap('valor')}
                label="Valor"
                inputComponent={InputMoneyFormat}
                inputRef={this.inputValor}
                functionsMap={this.inputvalorFunctionsMap}
              />

              <InputCaixa
                marginTop={true}
                customVariant={'outlined-small'}
                shrink={false}
                disabled={ajaxing}
                data={moment()}
                colaboradorPreferencial={getSessaoPainel().colaborador}
                utilizarDefaultValue={true}
                caixas={caixas}
                errorsMap={errorsMap}
                descricaoData="data do vale"
                labelUtilizacaoCaixa="Pago com dinheiro do caixa. Esta opção faz com que essa despesa entre no fluxo de caixa como uma retirada de dinheiro do caixa."
                functionsMap={this.inputCaixaFunctionsMap}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ValeDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ValeDialogPage)
