/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react'

import { Dialog, DialogContent } from '@material-ui/core'

import { ConfirmBusiness } from './ConfirmBusiness'
import { useStyles } from './PocketDialot.styles'
import { ProcessStatus } from './ProcessStatus'
import { StatusPocket } from './StatusPocket'

type PocketDialogProps = {
  isOpen: boolean
  onOpen: () => void
}

export type PocketStatus = 'process' | 'confirm' | 'success' | 'loading'

export type DataBusiness = {
  negocioId: number
  nomeNegocio: string
  dataProcessamento: string
}

export type PocketProps = {
  onCloseDialog: () => void
  onStatusChange: (val: PocketStatus) => void
  onSetDataBusiness?: (val: DataBusiness) => void
  dataBusiness?: DataBusiness
}

export const PocketDialog = ({ isOpen, onOpen }: PocketDialogProps) => {
  const [status, setStatus] = useState<PocketStatus>('process')
  const [dataBusiness, setDataBusiness] = useState<DataBusiness>()
  const classes = useStyles()

  const handleCloseDialog = () => {
    onOpen()
    setStatus('process')
  }

  const renderComponent = useMemo(() => {
    const condition = {
      process: <ProcessStatus onCloseDialog={handleCloseDialog} onStatusChange={setStatus} onSetDataBusiness={setDataBusiness} />,
      confirm: <ConfirmBusiness dataBusiness={dataBusiness} onCloseDialog={handleCloseDialog} onStatusChange={setStatus} />,
      loading: <StatusPocket status={status} />,
      success: <StatusPocket status={status} />
    }
    return condition[status as keyof typeof condition]
  }, [status])

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} PaperProps={{ style: { width: '100%' } }}>
      <DialogContent className={classes.content}>{renderComponent}</DialogContent>
    </Dialog>
  )
}
