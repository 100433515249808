import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import InputCaixa from 'pages/erp/painel/caixa/input/InputCaixa'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat, InputNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class CategoriaProdutoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: 'Compra de Produto',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      produto: this.props.produto,
      caixas: []
    }

    this.inputQuantidade = React.createRef()
    this.inputPreco = React.createRef()
    this.inputCaixaFunctionsMap = {}
    this.dataFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/inventario.buscarDadosCompraProdutoParaPersistencia',
      params: {
        idCompra: this.props.idCompra
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        let compraProduto = this.state.compraProduto
        if (response.data.compraProduto) {
          compraProduto = response.data.compraProduto
        }
        this.setState({
          compraProduto: compraProduto,
          caixas: response.data.caixas
        })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirMovimentacao()
  }

  persistirMovimentacao = () => {
    postAPI({
      url: 'erp/inventario.persistirCompra',
      data: this.getDadosCompra(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoProduto')
        this.props.handleCloseDialog()
        EventsManager.pub('SnackBarSuccess', { open: true, message: 'Estoque atualizado com sucesso.' })
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formMovimentacao')
      }
    })
  }

  getDadosCompra = () => {
    const dados = {}
    dados.id = this.props.idCompra
    dados.produto = { id: this.state.produto.id }
    dados.quantidade = this.inputQuantidade.current.value

    dados.precoUnitario = FormUtil.convertStringToNumber(this.inputPreco.current.value)

    dados.data = this.dataFunctionsMap.getDataAsInt()
    dados.caixa = this.inputCaixaFunctionsMap.getCaixa()

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, caixas, compraProduto } = this.state
    const { data, precoUnitario, quantidade, despesa } = compraProduto ? compraProduto : {}
    const { nome } = this.state.produto ? this.state.produto : {}
    const { caixa } = despesa ? despesa : {}

    let dataAsMomentDefaultValue = moment()
    if (data) {
      dataAsMomentDefaultValue = converterDataIntParaMoment(data)
    }

    let utilizarCaixaDefaultValue = true
    if (compraProduto && !caixa) {
      utilizarCaixaDefaultValue = false
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formMovimentacao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Typography variant="h6" align="left">
                {nome}
              </Typography>

              <InputHorizontalContainer
                style={{ marginTop: 20 }}
                inputTexts={[
                  {
                    containerProps: { xs: 12, sm: 7 },
                    input: (
                      <InputText
                        id="xprecounitario"
                        disabled={this.state.ajaxing}
                        customVariant={'outlined-small'}
                        shrink={false}
                        defaultValue={precoUnitario}
                        errorMessage={errorsMap('precoUnitario')}
                        label="Preço de Compra Unitário (R$)"
                        inputComponent={InputMoneyFormat}
                        inputProps={{
                          minValue: 0,
                          maxValue: 999999
                        }}
                        inputRef={this.inputPreco}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 12, sm: 5 },
                    input: (
                      <InputText
                        id="xquantidade"
                        disabled={this.state.ajaxing}
                        customVariant={'outlined-small'}
                        shrink={false}
                        defaultValue={quantidade}
                        errorMessage={errorsMap('quantidade')}
                        label={'Qtd. de Itens'}
                        inputComponent={InputNumberFormat}
                        inputProps={{
                          minValue: 1,
                          maxValue: 999999
                        }}
                        inputRef={this.inputQuantidade}
                      />
                    )
                  }
                ]}
              />

              <InputData
                functionsMap={this.dataFunctionsMap}
                disabled={this.state.ajaxing}
                keyboard={true}
                id="xdata"
                name="xdata"
                customVariant={'outlined-small'}
                shrink={false}
                errorMessage={errorsMap('data')}
                marginTop={true}
                defaultValue={dataAsMomentDefaultValue}
                label={'Data da Compra'}
                onChange={(data) => {
                  this.inputCaixaFunctionsMap.setData(data ? moment(data) : null)
                }}
              />

              <InputCaixa
                marginTop={true}
                caixaDefaultValue={caixa}
                disabled={this.state.ajaxing}
                data={dataAsMomentDefaultValue}
                colaboradorPreferencial={getSessaoPainel().colaborador}
                utilizarDefaultValue={utilizarCaixaDefaultValue}
                caixas={caixas}
                errorsMap={errorsMap}
                customVariant={'outlined-small'}
                shrink={false}
                descricaoData="data da movimentação"
                labelUtilizacaoCaixa="Pago com dinheiro do caixa. Esta opção faz com que essa compra entre no fluxo de caixa como uma retirada de dinheiro do caixa."
                functionsMap={this.inputCaixaFunctionsMap}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

CategoriaProdutoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CategoriaProdutoDialogPage)
