import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import RelatorioDespesasDetalhadasDialogPage from 'pages/erp/painel/relatorio/RelatorioDespesasDetalhadasDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import TipoDespesaEnum from 'support/domain/despesa/TipoDespesaEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaDespesa: {
    flexGrow: 1
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaQuantidade: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaIcone: {
    maxWidth: 66,
    minWidth: 66
  },
  tituloInformacoesFinanceiras: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  }
})

class RelatorioDespesasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxing: false,
      despesasDataInicial: this.colaboradorLocalPreferences.getMomentPreference('RelatorioDespesasDialogPageFiltro_DataInicial', moment().startOf('month')),
      despesasDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioDespesasDialogPageFiltro_DataFinal', moment().endOf('month')),
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  abrirDetalhes = (item) => {
    openBackableDialog(RelatorioDespesasDetalhadasDialogPage, {
      tipo: item.tipo,
      categoria: item.categoria,
      nome: item.nome,
      dataInicial: this.state.despesasDataInicial,
      dataFinal: this.state.despesasDataFinal
    })
  }

  getRenderContent = (size, item) => {
    const content = {}
    if (size === 'small') {
      if (item.totalizador) {
        content.valor = (
          <span>
            <b>{formatarValorMonetario(item.valor, { prefix: true })}</b>
          </span>
        )
        content.quantidade = (
          <span>
            <b>Qtd.: {item.quantidade.toString()}</b>
          </span>
        )
      } else {
        content.valor = formatarValorMonetario(item.valor, { prefix: true })
        content.quantidade = 'Qtd.: ' + item.quantidade.toString()
      }
      content.abrirDetalhes = (
        <IconButton
          style={{ marginTop: -10, marginBottom: -6, padding: 8 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    } else {
      if (item.totalizador) {
        content.valor = (
          <span>
            <b>{formatarValorMonetario(item.valor, { prefix: false })}</b>
          </span>
        )
        content.quantidade = (
          <span>
            <b>{item.quantidade.toString()}</b>
          </span>
        )
      } else {
        content.valor = formatarValorMonetario(item.valor, { prefix: false })
        content.quantidade = item.quantidade.toString()
      }
      content.abrirDetalhes = (
        <IconButton
          style={{ marginRight: 0, padding: 6 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    }

    let descricao
    if (item.nome) {
      descricao = item.nome
    } else {
      descricao = TipoDespesaEnum.getById(item.tipo.id).descricao
    }

    if (item.totalizador) {
      content.descricao = (
        <span>
          <b>{descricao}</b>
        </span>
      )
    } else {
      content.descricao = descricao
    }

    return content
  }

  getRequestParameters = () => {
    return {
      dataInicial: converterMomentParaDataInt(this.state.despesasDataInicial),
      dataFinal: converterMomentParaDataInt(this.state.despesasDataFinal)
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, despesasDataInicial, despesasDataFinal } = this.state

    return (
      <DialogPage {...dialogProps} title="Despesas" align="center" contentMaxWidth={1200} pageType="basicEntity" ajaxing={ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Informações Gerais
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <DateRangeNavigator
                    expanded={false}
                    defaultValue={{
                      tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioDespesasDialogPageFiltro_TipoIntervalo', 'mes'),
                      dataInicial: despesasDataInicial,
                      dataFinal: despesasDataFinal
                    }}
                    onChange={(data) => {
                      this.colaboradorLocalPreferences.setPreference('RelatorioDespesasDialogPageFiltro_TipoIntervalo', data.tipoIntervalo.nome)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioDespesasDialogPageFiltro_DataInicial', data.dataInicial)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioDespesasDialogPageFiltro_DataFinal', data.dataFinal)
                      this.setState({
                        despesasDataInicial: data.dataInicial,
                        despesasDataFinal: data.dataFinal
                      })
                    }}
                  />
                </div>
              </div>
            </FloatContent>

            <VirtualizedResponsiveTable
              key={converterMomentParaDataInt(despesasDataInicial).toString() + '-' + converterMomentParaDataInt(despesasDataFinal).toString()}
              showBackgroundDividers={true}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarDespesasResumidasPorData"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const lista = []
                  let valorTotalDespesas = 0
                  let quantidadeTotalDespesas = 0

                  for (let item of data.items) {
                    lista.push(item)
                    valorTotalDespesas += item.valor
                    quantidadeTotalDespesas += item.quantidade
                  }

                  if (valorTotalDespesas > 0) {
                    lista.push({
                      nome: 'Todas Despesas',
                      valor: valorTotalDespesas,
                      quantidade: quantidadeTotalDespesas,
                      totalizador: true
                    })
                  }

                  this.setState({ lista: lista })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Categoria', className: classes.colunaCliente, props: { xs: true } },
                  { label: 'Quantidade', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                  { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)

                  return {
                    itemData: [content.descricao, content.quantidade, content.valor, content.abrirDetalhes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 88,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container style={{ minWidth: 0 }} alignItems="center">
                          <Grid item xs style={{ minWidth: 0 }}>
                            <Typography variant="body2" noWrap={true}>
                              <b>{content.descricao}</b>
                            </Typography>
                          </Grid>
                          <Grid item>{content.abrirDetalhes}</Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 4 }} alignItems="center">
                          <Grid item xs>
                            <Typography variant="body2" noWrap={true}>
                              {content.valor}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">{content.quantidade}</Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Não existem despesas para o período informado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioDespesasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioDespesasDialogPage)
