import { Button, Typography } from '@material-ui/core'
import ListIcon from '@material-ui/icons/ListAltRounded'
import GlobeIcon from '@material-ui/icons/Public'
import ShareIcon from '@material-ui/icons/ShareOutlined'

import OnlineSchedulingImage from './OnlineScheduling.svg'
import { useStyles } from './OnlineSchedulingLP.style'

type OnlineSchedulingLPProps = {
  onClick: () => null
}

export const OnlineSchedulingLP = ({ onClick }: OnlineSchedulingLPProps) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={OnlineSchedulingImage} alt="Pessoa segurando um celular enquanto no fundo mostra seus contatos em um globo" />
      </div>
      <Typography className={classes.header}>50% dos clientes agendam fora do horário comercial. Ative o Agendamento Online e expanda seu negócio!</Typography>

      <div className={classes.iconWithText}>
        <ShareIcon className={classes.icon} />
        <Typography className={classes.description}>Divulgue seu link personalizado nas redes sociais.</Typography>
      </div>
      <div className={classes.iconWithText}>
        <ListIcon className={classes.icon} />
        <Typography className={classes.description}>Personalize regras, serviços e profissionais de acordo com a sua necessidade.</Typography>
      </div>
      <div className={classes.iconWithText}>
        <GlobeIcon className={classes.icon} />
        <Typography className={classes.description}>Aumente a visibilidade do seu negócio com o nosso Portal de Agendamentos.</Typography>
      </div>

      <Button className={classes.button} onClick={onClick} variant="contained" disableElevation>
        Ativar agendamento online
      </Button>
    </div>
  )
}
