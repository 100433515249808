export function isValidDate(date) {
  if (date && Object.prototype.toString.call(date) === '[object Date]') {
    if (!isNaN(date.getTime())) {
      // d.valueOf() could also work
      return true
    }
  }
  return false
}

const functionsObject = {
  isValidDate: isValidDate
}

export default functionsObject
