import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PaymentIcon from '@material-ui/icons/Payment'
import PersonIcon from '@material-ui/icons/Person'
import RedoIcon from '@material-ui/icons/Redo'
import AtendimentoDialogPage from 'pages/erp/painel/atendimento/AtendimentoDialogPage'
import VendaProdutoDialogPage from 'pages/erp/painel/inventario/VendaProdutoDialogPage'
import VendaPacoteDialogPage from 'pages/erp/painel/pacotes/VendaPacoteDialogPage'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import BaseDialog from 'support/components/dialog/BaseDialog'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const styles = (theme) => ({
  optionContainer: {
    padding: '20px 24px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.03)'
    }
  }
})

class RedirecionadorFluxoPosCadastroVendaDialog extends Component {
  render() {
    const { classes } = this.props
    const dialogProps = extractDialogProps(this.props)
    const options = []

    if (this.props.tipoVenda.id === TipoVendaEnum.VENDA_PACOTE.id && this.props.fechamentoContaAutomatico) {
      options.push(
        {
          icon: PaymentIcon,
          label: 'Registrar Pagamento',
          onClick: () => {
            openBackableDialog(NotaDialogPage, {
              parent: this,
              shouldCloseParent: true,
              idNota: this.props.realizarPagamentoProps.nota.id,
              setFecharPanelCliente: this.props.setFecharPanelCliente
            })
          }
        },
        {
          icon: RedoIcon,
          label: 'Pagar Depois',
          onClick: () => {
            if (this.props.setFecharPanelCliente) {
              this.props.setFecharPanelCliente()
            }
            this.props.handleCloseDialog()
          }
        }
      )
    } else {
      let labelBotaoVenderMais = ''
      if (this.props.tipoVenda.id === TipoVendaEnum.ATENDIMENTO.id) {
        labelBotaoVenderMais = 'Adicionar Mais Serviços'
      } else if (this.props.tipoVenda.id === TipoVendaEnum.VENDA_PRODUTO.id) {
        labelBotaoVenderMais = 'Vender Mais'
      } else if (this.props.tipoVenda.id === TipoVendaEnum.VENDA_PACOTE.id) {
        labelBotaoVenderMais = 'Vender Mais'
      }

      options.push({
        icon: PersonIcon,
        label: 'Voltar para o cliente',
        onClick: () => {
          this.props.handleCloseDialog()
        }
      })

      if (this.props.venderMaisProps) {
        options.push({
          icon: LibraryAddIcon,
          label: labelBotaoVenderMais,
          onClick: () => {
            let dialogPage = null
            if (this.props.tipoVenda.id === TipoVendaEnum.ATENDIMENTO.id) {
              dialogPage = AtendimentoDialogPage
            } else if (this.props.tipoVenda.id === TipoVendaEnum.VENDA_PRODUTO.id) {
              dialogPage = VendaProdutoDialogPage
            } else if (this.props.tipoVenda.id === TipoVendaEnum.VENDA_PACOTE.id) {
              dialogPage = VendaPacoteDialogPage
            }

            openBackableDialog(dialogPage, {
              parent: this,
              shouldCloseParent: true,
              ...this.props.venderMaisProps,
              setFecharPanelCliente: this.props.setFecharPanelCliente
            })
          }
        })
      }

      options.push({
        icon: DoneOutlineIcon,
        label: 'Ok, terminei',
        onClick: () => {
          EventsManager.pub('ShowNPS', true)
          if (this.props.setFecharPanelCliente) {
            this.props.setFecharPanelCliente()
          }
          this.props.handleCloseDialog()
        }
      })
    }

    return (
      <BaseDialog {...dialogProps} disabledOnClose={true} hasButtons={false} rawContainer={true} maxWidth={400}>
        <div>
          <div style={{ padding: 24 }}>
            <Typography variant="body1">
              <b>{this.props.textoConfirmacaoCadastro}</b>
            </Typography>
          </div>

          {options.map((option, index) => (
            <React.Fragment key={index}>
              <Divider />
              <Grid container alignItems="center" className={classes.optionContainer} onClick={option.onClick}>
                <Grid item style={{ marginRight: 24 }}>
                  <option.icon style={{ display: 'block' }} />
                </Grid>
                <Grid item xs>
                  {option.label}
                </Grid>
                <Grid item style={{ marginLeft: 8 }}>
                  <NavigateNextIcon style={{ display: 'block' }} />
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </div>
      </BaseDialog>
    )
  }
}

RedirecionadorFluxoPosCadastroVendaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RedirecionadorFluxoPosCadastroVendaDialog)
