import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import AtivacaoFluxoCaixaEnum from 'support/domain/caixa/AtivacaoFluxoCaixaEnum'
import CaixaEventoTipoEnum from 'support/domain/caixa/CaixaEventoTipoEnum'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'

function isEventoDespesa(caixaEvento) {
  if (CaixaEventoTipoEnum.DESPESA.id === caixaEvento.tipo.id) {
    return true
  }
  return false
}

function isEventoPagamento(caixaEvento) {
  if (CaixaEventoTipoEnum.PAGAMENTO.id === caixaEvento.tipo.id) {
    return true
  }
  return false
}

function isEventoAberturaCaixa(caixaEvento) {
  if (CaixaEventoTipoEnum.ABERTURA.id === caixaEvento.tipo.id) {
    return true
  }
  return false
}

function isEventoReaberturaCaixa(caixaEvento) {
  if (CaixaEventoTipoEnum.REABERTURA.id === caixaEvento.tipo.id) {
    return true
  }
  return false
}

function isEventoFechamentoCaixa(caixaEvento) {
  if (CaixaEventoTipoEnum.FECHAMENTO.id === caixaEvento.tipo.id) {
    return true
  }
  return false
}

function isEventoEntrada(caixaEvento) {
  if (CaixaEventoTipoEnum.ENTRADA.id === caixaEvento.tipo.id) {
    return true
  }
  return false
}

function isEventoSangria(caixaEvento) {
  if (CaixaEventoTipoEnum.SANGRIA.id === caixaEvento.tipo.id) {
    return true
  }
  return false
}

function getValorEvento(caixaEvento) {
  if (isEventoDespesa(caixaEvento)) {
    return caixaEvento.despesa.valor * -1
  } else if (isEventoPagamento(caixaEvento)) {
    if (caixaEvento.pagamento.formaPagamentoEstorno && caixaEvento.pagamento.formaPagamentoEstorno.tipo.id === FormaPagamentoTipoEnum.CREDITO.id) {
      return caixaEvento.pagamento.valorPago
    }

    return caixaEvento.pagamento.valorPagoFinal
  } else if (isEventoSangria(caixaEvento)) {
    return caixaEvento.sangria.valor * -1
  } else if (isEventoEntrada(caixaEvento)) {
    return caixaEvento.entrada.valor
  } else if (isEventoAberturaCaixa(caixaEvento)) {
    return caixaEvento.caixa.fundoCaixa
  } else if (isEventoFechamentoCaixa(caixaEvento) || isEventoReaberturaCaixa(caixaEvento)) {
    return null
  }
}

function getNome(caixa, props) {
  if (!props) {
    props = {}
  }

  if (caixa.colaborador && caixa.colaborador.apelido) {
    let nome = ''
    if (props.prefix) {
      nome = 'Caixa: '
    }
    return nome + caixa.colaborador.apelido
  } else {
    return 'Caixa Geral'
  }
}

function getDescricao(caixaEvento) {
  if (isEventoDespesa(caixaEvento)) {
    return 'Despesa - ' + caixaEvento.despesa.nome
  } else if (isEventoPagamento(caixaEvento)) {
    return 'Pagamento registrado para a fatura #' + caixaEvento.pagamento.notaVenda.numero
  } else if (isEventoSangria(caixaEvento)) {
    return CaixaEventoTipoEnum.getById(caixaEvento.tipo.id).descricao + (caixaEvento.sangria.observacao ? ' - ' + caixaEvento.sangria.observacao : '')
  } else if (isEventoEntrada(caixaEvento)) {
    return CaixaEventoTipoEnum.getById(caixaEvento.tipo.id).descricao + (caixaEvento.entrada.observacao ? ' - ' + caixaEvento.entrada.observacao : '')
  } else {
    return CaixaEventoTipoEnum.getById(caixaEvento.tipo.id).descricao
  }
}

function isHabilitado() {
  const controleFluxoCaixa = AtivacaoFluxoCaixaEnum.getById(getSessaoPainel().negocio.configuracoesGerais.controleFluxoCaixa.id)
  if (controleFluxoCaixa.id === AtivacaoFluxoCaixaEnum.HABILITADO.id) {
    return true
  }
  return false
}

const functionsObject = {
  isHabilitado: isHabilitado,
  isEventoDespesa: isEventoDespesa,
  isEventoPagamento: isEventoPagamento,
  isEventoAberturaCaixa: isEventoAberturaCaixa,
  isEventoReaberturaCaixa: isEventoReaberturaCaixa,
  isEventoFechamentoCaixa: isEventoFechamentoCaixa,
  isEventoEntrada: isEventoEntrada,
  isEventoSangria: isEventoSangria,
  getValorEvento: getValorEvento,
  getNome: getNome,
  getDescricao: getDescricao
}

export default functionsObject
