import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  popoverPaper: {
    width: '100%',
    [theme.breakpoints.up(460)]: {
      maxWidth: 430
    }
  },
  rootContent: {
    padding: theme.spacing(3)
  },
  headerIconContainer: {
    marginTop: -10,
    marginRight: theme.spacing(-1.5),
    textAlign: 'right'
  },
  headerIconButton: {
    padding: 8
  },
  iconPopover: {
    fontSize: 20
  },
  iconButtonPopover: {
    color: 'rgba(0,0,0,0.65)'
  },
  bodyContent: {
    marginTop: 8
  }

  /*
	janelaContainer:{
		zIndex: 1300,
		position:"fixed",
		top:0, left:0, bottom: 0, right: 0,
		background:"rgb(0,0,0,0.5)",
		transformOrigin: "10px 10px",
		transition: "opacity 288ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 192ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
	},
	janelaContent:{
		width:360,
		height: 500,
		margin:10,
		padding: 0,
		background:"rgb(240,240,240)"
	}*/
})

class BasePopover extends Component {
  render() {
    const { classes, topIconButtons, body, popoverStyle, style, ...others } = this.props

    /*if(false){
			return (
				<Backdrop open={this.props.open} onClick={this.handleCloseDialog} style={{zIndex: 1300}}>
					<div className={classes.janelaContainer} style={{display: this.props.open?"block":"none"}}  onClick={event => {event.stopPropagation()}}>
						<div className={classes.janelaContent}>
							<div style={style}>
								<div className={classes.rootContent}>
									<div className={classes.headerIconContainer}>
										{
											topIconButtons.filter(button => button.show === undefined || button.show).map((button,index) => {

												const iconButton = (
													<IconButton
														key={index}
														classes={{root: classes.iconButtonPopover, disabled: classes.siconPopoverDisabled}}
														{...button.props}
														className={classes.headerIconButton}
														onClick={button.onClick}
														disabled={button.disabled}
													>
														<button.icon className={classes.iconPopover} {...button.iconProps} />
													</IconButton>
												);

												if(!button.tooltip || button.disabled || (button.props && button.props.disabled)){
													return iconButton;
												}else{
													return (
														<Tooltip key={index} title={button.tooltip}>
															{iconButton}
														</Tooltip>
													);
												}
											})
										}
										<IconButton
											className={classes.headerIconButton}
											onClick={() => {
												this.props.onClose();
											}}
										>
											<CloseIcon className={classes.iconPopover} />
										</IconButton>
									</div>
									<div className={classes.bodyContent}>
										{body}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Backdrop>

			)
		}*/

    return (
      <Popover classes={{ paper: classes.popoverPaper }} {...others} disablePortal={true}>
        <div style={style}>
          <div className={classes.rootContent}>
            <div className={classes.headerIconContainer}>
              {topIconButtons
                .filter((button) => button.show === undefined || button.show)
                .map((button, index) => {
                  const iconButton = (
                    <IconButton
                      key={index}
                      classes={{ root: classes.iconButtonPopover, disabled: classes.siconPopoverDisabled }}
                      {...button.props}
                      className={classes.headerIconButton}
                      onClick={button.onClick}
                      disabled={button.disabled}
                    >
                      <button.icon className={classes.iconPopover} {...button.iconProps} />
                    </IconButton>
                  )

                  if (!button.tooltip || button.disabled || (button.props && button.props.disabled)) {
                    return iconButton
                  } else {
                    return (
                      <Tooltip key={index} title={button.tooltip}>
                        {iconButton}
                      </Tooltip>
                    )
                  }
                })}
              <IconButton
                className={classes.headerIconButton}
                onClick={() => {
                  this.props.onClose()
                }}
              >
                <CloseIcon className={classes.iconPopover} />
              </IconButton>
            </div>
            <div className={classes.bodyContent}>{body}</div>
          </div>
        </div>
      </Popover>
    )
  }
}

BasePopover.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BasePopover)
