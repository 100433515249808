import { useCallback, useEffect, useMemo, useState } from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Link from '@material-ui/core/Link'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import DiscountCoupon from 'pages/erp/painel/plano/discountcoupon/DiscountCoupon'
import { DiscountCouponResponse } from 'pages/erp/painel/plano/discountcoupon/types'
import { getPeriodName } from 'support/domain/cupons/utils'
import { HiddenSubmitButton } from 'support/util/FormUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

import PagamentoPeriodoOption from './PagamentoPeriodoOption'
import { CriacaoPagamentoPeriodoProps, ListPeriodsItem, PagamentoPeriodoPlanoEnumType } from './types'

const PagamentoPeriodoPlanoEnum: PagamentoPeriodoPlanoEnumType = require('support/domain/negocio/PagamentoPeriodoPlanoEnum').default

const PERCENTAGE = 100

const CriacaoPagamentoPeriodo = ({
  negocio,
  precosPlano,
  pagamentoPeriod,
  setPagamentoPeriodo,
  handleSubmit,
  ajaxing,
  termosLicenciamentoLinkProps,
  setCoupon
}: CriacaoPagamentoPeriodoProps) => {
  const [validDiscountCoupon, setValidDiscountCoupon] = useState<DiscountCouponResponse>()

  const periodos = useMemo<ListPeriodsItem[]>(
    () => [
      {
        period: PagamentoPeriodoPlanoEnum.MENSAL.descricao,
        title: PagamentoPeriodoPlanoEnum.MENSAL.descricao,
        description: 'Um mês de acesso ao sistema.',
        price: precosPlano.precoMensal
      },
      {
        period: PagamentoPeriodoPlanoEnum.TRIMESTRAL.descricao,
        title: `Trimestral ${precosPlano.percentualDescontoTrimestral ? '(' + precosPlano.percentualDescontoTrimestral + '% OFF)' : ''}`,
        subtitle: `(Economize ${formatarValorMonetario(
          precosPlano.precoMensal * ((precosPlano.percentualDescontoTrimestral / PERCENTAGE) * PagamentoPeriodoPlanoEnum.TRIMESTRAL.quantidadeMeses)
        )})`,
        description: 'Três meses de acesso ao sistema.',
        price: precosPlano.precoTrimestral
      },
      {
        period: PagamentoPeriodoPlanoEnum.SEMESTRAL.descricao,
        title: `Semestral ${precosPlano.percentualDescontoSemestral ? '(' + precosPlano.percentualDescontoSemestral + '% OFF)' : ''}`,
        subtitle: `(Economize ${formatarValorMonetario(
          precosPlano.precoMensal * ((precosPlano.percentualDescontoSemestral / PERCENTAGE) * PagamentoPeriodoPlanoEnum.SEMESTRAL.quantidadeMeses)
        )})`,
        description: 'Seis meses de acesso ao sistema.',
        price: precosPlano.precoSemestral
      },
      {
        period: PagamentoPeriodoPlanoEnum.ANUAL.descricao,
        title: `Anual ${precosPlano.percentualDescontoAnual ? '(' + precosPlano.percentualDescontoAnual + '% OFF)' : ''}`,
        subtitle: `(Economize ${formatarValorMonetario(
          precosPlano.precoMensal * ((precosPlano.percentualDescontoAnual / PERCENTAGE) * PagamentoPeriodoPlanoEnum.ANUAL.quantidadeMeses)
        )})`,
        description: 'Um ano de acesso ao sistema.',
        price: precosPlano.precoAnual
      }
    ],
    [precosPlano]
  )

  const listPeriodos = useMemo<ListPeriodsItem[]>(
    () =>
      validDiscountCoupon
        ? [
            {
              period: getPeriodName(validDiscountCoupon.period),
              title: validDiscountCoupon.title,
              description: validDiscountCoupon.description,
              price: validDiscountCoupon.price,
              subtitle: undefined
            }
          ]
        : periodos,
    [periodos, validDiscountCoupon]
  )

  const ITEM_MARGIN_TOP = 16

  const [selected, setSelected] = useState(pagamentoPeriod)

  const handleChange = useCallback(
    (selected: string) => {
      setPagamentoPeriodo(selected)
      setSelected(selected)
    },
    [setPagamentoPeriodo]
  )

  useEffect(() => {
    setSelected(listPeriodos[0].period)
  }, [validDiscountCoupon, listPeriodos])

  return (
    <>
      <form data-testid="form-submit" id="formCriacaoPagamentoPlano" autoComplete="off" noValidate onSubmit={(event) => handleSubmit(event)}>
        <Typography variant="h6">Opções de Pagamento</Typography>
        <Typography variant="body2" style={{ marginBottom: 24 }}>
          Escolha a opção de pagamento:
        </Typography>

        <RadioGroup data-testid="pagamento-periodo-group" name="xpagamentoPeriodo" id="xpagamentoPeriodo" value={selected} onChange={(event, value: string) => handleChange(value)}>
          {listPeriodos.map((period: ListPeriodsItem, index: number) => (
            <FormControlLabel
              data-testid={`pagamento-periodo-label-${period.period}`}
              key={index}
              style={{ marginTop: index > 0 ? ITEM_MARGIN_TOP : 0, marginRight: 4 }}
              control={<Radio />}
              value={period.period}
              disabled={ajaxing}
              label={<PagamentoPeriodoOption period={period.period} title={period.title} description={period.description} price={period.price} subtitle={period.subtitle} />}
            />
          ))}
        </RadioGroup>

        <Typography variant="body2" style={{ marginTop: 28, marginBottom: 30 }} data-testid="termos-de-licenciamento">
          Sujeito aos{' '}
          <Link underline="always" {...termosLicenciamentoLinkProps} rel="noopener noreferrer">
            <b>Termos de Licenciamento</b>
          </Link>
        </Typography>

        <DiscountCoupon
          negocio={negocio}
          selectedType={selected}
          setValidDiscountCoupon={({ coupon, validCoupon }: { coupon?: string; validCoupon?: DiscountCouponResponse }) => {
            setValidDiscountCoupon(validCoupon)
            setCoupon(coupon)
          }}
        />

        <HiddenSubmitButton />
      </form>
    </>
  )
}

export default CriacaoPagamentoPeriodo
