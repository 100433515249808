import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import BandeiraDialogPage from 'pages/erp/painel/formapagamento/BandeiraDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class BandeiraInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    const creationFunction = (props) => {
      openBackableDialog(BandeiraDialogPage, {
        nome: props.text
      })
    }

    if (!props.label) props.label = 'Bandeira'
    if (!props.id) props.id = 'xbandeira'
    if (!props.name) props.name = 'xbandeira'

    if (props.defaultValue !== undefined) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.creationFunction = creationFunction
    props.DialogSelectProps.creationPermission = verificarAutorizacao([PA.PODE_ACESSAR_DESPESA])
    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Bandeira...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'erp/formaspagamento.buscarBandeiras'
    props.DialogSelectProps.searchParamName = 'nome'
    props.DialogSelectProps.generateItemData = (bandeira) => {
      return {
        value: bandeira.id,
        label: bandeira.nome,
        primary: bandeira.nome
      }
    }

    props.DialogSelectProps.eventsNameToRefreshResults = ['ManutencaoBandeira']

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhuma bandeira foi encontrada',
        textButton: 'Cadastrar bandeira',
        creationFunction: creationFunction
      }
    }

    return <InputDialogSelect {...props} />
  }
}

BandeiraInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BandeiraInputDialogSelect)
