import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NegocioDadosBasicosDialogPage from 'pages/erp/painel/negocio/NegocioDadosBasicosDialogPage'
import NegocioPhotoPicker from 'pages/erp/painel/negocio/picker/NegocioPhotoPicker'
import TabFotos from 'pages/erp/painel/negocio/TabFotos'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import Subject from 'support/components/page/subject/Subject'
import Tab from 'support/components/tab/Tab'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import NegocioFotoTipoEnum from 'support/domain/negocio/NegocioFotoTipoEnum'
import { getDescricaoResumidaDiaSemanaPorNumero } from 'support/util/DateFormatter'
import ImageUtil from 'support/util/ImageUtil'
import { formatarEndereco } from 'support/util/TextFormatter'

const MAX_WIDTH_PAPER_DEFAULT = 760

const styles = (theme) => ({})

class NegocioDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
    this.cropperRef = React.createRef()

    this.state = {
      negocio: null,
      title: 'Perfil do Negócio',
      tabValue: 0,
      ajaxing: false
    }

    this.functionMapUpload = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManutencaoNegocio', (props) => {
      if (props !== undefined && props.negocio !== undefined) {
        this.setState({ negocio: props.negocio })
      } else {
        this.contentWithPreloadFunctions.reload()
      }
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/negocios.buscarPorId',
      params: {
        id: this.props.idNegocio
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const toolbarActions = {
          desktopItemsShow: 1,
          mobileItemsShow: 1,
          actions: [
            {
              label: 'Editar',
              handleAction: this.handlerEditar
            }
          ]
        }

        this.setState({
          negocio: response.data,
          toolbarActions: toolbarActions
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handlerEditar = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }
    openBackableDialog(NegocioDadosBasicosDialogPage, { idNegocio: this.state.negocio.id })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { negocio, tabValue } = this.state

    let telefones = ''
    let endereco = ''
    let atendimento = ''

    /*if(negocio !== null && negocio.usuario !== undefined && negocio.usuario.email.length > 0){
			emailUsuario = {
				type : "text",
				wordBreakAll: true,
				text : negocio.usuario.email
			};
		}else{
			emailUsuario = <span style={{color:"#999"}}>E-mail não cadastrado</span>;
		}*/

    let variacoesFoto = null
    if (negocio) {
      variacoesFoto = ImageUtil.extrairVariacoesImagem(negocio.foto)
      if (negocio.telefones && negocio.telefones.length > 0) {
        for (let telefone of negocio.telefones) {
          if (telefones.length > 0) {
            telefones = telefones + ', '
          }
          telefones = telefones + telefone.numero
        }
      }
      endereco = formatarEndereco(negocio.endereco, { format: 'simplificado' })
      if (negocio.horariosAtendimento && negocio.horariosAtendimento.length > 0) {
        const dias = []
        for (let horarioAtendimento of negocio.horariosAtendimento) {
          if (dias.indexOf(horarioAtendimento.dia) < 0) {
            dias.push(horarioAtendimento.dia)
          }
        }
        dias.sort(function (a, b) {
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })
        for (let dia of dias) {
          if (atendimento.length > 0) {
            atendimento = atendimento + ', '
          }
          atendimento = atendimento + getDescricaoResumidaDiaSemanaPorNumero(dia)
        }
      }
    }

    if (telefones) {
      telefones = (
        <Typography variant="body2" noWrap={true}>
          {telefones}
        </Typography>
      )
    }
    if (endereco) {
      endereco = (
        <Typography variant="body2" noWrap={true}>
          {endereco}
        </Typography>
      )
    }
    if (atendimento) {
      atendimento = (
        <Typography variant="body2" noWrap={true}>
          {atendimento}
        </Typography>
      )
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={MAX_WIDTH_PAPER_DEFAULT}
        pageType="basicEntity"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
        onPreClose={() => {
          EventsManager.pub('TabSaveSnackBar', { open: false })
        }}
      >
        {(dialogContentProps) => (
          <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadContentPadding={true} loadFunction={this.carregarMain}>
            {() => (
              <div>
                <Subject
                  photo={
                    <NegocioPhotoPicker negocio={negocio} photo1x={variacoesFoto.get(0)} photo2x={variacoesFoto.get(0)} framedImage={true} width={148} photoContextLabel="Logo" />
                  }
                  title={negocio.nome}
                  infos={[telefones, endereco, atendimento]}
                />

                <Tab
                  value={tabValue}
                  onChange={(event, value) => {
                    EventsManager.pub('TabSaveSnackBar', { open: false })
                    this.setState({ tabValue: value })
                  }}
                  tabs={[
                    { label: 'Apresentação', labelXs: 'Apresentação' },
                    { label: 'Portfólio', labelXs: 'Portfólio' }
                  ]}
                ></Tab>
                <div>
                  {tabValue === 0 && (
                    <TabFotos scrollElement={dialogContentProps.scrollContainerRef.current} negocio={this.state.negocio} tipo={NegocioFotoTipoEnum.APRESENTACAO_NEGOCIO} />
                  )}
                  {tabValue === 1 && <TabFotos scrollElement={dialogContentProps.scrollContainerRef.current} negocio={this.state.negocio} tipo={NegocioFotoTipoEnum.PORTFOLIO} />}
                </div>
              </div>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

NegocioDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NegocioDialogPage)
