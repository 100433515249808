import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import classNames from 'classnames'
import moment from 'moment'
import { VisaoEnum } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import { COLUNA_ITENS_MIN_DEFAULT_WIDTH, COLUNA_ITENS_MIN_WIDTH, PIXELS_POR_MINUTO_BASE } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import AgendaItensLoader from 'pages/erp/painel/atendimento/agendainterface/AgendaItensLoader'
import ItemCard from 'pages/erp/painel/atendimento/agendainterface/ItemCard'
import MarcadoresHorariosIndisponiveis from 'pages/erp/painel/atendimento/agendainterface/MarcadoresHorariosIndisponiveis'
import AgendaColunaItensScrollbars from 'pages/erp/painel/atendimento/agendainterface/scrollbars/AgendaColunaItensScrollbars'
import AgendaItensScrollbars from 'pages/erp/painel/atendimento/agendainterface/scrollbars/AgendaItensScrollbars'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import BloqueioHorarioDialogPage from 'pages/erp/painel/colaborador/BloqueioHorarioDialogPage'
import ColaboradorDialogPage from 'pages/erp/painel/colaborador/ColaboradorDialogPage'
import ColaboradorPhotoPicker from 'pages/erp/painel/colaborador/picker/ColaboradorPhotoPicker'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractPopupProps, openBackableDialog, openBackablePopup } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import AtendimentoStatusIcon from 'support/components/icon/AtendimentoStatus'
import VendaStatusIcon from 'support/components/icon/VendaStatus'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import StatusServicoEnum from 'support/domain/atendimento/AtendimentoStatusServicoEnum'
import AtendimentoTipoEnum from 'support/domain/atendimento/AtendimentoTipoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import ColorSchema from 'support/domain/color/ColorSchema'
import StatusVendaEnum from 'support/domain/venda/StatusVendaEnum'
import BackgroundUtil from 'support/util/BackgroundUtil'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import ImageUtil from 'support/util/ImageUtil'
import { formatarNome } from 'support/util/TextFormatter'
import { converterHorarioIntParaMinutos, converterMinutosParaHorarioInt, converterMomentParaHorarioInt } from 'support/util/TimeConverter'
import { formatarHorarioMinutos } from 'support/util/TimeFormatter'

const HEIGHT_GUIA_ATENDIMENTOS = 30

const BORDER_COLOR = '#ececec'

const styles = (theme) => ({
  containerHorarios: {
    //backgroundColor: "#f9f9f9",
    //borderRight: "1px solid "+BORDER_COLOR,
    position: 'relative',
    overflow: 'hidden',
    paddingTop: 0,
    flexBasis: HORARIOS_WIDTH
  },
  containerHorario: {
    fontSize: 12,
    paddingLeft: '7px',
    height: PIXELS_POR_MINUTO_BASE * 60,
    color: '#333'
  },
  indicadoresGridHorizontal: {
    borderTop: '1px solid ' + BORDER_COLOR,
    height: (PIXELS_POR_MINUTO_BASE * 60) / 2 - 1,
    width: '100%'
  },
  indicadoresGridHorizontalFraco: {
    borderTop: '1px solid ' + BORDER_COLOR
  },
  indicadoresGridHorizontalUltimo: {
    borderBottom: '1px solid ' + BORDER_COLOR
  },
  containerAgendamentos: {
    display: 'flex',
    flexGrow: 9999,
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative'
  },
  containerAgendamentosHeader: {
    //backgroundColor: "#f9f9f9",
    display: 'flex',
    width: '100%'
  },
  containerColunaItensHeader: {
    //borderRight: "1px solid "+BORDER_COLOR,
    height: '100%'
  },
  containerColunaItensHeaderProfissional: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f6f6f6'
    }
  },
  tituloHeaderColuna: {
    marginLeft: 10,
    textAlign: 'left',
    whiteSpace: 'normal',
    ...theme.text.limitedLines({ maxLines: 3, withEllipsis: false })
  },
  diaSemanaAtualHeaderColuna: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 58
  },
  subtituloHeaderColuna: {
    fontSize: 12,
    color: '#aaa',
    fontWeight: 400
  },
  containerColunaItens: {
    //borderRight: "1px solid "+BORDER_COLOR,
    borderRight: '1px solid #e2e2e2',
    zIndex: 1
  },
  botaoNavScrollColunaItens: {
    position: 'absolute',
    zIndex: 10,
    marginTop: 5,
    opacity: 0,
    visibility: 'hidden',
    backgroundColor: '#fff !important',
    '$containerAgendamentosHeader:hover &': {
      opacity: 0.6
    },
    '$containerAgendamentosHeader:hover &:hover': {
      opacity: 0.92
    }
  },
  botaoNavScrollColunaItensVisivel: {
    [theme.breakpoints.up('sm')]: {
      visibility: 'visible'
    }
  },
  iconeNavScrollColunaItens: {
    color: '#666',
    fontSize: 32
  },
  innerContainerColunaItensHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 16px',
    textAlign: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 8px'
    }
  },
  containerColunaItensAgendamentos: {
    textAlign: 'center',
    padding: '0px 24px'
  },
  guiaGridAdicionar: {
    position: 'absolute',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: 'rgba(245,245,245,0.9)',
    height: HEIGHT_GUIA_ATENDIMENTOS - 7,
    lineHeight: HEIGHT_GUIA_ATENDIMENTOS - 7 + 'px',
    border: '2px dashed ' + theme.palette.primaryStrong.main,
    borderRadius: 8,
    color: theme.palette.primaryStrong.main,
    fontSize: 12,
    zIndex: 3
  },
  containerGuiaHoraAtual: {
    display: 'none',
    position: 'absolute',
    width: 'calc(100% - 17px)'
  },
  guiaHoraAtualBase: {
    pointerEvents: 'none',
    borderRadius: 10,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primaryStrong.main,
    position: 'absolute',
    zIndex: 50,
    left: -5
  },
  guiaHoraAtualTraco: {
    zIndex: 50,
    pointerEvents: 'none',
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.primaryStrong.main,
    position: 'absolute',
    left: 0,
    top: 4
  },
  botaoManipulacaoBarraFerramentasEsquerda: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  singleLine: theme.text.singleLine()
})

const DIA_HEADER_HEIGHT = 60
const SEMANA_HEADER_HEIGHT = 88
const HORARIOS_DIFF_HEIGHT = 7
const HORARIOS_WIDTH = 45

class AgendaGridAtendimento extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)

    this.containerGridAtendimentos = React.createRef()
    this.guiaGridAdicionarRef = React.createRef()
    this.containerGuiaHoraAtualRef = React.createRef()

    this.agendaColunaItensScrollbarsFM = {}
    this.agendaItensScrollbarsFM = {}
    this.containerHorariosRef = React.createRef()
    this.contextoOrigemScroll = null
    this.ajustarVisibilidadeBotoesInterval = null

    this.ajustarAutoWidthColunasItensInterval = null
    this.ultimoContainerWidthColunas = 0

    this.state = {
      exibirBotaoScrollEsquerda: true,
      exibirBotaoScrollDireita: true
    }
  }

  componentDidMount() {
    this.ajustarVisibilidadeBotoesInterval = window.setInterval(() => {
      this.ajustarVisibilidadeBotoesScroll()
    }, 1000)
    this.ajustarAutoWidthColunasItensInterval = window.setInterval(() => {
      this.ajustarAutoWidthColunasItens()
    }, 200)
    this.eventsManager.sub('AgendaInterfacePosicionarHorario', (props) => {
      const intervaloHorarioDisponivel = this.extrairIntervaloHorarioDisponivel(1000, 1800)
      const agora = moment()
      const hojeAsInt = converterMomentParaDataInt(agora)
      const horarioAgoraAsInt = converterMomentParaHorarioInt(agora)

      if (
        this.props.perspectiva.id === PerspectivaEnum.DIA.id &&
        hojeAsInt === converterMomentParaDataInt(this.props.dataSelecionada) &&
        horarioAgoraAsInt >= intervaloHorarioDisponivel.de &&
        horarioAgoraAsInt <= intervaloHorarioDisponivel.ate
      ) {
        this.posicionarHorarioGrid(horarioAgoraAsInt)
      } else {
        this.posicionarHorarioGrid(intervaloHorarioDisponivel.de)
      }
    })
    this.eventsManager.sub('AjustarAutoWidthColunasItens', (props) => {
      this.ajustarAutoWidthColunasItens(props.force)
    })
    this.posicionarGuiaDataAtual()
    this.posicionamentoGuiaDataAtualInterval = window.setInterval(() => {
      this.posicionarGuiaDataAtual()
    }, 1000)

    //this.agendaItensScrollbarsFM.getScrollRef().scrollTop(this.colaboradorLocalPreferences.getPreference("AgendaInterface_GridScrollY",0))
    EventsManager.pub('AgendaInterfacePosicionarHorario')
  }

  extrairIntervaloHorarioDisponivel = (deDefault, ateDefault) => {
    let de = null
    let ate = null

    let horariosAtendimento = []

    if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      for (let colaborador of this.props.colaboradores) {
        if (this.props.colaboradorSelecionado.id === colaborador.id) {
          if (colaborador.horariosAtendimento) {
            horariosAtendimento = horariosAtendimento.concat(colaborador.horariosAtendimento)
          }
          break
        }
      }
    } else if (this.props.perspectiva.id === PerspectivaEnum.DIA.id) {
      //const diaSemana = parseInt(this.props.dataSelecionada.format("d"),10);
      const diaSemana = this.props.dataSelecionada.isoWeekday()
      for (let colaborador of this.props.colaboradores) {
        if (colaborador._show && colaborador.horariosAtendimento) {
          for (let horarioAtendimento of colaborador.horariosAtendimento) {
            if (horarioAtendimento.dia === diaSemana) {
              horariosAtendimento.push(horarioAtendimento)
            }
          }
        }
      }
    }

    for (let horarioAtendimento of horariosAtendimento) {
      if (de === null || horarioAtendimento.de < de) {
        de = horarioAtendimento.de
      }
      if (ate === null || horarioAtendimento.ate > ate) {
        ate = horarioAtendimento.ate
      }
    }

    if (de === null) {
      de = deDefault ? deDefault : 0
    }

    if (ate === null) {
      ate = ateDefault ? ateDefault : 2400
    }

    return {
      de: de,
      ate: ate
    }
  }

  posicionarGuiaDataAtual = (dataSelecionada) => {
    if (this.containerGuiaHoraAtualRef.current) {
      if (!dataSelecionada) {
        dataSelecionada = this.props.dataSelecionada
      }
      if (dataSelecionada && dataSelecionada.isSame(moment(), 'day')) {
        const agora = new Date()
        let horaAtualEmMinutos = agora.getHours() * 60 + agora.getMinutes()
        this.containerGuiaHoraAtualRef.current.style.top = horaAtualEmMinutos * this.props.pixelsPorMinuto - 5 + 'px'
        this.containerGuiaHoraAtualRef.current.style.display = 'block'
      } else {
        this.containerGuiaHoraAtualRef.current.style.display = 'none'
      }
    }
  }

  posicionarHorarioGrid = (horarioInt) => {
    const scrollRef = this.agendaItensScrollbarsFM.getScrollRef()
    let top = this.props.pixelsPorMinuto * converterHorarioIntParaMinutos(horarioInt) - 10

    if (this.containerGridAtendimentos && this.containerGridAtendimentos.current) {
      const styleContainerGridAtendimentos = this.containerGridAtendimentos.current.currentStyle || window.getComputedStyle(this.containerGridAtendimentos.current)
      if (styleContainerGridAtendimentos.marginTop) {
        top += parseInt(styleContainerGridAtendimentos.marginTop)
      }
    }

    if (Math.floor(top) !== Math.floor(scrollRef.getScrollTop())) {
      this.proximoScrollAutomatico = true
      scrollRef.scrollTop(top)
      this.colaboradorLocalPreferences.setPreference('AgendaInterface_GridScrollY', top)
    }
  }

  gerarColunasPorVisao = (visao) => {
    const { classes, colunaWidth } = this.props
    const colunas = {}
    const hoje = moment()

    if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      const asList = []
      const asMap = {}
      const dataBaseSemana = this.props.dataSelecionada.clone().subtract(parseInt(this.props.dataSelecionada.format('d'), 10), 'days')

      for (let i = 0; i <= 6; i++) {
        const diaSemana = parseInt(dataBaseSemana.format('d'), 10)
        const diaSemanaIso = dataBaseSemana.isoWeekday()

        if (this.props.diasSemana[diaSemana]._show) {
          const id = parseInt(dataBaseSemana.format('YYYYMMDD'), 10)

          let classNameDiaSemana = null
          if (hoje.isSame(dataBaseSemana, 'day')) {
            classNameDiaSemana = classes.diaSemanaAtualHeaderColuna
          }

          asList.push({
            id: id,
            nome: dataBaseSemana.format('ddd, D'),
            diaSemana: diaSemana,
            diaSemanaIso: diaSemanaIso,
            cor: '#eee',
            header: (
              <div>
                <div style={{ fontSize: 12, opacity: 0.8 }}>{dataBaseSemana.format('YYYY, MMM')}</div>
                <div
                  style={{
                    fontSize: classNameDiaSemana ? 20 : 22,
                    margin: '5px 0px 4px 0px',
                    opacity: 0.8,
                    fontWeight: 500,
                    width: 40,
                    height: 30,
                    lineHeight: '31px',
                    display: 'inline-block'
                  }}
                  className={classNameDiaSemana}
                >
                  {dataBaseSemana.format('DD')}
                </div>
                <div style={{ fontSize: 14, fontWeight: 500 }}>{dataBaseSemana.format('ddd')}</div>
              </div>
            )
          })
          asMap[id] = {
            posicao: asList.length - 1
          }
        }

        dataBaseSemana.add(1, 'days')
      }
      colunas.asList = asList
      colunas.asMap = asMap
    } else if (visao.id === VisaoEnum.PROFISSIONAL.id) {
      const asList = []
      const asMap = {}
      for (let colaborador of this.props.colaboradores) {
        if (colaborador._show === false) {
          continue
        }

        const variacoesFoto = ImageUtil.extrairVariacoesImagem(colaborador.foto)

        asList.push({
          id: colaborador.id,
          nome: formatarNome(colaborador.apelido, 2),
          colaborador: colaborador,
          cor: ColorSchema.getColorById(colaborador.cor).hex,
          header: (
            <React.Fragment>
              <ColaboradorPhotoPicker colaborador={colaborador} photo1x={variacoesFoto.get(2)} width={38} readOnly={true} />
              {colunaWidth > 110 && (
                <Typography variant="subtitle2" className={classes.tituloHeaderColuna}>
                  {formatarNome(colaborador.apelido, 2)}
                </Typography>
              )}
            </React.Fragment>
          )
        })
        asMap[colaborador.id] = {
          posicao: asList.length - 1
        }
      }
      colunas.asList = asList
      colunas.asMap = asMap
    } else if (visao.id === VisaoEnum.ANDAMENTO.id) {
      const asList = []
      const asMap = {}
      for (let status of StatusServicoEnum.values()) {
        if (status.selectable) {
          const id = status.id
          asList.push({
            id: id,
            nome: status.descricaoResumida,
            cor: '#eee',
            header: (
              <React.Fragment>
                <AtendimentoStatusIcon status={status} />
                {colunaWidth > 110 && (
                  <Typography variant="subtitle2" className={classes.tituloHeaderColuna}>
                    {status.descricaoResumida}
                  </Typography>
                )}
              </React.Fragment>
            )
          })
          asMap[id] = {
            posicao: asList.length - 1
          }
        }
      }
      colunas.asList = asList
      colunas.asMap = asMap
    } else if (visao.id === VisaoEnum.PAGAMENTO.id) {
      const asList = []
      const asMap = {}

      for (let status of StatusVendaEnum.values()) {
        let subtitulo = null
        if (status.id === StatusVendaEnum.NOTA_PENDENTE.id) {
          subtitulo = 'Feche a conta*'
        }

        const id = status.id
        asList.push({
          id: id,
          nome: status.descricao,
          cor: '#eee',
          header: (
            <React.Fragment>
              <VendaStatusIcon status={status} />
              {colunaWidth > 110 && (
                <Typography variant="subtitle2" className={classes.tituloHeaderColuna}>
                  {status.descricao}
                  {subtitulo && (
                    <span className={classes.subtituloHeaderColuna}>
                      <br />
                      {subtitulo}
                    </span>
                  )}
                </Typography>
              )}
            </React.Fragment>
          )
        })
        asMap[id] = {
          posicao: asList.length - 1
        }
      }
      colunas.asList = asList
      colunas.asMap = asMap
    }
    return colunas
  }

  scrollHorizontalColunaItens = (value) => {
    const left = this.agendaColunaItensScrollbarsFM.getScrollLeft()
    this.agendaColunaItensScrollbarsFM.scrollLeftWithAnimation(left + value, this.ajustarVisibilidadeBotoesScroll)
  }

  ajustarVisibilidadeBotoesScroll = () => {
    const { exibirBotaoScrollEsquerda, exibirBotaoScrollDireita } = this.state
    const scrollInfo = this.agendaColunaItensScrollbarsFM.getScrollInfo()
    if (exibirBotaoScrollEsquerda !== scrollInfo.canScrollLeft || exibirBotaoScrollDireita !== scrollInfo.canScrollRight) {
      this.setState({
        exibirBotaoScrollEsquerda: scrollInfo.canScrollLeft,
        exibirBotaoScrollDireita: scrollInfo.canScrollRight
      })
    }
  }

  ajustarAutoWidthColunasItens = (force) => {
    let clientWidth = this.agendaColunaItensScrollbarsFM.getClientWidth()
    const innerWidth = this.agendaColunaItensScrollbarsFM.getInnerWidth()

    if (this.props.colunaAutoWidth) {
      if (force || this.ultimoContainerWidthColunas !== clientWidth || innerWidth < clientWidth || (innerWidth > clientWidth && this.props.colunaWidth > COLUNA_ITENS_MIN_WIDTH)) {
        this.ultimoContainerWidthColunas = clientWidth
        let containerWidth = clientWidth

        const colunas = this.gerarColunasPorVisao(this.props.visao).asList
        containerWidth = containerWidth - 17
        containerWidth = containerWidth - 17 //scroll
        containerWidth = containerWidth - colunas.length
        let colunaWidth = containerWidth / colunas.length
        if (colunaWidth < COLUNA_ITENS_MIN_DEFAULT_WIDTH) {
          colunaWidth = COLUNA_ITENS_MIN_DEFAULT_WIDTH
        }
        EventsManager.pub('AgendaInterface', {
          action: 'changeColunaWidth',
          colunaWidth: colunaWidth
        })
      }
    }

    this.ultimoContainerWidthColunas = clientWidth
    if (clientWidth !== this.props.containerColunasWidth) {
      this.props.changeContainerColunasWidth(clientWidth)
    }
  }

  getListaHorarios = () => {
    const horarios = []
    for (let i = 0; i <= 23; i++) {
      horarios.push(i.toString().padStart(2, '0') + ':00')
      if (this.props.intervaloMinutosExibicaoTempo > 60) {
        i += this.props.intervaloMinutosExibicaoTempo / 60 - 1
      }
      if (this.props.intervaloMinutosExibicaoTempo === 30) {
        horarios.push(i.toString().padStart(2, '0') + ':30')
      } else if (this.props.intervaloMinutosExibicaoTempo === 15) {
        horarios.push(i.toString().padStart(2, '0') + ':15')
        horarios.push(i.toString().padStart(2, '0') + ':30')
        horarios.push(i.toString().padStart(2, '0') + ':45')
      } else if (this.props.intervaloMinutosExibicaoTempo === 10) {
        horarios.push(i.toString().padStart(2, '0') + ':10')
        horarios.push(i.toString().padStart(2, '0') + ':20')
        horarios.push(i.toString().padStart(2, '0') + ':30')
        horarios.push(i.toString().padStart(2, '0') + ':40')
        horarios.push(i.toString().padStart(2, '0') + ':50')
      } else if (this.props.intervaloMinutosExibicaoTempo === 5) {
        horarios.push(i.toString().padStart(2, '0') + ':05')
        horarios.push(i.toString().padStart(2, '0') + ':10')
        horarios.push(i.toString().padStart(2, '0') + ':15')
        horarios.push(i.toString().padStart(2, '0') + ':20')
        horarios.push(i.toString().padStart(2, '0') + ':25')
        horarios.push(i.toString().padStart(2, '0') + ':30')
        horarios.push(i.toString().padStart(2, '0') + ':35')
        horarios.push(i.toString().padStart(2, '0') + ':40')
        horarios.push(i.toString().padStart(2, '0') + ':45')
        horarios.push(i.toString().padStart(2, '0') + ':50')
        horarios.push(i.toString().padStart(2, '0') + ':55')
      }
    }
    return horarios
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.dataSelecionada.isSame(nextProps.dataSelecionada, 'day') || this.props.perspectiva.id !== nextProps.perspectiva.id) {
      EventsManager.pub('AgendaInterfacePosicionarHorario')
    }

    if (!this.props.dataSelecionada.isSame(nextProps.dataSelecionada, 'day')) {
      this.posicionarGuiaDataAtual(nextProps.dataSelecionada)
    }
    return true
  }

  openMenuGridAdicionar = (event, props) => {
    openBackablePopup(MenuGridAdicionar, {
      clientX: event.clientX,
      clientY: event.clientY,
      anchorReference: 'anchorPosition',
      novoAtendimentoFunction: () => {
        if (verificarAutorizacao([PA.PODE_MARCAR_ATENDIMENTO])) {
          EventsManager.pub('AgendaInterface', {
            action: 'adicionarVenda',
            data: props.data.toDate(),
            tipoAtendimentoPreSelecionado: AtendimentoTipoEnum.HORA_MARCADA,
            colaboradorPreSelecionado: props.colaborador,
            horarioPrePreenchido: converterMinutosParaHorarioInt(props.horarioMinutos)
          })
        } else {
          openBackableDialog(AccessDeniedDialog)
        }
      },
      novoBloqueioHorarioFunction: () => {
        if (verificarAutorizacao([PA.PODE_PERSISTIR_PROPRIO_BLOQUEIO_HORARIO])) {
          openBackableDialog(BloqueioHorarioDialogPage, {
            data: props.data.toDate(),
            colaboradorPreSelecionado: props.colaborador,
            horarioInicioPrePreenchido: converterMinutosParaHorarioInt(props.horarioMinutos)
          })
        } else {
          openBackableDialog(AccessDeniedDialog)
        }
      },
      onClosed: props.onClosed
    })
  }

  getPixelsPorMinutoTratado = () => {
    return (60 / this.props.intervaloMinutosExibicaoTempo) * PIXELS_POR_MINUTO_BASE
  }

  getIntervaloHorarioGrid = (listaHorarios, intervaloHorarioDisponivel, itens) => {
    let gridHorarioInicial = intervaloHorarioDisponivel.de
    let gridHorarioFinal = intervaloHorarioDisponivel.ate

    for (let item of itens) {
      if (item.horarioInicio < gridHorarioInicial) {
        gridHorarioInicial = item.horarioInicio
      }
      if (item.horarioTermino > gridHorarioFinal) {
        gridHorarioFinal = item.horarioTermino
      }
    }

    let referenciaIndexHorarioInicial = null
    let referenciaIndexHorarioFinal = null

    const gridHorarioFinalComparacao = converterMinutosParaHorarioInt(converterHorarioIntParaMinutos(gridHorarioFinal) - this.props.intervaloMinutosExibicaoTempo / 2)

    for (let i = 0; i < listaHorarios.length; i++) {
      const horario = parseInt(listaHorarios[i].replace(':', ''), 10)
      if (gridHorarioInicial <= horario && referenciaIndexHorarioInicial === null) {
        if (gridHorarioInicial === horario) {
          referenciaIndexHorarioInicial = i
        } else {
          referenciaIndexHorarioInicial = i - 1
        }
      }
      if (gridHorarioFinalComparacao < horario && referenciaIndexHorarioFinal === null) {
        referenciaIndexHorarioFinal = i
      }
    }

    let horarioInicialTratado = parseInt(listaHorarios[referenciaIndexHorarioInicial].replace(':', ''), 10)
    let horarioFinalTratado = null

    if (referenciaIndexHorarioFinal !== null) {
      horarioFinalTratado = parseInt(listaHorarios[referenciaIndexHorarioFinal].replace(':', ''), 10)
    }

    if (gridHorarioInicial - horarioInicialTratado === 0) {
      horarioInicialTratado = converterMinutosParaHorarioInt(converterHorarioIntParaMinutos(horarioInicialTratado) - this.props.intervaloMinutosExibicaoTempo / 5)
      if (horarioInicialTratado < 0) {
        horarioInicialTratado = 0
      }
    }

    if (horarioFinalTratado !== null) {
      if (horarioFinalTratado < gridHorarioFinal) {
        horarioFinalTratado = converterMinutosParaHorarioInt(converterHorarioIntParaMinutos(horarioFinalTratado) + this.props.intervaloMinutosExibicaoTempo / 2)
        if (horarioFinalTratado > 2400) {
          horarioInicialTratado = 2400
        }
      } else {
        horarioFinalTratado = converterMinutosParaHorarioInt(converterHorarioIntParaMinutos(horarioFinalTratado) + this.props.intervaloMinutosExibicaoTempo / 5)
        if (horarioFinalTratado > 2400) {
          horarioInicialTratado = 2400
        }
      }
    }

    return {
      horarioInicial: horarioInicialTratado,
      horarioFinal: horarioFinalTratado
    }
  }

  render() {
    const { classes, visao, colunaWidth } = this.props

    const dataSelecionadaAsInt = converterMomentParaDataInt(this.props.dataSelecionada)

    const colunas = this.gerarColunasPorVisao(visao)

    const listaHorarios = this.getListaHorarios()
    const elementosHorarios = []
    for (let i = 0; i < listaHorarios.length; i++) {
      const hora = listaHorarios[i]
      elementosHorarios.push(
        <div key={hora} className={classes.containerHorario}>
          {hora}
        </div>
      )
    }
    let heightTotalGrid = listaHorarios.length * 60 * PIXELS_POR_MINUTO_BASE
    heightTotalGrid += 17 // scroll

    const elementosIndicadoresGridVertical = []

    let agendaAtendimentosKey = converterMomentParaDataInt(this.props.dataSelecionada)
    for (let colaborador of this.props.colaboradores) {
      if (colaborador._show) {
        agendaAtendimentosKey += ',' + colaborador.id
      }
    }
    agendaAtendimentosKey += ',p:' + this.props.perspectiva.id
    agendaAtendimentosKey += ',v:' + this.props.visao.id

    if (this.props.colaboradorSelecionado) {
      agendaAtendimentosKey += ',cs:' + this.props.colaboradorSelecionado.id
    }
    for (let diaSemana of this.props.diasSemana) {
      if (diaSemana._show) {
        agendaAtendimentosKey += ',' + diaSemana.id
      }
    }

    for (let i = 0; i < colunas.asList.length; i++) {
      const coluna = colunas.asList[i]
      elementosIndicadoresGridVertical.push(
        <div key={coluna.id} className={classes.containerColunaItens} style={{ flexBasis: colunaWidth, minWidth: colunaWidth, height: heightTotalGrid }}></div>
      )
    }
    elementosIndicadoresGridVertical.push(<div key="ajustWidthScroll" style={{ flexBasis: 17, minWidth: 17, height: heightTotalGrid }}></div>)

    const backgroundProps = BackgroundUtil.generateBackgroundLines(HEIGHT_GUIA_ATENDIMENTOS, 1, '#ffffff', '#e4e4e4')

    let headerHeight = DIA_HEADER_HEIGHT
    if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      headerHeight = SEMANA_HEADER_HEIGHT
    }

    return (
      <AgendaItensLoader
        loadKey={agendaAtendimentosKey}
        dataSelecionada={this.props.dataSelecionada}
        colaboradorSelecionado={this.props.colaboradorSelecionado}
        perspectiva={this.props.perspectiva}
        visao={visao}
        colunas={colunas}
        colaboradores={this.props.colaboradores}
      >
        {(atendimentosProps) => {
          const gridHorarios = this.getIntervaloHorarioGrid(listaHorarios, this.extrairIntervaloHorarioDisponivel(null, null), atendimentosProps.itens)

          let rootGridMarginTop = converterHorarioIntParaMinutos(gridHorarios.horarioInicial) * this.getPixelsPorMinutoTratado() * -1
          let rootGridHeight = null
          if (gridHorarios.horarioFinal) {
            rootGridHeight = converterHorarioIntParaMinutos(gridHorarios.horarioFinal) * this.getPixelsPorMinutoTratado()
          }

          return (
            <React.Fragment>
              <div className={classes.containerHorarios}>
                <div style={{ height: headerHeight, width: '100%', position: 'absolute' }}>
                  <Grid style={{ height: '100%' }} className={classes.botaoManipulacaoBarraFerramentasEsquerda} container justifyContent="center" alignItems="center">
                    <Grid item>
                      <IconButton
                        style={{ marginBottom: 4, padding: 6, display: 'block', marginRight: 4 }}
                        onClick={() => {
                          this.props.changeToolsExpanded(!this.props.toolsExpanded)
                          EventsManager.pub('CustomScrollbars', { action: 'forceUpdate', delay: 200 })
                        }}
                      >
                        {this.props.toolsExpanded && <ChevronLeftIcon style={{ display: 'block' }} />}
                        {!this.props.toolsExpanded && <ChevronRightIcon style={{ display: 'block' }} />}
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    marginTop: headerHeight - HORARIOS_DIFF_HEIGHT,
                    height: 'calc(100% - ' + (headerHeight - HORARIOS_DIFF_HEIGHT) + 'px)',
                    overflow: 'hidden',
                    zIndex: 1,
                    position: 'relative'
                  }}
                  ref={this.containerHorariosRef}
                >
                  <div style={{ marginTop: rootGridMarginTop, height: rootGridHeight ? rootGridHeight + 10 : undefined, overflow: 'hidden' }}>
                    {elementosHorarios}
                    <div style={{ height: HORARIOS_DIFF_HEIGHT }}></div>
                    <div style={{ height: 20 }}></div>
                  </div>
                </div>
              </div>
              <div className={classes.containerAgendamentos}>
                <div className={classes.containerAgendamentosHeader} style={{ height: headerHeight }}>
                  <Fab
                    size="medium"
                    className={classNames(classes.botaoNavScrollColunaItens, this.state.exibirBotaoScrollEsquerda ? classes.botaoNavScrollColunaItensVisivel : null)}
                    style={{ left: 10 }}
                    onClick={() => this.scrollHorizontalColunaItens(-600)}
                  >
                    <ChevronLeftIcon className={classes.iconeNavScrollColunaItens} />
                  </Fab>
                  <Fab
                    size="medium"
                    className={classNames(classes.botaoNavScrollColunaItens, this.state.exibirBotaoScrollDireita ? classes.botaoNavScrollColunaItensVisivel : null)}
                    style={{ right: 20 }}
                    onClick={() => this.scrollHorizontalColunaItens(600)}
                  >
                    <ChevronRightIcon className={classes.iconeNavScrollColunaItens} />
                  </Fab>

                  <AgendaColunaItensScrollbars
                    functionsMap={this.agendaColunaItensScrollbarsFM}
                    onScrollStart={() => {
                      if (this.contextoOrigemScroll === null) {
                        this.contextoOrigemScroll = 'colunaItensScrollbars'
                      }
                    }}
                    onScrollStop={() => {
                      if (this.contextoOrigemScroll === 'colunaItensScrollbars') {
                        this.contextoOrigemScroll = null
                        this.ajustarVisibilidadeBotoesScroll()
                      }
                    }}
                    onScroll={(x, y) => {
                      if (this.contextoOrigemScroll === null || this.contextoOrigemScroll === 'colunaItensScrollbars') {
                        this.agendaItensScrollbarsFM.scrollLeft(y.getScrollLeft())
                      }
                    }}
                  >
                    <div style={{ display: 'inline-block', height: '100%', whiteSpace: 'nowrap' }}>
                      <ColunasHeader perspectiva={this.props.perspectiva} visao={visao} colunas={colunas} classesObject={classes} colunaWidth={colunaWidth + 1} />
                      <div key="ajustWidthDiff" style={{ display: 'inline-block', width: 17, height: 17 }}></div>
                      <div key="ajustWidthScroll" style={{ display: 'inline-block', width: 17, height: 17 }}></div>
                    </div>
                  </AgendaColunaItensScrollbars>
                </div>

                <div
                  style={{
                    height: 'calc(100% - ' + headerHeight + 'px)',
                    display: 'flex',
                    width: '100%',
                    position: 'relative',
                    justifyContent: 'center',
                    borderLeft: '1px solid ' + BORDER_COLOR
                  }}
                >
                  <AgendaItensScrollbars
                    functionsMap={this.agendaItensScrollbarsFM}
                    onScrollStart={() => {
                      if (this.contextoOrigemScroll === null) {
                        this.contextoOrigemScroll = 'itensScrollbars'
                      }
                      if (!this.proximoScrollAutomatico) {
                        this.colaboradorLocalPreferences.setMomentPreference('AgendaInterface_UltimoAcesso', moment())
                        this.colaboradorLocalPreferences.setMomentPreference('AgendaInterface_PosicionamentoGridAutomatico', false)
                      }
                      this.proximoScrollAutomatico = false
                    }}
                    onScrollStop={(x, y) => {
                      if (this.contextoOrigemScroll === 'itensScrollbars') {
                        this.contextoOrigemScroll = null
                        this.ajustarVisibilidadeBotoesScroll()
                      }
                      this.colaboradorLocalPreferences.setPreference('AgendaInterface_GridScrollY', this.agendaItensScrollbarsFM.getScrollRef().getScrollTop())
                    }}
                    onScroll={(x, y) => {
                      if (this.contextoOrigemScroll === null || this.contextoOrigemScroll === 'itensScrollbars') {
                        this.containerHorariosRef.current.scrollTop = y.getScrollTop()
                        this.agendaColunaItensScrollbarsFM.scrollLeft(y.getScrollLeft())
                      }
                    }}
                  >
                    <div
                      style={{
                        marginTop: rootGridMarginTop,
                        height: rootGridHeight ? rootGridHeight : undefined,
                        overflow: 'hidden',
                        top: '1px',
                        display: 'flex',
                        alignItems: 'flex-start',
                        position: 'absolute',
                        backgroundSize: backgroundProps.backgroundSize,
                        backgroundImage: backgroundProps.backgroundImage
                      }}
                      onClick={(event) => {
                        if (isMobile) {
                          const boundingClientRect = this.containerGridAtendimentos.current.getBoundingClientRect()
                          const x = event.pageX - boundingClientRect.x
                          const y = event.pageY - boundingClientRect.y
                          const coluna = parseInt(x / colunaWidth, 10)
                          const linha = parseInt(y / HEIGHT_GUIA_ATENDIMENTOS, 10)
                          const horarioMinutosPosicaoGuia = linha * (this.props.intervaloMinutosExibicaoTempo / 2)
                          const colunaColaborador = colunas.asList[coluna]
                          let colaborador = null
                          let data = null

                          if (colunaColaborador && this.props.perspectiva.id === PerspectivaEnum.DIA.id && visao.id === VisaoEnum.PROFISSIONAL.id) {
                            colaborador = colunaColaborador.colaborador
                            data = this.props.dataSelecionada
                          } else if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
                            colaborador = this.props.colaboradorSelecionado
                            data = converterDataIntParaMoment(colunaColaborador.id)
                          }

                          this.openMenuGridAdicionar(event, {
                            colaborador: colaborador,
                            data: data,
                            horarioMinutos: horarioMinutosPosicaoGuia
                          })
                        }
                      }}
                      onMouseMove={(event) => {
                        if (!isMobile) {
                          const boundingClientRect = this.containerGridAtendimentos.current.getBoundingClientRect()
                          const x = event.pageX - boundingClientRect.x
                          const y = event.pageY - boundingClientRect.y

                          if (this.guiaGridAdicionarRef.current && this.guiaGridAdicionarRef.current._showingMenu) {
                            this.guiaGridAdicionarRef.current.style.display = 'none'
                            return
                          }

                          if (!atendimentosProps.loaded) {
                            this.guiaGridAdicionarRef.current.style.display = 'none'
                            return
                          }

                          const coluna = parseInt(x / colunaWidth, 10)
                          const posicionamentoColuna = x % colunaWidth

                          if (posicionamentoColuna - coluna < 1 || posicionamentoColuna > colunaWidth - 2) {
                            this.guiaGridAdicionarRef.current.style.display = 'none'
                            return
                          }

                          const posicionamentoLinha = y % HEIGHT_GUIA_ATENDIMENTOS
                          if (posicionamentoLinha < 1 || posicionamentoLinha > HEIGHT_GUIA_ATENDIMENTOS - 3) {
                            this.guiaGridAdicionarRef.current.style.display = 'none'
                            return
                          }

                          const linha = parseInt(y / HEIGHT_GUIA_ATENDIMENTOS, 10)

                          if (coluna > colunas.asList.length - 1) {
                            this.guiaGridAdicionarRef.current.style.display = 'none'
                            return
                          }

                          this.horarioMinutosPosicaoGuia = linha * (this.props.intervaloMinutosExibicaoTempo / 2)

                          if (this.horarioMinutosPosicaoGuia >= 60 * 24) {
                            this.guiaGridAdicionarRef.current.style.display = 'none'
                            return
                          }

                          const colunaColaborador = colunas.asList[coluna]
                          if (colunaColaborador && this.props.perspectiva.id === PerspectivaEnum.DIA.id && visao.id === VisaoEnum.PROFISSIONAL.id) {
                            this.colaboradorPosicaoGuia = colunaColaborador.colaborador
                            this.dataPosicaoGuia = this.props.dataSelecionada
                          } else if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
                            this.colaboradorPosicaoGuia = this.props.colaboradorSelecionado
                            this.dataPosicaoGuia = converterDataIntParaMoment(colunaColaborador.id)
                          }
                          this.guiaGridAdicionarRef.current.style.left = coluna * colunaWidth + coluna + 1 + 'px'
                          this.guiaGridAdicionarRef.current.style.top = linha * HEIGHT_GUIA_ATENDIMENTOS + 1 + 'px'
                          this.guiaGridAdicionarRef.current.innerHTML = '&nbsp;&nbsp;' + formatarHorarioMinutos(this.horarioMinutosPosicaoGuia) + ' - Adicionar...'
                          this.guiaGridAdicionarRef.current.style.display = 'block'
                        }
                      }}
                      onMouseOut={(event) => {
                        if (!isMobile) {
                          window.setTimeout(() => {
                            if (this.guiaGridAdicionarRef.current && !this.guiaGridAdicionarRef.current._isMouseInsideGuia) {
                              this.guiaGridAdicionarRef.current.style.display = 'none'
                            }
                          }, 70)
                        }
                      }}
                      ref={this.containerGridAtendimentos}
                    >
                      {elementosIndicadoresGridVertical}

                      <MarcadoresHorariosIndisponiveis
                        dataSelecionada={this.props.dataSelecionada}
                        colaboradorSelecionado={this.props.colaboradorSelecionado}
                        colunaWidth={colunaWidth}
                        pixelsPorMinuto={this.props.pixelsPorMinuto}
                        perspectiva={this.props.perspectiva}
                        visao={visao}
                        colunas={colunas}
                        colaboradores={this.props.colaboradores}
                        diasSemana={this.props.diasSemana}
                      />

                      {atendimentosProps.itens &&
                        atendimentosProps.itens.length > 0 &&
                        atendimentosProps.itens
                          .filter((item) => {
                            if (this.props.perspectiva.id === PerspectivaEnum.DIA.id && dataSelecionadaAsInt !== item.data) {
                              return false
                            }
                            return true
                          })
                          .map((item) =>
                            item._instancias.map((instancia, index) => {
                              let itemCardKey = item.id + '-'
                              if (instancia._coluna) {
                                itemCardKey += instancia._coluna.posicao
                              } else {
                                itemCardKey += index
                              }
                              if (instancia._colunaInterna) {
                                itemCardKey += '-' + instancia._colunaInterna
                              }

                              return (
                                <ItemCard
                                  key={itemCardKey}
                                  perspectiva={this.props.perspectiva}
                                  heightTotalGrid={heightTotalGrid}
                                  regraCorCard={this.props.regraCorCard}
                                  colunaWidth={colunaWidth}
                                  pixelsPorMinuto={this.props.pixelsPorMinuto}
                                  guiaGridAdicionarRef={this.guiaGridAdicionarRef}
                                  itens={atendimentosProps.itens}
                                  item={item}
                                  itemInstancia={instancia}
                                />
                              )
                            })
                          )}

                      <div
                        className={classes.containerGuiaHoraAtual}
                        ref={this.containerGuiaHoraAtualRef}
                        style={{ visibility: atendimentosProps.loaded && this.props.perspectiva.id === PerspectivaEnum.DIA.id ? 'visible' : 'hidden' }}
                      >
                        <div className={classes.guiaHoraAtualBase}></div>
                        <div className={classes.guiaHoraAtualTraco}></div>
                      </div>

                      <div
                        className={classes.guiaGridAdicionar}
                        style={{ width: colunaWidth - 6, display: 'none' }}
                        ref={this.guiaGridAdicionarRef}
                        onClick={(event) => {
                          event.stopPropagation()
                          this.guiaGridAdicionarRef.current._showingMenu = true
                          this.openMenuGridAdicionar(event, {
                            colaborador: this.colaboradorPosicaoGuia,
                            data: this.dataPosicaoGuia,
                            horarioMinutos: this.horarioMinutosPosicaoGuia,
                            onClosed: () => {
                              this.guiaGridAdicionarRef.current._showingMenu = false
                            }
                          })
                        }}
                        onMouseEnter={(event) => {
                          this.guiaGridAdicionarRef.current._isMouseInsideGuia = true
                        }}
                        onMouseOut={(event) => {
                          this.guiaGridAdicionarRef.current._isMouseInsideGuia = false
                        }}
                      ></div>
                    </div>
                  </AgendaItensScrollbars>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </AgendaItensLoader>
    )
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.pixelsPorMinuto !== prevProps.pixelsPorMinuto) {
      this.posicionarGuiaDataAtual()
    }
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    window.clearInterval(this.ajustarVisibilidadeBotoesInterval)
    window.clearInterval(this.ajustarAutoWidthColunasItensInterval)
    window.clearInterval(this.posicionamentoGuiaDataAtualInterval)
  }
}

class ColunasHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  closeMenuColaboradorAnchorPosition = (event) => {
    event.stopPropagation()
    this.setState({
      menuColaboradorAnchorPosition: null
    })
  }

  moverProfissionalDireita = (idColaboradorDe, idColaboradorPara) => {
    this.setState({
      ajaxingMenu: true,
      ajaxingMoverDireita: true
    })
    this.moverProfissional(idColaboradorDe, idColaboradorPara)
  }

  moverProfissionalEsquerda = (idColaboradorDe, idColaboradorPara) => {
    this.setState({
      ajaxingMenu: true,
      ajaxingMoverEsquerda: true
    })
    this.moverProfissional(idColaboradorDe, idColaboradorPara)
  }

  moverProfissional = (idColaboradorDe, idColaboradorPara) => {
    postAPI({
      url: 'erp/colaboradores.moverColaborador',
      data: {
        idColaboradorDe: idColaboradorDe,
        idColaboradorPara: idColaboradorPara
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          ajaxingMenu: false,
          ajaxingMoverDireita: false,
          ajaxingMoverEsquerda: false,
          menuColaboradorAnchorPosition: null
        })
        EventsManager.pub('ManipulacaoColaborador')
      },
      onError: (response) => {
        this.setState((state) => ({
          ajaxingMenu: false,
          ajaxingMoverDireita: false,
          ajaxingMoverEsquerda: false
        }))
      }
    })
  }

  render() {
    const { visao, colunas, colunaWidth, classesObject } = this.props
    const { ajaxingMenu, ajaxingMoverDireita, ajaxingMoverEsquerda, idColaborador, posicao, menuColaboradorAnchorPosition } = this.state

    const elementosColunasHeader = []
    for (let i = 0; i < colunas.asList.length; i++) {
      const coluna = colunas.asList[i]
      const colunaProps = {}
      const colunasClasses = [classesObject.containerColunaItensHeader]

      if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
        colunasClasses.push(classesObject.containerColunaItensHeaderProfissional)
        colunaProps.onClick = (event) => {
          EventsManager.pub('AlterarPerspectivaAgenda', {
            dataSelecionada: converterDataIntParaMoment(coluna.id),
            perspectiva: PerspectivaEnum.DIA
          })
        }
      } else if (visao.id === VisaoEnum.PROFISSIONAL.id) {
        colunasClasses.push(classesObject.containerColunaItensHeaderProfissional)
        colunaProps.onClick = (event) => {
          this.setState({
            idColaborador: coluna.id,
            posicao: i,
            menuColaboradorAnchorPosition: {
              left: event.clientX,
              top: event.clientY
            }
          })
        }
      }

      elementosColunasHeader.push(
        <div key={coluna.id} {...colunaProps} className={classNames(colunasClasses)} style={{ display: 'inline-block', width: colunaWidth, overflow: 'hidden' }}>
          <div className={classesObject.innerContainerColunaItensHeader}>{coluna.header}</div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div style={{ display: 'inline-block', height: '100%', whiteSpace: 'nowrap' }}>{elementosColunasHeader}</div>
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={menuColaboradorAnchorPosition}
          open={Boolean(menuColaboradorAnchorPosition)}
          onClose={this.closeMenuColaboradorAnchorPosition}
        >
          {verificarAutorizacao([PA.PODE_ACESSAR_CAD_COLABORADORES]) && (
            <MenuItem
              disabled={ajaxingMenu}
              onClick={(event) => {
                openBackableDialog(ColaboradorDialogPage, {
                  idColaborador: idColaborador
                })
                this.closeMenuColaboradorAnchorPosition(event)
              }}
            >
              Abrir Profissional
            </MenuItem>
          )}

          <MenuItem
            disabled={ajaxingMenu}
            onClick={(event) => {
              EventsManager.pub('AlterarPerspectivaAgenda', {
                idColaboradorSelecionado: idColaborador,
                perspectiva: PerspectivaEnum.SEMANA
              })
              this.closeMenuColaboradorAnchorPosition(event)
            }}
          >
            Visão Semanal
          </MenuItem>
          <MenuItem
            disabled={ajaxingMenu}
            onClick={(event) => {
              EventsManager.pub('AlterarPerspectivaAgenda', {
                idColaboradorSelecionado: idColaborador,
                perspectiva: PerspectivaEnum.MES
              })
              this.closeMenuColaboradorAnchorPosition(event)
            }}
          >
            Visão Mensal
          </MenuItem>
          {colunas.asList.length > 1 && posicao < colunas.asList.length - 1 && (
            <MenuItem
              disabled={ajaxingMenu}
              onClick={(event) => {
                this.moverProfissionalDireita(idColaborador, colunas.asList[posicao + 1].id)
              }}
            >
              Mover para direita
              {ajaxingMoverDireita && <CircularProgress size={18} thickness={5.5} style={{ marginLeft: 12 }} />}
            </MenuItem>
          )}
          {colunas.asList.length > 1 && posicao > 0 && (
            <MenuItem
              disabled={ajaxingMenu}
              onClick={(event) => {
                this.moverProfissionalEsquerda(colunas.asList[posicao - 1].id, idColaborador)
              }}
            >
              Mover para esquerda
              {ajaxingMoverEsquerda && <CircularProgress size={18} thickness={5.5} style={{ marginLeft: 12 }} />}
            </MenuItem>
          )}
        </Menu>
      </React.Fragment>
    )
  }
}

class MenuGridAdicionar extends Component {
  render() {
    const popupProps = extractPopupProps(this.props)
    const { novoAtendimentoFunction, novoBloqueioHorarioFunction } = this.props

    return (
      <Menu {...popupProps} disablePortal={true}>
        <MenuItem
          onClick={(event) => {
            this.props.handleCloseDialog({ onClosedCallback: novoAtendimentoFunction })
          }}
        >
          Novo Atendimento/Venda
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            this.props.handleCloseDialog({ onClosedCallback: novoBloqueioHorarioFunction })
          }}
        >
          Bloqueio de Horário
        </MenuItem>
      </Menu>
    )
  }
}

AgendaGridAtendimento.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AgendaGridAtendimento)
