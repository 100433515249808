import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  FIXO: { id: 1, descricao: 'Fixo', descricaoExplicacao: 'O preço do serviço/variação será exibido para o cliente como sendo fixo' },
  A_PARTIR: {
    id: 2,
    descricao: 'A Partir De',
    descricaoExplicacao: 'O preço do serviço/variação será exibido para o cliente com um sinal de +, indicando que o preço é "A partir de"'
  },
  OCULTO: { id: 3, descricao: 'Não Exibir', descricaoExplicacao: 'O preço do serviço/variação NÃO será exibido para o cliente' }
})

export default enumObject
