import React, { Component } from 'react'

import AtendimentoDialogPage from 'pages/erp/painel/atendimento/AtendimentoDialogPage'
import VendaProdutoDialogPage from 'pages/erp/painel/inventario/VendaProdutoDialogPage'
import VendaPacoteDialogPage from 'pages/erp/painel/pacotes/VendaPacoteDialogPage'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'

class VendaDialogPage extends Component {
  static isDialogPage = true

  render() {
    const { venda, ...others } = this.props

    if (TipoVendaEnum.ATENDIMENTO.id === venda.tipo.id) {
      return <AtendimentoDialogPage {...others} idAtendimento={venda.atendimento.id} />
    } else if (TipoVendaEnum.VENDA_PRODUTO.id === venda.tipo.id) {
      return <VendaProdutoDialogPage {...others} idVendaProduto={venda.vendaProduto.id} />
    } else if (TipoVendaEnum.VENDA_PACOTE.id === venda.tipo.id) {
      return <VendaPacoteDialogPage {...others} idVendaPacote={venda.vendaPacote.id} />
    }
  }
}

export default VendaDialogPage
