import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import DescontoInputDialogSelect from 'pages/erp/painel/configuracoes/desconto/input/DescontoInputDialogSelect'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import InputPorcentagemValor from 'support/components/input/InputPorcentagemValor'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import DescontoDisponibilidadeEnum from 'support/domain/desconto/DescontoDisponibilidadeEnum'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

export const ID_DESCONTO_MANUAL = 0
const DESCRICAO_DESCONTO_MANUAL = 'Desconto personalizado'
const DESCONTO_MANUAL = { id: ID_DESCONTO_MANUAL, nome: DESCRICAO_DESCONTO_MANUAL }

class DescontoInputSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      desconto: null
    }

    const defaultValue = props.defaultValue

    if (defaultValue) {
      if (defaultValue.valor !== undefined && defaultValue.valor !== null) {
        if (defaultValue.desconto && defaultValue.desconto.id) {
          this.state.desconto = {
            id: defaultValue.desconto.id,
            nome: defaultValue.descricaoDesconto,
            tipoCalculoDesconto: defaultValue.tipoCalculo,
            valor: defaultValue.valor
          }
        } else {
          this.state.desconto = DESCONTO_MANUAL
          this.manualInputDefaultValue = { defaultTipoCalculo: defaultValue.tipoCalculo, defaultValor: defaultValue.valor }
        }
        this.selectDefaultValue = this.state.desconto
      }
    }

    this.inputValorManualFunctionMap = {}
  }

  componentDidMount = () => {
    const { functionsMap } = this.props
    if (functionsMap) {
      functionsMap['getValues'] = () => {
        const dados = {}
        if (this.state.desconto) {
          dados.aplicarDesconto = true
          if (this.state.desconto.id === ID_DESCONTO_MANUAL) {
            dados.desconto = null
            dados.manual = true
            if (this.inputValorManualFunctionMap.getValor && this.inputValorManualFunctionMap.getTipoCalculo) {
              dados.valorDescontoPersonalizado = FormUtil.convertStringToNumber(this.inputValorManualFunctionMap.getValor())
              dados.tipoCalculoDescontoPersonalizado = this.inputValorManualFunctionMap.getTipoCalculo()
            }
          } else {
            dados.desconto = this.state.desconto
            dados.manual = false
          }
        }
        return dados
      }
    }
  }

  render() {
    const podeAplicarDescontoPersonalizado = verificarAutorizacao([PA.PODE_APLICAR_DESCONTO_NCADASTRADO])
    const podeAplicarDescontoCadastrado = verificarAutorizacao([PA.PODE_APLICAR_DESCONTO_CADASTRADO])

    const { classes, disponibilidades, descontos, onChange, selectProps, manualInputProps, functionsMap, ...othersProps } = this.props
    delete othersProps.defaultValue

    selectProps.DialogSelectProps = {
      preProcessItemsFunction: (descontos) => {
        const descontosFiltrados = descontos.filter((desconto) => {
          return DescontoDisponibilidadeEnum.verify(disponibilidades, desconto.disponibilidade)
        })
        descontosFiltrados.unshift(DESCONTO_MANUAL)
        return descontosFiltrados
      }
    }

    let helpMessage = null
    if (!podeAplicarDescontoPersonalizado && !podeAplicarDescontoCadastrado) {
      helpMessage = 'Você não tem permissão para aplicar descontos'
    }

    const exibirCampoValorManual = this.state.desconto && this.state.desconto.id === ID_DESCONTO_MANUAL ? true : false

    const content = [
      <DescontoInputDialogSelect
        key="select"
        {...othersProps}
        {...selectProps}
        helpMessage={!exibirCampoValorManual ? helpMessage : null}
        defaultValue={this.selectDefaultValue}
        onChange={(item) => {
          const state = this.state
          state.desconto = item ? item.desconto : null
          if (onChange) {
            onChange(item)
          }
          this.setState(state)
        }}
      />
    ]

    if (exibirCampoValorManual) {
      content.push(
        <InputPorcentagemValor
          key="manualInput"
          {...othersProps}
          {...manualInputProps}
          {...this.manualInputDefaultValue}
          functionsMap={this.inputValorManualFunctionMap}
          disabled={this.props.disabled || !podeAplicarDescontoPersonalizado}
          helpMessage={helpMessage}
        />
      )
    }

    return content
  }
}

DescontoInputSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DescontoInputSelect)
