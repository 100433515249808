import React from 'react'

import { getAPI } from 'support/components/api/ApiClient'

import Constants from '../../../../Constants'

class VirtualizedListLoader extends React.Component {
  customKey = this.props.customKey
  requestCount = 0
  lastLoad = 0
  loading = false
  offset = 0
  canLoadMore = true
  lastIndexLoadedByLoadMore = 0

  constructor(props) {
    super(props)
    this.mounted = true
    this.state = {}
    this.request({ action: 'load' })
  }

  componentDidMount() {
    if (this.props.functionsMap !== undefined) {
      this.props.functionsMap['load'] = (delay) => {
        this.request({ action: 'load', delay: delay })
      }
      this.props.functionsMap['loadMore'] = (index) => {
        this.request({ action: 'loadmore', index: index })
      }
      this.props.functionsMap['refresh'] = () => {
        this.request({ action: 'refresh' })
      }
    }
  }

  request = (opts) => {
    if (this.loading && opts.action === 'loadmore') {
      return
    }

    this.loading = true

    if (opts.action === 'load') {
      this.lastIndexLoadedByLoadMore = 0
    }

    const requestCount = ++this.requestCount

    if (opts.action === 'loadmore' && (!this.canLoadMore || this.lastIndexLoadedByLoadMore >= opts.index)) {
      this.loading = false
      return
    }

    const { endpoint, contextoUsuario, getRequestParametersFunction, itemsPerRequest, onStart, onSuccess, onError } = this.props

    if (onStart) {
      onStart({ action: opts.action })
    }

    this.ultimaSolicitacaoBusca = new Date().getTime()

    window.setTimeout(
      () => {
        if (opts.delay && new Date().getTime() - this.ultimaSolicitacaoBusca < Constants.DELAY_EXECUTAR_BUSCA_POR_DIGITACAO) {
          return
        }

        let offset = null
        let limit = null

        if (itemsPerRequest) {
          if (opts.action === 'load') {
            this.offset = 0
            offset = this.offset
            limit = itemsPerRequest
          } else if (opts.action === 'loadmore') {
            offset = this.offset
            limit = itemsPerRequest
          } else if (opts.action === 'refresh') {
            offset = 0
            limit = this.offset * itemsPerRequest
          }
        }

        const params = Object.assign({ offset: offset, limit: limit }, getRequestParametersFunction ? getRequestParametersFunction() : {})

        getAPI({
          url: endpoint,
          params: params,
          contextoUsuario: contextoUsuario,
          requerAutorizacao: true,
          onSuccess: (response) => {
            if (this.mounted) {
              if (requestCount === this.requestCount) {
                if (limit) {
                  if (opts.action === 'load') {
                    this.offset += limit
                  } else if (opts.action === 'loadmore') {
                    this.offset += limit
                    this.lastIndexLoadedByLoadMore = opts.index
                  } else if (opts.action === 'refresh') {
                    opts.offset = limit
                  }
                  if (limit > response.data.length) {
                    this.canLoadMore = false
                  } else {
                    this.canLoadMore = true
                  }
                }
                this.lastLoad = new Date().getTime()
                this.loading = false
                if (onSuccess) {
                  onSuccess({ items: response.data, action: opts.action, offset: offset })
                }
              }
            }
          },
          onError: (response) => {
            if (this.mounted) {
              if (requestCount === this.requestCount) {
                this.loading = false
                if (onError) {
                  onError(response)
                }
              }
            }
          }
        })
      },
      opts.delay ? Constants.DELAY_EXECUTAR_BUSCA_POR_DIGITACAO + 15 : 0
    )
  }

  render() {
    return []
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.customKey !== this.props.customKey) {
      this.customKey = this.props.customKey
      this.request({ action: 'load' })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }
}

export default VirtualizedListLoader
