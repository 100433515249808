export function formatarValorNumero(valor, opts) {
  if (valor === null || valor === undefined) {
    return ''
  }

  if (typeof valor === 'string') {
    valor = parseFloat(valor)
  }

  if (opts === undefined) {
    opts = {}
  }

  var decimalScale = isNaN((opts.decimalScale = Math.abs(opts.decimalScale))) ? 2 : opts.decimalScale,
    decimalSeparator = opts.decimalSeparator === undefined ? ',' : opts.decimalSeparator,
    thousandSeparator = opts.thousandSeparator === undefined ? '.' : opts.thousandSeparator,
    s = valor < 0 ? '-' : '',
    i = String(parseInt((valor = Math.abs(Number(valor) || 0).toFixed(decimalScale)))),
    j = i.length > 3 ? i.length % 3 : 0

  let valorFormatado =
    s +
    (j ? i.substr(0, j) + thousandSeparator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
    (decimalScale
      ? decimalSeparator +
        Math.abs(valor - i)
          .toFixed(decimalScale)
          .slice(2)
      : '')

  if (opts.fixedDecimalScale !== undefined && opts.fixedDecimalScale === false) {
    valorFormatado = valorFormatado.replace(/[0]{1,2}$/g, '').replace(/,$/g, '')
  }

  return valorFormatado
}

export function formatarValorMonetario(valor, opts) {
  if (opts === undefined) {
    opts = {}
  }

  opts.decimalSeparator = ','
  opts.thousandSeparator = '.'

  if (opts.fixedDecimalScale === undefined) {
    opts.fixedDecimalScale = true
  }

  let negativo = false

  if ((typeof valor === 'string' || valor instanceof String) && !isNaN(valor)) {
    valor = Number(valor)
  }
  if (typeof valor === 'number') {
    if (valor < 0) {
      negativo = true
      valor = valor * -1
    }
  }

  var valorFormatado = formatarValorNumero(valor, opts)

  if (valorFormatado !== null && valorFormatado !== undefined && valorFormatado !== '') {
    if (opts.prefix === undefined || opts.prefix === true) {
      valorFormatado = 'R$ ' + valorFormatado
      if (negativo) {
        valorFormatado = ' ' + valorFormatado
      }
    }

    if (negativo) {
      valorFormatado = '-' + valorFormatado
    }
  }

  return valorFormatado
}

export function formatarValorPercentual(valor, opts) {
  if (opts === undefined) {
    opts = {}
  }

  opts.decimalSeparator = ','
  opts.thousandSeparator = '.'

  if (opts.fixedDecimalScale === undefined) {
    opts.fixedDecimalScale = false
  }

  var valorFormatado = formatarValorNumero(valor, opts)

  if (opts.suffix === undefined || opts.suffix === true) {
    valorFormatado = valorFormatado + '%'
  }

  return valorFormatado
}
