import React, { Component } from 'react'
import { isIOS } from 'react-device-detect'
import { Redirect } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import StoreIcon from '@material-ui/icons/Store'
import { User, withConfigCatClient } from 'configcat-react'
import logo from 'img/logo_salao99_enterprise_preto.png'
import moment from 'moment'
import NegocioDadosBasicosDialogPage from 'pages/erp/painel/negocio/NegocioDadosBasicosDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { ROUTE_ENTERPRISE_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PermissoesAcesso from 'support/domain/colaborador/PermissoesAcesso'
import NegocioPagamentoService from 'support/domain/negociopagamento/NegocioPagamentoService'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { logAGE, logCRM } from 'support/util/Logger'

const styles = (theme) => ({
  colunaIcon: {
    maxWidth: 40,
    minWidth: 40
  },
  colunaPagamento: {
    maxWidth: 110,
    minWidth: 110
  },
  nomeNegocioSelecionado: {
    fontWeight: 'bold'
  },
  containerErroPermissao: containerStatusStyle(theme.palette.text.disabled),
  containerEmpresaDesativada: containerStatusStyle(theme.palette.text.disabled),
  containerEmpresaPlanoVencido: containerStatusStyle(theme.palette.text.negative),
  containerSelecionado: {
    padding: 6,
    background: '#eee',
    fontSize: 12,
    marginTop: 2,
    display: 'inline-block',
    borderRadius: 5
  },
  paperTipoUso: {
    ...theme.container.card({ fullHeight: true })
  }
})

function containerStatusStyle(color) {
  return {
    marginTop: 2,
    color: color,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '18px',
    '& svg': {
      verticalAlign: 'text-bottom',
      height: 16,
      width: 16,
      marginRight: 2
    }
  }
}

class NegociosPanelPage extends Component {
  constructor(props) {
    super(props)
    this.mounted = true
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false,
      primeiroNegocioCriado: true,
      primeiroAcessoConcluido: false
    }
    this.campaigns = ['fbads', 'blzpro']
    this.inputNomeFiltro = React.createRef()
    this.virtualizedListFunctionMap = {}
    this.dataLayer = window.dataLayer || []
  }

  componentDidMount() {
    this.eventsManager.sub('ManutencaoNegocio', (props) => {
      if (props.action === 'create') {
        this.setState({ primeiroNegocioCriado: true })

        EventsManager.pub('SelecionarNegocio', {
          colaborador: props.negocio.colaboradorCriador,
          negocio: props.negocio
        })

        if (this.loaderFunctionsMap.load !== undefined) {
          this.loaderFunctionsMap.load()
        }
      } else {
        EventsManager.pub('AtualizarSessao')
        if (this.props.colaboradorSessao.carregado === true && this.loaderFunctionsMap.load !== undefined) {
          this.loaderFunctionsMap.load()
        }
      }
    })
  }

  prepararLista = (colaboradores) => {
    const listaSelecionados = []
    const listaNaoSelecionados = []
    for (let colaborador of colaboradores) {
      let colaboradorSelecionado = false
      if (colaborador.id === this.props.colaboradorSessao.id) {
        colaboradorSelecionado = true
      }
      if (colaboradorSelecionado) {
        listaSelecionados.push(colaborador)
      } else if (PermissoesAcesso.verificarAutorizacao(['ACESSO_SISTEMA'], colaborador.permissoes) && !colaborador.excluido) {
        listaNaoSelecionados.push(colaborador)
      }
    }
    return listaSelecionados.concat(listaNaoSelecionados)
  }

  checkCurrentCampaign = (campaigns) => {
    return campaigns.includes(localStorage.getItem('S99_Campaign'))
  }

  createStandardNegocio = () => {
    this.setState({ ajaxing: true })
    postAPI({
      url: 'erp/negocios.persistir',
      data: this.montarObjetoNegocio(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState(() => ({ ajaxing: false }))
        this.negocioCriado = response.data
        try {
          this.dataLayer.push({
            event: 'event',
            eventCategory: 'salao99:create-business',
            eventAction: 'callback',
            eventLabel: 'sucesso',
            userId: getSessaoPainel().usuario.id || '',
            businessId: this.negocioCriado.id || '',
            expressAdhesion: true
          })
        } catch (error) {
          logAGE(error, { component: 'NegociosPanelPage', context: 'createStandardNegocio' })
        }
        openBackableDialog(MessageDialog, {
          maxWidth: 500,
          text: (
            <span style={{ color: '#222' }}>
              <b>
                Uma nova conta para o seu negócio foi criada com sucesso! Você pode testar o sistema{' '}
                <u>gratuitamente por {this.checkCurrentCampaign(this.campaigns) ? '30 dias' : '7 dias'}</u>
              </b>
              .<br />
              <br />
              Caso tenha alguma dúvida ou precise de ajuda, entre em contato conosco através do nosso Chat de Suporte.
            </span>
          ),
          onClosed: () => {
            this.setState(() => ({ primeiroNegocioCriado: true, primeiroAcessoConcluido: true }))
          }
        })
      },
      onError: () => {
        this.setState(() => ({ ajaxing: false }))
        try {
          EventsManager.pub('MensagemSnackBar', 'Erro inesperado. Tente novamente.')
        } catch {
          logAGE('Ocorreu um erro inesperado na chamada da API', { component: 'NegociosPanelPage' })
        }
      }
    })
  }

  handlerOpenCreateDialog = () => {
    openBackableDialog(NegocioDadosBasicosDialogPage)
  }

  handlerEditarNegocio = (colaborador) => {
    openBackableDialog(NegocioDadosBasicosDialogPage, { idNegocio: colaborador.negocio.id })
  }

  handlerSelecionarNegocio = (colaborador) => {
    postAPI({
      url: 'erp/colaboradores.definirAcessoPadrao',
      data: {
        id: colaborador.id
      },
      requerAutorizacao: true,
      sendErroToGenericSnackbar: false,
      subscriptionEnabled: !isIOS,
      useSubscription: getSessaoPainel().negocio.usaPagamentoRecorrente && !isIOS,
      repeat: {
        stopFunction: () => {
          return !this.mounted
        }
      },
      onSuccess: () => {
        EventsManager.pub('SelecionarNegocio', {
          colaborador: colaborador,
          negocio: colaborador.negocio
        })
      }
    })
  }

  generateHorariosAtendimento = () => {
    const diasSemana = [1, 2, 3, 4, 5, 6]
    const horariosAtendimento = diasSemana.flatMap((dia) => [
      { dia, de: 900, ate: 1200 },
      { dia, de: 1300, ate: 1800 }
    ])
    return horariosAtendimento
  }

  montarObjetoNegocio = () => ({
    nome: 'Meu Negócio',
    telefones: [],
    publicoAlvo: '110',
    segmentosAtuacao: '0000000000',
    endereco: {},
    horariosAtendimento: this.generateHorariosAtendimento()
  })

  handlePlanRedirection = () => {
    const pagamentoRecorrenteUser = getSessaoPainel().negocio.usaPagamentoRecorrente

    if (pagamentoRecorrenteUser) {
      window.location.href = NegocioPagamentoService.gerarUrlDeRedirecionamentoParaPlanos(pagamentoRecorrenteUser)
    }

    return EventsManager.pub('NavegarPaginaPainel', { rota: '/plano' })
  }

  getRenderContent = (size, colaborador) => {
    const { classes } = this.props
    const negocio = colaborador.negocio

    const content = {}

    let tentarEditarFunction = this.handlerEditarNegocio
    if (!negocio.ativo) {
      tentarEditarFunction = () => {
        openBackableDialog(MessageDialog, {
          text: 'Este negócio não está ativo e não pode ser editado.'
        })
      }
    } else if (!PermissoesAcesso.verificarAutorizacao([PermissoesAcesso.PODE_ACESSAR_CONFIG_GERAIS], colaborador.permissoes)) {
      tentarEditarFunction = () => {
        openBackableDialog(AccessDeniedDialog, {
          message: 'Você não tem permissão para editar este negócio.'
        })
      }
    }

    content.isSelecionado = false
    if (colaborador.id === this.props.colaboradorSessao.id) {
      content.isSelecionado = true
    }

    let observacoes = null
    let selecaoHabilitada = true

    if (negocio.ativo === false) {
      if (negocio.dataVencimentoPlano < converterMomentParaDataInt(moment())) {
        observacoes = (
          <div className={classes.containerEmpresaPlanoVencido} style={{ cursor: 'pointer' }} onClick={this.handlePlanRedirection}>
            <InfoIcon /> O plano venceu em {converterDataIntParaMoment(negocio.dataVencimentoPlano).format('DD/MMM/YYYY')} e o cadastro da empresa está desativado.{' '}
            <b>Clique aqui</b> para realizar o pagamento e habilitar o sistema novamente.
          </div>
        )
      } else {
        observacoes = (
          <div className={classes.containerEmpresaDesativada}>
            <InfoIcon /> O cadastro da empresa está desativado
          </div>
        )
      }
    } else if (colaborador.excluido || !PermissoesAcesso.verificarAutorizacao(['ACESSO_SISTEMA'], colaborador.permissoes)) {
      selecaoHabilitada = false
      observacoes = (
        <div className={classes.containerErroPermissao}>
          <InfoIcon /> Você não tem permissão de acesso.
        </div>
      )
    }

    if (content.isSelecionado) {
      if (size === 'small') {
        content.selecionar = (
          <Typography variant="body2" style={{ marginTop: 4, fontSize: 13 }} className={classes.containerSelecionado}>
            <b>Selecionado</b>
          </Typography>
        )
      } else {
        content.selecionar = (
          <b style={{ fontSize: 13 }} className={classes.containerSelecionado}>
            Selecionado
          </b>
        )
      }
    } else {
      const selecionarButtonStyle = {}

      if (size === 'small') {
        selecionarButtonStyle.marginTop = 2
        selecionarButtonStyle.marginLeft = -6
      } else {
        selecionarButtonStyle.marginRight = -4
        selecionarButtonStyle.marginLeft = -4
      }

      content.selecionar = (
        <Button
          style={selecionarButtonStyle}
          disabled={!selecaoHabilitada}
          color="secondary"
          size="small"
          onClick={(event) => {
            event.stopPropagation()
            this.handlerSelecionarNegocio(colaborador)
          }}
        >
          SELECIONAR
        </Button>
      )
    }

    content.icon = <StoreIcon style={{ color: '#666', margin: '16px 0px' }} />
    content.nome = (
      <div style={{ padding: (size === 'large' ? 16 : 0) + 'px 0px' }}>
        <Typography variant="body2">{negocio.nome}</Typography>
        {observacoes}
      </div>
    )

    const editarButtonStyle = {}

    content.editar = (
      <IconButton
        style={editarButtonStyle}
        disabled={!selecaoHabilitada}
        onClick={(event) => {
          event.stopPropagation()
          tentarEditarFunction(colaborador)
        }}
      >
        <EditIcon />
      </IconButton>
    )

    return content
  }

  render() {
    if (SessaoUsuario.isUsuarioLogado() && this.state.primeiroAcessoConcluido === true) {
      return <Redirect to={ROUTE_ENTERPRISE_MAIN} />
    }

    const { classes, ...others } = this.props

    let title = 'Negócios / Empresas'
    let toolbarPaddingTop = null
    let maxWidth = null
    let align = null
    let toolbarActions = {
      actions: [
        {
          icon: <AddIcon />,
          handleAction: this.handlerOpenCreateDialog
        }
      ]
    }

    if (!getSessaoPainel().colaborador || !getSessaoPainel().colaborador.carregado) {
      title = <img src={logo} width="200" alt="Salão99 Enterprise" />
      toolbarPaddingTop = 12
      maxWidth = 600
      align = 'center'
      toolbarActions = {
        actions: [
          {
            label: 'Sair',
            handleAction: () => {
              EventsManager.pub('Logout')
            }
          }
        ]
      }
    }

    return (
      <PanelPage {...others} title={title} contentMaxWidth={maxWidth} align={align} toolbarPaddingTop={toolbarPaddingTop} toolbarActions={toolbarActions}>
        {this.state.primeiroNegocioCriado === true && (
          <FloatContent type="filter">
            <Grid container alignItems="center">
              <Grid item xs>
                <Grid container alignItems="center" justify="center">
                  <Grid item style={{ marginRight: 16 }}>
                    <SearchIcon color="secondary" />
                  </Grid>
                  <Grid item xs>
                    <InputText
                      customVariant="naked"
                      nakedLeftPadding={false}
                      placeholder="Procurar por nome..."
                      onChange={(event) => {
                        this.loaderFunctionsMap.load()
                      }}
                      inputRef={this.inputNomeFiltro}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FloatContent>
        )}

        {this.state.primeiroNegocioCriado === false && (
          <div style={{ textAlign: 'left' }}>
            <div className={classes.paperTipoUso} style={{ marginTop: 20 }}>
              <div style={{ padding: '2px 6px 6px' }}>
                <Typography variant="h5" style={{ fontSize: 26, fontWeight: 500 }}>
                  Para Proprietários
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8, fontSize: '0.95rem' }}>
                  Caso você seja o responsável ou proprietário de um negócio, clique no botão abaixo para criar a conta da sua empresa.{' '}
                </Typography>
                <Button
                  style={{ marginTop: 16, paddingTop: 10, paddingBottom: 10 }}
                  disabled={this.state.ajaxing}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={this.createStandardNegocio}
                >
                  <StoreIcon style={{ marginRight: 10 }} />
                  {this.state.ajaxing ? 'PROCESSANDO AGUARDE...' : 'CRIAR NOVO NEGÓCIO'}
                </Button>
              </div>
            </div>

            <div className={classes.paperTipoUso} style={{ marginTop: 30 }}>
              <div style={{ padding: '2px 6px 6px' }}>
                <Typography variant="h5" style={{ fontSize: 26, fontWeight: 500 }}>
                  Para Funcionários
                </Typography>
                <Typography variant="body2" style={{ marginTop: 8, fontSize: '0.95rem' }}>
                  Se você já trabalha em um estabelecimento que utiliza o Salão99, solicite ao administrador do sistema que adicione o seu e-mail{' '}
                  <b>{SessaoUsuario.getUsuario().email}</b> no cadastro de funcionários. Depois de cadastrado, atualize esta página.
                </Typography>
                <Button
                  style={{ marginTop: 16, paddingTop: 10, paddingBottom: 10 }}
                  disabled={this.state.ajaxing}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  <RefreshIcon style={{ marginRight: 10 }} />
                  {'ATUALIZAR PÁGINA'}
                </Button>
              </div>
            </div>
          </div>
        )}

        <br />

        <VirtualizedResponsiveTable
          dynamicHeight={true}
          showRowLoadingMessage={false}
          showHeaderColumns={false}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/colaboradores.buscarColaboradoresPorUsuario"
          itemsPerRequest={30}
          getRequestParametersFunction={() => ({
            nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null
          })}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({
                primeiroNegocioCriado:
                  (this.inputNomeFiltro.current && this.inputNomeFiltro.current.value && this.inputNomeFiltro.current.value.length > 0) || (data.items && data.items.length)
                    ? true
                    : false,
                lista: this.prepararLista(data.items)
              })
            }
          }}
          largeRenderProps={{
            columns: [
              { label: null, className: classes.colunaIcon },
              { label: null, props: { xs: true }, horizontalPadding: 'small' },
              { label: null, className: classes.colunaPagamento, horizontalPadding: 'small', isVisible: false, wrap: false, align: 'center' },
              { label: null, cellPaddingDiff: 14, horizontalPadding: 'small', align: 'right', wrap: false }
            ],
            itemRenderer: (colaborador, index) => {
              const content = this.getRenderContent('large', colaborador)
              return {
                onClickRow: content.onClickRow,
                itemData: [content.icon, content.nome, content.selecionar, content.editar]
              }
            }
          }}
          smallRenderProps={{
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                onClickRow: content.onClickRow,
                itemData: (
                  <div>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <div style={{ padding: '16px 0px' }}>
                          {content.nome}
                          {content.selecionar}
                        </div>
                      </Grid>
                      {
                        <Grid item style={{ marginLeft: 4 }}>
                          {content.editar}
                        </Grid>
                      }
                    </Grid>
                  </div>
                )
              }
            }
          }}
          emptyListProps={{
            show: this.state.primeiroNegocioCriado,
            text: 'Nenhuma empresa foi encontrada'
          }}
          listFunctionsMap={this.virtualizedListFunctionMap}
        />
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.mounted = false
    this.eventsManager.unsubscribe()
  }

  componentDidUpdate() {
    if (this.virtualizedListFunctionMap && this.virtualizedListFunctionMap.recomputeRowHeights) {
      this.virtualizedListFunctionMap.recomputeRowHeights(this.state.lista.length - 1)
    }
  }
}

NegociosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired,
  negocioSessao: PropTypes.object,
  usuarioSessao: PropTypes.object,
  getValue: PropTypes.func
}

export default withStyles(styles)(NegociosPanelPage)
