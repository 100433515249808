import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import ColaboradorDadosBasicosDialogPage from 'pages/erp/painel/colaborador/ColaboradorDadosBasicosDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import DefinicaoComissoesResponsiveTable from 'pages/erp/painel/servico/DefinicaoComissoesResponsiveTable'
import ServicoDadosBasicosDialogPage from 'pages/erp/painel/servico/ServicoDadosBasicosDialogPage'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import { DEFAULT_TIPO_CALCULO as DEFAULT_TIPO_CALCULO_COMISSAO, DEFAULT_VALOR as DEFAULT_VALOR_COMISSAO } from 'support/components/input/InputPorcentagemValor'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({})

class InterfaceDefinicaoComissoes extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.listFunctionsMap = {}
  }

  componentDidMount() {
    if (this.props.entidade === 'servico') {
      this.eventsManager.sub('ManipulacaoServico', (props) => {
        this.loaderFunctionsMap.load()
      })
    } else if (this.props.entidade === 'colaborador') {
      this.eventsManager.sub('ManipulacaoColaborador', (props) => {
        this.loaderFunctionsMap.load()
      })
    }
    if (this.props.functionsMap) {
      if (this.props.entidade === 'servico') {
        this.props.functionsMap.salvar = this.salvarServicos
      } else if (this.props.entidade === 'colaborador') {
        this.props.functionsMap.salvar = this.salvarColaboradores
      }
      this.props.functionsMap.load = () => {
        this.loaderFunctionsMap.load()
      }
    }
  }

  prepararListaServicos = (lista) => {
    if (!lista) {
      return []
    }

    this.servicosPorCategoria = {}
    const listaPreparada = []
    let itemCategoriaAtual = null

    let exibirCategorias = true
    let exibirServicosNaoSelecionados = true

    if (this.props.acao === 'configurarComissoes') {
      exibirCategorias = false
      exibirServicosNaoSelecionados = false
    }

    for (let item of lista) {
      if (!itemCategoriaAtual || itemCategoriaAtual.id !== item.id) {
        itemCategoriaAtual = {
          _checked: true,
          _update: true,
          tipo: 'categoria',
          id: item.id,
          nome: item.nome
        }
        this.servicosPorCategoria[itemCategoriaAtual.id] = {
          itemCategoria: itemCategoriaAtual,
          itensServicos: []
        }
        if (exibirCategorias) {
          listaPreparada.push(itemCategoriaAtual)
        }
      }

      if (!item.servicos || item.servicos.length === 0) {
        itemCategoriaAtual._checked = false
      } else {
        const servico = item.servicos[0]

        let colaboradorServico = {}
        let servicoSelecionado = false
        if (servico.colaboradoresServicos && servico.colaboradoresServicos.length > 0) {
          colaboradorServico = servico.colaboradoresServicos[0]
          servicoSelecionado = true
        }

        if (!servicoSelecionado) {
          itemCategoriaAtual._checked = false
        }

        const itemServico = {
          _checked: servicoSelecionado,
          _update: true,
          tipo: 'servico',
          id: servico.id,
          nome: servico.nome,
          categoria: {
            id: item.id,
            nome: item.nome
          },
          colaboradorServico: {
            id: colaboradorServico.id,
            comissaoSozinho:
              colaboradorServico.comissaoSozinho !== undefined && colaboradorServico.comissaoSozinho !== null ? colaboradorServico.comissaoSozinho : DEFAULT_VALOR_COMISSAO,
            tipoCalculoComissaoSozinho: colaboradorServico.tipoCalculoComissaoSozinho ? colaboradorServico.tipoCalculoComissaoSozinho : DEFAULT_TIPO_CALCULO_COMISSAO,
            comissaoComAssistente:
              colaboradorServico.comissaoComAssistente !== undefined && colaboradorServico.comissaoComAssistente !== null
                ? colaboradorServico.comissaoComAssistente
                : DEFAULT_VALOR_COMISSAO,
            descontarComissaoComAssistente:
              colaboradorServico.descontarComissaoComAssistente !== undefined && colaboradorServico.descontarComissaoComAssistente !== null
                ? colaboradorServico.descontarComissaoComAssistente
                : false,
            tipoCalculoComissaoComAssistente: colaboradorServico.tipoCalculoComissaoComAssistente
              ? colaboradorServico.tipoCalculoComissaoComAssistente
              : DEFAULT_TIPO_CALCULO_COMISSAO,
            comissaoComoAssistente:
              colaboradorServico.comissaoComoAssistente !== undefined && colaboradorServico.comissaoComoAssistente !== null
                ? colaboradorServico.comissaoComoAssistente
                : DEFAULT_VALOR_COMISSAO,
            tipoCalculoComissaoComoAssistente: colaboradorServico.tipoCalculoComissaoComoAssistente
              ? colaboradorServico.tipoCalculoComissaoComoAssistente
              : DEFAULT_TIPO_CALCULO_COMISSAO
          }
        }

        if (exibirServicosNaoSelecionados || servicoSelecionado) {
          listaPreparada.push(itemServico)
        }

        this.servicosPorCategoria[itemCategoriaAtual.id].itensServicos.push(itemServico)
      }
    }

    return listaPreparada
  }

  prepararListaColaboradores = (lista) => {
    if (!lista) {
      return []
    }

    let exibirColaboradoresNaoSelecionados = true

    if (this.props.acao === 'configurarComissoes') {
      exibirColaboradoresNaoSelecionados = false
    }

    const listaPreparada = []
    for (let colaborador of lista) {
      let colaboradorServico = {}
      let colaboradorSelecionado = false
      if (colaborador.colaboradoresServicos && colaborador.colaboradoresServicos.length > 0) {
        colaboradorServico = colaborador.colaboradoresServicos[0]
        colaboradorSelecionado = true
      }

      const itemColaborador = {
        _checked: colaboradorSelecionado,
        id: colaborador.id,
        nome: colaborador.apelido,
        colaboradorServico: {
          id: colaboradorServico.id,
          comissaoSozinho:
            colaboradorServico.comissaoSozinho !== undefined && colaboradorServico.comissaoSozinho !== null ? colaboradorServico.comissaoSozinho : DEFAULT_VALOR_COMISSAO,
          tipoCalculoComissaoSozinho: colaboradorServico.tipoCalculoComissaoSozinho ? colaboradorServico.tipoCalculoComissaoSozinho : DEFAULT_TIPO_CALCULO_COMISSAO,
          comissaoComAssistente:
            colaboradorServico.comissaoComAssistente !== undefined && colaboradorServico.comissaoComAssistente !== null
              ? colaboradorServico.comissaoComAssistente
              : DEFAULT_VALOR_COMISSAO,
          descontarComissaoComAssistente:
            colaboradorServico.descontarComissaoComAssistente !== undefined && colaboradorServico.descontarComissaoComAssistente !== null
              ? colaboradorServico.descontarComissaoComAssistente
              : false,
          tipoCalculoComissaoComAssistente: colaboradorServico.tipoCalculoComissaoComAssistente
            ? colaboradorServico.tipoCalculoComissaoComAssistente
            : DEFAULT_TIPO_CALCULO_COMISSAO,
          comissaoComoAssistente:
            colaboradorServico.comissaoComoAssistente !== undefined && colaboradorServico.comissaoComoAssistente !== null
              ? colaboradorServico.comissaoComoAssistente
              : DEFAULT_VALOR_COMISSAO,
          tipoCalculoComissaoComoAssistente: colaboradorServico.tipoCalculoComissaoComoAssistente
            ? colaboradorServico.tipoCalculoComissaoComoAssistente
            : DEFAULT_TIPO_CALCULO_COMISSAO
        }
      }

      if (exibirColaboradoresNaoSelecionados || colaboradorSelecionado) {
        listaPreparada.push(itemColaborador)
      }
    }
    return listaPreparada
  }

  changeCheckboxCategoria = (checked, index) => {
    if (this.props.onDataChange) {
      this.props.onDataChange()
    }

    const lista = this.props.lista
    const itemCategoria = lista[index]
    itemCategoria._checked = checked
    itemCategoria._update = true

    for (let servico of this.servicosPorCategoria[itemCategoria.id].itensServicos) {
      servico._checked = checked
      servico._update = true
    }
    this.props.onChange({ lista: this.props.lista })
  }

  changeCheckboxServico = (checked, index) => {
    if (this.props.onDataChange) {
      this.props.onDataChange()
    }

    const lista = this.props.lista
    const itemServico = lista[index]
    itemServico._checked = checked
    itemServico._update = true

    let categoriaSelecionada = true
    for (let servico of this.servicosPorCategoria[itemServico.categoria.id].itensServicos) {
      if (servico._checked === false) {
        categoriaSelecionada = false
        break
      }
    }

    const itemCategoria = this.servicosPorCategoria[itemServico.categoria.id].itemCategoria
    itemCategoria._checked = categoriaSelecionada
    itemCategoria._update = true

    this.props.onChange({ lista: lista })
  }

  changeCheckboxColaborador = (checked, index) => {
    if (this.props.onDataChange) {
      this.props.onDataChange()
    }
    const lista = this.props.lista
    const itemColaborador = lista[index]
    itemColaborador._checked = checked
    itemColaborador._update = true
    this.props.onChange({ lista: lista })
  }

  loadTrackingFunction = (data) => {
    if (data.status === 'loading') {
      if (this.props.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
        this.props.onChange({
          errorsMap: FormUtil.createErrorsMap(),
          lista: [],
          paginaCarregada: false
        })
      }
    } else if (data.status === 'loaded') {
      let lista = []
      if (this.props.entidade === 'servico') {
        lista = this.prepararListaServicos(data.items)
      } else if (this.props.entidade === 'colaborador') {
        lista = this.prepararListaColaboradores(data.items)
      }
      this.props.onChange({
        lista: lista,
        paginaCarregada: true
      })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.saveFunction()
  }

  getDadosServicos = () => {
    const colaboradoresServicos = []
    for (let item of this.props.lista) {
      if (item.tipo === 'servico' && item._checked === true) {
        const colaboradorServico = {}
        colaboradorServico.idServico = item.id
        colaboradorServico.comissaoSozinho = FormUtil.convertStringToNumber(item.colaboradorServico.comissaoSozinho)
        colaboradorServico.comissaoComAssistente = FormUtil.convertStringToNumber(item.colaboradorServico.comissaoComAssistente)
        colaboradorServico.descontarComissaoComAssistente = item.colaboradorServico.descontarComissaoComAssistente
        colaboradorServico.comissaoComoAssistente = FormUtil.convertStringToNumber(item.colaboradorServico.comissaoComoAssistente)
        colaboradorServico.tipoCalculoComissaoSozinho = item.colaboradorServico.tipoCalculoComissaoSozinho
        colaboradorServico.tipoCalculoComissaoComAssistente = item.colaboradorServico.tipoCalculoComissaoComAssistente
        colaboradorServico.tipoCalculoComissaoComoAssistente = item.colaboradorServico.tipoCalculoComissaoComoAssistente
        if (item.colaboradorServico.id) {
          colaboradorServico.id = item.colaboradorServico.id
        }
        colaboradoresServicos.push(colaboradorServico)
      }
    }
    return {
      idColaborador: this.props.colaborador.id,
      colaboradoresServicos: colaboradoresServicos
    }
  }

  getDadosColaboradores = () => {
    const colaboradoresServicos = []
    for (let item of this.props.lista) {
      if (item._checked === true) {
        const colaboradorServico = {}
        colaboradorServico.idColaborador = item.id
        colaboradorServico.comissaoSozinho = FormUtil.convertStringToNumber(item.colaboradorServico.comissaoSozinho)
        colaboradorServico.comissaoComAssistente = FormUtil.convertStringToNumber(item.colaboradorServico.comissaoComAssistente)
        colaboradorServico.descontarComissaoComAssistente = item.colaboradorServico.descontarComissaoComAssistente
        colaboradorServico.comissaoComoAssistente = FormUtil.convertStringToNumber(item.colaboradorServico.comissaoComoAssistente)
        colaboradorServico.tipoCalculoComissaoSozinho = item.colaboradorServico.tipoCalculoComissaoSozinho
        colaboradorServico.tipoCalculoComissaoComAssistente = item.colaboradorServico.tipoCalculoComissaoComAssistente
        colaboradorServico.tipoCalculoComissaoComoAssistente = item.colaboradorServico.tipoCalculoComissaoComoAssistente
        if (item.colaboradorServico.id) {
          colaboradorServico.id = item.colaboradorServico.id
        }
        colaboradoresServicos.push(colaboradorServico)
      }
    }
    return {
      idServico: this.props.servico.id,
      colaboradoresServicos: colaboradoresServicos
    }
  }

  getRequestParametersServicosFunction = () => {
    return {
      idColaborador: this.props.colaborador.id,
      carregarVariacoes: false
    }
  }

  getRequestParametersColaboradoresFunction = () => {
    return {
      idServico: this.props.servico.id
    }
  }

  salvarServicos = (onStart, onSuccess, onError) => {
    const lista = this.props.lista
    for (let posicao = 0; posicao < lista.length; posicao++) {
      const item = lista[posicao]
      item._update = true
    }

    this.props.onChange({
      ajaxing: true,
      errorsMap: FormUtil.createErrorsMap()
    })

    if (onStart) onStart()

    postAPI({
      url: 'erp/servicos.alterarColaboradorServicos',
      data: this.getDadosServicos(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        if (onSuccess) onSuccess('Configurações salvas com sucesso.')
        this.props.onChange({ ajaxing: false, lista: this.prepararListaServicos(response.data) })
        EventsManager.pub('AssociacaoServicoColaborador')
        if (this.props.onSaved) {
          this.props.onSaved()
        }
      },
      onError: (response) => {
        if (onError) onError()
        if (response.data && response.data.errors) {
          let idServicoErro = null
          const errorsKeys = Object.keys(response.data.errors)
          let scrollToIndex = -1
          if (errorsKeys.length > 0) {
            for (let i = 0; i < errorsKeys.length; i++) {
              let errorKey = errorsKeys[i]
              if (errorKey.startsWith('servico:')) {
                idServicoErro = parseInt(errorKey.split(':')[1].split('.')[0], 10)
                break
              }
            }
          }

          for (let posicao = 0; posicao < lista.length; posicao++) {
            const item = lista[posicao]
            if (item.tipo === 'servico') {
              item._update = true
              if (item.id === idServicoErro) {
                scrollToIndex = posicao
                break
              }
            }
          }

          this.props.onChange({ ajaxing: false, scrollToIndex: scrollToIndex, lista: this.props.lista, errorsMap: FormUtil.createErrorsMap(response.data.errors) })
          this.listFunctionsMap.scrollToIndex(scrollToIndex)
        }
      }
    })
  }

  salvarColaboradores = (onStart, onSuccess, onError) => {
    const lista = this.props.lista
    for (let posicao = 0; posicao < lista.length; posicao++) {
      const item = lista[posicao]
      item._update = true
    }

    this.props.onChange({
      ajaxing: true,
      errorsMap: FormUtil.createErrorsMap()
    })

    if (onStart) onStart()

    postAPI({
      url: 'erp/colaboradores.alterarColaboradorServicos',
      data: this.getDadosColaboradores(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        if (onSuccess) onSuccess('Configurações salvas com sucesso.')
        this.props.onChange({
          ajaxing: false
        })
        const lista = this.prepararListaColaboradores(response.data)
        this.props.onChange({ lista: lista })
        EventsManager.pub('AssociacaoServicoColaborador')
        if (this.props.onSaved) {
          this.props.onSaved()
        }
      },
      onError: (response) => {
        if (onError) onError()
        if (response.data && response.data.errors) {
          let idColaboradorErro = null
          const errorsKeys = Object.keys(response.data.errors)
          let scrollToIndex = -1
          if (errorsKeys.length > 0) {
            for (let i = 0; i < errorsKeys.length; i++) {
              let errorKey = errorsKeys[i]
              if (errorKey.startsWith('colaborador:')) {
                idColaboradorErro = parseInt(errorKey.split(':')[1].split('.')[0], 10)
                break
              }
            }
          }

          for (let posicao = 0; posicao < lista.length; posicao++) {
            const item = lista[posicao]
            item._update = true
            if (item.id === idColaboradorErro) {
              scrollToIndex = posicao
              break
            }
          }

          this.props.onChange({ ajaxing: false, scrollToIndex: scrollToIndex, lista: this.props.lista, errorsMap: FormUtil.createErrorsMap(response.data.errors) })
          this.listFunctionsMap.scrollToIndex(scrollToIndex)
        }
      }
    })
  }

  render() {
    const { errorsMap, ajaxing } = this.props

    const podePersistirColaborador = verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])

    let endpoint = null
    let getRequestParametersFunction = null
    let emptyListProps = {}

    if (this.props.entidade === 'servico') {
      endpoint = 'erp/servicos.buscar'
      getRequestParametersFunction = this.getRequestParametersServicosFunction
      if (this.props.acao === 'selecionarEntidade') {
        emptyListProps.text = 'Nenhum serviço foi cadastrado'
        emptyListProps.padding = true
        emptyListProps.textButton = 'Cadastrar Serviço'
        emptyListProps.creationFunction = () => {
          if (verificarAutorizacao([PA.PODE_ALTERAR_CAD_SERVICOS])) {
            openBackableDialog(ServicoDadosBasicosDialogPage, { abrirServicoDepoisCriacao: false })
          } else {
            openBackableDialog(AccessDeniedDialog)
          }
        }
      } else if (this.props.acao === 'configurarComissoes') {
        emptyListProps.text = 'Nenhum serviço foi vinculado a este colaborador. Acesse a tela do colaborador, selecione os serviço que ele pode realizar e salve os dados.'
      }
    } else if (this.props.entidade === 'colaborador') {
      endpoint = 'erp/colaboradores.buscar'
      getRequestParametersFunction = this.getRequestParametersColaboradoresFunction
      if (this.props.acao === 'selecionarEntidade') {
        emptyListProps.text = 'Nenhum colaborador foi cadastrado'
        emptyListProps.padding = true
        emptyListProps.textButton = 'Cadastrar Colaborador'
        emptyListProps.creationFunction = () => {
          if (verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])) {
            openBackableDialog(ColaboradorDadosBasicosDialogPage, { abrirColaboradorDepoisCriacao: false })
          } else {
            openBackableDialog(AccessDeniedDialog)
          }
        }
      } else if (this.props.acao === 'configurarComissoes') {
        emptyListProps.text = 'Nenhum colaborador foi vinculado a este serviço. Acesse a tela do serviço, selecione os colaboradores que podem realizá-lo e salve os dados.'
      }
    }

    return (
      <form id="formColaboradorServico" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
        <DefinicaoComissoesResponsiveTable
          entidade={this.props.entidade}
          acao={this.props.acao}
          errorsMap={errorsMap}
          disabled={ajaxing || !podePersistirColaborador}
          scrollElement={this.props.scrollElement}
          scrollToIndex={this.props.scrollToIndex}
          listFunctionsMap={this.listFunctionsMap}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint={endpoint}
          getRequestParametersFunction={getRequestParametersFunction}
          items={this.props.lista}
          loadTrackingFunction={this.loadTrackingFunction}
          changeCheckboxCategoria={this.changeCheckboxCategoria}
          changeCheckboxServico={this.changeCheckboxServico}
          changeCheckboxColaborador={this.changeCheckboxColaborador}
          onDataChange={this.props.onDataChange}
          emptyListProps={emptyListProps}
        />
        <FormUtil.HiddenSubmitButton />
        {this.props.acao === 'selecionarEntidade' && <br />}
      </form>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

InterfaceDefinicaoComissoes.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InterfaceDefinicaoComissoes)
