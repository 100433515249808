import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  ABERTURA: { id: 1, descricao: 'Abertura de Caixa' },
  FECHAMENTO: { id: 2, descricao: 'Fechamento de Caixa' },
  DESPESA: { id: 3, descricao: 'Despesa' },
  PAGAMENTO: { id: 4, descricao: 'Transação' },
  ENTRADA: { id: 5, descricao: 'Entrada dinheiro' },
  SANGRIA: { id: 6, descricao: 'Sangria' },
  REABERTURA: { id: 7, descricao: 'Reabertura de Caixa' }
})

export default enumObject
