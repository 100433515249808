import { styled } from '@grupoboticario/flora'
import { Text } from '@grupoboticario/flora-react'

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '8px',
  border: '1px solid #0000003D',
  cursor: 'pointer',
  maxHeight: '116px'
})

export const WrapperIcons = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '42px',
  maxHeight: '24px',
  marginBottom: '20px',
  '& > .tag': {
    backgroundColor: '#1B57CE',
    textTransform: 'capitalize'
  }
})
export const WrapperContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

export const WrapperTitle = styled('div', {
  display: 'flex',
  flexDirection: 'column'
})

export const Title = styled(Text, {
  fontWeight: 500,
  fontSize: '16px',
  marginBottom: '3px'
})

export const Description = styled(Text, {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#666666',
  '@fullscreen': {
    lineHeight: 'unset'
  }
})
