import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const MAX_WIDTH_PAPER_DEFAULT = 420

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class NFDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      ajaxing: false,
      errorsMap: createErrorsMap(),
      reemitirNf: false
    }

    this.loaderFunctionsMap = {}
    this.inputTomadorNome = React.createRef()
    this.inputEmail = React.createRef()
    this.inputCPFCNPJ = React.createRef()
    this.inputCep = React.createRef()
    this.inputEnderecoComplemento = React.createRef()
    this.inputEnderecoNumero = React.createRef()
    this.inputLogradouro = React.createRef()
    this.inputBairro = React.createRef()
    this.inputCidade = React.createRef()
    this.inputUf = React.createRef()
    this.dataCompetenciaFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/nfs.buscarNFPorId',
      params: {
        id: this.props.nf.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          nf: response.data
        })
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    const dados = {
      id: this.props.nf.id,
      tomadorNome: this.inputTomadorNome.current.value,
      tomadorEmail: this.inputEmail.current.value,
      tomadorCep: this.inputCep.current.value,
      tomadorEnderecoComplemento: this.inputEnderecoComplemento.current.value,
      tomadorEnderecoNumero: this.inputEnderecoNumero.current.value,
      tomadorLogradouro: this.inputLogradouro.current.value,
      tomadorBairro: this.inputBairro.current.value,
      tomadorCidade: this.inputCidade.current.value,
      tomadorUf: this.inputUf.current.value,
      reemitirNf: this.state.reemitirNf,
      dataCompetencia: this.dataCompetenciaFunctionsMap.getDataAsInt()
    }

    let cpfCnpj = null
    if (this.inputCPFCNPJ.current) {
      cpfCnpj = this.inputCPFCNPJ.current.value.replace(/[^0-9]/g, '').trim()

      if (cpfCnpj === '') {
        cpfCnpj = null
      }
    }
    dados.tomadorCpfCnpj = cpfCnpj

    return dados
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'admin/nfs.alterarNf',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoNF', { cliente: response.data })
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formNF')
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, reemitirNf } = this.state
    const {
      id,
      tomadorNome,
      tomadorCpfCnpj,
      tomadorEmail,
      tomadorUf,
      tomadorCidade,
      tomadorBairro,
      tomadorLogradouro,
      tomadorEnderecoNumero,
      tomadorEnderecoComplemento,
      tomadorCep,
      descricaoErroApresentacao,
      nfsDataCompetencia,
      nfsDescricao
    } = this.state.nf ? this.state.nf : {}

    const nfsDataCompetenciaAsMoment = moment(nfsDataCompetencia)

    let maxWidth = MAX_WIDTH_PAPER_DEFAULT

    let title = 'Nota Fiscal'

    if (id) {
      title += ' - ' + id
    }

    return (
      <DialogPage
        {...dialogProps}
        title={title}
        ajaxing={this.state.ajaxing}
        align="center"
        contentMaxWidth={maxWidth}
        pageType="basicEntity"
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formNF" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              {descricaoErroApresentacao && (
                <Typography variant="body2" style={{ padding: 16, background: '#fff6f6', color: '#eb9191', borderRadius: 10, marginBottom: 28 }}>
                  {descricaoErroApresentacao}
                </Typography>
              )}

              <InputText
                idname="xtomadornome"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorNome}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('tomadorNome')}
                label="Nome do tomador"
                inputRef={this.inputTomadorNome}
                inputProps={{
                  maxLength: 255
                }}
              />

              <InputText
                marginTop={true}
                idname="xemail"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorEmail}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('email')}
                label="E-mail"
                inputProps={{
                  maxLength: 255
                }}
                inputRef={this.inputEmail}
              />

              <InputText
                marginTop={true}
                idname="xcpf"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorCpfCnpj}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('tomadorCpfCnpj')}
                label="Tomador CPF/CNPJ"
                inputRef={this.inputCPFCNPJ}
                inputProps={{
                  maxLength: 20
                }}
              />

              <InputData
                functionsMap={this.dataCompetenciaFunctionsMap}
                marginTop={true}
                keyboard={true}
                disabled={this.state.ajaxing}
                id="xdata"
                name="xdata"
                errorMessage={errorsMap('dataCompetencia')}
                defaultValue={nfsDataCompetenciaAsMoment}
                label="Data de Competência"
                customVariant="outlined-small"
                shrink={false}
              />

              <InputText
                marginTop={true}
                idname="xdescricao"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={nfsDescricao}
                disabled={true}
                multiline={true}
                errorMessage={errorsMap('descricao')}
                label="Descrição"
              />

              <InputText
                marginTop={true}
                idname="xuf"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorUf}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('uf')}
                label="UF"
                inputRef={this.inputUf}
                inputProps={{
                  maxLength: 2
                }}
              />

              <InputText
                marginTop={true}
                idname="xcidade"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorCidade}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('cidade')}
                label="Cidade"
                inputRef={this.inputCidade}
                inputProps={{
                  maxLength: 255
                }}
              />

              <InputText
                marginTop={true}
                idname="xbairro"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorBairro}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('bairro')}
                label="Bairro"
                inputRef={this.inputBairro}
                inputProps={{
                  maxLength: 255
                }}
              />

              <InputText
                marginTop={true}
                idname="xlogradouro"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorLogradouro}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('logradouro')}
                label="Logradouro"
                inputRef={this.inputLogradouro}
                inputProps={{
                  maxLength: 255
                }}
              />

              <InputText
                marginTop={true}
                idname="xenderecoNumero"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorEnderecoNumero}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('enderecoNumero')}
                label="Número Endereço"
                inputRef={this.inputEnderecoNumero}
                inputProps={{
                  maxLength: 20
                }}
              />

              <InputText
                marginTop={true}
                idname="xenderecoComplemento"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorEnderecoComplemento}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('enderecoComplemento')}
                label="Complemento Endereço"
                inputRef={this.inputEnderecoComplemento}
                inputProps={{
                  maxLength: 45
                }}
              />

              <InputText
                marginTop={true}
                idname="xcep"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={tomadorCep}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('cep')}
                label="CEP"
                inputRef={this.inputCep}
                inputProps={{
                  maxLength: 10
                }}
              />

              <FormControlLabel
                style={{ marginTop: 10 }}
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.ajaxing}
                    checked={reemitirNf}
                    onChange={(event) => {
                      const checked = event.target.checked
                      this.setState((state) => {
                        state.reemitirNf = checked
                        return { state: state }
                      })
                    }}
                  />
                }
                label={<Typography variant="body2">Reemitir NF</Typography>}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

NFDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NFDialogPage)
