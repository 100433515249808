import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import NativeApp from 'support/components/nativeapp/NativeApp'
import PanelPage from 'support/components/panel/PanelPage'

const useStyles = makeStyles((theme) => ({
  openInNewIcon: {
    marginLeft: 6,
    marginBottom: 2
  },
  paperContent: {
    marginTop: 8
  }
}))

const YT_PLAYLIST_URL = 'https://www.youtube.com/@Salao99/playlists'

const CentraldeAjudaPanelPage = ({ ...others }) => {
  const styles = useStyles()

  const handleOpen = () => {
    if (DeviceIdentifier.isNativeApp()) {
      return NativeApp.executeNativeMethod({
        method: 'openLink',
        url: YT_PLAYLIST_URL
      })
    }
    window.open(YT_PLAYLIST_URL, '_blank')
  }

  return (
    <PanelPage {...others} title="Central de ajuda">
      <div className={styles.paperContent}>
        <Typography variant="body2" style={{ marginTop: 0 }}>
          Explore nossa playlist no Youtube com vídeo passo a passo para aproveitar ao máximo as ferramentas essenciais.
        </Typography>
        <br />
        <Button disabled={false} variant="contained" color="secondary" onClick={handleOpen}>
          ASSISTA AQUI
          <OpenInNewIcon className={styles.openInNewIcon} />
        </Button>
      </div>
    </PanelPage>
  )
}

export default CentraldeAjudaPanelPage
