import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import FluxoCaixaService from 'support/domain/fluxocaixa/FluxoCaixaService'
import TipoNegocioMovimentacaoEnum from 'support/domain/fluxocaixa/TipoNegocioMovimentacaoEnum'
import VisaoFluxoCaixaEnum from 'support/domain/fluxocaixa/VisaoFluxoCaixaEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerDescricao: {
    ...theme.screen.horizontalMarginStyles()
  },
  colunaData: {
    flexGrow: 0.6,
    maxWidth: 108,
    minWidth: 108
  },
  colunaValor: {
    flexGrow: 0.6,
    maxWidth: 108,
    minWidth: 108
  },
  textoTabela: {
    fontSize: 13
  },
  colaboradorContainer: {
    ...theme.text.limitedLines({ maxLines: 3, withEllipsis: false })
  },
  descricaoContainer: {
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

class DetalhesFluxoCaixaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }

    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNotaVendaPagamento', 'ManipulacaoVendas', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Detalhamento de Fluxo de Caixa - ' + formatarPeriodoNomeArquivo(this.props.dataInicial, this.props.dataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'fluxocaixa.gerarListaNegocioMovimentacoes',
        params: this.getRequestParameters()
      }
    })
  }

  getRequestParameters = () => {
    return {
      dataInicial: this.props.dataInicial,
      dataFinal: this.props.dataFinal,
      idTipoNegocioMovimentacao: this.props.tipoNegocioMovimentacao ? this.props.tipoNegocioMovimentacao.id : null,
      idFormaPagamento: this.props.receita ? this.props.receita.idFormaPagamento : null,
      idTipoDespesa: this.props.despesa ? this.props.despesa.idTipoDespesa : null
    }
  }

  getRenderContent = (size, item) => {
    const content = {}
    const { classes } = this.props

    if (size === 'small') {
      content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YY')
    } else {
      content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YYYY')
    }

    content.valor = <span>{formatarValorMonetario(FluxoCaixaService.getValorMovimentacao(item))}</span>
    content.descricaoTipo = <span className={classes.textoTabela}>{FluxoCaixaService.getDescricao(item)}</span>

    let linkAbrirNota = null
    if (FluxoCaixaService.isMovimentacaoReceita(item)) {
      linkAbrirNota = (
        <Link
          component="div"
          style={{ padding: 8, margin: '-6px -8px -8px -8px', fontSize: 13, cursor: 'pointer' }}
          onClick={() => {
            openBackableDialog(NotaDialogPage, {
              idNota: item.receita.pagamento.notaVenda.id
            })
          }}
        >
          <b>Visualizar Fatura</b>
        </Link>
      )
    }

    content.opcao = linkAbrirNota
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, visao, dataInicial, dataFinal, tipoNegocioMovimentacao, receita, despesa } = this.props

    let valorTotalReceita = 0
    let valorTotalDespesa = 0

    for (let item of this.state.lista) {
      if (FluxoCaixaService.isMovimentacaoReceita(item)) {
        valorTotalReceita = valorTotalReceita + item.valor
      } else {
        valorTotalDespesa = valorTotalDespesa + item.valor
      }
    }

    let descricaoTitulo = 'Fluxo de Caixa'
    let descricaoSubtitulo = ''

    if (despesa) {
      descricaoTitulo = despesa.descricao
    } else if (receita) {
      descricaoTitulo = receita.descricao
    } else if (tipoNegocioMovimentacao) {
      descricaoTitulo = TipoNegocioMovimentacaoEnum.getById(tipoNegocioMovimentacao.id).descricaoPlural
    }

    if (visao.id === VisaoFluxoCaixaEnum.DIA.id) {
      descricaoSubtitulo = converterDataIntParaMoment(dataInicial).format('DD/MM/YYYY')
    } else if (visao.id === VisaoFluxoCaixaEnum.SEMANA.id) {
      const descricaoDataInicial = converterDataIntParaMoment(dataInicial).format('DD/MM')
      const descricaoDataFinal = converterDataIntParaMoment(dataFinal).format('DD/MM')
      descricaoSubtitulo = descricaoDataInicial + ' - ' + descricaoDataFinal
    } else if (visao.id === VisaoFluxoCaixaEnum.MES.id) {
      descricaoSubtitulo = converterDataIntParaMoment(dataInicial).format('MMMM/YYYY')
    } else if (visao.id === VisaoFluxoCaixaEnum.ANO.id) {
      descricaoSubtitulo = converterDataIntParaMoment(dataInicial).format('YYYY')
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} align="center" title="Detalhamento" contentMaxWidth={800} ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h6">{descricaoTitulo}</Typography>
              <Typography variant="body1">
                <b>{descricaoSubtitulo}</b>
              </Typography>
            </FloatContent>

            <VirtualizedResponsiveTable
              minTableWidth={650}
              itemsPerRequest={30}
              showBackgroundDividers={true}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/fluxocaixa.buscarNegocioMovimentacoes"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Data', className: classes.colunaData },
                  { label: 'Descrição', props: { xs: true }, horizontalPadding: 'small' },
                  { label: 'Valor', className: classes.colunaValor, align: 'right', horizontsalPadding: 'none' }
                ],
                itemRenderer: (detalhes, index) => {
                  const content = this.getRenderContent('large', detalhes, index)

                  return {
                    itemData: [
                      content.data,
                      <React.Fragment>
                        <Typography variant="body2" className={classes.descricaoContainer}>
                          {content.descricaoTipo}
                        </Typography>
                        {content.opcao}
                      </React.Fragment>,
                      content.valor
                    ]
                  }
                }
              }}
              mediumRenderProps={{
                headerColumnHeight: 68,
                rowHeight: 90,
                columns: [
                  { label: 'Data', className: classes.colunaData },
                  { label: 'Descrição', props: { xs: true }, horizontalPadding: 'small' },
                  { label: 'Valor', className: classes.colunaValor, align: 'right' }
                ],
                itemRenderer: (detalhes, index) => {
                  const content = this.getRenderContent('large', detalhes, index)

                  return {
                    itemData: [
                      content.data,
                      <React.Fragment>
                        <Typography variant="body2" className={classes.descricaoContainer}>
                          {content.descricaoTipo}
                        </Typography>
                        {content.opcao}
                      </React.Fragment>,
                      content.valor
                    ]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 108,
                showFirstItemBorderTop: false,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container>
                          <Grid item xs>
                            <Typography variant="body2">
                              <b>{content.data}</b>
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingLeft: 8, textAlign: 'right' }}>
                            <Typography variant="body2">{content.valor}</Typography>
                          </Grid>
                        </Grid>
                        <Typography variant="body2" className={classes.descricaoContainer}>
                          {content.descricaoTipo}
                        </Typography>
                        {content.opcao}
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum detalhe foi encontrado'
              }}
            />

            {this.state.lista.length > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  {(!tipoNegocioMovimentacao || tipoNegocioMovimentacao.id === TipoNegocioMovimentacaoEnum.RECEITA.id) && (
                    <React.Fragment>
                      Total Receitas: <b>{formatarValorMonetario(valorTotalReceita)}</b>
                      <br />
                    </React.Fragment>
                  )}
                  {(!tipoNegocioMovimentacao || tipoNegocioMovimentacao.id === TipoNegocioMovimentacaoEnum.DESPESA.id) && (
                    <React.Fragment>
                      Total Despesas: <b>{formatarValorMonetario(valorTotalDespesa)}</b>
                      <br />
                    </React.Fragment>
                  )}
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

DetalhesFluxoCaixaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesFluxoCaixaDialogPage)
