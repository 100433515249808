import React, { Component } from 'react'

import AcessoNegadoPanelPage from 'pages/erp/painel/AcessoNegadoPanelPage'
import { verificarAutorizacaoPorRota } from 'pages/erp/painel/PainelErp'
import RouterContent from 'support/components/router/RouterContent'
import SessaoPainelErp from 'support/components/sessao/SessaoPainelErp'

class PanelPageErpContent extends Component {
  render() {
    const { colaboradorObrigatorio, ...others } = this.props

    const dadosSessaoUrl = SessaoPainelErp.getDadosNegocioSessaoFromUrl()
    if (dadosSessaoUrl && dadosSessaoUrl.idNegocio && parseInt(dadosSessaoUrl.idNegocio, 10) !== this.props.negocioSessao.id) {
      return []
    }

    if (colaboradorObrigatorio && (!this.props.colaboradorSessao || !this.props.colaboradorSessao.carregado)) {
      return []
    } else if (!verificarAutorizacaoPorRota()) {
      return <AcessoNegadoPanelPage {...others} />
    } else {
      return <RouterContent {...others} />
    }
  }
}

export default PanelPageErpContent
