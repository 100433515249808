import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import PropTypes from 'prop-types'
import { openBackableUnmanaged } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputText from 'support/components/input/InputText'
import { converterDataIntParaDate, converterDateParaDataInt, converterStringParaDate } from 'support/util/DateConverter'
import { isValidDate } from 'support/util/DateUtil'
import { autoCompleteValueOff } from 'support/util/FormUtil'

const styles = (theme) => ({
  clickable: {
    cursor: 'pointer'
  }
})

function getFormatPatternByFormatAndLib(format) {
  let momentFormatPattern = 'DD/MM/YYYY'
  if (format === 'simplified') {
    momentFormatPattern = 'DD/MM/YY'
  } else if (format === 'onlyYearAndMonth') {
    momentFormatPattern = 'MM/YYYY'
  }
  return momentFormatPattern
}

function CustomInputText(props) {
  const { error, helperText, showPickerIndicator } = props
  let errorMessage = props.errorMessage
  if (error && helperText) {
    errorMessage = helperText
  }

  const inputProps = Object.assign({}, props)
  delete inputProps.onInputChange
  delete inputProps.disableOpenOnEnter
  delete inputProps.showPickerIndicator

  if (showPickerIndicator) {
    if (!inputProps.InputProps) {
      inputProps.InputProps = {}
    }
    if (!inputProps.InputProps.style) {
      inputProps.InputProps.style = {}
    } else {
      inputProps.InputProps.style = Object.assign({}, inputProps.InputProps.style)
    }
    inputProps.InputProps.style.cursor = 'pointer'
    if (!inputProps.inputProps) {
      inputProps.inputProps = {}
    }
    if (!inputProps.inputProps.style) {
      inputProps.inputProps.style = {}
    } else {
      inputProps.inputProps.style = Object.assign({}, inputProps.inputProps.style)
    }
    inputProps.inputProps.style.cursor = 'pointer'
  }

  return <InputText {...inputProps} errorMessage={errorMessage} />
}

class InputData extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      value: this.getDefaultValue(),
      isPickerOpen: false
    }
  }

  getDefaultValue = () => {
    if (this.props.defaultValue) {
      if (isValidDate(this.props.defaultValue)) {
        return this.props.defaultValue
      } else if (moment.isMoment(this.props.defaultValue)) {
        return this.props.defaultValue.toDate()
      } else {
        return converterDataIntParaDate(this.props.defaultValue)
      }
    }
    return null
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getDataAsDate = () => {
        return this.getValue()
      }
      this.props.functionsMap.getDataAsInt = () => {
        return converterDateParaDataInt(this.getValue())
      }
    }
  }

  getValue = () => {
    return converterStringParaDate(this.inputRef.current.value, getFormatPatternByFormatAndLib(this.props.format))
  }

  openPicker = (event) => {
    openBackableUnmanaged({
      onCreateInstance: (instance) => {
        this.backableComponentInstance = instance
        this.setState({ isPickerOpen: true })
      },
      onClosed: () => {
        this.setState({ isPickerOpen: false })
      }
    })
  }

  handleChange = (date) => {
    if (this.props.onChange) {
      this.props.onChange(date)
    }
    this.setState({ value: date, menuAnchorEl: null })
  }

  render() {
    const { classes, keyboard, format, changeOnKeyPress, hideMenuOptions, ...others } = this.props
    delete others.classes
    delete others.value
    delete others.defaultValue

    others.onClose = () => {
      this.backableComponentInstance.props.handleClose()
    }

    others.okLabel = 'OK'
    others.cancelLabel = null
    others.showTodayButton = true
    others.todayLabel = 'Hoje'

    if (this.props.clearable !== false) {
      others.clearable = true
      others.clearLabel = 'LIMPAR'
    }

    others.autoComplete = autoCompleteValueOff
    others.format = getFormatPatternByFormatAndLib(format)
    others.invalidDateMessage = 'Data inválida'
    others.maxDateMessage = 'Data inválida'
    others.minDateMessage = 'Data inválida'
    others.value = this.state.value
    others.onChange = this.handleChange
    others.inputRef = this.inputRef
    others.animateYearScrolling = false
    others.TextFieldComponent = CustomInputText
    others.onInputChange = (event) => {
      if (this.props.onInputChange) {
        this.props.onInputChange(event)
      }
      if (changeOnKeyPress) {
        if (event.target.value === null || event.target.value === '') {
          this.handleChange(null)
        } else {
          const date = moment(event.target.value, getFormatPatternByFormatAndLib(this.props.format), true)
          if (date.isValid()) {
            this.handleChange(date.toDate())
          }
        }
      }
    }

    let datePickerComponent = null

    if (this.props.keyboard) {
      others.open = this.state.isPickerOpen
      others.disableOpenOnEnter = true
      others.endAdornmentOverride = (
        <InputAdornment position="end">
          <IconButton disabled={this.props.disabled || this.props.readOnly} style={{ padding: 6, marginRight: 8 }} onClick={(event) => this.openPicker(event)}>
            <DateRangeIcon fontSize="small" />
          </IconButton>
          {/*
						!hideMenuOptions &&
						<IconButton disabled={this.props.disabled || this.props.readOnly} style={{padding: 6}} onClick={event => {
							event.stopPropagation();
							this.setState({
								menuAnchorEl: event.currentTarget,
							});
						}}>
							<MoreVertIcon fontSize="small" />
						</IconButton>
					*/}
        </InputAdornment>
      )

      datePickerComponent = <KeyboardDatePicker {...others} />
    } else {
      others.open = this.state.isPickerOpen
      others.onOpen = () => {
        this.openPicker()
      }
      if (this.props.showPickerIndicator) {
        others.endAdornmentOverride = <ArrowDropDownIcon style={{ marginTop: -2, color: '#666' }} />
      }
      datePickerComponent = <DatePicker {...others} />
    }

    /*let maskRule = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
		if(format === "simplified"){
			maskRule = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/];
		}else if(format === "onlyYearAndMonth"){
			maskRule = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
		}*/

    return (
      <React.Fragment>
        {datePickerComponent}
        <Menu
          anchorEl={this.state.menuAnchorEl}
          open={Boolean(this.state.menuAnchorEl)}
          onClose={() => {
            this.setState({ menuAnchorEl: null })
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleChange(new Date())
            }}
          >
            Hoje
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleChange(null)
            }}
          >
            Limpar
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}

InputData.propTypes = {
  classes: PropTypes.object.isRequired,
  disablePast: PropTypes.bool,
  customVariant: PropTypes.string,
  shrink: PropTypes.bool,
  label: PropTypes.string,
  keyboard: PropTypes.bool,
  idname: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  inputProps: PropTypes.object
}

export default withStyles(styles)(InputData)
