import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  container: {
    display: 'inline-block',
    cursor: 'pointer'
  },
  innerContainer: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    overflow: 'hidden',
    background: 'rgb(229, 241, 255)',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '36px',
    color: '#037aff',
    fontSize: 15,
    fontWeight: 500,
    '$container:hover &': {
      background: 'rgb(232, 244, 255)'
    }
  },
  icon: {
    verticalAlign: 'top',
    marginTop: 4,
    color: '#555'
  }
})

class LoginAvatar extends Component {
  render() {
    const { classes, name, onClick } = this.props

    const nameParts = name.split(' ')
    let string = nameParts[0].charAt(0).toUpperCase()
    if (nameParts.length > 1) {
      string += nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    }

    return (
      <div className={classes.container} onClick={onClick}>
        <div className={classes.innerContainer}>{string}</div>
        <ArrowDropDownIcon className={classes.icon} />
      </div>
    )
  }
}

LoginAvatar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LoginAvatar)
