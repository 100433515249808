import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputFloatNumberFormat, InputNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ValorPlanoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idDespesa ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputPrecoMensal = React.createRef()
    this.inputPrecoTrimestral = React.createRef()
    this.inputPrecoSemestral = React.createRef()
    this.inputPrecoAnual = React.createRef()
    this.inputPercentualDescontoTrimestral = React.createRef()
    this.inputPercentualDescontoSemestral = React.createRef()
    this.inputPercentualDescontoAnual = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idPlanoPreco) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })

      getAPI({
        url: 'admin/negocios.buscarValorPlano',
        params: {
          idNegocioPlanoPreco: this.props.idPlanoPreco
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState((state) => ({
            negocioPlanoPreco: response.data,
            dataVencimento: response.data.dataVencimento
          }))
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    postAPI({
      url: 'admin/negocios.persistirValorPlano',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoValorPreco')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formValorPlano')
      }
    })
  }

  getDados = () => {
    const dados = {
      idPlanoPreco: this.props.idPlanoPreco,
      plano: this.props.plano,
      idNegocio: this.props.negocio.id,
      dataVencimento: this.state.dataVencimento,
      precoMensal: this.inputPrecoMensal.current ? FormUtil.convertStringToNumber(this.inputPrecoMensal.current.value) : null,
      precoTrimestral: this.inputPrecoTrimestral.current ? FormUtil.convertStringToNumber(this.inputPrecoTrimestral.current.value) : null,
      precoSemestral: this.inputPrecoSemestral.current ? FormUtil.convertStringToNumber(this.inputPrecoSemestral.current.value) : null,
      precoAnual: this.inputPrecoAnual.current ? FormUtil.convertStringToNumber(this.inputPrecoAnual.current.value) : null,
      percentualDescontoTrimestral: this.inputPercentualDescontoTrimestral.current ? FormUtil.convertStringToNumber(this.inputPercentualDescontoTrimestral.current.value) : null,
      percentualDescontoSemestral: this.inputPercentualDescontoSemestral.current ? FormUtil.convertStringToNumber(this.inputPercentualDescontoSemestral.current.value) : null,
      percentualDescontoAnual: this.inputPercentualDescontoAnual.current ? FormUtil.convertStringToNumber(this.inputPercentualDescontoAnual.current.value) : null
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, ajaxing, negocioPlanoPreco, dataVencimento } = this.state
    const { precoMensal, precoTrimestral, percentualDescontoTrimestral, precoSemestral, percentualDescontoSemestral, precoAnual, percentualDescontoAnual } = negocioPlanoPreco
      ? negocioPlanoPreco
      : {}

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formValorPlano" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Grid item xs>
                <InputData
                  functionsMap={this.dataFunctionsMap}
                  keyboard={true}
                  fullWidth={false}
                  disabled={ajaxing}
                  id="xdata"
                  name="xdata"
                  errorMessage={errorsMap('dataVencimento')}
                  onChange={(dataEscolhida) => {
                    this.setState({
                      dataVencimento: converterMomentParaDataInt(dataEscolhida)
                    })
                  }}
                  defaultValue={dataVencimento}
                  label="Data de vencimento"
                  customVariant="outlined-small"
                  shrink={false}
                />
              </Grid>
              <Grid container>
                <Grid item xs>
                  <InputText
                    marginTop={true}
                    idname="xprecoMensal"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={precoMensal}
                    inputComponent={InputFloatNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('precoMensal')}
                    label="Preço Mensal"
                    inputProps={{
                      maxLength: 10
                    }}
                    InputProps={{
                      style: {
                        maxWidth: 180
                      }
                    }}
                    inputRef={this.inputPrecoMensal}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <InputText
                    marginTop={true}
                    idname="xprecoTrimestral"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={precoTrimestral}
                    inputComponent={InputFloatNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('precoTrimestral')}
                    label="Preço Trimestral"
                    inputProps={{
                      maxLength: 10
                    }}
                    InputProps={{
                      style: {
                        maxWidth: 200
                      }
                    }}
                    inputRef={this.inputPrecoTrimestral}
                  />
                </Grid>
                <Grid item style={{ paddingLeft: 10 }}>
                  <InputText
                    marginTop={true}
                    idname="xdescontoTrimestral"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={percentualDescontoTrimestral}
                    inputComponent={InputNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('percentualDescontoTrimestral')}
                    label="Desconto (%)"
                    inputProps={{
                      maxLength: 10
                    }}
                    InputProps={{
                      style: {
                        maxWidth: 200
                      }
                    }}
                    inputRef={this.inputPercentualDescontoTrimestral}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <InputText
                    marginTop={true}
                    idname="xprecoSemestral"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={precoSemestral}
                    inputComponent={InputFloatNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('precoSemestral')}
                    label="Preço Semestral"
                    inputProps={{
                      maxLength: 10
                    }}
                    InputProps={{
                      style: {
                        maxWidth: 200
                      }
                    }}
                    inputRef={this.inputPrecoSemestral}
                  />
                </Grid>
                <Grid item style={{ paddingLeft: 10 }}>
                  <InputText
                    marginTop={true}
                    idname="xdescontoSemestral"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={percentualDescontoSemestral}
                    inputComponent={InputNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('percentualDescontoSemestral')}
                    label="Desconto (%)"
                    inputProps={{
                      maxLength: 10
                    }}
                    InputProps={{
                      style: {
                        maxWidth: 200
                      }
                    }}
                    inputRef={this.inputPercentualDescontoSemestral}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <InputText
                    marginTop={true}
                    idname="xprecoAnual"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={precoAnual}
                    inputComponent={InputFloatNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('precoAnual')}
                    label="Preço Anual"
                    inputProps={{
                      maxLength: 10
                    }}
                    InputProps={{
                      style: {
                        maxWidth: 200
                      }
                    }}
                    inputRef={this.inputPrecoAnual}
                  />
                </Grid>
                <Grid item style={{ paddingLeft: 10 }}>
                  <InputText
                    marginTop={true}
                    idname="xdescontoAnual"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={percentualDescontoAnual}
                    inputComponent={InputNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('percentualDescontoAnual')}
                    label="Desconto (%)"
                    inputProps={{
                      maxLength: 10
                    }}
                    InputProps={{
                      style: {
                        maxWidth: 200
                      }
                    }}
                    inputRef={this.inputPercentualDescontoAnual}
                  />
                </Grid>
              </Grid>

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ValorPlanoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ValorPlanoDialogPage)
