import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import RegisterPhoneDialogPage from 'pages/conta/RegisterPhoneDialogPage'
import EnterpriseHelmet from 'pages/erp/EnterpriseHelmet'
import { VisaoEnum as AgendaVisaoEnum, calcularPixelsPorMinuto, INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import DrawerErp from 'pages/erp/painel/DrawerErp'
import RoutesErp from 'pages/erp/painel/RoutesErp'
import { ROUTE_CONTA_MAIN, ROUTE_ENTERPRISE_MAIN, ROUTE_ENTERPRISE_PANEL, ROUTE_ENTERPRISE_PANEL_REGEX } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { HEADER_NAME_NEGOCIO } from 'support/components/api/ApiClient'
import { getAPI, patchAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { setBannerJourney } from 'support/components/campanha/banner'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import GoogleAnalyticsSupport from 'support/components/googleanalytics/GoogleAnalyticsSupport'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import Panel from 'support/components/panel/Panel'
import SessaoPainelErp from 'support/components/sessao/SessaoPainelErp'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import PermissoesAcesso from 'support/domain/colaborador/PermissoesAcesso'
import { TemaEnum } from 'support/style/Tema'
import { logAGE, logCORE } from 'support/util/Logger'
import { converterHorarioIntParaMinutos, converterMomentParaHorarioInt } from 'support/util/TimeConverter'
import UrlUtil from 'support/util/UrlUtil'
import WindowUtil from 'support/util/WindowUtil'

let INSTANCE = {}

export function verificarAutorizacao() {
  return INSTANCE.verificarAutorizacao.apply(null, arguments)
}

export function verificarAutorizacaoPorRota() {
  return INSTANCE.verificarAutorizacaoPorRota.apply(null, arguments)
}

export function getSessaoPainel() {
  if (INSTANCE.state) {
    return {
      usuario: INSTANCE.state.usuarioSessao,
      usuarioEstadoAtualizado: INSTANCE.state.usuarioEstadoAtualizado,
      colaborador: INSTANCE.state.colaboradorSessao,
      negocio: INSTANCE.state.negocioSessao
    }
  } else {
    return {}
  }
}

const styles = () => ({})

const UPDATE_STATE_INTERVAL = 60000
const UPDATE_SESSION_INTERVAL = 300000

class PainelErp extends Component {
  constructor(props) {
    super(props)
    INSTANCE = this
    this.eventsManager = EventsManager.new()
    this.state = {
      usuarioSessao: this.prepararDadosSessao(null),
      usuarioEstadoAtualizado: {},
      colaboradorSessao: this.prepararDadosSessao(null),
      negocioSessao: this.prepararDadosSessao(null)
    }
    this.panelFunctionMap = {}
    this.buscarUsuarioEstadoAtualizadoInterval = null
    this.globalLocalPreferences = LocalPreferences.new('global', null)
  }

  componentDidMount = () => {
    try {
      this.eventsManager.sub('Logout', () => {
        this.logout()
      })
      this.eventsManager.sub('SelecionarNegocio', (props) => {
        if (props.colaborador && props.negocio) {
          this.panelFunctionMap.alterarRota(this.gerarRotaCompleta('atendimentos', props.colaborador, props.negocio))
        }
      })
    } catch (error) {
      logAGE(error, { component: 'PainelErp', context: 'ComponentDidMount' })
    }
    this.eventsManager.sub('MarcarChatMensagensSuporteLidas', () => {
      const usuarioEstadoAtualizado = this.state.usuarioEstadoAtualizado
      if (usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas > 0) {
        usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas = 0
        this.setState({
          usuarioEstadoAtualizado: usuarioEstadoAtualizado
        })
      }
    })
    this.eventsManager.sub('AtualizarUsuarioEstadoAtualizado', () => {
      this.buscarUsuarioEstadoAtualizado()
    })

    this.buscarUsuarioEstadoAtualizadoInterval = window.setInterval(() => {
      SessaoUsuario.registrarTokenUsuarioAppNativo()
      if (process.env.REACT_APP_JOB_USUARIO_ATUALIZACAO_ESTADO === 'true') {
        this.buscarUsuarioEstadoAtualizado()
      }
    }, UPDATE_STATE_INTERVAL)
    this.atualizarSessaoInterval = window.setInterval(() => {
      if (process.env.REACT_APP_JOB_USUARIO_ATUALIZACAO_SESSAO === 'true') {
        EventsManager.pub('AtualizarSessao')
        GoogleAnalyticsSupport.sendPlataform()
      }
    }, UPDATE_SESSION_INTERVAL)

    GoogleAnalyticsSupport.sendPlataform()
  }

  buscarDadosSessao = (opts) => {
    let headers = {}
    let idUltimoNegocioAcessado = this.globalLocalPreferences.getPreference('PainelErp_IdUltimoNegocioAcessado', null)

    if (idUltimoNegocioAcessado) {
      headers[HEADER_NAME_NEGOCIO] = idUltimoNegocioAcessado
    }

    getAPI({
      url: 'cda/usuarios.buscarDadosSessao',
      headers: headers,
      onSuccess: (response) => {
        opts.onSuccess(response)
      },
      onError: (response) => {
        opts.onError(response)
      },
      requerAutorizacao: true,
      sendErroToGenericSnackbar: opts.sendErroToGenericSnackbar
    })
  }

  gerarRotaCompleta = (rota, colaborador, negocio) => {
    if (colaborador && colaborador.id && negocio && negocio.id) {
      return ROUTE_ENTERPRISE_PANEL + '/' + SessaoPainelErp.gerarDadosNegocioSessaoUrl(colaborador, negocio) + '/p/' + rota
    } else {
      return ROUTE_ENTERPRISE_PANEL + '/p/' + rota
    }
  }

  carregarPainel = () => {
    this.buscarDadosSessao({
      onSuccess: (response) => {
        if (!response.data.usuario.telefone && !DeviceIdentifier.isNativeApp()) {
          openBackableDialog(RegisterPhoneDialogPage, { name: response.data.usuario.nome })
        }

        this.buscarUsuarioEstadoAtualizado()

        const usuarioSessao = response.data.usuario !== undefined ? response.data.usuario : null
        const colaboradorSessao = response.data.colaborador !== undefined ? response.data.colaborador : null
        const negocioSessao = response.data.negocio !== undefined ? response.data.negocio : null

        this.definirEstadosIniciaisPaineis({
          usuarioSessao: usuarioSessao,
          colaboradorSessao: colaboradorSessao,
          negocioSessao: negocioSessao
        })

        this.registrarDadosSessao({
          usuario: usuarioSessao,
          colaborador: colaboradorSessao,
          negocio: negocioSessao
        })

        let rota = undefined
        if (window.location.pathname.match(new RegExp('.*' + ROUTE_ENTERPRISE_PANEL_REGEX + '(\\/p){0,1}\\/{0,1}$', 'gi'))) {
          if (response.data.colaborador === undefined) {
            rota = this.gerarRotaCompleta('negocios')
          } else {
            rota = this.gerarRotaCompleta('atendimentos', this.state.colaboradorSessao, this.state.negocioSessao)
          }
        }

        const dadosSessaoUrl = SessaoPainelErp.getDadosNegocioSessaoFromUrl()

        let idNegocioSessaoUrl = null
        if (dadosSessaoUrl && dadosSessaoUrl.idNegocio) {
          idNegocioSessaoUrl = parseInt(dadosSessaoUrl.idNegocio, 10)
        }

        let idColaboradorSessaoUrl = null
        if (dadosSessaoUrl && dadosSessaoUrl.idColaborador) {
          idColaboradorSessaoUrl = parseInt(dadosSessaoUrl.idColaborador, 10)
        }

        let idNegocioSessaoState = null
        if (this.state.negocioSessao && this.state.negocioSessao.id) {
          idNegocioSessaoState = this.state.negocioSessao.id
        }

        let idColaboradorSessaoState = null
        if (this.state.colaboradorSessao && this.state.colaboradorSessao.id) {
          idColaboradorSessaoState = this.state.colaboradorSessao.id
        }

        if (idNegocioSessaoUrl && idColaboradorSessaoUrl) {
          if (idNegocioSessaoUrl !== idNegocioSessaoState || idColaboradorSessaoUrl !== idColaboradorSessaoState) {
            if (response.data.colaborador === undefined) {
              rota = this.gerarRotaCompleta('negocios')
            } else {
              rota = this.gerarRotaCompleta('atendimentos', this.state.colaboradorSessao, this.state.negocioSessao)
            }
          }
        }

        if (!dadosSessaoUrl && colaboradorSessao) {
          setBannerJourney('login', { Colaborador: colaboradorSessao.id, Negocio: negocioSessao.id })
        }
        this.panelFunctionMap.notificarPainelCarregado({ rota: rota })
        SessaoUsuario.registrarTokenUsuarioAppNativo()
      },
      onError: (response) => {
        if (response.code === -1) {
          this.panelFunctionMap.notificarErroCarregamentoPainel({ mensagem: 'Falha de conexão com a internet' })
        } else if (response.code === 400 || response.code === 401) {
          this.logout()
        } else if (response.code === 403) {
          this.registrarDadosSessao({
            usuario: response.data.body
          })
          this.panelFunctionMap.notificarPainelCarregado({ rota: ROUTE_ENTERPRISE_PANEL + '/p/plano' })
        } else {
          this.panelFunctionMap.notificarErroCarregamentoPainel({
            mensagem: (
              <span>
                Ocorreu um erro inesperado!
                <br />
                Tente novamente em alguns instantes.
              </span>
            )
          })
        }
      },
      sendErroToGenericSnackbar: false
    })
  }

  definirEstadosIniciaisPaineis = (props) => {
    if (!props.colaboradorSessao || !props.colaboradorSessao.id) {
      return
    }

    const colaboradorLocalPreferences = LocalPreferences.new('colaborador', props.colaboradorSessao.id)

    colaboradorLocalPreferences.setMomentPreference('AgendaInterface_DataSelecionada', moment())
    colaboradorLocalPreferences.setPreference('AgendaInterface_Visao', AgendaVisaoEnum.PROFISSIONAL)
    const intervaloMinutosExibicaoTempo = colaboradorLocalPreferences.getPreference('AgendaInterface_IntervaloMinutosExibicaoTempo', INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT)
    const pixelsPorMinuto = calcularPixelsPorMinuto(intervaloMinutosExibicaoTempo)
    const top = pixelsPorMinuto * converterHorarioIntParaMinutos(converterMomentParaHorarioInt(moment())) - WindowUtil.getHeight() * 0.25
    colaboradorLocalPreferences.setPreference('AgendaInterface_GridScrollY', top)
  }

  atualizarSessao = (props) => {
    this.buscarDadosSessao({
      onSuccess: (response) => {
        if (!this.state.usuarioSessao || !this.state.usuarioSessao.carregado) {
          document.location.reload(true)
          return
        }

        if (!response.data.usuario || response.data.usuario.id !== this.state.usuarioSessao.id) {
          document.location.reload(true)
          return
        }

        this.registrarDadosSessao({
          usuario: response.data.usuario !== undefined ? response.data.usuario : null,
          colaborador: response.data.colaborador !== undefined ? response.data.colaborador : null,
          negocio: response.data.negocio !== undefined ? response.data.negocio : null
        })
        props.onSuccess(response)
      },
      onError: (response) => {
        if (response.code === 400 || response.code === 401) {
          this.logout()
          return
        }
        props.onError(response)
      },
      sendErroToGenericSnackbar: false
    })
  }

  limparSessao = (props) => {
    if (!props) {
      props = {}
    }
    const obj = {}
    if (props.usuario === true) {
      obj.usuario = null
    }
    if (props.colaborador === true) {
      obj.colaborador = null
    }
    if (props.negocio === true) {
      obj.negocio = null
    }
    this.registrarDadosSessao(obj)
  }

  registrarDadosSessao = (opts) => {
    const state = {}
    if (opts.usuario !== undefined) {
      state.usuarioSessao = this.prepararDadosSessao(opts.usuario)
    }

    if (opts.negocio !== undefined) {
      let negocioExcluido = false
      if (opts.negocio && opts.negocio.excluido === true) {
        negocioExcluido = true
      }

      if (opts.colaborador !== undefined && opts.colaborador !== null) {
        state.colaboradorSessao = this.prepararDadosSessao(!negocioExcluido ? opts.colaborador : null)
        localStorage.setItem('s99_NPS', opts.colaborador.npsHabilitado)
      }

      state.negocioSessao = this.prepararDadosSessao(!negocioExcluido ? opts.negocio : null)

      if (state.negocioSessao.carregado && state.negocioSessao.id) {
        this.globalLocalPreferences.setPreference('PainelErp_IdUltimoNegocioAcessado', state.negocioSessao.id, { type: 'permanent' })
      }

      if (opts.negocio && opts.negocio.configuracoesGerais && opts.negocio.configuracoesGerais.temaAplicacao) {
        EventsManager.pub('AlterarTemaAplicacao', {
          tema: TemaEnum.getById(opts.negocio.configuracoesGerais.temaAplicacao.id)
        })
      }
    }
    this.setState(state)
  }

  prepararDadosSessao = (entidade) => {
    if (entidade === null) {
      entidade = {
        carregado: false
      }
    } else {
      entidade['carregado'] = true
    }
    return entidade
  }

  logout = () => {
    patchAPI({
      url: 'cda/usuarios.logout',
      onSuccess: () => {
        logCORE('Logout', { component: 'PainelErp', context: 'Logout - success' })
      },
      onError: () => {
        logCORE('Logout', { component: 'PainelErp', context: 'Logout - error' })
      },
      requerAutorizacao: true
    })

    SessaoUsuario.limparSessao()
    // Session Storage control over Messenger Wizard: logout should clear its viewed status
    sessionStorage.setItem('viewedWizardOnCurrentSession', false)
    if (DeviceIdentifier.isNativeApp()) {
      this.panelFunctionMap.alterarRota(ROUTE_ENTERPRISE_MAIN + '/index-app')
    } else {
      this.panelFunctionMap.alterarRota(ROUTE_CONTA_MAIN + '/login?continuacao=' + encodeURIComponent(UrlUtil.getFullUrlFromRoute(ROUTE_ENTERPRISE_PANEL)))
    }
  }

  navegarPaginaPainel = (props) => {
    if (props.adicionarDadosNegocioSessao === undefined) {
      props.adicionarDadosNegocioSessao = true
    }

    let dadosNegocioSessao = ''

    if (props.adicionarDadosNegocioSessao) {
      let colaborador = undefined
      if (colaborador === undefined && this.state.colaboradorSessao.carregado) {
        colaborador = this.state.colaboradorSessao
      }

      let negocio = undefined
      if (negocio === undefined && this.state.negocioSessao.carregado) {
        negocio = this.state.negocioSessao
      }

      if (colaborador !== undefined && negocio !== undefined) {
        dadosNegocioSessao = '/' + SessaoPainelErp.gerarDadosNegocioSessaoUrl(colaborador, negocio)
      }
    }
    this.panelFunctionMap.alterarRota(ROUTE_ENTERPRISE_PANEL + dadosNegocioSessao + '/p' + props.rota, props.replace)
  }

  verificarAutorizacao = (permissoesRequeridas, and) => {
    if (this.state.colaboradorSessao.carregado === false) {
      return false
    }
    return PermissoesAcesso.verificarAutorizacao(permissoesRequeridas, this.state.colaboradorSessao.permissoes, and)
  }

  verificarAutorizacaoPorRota = (rota) => {
    return PermissoesAcesso.verificarAutorizacaoPorRota(rota, this.state.colaboradorSessao.carregado ? this.state.colaboradorSessao.permissoes : '')
  }

  buscarUsuarioEstadoAtualizado = () => {
    getAPI({
      url: 'cda/usuarios.buscarEstadoAtualizado',
      onSuccess: (response) => {
        this.setState({
          usuarioEstadoAtualizado: response.data
        })
      },
      contextoUsuario: 'erp',
      requerAutorizacao: true,
      sendErroToGenericSnackbar: false
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.usuarioSessao && this.state.usuarioSessao.carregado) {
      const dadosSessaoUrl = SessaoPainelErp.getDadosNegocioSessaoFromUrl()
      let idNegocioSessaoUrl = 0
      if (dadosSessaoUrl && dadosSessaoUrl.idNegocio) {
        idNegocioSessaoUrl = parseInt(dadosSessaoUrl.idNegocio, 10)
      }

      if (idNegocioSessaoUrl && nextState.usuarioSessao.carregado) {
        if (!nextState.negocioSessao || !nextState.negocioSessao.carregado || nextState.negocioSessao.id !== idNegocioSessaoUrl) {
          // Conflito de sessão de login em abas do navegador
          if (this.state.usuarioSessao.id !== nextState.usuarioSessao.id) {
            document.location.reload(true)
            return
          }

          EventsManager.pub('AtualizarSessao', {
            force: true,
            numeroMaximoTentativas: 999
          })
          return false
        }
      }
    }
    return true
  }

  render() {
    if (this.state.usuarioSessao.carregado === true && this.state.usuarioSessao.verificado === false) {
      this.panelFunctionMap.alterarRota(ROUTE_CONTA_MAIN + '/conta-pendente-verificacao/' + encodeURIComponent(this.state.usuarioSessao.email))
      return []
    }

    let hasMenu = true
    if (!this.state.colaboradorSessao || !this.state.colaboradorSessao.carregado) {
      hasMenu = false
    }

    let menuBadgeCount = 0
    if (this.state.usuarioEstadoAtualizado && this.state.usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas > 0) {
      menuBadgeCount = this.state.usuarioEstadoAtualizado.chatMensagensSuporteNaoLidas
    }

    const renderDrawerAndPagesProps = {
      hasMenu: hasMenu,
      menuBadgeCount: menuBadgeCount,
      negocioSessao: this.state.negocioSessao,
      usuarioSessao: this.state.usuarioSessao,
      usuarioEstadoAtualizado: this.state.usuarioEstadoAtualizado,
      colaboradorSessao: this.state.colaboradorSessao
    }

    return (
      <React.Fragment>
        <EnterpriseHelmet />
        <Panel
          {...this.props}
          functionsMap={this.panelFunctionMap}
          carregarPainel={this.carregarPainel}
          atualizarSessao={this.atualizarSessao}
          limparSessao={this.limparSessao}
          handleLogout={this.logout}
          navegarPaginaPainel={this.navegarPaginaPainel}
          renderDrawer={(props) => <DrawerErp key={'drawer'} {...props} {...renderDrawerAndPagesProps} />}
          renderPages={(props) => <RoutesErp key={'routes'} {...props} {...renderDrawerAndPagesProps} />}
          // Tecnical Debt: to use the prop above, must follow the pattern describe in this link/commit: https://github.com/grupoboticario/saloes-salao99-reactjs-erp-pro/pull/174/commits/addff11a2a6ee6c272c83042eaec6af39b24f557
          regexFullScreenPages={/\/p\/(atendimentos|fluxocaixa|suporte\/chat)/}
          regexWhiteBackgroundPages={/\/p\/(atendimentos)/}
        />
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    window.clearInterval(this.buscarUsuarioEstadoAtualizadoInterval)
    window.clearInterval(this.atualizarSessaoInterval)
  }
}

PainelErp.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(PainelErp)
