import { createRef, useState } from 'react'

import { Button, createStyles, Grid, Link, makeStyles, Theme, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import logo from 'img/logo_salao99_painel.png'
import { callAPI } from 'support/components/api/ApiClient'
import { ErrorResponseType } from 'support/components/api/types'
import { InputTelefone } from 'support/components/input/InputTelefone'
import DialogPage from 'support/components/page/DialogPage'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'
import { createErrorsMap } from 'support/util/FormUtil'
import { logAGE } from 'support/util/Logger'

interface StylesTheme extends Theme {
  page: {
    parentCenteredPaper: CSSProperties
    paper: ({ maxWidth }: { maxWidth: number }) => CSSProperties
  }
}

const useStyles = makeStyles<StylesTheme>((theme) =>
  createStyles({
    root: theme.page.parentCenteredPaper,
    paper: theme.page.paper({ maxWidth: 456 })
  })
)

type RegisterPhoneDialogPageProps = {
  name: string
  handleCloseDialog: () => void
  onSuccess?: () => void
}

function RegisterPhoneDialogPage({ name, onSuccess = () => null, ...dialogProps }: RegisterPhoneDialogPageProps) {
  const classes = useStyles()
  const [ajaxing, setAjaxing] = useState(false)
  const [errorsMap, setErrorsMap] = useState(() => createErrorsMap())
  const phoneInput = createRef<HTMLInputElement>()

  const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
  const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade
  const termosUsoLinkProps = TermosEPrivacidadeLinkProps.termosUso

  const salvar = () => {
    setAjaxing(true)

    callAPI({
      url: 'cda/usuarios.alterarTelefone',
      method: 'post',
      requerAutorizacao: true,
      data: { telefone: phoneInput?.current?.value },
      onSuccess: () => {
        onSuccess()
        dialogProps.handleCloseDialog()
      },
      onError: (error: ErrorResponseType) => {
        const responseErrorsMap = () => createErrorsMap(error.data.errors)
        setErrorsMap(responseErrorsMap)
        logAGE(error, { component: 'RegisterPhoneDialogPage', context: 'salvar' })
      }
    })

    setAjaxing(false)
  }

  return (
    <DialogPage {...dialogProps} align="center" contentMaxWidth={456} pageType="basicEntity" ajaxing={ajaxing} hideBackButton>
      <div style={{ textAlign: 'center' }}>
        <img src={logo} width="100" alt="Salão99" />
        <Typography variant="h5" style={{ marginTop: 22 }}>
          Olá, {name}
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 8, letterSpacing: '.1px' }}>
          Para prosseguir com o seu cadastro, <br /> informe seu número de celular.
        </Typography>
      </div>

      <InputTelefone
        label="Número de Celular"
        placeholder="(00) 0000-0000"
        inputVariant="outlined"
        phoneRef={phoneInput}
        error={errorsMap('telefone')}
        ajaxing={ajaxing}
        style={{ marginTop: 30 }}
      />

      <Grid container style={{ marginTop: 34, marginBottom: 42 }}>
        <Button disabled={ajaxing} variant="contained" color="primary" size="large" fullWidth onClick={salvar} role="button">
          AVANÇAR
        </Button>
      </Grid>

      <Typography variant="body2" style={{ marginTop: 42, marginBottom: 3, color: '#666' }} align="center">
        Ao criar sua conta você confirma que é maior de 13 anos e está de acordo com o{' '}
        <Link className={classes.link} {...termosUsoLinkProps} rel="noopener noreferrer" underline="always">
          Termo de uso
        </Link>
        . Seus dados serão tratados nos termos de nossa{' '}
        <Link className={classes.link} {...politicaPrivacidadeLinkProps} rel="noopener noreferrer" underline="always">
          Política de Privacidade
        </Link>
      </Typography>
    </DialogPage>
  )
}

export default RegisterPhoneDialogPage
