import DeviceIdentifier from 'support/components/device/DeviceIdentifier'

let executed = false

function executeScriptAndShowPopup() {
  if (executed === false) {
    executed = true

    if (DeviceIdentifier.isNativeApp() === false) {
      const script1 = document.createElement('script')
      script1.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      script1.setAttribute('data-document-language', 'true')
      script1.setAttribute('type', 'text/javascript')
      script1.setAttribute('charset', 'UTF-8')
      script1.setAttribute('data-domain-script', '8233ed28-11ca-4939-9705-5e15f3cfb5dc')
      document.body.appendChild(script1)

      const script2 = document.createElement('script')
      script2.setAttribute('type', 'text/javascript')
      script2.innerHTML = 'function OptanonWrapper() { }'
      document.body.appendChild(script2)
    }
  }
}

const functionsObject = {
  executeScriptAndShowPopup: executeScriptAndShowPopup
}

export default functionsObject
