import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import RelatorioDescontosDetalhadosDialogPage from 'pages/erp/painel/relatorio/RelatorioDescontosDetalhadosDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaDesconto: {
    flexGrow: 1
  },
  colunaQuantidadeLarge: {
    maxWidth: 100,
    minWidth: 100
  },
  colunaQuantidadeMedium: {
    maxWidth: 48,
    minWidth: 48
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 182,
    minWidth: 160
  },
  colunaIcone: {
    maxWidth: 66,
    minWidth: 66
  }
})

class RelatorioDespesasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxing: false,
      descontosDataInicial: this.colaboradorLocalPreferences.getMomentPreference('RelatorioDescontosDialogPageFiltro_DataInicial', moment().startOf('month')),
      descontosDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioDescontosDialogPageFiltro_DataFinal', moment().endOf('month')),
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  abrirDetalhes = (item) => {
    openBackableDialog(RelatorioDescontosDetalhadosDialogPage, {
      desconto: item.desconto,
      dataInicial: this.state.descontosDataInicial,
      dataFinal: this.state.descontosDataFinal,
      titulo: item.desconto.nome
    })
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.nomeDesconto = item.desconto.nome
    content.quantidadeVendas = item.quantidadeVendas
    content.valorTotalDesconto = formatarValorMonetario(item.valorTotalDesconto ? item.valorTotalDesconto : 0)
    content.valorSubtotal = formatarValorMonetario(item.valorSubtotal ? item.valorSubtotal : 0)
    content.valorTotal = formatarValorMonetario(item.valorTotal ? item.valorTotal : 0)
    if (size === 'medium') {
      content.descricaoValores = (
        <span>
          Subtotal : {content.valorSubtotal}
          <br />
          Desconto: {content.valorTotalDesconto}
          <br />
          Cobrado : {content.valorTotal}
        </span>
      )
    }

    if (size === 'small') {
      content.abrirDetalhes = (
        <IconButton
          style={{ marginTop: -10, marginBottom: -6, padding: 8 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    } else {
      content.abrirDetalhes = (
        <IconButton
          style={{ marginRight: 0, padding: 6 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    }

    return content
  }

  getRequestParameters = () => {
    return {
      dataInicial: converterMomentParaDataInt(this.state.descontosDataInicial),
      dataFinal: converterMomentParaDataInt(this.state.descontosDataFinal)
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, descontosDataInicial, descontosDataFinal } = this.state

    return (
      <DialogPage {...dialogProps} title="Descontos" align="center" contentMaxWidth={1200} pageType="basicEntity" ajaxing={ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Informações Gerais
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <DateRangeNavigator
                    expanded={false}
                    defaultValue={{
                      tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioDescontosDialogPageFiltro_TipoIntervalo', 'mes'),
                      dataInicial: descontosDataInicial,
                      dataFinal: descontosDataFinal
                    }}
                    onChange={(data) => {
                      this.colaboradorLocalPreferences.setPreference('RelatorioDescontosDialogPageFiltro_TipoIntervalo', data.tipoIntervalo.nome)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioDescontosDialogPageFiltro_DataInicial', data.dataInicial)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioDescontosDialogPageFiltro_DataFinal', data.dataFinal)
                      this.setState({
                        descontosDataInicial: data.dataInicial,
                        descontosDataFinal: data.dataFinal
                      })
                    }}
                  />
                </div>
              </div>
            </FloatContent>

            <VirtualizedResponsiveTable
              key={converterMomentParaDataInt(descontosDataInicial).toString() + '-' + converterMomentParaDataInt(descontosDataFinal).toString()}
              showBackgroundDividers={true}
              itemsPerRequest={20}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarDescontosResumidosPorData"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Desconto', className: classes.colunaDesconto, props: { xs: true } },
                  { label: 'Quantidade', className: classes.colunaQuantidadeLarge, horizontalPadding: 'small', align: 'right' },
                  { label: 'Subtotal (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { label: 'Descontos (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { label: 'Total Cobrado (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)

                  return {
                    itemData: [content.nomeDesconto, content.quantidadeVendas, content.valorSubtotal, content.valorTotalDesconto, content.valorTotal, content.abrirDetalhes]
                  }
                }
              }}
              mediumRenderProps={{
                headerColumnHeight: 50,
                rowHeight: 86,
                columns: [
                  { label: 'Desconto', className: classes.colunaDesconto, props: { xs: true } },
                  { label: 'Qtd', className: classes.colunaQuantidadeMedium, horizontalPadding: 'small' },
                  { label: 'Valores', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('medium', item)

                  return {
                    itemData: [content.nomeDesconto, content.quantidadeVendas, content.descricaoValores, content.abrirDetalhes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 125,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container style={{ minWidth: 0 }} alignItems="center">
                          <Grid item xs style={{ minWidth: 0 }}>
                            <Typography variant="body2" noWrap={true}>
                              <b>{content.nomeDesconto}</b>
                            </Typography>
                          </Grid>
                          <Grid item>{content.abrirDetalhes}</Grid>
                        </Grid>
                        <Typography variant="body2" noWrap={true}>
                          Quantidade: {content.quantidadeVendas}
                        </Typography>
                        <Typography variant="body2" noWrap={true}>
                          Subtotal Vendas: {content.valorSubtotal}
                        </Typography>
                        <Typography variant="body2" noWrap={true}>
                          Total Desconto: {content.valorTotalDesconto}
                        </Typography>
                        <Typography variant="body2" noWrap={true}>
                          Total Cobrado: {content.valorTotal}
                        </Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Não existem descontos para o período informado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioDespesasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioDespesasDialogPage)
