import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import EntradaDialogPage from 'pages/erp/painel/caixa/EntradaDialogPage'
import SangriaEntradaResponsiveTable from 'pages/erp/painel/caixa/table/SangriaEntradaResponsiveTable'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import TabContainerPersistHeader from 'support/components/tab/TabContainerPersistHeader'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  containerBotao: {
    marginTop: theme.spacing(2),
    ...theme.screen.horizontalMarginStyles(),
    textAlign: 'right'
  }
})

class TabEntradas extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()

    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      lista: [],
      paginaCarregada: false
    }
    this.loaderFunctionsMap = {}
  }

  loadTrackingFunction = (data) => {
    if (data.status === 'loading') {
      if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
        this.setState({ errorsMap: FormUtil.createErrorsMap(), lista: [], paginaCarregada: false })
      }
    } else if (data.status === 'loaded') {
      this.setState({ lista: data.items, paginaCarregada: true })
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoEntrada', (props) => {
      this.loaderFunctionsMap.refresh()
    })
  }

  handlerOpenCreateDialogPage = () => {
    if (verificarAutorizacao([PA.PODE_REGISTRAR_PROPRIA_ENTRADA])) {
      openBackableDialog(EntradaDialogPage, { caixa: this.props.caixa })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = (item) => {
    if (verificarAutorizacao([PA.PODE_REGISTRAR_PROPRIA_ENTRADA])) {
      openBackableDialog(EntradaDialogPage, { idEntrada: item.id, caixa: this.props.caixa })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerRemover = (item) => {
    if (verificarAutorizacao([PA.PODE_REGISTRAR_PROPRIA_ENTRADA])) {
      openBackableDialog(EntityRemovalDialog, {
        title: 'Remover Entrada',
        text: <span>Você tem certeza que deseja remover esta entrada? Essa operação não poderá ser desfeita.</span>,
        call: {
          method: 'post',
          url: 'erp/caixas.removerEntrada',
          contextoUsuario: 'erp',
          data: {
            id: item.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoEntrada')
          },
          errorKey: 'caixa'
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  render() {
    const { ajaxing, paginaCarregada, lista } = this.state

    let onClickRow = (item) => {
      this.handlerEditar(item)
    }

    const informativo =
      'Esse registro de entrada NÃO representa pagamentos feitos por clientes ou qualquer outro tipo de lucro. Utilize esta entrada para registrar um abastecimento de dinheiro no caixa para fins de controle, como troco e etc.'

    return (
      <TabContainer padding={false}>
        {paginaCarregada && (
          <TabContainerPersistHeader
            addTabContainerPadding={true}
            text={
              lista && lista.length > 0 ? (
                <span>
                  Lista de entradas manuais de dinheiro no caixa
                  <br />
                  <small style={{ opacity: 0.5 }}>{informativo}</small>
                </span>
              ) : (
                <span>
                  Nenhuma entrada foi registrada
                  <br />
                  <small style={{ opacity: 0.5 }}>{informativo}</small>
                </span>
              )
            }
            buttonsContent={
              <Button color="secondary" variant="text" style={{ marginRight: -8, marginLeft: -8 }} onClick={this.handlerOpenCreateDialogPage}>
                <AddIcon />
                <span style={{ marginLeft: 8, marginRight: 0 }}>ADICIONAR</span>
              </Button>
            }
          />
        )}

        <SangriaEntradaResponsiveTable
          disabled={ajaxing}
          scrollElement={this.props.scrollContainerRef}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/caixas.buscarEntradas"
          getRequestParametersFunction={() => {
            return {
              idCaixa: this.props.caixa.id
            }
          }}
          items={this.state.lista}
          loadTrackingFunction={this.loadTrackingFunction}
          onClickRow={onClickRow}
          removeFunction={this.handlerRemover}
          emptyListProps={{}}
        />
      </TabContainer>
    )
  }
}

TabEntradas.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabEntradas)
