import React, { Component } from 'react'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import { darken, emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import ProdutoDadosBasicosDialogPage from 'pages/erp/painel/inventario/ProdutoDadosBasicosDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CustomDialog from 'support/components/dialog/CustomDialog'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { searchPart } from 'support/util/StringUtil'

const ROW_HEIGHT = 54

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 480,
    width: '100%',
    margin: 4
  },
  rootTitle: {
    //borderBottom: "1px solid "+theme.palette.divider,
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    position: 'relative',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    zIndex: 1
  },
  titleMainContent: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(4)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  rightButton2: {
    position: 'absolute',
    right: theme.spacing(7),
    top: theme.spacing(1)
  },
  rightButton3: {
    position: 'absolute',
    right: theme.spacing(13),
    top: theme.spacing(1)
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1)
  },
  dialogContent: {
    maxHeight: 1200,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0
  },
  rootListItem: {
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  contentListItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 8
  },
  primaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 15
    //fontWeight: 500
  },
  secondaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: emphasize(darken(theme.palette.secondary.light, 0.15), 0.3)
  },
  iconListItem: {
    marginTop: 4,
    marginRight: -4
  }
})

class InputProdutoDialog extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      step: 0,
      categorias: [],
      produtos: [],
      produtosParaPesquisa: [],
      produtosParaPesquisaFiltrados: []
    }

    this.stringPesquisaProduto = ''
    this.scrollContainerRef = React.createRef()
    this.inputPesquisaNomeProduto = React.createRef()

    this.loaderStep1FunctionsMap = {}
    this.loaderStep2FunctionsMap = {}
    this.loaderStep3FunctionsMap = {}
  }

  componentDidMount = () => {
    this.setState({ step: 1 })
    this.eventsManager.sub('ManutencaoCategoriaProduto', (props) => {
      const { step } = this.state
      if (step === 1) {
        this.loaderStep1FunctionsMap.load()
      } else if (step === 2) {
        this.loaderStep2FunctionsMap.load()
      } else if (step === 3) {
        this.loaderStep3FunctionsMap.load()
      }
    })
    this.eventsManager.sub('ManipulacaoProduto', (props) => {
      if (props.produto && props.produto.controleEstoque) {
        this.props.setValue({
          produto: props.produto
        })
        this.props.handleCloseDialog()
      }
    })
  }

  renderCategoriaRow = (categoria, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={categoria.nome}
          onClick={() => {
            this.scrollContainerRef.current.scrollTop = 0
            this.setState({ step: 2, categoria: categoria })
          }}
        />
      </div>
    )
  }

  renderProdutoRow = (produto, { index, key, style }) => {
    return this.renderProduto(produto, key, style)
  }

  renderProduto = (produto, key, style) => {
    let primary = produto.nome
    let secondary = ''

    if (produto.valorVendaExterna) {
      secondary = formatarValorMonetario(produto.valorVendaExterna)
    }

    if (produto.controleEstoque) {
      if (secondary.length > 0) {
        secondary = secondary + ' - '
      }

      let quantidadeEstoque = produto.estoqueAtualQuantidade < 0 ? 0 : produto.estoqueAtualQuantidade

      secondary = secondary + quantidadeEstoque

      if (produto.estoqueAtualQuantidade === 1) {
        secondary = secondary + ' item'
      } else {
        secondary = secondary + ' itens'
      }
    }

    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={primary}
          secondary={secondary}
          onClick={(event) => {
            this.props.setValue({
              produto: produto
            })
            this.props.handleCloseDialog()
          }}
        />
      </div>
    )
  }

  handleChangePesquisaNomeProduto = () => {
    this.setState({ produtosParaPesquisaFiltrados: this.getProdutosFiltrados(this.state.produtosParaPesquisa) })
  }

  getProdutosFiltrados = (produtosParaPesquisa) => {
    this.stringPesquisaProduto = this.inputPesquisaNomeProduto.current.value.trim()
    const produtosParaPesquisaFiltrados = []
    for (let produto of produtosParaPesquisa) {
      if (
        searchPart(produto.nome, this.stringPesquisaProduto) ||
        searchPart(produto.sku, this.stringPesquisaProduto) ||
        searchPart(produto.codigoBarras, this.stringPesquisaProduto)
      ) {
        produto._update = true
        produtosParaPesquisaFiltrados.push(produto)
      }
    }
    return produtosParaPesquisaFiltrados
  }

  creationFunction = (props) => {
    if (!verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    if (!props) {
      props = {}
    }
    openBackableDialog(ProdutoDadosBasicosDialogPage, {
      nome: props.text
    })
  }

  render() {
    const { classes, vendaExterna, arquivado } = this.props
    const { step, categoria, categorias, produtos, produtosParaPesquisaFiltrados } = this.state

    let quantidadeItensScroll = 2
    let titulo = ''

    if (step === 1) {
      quantidadeItensScroll = categorias ? categorias.length : 0
    } else if (step === 2) {
      quantidadeItensScroll = produtos ? produtos.length : 0
      titulo = categoria.nome
    }

    if (quantidadeItensScroll < 1) {
      quantidadeItensScroll = 1
    }

    return (
      <CustomDialog classes={{ paper: classes.root }} dialogProps={this.props} disabledOnClose={this.state.ajaxing}>
        <DialogTitle disableTypography className={classes.rootTitle}>
          {step === 1 && [
            <Typography key="title" variant="h6">
              Selecione...
            </Typography>,
            <IconButton key="criar" color="secondary" className={classes.rightButton3} onClick={this.creationFunction}>
              <AddIcon />
            </IconButton>,
            <IconButton
              key="buscar"
              color="secondary"
              className={classes.rightButton2}
              onClick={() => {
                this.setState({ step: 3 })
              }}
            >
              <SearchIcon />
            </IconButton>
          ]}
          {step === 2 && [
            <IconButton
              key="voltar"
              className={classes.backButton}
              onClick={() => {
                let stepBack = step - 1
                this.setState({ step: stepBack })
              }}
            >
              <ArrowBackIcon />
            </IconButton>,
            <Typography key="title" variant="h6" className={classes.titleMainContent}>
              {titulo}
            </Typography>,
            <IconButton key="criar" color="secondary" className={classes.rightButton2} onClick={this.creationFunction}>
              <AddIcon />
            </IconButton>
          ]}

          {step === 3 && [
            <IconButton
              key="voltar"
              className={classes.backButton}
              onClick={() => {
                this.setState({ step: 1 })
              }}
            >
              <ArrowBackIcon />
            </IconButton>,
            <div className={classes.titleMainContent}>
              <InputText
                key="inpuPesquisa"
                autoFocus
                placeholder="Pesquisar por produto..."
                customVariant="naked"
                nakedLeftPadding={false}
                onChange={this.handleChangePesquisaNomeProduto}
                defaultValue={this.stringPesquisaProduto}
                inputRef={this.inputPesquisaNomeProduto}
              />
            </div>,
            <IconButton
              key="criar"
              color="secondary"
              className={classes.rightButton2}
              onClick={() => {
                this.creationFunction({ text: this.inputPesquisaNomeProduto.current.value.trim() })
              }}
            >
              <AddIcon />
            </IconButton>
          ]}

          <IconButton className={classes.closeButton} onClick={this.props.handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent className={classNames(classes.dialogContent, 'needsWillChange')} style={{ wwillChange: 'ttransform' }}>
            {step === 1 && (
              <VirtualizedList
                key="categorias"
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                contextoUsuario="erp"
                endpoint="erp/inventario.buscarCategoriasParaVenda"
                items={categorias}
                onLoad={(items) => {
                  this.setState({ categorias: items })
                }}
                loaderFunctionsMap={this.loaderStep1FunctionsMap}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderCategoriaRow}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum produto foi encontrado',
                  textButton: 'Cadastrar Produto',
                  creationFunction: this.creationFunction
                }}
              />
            )}
            {step === 2 && (
              <VirtualizedList
                key={'produtos'}
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                contextoUsuario="erp"
                endpoint="erp/inventario.buscarProdutos"
                getRequestParametersFunction={() => ({
                  idCategoria: this.state.categoria.id,
                  vendaExterna: vendaExterna,
                  arquivado: arquivado
                })}
                items={produtos}
                onLoad={(items) => {
                  this.setState({
                    produtos: items
                  })
                }}
                loaderFunctionsMap={this.loaderStep2FunctionsMap}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderProdutoRow}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum produto foi encontrado',
                  textButton: 'Cadastrar Produto',
                  creationFunction: this.creationFunction
                }}
              />
            )}
            {step === 3 && (
              <VirtualizedList
                key={'produtosParaPesquisa'}
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                contextoUsuario="erp"
                endpoint="erp/inventario.buscarProdutos"
                getRequestParametersFunction={() => ({
                  vendaExterna: vendaExterna,
                  arquivado: arquivado
                })}
                items={produtosParaPesquisaFiltrados}
                onLoad={(items) => {
                  this.setState({
                    produtosParaPesquisa: items,
                    produtosParaPesquisaFiltrados: this.getProdutosFiltrados(items)
                  })
                }}
                loaderFunctionsMap={this.loaderStep3FunctionsMap}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderProdutoRow}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum produto foi encontrado',
                  textButton: 'Cadastrar Produto',
                  creationFunction: () => {
                    this.creationFunction({ text: this.inputPesquisaNomeProduto.current.value.trim() })
                  }
                }}
              />
            )}
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

function ListItemSelectable(props) {
  return (
    <Grid data-testid='product' container alignItems="center" className={props.classesNamesObject.rootListItem} onClick={props.onClick}>
      <Grid item xs className={props.classesNamesObject.contentListItem}>
        <Typography variant="body2" className={props.classesNamesObject.primaryListItem}>
          {props.primary}
        </Typography>
        {props.secondary && (
          <Typography variant="body2" className={props.classesNamesObject.secondaryListItem}>
            {props.secondary}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <NavigateNextIcon className={props.classesNamesObject.iconListItem} />
      </Grid>
    </Grid>
  )
}

InputProdutoDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputProdutoDialog)
