import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import moment from 'moment'
import InputCaixa from 'pages/erp/painel/caixa/input/InputCaixa'
import ColaboradorDialogPage from 'pages/erp/painel/colaborador/ColaboradorDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import PassiveDynamicContent from 'support/components/passivedynamiccontent/PassiveDynamicContent'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  textoErro: {
    color: theme.palette.text.negative
  }
})

class RegistrarPagamentoDialogPage extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      paginaCarregada: true,
      title: 'Pagamento',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      caixas: []
    }

    this.dataFunctionsMap = {}
    this.inputObservacoes = React.createRef()
    this.inputValorPagamento = React.createRef()
    this.inputCaixaFunctionsMap = {}
    this.resumoCalculoValeFunctionMap = {}
    this.containerCampoValorPagamentoFunctionMap = {}
    this.contentWithPreloadFunctions = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoColaborador', (props) => {
      this.contentWithPreloadFunctions.reload()
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/colaborador/remuneracoes.buscarDadosParaRegistroPagamento',
      params: {
        idColaborador: this.props.colaborador.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const caixas = response.data.caixas
        const valesDisponiveis = response.data.valesDisponiveis

        for (let valesDisponivel of valesDisponiveis) {
          valesDisponivel._checked = true
          valesDisponivel.inputRef = React.createRef()
          valesDisponivel._editando = false
        }

        this.setState({
          caixas: caixas,
          valesDisponiveis: valesDisponiveis,
          paginaCarregada: true,
          toolbarActions: {
            actions: [
              {
                label: 'Salvar',
                showOnlyMobile: true,
                handleAction: () => this.salvar()
              }
            ]
          }
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/colaborador/remuneracoes.registrarPagamento',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoPagamentoComissao')
        this.props.setShouldCloseParent(true)
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        const errorsMap = createErrorsMap(response.data.errors)
        this.props.setShouldCloseParent(true)

        if (errorsMap('dadosDesatualizados')) {
          openBackableDialog(MessageDialog, {
            title: 'Erro no Processamento',
            text: (
              <span>
                Os dados desta página estão desatualizados.
                <br />
                <b className={this.props.classes.textoErro}>Inicie o processo de pagamento novamente.</b>
              </span>
            ),
            parent: this,
            shouldCloseParent: true
          })
        } else {
          this.setState((state) => ({ ajaxing: false, errorsMap: errorsMap }))
          focusFirstElementWithError('formPagamentoComissao')
        }
      }
    })
  }

  getDados = () => {
    const remuneracoes = []
    for (let remuneracao of this.props.remuneracoesSelecionadas) {
      remuneracoes.push({
        id: remuneracao.id
      })
    }

    const valesAbatimentos = []
    if (this.state.valesDisponiveis && this.state.valesDisponiveis.length > 0) {
      for (let valesDisponivel of this.state.valesDisponiveis) {
        if (valesDisponivel.inputRef.current) {
          const valeAbatimento = {
            vale: { id: valesDisponivel.id }
          }
          if (valesDisponivel._checked) {
            valeAbatimento.valorAbatimento = FormUtil.convertStringToNumber(valesDisponivel.inputRef.current.value)
          }
          valesAbatimentos.push(valeAbatimento)
        }
      }
    }

    const dados = {
      idColaborador: this.props.colaborador.id,
      dataPesquisaInicial: converterMomentParaDataInt(this.props.dataPesquisaInicial),
      dataPesquisaFinal: converterMomentParaDataInt(this.props.dataPesquisaFinal),
      valorPendentePagamento: this.props.valorPendentePagamento,
      remuneracoes: remuneracoes,
      observacoes: this.inputObservacoes.current.value,
      valorPagamento: FormUtil.convertStringToNumber(this.inputValorPagamento.current.value),
      dataPagamento: this.dataFunctionsMap.getDataAsInt(),
      valesAbatimentos: valesAbatimentos,
      caixa: this.inputCaixaFunctionsMap.getCaixa()
    }

    return dados
  }

  getValorTotalAbatimentos = () => {
    let valorAbatimentoVale = 0
    if (this.state.valesDisponiveis && this.state.valesDisponiveis.length > 0) {
      for (let valesDisponivel of this.state.valesDisponiveis) {
        if (valesDisponivel._checked && valesDisponivel.inputRef.current) {
          valorAbatimentoVale += FormUtil.convertStringToNumber(valesDisponivel.inputRef.current.value)
        }
      }
    }
    return valorAbatimentoVale
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, colaborador } = this.props
    const { caixas, ajaxing, errorsMap } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={380}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.contentWithPreloadFunctions}>
          {() => (
            <form id="formPagamentoComissao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Grid container alignItems="center">
                <Grid item style={{ marginRight: -2 }}>
                  <Typography variant="h6">{colaborador.apelido.split(' ')[0]} </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      openBackableDialog(ColaboradorDialogPage, {
                        idColaborador: colaborador.id
                      })
                    }}
                  >
                    <OpenInNewIcon fontSize="small" style={{ display: 'block' }} />
                  </IconButton>
                </Grid>
              </Grid>

              <Typography variant="body2">
                Período: {this.props.dataPesquisaInicial.format('DD/MMM/YY')} até {this.props.dataPesquisaFinal.format('DD/MMM/YY')}
              </Typography>
              <Typography variant="body2">Remunerações selecionadas: {this.props.remuneracoesSelecionadas.length}</Typography>
              <Typography variant="body2">Valor a pagar: {formatarValorMonetario(this.props.valorPendentePagamento)}</Typography>
              {this.state.valesDisponiveis && this.state.valesDisponiveis.length > 0 && (
                <React.Fragment>
                  <br />
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    <b>Vales Disponíveis</b>
                  </Typography>
                  {this.state.valesDisponiveis.map((vale, index) => (
                    <div key={index}>
                      <FormControlLabel
                        style={{ marginTop: 0 }}
                        control={
                          <Checkbox
                            color="primary"
                            style={{ padding: 6 }}
                            disabled={ajaxing}
                            checked={vale._checked}
                            onChange={(event) => {
                              if (!vale._editandoChangeTime || new Date().getTime() - vale._editandoChangeTime > 100) {
                                vale._checked = !vale._checked
                                this.setState({ valesDisponiveis: this.state.valesDisponiveis })
                                this.resumoCalculoValeFunctionMap.update()
                                this.containerCampoValorPagamentoFunctionMap.update()
                              }
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" style={{ paddingTop: 4, paddingBottom: 4 }}>
                            <span style={{ paddingRight: vale._checked ? 6 : 0 }}>
                              {vale.nome} - {converterDataIntParaMoment(vale.data).format('DD/MMM/YY')}{' '}
                              {!vale._editando ? (
                                <span>
                                  {' '}
                                  - <span style={{ whiteSpace: 'nowrap' }}>{formatarValorMonetario(vale.valorDisponivel)}</span>
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                            {vale._checked && (
                              <Link
                                color="secondary"
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={(event) => {
                                  vale._editandoChangeTime = new Date().getTime()
                                  vale._editando = !vale._editando
                                  this.setState({ valesDisponiveis: this.state.valesDisponiveis })
                                }}
                              >
                                {vale._editando ? 'Abater Tudo' : 'Editar'}
                              </Link>
                            )}
                          </Typography>
                        }
                      />
                      <InputText
                        marginTop={8}
                        style={{ display: vale._checked && vale._editando ? undefined : 'none' }}
                        key={'valorVale_' + vale.id}
                        disabled={ajaxing}
                        customVariant="outlined-small"
                        shrink={false}
                        idname={'xvalorValeAbatimento' + vale.id}
                        label="Valor para Abatimento (R$)"
                        defaultValue={vale.valorDisponivel}
                        onChange={() => {
                          this.resumoCalculoValeFunctionMap.update()
                          this.containerCampoValorPagamentoFunctionMap.update()
                        }}
                        errorMessage={errorsMap('valeAbatimento[' + index + '].valorAbatimento')}
                        staticHelpMessage={
                          <span>
                            Valor máximo: <b>{formatarValorMonetario(vale.valorDisponivel)}</b>
                          </span>
                        }
                        inputComponent={InputMoneyFormat}
                        inputRef={vale.inputRef}
                        inputProps={{
                          minValue: 0
                        }}
                      />
                    </div>
                  ))}
                </React.Fragment>
              )}
              <br />
              <Typography variant="body1">
                <b>Pagamento</b>
              </Typography>
              <PassiveDynamicContent
                fistUpdateOnMount={true}
                updateFunction={() => {
                  let localValorAbatimentoVale = this.getValorTotalAbatimentos()
                  let valorPendenteParaPagamento = this.props.valorPendentePagamento - localValorAbatimentoVale
                  if (valorPendenteParaPagamento < 0) {
                    valorPendenteParaPagamento = 0
                  }
                  if (localValorAbatimentoVale <= 0) {
                    return {
                      content: null
                    }
                  }
                  return {
                    content: (
                      <React.Fragment>
                        <br />
                        <Typography variant="body2">
                          Total a pagar: {formatarValorMonetario(this.props.valorPendentePagamento)}
                          <br />
                          Abatimento de Vale: {formatarValorMonetario(localValorAbatimentoVale)}
                          <br />
                          <b>Pendente para Pagamento: {formatarValorMonetario(valorPendenteParaPagamento)}</b>
                        </Typography>
                      </React.Fragment>
                    )
                  }
                }}
                functionsMap={this.resumoCalculoValeFunctionMap}
              />

              <InputText
                id="xobservacoes"
                name="xobservacoes"
                marginTop={true}
                customVariant={'outlined-small'}
                shrink={false}
                defaultValue={''}
                errorMessage={errorsMap('observacoes')}
                label="Observações (Opcional)"
                inputProps={{
                  maxLength: 2000
                }}
                inputRef={this.inputObservacoes}
              />

              <InputData
                sstyle={{ maxWidth: 230 }}
                marginTop={true}
                disabled={ajaxing}
                functionsMap={this.dataFunctionsMap}
                keyboard={true}
                defaultValue={new Date()}
                idname="xdata"
                customVariant={'outlined-small'}
                shrink={false}
                errorMessage={errorsMap('dataPagamento')}
                label={'Data do Pagamento'}
                onChange={(data) => {
                  this.inputCaixaFunctionsMap.setData(data ? moment(data) : null)
                }}
              />

              <PassiveDynamicContent
                fistUpdateOnMount={true}
                updateFunction={() => {
                  let localValorAbatimentoVale = this.getValorTotalAbatimentos()
                  let defaultValue = this.props.valorPendentePagamento - localValorAbatimentoVale
                  if (defaultValue < 0) {
                    defaultValue = 0
                  }
                  return {
                    content: (
                      <InputText
                        key={'xvalorPagamento' + localValorAbatimentoVale}
                        marginTop={true}
                        disabled={ajaxing}
                        customVariant="outlined-small"
                        shrink={false}
                        idname={'xvalorPagamento'}
                        label="Valor do Pagamento (R$)"
                        defaultValue={defaultValue}
                        errorMessage={errorsMap('valorPagamento')}
                        inputComponent={InputMoneyFormat}
                        inputRef={this.inputValorPagamento}
                        inputProps={{
                          minValue: 0
                        }}
                      />
                    )
                  }
                }}
                functionsMap={this.containerCampoValorPagamentoFunctionMap}
              />

              <InputCaixa
                disabled={ajaxing}
                marginTop={true}
                customVariant={'outlined-small'}
                shrink={false}
                data={moment()}
                colaboradorPreferencial={getSessaoPainel().colaborador}
                utilizarDefaultValue={true}
                caixas={caixas}
                errorsMap={errorsMap}
                descricaoData="data do pagamento"
                labelUtilizacaoCaixa="Pago com dinheiro do caixa. Esta opção faz com que essa despesa entre no fluxo de caixa como uma retirada de dinheiro do caixa."
                functionsMap={this.inputCaixaFunctionsMap}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Voltar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

RegistrarPagamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RegistrarPagamentoDialogPage)
