import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import NegocioInputDialogSelect from 'pages/admin/painel/negocio/input/NegocioInputDialogSelect'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import CustomDialog from 'support/components/dialog/CustomDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import FormUtil from 'support/util/FormUtil'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 400,
    width: '100%',
    margin: 4
  }
})

class TransferirPagamentoNegocioDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorsMap: FormUtil.createErrorsMap()
    }

    this.inputNegocioDestino = React.createRef()
    this.negocioDestinoFunctionMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.recalcular()
  }

  recalcular = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'admin/negocios.transferirPagamento',
      data: {
        idNegocioDestino: this.negocioDestinoFunctionMap.getValue(),
        idNegocioOrigem: this.props.idNegocioOrigem,
        idPagamento: this.props.idPagamento
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoPagamento')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formTransferir')
      }
    })
  }

  render() {
    const { classes } = this.props
    const { errorsMap } = this.state

    return (
      <CustomDialog classes={{ paper: classes.root }} dialogProps={this.props}>
        <DialogTitle>Transferir Pagamento</DialogTitle>
        <DialogContent>
          <form id="formTransferir" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <NegocioInputDialogSelect
              customVariant="outlined-small"
              disabled={this.state.ajaxing}
              errorMessage={errorsMap('negocioDestino')}
              placeholder="Negócio Destino"
              functionsMap={this.negocioDestinoFunctionMap}
            />

            <FormUtil.HiddenSubmitButton />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
            Cancelar
          </Button>
          <Button color="secondary" disabled={this.state.ajaxing} onClick={this.recalcular}>
            {this.state.ajaxing ? 'Aguarde' : 'Transferir'}
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

TransferirPagamentoNegocioDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TransferirPagamentoNegocioDialog)
