import React, { Component } from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

class PointOfSale extends Component {
  render() {
    const { ...others } = this.props

    return (
      <SvgIcon viewBox="0 0 16 16" {...others}>
        <path d="M 4.5 1 C 3.6774686 1 3 1.6774686 3 2.5 L 3 9.5 C 3 10.146861 3.4209662 10.697774 4 10.90625 L 4 12.5 C 4 13.322531 4.6774686 14 5.5 14 L 9.5 14 C 10.322531 14 11 13.322531 11 12.5 L 11 10.90625 C 11.579034 10.697774 12 10.146861 12 9.5 L 12 2.5 C 12 1.6774686 11.322531 1 10.5 1 L 6.875 1 L 4.5 1 z M 4.5 2 L 6.875 2 L 10.5 2 C 10.781469 2 11 2.2185314 11 2.5 L 11 9.5 C 11 9.7814686 10.781469 10 10.5 10 L 5 10 L 4.5 10 C 4.2185314 10 4 9.7814686 4 9.5 L 4 2.5 C 4 2.2185314 4.2185314 2 4.5 2 z M 5 3 L 5 5 L 10 5 L 10 3 L 5 3 z M 13 3 L 13 9 L 14 9 L 14 3 L 13 3 z M 5 6 L 5 7 L 6 7 L 6 6 L 5 6 z M 7 6 L 7 7 L 8 7 L 8 6 L 7 6 z M 9 6 L 9 7 L 10 7 L 10 6 L 9 6 z M 5 8 L 5 9 L 6 9 L 6 8 L 5 8 z M 7 8 L 7 9 L 8 9 L 8 8 L 7 8 z M 9 8 L 9 9 L 10 9 L 10 8 L 9 8 z M 5 11 L 8 11 L 8 13 L 5.5 13 C 5.2185314 13 5 12.781469 5 12.5 L 5 11 z M 9 11 L 10 11 L 10 12.5 C 10 12.781469 9.7814686 13 9.5 13 L 9 13 L 9 11 z" />
      </SvgIcon>
    )
  }
}

export default PointOfSale
