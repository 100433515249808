import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  ANTECEDENCIA_15: { id: 15, descricao: '15 minutos antes' },
  ANTECEDENCIA_30: { id: 30, descricao: '30 minutos antes' },
  ANTECEDENCIA_60: { id: 60, descricao: '1 hora antes' },
  ANTECEDENCIA_120: { id: 120, descricao: '2 horas antes' },
  ANTECEDENCIA_180: { id: 180, descricao: '3 horas antes' },
  ANTECEDENCIA_240: { id: 240, descricao: '4 horas antes' },
  ANTECEDENCIA_360: { id: 360, descricao: '6 horas antes' },
  ANTECEDENCIA_720: { id: 720, descricao: '12 horas antes' },
  ANTECEDENCIA_1440: { id: 1440, descricao: '24 horas antes' },
  ANTECEDENCIA_2880: { id: 2880, descricao: '48 horas antes' },
  ANTECEDENCIA_4320: { id: 4320, descricao: '3 dias antes' },
  ANTECEDENCIA_5760: { id: 5760, descricao: '4 dias antes' },
  ANTECEDENCIA_7200: { id: 7200, descricao: '5 dias antes' },
  ANTECEDENCIA_28640: { id: 8640, descricao: '6 dias antes' },
  ANTECEDENCIA_10080: { id: 10080, descricao: '7 dias antes' }
})
export default enumObject
