import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/ApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class AlteracaoSenhaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: true,
      title: 'Alteração Senha',
      errorsMap: createErrorsMap(),
      showCurrentPassword: false,
      showPassword: false,
      ajaxing: false,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }

    this.inputSenhaAtual = React.createRef()
    this.inputSenha = React.createRef()
    this.inputSenhaConfirmacao = React.createRef()
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  handleClickShowCurrentPassword = () => {
    this.setState((state) => ({ showCurrentPassword: !state.showCurrentPassword }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirSenha()
  }

  persistirSenha = () => {
    postAPI({
      url: 'cda/usuarios.alterarSenha',
      data: this.getSenhas(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoUsuario')
        SessaoUsuario.registrarUsuario(response.data)
        EventsManager.pub('AtualizarSessao')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formUsuario')
      }
    })
  }

  getSenhas = () => {
    const dados = {}
    dados.senhaAtual = this.inputSenhaAtual.current.value
    dados.senha = this.inputSenha.current.value
    dados.senhaConfirmacao = this.inputSenhaConfirmacao.current.value
    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <form id="formUsuario" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
          <InputText
            idname="xsenhaAtual"
            customVariant={'outlined-small'}
            autoFocus={true}
            shrink={false}
            disabled={this.state.ajaxing}
            type={this.state.showCurrentPassword ? 'text' : 'password'}
            errorMessage={errorsMap('senhaAtual')}
            label="Senha Atual"
            inputRef={this.inputSenhaAtual}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowCurrentPassword}>
                  {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />

          <InputText
            style={{ marginTop: 18 }}
            idname="xsenhanova"
            customVariant={'outlined-small'}
            shrink={false}
            disabled={this.state.ajaxing}
            type={this.state.showPassword ? 'text' : 'password'}
            errorMessage={errorsMap('senha')}
            helpMessage="Use oito ou mais caracteres com uma combinação de letras e números"
            label="Nova senha"
            inputRef={this.inputSenha}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />

          <InputText
            style={{ marginTop: 18 }}
            idname="xsenhanovaconfirmacao"
            customVariant={'outlined-small'}
            shrink={false}
            disabled={this.state.ajaxing}
            type={this.state.showPassword ? 'text' : 'password'}
            errorMessage={errorsMap('senhaConfirmacao')}
            label="Confirmar nova senha"
            inputRef={this.inputSenhaConfirmacao}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />

          <HiddenSubmitButton />
        </form>

        {this.state.paginaCarregada && (
          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
              Cancelar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
              {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
            </Button>
          </div>
        )}
      </DialogPage>
    )
  }
}

AlteracaoSenhaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AlteracaoSenhaDialogPage)
