import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import PacoteDialogPage from 'pages/erp/painel/pacotes/PacoteDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaPreco: {
    minWidth: 120,
    maxWidth: 120
  },
  colunaBotao: {
    minWidth: 80,
    maxWidth: 80
  },
  nomePacoteContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 3 })
  }
})

class PacotesPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      lista: []
    }

    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoPacote', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerOpenCreateDialog = () => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PACOTE])) {
      openBackableDialog(PacoteDialogPage)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = (item) => {
    openBackableDialog(PacoteDialogPage, {
      idPacote: item.id
    })
  }

  handlerRemover = (pacote) => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PACOTE])) {
      openBackableDialog(EntityRemovalDialog, {
        title: 'Remover Pacote',
        text: (
          <span>
            Você tem certeza que deseja remover o pacote <b>{pacote.nome}</b>? Essa operação não poderá ser desfeita.
          </span>
        ),
        call: {
          method: 'post',
          url: 'erp/pacotes.remover',
          contextoUsuario: 'erp',
          data: {
            id: pacote.id
          },
          onSuccess: () => {
            EventsManager.pub('ManipulacaoPacote')
          }
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Lista de Pacotes - ' + moment().format('YYYYDDMM'),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'pacotes.gerarListaPacotes',
        params: this.getRequestParameters()
      }
    })
  }

  getRequestParameters = () => {
    return {
      nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.nome = item.nome
    content.preco = formatarValorMonetario(item.preco)

    const buttonStyle = {}

    if (size === 'small') {
      buttonStyle.marginRight = -8
    }

    content.botao = (
      <IconButton
        style={buttonStyle}
        onClick={(event) => {
          event.stopPropagation()
          this.handlerRemover(item)
        }}
      >
        <DeleteIcon />
      </IconButton>
    )

    return content
  }

  render() {
    const { classes, ...others } = this.props

    const toolbarActions = {
      actions: [
        {
          icon: <AddIcon />,
          handleAction: this.handlerOpenCreateDialog
        }
      ]
    }

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <PanelPage {...others} title="Pacotes" toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent type="filter">
          <Grid container alignItems="center">
            <Grid item xs>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    nakedLeftPadding={false}
                    placeholder="Procurar por nome..."
                    onChange={(event) => {
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>

        <br />

        <VirtualizedResponsiveTable
          minTableWidth={650}
          windowScroller={true}
          showBackgroundDividers={true}
          itemsPerRequest={30}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/pacotes.buscar"
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.handlerEditar(item)
          }}
          largeRenderProps={{
            columns: [
              { label: 'Nome do Pacote', props: { xs: true } },
              { label: 'Preço', className: classes.colunaPreco, horizontalPadding: 'small', align: 'right' },
              { className: classes.colunaBotao, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (pacote, index) => {
              const content = this.getRenderContent('large', pacote)

              return {
                itemData: [content.nome, content.preco, content.botao]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 90,
            itemRenderer: (pacote, index) => {
              const content = this.getRenderContent('small', pacote)

              return {
                itemData: (
                  <Grid container style={{ backgroundColor: 'inherit' }} alignItems="center">
                    <Grid item xs style={{ backgroundColor: 'inherit' }}>
                      <Typography variant="body2" className={classes.nomePacoteContainerSmall}>
                        {content.nome}
                      </Typography>
                      <Typography variant="body2" style={{ marginTop: 4 }}>
                        <b>{content.preco}</b>
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: 4 }}>
                      {content.botao}
                    </Grid>
                  </Grid>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum pacote foi encontrado',
            textButton: 'Cadastrar pacote',
            creationFunction: this.handlerOpenCreateDialog
          }}
        />

        {this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            <Typography variant="body2">
              Pacotes cadastrados: <b>{this.state.lista.length}</b>
              <br />
            </Typography>
          </FloatContent>
        )}
      </PanelPage>
    )
  }
}

PacotesPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PacotesPanelPage)
