import { PagamentoPeriodoPlanoEnumType } from 'support/components/pagamentoperiodo/types'

const PagamentoPeriodoPlanoEnum: PagamentoPeriodoPlanoEnumType = require('support/domain/negocio/PagamentoPeriodoPlanoEnum').default
export function getPeriodName(name: string): string {
  switch (name) {
    case 'ANUAL':
      return PagamentoPeriodoPlanoEnum.ANUAL.descricao
    case 'SEMESTRAL':
      return PagamentoPeriodoPlanoEnum.SEMESTRAL.descricao
    case 'TRIMESTRAL':
      return PagamentoPeriodoPlanoEnum.TRIMESTRAL.descricao
    case 'MENSAL':
      return PagamentoPeriodoPlanoEnum.MENSAL.descricao
    default:
      return PagamentoPeriodoPlanoEnum.ANUAL.descricao
  }
}
