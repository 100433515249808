import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import RemuneracoesDetalhadasResponsiveTable from 'pages/erp/painel/remuneracao/table/RemuneracoesDetalhadasResponsiveTable'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class RemuneracoesPagasDetalhesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      lista: [],
      ajaxing: false
    }

    this.dataExporterFunctionsMap = {}
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Relatório de Comissões - Pagamento ' + this.props.pagamento.dataPagamento,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'colaborador/remuneracoes.gerarListaDetalhesRemuneracoesPorPagamento',
        params: this.getRequestParameters()
      }
    })
  }

  getRequestParameters = () => {
    return {
      idPagamento: this.props.pagamento.id
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_PRECO_VENDA_REMUNERACAO])) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    let quantidade = 0
    let valorTotalPago = 0

    if (this.state.lista) {
      for (let item of this.state.lista) {
        quantidade++
        valorTotalPago += item.valorPago ? item.valorPago : 0
      }
    }

    return (
      <DialogPage {...dialogProps} title="Detalhes do Pagamento" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Pagamento #{this.props.pagamento.numero}</Typography>
              <Typography variant="body1">Detalhamentos de remunerações</Typography>
            </FloatContent>

            <RemuneracoesDetalhadasResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              itemsPerRequest={30}
              loaderFunctionsMap={this.loaderFunctionsMap}
              autoIncrementNewItems={false}
              mostrarValorPendente={false}
              contextoUsuario="erp"
              endpoint="erp/colaborador/remuneracoes.buscarRemuneracoesPorPagamento"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  let listaCarregada = []
                  for (let remuneracaoPagamentoRemuneracao of data.items) {
                    remuneracaoPagamentoRemuneracao.remuneracao.valorPago = remuneracaoPagamentoRemuneracao.valorPago
                    listaCarregada.push(remuneracaoPagamentoRemuneracao.remuneracao)
                  }
                  listaCarregada = this.state.lista.concat(listaCarregada)

                  this.setState({ lista: listaCarregada })
                }
              }}
              emptyListProps={{
                text: 'Nenhuma remuneracão foi encontrada'
              }}
            />

            <br />

            {
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">Quantidade: {quantidade}</Typography>
                <Typography variant="body2">Total Pago: {formatarValorMonetario(valorTotalPago)}</Typography>
              </FloatContent>
            }
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RemuneracoesPagasDetalhesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RemuneracoesPagasDetalhesDialogPage)
