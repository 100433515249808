/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import SessaoPainelErp from 'support/components/sessao/SessaoPainelErp'
import { Installments } from 'support/domain/formapagamento/FormaPagamentoType'

import { useStyles } from './FormaPagamentoMercadoPago.styles'
import { FormPagamentoMercadoPago } from './FormPagamentoMercadoPago'

type ResponseData = {
  idNegocio: number
  taxaDebito: number
  taxaCreditoVista: number
  parcelas: Installments[]
}

const initialForm = {
  device: 'Maquininha Mercado Pago',
  taxCredit: '',
  taxDebit: '',
  hasInstallments: 'true',
  installmentsList: [] as Installments[],
  installments: '',
  responseData: {} as ResponseData,
  loading: false
}

type PagamentoMercadoPagoProps = {
  formaPagamento: {
    nome: string
  }
  typePayment: string
}

const PagamentoMercadoPago = ({ formaPagamento, typePayment, ...rest }: PagamentoMercadoPagoProps) => {
  const [form, setForm] = React.useState(initialForm)
  const [loading, setLoading] = React.useState(false)
  const classes = useStyles()
  const dialogProps = extractDialogProps(rest)
  const sessaoUsuario = SessaoPainelErp.getDadosNegocioSessaoFromUrl()

  const handleChange = (key: keyof typeof form, value: unknown) => {
    setForm((prevVal) => ({ ...prevVal, [key]: value }))
  }

  const handleInstallments = () => {
    const MAX_INSTALLMENTS = 36
    const list = []
    for (let index = 1; index < MAX_INSTALLMENTS; index++) {
      const actualNumber = index + 1
      list.push({ numero: actualNumber, taxa: 0 })
    }
    return list
  }

  const handleUpdateList = (oldList: Installments[], dataList: Installments[]) => {
    oldList.forEach((oldItem) => {
      const correspondente = dataList.find((newItem) => oldItem.numero === newItem.numero)
      if (correspondente) {
        oldItem.taxa = correspondente.taxa
      }
    })
    return oldList
  }

  const handleGetData = () => {
    setLoading(true)

    const installmentsList = handleInstallments()
    handleChange('installmentsList', installmentsList)
    getAPI({
      url: `erp/formaspagamento.buscarConfiguracaoMercadoPago?idNegocio=${sessaoUsuario?.idNegocio}`,
      requerAutorizacao: true,
      onSuccess: (response: { data: ResponseData }) => {
        if (response.data.parcelas.length > 0) {
          const formatedList = handleUpdateList(installmentsList, response.data.parcelas)
          handleChange('installmentsList', formatedList)
        } else {
          handleChange('hasInstallments', false)
        }
        handleChange('taxDebit', response.data.taxaDebito)
        handleChange('taxCredit', response.data.taxaCreditoVista)
        handleChange('installments', response.data.parcelas.length + 1)
        setLoading(false)
      }
    })
  }

  const handleSubmit = () => {
    const data = {
      idNegocio: sessaoUsuario?.idNegocio,
      taxaDebito: form.taxDebit,
      taxaCreditoVista: form.taxCredit,
      parcelas: form.hasInstallments === 'true' ? form.installmentsList.filter((item) => item.taxa > 0) : []
    }

    postAPI({
      url: 'erp/formaspagamento.persistirConfiguracaoMercadoPago',
      data,
      requerAutorizacao: true,
      onSuccess: () => {
        dialogProps.handleCloseDialog()
      }
    })
  }

  React.useEffect(() => {
    handleGetData()
  }, [])

  return (
    <DialogPage
      {...dialogProps}
      title="Alterar"
      align="center"
      contentMaxWidth="default"
      toolbarActions={{
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: handleSubmit
          }
        ]
      }}
    >
      {loading ? (
        <Box className={classes.loadingContent}>
          <CircularProgress data-testid="loading" size={34} thickness={5.2} />
        </Box>
      ) : (
        <FormPagamentoMercadoPago
          typePayment={typePayment}
          form={form}
          formaPagamento={formaPagamento}
          onCloseDialog={dialogProps.handleCloseDialog}
          onFormChange={handleChange}
          onSubmit={handleSubmit}
        />
      )}
    </DialogPage>
  )
}
export default PagamentoMercadoPago
