import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FormUtil from 'support/util/FormUtil'
import { createErrorsMap } from 'support/util/FormUtil'
import { InputPercentWithSuffixFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class FormaPagamentoPixDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Pix',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputTaxa = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/formaspagamento.buscarConfiguracaoPix',
      params: {},
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState((state) => ({
          pix: response.data
        }))

        this.registrarPaginaCarregada(response.data)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: FormUtil.createErrorsMap()
    }))

    this.persistirConfiguracaoPix()
  }

  getDados() {
    const dados = {
      id: this.state.pix.id
    }

    let taxa = 0
    if (this.inputTaxa.current.value) {
      taxa = this.inputTaxa.current.value === '' ? '0' : this.inputTaxa.current.value.replace('%', '')
    }

    dados.taxa = FormUtil.convertStringToNumber(taxa)

    return dados
  }

  persistirConfiguracaoPix() {
    postAPI({
      url: 'erp/formaspagamento.persistirConfiguracaoPix',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoFormaPagamentoPix')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formConfiguracaoPix')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, pix } = this.state
    let { taxa } = pix ? pix : {}

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formConfiguracaoPix" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                marginTop={true}
                id="xtaxa"
                name="xtaxa"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={taxa}
                disabled={this.state.ajaxing}
                inputComponent={InputPercentWithSuffixFormat}
                inputProps={{
                  minValue: 0,
                  maxValue: 100
                }}
                errorMessage={errorsMap('taxa')}
                label="Taxa de Recebimento"
                inputRef={this.inputTaxa}
              />

              <FormUtil.HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

FormaPagamentoPixDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FormaPagamentoPixDialogPage)
