function getStyles({ defaultTheme, tema, constant }) {
  return {
    text: {
      singleLine: function (props) {
        if (!props) {
          props = {}
        }

        return {
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: props.withEllipsis === false ? null : 'ellipsis'
        }
      },

      limitedLines: function (props) {
        if (!props) {
          props = {}
        }

        if (!props.maxLines) {
          props.maxLines = 2
        }

        const style = {
          display: '-webkit-box',
          lineClamp: props.maxLines,
          overflow: 'hidden',
          textOverflow: props.withEllipsis === false ? null : 'ellipsis',
          boxOrient: 'vertical'
        }

        return style
      }
    }
  }
}

const objectFunctions = {
  getStyles: getStyles
}

export default objectFunctions
