import { makeStyles } from '@material-ui/core'

const FONT_WEIGHT = 400

export const useStyles = makeStyles((theme) => ({
  container: { maxWidth: 1010, display: 'flex', flexDirection: 'column', gap: 8 },
  header: { color: '#222222', fontWeight: 500, fontSize: '16px', lineHeight: '24px' },
  imageContainer: {
    marginBlockEnd: '24px',
    width: '100%',
    maxHeight: 225,
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(180deg, #E3ECFF 0%, #FFFFFF 44.25%)',
    borderRadius: '12px'
  },
  iconWithText: { display: 'flex', alignItems: 'center', gap: 8 },
  icon: { color: '#00325F' },
  description: { color: '#666666', fontWeight: FONT_WEIGHT, fontSize: '14px', lineHeight: '24px' },
  button: {
    marginBlockStart: '24px',
    height: '48px',
    width: '220px',
    backgroundColor: '#264FEC',
    color: '#FFFFFF',
    fontWeight: FONT_WEIGHT,
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'none',
    maxWidth: 'fit-content',
    [theme.breakpoints.down('xs')]: { maxWidth: '100%', width: '100%', height: '32px' },
    '&:hover': { backgroundColor: '#002EC9' }
  }
}))
