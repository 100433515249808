import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({})

class TabCaixaResumo extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      caixa: this.props.caixa,
      ajaxing: false
    }

    this.containerFunctionMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/caixas.buscarCaixaResumo',
      params: {
        idCaixa: this.props.caixa.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const caixa = response.data.caixa
        let pagamentosPorForma = []
        const despesasTotal = response.data.despesasTotal
        const entradasTotal = response.data.entradasTotal
        const sangriasTotal = response.data.sangriasTotal
        let saldoDinheiro = caixa.fundoCaixa + entradasTotal - sangriasTotal - despesasTotal
        let lucroTotal = 0 - despesasTotal

        response.data.formasPagamento.forEach(function (pagamentoPorForma, index) {
          if ((pagamentoPorForma.formaPagamento.ativo && !pagamentoPorForma.formaPagamento.excluido) || pagamentoPorForma.valor > 0) {
            if (!isNaN(pagamentoPorForma.valor)) {
              if (pagamentoPorForma.formaPagamento.tipo.id === FormaPagamentoTipoEnum.DINHEIRO.id && pagamentoPorForma.valor) {
                saldoDinheiro = saldoDinheiro + pagamentoPorForma.valor
              }
              lucroTotal = lucroTotal + pagamentoPorForma.valor
            }
            pagamentosPorForma.push(pagamentoPorForma)
          }
        })

        this.setState({
          pagamentosPorForma: pagamentosPorForma,
          caixa: caixa,
          despesasTotal: despesasTotal,
          entradasTotal: entradasTotal,
          sangriasTotal: sangriasTotal,
          saldoDinheiro: saldoDinheiro,
          lucroTotal: lucroTotal
        })

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const { pagamentosPorForma, caixa, despesasTotal, entradasTotal, sangriasTotal, saldoDinheiro, lucroTotal } = this.state

    let containerFormasPagamento = null

    if (pagamentosPorForma) {
      containerFormasPagamento = pagamentosPorForma.map((pagamentoPorForma, index) => (
        <Grid key={'gfp_' + index} container alignItems="center">
          <Grid item xs={6}>
            <Typography align="left" variant="body2">
              {pagamentoPorForma.formaPagamento.nome}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right" variant="body2">
              {formatarValorMonetario(pagamentoPorForma.valor)}
            </Typography>
          </Grid>
        </Grid>
      ))
    }

    return (
      <TabContainer>
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.loaderFunctionsMap}>
          {() => (
            <React.Fragment>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    <b>Fundo de Caixa</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    {formatarValorMonetario(caixa.fundoCaixa)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
              <Typography align="left" variant="body2">
                <b>Pagamentos</b>
              </Typography>
              <div style={{ padding: '8px 0px 8px 0px' }}>{containerFormasPagamento}</div>
              <Divider style={{ marginTop: 8, marginBottom: 16 }} />
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    <b>Total Despesas</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    {formatarValorMonetario(despesasTotal)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    <b>Total Sangrias</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    {formatarValorMonetario(sangriasTotal)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    <b>Total Entradas</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    {formatarValorMonetario(entradasTotal)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    <b>Saldo em dinheiro</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    {formatarValorMonetario(saldoDinheiro)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    <b>Lucro Total</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    {formatarValorMonetario(lucroTotal)}
                  </Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </ContentWithPreload>
      </TabContainer>
    )
  }
}

TabCaixaResumo.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabCaixaResumo)
