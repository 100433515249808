import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import DetalhesResumoGastosProdutosDialogPage from 'pages/erp/painel/relatorio/DetalhesResumoGastosProdutosDialogPage'
import DetalhesValesColaboradorDialogPage from 'pages/erp/painel/relatorio/DetalhesValesColaboradorDialogPage'
import DetalhesVendasDialogPage from 'pages/erp/painel/relatorio/DetalhesVendasDialogPage'
import RemuneracoesDetalhesDialogPage from 'pages/erp/painel/remuneracao/RemuneracoesDetalhesDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerInformacoes: {
    marginTop: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paperInformacoes: {
    ...theme.container.card({ fullHeight: true })
  },
  tituloInformacoesColaborador: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  tituloCard: {
    fontSize: 20,
    paddingTop: 8,
    paddingBottom: 8
  }
})

class RelatorioDetalhesColaboradoresDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      paginaCarregada: false,
      colaborador: this.props.colaborador,
      ajaxingDadosColaborador: false,
      dadosColaboradorDataInicial: this.props.dataInicial
        ? this.props.dataInicial
        : this.colaboradorLocalPreferences.getMomentPreference('RelatorioDetalhesColaboradoresDialogPageFiltro_dadosColaboradorDataInicial', moment().startOf('month')),
      dadosColaboradorDataFinal: this.props.dataFinal
        ? this.props.dataFinal
        : this.colaboradorLocalPreferences.getMomentPreference('RelatorioDetalhesColaboradoresDialogPageFiltro_dadosColaboradorDataFinal', moment().endOf('month')),
      dadosColaboradorIntervalo: this.props.tipoIntervalo ? this.props.tipoIntervalo : 'mes'
    }

    this.dadosFinanceirosLoaderFunctionsMap = {}
  }

  carregarDadosFinanceiros = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxingDadosColaborador: true })

    getAPI({
      url: 'erp/relatorios.buscarDadosColaborador',
      params: {
        idColaborador: this.state.colaborador.id,
        dadosColaboradorDataInicial: converterMomentParaDataInt(this.state.dadosColaboradorDataInicial),
        dadosColaboradorDataFinal: converterMomentParaDataInt(this.state.dadosColaboradorDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxingDadosColaborador: false,
          dadosColaborador: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxingDadosColaborador: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  abrirDetalhesVendasCredito = () => {
    openBackableDialog(DetalhesVendasDialogPage, {
      dataInicial: this.state.dadosColaboradorDataInicial,
      dataFinal: this.state.dadosColaboradorDataFinal,
      colaborador: this.props.colaborador,
      tipo: TipoVendaEnum.VENDA_CREDITO.id,
      tituloDialog: 'Créditos Vendidos'
    })
  }

  abrirDetalhesVendasProdutos = () => {
    openBackableDialog(DetalhesVendasDialogPage, {
      dataInicial: this.state.dadosColaboradorDataInicial,
      dataFinal: this.state.dadosColaboradorDataFinal,
      colaborador: this.props.colaborador,
      tipo: TipoVendaEnum.VENDA_PRODUTO.id,
      tituloDialog: 'Produtos Vendidos'
    })
  }

  abrirDetalhesVendasPacotes = () => {
    openBackableDialog(DetalhesVendasDialogPage, {
      dataInicial: this.state.dadosColaboradorDataInicial,
      dataFinal: this.state.dadosColaboradorDataFinal,
      colaborador: this.props.colaborador,
      tipo: TipoVendaEnum.VENDA_PACOTE.id,
      tituloDialog: 'Pacotes Vendidos'
    })
  }

  abrirDetalhesAtendimentos = () => {
    openBackableDialog(DetalhesVendasDialogPage, {
      dataInicial: this.state.dadosColaboradorDataInicial,
      dataFinal: this.state.dadosColaboradorDataFinal,
      colaborador: this.props.colaborador,
      tipo: TipoVendaEnum.ATENDIMENTO.id,
      tituloDialog: 'Atendimentos'
    })
  }

  abrirDetalhesComissoesTotais = () => {
    openBackableDialog(RemuneracoesDetalhesDialogPage, {
      colaborador: this.props.colaborador,
      dataPesquisaInicial: this.state.dadosColaboradorDataInicial,
      dataPesquisaFinal: this.state.dadosColaboradorDataFinal,
      tipo: TipoRemuneracaoEnum.COMISSAO
    })
  }

  abrirDetalhesComissoesRecebidas = () => {
    openBackableDialog(RemuneracoesDetalhesDialogPage, {
      colaborador: this.props.colaborador,
      dataPesquisaInicial: this.state.dadosColaboradorDataInicial,
      dataPesquisaFinal: this.state.dadosColaboradorDataFinal,
      tipo: TipoRemuneracaoEnum.COMISSAO,
      apenasComPagamentoRegistrado: true
    })
  }

  abrirDetalhesGorjetasColaborador = () => {
    openBackableDialog(RemuneracoesDetalhesDialogPage, {
      colaborador: this.props.colaborador,
      dataPesquisaInicial: this.state.dadosColaboradorDataInicial,
      dataPesquisaFinal: this.state.dadosColaboradorDataFinal,
      tipo: TipoRemuneracaoEnum.GORJETA
    })
  }

  abrirDetalhesGorjetasRecebidas = () => {
    openBackableDialog(RemuneracoesDetalhesDialogPage, {
      colaborador: this.props.colaborador,
      dataPesquisaInicial: this.state.dadosColaboradorDataInicial,
      dataPesquisaFinal: this.state.dadosColaboradorDataFinal,
      tipo: TipoRemuneracaoEnum.GORJETA,
      apenasComPagamentoRegistrado: true
    })
  }

  abrirDetalhesValesColaborador = () => {
    openBackableDialog(DetalhesValesColaboradorDialogPage, {
      dataInicial: this.state.dadosColaboradorDataInicial,
      dataFinal: this.state.dadosColaboradorDataFinal,
      colaborador: this.props.colaborador
    })
  }

  abrirDetalhesGastosProdutos = () => {
    openBackableDialog(DetalhesResumoGastosProdutosDialogPage, {
      dataInicial: this.state.dadosColaboradorDataInicial,
      dataFinal: this.state.dadosColaboradorDataFinal,
      colaborador: this.props.colaborador
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxingDadosColaborador, dadosColaborador, dadosColaboradorDataInicial, dadosColaboradorDataFinal, dadosColaboradorIntervalo } = this.state

    let toolbarActions = null
    if (!ajaxingDadosColaborador) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.dadosFinanceirosLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let dadosColaboradorLoadKey =
      converterMomentParaDataInt(this.state.dadosColaboradorDataInicial).toString() + '-' + converterMomentParaDataInt(this.state.dadosColaboradorDataFinal).toString()

    return (
      <DialogPage
        {...dialogProps}
        title={this.props.colaborador.apelido}
        align="center"
        contentMaxWidth={1200}
        pageType="basicEntity"
        ajaxing={ajaxingDadosColaborador}
        toolbarActions={toolbarActions}
      >
        <div style={{ padding: '20px 24px 16px 24px', textAlign: 'center' }}>
          <Typography variant="h5" align="center" className={classes.tituloInformacoesColaborador}>
            Informações Gerais
          </Typography>
          <div style={{ display: 'inline-block' }}>
            <DateRangeNavigator
              expanded={false}
              defaultValue={{
                tipoIntervalo: dadosColaboradorIntervalo,
                dataInicial: dadosColaboradorDataInicial,
                dataFinal: dadosColaboradorDataFinal
              }}
              onChange={(data) => {
                this.colaboradorLocalPreferences.setPreference('RelatorioDetalhesColaboradoresDialogPageFiltro_DadosFinanceirosTipoIntervalo', data.tipoIntervalo.nome)
                this.colaboradorLocalPreferences.setMomentPreference('RelatorioDetalhesColaboradoresDialogPageFiltro_dadosColaboradorDataInicial', data.dataInicial)
                this.colaboradorLocalPreferences.setMomentPreference('RelatorioDetalhesColaboradoresDialogPageFiltro_dadosColaboradorDataFinal', data.dataFinal)
                this.setState({
                  dadosColaboradorDataInicial: data.dataInicial,
                  dadosColaboradorDataFinal: data.dataFinal
                })
              }}
            />
          </div>
        </div>

        <ContentWithPreload functionsMap={this.dadosFinanceirosLoaderFunctionsMap} loadFunction={this.carregarDadosFinanceiros} loadKey={dadosColaboradorLoadKey}>
          {() => (
            <div className={classes.containerInformacoes}>
              <Grid container spacing={isWidthDown('xs', this.props.width) ? 1 : 3}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Atendimento
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesAtendimentos()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ marginTop: 20 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Quantidade</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {dadosColaborador.quantidadeAtendimentos ? dadosColaborador.quantidadeAtendimentos : 0}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Quantidade em pacotes</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {dadosColaborador.quantidadeAtendimentosPacote ? dadosColaborador.quantidadeAtendimentosPacote : 0}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Valor Total</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosColaborador.valorAtendimentos ? dadosColaborador.valorAtendimentos : 0)}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Vendas Produtos
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesVendasProdutos()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ marginTop: 20 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Quantidade</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {dadosColaborador.quantidadeProdutosVendidos ? dadosColaborador.quantidadeProdutosVendidos : 0}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Valor Total</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosColaborador.valorProdutosVendidos ? dadosColaborador.valorProdutosVendidos : 0)}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Vendas Pacotes
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesVendasPacotes()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ marginTop: 20 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Quantidade</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {dadosColaborador.quantidadePacotesVendidos ? dadosColaborador.quantidadePacotesVendidos : 0}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Valor Total</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosColaborador.valorPacotesVendidos ? dadosColaborador.valorPacotesVendidos : 0)}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Créditos Vendidos
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesVendasCredito()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ marginTop: 20 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Quantidade</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {dadosColaborador.quantidadeCreditosVendidos ? dadosColaborador.quantidadeCreditosVendidos : 0}
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid container alignItems="center" style={{ marginTop: 0 }}>
                      <Grid item xs style={{ fontSize: 14, textTransform: 'uppercase' }}>
                        <b>Valor Total</b>
                      </Grid>
                      <Grid item style={{ fontSize: 15, textTransform: 'uppercase' }}>
                        {formatarValorMonetario(dadosColaborador.valorCreditosVendidos ? dadosColaborador.valorCreditosVendidos : 0)}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Valor Comissões Totais
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesComissoesTotais()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosColaborador.valorComissoesTotais ? dadosColaborador.valorComissoesTotais : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Valor Comissões Recebidas
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesComissoesRecebidas()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosColaborador.valorComissoesRecebidas ? dadosColaborador.valorComissoesRecebidas : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Valor Total Gorjetas
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesGorjetasColaborador()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosColaborador.valorTotalGorjetas ? dadosColaborador.valorTotalGorjetas : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Valor Gorjetas Recebidas
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesGorjetasRecebidas()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosColaborador.valorGorjetasRecebidas ? dadosColaborador.valorGorjetasRecebidas : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Valor Total Vales
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesValesColaborador()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosColaborador.valorTotalVales ? dadosColaborador.valorTotalVales : 0)}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Typography variant="h5" color="secondary" className={classes.tituloCard} style={{ marginTop: -10 }}>
                      Dias com Atendimento
                    </Typography>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Quantidade de dias que o colaborador realizou atendimentos.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {dadosColaborador.quantidadeDiasComAtendimento ? dadosColaborador.quantidadeDiasComAtendimento : 0}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.paperInformacoes} elevation={1}>
                    <Grid container alignItems="center" style={{ marginTop: -10 }}>
                      <Grid item xs>
                        <Typography variant="h5" color="secondary" className={classes.tituloCard}>
                          Gasto Total Produtos
                        </Typography>
                      </Grid>
                      <Grid item>
                        {
                          <Tooltip title="Abrir Detalhes">
                            <IconButton
                              style={{ marginRight: -8 }}
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirDetalhesGastosProdutos()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ color: '#444' }} gutterBottom>
                      Valor total gasto com produtos durante os atendimentos.
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: -6 }}>
                      {formatarValorMonetario(dadosColaborador.valorGastoTotalProdutos ? dadosColaborador.valorGastoTotalProdutos : 0)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

RelatorioDetalhesColaboradoresDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(RelatorioDetalhesColaboradoresDialogPage))
