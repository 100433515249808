import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import moment from 'moment'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import CaixaEventoTipoEnum from 'support/domain/caixa/CaixaEventoTipoEnum'
import CaixaService from 'support/domain/caixa/CaixaService'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerDescricao: {
    ...theme.screen.horizontalMarginStyles()
  },
  colunaData: {
    flexGrow: 0.6,
    maxWidth: 108,
    minWidth: 108
  },
  colunaColaborador: {
    flexGrow: 0.6,
    maxWidth: 150,
    minWidth: 150
  },
  colunaValor: {
    flexGrow: 0.6,
    maxWidth: 108,
    minWidth: 108
  },
  textoTabela: {
    fontSize: 13
  },
  colaboradorContainer: {
    ...theme.text.limitedLines({ maxLines: 3, withEllipsis: false })
  },
  descricaoContainer: {
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

class TabMovimentacoes extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      caixa: this.props.caixa,
      ajaxing: false
    }

    this.containerFunctionMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['FechamentoNota', 'ManipulacaoNotaVendaPagamento', 'CancelamentoNota', 'EstornoNotaVendaPagamento'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  getRequestParameters = () => {
    return {
      idCaixa: this.state.caixa.id
    }
  }

  getRenderContent = (size, item, index) => {
    const { classes } = this.props

    const content = {}

    const dataCriacaoAsMoment = moment(item.dataCriacao)

    if (size === 'small') {
      content.data = dataCriacaoAsMoment.format('DD/MM/YY HH:mm')
    } else {
      content.data = (
        <span>
          <span style={{ display: 'block', marginBottom: 1 }}>{dataCriacaoAsMoment.format('DD/MM/YY')}</span>
          <AccessTimeIcon style={{ fontSize: 16, verticalAlign: 'top', marginRight: 3 }} />
          <span style={{ fontSize: 13 }}>{dataCriacaoAsMoment.format('HH:mm')}</span>
        </span>
      )
    }

    const valor = formatarValorMonetario(CaixaService.getValorEvento(item))
    content.valor = <span>{valor ? valor : ''}</span>
    content.colaborador = <span>{item.colaborador.apelido}</span>

    let linkAbrirNota = null
    if (CaixaEventoTipoEnum.PAGAMENTO.id === item.tipo.id) {
      linkAbrirNota = (
        <Link
          component="div"
          style={{ padding: 8, margin: '-6px -8px -8px -8px', fontSize: 13, cursor: 'pointer' }}
          onClick={() => {
            openBackableDialog(NotaDialogPage, {
              idNota: item.pagamento.notaVenda.id
            })
          }}
        >
          <b>Visualizar Fatura</b>
        </Link>
      )
    }

    content.descricaoTipo = <span className={classes.textoTabela}>{CaixaService.getDescricao(item)}</span>
    content.opcao = linkAbrirNota
    return content
  }

  render() {
    const { classes } = this.props

    return (
      <TabContainer>
        <VirtualizedResponsiveTable
          minTableWidth={650}
          windowScroller={true}
          showHeaderColumns={true}
          scrollElement={this.props.scrollContainerRef}
          showBackgroundDividers={true}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/caixas.buscarCaixaEventos"
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({
                lista: data.items.filter((item) => {
                  return CaixaService.getValorEvento(item) ||
                    CaixaService.isEventoAberturaCaixa(item) ||
                    CaixaService.isEventoFechamentoCaixa(item) ||
                    CaixaService.isEventoReaberturaCaixa(item)
                    ? true
                    : false
                })
              })
            }
          }}
          maxMediumContentBreakpoint="sm"
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Colaborador', className: classes.colunaColaborador, horizontalPadding: 'small' },
              { label: 'Descrição', props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Valor', className: classes.colunaValor, align: 'right', horizontsalPadding: 'none' }
            ],
            itemRenderer: (detalhes, index) => {
              const content = this.getRenderContent('large', detalhes, index)

              return {
                itemData: [
                  content.data,
                  <Typography variant="body2" className={classes.colaboradorContainer}>
                    {content.colaborador}
                  </Typography>,
                  <React.Fragment>
                    <Typography variant="body2" className={classes.descricaoContainer}>
                      {content.descricaoTipo}
                    </Typography>
                    {content.opcao}
                  </React.Fragment>,
                  content.valor
                ]
              }
            }
          }}
          mediumRenderProps={{
            headerColumnHeight: 68,
            rowHeight: 90,
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Descrição', props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Valor', className: classes.colunaValor, align: 'right' }
            ],
            itemRenderer: (detalhes, index) => {
              const content = this.getRenderContent('large', detalhes, index)

              return {
                itemData: [
                  content.data,
                  <React.Fragment>
                    <Typography variant="body2" noWrap={true}>
                      {content.colaborador}
                    </Typography>
                    <Typography variant="body2" className={classes.descricaoContainer}>
                      {content.descricaoTipo}
                    </Typography>
                    {content.opcao}
                  </React.Fragment>,
                  content.valor
                ]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 108,
            showFirstItemBorderTop: false,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs>
                        <Typography variant="body2">
                          <b>{content.data}</b>
                        </Typography>
                      </Grid>
                      <Grid item style={{ paddingLeft: 8, textAlign: 'right' }}>
                        <Typography variant="body2">{content.valor}</Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" noWrap={true}>
                      <b>{content.colaborador}</b>
                    </Typography>
                    <Typography variant="body2" className={classes.descricaoContainer}>
                      {content.descricaoTipo}
                    </Typography>
                    {content.opcao}
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum detalhe foi encontrado'
          }}
        />
      </TabContainer>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabMovimentacoes.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabMovimentacoes)
