import React, { Component } from 'react'

import ReceiptIcon from '@material-ui/icons/Receipt'

class NotaAberta extends Component {
  render() {
    return <ReceiptIcon {...this.props} />
  }
}

export default NotaAberta
