import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputText from 'support/components/input/InputText'

import styles from './DiscountCoupon.styles'
import { DiscountCouponResponse } from './types'
import useDiscountCoupon from './useDiscountCoupon'

type DiscountCouponProps = {
  negocio: {
    id: number
  }
  selectedType: string
  setValidDiscountCoupon: ({ coupon, validCoupon }: { coupon?: string; validCoupon?: DiscountCouponResponse }) => void
}

function DiscountCoupon({ negocio, selectedType, setValidDiscountCoupon }: DiscountCouponProps) {
  const inputCouponRef = useRef<HTMLInputElement>(null)
  const { isLoading, validDiscountCoupon, validateCoupon, validationError } = useDiscountCoupon()

  const buttonClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const couponValue = inputCouponRef.current?.value

    if (!couponValue || couponValue.trim() === '') {
      return
    } else {
      validateCoupon({ coupon: couponValue, callback: setValidDiscountCoupon, selectedType, negocio })
    }
  }

  return (
    <div data-testid="discount-coupon">
      <Typography variant="h5" style={styles.title} data-testid="title">
        Tem cupom de desconto?
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs>
          <InputText
            data-testid="input-coupon"
            idname="coupon"
            customVariant="outlined-small"
            disabled={false}
            errorMessage={validationError}
            label="Código de cupom"
            inputRef={inputCouponRef}
            staticHelpMessage={validDiscountCoupon ? 'Cupom aplicado' : undefined}
          />
        </Grid>
        <Grid item>
          <Button data-testid="submit-button" disabled={isLoading} onClick={buttonClickHandler} color="secondary" variant={isMobile ? 'contained' : 'text'} size="large">
            Aplicar
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default DiscountCoupon
