import React from 'react'

import { grupoBoticarioTheme } from '@grupoboticario/flora'
import { Button, Heading, Modal, ModalBody, ModalContent, ModalHeader } from '@grupoboticario/flora-react'
import { CrossIcon } from '@grupoboticario/flora-react-icons'
import { Box, IconButton } from '@material-ui/core'
import InputText from 'support/components/input/InputText'
import { Device } from 'support/domain/mercadopago/MercadoPago'

import { modalStyles, useStyles } from './ModalMaquininha.styles'

type ModalMaquininhaProps = {
  isOpen: boolean
  loading: boolean
  inputValue: string
  selectedDevice: Device
  onOpen: (val: boolean) => void
  onEditDevice: (device: Device) => void
  onInputValue: (val: string) => void
}

export const ModalMaquininha = ({ isOpen, inputValue, loading, selectedDevice, onOpen, onEditDevice, onInputValue }: ModalMaquininhaProps) => {
  const classes = useStyles()

  const handleCloseModal = () => {
    onOpen(false)
  }

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputValue(event.currentTarget.value)
  }

  const handleEdit = (device: Device) => {
    onEditDevice(device)
  }

  return (
    <Modal open={isOpen}>
      <ModalContent size="small" css={modalStyles.modalContent} className={grupoBoticarioTheme}>
        <ModalHeader className={classes.header}>
          <Heading level="6">Editar apelido</Heading>
          <IconButton aria-label="Fechar Modal" onClick={handleCloseModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <InputText idname="apelido" marginTop={true} customVariant="outlined-small" shrink={false} onChange={handleInputValue} defaultValue={inputValue} label="Apelido" />
          <Box className={classes.wrapperButtonsModal}>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button isLoading={loading} disabled={loading || !inputValue} onClick={() => handleEdit(selectedDevice)}>
              Salvar
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
