import { TOOLBAR_EXPANDED_CLASS_NAME } from 'support/components/panel/Panel'

function getStyles({ defaultTheme, tema, constant }) {
  return {
    panelPage: {
      containerHeaderFullScreen: function (props) {
        return {
          height: props.headerHeight,
          backgroundColor: '#fff',
          padding: '0px ' + constant.SCREEN_MARGIN + 'px',
          ['*.' + TOOLBAR_EXPANDED_CLASS_NAME + ' & > *']: {
            marginTop: -20
          },
          [defaultTheme.breakpoints.down('xs')]: {
            padding: '0px ' + constant.SCREEN_MARGIN_XS + 'px',
            ['*.' + TOOLBAR_EXPANDED_CLASS_NAME + ' & > *']: {
              marginTop: -10
            }
          },
          [defaultTheme.breakpoints.down(380)]: {
            padding: '0px ' + constant.SCREEN_MARGIN_380 + 'px'
          }
        }
      },
      containerBodyFullScreen: function (props) {
        return {
          height: 'calc(100% - ' + (props.headerHeight + 20) + 'px)',
          ['*.' + TOOLBAR_EXPANDED_CLASS_NAME + ' &']: {
            height: 'calc(100% - ' + props.headerHeight + 'px)'
          },
          [defaultTheme.breakpoints.down('xs')]: {
            ['*.' + TOOLBAR_EXPANDED_CLASS_NAME + ' &']: {
              height: 'calc(100% - ' + (props.headerHeight + 10) + 'px)'
            }
          }
        }
      },
      container: function (props) {
        return {
          padding: '0px ' + constant.SCREEN_MARGIN + 'px',
          paddingBottom: defaultTheme.spacing(12),
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '0px ' + constant.SCREEN_MARGIN + 'px',
            paddingBottom: defaultTheme.spacing(12)
          },
          [defaultTheme.breakpoints.down('xs')]: {
            padding: '0px ' + constant.SCREEN_MARGIN_XS + 'px',
            paddingBottom: defaultTheme.spacing(12)
          },
          [defaultTheme.breakpoints.down(380)]: {
            padding: '0px ' + constant.SCREEN_MARGIN_380 + 'px',
            paddingBottom: defaultTheme.spacing(12)
          }
        }
      }
    }
  }
}

const objectFunctions = {
  getStyles: getStyles
}

export default objectFunctions
