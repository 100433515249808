import React, { Component } from 'react'
import Helmet from 'react-helmet'

import { FILE_MANIFEST_EMPTY } from 'pages/PublicFileMap'

class EnterpriseHelmet extends Component {
  render() {
    return (
      <Helmet>
        <title>Salão99 Enterprise</title>
        <link rel="manifest" href={process.env.PUBLIC_URL + '/' + FILE_MANIFEST_EMPTY} />

        <meta property="og:title" content="Conta Salão99" />
        <meta property="og:site_name" content="Conta Salão99" />
        <meta property="og:description" content="Conta do Salão99" />
        <meta property="og:image" content={process.env.PUBLIC_URL + '/metacontent/enterprise/android-icon-512x512.png'} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />

        <link rel="apple-touch-icon" sizes="57x57" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-57x57.png'} />
        <link rel="apple-touch-icon" sizes="60x60" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-60x60.png'} />
        <link rel="apple-touch-icon" sizes="72x72" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-72x72.png'} />
        <link rel="apple-touch-icon" sizes="76x76" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-76x76.png'} />
        <link rel="apple-touch-icon" sizes="114x114" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-114x114.png'} />
        <link rel="apple-touch-icon" sizes="120x120" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-120x120.png'} />
        <link rel="apple-touch-icon" sizes="144x144" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-144x144.png'} />
        <link rel="apple-touch-icon" sizes="152x152" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-152x152.pngg'} />
        <link rel="apple-touch-icon" sizes="167x167" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-167x167.png'} />
        <link rel="apple-touch-icon" sizes="180x180" href={process.env.PUBLIC_URL + '/metacontent/enterprise/apple-icon-180x180.png'} />
        <link rel="icon" type="image/png" sizes="512x512" href={process.env.PUBLIC_URL + '/metacontent/enterprise/favicon-512x512.png'} />
        <link rel="icon" type="image/png" sizes="192x192" href={process.env.PUBLIC_URL + '/metacontent/enterprise/favicon-192x192.png'} />
        <link rel="icon" type="image/png" sizes="32x32" href={process.env.PUBLIC_URL + '/metacontent/enterprise/favicon-32x32.png'} />
        <link rel="icon" type="image/png" sizes="96x96" href={process.env.PUBLIC_URL + '/metacontent/enterprise/favicon-96x96.png'} />
        <link rel="icon" type="image/png" sizes="16x16" href={process.env.PUBLIC_URL + '/metacontent/enterprise/favicon-16x16.png'} />
        <link rel="shortcut icon" href={process.env.PUBLIC_URL + '/metacontent/enterprise/favicon-v4.ico'} type="image/x-icon" sizes="16x16 32x32 48x48 64x64 128x128" />

        <meta name="application-name" content="Conta Salão99" />
        <meta name="apple-mobile-web-app-title" content="Conta Salão99" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="white" />

        <meta name="msapplication-TileImage" content={process.env.PUBLIC_URL + '/metacontent/enterprise/android-icon-192x192.png'} />
        <meta name="msapplication-TileColor" content="#ffffff" />
      </Helmet>
    )
  }
}

export default EnterpriseHelmet
