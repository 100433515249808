import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'

const styles = (theme) => ({})

class UltimasAtualizacoesContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      releaseNoteItems: []
    }
  }

  carregarDados = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'infrastructure/releasenotes.getAll',
      onSuccess: (response) => {
        this.setState({
          releaseNoteItems: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    return (
      <ContentWithPreload loadFunction={this.carregarDados}>
        {() =>
          this.state.releaseNoteItems.map((releaseNoteItem, index) => (
            <React.Fragment key={index}>
              <Typography variant="h6" style={{ fontSize: 18 }}>
                {releaseNoteItem.version}
              </Typography>
              <Typography variant="body2" color="secondary" style={{ opacity: 1, marginBottom: 16 }}>
                {releaseNoteItem.date}
              </Typography>
              <ul style={{ marginBlockStart: 0, paddingInlineStart: 24 }}>
                {releaseNoteItem.paragraphs.map((paragraph, index) => (
                  <li key={index}>
                    <Typography variant="body2" style={{ marginBottom: 8 }} dangerouslySetInnerHTML={{ __html: paragraph }}></Typography>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))
        }
      </ContentWithPreload>
    )
  }
}

UltimasAtualizacoesContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UltimasAtualizacoesContent)
