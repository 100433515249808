import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import DetalhesRemuneracaoDialog from 'pages/erp/painel/remuneracao/DetalhesRemuneracaoDialog'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import TipoCalculoEnum from 'support/domain/calculo/TipoCalculoEnum'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import TipoVisualizacaoRemuneracaoEnum from 'support/domain/comissao/TipoVisualizacaoRemuneracaoEnum'
import RemuneracaoService from 'support/domain/remuneracao/RemuneracaoService'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario, formatarValorPercentual } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaCheckbox: {
    maxWidth: 50,
    minWidth: 50
  },
  containerCheckboxSmall: {
    margin: '-4px -2px -4px 4px'
  },
  colunaData: {
    flexGrow: 0.6,
    maxWidth: 90,
    minWidth: 90
  },
  colunaValor: {
    flexGrow: 0.6,
    maxWidth: 122,
    minWidth: 122
  },
  colunaValorComMargemDireita: {
    flexGrow: 0.6,
    maxWidth: 122,
    minWidth: 122,
    paddingRight: 8
  },
  esconderColuna: {
    display: 'none'
  },
  colunaDescricao: {
    flexGrow: 1.3
  },
  valorVendaSemDescontoMediumESmall: {
    marginLeft: 2,
    color: '#aaa'
  },
  valorVendaSemDescontoLarge: {
    color: '#aaa'
  },
  colunaInvisivel: {
    display: 'none'
  },
  cancelado: {
    textDecoration: 'line-through'
  }
})

class RemuneracoesDetalhadasResponsiveTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tipoVisualizacao: !this.props.tipoVisualizacao ? TipoVisualizacaoRemuneracaoEnum.POR_COLABORADOR : this.props.tipoVisualizacao
    }
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const { tipoVisualizacao } = this.state
    const content = {}

    content._checked = item._checked
    content.dataGeracao = converterDataIntParaMoment(item.dataGeracao).format('DD/MMM/YY')

    const tipo = TipoRemuneracaoEnum.getById(item.tipo.id).descricao
    content.tipo = tipo
    let complementoComissao = ''

    if (item.tipo.id === TipoRemuneracaoEnum.COMISSAO.id) {
      const comissaoVenda = item.comissaoVenda
      let nome

      if (tipoVisualizacao.id === TipoVisualizacaoRemuneracaoEnum.POR_COLABORADOR.id) {
        nome = comissaoVenda.notaVenda.cliente.apelido
      } else if (tipoVisualizacao.id === TipoVisualizacaoRemuneracaoEnum.POR_VENDAS.id) {
        nome = item.colaborador.apelido
      }

      content.descricao = (
        <React.Fragment>
          <Typography variant="body2" noWrap={true}>
            {nome}
          </Typography>
          <Typography variant="body2" noWrap={true}>
            <b>
              {tipo}: {VendaService.getDescricao(comissaoVenda.venda)}
            </b>
          </Typography>
        </React.Fragment>
      )

      if (size === 'small') {
        content.valorBase = (
          <span>
            Valor Base: {formatarValorMonetario(comissaoVenda.venda.valorTotal)}
            {comissaoVenda.venda.valorDescontoCalculado > 0 && (
              <strike className={classes.valorVendaSemDescontoMediumESmall}>({formatarValorMonetario(comissaoVenda.valorVenda)})</strike>
            )}
            <br />
          </span>
        )
      } else {
        if (size === 'medium') {
          content.valorBase = (
            <span>
              {formatarValorMonetario(comissaoVenda.venda.valorTotal)}
              {comissaoVenda.venda.valorDescontoCalculado > 0 && (
                <strike className={classes.valorVendaSemDescontoMediumESmall}>({formatarValorMonetario(comissaoVenda.valorVenda)})</strike>
              )}
            </span>
          )
        } else if (size === 'large') {
          content.valorBase = (
            <span>
              {comissaoVenda.venda.valorDescontoCalculado > 0 && (
                <React.Fragment>
                  <strike className={classes.valorVendaSemDescontoLarge}>{formatarValorMonetario(comissaoVenda.valorVenda)}</strike>
                  <br />
                </React.Fragment>
              )}
              {formatarValorMonetario(comissaoVenda.venda.valorTotal)}
            </span>
          )
        }

        if (comissaoVenda.tipoCalculoComissao.id === TipoCalculoEnum.PORCENTAGEM.id) {
          complementoComissao = ' Cálculo: ' + formatarValorPercentual(comissaoVenda.valorAplicacaoComissao)
        }
      }
    } else if (item.tipo.id === TipoRemuneracaoEnum.GORJETA.id) {
      const gorjeta = item.gorjeta
      let nome
      if (tipoVisualizacao.id === TipoVisualizacaoRemuneracaoEnum.POR_COLABORADOR.id) {
        nome = gorjeta.notaVenda.cliente.apelido
      } else if (tipoVisualizacao.id === TipoVisualizacaoRemuneracaoEnum.POR_VENDAS.id) {
        nome = gorjeta.notaVenda.cliente.apelido
      }

      content.descricao = (
        <React.Fragment>
          <Typography variant="body2" noWrap={true}>
            {nome}
          </Typography>
          <Typography variant="body2" noWrap={true}>
            <b>
              {tipo}: Troco fatura #{gorjeta.notaVenda.numero}
            </b>
          </Typography>
        </React.Fragment>
      )

      if (size === 'small') {
        content.valorBase = <React.Fragment>Valor Base: {formatarValorMonetario(gorjeta.valorTotal)}</React.Fragment>
      } else {
        content.valorBase = formatarValorMonetario(gorjeta.valorTotal)
      }
    } else if (item.tipo.id === TipoRemuneracaoEnum.SALARIO.id) {
      const salario = item.salario

      content.descricao = (
        <React.Fragment>
          <Typography variant="body2" noWrap={true}>
            {tipo}
          </Typography>
          <Typography variant="body2" noWrap={true}>
            {RemuneracaoService.getObservacao(item)}
          </Typography>
        </React.Fragment>
      )

      if (size === 'small') {
        content.valorBase = <React.Fragment>Valor Base: {formatarValorMonetario(salario.valor)}</React.Fragment>
      } else {
        content.valorBase = formatarValorMonetario(salario.valor)
      }
    } else if (item.tipo.id === TipoRemuneracaoEnum.BONIFICACAO.id) {
      const bonificacao = item.bonificacao

      content.descricao = (
        <React.Fragment>
          <Typography variant="body2" noWrap={true}>
            {tipo}
          </Typography>
          <Typography variant="body2" noWrap={true}>
            {RemuneracaoService.getObservacao(item)}
          </Typography>
        </React.Fragment>
      )

      if (size === 'small') {
        content.valorBase = <React.Fragment>Valor Base: {formatarValorMonetario(bonificacao.valor)}</React.Fragment>
      } else {
        content.valorBase = formatarValorMonetario(bonificacao.valor)
      }
    }

    if (size === 'small') {
      content.dataRemuneracao = (
        <span>
          Data: <b>{converterDataIntParaMoment(item.dataRemuneracao).format('DD/MMM/YY')}</b>
        </span>
      )
      content.valorRemuneracao = (
        <span className={item.cancelada || item.excluido ? classes.cancelado : undefined}>Valor Remuneração: {formatarValorMonetario(item.valorRemuneracaoCalculadoEfetivo)}</span>
      )
      if (item.valorPago) {
        content.valorPago = (
          <React.Fragment>
            <br />
            Valor pago: {formatarValorMonetario(item.valorPago)}
          </React.Fragment>
        )
      }
      if (item.valorPendentePagamento) {
        content.valorPendentePagamento = (
          <React.Fragment>
            <br />
            Pendente pagamento: {formatarValorMonetario(item.valorPendentePagamento)}
          </React.Fragment>
        )
      }
    } else {
      content.dataRemuneracao = converterDataIntParaMoment(item.dataRemuneracao).format('DD/MMM/YY')
      content.valorPago = formatarValorMonetario(item.valorPago)
      content.valorPendentePagamento = formatarValorMonetario(item.valorPendentePagamento)
      content.valorRemuneracao = (
        <span>
          <span className={item.cancelada || item.excluido ? classes.cancelado : undefined}>{formatarValorMonetario(item.valorRemuneracaoCalculadoEfetivo)}</span>
          {verificarAutorizacao([PA.PODE_ACESSAR_PRECO_VENDA_REMUNERACAO]) && (
            <React.Fragment>
              <br />
              <small>
                {complementoComissao && (
                  <React.Fragment>
                    <span style={{ opacity: 0.6 }}>{complementoComissao}</span>
                    <br />
                  </React.Fragment>
                )}

                <Link
                  color="secondary"
                  style={{ fontSize: 13 }}
                  onClick={(event) => {
                    this.onClickVisualizarDetalhes(item, event)
                  }}
                >
                  Detalhes
                </Link>
              </small>
            </React.Fragment>
          )}
        </span>
      )
    }

    if (item.cancelada || item.excluido) {
      content.dataGeracao = (
        <span>
          <strike>{content.dataGeracao}</strike>
        </span>
      )
      content.dataRemuneracao = (
        <span>
          <strike>{content.dataRemuneracao}</strike>
        </span>
      )
      content.descricao = (
        <span>
          <strike>{content.descricao}</strike>
        </span>
      )
      content.valorBase = (
        <span>
          <strike>{content.valorBase}</strike>
        </span>
      )
      content.valorPendentePagamento = (
        <span>
          <strike>{content.valorPendentePagamento}</strike>
        </span>
      )
    }

    return content
  }

  onClickVisualizarDetalhes = (item, event) => {
    event.stopPropagation()
    openBackableDialog(DetalhesRemuneracaoDialog, {
      title: 'Detalhes do Cálculo',
      maxWidth: 350,
      remuneracao: item
    })
  }

  render() {
    const { classes, selecaoProps, mostrarValorPendente, ...others } = this.props

    const exibirValorBase = verificarAutorizacao([PA.PODE_ACESSAR_PRECO_VENDA_REMUNERACAO])
    const classeColunaValorBase = exibirValorBase ? classes.colunaValor : classes.esconderColuna

    const largeRendeColumns = []
    const mediumRenderProps = []

    let newColumn = null
    let primeiraColuna = true

    if (selecaoProps) {
      newColumn = {
        className: classes.colunaCheckbox,
        label: <Checkbox onClick={selecaoProps.selecionarTodosFunction} checked={selecaoProps.selecionarTodosValue} />,
        horizontalPadding: 'none'
      }
      largeRendeColumns.push(newColumn)
      mediumRenderProps.push(newColumn)
      primeiraColuna = false
    }

    newColumn = { label: 'Data Venda', className: classes.colunaData, horizontalPadding: primeiraColuna ? null : 'small' }
    largeRendeColumns.push(newColumn)
    mediumRenderProps.push(newColumn)

    largeRendeColumns.push({ label: 'Descrição', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } })
    largeRendeColumns.push({ label: 'Valor Base', className: classeColunaValorBase, align: 'right', horizontalPadding: 'small' })
    largeRendeColumns.push({
      label: (
        <span>
          Valor
          <br />
          Remuneração
        </span>
      ),
      className: classes.colunaValor,
      align: 'right',
      horizontalPadding: 'small'
    })
    largeRendeColumns.push({ label: 'Valor Pago', className: selecaoProps ? classes.colunaValorComMargemDireita : classes.colunaValor, align: 'right', horizontalPadding: 'small' })
    largeRendeColumns.push({
      label: 'Pagamento Pendente',
      className: mostrarValorPendente === undefined || mostrarValorPendente === true ? classes.colunaValor : classes.colunaInvisivel,
      align: 'right',
      horizontalPadding: 'small'
    })

    mediumRenderProps.push({ label: '', className: classes.colunaIconeTipoVendaMedium, isVisible: false, horizontalPadding: 'small' })
    mediumRenderProps.push({ label: 'Descrição', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } })
    mediumRenderProps.push({
      label: 'Valor Remuneração',
      className: selecaoProps ? classes.colunaValorComMargemDireita : classes.colunaValor,
      align: 'right',
      horizontalPadding: 'small'
    })

    let onClickRow = null
    if (!selecaoProps && exibirValorBase) {
      onClickRow = this.onClickVisualizarDetalhes
    }

    return (
      <VirtualizedResponsiveTable
        showBackgroundDividers={true}
        onClickRow={onClickRow}
        largeRenderProps={{
          rowHeight: 76,
          columns: largeRendeColumns,
          itemRenderer: (comissao, index) => {
            const content = this.getRenderContent('large', comissao)
            const itemData = []

            if (selecaoProps) {
              itemData.push(<Checkbox checked={content._checked === undefined ? false : content._checked} />)
            }

            itemData.push(content.dataRemuneracao)
            itemData.push(content.descricao)
            itemData.push(content.valorBase)
            itemData.push(content.valorRemuneracao)
            itemData.push(content.valorPago)
            itemData.push(content.valorPendentePagamento)

            return {
              selected: content._checked,
              itemData: itemData
            }
          }
        }}
        mediumRenderProps={{
          headerColumnHeight: 68,
          rowHeight: exibirValorBase ? 146 : 120,
          columns: mediumRenderProps,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('medium', item)
            const itemData = []

            if (selecaoProps) {
              itemData.push(<Checkbox checked={content._checked === undefined ? false : content._checked} />)
            }

            itemData.push(content.dataRemuneracao)
            itemData.push(content.iconeTipoVenda)
            itemData.push(
              <React.Fragment>
                <Typography variant="body2" noWrap={true}>
                  <b>{content.descricao}</b>
                </Typography>
                <Typography variant="body2">
                  {exibirValorBase && (
                    <small className={item.cancelada || item.excluido ? classes.cancelado : undefined}>
                      Valor Base: {content.valorBase}
                      <br />
                    </small>
                  )}
                  <small>
                    Valor pago: {content.valorPago}
                    {(mostrarValorPendente === undefined || mostrarValorPendente === true) && (
                      <React.Fragment>
                        <br />
                        <span className={item.cancelada || item.excluido ? classes.cancelado : undefined}>Pendente pagamento: {content.valorPendentePagamento}</span>
                      </React.Fragment>
                    )}
                  </small>
                </Typography>
              </React.Fragment>
            )
            itemData.push(content.valorRemuneracao)

            return {
              selected: content._checked,
              itemData: itemData
            }
          }
        }}
        smallRenderProps={{
          rowHeight: exibirValorBase ? 200 : 170,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('small', item)

            return {
              selected: content._checked,
              itemData: (
                <React.Fragment>
                  <Grid container alignItems="center" style={{ minWidth: 0, marginTop: -5, marginBottom: -1 }}>
                    {selecaoProps && (
                      <Grid item className={classes.containerCheckboxSmall}>
                        <Checkbox checked={content._checked === undefined ? false : content._checked} />
                      </Grid>
                    )}
                    <Grid item>
                      <Typography variant="body2">{content.dataRemuneracao}</Typography>
                    </Grid>
                  </Grid>
                  <div style={{ minWidth: 0, paddingLeft: selecaoProps ? 16 : 0 }}>
                    <Typography variant="body2" noWrap={true}>
                      <b>{content.descricao}</b>
                    </Typography>
                    {exibirValorBase && <Typography variant="body2">{content.valorBase}</Typography>}
                    <Typography variant="body2">
                      {content.valorRemuneracao}
                      {content.valorPago}
                      {(mostrarValorPendente === undefined || mostrarValorPendente === true) && content.valorPendentePagamento}
                    </Typography>
                    {exibirValorBase && (
                      <Typography variant="body2" style={{ marginBottom: -4 }}>
                        <Link
                          color="secondary"
                          onClick={(event) => {
                            this.onClickVisualizarDetalhes(item, event)
                          }}
                        >
                          Detalhes do Cálculo
                        </Link>
                      </Typography>
                    )}
                  </div>
                </React.Fragment>
              )
            }
          }
        }}
        {...others}
      />
    )
  }
}

RemuneracoesDetalhadasResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RemuneracoesDetalhadasResponsiveTable)
