import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import HistoricoPacoteDialogPage from 'pages/erp/painel/cliente/HistoricoPacoteDialogPage'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import CreditoService from 'support/domain/credito/CreditoService'
import TipoClienteTransacaoSaldoEnum from 'support/domain/credito/TipoClienteTransacaoSaldoEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaValor: {
    flexGrow: 0.6,
    maxWidth: 108,
    minWidth: 108
  },
  textoTabela: {
    fontSize: 13
  },
  descricaoContainer: {
    ...theme.text.limitedLines({ maxLines: 2 })
  },
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class TabCredito extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lista: [],
      cliente: this.props.cliente,
      ajaxing: false
    }

    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(
      ['ManipulacaoNotaVendaPagamento', 'AlteracaoSaldoCliente', 'ManipulacaoVenda', 'ManipulacaoVendas', 'EstornoNotaVendaPagamento', 'CancelamentoNota', 'FechamentoNota'],
      (props) => {
        this.loaderFunctionsMap.load()
      }
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  closeItemMenu() {
    this.setState((state) => ({
      creditoMenuAnchorEl: null
    }))
  }

  handlerRemoverVendaCredito = () => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover',
      text: (
        <span>
          Você tem certeza que deseja remover a venda de crédito no valor de <b>{formatarValorMonetario(this.state.itemMenu.valor)}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/vendas.removerVendaCredito',
        contextoUsuario: 'erp',
        data: {
          idVendaCredito: this.state.itemMenu.vendaCredito.id
        },
        onSuccess: () => {
          EventsManager.pub('AlteracaoSaldoCliente')
        },
        errorKey: 'caixa'
      }
    })
  }

  handlerRemoverManipulacaoDeliberadaSaldo = () => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover',
      text: (
        <span>
          Você tem certeza que deseja remover o crédito no valor de <b>{formatarValorMonetario(this.state.itemMenu.valor)}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/clientes.removerManipulacaoDeliberadaSaldo',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.manipulacaoDeliberadaSaldo.id
        },
        onSuccess: () => {
          EventsManager.pub('AlteracaoSaldoCliente')
        }
      }
    })
  }

  handlerAbrirNota = () => {
    let idNota

    if (this.state.itemMenu.tipo.id === TipoClienteTransacaoSaldoEnum.CREDITO_TROCO_PAGAMENTO.id) {
      idNota = this.state.itemMenu.creditoTrocoPagamento.notaVenda.id
    } else if (this.state.itemMenu.tipo.id === TipoClienteTransacaoSaldoEnum.VENDA_CREDITO.id) {
      idNota = this.state.itemMenu.vendaCredito.venda.notaVenda.id
    } else if (this.state.itemMenu.tipo.id === TipoClienteTransacaoSaldoEnum.PAGAMENTO.id) {
      idNota = this.state.itemMenu.pagamento.notaVenda.id
    } else if (this.state.itemMenu.tipo.id === TipoClienteTransacaoSaldoEnum.ESTORNO_PAGAMENTO.id) {
      idNota = this.state.itemMenu.pagamentoEstorno.notaVenda.id
    }

    openBackableDialog(NotaDialogPage, {
      idNota: idNota
    })
  }

  handlerAbrirHistoricoPacote = () => {
    openBackableDialog(HistoricoPacoteDialogPage, {
      idClientePacoteItem: this.state.itemMenu.manipulacaoCancelamento.clientePacoteItem.id
    })
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const content = {}

    content.dataTransacao = converterDataIntParaMoment(item.dataTransacao).format('DD/MMM/YY')

    content.descricao = CreditoService.getDescricao(item)
    if (item.manipulacaoDeliberadaSaldo && item.manipulacaoDeliberadaSaldo.observacoes) {
      content.descricao += ' - ' + item.manipulacaoDeliberadaSaldo.observacoes
    }

    content.descricao = <span className={classes.textoTabela}>{content.descricao}</span>
    content.valor = formatarValorMonetario(item.valor)

    content.botaoOpcoes = (
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ creditoMenuAnchorEl: event.currentTarget, itemMenu: item })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )

    return content
  }

  render() {
    const { classes } = this.props
    const { ajaxing, lista, cliente } = this.state

    return (
      <TabContainer>
        <VirtualizedResponsiveTable
          disabled={ajaxing}
          scrollElement={this.props.scrollElement}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/clientes.buscarClienteTransacoesSaldo"
          getRequestParametersFunction={() => ({
            idCliente: cliente.id
          })}
          items={lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Descrição', props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Valor', className: classes.colunaValor, align: 'right', horizontsalPadding: 'none' },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [
                  content.dataTransacao,
                  <React.Fragment>
                    <Typography variant="body2" className={classes.descricaoContainer}>
                      {content.descricao}
                    </Typography>
                  </React.Fragment>,
                  content.valor,
                  content.botaoOpcoes
                ]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 92,
            showFirstItemBorderTop: false,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: (
                  <React.Fragment>
                    <Grid container alignItems="center">
                      <Grid item xs style={{ minWidth: 0 }}>
                        <Typography variant="body2">{content.dataTransacao}</Typography>
                        <Typography variant="body2" noWrap={true}>
                          <b>{content.descricao}</b>
                        </Typography>
                        <Typography variant="body2">{content.valor}</Typography>
                      </Grid>
                      <Grid item style={{ minWidth: 0 }}>
                        {content.botaoOpcoes}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            padding: false,
            text: <span style={{ maxWidth: 470, display: 'inline-block' }}>Este cliente não possui nenhum crédito.</span>
          }}
        />

        <Menu anchorEl={this.state.creditoMenuAnchorEl} open={Boolean(this.state.creditoMenuAnchorEl)} onClose={() => this.closeItemMenu()}>
          {this.state.itemMenu && this.state.itemMenu.tipo && this.state.itemMenu.tipo.id === TipoClienteTransacaoSaldoEnum.MANIPULACAO_DELIBERADA_SALDO.id && (
            <MenuItem
              onClick={() => {
                this.handlerRemoverManipulacaoDeliberadaSaldo()
                this.closeItemMenu()
              }}
            >
              Remover
            </MenuItem>
          )}

          {this.state.itemMenu && this.state.itemMenu.tipo && this.state.itemMenu.tipo.id === TipoClienteTransacaoSaldoEnum.VENDA_CREDITO.id && (
            <MenuItem
              onClick={() => {
                this.handlerRemoverVendaCredito()
                this.closeItemMenu()
              }}
            >
              Remover
            </MenuItem>
          )}

          {this.state.itemMenu &&
            this.state.itemMenu.tipo &&
            this.state.itemMenu.tipo.id !== TipoClienteTransacaoSaldoEnum.MANIPULACAO_DELIBERADA_SALDO.id &&
            this.state.itemMenu.tipo.id !== TipoClienteTransacaoSaldoEnum.ESTORNO_PACOTE.id && (
              <MenuItem
                onClick={() => {
                  this.handlerAbrirNota()
                  this.closeItemMenu()
                }}
              >
                Ver Fatura
              </MenuItem>
            )}

          {this.state.itemMenu && this.state.itemMenu.tipo && this.state.itemMenu.tipo.id === TipoClienteTransacaoSaldoEnum.ESTORNO_PACOTE.id && (
            <MenuItem
              onClick={() => {
                this.handlerAbrirHistoricoPacote()
                this.closeItemMenu()
              }}
            >
              Histórico Pacote
            </MenuItem>
          )}
        </Menu>
      </TabContainer>
    )
  }
}

TabCredito.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabCredito)
