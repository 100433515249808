import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FileUploader from 'support/components/fileuploader/FileUploader'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ImportacaoClientesNegocioDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Importação de Clientes',
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      },
      errorsMap: createErrorsMap(),
      ajaxing: false,
      files: []
    }
  }

  salvar = () => {

    const { files, ajaxing } = this.state
    if (files && files.length > 0) {
      this.setState({ ajaxing: true })

      const formData = new FormData()
      formData.append('idNegocio', this.props.negocio.id)
      for (var i = 0; i < files.length; i++) {
        let file = files[i]
        formData.append('arquivos[' + i + ']', file)
      }

      postAPI({
        url: 'admin/negocios.importarClientesPorPlanilha',
        isMultiparFormData: true,
        data: formData,
        requerAutorizacao: true,
        timeout: 0,
        onSuccess: (response) => {
          this.props.handleCloseDialog()
        },
        onError: (response) => {
          if (response.code === 413) {
            this.setState({ ajaxing: false })
            EventsManager.pub('MensagemSnackBar', 'O tamanho do arquivo excedeu o limite permitido.')
          } else {
            this.setState({ ajaxing: false })
            EventsManager.pub('MensagemSnackBar', 'Erro inesperado.')
          }
        }
      })
    } else {
      openBackableDialog(MessageDialog, {
        title: 'Carregue uma planilha',
        text: 'É necessário carregar planilhas do seu computador/dispositivo para importar clientes.'
      })
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, negocio } = this.props
    const { ajaxing, files } = this.state

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <form id="formImportacaoClientes" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>

          <Typography variant="body1"><b>{negocio.nome}</b></Typography>
          <Typography variant="body2">ID: {negocio.id}</Typography>
          <br />

          <div style={{ opacity: ajaxing ? 0.5 : 1, pointerEvents: ajaxing ? 'none' : 'auto' }}>
            <FileUploader
              accept={"application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
              files={files}
              onDropChange={(files) => {
                this.setState({
                  files: files
                })
              }}
              onRemoveFile={(index) => {
                const files = this.state.files.slice(0)
                files.splice(index, 1)
                this.setState({
                  files: files
                })
              }}
            />
          </div>

          <HiddenSubmitButton />

          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
              Cancelar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
              {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
            </Button>
          </div>

        </form>

      </DialogPage>
    )
  }
}

ImportacaoClientesNegocioDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ImportacaoClientesNegocioDialogPage)
