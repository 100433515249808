import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import FornecedorDialogPage from 'pages/erp/painel/inventario/FornecedorDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class FornecedorInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    const creationFunction = (props) => {
      openBackableDialog(FornecedorDialogPage, {
        nome: props.text
      })
    }

    if (!props.label) props.label = 'Fornecedor'
    if (!props.id) props.id = 'xfornecedor'
    if (!props.name) props.name = 'xfornecedor'

    if (props.defaultValue !== undefined) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.nome
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.creationFunction = creationFunction
    props.DialogSelectProps.creationPermission = verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])
    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Fornecedor...'
    props.DialogSelectProps.loadAllAndCache = true
    props.DialogSelectProps.endpoint = 'erp/inventario.buscarFornecedoresPorNome'
    props.DialogSelectProps.searchParamName = 'nome'
    props.DialogSelectProps.generateItemData = (fornecedor) => {
      return {
        value: fornecedor.id,
        label: fornecedor.nome,
        primary: fornecedor.nome
      }
    }

    props.DialogSelectProps.autoSelectDefinitons = [
      {
        eventName: 'ManutencaoFornecedor',
        itemPropName: 'fornecedor'
      }
    ]

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum fornecedor foi encontrado',
        textButton: 'Cadastrar fornecedor',
        creationFunction: creationFunction
      }
    }

    return <InputDialogSelect {...props} />
  }
}

FornecedorInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FornecedorInputDialogSelect)
