import React, { Component } from 'react'

class BackableComponentNode extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const propsClone = Object.assign({}, this.props)
    const nextPropsClone = Object.assign({}, nextProps)
    delete propsClone.component
    delete nextPropsClone.component
    delete propsClone.parent
    delete nextPropsClone.parent

    const filter = function (key, value) {
      if (key.charAt(0) === '_' || key === 'current') {
        return
      }
      return value
    }

    const propsCloneString = JSON.stringify(propsClone, filter)
    const nextPropsCloneString = JSON.stringify(nextPropsClone, filter)

    if (propsCloneString !== nextPropsCloneString) {
      //console.log("SLOW","BackableComponentNode", this.url,propsClone);
      return true
    } else {
      //console.log("OTIMIZACAO,RENDER","BackableComponentNode");
      return false
    }
  }

  render() {
    const { component: ComponentToRender, ...others } = this.props
    return <ComponentToRender {...others} />
  }
}

export default BackableComponentNode
