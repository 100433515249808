import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BlockIcon from '@material-ui/icons/Block'
import EventIcon from '@material-ui/icons/Event'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

const MAX_WIDTH = 460

const styles = (theme) => ({
  labelRecursos: {
    color: theme.palette.text.negative
  }
})

class ExibicaoConflitoHorariosDialog extends Component {
  constructor(props) {
    super(props)
    this.paperRef = React.createRef()
    this.state = {
      content: null
    }
  }

  componentDidMount() {
    const { conflitosHorarios } = this.props
    this.setState({
      content: (
        <React.Fragment>
          {this.props.descricao}
          <br />
          <br />
          <VirtualizedResponsiveTable
            showHeaderColumns={false}
            showRowBorders={false}
            scrollElement={this.paperRef.current}
            showBackgroundDividers={false}
            dynamicHeight={true}
            loadingIndicatorRowHeight={200}
            items={conflitosHorarios}
            largeRenderProps={{
              columns: [{ props: { xs: true }, horizontalPadding: 'none' }],
              itemRenderer: (item, index) => {
                const content = this.getRenderContent('large', item, index)
                return {
                  itemData: [<div style={{ marginTop: index > 0 ? 24 : 6 }}>{content.conflito}</div>]
                }
              }
            }}
            smallRenderProps={{
              rowHeight: 80,
              horizontalPadding: 'none',
              itemRenderer: (item, index) => {
                const content = this.getRenderContent('small', item, index)
                return {
                  itemData: <div style={{ marginTop: index > 0 ? 24 : 0 }}>{content.conflito}</div>
                }
              }
            }}
          />
        </React.Fragment>
      )
    })
  }

  getRenderContent = (size, conflito, index) => {
    const { classes } = this.props
    const content = {}

    if (conflito.atendimento) {
      let nomesColaboradores = conflito.atendimento.venda.colaborador.apelido.split(' ')[0]
      if (conflito.atendimento.venda.assistentesAtendimento) {
        for (let assistente of conflito.atendimento.venda.assistentesAtendimento) {
          nomesColaboradores += ', ' + assistente.colaborador.apelido.split(' ')[0]
        }
      }

      let descricaoRecursos = ''
      if (conflito.recursos && conflito.recursos.length > 0) {
        for (let recurso of conflito.recursos) {
          if (descricaoRecursos) {
            descricaoRecursos += ', '
          }
          descricaoRecursos += recurso.nome
        }
        if (conflito.recursos.length === 1) {
          descricaoRecursos = 'Conflitos no Recurso: ' + descricaoRecursos
        } else {
          descricaoRecursos = 'Conflitos nos Recursos: ' + descricaoRecursos
        }
      }

      content.conflito = (
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item style={{ marginRight: 16 }}>
            <EventIcon />
          </Grid>
          <Grid>
            <Typography variant="body2">
              {converterDataIntParaMoment(conflito.atendimento.data).format('DD/MMM/YYYY')} - {formatarHorarioInt(conflito.atendimento.horarioInicio)} -{' '}
              {conflito.atendimento.venda.cliente.apelido.split(' ')[0]}
            </Typography>
            <Typography variant="body2">{conflito.atendimento.servico.nome}</Typography>
            <Typography variant="body2">Colaboradores: {nomesColaboradores}</Typography>
            {descricaoRecursos && (
              <Typography variant="body2" className={classes.labelRecursos}>
                {descricaoRecursos}
              </Typography>
            )}
          </Grid>
        </Grid>
      )
    } else if (conflito.bloqueioHorario) {
      let IconComponent = BlockIcon
      if (conflito.bloqueioHorario.grupo && conflito.bloqueioHorario.grupo.id) {
        IconComponent = EventBusyIcon
      }
      content.conflito = (
        <Grid container alignItems="center" wrap="noWrap">
          <Grid item style={{ marginRight: 16 }}>
            <IconComponent />
          </Grid>
          <Grid>
            <Typography variant="body2">
              {converterDataIntParaMoment(conflito.bloqueioHorario.data).format('DD/MMM/YYYY')} - {formatarHorarioInt(conflito.bloqueioHorario.horarioInicio)}
            </Typography>
            <Typography variant="body2">{conflito.bloqueioHorario.descricao}</Typography>
            <Typography variant="body2">{conflito.bloqueioHorario.colaborador.apelido}</Typography>
          </Grid>
        </Grid>
      )
    }

    return content
  }

  render() {
    return (
      <CustomDialog dialogProps={this.props} paperStyle={{ maxWidth: MAX_WIDTH }}>
        <DialogTitle>Conflitos de Horários</DialogTitle>
        <DialogContent ref={this.paperRef}>{this.state.content}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              this.props.handleCloseDialog({
                onClosedCallback: () => {
                  this.props.onConfirm()
                }
              })
            }}
          >
            SALVAR MESMO ASSIM
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

ExibicaoConflitoHorariosDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ExibicaoConflitoHorariosDialog)
