import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class AdquirenteDialogPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idAdquirente ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idAdquirente) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/formaspagamento.buscarAdquirentePorId',
        params: {
          id: this.props.idAdquirente
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState({ adquirente: response.data })

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirAdquirente()
  }

  persistirAdquirente = () => {
    postAPI({
      url: 'erp/formaspagamento.persistirAdquirente',
      data: this.getDadosAdquirente(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoAdquirente', {
          adquirente: response.data
        })
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formAdquirente')
      }
    })
  }

  getDadosAdquirente = () => {
    const dados = {}
    if (this.props.idAdquirente) {
      dados.id = this.props.idAdquirente
    }

    dados.nome = this.inputNome.current.value
    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state
    const { nome } = this.state.adquirente ? this.state.adquirente : {}

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formAdquirente" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={nomeDefaultValue}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome"
                helpMessage="Exemplos: Rede, Cielo, Pagseguro, Stone, Getnet e etc"
                inputProps={{
                  maxLength: 255
                }}
                inputRef={this.inputNome}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

AdquirenteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AdquirenteDialogPage)
