import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Link, Redirect } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinkMUI from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import logo from 'img/logo_salao99_painel.png'
import { ROUTE_CONTA_MAIN, ROUTE_ENTERPRISE_MAIN, ROUTE_PORTAL_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/ApiClient'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import GoogleAnalyticsSupport from 'support/components/googleanalytics/GoogleAnalyticsSupport'
import GoogleSSOButton from 'support/components/googleSSOButton'
import InputText from 'support/components/input/InputText'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'
import FormUtil from 'support/util/FormUtil'
import UrlUtil from 'support/util/UrlUtil'
import { InputTelefone } from 'support/components/input/InputTelefone'

const styles = (theme) => ({
  root: theme.page.parentCenteredPaper,
  paper: theme.page.paper({ maxWidth: 440 })
})

class CriarContaPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorsMap: FormUtil.createErrorsMap(),
      showPassword: false,
      ajaxing: false,
      contaCriada: false
    }
    this.inputNome = React.createRef()
    this.inputEmail = React.createRef()
    this.inputNumeroCelular = React.createRef()
    this.inputSenha = React.createRef()
    this.inputSenhaConfirmacao = React.createRef()

    const campaignValue = UrlUtil.getUrlQueryStringParsed()['campaign']
    if (campaignValue) {
      localStorage.setItem('S99_Campaign', campaignValue)
    }

    SessaoUsuario.registrarUrlContinuacaoAposLogin()
  }

  componentDidMount() {
    GoogleAnalyticsSupport.sendPlataform()
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState(() => ({ ajaxing: true }))

    let dataLayer = window.dataLayer || []
    dataLayer.push({
      event: 'event',
      eventCategory: 'salao99:account',
      eventAction: 'click:button',
      eventLabel: 'criar-conta'
    })

    let plataforma = null

    if (DeviceIdentifier.isNativeAndroid()) {
      plataforma = 'webview_android'
    } else if (DeviceIdentifier.isNativeIOS()) {
      plataforma = 'webview_ios'
    } else {
      plataforma = 'browser'
    }

    let campaignValue = localStorage.getItem('S99_Campaign')
    if (!campaignValue) {
      campaignValue = null
    }

    postAPI({
      url: 'cda/usuarios.criarConta',
      formId: 'formCriarConta',
      data: {
        nome: this.inputNome.current.value,
        email: this.inputEmail.current.value,
        numeroCelular: this.inputNumeroCelular.current.value,
        senha: this.inputSenha.current.value,
        senhaConfirmacao: this.inputSenhaConfirmacao.current.value,
        plataforma: plataforma,
        campanha: campaignValue
      },
      onSuccess: (response) => {
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:account',
          eventAction: 'callback',
          eventLabel: 'sucesso',
          userId: response.data.id || ''
        })

        SessaoUsuario.registrarUsuario(response.data)
        this.setState(() => ({ contaCriada: true }))
      },
      onPreFinal: () => {
        this.setState(() => ({ ajaxing: false }))
      },
      onClientError: (response) => {
        let listaErrosPorCampoParaDataLayer = ''
        if (response.data.errors) {
          for (let prop in response.data.errors) {
            listaErrosPorCampoParaDataLayer += '|' + prop + ':' + response.data.errors[prop].replace(/\s/g, '-')
          }
        }

        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:account',
          eventAction: 'callback',
          eventLabel: 'erro:dados-invalidos' + listaErrosPorCampoParaDataLayer
        })

        this.setState(() => ({ errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formCriarConta')
      },
      onServerError: () => {
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:account',
          eventAction: 'callback',
          eventLabel: 'erro:servidor'
        })
      },
      onClientConnectionError: () => {
        dataLayer.push({
          event: 'event',
          eventCategory: 'salao99:account',
          eventAction: 'callback',
          eventLabel: 'erro:conexao'
        })
      }
    })
  }

  render() {
    const { classes } = this.props
    const { errorsMap } = this.state

    const urlContinuacao = SessaoUsuario.getUrlContinuacaoAposLogin()

    if (SessaoUsuario.isUsuarioLogado()) {
      if (urlContinuacao) {
        if (this.state.contaCriada === true && urlContinuacao.indexOf(ROUTE_ENTERPRISE_MAIN) >= 0) {
          return <Redirect to={ROUTE_ENTERPRISE_MAIN} />
        } else {
          window.location.href = decodeURIComponent(urlContinuacao)
          return ''
        }
      } else {
        return <Redirect to={ROUTE_PORTAL_MAIN} />
      }
    }

    //let textoRodape = "";
    let helpMessageEmail = ''

    if (urlContinuacao && urlContinuacao.indexOf(ROUTE_ENTERPRISE_MAIN)) {
      //textoRodape = "Com esta conta do Salão99 Enterprise você poderá ter mais controle e eficiência na gestão do seu negócio.";
      helpMessageEmail = 'Iremos enviar um e-mail de confirmação para a ativação da conta'
    } else {
      //textoRodape = "Com esta conta do Salão99 você poderá agendar horários em seus estabelecimentos preferidos.";
    }

    let nomeAutoFocus = true
    if (DeviceIdentifier.isNativeApp()) {
      nomeAutoFocus = false
    }

    const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
    const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade
    const termosUsoLinkProps = TermosEPrivacidadeLinkProps.termosUso

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Crie sua conta do Salão99</title>
          <meta name="description" content="Crie sua Conta do Salão99." />
        </Helmet>

        <Paper className={classes.paper} elevation={1}>
          <div style={{ textAlign: 'center', marginTop: 4 }}>
            <img src={logo} width="100" alt="Salão99" />
            <Typography variant="h5" style={{ marginTop: 22 }}>
              Crie sua conta do Salão99
            </Typography>
          </div>

          <form id="formCriarConta" noValidate autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
            <InputText
              style={{ marginTop: 32 }}
              autoFocus={nomeAutoFocus}
              id="xnome"
              name="xnome"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              errorMessage={errorsMap('nome')}
              helpMessage="Informe o seu nome e sobrenome"
              label="Nome completo"
              inputRef={this.inputNome}
            />

            <InputText
              style={{ marginTop: 18 }}
              id="xemail"
              name="xemail"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              errorMessage={errorsMap('email')}
              helpMessage={helpMessageEmail}
              label="E-mail"
              inputRef={this.inputEmail}
            />

            <InputTelefone
              label="Número de Celular"
              placeholder="(00) 0000-0000"
              inputVariant='outlined'
              phoneRef={this.inputNumeroCelular}
              error={errorsMap('numeroCelular')}
              ajaxing={this.state.ajaxing}
              style={{ marginTop: 18 }}
            />

            <InputText
              style={{ marginTop: 18 }}
              id="xsenha"
              name="xsenha"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              type={this.state.showPassword ? 'text' : 'password'}
              errorMessage={errorsMap('senha')}
              helpMessage="Use oito ou mais caracteres com uma combinação de letras e números"
              label="Senha"
              inputRef={this.inputSenha}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <InputText
              style={{ marginTop: 18 }}
              id="xsenhaConfirmacao"
              name="xsenhaConfirmacao"
              shrink={false}
              customVariant="outlined"
              disabled={this.state.ajaxing}
              type={this.state.showPassword ? 'text' : 'password'}
              errorMessage={errorsMap('senhaConfirmacao')}
              label="Confirmar senha"
              inputRef={this.inputSenhaConfirmacao}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <Grid container style={{ marginTop: '26px', marginBottom: 14 }}>
              <Button disabled={this.state.ajaxing} variant="contained" color="primary" size="large" fullWidth type="submit">
                {this.state.ajaxing ? 'AGUARDE' : 'CRIAR CONTA'}
              </Button>
            </Grid>
            <Typography variant="body2" style={{ marginBottom: 3, color: '#666' }} align="center">
              Já possui uma conta?{' '}
              <Link disabled={this.state.ajaxing} to={ROUTE_CONTA_MAIN + '/login'} style={{ textDecoration: 'none' }}>
                Login
              </Link>
            </Typography>

            <GoogleSSOButton variant="signup" disabled={this.state.ajaxing} continuationUrl={urlContinuacao} withSeparator />

            {
              <Typography variant="body2" style={{ marginTop: 40, marginBottom: 3, color: '#666' }} align="center">
                Ao criar sua conta você confirma que é maior de 13 anos e está de acordo com o{' '}
                <LinkMUI className={classes.link} {...termosUsoLinkProps} rel="noopener noreferrer" underline="always">
                  Termo de uso
                </LinkMUI>
                . Os dados aqui coletados serão tratados nos termos de nossa{' '}
                <LinkMUI className={classes.link} {...politicaPrivacidadeLinkProps} rel="noopener noreferrer" underline="always">
                  Política de Privacidade
                </LinkMUI>
              </Typography>
            }
          </form>
        </Paper>
      </div>
    )
  }
}

CriarContaPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CriarContaPage)
