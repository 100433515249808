import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { prepararPerguntas } from 'support/domain/fichacliente/FichaClienteService'
import TipoRespostaEnum from 'support/domain/fichacliente/TipoRespostaEnum'
import FormUtil from 'support/util/FormUtil'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class FichaClienteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.listFunctionMap = {}
    this.state = {
      paginaCarregada: false,
      errorsMap: createErrorsMap(),
      fichaCliente: {},
      ajaxing: false
    }
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/fichascliente.buscarFichaClientePorId',
      params: {
        id: this.props.idFichaCliente
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          title: response.data.ficha.nome,
          fichaCliente: prepararPerguntas(response.data)
        })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
        window.setTimeout(() => {
          if (this.listFunctionMap && this.listFunctionMap.recomputeRowHeights) {
            this.listFunctionMap.recomputeRowHeights()
          }
        }, 100)
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirFicha()
  }

  persistirFicha() {
    postAPI({
      url: 'erp/fichascliente.persistirFichaCliente',
      data: this.getDadosFicha(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoFichaCliente', {
          action: 'update',
          fichaCliente: this.state.fichaCliente
        })
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
        FormUtil.focusFirstElementWithError('formFicha')
      }
    })
  }

  getDadosFicha = () => {
    const dados = {}

    dados.idCliente = this.state.fichaCliente.cliente.id
    dados.fichaCliente = {}
    dados.fichaCliente.id = this.state.fichaCliente.id
    dados.fichaCliente.ficha = { id: this.state.fichaCliente.ficha.id }
    dados.fichaCliente.respostas = []

    this.state.fichaCliente.perguntas.forEach((itemPergunta, index) => {
      const fichaPergunta = { id: itemPergunta.id }
      let resposta = ''
      if (itemPergunta.tipoResposta.id === TipoRespostaEnum.MULTIPLA_ESCOLHA.id) {
        resposta = itemPergunta.resposta.valor.join()
      } else {
        resposta = itemPergunta.resposta.valor
      }

      dados.fichaCliente.respostas.push({
        id: itemPergunta.resposta.id,
        fichaPergunta: fichaPergunta,
        resposta: resposta,
        tipoResposta: itemPergunta.tipoResposta
      })
    })

    return dados
  }

  getRenderContent = (size, itemPergunta, index) => {
    const content = {}

    if (itemPergunta.tipoResposta.id === TipoRespostaEnum.TEXTO_LIVRE.id) {
      content.resposta = (
        <div>
          <Typography variant="body2" color="textSecondary">
            {itemPergunta.descricao}
          </Typography>
          <InputText
            defaultValue={itemPergunta.resposta.valor}
            disabled={this.state.ajaxing}
            inputRef={itemPergunta._resposta}
            marginTop={4}
            multiline={true}
            rowsMax={10}
            rows={1}
            onChange={(event) => {
              itemPergunta.resposta.valor = itemPergunta._resposta.current.value
              if (this.listFunctionMap && this.listFunctionMap.recomputeRowHeights) {
                this.listFunctionMap.recomputeRowHeights(index)
              }
            }}
            style={{ marginBottom: 5 }}
          />
        </div>
      )
    } else if (itemPergunta.tipoResposta.id === TipoRespostaEnum.UNICA_ESCOLHA.id) {
      content.resposta = (
        <FormControl component="fieldset">
          <FormLabel>{itemPergunta.descricao}</FormLabel>
          <RadioGroup
            style={{ marginTop: 12 }}
            value={itemPergunta.resposta.valor ? itemPergunta.resposta.valor.toString() : null}
            onChange={(event) => {
              const perguntas = this.state.fichaCliente.perguntas.slice()
              perguntas[index].resposta.valor = parseInt(event.target.value, 10)
              this.setState((state) => {
                state.fichaCliente.perguntas = perguntas
                return { fichaCliente: state.fichaCliente }
              })
            }}
          >
            {itemPergunta.alternativas.map((alternativa) => (
              <FormControlLabel
                key={alternativa.id}
                style={{ marginLeft: 0 }}
                value={alternativa.id.toString()}
                control={<Radio style={{ padding: 5 }} />}
                label={alternativa.descricao}
                disabled={this.state.ajaxing}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )
    } else if (itemPergunta.tipoResposta.id === TipoRespostaEnum.MULTIPLA_ESCOLHA.id) {
      content.resposta = (
        <FormControl component="fieldset">
          <FormLabel style={{ marginBottom: 12 }}>{itemPergunta.descricao}</FormLabel>
          {itemPergunta.alternativas.map((alternativa) => {
            const indexAlternativa = itemPergunta.resposta.valor.indexOf(alternativa.id)

            return (
              <UncontrolledCheckbox
                key={'me_' + alternativa.id}
                formControlProps={{
                  style: { marginLeft: 0 },
                  label: alternativa.descricao
                }}
                style={{ padding: 5 }}
                color="secondary"
                defaultChecked={indexAlternativa !== -1 ? true : false}
                onChange={(value) => {
                  const perguntas = this.state.fichaCliente.perguntas.slice()

                  if (value) {
                    perguntas[index].resposta.valor.push(alternativa.id)
                  } else {
                    if (index !== -1) {
                      perguntas[index].resposta.valor.splice(indexAlternativa, 1)
                    }
                  }
                  this.setState((state) => {
                    state.fichaCliente.perguntas = perguntas
                    return { fichaCliente: state.fichaCliente }
                  })
                }}
              />
            )
          })}
        </FormControl>
      )
    }

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { fichaCliente } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={600}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <ContentWithPreload loadFunction={this.carregarMain}>
              {() => (
                <form id="formFichaCliente" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  <VirtualizedResponsiveTable
                    overscanRowCount={8}
                    showHeaderColumns={false}
                    showRowBorders={false}
                    scrollElement={dialogContentProps.scrollContainerRef.current}
                    showBackgroundDividers={false}
                    dynamicHeight={true}
                    loadingIndicatorRowHeight={200}
                    listFunctionsMap={this.listFunctionMap}
                    items={fichaCliente.perguntas}
                    largeRenderProps={{
                      columns: [{ label: 'resposta', props: { xs: true }, horizontalPadding: 'none' }],
                      itemRenderer: (itemPergunta, index) => {
                        const content = this.getRenderContent('large', itemPergunta, index)
                        return {
                          itemData: [<div style={{ marginTop: index > 0 ? 32 : 0 }}>{content.resposta}</div>]
                        }
                      }
                    }}
                    smallRenderProps={{
                      rowHeight: 80,
                      horizontalPadding: 'none',
                      itemRenderer: (item, index) => {
                        const content = this.getRenderContent('small', item, index)
                        return {
                          itemData: <div style={{ marginTop: index > 0 ? 32 : 0 }}>{content.resposta}</div>
                        }
                      }
                    }}
                  />

                  <HiddenSubmitButton />
                </form>
              )}
            </ContentWithPreload>

            {this.state.paginaCarregada && (
              <div className={classes.containerFooterButtons}>
                <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                  Cancelar
                </Button>
                <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                  {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

FichaClienteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FichaClienteDialogPage)
