import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import UndoIcon from '@material-ui/icons/Undo'
import WorkIcon from '@material-ui/icons/Work'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import ProductIcon from 'support/components/icon/Products'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import TipoDespesaEnum from 'support/domain/despesa/TipoDespesaEnum'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 110,
    minWidth: 110
  },
  colunaValor: {
    maxWidth: 110,
    minWidth: 110
  },
  colunaPagamento: {
    maxWidth: 110,
    minWidth: 110
  },
  iconeTipoDespesa: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaDescricao: {
    flexGrow: 1
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 3 }),
    marginTop: 4
  }
})

class RelatorioDespesasDetalhadasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      ajaxing: false
    }

    this.dataExporterFunctionsMap = {}
  }

  getRequestParameters = () => {
    const dataPesquisaInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataPesquisaFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      idTipo: this.props.tipo ? this.props.tipo.id : null,
      idCategoria: this.props.categoria ? this.props.categoria.id : null,
      dataPesquisaInicial: dataPesquisaInicial,
      dataPesquisaFinal: dataPesquisaFinal,
      ignorarEstornoDinheiro: false
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.valor = formatarValorMonetario(item.valor, { prefix: false })
    content.dataDespesa = converterDataIntParaMoment(item.data).format('DD/MMM/YYYY')

    if (item.tipo.id === TipoDespesaEnum.TAXA_RECEBIMENTO.id) {
      content.icone = <CreditCardIcon />
    } else if (item.tipo.id === TipoDespesaEnum.PRODUTO.id) {
      content.icone = <ProductIcon />
    } else if (item.tipo.id === TipoDespesaEnum.GERAL.id) {
      content.icone = <MoneyOffIcon />
    } else if (item.tipo.id === TipoDespesaEnum.COMISSAO.id) {
      content.icone = <WorkIcon />
    } else if (item.tipo.id === TipoDespesaEnum.VALE.id) {
      content.icone = <CardGiftcardIcon />
    } else if (item.tipo.id === TipoDespesaEnum.ESTORNO_DINHEIRO.id) {
      content.icone = <UndoIcon />
    } else if (item.tipo.id === TipoDespesaEnum.SERVICO.id) {
      content.icone = <ListAltIcon />
    }

    content.nome = item.nome
    content.pago = item.paga ? 'Pago' : 'Em Aberto'

    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Relatório de Despesas - ' + formatarPeriodoNomeArquivo(this.props.dataInicial, this.props.dataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarDespesas',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, tipo, categoria, nome } = this.props
    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')
    const titulo = categoria ? nome : tipo ? TipoDespesaEnum.getById(tipo.id).descricao : 'Todas despesas'

    let valorTotal = 0
    let quantidadeTotal = 0
    for (let item of this.state.lista) {
      valorTotal += item.valor
      quantidadeTotal++
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} title="Detalhamento" align="center" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">{titulo}</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              itemsPerRequest={30}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/despesas.buscar"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Data', className: classes.colunaData },
                  { label: '', className: classes.iconeTipoDespesa, isVisible: false, horizontalPadding: 'small' },
                  { label: 'Nome', className: classes.colunaDescricao, props: { xs: true }, horizontalPadding: 'small' },
                  { label: 'Pagamento', className: classes.colunaPagamento, horizontalPadding: 'small', align: 'right' },
                  { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (comissao, index) => {
                  const content = this.getRenderContent('large', comissao)

                  return {
                    itemData: [content.dataDespesa, content.icone, content.nome, content.pago, content.valor]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 120,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center">
                          <Grid item align="right" style={{ marginRight: 4 }}>
                            {content.icone}
                          </Grid>
                          <Grid item xs style={{ minWidth: 0 }}>
                            <Typography variant="body2">{content.dataDespesa}</Typography>
                          </Grid>
                          <Grid item align="right">
                            <Typography variant="body2">
                              <b>R$ {content.valor}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography variant="body2" className={classes.descricaoContainerSmall}>
                          {' '}
                          {content.nome}
                        </Typography>
                        <Typography variant="body2">{content.pago}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Não existem despesas para o período informado'
              }}
            />

            {quantidadeTotal > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Quantidade: <b>{quantidadeTotal}</b>
                  <br />
                  Total: <b>{formatarValorMonetario(valorTotal)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioDespesasDetalhadasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RelatorioDespesasDetalhadasDialogPage)
