import React, { Component } from 'react'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import ServicoDadosBasicosDialogPage from 'pages/erp/painel/servico/ServicoDadosBasicosDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CustomDialog from 'support/components/dialog/CustomDialog'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { removeAccentsAndSpecialChars } from 'support/util/StringUtil'
import { searchPart } from 'support/util/StringUtil'
import { formatarDuracao } from 'support/util/TimeFormatter'

const ROW_HEIGHT = 54

const styles = (theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 496,
    width: '100%',
    margin: 4
  },
  rootTitle: {
    //borderBottom: "1px solid "+theme.palette.divider,
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    position: 'relative',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    zIndex: 1
  },
  titleMainContent: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(12)
  },
  closeButton: {
    color: theme.palette.grey[500]
  },
  rightButton1: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  rightButton2: {
    position: 'absolute',
    right: theme.spacing(7),
    top: theme.spacing(1)
  },
  rightButton3: {
    position: 'absolute',
    right: theme.spacing(13),
    top: theme.spacing(1)
  },
  leftButton1: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1)
  },
  dialogContent: {
    maxHeight: 1200,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0
  },
  rootListItem: {
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  contentListItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 8
  },
  primaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 15
    //fontWeight: 500
  },
  secondaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: darken(theme.palette.secondary.main, 0.03)
  },
  iconListItem: {
    marginTop: 4,
    marginRight: -4
  }
})

class InputServicoDialog extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      step: 0,
      categorias: [],
      grupoServicos: [],
      servicos: [],
      variacoes: [],
      servicosParaPesquisa: [],
      servicosParaPesquisaFiltrados: []
    }

    this.stringPesquisaServico = ''
    this.scrollContainerRef = React.createRef()
    this.inputPesquisaNomeServico = React.createRef()
    this.loaderStep1FunctionsMap = {}
    this.loaderStep5FunctionsMap = {}
  }

  componentDidMount = () => {
    this.setState({ step: 1 })
    for (let eventName of ['ManipulacaoCategoriaServico', 'ManipulacaoServico', 'AssociacaoServicoColaborador']) {
      this.eventsManager.sub(eventName, (props) => {
        const { step } = this.state
        if (step === 1) {
          this.loaderStep1FunctionsMap.load()
        } else if (step <= 4) {
          this.setState({ step: 1 })
        } else if (step === 5) {
          this.loaderStep5FunctionsMap.load()
        }
      })
    }
    this.criarMapClientePacoteItem(this.props.pacoteItensDisponiveis)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const filter = function (key, value) {
      if (key.charAt(0) === '_' || key === 'current') {
        return
      }
      return value
    }

    const propsClone = Object.assign({}, this.props)
    const nextPropsClone = Object.assign({}, nextProps)

    const pacoteItensDisponiveisPropsCloneString = JSON.stringify(propsClone.pacoteItensDisponiveis, filter)
    const pacoteItensDisponiveisNextPropsCloneString = JSON.stringify(nextPropsClone.pacoteItensDisponiveis, filter)

    if (pacoteItensDisponiveisPropsCloneString !== pacoteItensDisponiveisNextPropsCloneString) {
      this.criarMapClientePacoteItem(nextProps.pacoteItensDisponiveis)
    }
    return true
  }

  criarMapClientePacoteItem = (pacoteItensDisponiveis) => {
    this.mapClientePacoteItem = {}
    if (this.props.pacoteItensDisponiveis) {
      for (let pacoteItemDisponivel of this.props.pacoteItensDisponiveis) {
        if (!this.props.dataAtendimento || !pacoteItemDisponivel.dataVencimento || pacoteItemDisponivel.dataVencimento >= converterMomentParaDataInt(this.props.dataAtendimento)) {
          this.mapClientePacoteItem[this.getPacoteItemMapKey(pacoteItemDisponivel.servico, pacoteItemDisponivel.variacaoServico)] = true
        }
      }
    }
  }

  getPacoteItemMapKey = (servico, variacao) => {
    let key = 's' + servico.id
    if (variacao) {
      key += 'v' + variacao.id
    }
    return key
  }

  prepararServicos = (servicos) => {
    for (let servico of servicos) {
      servico.variacoes.sort(function (a, b) {
        if (a.id < b.id) return -1
        if (a.id > b.id) return 1
        return 0
      })
    }
  }

  processarListaServicos = (servicos) => {
    const agrupamentos = [{}, {}, {}]

    for (let servico of servicos) {
      const partesNome = servico.nome.split(/\s+/)

      for (let i = 0; i <= 2; i++) {
        const agrupamento = agrupamentos[i]
        const grupo = partesNome.slice(0, i + 1).join(' ')
        const grupoParaComparacao = removeAccentsAndSpecialChars(grupo).toLowerCase()

        if (!agrupamento[grupoParaComparacao]) {
          agrupamento[grupoParaComparacao] = { nome: grupo, servicos: [servico] }
        } else {
          agrupamento[grupoParaComparacao].servicos.push(servico)
        }
      }
    }

    const agrupamentosViaveis = []
    for (let agrupamento of agrupamentos) {
      const quantidadeServicosAgrupamento = Object.keys(agrupamento).length
      if (quantidadeServicosAgrupamento > 1 && quantidadeServicosAgrupamento < servicos.length) {
        agrupamentosViaveis.push(agrupamento)
      }
    }

    const agrupamentosViaveisNivel2 = []
    for (let i = agrupamentosViaveis.length - 1; i >= 0; i--) {
      const agrupamentoViavel = agrupamentosViaveis[i]
      if (Object.keys(agrupamentoViavel).length < servicos.length * 0.5) {
        agrupamentosViaveisNivel2.push(agrupamentoViavel)
      }
    }

    let agrupamentoEscolhido = null

    if (agrupamentosViaveisNivel2.length === 0) {
      agrupamentoEscolhido = agrupamentosViaveis[0]
    } else {
      for (let i = agrupamentosViaveisNivel2.length - 1; i >= 0; i--) {
        const agrupamentoViavelNivel2 = agrupamentosViaveisNivel2[i]
        if (Object.keys(agrupamentoViavelNivel2).length < 20) {
          agrupamentoEscolhido = agrupamentoViavelNivel2
          break
        }
      }
      if (!agrupamentoEscolhido) {
        agrupamentoEscolhido = agrupamentosViaveisNivel2[0]
      }
    }

    const grupoServicos = []
    if (agrupamentoEscolhido) {
      for (const key of Object.keys(agrupamentoEscolhido)) {
        grupoServicos.push(agrupamentoEscolhido[key])
      }
    }

    this.setState({
      grupoServicos: grupoServicos,
      servicos: servicos,
      step: !servicos.length || grupoServicos.length ? 2 : 3,
      servicosParaPesquisa: [],
      servicosParaPesquisaFiltrados: []
    })
  }

  renderCategoriaRow = (categoria, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={categoria.nome}
          onClick={() => {
            this.scrollContainerRef.current.scrollTop = 0
            this.setState({ step: 2, categoria: categoria })
          }}
        />
      </div>
    )
  }

  renderGrupoServicoRow = (grupo, { index, key, style }) => {
    let primary = grupo.nome
    if (grupo.servicos.length === 1) {
      return this.renderServico(grupo.servicos[0], true, key, style)
    } else {
      primary += '  (' + grupo.servicos.length + ')'
    }
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={primary}
          onClick={() => {
            this.scrollContainerRef.current.scrollTop = 0
            this.setState({ step: 3, grupoServico: grupo, servicos: grupo.servicos })
          }}
        />
      </div>
    )
  }

  renderServicoRow = (servico, { index, key, style }) => {
    return this.renderServico(servico, false, key, style)
  }

  renderServico = (servico, limparGrupo, key, style) => {
    let primary = servico.nome
    let secondary = null
    if (servico.quantidadeVariacoes === 1) {
      const variacao = servico.variacoes[0]
      secondary = this.getSecondaryServicoOuVariacao(servico, variacao)
    } else {
      primary += '  (' + servico.quantidadeVariacoes + ')'
    }
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={primary}
          secondary={secondary}
          onClick={(event) => {
            let grupoServico = this.state.grupoServico

            if (limparGrupo) {
              grupoServico = null
            }

            if (!this.props.selectionType || this.props.selectionType === 'variacao') {
              if (servico.quantidadeVariacoes > 1) {
                this.scrollContainerRef.current.scrollTop = 0
                this.setState({ step: 4, grupoServico: grupoServico, servico: servico, variacoes: servico.variacoes })
              } else {
                this.props.setValue({
                  servico: servico,
                  variacao: servico.variacoes[0]
                })
                this.props.handleCloseDialog()
              }
            } else if (this.props.selectionType === 'servico') {
              this.props.setValue({
                servico: servico
              })
              this.props.handleCloseDialog()
            } else if (this.props.selectionType === 'servico|variacao') {
              if (servico.quantidadeVariacoes > 1) {
                this.setState({
                  servicoMenuAnchorPosition: {
                    left: event.clientX,
                    top: event.clientY
                  },
                  servicoMenufunction: {
                    selecionar: () => {
                      this.props.setValue({
                        servico: servico
                      })
                      this.props.handleCloseDialog()
                    },
                    abrirVariacoes: () => {
                      this.scrollContainerRef.current.scrollTop = 0
                      this.setState({ step: 4, servico: servico, variacoes: servico.variacoes, servicoMenuAnchorPosition: null })
                    }
                  }
                })
              } else {
                this.props.setValue({
                  servico: servico
                })
                this.props.handleCloseDialog()
              }
            }
          }}
        />
      </div>
    )
  }

  renderVariacaoRow = (variacao, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          classesNamesObject={this.props.classes}
          primary={variacao.nome}
          secondary={this.getSecondaryServicoOuVariacao(this.state.servico, variacao)}
          onClick={() => {
            this.props.setValue({
              servico: this.state.servico,
              variacao: variacao
            })
            this.props.handleCloseDialog()
          }}
        />
      </div>
    )
  }

  getSecondaryServicoOuVariacao = (servico, variacao) => {
    if (this.mapClientePacoteItem[this.getPacoteItemMapKey(servico)] || this.mapClientePacoteItem[this.getPacoteItemMapKey(servico, variacao)]) {
      return (
        <Grid container alignItems="center">
          <Grid item style={{ marginRight: 5 }}>
            <TipoVendaEnum.VENDA_PACOTE.iconComponent style={{ display: 'block', fontSize: 18 }} />
          </Grid>
          <Grid item>
            <Typography variant="body2">Crédito de Pacote</Typography>
          </Grid>
        </Grid>
      )
    } else {
      return formatarDuracao(variacao.duracao, { descricao: 'minificada' }) + ', ' + formatarValorMonetario(variacao.preco)
    }
  }

  handleChangePesquisaNomeServico = () => {
    this.setState({ servicosParaPesquisaFiltrados: this.getServicosFiltrados(this.state.servicosParaPesquisa) })
  }

  getServicosFiltrados = (servicosParaPesquisa) => {
    this.stringPesquisaServico = this.inputPesquisaNomeServico.current.value.trim()
    const servicosParaPesquisaFiltrados = []
    for (let servico of servicosParaPesquisa) {
      if (searchPart(servico.nome, this.stringPesquisaServico)) {
        servico._update = true
        servicosParaPesquisaFiltrados.push(servico)
      }
    }
    return servicosParaPesquisaFiltrados
  }

  creationFunction = (props) => {
    if (!verificarAutorizacao([PA.PODE_ALTERAR_CAD_SERVICOS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    if (!props) {
      props = {}
    }
    openBackableDialog(ServicoDadosBasicosDialogPage, {
      nome: props.text,
      abrirServicoDepoisCriacao: false
    })
  }

  render() {
    const { classes, colaborador } = this.props
    const {
      step,
      categoria,
      categorias,
      grupoServico,
      grupoServicos,
      servico,
      servicos,
      variacoes,
      servicosParaPesquisaFiltrados,
      servicoMenuAnchorPosition,
      servicoMenufunction
    } = this.state

    let quantidadeItensScroll = 2
    let titulo = ''

    if (step === 1) {
      quantidadeItensScroll = categorias ? categorias.length : 0
    } else if (step === 2) {
      quantidadeItensScroll = grupoServicos ? grupoServicos.length : 0
      titulo = categoria.nome
    } else if (step === 3) {
      quantidadeItensScroll = servicos ? servicos.length : 0
      if (grupoServico) {
        titulo = grupoServico.nome
      } else {
        titulo = categoria.nome
      }
    } else if (step === 4) {
      quantidadeItensScroll = variacoes ? variacoes.length : 0
      titulo = servico.nome
    }

    if (quantidadeItensScroll < 1) {
      quantidadeItensScroll = 1
    }

    return (
      <CustomDialog classes={{ paper: classes.root }} dialogProps={this.props} disabledOnClose={this.state.ajaxing}>
        <DialogTitle disableTypography className={classes.rootTitle}>
          {step === 1 && (
            <React.Fragment>
              <Typography variant="h6">Selecione...</Typography>
              <IconButton
                color="secondary"
                className={classes.rightButton2}
                onClick={() => {
                  this.setState({ step: 5 })
                }}
              >
                <SearchIcon />
              </IconButton>
              <IconButton color="secondary" className={classes.rightButton3} onClick={this.creationFunction}>
                <AddIcon />
              </IconButton>
            </React.Fragment>
          )}
          {step > 1 && step <= 4 && (
            <React.Fragment>
              <IconButton
                className={classes.leftButton1}
                onClick={() => {
                  let stepBack = step - 1
                  if (step === 3) {
                    if (grupoServico) {
                      stepBack = 2
                    } else {
                      stepBack = 1
                    }
                  } else if (step === 4) {
                    if (grupoServico) {
                      stepBack = 3
                    } else {
                      if (this.state.categoria) {
                        stepBack = 2
                      } else {
                        stepBack = 5
                      }
                    }
                  }
                  this.setState({ step: stepBack })
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" className={classes.titleMainContent}>
                {titulo}
              </Typography>
              <IconButton color="secondary" className={classes.rightButton2} onClick={this.creationFunction}>
                <AddIcon />
              </IconButton>
            </React.Fragment>
          )}

          {step === 5 && (
            <React.Fragment>
              <IconButton
                className={classes.leftButton1}
                onClick={() => {
                  this.setState({ step: 1 })
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <div className={classes.titleMainContent}>
                <InputText
                  key="inpuPesquisa"
                  autoFocus
                  placeholder="Pesquisar por serviço..."
                  customVariant="naked"
                  nakedLeftPadding={false}
                  onChange={this.handleChangePesquisaNomeServico}
                  defaultValue={this.stringPesquisaServico}
                  inputRef={this.inputPesquisaNomeServico}
                />
              </div>
              <IconButton
                color="secondary"
                className={classes.rightButton2}
                onClick={() => {
                  this.creationFunction({ text: this.inputPesquisaNomeServico.current.value.trim() })
                }}
              >
                <AddIcon />
              </IconButton>
            </React.Fragment>
          )}

          <IconButton className={classNames(classes.rightButton1, classes.closeButton)} onClick={this.props.handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent className={classNames(classes.dialogContent, 'needsWillChange')} style={{ wwillChange: 'ttransform' }}>
            {step === 1 && (
              <VirtualizedList
                key="categorias"
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                contextoUsuario="erp"
                endpoint="erp/servicos.buscarCategoriasPorColaborador"
                loaderFunctionsMap={this.loaderStep1FunctionsMap}
                getRequestParametersFunction={() => ({
                  idColaborador: colaborador ? colaborador.id : null
                })}
                items={categorias}
                onLoad={(items) => {
                  this.setState({ categorias: items })
                }}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderCategoriaRow}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum serviço foi encontrado',
                  textButton: 'Cadastrar Serviço',
                  footerText: colaborador
                    ? 'Você já cadastrou um serviço e ele não está aparecendo? Lembre-se de que também é preciso associar o serviço aos colaboradores que podem realizá-lo.'
                    : null,
                  creationFunction: this.creationFunction
                }}
              />
            )}
            {step === 2 && (
              <VirtualizedList
                key={'grupoServicos' + this.state.categoria.id}
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                contextoUsuario="erp"
                endpoint="erp/servicos.buscarServicosPorCategoria"
                getRequestParametersFunction={() => ({
                  idColaborador: colaborador ? colaborador.id : null,
                  idCategoria: this.state.categoria.id
                })}
                items={grupoServicos}
                onLoad={(items) => {
                  this.prepararServicos(items)
                  this.processarListaServicos(items)
                }}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderGrupoServicoRow}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum serviço foi encontrado',
                  textButton: 'Cadastrar Serviço',
                  footerText: colaborador
                    ? 'Você já cadastrou um serviço e ele não está aparecendo? Lembre-se de que também é preciso associar o serviço aos colaboradores que podem realizá-lo.'
                    : null,
                  creationFunction: this.creationFunction
                }}
              />
            )}
            {step === 3 && servicos && (
              <VirtualizedList
                key="servicos"
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                items={servicos}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderServicoRow}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum serviço foi encontrado',
                  textButton: 'Cadastrar Serviço',
                  footerText: colaborador
                    ? 'Você já cadastrou um serviço e ele não está aparecendo? Lembre-se de que também é preciso associar o serviço aos colaboradores que podem realizá-lo.'
                    : null,
                  creationFunction: this.creationFunction
                }}
              />
            )}

            {step === 4 && variacoes && (
              <VirtualizedList
                key="variacoes"
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                items={variacoes}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderVariacaoRow}
              />
            )}
            {step === 5 && (
              <VirtualizedList
                key={'servicosParaPesquisa'}
                windowScroller={true}
                scrollElement={this.scrollContainerRef.current}
                contextoUsuario="erp"
                endpoint="erp/servicos.buscarServicosPorCategoria"
                loaderFunctionsMap={this.loaderStep5FunctionsMap}
                getRequestParametersFunction={() => ({
                  idColaborador: colaborador ? colaborador.id : null
                })}
                items={servicosParaPesquisaFiltrados}
                onLoad={(items) => {
                  this.prepararServicos(items)

                  this.setState({
                    servicosParaPesquisa: items,
                    servicosParaPesquisaFiltrados: this.getServicosFiltrados(items)
                  })
                }}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.renderServicoRow}
                emptyListProps={{
                  padding: true,
                  text: 'Nenhum serviço foi encontrado',
                  textButton: 'Cadastrar Serviço',
                  footerText: colaborador
                    ? 'Você já cadastrou um serviço e ele não está aparecendo? Lembre-se de que também é preciso associar o serviço aos colaboradores que podem realizá-lo.'
                    : null,
                  creationFunction: () => {
                    this.creationFunction({ text: this.inputPesquisaNomeServico.current.value.trim() })
                  }
                }}
              />
            )}
            <Menu
              anchorReference="anchorPosition"
              anchorPosition={servicoMenuAnchorPosition}
              open={Boolean(servicoMenuAnchorPosition)}
              onClose={() => this.setState({ servicoMenuAnchorPosition: null })}
            >
              {servicoMenuAnchorPosition && [
                <MenuItem key="selecionar" onClick={servicoMenufunction.selecionar}>
                  Selecionar
                </MenuItem>,
                <MenuItem key="abrirVariacoes" onClick={servicoMenufunction.abrirVariacoes}>
                  Abrir Variações
                </MenuItem>
              ]}
            </Menu>
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

function ListItemSelectable(props) {
  let secondary = null
  if (props.secondary) {
    if (typeof props.secondary === 'string' || (props.secondary && props.secondary.type && props.secondary.type === 'span')) {
      secondary = (
        <Typography variant="body2" className={props.classesNamesObject.secondaryListItem}>
          {props.secondary}
        </Typography>
      )
    } else {
      secondary = <div className={props.classesNamesObject.secondaryListItem}>{props.secondary}</div>
    }
  }

  return (
    <Grid container alignItems="center" className={props.classesNamesObject.rootListItem} onClick={props.onClick}>
      <Grid item xs className={props.classesNamesObject.contentListItem}>
        <Typography variant="body2" className={props.classesNamesObject.primaryListItem}>
          {props.primary}
        </Typography>
        {secondary}
      </Grid>
      <Grid item>
        <NavigateNextIcon className={props.classesNamesObject.iconListItem} />
      </Grid>
    </Grid>
  )
}

InputServicoDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputServicoDialog)
