import { useCallback, useState } from 'react'

import { ApiFnParams, ApiParams, callAPIWithPromise } from '../CallApiWithPromisse'

export const useApiCall = <ResponseDataType>(callApiFn: (params: ApiFnParams) => void) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ResponseDataType | null>(null)
  const [error, setError] = useState<unknown>(null)

  const callApi = useCallback(
    async (params: ApiParams) => {
      setLoading(true)
      setError(null)
      try {
        const response = await callAPIWithPromise<ResponseDataType>(callApiFn, params)
        setData(response)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    },
    [callApiFn]
  )

  return { loading, data, error, callApi }
}
