import { createEnum } from 'support/util/EnumUtil'
import StringUtil from 'support/util/StringUtil'

export function createInlineValuesObject(props) {
  const obj = createEnum(props)
  obj['normalizeString'] = (string) => {
    if (!string) {
      string = ''
    }
    const itemsLength = obj.values().length
    return string.padEnd(itemsLength, '0').substring(0, itemsLength)
  }
  obj['generateAllItems'] = (string) => {
    string = obj.normalizeString(string)
    const items = obj.values().slice(0)
    const newItems = []
    for (let item of items) {
      item.exists = string.substring(item.position, item.position + 1) === '1'
      newItems.push(item)
    }
    return newItems
  }
  obj['getString'] = (items) => {
    let string = obj.normalizeString()
    for (let item of items) {
      string = StringUtil.setCharAt(string, item.position, item.exists ? '1' : '0')
    }
    return string
  }
  obj['verify'] = (requiredItems, stringToCheck, and) => {
    if (and === undefined) {
      and = false
    }

    stringToCheck = obj.normalizeString(stringToCheck)

    if (requiredItems !== undefined && requiredItems !== null && requiredItems.length > 0) {
      for (let requiredItem of requiredItems) {
        if (stringToCheck.charAt(requiredItem.position) === '1') {
          if (and === false) {
            return true
          }
        } else {
          if (and === true) {
            return false
          }
        }
      }
      if (and === false) {
        return false
      } else {
        return true
      }
    }
    return true
  }
  return obj
}
