import { ReactNode } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    newLabel: {
      display: 'inline-block',
      fontSize: '9px',
      border: '2px solid ' + theme.palette.secondary.main,
      marginLeft: 8,
      position: 'absolute',
      borderRadius: 20,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
      padding: '1px 6px'
    }
  })
)

type TagNewProps = {
  children: ReactNode
}

export const TagNew = ({ children }: TagNewProps) => {
  const classes = useStyles()

  return (
    <>
      {children}
      <span className={classes.newLabel}>Novo</span>
    </>
  )
}
