import { makeStyles, Theme } from '@material-ui/core'

export const BannerTokens = {
  paddingContentMobile: '24px',
  paddingContentDesktop: '32px',
  floraV2Tokens: {
    actionableColor: '#264FEC',
    altHover: '#AFD8FF',
    hover: '#002EC9'
  },
  backgroundColor: {
    branco: '#FFFFFF',
    azul: '#264FEC',
    roxo: '#291339',
    default: '#CCEEFF'
  }
}

export const useStyles = makeStyles<Theme>((theme) => ({
  banner: {
    display: 'flex',
    flexDirection: 'row',
    padding: BannerTokens.paddingContentMobile,
    position: 'fixed',
    bottom: 0,
    zIndex: 50,
    width: 'calc(100% - 48px)',

    [theme.breakpoints.up('md')]: {
      maxHeight: '171px',
      width: 'calc(100% - 328px)',
      padding: '0 32px'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  wrapperImageAndButton: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  wrapperCloseButton: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    '& > button': {
      position: 'relative',
      padding: '0px',
      [theme.breakpoints.down('sm')]: {
        top: '-16px',
        right: '-14px'
      }
    },
    '& > button > div': {
      width: '24px',
      height: '24px'
    },
    '& > button > div > svg': {
      width: '24px',
      height: '24px'
    }
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px'
  },
  closeButton: {
    marginTop: '12px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      fontWeight: 'bold'
    }
  },
  containerImg: {
    '& > img': {
      objectFit: 'cover',
      objectPosition: '100% 40%',
      height: '171px',
      width: '438px',
      [theme.breakpoints.down('sm')]: {
        height: '100px',
        width: '100%',
        objectPosition: 'center',
        objectFit: 'contain'
      }
    },
    [theme.breakpoints.up('md')]: {}
  },
  title: {
    gridArea: 'title',
    fontSize: '16px',
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    }
  },
  description: {
    marginBottom: '24px',
    gridArea: 'description',
    fontSize: '12px',

    [theme.breakpoints.up('md')]: {
      fontSize: '14px'
    }
  },
  button: {
    gridArea: 'button',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& button:first-of-type': {
      height: '32px',
      '&:hover': {
        backgroundColor: '#AFD8FF'
      }
    },
    '& button:nth-of-type(2)': {
      height: '32px',
      '&:hover': {
        backgroundColor: BannerTokens.floraV2Tokens.hover
      }
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  }
}))
