import { converterTudoParaMoment } from 'support/util/DateConverter'

export function formatarDateHHmm(date) {
  let string = date.toTimeString().split(' ')[0]
  string = string.split(':')
  return string[0] + ':' + string[1]
}

export function formatarNomeArquivo(data) {
  return formatar(data, 'YYYYMMDD')
}

export function formatarPeriodoNomeArquivo(dataInicial, dataFinal) {
  return formatarPeriodo(dataInicial, dataFinal, 'YYYYMMDD')
}

export function formatar(data, pattern) {
  if (data) {
    data = converterTudoParaMoment(data)
    data = data.clone().startOf('day')
  }

  let texto = ''

  if (data) {
    texto += data.format(pattern)
  }

  return texto
}

export function formatarPeriodo(dataInicial, dataFinal, pattern) {
  if (dataInicial) {
    dataInicial = converterTudoParaMoment(dataInicial)
    dataInicial = dataInicial.clone().startOf('day')
  }

  if (dataFinal) {
    dataFinal = converterTudoParaMoment(dataFinal)
    dataFinal = dataFinal.clone().startOf('day')
  }

  let texto = ''
  if (dataInicial) {
    if (!dataFinal) {
      texto = 'a partir '
    }

    texto += dataInicial.format(pattern)

    if (dataFinal && dataInicial.isSame(dataFinal, 'day')) {
      return texto
    }
  }

  if (dataFinal) {
    if (dataInicial) {
      texto += ' '
    }

    texto += 'até '
    texto += dataFinal.format(pattern)
  }

  return texto
}

const MAP_DIA_SEMANA = {
  1: { descricaoCOmpleta: 'Segunda-feira', descricaoResumida: 'Seg' },
  2: { descricaoCOmpleta: 'Terça-feira', descricaoResumida: 'Ter' },
  3: { descricaoCOmpleta: 'Quarta-feira', descricaoResumida: 'Qua' },
  4: { descricaoCOmpleta: 'Quinta-feira', descricaoResumida: 'Qui' },
  5: { descricaoCOmpleta: 'Sexta-feira', descricaoResumida: 'Sex' },
  6: { descricaoCOmpleta: 'Sábado', descricaoResumida: 'Sáb' },
  7: { descricaoCOmpleta: 'Domingo', descricaoResumida: 'Dom' }
}

export function getDescricaoDiaSemanaPorNumero(numero) {
  const dia = MAP_DIA_SEMANA[numero]
  if (dia) {
    return dia.descricaoCOmpleta
  }
  return ''
}

export function getDescricaoResumidaDiaSemanaPorNumero(numero) {
  const dia = MAP_DIA_SEMANA[numero]
  if (dia) {
    return dia.descricaoResumida
  }
  return ''
}
