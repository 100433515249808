import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class UncontrolledCheckbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.defaultChecked ? props.defaultChecked : false
    }
  }

  componentDidMount() {
    if (this.props.functionsMap !== undefined) {
      this.props.functionsMap.getValue = () => {
        return this.state.checked
      }
    }
  }

  render() {
    const { formControlProps, disabled, defaultChecked, onChange, ...othersPropsCheckbox } = this.props

    delete othersPropsCheckbox.functionsMap

    const checkboxComponent = (
      <Checkbox
        checked={this.state.checked}
        onChange={(event) => {
          this.setState({ checked: event.target.checked })
          if (this.props.onChange) {
            this.props.onChange(event.target.checked)
          }
        }}
        {...othersPropsCheckbox}
      />
    )

    if (formControlProps) {
      return <FormControlLabel {...formControlProps} control={checkboxComponent} disabled={disabled} />
    } else {
      return checkboxComponent
    }
  }
}

export default UncontrolledCheckbox
