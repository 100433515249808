import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { VisaoEnum } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import PropTypes from 'prop-types'
import { converterHorarioIntParaMinutos } from 'support/util/TimeConverter'

const styles = (theme) => ({
  root: {
    pointerEvents: 'none',
    position: 'relative',
    backgroundColor: '#d5d5d5',
    backgroundImage:
      'linear-gradient(45deg,' +
      theme.agendaInterface.horariosIndisponiveis.dark +
      ' 10%, ' +
      theme.agendaInterface.horariosIndisponiveis.light +
      ' 10%, ' +
      theme.agendaInterface.horariosIndisponiveis.light +
      ' 50%, ' +
      theme.agendaInterface.horariosIndisponiveis.dark +
      ' 50%, ' +
      theme.agendaInterface.horariosIndisponiveis.dark +
      ' 60%, ' +
      theme.agendaInterface.horariosIndisponiveis.light +
      ' 60%, ' +
      theme.agendaInterface.horariosIndisponiveis.light +
      ' 100%)',
    backgroundSize: '7.07px 7.07px',
    backgroundPositionY: '-2px',
    opacity: 0.15,
    zIndex: 2,
    borderRadius: 1,
    overflow: 'hidden',
    cursor: 'pointer',
    color: 'white'
  }
})

class MarcadoresHorariosIndisponiveis extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  prepararMarcadores = (horariosAtendimento) => {
    const marcadores = []
    let horaAtual = 0
    if (horariosAtendimento && horariosAtendimento.length > 0) {
      for (let horarioAtendimento of horariosAtendimento) {
        if (horarioAtendimento.de > horaAtual) {
          marcadores.push({
            de: horaAtual,
            ate: horarioAtendimento.de
          })
        }
        if (horaAtual < horarioAtendimento.ate) {
          horaAtual = horarioAtendimento.ate
        }
      }
      if (2359 > horaAtual) {
        marcadores.push({
          de: horaAtual,
          ate: 2400
        })
      }
    } else {
      marcadores.push({
        de: 0,
        ate: 2400
      })
    }
    return marcadores
  }

  gerarMarcadorContent = (coluna, marcador, index) => {
    const { classes, colunas } = this.props

    const deMinutos = converterHorarioIntParaMinutos(marcador.de)
    const ateMinutos = converterHorarioIntParaMinutos(marcador.ate)
    const duracaoMinutos = ateMinutos - deMinutos

    const rootProps = {
      key: coluna.id + '-' + index,
      className: classes.root,
      style: {}
    }

    const colunaPosicao = colunas.asMap[coluna.id].posicao
    const colunaWidth = this.props.colunaWidth - 2
    const colunaLeftPosition = colunaPosicao * this.props.colunaWidth + (colunaPosicao + 1) * 1

    rootProps.style.position = 'absolute'
    rootProps.style.top = deMinutos * this.props.pixelsPorMinuto + 1
    rootProps.style.left = colunaLeftPosition
    rootProps.style.width = colunaWidth
    rootProps.style.height = duracaoMinutos * this.props.pixelsPorMinuto - 3

    return <div {...rootProps}></div>
  }

  render() {
    const { dataSelecionada, colaboradorSelecionado, visao, colunas, colaboradores } = this.props

    const marcadoresContent = []

    if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      let horariosAtendimentoPorDia = null
      for (let colaborador of colaboradores) {
        if (colaborador.id === colaboradorSelecionado.id) {
          horariosAtendimentoPorDia = colaborador.horariosAtendimentoPorDia
          break
        }
      }

      for (let coluna of colunas.asList) {
        const horariosAtendimento = horariosAtendimentoPorDia[coluna.diaSemanaIso]
        const marcadores = this.prepararMarcadores(horariosAtendimento)
        if (marcadores.length > 0) {
          for (let i = 0; i < marcadores.length; i++) {
            const marcadorContent = this.gerarMarcadorContent(coluna, marcadores[i], i)
            if (marcadorContent) {
              marcadoresContent.push(marcadorContent)
            }
          }
        }
      }
    } else if (visao.id === VisaoEnum.PROFISSIONAL.id) {
      const diaSemanaDataSelecionada = dataSelecionada.isoWeekday()

      for (let coluna of colunas.asList) {
        let horariosAtendimentoPorDia = null
        for (let colaborador of colaboradores) {
          if (colaborador.id === coluna.id) {
            horariosAtendimentoPorDia = colaborador.horariosAtendimentoPorDia
            break
          }
        }

        const marcadores = this.prepararMarcadores(horariosAtendimentoPorDia[diaSemanaDataSelecionada])
        if (marcadores.length > 0) {
          for (let i = 0; i < marcadores.length; i++) {
            const marcadorContent = this.gerarMarcadorContent(coluna, marcadores[i], i)
            if (marcadorContent) {
              marcadoresContent.push(marcadorContent)
            }
          }
        }
      }
    }

    return marcadoresContent
  }
}

MarcadoresHorariosIndisponiveis.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MarcadoresHorariosIndisponiveis)
