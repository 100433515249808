import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    visibility: 'hidden'
  }
})

class DrawerMenuDivider extends Component {
  render() {
    const { style, classes } = this.props
    return <Divider className={classes.root} style={style} />
  }
}

DrawerMenuDivider.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DrawerMenuDivider)
