import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import VendaService from 'support/domain/venda/VendaService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  colunaCheckbox: {
    maxWidth: 50,
    minWidth: 50
  },
  iconeTipoVenda: {
    //display:"block",
    fontSize: '24px',
    display: 'none'
  },
  colunaIconeTipoVenda: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  backgroundColorInherit: {
    backgroundColor: 'inherit'
  },
  containerFooterButtons: theme.form.containerFooterButtons()
})

class GerenciarAtendimentosPacoteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Gerenciar atendimentos',
      quantidadeAtendimentosSelecionados: 0,
      lista: []
    }
  }

  getRenderContent = (size, venda, index) => {
    const { classes } = this.props
    const content = {}
    const checkboxStyle = {}
    if (size === 'small') {
      checkboxStyle.marginLeft = -2
    }

    content.checkbox = (
      <Checkbox
        style={checkboxStyle}
        disabled={this.state.ajaxing || venda.processada}
        checked={venda._checked === undefined ? false : venda._checked}
        onClick={(event) => {
          event.stopPropagation()
          this.selecionarItem(event, index)
        }}
      />
    )

    const complementoData = venda.dataVenda > this.state.clientePacoteItem.dataVencimento ? 'Vencido' : null

    content.data = (
      <span>
        {converterDataIntParaMoment(venda.dataVenda).format('DD/MMM/YY')}
        <br />
        {complementoData && (
          <Typography variant="body2" style={{ fontSize: 12, color: 'red', marginLeft: 7 }}>
            {' '}
            ({complementoData})
          </Typography>
        )}
      </span>
    )
    content.colaborador = venda.colaborador

    const iconeEDescricao = VendaService.getIconeEDescricao(venda)
    content.iconeTipoVenda = <iconeEDescricao.icon className={classes.iconeTipoVenda} />
    content.descricaoVenda = iconeEDescricao.descricao

    return content
  }

  selecionarItem = (event, index) => {
    const lista = this.state.lista
    const { clientePacoteItem } = this.state
    const qtdMaximaAtendimentosSelecionados = clientePacoteItem.quantidadeDisponivel + clientePacoteItem.quantidadeUtilizada

    const item = lista[index]
    let quantidadeAtendimentosSelecionados = this.state.quantidadeAtendimentosSelecionados
    let quantidadeDisponivel = this.state.quantidadeDisponivel

    if (quantidadeAtendimentosSelecionados === qtdMaximaAtendimentosSelecionados && event.target.checked) {
      openBackableDialog(MessageDialog, {
        text: <span>Selecione no máximo {qtdMaximaAtendimentosSelecionados} atendimentos.</span>
      })
    } else {
      if (!event.target.checked) {
        quantidadeAtendimentosSelecionados--
        quantidadeDisponivel++
      } else {
        quantidadeAtendimentosSelecionados++
        quantidadeDisponivel--
      }

      item._checked = event.target.checked
      item._update = true

      this.setState({
        lista: lista,
        quantidadeAtendimentosSelecionados: quantidadeAtendimentosSelecionados,
        quantidadeDisponivel: quantidadeDisponivel
      })
    }
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/clientes.alterarAtendimentosPacoteItem',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.setShouldCloseParent(true)
        this.props.handleCloseDialog()
        EventsManager.pub('ManutencaoPacote', { cliente: response.data })
        EventsManager.pub('RecarregarAtendimentos')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formCliente')
      }
    })
  }

  getDados = () => {
    const vendasSelecionadas = []

    this.state.lista.forEach((venda, index) => {
      if (venda._checked) {
        vendasSelecionadas.push({ id: venda.id })
      }
    })

    const dados = {
      idClientePacoteItem: this.state.clientePacoteItem.id,
      vendas: vendasSelecionadas
    }

    return dados
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/clientes.buscarClientePacoteItemPorId',
      params: { idClientePacoteItem: this.props.clientePacoteItem.id },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          clientePacoteItem: response.data,
          quantidadeDisponivel: response.data.quantidadeDisponivel
        })

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, cliente } = this.props
    const { quantidadeAtendimentosSelecionados, quantidadeDisponivel, clientePacoteItem } = this.state
    let toolbarActions = null

    if (this.state.lista && this.state.lista.length > 0) {
      toolbarActions = {
        actions: [
          {
            label: 'Salvar',
            showOnlyMobile: true,
            handleAction: () => this.salvar()
          }
        ]
      }
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={650} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <ContentWithPreload loadFunction={this.carregarMain}>
            {() => (
              <React.Fragment>
                <Typography variant="h6" style={{ marginBottom: 16, marginTop: 16 }}>
                  {clientePacoteItem.servico.nome}
                </Typography>
                {clientePacoteItem.dataVencimento && (
                  <Typography variant="body2" style={{ marginBottom: 8 }}>
                    <b>Vencimento:</b> {converterDataIntParaMoment(clientePacoteItem.dataVencimento).format('DD/MMM/YYYY')}
                  </Typography>
                )}
                <Typography variant="body2" style={{ marginBottom: 8 }}>
                  <b>Qtd. Utilizada:</b> {quantidadeAtendimentosSelecionados}
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 16 }}>
                  <b>Qtd. Disponível:</b> {quantidadeDisponivel}
                </Typography>

                <VirtualizedResponsiveTable
                  scrollElement={dialogContentProps.scrollContainerRef.current}
                  showBackgroundDividers={true}
                  loaderFunctionsMap={this.loaderFunctionsMap}
                  contextoUsuario="erp"
                  endpoint="erp/vendas.buscarVendasDisponiveisPacote"
                  getRequestParametersFunction={() => ({
                    idClientePacoteItem: clientePacoteItem.id,
                    idCliente: cliente.id,
                    idServico: clientePacoteItem.servico.id,
                    idVariacaoServico: clientePacoteItem.variacaoServico ? clientePacoteItem.variacaoServico.id : null
                  })}
                  items={this.state.lista}
                  loadTrackingFunction={(data) => {
                    if (data.status === 'loading') {
                      if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                        this.setState({ lista: [] })
                      }
                    } else if (data.status === 'loaded') {
                      let quantidadeAtendimentosSelecionados = this.state.quantidadeAtendimentosSelecionados

                      data.items.forEach((item, index) => {
                        if (item.clientePacoteItem) {
                          item._checked = true
                          quantidadeAtendimentosSelecionados++
                        }
                      })

                      this.setState({
                        lista: data.items,
                        quantidadeAtendimentosSelecionados: quantidadeAtendimentosSelecionados
                      })
                    }
                  }}
                  largeRenderProps={{
                    columns: [
                      { className: classes.colunaCheckbox, horizontalPadding: 'small' },
                      { label: 'Data', className: classes.colunaData },
                      //{label : "", className : classes.colunaIconeTipoVenda, isVisible:false, horizontalPadding: "small"},
                      { label: 'Serviço', className: classes.colunaDescricao, horizontalPadding: 'small', props: { xs: true } },
                      { label: 'Profissional', horizontalPadding: 'small', props: { xs: true } }
                    ],
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('large', item, index)
                      return {
                        itemData: [
                          content.checkbox,
                          content.data,
                          //content.iconeTipoVenda,
                          content.descricaoVenda,
                          content.colaborador.apelido
                        ]
                      }
                    }
                  }}
                  smallRenderProps={{
                    rowHeight: 85,
                    showFirstItemBorderTop: true,
                    horizontalPadding: 'none',
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('small', item, index)

                      return {
                        selected: item._checked,
                        itemData: (
                          <Grid container alignItems="center" className={classes.backgroundColorInherit}>
                            <Grid item style={{ marginRight: 8 }}>
                              {content.checkbox}
                            </Grid>
                            <Grid item xs style={{ minWidth: 0 }} className={classes.backgroundColorInherit}>
                              <Typography variant="body2" noWrap={true}>
                                {content.data}
                              </Typography>
                              <Typography variant="body2" style={{ fontWeight: 500 }} noWrap={true}>
                                {content.descricaoVenda}
                              </Typography>
                              <Typography variant="body2" noWrap={true}>
                                {content.colaborador.apelido}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      }
                    }
                  }}
                  emptyListProps={{
                    text: 'Nenhum atendimento foi encontrado'
                  }}
                />

                <HiddenSubmitButton />

                {this.state.lista && this.state.lista.length > 0 && (
                  <div className={classes.containerFooterButtons}>
                    <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                      Cancelar
                    </Button>
                    <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                      {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

GerenciarAtendimentosPacoteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GerenciarAtendimentosPacoteDialogPage)
