import React, { Component } from 'react'

import Link from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import WhatsAppService from 'support/domain/whatsapp/WhatsAppService'

const styles = (theme) => ({})

class SolicitacoesTreinamentoPanelPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lista: []
    }
    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.nomeUsuario = item.usuario.nome + (item.usuario.verificado ? ' (Verificado)' : '')
    content.numeroTelefone = item.numeroTelefone
    content.dataCriacao = moment(item.dataCriacao).format('DD/MM/YY HH:mm:ss')
    if (item.dataInicioAtendimento) {
      content.dataInicioAtendimento = moment(item.dataInicioAtendimento).format('DD/MM/YY HH:mm:ss')
    } else {
      content.dataInicioAtendimento = ''
    }

    return content
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  enviarMensagem = (solicitacao) => {}

  marcarInicioAtendimento = (solicitacao) => {
    postAPI({
      url: 'admin/treinamento.marcarInicioAtendimento',
      requerAutorizacao: true,
      data: {
        idSolicitacao: solicitacao.id
      },
      onSuccess: (response) => {
        this.loaderFunctionsMap.load()
      }
    })
  }

  abrirDetalhes = (solicitacao) => {
    let admin = null
    let perfil = null

    if (solicitacao.usuario.colaboradores) {
      for (let colaborador of solicitacao.usuario.colaboradores) {
        if (admin === null) {
          admin = colaborador.admin
        } else if (admin !== colaborador.admin) {
          perfil = 'Indefinido'
          break
        }
      }
    }

    if (!perfil) {
      if (admin === null) {
        perfil = 'Indefinido'
      } else if (admin) {
        perfil = 'Proprietário'
      } else if (admin) {
        perfil = 'Funcionário'
      }
    }

    openBackableDialog(MessageDialog, {
      title: 'Detalhes',
      maxWidth: 420,
      text: (
        <span>
          <b>Perfil:</b> {perfil}
          <br />
          <b>Email:</b> {solicitacao.usuario.email}
          <br />
          <b>Status:</b> {solicitacao.usuario.verificado ? ' E-mail Verificado' : 'E-mail NÃO Verificado'}
          <br />
          <b>Campanha:</b> {solicitacao.usuario.campanha ? solicitacao.usuario.campanha : 'Nenhuma'}
        </span>
      )
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { menuAnchorPosition, itemMenu } = this.state

    let enviarMensagemLinkProps = {}

    if (itemMenu) {
      enviarMensagemLinkProps = WhatsAppService.generateClickToChatLinkProps({
        phone: '55' + itemMenu.numeroTelefone,
        message: '',
        onClick: () => {
          this.closeMenu('menuAnchorPosition')
        }
      })
      enviarMensagemLinkProps.component = Link
    }

    return (
      <PanelPage
        {...others}
        title="Solicitações Treinamento"
        toolbarActions={{
          actions: [
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.loaderFunctionsMap.load()
              }
            }
          ]
        }}
      >
        <VirtualizedResponsiveTable
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="admin"
          endpoint="admin/treinamento.buscarSolicitacoesTreinamento"
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.setState({
              menuAnchorPosition: {
                left: event.clientX,
                top: event.clientY
              },
              itemMenu: item
            })
          }}
          largeRenderProps={{
            columns: [
              { label: 'Usuário', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Telefone', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Data Solicitação', horizontalPadding: 'small', props: { xs: true } },
              { label: 'Início Atendimento', horizontalPadding: 'small', props: { xs: true } }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [content.nomeUsuario, content.numeroTelefone, content.dataCriacao, content.dataInicioAtendimento]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 120,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                itemData: (
                  <React.Fragment>
                    <Typography variant="body1" noWrap={true}>
                      <b>{content.nomeUsuario}</b>
                    </Typography>
                    <Typography variant="body1" noWrap={true}>
                      {content.numeroTelefone}
                    </Typography>
                    <Typography variant="body2">Data Solicitação: {content.dataCriacao}</Typography>
                    <Typography variant="body2">Início Atendimento: {content.dataInicioAtendimento}</Typography>
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhum registro encontrado'
          }}
        />

        <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={() => this.closeMenu('menuAnchorPosition')}>
          <MenuItem
            onClick={() => {
              this.abrirDetalhes(this.state.itemMenu)
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Abrir Detalhes
          </MenuItem>
          <MenuItem {...enviarMensagemLinkProps}>Enviar Mensagem</MenuItem>
          <MenuItem
            onClick={() => {
              this.marcarInicioAtendimento(this.state.itemMenu)
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Marcar Início Atendimento
          </MenuItem>
        </Menu>
      </PanelPage>
    )
  }
}

SolicitacoesTreinamentoPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SolicitacoesTreinamentoPanelPage)
