import { isAndroid, isIOS } from 'react-device-detect'

import DeviceIdentifier from 'support/components/device/DeviceIdentifier'

function getPlatformString() {
  if (DeviceIdentifier.isNativeAndroid()) {
    return 'app_android'
  } else if (DeviceIdentifier.isNativeIOS()) {
    return 'app_ios'
  } else if (isAndroid) {
    return 'browser_android'
  } else if (isIOS) {
    return 'browser_ios'
  } else {
    return 'browser'
  }
}

const functionsObject = {
  getPlatformString: getPlatformString
}

export default functionsObject
