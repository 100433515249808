import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 90,
    minWidth: 90
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  colunaQuantidade: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  }
})

class DetalhesGastosProdutoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      idProduto: this.props.produto.id
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YY')

    content.quantidadeUso = (
      <span>
        {item.unidadeMedidaEmbalagem && (
          <React.Fragment>
            <span>{item.quantidadeUsoUnidadePersonalizada + ' ' + item.unidadeMedidaEmbalagem}</span>
            <br />
          </React.Fragment>
        )}
        <span>{item.quantidadeUsoQuantidade} unid.</span>
      </span>
    )

    content.valorCustoTotal = formatarValorMonetario(item.valorCustoTotal)

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    return (
      <DialogPage {...dialogProps} align="center" title="Detalhamento" ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">{this.props.produto.nome}</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              itemsPerRequest={30}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarGastosProduto"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              margin={true}
              largeRenderProps={{
                columns: [
                  { label: 'Data', props: { xs: true } },
                  { label: 'Quantidade', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' },
                  { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.data, content.quantidadeUso, content.valorCustoTotal]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 100,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center">
                          <Grid item xs style={{ marginRight: 4 }}>
                            <Typography variant="body2">
                              <b>{content.data}</b>
                            </Typography>
                            <Typography variant="body2" noWrap={true}>
                              Total: {content.valorCustoTotal}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" style={{ textAlign: 'right' }}>
                              {content.quantidadeUso}
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Não existem gastos para este produto'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

DetalhesGastosProdutoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesGastosProdutoDialogPage)
