import EstadoEnum from 'support/domain/localizacao/EstadoEnum'

export function formatarCPF(valor) {
  if (valor) {
    valor = valor.toString().padStart(11, '0')
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  }
  return ''
}
export function formatarCNPJ(valor) {
  if (valor) {
    valor = valor.toString().padStart(14, '0')
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  }
  return ''
}

export function formatarDataNascimento(cliente) {
  let dataNascimentoFormatada = null

  if (cliente && cliente.diaNascimento && cliente.mesNascimento) {
    dataNascimentoFormatada = cliente.diaNascimento.toString().padStart(2, '0') + '/' + cliente.mesNascimento.toString().padStart(2, '0')
    if (cliente.anoNascimento) {
      dataNascimentoFormatada += '/' + cliente.anoNascimento.toString()
    }
  }

  return dataNascimentoFormatada
}

export function formatarEndereco(endereco, opts) {
  if (!opts) {
    opts = {}
  }

  let enderecoFormatado = ''
  if (endereco) {
    if (endereco.endereco) {
      enderecoFormatado = endereco.endereco
    }

    if (endereco.numero) {
      enderecoFormatado += enderecoFormatado.length > 0 ? ', ' : 'Número: '
      enderecoFormatado += endereco.numero
    }

    if (endereco.complemento) {
      enderecoFormatado += enderecoFormatado.length > 0 ? ' - ' : 'Complemento: '
      enderecoFormatado += endereco.complemento
    }

    if (endereco.cep) {
      if (enderecoFormatado.length > 0) {
        enderecoFormatado = enderecoFormatado + ' - '
      }
      enderecoFormatado = enderecoFormatado + endereco.cep
    }

    if (endereco.bairro) {
      if (enderecoFormatado.length > 0) {
        enderecoFormatado = enderecoFormatado + ' - '
      }
      enderecoFormatado = enderecoFormatado + endereco.bairro
    }

    if (opts.format === 'simplificado') {
      return enderecoFormatado
    }

    if (endereco.cidade) {
      if (enderecoFormatado.length > 0) {
        enderecoFormatado = enderecoFormatado + ', '
      }
      enderecoFormatado = enderecoFormatado + endereco.cidade
    }

    if (endereco.estado && endereco.estado.id) {
      if (enderecoFormatado.length > 0) {
        enderecoFormatado = enderecoFormatado + ' - '
      }
      enderecoFormatado = enderecoFormatado + EstadoEnum.getById(endereco.estado.id).sigla
    }
  }
  return enderecoFormatado
}

export function formatarNome(nome, formato) {
  if (formato === 1) {
    //Apenas o primeiro nome
    return nome.split(' ')[0]
  } else if (formato === 2) {
    //Apenas primeiro e ultimo nome
    const partesNome = nome.split(' ')
    let nomeFormatado = partesNome[0]
    if (partesNome.length > 1) {
      nomeFormatado += ' ' + partesNome[partesNome.length - 1]
    }
    return nomeFormatado
  }
  return ''
}
