import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StoreIcon from '@material-ui/icons/Store'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import ReceiptLongIcon from 'support/components/icon/ReceiptLong'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import MunicipioIBGEInputDialogSelect from 'support/components/localizacao/MunicipioIBGEInputDialogSelect'
import UfIBGEInputDialogSelect from 'support/components/localizacao/UfIBGEInputDialogSelect'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { CNPJMask } from 'support/util/Masks'
import { CEPMask, InputStringNumberFormat } from 'support/util/Masks'
import { InputPercentFormat } from 'support/util/Masks'
import { InputTelefone } from 'support/components/input/InputTelefone'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

const INPUT_CUSTOM_VARIANT = 'outlined-small'

class ConfiguracoesGeraisNfDialogPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      errorsMap: createErrorsMap(),
      ajaxing: false
    }
    this.ufIbgeFunctionsMap = {}
    this.municipioIbgeFunctionsMap = {}
    this.inputRazaoSocial = React.createRef()
    this.inputNomeFantasia = React.createRef()
    this.inputCNPJ = React.createRef()
    this.inputInscricaoMunicipal = React.createRef()
    this.inputTelefone = React.createRef()
    this.inputEmail = React.createRef()
    this.inputLogradouro = React.createRef()
    this.inputNumeroEndereco = React.createRef()
    this.inputComplementoEndereco = React.createRef()
    this.inputBairro = React.createRef()
    this.inputCep = React.createRef()
    this.optanteSimplesFunctionMap = {}
    this.incentivadorCulturalFunctionMap = {}
    this.nfsCodigoServicoFunctionMap = {}
    this.regimeEspecialTributacaoFunctionMap = {}
    this.inputNfsAliquotaIss = React.createRef()
    this.inputNfsAliquotaAproximada = React.createRef()
    this.inputNumeroRps = React.createRef()
    this.inputSerieRps = React.createRef()
    this.inputNumeroLote = React.createRef()
    this.nfsEnviaEmailClienteFunctionMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/negocios.buscarDadosParaConfiguracaoNf',
      requerAutorizacao: true,
      params: {
        carregarDadosPersistidos: true,
        carregarCaracteristicasPrefeitura: false,
        carregarNfsCodigosServicos: false
      },
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const { negocioDadosPj, negocioConfiguracaoNf, empresaEnotas } = response.data

        const newState = {
          negocioDadosPj: negocioDadosPj,
          negocioConfiguracaoNf: negocioConfiguracaoNf,
          empresaEnotas: empresaEnotas
        }

        if (negocioDadosPj && negocioDadosPj.codigoIbgeCidade && negocioDadosPj.codigoIbgeUf) {
          newState.uf = {
            id: negocioDadosPj.codigoIbgeUf,
            sigla: negocioDadosPj.siglaUf
          }
          newState.municipio = {
            id: negocioDadosPj.codigoIbgeCidade,
            nome: negocioDadosPj.nomeCidade
          }
        }

        this.setState(newState)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarMainNivel2 = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/negocios.buscarDadosParaConfiguracaoNf',
      requerAutorizacao: true,
      params: {
        carregarDadosPersistidos: false,
        carregarCaracteristicasPrefeitura: true,
        carregarNfsCodigosServicos: true,
        codigoIbgeMunicipio: this.state.municipio.id
      },
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          caracteristicasPrefeitura: response.data.caracteristicasPrefeitura,
          nfsCodigosServicos: response.data.nfsCodigosServicos
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    const { municipio, uf, caracteristicasPrefeitura } = this.state

    const dados = {}
    dados.razaoSocial = this.inputRazaoSocial.current.value
    dados.nomeFantasia = this.inputNomeFantasia.current.value
    dados.cnpj = this.inputCNPJ.current.value
    dados.inscricaoMunicipal = this.inputInscricaoMunicipal.current.value
    dados.telefone = this.inputTelefone.current.value
    dados.email = this.inputEmail.current.value
    dados.logradouro = this.inputLogradouro.current.value
    dados.numeroEndereco = this.inputNumeroEndereco.current.value
    dados.complementoEndereco = this.inputComplementoEndereco.current.value
    dados.bairro = this.inputBairro.current.value
    dados.cep = this.inputCep.current.value
    dados.nomeCidade = municipio.nome
    dados.codigoIbgeCidade = municipio.id
    dados.siglaUf = uf.sigla
    dados.codigoIbgeUf = uf.id
    dados.optanteSimples = this.optanteSimplesFunctionMap.getValue()
    dados.incentivadorCultural = this.incentivadorCulturalFunctionMap.getValue()

    dados.nfsCodigoServico = { id: this.nfsCodigoServicoFunctionMap.getValue() }

    if (caracteristicasPrefeitura && caracteristicasPrefeitura.regimesEspecialTributacao && caracteristicasPrefeitura.regimesEspecialTributacao.length) {
      dados.regimeEspecialTributacao = this.regimeEspecialTributacaoFunctionMap.getValue()
    }

    dados.nfsAliquotaIss = FormUtil.convertStringToNumber(this.inputNfsAliquotaIss.current.value)
    dados.nfsAliquotaAproximada = FormUtil.convertStringToNumber(this.inputNfsAliquotaAproximada.current.value)

    dados.numeroRps = FormUtil.convertStringToNumber(this.inputNumeroRps.current.value)
    dados.serieRps = this.inputSerieRps.current.value
    dados.numeroLote = FormUtil.convertStringToNumber(this.inputNumeroLote.current.value)

    dados.nfsEnviarEmailCliente = this.nfsEnviaEmailClienteFunctionMap.getValue()
    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState({
      ajaxing: true,
      errorsMap: createErrorsMap()
    })

    postAPI({
      url: 'erp/negocios.salvarConfiguracoesGeraisNf',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('AtualizarSessao', {
          callback: () => {
            EventsManager.pub('ManipulacaoConfiguracoesGeraisNf')
            this.props.handleCloseDialog()
          }
        })
      },
      onError: (response) => {
        this.setState({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) })
        if (response.data.errors && response.data.errors['persistenciaEmpresa']) {
          openBackableDialog(MessageDialog, {
            text: response.data.errors['persistenciaEmpresa']
          })
        } else {
          focusFirstElementWithError('formConfiguracoesNf')
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { municipio, caracteristicasPrefeitura, nfsCodigosServicos, ajaxing, errorsMap } = this.state
    let {
      razaoSocial,
      nomeFantasia,
      cnpj,
      inscricaoMunicipal,
      telefone,
      email,
      logradouro,
      numeroEndereco,
      complementoEndereco,
      bairro,
      cep,
      optanteSimples,
      incentivadorCultural
    } = this.state.negocioDadosPj ? this.state.negocioDadosPj : {}
    let { regimeEspecialTributacao, sequencialNFe, serieNFe, sequencialLoteNFe } = this.state.empresaEnotas ? this.state.empresaEnotas : {}
    let { nfsCodigoServico, nfsAliquotaIss, nfsAliquotaAproximada, nfsEnviarEmailCliente } = this.state.negocioConfiguracaoNf ? this.state.negocioConfiguracaoNf : {}

    let municipioSelecionado = false
    let prefeituraHomologada = false
    let podeSalvar = false

    if (caracteristicasPrefeitura) {
      prefeituraHomologada = true
    }

    if (municipio) {
      municipioSelecionado = true
    }

    if (municipio && caracteristicasPrefeitura) {
      podeSalvar = true
    }

    if (!optanteSimples && optanteSimples !== false) {
      optanteSimples = true
    }

    if (!incentivadorCultural && incentivadorCultural !== false) {
      incentivadorCultural = false
    }

    if (!nfsEnviarEmailCliente && nfsEnviarEmailCliente !== false) {
      nfsEnviarEmailCliente = true
    }

    let ufDefaultValue = null
    let municipioDefaultValue = null
    if (this.state.uf) {
      ufDefaultValue = { id: this.state.uf.id, nome: this.state.uf.sigla }
    }
    if (this.state.municipio) {
      municipioDefaultValue = { id: this.state.municipio.id, nome: this.state.municipio.nome }
    }

    return (
      <DialogPage
        {...dialogProps}
        title={'Configurações'}
        align="center"
        contentMaxWidth={520}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              label: 'Salvar',
              disabled: ajaxing,
              show: podeSalvar,
              handleAction: () => this.salvar()
            }
          ]
        }}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => {
            return (
              <form id="formConfiguracoesNf" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                <Typography variant="body1">Selecione o estado e município da empresa</Typography>

                <UfIBGEInputDialogSelect
                  disabled={ajaxing}
                  defaultValue={ufDefaultValue}
                  functionsMap={this.ufIbgeFunctionsMap}
                  label="Estado (UF)"
                  customVariant={INPUT_CUSTOM_VARIANT}
                  shrink={true}
                  errorMessage={errorsMap('uf')}
                  onChange={(result) => {
                    if (!result) {
                      this.setState({ uf: null, municipio: null })
                    } else if (!this.state.uf || this.state.uf.id !== result.uf.id) {
                      this.setState({ uf: result.uf, municipio: null })
                    }
                  }}
                  style={{ marginTop: 32 }}
                />

                <MunicipioIBGEInputDialogSelect
                  key={this.state.uf ? this.state.uf.id : 0}
                  marginTop={true}
                  disabled={!this.state.uf || ajaxing ? true : false}
                  uf={this.state.uf}
                  defaultValue={municipioDefaultValue}
                  functionsMap={this.municipioIbgeFunctionsMap}
                  label="Município"
                  customVariant={INPUT_CUSTOM_VARIANT}
                  shrink={true}
                  errorMessage={errorsMap('municipio')}
                  onChange={(result) => {
                    if (result) {
                      this.setState({ municipio: result.municipio })
                    } else {
                      this.setState({ municipio: null })
                    }
                  }}
                />

                {!municipioSelecionado && <div style={{ marginBottom: 10 }}></div>}

                {municipioSelecionado && (
                  <ContentWithPreload key={municipio.id} loadFunction={this.carregarMainNivel2} loadContentPadding={true}>
                    {podeSalvar && (
                      <React.Fragment>
                        <SectionSeparator icon={<StoreIcon />} title="Cadastro Fiscal da Empresa" />

                        <InputText
                          idname="xrazaosocial"
                          customVariant={INPUT_CUSTOM_VARIANT}
                          shrink={false}
                          defaultValue={razaoSocial}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('razaoSocial')}
                          label="Razão Social"
                          inputRef={this.inputRazaoSocial}
                        />
                        <InputText
                          marginTop={true}
                          idname="xnomefantasia"
                          customVariant={INPUT_CUSTOM_VARIANT}
                          shrink={false}
                          defaultValue={nomeFantasia}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('nomeFantasia')}
                          label="Nome Fantasia"
                          inputRef={this.inputNomeFantasia}
                        />

                        <InputText
                          marginTop={true}
                          idname="xcnpj"
                          customVariant={INPUT_CUSTOM_VARIANT}
                          shrink={false}
                          defaultValue={cnpj}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('cnpj')}
                          label="CNPJ"
                          inputComponent={CNPJMask}
                          inputRef={this.inputCNPJ}
                        />

                        <InputText
                          marginTop={true}
                          idname="xinscricaoMunicipal"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={inscricaoMunicipal}
                          inputComponent={InputStringNumberFormat}
                          inputProps={{ maxLength: 20 }}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('inscricaoMunicipal')}
                          label="Inscrição Municipal"
                          helpMessage="Preencha apenas com números"
                          inputRef={this.inputInscricaoMunicipal}
                        />
                        
                        <InputTelefone
                          label="Telefone Comercial"
                          placeholder="(00) 0000-0000"
                          inputVariant='outlined-small'
                          defaultPhone={telefone}
                          phoneRef={this.inputTelefone}
                          error={errorsMap('numeroCelular')}
                          ajaxing={this.state.ajaxing}
                        />

                        <InputText
                          marginTop={true}
                          idname="xemail"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={email}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('email')}
                          label="E-mail"
                          inputRef={this.inputEmail}
                        />

                        <InputText
                          marginTop={true}
                          idname="xlogradouro"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={logradouro}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('logradouro')}
                          label="Logradouro"
                          inputRef={this.inputLogradouro}
                        />

                        <InputText
                          marginTop={true}
                          idname="xnumeroendereco"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={numeroEndereco}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('numeroEndereco')}
                          label="Número do Endereço"
                          inputProps={{ maxLength: 10 }}
                          inputRef={this.inputNumeroEndereco}
                        />

                        <InputText
                          marginTop={true}
                          idname="xcomplementoendereco"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={complementoEndereco}
                          inputProps={{ maxLength: 30 }}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('complementoEndereco')}
                          label="Complemento do Endereço"
                          inputRef={this.inputComplementoEndereco}
                        />

                        <InputText
                          marginTop={true}
                          idname="xbairro"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={bairro}
                          inputProps={{ maxLength: 25 }}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('bairro')}
                          label="Bairro"
                          inputRef={this.inputBairro}
                        />

                        <InputText
                          marginTop={true}
                          idname="xcep"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={cep}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('cep')}
                          label="CEP"
                          inputComponent={CEPMask}
                          inputRef={this.inputCep}
                        />

                        <InputSelect
                          marginTop={true}
                          idname="xoptanteSimples"
                          customVariant="outlined-small"
                          shrink={false}
                          label="Optante pelo Simples"
                          defaultValue={optanteSimples}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('optanteSimples')}
                          functionsMap={this.optanteSimplesFunctionMap}
                        >
                          <MenuItem value={true}>Sim</MenuItem>
                          <MenuItem value={false}>Não</MenuItem>
                        </InputSelect>

                        <InputSelect
                          marginTop={true}
                          idname="xincentivadorcultural"
                          customVariant="outlined-small"
                          shrink={false}
                          label="Incentivador Cultural"
                          defaultValue={incentivadorCultural}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('incentivadorCultural')}
                          functionsMap={this.incentivadorCulturalFunctionMap}
                        >
                          <MenuItem value={true}>Sim</MenuItem>
                          <MenuItem value={false}>Não</MenuItem>
                        </InputSelect>
                      </React.Fragment>
                    )}

                    <SectionSeparator icon={<ReceiptLongIcon />} title="Configuração NFS-e" />

                    {!prefeituraHomologada && (
                      <Typography variant="body2">Esta prefeitura ainda não foi homologada e não é possível emitir notas fiscais de serviço (NFS-e) pelo sistema</Typography>
                    )}

                    {prefeituraHomologada && (
                      <React.Fragment>
                        <InputSelect
                          idname="xcodigoservico"
                          customVariant="outlined-small"
                          shrink={false}
                          label="Serviço Padrão"
                          defaultValue={nfsCodigoServico ? nfsCodigoServico.id : null}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('nfsCodigoServico')}
                          functionsMap={this.nfsCodigoServicoFunctionMap}
                        >
                          {nfsCodigosServicos &&
                            nfsCodigosServicos.length > 0 &&
                            nfsCodigosServicos.map((nfsCodigoServico) => (
                              <MenuItem key={nfsCodigoServico.id} value={nfsCodigoServico.id} style={{ fontSize: 14 }}>
                                {nfsCodigoServico.descricao}
                              </MenuItem>
                            ))}
                        </InputSelect>

                        {caracteristicasPrefeitura && caracteristicasPrefeitura.regimesEspecialTributacao && caracteristicasPrefeitura.regimesEspecialTributacao.length && (
                          <InputSelect
                            idname="xregimeespecialtributacao"
                            customVariant="outlined-small"
                            shrink={false}
                            marginTop={true}
                            label="Regime Especial Tributacao"
                            defaultValue={regimeEspecialTributacao}
                            disabled={this.state.ajaxing}
                            errorMessage={errorsMap('regimeEspecialTributacao')}
                            functionsMap={this.regimeEspecialTributacaoFunctionMap}
                          >
                            {caracteristicasPrefeitura.regimesEspecialTributacao.map((regime) => (
                              <MenuItem key={regime.codigo} value={regime.codigo}>
                                {regime.nome}
                              </MenuItem>
                            ))}
                          </InputSelect>
                        )}

                        <InputText
                          marginTop={true}
                          idname="xaliquotaiss"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={nfsAliquotaIss}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('nfsAliquotaIss')}
                          label="Aliquota ISS (%)"
                          inputComponent={InputPercentFormat}
                          inputProps={{ maxValue: 100 }}
                          inputRef={this.inputNfsAliquotaIss}
                        />

                        <InputText
                          marginTop={true}
                          idname="xaliquotaaproximada"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={nfsAliquotaAproximada}
                          inputComponent={InputPercentFormat}
                          inputProps={{ maxValue: 100 }}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('nfsAliquotaAproximada')}
                          label="Alíquota total aproximada dos impostos (%)"
                          staticHelpMessage="Informe a alíquota total aproximada dos impostos. Esse campo será utilizado para informar ao cliente o valor aproximado pago de tributos na nota fiscal."
                          inputRef={this.inputNfsAliquotaAproximada}
                        />

                        <InputText
                          marginTop={true}
                          idname="xnumerorps"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={sequencialNFe}
                          inputComponent={InputStringNumberFormat}
                          inputProps={{ maxLength: 15 }}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('numeroRps')}
                          label="Número do próximo RPS"
                          staticHelpMessage="Informe o próximo número RPS sequencial. Se, por exemplo, a última nota emitida com RPS utilizou o número '50', informe o número '51'"
                          inputRef={this.inputNumeroRps}
                        />

                        <InputText
                          marginTop={true}
                          idname="xserierps"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={serieNFe}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('serieRps')}
                          label="Série do RPS"
                          inputRef={this.inputSerieRps}
                        />

                        <InputText
                          marginTop={true}
                          idname="xnumerolote"
                          customVariant="outlined-small"
                          shrink={false}
                          defaultValue={sequencialLoteNFe}
                          inputComponent={InputStringNumberFormat}
                          inputProps={{ maxLength: 15 }}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('numeroLote')}
                          label="Número do lote atual"
                          inputRef={this.inputNumeroLote}
                        />

                        <InputSelect
                          marginTop={true}
                          idname="xenviaemailcliente"
                          customVariant="outlined-small"
                          shrink={false}
                          label="Envia NF por E-mail para o cliente?"
                          defaultValue={nfsEnviarEmailCliente}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('nfsEnviaEmailCliente')}
                          functionsMap={this.nfsEnviaEmailClienteFunctionMap}
                        >
                          <MenuItem value={true}>Sim</MenuItem>
                          <MenuItem value={false}>Não</MenuItem>
                        </InputSelect>
                      </React.Fragment>
                    )}

                    {podeSalvar && (
                      <div className={classes.containerFooterButtons}>
                        <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                          Cancelar
                        </Button>
                        <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                          {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                        </Button>
                      </div>
                    )}
                  </ContentWithPreload>
                )}
              </form>
            )
          }}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesGeraisNfDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesGeraisNfDialogPage)
