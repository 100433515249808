import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  containerIcon: {
    paddingTop: 5
  },
  containerIconOutlinedSmall: {
    paddingTop: 11
  }
})

class InputsHorizontalContainer extends Component {
  render() {
    const { classes } = this.props

    let opacityIcon = 1
    let opacityInputText = 1

    if (this.props.disabled) {
      opacityIcon = 0.2
      opacityInputText = 0.8
    }

    let iconContainerClass = null
    if (this.props.customVariant === 'outlined-small') {
      iconContainerClass = classes.containerIconOutlinedSmall
    } else {
      iconContainerClass = classes.containerIcon
    }

    return (
      <Grid container alignItems="flex-start" spacing={2} style={this.props.style}>
        {this.props.icon && (
          <Grid item style={{ opacity: opacityIcon }}>
            <div className={iconContainerClass}>{this.props.icon}</div>
          </Grid>
        )}
        <Grid item xs style={{ opacity: opacityInputText }}>
          <Grid container spacing={2} alignItems="flex-start">
            {this.props.inputTexts.map((inputItem, index) => {
              let containerProps = inputItem.containerProps
              const key = inputItem.key ? inputItem.key : index
              if (!containerProps) {
                containerProps = {
                  xs: true
                }
              }
              return (
                <Grid key={key} item {...containerProps} style={{ opacity: opacityInputText, ...containerProps.style }}>
                  {inputItem.input}
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

InputsHorizontalContainer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputsHorizontalContainer)
