import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  NAO_GERAR_COMISSAO: { id: 4, descricaoParaColaborador: 'Não gerar comissão', descricaoParaServico: 'Não gerar comissão' },
  SEM_COMISSAO: { id: 1, descricaoParaColaborador: 'Usar Comissão do Serviço', descricaoParaServico: 'Usar Comissão do Colaborador' },
  SIMPLES: { id: 2, descricaoParaColaborador: 'Comissão Simples', descricaoParaServico: 'Comissão Simples' },
  AVANCADA: { id: 3, descricaoParaColaborador: 'Comissão Avançada', descricaoParaServico: 'Comissão Avançada' }
})

export default enumObject
