import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import moment from 'moment'
import AgendaItensLoader from 'pages/erp/painel/atendimento/agendainterface/AgendaItensLoader'
import ItemCard from 'pages/erp/painel/atendimento/agendainterface/ItemCard'
import AgendaItensScrollbars from 'pages/erp/painel/atendimento/agendainterface/scrollbars/AgendaItensScrollbars'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'

const BORDER_COLOR = '#e9e9e9'

const styles = (theme) => ({
  containerRoot: {
    display: 'flex',
    flexGrow: 9999,
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    marginTop: -7,
    zIndex: 5
  },
  containerHeader: {
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    '& table': {
      width: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse'
    },
    '& table tr td': {
      textAlign: 'center',
      verticalAlign: 'top',
      fontSize: 12
    }
  },
  containerBody: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    '& table': {
      width: '100%',
      minHeight: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse'
    },
    '& table tr td': {
      padding: 1,
      textAlign: 'center',
      verticalAlign: 'top',
      border: '1px solid ' + BORDER_COLOR,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f6f6f6'
      }
    }
  },
  gridHeader: {
    padding: '10px 0px 10px',
    [theme.breakpoints.down(420)]: {
      padding: '5px 0px 6px'
    }
  },
  gridHeaderSemana: {
    fontSize: 12
  },
  gridHeaderDia: {
    marginTop: 2,
    fontSize: 12,
    fontWeight: 500
  },
  gridHeaderDiaHoje: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 58,
    padding: 4,
    [theme.breakpoints.down(420)]: {
      padding: 3
    }
  }
})

class AgendaGridMensal extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.agendaItensScrollbarsFM = {}
  }

  componentDidMount() {
    this.agendaItensScrollbarsFM.getScrollRef().scrollTop(this.colaboradorLocalPreferences.getPreference('AgendaInterface_GridMensalScrollY', 0))
  }

  gerarDadosGrid = () => {
    const primeiroDiaMes = this.props.dataSelecionada.clone().startOf('month')
    const primeiroDiaMesAsInt = converterMomentParaDataInt(primeiroDiaMes)
    const dataBase = primeiroDiaMes.clone().subtract(parseInt(primeiroDiaMes.format('d'), 10), 'days')
    const ultimoDiaMes = this.props.dataSelecionada.clone().endOf('month')
    const ultimoDiaMesAsInt = converterMomentParaDataInt(ultimoDiaMes)
    const semanas = []
    const hoje = moment()

    while (true) {
      const semana = []
      for (let i = 0; i <= 6; i++) {
        if (this.props.diasSemana[parseInt(dataBase.format('d'), 10)]._show) {
          let dataAsInt = converterMomentParaDataInt(dataBase.clone())

          let isHoje = false
          if (hoje.isSame(dataBase, 'day')) {
            isHoje = true
          }

          let dentroMes = true
          if (dataAsInt < primeiroDiaMesAsInt || dataAsInt > ultimoDiaMesAsInt) {
            dentroMes = false
          }
          semana.push({
            dataAsInt: dataAsInt,
            isHoje: isHoje,
            dentroMes: dentroMes,
            descricaoSemana: dataBase.format('ddd'),
            descricaoDia: dataBase.format('DD')
          })
        }

        dataBase.add(1, 'days')
      }
      semanas.push(semana)
      if (dataBase.isAfter(ultimoDiaMes, 'day')) {
        break
      }
    }
    return {
      primeiroDiaMesAsInt: primeiroDiaMesAsInt,
      ultimoDiaMesAsInt: ultimoDiaMesAsInt,
      semanas: semanas
    }
  }

  render() {
    const { classes } = this.props

    const headerHeight = 60

    const dadosGrid = this.gerarDadosGrid()

    let agendaAtendimentosKey = converterMomentParaDataInt(this.props.dataSelecionada)
    for (let colaborador of this.props.colaboradores) {
      if (colaborador._show) {
        agendaAtendimentosKey += ',' + colaborador.id
      }
    }
    agendaAtendimentosKey += ',p:' + this.props.perspectiva.id
    agendaAtendimentosKey += ',v:' + this.props.visao.id

    if (this.props.colaboradorSelecionado) {
      agendaAtendimentosKey += ',cs:' + this.props.colaboradorSelecionado.id
    }
    for (let diaSemana of this.props.diasSemana) {
      if (diaSemana._show) {
        agendaAtendimentosKey += ',' + diaSemana.id
      }
    }

    return (
      <div className={classes.containerRoot}>
        <div className={classes.containerHeader} style={{ height: headerHeight }}>
          <div style={{ left: this.props.toolsExpanded ? -37 : -30, top: 20, position: 'absolute' }}>
            <IconButton
              style={{ padding: 6 }}
              onClick={() => {
                this.props.changeToolsExpanded(!this.props.toolsExpanded)
              }}
            >
              {this.props.toolsExpanded && <ChevronLeftIcon style={{ display: 'block' }} />}
              {!this.props.toolsExpanded && <ChevronRightIcon style={{ display: 'block' }} />}
            </IconButton>
          </div>
          <div>
            <Typography variant="body1" style={{ fontSize: 18, marginBottom: 6 }}>
              <b>{this.props.dataSelecionada.format('MMMM/YYYY')}</b>
            </Typography>
          </div>
          <table cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                {this.props.diasSemana
                  .filter((diaSemana) => diaSemana._show)
                  .map((diaSemana) => (
                    <td key={diaSemana.id}>{diaSemana.nomeResumido}</td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.containerBody} style={{ height: 'calc(100% - ' + headerHeight + 'px)' }}>
          <AgendaItensLoader
            loadKey={agendaAtendimentosKey}
            dataInicial={dadosGrid.semanas[0][0].dataAsInt}
            dataFinal={dadosGrid.semanas.slice(-1).pop().slice(-1).pop().dataAsInt}
            colaboradorSelecionado={this.props.colaboradorSelecionado}
            perspectiva={this.props.perspectiva}
            visao={this.props.visao}
            colaboradores={this.props.colaboradores}
          >
            {(atendimentosProps) => {
              const itensAgrupadosPorData = {}

              if (atendimentosProps.itens && atendimentosProps.itens.length > 0) {
                for (let item of atendimentosProps.itens) {
                  let grupo = itensAgrupadosPorData[item.data]
                  if (!grupo) {
                    grupo = []
                    itensAgrupadosPorData[item.data] = grupo
                  }
                  grupo.push(item)
                }
              }

              return (
                <AgendaItensScrollbars
                  functionsMap={this.agendaItensScrollbarsFM}
                  onScrollStop={(x, y) => {
                    this.colaboradorLocalPreferences.setPreference('AgendaInterface_GridMensalScrollY', this.agendaItensScrollbarsFM.getScrollRef().getScrollTop())
                  }}
                >
                  <table cellPadding={0} cellSpacing={0}>
                    <tbody>
                      {dadosGrid.semanas.map((semana, index) => (
                        <tr key={index}>
                          {semana.map((data, index) => {
                            const itens = itensAgrupadosPorData[data.dataAsInt]
                            let opacityHeader = 1
                            let opacityBody = 1
                            if (!data.dentroMes) {
                              opacityHeader = 0.35
                              opacityBody = 1.0
                            }

                            let itemsContent = []
                            if (itens) {
                              itemsContent = itens.map((item) =>
                                item._instancias.map((instancia, index) => (
                                  <ItemCard
                                    key={item.id}
                                    perspectiva={this.props.perspectiva}
                                    regraCorCard={this.props.regraCorCard}
                                    itens={atendimentosProps.itens}
                                    item={item}
                                    itemInstancia={instancia}
                                    colaboradorSelecionado={this.props.colaboradorSelecionado}
                                  />
                                ))
                              )
                            }

                            return (
                              <td
                                key={index}
                                onClick={() => {
                                  EventsManager.pub('AlterarPerspectivaAgenda', {
                                    dataSelecionada: converterDataIntParaMoment(data.dataAsInt),
                                    perspectiva: PerspectivaEnum.DIA
                                  })
                                }}
                              >
                                <div className={classes.gridHeader} style={{ opacity: opacityHeader }}>
                                  <div className={classes.gridHeaderDia}>
                                    <span className={data.isHoje ? classes.gridHeaderDiaHoje : null}>{data.descricaoDia}</span>
                                  </div>
                                </div>
                                <div style={{ opacity: opacityBody }}>
                                  {itemsContent.length > 0 && itemsContent.slice(0, this.props.numeroMaximoExibicaoAtendimentosMes)}
                                  {itemsContent.length > this.props.numeroMaximoExibicaoAtendimentosMes && <MoreHorizIcon />}
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AgendaItensScrollbars>
              )
            }}
          </AgendaItensLoader>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AgendaGridMensal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AgendaGridMensal)
