import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'

const styles = (theme) => ({
  colunaQuantidade: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaCliente: {
    flexGrow: 1
  },
  colunaServico: {
    flexGrow: 1
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

export const VisaoEnum = createEnum({
  CLIENTE: { id: 1, descricao: 'Visualizar por cliente' },
  SERVICO: { id: 2, descricao: 'Visualizar por serviço' }
})

class ServicosPacotesRealizadosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      visao: VisaoEnum.CLIENTE,
      ajaxing: false
    }
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.quantidade = item.quantidade
    content.descricao = item.descricao

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { visao } = this.state
    let endPoint = null
    let largeRenderProps = undefined
    let mediumRenderProps = undefined
    let smallRenderProps = undefined
    let quantidadeTotal = 0

    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    if (visao.id === VisaoEnum.CLIENTE.id) {
      endPoint = 'erp/relatorios.buscarClientesComServicosPacotesRealizadosPorData'

      largeRenderProps = {
        columns: [
          { label: 'Cliente', className: classes.colunaCliente, props: { xs: true } },
          { label: 'Quantidade', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('large', item)

          return {
            itemData: [content.descricao, content.quantidade]
          }
        }
      }

      mediumRenderProps = largeRenderProps

      smallRenderProps = {
        rowHeight: 80,
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('small', item)

          return {
            itemData: (
              <React.Fragment>
                <Typography variant="body2" className={classes.descricaoContainerSmall}>
                  <b>{content.descricao}</b>
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  Quantidade: {content.quantidade}
                </Typography>
              </React.Fragment>
            )
          }
        }
      }

      for (let item of this.state.lista) {
        quantidadeTotal = quantidadeTotal + item.quantidade
      }
    } else {
      endPoint = 'erp/relatorios.buscarServicosPacotesRealizadosPorData'

      largeRenderProps = {
        columns: [
          { label: 'Serviço', className: classes.colunaServico, props: { xs: true } },
          { label: 'Quantidade', className: classes.colunaQuantidade, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('large', item)

          return {
            itemData: [content.descricao, content.quantidade]
          }
        }
      }

      mediumRenderProps = largeRenderProps

      smallRenderProps = {
        rowHeight: 80,
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('small', item)

          return {
            itemData: (
              <React.Fragment>
                <Typography variant="body2" className={classes.descricaoContainerSmall}>
                  <b>{content.descricao}</b>
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  Quantidade: {content.quantidade}
                </Typography>
              </React.Fragment>
            )
          }
        }
      }

      for (let item of this.state.lista) {
        quantidadeTotal = quantidadeTotal + item.quantidade
      }
    }

    return (
      <DialogPage {...dialogProps} title="Detalhes" align="center" pageType="basicEntity" ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage">
              <Typography variant="h5">Serviços Realizados</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
              <InputSelect
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.visao.id}
                fullWidth={false}
                onChange={(event) => {
                  const visao = VisaoEnum.getById(parseInt(event.target.value, 10))
                  this.setState({ visao: visao })
                }}
              >
                {VisaoEnum.values().map((visao) => (
                  <MenuItem key={visao.id} value={visao.id}>
                    {visao.descricao}
                  </MenuItem>
                ))}
              </InputSelect>
            </FloatContent>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              key={visao.id}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              itemsPerRequest={30}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint={endPoint}
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={largeRenderProps}
              mediumRenderProps={mediumRenderProps}
              smallRenderProps={smallRenderProps}
              emptyListProps={{
                text: 'Não existem valores em aberto para o período informado'
              }}
            />

            {quantidadeTotal > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  Quantidade: <b>{quantidadeTotal}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

ServicosPacotesRealizadosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServicosPacotesRealizadosDialogPage)
