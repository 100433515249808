import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import PermissoesAcesso from 'support/domain/admin/usuario/PermissoesAcesso'
import EmissaoNfEnum from 'support/domain/venda/EmissaoNfEnum'
import OpcaoEmissaoNfFechamentoNotaEnum from 'support/domain/venda/OpcaoEmissaoNfFechamentoNotaEnum'
import RegraDataCompetenciaEnum from 'support/domain/venda/RegraDataCompetenciaEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ConfiguracoesGeraisDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Configurações NF',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.contentWithPreloadFunctions = {}
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/negocio.buscarConfiguracoesNf',
      params: {
        idNegocio: this.props.negocio.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const configuracaoGeral = response.data.configuracaoGeral
        const configuracaoNf = response.data.configuracaoNf ? response.data.configuracaoNf : {}

        this.setState({
          emissaoNf: configuracaoGeral.emissaoNf,
          configuracaoNf: configuracaoNf,
          opcaoEmissaoNfFechamentoNota: configuracaoNf.opcaoEmissaoNfFechamentoNota,
          nfsRegraDataCompetencia: configuracaoNf.nfsRegraDataCompetencia
        })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  desabilitarEmissaoNf = () => {
    if (!verificarAutorizacao([PermissoesAcesso.PODE_ALTERAR_CONFIGURACOES_NF])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    postAPI({
      url: 'admin/negocios.desabilitarEmissaoNf',
      data: {
        idNegocio: this.props.negocio.id
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.contentWithPreloadFunctions.reload()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  habilitarEmissaoNf = () => {
    if (!verificarAutorizacao([PermissoesAcesso.PODE_ALTERAR_CONFIGURACOES_NF])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    postAPI({
      url: 'admin/negocios.habilitarEmissaoNf',
      data: {
        idNegocio: this.props.negocio.id
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.contentWithPreloadFunctions.reload()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  salvar = () => {
    if (!verificarAutorizacao([PermissoesAcesso.PODE_ALTERAR_CONFIGURACOES_NF])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    postAPI({
      url: 'admin/negocios.alterarConfiguracaoNF',
      data: {
        idNegocio: this.props.negocio.id,
        emissaoNfsVendaPacote: this.state.configuracaoNf.emissaoNfsVendaPacote,
        emissaoNfsUsoPacote: this.state.configuracaoNf.emissaoNfsUsoPacote,
        opcaoEmissaoNfFechamentoNota: this.state.opcaoEmissaoNfFechamentoNota.id,
        nfsRegraDataCompetencia: this.state.nfsRegraDataCompetencia.id,
        nfsAplicarRegraSalaoParceiro: this.state.configuracaoNf.nfsAplicarRegraSalaoParceiro,
        nfsAplicarDeducaoSalaoParceiro: this.state.configuracaoNf.nfsAplicarDeducaoSalaoParceiro
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formConfigNF')
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { emissaoAtiva, emissaoNfsVendaPacote, emissaoNfsUsoPacote, nfsAplicarRegraSalaoParceiro, nfsAplicarDeducaoSalaoParceiro } = this.state.configuracaoNf
      ? this.state.configuracaoNf
      : {}

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain} functionsMap={this.contentWithPreloadFunctions}>
          {() => (
            <React.Fragment>
              <Button
                disabled={this.state.ajaxing}
                variant="contained"
                color="secondary"
                onClick={this.state.emissaoNf.id === EmissaoNfEnum.HABILITADO.id ? this.desabilitarEmissaoNf : this.habilitarEmissaoNf}
              >
                {this.state.emissaoNf.id === EmissaoNfEnum.HABILITADO.id ? 'Desabilitar Emissão Nf' : 'Habilitar Emissão Nf'}
              </Button>

              {this.state.emissaoNf && this.state.emissaoNf.id === EmissaoNfEnum.HABILITADO.id && (
                <form id="formConfigNF" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  <Grid container direction="column" alignItems="flex-start">
                    <Grid item>
                      <InputSelect
                        label="Emissão NF Fechamento Nota"
                        fullWidth={false}
                        marginTop={true}
                        customVariant="outlined-small"
                        shrink={false}
                        value={this.state.opcaoEmissaoNfFechamentoNota.id}
                        onChange={(event) => {
                          const id = event.target.value
                          this.setState({ opcaoEmissaoNfFechamentoNota: OpcaoEmissaoNfFechamentoNotaEnum.getById(id) })
                        }}
                      >
                        {OpcaoEmissaoNfFechamentoNotaEnum.values().map((opcao) => (
                          <MenuItem key={opcao.id} value={opcao.id}>
                            {opcao.descricaoOpcao}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    </Grid>

                    <Grid item>
                      <InputSelect
                        label="Regra Data Competência"
                        fullWidth={false}
                        marginTop={true}
                        customVariant="outlined-small"
                        shrink={false}
                        value={this.state.nfsRegraDataCompetencia.id}
                        onChange={(event) => {
                          const id = event.target.value
                          this.setState({ nfsRegraDataCompetencia: RegraDataCompetenciaEnum.getById(id) })
                        }}
                      >
                        {RegraDataCompetenciaEnum.values().map((opcao) => (
                          <MenuItem key={opcao.id} value={opcao.id}>
                            {opcao.descricaoOpcao}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    </Grid>

                    <Grid item>
                      <UncontrolledCheckbox
                        formControlProps={{
                          label: 'Emissão Ativa'
                        }}
                        defaultChecked={emissaoAtiva}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item>
                      <UncontrolledCheckbox
                        formControlProps={{
                          label: 'Emissão NFs Venda Pacote'
                        }}
                        defaultChecked={emissaoNfsVendaPacote}
                        onChange={(value) => {
                          this.setState((state) => {
                            state.configuracaoNf.emissaoNfsVendaPacote = value
                            return { configuracaoNf: state.configuracaoNf }
                          })
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <UncontrolledCheckbox
                        formControlProps={{
                          label: 'Emissão NFs Uso Pacote'
                        }}
                        defaultChecked={emissaoNfsUsoPacote}
                        onChange={(value) => {
                          this.setState((state) => {
                            state.configuracaoNf.emissaoNfsUsoPacote = value
                            return { configuracaoNf: state.configuracaoNf }
                          })
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <UncontrolledCheckbox
                        formControlProps={{
                          label: 'Aplicar Regra Salão Parceiro'
                        }}
                        defaultChecked={nfsAplicarRegraSalaoParceiro}
                        onChange={(value) => {
                          this.setState((state) => {
                            state.configuracaoNf.nfsAplicarRegraSalaoParceiro = value
                            return { configuracaoNf: state.configuracaoNf }
                          })
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <UncontrolledCheckbox
                        formControlProps={{
                          label: 'Aplicar Dedução Salão Parceiro'
                        }}
                        defaultChecked={nfsAplicarDeducaoSalaoParceiro}
                        onChange={(value) => {
                          this.setState((state) => {
                            state.configuracaoNf.nfsAplicarDeducaoSalaoParceiro = value
                            return { configuracaoNf: state.configuracaoNf }
                          })
                        }}
                      />
                    </Grid>
                  </Grid>

                  <HiddenSubmitButton />

                  {this.state.paginaCarregada && (
                    <div className={classes.containerFooterButtons}>
                      <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                        Cancelar
                      </Button>
                      <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                        {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                      </Button>
                    </div>
                  )}
                </form>
              )}
            </React.Fragment>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesGeraisDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesGeraisDialogPage)
