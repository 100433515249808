import { Component } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import BarChartIcon from '@material-ui/icons/BarChart'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import Description from '@material-ui/icons/DescriptionOutlined'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import PersonIcon from '@material-ui/icons/Person'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import StorageIcon from '@material-ui/icons/Storage'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import { User, withConfigCatClient } from 'configcat-react'
import NegocioSupport from 'pages/erp/painel/negocio/NegocioSupport'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import RelatorioAusenciaClientesDialogPage from 'pages/erp/painel/relatorio/RelatorioAusenciaClientesDialogPage'
import RelatorioClientesAtivosDialogPage from 'pages/erp/painel/relatorio/RelatorioClientesAtivosDialogPage'
import RelatorioClientesDialogPage from 'pages/erp/painel/relatorio/RelatorioClientesDialogPage'
import RelatorioColaboradoresDialogPage from 'pages/erp/painel/relatorio/RelatorioColaboradoresDialogPage'
import RelatorioDescontosDialogPage from 'pages/erp/painel/relatorio/RelatorioDescontosDialogPage'
import RelatorioDespesasDialogPage from 'pages/erp/painel/relatorio/RelatorioDespesasDialogPage'
import RelatorioEstoqueMovimentacoesDialogPage from 'pages/erp/painel/relatorio/RelatorioEstoqueMovimentacoesDialogPage'
import RelatorioFinanceiroDialogPage from 'pages/erp/painel/relatorio/RelatorioFinanceiroDialogPage'
import RelatorioFormasPagamentoDialogPage from 'pages/erp/painel/relatorio/RelatorioFormasPagamentoDialogPage'
import RelatorioPacotesDialogPage from 'pages/erp/painel/relatorio/RelatorioPacotesDialogPage'
import RelatorioProdutosDialogPage from 'pages/erp/painel/relatorio/RelatorioProdutosDialogPage'
import RelatorioServicosDialogPage from 'pages/erp/painel/relatorio/RelatorioServicosDialogPage'
import RelatorioVendasDialogPage from 'pages/erp/painel/relatorio/RelatorioVendasDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import ProductsIcon from 'support/components/icon/Products'
import PanelPage from 'support/components/panel/PanelPage'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { logCORE } from 'support/util/Logger'

import { NewRelatorioFinaceiroPage } from './NewRelatorioFinanceiroPage/NewRelatorioFinaceiroPage'

const styles = (theme) => ({})

class ConfiguracoesPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      isRelatorioFinanceiro: false
    }
  }

  componentDidMount() {
    this.evaluateRelatorioFinanceiroFeatureFlag()
  }

  evaluateRelatorioFinanceiroFeatureFlag() {
    try {
      const user = new User(SessaoUsuario.getUsuario()?.email)
      this.props.getValue('FT_GST_RELATORIOS_FINANCEIROS', false, user).then((value) => {
        this.setState({ isRelatorioFinanceiro: value })
      })
    } catch (error) {
      logCORE(error, { component: 'DrawerErp', context: 'evaluateRelatorioFinanceiroFeatureFlag' })
    }
  }

  abrirRelatorioDialog = (DialogPageComponent) => {
    NegocioSupport.tentarExibirAvisoImportante({
      onIgnore: () => {
        if (!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
          openBackableDialog(AccessDeniedDialog)
          return
        }
        openBackableDialog(DialogPageComponent)
      }
    })
  }

  abrirRelatorioFinanceiro = () => {
    const { isRelatorioFinanceiro } = this.state
    if (isRelatorioFinanceiro) {
      window.location.href = `${process.env.REACT_APP_MFE_ADDRESS}/relatorios`
      return
    }
    this.abrirRelatorioDialog(RelatorioFinanceiroDialogPage)
  }

  abrirRelatorioAtendimentoFinanceiro = () => {
    window.location.href = `${process.env.REACT_APP_MFE_ADDRESS}/relatorios/produtos-servicos`
  }

  abrirRelatorioVendas = () => {
    this.abrirRelatorioDialog(RelatorioVendasDialogPage)
  }

  abrirRelatorioDespesas = () => {
    this.abrirRelatorioDialog(RelatorioDespesasDialogPage)
  }

  abrirRelatorioDescontos = () => {
    this.abrirRelatorioDialog(RelatorioDescontosDialogPage)
  }

  abrirRelatorioServicos = () => {
    this.abrirRelatorioDialog(RelatorioServicosDialogPage)
  }

  abrirRelatorioProdutos = () => {
    this.abrirRelatorioDialog(RelatorioProdutosDialogPage)
  }

  abrirRelatorioPacotes = () => {
    this.abrirRelatorioDialog(RelatorioPacotesDialogPage)
  }

  abrirRelatorioFormasPagamento = () => {
    this.abrirRelatorioDialog(RelatorioFormasPagamentoDialogPage)
  }

  abrirRelatorioColaboradores = () => {
    this.abrirRelatorioDialog(RelatorioColaboradoresDialogPage)
  }

  abrirRelatorioEstoqueMovimentacao = () => {
    this.abrirRelatorioDialog(RelatorioEstoqueMovimentacoesDialogPage)
  }

  abrirRelatorioClientes = () => {
    this.abrirRelatorioDialog(RelatorioClientesDialogPage)
  }

  abrirRelatorioAusenciaClientes = () => {
    this.abrirRelatorioDialog(RelatorioAusenciaClientesDialogPage)
  }

  abrirRelatorioClientesAtivos = () => {
    this.abrirRelatorioDialog(RelatorioClientesAtivosDialogPage)
  }

  render() {
    const { classes, ...others } = this.props
    const { isRelatorioFinanceiro } = this.state

    return (
      <PanelPage {...others} contentMaxWidth={860} title="Relatórios">
        {isRelatorioFinanceiro ? (
          <NewRelatorioFinaceiroPage
            onOpenFinancialReport={this.abrirRelatorioFinanceiro}
            onOpenServiceReport={this.abrirRelatorioAtendimentoFinanceiro}
            onOpenCollaboratorsReport={this.abrirRelatorioColaboradores}
            onOpenCustomersReport={this.abrirRelatorioClientes}
            onOpenCustomersAwaysReport={this.abrirRelatorioAusenciaClientes}
            onOpenCustomersActiveReport={this.abrirRelatorioClientesAtivos}
            onOpenStoreReport={this.abrirRelatorioEstoqueMovimentacao}
          />
        ) : (
          <List disablePadding component="nav" style={{ marginTop: 0, cursor: 'pointer' }}>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioFinanceiro()}>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Informações Financeiras</b>} secondary="Dados financeiros, como receitas, despesas, lucro líquido, pagamentos e mais." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioVendas()}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText primary={<b>Atendimentos e Vendas</b>} secondary="Relatório de atendimento e vendas de produtos e pacotes realizadas por período." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioServicos()}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Serviços</b>} secondary="Dados sobre serviços, como serviços mais realizados no período, listagens e mais." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioProdutos()}>
              <ListItemIcon>
                <ProductsIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Produtos</b>} secondary="Dados sobre produtos, como produtos mais vendidos no período, listagens e mais." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioPacotes()}>
              <ListItemIcon>
                <ShoppingBasketIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Pacotes</b>} secondary="Dados sobre pacotes, como pacotes mais vendidos no período, listagens e mais." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioClientes()}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Clientes</b>} secondary="Dados gerais sobre clientes, como gasto médio, atendimentos, mais lucrativos e mais." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioAusenciaClientes()}>
              <ListItemIcon>
                <SentimentVeryDissatisfiedIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Clientes Sumidos</b>} secondary="Clientes que não realizam atendimentos ou compram produtos a um determinado tempo." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioClientesAtivos()}>
              <ListItemIcon>
                <SentimentSatisfiedAltIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Clientes Ativos</b>} secondary="Clientes que realizaram atendimentos ou compras de produtos recentemente." />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioDespesas()}>
              <ListItemIcon>
                <MoneyOffIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Despesas</b>} secondary="Listagem de despesas, com informações resumidas e detalhadas" />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioDescontos()}>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Descontos</b>} secondary="Listagem de descontos pré-cadastrados, com informações resumidas e detalhadas" />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioFormasPagamento()}>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Formas de Pagamento</b>} secondary="Dados sobre formas de pagamento" />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioColaboradores()}>
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Colaboradores</b>} secondary="Dados sobre colaboradores, como colaboradores mais lucrativos para seu estabelecimento" />
            </ListItem>
            <ListItem disableGutters disabled={!verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])} onClick={() => this.abrirRelatorioEstoqueMovimentacao()}>
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary={<b>Estoque</b>} secondary="Listagem de movimentação de estoque dos produtos" />
            </ListItem>
          </List>
        )}
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ConfiguracoesPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withConfigCatClient(ConfiguracoesPanelPage))
