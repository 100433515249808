import React from 'react'

import { Switch, Text, Toaster } from '@grupoboticario/flora-react'
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import InfoIcon from '@material-ui/icons/Info'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import { CANCELAR_INTEGRACAO, iconColor, POINT_PRO2_IMAGE, POINT_SMART_IMAGE } from 'support/domain/mercadopago/constants'
import { Device } from 'support/domain/mercadopago/MercadoPago'
import { DevicesOperationModeEnum, DevicesTypeEnum } from 'support/util/MercadoPagoUtil'

import { useStyles } from './MaquininhaIntegrada.styles'

type MaquininhaProps = {
  deviceItem: Device
  selectedDevice: Device
  onSelecDevice: (device: Device) => void
  onInputValue: (val: string) => void
  onOpenLink: (val: string) => void
  onEditDevice: (device: Device, cb: () => void) => void
  onOpen: (val: boolean) => void
}

const deviceOperatenMode = (device: Device) => {
  if (device.operating_mode === DevicesOperationModeEnum.PDV) {
    return DevicesOperationModeEnum.PDV
  } else {
    return DevicesOperationModeEnum.STANDALONE
  }
}

export const Maquininha = ({ deviceItem, selectedDevice, onOpen, onEditDevice, onSelecDevice, onInputValue, onOpenLink }: MaquininhaProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [checked, setChecked] = React.useState(deviceOperatenMode(deviceItem) === DevicesOperationModeEnum.PDV)
  const isOpenMenu = Boolean(anchorEl)

  const deviceType = (device: Device) => {
    if (device.id.includes(DevicesTypeEnum.GERTEC)) {
      return DevicesTypeEnum.PRO2
    } else {
      return DevicesTypeEnum.SMART
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>, device: Device) => {
    setAnchorEl(event.currentTarget as HTMLElement)
    onSelecDevice(device)
  }

  const handleClose = () => {
    setAnchorEl(null)
    onInputValue('')
  }

  const handleEditName = () => {
    onOpen(true)
    if (deviceItem.alias) {
      onInputValue(deviceItem.alias)
    }
    handleClose()
  }

  const handleCancelIntegration = () => {
    onOpenLink(CANCELAR_INTEGRACAO)
    handleClose()
  }

  const isPRO2 = React.useMemo(() => {
    return deviceType(deviceItem) === DevicesTypeEnum.PRO2
  }, [deviceItem])

  const statusText = React.useMemo(() => (checked ? 'Pagamento pelo Salão99' : 'Pagamento pela maquininha'), [checked])

  return (
    <Box key={deviceItem.id} className={classes.card}>
      <Box className={classes.cardContent}>
        <Box className={classes.wrapperTitle}>
          <Box className={classes.wrapperIconContent}>
            <Box className={classes.wrapperIconText}>
              <IconButton
                data-testid="menu"
                className={classes.iconButton}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleClick(event, deviceItem)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={selectedDevice === deviceItem && isOpenMenu}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch'
                  }
                }}
              >
                <MenuItem onClick={handleEditName}>Editar apelido</MenuItem>
                <MenuItem onClick={handleCancelIntegration}>Cancelar integração</MenuItem>
              </Menu>
              <Text className={classes.iconText}>{`Maquininha ${isPRO2 ? 'Point Pro 2' : 'Point Smart'}`}</Text>
            </Box>
            <Switch
              data-testid="switch"
              onCheckedChange={(value) => {
                onEditDevice(
                  {
                    ...deviceItem,
                    operating_mode: value ? DevicesOperationModeEnum.PDV : DevicesOperationModeEnum.STANDALONE
                  },
                  () => {
                    setChecked(value)
                    Toaster.notify({
                      kind: 'success',
                      description: `Reinicie sua maquininha e faça seus pagamentos ${value ? 'pelo sistema.' : ' pela maquininha.'}`,
                      origin: 'center-top',
                      showIcon: false,
                      duration: 3000
                    })
                  }
                )
              }}
              showFeedback
              checked={checked}
            />
          </Box>
        </Box>
        <Box className={classes.wrapperImgMaquininha}>
          <img src={isPRO2 ? POINT_PRO2_IMAGE : POINT_SMART_IMAGE} />
          <Box className={classes.column}>
            {!!deviceItem.alias && (
              <Box className={classes.wrapperData}>
                <ReceiptOutlinedIcon htmlColor={iconColor} />
                <Box className={classes.column}>
                  <Text className={classes.statusLabel}>Apelido:</Text>
                  <Text className={classes.statusValue}>{deviceItem.alias}</Text>
                </Box>
              </Box>
            )}
            <Box className={classes.wrapperStatus}>
              <CheckCircleOutlineIcon htmlColor={iconColor} />
              <Box className={classes.column}>
                <Box className={classes.wrapperTooltip}>
                  <Text className={classes.statusLabel}>Status</Text>
                  <Tooltip
                    placement="bottom-start"
                    title={
                      <React.Fragment>
                        <strong>- Pagamento pelo Salão99: </strong>seus <br />
                        pagamentos serão iniciados pelo <br />
                        Salão99.
                        <br />
                        <br />
                        <strong>- Pagamento pela maquininha: </strong>
                        <br />
                        seus pagamentos serão iniciados pela <br />
                        maquininha.
                      </React.Fragment>
                    }
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <InfoIcon className={classes.tooltipIcon} />
                  </Tooltip>
                </Box>
                <Text className={classes.statusValue}>{statusText}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
