import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import InputAutocomplete from 'support/components/input/InputAutocomplete'
import InputData from 'support/components/input/InputData'
import InputHorario from 'support/components/input/InputHorario'
import InputPorcentagemValor from 'support/components/input/InputPorcentagemValor'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'

const styles = (theme) => ({})

class InputsPage extends Component {
  ESTADOS = [
    { id: 1, descricao: 'Normal', props: {} },
    { id: 2, descricao: 'Com Erro', props: { errorMessage: 'Campo obrigatório' } },
    { id: 3, descricao: 'Sem foco', props: { focusEffectDisabled: true } },
    { id: 4, descricao: 'Com Erro / Sem foco', props: { errorMessage: 'Campo obrigatório', focusEffectDisabled: true } },
    { id: 5, descricao: 'Com Ajuda', props: { helpMessage: 'Informe o seu nome' } }
  ]
  CUSTOM_VARIANTES = ['standard', 'outlined', 'outlined-small', 'naked', 'filled']
  AUTOCOMPLETE_OPTIONS = [
    { label: 'Bruno Bellim', value: 1 },
    { label: 'Willian Pimentel', value: 2 }
  ]

  render() {
    const gridWidth = 250

    const elements = []

    elements.push(
      <Typography key="titulo" variant="h3" style={{ marginBottom: 40 }}>
        Lista de Inputs
      </Typography>
    )
    for (let estados of this.ESTADOS) {
      elements.push(
        <Typography key={estados.id} variant="h4" style={{ marginBottom: 40 }}>
          {estados.descricao}
        </Typography>
      )
      for (let customVariant of this.CUSTOM_VARIANTES) {
        elements.push(
          <Grid key={estados.id + customVariant} container alignItems="flex-start" style={{ marginBottom: 40 }} spacing={2} wrap={'nowrap'}>
            <Grid item style={{ width: 160 }}>
              <Typography variant="h6">{customVariant}</Typography>
            </Grid>
            <Grid item style={{ width: gridWidth }}>
              <InputText
                {...estados.props}
                shrink={true}
                customVariant={customVariant}
                label={'Input text (' + customVariant + ')'}
                placeholder={'Autocomplete (' + customVariant + ')'}
              />
            </Grid>
            <Grid item style={{ width: gridWidth }}>
              <InputText
                {...estados.props}
                shrink={true}
                customVariant={customVariant}
                multiline={true}
                rows={1}
                rowsMax={2}
                label={'Input text (' + customVariant + ')'}
                placeholder={'Autocomplete (' + customVariant + ')'}
              />
            </Grid>
            <Grid item style={{ width: gridWidth }}>
              <InputAutocomplete
                {...estados.props}
                label={'Autocomplete (' + customVariant + ')'}
                placeholder={'Autocomplete (' + customVariant + ')'}
                customVariant={customVariant}
                options={this.AUTOCOMPLETE_OPTIONS}
              />
            </Grid>
            <Grid item style={{ width: gridWidth }}>
              <InputSelect
                {...estados.props}
                label={'Input select (' + customVariant + ')'}
                placeholder={'Input select (' + customVariant + ')'}
                customVariant={customVariant}
                shrink={true}
              >
                {this.AUTOCOMPLETE_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item style={{ width: gridWidth }}>
              <InputHorario
                clearable
                keyboard={true}
                {...estados.props}
                label={'Input horario (' + customVariant + ')'}
                placeholder={'hh:mm'}
                value={null}
                customVariant={customVariant}
                shrink={true}
              />
            </Grid>
            <Grid item style={{ width: gridWidth }}>
              <InputData
                clearable={false}
                keyboard={true}
                {...estados.props}
                label={'Input data (' + customVariant + ')'}
                placeholder={'dd/mm/yyyy'}
                value={null}
                customVariant={customVariant}
                shrink={true}
              />
            </Grid>
            <Grid item style={{ width: gridWidth }}>
              <InputPorcentagemValor {...estados.props} shrink={true} customVariant={customVariant} label={'Input Valor (' + customVariant + ')'} placeholder={'Input Valor '} />
            </Grid>
          </Grid>
        )
      }
    }

    return <div style={{ padding: 30 }}>{elements}</div>
  }
}

InputsPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputsPage)
