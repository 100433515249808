import React, { Component } from 'react'

import FormHelperTextMaterialUI from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NAKED_MARGIN_LEFT } from 'support/components/input/core/Input'

const styles = (theme) => ({
  root: {
    lineHeight: '16px'
  },
  formHelperTextNaked: {
    marginTop: 4,
    marginLeft: NAKED_MARGIN_LEFT
  },
  formHelperTextNakedNoPaddingLeft: {
    marginTop: 4
  },
  formHelperTextFilled: {
    marginLeft: theme.inputs.filledMarginLeft
  },
  formHelperTextOutlined: {
    marginTop: 6,
    marginLeft: 2,
    marginRight: 2
  }
})

class FormHelperText extends Component {
  getClassFromCustomVariant = () => {
    const { classes, customVariant, nakedLeftPadding } = this.props
    if (customVariant === 'naked') {
      if (nakedLeftPadding === false) {
        return classes.formHelperTextNakedNoPaddingLeft
      } else {
        return classes.formHelperTextNaked
      }
    } else if (customVariant === 'filled') {
      return classes.formHelperTextFilled
    } else if (customVariant === 'outlined' || customVariant === 'outlined-small') {
      return classes.formHelperTextOutlined
    }
  }

  render() {
    const { classes } = this.props

    const style = {}

    if (this.props.margin !== undefined) {
      style.margin = this.props.margin
    }

    let content = null
    if (typeof this.props.children === 'string' || (this.props.children && this.props.children.type && this.props.children.type === 'span')) {
      content = (
        <FormHelperTextMaterialUI error={this.props.error} className={classNames(classes.root, this.getClassFromCustomVariant())} style={style}>
          {this.props.children}
        </FormHelperTextMaterialUI>
      )
    } else {
      content = (
        <div className={this.getClassFromCustomVariant()} style={style}>
          {this.props.children}
        </div>
      )
    }

    return content
  }
}

FormHelperText.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FormHelperText)
