import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AnotacaoDialogPage from 'pages/admin/painel/usuario/AnotacaoDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 80,
    minWidth: 80
  },
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class AnotacoesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      ajaxing: false,
      lista: []
    }
    this.loaderFunctionsMap = {}
    this.eventsManager = EventsManager.new()
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoAnotacao', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  getRenderContent = (size, item) => {
    const content = {}
    content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YY')
    content.anotacao = item.anotacao

    content.botaoOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -4 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ anotacaoMenuAnchorEl: event.currentTarget, itemMenu: item })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )

    return content
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  getRequestParameters = () => {
    return {
      idUsuario: this.props.usuario.id
    }
  }

  abrirAnotacaoDialog = (anotacao) => {
    openBackableDialog(AnotacaoDialogPage, {
      idUsuario: this.props.usuario.id,
      idAnotacao: anotacao.id
    })
  }

  handlerRemover = (anotacao) => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Anotação',
      text: <span>Você tem certeza que deseja remover esta anotação? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'admin/usuario.removerAnotacao',
        contextoUsuario: 'admin',
        data: {
          idAnotacao: anotacao.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoAnotacao')
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing } = this.state

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push({
      icon: <AddIcon />,
      handleAction: this.abrirAnotacaoDialog
    })

    let onClickRow = (anotacao) => {
      this.abrirAnotacaoDialog(anotacao)
    }

    return (
      <DialogPage {...dialogProps} title="Anotações" align="center" contentMaxWidth={1000} pageType="basicEntity" ajaxing={ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              itemsPerRequest={30}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="admin"
              endpoint="admin/usuario.buscarAnotacoes"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              onClickRow={onClickRow}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Data', className: classes.colunaData },
                  { label: 'Anotação', props: { xs: true } },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)

                  return {
                    itemData: [content.data, content.anotacao, content.botaoOpcoes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 65,
                showFirstItemBorderTop: false,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container style={{ minWidth: 0 }} alignItems="center">
                          <Grid item xs style={{ minWidth: 0 }}>
                            <Typography variant="body2" noWrap={true}>
                              <b>{content.data}</b>
                            </Typography>
                            <Typography variant="body2" noWrap={true}>
                              {content.anotacao}
                            </Typography>
                          </Grid>
                          <Grid item>{content.botaoOpcoes}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhuma anotação foi encontrada'
              }}
            />

            <Menu anchorEl={this.state.anotacaoMenuAnchorEl} open={Boolean(this.state.anotacaoMenuAnchorEl)} onClose={() => this.closeMenu('anotacaoMenuAnchorEl')}>
              <MenuItem
                onClick={() => {
                  this.abrirAnotacaoDialog(this.state.itemMenu)
                  this.closeMenu('anotacaoMenuAnchorEl')
                }}
              >
                Abrir/Editar
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handlerRemover(this.state.itemMenu)
                  this.closeMenu('anotacaoMenuAnchorEl')
                }}
              >
                Remover
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

AnotacoesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(AnotacoesDialogPage)
