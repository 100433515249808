import moment from 'moment'
import momentLocalePtBr from 'moment/locale/pt-br'

export const setMomentPtBr = () => {
  moment.updateLocale('pt-br', {
    ...momentLocalePtBr,
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
    monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
    weekdays: 'Domingo_Segunda-Feira_Terça-Feira_Quarta-Feira_Quinta-Feira_Sexta-Feira_Sábado'.split('_'),
    weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_')
  })
  moment.locale('pt-br')
}

export const dateAsMoment = moment()
