import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import VendaStatusIcon from 'support/components/icon/VendaStatus'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import StatusNotaVendaEnum from 'support/domain/venda/StatusNotaVendaEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaNumero: {
    flexGrow: 1,
    maxWidth: 100,
    minWidth: 100
  },
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaCliente: {
    flexGrow: 1.5
  },
  colunaIconeStatusNota: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  colunaNota: {
    flexGrow: 1,
    maxWidth: 120,
    minWidth: 120,
    [theme.breakpoints.down(686)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  iconeStatusNota: {
    display: 'block',
    fontSize: 26,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  }
})

class NotasResponsiveTable extends Component {
  constructor(props) {
    super(props)
    this.podeVisualizarValorVenda = verificarAutorizacao([PA.PODE_VISUALIZAR_VALOR_VENDA]) ? true : false
  }

  removerNota = (nota) => {
    if (!verificarAutorizacao([PA.PODE_CANCELAR_NOTA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover comanda',
      text: <span>Você tem certeza que deseja remover esta comanda? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'erp/vendas.removerNota',
        contextoUsuario: 'erp',
        data: {
          id: nota.id
        },
        onSuccess: (response) => {
          EventsManager.pub('ManipulacaoVendas')
        }
      }
    })
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const content = {}

    content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YY')
    content.nomeCliente = item.cliente.apelido
    content.numero = '#' + item.numero

    if (this.podeVisualizarValorVenda) {
      content.descricaoValorTotal = formatarValorMonetario(item.valorTotal, { prefix: false })

      if (item.valorTotal < item.valorPago && !item.cancelada) {
        content.descricaoValorAdicional = formatarValorMonetario(item.valorPago - item.valorTotal, { prefix: false })
      }

      let valorPendentePagamento = item.valorTotal - item.valorPago
      if (valorPendentePagamento > 0) {
        content.descricaoValorPendente = formatarValorMonetario(valorPendentePagamento, { prefix: false })
      }
    }

    if (item.cancelada) {
      if (size === 'small') {
        content.status = (
          <React.Fragment>
            <Typography variant="body2" component="span">
              Cancelada
            </Typography>
            <Link
              style={{ fontSize: 13, marginTop: -8, marginBottom: -8, padding: 8, display: 'inline-block' }}
              onClick={(event) => {
                event.stopPropagation()
                this.removerNota(item, event)
              }}
              color="secondary"
            >
              Remover
            </Link>
          </React.Fragment>
        )
      } else {
        content.status = (
          <React.Fragment>
            <Typography variant="body2">Cancelada</Typography>
            <Link
              style={{ fontSize: 13, display: 'block' }}
              onClick={(event) => {
                event.stopPropagation()
                this.removerNota(item, event)
              }}
              color="secondary"
            >
              Remover
            </Link>
          </React.Fragment>
        )
      }
      content.iconeStatusNota = <VendaStatusIcon hasTooltip={true} vendaCancelada={true} className={classes.iconeStatusNota} style={{ color: '#444' }} />
    } else if (item.status && item.status.id === StatusNotaVendaEnum.ABERTA.id) {
      content.iconeStatusNota = (
        <VendaStatusIcon hasTooltip={true} status={item.status} vendaCancelada={item.cancelado} className={classes.iconeStatusNota} style={{ color: '#444' }} />
      )
      content.status = 'Comanda Aberta'
    } else if (item.status && item.status.id === StatusNotaVendaEnum.FECHADA.id) {
      content.iconeStatusNota = <VendaStatusIcon hasTooltip={true} status={item.status} vendaCancelada={item.cancelado} color="secondary" className={classes.iconeStatusNota} />
      content.status = 'Comanda Fechada'
    }

    return content
  }

  render() {
    const { classes, origem, ...others } = this.props

    return (
      <VirtualizedResponsiveTable
        showBackgroundDividers={true}
        largeRenderProps={{
          columns: [
            { label: 'Comanda', className: classes.colunaNumero },
            { label: 'Data', className: classes.colunaData, horizontalPadding: 'small' },
            { label: 'Cliente', className: classes.colunaCliente, horizontalPadding: 'small', props: { xs: true } },
            { label: 'Valor Total (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right', display: this.podeVisualizarValorVenda },
            { label: 'Adicional (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right', display: this.podeVisualizarValorVenda },
            {
              label: (
                <span>
                  Pendente
                  <br />
                  Pagamento (R$)
                </span>
              ),
              className: classes.colunaValor,
              horizontalPadding: 'small',
              align: 'right',
              display: this.podeVisualizarValorVenda
            },
            { label: 'Situação', className: classes.colunaNota, align: 'right' },
            { label: '', className: classes.colunaIconeStatusNota, align: 'right', horizontalPadding: 'small', isVisible: false }
          ],
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('large', item)
            return {
              itemData: [
                content.numero,
                content.data,
                content.nomeCliente,
                content.descricaoValorTotal,
                content.descricaoValorAdicional ? content.descricaoValorAdicional : '-',
                content.descricaoValorPendente ? content.descricaoValorPendente : '-',
                content.status,
                content.iconeStatusNota
              ]
            }
          }
        }}
        mediumRenderProps={{
          rowHeight: 84,
          columns: [
            { label: 'Fatura', className: classes.colunaData },
            { label: 'Informações', className: classes.colunaCliente, horizontalPadding: 'small', props: { xs: true } },
            { label: 'Situação', className: classes.colunaNota },
            { label: '', className: classes.colunaIconeStatusNota, align: 'right', horizontalPadding: 'small', isVisible: false }
          ],
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('medium', item)
            return {
              itemData: [
                <span>
                  {content.numero}
                  <br />
                  {content.data}
                </span>,
                <React.Fragment>
                  <Typography variant="body2" noWrap={true}>
                    <b>{content.nomeCliente}</b>
                  </Typography>
                  {this.podeVisualizarValorVenda && (
                    <Typography variant="body2">
                      Total: R$ {content.descricaoValorTotal}
                      {content.descricaoValorAdicional && (
                        <span>
                          <br />
                          Adicional: R$ {content.descricaoValorAdicional}
                        </span>
                      )}
                      {content.descricaoValorPendente && (
                        <span>
                          <br />
                          Pendente: R$ {content.descricaoValorPendente}
                        </span>
                      )}
                    </Typography>
                  )}
                </React.Fragment>,
                content.status,
                content.iconeStatusNota
              ]
            }
          }
        }}
        smallRenderProps={{
          rowHeight: 108,
          itemRenderer: (item, index) => {
            const content = this.getRenderContent('small', item)

            return {
              itemData: (
                <React.Fragment>
                  <Grid container>
                    <Grid item xs style={{ minWidth: 0 /*minWidth - para ativar as reticências */ }}>
                      <Typography variant="body2">
                        <b>{content.numero}</b>
                      </Typography>
                      <Typography variant="body2">{content.data}</Typography>
                      <Typography variant="body2" noWrap={true}>
                        <b>{content.nomeCliente}</b>
                      </Typography>
                      <Typography variant="body2">{content.status}</Typography>
                    </Grid>
                    <Grid item align="right">
                      {this.podeVisualizarValorVenda && (
                        <React.Fragment>
                          <Typography variant="body2">
                            Total: <b>R$ {content.descricaoValorTotal}</b>
                          </Typography>
                          {content.descricaoValorAdicional && (
                            <Typography variant="body2">
                              Adicional: <b>R$ {content.descricaoValorAdicional}</b>
                            </Typography>
                          )}
                          {content.descricaoValorPendente && (
                            <Typography variant="body2">
                              Pendente: <b>R$ {content.descricaoValorPendente}</b>
                            </Typography>
                          )}
                        </React.Fragment>
                      )}
                      {content.iconeStatusNota}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            }
          }
        }}
        {...others}
      />
    )
  }
}

NotasResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotasResponsiveTable)
