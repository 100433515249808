import React, { Component } from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import InputText from 'support/components/input/InputText'
import TipoCalculoEnum from 'support/domain/calculo/TipoCalculoEnum'
import { shadeColor } from 'support/util/ColorUtil'
import { InputMoneyFormat, InputPercentFormat } from 'support/util/Masks'

const styles = (theme) => ({
  tabsRoot: {
    overflow: 'visible',
    minHeight: 37
  },
  tabRoot: {
    minWidth: 36,
    flexBasis: 36,
    minHeight: 36,
    opacity: 0.3,
    '&:hover': {
      backgroundColor: '#f4f4f4'
    },
    '&:focus': {
      backgroundColor: shadeColor(theme.palette.secondary.main, 0.93)
    }
  },
  tabLabelContainer: {
    paddingLeft: 0,
    paddingRight: 0
  },
  tabSelected: {
    opacity: 1,
    backgroundColor: shadeColor(theme.palette.secondary.main, 0.93)
  },
  hideIndicator: {
    display: 'none'
  },
  containerButtons: {
    whiteSpace: 'nowrap'
  },
  buttom: {
    display: 'inline-block',
    marginLeft: 3,
    borderRadius: 24,
    width: 22,
    height: 22,
    textAlign: 'center',
    lineHeight: '22px',
    fontSize: 12,
    fontWeight: 600,
    border: '1px solid rgba(0,0,0,0.17)',
    color: 'rgba(0,0,0,0.25)',
    '&:hover:not($buttomSelected):not($buttomDisabled):not($buttomSmall)': {
      cursor: 'pointer',
      border: '1px solid rgba(0,0,0,0.20)',
      backgroundColor: 'rgba(0,0,0,0.04)',
      color: 'rgba(0,0,0,0.42)'
    },
    '&$buttomSelected': {
      cursor: 'default',
      border: '1px solid ' + lighten(theme.palette.secondary.light, 0.1),
      backgroundColor: lighten(theme.palette.secondary.light, 0.9),
      color: lighten(theme.palette.secondary.light, 0.1)
    },
    '&$buttomDisabled': {
      cursor: 'default',
      opacity: 0.65
    },
    '&$buttomSelected&$buttomDisabled': {
      opacity: 0.45
    },
    '&:first-child': {
      marginLeft: 0
    },
    '&$buttomSmall': {
      cursor: 'pointer',
      borderRadius: 24,
      width: 38,
      border: '1px solid rgba(0,0,0,0.10)',
      backgroundColor: 'rgba(0,0,0,0.00)',
      color: 'rgba(0,0,0,0.48)'
    },
    '&$buttomSmall:hover': {
      border: '1px solid rgba(0,0,0,0.25)'
    }
  },
  buttomReal: {
    fontWeight: 500
  },
  buttomSmall: {},
  buttomSelected: {},
  buttomDisabled: {}
})

export const DEFAULT_VALOR = ''
export const DEFAULT_TIPO_CALCULO = TipoCalculoEnum.PORCENTAGEM

class InputComissao extends Component {
  constructor(props) {
    super(props)
    let tipoCalculo = null
    if (props.defaultTipoCalculo) {
      tipoCalculo = TipoCalculoEnum.getById(props.defaultTipoCalculo.id)
    }
    this.state = {
      value: this.props.defaultValor !== undefined && this.props.defaultValor !== null ? this.props.defaultValor : DEFAULT_VALOR,
      tipoCalculo: tipoCalculo ? tipoCalculo : DEFAULT_TIPO_CALCULO,
      alterarTipoCalculoAnchorEl: null
    }
    this.inputComissao = React.createRef()
  }

  componentDidMount() {
    if (this.props.functionsMap) {
      this.props.functionsMap.getValor = () => {
        return this.state.value
      }
      this.props.functionsMap.getTipoCalculo = () => {
        return this.state.tipoCalculo
      }
    }
  }

  openAlterarTipoCalculoItemMenu = (event) => {
    this.setState({ alterarTipoCalculoAnchorEl: event.currentTarget })
  }

  closeAlterarTipoCalculoItemMenu = () => {
    this.setState({ alterarTipoCalculoAnchorEl: null })
  }

  alterarTipoCalculo = (tipo) => {
    if (this.props.disabled) {
      return
    }

    const state = this.state
    state.tipoCalculo = tipo
    this.setState(state)
    if (this.props.onChangeTipoCalculo) {
      this.props.onChangeTipoCalculo(tipo)
    }
  }

  getTabValuePorTipoCalculo(tipoCalculo) {
    if (tipoCalculo.id === TipoCalculoEnum.PORCENTAGEM.id) {
      return 0
    } else if (tipoCalculo.id === TipoCalculoEnum.REAL.id) {
      return 1
    }
  }

  render() {
    const { classes, ...othersInputProps } = this.props
    const { alterarTipoCalculoAnchorEl } = this.state

    delete othersInputProps.defaultValor
    delete othersInputProps.defaultTipoCalculo
    delete othersInputProps.onChangeTipoCalculo
    delete othersInputProps.tipoCalculoVariant

    let mask = null
    const inputProps = {}
    if (this.state.tipoCalculo.id === TipoCalculoEnum.PORCENTAGEM.id) {
      mask = InputPercentFormat
      inputProps.maxValue = 100
    } else if (this.state.tipoCalculo.id === TipoCalculoEnum.REAL.id) {
      mask = InputMoneyFormat
    }

    let classNameSelected = null

    if (this.state.tipoCalculo.id === TipoCalculoEnum.PORCENTAGEM.id) {
      classNameSelected = [classes.buttomSelected, null]
    } else if (this.state.tipoCalculo.id === TipoCalculoEnum.REAL.id) {
      classNameSelected = [null, classes.buttomSelected]
    }

    othersInputProps.value = this.state.value
    othersInputProps.onChange = (event) => {
      const state = this.state
      state.value = event.target.value

      if (this.props.onChange) {
        this.props.onChange(event)
      }

      this.setState(state)
    }

    /*Problema ao remover sinal de negativo (-) com o backspace. O onChange não é chamado. */
    othersInputProps.onKeyUp = (event) => {
      const state = this.state
      state.value = event.target.value

      if (this.props.onKeyUp) {
        this.props.onKeyUp(event)
      }

      this.setState(state)
    }

    let disabledButtonClass = null
    if (this.props.disabled || this.props.readOnly) {
      disabledButtonClass = classes.buttomDisabled
    }

    const buttonsContainerStyles = {}
    if (this.props.customVariant === 'filled') {
      buttonsContainerStyles.paddingRight = 6
    } else if (this.props.customVariant === 'outlined' || this.props.customVariant === 'outlined-small') {
      buttonsContainerStyles.paddingRight = 12
    }

    othersInputProps.inputComponent = mask
    othersInputProps.inputProps = inputProps

    if (this.props.tipoCalculoVariant === undefined) {
      othersInputProps.endAdornment = (
        <div className={classes.containerButtons} style={buttonsContainerStyles}>
          <div className={classNames(classes.buttom, classNameSelected[0], disabledButtonClass)} onClick={() => this.alterarTipoCalculo(TipoCalculoEnum.PORCENTAGEM)}>
            %
          </div>
          <div className={classNames(classes.buttom, classes.buttomReal, classNameSelected[1], disabledButtonClass)} onClick={() => this.alterarTipoCalculo(TipoCalculoEnum.REAL)}>
            R$
          </div>
        </div>
      )
    } else if (this.props.tipoCalculoVariant === 'small') {
      let tipoCalculoLabel = null
      if (this.state.tipoCalculo.id === TipoCalculoEnum.PORCENTAGEM.id) {
        tipoCalculoLabel = '%'
      } else if (this.state.tipoCalculo.id === TipoCalculoEnum.REAL.id) {
        tipoCalculoLabel = 'R$'
      }
      othersInputProps.endAdornment = (
        <div className={classes.containerButtons} style={buttonsContainerStyles}>
          <div className={classNames(classes.buttom, classes.buttomSmall, null)} onClick={(event) => this.openAlterarTipoCalculoItemMenu(event)}>
            {tipoCalculoLabel} <ArrowDropDownIcon style={{ margin: -8, marginLeft: -7 }} />
          </div>
          <Menu anchorEl={alterarTipoCalculoAnchorEl} open={Boolean(alterarTipoCalculoAnchorEl)} onClose={this.closeAlterarTipoCalculoItemMenu}>
            <MenuItem
              onClick={() => {
                this.alterarTipoCalculo(TipoCalculoEnum.PORCENTAGEM)
                this.closeAlterarTipoCalculoItemMenu()
              }}
            >
              %
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.alterarTipoCalculo(TipoCalculoEnum.REAL)
                this.closeAlterarTipoCalculoItemMenu()
              }}
            >
              R$
            </MenuItem>
          </Menu>
        </div>
      )
    }

    return <InputText {...othersInputProps} />
  }
}

InputComissao.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputComissao)
