import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import { converteHexStrToRgbStr } from 'support/util/ColorUtil'

const styles = (theme) => ({})

class AgendaColunaItensScrollbars extends Component {
  constructor(props) {
    super(props)
    //this.handleSpringUpdate = this.handleSpringUpdate.bind(this);
    this.eventsManager = EventsManager.new()
  }

  componentDidMount() {
    const { scrollbars } = this.refs
    //scrollbars.container.style.overflow = "hidden";
    scrollbars.view.style.overflow = 'hidden'
    scrollbars.view.style.marginBottom = '0px'
    //console.log(scrollbars);

    /*this.springSystem = new SpringSystem();
		this.spring = this.springSystem.createSpring(1000, 45);
		this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });*/

    if (this.props.functionsMap) {
      this.props.functionsMap['getScrollLeft'] = this.getScrollLeft
      this.props.functionsMap['scrollLeft'] = this.scrollLeft
      this.props.functionsMap['scrollLeftWithAnimation'] = this.scrollLeftWithAnimation
      this.props.functionsMap['getScrollInfo'] = this.getScrollInfo
      this.props.functionsMap['getClientWidth'] = this.getClientWidth
      this.props.functionsMap['getInnerWidth'] = this.getInnerWidth
    }

    this.eventsManager.sub('CustomScrollbars', (props) => {
      if (props.action === 'forceUpdate') {
        window.setTimeout(
          () => {
            this.forceUpdate()
          },
          props.delay ? props.delay : 0
        )
      }
    })
  }

  getScrollLeft = () => {
    return this.refs.scrollbars.getScrollLeft()
  }

  scrollLeftWithAnimation = (left, callback) => {
    const { scrollbars } = this.refs
    /*const scrollLeft = scrollbars.getScrollLeft();
 	   const scrollWidth = scrollbars.getScrollWidth();
 	   const val = MathUtil.mapValueInRange(left, 0, scrollWidth, 0, scrollWidth);
 	   this.spring.setCurrentValue(scrollLeft).setAtRest();
 	   this.spring.setEndValue(val);*/
    scrollbars.scrollLeft(left)
    //scrollTo(scrollbars.getScrollLeft,scrollbars.scrollLeft,left,callback,0);
  }

  getClientWidth = () => {
    const { scrollbars } = this.refs
    return scrollbars.getClientWidth()
  }

  getInnerWidth = () => {
    const { scrollbars } = this.refs
    return scrollbars.view.firstChild.offsetWidth
  }

  getScrollInfo = () => {
    const { scrollbars } = this.refs
    const clientWidth = scrollbars.getClientWidth(),
      scrollWidth = scrollbars.getScrollWidth(),
      scrollLeft = scrollbars.getScrollLeft()

    let canScrollLeft = false
    if (scrollWidth > clientWidth && scrollLeft > 0) {
      canScrollLeft = true
    }

    let canScrollRight = false
    if (scrollWidth > clientWidth && scrollLeft + clientWidth < scrollWidth) {
      canScrollRight = true
    }
    return {
      canScrollLeft: canScrollLeft,
      canScrollRight: canScrollRight
    }
  }

  scrollLeft = (left) => {
    const { scrollbars } = this.refs
    scrollbars.scrollLeft(left)
  }

  /*handleSpringUpdate = (spring) => {
	   const { scrollbars } = this.refs;
	   const val = spring.getCurrentValue();
	   scrollbars.scrollLeft(val);
   }*/

  render() {
    const { theme } = this.props

    return (
      <Scrollbars
        ref="scrollbars"
        onScroll={this.props.onScroll ? (event) => this.props.onScroll(event, this.refs.scrollbars) : undefined}
        onScrollStart={this.props.onScrollStart}
        onScrollStop={this.props.onScrollStop}
        hideTracksWhenNotNeeded={true}
        renderTrackVertical={({ style, ...props }) => <div style={{ display: 'none' }} />}
        renderThumbHorizontal={({ style, ...props }) => (
          <div
            {...props}
            style={{ ...style, backgroundColor: converteHexStrToRgbStr(theme.palette.primary.main, 0.7), height: 3, cursor: 'pointer', borderRadius: 3, display: 'none' }}
          />
        )}
        renderTrackHorizontal={({ style, ...props }) => {
          return <div {...props} style={{ ...style, top: 0, width: '100%', backgroundColor: '#f1f1f1', height: 4, borderRadius: 3, display: 'none' }} />
        }}
      >
        {this.props.children}
      </Scrollbars>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
    /*this.springSystem.deregisterSpring(this.spring);
 		this.springSystem.removeAllListeners();
 		this.springSystem = undefined;
 		this.spring.destroy();
 		this.spring = undefined;*/
  }
}

AgendaColunaItensScrollbars.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(AgendaColunaItensScrollbars)
