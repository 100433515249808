import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import UncontrolledCheckbox from 'support/components/checkbox/UncontrolledCheckbox'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import NativeApp from 'support/components/nativeapp/NativeApp'
import DialogPage from 'support/components/page/DialogPage'
import AntecedenciaCancelamentoAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaCancelamentoAgendamentoOnlineEnum'
import AntecedenciaMaximaAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaMaximaAgendamentoOnlineEnum'
import AntecedenciaMinimaAgendamentoOnlineEnum from 'support/domain/agendamentoonline/AntecedenciaMinimaAgendamentoOnlineEnum'
import IntervaloHorarioAgendamentoOnlineEnum from 'support/domain/agendamentoonline/IntervaloHorarioAgendamentoOnlineEnum'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import NegocioService from 'support/domain/negocio/NegocioService'
import FormUtil, { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'
import { formatarHorarioInt } from 'support/util/TimeFormatter'
import TimeUtil from 'support/util/TimeUtil'

const styles = (theme) => ({
  containerHorarioDisponivel: {
    display: 'inline-block',
    padding: 6,
    border: '1px solid #dfdfdf',
    borderRadius: 6,
    margin: 4,
    fontSize: 12,
    color: '#888'
  },
  containerFooterButtons: theme.form.containerFooterButtons(),
  gridCanalAgendamentoIcon: {
    textAlign: 'center',
    minWidth: 42,
    maxWidth: 42,
    marginRight: 8
  },
  infoBanner: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: 4,
    padding: '8px 16px',
    gap: 8,
    marginTop: 10
  },
  infoBannerIcon: {
    width: 16,
    heigth: 16
  },
  infoBannerText: {
    fontSize: 12
  }
})

const INPUT_MAX_WIDTH = 380

class AgendamentoOnlineDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      controleAgendamentoOnline: ControleAgendamentoOnlineEnum.DESABILITADO,
      intervaloHorarioAgendamentoOnline: IntervaloHorarioAgendamentoOnlineEnum.INTERVALO_30,
      antecedenciaMinimaAgendamentoOnline: AntecedenciaMinimaAgendamentoOnlineEnum.ANTECEDENCIA_60,
      antecedenciaMaximaAgendamentoOnline: AntecedenciaMaximaAgendamentoOnlineEnum.ANTECEDENCIA_90,
      antecedenciaCancelamentoAgendamentoOnline: AntecedenciaCancelamentoAgendamentoOnlineEnum.ANTECEDENCIA_4,
      alterandoSufixoUrlPortal: false,
      title: '',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.sufixoUrlPortal = ''
    this.inputsufixoUrlPortal = React.createRef()
    this.inputPoliticaCancelamento = React.createRef()
    this.inputBuscavelAgendamentoOnline = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/negocios.buscarConfiguracoesAgendamentoOnline',
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const { negocio, configuracoesGerais, pendencias } = response.data

        const newState = {
          negocio: negocio,
          configuracoesGerais: configuracoesGerais,
          pendencias: pendencias,
          controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(configuracoesGerais.controleAgendamentoOnline.id),
          intervaloHorarioAgendamentoOnline: IntervaloHorarioAgendamentoOnlineEnum.getById(configuracoesGerais.intervaloHorarioAgendamentoOnline.id),
          antecedenciaMinimaAgendamentoOnline: AntecedenciaMinimaAgendamentoOnlineEnum.getById(configuracoesGerais.antecedenciaMinimaAgendamentoOnline.id),
          antecedenciaMaximaAgendamentoOnline: AntecedenciaMaximaAgendamentoOnlineEnum.getById(configuracoesGerais.antecedenciaMaximaAgendamentoOnline.id),
          antecedenciaCancelamentoAgendamentoOnline: AntecedenciaCancelamentoAgendamentoOnlineEnum.getById(configuracoesGerais.antecedenciaCancelamentoAgendamentoOnline.id)
        }
        this.setState(newState)
        this.sufixoUrlPortal = configuracoesGerais.sufixoUrlPortal
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState(() => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = {
      controleAgendamentoOnline: {
        id: this.state.controleAgendamentoOnline.id
      }
    }

    if (this.state.controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.HABILITADO.id) {
      const { intervaloHorarioAgendamentoOnline, antecedenciaMinimaAgendamentoOnline, antecedenciaMaximaAgendamentoOnline, antecedenciaCancelamentoAgendamentoOnline } = this.state

      dados.buscavelAgendamentoOnline = this.inputBuscavelAgendamentoOnline.getValue()
      dados.sufixoUrlPortal = this.sufixoUrlPortal
      dados.intervaloHorarioAgendamentoOnline = { id: intervaloHorarioAgendamentoOnline.id }
      dados.antecedenciaMinimaAgendamentoOnline = { id: antecedenciaMinimaAgendamentoOnline.id }
      dados.antecedenciaMaximaAgendamentoOnline = { id: antecedenciaMaximaAgendamentoOnline.id }
      dados.antecedenciaCancelamentoAgendamentoOnline = { id: antecedenciaCancelamentoAgendamentoOnline.id }
      dados.textoPoliticaCancelamentoAgendamentoOnline = this.inputPoliticaCancelamento.current.value
    }

    postAPI({
      url: 'erp/negocios.alterarConfiguracoesGerais',
      data: dados,
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
        EventsManager.pub('AtualizarSessao')
        EventsManager.pub('ManipulacaoConfiguracaoAgendamentoOnline')
      },
      onError: (response) => {
        const errorsMap = createErrorsMap(response.data.errors)

        const newState = {
          ajaxing: false,
          errorsMap: errorsMap
        }

        if (errorsMap('sufixoUrlPortal')) {
          newState.alterandoSufixoUrlPortal = true
        }

        this.setState(newState)
        focusFirstElementWithError('formConfiguracoesComissoes')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { configuracoesGerais, alterandoSufixoUrlPortal, errorsMap, ajaxing } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={640}
        pageType="basicEntity"
        ajaxing={ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => {
            const contentHorariosExemplos = []
            let horarioBase = 900
            while (true) {
              if (contentHorariosExemplos.length >= 4 || horarioBase > 2000) {
                break
              }
              contentHorariosExemplos.push(
                <div className={classes.containerHorarioDisponivel} key={horarioBase}>
                  {formatarHorarioInt(horarioBase)}
                </div>
              )
              horarioBase = TimeUtil.adiciconarMinutosHorarioInt(horarioBase, this.state.intervaloHorarioAgendamentoOnline.id)
            }

            let sufixoUrlPortal = NegocioService.gerarUrlAgendamentoOnline(this.state.negocio, this.sufixoUrlPortal)

            const linkSiteProps = {}
            const linkAppAndroidProps = {}
            const linkAppiOSProps = {}

            const siteLink = process.env.REACT_APP_WEB_ADDRESS
            const siteAppAndroid = 'https://play.google.com/store/apps/details?id=br.com.salao99.portal'
            const siteAppIOS = 'https://apps.apple.com/br/app/sal%C3%A3o99/id1555141764'

            if (DeviceIdentifier.isNativeApp()) {
              linkSiteProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: siteLink
                })
              }
              linkAppAndroidProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: siteAppAndroid
                })
              }
              linkAppiOSProps.onClick = () => {
                NativeApp.executeNativeMethod({
                  method: 'openLink',
                  url: siteAppIOS
                })
              }
            } else {
              linkSiteProps.href = siteLink
              linkSiteProps.target = 'siteLink'
              linkAppAndroidProps.href = siteAppAndroid
              linkAppAndroidProps.target = 'siteAppAndroid'
              linkAppiOSProps.href = siteAppIOS
              linkAppiOSProps.target = 'siteAppIOS'
            }

            return (
              <form id="formConfiguracoesComissoes" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                <Typography variant="h5" style={{ fontWeight: 500, fontSize: 22, marginTop: 10 }}>
                  Agendamento Online
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 400, marginTop: 8, marginBottom: 12 }}>
                  Economize tempo e alcance mais clientes. Com o Agendamento Online os seus clientes podem agendar horários 24h pelo aplicativo ou site.
                </Typography>
                <div style={{ maxWidth: INPUT_MAX_WIDTH }}>
                  <InputSelect
                    style={{ marginTop: 2, marginBottom: 2 }}
                    fullWidth={true}
                    customVariant="outlined-small"
                    disabled={ajaxing}
                    SelectDisplayProps={{
                      style: {
                        fontSize: 16,
                        ppaddingTop: 8
                      }
                    }}
                    value={this.state.controleAgendamentoOnline.id}
                    onChange={(event) => {
                      const id = event.target.value
                      this.setState({ controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(id) })
                    }}
                  >
                    {ControleAgendamentoOnlineEnum.values().map((controle) => (
                      <MenuItem key={controle.id} value={controle.id}>
                        {controle.descricaoOpcao}
                      </MenuItem>
                    ))}
                  </InputSelect>
                </div>
                {/*<Typography variant="body2" style={{color: "#adabab", fontSize: 13, marginTop: 6}}>{this.state.controleAgendamentoOnline.descricaoExplicacao}</Typography>*/}

                {this.state.controleAgendamentoOnline.id === ControleAgendamentoOnlineEnum.HABILITADO.id && (
                  <React.Fragment>
                    <Typography variant="body2" style={{ marginBottom: 2, marginTop: 32 }}>
                      Link personalizado da página de agendamentos online do seu negócio
                    </Typography>
                    {!alterandoSufixoUrlPortal && (
                      <div>
                        <Typography variant="body1" style={{ wordBreak: 'break-all' }}>
                          <LinkIcon style={{ verticalAlign: 'bottom', marginBottom: -1, marginRight: 6 }} />
                          <b>www.salao99.com.br{sufixoUrlPortal}</b>
                        </Typography>
                        <Button
                          style={{ textTransform: 'none', marginLeft: -8, fontSize: 15 }}
                          color="secondary"
                          onClick={() => {
                            this.setState({
                              alterandoSufixoUrlPortal: true
                            })
                            window.setTimeout(() => {
                              this.inputsufixoUrlPortal.current.focus()
                            }, 10)
                          }}
                        >
                          Alterar Link
                        </Button>
                      </div>
                    )}

                    {alterandoSufixoUrlPortal && (
                      <InputText
                        idname="xsufixoUrlPortal"
                        style={{ maxWidth: INPUT_MAX_WIDTH }}
                        fullWidth={true}
                        customVariant="outlined-small"
                        startAdornment={
                          <span
                            style={{ color: '#aaa', marginRight: -11, fontSize: 14, display: 'block', marginTop: 2 }}
                            onClick={() => {
                              window.setTimeout(() => {
                                this.inputsufixoUrlPortal.current.focus()
                              }, 10)
                            }}
                          >
                            www.salao99.com.br
                          </span>
                        }
                        inputRef={this.inputsufixoUrlPortal}
                        defaultValue={this.sufixoUrlPortal}
                        errorMessage={errorsMap('sufixoUrlPortal')}
                        helpMessage={
                          <span>
                            Utiliza apenas letras, números, traço(-) ou underline (_).
                            <br />
                            <b>Não são permitidos</b> caracteres especiais, emojis ou acentuações.
                          </span>
                        }
                        disabled={ajaxing}
                        onChange={() => {
                          this.sufixoUrlPortal = this.inputsufixoUrlPortal.current.value
                        }}
                        onBlur={() => {
                          this.setState({
                            alterandoSufixoUrlPortal: !errorsMap('sufixoUrlPortal') ? false : true
                          })
                        }}
                        maxLength="128"
                      />
                    )}

                    <UncontrolledCheckbox
                      formControlProps={{
                        style: { marginTop: 10 },
                        label: (
                          <Typography variant="body2" style={{ padding: '8px 0px' }}>
                            Exibir na busca do site e App
                            <span style={{ fontSize: 13, color: '#999', lineHeight: '16px', display: 'block' }}>
                              Usuários poderão encontrar a sua empresa na página de busca do Site ou do Aplicativo Salão99
                            </span>
                          </Typography>
                        )
                      }}
                      color="primary"
                      disabled={ajaxing}
                      defaultChecked={configuracoesGerais.buscavelAgendamentoOnline}
                      functionsMap={this.inputBuscavelAgendamentoOnline}
                    />

                    <Typography variant="h5" style={{ fontWeight: 500, marginTop: 32, fontSize: 20 }}>
                      Configurações
                    </Typography>
                    <Divider style={{ marginTop: 8, marginBottom: 24 }} />

                    <Typography variant="body1" style={{ fontWeight: 500, marginTop: 32 }}>
                      Intervalo de Horários
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 14, marginBottom: 6, marginTop: 2 }}>
                      Apresentação dos horários disponíveis na agenda online
                    </Typography>
                    <InputSelect
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      fullWidth={true}
                      disabled={ajaxing}
                      customVariant="outlined-small"
                      value={this.state.intervaloHorarioAgendamentoOnline.id}
                      onChange={(event) => {
                        const id = event.target.value
                        this.setState({ intervaloHorarioAgendamentoOnline: IntervaloHorarioAgendamentoOnlineEnum.getById(id) })
                      }}
                    >
                      {IntervaloHorarioAgendamentoOnlineEnum.values().map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                    <div style={{ color: '#adabab', fontSize: 13, marginTop: 6 }}>Exemplo: {contentHorariosExemplos}</div>

                    <Typography variant="body1" style={{ fontWeight: 500, marginTop: 32 }}>
                      Tempo de Antecedência Mínimo
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 14, marginBottom: 6, marginTop: 2 }}>
                      Tempo de antedecência mínimo para o cliente agendar um horário
                    </Typography>
                    <InputSelect
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      fullWidth={true}
                      disabled={ajaxing}
                      customVariant="outlined-small"
                      value={this.state.antecedenciaMinimaAgendamentoOnline.id}
                      onChange={(event) => {
                        const id = event.target.value
                        this.setState({ antecedenciaMinimaAgendamentoOnline: AntecedenciaMinimaAgendamentoOnlineEnum.getById(id) })
                      }}
                    >
                      {AntecedenciaMinimaAgendamentoOnlineEnum.values().map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>

                    <Typography variant="body1" style={{ fontWeight: 500, marginTop: 32 }}>
                      Período Máximo para Agendar
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 14, marginBottom: 6, marginTop: 2 }}>
                      O tempo máximo futuro que a agenda fica disponível para o cliente agendar um horário
                    </Typography>
                    <InputSelect
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      fullWidth={true}
                      disabled={ajaxing}
                      customVariant="outlined-small"
                      value={this.state.antecedenciaMaximaAgendamentoOnline.id}
                      onChange={(event) => {
                        const id = event.target.value
                        this.setState({ antecedenciaMaximaAgendamentoOnline: AntecedenciaMaximaAgendamentoOnlineEnum.getById(id) })
                      }}
                    >
                      {AntecedenciaMaximaAgendamentoOnlineEnum.values().map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>

                    <Typography variant="body1" style={{ fontWeight: 500, marginTop: 32 }}>
                      Antecedência para Cancelamento
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 14, marginBottom: 6, marginTop: 2 }}>
                      O tempo mínimo de antecedência para o cliente cancelar um agendamento.
                    </Typography>
                    <InputSelect
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      fullWidth={true}
                      disabled={ajaxing}
                      customVariant="outlined-small"
                      value={this.state.antecedenciaCancelamentoAgendamentoOnline.id}
                      onChange={(event) => {
                        const id = event.target.value
                        this.setState({ antecedenciaCancelamentoAgendamentoOnline: AntecedenciaCancelamentoAgendamentoOnlineEnum.getById(id) })
                      }}
                    >
                      {AntecedenciaCancelamentoAgendamentoOnlineEnum.values().map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                    <Typography variant="body2" style={{ color: '#adabab', fontSize: 13, marginTop: 6 }}>
                      Importante: O sistema não impedirá que o cliente faça o cancelamento fora do tempo mínimo de antecedência, mas exibirá um alerta avisando que período não está
                      sendo respeitado e o texto da política de cancelamento (caso seja cadastrado abaixo)
                    </Typography>

                    <Typography variant="body1" style={{ fontWeight: 500, marginTop: 32 }}>
                      Política de Cancelamento
                    </Typography>
                    <InputText
                      marginTop={2}
                      idname="xtextoPoliticaCancelamentoAgendamentoOnline"
                      customVariant="outlined-small"
                      shrink={true}
                      placeholder="Caso haja, escreva o texto da política de cancelamento de agendamentos da sua empresa"
                      defaultValue={configuracoesGerais.textoPoliticaCancelamentoAgendamentoOnline}
                      disabled={ajaxing}
                      errorMessage={errorsMap('textoPoliticaCancelamentoAgendamentoOnline')}
                      multiline={true}
                      rowsMax={10}
                      rows={6}
                      maxLength="4000"
                      inputRef={this.inputPoliticaCancelamento}
                    />
                    <br />
                    <br />
                  </React.Fragment>
                )}

                {this.state.paginaCarregada && (
                  <div className={classes.containerFooterButtons}>
                    <Button disabled={ajaxing} onClick={this.props.handleCloseDialog}>
                      Cancelar
                    </Button>
                    <Button disabled={ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                      {ajaxing ? 'Aguarde' : 'Salvar'}
                    </Button>
                  </div>
                )}

                <FormUtil.HiddenSubmitButton />
              </form>
            )
          }}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

AgendamentoOnlineDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AgendamentoOnlineDialogPage)
