import { Button } from '@material-ui/core'
import GoogleIcon from 'support/components/icon/Google'
import UrlUtil from 'support/util/UrlUtil'

import DeviceIdentifier from '../device/DeviceIdentifier'

const classes = {
  container: {
    width: 'calc(100% - 22px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  separator: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBlockStart: '12px',
    marginBlockEnd: '26px'
  },
  separatorLine: {
    border: '0.5px solid #DEDEDE',
    flexGrow: 2,
    height: '0px'
  },
  separatorText: {
    width: '80px',
    textAlign: 'center'
  },
  button: {
    width: '100%',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
  },
  buttonContent: {
    alignItems: 'center',
    display: 'flex',
    gap: '20px'
  }
}

const variants = {
  login: {
    buttonText: 'ENTRE COM O GOOGLE'
  },
  signup: {
    buttonText: 'CRIAR CONTA COM O GOOGLE'
  }
}

type GoogleSSOButtonProps = {
  variant?: 'login' | 'signup'
  withSeparator?: boolean
  continuationUrl: string
  disabled?: boolean
}

function GoogleSSOButton({ variant = 'login', continuationUrl, disabled = false, withSeparator = false }: GoogleSSOButtonProps) {
  if (DeviceIdentifier.isNativeApp()) {
    return null
  }

  return (
    <div style={classes.container as Record<string | number, string>}>
      {withSeparator && (
        <div style={classes.separator} data-testid="separator">
          <div style={classes.separatorLine} />
          <p style={classes.separatorText as Record<string | number, string>}>ou</p>
          <div style={classes.separatorLine} />
        </div>
      )}

      <Button
        disabled={disabled}
        size="large"
        fullWidth
        style={classes.button}
        onClick={() => {
          window.open(UrlUtil.getGoogleSsoURL(continuationUrl), '_self')
        }}
      >
        <div style={classes.buttonContent}>
          <GoogleIcon />
          {variants[variant].buttonText}
        </div>
      </Button>
    </div>
  )
}

export default GoogleSSOButton
