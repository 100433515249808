import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CustomDialog from 'support/components/dialog/CustomDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import FileUploader from 'support/components/fileuploader/FileUploader'
import InputData from 'support/components/input/InputData'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({
  container: {
    alignItems: 'flex-start'
  },
  root: {
    minWidth: 300,
    maxWidth: 480,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  rootTitle: {
    borderBottom: '1px solid ' + theme.palette.divider,
    margin: 0,
    padding: '16px 16px 14px 16px',
    paddingLeft: theme.spacing(3)
  },
  titleMainContent: {
    marginRight: theme.spacing(4)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down(360)]: {
      padding: theme.spacing(2)
    }
  },
  contentHeaderContainer: {
    marginBottom: theme.spacing(2)
  }
})

class UploadFotosDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorsMap: createErrorsMap(),
      files: [],
      lista: []
    }
    this.dataFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    const { files } = this.state
    if (files && files.length > 0) {
      this.setState({ ajaxing: true })

      const formData = new FormData()
      formData.append('idCliente', this.props.cliente.id)
      formData.append('data', this.dataFunctionsMap.getDataAsInt())
      for (var i = 0; i < files.length; i++) {
        let file = files[i]
        formData.append('imagens[' + i + ']', file)
      }

      postAPI({
        url: 'erp/clientes.adicionarFotos',
        isMultiparFormData: true,
        data: formData,
        requerAutorizacao: true,
        timeout: 0,
        onSuccess: (response) => {
          EventsManager.pub('ManipulacaoFotoGaleria')
          this.props.handleCloseDialog()
        },
        onError: (response) => {
          if (response.code === 413) {
            this.setState({ ajaxing: false })
            EventsManager.pub('MensagemSnackBar', 'O total de dados enviados é muito grande. Selecione menos fotos por envio.')
          } else {
            this.setState({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) })
            focusFirstElementWithError('formClienteFotos')
          }
        }
      })
    } else {
      openBackableDialog(MessageDialog, {
        title: 'Carregue uma imagem',
        text: 'É necessário carregar imagens do seu computador/dispositivo para enviar novas fotos para o cadastro deste cliente.'
      })
    }
  }

  render() {
    const { classes } = this.props
    const { ajaxing, errorsMap, files } = this.state

    return (
      <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={this.props} disabledOnClose={this.state.ajaxing}>
        <DialogTitle disableTypography className={classes.rootTitle}>
          <Typography variant="h6" className={classes.titleMainContent}>
            Adicionar Fotos
          </Typography>
          {!ajaxing && (
            <IconButton className={classes.closeButton} onClick={this.props.handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form id="formClienteFotos" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <Grid container style={{ marginBottom: 24, marginRight: 4, marginTop: 8 }} alignItems="center">
              <Grid item xs>
                <InputData
                  disabled={ajaxing}
                  defaultValue={moment().toDate()}
                  hideMenuOptions={true}
                  functionsMap={this.dataFunctionsMap}
                  keyboard={true}
                  customVariant={'outlined-small'}
                  shrink={false}
                  id="xdata"
                  name="xdata"
                  errorMessage={errorsMap('data')}
                  label={'Data das Fotos'}
                />
              </Grid>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Typography variant="body1">Arquivos</Typography>
                <Typography variant="body2">{files.length === 1 ? '1 selecionado' : files.length + ' selecionados'}</Typography>
              </Grid>
            </Grid>

            <div style={{ display: ajaxing ? 'none' : 'block' }}>
              <FileUploader
                acceptOnlyBasicImageFormats={true}
                uploading={ajaxing}
                files={files}
                onDropChange={(files) => {
                  this.setState({
                    files: files
                  })
                }}
                eventTrackingFunction={(props) => {
                  if (props.status === 'uploading') {
                    this.setState({ uploading: true })
                  } else if (props.status === 'notuploaded') {
                    this.setState({ uploading: false })
                  }
                }}
                onRemoveFile={(index) => {
                  const files = this.state.files.slice(0)
                  files.splice(index, 1)
                  this.setState({
                    files: files
                  })
                }}
              />
            </div>

            <div style={{ textAlign: 'right', marginTop: 24 }}>
              <Button type="submit" disabled={ajaxing} variant="contained" color="secondary">
                {ajaxing ? (
                  <span>
                    <CircularProgress size={18} thickness={5.5} color="inherit" style={{ verticalAlign: 'middle', marginRight: 8 }} /> SALVANDO...
                  </span>
                ) : (
                  'Salvar'
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </CustomDialog>
    )
  }
}

UploadFotosDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UploadFotosDialog)
