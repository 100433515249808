import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import GerenciarPacoteDialogPage from 'pages/erp/painel/cliente/GerenciarPacoteDialogPage'
import HistoricoPacoteDialogPage from 'pages/erp/painel/cliente/HistoricoPacoteDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import TabContainer from 'support/components/tab/TabContainer'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  }
})

class TabPacotes extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      lista: [],
      cliente: this.props.cliente,
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
  }

  closeItemMenu() {
    this.setState((state) => ({
      menuAnchorEl: null
    }))
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoVenda', 'ManipulacaoVendas', 'ManutencaoPacote'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.dataCriacao = converterDataIntParaMoment(item.dataCriacao).format('DD/MMM/YY')

    content.descricao = item.servico.nome
    if (item.variacaoServico) {
      content.descricao += ' - ' + item.variacaoServico.nome
    }

    let descricaoCreditos = []

    descricaoCreditos.push(
      <span key="disponiveis" style={{ whiteSpace: 'nowrap' }}>
        Disponível: {item.quantidadeDisponivel}
      </span>
    )
    descricaoCreditos.push(
      <span key="usados">
        , <span style={{ whiteSpace: 'nowrap' }}>Usados: {item.quantidadeUtilizada}</span>
      </span>
    )

    let descricaoSituacao = ''

    if (item.quantidadeCancelados > 0) {
      descricaoCreditos.push(
        <span key="cancelados">
          , <span style={{ whiteSpace: 'nowrap' }}>Cancelados: {item.quantidadeCancelados}</span>
        </span>
      )
    }

    if (item.quantidadeDisponivel > 0) {
      if (item.dataVencimento) {
        let dataVencimentoFormatada = converterDataIntParaMoment(item.dataVencimento).format('DD/MMM/YY')

        if (item.pacoteItemVencido) {
          descricaoCreditos = <strike>{descricaoCreditos}</strike>
          descricaoSituacao = 'Vencido em ' + dataVencimentoFormatada
        } else {
          descricaoSituacao = 'Vencimento: ' + dataVencimentoFormatada
        }
      }
    }

    descricaoSituacao = (
      <React.Fragment>
        <br />
        {descricaoSituacao}
      </React.Fragment>
    )
    if (item.cancelado) {
      content.descricao = <strike>{content.descricao}</strike>
      descricaoCreditos = <strike>{descricaoCreditos}</strike>
    }
    content.informacoesCreditos = (
      <React.Fragment>
        {descricaoCreditos}
        {descricaoSituacao}
      </React.Fragment>
    )
    content.botaoOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -4 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ menuAnchorEl: event.currentTarget, itemMenu: item })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )

    return content
  }

  abrirHistoricoPacote = (item) => {
    openBackableDialog(HistoricoPacoteDialogPage, {
      idClientePacoteItem: item.id
    })
  }

  gerenciarPacote = (item) => {
    openBackableDialog(GerenciarPacoteDialogPage, {
      clientePacoteItem: item,
      cliente: this.state.cliente
    })
  }

  render() {
    const { classes } = this.props
    const { cliente } = this.state

    return (
      <TabContainer>
        <VirtualizedResponsiveTable
          minTableWidth={320}
          scrollElement={this.props.scrollElement}
          showBackgroundDividers={true}
          itemsPerRequest={30}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/clientes.buscarPacoteItens"
          getRequestParametersFunction={() => ({
            idCliente: cliente.id
          })}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const lista = []

              for (let item of data.items) {
                if (item.dataVencimento) {
                  if (converterDataIntParaMoment(item.dataVencimento).isBefore(moment(), 'day')) {
                    item.pacoteItemVencido = true
                  } else {
                    item.pacoteItemVencido = false
                  }
                } else {
                  item.pacoteItemVencido = false
                }

                lista.push(item)
              }

              this.setState({ lista: lista })
            }
          }}
          largeRenderProps={{
            rowHeight: 74,
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Informações', props: { xs: true } },
              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('large', item)
              return {
                itemData: [
                  content.dataCriacao,
                  <React.Fragment>
                    <Typography variant="body2" noWrap={true}>
                      {content.descricao}
                    </Typography>
                    <Typography variant="body2">
                      <small>{content.informacoesCreditos}</small>
                    </Typography>
                  </React.Fragment>,
                  content.botaoOpcoes
                ]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 92,
            showFirstItemBorderTop: false,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)
              return {
                itemData: (
                  <Grid container alignItems="center">
                    <Grid item xs style={{ minWidth: 0 /*minWidth - para ativar as reticências */ }}>
                      <Typography variant="body2">Comprado em {content.dataCriacao}</Typography>
                      <Typography variant="body2" noWrap={true}>
                        <b>{content.descricao}</b>
                      </Typography>
                      <Typography variant="body2">
                        <small>{content.informacoesCreditos}</small>
                      </Typography>
                    </Grid>
                    <Grid item>{content.botaoOpcoes}</Grid>
                  </Grid>
                )
              }
            }
          }}
          emptyListProps={{
            padding: false,
            text: (
              <span style={{ maxWidth: 470, display: 'inline-block' }}>
                Este cliente não possui nenhum pacote. Os pacotes são contabilizados na conta do cliente <b>apenas após a geração da fatura da venda</b>.
              </span>
            )
          }}
        />

        <Menu anchorEl={this.state.menuAnchorEl} open={Boolean(this.state.menuAnchorEl)} onClose={() => this.closeItemMenu()}>
          <MenuItem
            onClick={() => {
              this.abrirHistoricoPacote(this.state.itemMenu)
              this.closeItemMenu()
            }}
          >
            Ver Detalhes
          </MenuItem>
          <MenuItem
            disabled={!verificarAutorizacao([PA.PODE_GERENCIAR_PACOTE]) || (this.state.itemMenu && (this.state.itemMenu.cancelado || this.state.itemMenu.pacoteItemVencido))}
            onClick={() => {
              this.gerenciarPacote(this.state.itemMenu)
              this.closeItemMenu()
            }}
          >
            Gerenciar Pacote
          </MenuItem>
        </Menu>
      </TabContainer>
    )
  }

  //this.abrirHistoricoPacote(event,item);

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabPacotes.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabPacotes)
