import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CustomDialog from 'support/components/dialog/CustomDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import ColaboradorNotificacoes from 'support/domain/colaborador/ColaboradorNotificacoes'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons,
  containerGrupoPermissoes: {
    ppadding: '24px 32px',
    '&:not(:first-child)': {
      borderTop: '1px solid #e6e6e6',
      paddingTop: 24
    },
    marginTop: 20,
    borderRadius: 4,
    [theme.breakpoints.down(410)]: {
      marginTop: 12,
      padding: 0,
      marginBottom: 24
    }
  },
  textCheckbox: {
    paddingTop: 6,
    paddingBottom: 6
  }
})

class ConfiguracoesNotificacoesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: 'Notificações',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.containerFunctionMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/colaboradores.buscarPorId',
      params: {
        id: this.props.colaborador.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.atualizarColaborador(response.data)
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  atualizarColaborador = (colaborador) => {
    this.setState({
      ajaxing: false,
      colaborador: colaborador,
      notificacoes: ColaboradorNotificacoes.generateAllItems(colaborador.notificacoes)
    })
  }

  handleChangeNotificacao = (notificacaoAlterada, event) => {
    const checked = event.target.checked
    const positionsToIgnore = [notificacaoAlterada.position]
    const notificacoes = this.state.notificacoes

    for (let notificacao of notificacoes) {
      if (notificacao.exists === checked) {
        positionsToIgnore.push(notificacao.position)
      }
    }

    const notificacoesDependentes = ColaboradorNotificacoes.buscarDependencias(notificacaoAlterada, checked, positionsToIgnore)

    if (notificacoesDependentes.length) {
      openBackableDialog(NotificacoesDependentesDialog, {
        inclusao: checked,
        notificacoesDependentes: notificacoesDependentes,
        actionFunction: () => {
          this.changeNotificacoes(notificacoesDependentes.concat(notificacaoAlterada), checked)
        }
      })
    } else {
      this.changeNotificacoes(notificacaoAlterada, checked)
    }
  }

  changeNotificacoes = (notificacoesParaAlteracao, checked) => {
    const changeNotificacaoFunction = (notificacoes, notificacaoParaAlterar) => {
      for (let notificacao of notificacoes) {
        if (notificacao.position === notificacaoParaAlterar.position) {
          notificacao.exists = checked
          break
        }
      }
    }

    const notificacoes = this.state.notificacoes
    if (Array.isArray(notificacoesParaAlteracao)) {
      for (let notificacaoParaAlteracao of notificacoesParaAlteracao) {
        changeNotificacaoFunction(notificacoes, notificacaoParaAlteracao)
      }
    } else {
      changeNotificacaoFunction(notificacoes, notificacoesParaAlteracao)
    }
    this.setState({ notificacoes: notificacoes })
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/colaboradores.alterarNotificacoes',
      data: {
        idColaborador: this.props.colaborador.id,
        notificacoes: ColaboradorNotificacoes.getString(this.state.notificacoes)
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        const colaborador = response.data
        EventsManager.pub('ManipulacaoColaborador', { colaborador: colaborador })
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { notificacoes } = this.state
    const agrupamentoNotificacoes = []

    if (notificacoes && notificacoes.length) {
      for (let i = 0; i < notificacoes.length; i++) {
        const notificacao = notificacoes[i]
        let agrupamentoAtual = null
        if (agrupamentoNotificacoes.length > 0) {
          agrupamentoAtual = agrupamentoNotificacoes[agrupamentoNotificacoes.length - 1]
        }
        if (agrupamentoAtual === null || agrupamentoAtual.nomeGrupo !== notificacao.grupo) {
          agrupamentoAtual = {
            nomeGrupo: notificacao.grupo,
            notificacoes: []
          }
          agrupamentoNotificacoes.push(agrupamentoAtual)
        }
        agrupamentoAtual.notificacoes.push(notificacao)
      }
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={505}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => {
            let content = null

            if (!verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])) {
              content = (
                <Typography variant="body2" align="center">
                  Você não tem permissao para acessar estas informações.
                </Typography>
              )
            } else {
              content = (
                <form id="formColaboradorNotificacoes" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  {agrupamentoNotificacoes.map((agrupamento) => (
                    <div key={agrupamento.nomeGrupo} className={classes.containerGrupoPermissoes}>
                      <div>
                        <Grid container wrap="nowrap" alignItems="center">
                          <Grid item style={{ marginRight: 14, marginLeft: 4 }}>
                            <NotificationsIcon style={{ color: '#3a3a3a' }} />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">{agrupamento.nomeGrupo}</Typography>
                          </Grid>
                        </Grid>

                        <FormGroup style={{ marginTop: 12, marginLeft: 10 }}>
                          {agrupamento.notificacoes.map((notificacao) => (
                            <FormControlLabel
                              key={notificacao.position}
                              control={
                                <Checkbox
                                  style={{ padding: 5, marginRight: 8 }}
                                  color="primary"
                                  disabled={this.state.ajaxing}
                                  checked={notificacao.exists}
                                  onChange={(event) => this.handleChangeNotificacao(notificacao, event)}
                                />
                              }
                              label={
                                <Typography variant="body2" className={classes.textCheckbox}>
                                  {notificacao.descricao}
                                </Typography>
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </div>
                  ))}

                  <HiddenSubmitButton />

                  {this.state.paginaCarregada && (
                    <div className={classes.containerFooterButtons}>
                      <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                        Cancelar
                      </Button>
                      <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                        {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                      </Button>
                    </div>
                  )}
                </form>
              )
            }
            return content
          }}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesNotificacoesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesNotificacoesDialogPage)

class NotificacoesDependentesDialog extends Component {
  render() {
    const { inclusao } = this.props

    let descricao = 'Ao adicionar essa notificação, o sistema também adicionará as seguintes notificações:'
    if (!inclusao) {
      descricao = 'Ao remover essa notificação, o sistema também removerá as seguintes notificações:'
    }

    return (
      <CustomDialog dialogProps={this.props}>
        <DialogContent>
          <Typography variant="body1" style={{ paddingBottom: 10, paddingTop: 16 }}>
            {descricao}
          </Typography>
          <div style={{ marginTop: 10, marginLeft: 16 }}>
            {this.props.notificacoesDependentes.map((notificacao) => (
              <Grid key={notificacao.position} container wrap="nowrap" alignItems="center" spacing={2}>
                <Grid item>
                  <NotificationsIcon fontSize="small" style={{ display: 'block' }} />
                </Grid>
                <Grid item>
                  <Typography variant="body2">{notificacao.descricao}</Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleCloseDialog} color="secondary">
            CANCELAR
          </Button>
          <Button
            onClick={() => {
              this.props.handleCloseDialog({
                onClosedCallback: () => {
                  this.props.actionFunction()
                }
              })
            }}
            color="secondary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}
