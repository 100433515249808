import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  MENSAL: { id: 1, descricao: 'Mensal', quantidadeMeses: 1 },
  TRIMESTRAL: { id: 3, descricao: 'Trimestral', quantidadeMeses: 3 },
  SEMESTRAL: { id: 4, descricao: 'Semestral', quantidadeMeses: 6 },
  ANUAL: { id: 2, descricao: 'Anual', quantidadeMeses: 12 }
})

export default enumObject
