import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import CategoriaInputDialogSelect from 'pages/erp/painel/inventario/input/CategoriaInputDialogSelect'
import FornecedorInputDialogSelect from 'pages/erp/painel/inventario/input/FornecedorInputDialogSelect'
import MarcaInputDialogSelect from 'pages/erp/painel/inventario/input/MarcaInputDialogSelect'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import FormHelperText from 'support/components/input/core/FormHelperText'
import InputPorcentagemValor from 'support/components/input/InputPorcentagemValor'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputFloatNumberFormat, InputMoneyFormat, InputNumberFormat, InputStringNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ProdutoDadosBasicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idProduto ? 'Novo Produto' : 'Alterar Produto',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      produto: {
        controleEstoque: true,
        controleVendaExterna: true,
        arquivado: false
      }
    }

    this.inputNome = React.createRef()
    this.inputPrecoCompra = React.createRef()
    this.inputCodigoBarras = React.createRef()
    this.inputSku = React.createRef()
    this.inputQuantidadeEmbalagem = React.createRef()
    this.inputUnidadeMedidaEmbalagem = React.createRef()
    this.inputEstoqueInicial = React.createRef()
    this.inputEstoqueMinimo = React.createRef()
    this.inputPrecoUnitario = React.createRef()
    this.inputValorVendaExterna = React.createRef()

    this.autocompleteCategoria = {}
    this.autocompleteMarca = {}
    this.autocompleteFornecedor = {}
    this.inputComissao = {}
    this.inputCaixaFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/inventario.buscarDadosParaPersistirProduto',
      params: {
        id: this.props.idProduto ? this.props.idProduto : null
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          produto: response.data.produto ? response.data.produto : this.state.produto,
          paginaCarregada: true,
          toolbarActions: {
            actions: [
              {
                showOnlyMobile: true,
                label: 'Salvar',
                handleAction: () => this.salvar()
              }
            ]
          }
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    const { quantidadeEmbalagem, unidadeMedidaEmbalagem } = this.state.produto ? this.state.produto : {}
    const quantidadeEmbalagemNova = FormUtil.convertStringToNumber(this.inputQuantidadeEmbalagem.current.value)
    const unidadeMedidaEmbalagemNova = this.inputUnidadeMedidaEmbalagem.current.value

    if ((!quantidadeEmbalagem || !unidadeMedidaEmbalagem) && quantidadeEmbalagemNova && unidadeMedidaEmbalagemNova) {
      openBackableDialog(EntityConfirmationDialog, {
        parent: this,
        closeOnSuccess: true,
        title: 'Confirmação',
        text: (
          <span>
            Você tem certeza que deseja salvar este produto, com as informações quantidade embalagem <b style={{ background: '#eee', padding: 5 }}>{quantidadeEmbalagemNova}</b> e
            unidade medida embalagem <b style={{ background: '#eee', padding: 5 }}>{unidadeMedidaEmbalagemNova}</b> ? Estas informações não poderão ser alteradas posteriormente.
          </span>
        ),
        cancelButtonLabel: 'VOLTAR',
        confirmButtonLabel: 'Confirmar',
        call: {
          method: 'post',
          url: 'erp/inventario.persistirProduto',
          contextoUsuario: 'erp',
          data: this.getDadosProduto(),
          onSuccess: (response, dialogConfirmationInstance) => {
            EventsManager.pub('ManipulacaoProduto', { produto: response.data })
            dialogConfirmationInstance.props.setShouldCloseParent(true)
            dialogConfirmationInstance.props.handleCloseDialog()
          },
          onError: (response, dialogConfirmationInstance) => {
            this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
            focusFirstElementWithError('formProduto')
            dialogConfirmationInstance.props.handleCloseDialog()
          }
        }
      })
    } else {
      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      postAPI({
        url: 'erp/inventario.persistirProduto',
        data: this.getDadosProduto(),
        requerAutorizacao: true,
        onSuccess: (response) => {
          EventsManager.pub('ManipulacaoProduto', { produto: response.data })
          this.props.handleCloseDialog()
        },
        onError: (response) => {
          this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
          focusFirstElementWithError('formProduto')
        }
      })
    }
  }

  getDadosProduto = () => {
    const dados = {}
    if (this.props.idProduto) {
      dados.id = this.props.idProduto
    }

    dados.nome = this.inputNome.current.value
    dados.precoCompra = FormUtil.convertStringToNumber(this.inputPrecoCompra.current.value)
    dados.quantidadeEmbalagem = FormUtil.convertStringToNumber(this.inputQuantidadeEmbalagem.current.value)

    if (this.inputUnidadeMedidaEmbalagem.current.value) {
      dados.unidadeMedidaEmbalagem = this.inputUnidadeMedidaEmbalagem.current.value
    }

    dados.codigoBarras = this.inputCodigoBarras.current.value
    dados.sku = this.inputSku.current.value
    dados.controleEstoque = this.state.produto.controleEstoque

    if (dados.controleEstoque) {
      if (!this.props.idProduto) {
        if (!this.inputEstoqueInicial.current.value) {
          dados.estoqueInicial = 0
        } else {
          dados.estoqueInicial = FormUtil.convertStringToNumber(this.inputEstoqueInicial.current.value)
        }
      }

      if (!this.inputEstoqueMinimo.current.value) {
        dados.estoqueMinimo = 0
      } else {
        dados.estoqueMinimo = FormUtil.convertStringToNumber(this.inputEstoqueMinimo.current.value)
      }
    }

    dados.vendaExterna = this.state.produto.controleVendaExterna

    if (dados.vendaExterna) {
      dados.valorVendaExterna = FormUtil.convertStringToNumber(this.inputValorVendaExterna.current.value)
      dados.comissaoVenda = FormUtil.convertStringToNumber(this.inputComissao.getValor())
      dados.tipoCalculoComissao = this.inputComissao.getTipoCalculo()
    }

    dados.arquivado = this.state.produto.arquivado

    dados.categoria = {
      id: this.autocompleteCategoria.getValue()
    }
    dados.marca = {
      id: this.autocompleteMarca.getValue()
    }
    dados.fornecedor = {
      id: this.autocompleteFornecedor.getValue ? this.autocompleteFornecedor.getValue() : null
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state
    const {
      nome,
      precoCompraMaisRecente,
      categoria,
      quantidadeEmbalagem,
      unidadeMedidaEmbalagem,
      marca,
      sku,
      codigoBarras,
      controleEstoque,
      controleVendaExterna,
      valorVendaExterna,
      fornecedor,
      estoqueMinimo,
      arquivado,
      tipoCalculoComissaoVenda,
      comissaoVenda
    } = this.state.produto ? this.state.produto : {}

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    const smEstoqueMinimo = !this.props.idProduto ? 6 : 12

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formProduto" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                customVariant="outlined-small"
                defaultValue={nomeDefaultValue}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome do Produto"
                placeholder=""
                inputProps={{
                  maxLength: 255
                }}
                inputRef={this.inputNome}
              />

              <InputText
                marginTop={true}
                customVariant="outlined-small"
                id="xprecoCompra"
                defaultValue={precoCompraMaisRecente}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('precoCompra')}
                label="Preço de Compra (R$)"
                placeholder="0,00"
                inputProps={{
                  minValue: 0,
                  maxValue: 999999
                }}
                inputComponent={InputMoneyFormat}
                inputRef={this.inputPrecoCompra}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputText
                    idname="xquantidadeEmbalagem"
                    customVariant="outlined-small"
                    defaultValue={quantidadeEmbalagem}
                    marginTop={true}
                    disabled={this.state.ajaxing || quantidadeEmbalagem ? true : false}
                    errorMessage={errorsMap('quantidadeEmbalagem')}
                    label="Quantidade por embalagem"
                    helpMessage="ex. 300 (para 300ml)"
                    inputRef={this.inputQuantidadeEmbalagem}
                    inputProps={{
                      maxLength: 7
                    }}
                    inputComponent={InputFloatNumberFormat}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputText
                    idname="xunidadeMedida"
                    customVariant="outlined-small"
                    marginTop={true}
                    defaultValue={unidadeMedidaEmbalagem}
                    disabled={this.state.ajaxing || unidadeMedidaEmbalagem ? true : false}
                    errorMessage={errorsMap('unidadeMedidaEmbalagem')}
                    label="Unidade de Medida"
                    helpMessage="Ex: ml, l, mg, gr, unid e etc"
                    inputProps={{
                      maxLength: 4
                    }}
                    inputRef={this.inputUnidadeMedidaEmbalagem}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CategoriaInputDialogSelect
                    marginTop={true}
                    customVariant="outlined-small"
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('categoria')}
                    placeholder="Selecione a Categoria"
                    defaultValue={categoria}
                    functionsMap={this.autocompleteCategoria}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MarcaInputDialogSelect
                    marginTop={true}
                    customVariant="outlined-small"
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('marca')}
                    placeholder="Selecione a Marca"
                    defaultValue={marca}
                    functionsMap={this.autocompleteMarca}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputText
                    id="xcodigobarras"
                    customVariant="outlined-small"
                    defaultValue={codigoBarras}
                    marginTop={true}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('codigoBarras')}
                    label="Código de Barras"
                    placeholder="ex. 1234567890123"
                    inputRef={this.inputCodigoBarras}
                    inputProps={{
                      maxLength: 13
                    }}
                    inputComponent={InputStringNumberFormat}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputText
                    id="xsku"
                    customVariant="outlined-small"
                    defaultValue={sku}
                    marginTop={true}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('codigoBarras')}
                    label="SKU"
                    placeholder="ex. 123ABC"
                    inputProps={{
                      maxLength: 120
                    }}
                    helpMessage={'Código para controle interno'}
                    inputRef={this.inputSku}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                style={{ marginTop: 16 }}
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.ajaxing}
                    checked={arquivado}
                    onChange={(event) => {
                      const checked = event.target.checked
                      this.setState((state) => {
                        state.produto.arquivado = checked
                        return { produto: state.produto }
                      })
                    }}
                  />
                }
                label={<Typography variant="body2">Arquivar Produto</Typography>}
              />
              <FormHelperText>Produtos arquivados não são exibidos nas listagens de produtos</FormHelperText>

              <Grid container alignItems="center">
                <Grid item xs>
                  <SectionSeparator icon={<SwapVertIcon />} title="Controle de Estoque" />
                </Grid>
                <Grid item>
                  <Switch
                    checked={controleEstoque}
                    onChange={(event) => {
                      const checked = event.target.checked
                      this.setState((state) => {
                        state.produto.controleEstoque = checked
                        return { produto: state.produto }
                      })
                    }}
                    color="secondary"
                  />
                </Grid>
              </Grid>

              {controleEstoque && (
                <React.Fragment>
                  <Grid container spacing={2}>
                    {!this.props.idProduto && (
                      <Grid item xs={12} sm={6}>
                        <InputText
                          customVariant="outlined-small"
                          id="xestoqueInicial"
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('estoqueInicial')}
                          label="Estoque Inicial (Em unidades)"
                          placeholder="0"
                          inputComponent={InputNumberFormat}
                          inputProps={{
                            minValue: 1,
                            maxValue: 999999
                          }}
                          inputRef={this.inputEstoqueInicial}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={smEstoqueMinimo}>
                      <InputText
                        customVariant="outlined-small"
                        id="xestoqueminimo"
                        defaultValue={estoqueMinimo}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('estoqueMinimo')}
                        label="Estoque Mínimo"
                        placeholder="0"
                        helpMessage={'Auxilia a identificação do produto com estoque baixo'}
                        inputProps={{
                          minValue: 1,
                          maxValue: 999999
                        }}
                        inputComponent={InputNumberFormat}
                        inputRef={this.inputEstoqueMinimo}
                      />
                    </Grid>
                  </Grid>

                  <FornecedorInputDialogSelect
                    marginTop={true}
                    customVariant="outlined-small"
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('fornecedor')}
                    placeholder="Selecione o fornecedor"
                    defaultValue={fornecedor}
                    functionsMap={this.autocompleteFornecedor}
                  />
                </React.Fragment>
              )}

              <Grid container alignItems="center">
                <Grid item xs>
                  <SectionSeparator icon={<MonetizationOnIcon />} title="Dados para Venda" />
                </Grid>
                <Grid item>
                  <Switch
                    checked={controleVendaExterna}
                    onChange={(event) => {
                      const checked = event.target.checked
                      this.setState((state) => {
                        state.produto.controleVendaExterna = checked
                        return { produto: state.produto }
                      })
                    }}
                    color="secondary"
                  />
                </Grid>
              </Grid>

              {controleVendaExterna && (
                <React.Fragment>
                  <InputText
                    customVariant="outlined-small"
                    id="xvalorvendaexterna"
                    defaultValue={valorVendaExterna}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('valorVendaExterna')}
                    label="Preço de Venda (R$)"
                    placeholder="0,00"
                    inputProps={{
                      minValue: 0,
                      maxValue: 999999
                    }}
                    inputComponent={InputMoneyFormat}
                    inputRef={this.inputValorVendaExterna}
                  />

                  <InputPorcentagemValor
                    customVariant="outlined-small"
                    idname="xcomissao"
                    label="Comissão de venda"
                    marginTop={true}
                    defaultValor={comissaoVenda}
                    defaultTipoCalculo={tipoCalculoComissaoVenda}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('comissao')}
                    functionsMap={this.inputComissao}
                  />
                </React.Fragment>
              )}

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ProdutoDadosBasicosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProdutoDadosBasicosDialogPage)
