import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import FichaPerguntaDialogPage from 'pages/erp/painel/fichacliente/FichaPerguntaDialogPage'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { ordenarItens } from 'support/domain/fichacliente/FichaClienteService'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  },
  containerFooterButtons: theme.form.containerFooterButtons(),
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 3 })
  }
})

class FichaPerguntasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: 'Ficha',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      perguntas: []
    }

    this.inputNome = React.createRef()
    this.keyGenerator = 0
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idFichaCliente) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/fichascliente.buscarFichaPorId',
        params: {
          id: this.props.idFichaCliente
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          if (response.data.perguntas) {
            response.data.perguntas.forEach((pergunta, index) => {
              response.data.perguntas.key = this.generateKeyId()
              response.data.perguntas.posicao = pergunta.posicao
            })
          }

          ordenarItens(response.data.perguntas)

          this.setState((state) => ({
            perguntas: response.data.perguntas,
            nome: response.data.nome
          }))

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  moverCimaPergunta = (pergunta) => {
    const index = pergunta.posicao - 1
    const perguntas = this.state.perguntas.slice()

    const perguntaAnterior = perguntas[index - 1]
    const perguntaAtual = perguntas[index]
    perguntaAnterior.posicao++
    perguntaAnterior._update = true
    perguntaAtual.posicao--
    perguntaAtual._update = true
    perguntas.splice(index - 1, 1, perguntaAtual)
    perguntas.splice(index, 1, perguntaAnterior)

    this.setState({ perguntas: perguntas })
  }

  moverBaixoPergunta = (pergunta) => {
    const index = pergunta.posicao - 1
    const perguntas = this.state.perguntas.slice()

    const proximoPergunta = perguntas[index + 1]
    const perguntaAtual = perguntas[index]
    proximoPergunta.posicao--
    proximoPergunta._update = true
    perguntaAtual.posicao++
    perguntaAtual._update = true
    perguntas.splice(index + 1, 1, perguntaAtual)
    perguntas.splice(index, 1, proximoPergunta)

    this.setState({ perguntas: perguntas })
  }

  adicionarPergunta = () => {
    const posicao = this.state.perguntas.length > 0 ? this.state.perguntas[this.state.perguntas.length - 1].posicao + 1 : 1

    openBackableDialog(FichaPerguntaDialogPage, {
      adicionarPergunta: (perguntaRetorno) => {
        const perguntas = this.state.perguntas.slice()
        perguntaRetorno.key = this.generateKeyId()
        perguntas.push(perguntaRetorno)
        this.setState({ perguntas: perguntas })
      },
      posicao: posicao
    })
  }

  closePerguntaMenu() {
    this.setState((state) => ({
      itemMenuAnchorEl: null
    }))
  }

  generateKeyId = () => {
    return ++this.keyGenerator
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    this.persistirModelo()
  }

  persistirModelo = () => {
    postAPI({
      url: 'erp/fichascliente.persistirFicha',
      data: this.getDadosModelo(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoFichaCliente')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formModelo')
      }
    })
  }

  getDadosModelo = () => {
    const dados = {}

    if (this.props.idFichaCliente) {
      dados.id = this.props.idFichaCliente
    }

    dados.nome = this.inputNome.current.value
    dados.perguntas = []

    this.state.perguntas.forEach((pergunta, index) => {
      dados.perguntas.push(pergunta)
    })

    return dados
  }

  handlerRemover(index) {
    const perguntas = this.state.perguntas.slice()
    perguntas.splice(index, 1)
    if (perguntas.length !== 0) {
      perguntas.forEach((pergunta, indexPergunta) => {
        if (indexPergunta >= index) {
          pergunta.posicao--
          pergunta._update = true
        }
      })
    }

    this.setState({ perguntas: perguntas })
  }

  handlerEditar = (pergunta) => {
    openBackableDialog(FichaPerguntaDialogPage, {
      pergunta: pergunta,
      adicionarPergunta: (perguntaRetorno) => {
        perguntaRetorno.key = pergunta.key
        perguntaRetorno._update = true
        const perguntas = this.state.perguntas.slice()
        perguntas[perguntaRetorno.posicao - 1] = perguntaRetorno
        this.setState({ perguntas: perguntas })
      },
      posicao: pergunta.posicao
    })
  }

  getRenderContent = (size, pergunta) => {
    const content = {}

    content.descricao = pergunta.descricao
    content.botaoMaisOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -16 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ itemMenuAnchorEl: event.currentTarget, itemMenu: pergunta })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, nome, perguntas } = this.state
    const itensErro = errorsMap('perguntas')

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <ContentWithPreload loadFunction={this.carregarMain}>
              {() => (
                <form id="formModelo" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                  <InputText
                    id="xnome"
                    defaultValue={nome}
                    autoFocus={true}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('nome')}
                    label="Nome da ficha"
                    customVariant={'outlined-small'}
                    shrink={false}
                    inputProps={{
                      maxLength: 200
                    }}
                    inputRef={this.inputNome}
                  />

                  {perguntas && perguntas.length > 0 && (
                    <React.Fragment>
                      <SectionSeparator icon={<FormatListBulletedIcon />} title="Lista de Perguntas" />

                      <div>
                        <VirtualizedResponsiveTable
                          scrollElement={dialogContentProps.scrollContainerRef.current}
                          showBackgroundDividers={true}
                          showHeaderColumns={false}
                          loaderFunctionsMap={this.loaderFunctionsMap}
                          items={this.state.perguntas}
                          onClickRow={(item, event) => {
                            this.handlerEditar(item)
                          }}
                          largeRenderProps={{
                            columns: [
                              { label: 'Pergunta', props: { xs: true }, horizontalPadding: 'none' },
                              { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                            ],
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('large', item)
                              return {
                                itemData: [content.descricao, content.botaoMaisOpcoes]
                              }
                            }
                          }}
                          smallRenderProps={{
                            rowHeight: 80,
                            horizontalPadding: 'none',
                            itemRenderer: (item, index) => {
                              const content = this.getRenderContent('small', item)
                              return {
                                itemData: (
                                  <React.Fragment>
                                    <Grid container alignItems="center" style={{ minWidth: 0, backgroundColor: 'inherit' }}>
                                      <Grid item xs style={{ minWidth: 0, backgroundColor: 'inherit' }}>
                                        <Typography variant="body2" className={classes.descricaoContainerSmall}>
                                          {content.descricao}
                                        </Typography>
                                      </Grid>
                                      <Grid item>{content.botaoMaisOpcoes}</Grid>
                                    </Grid>
                                  </React.Fragment>
                                )
                              }
                            }
                          }}
                        />
                      </div>

                      <div style={{ textAlign: 'right', marginTop: 20 }}>
                        <Button color="secondary" type="button" onClick={this.adicionarPergunta}>
                          <span style={{ marginTop: 1, display: 'inline-block', lineHeight: '22px' }}>Adicionar pergunta</span> <AddIcon style={{ marginLeft: 8 }} />
                        </Button>
                      </div>
                    </React.Fragment>
                  )}

                  {perguntas && perguntas.length === 0 && (
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                      <FormControl
                        style={{ marginLeft: 4, marginTop: 15, alignPerguntas: 'center', justifyContent: 'center' }}
                        error={FormUtil.errorFlag(itensErro)}
                        className={FormUtil.errorClass(itensErro)}
                      >
                        <FormHelperText style={{ textAlign: 'center' }}>{itensErro}</FormHelperText>

                        <Button color="secondary" type="button" onClick={this.adicionarPergunta}>
                          Adicionar pergunta
                        </Button>
                      </FormControl>
                    </div>
                  )}

                  <HiddenSubmitButton />
                </form>
              )}
            </ContentWithPreload>

            <Popover anchorEl={this.state.itemMenuAnchorEl} open={Boolean(this.state.itemMenuAnchorEl)} onClose={() => this.closePerguntaMenu()}>
              {this.state.itemMenu && this.state.perguntas.length > 0 && this.state.itemMenu.posicao !== this.state.perguntas[0].posicao && (
                <MenuItem
                  onClick={() => {
                    this.moverCimaPergunta(this.state.itemMenu)
                    this.closePerguntaMenu()
                  }}
                >
                  Mover para cima
                </MenuItem>
              )}
              {this.state.itemMenu && this.state.perguntas.length > 0 && this.state.itemMenu.posicao !== this.state.perguntas[this.state.perguntas.length - 1].posicao && (
                <MenuItem
                  onClick={() => {
                    this.moverBaixoPergunta(this.state.itemMenu)
                    this.closePerguntaMenu()
                  }}
                >
                  Mover para baixo
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  this.handlerRemover(this.state.itemMenu.posicao - 1)
                  this.closePerguntaMenu()
                }}
              >
                Remover
              </MenuItem>
            </Popover>

            {this.state.paginaCarregada && (
              <div className={classes.containerFooterButtons}>
                <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                  Cancelar
                </Button>
                <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                  {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

FichaPerguntasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FichaPerguntasDialogPage)
