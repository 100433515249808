import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import SimpleExpansionPanel from 'support/components/expansionpanel/SimpleExpansionPanel'

const styles = (theme) => ({
  panelRoot: {
    //boxShadow: "0px -1px 0px 0px rgba(0,0,0,0.1), 0px 1px 0px 0px rgba(0,0,0,0.1), 1px 0px 0px 0px rgba(0, 0, 0, 0.1), -1px 0px 1px 0px rgba(0,0,0,0.1)",
    border: 'none',
    borderBottom: '0px solid #e8e8e8',
    borderRadius: 0,
    padding: '0px',
    '&:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  panelExpanded: {
    borderBottom: '0px solid #e8e8e8',
    '&:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  summaryRoot: {
    padding: '0px',
    '&$summaryExpanded': {
      paddingTop: '0px',
      paddingBottom: '0px',
      borderBottom: '0px solid #e8e8e8',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  summaryContent: {
    margin: '14px 0 14px',
    '&$summaryExpanded': {
      margin: '14px 0 14px'
    }
  },
  summaryExpanded: {},
  detailsContainer: {
    padding: '0px'
  }
})

class NakedExpansionPanel extends Component {
  render() {
    return <SimpleExpansionPanel {...this.props} />
  }
}

NakedExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NakedExpansionPanel)
