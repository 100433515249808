import React, { Component } from 'react'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'
import FileUploader from 'support/components/fileuploader/FileUploader'

const styles = (theme) => ({
  root: {
    minWidth: 260,
    maxWidth: 790,
    width: '100%',
    margin: 4
  },
  rootTitle: {
    borderBottom: '1px solid ' + theme.palette.divider,
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContent: {
    maxHeight: 1200,
    padding: 0
  }
})

class FileUploaderDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false
    }
    this.functionMapUpload = {}
  }

  componentDidMount = () => {
    const { cropsProps } = this.props
    if (cropsProps) {
      if (!cropsProps.imageStyle) {
        cropsProps.imageStyle = {}
      }
      cropsProps.imageStyle.maxHeight = 'calc(100vh - 166px)'
      if (cropsProps.saveCroppedImageFunction) {
        const originalSaveCroppedImageFunction = cropsProps.saveCroppedImageFunction
        const modifiedSaveCroppedImageFunction = (data, onStart, onSuccess, onError) => {
          const modifiedOnSuccess = () => {
            this.props.handleCloseDialog()
            onSuccess()
          }
          originalSaveCroppedImageFunction(data, onStart, modifiedOnSuccess, onError)
        }
        cropsProps.saveCroppedImageFunction = modifiedSaveCroppedImageFunction
      }
    }
  }

  render() {
    const { classes, cropsProps } = this.props
    const { uploading } = this.state

    return (
      <CustomDialog classes={{ paper: classes.root }} dialogProps={this.props} disabledOnClose={uploading}>
        <DialogTitle disableTypography className={classes.rootTitle}>
          <Typography key="title" variant="h6" style={{ marginBottom: -2 }}>
            {this.props.title}
          </Typography>
          <IconButton disabled={uploading} className={classes.closeButton} onClick={this.props.handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <FileUploader
            dropzonePadding={true}
            functionMap={this.functionMapUpload}
            uploading={uploading}
            eventTrackingFunction={(props) => {
              if (props.status === 'uploading') {
                this.setState({ uploading: true })
              } else if (props.status === 'notuploaded') {
                this.setState({ uploading: false })
              }
            }}
            previewBorderRadius={this.props.previewBorderRadius}
            cropsProps={cropsProps}
          />
        </DialogContent>
      </CustomDialog>
    )
  }
}

FileUploaderDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FileUploaderDialog)
