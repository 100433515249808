import React from 'react'

import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  HABILITADO: {
    id: 1,
    descricaoOpcao: 'Habilitado',
    descricaoExplicacao: <span>Esta opção habilita o Agendamento Online para os seus clientes.</span>,
    descricaoExplicacaoColaborador: <span>Clientes podem selecionar este profissional para marcar Agendamentos Online.</span>,
    descricaoExplicacaoCliente: <span>Este cliente pode marcar Agendamentos Online.</span>,
    descricaoExplicacaoCategoriaServico: <span>Clientes podem selecionar serviços desta categoria para marcar Agendamentos Online.</span>,
    descricaoExplicacaoServico: <span>Clientes podem selecionar este serviço para marcar Agendamentos Online.</span>
  },
  DESABILITADO: {
    id: 2,
    descricaoOpcao: 'Desabilitado',
    descricaoExplicacao: <span>Esta opção desabilita o Agendamento Online para os seus clientes.</span>,
    descricaoExplicacaoColaborador: (
      <span>
        Clientes{' '}
        <b>
          <u>não podem</u>
        </b>{' '}
        selecionar este profissional para marcar Agendamentos Online.
      </span>
    ),
    descricaoExplicacaoCliente: (
      <span>
        Este cliente{' '}
        <b>
          <u>não pode</u>
        </b>{' '}
        marcar Agendamentos Online.
      </span>
    ),
    descricaoExplicacaoCategoriaServico: (
      <span>
        Clientes{' '}
        <b>
          <u>não podem</u>
        </b>{' '}
        selecionar serviços desta categoria para marcar Agendamentos Online.
      </span>
    ),
    descricaoExplicacaoServico: (
      <span>
        Clientes{' '}
        <b>
          <u>não podem</u>
        </b>{' '}
        selecionar este serviço para marcar Agendamentos Online.
      </span>
    )
  }
})
export default enumObject
