import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth from '@material-ui/core/withWidth'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import TipoCalculoEnum from 'support/domain/calculo/TipoCalculoEnum'
import ColorSchema from 'support/domain/color/ColorSchema'
import PrioridadeComissaoPadraoServicoEnum from 'support/domain/comissao/PrioridadeComissaoPadraoServicoEnum'
import { formatarValorMonetario, formatarValorPercentual } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaCor: {
    maxWidth: 36,
    minWidth: 36
  },
  colunaServico: {
    flexGrow: 1.3
  },
  colunaTipo: {
    maxWidth: 122,
    minWidth: 122
  },
  indicadorCor: {
    width: 13,
    height: 13,
    borderRadius: 15,
    backgroundColor: '#ff8989',
    border: '1px solid white'
  },
  nomeServicoContainerSmall: {
    fontSize: 14,
    fontWeight: 500,
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

class DetalhesComissoesPorServicoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false,
      lista: []
    }

    this.loaderFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const { classes } = this.props
    const content = {}

    content.nomeServico = item.servico.nome
    content.nomeCategoria = item.servico.categoria.nome
    if (item.valorComissaoSozinho && item.valorComissaoSozinho > 0) {
      if (item.tipoCalculoComissaoSozinho.id === TipoCalculoEnum.PORCENTAGEM.id) {
        content.valorComissaoSozinho = formatarValorPercentual(item.valorComissaoSozinho)
      } else {
        content.valorComissaoSozinho = formatarValorMonetario(item.valorComissaoSozinho)
      }
    } else {
      content.valorComissaoSozinho = formatarValorPercentual(0)
    }

    if (item.valorComissaoComAssistente && item.valorComissaoComAssistente > 0) {
      if (item.tipoCalculoComissaoComAssistente.id === TipoCalculoEnum.PORCENTAGEM.id) {
        content.valorComissaoComAssistente = formatarValorPercentual(item.valorComissaoComAssistente)
      } else {
        content.valorComissaoComAssistente = formatarValorMonetario(item.valorComissaoComAssistente)
      }
    } else {
      content.valorComissaoComAssistente = formatarValorPercentual(0)
    }

    if (item.valorComissaoComoAssistente && item.valorComissaoComoAssistente > 0) {
      if (item.tipoCalculoComissaoComoAssistente.id === TipoCalculoEnum.PORCENTAGEM.id) {
        content.valorComissaoComoAssistente = formatarValorPercentual(item.valorComissaoComoAssistente)
      } else {
        content.valorComissaoComoAssistente = formatarValorMonetario(item.valorComissaoComoAssistente)
      }
    } else {
      content.valorComissaoComoAssistente = formatarValorPercentual(0)
    }

    content.indicadorCor = <div className={classes.indicadorCor} style={{ backgroundColor: ColorSchema.getColorById(item.cor).light }}></div>

    return content
  }

  getRequestParameters = () => {
    return {
      idColaborador: this.props.colaborador.id
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing } = this.state

    let textoDescricaoPrioridadeComissao = 'O sistema está considerando primeiro '

    if (getSessaoPainel().negocio.configuracoesGerais.prioridadeComissaoServicoPadrao.id === PrioridadeComissaoPadraoServicoEnum.COLABORADOR.id) {
      textoDescricaoPrioridadeComissao +=
        'as comissões configuradas para este colaborador. Se o colaborador não possui comissão configurada, o sistema utiliza a comissão do serviço. '
    } else {
      textoDescricaoPrioridadeComissao += 'as comissões configuradas nos serviços. Se o serviço não possui comissão configurada, o sistema utiliza a comissão do colaborador. '
    }

    textoDescricaoPrioridadeComissao += 'Para inverter esse comportamento, acesse Menu Principal > Configurações > Comissões e Gorjetas, e selecione o comportamento desejado.'

    return (
      <DialogPage {...dialogProps} title="Detalhamento de Comissões" align="center" contentMaxWidth={850} pageType="basicEntity" ajaxing={ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <Typography variant="body2" style={{ marginBottom: 20 }}>
              {textoDescricaoPrioridadeComissao}
            </Typography>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              loaderFunctionsMap={this.loaderFunctionsMap}
              itemsPerRequest={30}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              contextoUsuario="erp"
              endpoint="erp/colaboradores.buscarComissoesPorServico"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { className: classes.colunaCor, horizontalPadding: 'small' },
                  { label: 'Categoria', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Serviço', className: classes.colunaServico, props: { xs: true } },
                  { label: 'Sozinho', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { label: 'Com Assistente', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { label: 'Como Assistente', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [
                      content.indicadorCor,
                      content.nomeCategoria,
                      content.nomeServico,
                      content.valorComissaoSozinho,
                      content.valorComissaoComAssistente,
                      content.valorComissaoComoAssistente
                    ]
                  }
                }
              }}
              mediumRenderProps={{
                headerColumnHeight: 62,
                rowHeight: 80,
                columns: [
                  { className: classes.colunaCor, horizontalPadding: 'small' },
                  { label: 'Serviço', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Comissões', horizontalPadding: 'small', props: { xs: true } }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('medium', item, index)

                  return {
                    itemData: [
                      content.indicadorCor,
                      <React.Fragment>
                        <Typography variant="body2" noWrap={true} style={{ fontSize: 13 }}>
                          {content.nomeCategoria}
                        </Typography>
                        <Typography variant="body2" noWrap={true}>
                          {content.nomeServico}
                        </Typography>
                      </React.Fragment>,
                      <React.Fragment>
                        <Typography variant="body2">Sozinho: {content.valorComissaoSozinho}</Typography>
                        <Typography variant="body2">Com Assistente: {content.valorComissaoComAssistente}</Typography>
                        <Typography variant="body2">Como Assistente: {content.valorComissaoComoAssistente}</Typography>
                      </React.Fragment>
                    ]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 140,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Typography variant="body2" noWrap={true}>
                          {content.nomeCategoria}
                        </Typography>
                        <Typography variant="body2" className={classes.nomeServicoContainerSmall}>
                          {content.nomeServico}
                        </Typography>
                        <Typography variant="body2">Sozinho: {content.valorComissaoSozinho}</Typography>
                        <Typography variant="body2">Com Assistente: {content.valorComissaoComAssistente}</Typography>
                        <Typography variant="body2">Como Assistente: {content.valorComissaoComoAssistente}</Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                padding: true,
                text: 'Este colaborador não realiza nenhum serviço'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

DetalhesComissoesPorServicoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(DetalhesComissoesPorServicoDialogPage))
