import { ErrorResponseType } from 'support/components/api/types'

/* eslint-disable no-console */
interface LoggerMetadata {
  squad?: 'CRM' | 'AGE' | 'CORE'
  component?: string
  context?: string
}

type LoggerInfo = string | ErrorResponseType | unknown

interface LoggerContent {
  info: LoggerInfo
  metadata: LoggerMetadata
}

const log = (content: LoggerContent): void => {
  if (process.env.NODE_ENV === 'development') {
    console.error(content.info)
    return
  }

  // TODO: change to custom New Relic function when fixed
  console.error(JSON.stringify({ ...content }))
}

// TODO: change to only 1 log function when codebase is primarily typescript
const logCORE = (info: LoggerInfo, metadata?: LoggerMetadata): void => log({ info, metadata: { ...metadata, squad: 'CORE' } })
const logAGE = (info: LoggerInfo, metadata?: LoggerMetadata): void => log({ info, metadata: { ...metadata, squad: 'AGE' } })
const logCRM = (info: LoggerInfo, metadata?: LoggerMetadata): void => log({ info, metadata: { ...metadata, squad: 'CRM' } })

export { logCORE, logAGE, logCRM }
