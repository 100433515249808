import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import NotaDialogPage from 'pages/erp/painel/venda/NotaDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { createEnum } from 'support/util/EnumUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    maxWidth: 90,
    minWidth: 90
  },
  colunaNota: {
    maxWidth: 148,
    minWidth: 148
  },
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaCliente: {
    flexGrow: 1
  },
  colunaValores: {
    maxWidth: 200,
    minWidth: 200,
    [theme.breakpoints.down(900)]: {
      paddingLeft: 16
    }
  },
  notaContentSmall: {
    [theme.breakpoints.up(900)]: {
      display: 'none'
    }
  }
})

export const VisaoEnum = createEnum({
  CLIENTE: { id: 1, descricao: 'Visualizar por cliente' },
  NOTA: { id: 2, descricao: 'Visualizar por fatura' }
})

export const OrdenacaoEnum = createEnum({
  CLIENTE: { id: 1, descricao: 'Ordenar por Cliente' },
  VALOR: { id: 2, descricao: 'Ordenar por Valor' }
})

class NotasEmAbertoDetalhesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}
    this.eventsManager = EventsManager.new()

    this.state = {
      lista: [],
      visao: VisaoEnum.CLIENTE,
      ordenacao: OrdenacaoEnum.CLIENTE,
      ajaxing: false
    }

    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoVenda', 'ManipulacaoVendas', 'EstornoNotaVendaPagamento'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)

    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      idOrdenacao: this.state.ordenacao ? this.state.ordenacao.id : null
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    if (this.state.visao.id === VisaoEnum.CLIENTE.id) {
      content.nomeCliente = item.descricao
      content.idCliente = item.id
      content.valorEmAberto = formatarValorMonetario(item.valor)
    } else {
      content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YY')
      content.nomeCliente = item.cliente.apelido
      content.notaContent = null

      if (size === 'small') {
        content.notaContent = (
          <Grid key="notaContent" container alignItems="flex-start" justify="flex-start" style={{ marginTop: 2 }}>
            <Grid item>
              <Typography variant="body2">Fatura: #{item.numero}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                style={{ marginTop: -7, padding: 6 }}
                onClick={() => {
                  openBackableDialog(NotaDialogPage, {
                    parent: this,
                    shouldCloseParent: false,
                    idNota: item.id
                  })
                }}
              >
                <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )
      } else {
        const notaContentInner = (
          <React.Fragment>
            <Grid item style={{ marginTop: 1 }}>
              <Typography variant="body2">Fatura: #{item.numero}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                style={{ marginTop: -1, marginRight: -4, padding: 6 }}
                onClick={() => {
                  openBackableDialog(NotaDialogPage, {
                    parent: this,
                    shouldCloseParent: false,
                    idNota: item.id
                  })
                }}
              >
                <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
              </IconButton>
            </Grid>
          </React.Fragment>
        )

        content.notaContent = (
          <Grid key="notaContent" container alignItems="center" justify="flex-start">
            {notaContentInner}
          </Grid>
        )

        content.notaContentMediumDynamic = (
          <Grid key="notaContent" container alignItems="center" justify="flex-start" className={this.props.classes.notaContentSmall}>
            {notaContentInner}
          </Grid>
        )
      }

      if (size === 'small') {
        content.valorTotal = <React.Fragment>Valor Total: {formatarValorMonetario(item.valorTotal)}</React.Fragment>
        content.valorPago = <React.Fragment>Valor Pago: {formatarValorMonetario(item.valorPago)}</React.Fragment>
        content.valorEmAberto = <React.Fragment>Valor Em Aberto: {formatarValorMonetario(item.valorTotal - item.valorPago)}</React.Fragment>
      } else {
        content.valorTotal = formatarValorMonetario(item.valorTotal)
        content.valorPago = formatarValorMonetario(item.valorPago)
        content.valorEmAberto = formatarValorMonetario(item.valorTotal - item.valorPago)
      }
    }
    return content
  }

  downloadRelatorio = (event) => {
    let endpoint = ''

    if (this.state.visao.id === VisaoEnum.CLIENTE.id) {
      endpoint = 'relatorios.gerarClientesComNotasEmAbertoNaoPagas'
    } else {
      endpoint = 'relatorios.gerarNotasEmAbertoNaoPagasPorData'
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Relatório de Comandas Em Aberto - ' + formatarPeriodoNomeArquivo(this.props.dataInicial, this.props.dataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: endpoint,
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { visao, ordenacao } = this.state
    let endPoint = null
    let largeRenderProps = undefined
    let mediumRenderProps = undefined
    let smallRenderProps = undefined
    let valorTotal = 0
    let quantidadeTotal = 0
    let descricaoRodape = undefined

    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    if (visao.id === VisaoEnum.CLIENTE.id) {
      endPoint = 'erp/relatorios.buscarClientesComNotasEmAbertoNaoPagasPorData'

      largeRenderProps = {
        columns: [
          { label: 'Cliente', className: classes.colunaCliente, props: { xs: true } },
          { label: 'Valor Em Aberto', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('large', item)

          return {
            itemData: [content.nomeCliente, content.valorEmAberto]
          }
        }
      }

      mediumRenderProps = largeRenderProps

      smallRenderProps = {
        rowHeight: 80,
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('small', item)

          return {
            itemData: (
              <React.Fragment>
                <Typography variant="body2" noWrap={true}>
                  <b>{content.nomeCliente}</b>
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {content.valorEmAberto}
                </Typography>
              </React.Fragment>
            )
          }
        }
      }

      for (let item of this.state.lista) {
        valorTotal += item.valor
        quantidadeTotal++
      }

      descricaoRodape = 'Clientes:'
    } else {
      endPoint = 'erp/relatorios.buscarNotasEmAbertoNaoPagasPorData'

      largeRenderProps = {
        columns: [
          { label: 'Data', className: classes.colunaData },
          { label: 'Cliente', className: classes.colunaCliente, props: { xs: true }, horizontalPadding: 'small' },
          { label: 'Fatura', className: classes.colunaNota, horizontalPadding: 'small', align: 'left' },
          { label: 'Valor Total', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
          { label: 'Valor Pago', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
          { label: 'Valor Em Aberto', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (comissao, index) => {
          const content = this.getRenderContent('large', comissao)

          return {
            itemData: [content.data, content.nomeCliente, content.notaContent, content.valorTotal, content.valorPago, content.valorEmAberto]
          }
        }
      }

      mediumRenderProps = {
        headerColumnHeight: 68,
        rowHeight: 106,
        columns: [
          { label: 'Data', className: classes.colunaData },
          { label: 'Descrição', className: classes.colunaCliente, props: { xs: true }, horizontalPadding: 'small' },
          { label: 'Fatura', className: classes.colunaNota, props: { xs: true }, showOnlyUp: '900', align: 'left' },
          { label: 'Valores', className: classes.colunaValores, horizontalPadding: 'small', align: 'left' }
        ],
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('medium', item)

          return {
            itemData: [
              content.data,
              <React.Fragment>
                <Typography variant="body2" noWrap={true}>
                  <b>{content.nomeCliente}</b>
                </Typography>
                {content.notaContentMediumDynamic}
              </React.Fragment>,
              content.notaContent,
              <React.Fragment>
                <Typography variant="body2" noWrap={true}>
                  Total: {content.valorTotal}
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  Pago: {content.valorPago}
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  Em aberto: {content.valorEmAberto}
                </Typography>
              </React.Fragment>
            ]
          }
        }
      }

      smallRenderProps = {
        rowHeight: 166,
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('small', item)

          return {
            itemData: (
              <React.Fragment>
                <Grid container alignItems="center" style={{ minWidth: 0 }}>
                  <Grid item xs style={{ minWidth: 0 }}>
                    <Typography variant="body2">{content.data}</Typography>
                  </Grid>
                  <Grid item align="right" style={{ minWidth: 0 }}>
                    <Typography variant="body2">{content.notaContent}</Typography>
                  </Grid>
                </Grid>
                <Typography variant="body2" noWrap={true}>
                  <b>{content.nomeCliente}</b>
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {content.valorTotal}
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {content.valorPago}
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {content.valorEmAberto}
                </Typography>
              </React.Fragment>
            )
          }
        }
      }

      for (let item of this.state.lista) {
        valorTotal += item.valorTotal - item.valorPago
        quantidadeTotal++
      }

      descricaoRodape = 'Comandas:'
    }

    const toolbarActions = {
      actions: []
    }

    if (this.state.lista && this.state.lista.length) {
      toolbarActions.actions.push({
        label: 'Exportar',
        handleAction: this.downloadRelatorio
      })
    }

    return (
      <DialogPage {...dialogProps} title="Detalhamento" align="center" pageType="basicEntity" ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Comandas em Aberto com Pagamento Pendente</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
              {this.state.lista && this.state.lista.length > 0 && (
                <Grid container direction="column" justify="center" alignItems="center" style={{ marginTop: 10 }}>
                  <Grid item>
                    <InputSelect
                      customVariant="naked"
                      nakedLeftPadding={false}
                      value={this.state.visao.id}
                      fullWidth={false}
                      onChange={(event) => {
                        const visao = VisaoEnum.getById(parseInt(event.target.value, 10))
                        this.setState({ visao: visao })
                      }}
                    >
                      {VisaoEnum.values().map((visao) => (
                        <MenuItem key={visao.id} value={visao.id}>
                          {visao.descricao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </Grid>
                  {visao === VisaoEnum.CLIENTE && (
                    <Grid item>
                      <InputSelect
                        customVariant="naked"
                        nakedLeftPadding={false}
                        value={this.state.ordenacao.id}
                        marginTop={-6}
                        fullWidth={false}
                        onChange={(event) => {
                          const ordenacao = OrdenacaoEnum.getById(parseInt(event.target.value, 10))
                          this.setState({ ordenacao: ordenacao })
                        }}
                      >
                        {OrdenacaoEnum.values().map((ordenacao) => (
                          <MenuItem key={ordenacao.id} value={ordenacao.id}>
                            {ordenacao.descricao}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    </Grid>
                  )}
                </Grid>
              )}
            </FloatContent>

            <div>
              <VirtualizedResponsiveTable
                showBackgroundDividers={true}
                key={visao.id + '_' + ordenacao.id}
                scrollElement={dialogContentProps.scrollContainerRef.current}
                itemsPerRequest={30}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="erp"
                endpoint={endPoint}
                getRequestParametersFunction={this.getRequestParameters}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    this.setState({ lista: data.items })
                  }
                }}
                largeRenderProps={largeRenderProps}
                mediumRenderProps={mediumRenderProps}
                smallRenderProps={smallRenderProps}
                emptyListProps={{
                  text: 'Não existem valores em aberto para o período informado'
                }}
              />
            </div>

            {quantidadeTotal > 1 && (
              <FloatContent container="paper" context="DialogPage" type="tableSummary">
                <Typography variant="body2">
                  {descricaoRodape} <b>{quantidadeTotal}</b>
                  <br />
                  Total: <b>{formatarValorMonetario(valorTotal)}</b>
                  <br />
                </Typography>
              </FloatContent>
            )}
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

NotasEmAbertoDetalhesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotasEmAbertoDetalhesDialogPage)
