import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import InputProduto from 'pages/erp/painel/inventario/input/InputProduto'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoUnidadeMedida from 'support/domain/servico/TipoUnidadeMedida'
import FormUtil from 'support/util/FormUtil'
import { createErrorsMap, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputFloatNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class VariacaoServicoProdutosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }
    this.keyGenerator = 0
  }

  generateKeyId = () => {
    return ++this.keyGenerator
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (this.isModoAlteracaoCadastral()) {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/servicos.buscarVariacoesServicosProdutos',
        params: {
          idServico: this.props.servico.id,
          idVariacaoServico: this.props.variacaoServico.id
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          const variacaoServico = response.data[0]

          if (variacaoServico.variacaoServicoProdutos && variacaoServico.variacaoServicoProdutos.length > 0) {
            variacaoServico.variacaoServicoProdutos.sort(function (a, b) {
              if (a.id < b.id) return -1
              if (a.id > b.id) return 1
              return 0
            })
            for (let item of variacaoServico.variacaoServicoProdutos) {
              item.key = this.generateKeyId()
              item.functionsMap = {}
            }
          } else {
            variacaoServico.variacaoServicoProdutos = [this.gerarNovoVariacaoServicoProduto()]
          }

          this.setState({
            ajaxing: false,
            variacaoServicoProdutos: variacaoServico.variacaoServicoProdutos
          })
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          this.setState({
            ajaxing: false
          })
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    } else if (this.isModoAlteracaoInstancia()) {
      const variacaoServicoProdutos = []

      if (this.props.variacaoServicoProdutos.length > 0) {
        this.props.variacaoServicoProdutos.sort(function (a, b) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })
        for (let item of this.props.variacaoServicoProdutos) {
          item.key = this.generateKeyId()
          item.functionsMap = {}
          variacaoServicoProdutos.push(item)
        }
      } else {
        variacaoServicoProdutos.push(this.gerarNovoVariacaoServicoProduto())
      }

      this.setState({
        ajaxing: false,
        variacaoServicoProdutos: variacaoServicoProdutos
      })
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    }
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  getDadosVariacaoServicoProdutos = () => {
    let dados = {
      variacaoServico: this.props.variacaoServico,
      variacaoServicoProdutos: []
    }
    for (let item of this.state.variacaoServicoProdutos) {
      dados.variacaoServicoProdutos.push(item.functionsMap.getValue())
    }
    return dados
  }

  salvar = () => {
    if (this.isModoAlteracaoCadastral()) {
      if (verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])) {
        this.setState((state) => ({
          ajaxing: true,
          errorsMap: createErrorsMap()
        }))

        postAPI({
          url: 'erp/servicos.persistirVariacaoServicoProdutos',
          data: this.getDadosVariacaoServicoProdutos(),
          requerAutorizacao: true,
          onSuccess: (response) => {
            EventsManager.pub('ManipulacaoVariacaoServicoProdutos')
            this.props.handleCloseDialog()
          },
          onError: (response) => {
            this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
            FormUtil.focusFirstElementWithError('formVariacaoServicoProdutos')
          }
        })
      } else {
        openBackableDialog(AccessDeniedDialog)
      }
    } else if (this.isModoAlteracaoInstancia()) {
      this.setState((state) => ({
        ajaxing: true,
        errorsMap: createErrorsMap()
      }))

      const dados = this.getDadosVariacaoServicoProdutos()

      postAPI({
        url: 'erp/servicos.validarVariacaoServicoProdutos',
        data: dados,
        requerAutorizacao: true,
        onSuccess: (response) => {
          this.props.handleCloseDialog()
          this.props.onChange(dados)
        },
        onError: (response) => {
          this.setState((state) => ({ ajaxing: false, errorsMap: FormUtil.createErrorsMap(response.data.errors) }))
          FormUtil.focusFirstElementWithError('formVariacaoServicoProdutos')
        }
      })
    }
  }

  removerVariacaoServicoProduto = (index) => {
    const variacaoServicoProdutos = this.state.variacaoServicoProdutos.slice()
    variacaoServicoProdutos.splice(index, 1)
    this.setState({ variacaoServicoProdutos: variacaoServicoProdutos })
  }

  adicionarVariacaoServicoProduto = () => {
    const variacaoServicoProdutos = this.state.variacaoServicoProdutos.slice()
    let novoItem = this.gerarNovoVariacaoServicoProduto()
    variacaoServicoProdutos.push(novoItem)
    this.setState({ variacaoServicoProdutos: variacaoServicoProdutos })
  }

  gerarNovoVariacaoServicoProduto = () => {
    return {
      key: this.generateKeyId(),
      tipoUso: TipoUnidadeMedida.QUANTIDADE,
      functionsMap: {}
    }
  }

  isModoAlteracaoCadastral = () => {
    if (!this.props.variacaoServicoProdutos) {
      return true
    } else {
      return false
    }
  }

  isModoAlteracaoInstancia = () => {
    if (this.props.variacaoServicoProdutos) {
      return true
    } else {
      return false
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, variacaoServicoProdutos } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={'Uso de Produtos'}
        align="center"
        contentMaxWidth={560}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formVariacaoServicoProdutos" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Typography variant="h5">{this.props.servico.nome}</Typography>
              {this.props.variacaoServico.nome && (
                <Typography variant="body1">
                  <b>Variação: {this.props.variacaoServico.nome}</b>
                </Typography>
              )}

              <br />

              {this.isModoAlteracaoCadastral() && (
                <Typography variant="body2">
                  Cadastre a quantidade utilizada de cada produto para realizar esse serviço. Esta configuração irá reduzir automáticamente o estoque dos produtos.
                </Typography>
              )}

              {this.isModoAlteracaoInstancia() && (
                <Typography variant="body2">
                  Informe a quantidade utilizada de cada produto para realizar este atendimento. O sistema irá reduzir automáticamente o estoque dos produtos.
                </Typography>
              )}

              {variacaoServicoProdutos &&
                variacaoServicoProdutos.map((variacaoServicoProduto, index) => {
                  return (
                    <ItemVariacaoServicoProduto
                      index={index}
                      ajaxing={this.state.ajaxing}
                      errorsMap={errorsMap}
                      isModoAlteracaoCadastral={this.isModoAlteracaoCadastral}
                      variacaoServicoProdutos={variacaoServicoProdutos}
                      variacaoServicoProduto={variacaoServicoProduto}
                      key={variacaoServicoProduto.key}
                      removerVariacaoServicoProduto={this.removerVariacaoServicoProduto}
                    />
                  )
                })}

              <div style={{ textAlign: 'right', marginTop: 20, marginRight: -8 }}>
                <Button color="secondary" type="button" onClick={this.adicionarVariacaoServicoProduto}>
                  Adicionar mais produtos
                </Button>
              </div>

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

class ItemVariacaoServicoProduto extends Component {
  constructor(props) {
    super(props)

    let tipoUso = this.props.variacaoServicoProduto.tipoUso

    if (
      TipoUnidadeMedida.PERSONALIZADA.id === tipoUso.id &&
      (!this.props.variacaoServicoProduto.produto.quantidadeEmbalagem || this.props.variacaoServicoProduto.produto.quantidadeEmbalagem <= 0)
    ) {
      tipoUso = TipoUnidadeMedida.QUANTIDADE
    }

    let quantidadeUso = this.props.variacaoServicoProduto.quantidadeUso
    if (
      this.props.variacaoServicoProduto.tipoUso.id === TipoUnidadeMedida.PERSONALIZADA.id &&
      (!this.props.variacaoServicoProduto.produto.quantidadeEmbalagem || this.props.variacaoServicoProduto.produto.quantidadeEmbalagem <= 0)
    ) {
      quantidadeUso = null
    }

    this.state = {
      produto: this.props.variacaoServicoProduto.produto,
      tipoUso: tipoUso,
      quantidadeUso: quantidadeUso
    }
  }

  componentDidMount() {
    const { functionsMap } = this.props.variacaoServicoProduto

    if (functionsMap !== undefined) {
      functionsMap['getValue'] = () => {
        return {
          id: this.props.variacaoServicoProduto.id,
          produto: this.state.produto,
          tipoUso: this.state.tipoUso,
          quantidadeUso: FormUtil.convertStringToNumber(this.state.quantidadeUso)
        }
      }
    }
  }

  getKeyErrorsMapPrefix(index) {
    return 'variacaoServicoProdutos[' + index + ']'
  }

  render() {
    const { ajaxing, errorsMap, index, variacaoServicoProduto } = this.props
    const { produto, tipoUso } = this.state

    const opcoesTipoUso = [TipoUnidadeMedida.QUANTIDADE]
    let quantidadeUsoHelpMessage = null
    let endAdornment

    if (produto && produto.quantidadeEmbalagem && produto.unidadeMedidaEmbalagem) {
      if (this.state.quantidadeUso) {
        if (tipoUso.id === TipoUnidadeMedida.PERSONALIZADA.id) {
          quantidadeUsoHelpMessage = this.state.quantidadeUso + ' ' + produto.unidadeMedidaEmbalagem
        } else if (tipoUso.id === TipoUnidadeMedida.QUANTIDADE.id && this.state.quantidadeUso > 0) {
          quantidadeUsoHelpMessage = produto.quantidadeEmbalagem * this.state.quantidadeUso + ' ' + produto.unidadeMedidaEmbalagem
        }
        if (quantidadeUsoHelpMessage) {
          quantidadeUsoHelpMessage = (
            <span>
              Utiliza <b>{quantidadeUsoHelpMessage}</b> deste produto
            </span>
          )
        }
      }

      opcoesTipoUso.push({ id: TipoUnidadeMedida.PERSONALIZADA.id, descricao: 'Em ' + produto.unidadeMedidaEmbalagem })
      endAdornment = (
        <InputSelect
          customVariant="naked"
          style={{ width: 'auto', minWidth: 'auto', maxWidth: 'none' }}
          InputProps={{ style: { marginRight: 6 } }}
          value={tipoUso.id.toString()}
          fullWidth={false}
          onChange={(event) => {
            const tipoUso = TipoUnidadeMedida.getById(parseInt(event.target.value, 10))
            this.setState({ tipoUso: tipoUso })
          }}
        >
          {opcoesTipoUso.map((tipo) => (
            <MenuItem key={tipo.id} value={tipo.id}>
              {tipo.descricao.toLowerCase()}
            </MenuItem>
          ))}
        </InputSelect>
      )
    }

    if (produto && !produto.quantidadeEmbalagem && this.props.isModoAlteracaoCadastral()) {
      quantidadeUsoHelpMessage = (
        <span>
          Caso precise informar a quantidade de uso em fração (ex: 30ml de uma embalagem que contém 100ml), é necessário preencher o campo <b>Quantidade por embalagem</b> na tela
          de cadastro do produto.
        </span>
      )
    }

    let quantidadeUsoDefaultValue = variacaoServicoProduto.quantidadeUso
    if (
      variacaoServicoProduto.tipoUso.id === TipoUnidadeMedida.PERSONALIZADA.id &&
      (!this.props.variacaoServicoProduto.produto.quantidadeEmbalagem || this.props.variacaoServicoProduto.produto.quantidadeEmbalagem <= 0)
    ) {
      quantidadeUsoDefaultValue = null
    }

    let errorsMapProduto = errorsMap(this.getKeyErrorsMapPrefix(index) + '.produto')
    let errorsMapQuantidadeUso = errorsMap(this.getKeyErrorsMapPrefix(index) + '.quantidadeUso')

    return (
      <Grid container alignItems="center" style={{ position: 'relative', marginTop: 32 }}>
        <Grid item style={{ width: 5, backgroundColor: '#d8d8d8', height: '100%', position: 'absolute', borderRadius: 1 }}></Grid>

        <Grid item xs={true} style={{ marginLeft: 14 }}>
          <Grid container justify="center" alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <InputProduto
                style={{ marginTop: 0 }}
                shrink={false}
                customVariant="outlined-small"
                label="Escolha o Produto"
                idname={'xproduto' + index}
                disabled={ajaxing}
                defaultValue={{ produto: produto }}
                onChange={(value) => {
                  if (value) {
                    this.setState({
                      produto: value.produto ? value.produto : null
                    })
                  } else {
                    this.setState({
                      produto: null
                    })
                  }
                }}
                errorMessage={errorsMapProduto}
              />
            </Grid>

            <Grid item xs={true}>
              <InputText
                style={{ marginTop: 6 }}
                shrink={true}
                customVariant="outlined-small"
                idname={'xquantidadeUso' + index}
                defaultValue={quantidadeUsoDefaultValue}
                errorMessage={errorsMapQuantidadeUso}
                helpMessage={quantidadeUsoHelpMessage}
                disabled={ajaxing}
                placeholder="0"
                label="Quantidade de uso"
                inputComponent={InputFloatNumberFormat}
                onChange={(event) => {
                  this.setState({ quantidadeUso: event.target.value })
                }}
                endAdornment={endAdornment}
              />
            </Grid>

            {
              <Grid item style={{ marginRight: -8 }}>
                <IconButton
                  style={{ padding: 4, marginTop: 13 }}
                  disabled={ajaxing}
                  onClick={() => {
                    this.props.removerVariacaoServicoProduto(index)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

VariacaoServicoProdutosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VariacaoServicoProdutosDialogPage)
