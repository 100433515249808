import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ServicoService from 'support/domain/servico/ServicoService'
import { formatarNome } from 'support/util/TextFormatter'

import ListagemHorariosLivres from './ListagemHorariosLivres'
import styles from './styles'

const ID_QUALQUER_COLABORADOR = 0

const StepListagemHorariosLivres = ({
  classes,
  negocio,
  idItemBuscaHorariosLivres,
  colaboradorBuscaHorariosLivres,
  servicoBuscaHorariosLivres,
  variacaoBuscaHorariosLivres,
  fecharDialogBuscarProximosHorarios
}) => {
  return (
    <div>
      <Grid container alignItems="center" style={{ marginBottom: 24 }}>
        <Grid item style={{ paddingRight: 4 }}>
          <IconButton
            style={{ marginLeft: -14 }}
            onClick={() => {
              fecharDialogBuscarProximosHorarios()
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs style={{ paddingLeft: 0 }}>
          <Typography variant="h6" className={classes.titulo}>
            Próximos Horários
          </Typography>
        </Grid>
      </Grid>

      <div style={{ marginBottom: 24 }}>
        <Typography variant="h6" className={classes.confirmacaoNomeServico}>
          {ServicoService.gerarNomeCompletoServico(servicoBuscaHorariosLivres, variacaoBuscaHorariosLivres)}
        </Typography>
        <Typography variant="body2" className={classes.confirmacaoProfissional}>
          {colaboradorBuscaHorariosLivres.id === ID_QUALQUER_COLABORADOR
            ? '' + colaboradorBuscaHorariosLivres.nome
            : 'Profissional: ' + formatarNome(colaboradorBuscaHorariosLivres.nome, 2)}
        </Typography>
      </div>

      <ListagemHorariosLivres
        classes={classes}
        negocio={negocio}
        idItem={idItemBuscaHorariosLivres}
        colaborador={colaboradorBuscaHorariosLivres}
        servico={servicoBuscaHorariosLivres}
        variacao={variacaoBuscaHorariosLivres}
      />
    </div>
  )
}

export default withStyles(styles)(StepListagemHorariosLivres)
