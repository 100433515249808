import React, { Component } from 'react'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import CustomDialog from 'support/components/dialog/CustomDialog'

const styles = (theme) => ({
  paper: {
    width: '100%'
  },
  containerFixNoTitle: {
    width: '100%',
    height: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#999'
  }
})

class BaseDialog extends Component {
  constructor(props) {
    super(props)
    this.paperRef = React.createRef()
  }

  render() {
    const { classes, title, children, actions, hasCloseButton, hasButtons, rawContainer, disablePortal } = this.props
    let { maxWidth } = this.props

    if (!maxWidth) {
      maxWidth = 500
    }

    let content = children ? children : null
    if (content) {
      if (content.type === 'span') {
        content = <DialogContentText>{content}</DialogContentText>
      } else if (typeof content === 'string') {
        content = <DialogContentText>{content}</DialogContentText>
      } else if (typeof content === 'function') {
        content = content({ scrollContainerRef: this.paperRef })
      }
    }

    if (!rawContainer) {
      content = <DialogContent>{content}</DialogContent>
    }

    return (
      <CustomDialog
        dialogProps={this.props}
        classes={{ paper: classes.paper }}
        paperRef={this.paperRef}
        paperStyle={{
          maxWidth: maxWidth
        }}
        disablePortal={disablePortal}
      >
        <div style={{ maxWidth: maxWidth }}>
          {hasCloseButton && (
            <IconButton onClick={() => this.props.handleCloseDialog()} className={classes.closeButton}>
              <CloseIcon style={{ display: 'block' }} />
            </IconButton>
          )}
          {title && <DialogTitle>{title}</DialogTitle>}
          {!title && !rawContainer && <div className={classes.containerFixNoTitle}></div>}
          {content}
          {(hasButtons === undefined || hasButtons) && <DialogActions>{actions}</DialogActions>}
        </div>
      </CustomDialog>
    )
  }
}

BaseDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BaseDialog)
