import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import ColaboradorDadosBasicosDialogPage from 'pages/erp/painel/colaborador/ColaboradorDadosBasicosDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InputDialogSelect from 'support/components/input/InputDialogSelect'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({})

class ColaboradorInputDialogSelect extends Component {
  render() {
    const { classes, ...others } = this.props
    const props = Object.assign({}, others)

    const creationFunction = (props) => {
      openBackableDialog(ColaboradorDadosBasicosDialogPage, {
        nome: props.text
      })
    }

    if (!props.label) props.label = 'Colaborador'
    if (!props.id) props.id = 'xcolaborador'
    if (!props.label) props.label = 'xcolaborador'

    if (props.defaultValue && props.defaultValue.id !== undefined && props.defaultValue.id !== null) {
      props.defaultValue = {
        value: props.defaultValue.id,
        label: props.defaultValue.apelido,
        colaborador: props.defaultValue
      }
    }

    if (!props.DialogSelectProps) {
      props.DialogSelectProps = {}
    }

    props.DialogSelectProps.creationFunction = creationFunction
    props.DialogSelectProps.creationPermission = verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])
    props.DialogSelectProps.inputSearchPlaceholder = 'Buscar Colaborador...'
    props.DialogSelectProps.loadAllAndCache = true
    if (props.context === 'atendimento') {
      props.DialogSelectProps.endpoint = 'erp/colaboradores.buscarColaboradoresParaAtendimento'
    } else if (props.context === 'vendaProduto') {
      props.DialogSelectProps.endpoint = 'erp/colaboradores.buscarColaboradoresParaVendaProduto'
    } else if (props.context === 'vendaPacote') {
      props.DialogSelectProps.endpoint = 'erp/colaboradores.buscarColaboradoresParaVendaPacote'
    } else if (props.context === 'vendaCredito') {
      props.DialogSelectProps.endpoint = 'erp/colaboradores.buscarColaboradoresParaVendaCredito'
    } else {
      props.DialogSelectProps.endpoint = 'erp/colaboradores.buscar'
    }

    props.DialogSelectProps.searchParamName = 'nome'
    props.DialogSelectProps.getRequestParametersFunction = () => {
      return {
        idServico: this.props.servico ? this.props.servico.id : null
      }
    }
    props.DialogSelectProps.generateItemData = (colaborador) => {
      return {
        value: colaborador.id,
        label: colaborador.apelido,
        primary: colaborador.apelido,
        colaborador: colaborador
      }
    }

    props.DialogSelectProps.eventsNameToRefreshResults = ['ManipulacaoColaborador', 'AssociacaoServicoColaborador']

    if (!props.DialogSelectProps.emptyListProps) {
      props.DialogSelectProps.emptyListProps = {
        padding: true,
        text: 'Nenhum colaborador foi encontrado',
        textButton: 'Cadastrar colaborador',
        footerText: this.props.servico
          ? 'Você já cadastrou um colaborador e ele não está aparecendo? Lembre-se de que também é preciso associar o serviços aos colaboradores que podem realizá-los.'
          : null,
        creationFunction: creationFunction
      }
    }

    const propsToInput = Object.assign({}, props)

    delete propsToInput.servico
    delete propsToInput.contexto

    return <InputDialogSelect {...propsToInput} />
  }
}

ColaboradorInputDialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ColaboradorInputDialogSelect)
