import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import InputListIntervaloHorario, {
  adicionarIntervaloFormObject as adicionarIntervaloFormObjectHorario,
  changeIntervaloFormObject as changeIntervaloFormObjectHorario,
  criarIntervalo,
  removerIntervaloFormObject as removerIntervaloFormObjectHorario
} from 'support/components/input/InputListIntervaloHorario'
import { getDescricaoDiaSemanaPorNumero } from 'support/util/DateFormatter'

const styles = (theme) => ({})

export function createFormObject(dia) {
  if (dia.intervalos !== undefined) {
    const intervalos = []
    dia.intervalos.forEach((intervalo) => {
      intervalos.push(
        criarIntervalo(
          intervalo.id,
          criarDataParaHorario(parseInt(intervalo.de / 100, 10), parseInt(intervalo.de % 100, 10)),
          criarDataParaHorario(parseInt(intervalo.ate / 100, 10), parseInt(intervalo.ate % 100, 10))
        )
      )
    })
    return intervalos
  } else {
    if (dia.dia === 1) {
      return [criarIntervalo(null, criarDataParaHorario(9), criarDataParaHorario(12)), criarIntervalo(null, criarDataParaHorario(13), criarDataParaHorario(18))]
    } else if (dia.dia <= 5) {
      return [criarIntervalo(null, criarDataParaHorario(9), criarDataParaHorario(12)), criarIntervalo(null, criarDataParaHorario(13), criarDataParaHorario(18))]
    } else if (dia.dia === 6) {
      return [criarIntervalo(null, criarDataParaHorario(9), criarDataParaHorario(12)), criarIntervalo(null, criarDataParaHorario(13), criarDataParaHorario(19))]
    } else {
      return []
    }
  }
}

function criarDataParaHorario(horas, minutos) {
  if (minutos === undefined) {
    minutos = 0
  }

  const data = new Date()
  data.setHours(horas, minutos)
  return data
}

export function adicionarIntervaloFormObject(intervalos) {
  adicionarIntervaloFormObjectHorario(intervalos)
}

export const changeIntervaloFormObject = (intervalos, index, prop, value) => {
  changeIntervaloFormObjectHorario(intervalos, index, prop, value)
}

export const removerIntervaloFormObject = (intervalos, index) => {
  removerIntervaloFormObjectHorario(intervalos, index)
}

class InputListIntervaloHorarioDia extends Component {
  handleAdicionarIntervaloHorario = () => {
    if (this.props.onChange) this.props.onChange()
    this.props.handleAdicionarIntervaloHorario(this.props.dia)
  }

  render() {
    const { classes } = this.props
    const diaSemana = getDescricaoDiaSemanaPorNumero(this.props.dia)

    let intervalosConteudo = null
    if (this.props.intervalos === null || this.props.intervalos.length === 0) {
      intervalosConteudo = (
        <div>
          <Button
            disabled={this.props.disabled}
            style={{ marginTop: 2 }}
            variant="outlined"
            size="small"
            color="default"
            className={classes.button}
            onClick={() => this.handleAdicionarIntervaloHorario()}
          >
            <AddIcon style={{ marginRight: 4 }} />
            Adicionar horário
          </Button>
        </div>
      )
    } else {
      intervalosConteudo = <InputListIntervaloHorario {...this.props} />
    }

    return (
      <div style={{ borderLeft: '5px solid #d8d8d8', borderRadius: 3, paddingTop: 2, paddingBottom: 2, paddingLeft: 9, marginBottom: this.props.adicionarSeparador ? 28 : 0 }}>
        <div style={{ padding: 0 }}>
          <Typography style={{ marginBottom: 4, fontWeight: 400, csolor: '#8a8a8a' }} variant="body2">
            {diaSemana}
          </Typography>
          {intervalosConteudo}
        </div>
        {this.props.adicionarSeparador === true && false && <Divider style={{ padding: 0, marginTop: 24 }} />}
      </div>
    )
  }
}

InputListIntervaloHorarioDia.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputListIntervaloHorarioDia)
