import { createEnum } from 'support/util/EnumUtil'

const enumObj = createEnum({
  AC: { id: 1, sigla: 'AC', nome: 'Acre' },
  AL: { id: 2, sigla: 'AL', nome: 'Alagoas' },
  AP: { id: 3, sigla: 'AP', nome: 'Amapá' },
  AM: { id: 4, sigla: 'AM', nome: 'Amazonas' },
  BA: { id: 5, sigla: 'BA', nome: 'Bahia' },
  CE: { id: 6, sigla: 'CE', nome: 'Ceará' },
  DF: { id: 7, sigla: 'DF', nome: 'Distrito Federal' },
  ES: { id: 8, sigla: 'ES', nome: 'Espírito Santo' },
  GO: { id: 9, sigla: 'GO', nome: 'Goiás' },
  MA: { id: 10, sigla: 'MA', nome: 'Maranhão' },
  MT: { id: 11, sigla: 'MT', nome: 'Mato Grosso' },
  MS: { id: 12, sigla: 'MS', nome: 'Mato Grosso do Sul' },
  MG: { id: 13, sigla: 'MG', nome: 'Minas Gerais' },
  PA: { id: 14, sigla: 'PA', nome: 'Pará' },
  PB: { id: 15, sigla: 'PB', nome: 'Paraíba' },
  PR: { id: 16, sigla: 'PR', nome: 'Paraná' },
  PE: { id: 17, sigla: 'PE', nome: 'Pernambuco' },
  PI: { id: 18, sigla: 'PI', nome: 'Piauí' },
  RJ: { id: 19, sigla: 'RJ', nome: 'Rio de Janeiro' },
  RN: { id: 20, sigla: 'RN', nome: 'Rio Grande do Norte' },
  RS: { id: 21, sigla: 'RS', nome: 'Rio Grande do Sul' },
  RO: { id: 22, sigla: 'RO', nome: 'Rondônia' },
  RR: { id: 23, sigla: 'RR', nome: 'Roraima' },
  SC: { id: 24, sigla: 'SC', nome: 'Santa Catarina' },
  SP: { id: 25, sigla: 'SP', nome: 'São Paulo' },
  SE: { id: 26, sigla: 'SE', nome: 'Sergipe' },
  TO: { id: 27, sigla: 'TO', nome: 'Tocantins' }
})
export default enumObj
