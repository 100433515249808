import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'

import { MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import InputCaixa from 'pages/erp/painel/caixa/input/InputCaixa'
import InputFormaPagamento from 'pages/erp/painel/formapagamento/input/InputFormaPagamento'
import { getSessaoPainel, verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import FormHelperText from 'support/components/input/core/FormHelperText'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoGorjetaEnum from 'support/domain/comissao/TipoGorjetaEnum'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import DestinoValorAdicionalPagamentoEnum from 'support/domain/venda/DestinoValorAdicionalPagamentoEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'
import { MercadoPagoUtils } from 'support/util/MercadoPagoUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import NumberUtil from 'support/util/NumberUtil'
import StringUtil from 'support/util/StringUtil'

import MercadoPagoDialogPage from './MercadoPagoDialogPage'

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      justifyContent: 'space-between',
      minHeight: 'calc(100vh - 112px)'
    }
  },
  containerFooterButtons: theme.form.containerFooterButtons(),
  labelOpcaoValorAdicional: {
    paddingTop: 2,
    paddingBottom: 2
  },
  wrapperButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: '40px',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      marginBottom: '40px'
    }
  },
  realizarPagamento: {
    padding: '10px 24px',
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
      width: '100%'
    }
  },
  cancelar: {
    padding: '10px 24px',
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
      display: 'none'
    }
  }
})

class NotaPagamentoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.valorPendentePagamento = this.props.valorPendentePagamento

    this.state = {
      pagamento: this.props.pagamento,
      valorAdicional: 0,
      keyInputValorEntrada: 1,
      destinoValorAdicional: DestinoValorAdicionalPagamentoEnum.TROCO,
      title: 'Pagamento',
      errorsMap: createErrorsMap(),
      data: moment(),
      ajaxing: false,
      mercadoPago: {
        parcelas: null,
        formaPagamento: null,
        valor: null,
        tipo: null,
        tipoPagamento: null,
        dataPagamento: null
      }
    }

    let valorEntradaDefaultValue = this.getValorPendentePagamento()
    const { valorEntrada } = this.state.pagamento ? this.state.pagamento : {}
    if (valorEntrada !== undefined && valorEntrada !== null) {
      valorEntradaDefaultValue = valorEntrada
    }

    this.state.valorEntradaDefaultValue = valorEntradaDefaultValue
    this.state.mercadoPago.valor = valorEntradaDefaultValue

    this.formaPagamentoFunctionMap = {}
    this.dataPagamentoFunctionMap = {}
    this.inputValorEntrada = React.createRef()
    this.inputCaixaFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/vendas.buscarDadosParaPersistenciaPagamento',
      params: {
        idPagamento: this.props.pagamento ? this.props.pagamento.id : null,
        idNotaVenda: this.props.nota.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const state = {
          caixas: response.data.caixas
        }
        const pagamento = response.data.pagamento

        if (pagamento) {
          state.pagamento = pagamento
          state.formaPagamento = pagamento.formaPagamento
          state.valorAdicional = pagamento.valorAdicional
          if (pagamento.destinoValorAdicional) {
            state.destinoValorAdicional = pagamento.destinoValorAdicional
          }
          state.data = converterDataIntParaMoment(pagamento.dataPagamento)
        }

        this.setState(state)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  carregarVendas = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/vendas.buscarColaboradoresParaGorjeta',
      params: {
        idNota: this.props.nota.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const state = this.state
        state.gorjetas = []

        response.data.sort(function (a, b) {
          if (a.apelido < b.apelido) return -1
          if (a.apelido > b.apelido) return 1
          return 0
        })

        response.data.forEach((colaborador) => {
          const valorDefault =
            response.data.length === 1 && (!state.pagamento || !state.pagamento.gorjetas || state.pagamento.gorjetas.length === 0) ? state.valorAdicional : undefined
          state.gorjetas.push({
            colaborador: colaborador,
            descricao: StringUtil.applyMaxWords(colaborador.apelido, { maxWords: 3 }),
            tipo: TipoGorjetaEnum.COLABORADOR,
            valor: valorDefault,
            valorInputRef: React.createRef()
          })
        })

        state.gorjetas.push({
          descricao: 'a Empresa',
          tipo: TipoGorjetaEnum.ESTABELECIMENTO,
          valorInputRef: React.createRef()
        })

        if (state.pagamento && state.pagamento.gorjetas) {
          state.gorjetas.forEach((gorjeta) => {
            state.pagamento.gorjetas.forEach((gorjetaArmazenada) => {
              if (
                gorjetaArmazenada.tipo.id === TipoGorjetaEnum.COLABORADOR.id &&
                gorjeta.tipo.id === TipoGorjetaEnum.COLABORADOR.id &&
                gorjeta.colaborador.id === gorjetaArmazenada.colaborador.id
              ) {
                gorjeta.valor = gorjetaArmazenada.valorTotal
                gorjeta.id = gorjetaArmazenada.id
              } else if (gorjetaArmazenada.tipo.id === TipoGorjetaEnum.ESTABELECIMENTO.id && gorjeta.tipo.id === TipoGorjetaEnum.ESTABELECIMENTO.id) {
                gorjeta.valor = gorjetaArmazenada.valorTotal
                gorjeta.id = gorjetaArmazenada.id
              }
            })
          })
        }

        this.setState(state)
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    let destinoValorAdicional = this.state.destinoValorAdicional
    let valorAdicional = this.state.valorAdicional
    const formaPagamentoDados = this.formaPagamentoFunctionMap.getValues()
    const gorjetas = []

    if (valorAdicional && valorAdicional > 0) {
      if (destinoValorAdicional.id === DestinoValorAdicionalPagamentoEnum.GORJETA.id) {
        for (let gorjeta of this.state.gorjetas) {
          const valorGorjeta = gorjeta.valorInputRef.current.value === '' ? 0 : FormUtil.convertStringToNumber(gorjeta.valorInputRef.current.value)
          const colaborador = {}

          if (gorjeta.tipo.id === TipoGorjetaEnum.COLABORADOR.id) {
            colaborador.id = gorjeta.colaborador.id
          }

          gorjetas.push({
            colaborador: colaborador,
            tipo: { id: gorjeta.tipo.id },
            id: gorjeta.id,
            valor: valorGorjeta
          })
        }
      }
    } else {
      destinoValorAdicional = undefined
    }

    return {
      id: this.state.pagamento ? this.state.pagamento.id : null,
      cliente: this.props.cliente,
      notaVenda: this.props.nota,
      formaPagamento: formaPagamentoDados.formaPagamento,
      adquirente: formaPagamentoDados.adquirente,
      bandeira: formaPagamentoDados.bandeira,
      numeroParcelas: formaPagamentoDados.numeroParcelas,
      dataPagamento: this.dataPagamentoFunctionMap.getDataAsInt(),
      valorEntrada: FormUtil.convertStringToNumber(this.inputValorEntrada.current.value),
      valorAdicional: valorAdicional,
      destinoValorAdicional: destinoValorAdicional,
      gorjetas: gorjetas,
      retornarVendas: this.props.retornarVendas ? this.props.retornarVendas : null,
      caixa: this.inputCaixaFunctionsMap.getCaixa()
    }
  }

  salvar = () => {
    if (this.state.pagamento && this.state.pagamento.valorTotalEstornos > 0) {
      openBackableDialog(MessageDialog, {
        text: (
          <span>
            Atenção: Este pagamento possui estorno e não é possível alterar um pagamento com estorno.
            <br /> <br /> <b>Valor do estorno: {formatarValorMonetario(this.state.pagamento.valorTotalEstornos)}</b> <br /> <br /> Deseja remover o estorno e salvar este pagamento?
          </span>
        ),
        actionButtons: [
          {
            label: 'Voltar'
          },
          {
            label: 'Confirmar',
            onClickFunction: (dialogInstance) => {
              this.salvarPagamento()
              dialogInstance.props.setShouldCloseParent(true)
              dialogInstance.props.handleCloseDialog()
            }
          }
        ]
      })
    } else {
      this.salvarPagamento()
    }
  }

  realizarPagamentoMercadoPago = () => {
    this.setState({ ajaxing: true })
    postAPI({
      url: 'erp/vendas.persistirNotaPagamento',
      requerAutorizacao: true,
      data: {
        ...this.getDados(),
        numeroParcelas: this.state.mercadoPago.tipo.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id ? this.state.mercadoPago.parcelas : 1,
        idMaquinaMercadoPago: this.state.mercadoPago.deviceId,
        tipoFormaPagamentoIntegrado: this.state.mercadoPago.tipo.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id ? 'CARTAO_CREDITO' : 'CARTAO_DEBITO'
      },
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: () => {
        getAPI({
          url: 'erp/vendas.buscarNotaVendaPorId',
          params: {
            idNota: this.props.nota.id
          },
          requerAutorizacao: true,
          onPreFinal: () => {
            this.setState({ ajaxing: false })
          },
          onSuccess: (response) => {
            EventsManager.pub('ManipulacaoNotaVendaPagamento')
            EventsManager.pub('AlteracaoSaldoCliente')

            openBackableDialog(MercadoPagoDialogPage, {
              isTotal: this.state.valorEntradaDefaultValue === this.state.mercadoPago.valor,
              idNota: this.props.nota.id,
              mercadoPayment: this.getDados(),
              mercadoPago: this.state.mercadoPago,
              mercadoPagoPagamentoId: response.data.pagamentos[0].id,
              handleCloseDialog: this.props.handleCloseDialog || this.props.thisNotaDialogPage.handleCloseDialog,
              thisNotaDialogPage: this.props.thisNotaDialogPage
            })
          },
          onError: (response) => {
            notifyContentNotLoaded({ messageErrorCode: response.code })
          }
        })
      },
      onError: (response) => {
        const errorsMap = createErrorsMap(response.data.errors)
        this.setState((state) => ({ ajaxing: false, errorsMap }))
        focusFirstElementWithError('formNotaVendaPagamento')
        EventsManager.pub('MensagemSnackBar', response.data.errors['Pagamento Integrado Mercado Pago'])
      }
    })
  }

  salvarPagamento = () => {
    if (!verificarAutorizacao([PA.PODE_REGISTRAR_PAGAMENTO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/vendas.persistirNotaPagamento',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoNotaVendaPagamento')
        EventsManager.pub('AlteracaoSaldoCliente')

        if (this.props.retornarVendas) {
          EventsManager.pub('ManipulacaoVendas', {
            action: 'alteracaoStatus',
            notaVenda: response.data.notaVenda,
            vendas: response.data.vendas
          })
        }

        this.props.handleCloseDialog()
      },
      onError: (response) => {
        const errorsMap = createErrorsMap(response.data.errors)
        this.setState((state) => ({ ajaxing: false, errorsMap }))
        focusFirstElementWithError('formNotaVendaPagamento')

        if (errorsMap('totalGorjetas')) {
          openBackableDialog(MessageDialog, {
            title: 'Gorjetas',
            text: errorsMap('totalGorjetas')
          })
        } else if (errorsMap('conflitoValorAdicional')) {
          const message = errorsMap('conflitoValorAdicional')
          this.ajustarConflitoValorAdicional(message.valorPendenteNotaAtualizado)
        } else if (errorsMap('pacoteCancelado')) {
          const message = errorsMap('pacoteCancelado')
          openBackableDialog(MessageDialog, {
            title: 'Pacote Cancelado',
            text: message
          })
        }
      }
    })
  }

  getValorPendentePagamento = () => {
    const valorEntrada = this.state.pagamento ? this.state.pagamento.valorEntrada : 0
    const valorAdicional = this.state.pagamento ? this.state.pagamento.valorAdicional : 0

    let valorPendentePagamento = this.valorPendentePagamento

    if (valorEntrada) {
      valorPendentePagamento = valorPendentePagamento + valorEntrada - valorAdicional
    }

    if (valorPendentePagamento < 0) {
      valorPendentePagamento = 0
    }
    return valorPendentePagamento
  }

  ajustarConflitoValorAdicional = (valorPendenteNotaAtualizado) => {
    const valorEntrada = FormUtil.convertStringToNumber(this.inputValorEntrada.current.value)
    let valorAdicionalRecalculado = valorEntrada - this.getValorPendentePagamento()
    if (valorAdicionalRecalculado < 0) {
      valorAdicionalRecalculado = 0
    }
    if (valorAdicionalRecalculado !== this.state.valorAdicional) {
      openBackableDialog(MessageDialog, {
        text: 'As informações de pagamento estão desatualizados. O sistema irá atualizar o formulário para que seja possível salvar os dados corretamente.',
        onClosed: () => {
          const { gorjetas } = this.state
          if (gorjetas && gorjetas.length > 0) {
            for (let gorjeta of gorjetas) {
              gorjeta.valor = 0
            }
          }
          this.setState({ valorAdicional: NumberUtil.round(valorAdicionalRecalculado), gorjetas: gorjetas })
        }
      })
    } else {
      this.valorPendentePagamento = valorPendenteNotaAtualizado
      valorAdicionalRecalculado = valorEntrada - this.getValorPendentePagamento()
      openBackableDialog(MessageDialog, {
        text: 'As informações de pagamento estão desatualizados. O sistema irá atualizar o formulário para que seja possível salvar os dados corretamente.',
        onClosed: () => {
          const { gorjetas } = this.state
          for (let gorjeta of gorjetas) {
            gorjeta.valor = 0
          }
          this.setState({ valorAdicional: NumberUtil.round(valorAdicionalRecalculado), gorjetas: gorjetas })
        }
      })
    }
  }

  onChangeValor = (valor) => {
    if (valor > this.getValorPendentePagamento()) {
      const formaPagamento = this.formaPagamentoFunctionMap.getValues().formaPagamento
      let destinoValorAdicional = DestinoValorAdicionalPagamentoEnum.CREDITO
      if (!formaPagamento || !formaPagamento.tipo || formaPagamento.tipo.id === FormaPagamentoTipoEnum.DINHEIRO.id) {
        destinoValorAdicional = DestinoValorAdicionalPagamentoEnum.TROCO
      }
      if (this.state.destinoValorAdicional) {
        destinoValorAdicional = this.state.destinoValorAdicional
      }
      this.setState({
        valorAdicional: NumberUtil.round(valor - this.getValorPendentePagamento()),
        destinoValorAdicional: destinoValorAdicional
      })
    } else {
      if (this.state.valorAdicional) {
        this.setState({
          valorAdicional: 0,
          mercadoPago: {
            valor
          }
        })
      }
    }
  }

  componentDidMount() {
    this.setState({ mercadoPago: { ...this.state.mercadoPago, parcelas: this.state.formaPagamento?.mercadoPago?.parcelas } })
  }

  componentDidUpdate(_, prevState) {
    if (this.state.formaPagamento !== prevState.formaPagamento) {
      const mercadoPago = this.state.formaPagamento.mercadoPago

      this.setState({
        mercadoPago: {
          ...mercadoPago,
          valor: this.state.valorEntradaDefaultValue
        }
      })
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { valorEntradaDefaultValue, keyInputValorEntrada, valorAdicional, ajaxing, errorsMap, caixas, data, gorjetas } = this.state
    const { formaPagamento, adquirente, bandeira, numeroParcelas, caixa, valorTotalEstornos } = this.state.pagamento ? this.state.pagamento : {}

    let dataPagamentoDefaultAsMoment = data

    let formaPagamentoDefaultValue
    if (formaPagamento && formaPagamento.tipo) {
      formaPagamentoDefaultValue = {
        formaPagamento: formaPagamento,
        adquirente: adquirente,
        bandeira: bandeira,
        numeroParcelas: numeroParcelas
      }
    }

    let mensagemAjudaEstorno = null

    if (valorTotalEstornos && valorTotalEstornos > 0) {
      mensagemAjudaEstorno =
        'Atenção: Este pagamento tem estorno de ' + formatarValorMonetario(valorTotalEstornos) + '. Para alterar este pagamento, o sistema precisará remover este estorno.'
    }

    const totalParcelas = Math.floor(valorEntradaDefaultValue / 10)
    const numeroParcelasCalc = totalParcelas > 12 ? 12 : totalParcelas <= 1 ? 1 : totalParcelas
    const numeroParcelasMercadoPago = Array.from({ length: numeroParcelasCalc }, (_, index) => index + 1)

    const listaFormaPagamentoCreditoDebito = [
      {
        tipo: {
          id: 2
        },
        nome: 'Cartão de Crédito'
      },
      {
        tipo: {
          id: 3
        },
        nome: 'Cartão de Débito'
      }
    ]

    const isCredito = this.state.mercadoPago?.tipo?.tipo?.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id
    const isMercadoPago = MercadoPagoUtils.isMercadoPago(this.state.formaPagamento)
    const notaDialogPageProps = this
    return (
      <DialogPage {...dialogProps} align="center" contentMaxWidth={570} title={this.state.title} ajaxing={this.state.ajaxing}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <React.Fragment>
              <Typography variant="body1" style={{ marginBottom: 24 }} align="right">
                <span style={{ fontWeight: 500, marginRight: 10 }}>Falta Pagar</span> {formatarValorMonetario(this.getValorPendentePagamento())}
              </Typography>

              <form id="formNotaVendaPagamento" className={classes.form} autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                <div>
                  <InputFormaPagamento
                    notaDialogPageProps={notaDialogPageProps}
                    valorTotal={this.getValorPendentePagamento()}
                    idname="xformaPagamento"
                    disabled={ajaxing}
                    defaultValue={formaPagamentoDefaultValue}
                    autoFocus={true}
                    customVariant={'outlined-small'}
                    shrink={false}
                    cliente={this.props.cliente}
                    errorMessage={errorsMap('formaPagamento')}
                    label="Forma de Pagamento"
                    functionsMap={this.formaPagamentoFunctionMap}
                    onChange={(pagamento) => {
                      const newState = {
                        formaPagamento: null
                      }
                      if (pagamento) {
                        if (pagamento.formaPagamento && pagamento.formaPagamento.tipo && pagamento.formaPagamento.tipo.id) {
                          newState.formaPagamento = pagamento.formaPagamento
                          if (FormaPagamentoTipoEnum.DINHEIRO.id !== pagamento.formaPagamento.tipo.id) {
                            if (FormaPagamentoTipoEnum.CREDITO.id === pagamento.formaPagamento.tipo.id) {
                              const valorAtual = FormUtil.convertStringToNumber(this.inputValorEntrada.current.value)
                              if (pagamento.cliente.saldo < valorAtual) {
                                const novoValor = pagamento.cliente.saldo
                                this.onChangeValor(novoValor)
                                if (this.state.formaPagamento ? this.state.formaPagamento.tipo.id : 0 !== newState.formaPagamento.tipo.id) {
                                  newState.keyInputValorEntrada = this.state.keyInputValorEntrada + 1
                                  newState.valorEntradaDefaultValue = novoValor
                                }
                              }
                            }
                            if (this.state.destinoValorAdicional.id === DestinoValorAdicionalPagamentoEnum.TROCO.id) {
                              newState.destinoValorAdicional = DestinoValorAdicionalPagamentoEnum.CREDITO
                            }
                          }
                        }
                      }
                      this.setState(newState)
                    }}
                  />

                  {isMercadoPago ? (
                    <>
                      <Grid container alignItems="flex-start" spacing={1}>
                        <Grid item xs={isCredito ? 6 : 12}>
                          <InputText
                            key={'valorEntrada' + keyInputValorEntrada}
                            marginTop={20}
                            disabled={ajaxing}
                            idname="xvalorEntrada"
                            label="Valor (R$)"
                            customVariant={'outlined-small'}
                            shrink={false}
                            defaultValue={valorEntradaDefaultValue}
                            errorMessage={errorsMap('valorEntrada')}
                            inputComponent={InputMoneyFormat}
                            inputRef={this.inputValorEntrada}
                            onChange={() => {
                              const valor = FormUtil.convertStringToNumber(this.inputValorEntrada.current.value)
                              this.setState({ mercadoPago: { ...this.state.mercadoPago, valor: valor || '0,00' } })
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          {isCredito && (
                            <InputSelect
                              hideIconComponent
                              style={{ marginTop: 20 }}
                              label="Parcelas"
                              fullWidth
                              customVariant="outlined-small"
                              shrink={false}
                              value={this.state.mercadoPago.parcelas}
                              onChange={(event) => {
                                const value = event.target.value
                                this.setState({ mercadoPago: { ...this.state.mercadoPago, parcelas: value } })
                              }}
                            >
                              {numeroParcelasMercadoPago.map((value, index) => (
                                <MenuItem key={value} value={value}>
                                  {index === 0 ? `À vista` : `${value}x`}
                                </MenuItem>
                              ))}
                            </InputSelect>
                          )}
                        </Grid>
                      </Grid>
                      <InputCaixa
                        marginTop={true}
                        customVariant="outlined-small"
                        shrink={false}
                        utilizacaoObrigatoria
                        disabled={ajaxing}
                        data={dataPagamentoDefaultAsMoment}
                        colaboradorPreferencial={getSessaoPainel().colaborador}
                        caixaDefaultValue={caixa}
                        caixas={caixas}
                        errorsMap={errorsMap}
                        descricaoData="data do pagamento"
                        functionsMap={this.inputCaixaFunctionsMap}
                      />
                      <Grid container alignItems="flex-start" spacing={1}>
                        <Grid item xs>
                          <InputSelect
                            hideIconComponent
                            style={{ marginTop: 20 }}
                            label="Tipo"
                            fullWidth
                            customVariant="outlined-small"
                            shrink={false}
                            value={this.state.mercadoPago.tipo?.nome}
                            onChange={(event) => {
                              const value = event.target.value
                              const pagamento = listaFormaPagamentoCreditoDebito.find((pagamento) => pagamento.nome === value)
                              this.setState({ mercadoPago: { ...this.state.mercadoPago, tipo: { nome: value, tipo: { id: pagamento.tipo.id } } } })
                            }}
                          >
                            {listaFormaPagamentoCreditoDebito.map((value) => (
                              <MenuItem key={value} value={value.nome}>
                                {value.nome}
                              </MenuItem>
                            ))}
                          </InputSelect>
                        </Grid>
                        <Grid item xs>
                          <InputData
                            style={{ minWidth: 164 }}
                            marginTop={true}
                            label="Data do Pagamento"
                            customVariant={'outlined-small'}
                            shrink={false}
                            disabled={ajaxing}
                            functionsMap={this.dataPagamentoFunctionMap}
                            keyboard={true}
                            idname="xdataPagamento"
                            errorMessage={errorsMap('dataPagamento')}
                            defaultValue={dataPagamentoDefaultAsMoment}
                            onChange={(data) => {
                              const dataAsMoment = data ? moment(data) : null
                              const { state } = this
                              state.data = dataAsMoment
                              this.inputCaixaFunctionsMap.setData(dataAsMoment)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container alignItems="flex-start" spacing={1}>
                        <Grid item xs>
                          <InputText
                            key={'valorEntrada' + keyInputValorEntrada}
                            marginTop={true}
                            disabled={ajaxing}
                            idname="xvalorEntrada"
                            label="Valor (R$)"
                            customVariant={'outlined-small'}
                            shrink={false}
                            defaultValue={valorEntradaDefaultValue}
                            errorMessage={errorsMap('valorEntrada')}
                            inputComponent={InputMoneyFormat}
                            inputRef={this.inputValorEntrada}
                            onChange={(event) => {
                              const valor = FormUtil.convertStringToNumber(this.inputValorEntrada.current.value)
                              this.onChangeValor(valor)
                            }}
                          />
                        </Grid>

                        <Grid item xs>
                          <InputData
                            style={{ minWidth: 164 }}
                            marginTop={true}
                            label="Data do Pagamento"
                            customVariant={'outlined-small'}
                            shrink={false}
                            disabled={ajaxing}
                            functionsMap={this.dataPagamentoFunctionMap}
                            keyboard={true}
                            idname="xdataPagamento"
                            errorMessage={errorsMap('dataPagamento')}
                            defaultValue={dataPagamentoDefaultAsMoment}
                            onChange={(data) => {
                              const dataAsMoment = data ? moment(data) : null
                              const { state } = this
                              state.data = dataAsMoment
                              this.inputCaixaFunctionsMap.setData(dataAsMoment)
                            }}
                          />
                        </Grid>
                      </Grid>

                      <InputCaixa
                        marginTop={true}
                        customVariant="outlined-small"
                        shrink={false}
                        utilizacaoObrigatoria={true}
                        disabled={ajaxing}
                        data={dataPagamentoDefaultAsMoment}
                        colaboradorPreferencial={getSessaoPainel().colaborador}
                        caixaDefaultValue={caixa}
                        caixas={caixas}
                        errorsMap={errorsMap}
                        descricaoData="data do pagamento"
                        functionsMap={this.inputCaixaFunctionsMap}
                      />

                      {mensagemAjudaEstorno && (
                        <FormHelperText margin={10} error={true}>
                          {mensagemAjudaEstorno}
                        </FormHelperText>
                      )}

                      {valorAdicional > 0 && this.state.formaPagamento && (
                        <React.Fragment>
                          <Typography variant="body2" style={{ marginTop: 24, marginBottom: 14, color: '#999' }}>
                            O que fazer com o valor adicional pago?
                          </Typography>
                          <RadioGroup
                            value={this.state.destinoValorAdicional.id.toString()}
                            onChange={(event) => {
                              this.setState({
                                destinoValorAdicional: DestinoValorAdicionalPagamentoEnum.getById(parseInt(event.target.value, 10))
                              })
                            }}
                          >
                            {this.state.formaPagamento.tipo.id === FormaPagamentoTipoEnum.DINHEIRO.id && (
                              <FormControlLabel
                                disabled={ajaxing}
                                value={DestinoValorAdicionalPagamentoEnum.TROCO.id.toString()}
                                control={<Radio />}
                                label={
                                  <Typography variant="body2" className={classes.labelOpcaoValorAdicional}>
                                    Registrar {formatarValorMonetario(valorAdicional)} como troco
                                  </Typography>
                                }
                              />
                            )}

                            {this.state.formaPagamento.tipo.id !== FormaPagamentoTipoEnum.CREDITO.id && (
                              <FormControlLabel
                                disabled={ajaxing}
                                value={DestinoValorAdicionalPagamentoEnum.CREDITO.id.toString()}
                                control={<Radio />}
                                label={
                                  <Typography variant="body2" className={classes.labelOpcaoValorAdicional}>
                                    Registrar {formatarValorMonetario(valorAdicional)} como crédito para cliente
                                  </Typography>
                                }
                              />
                            )}

                            <FormControlLabel
                              disabled={ajaxing}
                              value={DestinoValorAdicionalPagamentoEnum.GORJETA.id.toString()}
                              control={<Radio />}
                              label={
                                <Typography variant="body2" className={classes.labelOpcaoValorAdicional}>
                                  Registrar {formatarValorMonetario(valorAdicional)} como gorjeta
                                </Typography>
                              }
                            />
                          </RadioGroup>

                          {this.state.destinoValorAdicional.id === DestinoValorAdicionalPagamentoEnum.GORJETA.id && (
                            <ContentWithPreload loadFunction={this.carregarVendas}>
                              {() =>
                                gorjetas.map((gorjeta, indice) => {
                                  let key = 0
                                  let staticHelpMessage = null
                                  if (gorjeta.colaborador) {
                                    key = gorjeta.colaborador.id
                                    staticHelpMessage = (
                                      <span>
                                        Este valor ficará pendente no sistema até que seja registrado o repasse dessa gorjeta para o profissional.
                                        <Link
                                          onClick={() => {
                                            openBackableDialog(MessageDialog, {
                                              maxWidth: 600,
                                              title: 'Gorjetas',
                                              text: (
                                                <span>
                                                  A gorjetas registradas para profissionais possuem o objetivo de controlar o pagamento/repasse dos valores depois que o cliente
                                                  efetua o pagamento, seja no fim do dia, semana ou mês. Esses valores permanecerão pendentes para o acerto com o profissional na
                                                  tela de <b>Remunerações</b>, junto com os demais valores pendentes de possíveis comissões (é possível acertar todos os valores
                                                  pendentes de comissões e gorjetas de uma única vez).
                                                  <br />
                                                  <br />
                                                  Para casos em que o profissional recebe a gorjeta no mesmo momento em que o cliente paga a conta, não é aconselhavel realizar esse
                                                  controle e registrar o valor como gorjeta.
                                                  <br />
                                                  <br />
                                                  <b>Exemplo 1:</b> A conta é de R$ 90,00, mas o cliente efetua o pagamento de R$ 100,00 no cartão de crédito com o objetivo de
                                                  entregar R$ 10,00 como gorjeta para o profissional. Neste caso, como o profissional não recebe a gorjeta no mesmo momento em que o
                                                  cliente paga a conta, a empresa deve controlar esse valor para não esquecer de repassá-lo para o profissional depois (no fim do
                                                  dia, semana ou mês). Para isso, <u>é aconselhavel</u> registrar o pagamento do cliente como R$ 100,00 e depois registrar R$ 10,00
                                                  como gorjeta para o profissional.
                                                  <br />
                                                  <br />
                                                  <b>Exemplo 2:</b> A conta é de R$ 90,00, mas o cliente efetua o pagamento de R$ 100,00 no dinheiro com o objetivo de entregar R$
                                                  10,00 como gorjeta para o profissional. Neste caso, como o pagamento foi em dinheiro, a gorjeta pode ser entregue no mesmo momento
                                                  para o colaborador e <u>não é aconselhavel</u> registrar o valor como gorjeta no sistema. Desta forma, é preciso apenas registrar
                                                  R$ 90,00 no sistema como pagamento do cliente.
                                                </span>
                                              )
                                            })
                                          }}
                                          color="secondary"
                                        >
                                          <b> Saiba Mais</b>
                                        </Link>
                                      </span>
                                    )
                                  }
                                  return (
                                    <InputText
                                      key={'c-' + valorAdicional + '-' + key}
                                      marginTop={true}
                                      customVariant="outlined-small"
                                      shrink={false}
                                      defaultValue={gorjeta.valor}
                                      label={'Gorjeta para ' + gorjeta.descricao + ' (R$)'}
                                      staticHelpMessage={staticHelpMessage}
                                      inputComponent={InputMoneyFormat}
                                      errorMessage={errorsMap('gorjeta[' + key + ']')}
                                      inputRef={gorjeta.valorInputRef}
                                    />
                                  )
                                })
                              }
                            </ContentWithPreload>
                          )}
                        </React.Fragment>
                      )}
                    </>
                  )}
                </div>
                <div className={classes.wrapperButtons}>
                  <Button className={classes.cancelar} disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  {isMercadoPago ? (
                    <Button className={classes.realizarPagamento} disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.realizarPagamentoMercadoPago}>
                      {isMobile ? 'Continuar' : 'Realizar pagamento'}
                    </Button>
                  ) : (
                    <Button className={classes.realizarPagamento} disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                      {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                    </Button>
                  )}
                </div>
                <HiddenSubmitButton />
              </form>
            </React.Fragment>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

NotaPagamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired,
  nota: {
    id: PropTypes.number
  }
}

export default withStyles(styles)(NotaPagamentoDialogPage)
