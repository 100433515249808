import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import FornecedorDialogPage from 'pages/erp/painel/inventario/FornecedorDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class FornecedoresDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      ajaxing: false,
      itemMenu: null,
      itemMenuAnchorEl: null
    }
    this.inputNomeFiltro = React.createRef()
  }

  componentDidMount() {
    this.eventsManager.sub('ManutencaoFornecedor', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerRemover = (fornecedor) => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])) {
      openBackableDialog(EntityRemovalDialog, {
        title: 'Remover Fornecedor',
        text: (
          <span>
            Você tem certeza que deseja remover o fornecedor <b>{fornecedor.nome}</b>? Essa operação não poderá ser desfeita.
          </span>
        ),
        call: {
          method: 'post',
          url: 'erp/inventario.removerFornecedor',
          contextoUsuario: 'erp',
          data: {
            id: fornecedor.id
          },
          onSuccess: () => {
            EventsManager.pub('ManutencaoFornecedor')
          }
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerOpenCreateDialog = () => {
    if (verificarAutorizacao([PA.PODE_GERENCIAR_PRODUTO])) {
      openBackableDialog(FornecedorDialogPage)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = (item) => {
    openBackableDialog(FornecedorDialogPage, {
      idFornecedor: item.id
    })
  }

  getRenderContent = (size, fornecedor) => {
    const content = {}

    content.nome = fornecedor.nome
    content.botaoRemover = (
      <IconButton
        style={{ marginRight: size === 'small' ? -16 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.handlerRemover(fornecedor)
        }}
      >
        <DeleteIcon />
      </IconButton>
    )
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage
        {...dialogProps}
        title="Fornecedores"
        align="center"
        contentMaxWidth={600}
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              icon: <AddIcon />,
              handleAction: this.handlerOpenCreateDialog
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent context="DialogPage" type="filter">
              <Grid container alignItems="center">
                <Grid item xs>
                  <Grid container alignItems="center" justify="center">
                    <Grid item style={{ marginRight: 16 }}>
                      <SearchIcon color="secondary" />
                    </Grid>
                    <Grid item xs>
                      <InputText
                        customVariant="naked"
                        nakedLeftPadding={false}
                        placeholder="Procurar por nome..."
                        onChange={(event) => {
                          this.loaderFunctionsMap.load()
                        }}
                        inputRef={this.inputNomeFiltro}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FloatContent>

            <br />

            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              itemsPerRequest={20}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/inventario.buscarFornecedores"
              getRequestParametersFunction={() => ({
                nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null
              })}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              onClickRow={(item, event) => {
                this.handlerEditar(item)
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Nome', props: { xs: true } },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.nome, content.botaoRemover]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 80,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography variant="body2">{content.nome}</Typography>
                          </Grid>
                          <Grid item>{content.botaoRemover}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum fornecedor foi encontrado',
                textButton: 'Cadastrar fornecedor',
                creationFunction: this.handlerOpenCreateDialog
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

FornecedoresDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FornecedoresDialogPage)
