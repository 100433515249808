import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import logo from 'img/logo_salao99_painel.png'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: theme.container.basic(),
  root2: theme.container.centered({ maxWidth: 99999 }),
  logoCentral: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 200,
    [theme.breakpoints.down(1380)]: {
      width: 200
    },
    [theme.breakpoints.down(700)]: {
      width: 190
    },
    [theme.breakpoints.down(500)]: {
      width: 164
    }
  },
  titulo: {
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    letterSpacing: '.02125rem',
    fontWeight: '400',
    textAlign: 'center',
    maxWidth: 526,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20
  }
})

class PaginaNaoEncontradaContent extends Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classNames(classes.root, 'minheight100')}>
        <Helmet>
          <title>Página não encontrada</title>
          <meta name="description" content={'Página não encontrada'} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className={classNames(classes.root2, 'minheight100')} style={{ paddingTop: 56, textAlign: 'center' }}>
          <img src={logo} alt="Salão99" className={classes.logoCentral} />
          <Typography align="center" className={classes.titulo} component="h1">
            Página Não Encontrada
          </Typography>
          <Link color="secondary" href="/" style={{ marginTop: 16, fontSize: 18, display: 'inline-block' }}>
            Ir para a Página Inicial
          </Link>
        </div>
      </div>
    )
  }
}

PaginaNaoEncontradaContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PaginaNaoEncontradaContent)
