import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import InputCaixa from 'pages/erp/painel/caixa/input/InputCaixa'
import { getSessaoPainel, verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import DestinoValorAdicionalPagamentoEnum from 'support/domain/venda/DestinoValorAdicionalPagamentoEnum'
import FormUtil, { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'
import { MercadoPagoUtils } from 'support/util/MercadoPagoUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import StringUtil from 'support/util/StringUtil'

import { EstornoStatusPagamento } from './EstornoStatusPagamento'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  form: {
    paddingTop: '10px',
    '@media (max-width: 768px)': {
      minHeight: 'calc(100vh - 76px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  },
  saveButton: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'block',
      textTransform: 'capitalize',
      width: '100%',
      minHeight: '48px',
      marginBottom: '30px'
    }
  },
  buttons: {
    minHeight: '40px'
  }
})

class EstornoPagamentoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Estorno',
      errorsMap: createErrorsMap(),
      tipoPagamentoEstorno: FormaPagamentoTipoEnum.DINHEIRO,
      ajaxing: false,
      isFetching: false,
      status: 'loading',
      errorMessage: '',
      caixas: []
    }

    this.dataEstornoFunctionMap = {}
    this.inputValorEstornoVenda = React.createRef()
    this.inputValorEstornoCreditoTrocoPagamento = React.createRef()
    this.inputCaixaFunctionsMap = {}
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/vendas.buscarDadosParaPersistenciaEstorno',
      params: {
        idPagamento: this.props.pagamento.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        let tipoPagamentoEstorno
        const pagamento = response.data.pagamento

        if (MercadoPagoUtils.isMercadoPago(pagamento?.formaPagamento)) {
          tipoPagamentoEstorno = FormaPagamentoTipoEnum.getById(pagamento.formaPagamento.tipo.id)
        } else if (pagamento.formaPagamentoEstorno) {
          tipoPagamentoEstorno = FormaPagamentoTipoEnum.getById(pagamento.formaPagamentoEstorno.tipo.id)
        } else {
          tipoPagamentoEstorno = FormaPagamentoTipoEnum.DINHEIRO
        }

        if (pagamento.destinoValorAdicional && pagamento.destinoValorAdicional.id === DestinoValorAdicionalPagamentoEnum.GORJETA.id) {
          pagamento.gorjetas.sort(function (a, b) {
            const nomeColaboradorA = a.colaborador && a.colaborador.apelido ? a.colaborador.apelido : null
            const nomeColaboradorB = b.colaborador && b.colaborador.apelido ? b.colaborador.apelido : null
            if (nomeColaboradorA && !nomeColaboradorB) {
              return -1
            } else if (!nomeColaboradorA && nomeColaboradorB) {
              return 1
            } else {
              if (nomeColaboradorA < nomeColaboradorB) return -1
              if (nomeColaboradorA > nomeColaboradorB) return 1
              return 0
            }
          })
          pagamento.gorjetas.forEach((gorjeta) => {
            gorjeta.valorInputRef = React.createRef()
          })
        }

        this.setState({
          ajaxing: false,
          pagamento: pagamento,
          caixas: response.data.caixas,
          tipoPagamentoEstorno: tipoPagamentoEstorno
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    const { tipoPagamentoEstorno, pagamento } = this.state

    const dados = {}
    const gorjetas = []

    dados.idPagamento = pagamento.id
    if (this.inputValorEstornoVenda.current) {
      dados.valorEstornoVenda = FormUtil.convertStringToNumber(this.inputValorEstornoVenda.current.value)
    }
    dados.dataEstorno = this.dataEstornoFunctionMap.getDataAsInt()
    dados.tipoPagamentoEstorno = {
      id: tipoPagamentoEstorno.id
    }

    if (pagamento.destinoValorAdicional) {
      if (pagamento.destinoValorAdicional.id === DestinoValorAdicionalPagamentoEnum.GORJETA.id) {
        for (let gorjeta of pagamento.gorjetas) {
          const valorEstornoGorjeta = gorjeta.valorInputRef.current.value === '' ? 0 : FormUtil.convertStringToNumber(gorjeta.valorInputRef.current.value)

          gorjetas.push({
            id: gorjeta.id,
            valorEstorno: valorEstornoGorjeta
          })
        }

        dados.gorjetas = gorjetas
      }
      if (pagamento.destinoValorAdicional.id === DestinoValorAdicionalPagamentoEnum.CREDITO.id) {
        dados.valorEstornoCreditoTrocoPagamento = FormUtil.convertStringToNumber(this.inputValorEstornoCreditoTrocoPagamento.current.value)
      }
    }

    dados.caixa = this.inputCaixaFunctionsMap.getCaixa()
    return dados
  }

  salvar = () => {
    if (!verificarAutorizacao([PA.PODE_REGISTRAR_ESTORNO])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    this.setState((state) => ({
      isFetching: true,
      status: 'loading',
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/vendas.persistirPagamentoEstorno',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: () => {
        EventsManager.pub('EstornoNotaVendaPagamento')
        EventsManager.pub('AlteracaoSaldoCliente')
        const isMercadoPagoIntegrado = MercadoPagoUtils.isMercadoPago(this.props.pagamento.formaPagamento)
        if(isMercadoPagoIntegrado) {
          postAPI({
            url: 'erp/vendas.reabrirNota',
            requerAutorizacao: true,
            data: {
              id: this.props.nota.id
            },
            onSuccess: () => {
              this.setState({
                status: 'success'
              })
            }
          })
        } else {
          this.setState({
            status: 'success'
          })
        }
      },
      onError: (response) => {
        if (response.data.errors) {
          const errorKey = response.data.errors ? Object.keys(response.data.errors).find((error) => error) : {}
          this.setState({
            status: 'error',
            errorMessage: response.data.errors[errorKey],
            errorsMap: createErrorsMap(response.data.errors)
          })
        }
        this.setState({
          status: 'error'
        })
        focusFirstElementWithError('formNotaVendaPagamento')
      },
      onFinally: () => {
        this.setState({
          status: 'loading'
        })
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, errorMessage, isFetching, status, errorsMap, pagamento, tipoPagamentoEstorno, caixas } = this.state
    const { formaPagamento, dataEstorno, valorEstornoVenda, despesaEstorno, gorjetas, clienteCreditoTrocoPagamento } = pagamento ? pagamento : {}
    const pagamentoCartao =
      formaPagamento && (formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id || formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id)
    const isMercadoPago = MercadoPagoUtils.isMercadoPago(this.state?.pagamento?.formaPagamento)
    let dataEstornoDefaultAsMoment
    dataEstornoDefaultAsMoment = moment()

    let valorVenda = 0

    if (pagamento) {
      valorVenda = pagamento.destinoValorAdicional ? pagamento.valorPago - pagamento.valorAdicional : pagamento.valorPago
    }

    let valorEstornoVendasDefault = isMercadoPago ? pagamento.valorPagoFinal : valorVenda
    let valorEstornoCreditoDefault = clienteCreditoTrocoPagamento && clienteCreditoTrocoPagamento.valorEstorno > 0 ? clienteCreditoTrocoPagamento.valorEstorno : 0
    valorEstornoCreditoDefault = tipoPagamentoEstorno && tipoPagamentoEstorno.id !== FormaPagamentoTipoEnum.CREDITO.id ? valorEstornoCreditoDefault : 0

    let caixa = null
    let utilizarDefaultValue = true
    let caixaDisponivel = true

    if (despesaEstorno && despesaEstorno.caixa) {
      caixa = despesaEstorno.caixa
    }

    if (dataEstorno && !caixa) {
      utilizarDefaultValue = false
    }

    if (
      tipoPagamentoEstorno.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id ||
      tipoPagamentoEstorno.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id ||
      tipoPagamentoEstorno.id === FormaPagamentoTipoEnum.CREDITO.id ||
      tipoPagamentoEstorno.id === FormaPagamentoTipoEnum.PIX.id
    ) {
      caixaDisponivel = false
    }

    const maxDate = moment().add(60, 'days').toDate()

    return (
      <DialogPage
        {...dialogProps}
        align="center"
        contentMaxWidth={570}
        title={isFetching ? '' : this.state.title}
        ajaxing={this.state.ajaxing}
        noPadding
        hidebackButton={isFetching}
        hasCloseButton={status === 'error'}
        handleCloseDialog={() => {
          if (this.state.isFetching) {
            this.setState({ isFetching: false, status: 'loading' })
          } else {
            this.props.handleCloseDialog()
          }
        }}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <React.Fragment>
              <form className={classes.form} id="formNotaVendaPagamento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                {isFetching ? (
                  <EstornoStatusPagamento statusType={status} errorMessage={errorMessage} handleCloseDialog={this.props.handleCloseDialog} />
                ) : (
                  <>
                    <div>
                      <InputSelect
                        idname="xforma"
                        disabled={isMercadoPago || ajaxing}
                        label="Forma de estorno"
                        customVariant="outlined-small"
                        shrink={false}
                        value={tipoPagamentoEstorno.id}
                        errorMessage={errorsMap('tipoPagamentoEstorno')}
                        onChange={(event) => {
                          this.setState({
                            tipoPagamentoEstorno: FormaPagamentoTipoEnum.getById(parseInt(event.target.value, 10))
                          })
                        }}
                      >
                        {FormaPagamentoTipoEnum.values()
                          .filter((forma) => {
                            if (!isMercadoPago) {
                              switch (forma.id) {
                                case FormaPagamentoTipoEnum.DINHEIRO.id:
                                  return true
                                case FormaPagamentoTipoEnum.CREDITO.id:
                                  return true
                                case FormaPagamentoTipoEnum.CARTAO_CREDITO.id:
                                  if (pagamentoCartao && formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id) {
                                    return true
                                  }
                                  break
                                case FormaPagamentoTipoEnum.CARTAO_DEBITO.id:
                                  if (pagamentoCartao && formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id) {
                                    return true
                                  }
                                  break
                                case FormaPagamentoTipoEnum.PIX.id:
                                  if (formaPagamento.tipo.id === FormaPagamentoTipoEnum.PIX.id) {
                                    return true
                                  }
                                  break
                                default:
                                  return false
                              }
                            }
                            switch (forma.id) {
                              case FormaPagamentoTipoEnum.MERCADO_PAGO.id:
                                return true
                              default:
                                return false
                            }
                          })
                          .map((forma) => (
                            <MenuItem key={forma.id} value={forma.id}>
                              {forma.descricao}
                            </MenuItem>
                          ))}
                      </InputSelect>

                      {pagamento.valorAdicional !== pagamento.valorEntrada && (
                        <InputText
                          marginTop={true}
                          key={'valor_' + tipoPagamentoEstorno.id}
                          disabled={isMercadoPago || ajaxing}
                          customVariant="outlined-small"
                          shrink={false}
                          idname="xvalorEstornoVenda"
                          label="Valor do estorno (R$)"
                          defaultValue={valorEstornoVendasDefault}
                          errorMessage={errorsMap('valorEstornoVenda')}
                          staticHelpMessage={
                            <span>
                              É possível estornar o valor máximo de <b>{formatarValorMonetario(valorVenda)}</b> (valor original do pagamento)
                            </span>
                          }
                          inputComponent={InputMoneyFormat}
                          inputRef={this.inputValorEstornoVenda}
                          inputProps={{
                            minValue: 0
                          }}
                        />
                      )}

                      {clienteCreditoTrocoPagamento && (
                        <InputText
                          marginTop={true}
                          customVariant="outlined-small"
                          shrink={false}
                          key={'credito_' + tipoPagamentoEstorno.id}
                          disabled={ajaxing || tipoPagamentoEstorno.id === FormaPagamentoTipoEnum.CREDITO.id}
                          label={'Estorno do crédito do cliente (R$)'}
                          defaultValue={valorEstornoCreditoDefault}
                          inputComponent={InputMoneyFormat}
                          errorMessage={errorsMap('valorEstornoCreditoTrocoPagamento')}
                          staticHelpMessage={
                            <span>
                              É possível estornar o valor máximo de <b>{formatarValorMonetario(clienteCreditoTrocoPagamento.valorTotal)}</b> (valor original do crédito)
                            </span>
                          }
                          inputRef={this.inputValorEstornoCreditoTrocoPagamento}
                          inputProps={{
                            minValue: 0
                          }}
                        />
                      )}

                      {gorjetas &&
                        gorjetas.map((gorjeta, indice) => {
                          let key = 0
                          let complementoLabel = 'da Empresa'

                          if (gorjeta.colaborador) {
                            key = gorjeta.colaborador.id
                            complementoLabel = StringUtil.applyMaxWords(gorjeta.colaborador.apelido, { maxWords: 3 })
                          }

                          return (
                            <InputText
                              marginTop={true}
                              customVariant="outlined-small"
                              shrink={false}
                              key={'c_' + key}
                              disabled={ajaxing}
                              label={'Estorno da gorjeta ' + complementoLabel + ' (R$)'}
                              defaultValue={gorjeta.valorEstorno > 0 ? gorjeta.valorEstorno : 0}
                              inputComponent={InputMoneyFormat}
                              errorMessage={errorsMap('gorjeta[' + indice + '].valorEstorno')}
                              staticHelpMessage={
                                <span>
                                  É possível estornar o valor máximo de <b>{formatarValorMonetario(gorjeta.valorTotal)}</b> (valor original da gorjeta)
                                </span>
                              }
                              inputRef={gorjeta.valorInputRef}
                              inputProps={{
                                minValue: 0
                              }}
                            />
                          )
                        })}

                      <InputData
                        maxDate={maxDate}
                        disablePast
                        style={{ minWidth: 164 }}
                        marginTop={true}
                        customVariant="outlined-small"
                        shrink={false}
                        label="Data do Estorno"
                        disabled={ajaxing}
                        functionsMap={this.dataEstornoFunctionMap}
                        keyboard={true}
                        idname="xdataEstorno"
                        errorMessage={errorsMap('dataEstorno')}
                        defaultValue={dataEstornoDefaultAsMoment}
                        onChange={(data) => {
                          const dataAsMoment = data ? moment(data) : null
                          const { state } = this
                          state.data = dataAsMoment
                          this.inputCaixaFunctionsMap.setData(dataAsMoment)
                        }}
                      />

                      <InputCaixa
                        key={tipoPagamentoEstorno.id}
                        customVariant="outlined-small"
                        shrink={false}
                        disabled={ajaxing}
                        disponivel={caixaDisponivel}
                        marginTop={true}
                        data={dataEstornoDefaultAsMoment}
                        colaboradorPreferencial={getSessaoPainel().colaborador}
                        utilizarDefaultValue={utilizarDefaultValue}
                        caixaDefaultValue={caixa}
                        caixas={caixas}
                        errorsMap={errorsMap}
                        descricaoData="data do estorno"
                        labelUtilizacaoCaixa="Pago com dinheiro do caixa. Esta opção faz com que essa despesa entre no fluxo de caixa como uma retirada de dinheiro do caixa."
                        functionsMap={this.inputCaixaFunctionsMap}
                      />

                      <HiddenSubmitButton />

                      {pagamento && (
                        <div className={classes.containerFooterButtons}>
                          <Button className={classes.buttons} disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                            Cancelar
                          </Button>
                          <Button className={classes.buttons} disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                            {this.state.ajaxing ? 'Aguarde' : 'Solicitar estorno'}
                          </Button>
                        </div>
                      )}
                    </div>
                    <Button className={classes.saveButton} disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                      Estornar pagamento
                    </Button>
                  </>
                )}
              </form>
            </React.Fragment>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

EstornoPagamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EstornoPagamentoDialogPage)
