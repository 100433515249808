import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  ILIMITADA: { id: 1, descricao: 'Sem prazo de validade' },
  POR_DIAS: { id: 2, descricao: 'Definir prazo de validade em dias', periodo: 'Dias' },
  POR_SEMANAS: { id: 3, descricao: 'Definir prazo de validade em semanas', periodo: 'Semanas' },
  POR_MESES: { id: 4, descricao: 'Definir prazo de validade em meses', periodo: 'Meses' },
  POR_ANOS: { id: 5, descricao: 'Definir prazo de validade em anos', periodo: 'Anos' }
})

export default enumObject
