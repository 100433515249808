import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth from '@material-ui/core/withWidth'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import SettingsIcon from '@material-ui/icons/Settings'
import moment from 'moment'
import CategoriasDespesasDialogPage from 'pages/erp/painel/despesas/CategoriasDespesasDialogPage'
import DespesaDialogPage from 'pages/erp/painel/despesas/DespesaDialogPage'
import PagamentoDespesaDialog from 'pages/erp/painel/despesas/PagamentoDespesaDialog'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import FacilitadorRemocaoRepeticao from 'support/components/repeticao/FacilitadorRemocaoRepeticao'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import TipoDespesaEnum from 'support/domain/despesa/TipoDespesaEnum'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaDescricao: {
    flexGrow: 1.0
  },
  colunaCtegoria: {
    flexGrow: 1.0
  },
  colunaData: {
    flexGrow: 0.8,
    maxWidth: 110,
    minWidth: 110
  },
  colunaValor: {
    flexGrow: 0.8,
    maxWidth: 110,
    minWidth: 110
  },
  colunaPagamento: {
    flexGrow: 0.8,
    maxWidth: 100,
    minWidth: 100
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 }),
    marginTop: 2,
    marginBottom: 4
  }
})

class DespesasPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.loaderFunctionsMap = {}

    this.tipoPesquisa = { id: null }

    this.state = {
      lista: [],
      title: 'Despesas',
      ajaxing: false,
      nomeFiltro: this.colaboradorLocalPreferences.getPreference('DespesasPanelPageFiltro_NomeFiltro', ''),
      dataPesquisaInicial: this.colaboradorLocalPreferences.getMomentPreference('DespesasPanelPageFiltro_DataPesquisaInicial', moment().startOf('month')),
      dataPesquisaFinal: this.colaboradorLocalPreferences.getMomentPreference('DespesasPanelPageFiltro_DataPesquisaFinal', moment().endOf('month'))
    }

    this.inputNomeFiltro = React.createRef()
    this.facilitadorRemocaoRepeticaoFunctionMap = {}
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManutencaoDespesa', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  abrirDespesaDialog = () => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_DESPESA])) {
      openBackableDialog(DespesaDialogPage)
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  handlerEditar = (event, despesa) => {
    event.stopPropagation()
    openBackableDialog(DespesaDialogPage, {
      idDespesa: despesa.id
    })
  }

  abrirCategoriasDialog = () => {
    openBackableDialog(CategoriasDespesasDialogPage)
  }

  abrirPagamento = (despesa) => {
    if (verificarAutorizacao([PA.PODE_ALTERAR_DESPESA])) {
      openBackableDialog(PagamentoDespesaDialog, {
        despesa: despesa
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  getRequestParameters = () => {
    const dataPesquisaInicial = converterMomentParaDataInt(this.state.dataPesquisaInicial)
    const dataPesquisaFinal = converterMomentParaDataInt(this.state.dataPesquisaFinal)
    return {
      idTipo: TipoDespesaEnum.GERAL.id,
      nomeDespesa: this.inputNomeFiltro.current && this.inputNomeFiltro.current.value ? this.inputNomeFiltro.current.value : null,
      dataPesquisaInicial: dataPesquisaInicial,
      dataPesquisaFinal: dataPesquisaFinal,
      ignorarEstornoDinheiro: true
    }
  }

  getLoaderKey = () => {
    const { dataPesquisaInicial, dataPesquisaFinal } = this.state

    let key = 'loader'

    if (dataPesquisaInicial) {
      key += '-i' + dataPesquisaInicial
    }

    if (dataPesquisaFinal) {
      key += '-f' + dataPesquisaFinal
    }

    return key
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.dataDespesa = converterDataIntParaMoment(item.data).format('DD/MMM/YYYY')

    content.nome = (item.observacoes ? '* ' : '') + item.nome
    content.nomeCategoria = item.categoria.nome
    if (size === 'small') {
      content.valor = formatarValorMonetario(item.valor)
    } else {
      content.valor = formatarValorMonetario(item.valor, { prefix: false })
    }
    if (item.paga) {
      if (size === 'small') {
        content.pagamento = (
          <Typography variant="body2">
            <b>Pago</b>
          </Typography>
        )
      } else {
        content.pagamento = 'Pago'
      }
    } else {
      const buttonStyle = {}
      if (size === 'small') {
        buttonStyle.marginTop = -6
        buttonStyle.marginBottom = -8
        buttonStyle.marginLeft = -9
      }
      content.pagamento = (
        <Button
          style={buttonStyle}
          color="secondary"
          size="small"
          onClick={(event) => {
            this.handlerEditar(event, item)
          }}
        >
          PAGAR
        </Button>
      )
    }

    return content
  }

  openMenu = (item, event) => {
    event.stopPropagation()
    this.setState({
      menuAnchorPosition: {
        left: event.clientX,
        top: event.clientY
      },
      itemMenu: item
    })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  openMenuConfiguracoes = (event) => {
    this.setState({ criarItemMenuConfigAnchorEl: event.currentTarget })
  }

  handlerRemover = (event, despesa) => {
    event.stopPropagation()

    if (!verificarAutorizacao([PA.PODE_REMOVER_DESPESA])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    this.facilitadorRemocaoRepeticaoFunctionMap.remover({
      abrirMenu: despesa.repeticao && despesa.repeticao.id ? true : false,
      event: event,
      call: {
        endpoint: 'erp/despesas.remover',
        data: {
          id: despesa.id
        },
        onSuccess: () => {
          EventsManager.pub('ManutencaoDespesa')
        },
        errorKey: 'caixa'
      },
      dialog: {
        title: 'Remover Despesa',
        itemDescription: (
          <span>
            a despesa <b>{despesa.nome}</b>
          </span>
        )
      }
    })
  }

  downloadRelatorio = (event) => {
    const { dataPesquisaInicial, dataPesquisaFinal } = this.state

    let filename = 'Lista de Despesas'

    if (dataPesquisaInicial || dataPesquisaFinal) {
      filename += ' - ' + formatarPeriodoNomeArquivo(dataPesquisaInicial, dataPesquisaFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'despesas.gerarListaDespesas',
        params: this.getRequestParameters()
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { criarItemMenuConfigAnchorEl, menuAnchorPosition, dataPesquisaInicial, dataPesquisaFinal } = this.state

    let valorTotalPagas = 0
    let valorTotalPendentes = 0
    let quantidadePagas = 0
    let quantidadePendentes = 0
    for (let item of this.state.lista) {
      if (item.paga) {
        valorTotalPagas += item.valor
        quantidadePagas++
      } else {
        valorTotalPendentes += item.valor
        quantidadePendentes++
      }
    }

    const toolbarActions = {
      actions: []
    }

    toolbarActions.actions.push(
      {
        icon: <AddIcon />,
        handleAction: this.abrirDespesaDialog
      },
      {
        icon: <SettingsIcon />,
        handleAction: this.openMenuConfiguracoes
      },
      {
        icon: <RefreshIcon />,
        label: 'Atualizar',
        handleAction: () => {
          this.loaderFunctionsMap.load()
        }
      }
    )

    if (this.state.lista && this.state.lista.length && verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      toolbarActions.actions.push({
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    return (
      <PanelPage {...others} title={this.state.title} toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginLeft: -10, marginRight: 16 }}>
              <DateRangeNavigator
                expanded={false}
                defaultValue={{
                  tipoIntervalo: this.colaboradorLocalPreferences.getPreference('DespesasPanelPageFiltro_PesquisaTipoIntervalo', 'mes'),
                  dataInicial: dataPesquisaInicial,
                  dataFinal: dataPesquisaFinal
                }}
                onChange={(data) => {
                  this.colaboradorLocalPreferences.setPreference('DespesasPanelPageFiltro_PesquisaTipoIntervalo', data.tipoIntervalo.nome)
                  this.colaboradorLocalPreferences.setMomentPreference('DespesasPanelPageFiltro_DataPesquisaInicial', data.dataInicial)
                  this.colaboradorLocalPreferences.setMomentPreference('DespesasPanelPageFiltro_DataPesquisaFinal', data.dataFinal)
                  this.setState({
                    dataPesquisaInicial: data.dataInicial,
                    dataPesquisaFinal: data.dataFinal
                  })
                }}
              />
            </Grid>
            <Grid item xs style={{ marginLeft: 0, marginRight: 16, minWidth: 250 }}>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    nakedLeftPadding={false}
                    defaultValue={this.state.nomeFiltro}
                    placeholder="Procurar por descrição..."
                    onChange={(event) => {
                      this.colaboradorLocalPreferences.setPreference('DespesasPanelPageFiltro_NomeFiltro', event.target.value)
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>

        <br />

        <VirtualizedResponsiveTable
          showBackgroundDividers={true}
          itemsPerRequest={30}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/despesas.buscar"
          getRequestParametersFunction={this.getRequestParameters}
          loaderKey={this.getLoaderKey()}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              const state = { lista: data.items }
              this.setState(state)
            }
          }}
          onClickRow={(item, event) => {
            this.openMenu(item, event)
          }}
          largeRenderProps={{
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Categoria', className: classes.colunaCategoria, props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Descrição', className: classes.colunaDescricao, props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
              { label: 'Pagamento', className: classes.colunaPagamento, align: 'right' }
            ],
            itemRenderer: (despesa, index) => {
              const content = this.getRenderContent('large', despesa)
              return {
                itemData: [content.dataDespesa, content.nomeCategoria, content.nome, content.valor, content.pagamento]
              }
            }
          }}
          mediumRenderProps={{
            headerColumnHeight: 62,
            rowHeight: 70,
            columns: [
              { label: 'Data', className: classes.colunaData },
              { label: 'Descrição', className: classes.colunaDescricao, props: { xs: true }, horizontalPadding: 'small' },
              { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
              { label: 'Pagamento', className: classes.colunaPagamento, align: 'right' }
            ],
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('medium', item, index)
              return {
                itemData: [
                  content.dataDespesa,
                  <React.Fragment>
                    <Typography variant="body2" noWrap={true} style={{ fontSize: 13 }}>
                      {content.nomeCategoria}
                    </Typography>
                    <Typography variant="body2" noWrap={true}>
                      {content.nome}
                    </Typography>
                  </React.Fragment>,
                  content.valor,
                  content.pagamento
                ]
              }
            }
          }}
          smallRenderProps={{
            rowHeight: 112,
            itemRenderer: (item, index) => {
              const content = this.getRenderContent('small', item)

              return {
                itemData: (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs>
                        <Typography variant="body2">
                          <b>{content.dataDespesa}</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" style={{ fontWeight: 500 }}>
                          {content.valor}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" noWrap={true} style={{ fontSize: 13 }}>
                      {content.nomeCategoria}
                    </Typography>
                    <Typography variant="body1" className={classes.descricaoContainerSmall}>
                      {content.nome}
                    </Typography>
                    {content.pagamento}
                  </React.Fragment>
                )
              }
            }
          }}
          emptyListProps={{
            text: 'Nenhuma despesa foi encontrada',
            textButton: 'Cadastrar Despesa',
            creationFunction: this.abrirDespesaDialog
          }}
        />

        <Menu anchorReference="anchorPosition" anchorPosition={menuAnchorPosition} open={Boolean(menuAnchorPosition)} onClose={() => this.closeMenu('menuAnchorPosition')}>
          <MenuItem
            onClick={(event) => {
              this.handlerEditar(event, this.state.itemMenu)
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Abrir/Editar
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              this.handlerRemover(event, this.state.itemMenu)
              this.closeMenu('menuAnchorPosition')
            }}
          >
            Remover
          </MenuItem>
        </Menu>

        <Menu anchorEl={criarItemMenuConfigAnchorEl} open={Boolean(criarItemMenuConfigAnchorEl)} onClose={() => this.closeMenu('criarItemMenuConfigAnchorEl')}>
          <MenuItem
            onClick={() => {
              this.abrirCategoriasDialog()
              this.closeMenu('criarItemMenuConfigAnchorEl')
            }}
          >
            Categorias
          </MenuItem>
        </Menu>

        <FacilitadorRemocaoRepeticao functionsMap={this.facilitadorRemocaoRepeticaoFunctionMap} />

        {(quantidadePendentes > 1 || quantidadePagas > 1) && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            {quantidadePendentes > 0 && (
              <React.Fragment>
                <Typography variant="body2" style={{ paddingTop: 6 }}>
                  <b>Despesas Pendentes</b>
                  <br />
                  <small>
                    Quantidade: <b>{quantidadePendentes}</b>, Total: <b>{formatarValorMonetario(valorTotalPendentes)}</b>
                  </small>
                </Typography>
                {quantidadePagas > 0 && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
              </React.Fragment>
            )}

            {quantidadePagas > 0 && (
              <React.Fragment>
                <Typography variant="body2">
                  <b>Despesas Pagas</b>
                  <br />
                  <small>
                    Quantidade: <b>{quantidadePagas}</b>, Total: <b>{formatarValorMonetario(valorTotalPagas)}</b>
                  </small>
                </Typography>
              </React.Fragment>
            )}
          </FloatContent>
        )}
      </PanelPage>
    )
  }
}

DespesasPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(DespesasPanelPage))
