import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import InputServico from 'pages/erp/painel/servico/input/InputServico'
import PropTypes from 'prop-types'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputMoneyFormat } from 'support/util/Masks'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class AlterarServicoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Alterar Serviço',
      clientePacoteItem: this.props.clientePacoteItem,
      errorsMap: createErrorsMap(),
      ajaxing: false,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }

    this.servicoFunctionsMap = {}
    this.inputValorReferencia = React.createRef()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/clientes.alterarPacoteItem',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.setShouldCloseParent(true)
        this.props.handleCloseDialog()
        EventsManager.pub('ManutencaoPacote', { cliente: response.data })
        EventsManager.pub('RecarregarAtendimentos')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formCliente')
      }
    })
  }

  getDados = () => {
    const servicoObject = this.servicoFunctionsMap.getValue()
    const dados = {
      id: this.state.clientePacoteItem.id,
      valorReferencia: FormUtil.convertStringToNumber(this.inputValorReferencia.current.value)
    }

    if (servicoObject) {
      if (servicoObject.servico) {
        dados.servico = {
          id: servicoObject.servico.id
        }
      }
      if (servicoObject.variacao) {
        dados.variacaoServico = {
          id: servicoObject.variacao.id
        }
      }
    }

    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { clientePacoteItem, classes } = this.props
    const { errorsMap, servicoSelecionado } = this.state
    let nomeServico = null
    let quantidadeDisponivel = null
    let valorReferenciaDescricao = null
    let valorReferenciaDefault = null

    if (clientePacoteItem) {
      nomeServico = clientePacoteItem.servico.nome
      if (clientePacoteItem.variacaoServico) {
        nomeServico += ' - ' + clientePacoteItem.variacaoServico.nome
      }
      quantidadeDisponivel = clientePacoteItem.quantidadeDisponivel

      if (clientePacoteItem.valorReferencia) {
        valorReferenciaDescricao = formatarValorMonetario(clientePacoteItem.valorReferencia)
        valorReferenciaDefault = clientePacoteItem.valorReferencia
      } else {
        //valorReferenciaDescricao = "Não informado";
      }
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <form id="formCliente" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
          <Typography variant="body2" style={{ marginBottom: 1 }}>
            <b>Serviço:</b> {nomeServico}
          </Typography>
          {valorReferenciaDescricao && (
            <Typography variant="body2" style={{ marginTop: 2, marginBottom: 1 }}>
              <b>Valor Unitário:</b> {valorReferenciaDescricao}
            </Typography>
          )}
          <Typography variant="body2" style={{ marginTop: 2, marginBottom: 1 }}>
            <b>Créditos Disponíveis:</b> {quantidadeDisponivel}
          </Typography>

          <InputServico
            marginTop={true}
            shrink={false}
            customVariant="outlined-small"
            label="Escolha o novo serviço"
            selectionType="servico|variacao"
            disabled={this.state.ajaxing}
            functionsMap={this.servicoFunctionsMap}
            errorMessage={errorsMap('servico')}
            onChange={(value) => {
              if (value) {
                this.setState({ servicoSelecionado: value.servico })
              } else {
                this.setState({ servicoSelecionado: null })
              }
            }}
          />

          {servicoSelecionado && (
            <InputText
              marginTop={true}
              shrink={false}
              customVariant="outlined-small"
              idname="xvalorReferencia"
              disabled={this.state.ajaxing}
              label="Novo Valor Unitário (R$)"
              inputComponent={InputMoneyFormat}
              defaultValue={valorReferenciaDefault}
              inputRef={this.inputValorReferencia}
              errorMessage={errorsMap('valorReferencia')}
              endAdornment={
                <HelpOutlineIcon
                  style={{ opacity: 0.5, padding: 8, margin: '-8px 0px -8px -8px', cursor: 'pointer' }}
                  onClick={(event) => {
                    event.stopPropagation()
                    openBackableDialog(MessageDialog, {
                      text: (
                        <span className={classes.textPrimary}>
                          O Valor Unitário é utilizado para modificar o preço padrão unitário do serviço dentro de um pacote. Exemplo:
                          <br />
                          <br />
                          <span className={classes.textSecondary} style={{ fontSize: 14 }}>
                            - Serviço de manicure custa por padrão R$ 30,00;
                            <br />- O pacote com 10 serviços de manicure custa R$ 250,00;
                          </span>
                          <br />
                          <br />
                          Neste caso, no pacote com 10 serviços de manicure, cada serviço custa R$ 25,00. Então, para configurar esse pacote, preencha:
                          <br />
                          <br />
                          <span className={classes.textSecondary} style={{ fontSize: 14 }}>
                            <b>Preço do pacote:</b> R$ 250,00
                            <br />
                            <Grid container style={{ marginTop: 4 }}>
                              <Grid item>
                                <div style={{ height: '100%', width: 4, backgroundColor: '#aaa', marginRight: 6 }}></div>
                              </Grid>
                              <Grid item xs>
                                <b>Serviço:</b> Manicure
                                <br />
                                <b>Preço Unitário:</b> R$ 25,00 <b>Qtd:</b> 10
                              </Grid>
                            </Grid>
                          </span>
                        </span>
                      )
                    })
                  }}
                />
              }
              staticHelpMessage={
                <span>
                  Para o sistema considerar o valor padrão deste serviço, deixe o <b>Novo Valor Unitário</b> em branco
                </span>
              }
            />
          )}

          <HiddenSubmitButton />

          {
            <div className={classes.containerFooterButtons}>
              <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                Cancelar
              </Button>
              <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
              </Button>
            </div>
          }
        </form>
      </DialogPage>
    )
  }
}

AlterarServicoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AlterarServicoDialogPage)
