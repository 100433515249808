function getStyles({ defaultTheme, tema, constant }) {
  return {
    form: {
      containerFooterButtons: (props) => {
        if (!props) {
          props = {}
        }

        const style = {
          textAlign: 'right',
          marginTop: defaultTheme.spacing(3),
          '& button': {
            textTransform: 'none'
          },
          '& > button:not(:last-child)': {
            marginRight: 12
          }
        }

        if (!props.showMobile) {
          style[defaultTheme.breakpoints.down('xs')] = {
            display: 'none'
          }
        }

        return style
      }
    }
  }
}

const objectFunctions = {
  getStyles: getStyles
}

export default objectFunctions
