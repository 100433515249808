import { useEffect, useMemo, useRef } from 'react'

import { Box, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons'

export interface StylesTheme extends Theme {
  spinner: string
  h1: string
  h2: string
  p: string
  icon: string
  button: string
}

export type StatusType = 'loading' | 'error' | 'success'

type EstornoStatusPagamentoProps = {
  statusType: StatusType
  errorMessage: string
  handleCloseDialog?: () => void
}

const useStyles = makeStyles<StylesTheme>((theme) =>
  createStyles({
    container: {
      position: 'relative',
      height: '450px',
      maxWidth: '845px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    closeButton: {
      position: 'absolute',
      top: '0px',
      right: '10px'
    },
    spinner: {
      marginBottom: 40,
      borderWidth: 6,
      width: '60px',
      height: '60px'
    },
    h1: {
      fontSize: '20px',
      fontWeight: 500,
      textAlign: 'center'
    },
    h2: {
      fontSize: '18px',
      fontWeight: 400,
      textAlign: 'center',
      '& > b': {
        color: theme.palette.secondary.main
      }
    },
    p: {
      fontSize: '16px',
      color: '#666',
      fontWeight: 400,
      textAlign: 'center'
    },
    icon: {
      width: '60px',
      height: '60px',
      marginBottom: '40px'
    },
    button: {
      marginTop: 40,
      fontWeight: 500,
      textTransform: 'capitalize',
      padding: '10px 24px'
    }
  })
)

export const EstornoStatusPagamento = ({ statusType, errorMessage, handleCloseDialog }: EstornoStatusPagamentoProps) => {
  const classes = useStyles()

  const TIMEOUT = 5000
  const timeout = useRef<NodeJS.Timeout>()

  const isError = statusType === 'error'
  const isLoading = statusType === 'loading'
  const isSuccess = statusType === 'success'

  useEffect(() => {
    if (isSuccess) {
      timeout.current = setTimeout(() => {
        handleCloseDialog?.()
      }, TIMEOUT)

      return () => clearTimeout(timeout.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const title = useMemo(() => {
    const condition = {
      loading: 'Estamos solicitando o estorno do pagamento',
      error: 'Estorno não realizado!',
      success: 'Estorno realizado com sucesso!'
    }
    return condition[statusType]
  }, [statusType])

  const subTitle = useMemo(() => {
    const condition = {
      loading: 'Aguarde um pouco',
      error: errorMessage,
      success: 'O estorno foi aprovado e o crédito será efetuado de acordo com o banco do cliente.'
    }
    return condition[statusType]
  }, [statusType, errorMessage])

  return (
    <Box className={classes.container}>
      {isError && <CancelOutlined color="secondary" className={classes.icon} fontSize="large" />}
      {isLoading && <CircularProgress color="secondary" className={classes.spinner} size="xl" />}
      {isSuccess && <CheckCircleOutline color="secondary" className={classes.icon} fontSize="large" />}
      <Typography className={classes.h1}>{title}</Typography>
      <Typography className={classes.p}>{subTitle}</Typography>
    </Box>
  )
}
