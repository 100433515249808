import React, { Component } from 'react'

import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import FileUploaderDialog from 'support/components/fileuploader/FileUploaderDialog'
import PhotoPicker from 'support/components/picker/PhotoPicker'
import PA from 'support/domain/colaborador/PermissoesAcesso'

class NegocioPhotoPicker extends Component {
  handleAlterarFoto = (event) => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { negocio } = this.props

    openBackableDialog(FileUploaderDialog, {
      title: 'Foto do Perfil',
      cropsProps: {
        aspect: 1,
        exportSize: {
          width: 320,
          height: 320
        },
        saveCroppedImageFunction: (data, onStart, onSuccess, onError) => {
          onStart()
          postAPI({
            url: 'erp/negocios.alterarFotoPerfil',
            data: {
              negocio: {
                id: negocio.id
              },
              imageDataAsBase64: data
            },
            requerAutorizacao: true,
            timeout: 0,
            onSuccess: (response) => {
              onSuccess()
              EventsManager.pub('ManutencaoNegocio')
              EventsManager.pub('SnackBarSuccess', { open: true, message: 'Foto alterada com sucesso.' })
            },
            onError: (response) => {
              onError()
            }
          })
        }
      }
    })
  }

  handlerRemoverFoto = () => {
    if (!verificarAutorizacao([PA.PODE_ACESSAR_CONFIG_GERAIS])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const { negocio } = this.props

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Foto',
      text: (
        <span>
          Você tem certeza que deseja remover a foto do negocio <b>{negocio.nome}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/negocios.alterarFotoPerfil',
        contextoUsuario: 'erp',
        data: {
          negocio: {
            id: negocio.id
          }
        },
        onSuccess: () => {
          EventsManager.pub('ManutencaoNegocio')
          EventsManager.pub('SnackBarSuccess', { open: true, message: 'Foto removida com sucesso.' })
        }
      }
    })
  }

  render() {
    const { negocio, ...others } = this.props

    return (
      <PhotoPicker
        {...others}
        editFunction={this.handleAlterarFoto}
        removeFunction={this.handlerRemoverFoto}
        hasWritePermission={verificarAutorizacao([PA.PODE_PERSISTIR_COLABORADOR])}
      />
    )
  }
}

export default NegocioPhotoPicker
