import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  VERIFICACAO_CONTA: { id: 1, descricao: 'Verificação de Conta' },
  REDEFINICAO_SENHA: { id: 2, descricao: 'Redefinição de Senha' },
  LIBERACAO_ACESSO_COLABORADOR: { id: 3, descricao: 'Liberação de Acesso para Colaborador' },
  NOTIFICACAO_ADMIN: { id: 4, descricao: 'Notificação Admin' },
  NOTA_CLIENTE: { id: 5, descricao: 'Envio de Fatura para o Cliente' },
  AO_CRIACAO_PARA_COLABORADOR: { id: 6, descricao: 'Agendamento online - Colaborador' },
  AO_CANCELAMENTO_PARA_COLABORADOR: { id: 7, descricao: 'Agendamento online - Colaborador' },
  AO_CRIACAO_PARA_CLIENTE: { id: 8, descricao: 'Agendamento online - Cliente' },
  AO_CANCELAMENTO_PARA_CLIENTE: { id: 9, descricao: 'Agendamento online - Cliente' },
  ADMIN: { id: 10, descricao: 'Admin' },
  NF_ERRO_EMISSAO_CANCELAMENTO: { id: 11, descricao: 'NF - Erro de emissão ou cancelamento' }
})

export default enumObject
