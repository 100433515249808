import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { isWidthDown } from '@material-ui/core/withWidth'
import withWidth from '@material-ui/core/withWidth'
import AddIcon from '@material-ui/icons/Add'
import ContactsIcon from '@material-ui/icons/Contacts'
import GetAppIcon from '@material-ui/icons/GetApp'
import GroupIcon from '@material-ui/icons/Group'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import ClienteDadosBasicosDialogPage from 'pages/erp/painel/cliente/ClienteDadosBasicosDialogPage'
import ClienteDialogPage from 'pages/erp/painel/cliente/ClienteDialogPage'
import EnviarMensagemManualAniversarioDialog from 'pages/erp/painel/cliente/EnviarMensagemManualAniversarioDialog'
import ImportacaoContatosDialogPage from 'pages/erp/painel/cliente/ImportacaoContatosDialogPage'
import ClientePhotoPicker from 'pages/erp/painel/cliente/picker/ClientePhotoPicker'
import UnificacaoClientesDialogPage from 'pages/erp/painel/cliente/UnificacaoClientesDialogPage'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import FloatContent from 'support/components/page/FloatContent'
import PanelPage from 'support/components/panel/PanelPage'
import SelectedItemsActionSnackBar from 'support/components/snackbar/SelectedItemsActionSnackBar'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import ImageUtil from 'support/util/ImageUtil'

const styles = (theme) => ({
  colunaCheckbox: {
    maxWidth: 58,
    minWidth: 58
  },
  colunaFoto: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaMensagem: {
    maxWidth: 160,
    minWidth: 160
  },
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  },
  nomeCliente: {
    ...theme.text.limitedLines({ maxLines: 3 })
  }
})

export const VisaoEnum = createEnum({
  CADASTRO: { id: 1, descricao: 'Todos os Clientes' },
  ANIVERSARIANTES: { id: 2, descricao: 'Aniversariantes' }
})

class ClientesPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      visao: VisaoEnum.CADASTRO,
      lista: []
    }
    this.inputNomeFiltro = React.createRef()
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoCliente', (props) => {
      this.loaderFunctionsMap.load()
    })

    this.eventsManager.sub('ClienteConfirmacaoEnvioManualAniversario', (props) => {
      this.loaderFunctionsMap.load()
    })
    this.eventsManager.sub('AbrirLoadingCarregandoContatos', (props) => {
      EventsManager.pub('MensagemSnackBar', 'Erro inesperado (cod:666). Tente novamente.')
    })
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  unificarClientesDialog = () => {
    if (!verificarAutorizacao([PA.PODE_ALTERAR_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(UnificacaoClientesDialogPage)
  }

  handlerOpenCreateDialog = () => {
    if (!verificarAutorizacao([PA.PODE_CADASTRAR_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(ClienteDadosBasicosDialogPage)
  }

  handlerOpenImportacaoContatosDialog = () => {
    if (!verificarAutorizacao([PA.PODE_CADASTRAR_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(ImportacaoContatosDialogPage)
  }

  handlerEditar = (cliente) => {
    openBackableDialog(ClienteDialogPage, {
      idCliente: cliente.id
    })
  }

  handlerEnviarMensagemAniversario = (cliente) => {
    if (!verificarAutorizacao([PA.PODE_VISUALIZAR_DADOS_CONTATO_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog, {
        message: (
          <span>
            Você precisa ter a permissão de <u>visualizar dados de contato dos clientes</u> para pode enviar a mensagem de aniversário.
          </span>
        )
      })
      EventsManager.pub('AtualizarSessao')
      return
    }

    openBackableDialog(EnviarMensagemManualAniversarioDialog, {
      cliente: cliente
    })
  }

  handlerRemover = () => {
    if (!verificarAutorizacao([PA.PODE_REMOVER_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover cliente',
      text: (
        <span>
          Você tem certeza que deseja remover o cliente <b>{this.state.itemMenu.apelido}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/clientes.remover',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManipulacaoCliente')
        }
      }
    })
  }

  selecionarItem = (event, index) => {
    const lista = this.state.lista
    const item = lista[index]
    item._checked = event.target.checked
    item._update = true
    for (let i = 0; i < lista.length; i++) {
      if (!lista[i]._checked) {
        break
      }
    }
    this.setState({ lista: lista })
  }

  desabilitarRemocaoLote = () => {
    const { lista } = this.state
    for (let i = 0; i < lista.length; i++) {
      const item = lista[i]
      item._checked = false
      item._update = true
    }
    this.setState({ lista: lista })
  }

  getQuantidadeItensSelecionadosRemocao = () => {
    const { lista } = this.state
    let itensSelecionados = 0
    for (let i = 0; i < lista.length; i++) {
      const item = lista[i]
      if (item._checked) {
        itensSelecionados++
      }
    }
    return itensSelecionados
  }

  removerItensSelecionados = () => {
    if (!verificarAutorizacao([PA.PODE_REMOVER_CLIENTE])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const itensSelecionados = this.getQuantidadeItensSelecionadosRemocao()
    let textSelecionados = 'o cliente selecionado'
    if (itensSelecionados > 1) {
      textSelecionados = (
        <span>
          os <b>{itensSelecionados} clientes</b> selecionados
        </span>
      )
    }

    const idsCliente = []

    for (let item of this.state.lista) {
      if (item._checked) {
        idsCliente.push(item.id)
      }
    }

    const data = {}
    if (idsCliente.length > 0) {
      data.idsCliente = idsCliente
    }

    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover clientes',
      text: <span>Você tem certeza que deseja remover {textSelecionados}? Essa operação não poderá ser desfeita</span>,
      call: {
        method: 'post',
        url: 'erp/clientes.removerClientes',
        contextoUsuario: 'erp',
        data: data,
        onSuccess: (response) => {
          EventsManager.pub('ManipulacaoCliente')
        },
        errorKey: 'cliente'
      }
    })
  }

  getRenderContent = (size, cliente, index) => {
    const content = {}

    const checkboxStyle = {}
    if (size === 'small') {
      checkboxStyle.marginLeft = -2
    }

    content.checkbox = (
      <Checkbox
        style={checkboxStyle}
        disabled={!verificarAutorizacao([PA.PODE_REMOVER_CLIENTE])}
        checked={cliente._checked === undefined ? false : cliente._checked}
        onClick={(event) => {
          event.stopPropagation()
          this.selecionarItem(event, index)
        }}
      />
    )

    const variacoesFoto = ImageUtil.extrairVariacoesImagem(cliente.foto)

    content.foto = <ClientePhotoPicker cliente={cliente} photo1x={variacoesFoto.get(2)} width={38} preCheckWritePermission={true} />
    content.nomeCliente = cliente.apelido
    content.aniversario = cliente.diaNascimento + '/' + cliente.mesNascimento

    content.botaoOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -4 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ clienteMenuAnchorEl: event.currentTarget, itemMenu: cliente })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )
    return content
  }

  getRenderContentAniversariante = (size, cliente) => {
    const content = {}

    const variacoesFoto = ImageUtil.extrairVariacoesImagem(cliente.foto)

    content.foto = <ClientePhotoPicker cliente={cliente} photo1x={variacoesFoto.get(2)} width={38} preCheckWritePermission={true} />

    const moment30DiasFrente = moment().add(30, 'days')

    const mesDiaAtual = parseInt(moment().format('MMDD'), 10)
    const mesDia30DiasFrente = parseInt(moment30DiasFrente.format('MMDD'), 10)
    const mesDia3DiasAtras = parseInt(moment().subtract(3, 'days').format('MMDD'), 10)
    const dataInt30DiasAtras = parseInt(moment().subtract(30, 'days').format('YYYYMMDD'), 10)
    const mesDiaNascimento = parseInt(cliente.mesNascimento.toString().padStart(2, '0') + cliente.diaNascimento.toString().padStart(2, '0'), 10)

    const mesDia30DiasFrenteReiniciado = mesDia30DiasFrente < mesDiaAtual ? true : false
    const mesDia3DiasAtrasRetrocedido = mesDia3DiasAtras > mesDiaAtual ? true : false

    let isMesDiaNascimentoPassado = false
    if (mesDia3DiasAtrasRetrocedido) {
      if (mesDiaNascimento >= mesDia3DiasAtras || mesDiaNascimento < mesDiaAtual) {
        isMesDiaNascimentoPassado = true
      }
    } else {
      if (mesDiaNascimento >= mesDia3DiasAtras && mesDiaNascimento < mesDiaAtual) {
        isMesDiaNascimentoPassado = true
      }
    }

    let isMesDiaNascimentoFuturoMaior30Dias = false
    if (mesDia30DiasFrenteReiniciado) {
      if (mesDiaNascimento > mesDia30DiasFrente && mesDiaNascimento < mesDiaAtual) {
        isMesDiaNascimentoFuturoMaior30Dias = true
      }
    } else {
      if (mesDiaNascimento >= mesDia30DiasFrente || mesDiaNascimento < mesDiaAtual) {
        isMesDiaNascimentoFuturoMaior30Dias = true
      }
    }

    let isDataEnvioMensagemAniversarioHojOuPassadoMaximo30Dias = false
    let isDataEnvioMensagemAniversarioPassadoMinimo30Dias = false
    if (cliente.dataEnvioMensagemAniversario) {
      if (cliente.dataEnvioMensagemAniversario >= dataInt30DiasAtras) {
        isDataEnvioMensagemAniversarioHojOuPassadoMaximo30Dias = true
      }
      if (cliente.dataEnvioMensagemAniversario < dataInt30DiasAtras) {
        isDataEnvioMensagemAniversarioPassadoMinimo30Dias = true
      }
    }

    const containerNomeStyle = {}
    const containerBotaoEnviarProps = {
      color: 'secondary',
      style: { marginLeft: -5, marginTop: -4, marginBottom: -4 }
    }
    const containerUltimoEnvioStyle = { fontSize: 13 }

    if (isMesDiaNascimentoPassado || isMesDiaNascimentoFuturoMaior30Dias) {
      containerNomeStyle.color = 'rgba(0,0,0,0.35)'
    }

    if (isMesDiaNascimentoFuturoMaior30Dias && !isMesDiaNascimentoPassado) {
      containerBotaoEnviarProps.style.opacity = 0.55
      containerBotaoEnviarProps.color = 'default'
    } else if (isDataEnvioMensagemAniversarioHojOuPassadoMaximo30Dias) {
      containerBotaoEnviarProps.style.opacity = 0.55
      containerBotaoEnviarProps.color = 'default'
    }

    if (isDataEnvioMensagemAniversarioPassadoMinimo30Dias) {
      containerUltimoEnvioStyle.color = 'rgba(0,0,0,0.4)'
    }

    content.cliente = (
      <span style={containerNomeStyle}>
        <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block' }}>
          <b>{cliente.apelido}</b>
        </span>
        Aniversário: {cliente.diaNascimento.toString().padStart(2, '0')}/{cliente.mesNascimento.toString().padStart(2, '0')}
      </span>
    )

    content.mensagem = (
      <React.Fragment>
        <Button
          {...containerBotaoEnviarProps}
          size="small"
          onClick={(event) => {
            event.stopPropagation()
            this.handlerEnviarMensagemAniversario(cliente)
          }}
        >
          ENVIAR MENSAGEM
        </Button>
        {cliente.dataEnvioMensagemAniversario && (
          <span style={containerUltimoEnvioStyle}>
            <br />
            Último envio: {converterDataIntParaMoment(cliente.dataEnvioMensagemAniversario).format('DD/MM/YY')}
          </span>
        )}
      </React.Fragment>
    )

    content.botaoOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -16 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ clienteMenuAnchorEl: event.currentTarget, itemMenu: cliente })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )
    return content
  }

  downloadRelatorio = (event) => {
    if (
      verificarAutorizacao([PA.PODE_VISUALIZAR_DADOS_PESSOAIS_CLIENTE]) &&
      verificarAutorizacao([PA.PODE_VISUALIZAR_DADOS_CONTATO_CLIENTE]) &&
      verificarAutorizacao([PA.PODE_VISUALIZAR_DADOS_ENDERECO_CLIENTE])
    ) {
      this.dataExporterFunctionsMap.export({
        targetAnchorEl: event.currentTarget,
        filename: 'Lista de Clientes - ' + moment().format('YYYYDDMM'),
        accessTokenContext: 'colaborador',
        downloadCall: {
          url: 'clientes.gerarListaClientes',
          params: this.getRequestParameters()
        }
      })
    } else {
      openBackableDialog(AccessDeniedDialog)
    }
  }

  getRequestParameters = () => {
    return {
      nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null
    }
  }

  openMenuConfiguracoes = (event) => {
    this.setState({ criarItemMenuConfigAnchorEl: event.currentTarget })
  }

  render() {
    const { classes, ...others } = this.props
    const { visao } = this.state

    let onClickRow = (item) => {
      this.handlerEditar(item)
    }

    const quantidadeItensSelecionadosRemocao = this.getQuantidadeItensSelecionadosRemocao()

    let largeRenderProps = undefined
    let smallRenderProps = undefined

    let endPoint = null
    let clienteNaoEncontrado = null

    if (visao.id === VisaoEnum.CADASTRO.id) {
      endPoint = 'erp/clientes.buscarLista'
      clienteNaoEncontrado = 'Nenhum cliente foi encontrado'

      largeRenderProps = {
        columns: [
          { className: classes.colunaCheckbox, horizontalPadding: 'small' },
          { className: classes.colunaFoto, horizontalPadding: 'small' },
          { label: 'Nome', props: { xs: true } },
          { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('large', item, index)
          return {
            selected: item._checked,
            itemData: [content.checkbox, content.foto, content.nomeCliente, content.botaoOpcoes]
          }
        }
      }
      smallRenderProps = {
        rowHeight: 80,
        itemRenderer: (item, index) => {
          const content = this.getRenderContent('small', item, index)
          return {
            selected: item._checked,
            itemData: (
              <React.Fragment>
                <Grid container alignItems="center" style={{ minWidth: 0 }}>
                  <Grid item style={{ marginRight: 6 }}>
                    {content.checkbox}
                  </Grid>
                  <Grid item style={{ marginRight: 16 }}>
                    {content.foto}
                  </Grid>
                  <Grid item xs style={{ minWidth: 0 }}>
                    <Typography variant="body2" className={classes.nomeCliente}>
                      {content.nomeCliente}
                    </Typography>
                  </Grid>
                  <Grid item>{content.botaoOpcoes}</Grid>
                </Grid>
              </React.Fragment>
            )
          }
        }
      }
    } else if (visao.id === VisaoEnum.ANIVERSARIANTES.id) {
      endPoint = 'erp/clientes.buscarAniversariantes'
      clienteNaoEncontrado = 'Nenhum aniversariante foi encontrado'

      largeRenderProps = {
        rowHeight: 70,
        columns: [
          { className: classes.colunaFoto },
          { label: 'Cliente', props: { xs: true } },
          { label: 'Mensagem', className: classes.colunaMensagem },
          { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
        ],
        itemRenderer: (item, index) => {
          const content = this.getRenderContentAniversariante('large', item)
          return {
            itemData: [content.foto, content.cliente, content.mensagem, content.botaoOpcoes]
          }
        }
      }
      smallRenderProps = {
        rowHeight: 110,
        itemRenderer: (item, index) => {
          const content = this.getRenderContentAniversariante('small', item)
          return {
            itemData: (
              <React.Fragment>
                <Grid container alignItems="center" style={{ minWidth: 0 }} wrap="nowrap">
                  <Grid item style={{ marginRight: 16 }}>
                    {content.foto}
                  </Grid>
                  <Grid item xs style={{ minWidth: 0 }}>
                    <Typography variant="body2">{content.cliente}</Typography>
                    <Typography variant="body2">{content.mensagem}</Typography>
                  </Grid>
                  <Grid item>{content.botaoOpcoes}</Grid>
                </Grid>
              </React.Fragment>
            )
          }
        }
      }
    }

    const toolbarActions = {
      desktopItemsShow: 3,
      mobileItemsShow: 3,
      actions: []
    }

    toolbarActions.actions.push({
      icon: <AddIcon />,
      handleAction: this.handlerOpenCreateDialog
    })

    if (verificarAutorizacao([PA.PODE_ACESSAR_RELATORIOS])) {
      toolbarActions.actions.push({
        disabled: this.state.lista && this.state.lista.length && this.state.visao.id === VisaoEnum.CADASTRO.id ? false : true,
        icon: <GetAppIcon />,
        label: 'Exportar',
        handleAction: (event) => this.downloadRelatorio(event)
      })
    }

    toolbarActions.actions.push({
      icon: <GroupIcon />,
      label: 'Unificar Clientes',
      handleAction: this.unificarClientesDialog
    })

    if (DeviceIdentifier.isNativeApp()) {
      toolbarActions.actions.push({
        icon: <ContactsIcon />,
        label: 'Importar Contatos',
        handleAction: this.handlerOpenImportacaoContatosDialog
      })
    }

    let placeHolder
    if (isWidthDown('xs', this.props.width)) {
      placeHolder = 'Procurar...'
    } else {
      placeHolder = 'Procurar por nome, telefone ou CPF...'
    }

    return (
      <PanelPage {...others} title="Clientes" toolbarActions={toolbarActions}>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

        <FloatContent type="filter">
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 16 }}>
              <InputSelect
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.visao.id}
                fullWidth={false}
                onChange={(event) => {
                  const visao = VisaoEnum.getById(parseInt(event.target.value, 10))
                  this.setState({ visao: visao })
                }}
              >
                {VisaoEnum.values().map((visao) => (
                  <MenuItem key={visao.id} value={visao.id}>
                    {visao.descricao}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item xs style={{ marginRight: 16, minWidth: 200 }}>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginRight: 16 }}>
                  <SearchIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <InputText
                    customVariant="naked"
                    nakedLeftPadding={false}
                    placeholder={placeHolder}
                    onChange={(event) => {
                      this.loaderFunctionsMap.load()
                    }}
                    inputRef={this.inputNomeFiltro}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FloatContent>

        <br />

        <VirtualizedResponsiveTable
          key={visao.id}
          showBackgroundDividers={true}
          itemsPerRequest={20}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint={endPoint}
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          onClickRow={onClickRow}
          largeRenderProps={largeRenderProps}
          smallRenderProps={smallRenderProps}
          emptyListProps={{
            text: clienteNaoEncontrado,
            textButton: 'Cadastrar cliente',
            creationFunction: this.handlerOpenCreateDialog
          }}
        />

        <Menu anchorEl={this.state.clienteMenuAnchorEl} open={Boolean(this.state.clienteMenuAnchorEl)} onClose={() => this.closeMenu('clienteMenuAnchorEl')}>
          <MenuItem
            onClick={() => {
              this.handlerEditar(this.state.itemMenu)
              this.closeMenu('clienteMenuAnchorEl')
            }}
          >
            Abrir/Editar
          </MenuItem>
          <MenuItem
            disabled={!verificarAutorizacao([PA.PODE_REMOVER_CLIENTE])}
            onClick={() => {
              this.handlerRemover()
              this.closeMenu('clienteMenuAnchorEl')
            }}
          >
            Remover
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={this.state.criarItemMenuConfigAnchorEl}
          open={Boolean(this.state.criarItemMenuConfigAnchorEl)}
          onClose={() => this.closeMenu('criarItemMenuConfigAnchorEl')}
        >
          <MenuItem
            onClick={() => {
              this.closeMenu('criarItemMenuConfigAnchorEl')
              this.unificarClientesDialog()
            }}
          >
            Unificar clientes
          </MenuItem>
        </Menu>

        <SelectedItemsActionSnackBar
          show={quantidadeItensSelecionadosRemocao > 0}
          label={'Selecionados: ' + quantidadeItensSelecionadosRemocao}
          action={{
            label: 'Remover',
            handleFunction: this.removerItensSelecionados,
            cancelFunction: this.desabilitarRemocaoLote
          }}
        />

        {this.state.lista.length > 1 && (
          <FloatContent container="paper" context="DialogPage" type="tableSummary">
            <Typography variant="body2">
              Clientes cadastrados: <b>{this.state.lista.length}</b>
              <br />
            </Typography>
          </FloatContent>
        )}
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

ClientesPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withWidth()(ClientesPanelPage))
