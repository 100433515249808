import { Box, Button, Typography } from '@material-ui/core'
import moment from 'moment'
import { postAPI } from 'support/components/api/PainelAdminApiClient'
import EventsManager from 'support/components/eventsmanager/EventsManager'

import { PocketProps } from './PocketDialog'
import { useStyles } from './PocketDialot.styles'

const TIME = 3000

export const ConfirmBusiness = ({ onCloseDialog, dataBusiness, onStatusChange }: PocketProps) => {
  const classes = useStyles()

  const handleClick = () => {
    onStatusChange('loading')
    postAPI({
      url: `admin/pocket/migracao/${dataBusiness?.negocioId}`,
      contextoUsuario: 'admin',
      requerAutorizacao: true,
      onSuccess: () => {
        onStatusChange('success')
        setTimeout(() => {
          onCloseDialog()
        }, TIME)
      },
      onError: (response: { data: { errors: object } }) => {
        Object.entries(response.data.errors).map(([, value]) => {
          EventsManager.pub('MensagemSnackBar', value)
        })
        onCloseDialog()
      }
    })
  }
  return (
    <>
      <Typography variant="h2" className={classes.title} style={{ marginBottom: 8 }}>
        Confirme os dados do negócio
      </Typography>
      <Typography className={classes.subtitle}>Lembre-se: Ao reprocessar, todos os dados inseridos no Salão99 após a data de migração serão perdidos.</Typography>
      <Box className={classes.wrapperBox}>
        <Box className={classes.box}>
          <Typography>{`Negócio: ${dataBusiness?.nomeNegocio}`}</Typography>
        </Box>
        <Box className={classes.box}>
          <Typography>{`Migração em: ${moment(dataBusiness?.dataProcessamento).format('DD/MM/YYYY')}`}</Typography>
        </Box>
      </Box>
      <Box className={classes.wrapperButtons}>
        <Button onClick={onCloseDialog}>Cancelar</Button>
        <Button onClick={handleClick} variant="contained" color="secondary">
          Reprocessar
        </Button>
      </Box>
    </>
  )
}
