import React, { Component } from 'react'

import SliderMUI from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8
  },
  thumb: {
    height: '20px !important',
    width: '20px !important',
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: '-6px !important',
    marginLeft: '-10px !important',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})

class Slider extends Component {
  render() {
    const { classes, ...others } = this.props

    return <SliderMUI classes={classes} {...others} />
  }
}

Slider.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Slider)
