import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import { ROUTE_ENTERPRISE_PANEL_REGEX } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/ApiClient'
import UltimasAtualizacoesContent from 'support/components/atualizacao/UltimasAtualizacoesContent'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import BaseDialog from 'support/components/dialog/BaseDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'

const styles = (theme) => ({})

class AtualizarSistemaDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dadosCarregados: false,
      mostrarBotaoNovidades: false,
      atualizando: false
    }
  }

  carregarDados = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'infrastructure/releasenotes.hasNewItems',
      onSuccess: (response) => {
        this.setState({
          dadosCarregados: true,
          mostrarBotaoNovidades: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { dadosCarregados, mostrarBotaoNovidades, atualizando } = this.state

    let actions = null

    let isPainelErp = false
    if (document.location.href.match(new RegExp('.*' + ROUTE_ENTERPRISE_PANEL_REGEX + '.*', 'gi'))) {
      isPainelErp = true
    }

    if (atualizando === false && dadosCarregados) {
      actions = (
        <React.Fragment>
          {mostrarBotaoNovidades && isPainelErp && (
            <Button
              onClick={() => {
                openBackableDialog(MessageDialog, {
                  maxWidth: 560,
                  title: 'Últimas Atualizações',
                  text: <UltimasAtualizacoesContent />
                })
              }}
              color="secondary"
            >
              Ver Novidades
            </Button>
          )}
          <Button
            onClick={() => {
              this.setState({ atualizando: true })
              window.setTimeout(() => {
                document.location.reload(true)
              }, 1000)
            }}
            color="secondary"
          >
            Recarregar
          </Button>
        </React.Fragment>
      )
    }

    return (
      <BaseDialog
        {...others}
        maxWidth={368}
        disabledOnClose={true}
        title="Recarregue o sistema"
        actions={actions}
        onPreClose={() => {
          document.location.reload(true)
        }}
      >
        {atualizando === false && (
          <ContentWithPreload loadFunction={this.carregarDados} key="main">
            {() => <DialogContentText>O sistema foi atualizado automaticamente e será necessário recarregá-lo.</DialogContentText>}
          </ContentWithPreload>
        )}

        {atualizando === true && <ContentWithPreload loadFunction={() => {}} key="loading"></ContentWithPreload>}
      </BaseDialog>
    )
  }
}

AtualizarSistemaDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AtualizarSistemaDialog)
