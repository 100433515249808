import { useEffect } from 'react'

import { Grid, IconButton, MenuItem } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'

import TipoTelefoneEnum from '../../../domain/telefone/TipoTelefoneEnum'
import { TelefoneMask } from '../../../util/Masks'
import InputSelect from '../InputSelect'
import InputText from '../InputText'
import { useStyles } from './InputTelefoneList.styles'
import { TelefoneT, TipoTelefoneT } from './types'
import { useInputListTelefoneList } from './useInputTelefoneList'

type InputTelefoneListProps = {
  disabled?: boolean
  defaultPhones?: TelefoneT[]
  defaultPhoneType?: TipoTelefoneT
  maxNumberOfPhones?: number
  componentFunctions: Record<string, unknown>
  idName?: string
  inputVariant?: 'filled' | 'naked' | 'outlined' | 'outlined-small'
  errorsMap: (key: string) => string | null
}

export const InputTelefoneList = ({
  defaultPhones,
  defaultPhoneType,
  componentFunctions,
  maxNumberOfPhones = 5,
  idName = 'xtelefone',
  disabled = false,
  inputVariant = 'outlined-small',
  errorsMap,
  ...props
}: InputTelefoneListProps) => {
  const { telefones, handleMask, handleMaskDefault, handleAdicionarTelefone, handleRemoverTelefone, handleChangeTipoTelefone } = useInputListTelefoneList(
    defaultPhones,
    defaultPhoneType,
    maxNumberOfPhones
  )
  const styles = useStyles()

  useEffect(() => {
    componentFunctions.getValues = () => {
      return telefones
        .map((telefone) => ({
          id: telefone.id,
          tipo: telefone.tipo,
          numero: telefone.inputRef?.current?.value.trim()
        }))
        .filter((telefone) => telefone.numero && telefone.numero.trim() !== '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telefones])

  return (
    <div>
      {telefones.map((telefone, index) => {
        const { key, numero, inputRef, maskId, tipo } = telefone
        const id = 'xtelefone' + index
        const showRemovePhone = telefones.length > 1
        const showAddPhone = index === telefones.length - 1 && telefones.length < maxNumberOfPhones

        return (
          <Grid container key={key}>
            <Grid item>
              <InputText
                {...props}
                customVariant={inputVariant}
                marginTop={true}
                shrink={true}
                style={{ marginRight: 10 }}
                className={styles.inputContainer}
                fullWidth={false}
                errorMessage={errorsMap('telefones[' + index + '].numero')}
                label={`Telefone ${index + 1}`}
                idname={idName + index}
                id={id ? id + index : undefined}
                defaultValue={numero}
                inputProps={{
                  ref: inputRef,
                  maskId: maskId,
                  onBlur: (event: { target: { value: string } }) => handleMask(key, maskId, event.target.value),
                  onFocus: () => handleMaskDefault(key),
                  style: { textAlign: 'right' },
                  'data-testid': 'input' + index
                }}
                inputComponent={TelefoneMask}
                placeholder="(00) 0000-0000"
                startAdornment={
                  <InputSelect
                    disabled={defaultPhoneType ? true : false || disabled}
                    customVariant="naked"
                    nakedLeftPadding={false}
                    style={{ width: 'auto', minWidth: 'auto', marginRight: -8 }}
                    className={styles.containerTipo}
                    styles={{
                      inputSelect: styles.selectRootClass
                    }}
                    InputProps={{ style: { marginLeft: 0, fontSize: 14 } }}
                    fullWidth={false}
                    value={tipo.id}
                    onChange={(event: { target: { value: number } }) => handleChangeTipoTelefone(index, event.target.value)}
                    label="Tipo"
                    hideIconComponent={defaultPhoneType ? true : false}
                  >
                    {TipoTelefoneEnum.values().map((tipo: TipoTelefoneT, idx: number) => (
                      <MenuItem data-testid={'selectOption' + index + idx} key={tipo.id} value={tipo.id}>
                        {tipo.descricao}
                      </MenuItem>
                    ))}
                  </InputSelect>
                }
              />
            </Grid>

            {showRemovePhone && (
              <Grid item>
                <IconButton
                  data-testid={'deleteButton' + index}
                  disabled={disabled}
                  style={{ padding: 6, marginTop: 24, marginLeft: -8 }}
                  onClick={() => handleRemoverTelefone(index)}
                >
                  <DeleteIcon style={{ color: '#bbb' }} />
                </IconButton>
              </Grid>
            )}

            {showAddPhone && (
              <Grid item>
                <IconButton
                  data-testid={'addButton'}
                  disabled={disabled}
                  color="primary"
                  style={{ padding: 6, marginTop: 24, marginLeft: -8, marginRight: -8 }}
                  onClick={() => handleAdicionarTelefone()}
                >
                  <AddCircleIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )
      })}
    </div>
  )
}
