import React from 'react'

import TipoRespostaEnum from 'support/domain/fichacliente/TipoRespostaEnum'

export function ordenarItens(itens) {
  if (!itens) {
    return itens
  }

  itens.sort((i1, i2) => {
    const numeroPergunta1 = i1.posicao
    const numeroPergunta2 = i2.posicao

    if (numeroPergunta1 > numeroPergunta2) {
      return 1
    } else if (numeroPergunta1 < numeroPergunta2) {
      return -1
    } else {
      return 0
    }
  })
}

export function prepararPerguntas(fichaCliente) {
  let fichaClienteOrganizada = {}
  fichaClienteOrganizada.id = fichaCliente.id
  fichaClienteOrganizada.dataCriacao = fichaCliente.dataCriacao
  fichaClienteOrganizada.cliente = fichaCliente.cliente
  fichaClienteOrganizada.ficha = fichaCliente.ficha
  fichaClienteOrganizada.perguntas = []

  fichaCliente.ficha.perguntas.forEach((itemPergunta, index) => {
    let pergunta = {}
    pergunta.resposta = {}
    pergunta.id = itemPergunta.id
    pergunta.tipoResposta = itemPergunta.tipoResposta
    pergunta.posicao = itemPergunta.posicao
    pergunta.descricao = itemPergunta.descricao
    pergunta.alternativas = itemPergunta.alternativas

    if (pergunta.alternativas) {
      ordenarItens(itemPergunta.alternativas)
    }

    fichaCliente.respostas.forEach((itemResposta, index) => {
      if (itemResposta.pergunta.id === itemPergunta.id && itemPergunta.tipoResposta.id === itemResposta.tipoResposta.id) {
        pergunta.resposta.valor = itemResposta.resposta
        pergunta.resposta.tipoResposta = itemResposta.tipoResposta
        pergunta.resposta.id = itemResposta.id
      }
    })

    if (itemPergunta.tipoResposta.id === TipoRespostaEnum.TEXTO_LIVRE.id) {
      pergunta._resposta = React.createRef()
    } else if (itemPergunta.tipoResposta.id === TipoRespostaEnum.MULTIPLA_ESCOLHA.id) {
      if (pergunta.resposta.valor) {
        pergunta.resposta.valor = JSON.parse('[' + pergunta.resposta.valor + ']')
      } else {
        pergunta.resposta.valor = []
      }
    }

    fichaClienteOrganizada.perguntas.push(pergunta)
  })

  ordenarItens(fichaClienteOrganizada.perguntas)

  return fichaClienteOrganizada
}
