import React, { Component } from 'react'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import TipoCalculoEnum from 'support/domain/calculo/TipoCalculoEnum'
import TipoRemuneracaoEnum from 'support/domain/comissao/TipoRemuneracaoEnum'
import { formatarValorMonetario, formatarValorPercentual } from 'support/util/NumberFormatter'
import { round } from 'support/util/NumberUtil'

const styles = (theme) => ({})

function LinhaDescricaoValorDetalheRemuneracao(props) {
  return (
    <Grid container>
      <Grid item xs>
        <Typography variant="body2">{props.descricao}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" style={{ fontSize: 13 }}>
          {props.valor}
        </Typography>
      </Grid>
    </Grid>
  )
}

class DetalhesRemuneracaoDialog extends Component {
  render() {
    const { classes, remuneracao, ...others } = this.props

    let mainContent = null
    let valorRemuneracaoCalculadoEfetivo = remuneracao.valorRemuneracaoCalculadoEfetivo

    if (remuneracao.tipo.id === TipoRemuneracaoEnum.COMISSAO.id) {
      const comissao = remuneracao.comissaoVenda

      let valorVenda = comissao.valorVenda
      let valorAplicacaoComissao = comissao.valorAplicacaoComissao
      let valorAplicacaoComissaoFormatado = ''
      if (comissao.tipoCalculoComissao.id === TipoCalculoEnum.PORCENTAGEM.id) {
        valorAplicacaoComissaoFormatado = formatarValorPercentual(valorAplicacaoComissao)
      } else {
        valorAplicacaoComissaoFormatado = formatarValorMonetario(valorAplicacaoComissao)
      }

      let valorDescontoCalculadoVenda = 0
      if (comissao.venda && comissao.valorDescontoCalculado > 0) {
        valorDescontoCalculadoVenda = comissao.venda.valorDescontoCalculado
      }

      let valorTaxaRecebimentoVenda = comissao.valorTaxaRecebimentoVenda
      let valorNaoComissionavel = comissao.valorNaoComissionavel
      let valorCustoNaoComissionavel = comissao.valorCustoNaoComissionavel
      let valorDescontoAssistentes = comissao.valorDescontoAssistentes

      let valorBaseCalculoComissao = valorVenda - valorNaoComissionavel - valorDescontoCalculadoVenda - valorCustoNaoComissionavel - valorTaxaRecebimentoVenda
      let valorBrutoComissao = round(
        comissao.valorComissaoCalculado -
          comissao.valorDescontoCalculado -
          comissao.valorNaoComissionavelCalculado -
          comissao.valorCustoNaoComissionavelCalculado -
          comissao.valorTaxaRecebimentoCalculado
      )

      mainContent = (
        <React.Fragment>
          <LinhaDescricaoValorDetalheRemuneracao descricao="Valor da Venda" valor={formatarValorMonetario(valorVenda)} />
          {valorVenda !== valorBaseCalculoComissao && (
            <React.Fragment>
              <br />
              <Typography variant="body2">
                <b>Descontos Pré-cálculo</b>
              </Typography>
              <br />
              {valorDescontoCalculadoVenda > 0 && (
                <LinhaDescricaoValorDetalheRemuneracao descricao="Desconto na Venda" valor={'-' + formatarValorMonetario(valorDescontoCalculadoVenda)} />
              )}
              {valorTaxaRecebimentoVenda > 0 && (
                <LinhaDescricaoValorDetalheRemuneracao descricao="Taxa de Forma de Pagamento" valor={'-' + formatarValorMonetario(valorTaxaRecebimentoVenda)} />
              )}
              {valorCustoNaoComissionavel > 0 && (
                <LinhaDescricaoValorDetalheRemuneracao descricao="Uso de Produtos" valor={'-' + formatarValorMonetario(valorCustoNaoComissionavel)} />
              )}
              {valorNaoComissionavel > 0 && (
                <LinhaDescricaoValorDetalheRemuneracao descricao="Valor Não Comissionável" valor={'-' + formatarValorMonetario(valorNaoComissionavel)} />
              )}
              <br />
              <LinhaDescricaoValorDetalheRemuneracao descricao={'Valor para Cálculo'} valor={formatarValorMonetario(valorBaseCalculoComissao)} />
              <br />
              <Divider />
            </React.Fragment>
          )}
          <br />
          <LinhaDescricaoValorDetalheRemuneracao descricao="Cálculo para Comissão" valor={valorAplicacaoComissaoFormatado} />
          {comissao.tipoCalculoComissao.id === TipoCalculoEnum.REAL.id && comissao.venda.quantidade > 1 && (
            <LinhaDescricaoValorDetalheRemuneracao descricao="Quantidade" valor={comissao.venda.quantidade} />
          )}
          <LinhaDescricaoValorDetalheRemuneracao descricao="Valor Bruto da Comissão" valor={formatarValorMonetario(valorBrutoComissao)} />
          <br />
          {valorBrutoComissao !== comissao.valorComissaoCalculadoEfetivo && (
            <React.Fragment>
              <Typography variant="body2">
                <b>Descontos Pós-cálculo</b>
              </Typography>
              <br />
              {valorDescontoAssistentes > 0 && (
                <LinhaDescricaoValorDetalheRemuneracao descricao="Comissões de Assistentes" valor={'-' + formatarValorMonetario(valorDescontoAssistentes)} />
              )}
              <br />
            </React.Fragment>
          )}
          <LinhaDescricaoValorDetalheRemuneracao descricao={<b>Valor Total da Comissão</b>} valor={<b>{formatarValorMonetario(valorRemuneracaoCalculadoEfetivo)}</b>} />
          <br />
          <Divider />
        </React.Fragment>
      )
    } else if (remuneracao.tipo.id === TipoRemuneracaoEnum.GORJETA.id) {
      const gorjeta = remuneracao.gorjeta
      let valorTotal = gorjeta.valorTotal
      let valorEstorno = gorjeta.valorEstorno
      let valorTaxaRecebimentoCalculado = gorjeta.valorTaxaRecebimentoCalculado

      mainContent = (
        <React.Fragment>
          {valorTotal !== valorRemuneracaoCalculadoEfetivo && (
            <React.Fragment>
              <LinhaDescricaoValorDetalheRemuneracao descricao="Valor Bruto" valor={formatarValorMonetario(valorTotal)} />
              {valorEstorno > 0 && <LinhaDescricaoValorDetalheRemuneracao descricao="Estorno" valor={'-' + formatarValorMonetario(valorEstorno)} />}
              {valorTaxaRecebimentoCalculado > 0 && (
                <LinhaDescricaoValorDetalheRemuneracao descricao="Taxa de Forma de Pagamento" valor={'-' + formatarValorMonetario(valorTaxaRecebimentoCalculado)} />
              )}
            </React.Fragment>
          )}
          <LinhaDescricaoValorDetalheRemuneracao descricao={<b>Valor Total da Gorjeta</b>} valor={<b>{formatarValorMonetario(valorRemuneracaoCalculadoEfetivo)}</b>} />
        </React.Fragment>
      )
    } else if (remuneracao.tipo.id === TipoRemuneracaoEnum.SALARIO.id) {
      const salario = remuneracao.salario

      mainContent = (
        <React.Fragment>
          <LinhaDescricaoValorDetalheRemuneracao descricao={<b>Valor do Salário</b>} valor={<b>{formatarValorMonetario(salario.valor)}</b>} />
        </React.Fragment>
      )
    } else if (remuneracao.tipo.id === TipoRemuneracaoEnum.BONIFICACAO.id) {
      const bonificacao = remuneracao.bonificacao

      mainContent = (
        <React.Fragment>
          <LinhaDescricaoValorDetalheRemuneracao descricao={<b>Valor da Bonificação</b>} valor={<b>{formatarValorMonetario(bonificacao.valor)}</b>} />
        </React.Fragment>
      )
    }

    return <MessageDialog {...others} text={mainContent} />
  }
}

DetalhesRemuneracaoDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesRemuneracaoDialog)
