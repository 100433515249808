import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import PersonIcon from '@material-ui/icons/Person'
import PlaceIcon from '@material-ui/icons/Place'
import WorkIcon from '@material-ui/icons/Work'
import ConfiguracoesHorariosDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesHorariosDialogPage'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import SectionSeparator from 'support/components/form/SectionSeparator'
import AttributionIcon from 'support/components/icon/Attribution'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import FormHelperText from 'support/components/input/core/FormHelperText'
import InputColorSelect from 'support/components/input/InputColorSelect'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import BancoInputDialogSelect from 'support/domain/banking/input/BancoInputDialogSelect'
import TipoContaBancariaEnum from 'support/domain/banking/TipoContaBancariaEnum'
import TipoPessoaEnum from 'support/domain/banking/TipoPessoaEnum'
import ColaboradorCargoEnum from 'support/domain/colaborador/ColaboradorCargoEnum'
import ColaboradorTipoRecebimentoEnum from 'support/domain/colaborador/ColaboradorTipoRecebimentoEnum'
import EstadoEnum from 'support/domain/localizacao/EstadoEnum'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import { CEPMask, CNPJMask, CPFMask, DataNascimentoMask, getDataNascimentoAnoMaskIdByNumero, InputStringNumberFormat } from 'support/util/Masks'
import { InputTelefoneList } from 'support/components/input/InputTelefoneList'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

const INPUT_CUSTOM_VARIANT = 'outlined-small'

class ColaboradorDadosBasicosDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      colaborador: null,
      controleAgendamentoOnline: ControleAgendamentoOnlineEnum.HABILITADO,
      meiosRecebimento: this.createMeiosRecebimentoObject(),
      cargos: ColaboradorCargoEnum.generateAllItems(),
      realizaAtendimentos: true,
      vendeProdutos: false,
      vendePacotes: false,
      title: 'Colaborador',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
    this.inputApelido = React.createRef()
    this.inputEmail = React.createRef()
    this.inputCor = React.createRef()
    this.inputIdentificacaoSalaoParceiro = React.createRef()
    this.telefonesComponentFunctions = {}
    this.inputRG = React.createRef()
    this.inputCPF = React.createRef()
    this.inputDataNascimento = React.createRef()
    this.inputInstagram = React.createRef()
    this.inputInformacoesAdicionais = React.createRef()
    this.inputOrgaoExpedidor = React.createRef()
    this.inputEnderecoEndereco = React.createRef()
    this.inputEnderecoNumero = React.createRef()
    this.inputEnderecoComplemento = React.createRef()
    this.inputEnderecoBairro = React.createRef()
    this.inputEnderecoCidade = React.createRef()
    this.enderecoEstadoFunctionMap = {}
    this.inputEnderecoCep = React.createRef()
  }

  handleChangeCargo = (position, event) => {
    const cargos = this.state.cargos
    for (let cargo of cargos) {
      if (cargo.position === position) {
        cargo.exists = event.target.checked
        break
      }
    }
    this.setState({ cargos: cargos })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idColaborador) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/colaboradores.buscarPorId',
        params: {
          id: this.props.idColaborador
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState((state) => ({
            colaborador: response.data,
            cargos: ColaboradorCargoEnum.generateAllItems(response.data.cargos),
            realizaAtendimentos: response.data.realizaAtendimentos,
            vendeProdutos: response.data.vendeProdutos,
            vendePacotes: response.data.vendePacotes,
            meiosRecebimento: this.createMeiosRecebimentoObject(response.data.meiosRecebimento),
            controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(response.data.controleAgendamentoOnline.id)
          }))
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  createMeiosRecebimentoObject(meiosRecebimento) {
    if (!meiosRecebimento) {
      meiosRecebimento = []
    }

    ColaboradorTipoRecebimentoEnum.values().forEach(function (tipoRecebimento) {
      let encontrouTipoRecebimento = false
      let meioRecebimento = {}

      for (let index in meiosRecebimento) {
        if (meiosRecebimento[index].tipo.id === tipoRecebimento.id) {
          encontrouTipoRecebimento = true
          meioRecebimento = meiosRecebimento[index]
          break
        }
      }
      if (!encontrouTipoRecebimento) {
        meioRecebimento = {
          tipo: {
            id: tipoRecebimento.id
          },
          contaBancaria: {},
          chavePix: {}
        }
        meiosRecebimento.push(meioRecebimento)
      }

      meioRecebimento.inputChavePix = React.createRef()
      meioRecebimento.inputAgencia = React.createRef()
      meioRecebimento.inputConta = React.createRef()
      meioRecebimento.inputContaDigito = React.createRef()
      meioRecebimento.inputNomeBeneficiarioBanco = React.createRef()
      meioRecebimento.inputCpfBeneficiarioBanco = React.createRef()
      meioRecebimento.inputCnpjBeneficiarioBanco = React.createRef()
    })

    meiosRecebimento.sort(function (a, b) {
      if (b.tipo.id < a.tipo.id) return -1
      if (b.tipo.id > a.tipo.id) return 1
      return 0
    })

    return meiosRecebimento
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  getDados = () => {
    const dados = {
      telefones: this.telefonesComponentFunctions.getValues()
    }

    if (this.props.idColaborador) {
      dados.id = this.props.idColaborador
    }

    dados.nome = this.inputNome.current.value

    if (this.inputApelido.current.value) {
      dados.apelido = this.inputApelido.current.value
    } else {
      dados.apelido = this.inputNome.current.value
    }

    dados.identificacaoSalaoParceiro = this.inputIdentificacaoSalaoParceiro.current.value

    dados.email = this.inputEmail.current.value
    dados.cor = this.inputCor.current.value.id
    dados.controleAgendamentoOnline = { id: this.state.controleAgendamentoOnline.id }
    dados.cargos = ColaboradorCargoEnum.getString(this.state.cargos)
    dados.realizaAtendimentos = this.state.realizaAtendimentos
    dados.vendeProdutos = this.state.vendeProdutos
    dados.vendePacotes = this.state.vendePacotes

    let instagram = this.inputInstagram.current.value.trim()
    if (instagram === '') {
      instagram = null
    }
    dados.instagram = instagram

    dados.colaboradorMeiosRecebimento = []

    this.state.meiosRecebimento.forEach((meioRecebimento) => {
      const colaboradorMeioRecebimento = {
        id: meioRecebimento.id,
        tipo: {
          id: meioRecebimento.tipo.id
        }
      }
      if (meioRecebimento.tipo.id === ColaboradorTipoRecebimentoEnum.PIX.id) {
        colaboradorMeioRecebimento.chavePix = {
          valor: meioRecebimento.inputChavePix.current.value,
          id: meioRecebimento.chavePix ? meioRecebimento.chavePix.id : null
        }
      } else {
        const contaBancaria = meioRecebimento.contaBancaria
        let cpfContaBancaria = null
        let cnpjContaBancaria = null
        if (contaBancaria.tipoPessoa && contaBancaria.tipoPessoa.id === TipoPessoaEnum.PESSOA_FISICA.id && meioRecebimento.inputCpfBeneficiarioBanco.current) {
          cpfContaBancaria = meioRecebimento.inputCpfBeneficiarioBanco.current.value.replace(/[^0-9]/g, '').trim()
          if (cpfContaBancaria === '') {
            cpfContaBancaria = null
          }
        }

        if (contaBancaria.tipoPessoa && contaBancaria.tipoPessoa.id === TipoPessoaEnum.PESSOA_JURIDICA.id && meioRecebimento.inputCnpjBeneficiarioBanco.current) {
          cnpjContaBancaria = meioRecebimento.inputCnpjBeneficiarioBanco.current.value.replace(/[^0-9]/g, '').trim()
          if (cnpjContaBancaria === '') {
            cnpjContaBancaria = null
          }
        }

        colaboradorMeioRecebimento.contaBancaria = {
          id: contaBancaria.id,
          banco: contaBancaria.banco ? { id: contaBancaria.banco.id } : null,
          tipoPessoa: contaBancaria.tipoPessoa ? { id: contaBancaria.tipoPessoa.id } : null,
          tipo: contaBancaria.tipo ? { id: contaBancaria.tipo.id } : null,
          agencia: meioRecebimento.inputAgencia.current.value.trim(),
          conta: meioRecebimento.inputConta.current.value.trim(),
          contaDigito: meioRecebimento.inputContaDigito.current.value.trim(),
          nome: meioRecebimento.inputNomeBeneficiarioBanco.current.value.trim(),
          cpf: cpfContaBancaria,
          cnpj: cnpjContaBancaria
        }
      }

      dados.colaboradorMeiosRecebimento.push(colaboradorMeioRecebimento)
    })

    let cpf = null
    if (this.inputCPF.current) {
      cpf = this.inputCPF.current.value.replace(/[^0-9]/g, '').trim()

      if (cpf === '') {
        cpf = null
      }
    }
    dados.cpf = cpf

    let rg = null

    if (this.inputRG.current) {
      rg = this.inputRG.current.value.trim()
      if (rg === '') {
        rg = null
      }
    }
    dados.rg = rg
    dados.informacoesAdicionais = this.inputInformacoesAdicionais.current.value
    dados.orgaoExpedidor = this.inputOrgaoExpedidor.current.value

    let dataNascimento = null

    if (this.inputDataNascimento.current) {
      dataNascimento = this.inputDataNascimento.current.value
    }

    let diaNascimento, mesNascimento, anoNascimento
    if (dataNascimento) {
      const datas = dataNascimento.replace(/\s+/g, '').split('/')

      diaNascimento = parseInt(datas[0], 10)
      mesNascimento = datas[1] ? parseInt(datas[1], 10) : null
      anoNascimento = datas[2] ? parseInt(datas[2], 10) : null
    }
    dados.diaNascimento = diaNascimento
    dados.mesNascimento = mesNascimento
    dados.anoNascimento = anoNascimento

    let cep = this.inputEnderecoCep.current.value ? this.inputEnderecoCep.current.value : null
    let estado = {
      id: this.enderecoEstadoFunctionMap.getValue()
    }

    dados.endereco = {
      id: this.state.colaborador && this.state.colaborador.endereco ? this.state.colaborador.endereco.id : null,
      endereco: this.inputEnderecoEndereco.current.value,
      numero: this.inputEnderecoNumero.current.value,
      complemento: this.inputEnderecoComplemento.current.value,
      bairro: this.inputEnderecoBairro.current.value,
      cidade: this.inputEnderecoCidade.current.value,
      estado: estado,
      cep: cep
    }

    return dados
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/colaboradores.persistir',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        const colaborador = response.data
        EventsManager.pub('ManipulacaoColaborador', { colaborador: colaborador })
        if (!this.props.idColaborador) {
          openBackableDialog(ConfiguracoesHorariosDialogPage, {
            parent: this,
            shouldCloseParent: true,
            colaborador: colaborador,
            origem: 'novoColaborador'
          })
        } else {
          this.props.handleCloseDialog()
        }
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formColaborador')
      }
    })
  }

  handleCheckMask = (value) => {
    const maskAnoNascimento = getDataNascimentoAnoMaskIdByNumero(value)
    this.setState({ maskAnoNascimento: maskAnoNascimento })
  }

  getKeyErrorsMapPrefix(index) {
    return 'meiosRecebimento[' + index + ']'
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, meiosRecebimento, realizaAtendimentos, vendeProdutos, vendePacotes } = this.state
    let { maskAnoNascimento } = this.state
    let objEndereco = {}

    if (this.state.colaborador && this.state.colaborador.endereco) {
      objEndereco = this.state.colaborador.endereco
    }

    const {
      nome,
      apelido,
      cor,
      identificacaoSalaoParceiro,
      telefones,
      usuario,
      cpf,
      rg,
      diaNascimento,
      mesNascimento,
      anoNascimento,
      instagram,
      informacoesAdicionais,
      orgaoExpedidor
    } = this.state.colaborador ? this.state.colaborador : {}
    const { endereco, numero, complemento, bairro, cidade, estado, cep } = objEndereco

    let nomeDefaultValue = this.props.nome
    if (nome) {
      nomeDefaultValue = nome
    }

    let dataNascimentoDefaultValue = null

    if (diaNascimento) {
      dataNascimentoDefaultValue = diaNascimento.toString().padStart(2, '0') + mesNascimento.toString().padStart(2, '0')
      if (anoNascimento) {
        dataNascimentoDefaultValue = dataNascimentoDefaultValue + anoNascimento
        maskAnoNascimento = true
      }
    }

    let regraSalaoParceiroAplicada = false
    if (getSessaoPainel().negocio.carregado && getSessaoPainel().negocio.configuracaoNf && getSessaoPainel().negocio.configuracaoNf.nfsAplicarRegraSalaoParceiro === true) {
      regraSalaoParceiroAplicada = true
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={540}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => (
            <form id="formColaborador" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                defaultValue={nomeDefaultValue}
                customVariant="outlined-small"
                shrink={false}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome"
                inputRef={this.inputNome}
                inputProps={{
                  maxLength: 100
                }}
                endAdornment={<InputColorSelect style={{ marginRight: 8 }} defaultValue={cor} inputRef={this.inputCor} />}
              />

              <InputText
                marginTop={true}
                idname="xapelido"
                defaultValue={apelido}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('apelido')}
                label="Apelido"
                inputRef={this.inputApelido}
                inputProps={{
                  maxLength: 100
                }}
              />

              <InputText
                marginTop={true}
                idname="xemail"
                label="E-mail do colaborador"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={usuario ? usuario.email : ''}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('email')}
                helpMessage={
                  <span>
                    Preencha este campo de e-mail caso o colaborador também precise acessar o sistema. É através deste e-mail que o seu colaborador conseguirá acessar essa conta.
                    Além disso, lembre-se de entrar na seção de <b>Permissões</b> nas configurações do colaborador e definir quais permissões este colaborar terá para acessar o
                    sistema.
                  </span>
                }
                inputRef={this.inputEmail}
              />

              <InputText
                marginTop={true}
                style={{ display: !regraSalaoParceiroAplicada ? 'none' : undefined }}
                idname="xidentificacaosalaoparceiro"
                defaultValue={identificacaoSalaoParceiro}
                customVariant="outlined-small"
                shrink={false}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('identificacaoSalaoParceiro')}
                label="Identificação - Profissional Parceiro"
                inputRef={this.inputIdentificacaoSalaoParceiro}
                staticHelpMessage={
                  <span>
                    Texto que identificará o profissional na descrição da Cota Parte na nota fiscal do Salão Parceiro. É comum preencher o nome ou o CNPJ do profissional.
                  </span>
                }
                inputProps={{
                  maxLength: 45
                }}
              />

              {getSessaoPainel().negocio.carregado &&
                ControleAgendamentoOnlineEnum.HABILITADO.id === getSessaoPainel().negocio.configuracoesGerais.controleAgendamentoOnline.id && (
                  <React.Fragment>
                    <InputSelect
                      idname="xcontroleAtendimentoOnline"
                      style={{ minWidth: 200 }}
                      marginTop={true}
                      label={'Agendamento Online'}
                      disabled={this.state.ajaxing}
                      helpMessage={this.state.controleAgendamentoOnline.descricaoExplicacaoColaborador}
                      customVariant="outlined-small"
                      value={this.state.controleAgendamentoOnline.id}
                      onChange={(event) => {
                        const id = event.target.value
                        this.setState({ controleAgendamentoOnline: ControleAgendamentoOnlineEnum.getById(id) })
                      }}
                    >
                      {ControleAgendamentoOnlineEnum.values().map((controle) => (
                        <MenuItem key={controle.id} value={controle.id}>
                          {controle.descricaoOpcao}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  </React.Fragment>
                )}

              <SectionSeparator icon={<WorkIcon style={{ marginLeft: -1 }} />} title="Cargos e Funções" />

              {this.state.cargos.map((cargo) => (
                <FormControlLabel
                  key={cargo.position}
                  control={<Checkbox color="primary" disabled={this.state.ajaxing} checked={cargo.exists} onChange={(event) => this.handleChangeCargo(cargo.position, event)} />}
                  label={<Typography variant="body1">{cargo.descricao}</Typography>}
                />
              ))}

              <SectionSeparator icon={<AttributionIcon style={{ marginLeft: -2 }} />} title="Atribuições" />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.ajaxing}
                    checked={realizaAtendimentos}
                    onChange={(event) => {
                      const realizaAtendimentos = event.target.checked
                      this.setState({ realizaAtendimentos: realizaAtendimentos })
                    }}
                  />
                }
                label={
                  <React.Fragment>
                    <Typography variant="body1">Executa Atendimentos</Typography>
                    <FormHelperText customVariant="outlined-small" margin={0}>
                      Ao habilitar esta opção, o sistema permitirá criar atendimentos (agendados ou por ordem de chegada) para este colaborador.
                    </FormHelperText>
                  </React.Fragment>
                }
              />

              <FormControlLabel
                style={{ marginTop: 20 }}
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.ajaxing}
                    checked={vendeProdutos}
                    onChange={(event) => {
                      const vendeProdutos = event.target.checked
                      this.setState({ vendeProdutos: vendeProdutos })
                    }}
                  />
                }
                label={
                  <React.Fragment>
                    <Typography variant="body1">Vende Produtos</Typography>
                    <FormHelperText customVariant="outlined-small" margin={0}>
                      Ao habilitar esta opção, o sistema permitirá registrar vendas de produtos por este colaborador.
                    </FormHelperText>
                  </React.Fragment>
                }
              />

              <FormControlLabel
                style={{ marginTop: 20 }}
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.ajaxing}
                    checked={vendePacotes}
                    onChange={(event) => {
                      const vendePacotes = event.target.checked
                      this.setState({ vendePacotes: vendePacotes })
                    }}
                  />
                }
                label={
                  <React.Fragment>
                    <Typography variant="body1">Vende Pacotes</Typography>
                    <FormHelperText customVariant="outlined-small" margin={0}>
                      Ao habilitar esta opção, o sistema permitirá registrar vendas de pacotes por este colaborador.
                    </FormHelperText>
                  </React.Fragment>
                }
              />

              <SectionSeparator icon={<ContactPhoneIcon />} title="Contato" />

              <InputText
                idname="xinstagram"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={instagram}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('instagram')}
                label="Instagram"
                inputRef={this.inputInstagram}
              />

              <InputTelefoneList
                defaultPhones={telefones}
                maxNumberOfPhones={5}
                disabled={this.state.ajaxing}
                errorsMap={this.state.errorsMap}
                componentFunctions={this.telefonesComponentFunctions}
              />

              <SectionSeparator icon={<PersonIcon />} title="Informações Pessoais" />

              <InputText
                idname="xdatanascimento"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={dataNascimentoDefaultValue}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('dataNascimento')}
                label="Data de Nascimento"
                helpMessage="Utilize dia/mês (ex: 01/06) ou dia/mês/ano (ex: 01/06/1988)"
                inputComponent={DataNascimentoMask}
                inputRef={this.inputDataNascimento}
                inputProps={{ maskAnoNascimento: maskAnoNascimento }}
                onChange={(event) => this.handleCheckMask(event.target.value)}
              />

              <InputText
                marginTop={true}
                idname="xcpf"
                customVariant="outlined-small"
                shrink={false}
                defaultValue={cpf}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('cpf')}
                label="CPF"
                inputComponent={CPFMask}
                inputRef={this.inputCPF}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    containerProps: { xs: 4 },
                    input: (
                      <InputText
                        idname="xorgaoexpedidor"
                        customVariant="outlined-small"
                        shrink={false}
                        defaultValue={orgaoExpedidor}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('orgaoExpedidor')}
                        label="Órgão Expedidor"
                        inputRef={this.inputOrgaoExpedidor}
                        inputProps={{
                          maxLength: 10
                        }}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 8 },
                    input: (
                      <InputText
                        idname="xrg"
                        customVariant="outlined-small"
                        shrink={false}
                        defaultValue={rg}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('rg')}
                        label="RG"
                        inputRef={this.inputRG}
                      />
                    )
                  }
                ]}
              />

              <InputText
                disabled={this.state.ajaxing}
                marginTop={true}
                id="xinfadicionais"
                name="xinfadicionais"
                multiline={true}
                defaultValue={informacoesAdicionais}
                errorMessage={errorsMap('informacoesAdicionais')}
                customVariant={INPUT_CUSTOM_VARIANT}
                shrink={false}
                label="Informações Adicionais"
                inputRef={this.inputInformacoesAdicionais}
                inputProps={{
                  maxLength: 2000
                }}
              />

              <SectionSeparator icon={<AccountBalanceWalletIcon />} title="Informações Bancárias" />

              {meiosRecebimento.map((meioRecebimento, index) => {
                const { chavePix, contaBancaria } = meioRecebimento
                if (meioRecebimento.tipo.id === ColaboradorTipoRecebimentoEnum.PIX.id) {
                  return (
                    <InputText
                      key="pix"
                      idname="xchavePix"
                      customVariant="outlined-small"
                      shrink={false}
                      defaultValue={chavePix ? chavePix.valor : undefined}
                      disabled={this.state.ajaxing}
                      errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.chavePix')}
                      label="Chave Pix"
                      inputProps={{
                        maxLength: 255
                      }}
                      inputRef={meioRecebimento.inputChavePix}
                    />
                  )
                } else {
                  const { tipoPessoa, tipo, banco, agencia, conta, contaDigito } = contaBancaria ? contaBancaria : {}
                  return (
                    <React.Fragment key="rfb">
                      <InputHorizontalContainer
                        customVariant={INPUT_CUSTOM_VARIANT}
                        style={{ marginTop: 14 }}
                        inputTexts={[
                          {
                            input: (
                              <BancoInputDialogSelect
                                disabled={this.state.ajaxing}
                                customVariant={INPUT_CUSTOM_VARIANT}
                                shrink={false}
                                defaultValue={banco}
                                functionsMap={this.colaboradorFunctionsMap}
                                errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.banco')}
                                label={'Banco'}
                                onChange={(value) => {
                                  const state = this.state
                                  state.meiosRecebimento[index].contaBancaria.banco = value ? value.banco : null
                                  this.setState(state)
                                }}
                              />
                            )
                          }
                        ]}
                      />

                      <InputHorizontalContainer
                        customVariant={INPUT_CUSTOM_VARIANT}
                        style={{ marginTop: 14 }}
                        inputTexts={[
                          {
                            containerProps: { xs: 12, sm: 4 },
                            input: (
                              <InputText
                                disabled={this.state.ajaxing}
                                id="xagencia"
                                name="xagencia"
                                defaultValue={agencia}
                                customVariant={INPUT_CUSTOM_VARIANT}
                                shrink={false}
                                label="Agência"
                                errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.agencia')}
                                inputProps={{
                                  maxLength: 7
                                }}
                                inputRef={meioRecebimento.inputAgencia}
                              />
                            )
                          },
                          {
                            containerProps: { xs: 8, sm: 5 },
                            input: (
                              <InputText
                                disabled={this.state.ajaxing}
                                id="xconta"
                                name="xconta"
                                defaultValue={conta}
                                customVariant={INPUT_CUSTOM_VARIANT}
                                shrink={false}
                                label="Conta"
                                errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.conta')}
                                inputComponent={InputStringNumberFormat}
                                inputProps={{
                                  maxLength: 15
                                }}
                                inputRef={meioRecebimento.inputConta}
                              />
                            )
                          },
                          {
                            containerProps: { xs: 4, sm: 3 },
                            input: (
                              <InputText
                                disabled={this.state.ajaxing}
                                id="xdigito"
                                name="xdigito"
                                defaultValue={contaDigito}
                                customVariant={INPUT_CUSTOM_VARIANT}
                                shrink={false}
                                label="Dígito"
                                errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.contaDigito')}
                                inputComponent={InputStringNumberFormat}
                                inputProps={{
                                  maxLength: 2
                                }}
                                inputRef={meioRecebimento.inputContaDigito}
                              />
                            )
                          }
                        ]}
                      />

                      <InputHorizontalContainer
                        customVariant={INPUT_CUSTOM_VARIANT}
                        style={{ marginTop: 14 }}
                        inputTexts={[
                          {
                            containerProps: { xs: 12, sm: 6 },
                            input: (
                              <InputSelect
                                disabled={this.state.ajaxing}
                                id="xtipoConta"
                                name="xtipoConta"
                                defaultValue={tipo ? tipo.id : null}
                                customVariant={INPUT_CUSTOM_VARIANT}
                                shrink={false}
                                label="Tipo Conta"
                                errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.tipoConta')}
                                onChange={(event) => {
                                  const state = this.state
                                  state.meiosRecebimento[index].contaBancaria.tipo = TipoContaBancariaEnum.getById(event.target.value)
                                  this.setState(state)
                                }}
                              >
                                {TipoContaBancariaEnum.values().map((tipoConta) => (
                                  <MenuItem key={tipoConta.id} value={tipoConta.id}>
                                    {tipoConta.descricao}
                                  </MenuItem>
                                ))}
                              </InputSelect>
                            )
                          },
                          {
                            containerProps: { xs: 12, sm: 6 },
                            input: (
                              <InputSelect
                                disabled={this.state.ajaxing}
                                id="xtipoPessoa"
                                name="xtipoPessoa"
                                defaultValue={tipoPessoa ? tipoPessoa.id : null}
                                customVariant={INPUT_CUSTOM_VARIANT}
                                shrink={false}
                                label="Tipo Pessoa"
                                errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.tipoPessoa')}
                                onChange={(event) => {
                                  const state = this.state
                                  state.meiosRecebimento[index].contaBancaria.tipoPessoa = TipoPessoaEnum.getById(event.target.value)
                                  this.setState(state)
                                }}
                              >
                                {TipoPessoaEnum.values().map((tipoPessoa) => (
                                  <MenuItem key={tipoPessoa.id} value={tipoPessoa.id}>
                                    {tipoPessoa.descricao}
                                  </MenuItem>
                                ))}
                              </InputSelect>
                            )
                          }
                        ]}
                      />

                      <InputHorizontalContainer
                        customVariant={INPUT_CUSTOM_VARIANT}
                        style={{ marginTop: 14 }}
                        inputTexts={[
                          {
                            containerProps: { xs: 12, sm: !tipoPessoa ? 12 : 8 },
                            input: (
                              <InputText
                                disabled={this.state.ajaxing}
                                id="xnomeBeneficiario"
                                name="xnomeBeneficiario"
                                defaultValue={contaBancaria.nome}
                                customVariant={INPUT_CUSTOM_VARIANT}
                                shrink={false}
                                errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.nomeBeneficiario')}
                                label={tipoPessoa && tipoPessoa.id === TipoPessoaEnum.PESSOA_JURIDICA.id ? 'Razão Social' : 'Nome'}
                                inputProps={{
                                  maxLength: 255
                                }}
                                inputRef={meioRecebimento.inputNomeBeneficiarioBanco}
                              />
                            )
                          },
                          {
                            containerProps: { xs: 12, sm: 4 },
                            input: (
                              <React.Fragment>
                                <InputText
                                  disabled={this.state.ajaxing}
                                  id="xcpfBancario"
                                  name="xcpfBancario"
                                  defaultValue={contaBancaria.cpf}
                                  customVariant={INPUT_CUSTOM_VARIANT}
                                  shrink={false}
                                  style={{ display: tipoPessoa && tipoPessoa.id === TipoPessoaEnum.PESSOA_FISICA.id ? undefined : 'none' }}
                                  errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.cpf')}
                                  label="CPF"
                                  inputComponent={CPFMask}
                                  inputRef={meioRecebimento.inputCpfBeneficiarioBanco}
                                />
                                <InputText
                                  id="xcnpjBancario"
                                  name="xcnpjBancario"
                                  style={{ display: tipoPessoa && tipoPessoa.id === TipoPessoaEnum.PESSOA_JURIDICA.id ? undefined : 'none' }}
                                  customVariant={INPUT_CUSTOM_VARIANT}
                                  shrink={false}
                                  defaultValue={contaBancaria.cnpj}
                                  disabled={this.state.ajaxing}
                                  errorMessage={errorsMap(this.getKeyErrorsMapPrefix(index) + '.cnpj')}
                                  label="CNPJ"
                                  inputComponent={CNPJMask}
                                  inputRef={meioRecebimento.inputCnpjBeneficiarioBanco}
                                />
                              </React.Fragment>
                            )
                          }
                        ]}
                      />
                    </React.Fragment>
                  )
                }
              })}

              <SectionSeparator icon={<PlaceIcon />} title="Endereço" />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        idname="xendereco"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        defaultValue={endereco}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.endereco')}
                        label="Endereço"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoEndereco}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    containerProps: { xs: 5, sm: 4 },
                    input: (
                      <InputText
                        idname="xenderecoNumero"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        defaultValue={numero}
                        inputComponent={InputStringNumberFormat}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.numero')}
                        label="Número"
                        inputProps={{
                          maxLength: 10
                        }}
                        inputRef={this.inputEnderecoNumero}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 7, sm: 8 },
                    input: (
                      <InputText
                        idname="xenderecoComplemento"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        disabled={this.state.ajaxing}
                        defaultValue={complemento}
                        errorMessage={errorsMap('endereco.complemento')}
                        label="Complemento"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoComplemento}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        idname="xenderecoBairro"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        defaultValue={bairro}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.bairro')}
                        label="Bairro"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoBairro}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    input: (
                      <InputText
                        idname="xenderecoCidade"
                        customVariant={INPUT_CUSTOM_VARIANT}
                        shrink={false}
                        disabled={this.state.ajaxing}
                        defaultValue={cidade}
                        errorMessage={errorsMap('endereco.cidade')}
                        label="Cidade"
                        inputProps={{
                          maxLength: 255
                        }}
                        inputRef={this.inputEnderecoCidade}
                      />
                    )
                  }
                ]}
              />

              <InputHorizontalContainer
                customVariant={INPUT_CUSTOM_VARIANT}
                style={{ marginTop: 14 }}
                inputTexts={[
                  {
                    containerProps: { xs: 7 },
                    input: (
                      <InputSelect
                        idname="xenderecoEstado"
                        customVariant="outlined-small"
                        shrink={false}
                        label="Estado"
                        defaultValue={estado ? estado.id : null}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.estado')}
                        helpMessage="Selecione o estado"
                        functionsMap={this.enderecoEstadoFunctionMap}
                      >
                        {EstadoEnum.values().map((estado) => (
                          <MenuItem key={estado.id} value={estado.id}>
                            {estado.nome}
                          </MenuItem>
                        ))}
                      </InputSelect>
                    )
                  },
                  {
                    containerProps: { xs: 5 },
                    input: (
                      <InputText
                        idname="xenderecoCep"
                        customVariant="outlined-small"
                        shrink={false}
                        defaultValue={cep}
                        disabled={this.state.ajaxing}
                        errorMessage={errorsMap('endereco.cep')}
                        label="CEP"
                        inputComponent={CEPMask}
                        inputRef={this.inputEnderecoCep}
                      />
                    )
                  }
                ]}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ColaboradorDadosBasicosDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ColaboradorDadosBasicosDialogPage)
