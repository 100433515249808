import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import FilaEspera from 'pages/erp/painel/atendimento/agendainterface/FilaEspera'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'

const styles = (theme) => ({})

class FilaEsperaDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      title: 'Fila de Espera'
    }
  }

  componentDidMount() {
    this.eventsManager.sub('AgendaInterface', (props) => {
      if (props.action === 'changeColunaWidth') {
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, ...others } = this.props

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={324} ajaxing={false} toolbarActions={this.state.toolbarActions}>
        {(dialogContentProps) => <FilaEspera scrollElement={dialogContentProps.scrollContainerRef.current} {...others} />}
      </DialogPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

FilaEsperaDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FilaEsperaDialogPage)
