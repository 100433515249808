import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import RegistrarPagamentoDialogPage from 'pages/erp/painel/remuneracao/RegistrarPagamentoDialogPage'
import RemuneracoesDetalhadasResponsiveTable from 'pages/erp/painel/remuneracao/table/RemuneracoesDetalhadasResponsiveTable'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { createErrorsMap } from 'support/util/FormUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { round } from 'support/util/NumberUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  containerTotalizadores: {
    float: 'right',
    display: 'inline-block',
    maxWidth: 340,
    width: '100%',
    paddingBottom: 0,
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    }
  }
})

class SelecionarRemuneracoesPagamentoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: true,
      title: 'Pagamento',
      selecionarTodos: true,
      errorsMap: createErrorsMap(),
      ajaxing: false,
      caixas: []
    }

    this.dataFunctionsMap = {}
    this.inputCaixaFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    const idsTiposRemuneracao = []
    if (this.props.tipo) {
      idsTiposRemuneracao.push(this.props.tipo.id)
    }

    getAPI({
      url: 'erp/colaborador/remuneracoes.buscarDadosSelecionarRemuneracoesPagamento',
      params: {
        idColaborador: this.props.colaborador.id,
        dataInicial: converterMomentParaDataInt(this.props.dataPesquisaInicial),
        dataFinal: converterMomentParaDataInt(this.props.dataPesquisaFinal),
        idsTipos: idsTiposRemuneracao.length > 0 ? idsTiposRemuneracao : null,
        excluidos: false,
        cancelados: false,
        apenasPendentePagamento: true
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        for (let item of response.data.remuneracoes) {
          item._checked = true
        }
        this.setState({
          lista: response.data.remuneracoes,
          valorValesDisponiveis: response.data.valorValesDisponiveis,
          paginaCarregada: true
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  selecionarItem = (item) => {
    const lista = this.state.lista
    item._checked = !item._checked
    item._update = true
    this.setState({ selecionarTodos: this.getStatusSelecionarTodos(), lista: lista })
    //this.resumoTotalFunctionMap.update();
  }

  getStatusSelecionarTodos = () => {
    const lista = this.state.lista
    for (let i = 0; i < lista.length; i++) {
      const itemLista = lista[i]
      if (!itemLista._checked) {
        return false
      }
    }
    return true
  }

  selecionarTodos = (event) => {
    const lista = this.state.lista
    if (event.target.checked) {
      for (let item of lista) {
        item._checked = true
        item._update = true
      }
    } else {
      for (let item of lista) {
        item._checked = false
        item._update = true
      }
    }
    this.setState({ lista: lista, selecionarTodos: event.target.checked })
    //this.resumoTotalFunctionMap.update();
  }

  abrirRegistrarPagamentoDialogPage = () => {
    if (!verificarAutorizacao([PA.PODE_PAGAR_REMUNERACOES])) {
      openBackableDialog(AccessDeniedDialog)
      return
    }

    const remuneracoesSelecionadas = []
    let valorPendentePagamento = 0
    for (let item of this.state.lista) {
      if (item._checked) {
        remuneracoesSelecionadas.push(item)
        valorPendentePagamento += item.valorPendentePagamento
      }
    }

    if (remuneracoesSelecionadas.length === 0) {
      openBackableDialog(MessageDialog, {
        text: 'É necessário selecionar ao menos uma remuneração para realizar o registro de pagamento.'
      })
      return
    }

    openBackableDialog(RegistrarPagamentoDialogPage, {
      parent: this,
      colaborador: this.props.colaborador,
      dataPesquisaInicial: this.props.dataPesquisaInicial,
      dataPesquisaFinal: this.props.dataPesquisaFinal,
      remuneracoesSelecionadas: remuneracoesSelecionadas,
      valorPendentePagamento: round(valorPendentePagamento)
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, colaborador } = this.props

    let quantidadeSelecionados = 0
    let valorTotalServicosSemDesconto = 0
    let valorTotalServicosComDesconto = 0
    let valorTotalProdutosSemDesconto = 0
    let valorTotalProdutosComDesconto = 0
    let valorTotalRemuneracoes = 0
    let valorTotalPendentePagamento = 0
    let valorTotalPago = 0
    let valorPendentePagamentoDescontandoVales = 0

    let mapFormasPagamentos = new Map()

    if (this.state.lista) {
      for (let item of this.state.lista) {
        if (item._checked) {
          if (item.comissaoVenda) {
            if (item.comissaoVenda.venda.tipo.id === TipoVendaEnum.ATENDIMENTO.id) {
              valorTotalServicosSemDesconto += item.comissaoVenda.valorVenda
              valorTotalServicosComDesconto += item.comissaoVenda.venda.valorTotal
            } else if (item.comissaoVenda.venda.tipo.id === TipoVendaEnum.VENDA_PRODUTO.id) {
              valorTotalProdutosSemDesconto += item.comissaoVenda.valorVenda
              valorTotalProdutosComDesconto += item.comissaoVenda.venda.valorTotal
            }
          }
          valorTotalRemuneracoes += item.valorRemuneracaoCalculadoEfetivo
          valorTotalPendentePagamento += item.valorPendentePagamento
          valorTotalPago += item.valorPago

          if (item.formasPagamento && item.formasPagamento.length > 0) {
            for (let formaPagamento of item.formasPagamento) {
              let valor = mapFormasPagamentos.get(formaPagamento.formaPagamento.tipo.id)
              if (!valor) {
                valor = 0
              }
              mapFormasPagamentos.set(formaPagamento.formaPagamento.tipo.id, valor + formaPagamento.valor)
            }
          }

          quantidadeSelecionados++
        }
      }
    }

    mapFormasPagamentos = new Map([...mapFormasPagamentos.entries()].sort((a, b) => b[1] - a[1]))

    const formasPagamentoContent = []
    for (var [idTipo, valorTemp] of mapFormasPagamentos) {
      if (valorTemp > 0) {
        formasPagamentoContent.push(
          <Typography variant="body2" key={idTipo}>
            {FormaPagamentoTipoEnum.getById(idTipo).descricao}: {formatarValorMonetario(valorTemp)}
          </Typography>
        )
      }
    }

    valorPendentePagamentoDescontandoVales = valorTotalPendentePagamento - this.state.valorValesDisponiveis
    if (valorPendentePagamentoDescontandoVales < 0) {
      valorPendentePagamentoDescontandoVales = 0
    }

    const paginaCarregadaEComRemuneracoesParaPagamento = this.state.paginaCarregada && this.state.lista && this.state.lista.length > 0 ? true : false

    const toolbarActions = {
      actions: []
    }

    if (paginaCarregadaEComRemuneracoesParaPagamento) {
      toolbarActions.actions.push({
        label: 'Continuar',
        handleAction: () => this.abrirRegistrarPagamentoDialogPage()
      })
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} ajaxing={this.state.ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <ContentWithPreload loadFunction={this.carregarMain}>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h6" noWrap={true}>
                {colaborador.apelido}
              </Typography>
              <Typography variant="body1">
                <b>
                  {this.props.dataPesquisaInicial.format('DD/MM/YY')} a {this.props.dataPesquisaFinal.format('DD/MM/YY')}
                </b>
              </Typography>
              {paginaCarregadaEComRemuneracoesParaPagamento && <Typography variant="body2">Selecione as remunerações desejadas para registrar o pagamento:</Typography>}
            </FloatContent>

            <form id="formPagamentoComissao" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <RemuneracoesDetalhadasResponsiveTable
                lastLineBorder={true}
                scrollElement={dialogContentProps.scrollContainerRef.current}
                items={this.state.lista}
                emptyListProps={{
                  text: 'Nenhuma remuneração foi encontrada para pagamento neste período'
                }}
                onClickRow={(item, event, index) => {
                  this.selecionarItem(item)
                }}
                selecaoProps={{
                  selecionarTodosFunction: this.selecionarTodos,
                  selecionarTodosValue: this.state.selecionarTodos
                }}
              />

              {paginaCarregadaEComRemuneracoesParaPagamento && (
                <React.Fragment>
                  <br />
                  <FloatContent container="paper" context="DialogPage" type="tableSummary">
                    <Typography variant="body2">
                      <b>
                        Selecionados: {quantidadeSelecionados} de {this.state.lista.length}
                      </b>
                    </Typography>
                    {verificarAutorizacao([PA.PODE_ACESSAR_PRECO_VENDA_REMUNERACAO]) && (
                      <React.Fragment>
                        <Typography variant="body2">Serviços Sem Desconto: {formatarValorMonetario(valorTotalServicosSemDesconto)}</Typography>
                        <Typography variant="body2">Serviços Com Desconto: {formatarValorMonetario(valorTotalServicosComDesconto)}</Typography>
                        <Typography variant="body2">Produtos Sem Desconto: {formatarValorMonetario(valorTotalProdutosSemDesconto)}</Typography>
                        <Typography variant="body2">Produtos Com Desconto: {formatarValorMonetario(valorTotalProdutosComDesconto)}</Typography>
                      </React.Fragment>
                    )}
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="body2">Total Remunerações: {formatarValorMonetario(valorTotalRemuneracoes)}</Typography>
                    {formasPagamentoContent.length > 0 && (
                      <React.Fragment>
                        <Typography variant="body2">Por Forma de Pagamento:</Typography>
                        {formasPagamentoContent}
                      </React.Fragment>
                    )}
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="body2">Total Já Pago: {formatarValorMonetario(valorTotalPago)}</Typography>
                    <br />
                    <Divider />
                    {this.state.valorValesDisponiveis > 0 && (
                      <React.Fragment>
                        <br />
                        <Typography variant="body2">Vales Pendentes: {formatarValorMonetario(this.state.valorValesDisponiveis)}</Typography>
                        <br />
                        <Divider />
                      </React.Fragment>
                    )}
                    <br />
                    {this.state.valorValesDisponiveis > 0 && (
                      <React.Fragment>
                        <Typography variant="body2">
                          <b>Pendente para Pagamento:</b>
                        </Typography>
                        <Typography variant="body2">Valor Integral: {formatarValorMonetario(valorTotalPendentePagamento)}</Typography>
                        <Typography variant="body2">Descontando Vales: {formatarValorMonetario(valorPendentePagamentoDescontandoVales)}</Typography>
                      </React.Fragment>
                    )}
                    {!this.state.valorValesDisponiveis && (
                      <Typography variant="body2">
                        <b>Pendente Pagamento: {formatarValorMonetario(valorTotalPendentePagamento)}</b>
                      </Typography>
                    )}
                  </FloatContent>
                  <div className={classes.containerFooterButtons}>
                    <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                      Voltar
                    </Button>
                    <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.abrirRegistrarPagamentoDialogPage}>
                      {this.state.ajaxing ? 'Aguarde' : 'Continuar'}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </form>
          </ContentWithPreload>
        )}
      </DialogPage>
    )
  }
}

SelecionarRemuneracoesPagamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SelecionarRemuneracoesPagamentoDialogPage)
