import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListagemNegociosModoGrid from 'pages/portal/negocio/ListagemNegociosModoGrid'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PortalApiClient'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import PanelPage from 'support/components/panel/PanelPage'

const styles = (theme) => ({})

class FavoritosPanelPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.contentWithPreloadFunctions = {}
    this.state = {
      negociosPreparadosBusca: []
    }
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'ptl/negocios.buscarNegociosFavoritos',
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          negociosPreparadosBusca: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoNegociosFavoritos', (props) => {
      this.contentWithPreloadFunctions.reload()
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { negociosPreparadosBusca } = this.state

    return (
      <PanelPage {...others} contentMaxWidth={1240} title="Favoritos">
        <ContentWithPreload functionsMap={this.contentWithPreloadFunctions} loadFunction={this.carregarMain}>
          {() => {
            if (negociosPreparadosBusca.length > 0) {
              return <ListagemNegociosModoGrid negociosPreparadosBusca={negociosPreparadosBusca} />
            } else {
              return (
                <Typography variant="body1" noWrap={true}>
                  Você ainda não adicionou nenhum estabelecimento como favorito.
                </Typography>
              )
            }
          }}
        </ContentWithPreload>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

FavoritosPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FavoritosPanelPage)
