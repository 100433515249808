import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 122,
    minWidth: 122,
    [theme.breakpoints.down(740)]: {
      maxWidth: 100,
      minWidth: 100
    }
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  descricaoContainerSmall: {
    ...theme.text.limitedLines({ maxLines: 2 }),
    marginTop: 2
  }
})

class DetalhesValesColaboradorDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      ajaxing: false
    }
  }

  getRequestParameters = () => {
    const dataInicial = converterMomentParaDataInt(this.props.dataInicial)
    const dataFinal = converterMomentParaDataInt(this.props.dataFinal)
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      idColaborador: this.props.colaborador.id
    }
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.data = converterDataIntParaMoment(item.data).format('DD/MMM/YYYY')
    content.descricao = item.nome
    content.valor = formatarValorMonetario(item.valor, { prefix: false })

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, dataInicial, dataFinal } = this.props
    const { ajaxing } = this.state

    const descricaoFiltroPeriodo = dataInicial.format('DD/MM/YYYY') + ' - ' + dataFinal.format('DD/MM/YYYY')

    return (
      <DialogPage {...dialogProps} align="center" title={this.props.colaborador.apelido} ajaxing={ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">Lista de Vales</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <VirtualizedResponsiveTable
              showBackgroundDividers={true}
              itemsPerRequest={20}
              loaderFunctionsMap={this.loaderFunctionsMap}
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              contextoUsuario="erp"
              endpoint="erp/colaboradores.buscarValesPorColaborador"
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Data', className: classes.colunaData },
                  { label: 'Descricao', horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.data, content.descricao, content.valor]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 100,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center">
                          <Grid item xs style={{ marginRight: 4 }}>
                            <Typography variant="body2">
                              <b>{content.data}</b>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <b>R$ {content.valor}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography variant="body2" className={classes.descricaoContainerSmall}>
                          {content.descricao}
                        </Typography>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhum vale foi encontrado.'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

DetalhesValesColaboradorDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesValesColaboradorDialogPage)
