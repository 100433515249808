import moment from 'moment'

export function converterHorarioIntParaMinutos(horarioInt) {
  const horas = Math.floor(horarioInt / 100)
  const restoMinutos = horarioInt % 100
  return horas * 60 + restoMinutos
}

export function converterMinutosParaHorarioInt(minutos) {
  const horas = parseInt(minutos / 60, 10)
  const restoMinutos = minutos % 60
  return horas * 100 + restoMinutos
}

export function converterHorarioIntParaDate(horarioInt) {
  if (horarioInt === undefined || horarioInt === null) {
    return null
  }
  const date = new Date()
  date.setHours(parseInt(horarioInt / 100, 10), parseInt(horarioInt % 100, 10))
  return date
}

export function converterDateParaHorarioInt(date) {
  if (date === undefined || date === null) {
    return null
  }
  return parseInt(date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0'), 10)
}

export function converterMomentParaHorarioInt(moment) {
  if (moment === undefined || moment === null) {
    return null
  }
  return parseInt(moment.format('HHmm'), 10)
}

export function converterStringHHMMParaDate(string) {
  const horario = moment(string, 'HH:mm', true)
  if (horario.isValid()) {
    return horario.toDate()
  } else {
    return null
  }
}
