import { darken, lighten } from '@material-ui/core/styles/colorManipulator'

const colors = [
  createColor(1, '#39bffb', '#a8e4ff', '#38c0ff'),
  createColor(2, '#5e97ff', '#b7d3f9', '#65a5ff'),
  createColor(3, '#845eff', '#d9ccff', '#aa8ffe'),
  createColor(4, '#cc5eff', '#f2d5ff', '#d881ff'),
  createColor(5, '#ff5eee', '#ffcbfa', '#f991ef'),
  createColor(6, '#7e9ad8', '#d0dbf3', '#83a3ea'),
  createColor(7, '#4bedcd', '#a3f1e1', '#16dcb3'),
  createColor(8, '#70ef88', '#b2f5bf', '#4fde6b'),
  createColor(9, '#acec50', '#d8f5ae', '#97dc34'),
  createColor(10, '#d4ec50', '#e2f194', '#b8da08'),
  createColor(11, '#ecea50', '#f7f6b2', '#f3e53e'),
  createColor(12, '#81ab8a', '#d2e6d7', '#66cc7e'),
  createColor(13, '#ffef67', '#f5ecac', '#f5d813'),
  createColor(14, '#ffc444', '#ffe2a3', '#ffc342'),
  createColor(15, '#ff9800', '#ffdead', '#ffbd5b'),
  createColor(16, '#fd7144', '#ffd8cb', '#ff9a78'),
  createColor(17, '#ff6459', '#ffd2cf', '#ff867c'),
  createColor(18, '#ae896e', '#ecdcd1', '#d6976c')
]

function createColor(id, hex, cardBackground, cardLinhaLateral) {
  const cardBackgroundDarken = darken(cardBackground, 0.01)

  return {
    id: id,
    hex: hex,
    light: lighten(hex, 0.35),
    cardBackground: cardBackground,
    cardLinhaLateralBackground: {
      principalProps: {
        backgroundColor: cardLinhaLateral
      },
      assistenteProps: {
        backgroundColor: cardLinhaLateral,
        backgroundImage:
          'linear-gradient(45deg,' +
          cardLinhaLateral +
          ' 10%, ' +
          cardBackgroundDarken +
          ' 10%, ' +
          cardBackgroundDarken +
          ' 50%, ' +
          cardLinhaLateral +
          ' 50%, ' +
          cardLinhaLateral +
          ' 60%, ' +
          cardBackgroundDarken +
          ' 60%, ' +
          cardBackgroundDarken +
          ' 100%)',
        backgroundSize: '7.07px 7.07px',
        backgroundPositionY: '-2px'
      }
    },
    cardIcones: darken(cardLinhaLateral, 0.2),
    cardTexto: darken(cardLinhaLateral, 0.8)
  }
}

const colorsById = (function () {
  const colorsObj = {}
  for (let color of colors) {
    colorsObj[color.id] = color
  }
  return colorsObj
})()

const getDefaultColor = () => {
  return colors[1]
}

export default {
  getColors: () => colors,
  getDefaultColor: getDefaultColor,
  getHexColors: () => {
    const hexColors = []
    for (let color of colors) {
      hexColors.push(color.hex)
    }
    return hexColors
  },
  getColorById: (id) => {
    if (id) {
      let color = colorsById[id.toString()]
      if (color) {
        return colorsById[id.toString()]
      }
    }
    return getDefaultColor()
  },
  getColorByHex: (hex) => {
    if (hex) {
      for (let color of colors) {
        if (color.hex === hex) {
          return color
        }
      }
    }
    return getDefaultColor()
  }
}
