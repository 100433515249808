import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SettingsIcon from '@material-ui/icons/Settings'
import AdquirentesDialogPage from 'pages/erp/painel/formapagamento/AdquirentesDialogPage'
import BandeirasDialogPage from 'pages/erp/painel/formapagamento/BandeirasDialogPage'
import FormaPagamentoConfiguracaoCreditoDialogPage from 'pages/erp/painel/formapagamento/FormaPagamentoConfiguracaoCreditoDialogPage'
import FormaPagamentoConfiguracaoDebitoDialogPage from 'pages/erp/painel/formapagamento/FormaPagamentoConfiguracaoDebitoDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  },
  colunaPreco: {
    minWidth: 120,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  selectableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7f7f7'
    }
  }
})

class FormaPagamentoCartaoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}
    this.state = {
      lista: [],
      itemMenu: null,
      formaPagamentoMenuAnchorEl: null
    }
  }

  componentDidMount() {
    this.eventsManager.sub('ManutencaoFormaPagamentoCartao', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerOpenCreateDialog = (props) => {
    if (this.props.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id) {
      openBackableDialog(FormaPagamentoConfiguracaoCreditoDialogPage, {
        formaPagamento: this.props.formaPagamento
      })
    } else if (this.props.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id) {
      openBackableDialog(FormaPagamentoConfiguracaoDebitoDialogPage, {
        formaPagamento: this.props.formaPagamento
      })
    }
  }

  openMenuConfiguracoes = (event) => {
    this.setState({ criarItemMenuConfigAnchorEl: event.currentTarget })
  }

  handlerEditar = (item) => {
    if (this.props.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id) {
      openBackableDialog(FormaPagamentoConfiguracaoCreditoDialogPage, {
        formaPagamento: this.props.formaPagamento,
        idConfiguracaoCartao: item.id
      })
    } else if (this.props.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id) {
      openBackableDialog(FormaPagamentoConfiguracaoDebitoDialogPage, {
        formaPagamento: this.props.formaPagamento,
        idConfiguracaoCartao: item.id
      })
    }
  }

  handlerDuplicar = (item) => {
    if (this.props.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id) {
      openBackableDialog(FormaPagamentoConfiguracaoCreditoDialogPage, {
        formaPagamento: this.props.formaPagamento,
        idConfiguracaoCartao: item.id,
        duplicar: true
      })
    } else if (this.props.formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id) {
      openBackableDialog(FormaPagamentoConfiguracaoDebitoDialogPage, {
        formaPagamento: this.props.formaPagamento,
        idConfiguracaoCartao: item.id,
        duplicar: true
      })
    }
  }

  closeMenu = (key) => {
    this.setState({ [key]: null })
  }

  handlerRemover = (item) => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover Configuração',
      text: <span>Você tem certeza que deseja remover esta configuração? Essa operação não poderá ser desfeita.</span>,
      call: {
        method: 'post',
        url: 'erp/formaspagamento.removerConfiguracaoCartao',
        contextoUsuario: 'erp',
        data: {
          idConfiguracaoCartao: item.id
        },
        onSuccess: () => {
          EventsManager.pub('ManutencaoFormaPagamentoCartao')
        }
      }
    })
  }

  getRenderContent = (size, formaPagamento) => {
    const content = {}
    content.nome = (
      <span>
        {formaPagamento.nomeAdquirente} - {formaPagamento.nomeBandeira}
      </span>
    )

    content.botaoMaisOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -16 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ formaPagamentoMenuAnchorEl: event.currentTarget, itemMenu: formaPagamento })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )
    return content
  }

  abrirAdquirentesDialog = () => {
    openBackableDialog(AdquirentesDialogPage)
  }

  abrirBandeirasDialog = () => {
    openBackableDialog(BandeirasDialogPage)
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { formaPagamentoMenuAnchorEl, criarItemMenuConfigAnchorEl } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={this.props.formaPagamento.nome}
        align="center"
        pageType="basicEntity"
        contentMaxWidth={600}
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              icon: <AddIcon />,
              handleAction: this.handlerOpenCreateDialog
            },
            {
              icon: <SettingsIcon />,
              handleAction: this.openMenuConfiguracoes
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/formaspagamento.buscarConfiguracoesCartao"
              getRequestParametersFunction={() => ({
                idTipoFormaPagamento: this.props.formaPagamento.tipo.id
              })}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const tabelaPlana = []

                  for (let item of data.items) {
                    const configuracao = {
                      id: item.id,
                      nomeAdquirente: item.formaPagamentoAdquirenteBandeira.formaPagamentoAdquirente.adquirente.nome,
                      nomeBandeira: item.formaPagamentoAdquirenteBandeira.bandeira.nome
                    }

                    tabelaPlana.push(configuracao)
                  }

                  tabelaPlana.sort(function (a, b) {
                    if (a.nomeAdquirente < b.nomeAdquirente) return -1
                    if (a.nomeAdquirente > b.nomeAdquirente) return 1
                    if (a.nomeBandeira < b.nomeBandeira) return -1
                    if (a.nomeBandeira > b.nomeBandeira) return 1
                    return 0
                  })

                  this.setState({ lista: tabelaPlana })
                }
              }}
              onClickRow={(item, event) => {
                this.handlerEditar(item)
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Configurações', props: { xs: true } },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.nome, content.botaoMaisOpcoes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 80,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography variant="body2">{content.nome}</Typography>
                          </Grid>
                          <Grid item>{content.botaoMaisOpcoes}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhuma configuração foi encontrada',
                textButton: 'Cadastrar configuração',
                creationFunction: this.handlerOpenCreateDialog
              }}
            />

            <Menu anchorEl={criarItemMenuConfigAnchorEl} open={Boolean(criarItemMenuConfigAnchorEl)} onClose={() => this.closeMenu('criarItemMenuConfigAnchorEl')}>
              <MenuItem
                onClick={() => {
                  this.abrirAdquirentesDialog()
                  this.closeMenu('criarItemMenuConfigAnchorEl')
                }}
              >
                Adquirentes
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.abrirBandeirasDialog()
                  this.closeMenu('criarItemMenuConfigAnchorEl')
                }}
              >
                Bandeiras
              </MenuItem>
            </Menu>

            <Menu anchorEl={formaPagamentoMenuAnchorEl} open={Boolean(formaPagamentoMenuAnchorEl)} onClose={() => this.closeMenu('formaPagamentoMenuAnchorEl')}>
              <MenuItem
                onClick={() => {
                  this.handlerEditar(this.state.itemMenu)
                  this.closeMenu('formaPagamentoMenuAnchorEl')
                }}
              >
                Editar
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handlerDuplicar(this.state.itemMenu)
                  this.closeMenu('formaPagamentoMenuAnchorEl')
                }}
              >
                Duplicar
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handlerRemover(this.state.itemMenu)
                  this.closeMenu('formaPagamentoMenuAnchorEl')
                }}
              >
                Remover
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

FormaPagamentoCartaoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FormaPagamentoCartaoDialogPage)
