import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import CategoryIcon from '@material-ui/icons/Category'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EntityDuplicationDialog from 'support/components/dialog/preconstructed/EntityDuplicationDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputColorSelect from 'support/components/input/InputColorSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class CategoriaServicoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      categoria: null,
      title: 'Categoria',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
    this.inputCor = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idCategoriaServico) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'erp/servicos.buscarCategoriaPorId',
        params: {
          id: this.props.idCategoriaServico
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState((state) => ({
            categoria: response.data
          }))
          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = (existe) => {
    this.setState({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    })
  }

  getCategoriasDuplicados = (categoriasDuplicadas) => {
    const categoriasDuplicadasFormatadas = []

    categoriasDuplicadas.forEach((categoriaDuplicada, index) => {
      categoriasDuplicadasFormatadas.push({
        id: categoriaDuplicada.id,
        descricao: categoriaDuplicada.nome,
        icone: <CategoryIcon />
      })
    })

    return categoriasDuplicadasFormatadas
  }

  handleSubmit(event) {
    event.preventDefault()
    this.salvar()
  }

  getDadosCategoria = () => {
    return {
      id: this.props.idCategoriaServico,
      nome: this.inputNome.current.value,
      cor: this.inputCor.current.value.id
    }
  }

  salvar = () => {
    this.persistirCategoria()
  }

  persistirCategoria = (ignorarCategoriasDuplicadas) => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = this.getDadosCategoria()
    dados.ignorarDuplicados = ignorarCategoriasDuplicadas

    postAPI({
      url: 'erp/servicos.persistirCategoria',
      data: dados,
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.props.handleCloseDialog({
          onClosedCallback: () => {
            EventsManager.pub('ManipulacaoCategoriaServico', {
              categorias: response.data.categorias,
              categoria: response.data.categoria
            })
          }
        })
      },
      onError: (response) => {
        if (response.code === 400 && response.data.errors.categoriasDuplicadas) {
          openBackableDialog(EntityDuplicationDialog, {
            title: 'Categoria Duplicada',
            descricao: 'O sistema encontrou uma ou mais categorias que podem ser duplicadas:',
            conteudo: this.getCategoriasDuplicados(response.data.errors.categoriasDuplicadas),
            onConfirm: () => {
              this.persistirCategoria(true)
            }
          })
          this.setState({ ajaxing: false, errorsMap: createErrorsMap() })
        } else {
          this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
          focusFirstElementWithError('formCategoriaServico')
        }
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { categoria, errorsMap } = this.state

    let nomeDefaultValue = this.props.nome
    if (categoria && categoria.nome) {
      nomeDefaultValue = categoria.nome
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formCategoriaServico" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xnome"
                defaultValue={nomeDefaultValue}
                customVariant="outlined-small"
                shrink={false}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('nome')}
                label="Nome da categoria"
                inputRef={this.inputNome}
                endAdornment={<InputColorSelect style={{ marginRight: 8 }} defaultValue={this.state.categoria ? categoria.cor : ''} inputRef={this.inputCor} />}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

CategoriaServicoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CategoriaServicoDialogPage)
