import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import BaseDialog from 'support/components/dialog/BaseDialog'
import InputText from 'support/components/input/InputText'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({})

class EnviarNotaParaClienteDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ajaxing: false,
      possuiEmailCadastrado: false,
      errorsMap: createErrorsMap(),
      step: 0
    }
    this.inputEmail = React.createRef()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.enviar()
  }

  enviar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/vendas.enviarNotaParaCliente',
      data: {
        idNota: this.props.nota.id,
        idCliente: this.props.nota.cliente.id,
        email: this.inputEmail.current ? this.inputEmail.current.value : null
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          step: 2
        })
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formEnvioEmail')
      }
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'erp/clientes.verificarPossuiEmailCadastrado',
      params: {
        idCliente: this.props.nota.cliente.id
      },
      requerAutorizacao: true,
      onSuccess: (response) => {
        this.setState({
          possuiEmailCadastrado: response.data,
          step: 1
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  render() {
    const { step, errorsMap } = this.state
    const { classes, nota, ...others } = this.props

    let actions = null
    if (step === 1) {
      actions = (
        <React.Fragment>
          {!this.state.ajaxing && (
            <Button onClick={this.props.handleCloseDialog} color="primary" disabled={this.state.ajaxing}>
              CANCELAR
            </Button>
          )}
          <Button onClick={this.enviar} color="primary" autoFocus disabled={this.state.ajaxing}>
            {this.state.ajaxing ? (
              <span>
                <CircularProgress size={18} thickness={5.5} style={{ verticalAlign: 'middle', marginRight: 8 }} /> AGUARDE...
              </span>
            ) : (
              'Enviar Agora'
            )}
          </Button>
        </React.Fragment>
      )
    } else if (step === 2) {
      actions = (
        <React.Fragment>
          <Button onClick={this.props.handleCloseDialog} color="primary">
            OK
          </Button>
        </React.Fragment>
      )
    }

    return (
      <BaseDialog disabledOnClose={this.state.ajaxing} actions={actions} {...others} title={step === 2 ? 'Fatura enviada com sucesso!' : null}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => {
            const { possuiEmailCadastrado } = this.state

            return (
              <React.Fragment>
                {step === 1 && (
                  <React.Fragment>
                    <DialogContentText>
                      O sistema enviará esta fatura em PDF por e-mail para o cliente <b>{nota.cliente.apelido}</b>.{possuiEmailCadastrado && ' Deseja realmente enviar o e-mail?'}
                      {!possuiEmailCadastrado && ' O e-mail deste cliente não foi preenchido durante o cadastro e agora será necessário informá-lo:'}
                    </DialogContentText>
                    {!possuiEmailCadastrado && (
                      <form id="formEnvioEmail" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
                        <InputText
                          id="xemail"
                          customVariant="outlined-small"
                          shrink={false}
                          disabled={this.state.ajaxing}
                          errorMessage={errorsMap('email')}
                          label={'E-mail do Cliente'}
                          inputRef={this.inputEmail}
                        />
                        <HiddenSubmitButton />
                      </form>
                    )}
                  </React.Fragment>
                )}
                {step === 2 && (
                  <React.Fragment>
                    <DialogContentText>
                      Caso o cliente não encontre o e-mail na caixa de entrada, certifique-se de orientá-lo a procurar nas pastas{' '}
                      <b>Spam, Lixeira, Lixo Eletrônico, Itens Apagados ou Arquivo</b>.
                    </DialogContentText>
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          }}
        </ContentWithPreload>
      </BaseDialog>
    )
  }
}

EnviarNotaParaClienteDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EnviarNotaParaClienteDialog)
