import { Component } from 'react'

class PassiveDynamicContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null
    }
  }

  componentDidMount = () => {
    const { fistUpdateOnMount, functionsMap } = this.props
    if (functionsMap) {
      functionsMap['update'] = () => {
        this.handleUpdate()
      }
    }
    if (fistUpdateOnMount) {
      this.handleUpdate()
    }
  }

  handleUpdate = () => {
    const data = this.props.updateFunction()
    if (data.content === null || data.content !== this.content) {
      this.content = data.content
      this.setState({ content: data.content })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const state = this.state
    state.content = nextProps.updateFunction().content
    return true
  }

  render() {
    return this.state.content
  }
}

export default PassiveDynamicContent
