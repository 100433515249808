import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import AdminHelmet from 'pages/admin/AdminHelmet'
import DrawerAdmin from 'pages/admin/painel/DrawerAdmin'
import RoutesAdmin from 'pages/admin/painel/RoutesAdmin'
import RegisterPhoneDialogPage from 'pages/conta/RegisterPhoneDialogPage/RegisterPhoneDialogPage'
import { ROUTE_ADMIN_PANEL, ROUTE_ADMIN_PANEL_REGEX, ROUTE_CONTA_MAIN } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI, patchAPI } from 'support/components/api/PainelAdminApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import Panel from 'support/components/panel/Panel'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import PermissoesAcesso from 'support/domain/admin/usuario/PermissoesAcesso'
import { logCORE } from 'support/util/Logger'
import UrlUtil from 'support/util/UrlUtil'

let INSTANCE = {}

export function verificarAutorizacao() {
  return INSTANCE.verificarAutorizacao.apply(null, arguments)
}

export function verificarAutorizacaoPorRota() {
  return INSTANCE.verificarAutorizacaoPorRota.apply(null, arguments)
}

export function getSessaoPainel() {
  if (INSTANCE.state) {
    return {
      usuario: INSTANCE.state.usuarioSessao
    }
  } else {
    return {}
  }
}

const styles = () => ({})

class PainelAdmin extends Component {
  constructor(props) {
    super(props)
    INSTANCE = this
    this.eventsManager = EventsManager.new()
    this.state = {
      usuarioSessao: this.prepararDadosSessao(null),
      usuarioAdminSessao: this.prepararDadosSessao(null)
    }
    this.panelFunctionMap = {}
  }

  buscarDadosSessao = (opts) => {
    getAPI({
      url: 'cda/usuarios.buscarDadosSessao',
      onSuccess: (response) => {
        opts.onSuccess(response)
      },
      onError: (response) => {
        opts.onError(response)
      },
      contextoUsuario: 'admin',
      requerAutorizacao: true,
      sendErroToGenericSnackbar: opts.sendErroToGenericSnackbar
    })
  }

  carregarPainel = () => {
    this.setState(() => ({ paginaCarregando: true }))
    this.buscarDadosSessao({
      onSuccess: (response) => {
        if (!response.data.usuario.telefone && !DeviceIdentifier.isNativeApp()) {
          openBackableDialog(RegisterPhoneDialogPage, { name: response.data.usuario.nome })
        }

        this.registrarDadosSessao({
          usuario: response.data.usuario !== undefined ? response.data.usuario : null,
          usuarioAdmin: response.data.usuarioAdmin !== undefined ? response.data.usuarioAdmin : null
        })
        let rota = undefined
        if (window.location.pathname.match(new RegExp('.*' + ROUTE_ADMIN_PANEL_REGEX + '(\\/p){0,1}\\/{0,1}$', 'gi'))) {
          rota = ROUTE_ADMIN_PANEL + '/p/suporte/chat'
        }
        this.panelFunctionMap.notificarPainelCarregado({ rota: rota })
      },
      onError: (response) => {
        if (response.code === -1) {
          this.panelFunctionMap.notificarErroCarregamentoPainel({ mensagem: 'Falha de conexão com a internet' })
        } else if (response.code === 400 || response.code === 401) {
          this.logout()
        } else if (response.code === 403) {
          this.registrarDadosSessao({
            usuario: response.data.body
          })
          this.panelFunctionMap.notificarPainelCarregado({ rota: ROUTE_ADMIN_PANEL + '/p/suporte/chat' })
        } else {
          this.panelFunctionMap.notificarErroCarregamentoPainel({ mensagem: 'Ocorreu um erro inesperado! Tente novamente em alguns instantes.' })
        }
      },
      sendErroToGenericSnackbar: false
    })
  }

  atualizarSessao = (props) => {
    this.buscarDadosSessao({
      onSuccess: (response) => {
        this.registrarDadosSessao({
          usuario: response.data.usuario !== undefined ? response.data.usuario : null
        })
        props.onSuccess(response)
      },
      onError: (response) => {
        if (response.code === 400 || response.code === 401) {
          this.logout()
          return
        }
        props.onError(response)
      },
      sendErroToGenericSnackbar: false
    })
  }

  limparSessao = (props) => {
    if (!props) {
      props = {}
    }
    const obj = {}
    if (props.usuario === true) {
      obj.usuario = null
    }
    this.registrarDadosSessao(obj)
  }

  registrarDadosSessao = (opts) => {
    if (opts.usuario !== undefined) {
      this.setState({ usuarioSessao: this.prepararDadosSessao(opts.usuario) })
    }
    if (opts.usuarioAdmin !== undefined) {
      this.setState({ usuarioAdminSessao: this.prepararDadosSessao(opts.usuarioAdmin) })
    }
  }

  prepararDadosSessao = (entidade) => {
    if (entidade === null) {
      entidade = {
        carregado: false
      }
    } else {
      entidade['carregado'] = true
    }
    return entidade
  }

  logout = () => {
    patchAPI({
      url: 'cda/usuarios.logout',
      onSuccess: () => {
        logCORE('Logout', { component: 'PainelAdmin', context: 'Logout - success' })
      },
      onError: () => {
        logCORE('Logout', { component: 'PainelAdmin', context: 'Logout - error' })
      },
      requerAutorizacao: true
    })

    SessaoUsuario.limparSessao()
    this.panelFunctionMap.alterarRota(ROUTE_CONTA_MAIN + '/login?continuacao=' + encodeURIComponent(UrlUtil.getFullUrlFromRoute(ROUTE_ADMIN_PANEL)))
  }

  navegarPaginaPainel = (props) => {
    this.panelFunctionMap.alterarRota(ROUTE_ADMIN_PANEL + '/p' + props.rota)
  }

  verificarAutorizacao = (permissoesRequeridas, and) => {
    if (this.state.usuarioAdminSessao.carregado === false) {
      return false
    }
    return PermissoesAcesso.verificarAutorizacao(permissoesRequeridas, this.state.usuarioAdminSessao.permissoes, and)
  }

  verificarAutorizacaoPorRota = (rota) => {
    return PermissoesAcesso.verificarAutorizacaoPorRota(rota, this.state.usuarioAdminSessao.permissoes)
  }

  render() {
    if (this.state.usuarioSessao.carregado === true && this.state.usuarioSessao.verificado === false) {
      this.alterarRota(ROUTE_CONTA_MAIN + '/conta-pendente-verificacao/' + encodeURIComponent(this.state.usuarioSessao.email))
      return []
    }

    const renderDrawerAndPagesProps = {
      hasMenu: true,
      usuarioSessao: this.state.usuarioSessao,
      usuarioAdminSessao: this.state.usuarioAdminSessao
    }

    return (
      <React.Fragment>
        <AdminHelmet />
        <Panel
          {...this.props}
          functionsMap={this.panelFunctionMap}
          carregarPainel={this.carregarPainel}
          atualizarSessao={this.atualizarSessao}
          limparSessao={this.limparSessao}
          handleLogout={this.logout}
          navegarPaginaPainel={this.navegarPaginaPainel}
          renderDrawer={(props) => <DrawerAdmin {...props} {...renderDrawerAndPagesProps} />}
          renderPages={(props) => <RoutesAdmin {...props} {...renderDrawerAndPagesProps} />}
          regexFullScreenPages={/\/p\/(suporte\/chat)/}
        />
      </React.Fragment>
    )
  }
}

PainelAdmin.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PainelAdmin)
