import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import classNames from 'classnames'
import moment from 'moment'
import AvaliacaoDialogPage from 'pages/erp/painel/agendamentoonline/AvaliacaoDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import DialogPage from 'support/components/page/DialogPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'

const styles = (theme) => ({
  containerEstrelaPintada: {
    '& svg': {
      color: '#ffc55b',
      fontSize: 18,
      marginLeft: -1,
      marginRight: -1
    }
  },
  containerEstrelaApagada: {
    '& svg': {
      color: '#e6e6e6',
      fontSize: 18,
      marginLeft: -1,
      marginRight: -1
    }
  },
  containerLargeRender: {
    paddingTop: 14,
    paddingBottom: 16
  },
  colunaRespota: {
    alignSelf: 'start'
  }
})

class AvaliacoesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      ajaxing: false
    }

    this.virtualizedListFunctionMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoAvaliacao', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  getRenderContent = (size, avaliacao) => {
    const { classes } = this.props
    const content = {}

    const nomeClienteContent = (
      <Typography variant="body2">
        <b>{avaliacao.cliente.nome}</b>
      </Typography>
    )
    const dataContent = (
      <Typography variant="body2" style={{ fontSize: 14 }}>
        {moment(avaliacao.data).format(size === 'small' ? 'DD/MMM/YYYY' : 'DD [de] MMMM [de] YYYY')}
      </Typography>
    )
    const notaContent = (
      <div style={{ position: 'relative', height: 16, width: 79, marginTop: 2, display: 'inline-flex' }}>
        <div className={classNames(classes.containerEstrelaApagada)} style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap' }}>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </div>
        <div
          className={classNames(classes.containerEstrelaPintada)}
          style={{ position: 'absolute', top: 0, whiteSpace: 'nowrap', width: avaliacao.nota * 20 + 1 + '%', overflow: 'hidden' }}
        >
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </div>
      </div>
    )
    let comentarioContent = null
    if (avaliacao.comentario) {
      comentarioContent = (
        <Typography variant="body2" style={{ padding: '4px 0px 0px 0px', fontSize: size === 'small' ? 13 : 14 }}>
          {avaliacao.comentario.charAt(0).toUpperCase() + avaliacao.comentario.slice(1)}
        </Typography>
      )
    }

    if (size === 'small') {
      content.avaliacao = (
        <React.Fragment>
          <Grid container>
            <Grid item xs style={{ marginRight: 8 }}>
              {nomeClienteContent}
              {notaContent}
            </Grid>
            <Grid item>{dataContent}</Grid>
          </Grid>
          {comentarioContent}
        </React.Fragment>
      )
    } else {
      content.avaliacao = (
        <React.Fragment>
          {nomeClienteContent}
          {dataContent}
          {notaContent}
          {comentarioContent}
        </React.Fragment>
      )
    }

    content.resposta = (
      <div>
        {avaliacao.resposta && (
          <Typography variant="body2" style={{ color: '#666', fontSize: size === 'small' ? 13 : 14, background: '#f5f5f5', padding: 12, borderRadius: 6, marginBottom: 8 }}>
            {avaliacao.resposta.charAt(0).toUpperCase() + avaliacao.resposta.slice(1)}
          </Typography>
        )}
        <Button
          variant="outlined"
          size="small"
          color="primary"
          style={{ marginLeft: 0, textTransform: 'none', marginTop: 4 }}
          onClick={() => {
            openBackableDialog(AvaliacaoDialogPage, {
              idAvaliacao: avaliacao.id
            })
          }}
        >
          {avaliacao.resposta ? 'Editar Resposta' : 'Responder'}
        </Button>
      </div>
    )
    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage {...dialogProps} title="Avaliações" align="center" contentMaxWidth={920} ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <br />

            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              itemsPerRequest={20}
              dynamicHeight={true}
              loadingIndicatorRowHeight={200}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/negocios.buscarAvaliacoes"
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Avaliação', props: { xs: true } },
                  { label: 'Resposta', props: { xs: true }, className: classes.colunaRespota }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [<div className={classes.containerLargeRender}>{content.avaliacao}</div>, <div className={classes.containerLargeRender}>{content.resposta}</div>]
                  }
                }
              }}
              smallRenderProps={{
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <div className={classes.containerLargeRender}>
                        <div style={{ marginBottom: 6 }}>{content.avaliacao}</div>
                        {content.resposta}
                      </div>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhuma avaliação foi encontrado'
              }}
              listFunctionsMap={this.virtualizedListFunctionMap}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.virtualizedListFunctionMap && this.virtualizedListFunctionMap.recomputeRowHeights) {
      this.virtualizedListFunctionMap.recomputeRowHeights(this.state.lista.length - 1)
    }
  }
}

AvaliacoesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AvaliacoesDialogPage)
