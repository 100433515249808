import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import PlanoEnum from 'support/domain/negocio/PlanoEnum'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'
import FormUtil from 'support/util/FormUtil'
import { InputNumberFormat } from 'support/util/Masks'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class PlanoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Plano',
      errorsMap: createErrorsMap(),
      ajaxing: false,
      alterarPrecoPlano: false
    }

    this.inputDiasPos = React.createRef()
    this.inputObservacao = React.createRef()
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/negocios.buscarPlano',
      params: {
        idNegocio: this.props.negocio.id
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        const negocio = response.data.negocio

        this.setState({
          negocio: negocio,
          plano: negocio.plano,
          dataSistemaAtivo: negocio.dataSistemaAtivo,
          dataVencimentoPlano: negocio.dataVencimentoPlano,
          diasDisponibilizadosPospago: negocio.diasDisponibilizadosPospago
        })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getDados = () => {
    const dados = {
      plano: { id: this.state.plano.id },
      dataSistemaAtivoPosterior: this.state.dataSistemaAtivo,
      dataVencimentoPlano: this.state.dataVencimentoPlano,
      idNegocio: this.props.negocio.id,
      quantidadeDiasPos: FormUtil.convertStringToNumber(this.inputDiasPos.current.value),
      observacao: this.inputObservacao.current.value
    }

    return dados
  }

  salvar = () => {
    postAPI({
      url: 'admin/negocios.alterarPlano',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
        EventsManager.pub('ManipulacaoNegocio')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formPlano')
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, ajaxing, negocio, diasPospago, dataVencimentoPlano, dataSistemaAtivo } = this.state
    let diasDisponibilizadosPospago = this.state.diasDisponibilizadosPospago

    if (diasPospago !== undefined && diasPospago !== null) {
      diasDisponibilizadosPospago = diasPospago
    }

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" pageType="basicForm" contentMaxWidth={580} toolbarActions={this.state.toolbarActions}>
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formPlano" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Grid container direction="column">
                <Grid item>
                  <InputSelect
                    style={{ marginTop: 6, marginBottom: 2 }}
                    fullWidth={false}
                    label="Plano Contratado"
                    customVariant="outlined-small"
                    shrink={false}
                    errorMessage={errorsMap('plano')}
                    value={this.state.plano.id}
                    onChange={(event) => {
                      const id = event.target.value
                      const hoje = moment().startOf('day')
                      let dataVencimentoPlano = converterDataIntParaMoment(negocio.dataVencimentoPlano).startOf('day')
                      const diferencaDatas = dataVencimentoPlano.diff(hoje, 'days')
                      let dataSistemaAtivo = converterDataIntParaMoment(negocio.dataSistemaAtivo).startOf('day')
                      let diasPospago = negocio.diasDisponibilizadosPospago

                      if (diferencaDatas > 1) {
                        if (negocio.plano.id === PlanoEnum.INDIVIDUAL.id && id === PlanoEnum.EMPRESA.id) {
                          dataVencimentoPlano.subtract(0.5 * diferencaDatas, 'days')
                          dataSistemaAtivo.subtract(0.5 * diferencaDatas, 'days')
                        } else if (negocio.plano.id === PlanoEnum.EMPRESA.id && id === PlanoEnum.INDIVIDUAL.id) {
                          dataVencimentoPlano.add(diferencaDatas, 'days')
                          dataSistemaAtivo.add(diferencaDatas, 'days')
                        }

                        diasPospago = dataSistemaAtivo.diff(dataVencimentoPlano, 'days')
                      }

                      this.setState({
                        plano: PlanoEnum.getById(id),
                        dataVencimentoPlano: converterMomentParaDataInt(dataVencimentoPlano),
                        dataSistemaAtivo: converterMomentParaDataInt(dataSistemaAtivo),
                        diasPospago: diasPospago
                      })
                    }}
                  >
                    {PlanoEnum.values().map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    ))}
                  </InputSelect>
                </Grid>
                <Grid item xs>
                  <InputData
                    key={dataVencimentoPlano}
                    functionsMap={this.dataFunctionsMap}
                    marginTop={true}
                    keyboard={true}
                    fullWidth={false}
                    disabled={ajaxing}
                    id="xdata"
                    name="xdata"
                    errorMessage={errorsMap('dataVencimentoPlano')}
                    defaultValue={dataVencimentoPlano}
                    onChange={(data) => {
                      this.setState({
                        dataVencimentoPlano: converterMomentParaDataInt(data)
                      })
                    }}
                    label="Data de Vencimento"
                    customVariant="outlined-small"
                    shrink={false}
                  />
                </Grid>
                <Grid item xs>
                  <InputData
                    key={dataSistemaAtivo}
                    functionsMap={this.dataFunctionsMap}
                    marginTop={true}
                    keyboard={true}
                    fullWidth={false}
                    disabled={ajaxing}
                    id="xdata"
                    name="xdata"
                    errorMessage={errorsMap('dataSistemaAtivoPosterior')}
                    defaultValue={dataSistemaAtivo}
                    onChange={(dataEscolhida) => {
                      const hoje = moment().startOf('day')
                      const dataSistemaAtivoAnterior = converterDataIntParaMoment(negocio.dataSistemaAtivo).startOf('day')
                      let diferencaDatas = 0

                      if (dataEscolhida != null) {
                        if (dataSistemaAtivoAnterior.isBefore(hoje)) {
                          diferencaDatas = dataEscolhida.diff(hoje, 'days')
                        } else {
                          diferencaDatas = dataEscolhida.diff(dataSistemaAtivoAnterior, 'days')
                        }
                      }

                      const diasPospago = diferencaDatas + negocio.diasDisponibilizadosPospago
                      this.setState({
                        dataSistemaAtivo: converterMomentParaDataInt(dataEscolhida),
                        diasPospago: diasPospago
                      })
                    }}
                    label="Data de bloqueio do sistema"
                    customVariant="outlined-small"
                    shrink={false}
                  />
                </Grid>
                <Grid item xs>
                  <InputText
                    key={diasDisponibilizadosPospago}
                    marginTop={true}
                    idname="xdias"
                    customVariant="outlined-small"
                    shrink={false}
                    defaultValue={diasDisponibilizadosPospago}
                    inputComponent={InputNumberFormat}
                    disabled={this.state.ajaxing}
                    errorMessage={errorsMap('quantidadeDiasPos')}
                    label="Dias Liberados Pós-Pagos"
                    inputProps={{
                      style: {
                        maxWidth: 120
                      }
                    }}
                    inputRef={this.inputDiasPos}
                  />
                </Grid>
                <Grid item xs>
                  <InputText
                    marginTop={true}
                    idname="xobservacao"
                    customVariant="outlined-small"
                    shrink={false}
                    disabled={this.state.ajaxing}
                    multiline={true}
                    errorMessage={errorsMap('observacao')}
                    label="Observação"
                    inputProps={{
                      style: {
                        maxWidth: 400
                      }
                    }}
                    inputRef={this.inputObservacao}
                  />
                </Grid>
              </Grid>

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

PlanoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PlanoDialogPage)
