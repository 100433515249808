import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  }
})

class CotaParteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxing: false,
      cotaParteDataInicial: this.colaboradorLocalPreferences.getMomentPreference('CotaParteDialogPageFiltro_DataInicial', moment().startOf('month')),
      cotaParteDataFinal: this.colaboradorLocalPreferences.getMomentPreference('CotaParteDialogPageFiltro_DataFinal', moment().endOf('month')),
      detalhamentoCotaParteNfs: []
    }
    this.cotaParteLoaderFunctionsMap = {}
  }

  carregarMaisUtilizados = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/vendas.buscarDetalhamentoCotaParteNfs',
      params: {
        dataInicial: converterMomentParaDataInt(this.state.cotaParteDataInicial),
        dataFinal: converterMomentParaDataInt(this.state.cotaParteDataFinal)
      },
      requerAutorizacao: true,

      onSuccess: (response) => {
        this.setState({
          ajaxing: false,
          detalhamentoCotaParteNfs: response.data
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        this.setState({
          ajaxing: false
        })
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  getRenderContent = (size, item) => {
    const content = {}

    content.descricao = item.nomeColaborador ? item.nomeColaborador : 'Estabelecimento'
    content.valor = formatarValorMonetario(item.valor)

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, cotaParteDataInicial, cotaParteDataFinal } = this.state

    let toolbarActions = null
    if (!ajaxing) {
      toolbarActions = {
        actions: [
          {
            icon: <RefreshIcon />,
            handleAction: () => {
              this.cotaParteLoaderFunctionsMap.reload()
            }
          }
        ]
      }
    }

    let detalhamentoCotaParteNfsLoadKey =
      converterMomentParaDataInt(this.state.cotaParteDataInicial).toString() + '-' + converterMomentParaDataInt(this.state.cotaParteDataFinal).toString()

    return (
      <DialogPage {...dialogProps} title="Salão Parceiro" align="center" contentMaxWidth={600} pageType="basicEntity" ajaxing={ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Cotas Parte por Período
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <DateRangeNavigator
                    expanded={false}
                    defaultValue={{
                      tipoIntervalo: this.colaboradorLocalPreferences.getPreference('CotaParteDialogPageFiltro_TipoIntervalo', 'mes'),
                      dataInicial: cotaParteDataInicial,
                      dataFinal: cotaParteDataFinal
                    }}
                    onChange={(data) => {
                      this.colaboradorLocalPreferences.setPreference('CotaParteDialogPageFiltro_TipoIntervalo', data.tipoIntervalo.nome)
                      this.colaboradorLocalPreferences.setMomentPreference('CotaParteDialogPageFiltro_DataInicial', data.dataInicial)
                      this.colaboradorLocalPreferences.setMomentPreference('CotaParteDialogPageFiltro_DataFinal', data.dataFinal)
                      this.setState({
                        cotaParteDataInicial: data.dataInicial,
                        cotaParteDataFinal: data.dataFinal
                      })
                    }}
                  />
                </div>
              </div>
            </FloatContent>

            <ContentWithPreload functionsMap={this.cotaParteLoaderFunctionsMap} loadFunction={this.carregarMaisUtilizados} loadKey={detalhamentoCotaParteNfsLoadKey}>
              {() => (
                <VirtualizedResponsiveTable
                  showBackgroundDividers={true}
                  scrollElement={dialogContentProps.scrollContainerRef.current}
                  items={this.state.detalhamentoCotaParteNfs}
                  largeRenderProps={{
                    columns: [
                      { label: 'Parte', props: { xs: true } },
                      { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' }
                    ],
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('large', item)

                      return {
                        itemData: [content.descricao, content.valor]
                      }
                    }
                  }}
                  smallRenderProps={{
                    rowHeight: 60,
                    itemRenderer: (item, index) => {
                      const content = this.getRenderContent('small', item)

                      return {
                        itemData: (
                          <React.Fragment>
                            <Grid container style={{ marginTop: 4 }} alignItems="center">
                              <Grid item xs>
                                <Typography variant="body2" noWrap={true}>
                                  {content.descricao}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Grid item style={{ paddingLeft: 8, textAlign: 'right' }}>
                                  <Typography variant="body2">{content.valor}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )
                      }
                    }
                  }}
                  emptyListProps={{
                    text: 'Não existem cotas parte para o período informado'
                  }}
                />
              )}
            </ContentWithPreload>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

CotaParteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(CotaParteDialogPage)
