import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DetalhesPagamentosDialogPage from 'pages/erp/painel/relatorio/DetalhesPagamentosDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { createEnum } from 'support/util/EnumUtil'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaValor: {
    maxWidth: 122,
    minWidth: 122
  },
  colunaIcone: {
    maxWidth: 66,
    minWidth: 66
  }
})

const Tipo = createEnum({
  ADQUIRENTE: { id: 1 },
  BANDEIRA: { id: 2 }
})

class ResumoPagamentosAdquirentesBandeirasDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.loaderFunctionsMap = {}
    this.state = {
      lista: []
    }
  }

  abrirDetalhes = (item) => {
    const { dataInicial, dataFinal, idFormaPagamento } = this.props

    openBackableDialog(DetalhesPagamentosDialogPage, {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      tituloDialog: item.nomeBandeira ? item.nomeAdquirente + ' - ' + item.nomeBandeira : item.nomeAdquirente ? 'Maquininha: ' + item.nomeAdquirente : item.nome,
      idFormaPagamento: idFormaPagamento,
      idAdquirente: item.idAdquirente,
      idBandeira: item.idBandeira
    })
  }

  getRenderContent = (size, item) => {
    const content = {}
    if (item.tipo) {
      if (item.tipo.id === Tipo.ADQUIRENTE.id) {
        content.descricao = (
          <span>
            <b> Maquininha: {item.nomeAdquirente}</b>
          </span>
        )
      } else if (item.tipo.id === Tipo.BANDEIRA.id) {
        content.descricao = (
          <span style={{ paddingLeft: 4, fontSize: 13 }}>
            {' '}
            <ChevronRightIcon viewBox="4 5 17 17" style={{ verticalAlign: 'middle', width: 17, height: 17 }} /> Bandeira: {item.nomeBandeira}
          </span>
        )
      }
    } else {
      content.descricao = (
        <span>
          <b>{item.nome}</b>
        </span>
      )
    }

    if (size === 'small') {
      content.valorTotal = formatarValorMonetario(item.valorTotal, { prefix: true })
      content.abrirDetalhes = (
        <IconButton
          style={{ marginTop: -10, marginBottom: -6, padding: 8 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    } else {
      if (item.tipo && item.tipo.id === Tipo.BANDEIRA.id) {
        content.valorTotal = <span style={{ paddingRight: 0, fontSize: 13 }}>{formatarValorMonetario(item.valorTotal, { prefix: false })}</span>
      } else {
        content.valorTotal = (
          <span>
            <b>{formatarValorMonetario(item.valorTotal, { prefix: false })}</b>
          </span>
        )
      }
      content.abrirDetalhes = (
        <IconButton
          style={{ marginRight: 0, padding: 6 }}
          onClick={(event) => {
            event.stopPropagation()
            this.abrirDetalhes(item)
          }}
        >
          <OpenInNewIcon style={{ display: 'block', color: '#333' }} fontSize="small" />
        </IconButton>
      )
    }

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes, dataInicial, dataFinal, idFormaPagamento } = this.props
    const descricaoFiltroPeriodo = this.props.dataInicial.format('DD/MM/YYYY') + ' - ' + this.props.dataFinal.format('DD/MM/YYYY')

    return (
      <DialogPage {...dialogProps} title={'Maquininhas e Bandeiras'} align="center" pageType="basicEntity" contentMaxWidth={600} ajaxing={this.state.ajaxing}>
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent textAlign="center" context="DialogPage" type="sectionTitle">
              <Typography variant="h5">{this.props.tituloDialog}</Typography>
              <Typography variant="body1">
                <b>{descricaoFiltroPeriodo}</b>
              </Typography>
            </FloatContent>

            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              itemsPerRequest={20}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarResumoAdquirentesBandeiras"
              getRequestParametersFunction={() => ({
                dataInicial: converterMomentParaDataInt(dataInicial),
                dataFinal: converterMomentParaDataInt(dataFinal),
                idFormaPagamento: idFormaPagamento
              })}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const lista = []
                  let valorTotalTodosAdquirentes = 0

                  for (let item of data.items) {
                    let adquirenteEncontrado

                    lista.forEach((itemLista) => {
                      if (itemLista.idAdquirente === item.idAdquirente && !itemLista.idBandeira) {
                        adquirenteEncontrado = itemLista
                        return
                      }
                    })

                    if (!adquirenteEncontrado) {
                      adquirenteEncontrado = {
                        nomeAdquirente: item.nomeAdquirente,
                        idAdquirente: item.idAdquirente,
                        valorTotal: 0,
                        tipo: Tipo.ADQUIRENTE
                      }

                      lista.push(adquirenteEncontrado)
                    }

                    adquirenteEncontrado.valorTotal += item.valorTotal

                    lista.push({
                      idAdquirente: item.idAdquirente,
                      idBandeira: item.idBandeira,
                      nomeAdquirente: item.nomeAdquirente,
                      nomeBandeira: item.nomeBandeira,
                      valorTotal: item.valorTotal,
                      tipo: Tipo.BANDEIRA
                    })

                    valorTotalTodosAdquirentes += item.valorTotal
                  }

                  lista.push({
                    nome: 'Todas Maquininhas',
                    valorTotal: valorTotalTodosAdquirentes
                  })

                  this.setState({ lista: lista })
                }
              }}
              onClickRow={(item, event) => {
                this.abrirDetalhes(item)
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Nome', props: { xs: true } },
                  { label: 'Valor (R$)', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    itemData: [content.descricao, content.valorTotal, content.abrirDetalhes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 80,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container style={{ minWidth: 0 }} alignItems="center">
                          <Grid item xs style={{ minWidth: 0 }}>
                            <Typography variant="body2" noWrap={true}>
                              <b>{content.descricao}</b>
                            </Typography>
                            <Typography variant="body2" noWrap={true}>
                              {content.valorTotal}
                            </Typography>
                          </Grid>
                          <Grid item>{content.abrirDetalhes}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

ResumoPagamentosAdquirentesBandeirasDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ResumoPagamentosAdquirentesBandeirasDialogPage)
