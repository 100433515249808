import { useState } from 'react'

import { Box, Button, Typography } from '@material-ui/core'
import { getAPI } from 'support/components/api/PainelAdminApiClient'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'

import { DataBusiness, PocketProps } from './PocketDialog'
import { useStyles } from './PocketDialot.styles'

export const ProcessStatus = ({ onCloseDialog, onStatusChange, onSetDataBusiness }: PocketProps) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')

  const handleClick = () => {
    setLoading(true)
    getAPI({
      url: `admin/pocket/migracao/negocio/${id}`,
      contextoUsuario: 'admin',
      requerAutorizacao: true,
      onSuccess: (response: { data: DataBusiness }) => {
        onSetDataBusiness?.(response.data)
        onStatusChange('confirm')
        setLoading(false)
      },
      onError: (response: { data: { errors: object } }) => {
        Object.entries(response.data.errors).map(([, value]) => {
          EventsManager.pub('MensagemSnackBar', value)
        })
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        Informe os dados do negócio
      </Typography>
      <InputText
        idname="idsalao"
        customVariant="outlined-small"
        shrink={false}
        autoFocus
        label="ID Salão99"
        inputProps={{
          maxLength: 500,
          'data-testid': 'idsalao'
        }}
        value={id}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setId(event.target.value)}
      />
      <Box className={classes.wrapperButtons}>
        <Button onClick={onCloseDialog}>Cancelar</Button>
        <Button onClick={handleClick} disabled={id.length <= 0 || loading} variant="contained" color="secondary">
          Continuar
        </Button>
      </Box>
    </>
  )
}
