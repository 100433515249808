import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'

class FacilitadorRemocaoRepeticao extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { functionsMap } = this.props
    if (functionsMap) {
      functionsMap.remover = (props) => {
        this.item = props.item
        this.call = props.call
        this.dialog = props.dialog
        if (props.abrirMenu) {
          this.setState({
            deleteItemMenuAnchorPosition: {
              left: props.event.clientX,
              top: props.event.clientY
            }
          })
        } else {
          this.handlerRemover(false)
        }
      }
    }
  }

  handlerRemover = (removerProximasRecorrencias) => {
    let complementoTexto = ''

    if (removerProximasRecorrencias) {
      complementoTexto = ' e as próximas repetições'
    }

    openBackableDialog(EntityRemovalDialog, {
      parent: this.dialog.parent,
      title: this.dialog.title,
      text: (
        <span>
          Você tem certeza que deseja remover {this.dialog.itemDescription}
          {complementoTexto}? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: this.call.endpoint,
        contextoUsuario: 'erp',
        data: {
          ...this.call.data,
          removerProximasRecorrencias: removerProximasRecorrencias
        },
        onSuccess: this.call.onSuccess,
        errorKey: this.call.errorKey
      }
    })
  }

  closeDeleteItemMenu = (event) => {
    event.stopPropagation()
    this.setState({ deleteItemMenuAnchorPosition: null })
  }

  render() {
    const { deleteItemMenuAnchorPosition } = this.state

    return (
      <React.Fragment>
        <Popover
          anchorReference="anchorPosition"
          transformOrigin={{ horizontal: 'center', vertical: 'center' }}
          anchorPosition={deleteItemMenuAnchorPosition}
          open={Boolean(deleteItemMenuAnchorPosition)}
          onClose={(event) => this.closeDeleteItemMenu(event)}
        >
          <MenuItem
            onClick={(event) => {
              this.handlerRemover(false)
              this.closeDeleteItemMenu(event)
            }}
          >
            Remover somente este
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              this.handlerRemover(true)
              this.closeDeleteItemMenu(event)
            }}
          >
            Remover este e os próximos
          </MenuItem>
        </Popover>
      </React.Fragment>
    )
  }
}

export default FacilitadorRemocaoRepeticao
