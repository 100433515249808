import { createEnum } from 'support/util/EnumUtil'

const enumObject = createEnum({
  SEGUNDA_FEIRA: { id: 1, descricao: 'Segunda-feira' },
  TERCA_FEIRA: { id: 2, descricao: 'Terça-feira' },
  QUARTA_FEIRA: { id: 3, descricao: 'Quarta-feira' },
  QUINTA_FEIRA: { id: 4, descricao: 'Quinta-feira' },
  SEXTA_FEIRA: { id: 5, descricao: 'Sexta-feira' },
  SABADO: { id: 6, descricao: 'Sábado' },
  DOMINGO: { id: 7, descricao: 'Domingo' }
})

export default enumObject
