import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import NFDialogPage from 'pages/admin/painel/notafiscal/NFDialogPage'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PainelAdminApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import NfStatusIcon from 'support/components/icon/NfStatus'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import PanelPage from 'support/components/panel/PanelPage'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import StatusApresentacaoNfEnum from 'support/domain/venda/StatusApresentacaoNfEnum'
import TipoNfEnum from 'support/domain/venda/TipoNfEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 110
  },
  colunaId: {
    flexGrow: 1,
    maxWidth: 110,
    minWidth: 110
  },
  colunaTomador: {
    flexGrow: 0.7
  },
  colunaErro: {
    flexGrow: 1
  },
  colunaIconeStatusApresentaco: {
    maxWidth: 44,
    minWidth: 44
  },
  colunaValor: {
    flexGrow: 1,
    maxWidth: 130,
    minWidth: 120
  },
  colunaSituacao: {
    maxWidth: 94,
    minWidth: 94,
    [theme.breakpoints.down(686)]: {
      display: 'none'
    }
  },
  descricaoErroContainer: {
    ...theme.text.limitedLines({ maxLines: 3 })
  }
})

class NFsPanelPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ajaxing: false,
      lista: [],
      dataInicial: moment().startOf('month'),
      dataFinal: moment().endOf('month')
    }

    this.loaderFunctionsMap = {}
    this.mainLoaderFunctionsMap = {}
    this.inputFiltro = React.createRef()
    this.eventsManager = EventsManager.new()
  }

  componentDidMount() {
    this.eventsManager.sub('ManipulacaoNF', (props) => {
      this.mainLoaderFunctionsMap.reload()
    })
  }

  getRenderContent = (size, item) => {
    const content = {}

    if (item.tipo.id === TipoNfEnum.NFSE.id) {
      content.data = moment(item.nfsDataCompetencia).format('DD/MMM/YY')
      content.id = item.id
      content.tomadorNome = item.tomadorNome
      content.valor = formatarValorMonetario(item.nfsValorTotal)
      content.descricaoErroApresentacao = item.descricaoErroApresentacao
      content.descricaoStatus = StatusApresentacaoNfEnum.getById(item.statusApresentacao.id).descricaoResumida
      content.iconeStatus = <NfStatusIcon nf={item} hasTooltip={true} />
    }

    return content
  }

  handlerAbrirNF = (nf) => {
    openBackableDialog(NFDialogPage, {
      nf: nf
    })
  }

  getLoaderKey = () => {
    const { dataInicial, dataFinal } = this.state

    let key = 'loader'

    if (dataInicial) {
      key += '-di' + dataInicial
    }

    if (dataFinal) {
      key += '-df' + dataFinal
    }
    return key
  }

  getRequestParameters = () => {
    return {
      dataInicial: converterMomentParaDataInt(this.state.dataInicial),
      dataFinal: converterMomentParaDataInt(this.state.dataFinal)
    }
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'admin/nfs.buscarSomatorioNfs',
      params: {
        dataInicial: converterMomentParaDataInt(this.state.dataInicial),
        dataFinal: converterMomentParaDataInt(this.state.dataFinal)
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          somatorioNfs: response.data
        })

        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  render() {
    const { classes, ...others } = this.props
    const { dataInicial, dataFinal, somatorioNfs } = this.state
    const loadKey = dataInicial + '-' + dataFinal

    let onClickRow = (nf) => {
      this.handlerAbrirNF(nf)
    }

    return (
      <PanelPage
        {...others}
        title="Notas Fiscais"
        toolbarActions={{
          actions: [
            {
              icon: <RefreshIcon />,
              handleAction: () => {
                this.mainLoaderFunctionsMap.reload()
              }
            }
          ]
        }}
      >
        <ContentWithPreload functionsMap={this.mainLoaderFunctionsMap} loadFunction={this.carregarMain} loadKey={loadKey}>
          {() => (
            <React.Fragment>
              <Grid container alignItems="center">
                <Grid item style={{ marginLeft: -10, marginRight: 16 }}>
                  <DateRangeNavigator
                    expanded={false}
                    defaultValue={{
                      tipoIntervalo: 'mes',
                      dataInicial: dataInicial,
                      dataFinal: dataFinal
                    }}
                    onChange={(data) => {
                      this.setState({
                        dataInicial: data.dataInicial,
                        dataFinal: data.dataFinal
                      })
                    }}
                  />
                </Grid>
              </Grid>

              <div style={{ marginTop: 24, marginBottom: 24 }}>
                {somatorioNfs.map((somatorio, index) => (
                  <Typography key={index} variant="body2" style={{ marginTop: 8 }}>
                    <b>{StatusApresentacaoNfEnum.getById(somatorio.statusApresentacao.id).descricao}:</b> {formatarValorMonetario(somatorio.valor)} ({somatorio.quantidade})
                  </Typography>
                ))}
              </div>

              <VirtualizedResponsiveTable
                showBackgroundDividers={true}
                itemsPerRequest={20}
                loaderFunctionsMap={this.loaderFunctionsMap}
                contextoUsuario="admin"
                endpoint="admin/nfs.buscarNfs"
                getRequestParametersFunction={this.getRequestParameters}
                loaderKey={this.getLoaderKey()}
                items={this.state.lista}
                loadTrackingFunction={(data) => {
                  if (data.status === 'loading') {
                    if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                      this.setState({ lista: [] })
                    }
                  } else if (data.status === 'loaded') {
                    const state = { lista: data.items }
                    this.setState(state)
                  }
                }}
                onClickRow={onClickRow}
                largeRenderProps={{
                  columns: [
                    { label: 'Competência', className: classes.colunaData },
                    { label: 'Id', className: classes.colunaId },
                    { label: 'Tomador/Cliente', className: classes.colunaTomador, horizontalPadding: 'small', props: { xs: true } },
                    { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                    { label: 'Erro', className: classes.colunaErro, props: { xs: true } },
                    { label: 'Situação', className: classes.colunaSituacao },
                    { label: '', className: classes.colunaIconeStatusApresentaco, align: 'right', horizontalPadding: 'small', isVisible: false }
                  ],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('large', item)
                    return {
                      itemData: [content.data, content.id, content.tomadorNome, content.valor, content.descricaoErroApresentacao, content.descricaoStatus, content.iconeStatus]
                    }
                  }
                }}
                mediumRenderProps={{
                  headerColumnHeight: 52,
                  rowHeight: 102,
                  columns: [
                    { label: 'Competência', className: classes.colunaData },
                    { label: 'Informações', props: { xs: true } },
                    { label: 'Valor', className: classes.colunaValor, horizontalPadding: 'small', align: 'right' },
                    { label: 'Situação', className: classes.colunaSituacao },
                    { label: '', className: classes.colunaIconeStatusApresentaco, align: 'right', horizontalPadding: 'small', isVisible: false }
                  ],
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('medium', item)

                    return {
                      itemData: [
                        content.data,
                        <React.Fragment>
                          <Typography variant="body2" noWrap={true}>
                            <b>
                              {content.id} - {content.tomadorNome}
                            </b>
                          </Typography>
                          {content.descricaoErroApresentacao && (
                            <Typography variant="body2" className={classes.descricaoErroContainer}>
                              Erro: {content.descricaoErroApresentacao}
                            </Typography>
                          )}
                        </React.Fragment>,
                        content.valor,
                        content.descricaoStatus,
                        content.iconeStatus
                      ]
                    }
                  }
                }}
                smallRenderProps={{
                  rowHeight: 146,
                  itemRenderer: (item, index) => {
                    const content = this.getRenderContent('small', item)
                    return {
                      itemData: (
                        <React.Fragment>
                          <Grid container alignItems="center">
                            <Grid item xs style={{ minWidth: 0 /*minWidth - para ativar as reticências */ }}>
                              <Typography variant="body2">{content.data}</Typography>
                              <Typography variant="body2" noWrap={true}>
                                <b>
                                  {content.id} - {content.tomadorNome}
                                </b>
                              </Typography>
                              <Typography variant="body2">{content.valor}</Typography>
                            </Grid>
                            <Grid item align="right">
                              {content.iconeStatus}
                            </Grid>
                          </Grid>
                          {content.descricaoErroApresentacao && (
                            <Typography variant="body2" className={classes.descricaoErroContainer}>
                              Erro: {content.descricaoErroApresentacao}
                            </Typography>
                          )}
                        </React.Fragment>
                      )
                    }
                  }
                }}
                emptyListProps={{
                  text: 'Nenhuma nota fiscal foi encontrada'
                }}
              />
            </React.Fragment>
          )}
        </ContentWithPreload>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

NFsPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NFsPanelPage)
