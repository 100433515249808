import React, { Component } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ScheduleIcon from '@material-ui/icons/Schedule'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import ConfiguracoesComissaoGorjetaDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesComissaoGorjetaDialogPage'
import ConfiguracoesHorariosDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesHorariosDialogPage'
import ConfiguracoesNotificacoesDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesNotificacoesDialogPage'
import ConfiguracoesPermissoesDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesPermissoesDialogPage'
import ConfiguracoesServicosDialogPage from 'pages/erp/painel/colaborador/ConfiguracoesServicosDialogPage'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import TabContainer from 'support/components/tab/TabContainer'

const styles = (theme) => ({
  tituloOpcao: {
    fontWeight: 500
  }
})

class TabConfiguracoes extends Component {
  handleGerenciarComissoes = () => {
    openBackableDialog(ConfiguracoesComissaoGorjetaDialogPage, {
      parent: this,
      colaborador: this.props.colaborador
    })
  }

  handleGerenciarNotificacoes = () => {
    openBackableDialog(ConfiguracoesNotificacoesDialogPage, {
      parent: this,
      colaborador: this.props.colaborador
    })
  }

  handleGerenciarHorarios = () => {
    openBackableDialog(ConfiguracoesHorariosDialogPage, {
      parent: this,
      colaborador: this.props.colaborador
    })
  }

  handleGerenciarPermissoes = () => {
    openBackableDialog(ConfiguracoesPermissoesDialogPage, {
      parent: this,
      colaborador: this.props.colaborador
    })
  }

  handleGerenciarServicos = () => {
    openBackableDialog(ConfiguracoesServicosDialogPage, {
      parent: this,
      colaborador: this.props.colaborador
    })
  }

  render() {
    return (
      <TabContainer>
        <List disablePadding component="nav" style={{ marginTop: 0, cursor: 'pointer' }}>
          <ListItem disableGutters style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => this.handleGerenciarHorarios()}>
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Horários de Trabalho</b>} secondary="Configure os horários de trabalho do colaborador" />
          </ListItem>
          <ListItem
            disabled={!this.props.colaborador.realizaAtendimentos}
            disableGutters
            style={{ marginTop: 8, cursor: !this.props.colaborador.realizaAtendimentos ? 'default' : 'pointer' }}
            onClick={() => (this.props.colaborador.realizaAtendimentos ? this.handleGerenciarServicos() : null)}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Serviços</b>} secondary="Configure os serviços que o colaborador pode executar" />
          </ListItem>
          <ListItem disableGutters style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => this.handleGerenciarComissoes()}>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Comissões e Gorjetas</b>} secondary="Configure os cálculos de comissão e recebimento de gorjetas" />
          </ListItem>
          <ListItem disableGutters style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => this.handleGerenciarPermissoes()}>
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Permissões</b>} secondary="Defina as permissões do colaborador no sistema" />
          </ListItem>
          <ListItem disableGutters style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => this.handleGerenciarNotificacoes()}>
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Notificações</b>} secondary="Configure as notificações de agendamento e agendamento online" />
          </ListItem>
        </List>
      </TabContainer>
    )
  }
}

TabConfiguracoes.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabConfiguracoes)
