export const styles = {
  modalContentPagamento: {
    padding: '24px',
    borderRadius: '8px',
    top: '40%',
    '@media (max-width: 765px)': {
      margin: '0px 24px',
      top: '30%'
    }
  },
  modalHeaderPagamento: {
    padding: '0px',
    marginTop: '8px'
  },
  modalBodyPagamento: {
    padding: '0px',
    marginTop: '8px'
  },
  tagPagamento: {
    color: '$actionConversionStatic',
    borderColor: '$actionConversionStatic',
    maxHeight: '24px'
  },
  headingPagamento: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px'
  },
  descriptionPagamento: {
    fontWeight: 400,
    lineHeight: '24px'
  },
  iconButtonPagamento: {
    color: '#264FEC'
  },
  modalFooterPagamento: {
    paddingLeft: '0px',
    paddingRight: '0px',
    '& > button': {
      width: '100%'
    }
  }
}
