import ServicoService from 'support/domain/servico/ServicoService'

function gerarDescricaoServico(atendimento) {
  return ServicoService.gerarNomeCompletoServico(atendimento.servico, atendimento.variacaoServico)
}

const functionsObject = {
  gerarDescricaoServico: gerarDescricaoServico
}

export default functionsObject
