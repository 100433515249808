import { Box, Checkbox, FormControlLabel, MenuItem, Typography } from '@material-ui/core'
import moment from 'moment'
import InputData from 'support/components/input/InputData'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import { cuponsPeriodList, cuponsPlansList } from 'support/domain/cupons/constants'
import { CuponsPeriodEnum } from 'support/domain/cupons/CuponsEnum'
import { InputMoneyFormat } from 'support/util/Masks'

import { useStyles } from './Cupons.style'

export type CuponsAddPageFormProps = {
  form: {
    coupon: string
    period: string
    title: string
    price: string
    plan: string
    dueDate: string
    description: string
    cycles: number
    active: boolean
    isValidForFirstPayment: boolean
  }
  onChange: (key: keyof CuponsAddPageFormProps['form'], val: string | boolean | number) => void
}

export const CuponsAddPageForm = ({ form, onChange }: CuponsAddPageFormProps) => {
  const classes = useStyles()
  const cyclesList = Array.from({ length: 12 }, (_, i) => i + 1)

  const resetCyclesField = (value: string) => {
    if (value !== CuponsPeriodEnum.MENSAL) {
      onChange('cycles', 1)
    }
  }

  return (
    <>
      <Box className={classes.wrapperInput}>
        <InputText
          idname="coupon"
          customVariant="outlined-small"
          shrink={false}
          autoFocus
          label="Código do cupom"
          inputProps={{
            maxLength: 500,
            'data-testid': 'coupon'
          }}
          value={form.coupon}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('coupon', event.target.value)}
        />
        <InputText
          idname="title"
          customVariant="outlined-small"
          shrink={false}
          label="Título do cupom"
          inputProps={{
            maxLength: 500,
            'data-testid': 'title'
          }}
          value={form.title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('title', event.target.value)}
        />
      </Box>
      <Box className={classes.wrapperInput}>
        <InputSelect
          idname="period"
          label="Período"
          fullWidth
          customVariant="outlined-small"
          shrink={false}
          inputProps={{
            'data-testid': 'period'
          }}
          value={form.period}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value
            onChange('period', value)
            resetCyclesField(value)
          }}
        >
          {cuponsPeriodList.map((period) => (
            <MenuItem key={period.value} value={period.value}>
              {period.label}
            </MenuItem>
          ))}
        </InputSelect>
        <InputSelect
          idname="plan"
          label="Plano"
          fullWidth
          customVariant="outlined-small"
          shrink={false}
          inputProps={{
            'data-testid': 'plan'
          }}
          value={form.plan}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('plan', event.target.value)}
        >
          {cuponsPlansList.map((plans) => (
            <MenuItem key={plans.value} value={plans.value}>
              {plans.label}
            </MenuItem>
          ))}
        </InputSelect>
      </Box>
      <Box className={classes.wrapperInput}>
        <InputText
          idname="price"
          label="Preço (R$)"
          customVariant={'outlined-small'}
          shrink={false}
          inputComponent={InputMoneyFormat}
          inputProps={{
            'data-testid': 'price'
          }}
          value={form.price}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('price', event.target.value)}
        />
        <InputData
          disablePast
          customVariant="outlined-small"
          shrink={false}
          label="Data de vencimento"
          keyboard
          idname="dueDate"
          inputProps={{
            'data-testid': 'dueDate'
          }}
          onChange={(data: Date) => {
            const formatedData = moment(data).format('yyyy-MM-DD')
            onChange('dueDate', formatedData)
          }}
          value={form.dueDate}
        />
      </Box>
      <Box className={classes.wrapperInput}>
        <InputSelect
          idname="cycles"
          label="Ciclos"
          fullWidth
          customVariant="outlined-small"
          disabled={form.period !== CuponsPeriodEnum.MENSAL}
          inputProps={{
            'data-testid': 'cycles'
          }}
          value={form.cycles}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('cycles', event.target.value)}
        >
          {cyclesList.map((cycle) => (
            <MenuItem key={cycle} value={cycle}>
              {cycle}
            </MenuItem>
          ))}
        </InputSelect>
        <InputText
          idname="description"
          customVariant={'outlined-small'}
          shrink={false}
          multiline
          label="Descrição"
          inputProps={{
            maxLength: 2000,
            'data-testid': 'description'
          }}
          value={form.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('description', event.target.value)}
        />
      </Box>
      <Box className={classes.wrapperCheckbox}>
        <Box className={classes.wrapperInput}>
          <FormControlLabel
            labelPlacement="start"
            value="active"
            control={
              <Checkbox
                data-testid="active"
                color="secondary"
                checked={form.active}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('active', event.target.checked)}
              />
            }
            label={<Typography variant="body2">Ativo</Typography>}
          />
          <FormControlLabel
            labelPlacement="start"
            value="isValidForFirstPayment"
            control={
              <Checkbox
                data-testid="isValidForFirstPayment"
                color="secondary"
                checked={form.isValidForFirstPayment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('isValidForFirstPayment', event.target.checked)}
              />
            }
            label={<Typography variant="body2">Válido para primeiro pagamento</Typography>}
          />
        </Box>
      </Box>
    </>
  )
}
