/* eslint-disable @typescript-eslint/no-explicit-any */
import { Redirect } from 'react-router-dom'

import { ROUTE_ENTERPRISE_MAIN, ROUTE_PORTAL_MAIN } from 'pages/RouteMap'
import DeviceIdentifier from 'support/components/device/DeviceIdentifier'
import SessaoUsuario from 'support/components/sessao/SessaoUsuario'
import { logAGE } from 'support/util/Logger/Logger'
import UrlUtil from 'support/util/UrlUtil'

const navigate = (path: string) => {
  try {
    new Redirect({ to: { path } as any })
  } catch (error) {
    logAGE(error, { component: 'routerUserAfterSuccessfulLogin', context: 'navigate' })
  }
}

export const redirectUserAfterSuccessfulLogin = () => {
  const { getUrlContinuacaoAposLogin, getUsuario } = SessaoUsuario

  const continuationUrl = getUrlContinuacaoAposLogin()
  if (continuationUrl) {
    const isOnBrowserAndACollaborator = !DeviceIdentifier.isNativeApp() && getUsuario().quantidadeColaboradores > 0
    if (isOnBrowserAndACollaborator) {
      const { getBaseUrlWithoutQueryParamsOrFragments, getFullUrlFromRoute } = UrlUtil

      const baseUrlFromContinuationUrl = getBaseUrlWithoutQueryParamsOrFragments(decodeURIComponent(continuationUrl))
      const urlHomeBase = getBaseUrlWithoutQueryParamsOrFragments(getFullUrlFromRoute(ROUTE_PORTAL_MAIN))

      if (baseUrlFromContinuationUrl === urlHomeBase) {
        navigate(ROUTE_ENTERPRISE_MAIN)
      }
    }
    window.location.href = decodeURIComponent(continuationUrl)
  } else {
    navigate(ROUTE_PORTAL_MAIN)
  }
}
