import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import DiaSemanalEnum from 'support/domain/datetime/DiaSemanalEnum'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class HorarioAtendimentoAODialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      title: !this.props.idColaboradorHorarioAtendimentoAO ? 'Criar' : 'Alterar',
      diaSemana: { id: null },
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputHorarioAtendimentoAO = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    if (!this.props.idColaboradorHorarioAtendimentoAO) {
      this.registrarPaginaCarregada()
      notifyContentLoaded()
    } else {
      this.setState({ ajaxing: true })
      getAPI({
        url: 'admin/colaboradores.buscarColaboradorHorarioAtendimentoAO',
        params: {
          id: this.props.idColaboradorHorarioAtendimentoAO
        },
        requerAutorizacao: true,
        onPreFinal: () => {
          this.setState({ ajaxing: false })
        },
        onSuccess: (response) => {
          this.setState((state) => ({
            colaboradorHorarioAtendimentoAO: response.data,
            diaSemana: { id: response.data.dia }
          }))

          this.registrarPaginaCarregada()
          notifyContentLoaded()
        },
        onError: (response) => {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      })
    }
  }

  registrarPaginaCarregada = (existe) => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  getDados = () => {
    const dados = {}

    if (this.props.idColaboradorHorarioAtendimentoAO !== null) {
      dados.id = this.props.idColaboradorHorarioAtendimentoAO
    }

    if (this.state.diaSemana) {
      dados.diaSemana = this.state.diaSemana.id
    }

    dados.horariosFixos = this.inputHorarioAtendimentoAO.current.value
    dados.idNegocio = this.props.idNegocio
    dados.idColaborador = this.props.idColaborador

    return dados
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'admin/colaboradores.persistirColaboradorHorarioAtendimentoAO',
      data: this.getDados(),
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManipulacaoHorarioAtendimentoAO', { colaboradorHorarioAtendimentoAO: response.data })
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formColaboradorHorarioAtendimento')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap } = this.state
    let { horariosFixos } = this.state.colaboradorHorarioAtendimentoAO ? this.state.colaboradorHorarioAtendimentoAO : {}

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formColaboradorHorarioAtendimento" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputSelect
                customVariant={'outlined-small'}
                nakedLeftPadding={false}
                value={this.state.diaSemana.id}
                style={{ maxWidth: 200 }}
                autoFocus={true}
                fullWidth={false}
                displayEmpty
                errorMessage={errorsMap('dia')}
                onChange={(event) => {
                  this.setState({ diaSemana: { id: event.target.value } })
                }}
              >
                <MenuItem value="">{this.state.paginaCarregada ? 'Selecionar dia' : 'Carregando...'}</MenuItem>
                {DiaSemanalEnum.values().map((diaSemana) => (
                  <MenuItem key={diaSemana.id} value={diaSemana.id}>
                    {diaSemana.descricao}
                  </MenuItem>
                ))}
              </InputSelect>

              <InputText
                id="xhorariosAtendimentoAO"
                name="xhorariosAtendimentoAO"
                customVariant={'outlined-small'}
                marginTop={true}
                shrink={false}
                defaultValue={horariosFixos}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('horariosFixos')}
                label="Horarios Atendimento AO"
                helpMessage="Ex.: 900,945,1030,1115,1200,1245,1330,1415,1500,1545,1630,1715"
                inputRef={this.inputHorarioAtendimentoAO}
              />

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

HorarioAtendimentoAODialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(HorarioAtendimentoAODialogPage)
