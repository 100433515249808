import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExibicaoConflitoHorariosDialog from 'pages/erp/painel/atendimento/ExibicaoConflitoHorariosDialog'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputHorizontalContainer from 'support/components/input/container/InputHorizontalContainer'
import InputHorario from 'support/components/input/InputHorario'
import InputMultiDate from 'support/components/input/InputMultiDate'
import InputText from 'support/components/input/InputText'
import SelectableContent from 'support/components/list/SelectableContent'
import DialogPage from 'support/components/page/DialogPage'
import { createErrorsMap, focusFirstElementWithError, HiddenSubmitButton } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class BloqueioHorarioGrupoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      title: !this.props.idBloqueioHorarioGrupo ? 'Criar' : 'Alterar',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputDescricao = React.createRef()
    this.horarioInicioFunctionsMap = {}
    this.horarioTerminoFunctionsMap = {}
    this.datasFunctionsMap = {}
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })
    getAPI({
      url: 'erp/colaboradores.buscarDadosBloqueioGrupoPrePersistencia',
      params: {
        idGrupo: this.props.idBloqueioHorarioGrupo
      },
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState((state) => ({
          colaboradores: this.prepararColaboradores(response.data.colaboradores, response.data.bloqueioHorarioGrupo ? response.data.bloqueioHorarioGrupo.colaboradores : null),
          bloqueioHorarioGrupo: response.data.bloqueioHorarioGrupo
        }))
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  prepararColaboradores = (colaboradores, grupoColaboradores) => {
    for (let colaborador of colaboradores) {
      colaborador._checked = true
    }
    if (grupoColaboradores && grupoColaboradores.length > 0) {
      main: for (let colaborador of colaboradores) {
        for (let grupoColaborador of grupoColaboradores) {
          if (grupoColaborador.colaborador.id === colaborador.id) {
            continue main
          }
        }
        colaborador._checked = false
      }
    }
    colaboradores.sort(function (a, b) {
      if (a.nome < b.nome) return -1
      if (a.nome > b.nome) return 1
      return 0
    })
    return colaboradores
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            showOnlyMobile: true,
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  changeExibicaoColaborador = (idColaborador, show) => {
    const colaboradores = this.state.colaboradores.slice()
    let nenhumChecked = true
    let colaboradorAlterado = null
    for (let i = 0; i < colaboradores.length; i++) {
      const colaborador = colaboradores[i]
      if (colaborador.id === idColaborador) {
        colaboradorAlterado = colaborador
        colaboradorAlterado._checked = show
      }
      if (colaborador._checked === true) {
        nenhumChecked = false
      }
    }
    if (nenhumChecked === true && colaboradorAlterado) {
      colaboradorAlterado._checked = true
    }
    this.setState({ colaboradores: colaboradores })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = (ignorarConflitosHorarios) => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    const dados = this.getDadosBloqueioHorarioGrupo()
    dados.ignorarConflitosHorarios = ignorarConflitosHorarios

    postAPI({
      url: 'erp/colaboradores.persistirBloqueioHorarioGrupo',
      data: dados,
      requerAutorizacao: true,
      onSuccess: (response) => {
        EventsManager.pub('ManutencaoBloqueioHorarioGrupo')
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        if (response.code === 400 && response.data.errors.conflitosHorarios) {
          openBackableDialog(ExibicaoConflitoHorariosDialog, {
            descricao: 'O sistema encontrou um ou mais conflitos de horários para este bloqueio de horário:',
            conflitosHorarios: response.data.errors.conflitosHorarios,
            onConfirm: () => {
              this.salvar(true)
            }
          })
          this.setState({ ajaxing: false, errorsMap: createErrorsMap() })
        } else {
          this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
          focusFirstElementWithError('formBloqueioHorarioGrupo')
        }
      }
    })
  }

  getDadosBloqueioHorarioGrupo = () => {
    const dados = {}
    if (this.props.idBloqueioHorarioGrupo) {
      dados.id = this.props.idBloqueioHorarioGrupo
    }
    dados.descricao = this.inputDescricao.current.value
    dados.horarioInicio = this.horarioInicioFunctionsMap.getHorarioAsInt()
    dados.horarioTermino = this.horarioTerminoFunctionsMap.getHorarioAsInt()
    dados.datas = this.datasFunctionsMap.getValue()
    dados.colaboradores = this.state.colaboradores.filter((colaborador) => colaborador._checked)
    return dados
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { errorsMap, ajaxing } = this.state
    const { descricao, horarioInicio, horarioTermino, datas } = this.state.bloqueioHorarioGrupo ? this.state.bloqueioHorarioGrupo : {}

    let descricaoDefaultValue = this.props.descricao
    if (descricao) {
      descricaoDefaultValue = descricao
    }

    let datasDefaultValue = null
    if (datas && datas.length > 0) {
      datasDefaultValue = []
      for (let data of datas) {
        datasDefaultValue.push(data.data)
      }
    }

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth="default"
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadFunction={this.carregarMain}>
          {() => (
            <form id="formBloqueioHorarioGrupo" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <InputText
                idname="xdescricao"
                customVariant={'outlined-small'}
                shrink={false}
                defaultValue={descricaoDefaultValue}
                autoFocus={true}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('descricao')}
                label="Descricao"
                inputRef={this.inputDescricao}
              />

              <InputHorizontalContainer
                style={{ marginTop: 12 }}
                inputTexts={[
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <InputHorario
                        disabled={ajaxing}
                        functionsMap={this.horarioInicioFunctionsMap}
                        keyboard={true}
                        idname="xhorarioinicio"
                        errorMessage={errorsMap('horarioInicio')}
                        style={{ marginTop: 0 }}
                        defaultValue={horarioInicio}
                        placeholder="Horário Início"
                        customVariant="outlined-small"
                        shrink={false}
                      />
                    )
                  },
                  {
                    containerProps: { xs: 12, sm: 6 },
                    input: (
                      <InputHorario
                        disabled={ajaxing}
                        functionsMap={this.horarioTerminoFunctionsMap}
                        keyboard={true}
                        idname="xhorariotermino"
                        errorMessage={errorsMap('horarioTermino')}
                        style={{ marginTop: 0 }}
                        defaultValue={horarioTermino}
                        placeholder="Horário Término"
                        customVariant="outlined-small"
                        shrink={false}
                      />
                    )
                  }
                ]}
              />

              <InputMultiDate
                idname="xdatas"
                marginTop={true}
                customVariant={'outlined-small'}
                shrink={false}
                defaultValue={datasDefaultValue}
                disabled={this.state.ajaxing}
                errorMessage={errorsMap('datas')}
                label="Datas"
                functionsMap={this.datasFunctionsMap}
              />

              {this.state.colaboradores.map((colaborador, index) => {
                return (
                  <div key={colaborador.id}>
                    <SelectableContent
                      key={index}
                      style={{ marginTop: index === 0 ? 20 : 10 }}
                      color="primary"
                      onChange={(event) => {
                        this.changeExibicaoColaborador(colaborador.id, event.target.checked)
                      }}
                      checked={colaborador._checked}
                      label={<Typography variant="body2">{colaborador.apelido}</Typography>}
                      selectable={true}
                    />
                  </div>
                )
              })}

              <HiddenSubmitButton />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button
                    disabled={this.state.ajaxing}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.salvar()
                    }}
                  >
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

BloqueioHorarioGrupoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BloqueioHorarioGrupoDialogPage)
