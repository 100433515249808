import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import moment from 'moment'
import InputTipoVenda from 'pages/erp/painel/venda/input/InputTipoVenda'
import VendasResponsiveTable from 'pages/erp/painel/venda/table/VendasResponsiveTable'
import VendaPopup from 'pages/erp/painel/venda/VendaPopup'
import PropTypes from 'prop-types'
import { openBackablePopup } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import TabContainer from 'support/components/tab/TabContainer'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import TipoVendaEnum from 'support/domain/venda/TipoVendaEnum'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'
import { createEnum } from 'support/util/EnumUtil'

const styles = (theme) => ({})

const OpcoesPeriodoFiltroVendasEnum = createEnum({
  ANTIGAS: { id: 1, descricao: 'Até Hoje' },
  PROXIMAS: { id: 2, descricao: 'Futuros' }
})

class TabVendas extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    const periodoFiltro = OpcoesPeriodoFiltroVendasEnum.ANTIGAS
    const datas = this.getIntervaloDatasPorPeriodoFiltro(periodoFiltro)

    this.state = {
      lista: [],
      cliente: this.props.cliente,
      periodoFiltro: periodoFiltro,
      dataInicial: datas.dataInicial,
      dataFinal: datas.dataFinal,
      ajaxing: false
    }

    this.loaderFunctionsMap = {}
    this.dataExporterFunctionsMap = {}
  }

  componentDidMount() {
    this.eventsManager.sub(['ManipulacaoVenda', 'ManipulacaoVendas'], (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  handleChangePeriodoFiltro(periodoFiltro) {
    const datas = this.getIntervaloDatasPorPeriodoFiltro(periodoFiltro)
    this.setState({
      periodoFiltro: periodoFiltro,
      dataInicial: datas.dataInicial,
      dataFinal: datas.dataFinal
    })
  }

  getIntervaloDatasPorPeriodoFiltro = (periodoFiltro) => {
    let dataInicial = null
    let dataFinal = null

    if (periodoFiltro.id === OpcoesPeriodoFiltroVendasEnum.PROXIMAS.id) {
      dataInicial = moment().add(1, 'days')
    } else {
      dataFinal = moment()
    }
    return {
      dataInicial: dataInicial,
      dataFinal: dataFinal
    }
  }

  getLoaderKey = () => {
    const { dataInicial, dataFinal, tipoVenda } = this.state

    let key = 'loader'

    if (dataInicial) {
      key += '-di' + dataInicial
    }

    if (dataFinal) {
      key += '-df' + dataFinal
    }

    if (tipoVenda) {
      key += '-t' + tipoVenda.id
    }

    return key
  }

  abrirVendaPopup = (event, venda) => {
    event.stopPropagation()
    openBackablePopup(VendaPopup, {
      clientX: event.clientX,
      clientY: event.clientY,
      anchorReference: 'anchorPosition',
      origem: 'cliente',
      venda: venda
    })
  }

  downloadRelatorio = (event) => {
    const { dataInicial, dataFinal } = this.state

    let filename = 'Lista de Vendas'
    if (dataInicial || dataFinal) {
      filename += ' - ' + formatarPeriodoNomeArquivo(dataInicial, dataFinal)
    }

    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: filename,
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'vendas.gerarListaVendas',
        params: this.getRequestParameters()
      }
    })
  }

  getRequestParameters = () => {
    return {
      idCliente: this.state.cliente.id,
      tipo: this.state.tipoVenda ? this.state.tipoVenda.id : null,
      dataInicial: converterMomentParaDataInt(this.state.dataInicial),
      dataFinal: converterMomentParaDataInt(this.state.dataFinal),
      buscarStatusClienteFaltou: true
    }
  }

  render() {
    const { tipoVenda, periodoFiltro } = this.state

    return (
      <TabContainer>
        <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />
        <VendasResponsiveTable
          header={
            <Grid container alignItems="center" style={{ marginBottom: 16 }}>
              <Grid item style={{ marginRight: 4 }}>
                <InputTipoVenda
                  fullWidth={false}
                  shrink={false}
                  customVariant="naked"
                  nakedLeftPadding={false}
                  defaultValue={tipoVenda ? tipoVenda.id : null}
                  onChange={(event) => {
                    const tipoVenda = TipoVendaEnum.getById(parseInt(event.target.value, 10))
                    this.setState({ tipoVenda: tipoVenda })
                  }}
                />
              </Grid>
              <Grid item xs style={{ marginRight: 4 }}>
                <InputSelect
                  fullWidth={false}
                  shrink={false}
                  displayEmpty={true}
                  defaultValue={periodoFiltro.id}
                  customVariant="naked"
                  onChange={(event) => {
                    this.handleChangePeriodoFiltro(OpcoesPeriodoFiltroVendasEnum.getById(parseInt(event.target.value, 10)))
                  }}
                >
                  {OpcoesPeriodoFiltroVendasEnum.values().map((periodo) => (
                    <MenuItem key={periodo.id} value={periodo.id}>
                      {periodo.descricao}
                    </MenuItem>
                  ))}
                </InputSelect>
              </Grid>
              <Grid item>
                {this.state.lista.length > 0 && (
                  <IconButton color="secondary" variant="text" style={{ padding: 4 }} onClick={this.downloadRelatorio}>
                    <GetAppIcon style={{ display: 'block' }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          }
          origem="cliente"
          scrollElement={this.props.scrollElement}
          itemsPerRequest={15}
          loaderFunctionsMap={this.loaderFunctionsMap}
          contextoUsuario="erp"
          endpoint="erp/vendas.buscarVendas"
          getRequestParametersFunction={this.getRequestParameters}
          items={this.state.lista}
          loaderKey={this.getLoaderKey()}
          loadTrackingFunction={(data) => {
            if (data.status === 'loading') {
              if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                this.setState({ lista: [] })
              }
            } else if (data.status === 'loaded') {
              this.setState({ lista: data.items })
            }
          }}
          onClickRow={(item, event) => {
            this.abrirVendaPopup(event, item)
          }}
          emptyListProps={{
            padding: false,
            text: 'Nenhuma atividade foi encontrada'
          }}
        />
      </TabContainer>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

TabVendas.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TabVendas)
