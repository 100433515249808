import { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import MinhaContaPanelPage from 'pages/conta/painel/MinhaContaPanelPage'
import AgendamentoOnlinePanelPage from 'pages/erp/painel/agendamentoonline/AgendamentoOnlinePanelPage'
import AtendimentosPanelPage from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import CaixasPanelPage from 'pages/erp/painel/caixa/CaixasPanelPage'
import FluxoCaixaPanelPage from 'pages/erp/painel/caixa/FluxoCaixaPanelPage'
import ClientesPanelPage from 'pages/erp/painel/cliente/ClientesPanelPage'
import ColaboradoresPanelPage from 'pages/erp/painel/colaborador/ColaboradoresPanelPage'
import ConfiguracoesPanelPage from 'pages/erp/painel/configuracoes/ConfiguracoesPanelPage'
import DespesasPanelPage from 'pages/erp/painel/despesas/DespesasPanelPage'
import ProdutosPanelPage from 'pages/erp/painel/inventario/ProdutosPanelPage'
import NegociosPanelPage from 'pages/erp/painel/negocio/NegociosPanelPage'
import NfPanelPage from 'pages/erp/painel/notafiscal/ApresentacaoNfPanelPage'
import NfsPanelPage from 'pages/erp/painel/notafiscal/NfsPanelPage'
import PacotesPanelPage from 'pages/erp/painel/pacotes/PacotesPanelPage'
import PanelPageErpContent from 'pages/erp/painel/PanelPageErpContent'
import PlanoPanelPage from 'pages/erp/painel/plano/PlanoPanelPage'
import RelatoriosPanelPage from 'pages/erp/painel/relatorio/RelatoriosPanelPage'
import RemuneracoesPanelPage from 'pages/erp/painel/remuneracao/RemuneracoesPanelPage'
import ServicosPanelPage from 'pages/erp/painel/servico/ServicosPanelPage'
import SuporteChatPanelPage from 'pages/erp/painel/suporte/SuporteChatPanelPage'
import TermosEPrivacidadePanelPage from 'pages/erp/painel/termoseprivacidade/TermosEPrivacidadePanelPage'
import UltimasAtualizacoesPanelPage from 'pages/erp/painel/ultimasatualizacoes/UltimasAtualizacoesPanelPage'
import NotasPanelPage from 'pages/erp/painel/venda/NotasPanelPage'
import VendasPanelPage from 'pages/erp/painel/venda/VendasPanelPage'
import { ROUTE_ENTERPRISE_PANEL } from 'pages/RouteMap'
import EventsManager from 'support/components/eventsmanager/EventsManager'

import CentraldeAjudaPanelPage from './centraldeajuda'
import { MaquininhaIntegradaPanelPage } from './maquininhaintegrada/MaquininhaIntegradaPanelPage'

class RoutesErp extends Component {
  /*shouldComponentUpdate(nextProps, nextState) {

		if (this.props.desktopDrawerOpen !== undefined && this.props.desktopDrawerOpen !== null) {
			if((this.props.desktopDrawerOpen !== nextProps.desktopDrawerOpen || this.props.mobileDrawerOpen !== nextProps.mobileDrawerOpen) && this.props.panelPagesProps.toolbarExpanded === nextProps.panelPagesProps.toolbarExpanded){
				console.log("[OTIMIZACAO]: render RoutesErp");
				return false;
			}

		}
		return true;

	}*/

  componentDidMount() {
    this.url = window.document.location.href
  }

  render() {
    if (
      this.props.paginaCarregada &&
      this.props.colaboradorSessao.carregado === false &&
      !window.location.pathname.endsWith('/negocios') &&
      !window.location.pathname.endsWith('/minhaconta') &&
      !window.location.pathname.endsWith('/suporte/chat')
    ) {
      EventsManager.pub('NavegarPaginaPainel', { rota: '/negocios', adicionarDadosNegocioSessao: false })
      return []
    }

    const panelPagesProps = { ...this.props, sendPageViewGA: true }

    return (
      <Switch>
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador?/p/negocios'}
          render={(props) => <PanelPageErpContent title="Negócios" {...panelPagesProps} component={NegociosPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador?/p/minhaconta'}
          render={(props) => <PanelPageErpContent title="Minha Conta" {...panelPagesProps} component={MinhaContaPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/atendimentos'}
          render={(props) => <PanelPageErpContent title="Atendimentos" {...{ ...panelPagesProps, match: props.match }} component={AtendimentosPanelPage} />}
        />

        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/fluxocaixa'}
          render={(props) => <PanelPageErpContent title="Fluxo de Caixa" {...panelPagesProps} component={FluxoCaixaPanelPage} />}
        />

        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/vendas'}
          render={(props) => <PanelPageErpContent title="Vendas" {...panelPagesProps} component={VendasPanelPage} colaboradorObrigatorio={true} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/notas'}
          render={(props) => <PanelPageErpContent title="Comandas" {...panelPagesProps} component={NotasPanelPage} colaboradorObrigatorio={true} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/caixas'}
          render={(props) => <PanelPageErpContent title="Controle de Caixa" {...panelPagesProps} component={CaixasPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/remuneracoes'}
          render={(props) => <PanelPageErpContent title="Remunerações" {...panelPagesProps} component={RemuneracoesPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/servicos'}
          render={(props) => <PanelPageErpContent title="Serviços" {...panelPagesProps} component={ServicosPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/clientes'}
          render={(props) => <PanelPageErpContent title="Clientes" {...panelPagesProps} component={ClientesPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/colaboradores'}
          render={(props) => <PanelPageErpContent title="Colaboradores" {...panelPagesProps} component={ColaboradoresPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/configuracoes'}
          render={(props) => <PanelPageErpContent title="Configurações" {...panelPagesProps} component={ConfiguracoesPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/agendamentoonline'}
          render={(props) => <PanelPageErpContent title="Agendamento Online" {...panelPagesProps} component={AgendamentoOnlinePanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/plano'}
          render={(props) => <PanelPageErpContent title="Licença de Uso" {...panelPagesProps} component={PlanoPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/pacotes'}
          render={(props) => <PanelPageErpContent title="Pacotes" {...panelPagesProps} component={PacotesPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/inventario'}
          render={(props) => <PanelPageErpContent title="Produtos" {...panelPagesProps} component={ProdutosPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/despesas'}
          render={(props) => <PanelPageErpContent title="Despesas" {...panelPagesProps} component={DespesasPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/apresentacaonf'}
          render={(props) => <PanelPageErpContent title="Notas Fiscais" {...panelPagesProps} component={NfPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/nfs'}
          render={(props) => <PanelPageErpContent title="Notas Fiscais" {...panelPagesProps} component={NfsPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador/p/relatorios'}
          render={(props) => <PanelPageErpContent title="Relatórios" {...panelPagesProps} component={RelatoriosPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador?/p/termoseprivacidade'}
          render={(props) => <PanelPageErpContent title="Termos e Privacidade" {...panelPagesProps} component={TermosEPrivacidadePanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador?/p/atualizacoes'}
          render={(props) => <PanelPageErpContent title="Últimas Atualizações" {...panelPagesProps} component={UltimasAtualizacoesPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador?/p/suporte/chat'}
          render={(props) => <PanelPageErpContent title="Suporte - Chat" {...panelPagesProps} component={SuporteChatPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador?/p/centraldeajuda'}
          render={(props) => <PanelPageErpContent title="Central de ajuda" {...panelPagesProps} component={CentraldeAjudaPanelPage} />}
        />
        <Route
          path={ROUTE_ENTERPRISE_PANEL + '/:idColaborador?/p/gerenciar-maquininha'}
          render={(props) => <PanelPageErpContent title="Maquininha Integrada" {...panelPagesProps} component={MaquininhaIntegradaPanelPage} />}
        />
      </Switch>
    )
  }
}

export default RoutesErp
