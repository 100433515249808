import { useMemo, useState } from 'react'

import { grupoBoticarioTheme } from '@grupoboticario/flora'
import { Button, Flex, Heading, IconButton, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Text } from '@grupoboticario/flora-react'
import { CrossIcon } from '@grupoboticario/flora-react-icons'
import { postAPI } from 'support/components/api/PainelErpApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { StatusPagamentoEnum } from 'support/domain/mercadopago/MercadoPagoEnum'

import { MercadoPagoDialogPage } from './MercadoPagoDialogPage'
import NotaDialogPage from './NotaDialogPage'
import { styles } from './PagamentoOpenedDialogStyles'

type StatusDevice = 'ABERTO' | 'PENDENTE' | 'OUTRO_PAGAMENTO' | 'ERROR_CANCELAMENTO_COMANDA'

type PagamenteOpenedDialog = {
  isVisible: boolean
  onVisible: () => void
  pagamento?: {
    formaPagamento: object
    valorPago: number
    id: number
  }
  notaId: number
  status: StatusDevice | string
  mercadoPagoDebitPayment: {
    status: string
    deviceId: string
    notaVenda: {
      idNotaVenda: string
      numeroComanda: string
    }
  }
  device?: {
    id: string
  }
  thisNotaDialogPage: {
    setPagamentosMercadoCancelado: () => void
  }
  handleCloseDialog: () => void
}

const PagamenteOpenedDialog = ({
  device,
  isVisible,
  status,
  thisNotaDialogPage,
  notaId,
  pagamento,
  mercadoPagoDebitPayment,
  onVisible,
  handleCloseDialog
}: PagamenteOpenedDialog) => {
  const [isLoading, setIsLoading] = useState(false)

  const description = useMemo(() => {
    const descCondition = {
      ABERTO: 'Finalize o pagamento em sua Maquininha Mercado Pago ou cancele a solicitação.',
      PENDENTE: 'Finalize o pagamento ou cancele a solicitação em sua Maquininha Mercado Pago.',
      OUTRO_PAGAMENTO: 'Não é possível realizar um pagamento integrado com outro em aberto. Cancele ou finalize o seu pagamento. ',
      ERROR_CANCELAMENTO_COMANDA: 'Não é possível cancelar essa comanda pois você possui um pagamento pendente'
    }
    return descCondition[status as keyof typeof descCondition]
  }, [status])

  const title = useMemo(() => {
    const titleCondition = {
      ABERTO: 'Você possui um pagamento em aberto para essa comanda',
      PENDENTE: 'Você possui um pagamento em aberto para essa comanda.',
      OUTRO_PAGAMENTO: `Você já possui um pagamento pendente na comanda #${mercadoPagoDebitPayment?.notaVenda?.numeroComanda}`,
      ERROR_CANCELAMENTO_COMANDA: 'Para cancelar a comanda, cancele ou finalize o seu pagamento na maquininha. '
    }
    return titleCondition[status as keyof typeof titleCondition]
  }, [status, mercadoPagoDebitPayment])

  const handlePayment = () => {
    const mercadoPago = {
      formaPagamento: pagamento?.formaPagamento,
      deviceId: device?.id,
      valor: pagamento?.valorPago
    }
    openBackableDialog(MercadoPagoDialogPage, {
      mercadoPayment: {
        notaVenda: {
          id: notaId
        }
      },
      mercadoPago,
      mercadoPagoPagamentoId: pagamento?.id,
      handleCloseDialog,
      thisNotaDialogPage
    })
    onVisible()
  }

  const handleCancelPayment = () => {
    setIsLoading(true)
    postAPI({
      method: 'post',
      url: `erp/vendas/integracoes-mp.cancelar/${pagamento?.id}`,
      requerAutorizacao: true,
      onSuccess: () => {
        thisNotaDialogPage.setPagamentosMercadoCancelado()
        onVisible()
      },
      onFinally: () => {
        setIsLoading(false)
      }
    })
  }

  const handleGoToAnotherPayment = () => {
    openBackableDialog(NotaDialogPage, {
      idNota: mercadoPagoDebitPayment?.notaVenda?.idNotaVenda,
      retornarVendas: false,
      maquininha: null,
      isMercadoPagoDialogVisible: true
    })
    onVisible()
  }

  return (
    <Modal open={isVisible}>
      <ModalContent size="small" css={styles.modalContentPagamento} className={grupoBoticarioTheme}>
        <Flex justify="flex-end" align="center">
          <IconButton data-testid="close-button" css={styles.iconButtonPagamento} tabIndex={-1} aria-label="Fechar" icon={<CrossIcon />} onClick={onVisible} />
        </Flex>

        <ModalHeader css={styles.modalHeaderPagamento}>
          <Heading css={styles.headingPagamento} level="5" color="$brand-1">
            {title}
          </Heading>
        </ModalHeader>

        <ModalBody css={styles.modalBodyPagamento}>
          <Text css={styles.descriptionPagamento}>{description}</Text>
        </ModalBody>

        <ModalFooter css={styles.modalFooterPagamento}>
          {status === StatusPagamentoEnum.ABERTO && (
            <>
              <Button isLoading={isLoading} onClick={handleCancelPayment} variant="ghost" tabIndex={0}>
                Cancelar
              </Button>
              <Button onClick={handlePayment}>Continuar pagamento</Button>
            </>
          )}
          {status === 'OUTRO_PAGAMENTO' && <Button onClick={handleGoToAnotherPayment}>Ir para comanda</Button>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { PagamenteOpenedDialog }
