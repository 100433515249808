import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'

const styles = (theme) => ({
  messageRoot: {
    marginBottom: theme.spacing(2)
  }
})

class AccessDeniedDialog extends Component {
  render() {
    const { classes, message, ...others } = this.props

    return (
      <MessageDialog
        {...others}
        title="Acesso Negado"
        text={
          <React.Fragment>
            {message ? message : 'Você não tem permissão para executar essa ação.'} Se você acredita que deve ter acesso a essa funcionalidade, entre em contato com o responsável
            pelo sistema na sua empresa.
          </React.Fragment>
        }
      />
    )
  }
}

AccessDeniedDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AccessDeniedDialog)
