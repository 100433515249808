import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import PropTypes from 'prop-types'
import InputListIntervaloHorarioDia, {
  adicionarIntervaloFormObject as adicionarIntervaloFormObjectDia,
  createFormObject as createFormObjectDia,
  removerIntervaloFormObject as removerIntervaloFormObjectDia
} from 'support/components/input/InputListIntervaloHorarioDia'
import { formatarDateHHmm, getDescricaoDiaSemanaPorNumero } from 'support/util/DateFormatter'

const styles = (theme) => ({
  iconWithText: {
    verticalAlign: 'top'
  },
  iconeIntervaloReadonly: {
    color: theme.palette.text.disabled
  },
  textoIntervaloReadonly: {
    color: theme.palette.text.secondary
  }
})

class InputListIntervaloHorarioSemana extends Component {
  constructor(props) {
    super(props)

    let horariosAtendimento = []

    if (props.defaultValue === undefined || props.defaultValue === null) {
      ;[1, 2, 3, 4, 5, 6, 7].forEach((dia) => {
        const item = { dia: dia }
        horariosAtendimento[dia] = createFormObjectDia(item)
      })
    } else {
      horariosAtendimento = this.converterMapeamentoObjetoPlano(props.defaultValue)
    }

    this.state = {
      horariosAtendimento: horariosAtendimento
    }
  }

  componentDidMount() {
    if (this.props.componentFunctions) {
      this.props.componentFunctions.getValues = () => {
        const values = []
        this.state.horariosAtendimento.forEach((intervalos, dia) => {
          intervalos.forEach((intervalo) => {
            values.push({
              id: intervalo.id,
              dia: dia,
              de: intervalo.deInputFunctionsMap.getHorarioAsInt(),
              ate: intervalo.ateInputFunctionsMap.getHorarioAsInt()
            })
          })
        })

        values.sort(function (a, b) {
          if (a.dia < b.dia) {
            return -1
          } else if (a.dia > b.dia) {
            return 1
          } else {
            if (a.key < b.key) {
              return -1
            } else if (a.key > b.key) {
              return 1
            }
          }
          return 0
        })

        return values
      }
    }
  }

  converterMapeamentoObjetoPlano = (horariosAtendimento) => {
    horariosAtendimento.sort((i1, i2) => {
      if (i1.dia > i2.dia) {
        return 1
      } else if (i1.dia < i2.dia) {
        return -1
      } else {
        if (i1.de > i2.de) {
          return 1
        } else if (i1.de < i2.de) {
          return -1
        } else {
          if (i1.ate > i2.ate) {
            return 1
          } else if (i1.ate < i2.ate) {
            return -1
          } else {
            return 0
          }
        }
      }
    })

    const horariosAtendimentoConvertido = []
    let diaAtual = 0
    let horarioAtendimentoAtual = null
    let contadorDias = 0
    horariosAtendimento.forEach((horarioAtendimento) => {
      if (horarioAtendimento.dia > contadorDias + 1) {
        for (let i = contadorDias + 1; i < horarioAtendimento.dia; i++) {
          horariosAtendimentoConvertido.push({
            dia: i,
            intervalos: []
          })
        }
      }

      if (horarioAtendimento.dia !== diaAtual) {
        horarioAtendimentoAtual = {
          dia: horarioAtendimento.dia,
          intervalos: []
        }
        diaAtual = horarioAtendimento.dia
        horariosAtendimentoConvertido.push(horarioAtendimentoAtual)
      }
      horarioAtendimentoAtual.intervalos.push({
        id: horarioAtendimento.id,
        de: horarioAtendimento.de,
        ate: horarioAtendimento.ate
      })
      contadorDias = horarioAtendimento.dia
    })

    if (contadorDias < 7) {
      for (let i = contadorDias + 1; i <= 7; i++) {
        horariosAtendimentoConvertido.push({
          dia: i,
          intervalos: []
        })
      }
    }

    const horariosAtendimentoFinal = []
    horariosAtendimentoConvertido.forEach((dia) => {
      horariosAtendimentoFinal[dia.dia] = createFormObjectDia(dia)
    })

    return horariosAtendimentoFinal
  }

  handleAdicionarIntervaloHorario = (dia) => {
    const horariosAtendimento = this.state.horariosAtendimento.slice()
    adicionarIntervaloFormObjectDia(horariosAtendimento[dia])
    this.setState((state) => ({ horariosAtendimento: horariosAtendimento }))
  }

  handleRemoverIntervaloHorario = (dia, index) => {
    const horariosAtendimento = this.state.horariosAtendimento.slice()
    removerIntervaloFormObjectDia(horariosAtendimento[dia], index)
    this.setState((state) => ({ horariosAtendimento: horariosAtendimento }))
  }

  render() {
    const { classes } = this.props
    const { adicionarSeparador, adicionarUltimoSeparador } = this.props

    let horariosAtendimento = []
    if (this.props.readOnly && this.props.readOnly === true) {
      if (this.props.value && this.props.value.length > 0) {
        horariosAtendimento = this.converterMapeamentoObjetoPlano(this.props.value)
      }
    } else {
      horariosAtendimento = this.state.horariosAtendimento
    }

    let horariosAtendimentoLength = horariosAtendimento.length
    let contatorItems = 1

    let semanaContent = null
    if (this.props.readOnly && this.props.readOnly === true) {
      const showUnavailableDays = this.props.showUnavailableDays !== undefined ? this.props.showUnavailableDays : false
      let exibiuAlgumDia = false

      if (horariosAtendimento && horariosAtendimento.length > 0) {
        semanaContent = horariosAtendimento.map((intervalos, dia) => {
          const content = []

          if (showUnavailableDays === false && (!intervalos || intervalos.length === 0)) {
            return null
          }

          content.push(
            <Grid key={'dia' + dia} container spacing={2} style={{ marginTop: !exibiuAlgumDia && this.props.extraMarginTopWhenNotEmpty ? 20 : 0 }}>
              <Grid item>
                <AccessTimeIcon className={classes.iconWithText} fontSize="small" />
              </Grid>
              <Grid item xs>
                <Typography variant="body2">{getDescricaoDiaSemanaPorNumero(dia)}</Typography>
              </Grid>
            </Grid>
          )

          if (intervalos && intervalos.length > 0) {
            content.push(
              intervalos.map((intervalo, index) => {
                return (
                  <Grid key={'dia' + dia + 'in' + index} container spacing={2} style={{ paddingLeft: 16 }}>
                    <Grid item>
                      <SubdirectoryArrowRightIcon className={classes.iconeIntervaloReadonly} fontSize="small" />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body2" className={classes.textoIntervaloReadonly}>
                        {formatarDateHHmm(intervalo.de) + ' - ' + formatarDateHHmm(intervalo.ate)}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              })
            )
          } else {
            content.push(
              <Grid key={'dia' + dia + 'vazio'} container spacing={2} style={{ paddingLeft: 16 }}>
                <Grid item>
                  <SubdirectoryArrowRightIcon className={classes.iconeIntervaloReadonly} fontSize="small" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" className={classes.textoIntervaloReadonly}>
                    Nenhum horário cadastrado
                  </Typography>
                </Grid>
              </Grid>
            )
          }

          exibiuAlgumDia = true

          return <div style={{ marginTop: 10 }}>{content}</div>
        })
      }

      if (!exibiuAlgumDia) {
        semanaContent = (
          <Grid key={'diavazio'} container spacing={2}>
            <Grid item>
              <AccessTimeIcon className={classes.iconWithText} fontSize="small" />
            </Grid>
            <Grid item xs>
              <Typography variant="body2">Horários de atendimento não cadastrados</Typography>
            </Grid>
          </Grid>
        )
      }
    } else {
      semanaContent = horariosAtendimento.map((intervalos, dia) => {
        contatorItems++
        return (
          <InputListIntervaloHorarioDia
            key={dia}
            dia={dia}
            intervalos={intervalos}
            readOnly={this.props.readOnly}
            disabled={this.props.disabled}
            errorsMap={this.props.errorsMap}
            onChange={this.props.onChange}
            handleAdicionarIntervaloHorario={this.handleAdicionarIntervaloHorario}
            handleRemoverIntervaloHorario={this.handleRemoverIntervaloHorario}
            maxIntervalos={this.props.maxIntervalos}
            adicionarSeparador={adicionarSeparador && (contatorItems < horariosAtendimentoLength || adicionarUltimoSeparador)}
          />
        )
      })
    }

    return <div style={this.props.style}>{semanaContent}</div>
  }
}

InputListIntervaloHorarioSemana.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InputListIntervaloHorarioSemana)
