import React, { Component } from 'react'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'
import { darken, emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import CustomDialog from 'support/components/dialog/CustomDialog'
import AccessDeniedDialog from 'support/components/dialog/preconstructed/AccessDeniedDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import { searchPart } from 'support/util/StringUtil'

const DEFAULT_ROW_HEIGHT = 54

const styles = (theme) => ({
  container: {
    alignItems: 'flex-start'
  },
  root: {
    minWidth: 300,
    maxWidth: 480,
    width: '100%',
    margin: '16px 4px',
    maxHeight: 'calc(100% - 32px)'
  },
  rootTitle: {
    //borderBottom: "1px solid "+theme.palette.divider,
    margin: 0,
    padding: '16px 16px 14px 16px',
    paddingLeft: theme.spacing(3),
    position: 'relative',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    zIndex: 1
  },
  titleMainContent: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(4)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(7),
    top: theme.spacing(1)
  },
  searchIcon: {
    position: 'absolute',
    left: theme.spacing(3),
    marginLeft: -3,
    top: 22
  },
  dialogContent: {
    maxHeight: 1200,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0
  },
  rootListItem: {
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  rootListItemDisabled: {
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  contentListItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 8
  },
  primaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 15
    //fontWeight: 500
  },
  secondaryListItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: emphasize(darken(theme.palette.secondary.light, 0.15), 0.3)
  },
  iconListItem: {
    marginTop: 4,
    marginRight: -4
  }
})

const DEFAULT_ITEMS_PER_REQUEST = 20

class DialogSelect extends Component {
  constructor(props) {
    super(props)

    this.eventsManager = EventsManager.new()

    this.state = {
      lista: []
    }

    this.listaCached = []

    this.inputPesquisa = React.createRef()
    this.scrollContainerRef = React.createRef()
    this.loaderFunctionsMap = {}
  }

  componentDidMount() {
    const { eventsNameToRefreshResults, autoSelectDefinitons } = this.props
    if (eventsNameToRefreshResults && eventsNameToRefreshResults.length > 0) {
      for (let eventName of eventsNameToRefreshResults) {
        this.eventsManager.sub(eventName, (props) => {
          this.loaderFunctionsMap.load()
        })
      }
    }
    if (autoSelectDefinitons && autoSelectDefinitons.length > 0) {
      for (let definition of autoSelectDefinitons) {
        if (definition.eventName && definition.itemPropName) {
          this.eventsManager.sub(definition.eventName, (props) => {
            const item = props[definition.itemPropName]
            if (item) {
              this.select(this.props.generateItemData(item))
            }
          })
        }
      }
    }

    const trySetFocus = () => {
      window.setTimeout(() => {
        if (this.inputPesquisa.current) {
          this.inputPesquisa.current.focus()
        } else {
          trySetFocus()
        }
      }, 100)
    }
    trySetFocus()
  }

  renderRow = (item, { index, key, style }) => {
    return (
      <div key={key} style={style}>
        <ListItemSelectable
          data-testid='customer'
          classesNamesObject={this.props.classes}
          primary={item.primary}
          secondary={item.secondary}
          disabled={item.disabled}
          onClick={() => {
            this.select(item)
          }}
        />
      </div>
    )
  }

  select = (item) => {
    if (this.props.closeDialogOnSelect === false) {
      this.props.onSelect(item, this)
    } else {
      this.props.handleCloseDialog({
        onClosedCallback: () => {
          this.props.onSelect(item, this)
        }
      })
    }
  }

  handleSearchChange = (event) => {
    if (this.props.loadAllAndCache) {
      const termo = this.inputPesquisa.current ? this.inputPesquisa.current.value.trim() : ''
      const listaFiltrada = []
      for (let item of this.listaCached) {
        if (searchPart(item.label, termo)) {
          item._update = true
          listaFiltrada.push(item)
        }
      }
      this.setState({ lista: listaFiltrada })
    } else {
      this.loaderFunctionsMap.load()
    }
  }

  prepareEmptyListProps = () => {
    const { creationPermission } = this.props
    if (this.props.emptyListProps) {
      return {
        ...this.props.emptyListProps,
        creationFunction: (props) => {
          if (!creationPermission) {
            openBackableDialog(AccessDeniedDialog)
            return
          }

          if (!props) {
            props = {}
          }

          props.text = this.inputPesquisa.current ? this.inputPesquisa.current.value : null
          this.props.emptyListProps.creationFunction(props)
        }
      }
    } else {
      return null
    }
  }

  render() {
    const { classes, creationFunction, creationPermission } = this.props
    const { lista } = this.state

    return (
      <CustomDialog classes={{ container: classes.container, paper: classes.root }} dialogProps={this.props} disabledOnClose={this.state.ajaxing}>
        <DialogTitle disableTypography className={classes.rootTitle}>
          <SearchIcon className={classes.searchIcon} />
          <div className={classes.titleMainContent}>
            <InputText
              autoFocus
              placeholder={this.props.inputSearchPlaceholder}
              customVariant="naked"
              nakedLeftPadding={false}
              onChange={this.handleSearchChange}
              inputRef={this.inputPesquisa}
            />
          </div>
          {creationFunction && (
            <IconButton
              className={classes.addButton}
              color="secondary"
              onClick={() => {
                if (!creationPermission) {
                  openBackableDialog(AccessDeniedDialog)
                  return
                }

                creationFunction({ text: this.inputPesquisa.current ? this.inputPesquisa.current.value : null })
              }}
            >
              <AddIcon />
            </IconButton>
          )}
          <IconButton className={classes.closeButton} onClick={this.props.handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <RootRef rootRef={this.scrollContainerRef}>
          <DialogContent className={classNames(classes.dialogContent, 'needsWillChange')} style={{ wwillChange: 'ttransform' }}>
            <VirtualizedList
              rowHeight={DEFAULT_ROW_HEIGHT}
              windowScroller={true}
              scrollElement={this.scrollContainerRef.current}
              itemsPerRequest={this.props.loadAllAndCache ? false : DEFAULT_ITEMS_PER_REQUEST}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario={this.props.contextoUsuario ? this.props.contextoUsuario : 'erp'}
              endpoint={this.props.endpoint}
              getRequestParametersFunction={() => ({
                [this.props.searchParamName]: this.inputPesquisa.current ? this.inputPesquisa.current.value : null,
                ...(this.props.getRequestParametersFunction ? this.props.getRequestParametersFunction() : {})
              })}
              items={lista}
              autoIncrementNewItems={false}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  let listaCarregada = []

                  if (this.props.preProcessItemsFunction) {
                    listaCarregada = this.props.preProcessItemsFunction(data.items)
                  } else {
                    listaCarregada = data.items
                  }

                  if (listaCarregada.length && this.props.preProcessItemFunction) {
                    let listaProcessadaPorItem = []
                    for (let i = 0; i < listaCarregada.length; i++) {
                      const item = listaCarregada[i]
                      let itemProcessado = this.props.preProcessItemFunction(item)
                      if (itemProcessado !== null) {
                        listaProcessadaPorItem.push(itemProcessado)
                      }
                    }
                    listaCarregada = listaProcessadaPorItem
                  }

                  if (listaCarregada.length > 0) {
                    for (let i = 0; i < listaCarregada.length; i++) {
                      listaCarregada[i] = this.props.generateItemData(listaCarregada[i])
                    }
                  }

                  if (this.props.loadAllAndCache) {
                    this.listaCached = listaCarregada.slice(0)
                  } else if (data.action === 'loadmore') {
                    listaCarregada = lista.concat(listaCarregada)
                  }

                  this.setState({ lista: listaCarregada })
                }
              }}
              rowRenderer={this.renderRow}
              emptyListProps={this.prepareEmptyListProps()}
            />
          </DialogContent>
        </RootRef>
      </CustomDialog>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

function ListItemSelectable(props) {
  let onClick = props.onClick
  let classes = [props.classesNamesObject.rootListItem]
  let gridContentStyle = {}
  if (props.disabled) {
    classes.push(props.classesNamesObject.rootListItemDisabled)
    gridContentStyle.opacity = 0.25
    onClick = null
  }

  return (
    <Grid container alignItems="center" className={classNames(classes)} onClick={onClick}>
      <Grid data-testid='customer' item xs className={props.classesNamesObject.contentListItem} style={gridContentStyle}>
        <Typography variant="body1" className={props.classesNamesObject.primaryListItem}>
          {props.primary}
        </Typography>
        {props.secondary && (
          <Typography variant="body2" className={props.classesNamesObject.secondaryListItem}>
            {props.secondary}
          </Typography>
        )}
      </Grid>
      <Grid item style={gridContentStyle}>
        <NavigateNextIcon className={props.classesNamesObject.iconListItem} />
      </Grid>
    </Grid>
  )
}

DialogSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DialogSelect)
