import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    marginLeft: 0
  },
  checkbox: {
    padding: 0,
    marginRight: 8,
    verticalAlign: 'top'
  }
})

class SelectableContent extends Component {
  render() {
    const { classes } = this.props
    return (
      <FormControlLabel
        style={this.props.style}
        classes={{ root: classes.root }}
        className={this.props.className}
        label={this.props.label}
        control={
          <Checkbox
            className={classes.checkbox}
            style={{ display: this.props.selectable ? 'inline-block' : 'none' }}
            disabled={this.props.disabled}
            checked={this.props.checked}
            color={this.props.color}
            onClick={this.props.selectable ? this.props.onClick : null}
            onChange={this.props.selectable ? this.props.onChange : null}
          />
        }
      />
    )
  }
}

SelectableContent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SelectableContent)
