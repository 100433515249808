import Cookies from 'js-cookie'
import { createEnum } from 'support/util/EnumUtil'

export const OSEnum = createEnum({
  ANDROID: { id: 1, code: 'android' },
  IOS: { id: 2, code: 'ios' }
})

const URL_PARAM_OS = 'os'
const URL_PARAM_OS_ANDROID_API_LEVEL = 'osapilevel'
const URL_PARAM_OS_ANDROID_VERSION = 'osversion'
const URL_PARAM_OS_IOS_VERSION = 'osversion'
const URL_PARAM_APP_VERSION = 'appversion'

const cookiesOptions = { domain: '.salao99.com.br' }

const DeviceIdentifier = {
  KEY_DEVICE_OS: 's99DeviceOS',
  KEY_ANDROID_OS_API_LEVEL: 's99AndroidApiLevel',
  KEY_ANDROID_OS_VERSION: 's99AndroidVersion',
  KEY_IOS_OS_VERSION: 's99IOSVersion',
  KEY_APP_VERSION: 's99AppVersion',

  registerDevice: function (urlParams) {
    if (urlParams[URL_PARAM_OS] === OSEnum.ANDROID.code) {
      localStorage.setItem(DeviceIdentifier.KEY_DEVICE_OS, urlParams[URL_PARAM_OS])
      localStorage.setItem(DeviceIdentifier.KEY_ANDROID_OS_API_LEVEL, urlParams[URL_PARAM_OS_ANDROID_API_LEVEL])
      localStorage.setItem(DeviceIdentifier.KEY_ANDROID_OS_VERSION, urlParams[URL_PARAM_OS_ANDROID_VERSION])
      localStorage.setItem(DeviceIdentifier.KEY_APP_VERSION, urlParams[URL_PARAM_APP_VERSION])

      Cookies.set(DeviceIdentifier.KEY_DEVICE_OS, urlParams[URL_PARAM_OS], cookiesOptions)
      Cookies.set(DeviceIdentifier.KEY_ANDROID_OS_API_LEVEL, urlParams[URL_PARAM_OS_ANDROID_API_LEVEL], cookiesOptions)
      Cookies.set(DeviceIdentifier.KEY_ANDROID_OS_VERSION, urlParams[URL_PARAM_OS_ANDROID_VERSION], cookiesOptions)
      Cookies.set(DeviceIdentifier.KEY_APP_VERSION, urlParams[URL_PARAM_APP_VERSION], cookiesOptions)
    } else if (urlParams[URL_PARAM_OS] === OSEnum.IOS.code) {
      localStorage.setItem(DeviceIdentifier.KEY_DEVICE_OS, urlParams[URL_PARAM_OS])
      localStorage.setItem(DeviceIdentifier.KEY_IOS_OS_VERSION, urlParams[URL_PARAM_OS_IOS_VERSION])
      localStorage.setItem(DeviceIdentifier.KEY_APP_VERSION, urlParams[URL_PARAM_APP_VERSION])

      Cookies.set(DeviceIdentifier.KEY_DEVICE_OS, urlParams[URL_PARAM_OS], cookiesOptions)
      Cookies.set(DeviceIdentifier.KEY_IOS_OS_VERSION, urlParams[URL_PARAM_OS_IOS_VERSION], cookiesOptions)
      Cookies.set(DeviceIdentifier.KEY_APP_VERSION, urlParams[URL_PARAM_APP_VERSION], cookiesOptions)
    }
  },

  getDeviceOS: function () {
    return localStorage.getItem(DeviceIdentifier.KEY_DEVICE_OS)
  },

  isNativeApp: function () {
    if (DeviceIdentifier.isNativeAndroid() || DeviceIdentifier.isNativeIOS()) {
      return true
    } else {
      return false
    }
  },

  isNativeAndroid: function () {
    if (DeviceIdentifier.getDeviceOS() === OSEnum.ANDROID.code) {
      return true
    } else {
      return false
    }
  },

  isNativeIOS: function () {
    if (DeviceIdentifier.getDeviceOS() === OSEnum.IOS.code) {
      return true
    } else {
      return false
    }
  }
}

export default DeviceIdentifier
