import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DoneIcon from '@material-ui/icons/Done'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import SearchIcon from '@material-ui/icons/Search'
import StarIcon from '@material-ui/icons/Star'
import SubjectIcon from '@material-ui/icons/Subject'
import classNames from 'classnames'
import AgendarAtendimentosDialog from 'pages/portal/negocio/AgendarAtendimentosDialog'
import NegocioAvaliacoesDialog from 'pages/portal/negocio/NegocioAvaliacoesDialog'
import NegocioFotosDialog from 'pages/portal/negocio/NegocioFotosDialog'
import NegocioInformacoesDialog from 'pages/portal/negocio/NegocioInformacoesDialog'
import PaginaNaoEncontradaContent from 'pages/portal/PaginaNaoEncontradaContent'
import PortalPageFooter from 'pages/portal/PortalPageFooter'
import { ROUTE_CONTA_MAIN, ROUTE_PORTAL_PANEL } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import { getAPI } from 'support/components/api/PortalApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import CookieConsentManager from 'support/components/cookieconsent/CookieConsentManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import NakedExpansionPanel from 'support/components/expansionpanel/NakedExpansionPanel'
import LoginIcon from 'support/components/icon/Login'
import InputText from 'support/components/input/InputText'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import LoginAvatar from 'support/components/loginavatar/LoginAvatar'
import ControleAgendamentoOnlineEnum from 'support/domain/negocio/ControleAgendamentoOnlineEnum'
import NegocioService from 'support/domain/negocio/NegocioService'
import ServicoService from 'support/domain/servico/ServicoService'
import TipoExibicaoPrecoEnum from 'support/domain/servico/TipoExibicaoPrecoEnum'
import { converterDataIntParaMoment, converterMomentParaDataInt } from 'support/util/DateConverter'
import ImageUtil from 'support/util/ImageUtil'
import LocalizacaoFormatter from 'support/util/LocalizacaoFormatter'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import StringUtil from 'support/util/StringUtil'
import { formatarDuracao } from 'support/util/TimeFormatter'

import Constants from '../../../Constants'

const ID_CATEGORIA_SERVICOS_POPULARES = 0

let ITEM_ID_GENERATOR = 0
const PREFERENCE_KEY_ITENS_RECUPERACAO = 'Portal_NegocioPageItensRecuperacao'

export function prepararListaItensParaRecuperacaoEstado(idNegocio, data, itens) {
  const globalLocalPreferences = LocalPreferences.new('global', null)
  const itensParaRecuperacao = []
  const idVariacoesUtilizadas = []
  for (let item of itens) {
    if (idVariacoesUtilizadas.indexOf(item.variacao.id) < 0) {
      idVariacoesUtilizadas.push(item.variacao.id)
      itensParaRecuperacao.push({
        servico: item.servico,
        variacao: item.variacao,
        colaborador: item.colaborador
      })
    }
  }

  globalLocalPreferences.setPreference(
    PREFERENCE_KEY_ITENS_RECUPERACAO,
    { idNegocio: idNegocio, data: data ? converterMomentParaDataInt(data) : null, itens: itensParaRecuperacao },
    { type: 'permanent' }
  )
}

function recuperarEstadoItens() {
  const globalLocalPreferences = LocalPreferences.new('global', null)
  const itensRecuperados = globalLocalPreferences.getPreference(PREFERENCE_KEY_ITENS_RECUPERACAO)
  if (itensRecuperados && itensRecuperados.data) {
    itensRecuperados.data = converterDataIntParaMoment(itensRecuperados.data)
  }
  globalLocalPreferences.setPreference(PREFERENCE_KEY_ITENS_RECUPERACAO, null, { type: 'permanent' })
  return itensRecuperados
}

const WIDTH_HEADER_MODELO2 = 780
const WIDTH_SERVICOS_MODELO2 = 510

const styles = (theme) => ({
  modelo1: {
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      display: 'none'
    }
  },
  modelo2: {
    [theme.breakpoints.up(WIDTH_HEADER_MODELO2)]: {
      display: 'none'
    }
  },
  header: {
    //borderBottom: "1px solid rgba(0,0,0,0.1)"
  },
  content: {
    ...theme.container.basic(),
    ...theme.container.centered({ maxWidth: 1200 })
  },
  headerButtonRoot: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    '& svg': {
      fontSize: '28px !important'
    }
  },
  infoHeaderContent: {
    marginTop: 14,
    paddingBottom: 46,
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      paddingBottom: 40
    }
    //borderBottom: "1px solid rgba(0,0,0,0.13)"
  },
  containerNegocioFotoPerfil: {
    marginRight: 40,
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      flexGrow: 0,
      marginRight: 0,
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  negocioFotoPerfil: {
    width: 150,
    height: 150,
    display: 'block',
    borderRadius: 160,
    marginTop: 0,
    boxShadow: '1px 2px 14px rgba(0,0,0,0.035)',
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  containerNegocioInfo: {
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      textAlign: 'center',
      '& p, & button': {
        textAlign: 'center'
      }
    }
  },
  negocioNome: {
    fontSize: 32,
    lineHeight: '36px',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      marginTop: 24
    },
    [theme.breakpoints.down(525)]: {
      fontSize: 32,
      lineHeight: '36px',
      marginBottom: 13
    },
    [theme.breakpoints.down(425)]: {
      fontSize: 30,
      lineHeight: '34px',
      marginBottom: 14
    }
  },
  negocioEndereco: {
    marginBottom: 2,
    fontWeight: 500,
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      marginTop: 18,
      marginBottom: 10
    }
  },
  containerNegocioAvalicoes: {
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      marginBottom: 4
    }
  },
  negocioAvalicoesBotaoRoot: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: -6,
    marginTop: 2,
    color: '#fdc050'
  },
  negocioAvalicoesBotaoIcone: {
    color: '#fdc050',
    fontSize: '24px !important',
    marginTop: -4,
    marginLeft: -1,
    [theme.breakpoints.down(WIDTH_HEADER_MODELO2)]: {
      marginTop: -2
    }
  },
  negocioAvalicoesBotaoStatus: {
    paddingLeft: 6,
    [theme.breakpoints.down(380)]: {
      display: 'none'
    }
  },
  negocioAvalicoesBotaoQuantidade: {
    color: '#aaaaaa',
    fontWeight: 400,
    paddingLeft: 10
  },
  negocioFavoritoBotaoIsolado: {
    padding: 4,
    marginTop: 0,
    marginBottom: 0
  },
  negocioFavoritoBotaoIsoladoIcone: {
    fontSize: 28
  },
  negocioSecoesBotaoRoot: {
    textTransform: 'none',
    fontSize: '18px',
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: 500
  },
  agendamentoOnlineIndisponivel: {
    background: '#ffecec',
    padding: '14px',
    borderRadius: 25,
    display: 'block',
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center'
  },
  inputContainerCampoBusca: {
    borderRadius: 100,
    padding: '16px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 16px',
      fontSize: 20
    }
  },
  negocioServicosCategoriaNome: {
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down(WIDTH_SERVICOS_MODELO2)]: {
      fontSize: 18
    }
  },
  negocioServicoContainer: {
    cursor: 'pointer',
    paddingTop: 16,
    paddingBottom: 16,
    borderTop: '1px solid #e8e8e8'
  },
  negocioServicoContainerPrimeiro: {
    borderTop: '0px solid'
  },
  negocioServicoSelecionado: {},
  negocioServicoNomeDescricaoContainer: {},
  negocioServicoCustomCheckbox: {
    width: 24,
    height: 24,
    border: '2px solid #444',
    borderRadius: 24,
    textAlign: 'center',
    '$negocioServicoSelecionado &': {
      background: '#444'
    }
  },
  negocioServicoCustomCheckboxIcon: {
    fontSize: 18,
    marginTop: 3,
    color: 'white',
    strokeWidth: 2,
    stroke: '#ffffff'
  },
  negocioServicosNome: {
    color: '#333',
    fontSize: 15,
    [theme.breakpoints.down(WIDTH_SERVICOS_MODELO2)]: {
      fontWeight: 500
    },
    '$negocioServicoSelecionado &': {
      fontWeight: 500
    }
  },
  negocioServicosDescricao: {
    color: '#afafaf',
    whiteSpace: 'pre-wrap',
    fontSize: 13,
    [theme.breakpoints.down(WIDTH_SERVICOS_MODELO2)]: {
      ffontSize: 12
    }
  },
  negocioServicoPrecoDuracaoContainer: {
    [theme.breakpoints.up(WIDTH_SERVICOS_MODELO2)]: {
      marginLeft: 16,
      textAlign: 'right'
    },
    [theme.breakpoints.down(WIDTH_SERVICOS_MODELO2)]: {
      display: 'none'
    }
  },
  negocioServicoPreco: {
    color: '#777',
    fontSize: 15
  },
  negocioServicoDuracao: {
    color: '#afafaf',
    fontSize: 12
  },
  negocioServicoDuracaoSozinho: {
    fontSize: 15
  },
  negocioServicoPrecoDuracaoLinhaUnica: {
    marginTop: 3,
    fontSize: '13px',
    [theme.breakpoints.up(WIDTH_SERVICOS_MODELO2)]: {
      display: 'none'
    }
  },
  negocioServicoContainerBotaoAgendar: {
    marginLeft: 24,
    [theme.breakpoints.down(402)]: {
      display: 'none'
    }
  },
  negocioServicoBotaoAgendar: {
    textTransform: 'none',
    fontsSize: '16px',
    fontWeight: 500,
    [theme.breakpoints.down(800)]: {
      paddingLeft: 10,
      paddingRight: 10
    },
    [theme.breakpoints.down(WIDTH_SERVICOS_MODELO2)]: {
      fontSize: 13,
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  snackbarRoot: {
    zIndex: theme.zIndex.drawer - 1
  },
  snackbarContent: {
    background: '#fff',
    padding: '20px 28px',
    borderRadius: 60,
    minWidth: '250px',
    maxWidth: '250px',
    [theme.breakpoints.down('xs')]: {
      padding: '14px 26px'
    }
  },
  snackbarMessage: {
    color: '#555',
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 4
  },
  snackbarBotaoAgendar: {
    fontSize: 18,
    textTransform: 'none'
  }
})

class NegocioPage extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.state = {
      negocio: null,
      paginaNaoEncontrada: false
    }
    this.globalLocalPreferences = LocalPreferences.new('global', null)
    this.inputPalavrasChave = React.createRef()
  }

  componentDidMount() {
    document.body.classList.add('minheight100')

    CookieConsentManager.executeScriptAndShowPopup()

    this.eventsManager.sub('EnviarPageViewGA', (props) => {
      if (this.idAcompanhamentoGa) {
        window.gtag('event', 'page_view', {
          page_title: props.pageTitle,
          page_path: this.pageViewBasePath + props.pagePath,
          send_to: this.idAcompanhamentoGa
        })
      }
    })
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    let url = null
    const params = {}

    if (this.props.match.params.idNegocio) {
      url = 'ptl/negocios.buscarPorId'
      params.idWithHash = NegocioService.gerarNegocioIdWithHash({ id: this.props.match.params.idNegocio, hash: this.props.match.params.hash })
    } else {
      url = 'ptl/negocios.buscarPorSufixoUrlPortal'
      params.sufixoUrlPortal = this.props.match.params.sufixoUrlPortal
    }

    getAPI({
      url: url,
      params: params,
      requerAutorizacao: true,
      onSuccess: (response) => {
        const negocio = response.data.negocio
        const cliente = response.data.cliente
        if (!negocio || !negocio.paginaPortalAtiva || negocio.excluido === true) {
          this.setState({
            paginaNaoEncontrada: true
          })
          return
        }

        if (negocio.configuracoesGerais.idAcompanhamentoGa) {
          this.idAcompanhamentoGa = negocio.configuracoesGerais.idAcompanhamentoGa
          this.pageViewBasePath = NegocioService.gerarUrlAgendamentoOnline(negocio, negocio.configuracoesGerais.sufixoUrlPortal)
          window.gtag('config', this.idAcompanhamentoGa, {
            send_page_view: false
          })
          EventsManager.pub('EnviarPageViewGA', {
            pageTitle: 'Perfil Salão99 - Página de Agendamentos',
            pagePath: ''
          })
        }

        let existeCategoriaComCarregamentoDeServicosExpandidos = false
        for (let categoriaServico of negocio.categoriasServicos) {
          if (categoriaServico.carregarServicosExpandidosAo === true) {
            categoriaServico.expanded = true
            existeCategoriaComCarregamentoDeServicosExpandidos = true
          }
          categoriaServico.servicos.sort((a, b) => {
            if (a.nome < b.nome) {
              return -1
            }
            if (a.nome > b.nome) {
              return 1
            }
            if (a.id < b.id) {
              return -1
            }
            if (a.id > b.id) {
              return 1
            }
            return 0
          })
          for (let servico of categoriaServico.servicos) {
            servico.variacoes.sort((a, b) => {
              if (a.id < b.id) {
                return -1
              }
              if (a.id > b.id) {
                return 1
              }
              return 0
            })
          }
        }

        negocio.mapServicosPorId = {}
        negocio.mapVariacoesPorId = {}

        const servicosPopulares = []

        for (let categoriaServico of negocio.categoriasServicos) {
          for (let servico of categoriaServico.servicos) {
            negocio.mapServicosPorId[servico.id] = servico
            for (let variacao of servico.variacoes) {
              negocio.mapVariacoesPorId[variacao.id] = variacao
              if (variacao.topServicoPopular) {
                const servicoPopular = JSON.parse(JSON.stringify(servico))
                servicoPopular.nome = categoriaServico.nome + ' - ' + servicoPopular.nome
                servicoPopular.variacoes = [variacao]
                servicosPopulares.push(servicoPopular)
              }
            }
          }
        }

        if (servicosPopulares && servicosPopulares.length > 0) {
          servicosPopulares.sort((a, b) => {
            if (a.variacoes[0].topServicoPopular < b.variacoes[0].topServicoPopular) {
              return -1
            }
            if (a.variacoes[0].topServicoPopular > b.variacoes[0].topServicoPopular) {
              return 1
            }
            if (a.variacoes[0].nome < b.variacoes[0].nome) {
              return -1
            }
            if (a.variacoes[0].nome > b.variacoes[0].nome) {
              return 1
            }
            return 0
          })

          if (negocio.configuracoesGerais.exibicaoCategoriaServicosMaisAgendados) {
            negocio.categoriasServicos.unshift({
              id: ID_CATEGORIA_SERVICOS_POPULARES,
              nome: 'Serviços mais Agendados',
              expanded: true,
              servicos: servicosPopulares
            })
          }
        }

        if (!existeCategoriaComCarregamentoDeServicosExpandidos) {
          negocio.categoriasServicos[0].expanded = true
        }

        this.setState({
          negocio: negocio,
          cliente: cliente
        })
        notifyContentLoaded()
      },
      onError: (response) => {
        if (response.code === 400) {
          this.setState({
            paginaNaoEncontrada: true
          })
        } else {
          notifyContentNotLoaded({ messageErrorCode: response.code })
        }
      }
    })
  }

  render() {
    const { negocio, cliente } = this.state
    const { classes } = this.props

    if (this.state.paginaNaoEncontrada) {
      return <PaginaNaoEncontradaContent />
    }

    let loadKey = 'carregarMain'
    if (this.props.usuarioSessao && this.props.usuarioSessao.carregado) {
      loadKey += this.props.usuarioSessao.id
    }

    let isFromInternalURL = false

    if (sessionStorage.getItem('businessLinkFromInternalSource') === 'true') {
      isFromInternalURL = true
    }

    return (
      <ContentWithPreload loadContentPadding={true} loadFunction={this.carregarMain} loadKey={loadKey}>
        {() => {
          const variacoesFoto = ImageUtil.extrairVariacoesImagem(negocio.foto)

          let contaButton = null
          if (this.props.usuarioSessao && this.props.usuarioSessao.carregado) {
            contaButton = (
              <LoginAvatar
                name={this.props.usuarioSessao.nome}
                onClick={() => {
                  this.props.history.push(ROUTE_PORTAL_PANEL)
                }}
              />
            )
          } else {
            contaButton = (
              <Button
                classes={{ root: classes.headerButtonRoot }}
                startIcon={<LoginIcon />}
                onClick={() => {
                  this.props.history.push(ROUTE_CONTA_MAIN + '/login?continuacao=' + encodeURIComponent(window.location.href))
                }}
              >
                Login
              </Button>
            )
          }

          let botaoAvaliacoesStyle = {}
          let labelDescricaoStatusAvaliacao = ''

          if (negocio.quantidadeAvaliacoes > 0) {
            if (negocio.notaMediaAvaliacoes >= 4.3) {
              labelDescricaoStatusAvaliacao = '- Ótimo'
            } else if (negocio.notaMediaAvaliacoes >= 4.0) {
              labelDescricaoStatusAvaliacao = '- Bom'
            }
          } else {
            botaoAvaliacoesStyle.pointerEvents = 'none'
          }

          return (
            <React.Fragment>
              <Helmet>
                <title>{negocio.nome} - Agende Online</title>
                <meta name="description" content={'Faça o seu agendamento online. ' + negocio.descricao} />
              </Helmet>

              <Grid container alignItems="center" direction="column" className="minheight100">
                <Grid item xs container direction="column" style={{ flexWrap: 'nowrap' }}>
                  <div className={classes.header}>
                    <div className={classNames(classes.content)}>
                      <Grid container alignItems="center" style={{ paddingTop: 8, marginBottom: 0 }}>
                        <Grid item style={{ marginRight: 16, marginLeft: -14 }}>
                          {isFromInternalURL && (
                            <IconButton
                              onClick={() => {
                                window.history.go(-1)
                              }}
                            >
                              <ArrowBackIcon style={{ fontSize: '28px' }} />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item>{contaButton}</Grid>
                      </Grid>
                      <Grid container alignItems="center" className={classes.infoHeaderContent}>
                        <Grid item className={classes.containerNegocioFotoPerfil}>
                          <img src={variacoesFoto.get(0)} className={classes.negocioFotoPerfil} alt={negocio.nome} />
                        </Grid>
                        <Grid item xs className={classes.containerNegocioInfo}>
                          <Typography variant="body1" className={classes.negocioNome}>
                            {negocio.nome}
                          </Typography>
                          <Typography variant="body1" className={classes.negocioEndereco}>
                            {LocalizacaoFormatter.formatarEnderecoResumido(negocio.endereco)}
                          </Typography>
                          <div className={classes.containerNegocioAvalicoes}>
                            {
                              <Button
                                className={classes.negocioAvalicoesBotaoRoot}
                                style={botaoAvaliacoesStyle}
                                startIcon={<StarIcon className={classes.negocioAvalicoesBotaoIcone} />}
                                onClick={() => {
                                  EventsManager.pub('EnviarPageViewGA', {
                                    pageTitle: 'Perfil Salão99 - Avaliações',
                                    pagePath: '/avaliacoes'
                                  })
                                  openBackableDialog(NegocioAvaliacoesDialog, {
                                    negocio: negocio
                                  })
                                }}
                              >
                                {negocio.quantidadeAvaliacoes === 0 && (
                                  <React.Fragment>
                                    <span className={classes.negocioAvalicoesBotaoQuantidade} style={{ paddingLeft: 0 }}>
                                      Sem Avaliações
                                    </span>
                                  </React.Fragment>
                                )}
                                {negocio.quantidadeAvaliacoes > 0 && (
                                  <React.Fragment>
                                    {negocio.notaMediaAvaliacoes.toFixed(1)}
                                    <span className={classes.negocioAvalicoesBotaoStatus}>{labelDescricaoStatusAvaliacao}</span>{' '}
                                    <span className={classes.negocioAvalicoesBotaoQuantidade}>
                                      {negocio.quantidadeAvaliacoes} avaliaç{negocio.quantidadeAvaliacoes !== 1 ? 'ões' : 'ão'}
                                    </span>
                                  </React.Fragment>
                                )}
                              </Button>
                            }
                            <BotaoFavoritar
                              negocio={negocio}
                              usuarioSessao={this.props.usuarioSessao}
                              loginFunction={() => {
                                this.props.history.push(ROUTE_CONTA_MAIN + '/login?continuacao=' + encodeURIComponent(window.location.href))
                              }}
                              render={(props) => (
                                <IconButton
                                  className={classNames(classes.negocioFavoritoBotaoIsolado, classes.modelo2)}
                                  disabled={props.ajaxing}
                                  onClick={props.onClick}
                                  style={{}}
                                >
                                  <props.icon className={classes.negocioFavoritoBotaoIsoladoIcone} />
                                </IconButton>
                              )}
                            />
                          </div>

                          <div>
                            <Button
                              disabled={!negocio.fotos || negocio.fotos.length === 0 ? true : false}
                              className={classes.negocioSecoesBotaoRoot}
                              style={{ marginLeft: -6 }}
                              startIcon={<PhotoLibraryIcon />}
                              onClick={() => {
                                EventsManager.pub('EnviarPageViewGA', {
                                  pageTitle: 'Perfil Salão99 - Galeria de Fotos',
                                  pagePath: '/galeria-fotos'
                                })
                                openBackableDialog(NegocioFotosDialog, {
                                  negocio: negocio,
                                  fotos: negocio.fotos
                                })
                              }}
                            >
                              Fotos
                            </Button>
                            <Button
                              className={classes.negocioSecoesBotaoRoot}
                              style={{ marginLeft: 16 }}
                              startIcon={<SubjectIcon />}
                              onClick={() => {
                                EventsManager.pub('EnviarPageViewGA', {
                                  pageTitle: 'Perfil Salão99 - Informações Detalhadas',
                                  pagePath: '/informacoes-detalhadas'
                                })
                                openBackableDialog(NegocioInformacoesDialog, {
                                  negocio: negocio
                                })
                              }}
                            >
                              Informações
                            </Button>
                            <BotaoFavoritar
                              negocio={negocio}
                              usuarioSessao={this.props.usuarioSessao}
                              loginFunction={() => {
                                this.props.history.push(ROUTE_CONTA_MAIN + '/login?continuacao=' + encodeURIComponent(window.location.href))
                              }}
                              render={(props) => (
                                <Button
                                  className={classNames(classes.negocioSecoesBotaoRoot, classes.modelo1)}
                                  style={{ marginLeft: 16 }}
                                  disabled={props.ajaxing}
                                  startIcon={<props.icon />}
                                  onClick={props.onClick}
                                >
                                  {props.isFavorito ? 'Favorito' : 'Favoritar'}
                                </Button>
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div style={{ width: '100%' }}>
                    {negocio.agendamentoOnlineAtivo === true && <ListagemServicos usuarioSessao={this.props.usuarioSessao} negocio={negocio} cliente={cliente} classes={classes} />}

                    {negocio.agendamentoOnlineAtivo === false && (
                      <div className={classNames(classes.content)}>
                        <div className={classNames(classes.agendamentoOnlineIndisponivel)}>
                          <Typography variant="body1">No momento, esta empresa não está recebendo agendamentos online</Typography>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item xs style={{ flexGrow: 0, width: '100%' }}>
                  <PortalPageFooter />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }}
      </ContentWithPreload>
    )
  }

  componentWillUnmount() {
    document.body.classList.remove('minheight100')
    this.eventsManager.unsubscribe()
  }
}

class BotaoFavoritar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ajaxing: false
    }
  }

  render() {
    const { negocio, usuarioSessao, loginFunction } = this.props

    let botaoProps = {}
    if (usuarioSessao && usuarioSessao.carregado) {
      let isFavorito = false
      if (this.props.usuarioSessao.negociosFavoritos) {
        for (let negocioFavorito of this.props.usuarioSessao.negociosFavoritos) {
          if (negocioFavorito.negocio.id === negocio.id) {
            isFavorito = true
            break
          }
        }
      }

      botaoProps.isFavorito = isFavorito

      if (isFavorito) {
        botaoProps.onClick = () => {
          this.setState({ ajaxing: true })
          EventsManager.pub('RemoverNegocioFavorito', {
            idNegocio: negocio.id,
            onSuccess: () => {
              this.setState({ ajaxing: false })
            }
          })
        }
        botaoProps.icon = FavoriteIcon
      } else {
        botaoProps.onClick = () => {
          this.setState({ ajaxing: true })
          EventsManager.pub('AdicionarNegocioFavorito', {
            idNegocio: negocio.id,
            onSuccess: () => {
              this.setState({ ajaxing: false })
              EventsManager.pub('EnviarPageViewGA', {
                pageTitle: 'Perfil Salão99 - Favoritado',
                pagePath: '/favoritado'
              })
            }
          })
        }
        botaoProps.icon = FavoriteBorderIcon
      }
    } else {
      botaoProps.onClick = loginFunction
      botaoProps.icon = FavoriteBorderIcon
    }

    botaoProps.ajaxing = this.state.ajaxing

    if (this.props.render) {
      return this.props.render(botaoProps)
    }
    return ''
  }
}

const QUANTIDADE_VARIACOES_LOTE_EXIBICAO = 30

function SlideTransition(props) {
  return <Slide {...props} direction="up" />
}

class ListagemServicos extends Component {
  requestCount = 0
  limiteExibicaoVariacoes = QUANTIDADE_VARIACOES_LOTE_EXIBICAO

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    const categoriasServicosFiltradas = props.negocio.categoriasServicos.slice()
    this.state = {
      agendando: false,
      data: null,
      categoriasServicosFiltradas: categoriasServicosFiltradas,
      categoriasServicosExibiveis: categoriasServicosFiltradas,
      itensSelecionados: [],
      itensSelecionadosKeys: []
    }
  }

  componentDidMount() {
    this.eventsManager.sub('AlteracaoDataCalendario', (props) => {
      this.alterarDataParaAgendamento(props.data)
    })
    this.eventsManager.sub('LimparMarcacaoServicos', (props) => {
      this.setState({ itensSelecionados: [], itensSelecionadosKeys: [] })
    })
    this.eventsManager.sub('DesmarcarServico', (props) => {
      const { itensSelecionados, itensSelecionadosKeys } = this.state
      let index = null
      for (let i = 0; i < itensSelecionados.length; i++) {
        if (props.id === itensSelecionados[i].id) {
          index = i
          break
        }
      }
      if (index !== null) {
        itensSelecionados.splice(index, 1)
        itensSelecionadosKeys.splice(index, 1)
      }
      this.setState({ itensSelecionados: itensSelecionados, itensSelecionadosKeys: itensSelecionadosKeys })
    })
    const dadosRecuperacao = recuperarEstadoItens()
    if (dadosRecuperacao && dadosRecuperacao.idNegocio === this.props.negocio.id) {
      const itensRecuperados = dadosRecuperacao.itens
      const itensCarregamento = []
      if (itensRecuperados && itensRecuperados.length) {
        for (let itemRecuperado of itensRecuperados) {
          itemRecuperado.servico = this.props.negocio.mapServicosPorId[itemRecuperado.servico.id]
          itemRecuperado.variacao = this.props.negocio.mapVariacoesPorId[itemRecuperado.variacao.id]
          if (itemRecuperado.servico && itemRecuperado.variacao) {
            itensCarregamento.push(itemRecuperado)
          }
        }
      }
      if (itensCarregamento && itensCarregamento.length > 0) {
        this.alterarDataParaAgendamento(dadosRecuperacao.data)
        this.carregarItens(itensCarregamento)
        this.iniciarAgendamento()
      }
    }
  }

  alterarDataParaAgendamento = (data) => {
    const { state } = this
    state.data = data
  }

  aplicarBuscaServico = (termo) => {
    this.requestCount++
    this.ultimaSolicitacaoBusca = new Date().getTime()

    window.setTimeout(() => {
      if (new Date().getTime() - this.ultimaSolicitacaoBusca < Constants.DELAY_EXECUTAR_BUSCA_POR_DIGITACAO_RAPIDO) {
        return
      }

      if (!termo.trim()) {
        this.setState({ categoriasServicosFiltradas: this.props.negocio.categoriasServicos, categoriasServicosExibiveis: this.props.negocio.categoriasServicos })
        return
      }

      this.limiteExibicaoVariacoes = QUANTIDADE_VARIACOES_LOTE_EXIBICAO

      const categoriasServicos = JSON.parse(JSON.stringify(this.props.negocio.categoriasServicos))
      const categoriasServicosFiltradas = []

      for (let i1 = 0; i1 < categoriasServicos.length; i1++) {
        const categoria = categoriasServicos[i1]

        if (categoria.id === ID_CATEGORIA_SERVICOS_POPULARES) {
          continue
        }

        if (StringUtil.searchPart(categoria.nome, termo, { anyDistanceBetweenWords: true })) {
          categoria.expanded = true
          categoriasServicosFiltradas.push(categoria)
        } else {
          const servicosEncontrados = []
          for (let i2 = 0; i2 < categoria.servicos.length; i2++) {
            const servico = categoria.servicos[i2]
            if (StringUtil.searchPart(categoria.nome + ' ' + servico.nome, termo, { anyDistanceBetweenWords: true })) {
              servicosEncontrados.push(servico)
            } else {
              const variacoesEncontradas = []
              for (let i3 = 0; i3 < servico.variacoes.length; i3++) {
                const variacao = servico.variacoes[i3]
                let stringBusca = categoria.nome + ' ' + servico.nome
                if (variacao.nome) {
                  stringBusca += ' ' + variacao.nome
                }
                if (servico.descricao) {
                  stringBusca += ' ' + servico.descricao
                }
                if (variacao.descricao) {
                  stringBusca += ' ' + variacao.descricao
                }
                if (StringUtil.searchPart(stringBusca, termo, { anyDistanceBetweenWords: true })) {
                  variacoesEncontradas.push(variacao)
                }
              }
              if (variacoesEncontradas.length > 0) {
                servico.variacoes = variacoesEncontradas
                servicosEncontrados.push(servico)
              }
            }
          }
          if (servicosEncontrados.length > 0) {
            categoria.expanded = true
            categoria.servicos = servicosEncontrados
            categoriasServicosFiltradas.push(categoria)
          }
        }
      }
      this.setState({
        categoriasServicosFiltradas: categoriasServicosFiltradas,
        categoriasServicosExibiveis: this.processarCategoriasServicosExibiveis(JSON.parse(JSON.stringify(categoriasServicosFiltradas)))
      })
    }, Constants.DELAY_EXECUTAR_BUSCA_POR_DIGITACAO_RAPIDO + 15)
  }

  processarCategoriasServicosExibiveis = (categoriasServicos) => {
    const categoriasServicosExibiveis = []
    let contadorRegistros = 0
    for (let i1 = 0; i1 < categoriasServicos.length; i1++) {
      const categoria = categoriasServicos[i1]
      const servicosCarregados = []
      for (let i2 = 0; i2 < categoria.servicos.length; i2++) {
        const servico = categoria.servicos[i2]
        const variacoesCarregadas = []
        for (let i3 = 0; i3 < servico.variacoes.length; i3++) {
          contadorRegistros++
          if (contadorRegistros <= this.limiteExibicaoVariacoes) {
            variacoesCarregadas.push(servico.variacoes[i3])
          } else {
            break
          }
        }
        if (variacoesCarregadas.length > 0) {
          servico.variacoes = variacoesCarregadas
          servicosCarregados.push(servico)
        }
      }
      if (servicosCarregados.length > 0) {
        categoria.expanded = true
        categoria.servicos = servicosCarregados
        categoriasServicosExibiveis.push(categoria)
      }
    }
    return categoriasServicosExibiveis
  }

  isExibeBotaoCarregarMais = () => {
    const { categoriasServicosFiltradas, categoriasServicosExibiveis } = this.state

    if (!categoriasServicosFiltradas || categoriasServicosFiltradas.length === 0 || !categoriasServicosExibiveis || categoriasServicosExibiveis.length === 0) {
      return false
    }

    const ultimaCategoriaFiltrada = categoriasServicosFiltradas[categoriasServicosFiltradas.length - 1]
    const ultimoServicoFiltrado = ultimaCategoriaFiltrada.servicos[ultimaCategoriaFiltrada.servicos.length - 1]
    const ultimaVariacaoFiltrada = ultimoServicoFiltrado.variacoes[ultimoServicoFiltrado.variacoes.length - 1]

    const ultimaCategoriaExibivel = categoriasServicosExibiveis[categoriasServicosExibiveis.length - 1]
    const ultimoServicoExibivel = ultimaCategoriaExibivel.servicos[ultimaCategoriaExibivel.servicos.length - 1]
    const ultimaVariacaoExibivel = ultimoServicoExibivel.variacoes[ultimoServicoExibivel.variacoes.length - 1]

    if (ultimaVariacaoFiltrada.id !== ultimaVariacaoExibivel.id) {
      return true
    } else {
      return false
    }
  }

  exibirMais = () => {
    const { categoriasServicosFiltradas } = this.state
    this.limiteExibicaoVariacoes += QUANTIDADE_VARIACOES_LOTE_EXIBICAO
    this.setState({ categoriasServicosExibiveis: this.processarCategoriasServicosExibiveis(JSON.parse(JSON.stringify(categoriasServicosFiltradas))) })
  }

  selecionarItem = (servico, variacao) => {
    this.carregarItens([
      {
        servico: servico,
        variacao: variacao
      }
    ])
  }

  carregarItens = (itens) => {
    if (itens && itens.length) {
      let { itensSelecionados, itensSelecionadosKeys } = this.state
      for (let item of itens) {
        const key = item.servico.id + '-' + item.variacao.id
        const itemSelecionadoKeyIndex = itensSelecionadosKeys.indexOf(key)
        if (itemSelecionadoKeyIndex >= 0) {
          itensSelecionados.splice(itemSelecionadoKeyIndex, 1)
          itensSelecionadosKeys.splice(itemSelecionadoKeyIndex, 1)
        } else {
          itensSelecionados.push({
            id: ++ITEM_ID_GENERATOR,
            servico: item.servico,
            variacao: item.variacao,
            colaborador: item.colaborador
          })
          itensSelecionadosKeys = itensSelecionados.map((itemSelecionado) => itemSelecionado.servico.id + '-' + itemSelecionado.variacao.id)
        }
      }
      this.setState({ itensSelecionados: itensSelecionados, itensSelecionadosKeys: itensSelecionadosKeys })
    }
  }

  iniciarAgendamento = () => {
    if (!this.props.usuarioSessao || !this.props.usuarioSessao.carregado) {
      const irParaIdentificacaoUsuario = (path, dialogInstance) => {
        prepararListaItensParaRecuperacaoEstado(this.props.negocio.id, this.state.data, this.state.itensSelecionados)
        dialogInstance.props.setOnClosed(() => {
          EventsManager.pub('RoutePush', {
            path: ROUTE_CONTA_MAIN + path + '?continuacao=' + encodeURIComponent(window.location.href)
          })
        })
      }

      openBackableDialog(MessageDialog, {
        parent: this,
        title: (
          <span>
            <LoginIcon style={{ verticalAlign: 'middle', marginTop: -1, marginRight: 6 }} /> Continuar...
          </span>
        ),
        text: 'É necessário fazer login para realizar o agendamento. Caso ainda não tenha uma conta no Salão99, crie uma agora mesmo! Leva menos de 1 minutinho =)',
        actionButtons: [
          {
            label: 'Fazer Login',
            onClickFunction: (dialogInstance) => {
              irParaIdentificacaoUsuario('/login', dialogInstance)
              dialogInstance.props.handleCloseDialog()
            }
          },
          {
            label: 'Criar Conta',
            onClickFunction: (dialogInstance) => {
              irParaIdentificacaoUsuario('/criar-conta', dialogInstance)
              dialogInstance.props.handleCloseDialog()
            }
          }
        ]
      })

      return
    }

    if (this.props.cliente && this.props.cliente.controleAgendamentoOnline.id !== ControleAgendamentoOnlineEnum.HABILITADO.id) {
      openBackableDialog(MessageDialog, {
        parent: this,
        title: (
          <span style={{ color: '#f36d6d' }}>
            <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginTop: -1 }} /> Acesso Bloqueado
          </span>
        ),
        text: (
          <span>
            Este estabelecimento bloqueou temporariamente o seu acesso à Agenda Online. Em caso de dúvidas, entre em contato diretamente com <b>{this.props.negocio.nome}</b>.
          </span>
        )
      })
      return
    }

    this.setState({ agendando: true })
    openBackableDialog(AgendarAtendimentosDialog, {
      usuarioSessao: this.props.usuarioSessao,
      negocio: this.props.negocio,
      data: this.state.data,
      itens: this.state.itensSelecionados,
      onClosed: (props) => {
        if (props.precisaFazerLogin !== true) {
          this.setState({ agendando: false })
        }
      }
    })
  }

  limparSelecaoServicos = () => {
    this.setState({ data: null, itensSelecionados: [], itensSelecionadosKeys: [] })
  }

  render() {
    const { classes } = this.props
    const { categoriasServicosExibiveis } = this.state

    return (
      <div className={classNames(classes.content)}>
        <InputText
          /*sstyle={{ display: "flex", marginTop: 0, maxWidth: 574, marginBottom: 24}}*/
          style={{ marginBottom: 28 }}
          InputProps={{
            className: classes.inputContainerCampoBusca,
            inputProps: {
              style: {
                fontSize: 16,
                paddingTop: 8
              }
            }
          }}
          idname="xpesquisaservico"
          shrink={false}
          customVariant="filled"
          nakedLeftPadding={false}
          placeholder="Pesquisar Serviço...."
          startAdornment={<SearchIcon style={{ marginRight: 16 }} />}
          focusEffectDisabled={true}
          onChange={(event) => {
            this.aplicarBuscaServico(event.target.value)
          }}
        />

        <div style={{ padding: '0px 4px' }}>
          {categoriasServicosExibiveis &&
            categoriasServicosExibiveis.length > 0 &&
            categoriasServicosExibiveis.map((categoria, index) => {
              return (
                <NakedExpansionPanel
                  key={categoria.id}
                  title={<span className={classes.negocioServicosCategoriaNome}>{categoria.nome}</span>}
                  expandKey={this.requestCount}
                  defaultExpanded={categoria.expanded ? true : false}
                  content={(expanded) => {
                    let linha = 0

                    return (
                      <React.Fragment>
                        {expanded &&
                          categoria.servicos &&
                          categoria.servicos.length > 0 &&
                          categoria.servicos.map((servico) => {
                            return servico.variacoes.map((variacao) => {
                              let classesNegocioServicoContainer = [classes.negocioServicoContainer]
                              if (linha++ === 0) {
                                classesNegocioServicoContainer.push(classes.negocioServicoContainerPrimeiro)
                              }

                              let doneIconContent = null
                              let textoBotao = 'Selecionar'
                              let styleCheckbox = { width: 24, height: 24, border: '2px solid #444', borderRadius: 24, textAlign: 'center' }

                              if (this.state.itensSelecionadosKeys.indexOf(servico.id + '-' + variacao.id) >= 0) {
                                classesNegocioServicoContainer.push(classes.negocioServicoSelecionado)
                                doneIconContent = <DoneIcon className={classes.negocioServicoCustomCheckboxIcon} />
                                styleCheckbox.background = '#444'
                                textoBotao = 'Desmarcar'
                              }

                              let descricaoPrecoDuracaoLinhaUnica = null
                              let linhaPreco = null
                              let linhaDuracao = null
                              const duracaoFormatada = formatarDuracao(variacao.duracao, { descricao: 'minificada' })
                              if (TipoExibicaoPrecoEnum.OCULTO.id !== servico.tipoExibicaoPreco.id && variacao.preco > 0) {
                                const precoFormatado = formatarValorMonetario(variacao.preco)
                                const complementoPreco = TipoExibicaoPrecoEnum.A_PARTIR.id === servico.tipoExibicaoPreco.id ? '+' : ''
                                descricaoPrecoDuracaoLinhaUnica = duracaoFormatada + ' \u2022 ' + precoFormatado + complementoPreco
                                linhaPreco = (
                                  <Typography variant="body1" className={classes.negocioServicoPreco}>
                                    {precoFormatado + complementoPreco}
                                  </Typography>
                                )
                                linhaDuracao = (
                                  <Typography variant="body2" className={classes.negocioServicoDuracao}>
                                    {duracaoFormatada}
                                  </Typography>
                                )
                              } else {
                                descricaoPrecoDuracaoLinhaUnica = duracaoFormatada
                                linhaDuracao = (
                                  <Typography variant="body1" className={classes.negocioServicoDuracaoSozinho}>
                                    {duracaoFormatada}
                                  </Typography>
                                )
                              }

                              return (
                                <Grid
                                  key={variacao.id}
                                  container
                                  alignItems="center"
                                  className={classNames(classesNegocioServicoContainer)}
                                  onClick={() => {
                                    this.selecionarItem(servico, variacao)
                                  }}
                                >
                                  <Grid item style={{ marginRight: 16 }}>
                                    <div className={classes.negocioServicoCustomCheckbox} style={styleCheckbox}>
                                      {doneIconContent}
                                    </div>
                                  </Grid>
                                  <Grid item xs className={classes.negocioServicoNomeDescricaoContainer}>
                                    <Typography variant="body1" className={classes.negocioServicosNome}>
                                      {ServicoService.gerarNomeCompletoServico(servico, variacao)}
                                    </Typography>
                                    <Typography variant="body2" className={classes.negocioServicosDescricao}>
                                      {servico.descricao} {variacao.descricao}
                                    </Typography>
                                    <Typography variant="body2" className={classes.negocioServicoPrecoDuracaoLinhaUnica}>
                                      {descricaoPrecoDuracaoLinhaUnica}
                                    </Typography>
                                  </Grid>
                                  <Grid item className={classes.negocioServicoPrecoDuracaoContainer}>
                                    {linhaPreco}
                                    {linhaDuracao}
                                  </Grid>
                                  <Grid item className={classes.negocioServicoContainerBotaoAgendar}>
                                    <Button className={classes.negocioServicoBotaoAgendar} variant="contained" color="secondary">
                                      {textoBotao}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} className={classes.negocioServicoPrecoDuracaoLinhaUnicaContainer}></Grid>
                                </Grid>
                              )
                            })
                          })}
                      </React.Fragment>
                    )
                  }}
                />
              )
            })}

          {this.isExibeBotaoCarregarMais() && (
            <div style={{ textAlign: 'center', marginTop: 16 }}>
              <Button style={{ fontSize: 16, textTransform: 'none' }} onClick={this.exibirMais} endIcon={<ArrowDropDownIcon />}>
                Exibir Mais
              </Button>
            </div>
          )}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Snackbar
          className={classNames('needsWillChange', classes.snackbarRoot)}
          ContentProps={{
            classes: {
              root: classes.snackbarContent
            }
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!this.state.agendando && this.state.itensSelecionados.length > 0 ? true : false}
          message={
            <span className={classes.snackbarMessage}>
              {this.state.itensSelecionados.length} Serviço{this.state.itensSelecionados.length > 1 ? 's' : ''}
              <span
                style={{ display: 'block', fontSize: 13, color: '#999', padding: '8px 0px', marginTop: -6, marginBottom: -8, cursor: 'pointer' }}
                onClick={this.limparSelecaoServicos}
              >
                <HighlightOffIcon style={{ verticalAlign: 'middle', fontSize: 18, marginLeft: 2, marginTop: -2, marginRight: 0, stroke: '#dddddd' }} /> Limpar Serviços
              </span>
            </span>
          }
          TransitionComponent={SlideTransition}
          action={[
            <Button key="agendar" classes={{ text: classes.snackbarBotaoAgendar }} size="large" color="secondary" onClick={this.iniciarAgendamento} endIcon={<ArrowForwardIcon />}>
              Agendar
            </Button>
          ]}
        />
      </div>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

NegocioPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NegocioPage)
