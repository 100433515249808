import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CakeIcon from '@material-ui/icons/Cake'
import EventIcon from '@material-ui/icons/Event'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import { createEnum } from 'support/util/EnumUtil'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons(),
  icon: {
    '& svg': {
      color: theme.palette.secondary.main,
      display: 'block'
    }
  }
})

export const TipoTemplateEnum = createEnum({
  LEMBRETE: { id: 1 },
  ANIVERSARIO: { id: 2 }
})

class ConfiguracoesEnvioMensagemDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.state = {
      paginaCarregada: false,
      configuracaoLembrete: null,
      configuracaoMensagemAniversario: null,
      errorsMap: createErrorsMap(),
      ajaxing: false
    }
    this.inputTemplateLembrete = React.createRef()
    this.inputTemplateAniversario = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/negocios.buscarConfiguracoesMensagemTemplate',
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          configuracaoLembrete: response.data.configuracaoLembrete,
          configuracaoMensagemAniversario: response.data.configuracaoMensagemAniversario
        })
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  closeMenuModeloMensagemManualAnchorPosition = () => {
    this.setState({
      menuModeloMensagemManualAnchorPosition: null
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/negocios.alterarConfiguracoesTemplateMensagem',
      data: {
        templateLembrete: this.inputTemplateLembrete.current.value,
        templateAniversario: this.inputTemplateAniversario.current.value
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formConfiguracoesTemplates')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, errorsMap, menuModeloMensagemManualAnchorPosition, configuracaoLembrete, configuracaoMensagemAniversario } = this.state

    return (
      <DialogPage
        {...dialogProps}
        title={'Mensagens'}
        align="center"
        contentMaxWidth={520}
        pageType="basicForm"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => (
            <form id="formConfiguracoesTemplates" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Typography variant="h6">Modelos de mensagens</Typography>
              <Typography variant="body2">Configure o modelos padrões de mensagens que o sistema deve gerar para cada situação.</Typography>

              <Grid container alignItems="center" style={{ marginTop: 24 }}>
                <Grid item style={{ marginRight: 16 }}>
                  <EventIcon style={{ display: 'block' }} />
                </Grid>
                <Grid item xs style={{ padding: '8px 0px' }}>
                  <Typography variant="body2" style={{ fontSize: 15 }}>
                    <b>Lembrete de Agendamento</b>
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    onClick={(event) => {
                      this.setState({
                        menuModeloMensagemManualAnchorPosition: {
                          left: event.clientX,
                          top: event.clientY
                        },
                        tipoTemplate: TipoTemplateEnum.LEMBRETE
                      })
                    }}
                  >
                    <MoreVertIcon style={{ display: 'block' }} />
                  </IconButton>
                </Grid>
              </Grid>

              <InputText
                disabled={ajaxing}
                InputProps={{
                  style: {
                    fontSize: 14
                  }
                }}
                extraVerticalPadding={true}
                spellCheck="false"
                idname="xmodelomensagemmanual"
                defaultValue={configuracaoLembrete.modeloMensagemManual}
                errorMessage={errorsMap('templateLembrete')}
                customVariant="filled"
                inputRef={this.inputTemplateLembrete}
                multiline={true}
                rowsMax={10}
              />

              <Grid container alignItems="center" style={{ marginTop: 24 }}>
                <Grid item style={{ marginRight: 16 }}>
                  <CakeIcon style={{ display: 'block' }} />
                </Grid>
                <Grid item xs style={{ padding: '8px 0px' }}>
                  <Typography variant="body2" style={{ fontSize: 15 }}>
                    <b>Aniversário</b>
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    onClick={(event) => {
                      this.setState({
                        menuModeloMensagemManualAnchorPosition: {
                          left: event.clientX,
                          top: event.clientY
                        },
                        tipoTemplate: TipoTemplateEnum.ANIVERSARIO
                      })
                    }}
                  >
                    <MoreVertIcon style={{ display: 'block' }} />
                  </IconButton>
                </Grid>
              </Grid>

              <InputText
                disabled={ajaxing}
                InputProps={{
                  style: {
                    fontSize: 14
                  }
                }}
                extraVerticalPadding={true}
                spellCheck="false"
                idname="xmodelomensagemmanual"
                defaultValue={configuracaoMensagemAniversario.modeloMensagemManual}
                errorMessage={errorsMap('templateAniversario')}
                customVariant="filled"
                inputRef={this.inputTemplateAniversario}
                multiline={true}
                rowsMax={10}
              />
            </form>
          )}
        </ContentWithPreload>

        <Menu
          anchorReference="anchorPosition"
          anchorPosition={menuModeloMensagemManualAnchorPosition}
          open={Boolean(menuModeloMensagemManualAnchorPosition)}
          onClose={this.closeMenuModeloMensagemManualAnchorPosition}
        >
          <MenuItem
            onClick={(event) => {
              if (this.state.tipoTemplate.id === TipoTemplateEnum.LEMBRETE.id) {
                this.inputTemplateLembrete.current.value = configuracaoLembrete.modeloMensagemManualPadrao
              } else if (this.state.tipoTemplate.id === TipoTemplateEnum.ANIVERSARIO.id) {
                this.inputTemplateAniversario.current.value = configuracaoMensagemAniversario.modeloMensagemManualPadrao
              }

              this.closeMenuModeloMensagemManualAnchorPosition()
            }}
          >
            Redefinir modelo
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              this.closeMenuModeloMensagemManualAnchorPosition()
              openBackableDialog(MessageDialog, {
                title: 'Instruções',
                maxWidth: 620,
                text: (
                  <span style={{ fontSize: 14 }}>
                    É possível configurar um modelo para o envio manual de mensagens de lembrete e de aniversário. Utilize o recurso de <b>variáveis</b> para criar o modelo de
                    mensagem ideal para o seu negócio.
                    <br />
                    <br />
                    <span style={{ fontSize: 16 }}>
                      <b>Variáveis</b>
                    </span>
                    <br />
                    <br />
                    As variáveis podem ser adicionadas em qualquer lugar do texto e serão substituídas por informações específicas no momento da geração da mensagens. As variáveis
                    são identificadas pelos sinais de colchetes. Veja o exemplo a seguir:
                    <br />
                    <span style={{ backgroundColor: '#f4f4f4', display: 'inline-block', padding: 15, fontSize: 13, margin: '24px 0px', borderRadius: 3 }}>
                      <b>[saudacao]</b> -{'>'} Essa variável será substituída por Bom dia, Boa tarde ou Boa noite, dependendo da hora do dia em que a messagem for gerada.
                      <br />
                      <br />
                      <b>Exemplo do modelo</b>:<br />
                      [saudacao]! Você tem um horário agendado.
                      <br />
                      <br />
                      <b>Mensagem gerada:</b>
                      <br />
                      Bom dia! Você tem um horário agendado.
                    </span>
                    <br />
                    {this.state.tipoTemplate.id === TipoTemplateEnum.LEMBRETE.id && (
                      <React.Fragment>
                        <b>[saudacao]</b> -{'>'} Bom dia, boa tarde ou boa noite.
                        <br />
                        <br />
                        <b>[data-agendamento]</b> -{'>'} Data do agendamento.
                        <br />
                        <br />
                        <b>[servicos]</b> -{'>'} Os serviços que serão realizados para o cliente, com exibição do horário e profissional.
                        <br />
                        <br />
                        <b>[servicos-sem-profissional]</b> -{'>'} Os serviços que serão realizados para o cliente, apenas com exibição do horário. <br />
                        <br />
                        <b>[servicos-com-data]</b> -{'>'} Os serviços que serão realizados para o cliente, com exibição da data, horário e profissional. <br />
                        <br />
                        <b>[servicos-com-preco]</b> -{'>'} Os serviços que serão realizados para o cliente, com exibição da data, horário, profissional e preço. <br />
                        <br />
                        <b>[horarios-servicos]</b> -{'>'} Apenas as datas dos serviços que serão realizados para o cliente. <br />
                        <br />
                        <b>[nome-cliente]</b> -{'>'} O nome do cliente.
                        <br />
                        <br />
                        <b>[apelido-cliente]</b> -{'>'} O apelido do cliente.
                        <br />
                        <br />
                        <b>[nome-empresa]</b> -{'>'} Nome do seu estabelecimento.
                        <br />
                      </React.Fragment>
                    )}
                    {this.state.tipoTemplate.id === TipoTemplateEnum.ANIVERSARIO.id && (
                      <React.Fragment>
                        <b>[saudacao]</b> -{'>'} Bom dia, boa tarde ou boa noite.
                        <br />
                        <br />
                        <b>[nome-cliente]</b> -{'>'} O nome do cliente.
                        <br />
                        <br />
                        <b>[apelido-cliente]</b> -{'>'} O apelido do cliente.
                        <br />
                        <br />
                        <b>[nome-empresa]</b> -{'>'} Nome do seu estabelecimento.
                        <br />
                      </React.Fragment>
                    )}
                  </span>
                )
              })
            }}
          >
            Instruções
          </MenuItem>
        </Menu>

        {this.state.paginaCarregada && (
          <div className={classes.containerFooterButtons}>
            <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
              Cancelar
            </Button>
            <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
              {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
            </Button>
          </div>
        )}
      </DialogPage>
    )
  }
}

ConfiguracoesEnvioMensagemDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesEnvioMensagemDialogPage)
