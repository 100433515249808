import React from 'react'

import moment from 'moment'
import { verificarAutorizacao } from 'pages/erp/painel/PainelErp'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import MessageDialog from 'support/components/dialog/preconstructed/MessageDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import PA from 'support/domain/colaborador/PermissoesAcesso'
import NegocioPagamentoService from 'support/domain/negociopagamento/NegocioPagamentoService'
import { converterDataIntParaMoment } from 'support/util/DateConverter'

function tentarExibirAvisoImportante(params) {
  if (!params) {
    params = {}
  }

  if (tentarExibirAvisoVencimentoPlano(params)) {
    return
  }

  if (params.onIgnore) {
    params.onIgnore()
  }
}

function tentarExibirAvisoVencimentoPlano(params) {
  let exibiuAviso = false

  if (!getSessaoPainel().colaborador || !getSessaoPainel().colaborador.id) {
    return exibiuAviso
  }

  const possuiPagamentoPorAssinatura = getSessaoPainel().negocio?.usaPagamentoRecorrente

  if (possuiPagamentoPorAssinatura) {
    return exibiuAviso
  }

  const permissaoPodeVisualizarERealizarPagamentos = verificarAutorizacao([PA.PODE_VISUALIZAR_E_REALIZAR_PAGAMENTO_PLANO])

  if (!permissaoPodeVisualizarERealizarPagamentos) {
    return exibiuAviso
  }

  const dataVencimentoPlanoAsMoment = converterDataIntParaMoment(getSessaoPainel().negocio.dataVencimentoPlano).startOf('day')

  const diasParaVencimento = dataVencimentoPlanoAsMoment.diff(moment().startOf('day'), 'days')

  if (diasParaVencimento <= 7) {
    const colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    const dataProximaExibicao = colaboradorLocalPreferences.getMomentPreference('AvisoImportanteNegocio_DataProximaExibicao', null)

    if (!dataProximaExibicao || moment().diff(dataProximaExibicao, 'seconds') >= 0) {
      exibiuAviso = true

      let intervaloHorasExibicao = null
      if (diasParaVencimento === 7) {
        intervaloHorasExibicao = 24 * 2 + 18
      } else if (diasParaVencimento === 6) {
        intervaloHorasExibicao = 24 * 1 + 18
      } else if (diasParaVencimento === 5) {
        intervaloHorasExibicao = 24 * 1 + 18
      } else if (diasParaVencimento <= 4) {
        intervaloHorasExibicao = 18
      }

      colaboradorLocalPreferences.setMomentPreference('AvisoImportanteNegocio_DataProximaExibicao', moment().add(intervaloHorasExibicao, 'hours'), { type: 'permanent' })

      const podeMostrarFluxoPagamento = NegocioPagamentoService.podeMostrarFluxoPagamento()

      let text = null
      let title = 'Aviso Importante'
      if (diasParaVencimento >= 0) {
        let descricaoDias = null
        if (diasParaVencimento === 0) {
          descricaoDias = 'hoje'
        } else {
          descricaoDias = 'daqui ' + diasParaVencimento + ' dia' + (diasParaVencimento > 1 ? 's' : '')
        }
        text = (
          <React.Fragment>
            O seu plano vence em {dataVencimentoPlanoAsMoment.format('DD/MMM/YYYY')} ({descricaoDias})
          </React.Fragment>
        )
      } else {
        title = 'Plano Vencido'
        text = (
          <React.Fragment>
            O seu plano <b>venceu em {dataVencimentoPlanoAsMoment.format('DD/MMM/YYYY')}</b>
          </React.Fragment>
        )
      }

      if (podeMostrarFluxoPagamento) {
        text = <React.Fragment>{text}. Faça um pagamento e evite que o seu cadastro seja desabilitado.</React.Fragment>
      } else {
        text = <React.Fragment>{text}. Acesse a sua conta no site do Salão99 Enterprise pelo navegador de internet e regularize o acesso.</React.Fragment>
      }

      const actionButtons = []

      if (podeMostrarFluxoPagamento) {
        actionButtons.push({
          label: 'Efetuar Pagamento',
          onClosedCallbackFunction: () => {
            EventsManager.pub('NavegarPaginaPainel', { rota: '/plano' })
          }
        })
        actionButtons.push({ label: 'Ignorar', onClosedCallbackFunction: params.onIgnore })
      } else {
        actionButtons.push({ label: 'Ok, Entendi', onClosedCallbackFunction: params.onIgnore })
      }

      openBackableDialog(MessageDialog, {
        maxWidth: 580,
        title: title,
        text: (
          <span>
            {text}
            {podeMostrarFluxoPagamento && (
              <React.Fragment>
                <br />
                <br />
                <small>
                  * Se você já realizou o pagamento por boleto bancário, desconsidere essa mensagem;
                  <br />
                  * Pagamentos com cartão de crédito geralmente são aprovados em até 5 minutos;
                  <br />* Pagamentos com boleto bancário são aprovados em até 2 dias úteis.
                </small>
              </React.Fragment>
            )}
          </span>
        ),
        actionButtons: actionButtons
      })
    }
  }

  return exibiuAviso
}

const functionsObject = {
  tentarExibirAvisoImportante: tentarExibirAvisoImportante
}

export default functionsObject
