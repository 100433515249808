import moment from 'moment'

export function converterDataIntParaDate(dataInt) {
  if (dataInt === undefined || dataInt === null) {
    return null
  }
  const dia = parseInt(dataInt.toString().substr(6, 2))
  const mes = parseInt(dataInt.toString().substr(4, 2), 10) - 1
  const ano = parseInt(dataInt.toString().substr(0, 4))
  return new Date(ano, mes, dia)
}

export function converterDateParaDataInt(date) {
  if (date === undefined || date === null) {
    return null
  }
  return parseInt(date.getFullYear().toString().padStart(4, '0') + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0'), 10)
}

export function converterStringParaDate(string, momentFormat) {
  const data = moment(string, momentFormat, true)
  if (data.isValid()) {
    return data.toDate()
  } else {
    return null
  }
}

export function converterMomentParaDataInt(moment) {
  if (moment === undefined || moment === null) {
    return null
  }
  return parseInt(moment.format('YYYYMMDD'), 10)
}

export function converterMomentParaDataHoraMinutoLong(moment) {
  if (moment === undefined || moment === null) {
    return null
  }
  return parseInt(moment.format('YYYYMMDDHHmm'), 10)
}

export function converterDataIntParaMoment(dataInt) {
  return moment(dataInt.toString(), 'YYYYMMDD')
}

export function converterTudoParaMoment(dataObject) {
  if (dataObject) {
    if (dataObject && Number.isInteger(dataObject)) {
      dataObject = converterDataIntParaMoment(dataObject)
    } else if (Object.prototype.toString.call(dataObject) === '[object Date]' && typeof dataObject.getMonth === 'function') {
      dataObject = moment(dataObject)
    }
  }
  return dataObject
}
