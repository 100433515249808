import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import moment from 'moment'
import { getSessaoPainel } from 'pages/erp/painel/PainelErp'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DataExporter from 'support/components/dataexporter/DataExporter'
import LocalPreferences from 'support/components/localpreferences/LocalPreferences'
import DateRangeNavigator from 'support/components/navigator/DateRangeNavigator'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FileFormatEnum from 'support/domain/file/FileFormatEnum'
import InventarioService from 'support/domain/inventario/InventarioService'
import TipoMovimentacaoEstoqueEnum from 'support/domain/inventario/TipoMovimentacaoEstoqueEnum'
import TipoUnidadeMedida from 'support/domain/servico/TipoUnidadeMedida'
import { converterMomentParaDataInt } from 'support/util/DateConverter'
import { converterDataIntParaMoment } from 'support/util/DateConverter'
import { formatarPeriodoNomeArquivo } from 'support/util/DateFormatter'

const styles = (theme) => ({
  colunaData: {
    flexGrow: 0.6,
    maxWidth: 90,
    minWidth: 90
  },
  colunaQuantidade: {
    flexGrow: 0.6,
    maxWidth: 90,
    minWidth: 90
  },
  colunaMotivo: {
    flexGrow: 1.3
  },
  motivoContainerSmall: {
    marginTop: 4,
    fontSize: 14,
    ...theme.text.limitedLines({ maxLines: 2 })
  },
  nomeProdutoContainerSmall: {
    marginTop: 4,
    fontSize: 14,
    ...theme.text.limitedLines({ maxLines: 2 })
  }
})

class RelatorioEstoqueMovimentacoesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.colaboradorLocalPreferences = LocalPreferences.new('colaborador', getSessaoPainel().colaborador.id)
    this.state = {
      ajaxing: false,
      movimentacaoEstoqueDataInicial: this.colaboradorLocalPreferences.getMomentPreference('RelatorioEstoqueMovimentacoesDialogPageFiltro_DataInicial', moment().startOf('month')),
      movimentacaoEstoqueDataFinal: this.colaboradorLocalPreferences.getMomentPreference('RelatorioEstoqueMovimentacoesDialogPageFiltro_DataFinal', moment().endOf('month')),
      lista: []
    }
    this.loaderFunctionsMap = {}
    this.dataExporterFunctionsMap = {}
  }

  getRenderContent = (size, item) => {
    const content = {}
    const sinal = item.tipo.id === TipoMovimentacaoEstoqueEnum.SAIDA.id ? '-' : ''
    content.dataMovimentacao = converterDataIntParaMoment(item.dataMovimentacao).format('DD/MM/YYYY')
    content.nomeProduto = item.produto.nome
    content.motivo = InventarioService.getDescricaoMotivoMovimentacaoEstoque(item)
    const unidadeMedidaPersonalizada = item.tipoUnidadeMedida.id === TipoUnidadeMedida.PERSONALIZADA.id

    content.quantidade = (
      <React.Fragment>
        {unidadeMedidaPersonalizada && (
          <React.Fragment>
            <span style={{ fontSize: 14 }}>{sinal + item.quantidadeUnidadeMedida + ' ' + item.produto.unidadeMedidaEmbalagem}</span>
            <br />
          </React.Fragment>
        )}
        <span style={{ fontSize: 14 }}>
          {(unidadeMedidaPersonalizada ? ' (' : '') + sinal + item.quantidadeUnidades} unid.{unidadeMedidaPersonalizada ? ')' : ''}
        </span>
      </React.Fragment>
    )

    return content
  }

  downloadRelatorio = (event) => {
    this.dataExporterFunctionsMap.export({
      targetAnchorEl: event.currentTarget,
      filename: 'Relatório de Movimentações de Estoque - ' + formatarPeriodoNomeArquivo(this.state.movimentacaoEstoqueDataInicial, this.state.movimentacaoEstoqueDataFinal),
      accessTokenContext: 'colaborador',
      downloadCall: {
        url: 'relatorios.gerarMovimentacoesEstoquePorData',
        params: this.getRequestParameters()
      }
    })
  }

  getRequestParameters = () => {
    return {
      dataInicial: converterMomentParaDataInt(this.state.movimentacaoEstoqueDataInicial),
      dataFinal: converterMomentParaDataInt(this.state.movimentacaoEstoqueDataFinal)
    }
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props
    const { ajaxing, movimentacaoEstoqueDataInicial, movimentacaoEstoqueDataFinal } = this.state

    let toolbarActions = null

    if (!ajaxing && this.state.lista && this.state.lista.length > 0) {
      toolbarActions = {
        actions: [
          {
            icon: <GetAppIcon />,
            label: 'Exportar',
            handleAction: this.downloadRelatorio
          }
        ]
      }
    }

    return (
      <DialogPage {...dialogProps} title="Movimentações" align="center" contentMaxWidth={1000} pageType="basicEntity" ajaxing={ajaxing} toolbarActions={toolbarActions}>
        {(dialogContentProps) => (
          <React.Fragment>
            <DataExporter functionsMap={this.dataExporterFunctionsMap} formats={[FileFormatEnum.PDF, FileFormatEnum.XLSX, FileFormatEnum.CSV]} />

            <FloatContent textAlign="center" type="sectionTitle" context="DialogPage">
              <Typography variant="h5" align="center" className={classes.titulo}>
                Movimentações de Estoque
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', marginRight: 2 }}>
                  <DateRangeNavigator
                    expanded={false}
                    defaultValue={{
                      tipoIntervalo: this.colaboradorLocalPreferences.getPreference('RelatorioEstoqueMovimentacoesDialogPageFiltro_DadosFinanceirosTipoIntervalo', 'mes'),
                      dataInicial: movimentacaoEstoqueDataInicial,
                      dataFinal: movimentacaoEstoqueDataFinal
                    }}
                    onChange={(data) => {
                      this.colaboradorLocalPreferences.setPreference('RelatorioEstoqueMovimentacoesDialogPageFiltro_DadosFinanceirosTipoIntervalo', data.tipoIntervalo.nome)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioEstoqueMovimentacoesDialogPageFiltro_DataInicial', data.dataInicial)
                      this.colaboradorLocalPreferences.setMomentPreference('RelatorioEstoqueMovimentacoesDialogPageFiltro_DataFinal', data.dataFinal)
                      this.setState({
                        movimentacaoEstoqueDataInicial: data.dataInicial,
                        movimentacaoEstoqueDataFinal: data.dataFinal
                      })
                    }}
                  />
                </div>
              </div>
            </FloatContent>

            <VirtualizedResponsiveTable
              key={converterMomentParaDataInt(movimentacaoEstoqueDataInicial).toString() + '-' + converterMomentParaDataInt(movimentacaoEstoqueDataFinal).toString()}
              showBackgroundDividers={true}
              itemsPerRequest={20}
              scrollElement={dialogContentProps.scrollContainerRef.current}
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/relatorios.buscarMovimentacoesEstoquePorData"
              getRequestParametersFunction={this.getRequestParameters}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  this.setState({ lista: data.items })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Data', className: classes.colunaData },
                  { label: 'Produto', props: { xs: true }, horizontalPadding: 'small' },
                  { label: 'Motivo', className: classes.colunaMotivo, horizontalPadding: 'small', props: { xs: true } },
                  { label: 'Quantidade', className: classes.colunaQuantidade, align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)

                  return {
                    itemData: [content.dataMovimentacao, content.nomeProduto, content.motivo, content.quantidade]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 100,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)

                  return {
                    itemData: (
                      <React.Fragment>
                        <Grid container style={{ marginTop: 4 }} alignItems="center">
                          <Grid item xs>
                            <Typography variant="body2">
                              <b>{content.dataMovimentacao}</b>
                            </Typography>
                            <Typography variant="body2" className={classes.motivoContainerSmall}>
                              {content.motivo}
                            </Typography>
                            <Typography variant="body2" className={classes.nomeProdutoContainerSmall}>
                              {content.nomeProduto}
                            </Typography>
                          </Grid>
                          <Grid item style={{ minWidth: 0 }}>
                            <Typography variant="body2" style={{ fontSize: 12, textAlign: 'right' }}>
                              {content.quantidade}
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Não existem dados para o período informado'
              }}
            />
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

RelatorioEstoqueMovimentacoesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(RelatorioEstoqueMovimentacoesDialogPage)
