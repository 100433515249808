import React, { useEffect, useState } from 'react'

import { grupoBoticarioTheme } from '@grupoboticario/flora'
import { Button, Flex, Heading, IconButton, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tag, Text } from '@grupoboticario/flora-react'
import { CrossIcon } from '@grupoboticario/flora-react-icons'
import useAnchorElementHighlight from 'support/hooks/useAnchorElementHighlight'

import { closeButtonStyle, modalBodyStyle, modalContentStyle, modalFooterStyle, modalHeaderStyle, tagStyle, titleStyle } from './Wizard.styles'

type WizardProps = {
  anchorElementClass: string
  shouldBeVisible: boolean
  tagText: string
  heading: string
  description: string
  actionButtonText: string
  onContinue: () => void
  scrollbar?: React.RefObject<{ scrollTop: (arg: number) => void }>
  tooltipDirection?: 'up' | 'down'
}

const ZERO = 0
const ONE = 1

function Wizard({ anchorElementClass, shouldBeVisible, tagText, heading, description, actionButtonText, onContinue, scrollbar, tooltipDirection = 'down' }: WizardProps) {
  const elements = document.querySelectorAll(`.${anchorElementClass}`)
  const lastIndexElement = elements.length - ONE
  const anchorElement = elements[lastIndexElement]

  const [position, setPosition] = useState<DOMRect>({ x: ZERO, y: ZERO, height: ZERO, width: ZERO, bottom: ZERO, top: ZERO, left: ZERO, right: ZERO, toJSON: () => ZERO })
  const [isModalOpen, setIsModalOpen] = useState(shouldBeVisible)

  const { toggleHiglightElement } = useAnchorElementHighlight({ position, elementSelector: `${anchorElementClass}-highlight` })

  const onRefUpdate = (modalContent: HTMLElement | null) => {
    toggleHiglightElement(modalContent)
    scrollbar?.current?.scrollTop(position.bottom)
  }

  useEffect(() => {
    if (anchorElement) {
      setIsModalOpen(shouldBeVisible)

      const rect = anchorElement.getBoundingClientRect()

      if (rect) {
        const isSetOffSet = rect?.x !== 0 || rect?.y !== 0
        if (isSetOffSet) {
          setPosition({ ...rect, top: rect.top, right: rect.right, height: rect.height, width: rect.width })
        }
      }
    }
  }, [anchorElement, shouldBeVisible])

  return (
    <Modal open={isModalOpen}>
      <ModalContent
        ref={(modalContent: HTMLElement | null) => onRefUpdate(modalContent)}
        size="small"
        css={modalContentStyle(position, tooltipDirection)}
        className={grupoBoticarioTheme}
      >
        <Flex>
          {tagText && (
            <Tag shape="ghost" css={tagStyle}>
              {tagText}
            </Tag>
          )}

          <IconButton tabIndex={-1} css={closeButtonStyle} aria-label="Fechar" icon={<CrossIcon color="$actionNavigationStandardStatic" />} onClick={() => setIsModalOpen(false)} />
        </Flex>

        <ModalHeader css={modalHeaderStyle}>
          <Heading level="5" color="$brand-1" css={titleStyle}>
            {heading}
          </Heading>
        </ModalHeader>

        <ModalBody css={modalBodyStyle}>
          <Text color="$dark-light">{description}</Text>
        </ModalBody>

        <ModalFooter css={modalFooterStyle}>
          <Button tabIndex={0} onClick={onContinue}>
            {actionButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default Wizard
