import OrigemMensagemEnum from 'support/domain/suporte/chat/OrigemMensagemEnum'

const NOME_REMETENTE_SISTEMA = 'Mensagem do Sistema'

function getNomeRemetente(mensagem) {
  if (mensagem.origem.id === OrigemMensagemEnum.SISTEMA.id) {
    return NOME_REMETENTE_SISTEMA
  } else {
    return mensagem.nomeRemetente
  }
}

const functionsObject = {
  getNomeRemetente: getNomeRemetente
}

export default functionsObject
