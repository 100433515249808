import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'
import FormaPagamentoCartaoDialogPage from 'pages/erp/painel/formapagamento/FormaPagamentoCartaoDialogPage'
import FormaPagamentoChequeDialogPage from 'pages/erp/painel/formapagamento/FormaPagamentoChequeDialogPage'
import FormaPagamentoDialogPage from 'pages/erp/painel/formapagamento/FormaPagamentoDialogPage'
import FormaPagamentoPixDialogPage from 'pages/erp/painel/formapagamento/FormaPagamentoPixDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps, openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentBypass from 'support/components/contentbypass/ContentBypass'
import EntityConfirmationDialog from 'support/components/dialog/preconstructed/EntityConfirmationDialog'
import EntityRemovalDialog from 'support/components/dialog/preconstructed/EntityRemovalDialog'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputText from 'support/components/input/InputText'
import DialogPage from 'support/components/page/DialogPage'
import FloatContent from 'support/components/page/FloatContent'
import VirtualizedResponsiveTable from 'support/components/table/virtualizedtable/VirtualizedResponsiveTable'
import FormaPagamentoTipoEnum from 'support/domain/formapagamento/FormaPagamentoTipoEnum'
import FormUtil from 'support/util/FormUtil'
import FormaPagamentoMercadoPagoDialogPage from './FormaPagamentoMercadoPagoDialogPage'

const styles = (theme) => ({
  colunaIcone: {
    minWidth: 48,
    maxWidth: 48
  }
})

class FormasPagamentoDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.loaderFunctionsMap = {}

    this.state = {
      lista: [],
      errorsMap: FormUtil.createErrorsMap(),
      ajaxing: false,
      itemMenu: null,
      itemMenuAnchorEl: null
    }
    this.inputNomeFiltro = React.createRef()
  }

  componentDidMount() {
    this.eventsManager.sub('ManutencaoFormaPagamento', (props) => {
      this.loaderFunctionsMap.load()
    })
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }

  handlerInativar = () => {
    openBackableDialog(EntityConfirmationDialog, {
      title: 'Inativar',
      text: (
        <span>
          Você tem certeza que deseja inativar a forma de pagamento <b>{this.state.itemMenu.nome}</b>?
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/formaspagamento.inativar',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManutencaoFormaPagamento')
        }
      }
    })
  }

  handlerAtivar = () => {
    openBackableDialog(EntityConfirmationDialog, {
      title: 'Ativar',
      text: (
        <span>
          Você tem certeza que deseja ativar a forma de pagamento <b>{this.state.itemMenu.nome}</b>?
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/formaspagamento.ativar',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManutencaoFormaPagamento')
        }
      }
    })
  }

  handlerRemover = () => {
    openBackableDialog(EntityRemovalDialog, {
      title: 'Remover',
      text: (
        <span>
          Você tem certeza que deseja remover a forma de pagamento <b>{this.state.itemMenu.nome}</b>? Essa operação não poderá ser desfeita.
        </span>
      ),
      call: {
        method: 'post',
        url: 'erp/formaspagamento.remover',
        contextoUsuario: 'erp',
        data: {
          id: this.state.itemMenu.id
        },
        onSuccess: () => {
          EventsManager.pub('ManutencaoFormaPagamento')
        }
      }
    })
  }

  handlerOpenCreateDialog = () => {
    openBackableDialog(FormaPagamentoDialogPage)
  }

  handlerEditar = (item) => {
    openBackableDialog(FormaPagamentoDialogPage, {
      idFormaPagamento: item.id
    })
  }

  configurar = (formaPagamento) => {
    if (formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id || formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id) {
      openBackableDialog(FormaPagamentoCartaoDialogPage, { formaPagamento: formaPagamento })
    } else if (formaPagamento.tipo.id === FormaPagamentoTipoEnum.CHEQUE.id) {
      openBackableDialog(FormaPagamentoChequeDialogPage)
    } else if (formaPagamento.tipo.id === FormaPagamentoTipoEnum.PIX.id) {
      openBackableDialog(FormaPagamentoPixDialogPage)
    } else if (formaPagamento.tipo.id === FormaPagamentoTipoEnum.MERCADO_PAGO.id) {
      openBackableDialog(FormaPagamentoMercadoPagoDialogPage, {
        formaPagamento: formaPagamento,
      })
    }
  }

  closeItemMenu() {
    this.setState((state) => ({
      itemMenuAnchorEl: null
    }))
  }

  prepararFormasPagamento = (formasPagamento) => {
    let indexTipoCredito = null
    for (let i = 0; i < formasPagamento.length; i++) {
      if (formasPagamento[i].tipo.id === FormaPagamentoTipoEnum.CREDITO.id) {
        indexTipoCredito = i
        break
      }
    }
    formasPagamento.splice(indexTipoCredito, 1)
  }

  getRenderContent = (size, formaPagamento) => {
    const content = {}

    content.nome = formaPagamento.nome
    if (!formaPagamento.ativo) {
      content.nome = (
        <span style={{ color: '#999' }}>
          <strike>{content.nome}</strike> <small>(Inativo)</small>
        </span>
      )
    }

    content.linhaClicavel =
      formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_CREDITO.id ||
      formaPagamento.tipo.id === FormaPagamentoTipoEnum.CARTAO_DEBITO.id ||
      formaPagamento.tipo.id === FormaPagamentoTipoEnum.CHEQUE.id ||
      formaPagamento.tipo.id === FormaPagamentoTipoEnum.CUSTOMIZADA.id ||
      formaPagamento.tipo.id === FormaPagamentoTipoEnum.PIX.id ||
      formaPagamento.tipo.id === FormaPagamentoTipoEnum.MERCADO_PAGO.id

    content.botaoMaisOpcoes = (
      <IconButton
        style={{ marginRight: size === 'small' ? -16 : 0 }}
        onClick={(event) => {
          event.stopPropagation()
          this.setState({ itemMenuAnchorEl: event.currentTarget, itemMenu: formaPagamento, linhaClicavelMenu: content.linhaClicavel })
        }}
      >
        <MoreVertIcon />
      </IconButton>
    )

    return content
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    let onClickRow = (item) => {
      if (item.tipo.id === FormaPagamentoTipoEnum.CUSTOMIZADA.id) {
        this.handlerEditar(item)
      } else {
        this.configurar(item)
      }
    }

    return (
      <DialogPage
        {...dialogProps}
        title="Formas de Pagamento"
        align="center"
        contentMaxWidth={600}
        ajaxing={this.state.ajaxing}
        toolbarActions={{
          actions: [
            {
              icon: <AddIcon />,
              handleAction: this.handlerOpenCreateDialog
            }
          ]
        }}
      >
        {(dialogContentProps) => (
          <React.Fragment>
            <FloatContent context="DialogPage" type="filter">
              <Grid container alignItems="center">
                <Grid item xs>
                  <Grid container alignItems="center" justify="center">
                    <Grid item style={{ marginRight: 9 }}>
                      <SearchIcon color="secondary" />
                    </Grid>
                    <Grid item xs>
                      <InputText
                        customVariant="naked"
                        nakedLeftPadding={false}
                        placeholder="Procurar por nome..."
                        onChange={(event) => {
                          this.loaderFunctionsMap.load()
                        }}
                        inputRef={this.inputNomeFiltro}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FloatContent>
            <br />
            <VirtualizedResponsiveTable
              scrollElement={dialogContentProps.scrollContainerRef.current}
              showBackgroundDividers={true}
              itemsPerRequest={20}  
              loaderFunctionsMap={this.loaderFunctionsMap}
              contextoUsuario="erp"
              endpoint="erp/formaspagamento.buscar"
              getRequestParametersFunction={() => ({
                nome: this.inputNomeFiltro.current ? this.inputNomeFiltro.current.value : null
              })}
              items={this.state.lista}
              loadTrackingFunction={(data) => {
                if (data.status === 'loading') {
                  if (this.state.lista.length > 0 && (data.action === 'load' || data.action === 'refresh')) {
                    this.setState({ lista: [] })
                  }
                } else if (data.status === 'loaded') {
                  const formasPagamento = data.items
                  this.prepararFormasPagamento(formasPagamento)
                  this.setState({ lista: formasPagamento })
                }
              }}
              largeRenderProps={{
                columns: [
                  { label: 'Nome', props: { xs: true } },
                  { className: classes.colunaIcone, horizontalPadding: 'small', align: 'right' }
                ],
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('large', item)
                  return {
                    onClickRow: content.linhaClicavel ? onClickRow : null,
                    itemData: [content.nome, content.botaoMaisOpcoes]
                  }
                }
              }}
              smallRenderProps={{
                rowHeight: 80,
                itemRenderer: (item, index) => {
                  const content = this.getRenderContent('small', item)
                  return {
                    onClickRow: content.linhaClicavel ? onClickRow : null,
                    itemData: (
                      <React.Fragment>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography variant="body2">{content.nome}</Typography>
                          </Grid>
                          <Grid item>{content.botaoMaisOpcoes}</Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                }
              }}
              emptyListProps={{
                text: 'Nenhuma forma de pagamento foi encontrada',
                textButton: 'Cadastrar forma de pagamento',
                creationFunction: this.handlerOpenCreateDialog
              }}
            />

            <Menu anchorEl={this.state.itemMenuAnchorEl} open={Boolean(this.state.itemMenuAnchorEl)} onClose={() => this.closeItemMenu()}>
              {this.state.itemMenu && (
                <ContentBypass>
                  {this.state.linhaClicavelMenu && (
                    <MenuItem
                      onClick={() => {
                        onClickRow(this.state.itemMenu)
                        this.closeItemMenu()
                      }}
                    >
                      Configurar
                    </MenuItem>
                  )}
                  {this.state.itemMenu.ativo && !this.state.itemMenu.permiteExclusao && (
                    <MenuItem
                      onClick={() => {
                        this.handlerInativar()
                        this.closeItemMenu()
                      }}
                    >
                      Inativar
                    </MenuItem>
                  )}
                  {this.state.itemMenu.permiteExclusao && (
                    <MenuItem
                      onClick={() => {
                        this.handlerRemover()
                        this.closeItemMenu()
                      }}
                    >
                      Remover
                    </MenuItem>
                  )}
                  {!this.state.itemMenu.ativo && (
                    <MenuItem
                      onClick={() => {
                        this.handlerAtivar()
                        this.closeItemMenu()
                      }}
                    >
                      Ativar
                    </MenuItem>
                  )}
                </ContentBypass>
              )}
            </Menu>
          </React.Fragment>
        )}
      </DialogPage>
    )
  }
}

FormasPagamentoDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FormasPagamentoDialogPage)
