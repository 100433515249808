import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import LinkMUI from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TermosEPrivacidade from 'support/components/termoseprivacidade/TermosEPrivacidade'
import ServicoService from 'support/domain/servico/ServicoService'
import TipoExibicaoPrecoEnum from 'support/domain/servico/TipoExibicaoPrecoEnum'
import { formatarValorMonetario } from 'support/util/NumberFormatter'
import { formatarNome } from 'support/util/TextFormatter'
import { formatarHorarioInt } from 'support/util/TimeFormatter'

import styles from './styles'

const ID_QUALQUER_COLABORADOR = 0

const StepConfirmacao = ({ ajaxing, itensSubmetidos, dataSelecionada, agendarAtendimentos, onBack, classes }) => {
  const TermosEPrivacidadeLinkProps = TermosEPrivacidade.getLinkProps()
  const politicaPrivacidadeLinkProps = TermosEPrivacidadeLinkProps.politicaPrivacidade
  const termosUsoLinkProps = TermosEPrivacidadeLinkProps.termosUso
  return (
    <div>
      <Grid container alignItems="center" style={{ marginBottom: 24 }}>
        <Grid item xs style={{ paddingLeft: 0 }}>
          <Typography variant="h6" className={classes.titulo}>
            {ajaxing ? 'Aguarde...' : 'Confirme os dados...'}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ opacity: ajaxing ? 0.4 : 1 }}>
        {itensSubmetidos.map((item) => {
          let nomeColaborador = null
          let colaborador = item.mapColaboradoresPorId[item.colaborador.id]
          if (colaborador.id === ID_QUALQUER_COLABORADOR) {
            nomeColaborador = colaborador.nome
          } else {
            nomeColaborador = 'Profissional: ' + formatarNome(colaborador.nome, 2)
          }

          let precoFormatado = ''
          if (TipoExibicaoPrecoEnum.OCULTO.id !== item.servico.tipoExibicaoPreco.id) {
            const complementoPreco = TipoExibicaoPrecoEnum.A_PARTIR.id === item.servico.tipoExibicaoPreco.id ? '+' : ''
            precoFormatado = ' \u2022 ' + formatarValorMonetario(item.variacao.preco) + complementoPreco
          }

          return (
            <div style={{ marginBottom: 32 }} key={item.id}>
              <Typography variant="h6" className={classes.confirmacaoNomeServico}>
                {ServicoService.gerarNomeCompletoServico(item.servico, item.variacao)}
              </Typography>
              <Typography variant="body2" className={classes.confirmacaoData}>
                {dataSelecionada.format('ddd, DD/MMM/YYYY')} às {formatarHorarioInt(item.periodoHorarioSelecionado.de)} {precoFormatado}
              </Typography>
              <Typography variant="body2" className={classes.confirmacaoProfissional}>
                {nomeColaborador}
              </Typography>
              {item.textoObservacoes && (
                <Typography variant="body2" className={classes.confirmacaoObservacoes}>
                  Observações: {item.textoObservacoes}
                </Typography>
              )}
            </div>
          )
        })}
        <Typography variant="body2">
          Ao agendar esse atendimento, você confirma que é maior de 13 anos e está de acordo com o{' '}
          <LinkMUI className={classes.link} {...termosUsoLinkProps} rel="noopener noreferrer" underline="always">
            Termo de uso
          </LinkMUI>
          . Seus dados serão tratados nos termos de nossa{' '}
          <LinkMUI className={classes.link} {...politicaPrivacidadeLinkProps} rel="noopener noreferrer" underline="always">
            Política de Privacidade
          </LinkMUI>
        </Typography>
        <br />
      </div>

      <div style={{ textAlign: 'right', marginTop: 16, marginRight: 0 }}>
        <Button disabled={ajaxing} style={{ textTransform: 'none', fontSize: 16, marginRight: 16 }} color="primary" onClick={() => onBack()}>
          Voltar
        </Button>
        <Button disabled={ajaxing} style={{ textTransform: 'none', fontSize: 16, marginRight: -4 }} color="primary" variant="contained" onClick={agendarAtendimentos}>
          {ajaxing ? (
            <span>
              <CircularProgress size={18} thickness={5.5} style={{ verticalAlign: 'middle', marginRight: 8 }} /> Aguarde...
            </span>
          ) : (
            'Agendar'
          )}
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(StepConfirmacao)
