import React, { Component } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import AlteracaoValorReferenciaDialogPage from 'pages/erp/painel/cliente/AlteracaoValorReferenciaDialogPage'
import AlterarServicoDialogPage from 'pages/erp/painel/cliente/AlterarServicoDialogPage'
import CancelarServicoDialogPage from 'pages/erp/painel/cliente/CancelarServicoDialogPage'
import GerenciarAtendimentosPacoteDialogPage from 'pages/erp/painel/cliente/GerenciarAtendimentosPacoteDialogPage'
import PropTypes from 'prop-types'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import DialogPage from 'support/components/page/DialogPage'

const styles = (theme) => ({})

class GerenciarPacoteDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      title: 'Gerenciar Pacote'
    }
  }

  handleAlterarValorReferencia = () => {
    openBackableDialog(AlteracaoValorReferenciaDialogPage, {
      parent: this,
      clientePacoteItem: this.props.clientePacoteItem
    })
  }

  handleAlterarServico = () => {
    openBackableDialog(AlterarServicoDialogPage, {
      parent: this,
      clientePacoteItem: this.props.clientePacoteItem
    })
  }

  handleCancelarPacote = () => {
    openBackableDialog(CancelarServicoDialogPage, {
      parent: this,
      clientePacoteItem: this.props.clientePacoteItem
    })
  }

  handleGerenciarAtendimentos = () => {
    openBackableDialog(GerenciarAtendimentosPacoteDialogPage, {
      parent: this,
      clientePacoteItem: this.props.clientePacoteItem,
      cliente: this.props.cliente
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const inativarOpcoesSemCreditoDisponivel = this.props.clientePacoteItem.quantidadeDisponivel === 0
    const inativarOpcoesSemCredito = this.props.clientePacoteItem.quantidadeDisponivel === 0 && this.props.clientePacoteItem.quantidadeUtilizada === 0

    return (
      <DialogPage {...dialogProps} title={this.state.title} align="center" contentMaxWidth={500} toolbarActions={this.state.toolbarActions}>
        <List disablePadding component="nav" style={{ marginTop: 0, cursor: 'pointer' }}>
          <ListItem
            disabled={inativarOpcoesSemCredito}
            disableGutters
            style={{ marginTop: 8, cursor: inativarOpcoesSemCredito ? 'default' : 'pointer' }}
            onClick={() => (!inativarOpcoesSemCredito ? this.handleGerenciarAtendimentos() : null)}
          >
            <ListItemIcon>
              <ListAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Gerenciar Atendimentos</b>} secondary="Gerencie quais atendimentos utilizam os créditos deste pacote" />
          </ListItem>
          <ListItem
            disabled={inativarOpcoesSemCreditoDisponivel}
            style={{ cursor: inativarOpcoesSemCreditoDisponivel ? 'default' : 'pointer' }}
            disableGutters
            onClick={() => (!inativarOpcoesSemCreditoDisponivel ? this.handleAlterarServico() : null)}
          >
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Alterar Serviço</b>} secondary=" Os créditos disponíveis no serviço atual serão zerados e migrados para o novo serviço escolhido" />
          </ListItem>
          <ListItem
            disabled={inativarOpcoesSemCreditoDisponivel}
            style={{ cursor: inativarOpcoesSemCreditoDisponivel ? 'default' : 'pointer' }}
            disableGutters
            onClick={() => (!inativarOpcoesSemCreditoDisponivel ? this.handleAlterarValorReferencia() : null)}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Alterar valor unitário do serviço</b>} secondary="O valor unitário é utilizado para para modificar o preço padrão do serviço neste pacote" />
          </ListItem>
          <ListItem
            disabled={inativarOpcoesSemCreditoDisponivel}
            style={{ cursor: inativarOpcoesSemCreditoDisponivel ? 'default' : 'pointer' }}
            disableGutters
            onClick={() => (!inativarOpcoesSemCreditoDisponivel ? this.handleCancelarPacote() : null)}
          >
            <ListItemIcon>
              <CancelOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={<b>Cancelar/Estornar Créditos</b>}
              secondary="Os créditos disponíveis podem ser devolvidos em forma de estorno no pagamento ou adicionado como crédito para o cliente"
            />
          </ListItem>
        </List>
      </DialogPage>
    )
  }
}

GerenciarPacoteDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GerenciarPacoteDialogPage)
