import React, { Component } from 'react'

import Badge from '@material-ui/core/Badge'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import NotesIcon from '@material-ui/icons/Notes'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import classNames from 'classnames'
import moment from 'moment'
import NegociosDialogPage from 'pages/admin/painel/negocio/NegociosDialogPage'
import { verificarAutorizacao } from 'pages/admin/painel/PainelAdmin'
import AnotacoesDialogPage from 'pages/admin/painel/usuario/AnotacoesDialogPage'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelAdminApiClient'
import { openBackableDialog } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import InterfaceChatSuporte, { VisaoEnum } from 'support/components/chat/InterfaceChatSuporte'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import VirtualizedList from 'support/components/list/virtualizedlist/VirtualizedList'
import PanelPage from 'support/components/panel/PanelPage'
import CustomScrollbars from 'support/components/scroll/CustomScrollbars'
import PermissoesAcesso from 'support/domain/admin/usuario/PermissoesAcesso'

const INTERVALO_BUSCA_CONVERSAS = 5000
const ROW_HEIGHT_CONVERSAS = 50

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  containerConversas: {
    width: '13%',
    minWidth: 46,
    maxWidth: 250,
    backgroundColor: '#fff'
  },
  containerConversaWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
    height: ROW_HEIGHT_CONVERSAS - 1,
    backgroundColor: '#fff',
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  containerConversaWrapperSelecionado: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.92),
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.light, 0.92)
    }
  },
  containerConversa: {
    padding: '8px',
    paddingLeft: 16,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 4
    }
  },
  conversaNaoRespondida: {},
  conversaNomeUsuario: {
    fontSize: 13,
    whiteSpace: 'nowrap',
    '$conversaNaoRespondida &': {
      color: theme.palette.secondary.main,
      fontWeight: 500
    }
  },
  conversaTempoEsperando: {
    fontSize: 10
  },
  containerUsuario: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  containerUsuarioHeader: {
    height: 74,
    position: 'relative',
    paddingLeft: 40,
    width: 'calc(100% - 40px)',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      width: 'calc(100% - 20px)'
    },
    backgroundColor: '#fff',
    //...theme.util.createBoxShadow("-1px 1px 7px -1px rgb(0,0,0,0.1)"),
    zIndex: 10
  },
  containerUsuarioPainel: {
    position: 'relative',
    flex: '1 1 0',
    height: '100%'
  },
  iconBadge: {
    color: '#ffffff',
    backgroundColor: theme.palette.secondary.main
  }
})

class SuporteChatPanelPage extends Component {
  constructor(props) {
    super(props)
    this.mounted = true

    this.dataUltimaBuscaConversas = 0
    this.buscarConversas = true
    this.buscandoConversas = false
    this.carregarConversaFunctionsMap = {}
    this.eventsManager = EventsManager.new()

    this.state = {
      permissaoAcessarChat: true,
      idConversa: null,
      conversa: null,
      conversas: []
    }
  }

  componentDidMount = () => {
    this.eventsManager.sub('ManipulacaoAnotacao', (props) => {
      this.carregarConversaFunctionsMap.reload()
    })
    this.tentarBuscarNovasConversas()
    EventsManager.pub('PanelFullScreenMode', true)
  }

  programarBuscaConversas = (delay) => {
    window.setTimeout(
      () => {
        this.buscarConversas = true
      },
      delay ? delay : 0
    )
  }

  carregarConversa = (notifyContentLoaded, notifyContentNotLoaded) => {
    getAPI({
      url: 'admin/chat.buscarConversa',
      params: {
        idConversa: this.state.idConversa
      },
      onSuccess: (response) => {
        this.setState({
          conversa: response.data
        })

        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      },
      requerAutorizacao: true
    })
  }

  tentarBuscarNovasConversas = () => {
    if (!this.mounted) {
      return
    }
    if (this.buscarConversas || new Date().getTime() - this.dataUltimaBuscaConversas > INTERVALO_BUSCA_CONVERSAS) {
      if (this.buscandoConversas === false) {
        this.buscandoConversas = true
        this.buscarConversas = false
        this.dataUltimaBuscaConversas = new Date().getTime()

        getAPI({
          url: 'admin/chat.buscarUltimasConversas',
          requerAutorizacao: true,
          onSuccess: (response) => {
            const conversas = response.data
            if (conversas && conversas.length > 0) {
              for (let i = 0; i < conversas.length; i++) {
                const conversa = conversas[i]
                conversa._update = true
              }
              const newState = { conversas: conversas }
              if (this.state.idConversa === null) {
                newState.idConversa = conversas[0].id
              }
              this.setState(newState)
            }
            this.buscandoConversas = false
          },
          onError: (response) => {
            if (response.code === 401 || response.code === 403) {
              this.setState({ permissaoAcessarChat: false })
            } else {
              this.programarBuscaConversas(500)
            }
            this.buscandoConversas = false
          },
          sendErroToGenericSnackbar: false
        })
      }
    }
    window.setTimeout(() => {
      this.tentarBuscarNovasConversas()
    }, 100)
  }

  renderConversa = (conversa, { index, key, style }) => {
    const { classes } = this.props

    let nomeUsuario = conversa.usuario.nome
    let descricaoEsperando = null
    const containerConversaWrapperClasses = [classes.containerConversaWrapper]
    if (conversa.respostaPendente) {
      containerConversaWrapperClasses.push(classes.conversaNaoRespondida)
      nomeUsuario = '* ' + nomeUsuario
      if (conversa.dataRespostaPendente) {
        descricaoEsperando = <span style={{ whiteSpace: 'nowrap' }}>{moment(conversa.dataRespostaPendente).format('HH:mm DD/MMM')}</span>
      }
    }
    if (this.state.conversa && this.state.idConversa === conversa.id) {
      containerConversaWrapperClasses.push(classes.containerConversaWrapperSelecionado)
    }

    return (
      <div key={key} style={style}>
        <div
          className={classNames(containerConversaWrapperClasses)}
          onClick={() => {
            this.selecionarConversa(conversa)
          }}
        >
          <div className={classes.containerConversa}>
            <Typography className={classes.conversaNomeUsuario} variant="body2">
              {nomeUsuario}
            </Typography>
            {descricaoEsperando && <div className={classes.conversaTempoEsperando}>{descricaoEsperando}</div>}
          </div>
        </div>
      </div>
    )
  }

  selecionarConversa = (conversa) => {
    const { conversas } = this.state
    for (let i = 0; i < conversas.length; i++) {
      conversas[i]._update = true
    }
    this.setState({ idConversa: conversa.id, conversas: conversas })
  }

  openUsuarioMenu = (event) => {
    this.setState({ usuarioMenuAnchorEl: event.currentTarget })
  }

  closeUsuarioMenu = () => {
    this.setState({ usuarioMenuAnchorEl: null })
  }

  marcarComoLido = () => {
    postAPI({
      url: 'admin/chat.marcarConversaComoLida',
      requerAutorizacao: true,
      data: {
        idConversa: this.state.conversa.id
      },
      onSuccess: (response) => {
        this.programarBuscaConversas(0)
        this.closeUsuarioMenu()
      }
    })
  }

  marcarConversaPedidoAvaliacaoApp = (conversa) => {
    const novoValorFlag = !conversa.pedidoAvaliacaoApp
    this.setState({ ajaxingConversaPedidoAvaliacaoApp: true })

    postAPI({
      url: 'admin/chat.marcarConversaPedidoAvaliacaoApp',
      requerAutorizacao: true,
      data: {
        idConversa: conversa.id,
        flag: novoValorFlag
      },
      onSuccess: (response) => {
        const conversaState = this.state.conversa
        if (conversaState.id === conversa.id) {
          conversaState.pedidoAvaliacaoApp = novoValorFlag
        }
        this.setState({ ajaxingConversaPedidoAvaliacaoApp: false })
      },
      repeat: {
        stopFunction: () => {
          return this.state.conversa.id !== conversa.id
        }
      }
    })
  }

  abrirModoAdmin = () => {
    openBackableDialog(NegociosDialogPage, { usuario: this.state.conversa.usuario })
  }

  abrirAnotacoes = () => {
    openBackableDialog(AnotacoesDialogPage, { usuario: this.state.conversa.usuario })
  }

  marcarComoNaoLido = () => {
    postAPI({
      url: 'admin/chat.marcarConversaComoNaoLida',
      requerAutorizacao: true,
      data: {
        idConversa: this.state.conversa.id
      },
      onSuccess: (response) => {
        this.programarBuscaConversas(0)
        this.closeUsuarioMenu()
      }
    })
  }

  render() {
    const { classes, ...others } = this.props
    const { permissaoAcessarChat, conversas, usuarioMenuAnchorEl, conversa, ajaxingConversaPedidoAvaliacaoApp } = this.state

    return (
      <PanelPage {...others} title="Suporte - Chat">
        <div className={classes.root}>
          <React.Fragment>
            <div className={classes.containerConversas}>
              <CustomScrollbars
                hideTracksWhenNotNeeded={true}
                showOnHover={true}
                verticalScrollWidth={4}
                applyWillChangeOnScrollContainer={true}
                rrenderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style, right: 0, height: '100%', width: 6, backgroundColor: '#ededed' }} />}
                rrenderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: '#fff', width: 4, borderRadius: 3, left: 1 }} />}
              >
                {(scrollProps) => (
                  <div>
                    {permissaoAcessarChat && (
                      <VirtualizedList
                        rowHeight={ROW_HEIGHT_CONVERSAS}
                        scrollElement={scrollProps.scrollElement}
                        items={conversas}
                        rowRenderer={(conversa, props) => {
                          return this.renderConversa(conversa, props)
                        }}
                      />
                    )}
                  </div>
                )}
              </CustomScrollbars>
            </div>

            {this.state.idConversa && (
              <ContentWithPreload functionsMap={this.carregarConversaFunctionsMap} loadFunction={this.carregarConversa} loadKey={this.state.idConversa}>
                {() => {
                  return (
                    <div className={classes.containerUsuario}>
                      <div className={classes.containerUsuarioHeader}>
                        <Grid container wrap="nowrap" style={{ height: '50%' }}>
                          <Grid item>
                            <Typography variant="body2" style={{ marginTop: 1 }}>
                              <b>{conversa.usuario.nome}</b>
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: 12, marginTop: -2 }}>
                              {conversa.usuario.email}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container wrap="nowrap" style={{ height: '50%', marginLeft: -3, marginTop: 8 }}>
                          <Grid item style={{ marginRight: 16 }}>
                            <IconButton
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.abrirAnotacoes()
                              }}
                            >
                              <Badge
                                variant="dot"
                                classes={{ badge: classes.iconBadge }}
                                badgeContent={conversa.usuario.anotacoes ? conversa.usuario.anotacoes.length : 0}
                                max={9}
                                overlap="rectangular"
                              >
                                <NotesIcon fontSize="small" style={{ display: 'block' }} />
                              </Badge>
                            </IconButton>
                          </Grid>
                          <Grid item style={{ marginRight: 16 }}>
                            <IconButton
                              size="small"
                              disabled={ajaxingConversaPedidoAvaliacaoApp}
                              style={{ opacity: ajaxingConversaPedidoAvaliacaoApp ? 0.3 : 1 }}
                              onClick={(event) => {
                                this.marcarConversaPedidoAvaliacaoApp(conversa)
                              }}
                            >
                              {!conversa.pedidoAvaliacaoApp && <StarBorderIcon fontSize="small" style={{ display: 'block' }} />}
                              {conversa.pedidoAvaliacaoApp && <StarIcon fontSize="small" style={{ display: 'block', color: '#ffd066' }} />}
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton size="small" onClick={this.openUsuarioMenu}>
                              <MoreVertIcon fontSize="small" style={{ display: 'block' }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Menu anchorEl={usuarioMenuAnchorEl} open={Boolean(usuarioMenuAnchorEl)} onClose={() => this.closeUsuarioMenu()}>
                          <MenuItem
                            onClick={() => {
                              this.marcarComoLido()
                              this.closeUsuarioMenu()
                            }}
                          >
                            Marca como Lido
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.marcarComoNaoLido()
                              this.closeUsuarioMenu()
                            }}
                          >
                            Marca como Não Lido
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.abrirModoAdmin()
                              this.closeUsuarioMenu()
                            }}
                          >
                            Listar Negócios
                          </MenuItem>
                        </Menu>
                      </div>
                      <div className={classes.containerUsuarioPainel}>
                        <InterfaceChatSuporte
                          key={conversa.id}
                          maxWidth={900}
                          containerPadding="basic"
                          showEmojisButton={false}
                          visao={VisaoEnum.ATENDENTE}
                          conversa={conversa}
                          permissaoAcessarChat={verificarAutorizacao([PermissoesAcesso.PODE_ACESSAR_SUPORTE_CHAT])}
                          permissaoEnviarMensagemChat={verificarAutorizacao([PermissoesAcesso.PODE_ENVIAR_MENSAGEM_SUPORTE_CHAT])}
                        />
                      </div>
                    </div>
                  )
                }}
              </ContentWithPreload>
            )}
          </React.Fragment>
        </div>
      </PanelPage>
    )
  }

  componentWillUnmount() {
    this.mounted = false
    this.eventsManager.unsubscribe()
  }
}

SuporteChatPanelPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(SuporteChatPanelPage)
