import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { extractDialogProps } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import ContentWithPreload from 'support/components/contentload/ContentWithPreload'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import DialogPage from 'support/components/page/DialogPage'
import PrioridadeComissaoPadraoProdutoEnum from 'support/domain/comissao/PrioridadeComissaoPadraoProdutoEnum'
import PrioridadeComissaoPadraoServicoEnum from 'support/domain/comissao/PrioridadeComissaoPadraoServicoEnum'
import RegraComissaoDescontoEnum from 'support/domain/comissao/RegraComissaoDescontoEnum'
import RegraComissaoGeracaoEnum from 'support/domain/comissao/RegraComissaoGeracaoEnum'
import RegraComissaoTaxaRecebimentoEnum from 'support/domain/comissao/RegraComissaoTaxaRecebimentoEnum'
import { createErrorsMap, focusFirstElementWithError } from 'support/util/FormUtil'

const styles = (theme) => ({
  containerFooterButtons: theme.form.containerFooterButtons()
})

class ConfiguracoesComissoesDialogPage extends Component {
  static isDialogPage = true

  constructor(props) {
    super(props)

    this.state = {
      paginaCarregada: false,
      prioridadeComissaoServicoPadrao: PrioridadeComissaoPadraoServicoEnum.COLABORADOR,
      prioridadeComissaoProdutoPadrao: PrioridadeComissaoPadraoProdutoEnum.COLABORADOR,
      regraComissaoTaxaRecebimentoPrincipal: RegraComissaoTaxaRecebimentoEnum.NAO_APLICAR_TAXA,
      regraComissaoTaxaRecebimentoAssistente: RegraComissaoTaxaRecebimentoEnum.NAO_APLICAR_TAXA,
      regraComissaoDescontoProfissional: RegraComissaoDescontoEnum.NAO_APLICAR_DESCONTO,
      regraComissaoDescontoAssistente: RegraComissaoDescontoEnum.NAO_APLICAR_DESCONTO,
      regraComissaoGeracao: RegraComissaoGeracaoEnum.FECHAMENTO_CONTA,
      title: 'Configurações',
      errorsMap: createErrorsMap(),
      ajaxing: false
    }

    this.inputNome = React.createRef()
  }

  carregarMain = (notifyContentLoaded, notifyContentNotLoaded) => {
    this.setState({ ajaxing: true })

    getAPI({
      url: 'erp/negocios.buscarConfiguracoesGerais',
      requerAutorizacao: true,
      onPreFinal: () => {
        this.setState({ ajaxing: false })
      },
      onSuccess: (response) => {
        this.setState({
          prioridadeComissaoServicoPadrao: PrioridadeComissaoPadraoServicoEnum.getById(response.data.prioridadeComissaoServicoPadrao.id),
          prioridadeComissaoProdutoPadrao: PrioridadeComissaoPadraoProdutoEnum.getById(response.data.prioridadeComissaoProdutoPadrao.id),
          regraComissaoTaxaRecebimentoProfissional: RegraComissaoTaxaRecebimentoEnum.getById(response.data.regraComissaoTaxaRecebimentoProfissional.id),
          regraComissaoTaxaRecebimentoAssistente: RegraComissaoTaxaRecebimentoEnum.getById(response.data.regraComissaoTaxaRecebimentoAssistente.id),
          regraComissaoDescontoProfissional: RegraComissaoDescontoEnum.getById(response.data.regraComissaoDescontoProfissional.id),
          regraComissaoDescontoAssistente: RegraComissaoDescontoEnum.getById(response.data.regraComissaoDescontoAssistente.id),
          regraComissaoGeracao: RegraComissaoGeracaoEnum.getById(response.data.regraComissaoGeracao.id)
        })
        this.registrarPaginaCarregada()
        notifyContentLoaded()
      },
      onError: (response) => {
        notifyContentNotLoaded({ messageErrorCode: response.code })
      }
    })
  }

  registrarPaginaCarregada = () => {
    this.setState((state) => ({
      paginaCarregada: true,
      toolbarActions: {
        actions: [
          {
            label: 'Salvar',
            handleAction: () => this.salvar()
          }
        ]
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.salvar()
  }

  salvar = () => {
    this.setState((state) => ({
      ajaxing: true,
      errorsMap: createErrorsMap()
    }))

    postAPI({
      url: 'erp/negocios.alterarConfiguracoesGerais',
      data: {
        prioridadeComissaoServicoPadrao: {
          id: this.state.prioridadeComissaoServicoPadrao.id
        },
        prioridadeComissaoProdutoPadrao: {
          id: this.state.prioridadeComissaoProdutoPadrao.id
        },
        regraComissaoTaxaRecebimentoProfissional: {
          id: this.state.regraComissaoTaxaRecebimentoProfissional.id
        },
        regraComissaoTaxaRecebimentoAssistente: {
          id: this.state.regraComissaoTaxaRecebimentoAssistente.id
        },
        regraComissaoDescontoProfissional: {
          id: this.state.regraComissaoDescontoProfissional.id
        },
        regraComissaoDescontoAssistente: {
          id: this.state.regraComissaoDescontoAssistente.id
        },
        regraComissaoGeracao: {
          id: this.state.regraComissaoGeracao.id
        }
      },
      requerAutorizacao: true,
      onSuccess: () => {
        this.props.handleCloseDialog()
        EventsManager.pub('AtualizarSessao')
      },
      onError: (response) => {
        this.setState((state) => ({ ajaxing: false, errorsMap: createErrorsMap(response.data.errors) }))
        focusFirstElementWithError('formConfiguracoesComissoes')
      }
    })
  }

  render() {
    const dialogProps = extractDialogProps(this.props)
    const { classes } = this.props

    return (
      <DialogPage
        {...dialogProps}
        title={this.state.title}
        align="center"
        contentMaxWidth={640}
        pageType="basicEntity"
        ajaxing={this.state.ajaxing}
        toolbarActions={this.state.toolbarActions}
      >
        <ContentWithPreload loadContentClassName={classes.paperContent} loadFunction={this.carregarMain}>
          {() => (
            <form id="formConfiguracoesComissoes" autoComplete="off" noValidate onSubmit={(event) => this.handleSubmit(event)}>
              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra para geração de comissões
              </Typography>
              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.regraComissaoGeracao.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoGeracao: RegraComissaoGeracaoEnum.getById(id) })
                }}
              >
                {RegraComissaoGeracaoEnum.values().map((regra) => (
                  <MenuItem key={regra.id} value={regra.id}>
                    {regra.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.regraComissaoGeracao.descricaoExplicacao}
              </Typography>

              <Divider style={{ marginTop: 32 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Prioridade para aplicação das comissões de serviço
              </Typography>
              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.prioridadeComissaoServicoPadrao.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ prioridadeComissaoServicoPadrao: PrioridadeComissaoPadraoServicoEnum.getById(id) })
                }}
              >
                {PrioridadeComissaoPadraoServicoEnum.values().map((prioridade) => (
                  <MenuItem key={prioridade.id} value={prioridade.id}>
                    {prioridade.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.prioridadeComissaoServicoPadrao.descricaoExplicacao}
              </Typography>

              <Divider style={{ marginTop: 32 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Prioridade para aplicação das comissões de venda de produtos
              </Typography>
              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.prioridadeComissaoProdutoPadrao.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ prioridadeComissaoProdutoPadrao: PrioridadeComissaoPadraoProdutoEnum.getById(id) })
                }}
              >
                {PrioridadeComissaoPadraoProdutoEnum.values().map((prioridade) => (
                  <MenuItem key={prioridade.id} value={prioridade.id}>
                    {prioridade.descricaoOpcao}
                  </MenuItem>
                ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.prioridadeComissaoProdutoPadrao.descricaoExplicacao}
              </Typography>

              <Divider style={{ marginTop: 32 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo de comissões/gorjetas com taxa de forma de pagamento para o profissional principal
              </Typography>
              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.regraComissaoTaxaRecebimentoProfissional.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoTaxaRecebimentoProfissional: RegraComissaoTaxaRecebimentoEnum.getById(id) })
                }}
              >
                {RegraComissaoTaxaRecebimentoEnum.values()
                  .filter((regra) => regra.id !== RegraComissaoTaxaRecebimentoEnum.REGRA_PADRAO_SISTEMA.id)
                  .map((regra) => (
                    <MenuItem key={regra.id} value={regra.id}>
                      {regra.descricaoOpcao}
                    </MenuItem>
                  ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.regraComissaoTaxaRecebimentoProfissional.descricaoExplicacao}
              </Typography>

              <Divider style={{ marginTop: 32 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo de comissões/gorjetas com taxa de forma de pagamento para o assistente
              </Typography>
              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.regraComissaoTaxaRecebimentoAssistente.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoTaxaRecebimentoAssistente: RegraComissaoTaxaRecebimentoEnum.getById(id) })
                }}
              >
                {RegraComissaoTaxaRecebimentoEnum.values()
                  .filter((regra) => regra.id !== RegraComissaoTaxaRecebimentoEnum.REGRA_PADRAO_SISTEMA.id)
                  .map((regra) => (
                    <MenuItem key={regra.id} value={regra.id}>
                      {regra.descricaoOpcao}
                    </MenuItem>
                  ))}
              </InputSelect>
              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.regraComissaoTaxaRecebimentoAssistente.descricaoExplicacao}
              </Typography>

              <Divider style={{ marginTop: 32 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo de comissões com descontos para o profissional principal
              </Typography>

              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.regraComissaoDescontoProfissional.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoDescontoProfissional: RegraComissaoDescontoEnum.getById(id) })
                }}
              >
                {RegraComissaoDescontoEnum.values()
                  .filter((regra) => regra.id !== RegraComissaoDescontoEnum.REGRA_PADRAO_SISTEMA.id)
                  .map((regra) => (
                    <MenuItem key={regra.id} value={regra.id}>
                      {regra.descricaoOpcao}
                    </MenuItem>
                  ))}
              </InputSelect>

              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.regraComissaoDescontoProfissional.descricaoExplicacao}
              </Typography>

              <Divider style={{ marginTop: 32 }} />

              <Typography variant="body2" style={{ marginTop: 32, fontWeight: 500 }}>
                Regra de cálculo de comissões com descontos para o assistente
              </Typography>

              <InputSelect
                style={{ marginTop: 2, marginBottom: 2 }}
                fullWidth={false}
                customVariant="naked"
                nakedLeftPadding={false}
                value={this.state.regraComissaoDescontoAssistente.id}
                onChange={(event) => {
                  const id = event.target.value
                  this.setState({ regraComissaoDescontoAssistente: RegraComissaoDescontoEnum.getById(id) })
                }}
              >
                {RegraComissaoDescontoEnum.values()
                  .filter((regra) => regra.id !== RegraComissaoDescontoEnum.REGRA_PADRAO_SISTEMA.id)
                  .map((regra) => (
                    <MenuItem key={regra.id} value={regra.id}>
                      {regra.descricaoOpcao}
                    </MenuItem>
                  ))}
              </InputSelect>

              <Typography variant="body2" style={{ color: '#adabab', fontSize: 13 }}>
                {this.state.regraComissaoDescontoAssistente.descricaoExplicacao}
              </Typography>
              <br />

              {this.state.paginaCarregada && (
                <div className={classes.containerFooterButtons}>
                  <Button disabled={this.state.ajaxing} onClick={this.props.handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled={this.state.ajaxing} variant="contained" color="secondary" onClick={this.salvar}>
                    {this.state.ajaxing ? 'Aguarde' : 'Salvar'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </ContentWithPreload>
      </DialogPage>
    )
  }
}

ConfiguracoesComissoesDialogPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfiguracoesComissoesDialogPage)
