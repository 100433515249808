import React from 'react'
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'

class DynamicHeightList extends React.Component {
  constructor(props) {
    super(props)
    this._cache = new CellMeasurerCache({
      defaultWidth: this.props.width,
      fixedWidth: true
    })
    this.listRef = React.createRef()
  }

  componentDidMount() {
    if (this.props.functionsMap !== undefined) {
      this.props.functionsMap['scrollToRow'] = (index) => {
        //this.listRef.current.scrollToRow(index);
        window.setTimeout(() => {
          this.recomputeRowHeights()
          this.listRef.current.scrollToRow(index)
          this.listRef.current.scrollToRow(index)
        }, 0)
        /*window.setTimeout(() => {
					this.listRef.current.scrollToRow(index);
					window.setTimeout(() => {
						this.listRef.current.scrollToRow(index);
						window.setTimeout(() => {
							this.listRef.current.scrollToRow(index);
							window.setTimeout(() => {
								this.listRef.current.scrollToRow(index);
							},100)
						},50)
					},30)
				},30)*/
      }
      this.props.functionsMap['recomputeRowHeights'] = (index) => {
        this.recomputeRowHeights(index)
      }
      this.props.functionsMap['softRecomputeRowHeights'] = (index) => {
        this.softRecomputeRowHeights(index)
      }
    }
  }

  recomputeRowHeights = (index) => {
    if (this.props.getItems() && this.props.getItems().length > 0 && this.listRef.current) {
      //console.log("recomputeRowHeights");
      this._cache.clearAll()
      this.listRef.current.measureAllRows()
      if (index === null || index === undefined) {
        this.listRef.current.recomputeRowHeights()
      } else {
        this.listRef.current.recomputeRowHeights(index)
      }
    }
  }

  softRecomputeRowHeights = (index) => {
    if (this.props.getItems() && this.props.getItems().length > 0 && this.listRef.current) {
      //console.log("softRecomputeRowHeights");
      this._cache.clear(index)
      this.listRef.current.measureAllRows()
      this.listRef.current.recomputeRowHeights(index)
    }
  }

  cellRenderer = (params) => {
    const { key, parent, index } = params
    return (
      <CellMeasurer cache={this._cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {this.props.rowRenderer(params)}
      </CellMeasurer>
    )
  }

  render() {
    const { getItems, rowRenderer, functionsMap, ...others } = this.props
    return (
      <List
        {...others}
        ref={this.listRef}
        columnCount={1}
        columnWidth={others.width}
        deferredMeasurementCache={this._cache}
        rowRenderer={this.cellRenderer}
        rowHeight={this._cache.rowHeight}
        overscanIndicesGetter={({
          direction, // One of "horizontal" or "vertical"
          cellCount, // Number of rows or columns in the current axis
          scrollDirection, // 1 (forwards) or -1 (backwards)
          overscanCellsCount, // Maximum number of cells to over-render in either direction
          startIndex, // Begin of range of visible cells
          stopIndex // End of range of visible cells
        }) => {
          //funcao para fazer o overscanRowCount funcionar para esse componente de lista com altura dinamica
          //console.log(startIndex,stopIndex,overscanCellsCount,Math.max(0, startIndex - overscanCellsCount),Math.min(cellCount - 1, stopIndex + overscanCellsCount))
          return {
            overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
            overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount)
          }
        }}
      />
    )
  }
}

export default DynamicHeightList
