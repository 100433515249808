import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import AgendaGridAtendimentos from 'pages/erp/painel/atendimento/agendainterface/AgendaGridAtendimentos'
import AgendaGridMensal from 'pages/erp/painel/atendimento/agendainterface/AgendaGridMensal'
import { LEFT_TOOLS_WIDTH_EXPANDED, LEFT_TOOLS_WIDTH_RETRACTED } from 'pages/erp/painel/atendimento/agendainterface/BarraFerramentasEsquerda'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  rootWithLeftToolsWidthExpanded: {
    flexBasis: 'calc(100%)',
    [theme.breakpoints.up('sm')]: {
      flexBasis: 'calc(100% - ' + LEFT_TOOLS_WIDTH_EXPANDED + 'px)'
    }
  },
  rootWithLeftToolsWidthRetracted: {
    flexBasis: 'calc(100%)',
    [theme.breakpoints.up('sm')]: {
      flexBasis: 'calc(100% - ' + LEFT_TOOLS_WIDTH_RETRACTED + 'px)'
    }
  }
})

class AgendaOperacao extends Component {
  render() {
    const { classes, ...others } = this.props

    let content = null

    if (this.props.perspectiva.id === PerspectivaEnum.DIA.id || this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      content = <AgendaGridAtendimentos {...others} />
    } else if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
      content = <AgendaGridMensal {...others} />
    }

    return <div className={classNames(classes.root, this.props.toolsExpanded ? classes.rootWithLeftToolsWidthExpanded : classes.rootWithLeftToolsWidthRetracted)}>{content}</div>
  }
}

AgendaOperacao.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AgendaOperacao)
