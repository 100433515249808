function getHeight() {
  const body = document.body,
    html = document.documentElement
  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
}

const functionsObject = {
  getHeight: getHeight
}

export default functionsObject
